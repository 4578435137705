import { ReactElement, ReactNode } from 'react'
import { valueof } from 'src/@types/global'
import { DOSSIER_STATUS_TYPE, PROJECT_USER_ROLE_TYPE, USER_ROLES_TYPE } from 'src/shared/constants/constants'
import { MILESTONE_STATUS_TYPE } from 'src/shared/constants/milestone-constants'
import { NextState } from 'src/shared/constants/workflow-constants'

type FOR_STATE_TYPE = DOSSIER_STATUS_TYPE | MILESTONE_STATUS_TYPE
interface Props {
  forState: FOR_STATE_TYPE | FOR_STATE_TYPE[]
  nextStates: NextState[]
  userProjectRoles?: Array<valueof<PROJECT_USER_ROLE_TYPE>>
  canEditOwnCanton?: boolean
  userGlobalRoles: Array<USER_ROLES_TYPE>
  children: ReactNode
}

/**
 * Component that conditionally renders children if the `forState` is available for the user.
 */
export const NextStateIf = ({
  children,
  nextStates,
  userProjectRoles,
  canEditOwnCanton,
  userGlobalRoles,
  forState,
}: Props): ReactElement | null => {
  const nextState = nextStates.find(({ state }) => forState.includes(state))
  if (nextState) {
    const hasGlobalRole = nextState.userRoles.some((r) => userGlobalRoles.includes(r))
    const hasProjectRole = userProjectRoles ? nextState.projectRoles?.some((r) => userProjectRoles.includes(r)) : false
    const hasProgramRole = canEditOwnCanton ? nextState.programRoles && canEditOwnCanton : false

    if (hasGlobalRole || hasProjectRole || hasProgramRole) {
      return <>{children}</>
    }
  }

  return null
}
