import { Box, Stack } from '@mui/material'
import { ReactElement, useEffect, useState } from 'react'
import { Form } from 'react-final-form'
import { useParams } from 'react-router'
import { Kap_Goal_Annual_Report_Insert_Input, Mutation_Root, Query_Root } from 'src/@types/graphql'
import { useMessageSource } from 'src/i18n/useMessageSource'
import { ROUTES } from 'src/routing/routes'
import {
  queryKapGoalAnnualReportByGoalAndMilestoneId,
  upsertKapGoalAnnualReportMutation,
} from 'src/screens/kap/implementation/milestone/details/annual-report/goals/kapMilestoneGoalQueries'

import { queryMilestoneById } from 'src/screens/shared/implementation/details/milestoneQueries'
import { usePermissionsForKapMilestones } from 'src/service/security/PermissionHook'
import { SaveAndBackButton, SaveButton } from 'src/shared/button/Buttons'
import { GOAL_CHAR, PROJECT, TEXT_LENGTH } from 'src/shared/constants/constants'
import {
  GOAL_MEASURE_RATING,
  MILESTONE_RESPONSIBLE_TYPE_TYPE,
  MILESTONE_STATUS_TYPE,
} from 'src/shared/constants/milestone-constants'
import { Option } from 'src/shared/form/control'
import { AutoCompleteField } from 'src/shared/form/control/AutoCompleteField'
import { HtmlEditorField } from 'src/shared/form/control/HtmlEditorField'
import { DirtyFormSpy } from 'src/shared/form/dirty/DirtyFormSpy'
import { createDecorators } from 'src/shared/form/utils/decorators'
import { composeValidators, maxChar, required } from 'src/shared/form/validation/validators'
import { PageLayout } from 'src/shared/layout/PageLayout'
import { ScreenLayout } from 'src/shared/layout/ScreenLayout'
import { NotAuthorized } from 'src/shared/not-authorized/NotAuthorized'
import { HelpAndInstructions } from 'src/shared/presentation/HelpAndInstructions'
import { useDelayedNavigate } from 'src/shared/utils/hooks/navigation-hooks'
import { HtmlSanitizer } from 'src/shared/utils/HtmlSanitizer'
import { useNotificationService } from 'src/shared/utils/NotificationService'
import { useClient, useQuery } from 'urql'

interface KapGoalRatingForm {
  rating: string
  reason: string
}

const decorators = createDecorators()

export const KapMilestoneGoalReportEditPage = (): ReactElement => {
  const { programId, milestoneId, milestonePath, goalId } = useParams()
  const program_id = parseInt(programId as string)
  const goal_id = parseInt(goalId as string)
  const milestone_id = parseInt(milestoneId as string)
  const { getMessage } = useMessageSource()
  const navigate = useDelayedNavigate()
  const process = PROJECT.KAP
  const urqlClient = useClient()
  const notificationService = useNotificationService()

  const [goalTitle, setGoalTitle] = useState('')

  const [{ data, error }] = useQuery<{ milestone_by_pk: Query_Root['milestone_by_pk'] }, { id: number }>({
    query: queryMilestoneById,
    variables: { id: milestone_id },
  })

  if (error) {
    notificationService.operationFailed()
  }
  const milestone = data?.milestone_by_pk
  const { loading, canEdit } = usePermissionsForKapMilestones(
    program_id,
    milestone?.status as MILESTONE_STATUS_TYPE,
    milestone?.responsible_type as MILESTONE_RESPONSIBLE_TYPE_TYPE,
  )

  const [initialValues, setInitialValues] = useState<KapGoalRatingForm>()
  const [ratingOptions, setRatingOptions] = useState<Option[]>([])

  useEffect(() => {
    const initData = async () => {
      const ratingOptions = Object.keys(GOAL_MEASURE_RATING).map((key) => ({
        label: `${getMessage(`label.goal.rating.${key}`)}`,
        value: key,
      }))
      setRatingOptions(ratingOptions)
      const { data } = await urqlClient
        .query<
          {
            kap_goal_annual_report: Query_Root['kap_goal_annual_report']
            kap_goal_by_pk: Query_Root['kap_goal_by_pk']
          },
          { goalId: number; milestoneId: number }
        >(queryKapGoalAnnualReportByGoalAndMilestoneId, {
          goalId: goal_id,
          milestoneId: milestone_id,
        })
        .toPromise()

      if (data && data.kap_goal_annual_report && data.kap_goal_by_pk) {
        setInitialValues({
          rating: data?.kap_goal_annual_report?.[0]?.rating ?? '',
          reason: data?.kap_goal_annual_report?.[0]?.reason ?? '',
        })

        const goalIdCharacter = getMessage(`label.goal.id.character`)
        setGoalTitle(`${data.kap_goal_by_pk?.name} (${goalIdCharacter}${data.kap_goal_by_pk.sort_number})`)
      } else {
        notificationService.operationFailed()
      }
    }
    initData()
  }, [urqlClient, goal_id, milestone_id, notificationService, getMessage])

  let submit: any = () => {}
  let formValid = false

  const onSave = (event: any) => {
    submit(event)
  }

  const onSaveAndBack = async (event: any) => {
    try {
      await submit(event)
      formValid && onBack()
    } catch (error) {
      // do nothing
    }
  }
  const onBack = () => {
    const route = ROUTES.KapAnnualReportMilestoneRoot.nested.GoalRating.params({
      programId,
      milestoneId,
      milestonePath,
    })

    navigate(`${route}#${GOAL_CHAR}${goalId}`, { state: { expandedEntityId: `${GOAL_CHAR}${goalId}` } })
  }

  const onSubmitLocal = async (values: KapGoalRatingForm) => {
    const goalKapAnnualReportModel = {
      milestone_id: milestone_id,
      kap_goal_id: goal_id,
      rating: values.rating,
      reason: HtmlSanitizer.sanitize(values.reason),
    }

    const { data } = await urqlClient
      .mutation<
        { insert_kap_goal_annual_report_one: Mutation_Root['insert_kap_goal_annual_report_one'] },
        { object: Kap_Goal_Annual_Report_Insert_Input }
      >(upsertKapGoalAnnualReportMutation, { object: goalKapAnnualReportModel })
      .toPromise()

    if (data && data?.insert_kap_goal_annual_report_one?.id) {
      notificationService.changesSaved()
      setInitialValues(values)
    } else {
      notificationService.operationFailed()
    }
  }

  if (!loading && !canEdit) {
    return <NotAuthorized inEditPage />
  }

  return (
    <>
      <ScreenLayout
        title={getMessage('label.edit.goal.milestone.title', [goalTitle])}
        onBack={onBack}
        hasSecondLevelNavigation={false}
        actions={
          <>
            <SaveAndBackButton origin="header" onClick={onSaveAndBack} />
            <SaveButton origin="header" onClick={onSave} />
          </>
        }
      >
        <PageLayout>
          <>
            {!loading && initialValues && (
              <>
                <HelpAndInstructions
                  labelKey={`label.help.milestone.goal.rating.${process.toLowerCase()}`}
                  defaultExpansion
                />
                <Box>
                  <Form<KapGoalRatingForm>
                    initialValues={initialValues}
                    onSubmit={onSubmitLocal}
                    decorators={decorators}
                    render={({ handleSubmit, valid }) => {
                      submit = handleSubmit
                      formValid = valid
                      return (
                        <form onSubmit={handleSubmit} noValidate>
                          <Stack spacing={2}>
                            <AutoCompleteField
                              name="rating"
                              label={getMessage('label.goal.rating')}
                              options={ratingOptions}
                              validate={required()}
                              required
                            />
                            <HtmlEditorField
                              name="reason"
                              label={getMessage('label.goal.rating.comment')}
                              validate={composeValidators(maxChar(TEXT_LENGTH.XL), required())}
                              required
                            />
                            <DirtyFormSpy />
                          </Stack>
                        </form>
                      )
                    }}
                  />
                </Box>
              </>
            )}
          </>
        </PageLayout>
      </ScreenLayout>
    </>
  )
}
