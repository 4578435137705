import { LoadingButton } from '@mui/lab'
import { Box, DialogContent, IconButton } from '@mui/material'
import { DataGridPro, GridColDef, GridPaginationModel, GridSortModel, useGridApiRef } from '@mui/x-data-grid-pro'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { EvaluationNeedDetailsResponseData, Mutation_Root, ReportInput } from 'src/@types/graphql'
import { useMessageSource } from 'src/i18n/useMessageSource'
import { resolveIntermediateMilestoneRoot } from 'src/routing/routing-utils'
import { ReportService } from 'src/service/axios/ReportService'
import { PROJECT, PROJECT_TYPE } from 'src/shared/constants/constants'
import { MILESTONE_TYPE } from 'src/shared/constants/milestone-constants'
import { REPORTING_DOWNLOAD, REPORTING_DOWNLOAD_TYPE } from 'src/shared/constants/reporting-constants'
import { DownloadIcon, ExternalIcon } from 'src/shared/icons/Icons'
import { ModalDialog } from 'src/shared/modal-dialog/ModalDialog'
import { HtmlRenderer } from 'src/shared/presentation/HtmlRenderer'
import { saveFileAs } from 'src/shared/utils/BlobUtils'
import { DateUtils } from 'src/shared/utils/DateUtils'
import { useGridTranslateHook } from 'src/shared/utils/hooks/grid-translate-hook'
import { useNotificationService } from 'src/shared/utils/NotificationService'
import { Utils } from 'src/shared/utils/Utils'
import { useUserLocale } from 'src/user/UserContext'
import { gql, useClient } from 'urql'

interface ReportEvaluationNeedDetailsProps {
  reportInput: ReportInput
  evaluationNeed: string | undefined | null
  process: PROJECT_TYPE
  modalOpen: boolean
  setModalOpen: (v: boolean) => void
}

const fetchEvaluationNeedDetailsReportDataMutation = gql`
  mutation fetchEvaluationNeedDetailsReportData(
    $report: ReportInput!
    $evaluationNeed: String
    $isPfKap: Boolean!
    $isPfPgv: Boolean!
  ) {
    fetchPfKapProjectEvaluationNeedDetailsReportData(report: $report, evaluationNeed: $evaluationNeed)
      @include(if: $isPfKap) {
      data {
        projectId
        milestoneId
        shortTitle
        plannedYear
        milestoneYear
        reasoning
      }
    }
    fetchPfPgvProjectEvaluationNeedDetailsReportData(report: $report, evaluationNeed: $evaluationNeed)
      @include(if: $isPfPgv) {
      data {
        projectId
        milestoneId
        shortTitle
        plannedYear
        milestoneYear
        reasoning
      }
    }
  }
`

export const ReportEvaluationNeedDetails = ({
  reportInput,
  evaluationNeed,
  modalOpen,
  setModalOpen,
  process,
}: ReportEvaluationNeedDetailsProps) => {
  const { getMessage } = useMessageSource()
  const apiRef = useGridApiRef()
  const urqlClient = useClient()
  const notificationService = useNotificationService()
  const gridTranslations = useGridTranslateHook()
  const language = useUserLocale()
  const isPfKap = process === PROJECT.PF_KAP
  const isPfPgv = process === PROJECT.PF_PGV
  const baseUrl = isPfKap ? '/pf-kap' : '/pf-pgv'
  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({ page: 0, pageSize: 10 })
  const [sortModel, setSortModel] = useState<GridSortModel>([
    {
      field: 'milestoneYear',
      sort: 'desc',
    },
  ])
  const [evaluationNeeds, setEvaluationNeeds] = useState<EvaluationNeedDetailsResponseData[]>([])
  const [loadingDownload, setLoadingDownload] = useState<boolean>(false)
  const fetchData = useCallback(async () => {
    if (evaluationNeed !== undefined) {
      const { data } = await urqlClient
        .mutation<
          {
            fetchPfKapProjectEvaluationNeedDetailsReportData: Mutation_Root['fetchPfKapProjectEvaluationNeedDetailsReportData']
            fetchPfPgvProjectEvaluationNeedDetailsReportData: Mutation_Root['fetchPfPgvProjectEvaluationNeedDetailsReportData']
          },
          { report: ReportInput; evaluationNeed: string | null; isPfKap: boolean; isPfPgv: boolean }
        >(fetchEvaluationNeedDetailsReportDataMutation, {
          report: reportInput,
          evaluationNeed: evaluationNeed,
          isPfKap: isPfKap,
          isPfPgv: isPfPgv,
        })
        .toPromise()

      if (data) {
        const evaluationNeedReportsData =
          process === PROJECT.PF_KAP
            ? data.fetchPfKapProjectEvaluationNeedDetailsReportData.data
            : data.fetchPfPgvProjectEvaluationNeedDetailsReportData.data

        setEvaluationNeeds(evaluationNeedReportsData)
      } else {
        notificationService.operationFailed()
      }
    }
  }, [urqlClient, notificationService, process, evaluationNeed, reportInput, isPfKap, isPfPgv])

  useEffect(() => {
    if (modalOpen && evaluationNeed !== undefined) {
      fetchData()
    }
  }, [evaluationNeed, modalOpen, fetchData])

  const rows = useMemo(
    () =>
      evaluationNeeds.map((evaluationNeed, index) => ({
        ...evaluationNeed,
        plannedYear:
          evaluationNeed.plannedYear !== null
            ? DateUtils.formatDate(DateUtils.parseDateGraphQL(evaluationNeed.plannedYear), DateUtils.YEAR_FORMAT)
            : null,
        id: index,
      })),
    [evaluationNeeds],
  )

  const handleOpenLink = useCallback(
    (event: any, projectId: string, milestoneId: string) => {
      event.stopPropagation()
      const milestonePath = Utils.resolveMilestonePath(MILESTONE_TYPE.INTERMEDIATE_DISCUSSION)
      const url = resolveIntermediateMilestoneRoot(isPfKap ? '/pf-kap' : '/pf-pgv').nested.Evaluation.params({
        milestoneId,
        milestonePath,
        projectId,
      })
      window.open(url, '_blank')
    },
    [isPfKap],
  )

  const columns = useMemo<GridColDef[]>(
    () => [
      {
        field: 'projectId',
        headerName: getMessage('label.id'),
        flex: 0.5,
        renderCell: ({ value }) => `${getMessage(`label.project.id.character.${process.toLowerCase()}`)}${value}`,
      },
      {
        field: 'shortTitle',
        headerName: getMessage('label.short.title'),
        flex: 2,
      },
      {
        field: 'milestoneYear',
        headerName: getMessage('label.milestone.year'),
        flex: 1,
      },
      {
        field: 'plannedYear',
        headerName: getMessage('label.planned.year'),
        flex: 1,
      },
      {
        field: 'reasoning',
        headerName: getMessage('label.reasoning'),
        flex: 2,
        renderCell: ({ value }) => {
          return <HtmlRenderer html={value} truncate variant="body2" />
        },
      },
      {
        field: 'link',
        headerName: getMessage('label.link'),
        flex: 1,
        sortable: false,
        renderCell: ({ row }) => {
          return (
            <IconButton color="primary" onClick={(event) => handleOpenLink(event, row.projectId, row.milestoneId)}>
              <ExternalIcon />
            </IconButton>
          )
        },
      },
    ],
    [getMessage, handleOpenLink, process],
  )

  const onDownloadHandle = (reportType: REPORTING_DOWNLOAD_TYPE) => async () => {
    if (reportInput && evaluationNeed !== undefined) {
      setLoadingDownload(true)
      await ReportService.generateReport(reportInput, baseUrl, reportType, language, { evaluationNeed })
        .then((response) => {
          saveFileAs(response)
        })
        .catch(() => {
          notificationService.operationFailed()
        })
        .finally(() => {
          setLoadingDownload(false)
        })
    }
  }

  return (
    <>
      {evaluationNeed !== undefined && (
        <ModalDialog
          open={modalOpen}
          onClose={() => {
            setModalOpen(false)
            setEvaluationNeeds([])
          }}
          title={`${getMessage('label.evaluation.need')}: ${
            evaluationNeed !== null
              ? getMessage(`label.evaluation.need.${evaluationNeed}`)
              : getMessage('label.not.available')
          }`}
          maxWidth="lg"
          action={
            <LoadingButton
              variant="text"
              color="primary"
              startIcon={<DownloadIcon />}
              onClick={onDownloadHandle(REPORTING_DOWNLOAD.EVALUATION_NEED)}
              loading={loadingDownload}
            >
              {getMessage('button.download')}
            </LoadingButton>
          }
        >
          <DialogContent>
            <Box sx={{ height: 668 }}>
              <DataGridPro
                apiRef={apiRef}
                columns={columns}
                rows={rows}
                localeText={gridTranslations}
                disableColumnResize
                disableColumnSelector
                disableColumnReorder
                disableColumnPinning
                disableRowSelectionOnClick
                disableColumnMenu
                disableColumnFilter
                sortModel={sortModel}
                onSortModelChange={setSortModel}
                disableMultipleColumnsSorting
                paginationModel={paginationModel}
                onPaginationModelChange={setPaginationModel}
                pageSizeOptions={[10, 25, 50]}
                pagination
              />
            </Box>
          </DialogContent>
        </ModalDialog>
      )}
    </>
  )
}
