import { useMessageSource } from 'src/i18n/useMessageSource'
import { ROUTES } from 'src/routing/routes'
import { BASE_URL, PROJECT } from 'src/shared/constants/constants'
import { Sidebar, SidebarGroupObject } from 'src/shared/sidebar/Sidebar'

export const ReportingSidebar = () => {
  const { getMessage } = useMessageSource()

  const routeGroups: SidebarGroupObject[] = [
    {
      groupMessageKey: 'label.navigation.reports',
      list: [
        {
          listMessageKey: `label.reporting.${PROJECT.KAP}`,
          href: ROUTES.KapReportingRoot.params({}),
          routePath: ROUTES.KapReportingRoot.path,
        },
        {
          listMessageKey: `label.reporting.${PROJECT.PF_KAP}`,
          href: ROUTES.PfKapReportingRoot.params({}),
          routePath: ROUTES.PfKapReportingRoot.path,
        },
        {
          listMessageKey: `label.reporting.${PROJECT.PF_PGV}`,
          href: ROUTES.PfPgvReportingRoot.params({}),
          routePath: ROUTES.PfPgvReportingRoot.path,
        },
      ],
    },
  ]

  return (
    <>
      <Sidebar
        routes={routeGroups}
        hideDetailedInformation
        home={{ url: BASE_URL.REPORTING, label: getMessage('label.navigation.reporting.dashboard') }}
      />
    </>
  )
}
