import { USER_ROLES_TYPE } from 'src/shared/constants/constants'
import { NextState } from 'src/shared/constants/workflow-constants'

const nextStatesForUser =
  (nextStates: Array<NextState>) =>
  (roles: Array<USER_ROLES_TYPE>, canEditOwnCanton: boolean): Array<NextState> => {
    const forProgramRoles = nextStates.filter((s) => s?.programRoles?.length && canEditOwnCanton)
    const forGlobalRoles = nextStates.filter((s) => s.userRoles.some((r) => roles.includes(r)))

    return [...forProgramRoles, ...forGlobalRoles]
  }

export const ProgramWorkflowPermissionService = {
  nextStatesForUser,
}
