import { ReactElement } from 'react'
import { useParams } from 'react-router-dom'
import { Query_Root } from 'src/@types/graphql'
import { useMessageSource } from 'src/i18n/useMessageSource'
import { ROUTES } from 'src/routing/routes'
import { BASE_URL, PROJECT } from 'src/shared/constants/constants'
import { Sidebar, SidebarGroupObject } from 'src/shared/sidebar/Sidebar'
import { useSidebarContext } from 'src/shared/sidebar/SidebarAwareContext'
import { useNotificationService } from 'src/shared/utils/NotificationService'
import { gql, useQuery } from 'urql'

const getProjectQueryById = gql`
  query fetchSingleProject($id: Int!) {
    pf_kap_project_by_pk(id: $id) {
      id
      project_base {
        dossier {
          short_title
          status
          funding_round {
            name
          }
        }
      }
    }
  }
`

export const PfKapSidebar = (): ReactElement => {
  const { projectId } = useParams()
  const notificationService = useNotificationService()
  const id = parseInt(projectId as string)
  const { getMessage } = useMessageSource()

  const [{ data, error, fetching }, refetchQuery] = useQuery<
    {
      pf_kap_project_by_pk: Query_Root['pf_kap_project_by_pk']
    },
    {
      id: number
    }
  >({
    query: getProjectQueryById,
    variables: { id },
  })

  if (error) {
    notificationService.operationFailed()
  }

  const dossier = data?.pf_kap_project_by_pk?.project_base.dossier
  const shortTitle = dossier?.short_title
  const pfKapProjectId = data?.pf_kap_project_by_pk?.id
    ? `${getMessage('label.project.id.character.pf_kap')}${data?.pf_kap_project_by_pk?.id}`
    : `${getMessage('label.not.available')}`
  const fundingRoundName = dossier?.funding_round?.name
  const status = dossier?.status

  useSidebarContext(refetchQuery)

  const routeGroups: SidebarGroupObject[] = [
    {
      groupMessageKey: 'label.project',
      list: [
        {
          listMessageKey: 'label.navigation.project.details',
          href: ROUTES.PfKapDetailsRoot.params({ projectId }),
          routePath: ROUTES.PfKapDetailsRoot.path,
        },
        {
          listMessageKey: 'label.navigation.project.permissions',
          href: ROUTES.PfKapPermissions.params({ projectId }),
          routePath: ROUTES.PfKapPermissions.path,
        },
        {
          listMessageKey: 'label.navigation.project.journal',
          href: ROUTES.PfKapProjectJournal.params({ projectId }),
          routePath: ROUTES.PfKapProjectJournal.path,
        },
      ],
    },
    {
      groupMessageKey: 'label.application',
      list: [
        {
          listMessageKey: 'label.navigation.application.details',
          href: ROUTES.PfKapApplicationDetailsRoot.params({ projectId }),
          routePath: ROUTES.PfKapApplicationDetailsRoot.path,
        },
        {
          listMessageKey: 'label.navigation.application.committees',
          href: ROUTES.PfKapApplicationCommittee.params({ projectId }),
          routePath: ROUTES.PfKapApplicationCommittee.path,
        },
        {
          listMessageKey: 'label.navigation.application.assessment',
          href: ROUTES.PfKapApplicationAssessment.params({ projectId }),
          routePath: ROUTES.PfKapApplicationAssessment.path,
        },
      ],
    },
    {
      groupMessageKey: 'label.implementation',
      list: [
        {
          listMessageKey: 'label.navigation.project.implementation',
          href: ROUTES.PfKapImplementationDetailsRoot.params({ projectId }),
          routePath: ROUTES.PfKapImplementationDetailsRoot.path,
        },
      ],
    },
  ]

  return (
    <Sidebar
      title={shortTitle}
      entityId={pfKapProjectId}
      fundingRoundName={fundingRoundName}
      status={status}
      routes={routeGroups}
      process={PROJECT.PF_KAP}
      home={{ url: BASE_URL.PF_KAP, label: getMessage('label.navigation.pfkap.cockpit') }}
      loading={fetching}
    />
  )
}
