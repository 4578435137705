import { Box } from '@mui/material'
import { get } from 'lodash'
import { ReactElement, useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { Kap_Measure, Project_Base, Query_Root } from 'src/@types/graphql'
import { useMessageSource } from 'src/i18n/useMessageSource'
import {
  fetchFactsheetByIdQuery,
  relatedPfKapProjectsAndKapMeasuresQuery,
} from 'src/screens/factsheets/factsheet/factsheetQueries'
import { FactsheetLinkedKapMeasuresGrid } from 'src/screens/factsheets/factsheet/related-projects-and-measures/FactsheetLinkedKapMeasuresGrid'
import { FactsheetLinkedPfKapProjectsGrid } from 'src/screens/factsheets/factsheet/related-projects-and-measures/FactsheetLinkedPfKapProjectsGrid'
import { PROJECT } from 'src/shared/constants/constants'
import { PageLayout } from 'src/shared/layout/PageLayout'
import { ScreenLayout } from 'src/shared/layout/ScreenLayout'
import { FactsheetExportMenu } from 'src/shared/menu/FactsheetExportMenu'
import { HelpAndInstructions } from 'src/shared/presentation/HelpAndInstructions'
import { Section } from 'src/shared/presentation/Section'
import { useError } from 'src/shared/utils/hooks/page-loading-error-hook'
import { useNotificationService } from 'src/shared/utils/NotificationService'
import { Utils } from 'src/shared/utils/Utils'
import { UserContext, useUserLocale } from 'src/user/UserContext'
import { useClient, useQuery } from 'urql'

export const FactsheetRelatedProjectsAndMeasuresPage = (): ReactElement => {
  const { getMessage } = useMessageSource()
  const language = useUserLocale()
  const notificationService = useNotificationService()
  const setError = useError()
  const urqlClient = useClient()
  const { user } = useContext(UserContext)

  const { factsheetId } = useParams()

  const [relatedProjects, setRelatedProjects] = useState<Array<Project_Base>>([])
  const [relatedMeasures, setRelatedMeasures] = useState<Array<Kap_Measure>>([])

  const canViewLinkedProjects =
    Utils.isFactsheetCoordinatorRole(user.roles) ||
    (Utils.isInternalRole(user.roles) &&
      Utils.resolveProcessFromInternalGfchRole(user.roles).includes(PROJECT.PF_KAP)) ||
    Utils.isExternalRole(user.roles)

  const canViewLinkedMeasures =
    Utils.isFactsheetCoordinatorRole(user.roles) ||
    (Utils.isInternalRole(user.roles) && Utils.resolveProcessFromInternalGfchRole(user.roles).includes(PROJECT.KAP)) ||
    Utils.isCantonalRole(user.roles)

  useEffect(() => {
    const initData = async () => {
      const { data, error } = await urqlClient
        .query<{ project_base: Query_Root['project_base']; kap_measure: Query_Root['kap_measure'] }>(
          relatedPfKapProjectsAndKapMeasuresQuery,
          {
            factsheetId,
          },
        )
        .toPromise()

      if (error) {
        setError()
      } else {
        const relatedProjects = data?.project_base ?? []
        const relatedMeasures = data?.kap_measure ?? []
        setRelatedProjects(relatedProjects)
        setRelatedMeasures(relatedMeasures)
      }
    }
    initData()
  }, [urqlClient, setError, factsheetId])

  const [{ data, error }] = useQuery<{
    factsheet_by_pk: Query_Root['factsheet_by_pk']
  }>({
    query: fetchFactsheetByIdQuery,
    variables: { id: factsheetId },
  })

  const factsheetTitle = data?.factsheet_by_pk?.title

  if (error) {
    notificationService.operationFailed()
  }

  return (
    <ScreenLayout
      title={get(factsheetTitle, language, '')}
      hasSecondLevelNavigation={false}
      actions={<FactsheetExportMenu />}
    >
      <PageLayout>
        {canViewLinkedProjects && (
          <Section
            id="linked-pf-kap-projects"
            title={getMessage('label.factsheet.linked.pf_kap.projects')}
            helpAndInstructions={<HelpAndInstructions labelKey="label.help.factsheet.linked.pf_kap.projects" />}
          >
            <Box>
              <FactsheetLinkedPfKapProjectsGrid projects={relatedProjects} />
            </Box>
          </Section>
        )}
        {canViewLinkedMeasures && (
          <Section
            id="linked-kap-measures"
            title={getMessage('label.factsheet.linked.kap.measures')}
            helpAndInstructions={<HelpAndInstructions labelKey="label.help.factsheet.linked.kap.measures" />}
          >
            <Box>
              <FactsheetLinkedKapMeasuresGrid measures={relatedMeasures} />
            </Box>
          </Section>
        )}
      </PageLayout>
    </ScreenLayout>
  )
}
