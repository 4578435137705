import { ComposePaths, ExtractRouteParams, withParams } from 'src/routing/routing-utils'
import { AnalysisDetailsPage } from 'src/screens/success-factor-tool/analysis/details/analysis/AnalysisDetailsPage'
import { AnalysisAreaOfActivityEditPage } from 'src/screens/success-factor-tool/analysis/details/analysis/edit/AnalysisAreaOfActivityEditPage'
import { AnalysisFocusTopicEditPage } from 'src/screens/success-factor-tool/analysis/details/analysis/edit/AnalysisFocusTopicEditPage'
import { AnalysisSuccessFactorEditPage } from 'src/screens/success-factor-tool/analysis/details/analysis/edit/AnalysisSuccessFactorEditPage'
import { AnalysisCoverPage } from 'src/screens/success-factor-tool/analysis/details/cover/AnalysisCoverPage'
import { AnalysisCoverEditPage } from 'src/screens/success-factor-tool/analysis/details/cover/edit/AnalysisCoverEditPage'
import { AnalysisResultsPage } from 'src/screens/success-factor-tool/analysis/details/results/AnalysisResultsPage'
import { SuccessFactorsDetailsIndexPage } from 'src/screens/success-factor-tool/analysis/details/SuccessFactorsDetailsIndexPage'
import { SuccessFactorsIndexPage } from 'src/screens/success-factor-tool/index/SuccessFactorsIndexPage'
import { SuccessFactorsOverviewPage } from 'src/screens/success-factor-tool/public/overview/SuccessFactorsOverviewPage'
import { SuccessFactorsPublicLandingPage } from 'src/screens/success-factor-tool/public/SuccessFactorsPublicLandingPage'

const SuccessFactorsIndex = {
  path: 'success-factors',
  Component: SuccessFactorsIndexPage,
  roles: ['PD-SUCCESS_FACTOR_CONTRIBUTOR', 'PD-GFCH_SUCCESS_FACTOR_COORDINATOR'],
} as const

const SuccessFactorsDetailsRoot = {
  path: 'success-factors/analysis/:analysisId/details',
  Component: SuccessFactorsDetailsIndexPage,
  roles: ['PD-SUCCESS_FACTOR_CONTRIBUTOR', 'PD-GFCH_SUCCESS_FACTOR_COORDINATOR'],
} as const

const AnalysisDetails = {
  path: 'analysis',
  Component: AnalysisDetailsPage,
  navigationKey: 'label.navigation.success.factors.analysis',
  primary: true,
} as const

const AnalysisResults = {
  path: 'results',
  Component: AnalysisResultsPage,
  navigationKey: 'label.navigation.success.factors.results',
} as const

const AnalysisCover = {
  path: 'cover',
  Component: AnalysisCoverPage,
  navigationKey: 'label.navigation.success.factors.cover',
} as const

const AnalysisCoverEdit = {
  path: 'cover/edit',
  Component: AnalysisCoverEditPage,
  hideNavigation: true,
} as const

const AnalysisAreaOfActivityEdit = {
  path: 'analysis/area-of-activity/:areaOfActivityId/edit',
  Component: AnalysisAreaOfActivityEditPage,
  hideNavigation: true,
} as const

const AnalysisFocusTopicEdit = {
  path: 'analysis/focus-topic/:focusTopicId/edit',
  Component: AnalysisFocusTopicEditPage,
  hideNavigation: true,
} as const

const AnalysisSuccessFactorEdit = {
  path: 'analysis/success-factor/:successFactorId/edit',
  Component: AnalysisSuccessFactorEditPage,
  hideNavigation: true,
} as const

const SuccessFactorsPublicLanding = {
  path: 'pub/sft',
  Component: SuccessFactorsPublicLandingPage,
} as const

const SuccessFactorsPublicOverview = {
  path: 'pub/sft/overview',
  Component: SuccessFactorsOverviewPage,
  hideNavigation: true,
} as const

export const SuccessFactorsRoutes = {
  SuccessFactorsIndex: SuccessFactorsIndex,
  SuccessFactorAnalysisRoot: {
    ...SuccessFactorsDetailsRoot,
    params: (props: ExtractRouteParams<typeof SuccessFactorsDetailsRoot.path>) =>
      withParams(`/${SuccessFactorsDetailsRoot.path}`)(props),
    nested: {
      AnalysisDetails: {
        ...AnalysisDetails,
        params: (
          props: ExtractRouteParams<ComposePaths<typeof SuccessFactorsDetailsRoot.path, typeof AnalysisDetails.path>>,
        ) => withParams(`/${SuccessFactorsDetailsRoot.path}/${AnalysisDetails.path}`)(props),
      },
      AnalysisAreaOfActivityEdit: {
        ...AnalysisAreaOfActivityEdit,
        params: (
          props: ExtractRouteParams<
            ComposePaths<typeof SuccessFactorsDetailsRoot.path, typeof AnalysisAreaOfActivityEdit.path>
          >,
        ) => withParams(`/${SuccessFactorsDetailsRoot.path}/${AnalysisAreaOfActivityEdit.path}`)(props),
      },
      AnalysisResults: {
        ...AnalysisResults,
        params: (
          props: ExtractRouteParams<ComposePaths<typeof SuccessFactorsDetailsRoot.path, typeof AnalysisResults.path>>,
        ) => withParams(`/${SuccessFactorsDetailsRoot.path}/${AnalysisResults.path}`)(props),
      },
      AnalysisCover: {
        ...AnalysisCover,
        params: (
          props: ExtractRouteParams<ComposePaths<typeof SuccessFactorsDetailsRoot.path, typeof AnalysisCover.path>>,
        ) => withParams(`/${SuccessFactorsDetailsRoot.path}/${AnalysisCover.path}`)(props),
      },
      AnalysisCoverEdit: {
        ...AnalysisCoverEdit,
        params: (
          props: ExtractRouteParams<ComposePaths<typeof SuccessFactorsDetailsRoot.path, typeof AnalysisCoverEdit.path>>,
        ) => withParams(`/${SuccessFactorsDetailsRoot.path}/${AnalysisCoverEdit.path}`)(props),
      },
      AnalysisFocusTopicEdit: {
        ...AnalysisFocusTopicEdit,
        params: (
          props: ExtractRouteParams<
            ComposePaths<typeof SuccessFactorsDetailsRoot.path, typeof AnalysisFocusTopicEdit.path>
          >,
        ) => withParams(`/${SuccessFactorsDetailsRoot.path}/${AnalysisFocusTopicEdit.path}`)(props),
      },
      AnalysisSuccessFactorEdit: {
        ...AnalysisSuccessFactorEdit,
        params: (
          props: ExtractRouteParams<
            ComposePaths<typeof SuccessFactorsDetailsRoot.path, typeof AnalysisSuccessFactorEdit.path>
          >,
        ) => withParams(`/${SuccessFactorsDetailsRoot.path}/${AnalysisSuccessFactorEdit.path}`)(props),
      },
    },
  },
  SuccessFactorsPublicLanding: SuccessFactorsPublicLanding,
  SuccessFactorsPublicOverview: SuccessFactorsPublicOverview,
}
