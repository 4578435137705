import { LoadingButton } from '@mui/lab'
import { Box, Button, DialogActions, DialogContent, Link, Stack, Typography } from '@mui/material'
import Alert from '@mui/material/Alert'
import { ReactElement } from 'react'
import { useEnvironment } from 'src/env/EnvironmentStore'
import { useMessageSource } from 'src/i18n/useMessageSource'
import { DOSSIER_STATUS, PROJECT, PROJECT_TYPE } from 'src/shared/constants/constants'
import { DownloadIcon } from 'src/shared/icons/Icons'
import { ModalDialog } from 'src/shared/modal-dialog/ModalDialog'
import { useUserLocale } from 'src/user/UserContext'
import styled from 'styled-components/macro'

interface Props {
  open: boolean
  onCancel: () => void
  onSend: () => void
  titleKey: string
  confirmButtonKey?: string
  contentKey: string
  processType: PROJECT_TYPE
  entityId: number
  buttonLoadingKey: string
  loading: boolean
  pdfDownloadVisible?: boolean
}

const ModalDialogStyled = styled(ModalDialog)`
  & .MuiDialogTitle-root {
    & .MuiIconButton-root {
      color: ${({ theme }) => theme.colors.secondary.main};
      &:hover {
        background-color: ${({ theme }) => theme.colors.secondary.light};
      }
    }
  }
`
const AlertStyled = styled(Alert)`
  background-color: ${({ theme }) => theme.colors.secondary.light};
  & .MuiSvgIcon-root {
    color: ${({ theme }) => theme.colors.secondary.main};
  }
  &:hover {
    cursor: pointer;
    background-color: ${({ theme }) => theme.colors.secondary.lightHover};
  }
  &:hover .MuiSvgIcon-fontSizeMedium {
    color: ${({ theme }) => theme.colors.secondary.dark};
  }
`

export const HandoverModalDialog = ({
  open,
  loading,
  onCancel,
  onSend,
  processType,
  entityId,
  titleKey,
  contentKey,
  buttonLoadingKey,
  pdfDownloadVisible = false,
}: Props): ReactElement => {
  const { getMessage } = useMessageSource()

  const { backendUrl } = useEnvironment()
  const process = processType.toUpperCase()
  const locale = useUserLocale()

  const resolvePdfDownloadLink = (processType: PROJECT_TYPE) => {
    switch (processType) {
      case PROJECT.PF_KAP:
      case PROJECT.PF_PGV:
        return `${backendUrl}/api/${process}/project/${entityId}/export/application-pdf?lang=${locale}`
      case PROJECT.KAP:
        return `${backendUrl}/api/kap/program/${entityId}/export/application-pdf?lang=${locale}`
      default:
        throw Error(`Unknown process type ${processType}`)
    }
  }

  return (
    <ModalDialogStyled open={open} onClose={onCancel} title={getMessage(titleKey)} maxWidth="sm" withCloseIcon={true}>
      <DialogContent>
        <Stack spacing={3}>
          {pdfDownloadVisible && (
            <Link
              href={resolvePdfDownloadLink(processType)}
              underline="none"
              sx={{ display: 'flex', color: 'inherit', flex: 1 }}
            >
              <AlertStyled severity="success" icon={<DownloadIcon />} sx={{ display: 'flex', flex: 1 }}>
                <Box sx={{ display: 'flex', gridGap: 0.5 }}>
                  <Typography fontWeight="bold" variant="subtitle2" color="textPrimary" component="span">
                    {getMessage(`label.project.status.${DOSSIER_STATUS.APPLICATION}`)}
                  </Typography>
                  <Typography variant="body2"> (pdf)</Typography>
                </Box>
              </AlertStyled>
            </Link>
          )}
          <Typography>{getMessage(contentKey)}</Typography>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button variant="text" color="secondary" onClick={onCancel}>
          {getMessage('button.cancel')}
        </Button>
        <LoadingButton variant="contained" color="secondary" onClick={onSend} loading={loading}>
          {getMessage(buttonLoadingKey)}
        </LoadingButton>
      </DialogActions>
    </ModalDialogStyled>
  )
}
