import { LEVEL } from 'src/shared/constants/constants'

const sortOrder = Object.keys(LEVEL).map((level) => level)

/**
 * Sorts KAP measures by level in custom specified order. The original list is mutated and sorted.
 * The custom order is: Intervention - Policy - Network - Communication
 *
 * @param list list of objects that somehow contain measures or if nested connection is not provided
 *             then the measure list itself.
 * @param nestedConnection field which contains the {@link Kap_Measure} object.
 * @returns {Array} modified list with KAP measures sorted.
 */
const sortKapMeasures = (list?: any[], nestedConnection?: string): any[] => {
  if (!list) {
    return []
  }

  return list.sort(function (m1, m2) {
    const m1Level = nestedConnection ? m1[nestedConnection].level : m1.level
    const m2Level = nestedConnection ? m2[nestedConnection].level : m2.level
    const m1SortNumber = nestedConnection ? m1[nestedConnection].sort_number : m1.sort_number
    const m2SortNumber = nestedConnection ? m2[nestedConnection].sort_number : m2.sort_number

    if (m1Level === m2Level) {
      return m1SortNumber - m2SortNumber
    } else {
      return sortOrder.indexOf(m1Level) - sortOrder.indexOf(m2Level)
    }
  })
}

export { sortKapMeasures }
