import { ReactElement } from 'react'
import { Field } from 'react-final-form'
import ReCAPTCHA from 'react-google-recaptcha'

interface ReCaptchaFieldProps {
  name: string
  siteKey: string
}

export const ReCaptchaField = (props: ReCaptchaFieldProps): ReactElement => {
  const { name, siteKey } = props
  return (
    <Field
      name={name}
      render={({ input: { onChange } }) => {
        return <ReCAPTCHA sitekey={siteKey} onChange={onChange} />
      }}
    />
  )
}
