import { ReactElement, useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { Mutation_Root } from 'src/@types/graphql'
import { useMessageSource } from 'src/i18n/useMessageSource'
import { resolveDetailsRoot } from 'src/routing/routing-utils'
import {
  GoalForm,
  GoalFormValues,
  initialValuesFactory,
} from 'src/screens/shared/project/details/goals-and-measures/goal/GoalForm'
import { insertProjectGoal } from 'src/screens/shared/project/details/goals-and-measures/goal/goalQueries'
import { usePermissionsForProject } from 'src/service/security/PermissionHook'
import { CreateButton } from 'src/shared/button/Buttons'
import { GOAL_CHAR } from 'src/shared/constants/constants'
import { PageLayout } from 'src/shared/layout/PageLayout'
import { ScreenLayout } from 'src/shared/layout/ScreenLayout'
import { NotAuthorized } from 'src/shared/not-authorized/NotAuthorized'
import { HelpAndInstructions } from 'src/shared/presentation/HelpAndInstructions'
import { useDelayedNavigate } from 'src/shared/utils/hooks/navigation-hooks'
import { HtmlSanitizer } from 'src/shared/utils/HtmlSanitizer'
import { useNotificationService } from 'src/shared/utils/NotificationService'
import { Utils } from 'src/shared/utils/Utils'
import { useClient } from 'urql'

interface ProjectGoalCreatePageProps {
  baseUrl: '/pf-kap' | '/pf-pgv'
}

export const ProjectGoalCreatePage = ({ baseUrl }: ProjectGoalCreatePageProps): ReactElement => {
  const { projectId } = useParams()
  const id = parseInt(projectId as string)
  const { getMessage } = useMessageSource()
  const [initialValues, setInitialValues] = useState<GoalFormValues | null>(null)
  const navigate = useDelayedNavigate()
  const urqlClient = useClient()
  const notificationService = useNotificationService()
  const pageTitle = getMessage('label.goal.title.add')
  const process = Utils.resolveProcessToLowerCase(baseUrl)
  const projectType = Utils.resolveProcess(baseUrl)
  const {
    canEdit,
    loading,
    metadata: { projectBaseId },
  } = usePermissionsForProject(projectType, id)

  const onSubmit = () => {
    // trigger submit event
    document.getElementById('goal-form')?.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }))
  }

  const onSaveHandler = async (values: GoalFormValues) => {
    // insert project goal
    const { error, data } = await insertGoal({ ...values })
    if (error) {
      notificationService.operationFailed()
    } else {
      setInitialValues({ ...values })
      notificationService.changesSaved()
      onBack(data?.insert_project_goal?.returning[0].id)
    }
  }

  const insertGoal = async (values: GoalFormValues) => {
    const insertObject = {
      name: values.name,
      description: HtmlSanitizer.sanitize(values.description),
      projectBaseId: values.projectBaseId,
      measuresToBeAdded: values.measureIds?.map((measureId: number) => ({
        project_measure_id: measureId,
      })),
    }
    const { error, data } = await urqlClient
      .mutation<{
        insert_project_goal: Mutation_Root['insert_project_goal']
      }>(insertProjectGoal, insertObject)
      .toPromise()

    return { error, data }
  }

  const onBack = (goalId?: number) => {
    const route = resolveDetailsRoot(baseUrl).nested.GoalsAndMeasures.params({ projectId })

    if (goalId) {
      navigate(`${route}#${GOAL_CHAR}${goalId}`, { state: { expandedEntityId: `${GOAL_CHAR}${goalId}` } })
    } else {
      navigate(route)
    }
  }

  useEffect(() => {
    const initializeFormValues = async () => {
      if (projectBaseId) {
        const initialValues = initialValuesFactory(projectBaseId)
        setInitialValues(initialValues)
      }
    }
    initializeFormValues()
  }, [id, urqlClient, baseUrl, projectBaseId])

  if (!loading && !canEdit) {
    return <NotAuthorized />
  }

  return (
    <ScreenLayout
      title={pageTitle}
      hasSecondLevelNavigation={false}
      onBack={() => {
        navigate(resolveDetailsRoot(baseUrl).nested.GoalsAndMeasures.params({ projectId }))
      }}
      actions={
        <>
          <CreateButton origin="header" onClick={onSubmit} />
        </>
      }
    >
      <PageLayout>
        <>
          {!loading && initialValues && (
            <>
              <HelpAndInstructions labelKey={`label.help.goals.and.measures.goals.${process}`} defaultExpansion />
              <GoalForm initialValues={initialValues} onSave={onSaveHandler} />
            </>
          )}
        </>
      </PageLayout>
    </ScreenLayout>
  )
}
