import { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router'
import { Mutation_Root, Query_Root } from 'src/@types/graphql'
import { useMessageSource } from 'src/i18n/useMessageSource'
import { ROUTES } from 'src/routing/routes'
import {
  fetchKapProgramImplementationCommentQuery,
  updateKapProgramImplementationCommentMutation,
} from 'src/screens/kap/implementation/summary/kapImplementationSummaryQueries'
import { SummaryCommentEditForm, SummaryCommentFormValues } from 'src/screens/shared/common/SummaryCommentEditForm'
import { usePermissionsForKapProgram } from 'src/service/security/PermissionHook'
import { SaveAndBackButton, SaveButton } from 'src/shared/button/Buttons'
import { PageLayout } from 'src/shared/layout/PageLayout'
import { ScreenLayout } from 'src/shared/layout/ScreenLayout'
import { NotAuthorized } from 'src/shared/not-authorized/NotAuthorized'
import { HelpAndInstructions } from 'src/shared/presentation/HelpAndInstructions'
import { useDelayedNavigate } from 'src/shared/utils/hooks/navigation-hooks'
import { HtmlSanitizer } from 'src/shared/utils/HtmlSanitizer'
import { useNotificationService } from 'src/shared/utils/NotificationService'
import { useClient } from 'urql'

type originType = 'SAVE' | 'SAVE_AND_BACK'

export const KapImplementationSummaryEditPage = () => {
  const { programId } = useParams()
  const navigate = useDelayedNavigate()
  const { getMessage } = useMessageSource()
  const urqlClient = useClient()
  const notificationService = useNotificationService()
  const originRef = useRef<originType>('SAVE')
  const [implementationSummaryComment, setImplementationSummaryComment] = useState<SummaryCommentFormValues>()

  const { loading, canEdit } = usePermissionsForKapProgram(parseInt(programId as string))

  useEffect(() => {
    const fetchData = async () => {
      const { data } = await urqlClient
        .query<{ kap_program_by_pk: Query_Root['kap_program_by_pk'] }>(fetchKapProgramImplementationCommentQuery, {
          programId,
        })
        .toPromise()

      if (data && data.kap_program_by_pk) {
        setImplementationSummaryComment({
          comment: data.kap_program_by_pk.implementation_comment || null,
        })
      } else {
        notificationService.operationFailed()
      }
    }

    fetchData()
  }, [notificationService, programId, urqlClient])

  const onBackHandler = () => {
    const route = ROUTES.KapImplementationDetailsRoot.nested.Summary.params({ programId })
    navigate(`${route}#comments`)
  }

  const onSubmit = (origin: originType) => (_: any) => {
    // trigger submit event
    if (originRef) {
      originRef.current = origin
    }
    document.getElementById('edit-form')?.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }))
  }

  const updateHandler = async (values: SummaryCommentFormValues) => {
    const { error, data } = await urqlClient
      .mutation<{ update_kap_program: Mutation_Root['update_kap_program'] }>(
        updateKapProgramImplementationCommentMutation,
        {
          programId,
          implementationComment: HtmlSanitizer.sanitize(values.comment ?? ''),
        },
      )
      .toPromise()

    if (error || data?.update_kap_program?.affected_rows !== 1) {
      notificationService.operationFailed()
    } else {
      notificationService.changesSaved()
      setImplementationSummaryComment(values)
      originRef.current === 'SAVE_AND_BACK' && onBackHandler()
    }
  }

  if (!loading && !canEdit) {
    return <NotAuthorized inEditPage />
  }

  return (
    <ScreenLayout
      title={getMessage('label.title.edit.comments')}
      onBack={onBackHandler}
      hasSecondLevelNavigation={false}
      actions={
        <>
          <SaveAndBackButton origin="header" onClick={onSubmit('SAVE_AND_BACK')} />
          <SaveButton origin="header" onClick={onSubmit('SAVE')} />
        </>
      }
    >
      <PageLayout>
        <>
          {!loading && implementationSummaryComment && (
            <>
              <HelpAndInstructions labelKey="label.help.kap.implementation.summary.comments" defaultExpansion />
              <SummaryCommentEditForm comment={implementationSummaryComment.comment} onSave={updateHandler} />
            </>
          )}
        </>
      </PageLayout>
    </ScreenLayout>
  )
}
