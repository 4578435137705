import { ReactElement, useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { Mutation_Root, Query_Root } from 'src/@types/graphql'
import { useMessageSource } from 'src/i18n/useMessageSource'
import { resolveDetailsRoot } from 'src/routing/routing-utils'
import { OrganizationForm } from 'src/screens/shared/organization/edit/OrganizationForm'
import { queryOrganizationTypes } from 'src/screens/shared/organization/organizationQueries'
import {
  initialValuesFactory,
  OrganizationFormValues,
  OrganizationType,
} from 'src/screens/shared/organization/utils/OrganizationFormUtils'
import {
  insertPartnerOrganizationMutationQuery,
  insertResponsibleOrganizationMutationQuery,
} from 'src/screens/shared/project/details/basic-information/organization/projectOrganizationQueries'
import { usePermissionsForProject } from 'src/service/security/PermissionHook'
import { CreateButton } from 'src/shared/button/Buttons'
import { PageLayout } from 'src/shared/layout/PageLayout'
import { ScreenLayout } from 'src/shared/layout/ScreenLayout'
import { NotAuthorized } from 'src/shared/not-authorized/NotAuthorized'
import { HelpAndInstructions } from 'src/shared/presentation/HelpAndInstructions'
import { useDelayedNavigate } from 'src/shared/utils/hooks/navigation-hooks'
import { useError } from 'src/shared/utils/hooks/page-loading-error-hook'
import { useNotificationService } from 'src/shared/utils/NotificationService'
import { Utils } from 'src/shared/utils/Utils'
import { useClient } from 'urql'

interface ProjectOrganizationCreatePageProps {
  baseUrl: '/pf-kap' | '/pf-pgv'
}

export const ProjectOrganizationCreatePage = ({ baseUrl }: ProjectOrganizationCreatePageProps): ReactElement => {
  const { projectId, organizationRelationType } = useParams()
  const id = parseInt(projectId as string)
  const { getMessage } = useMessageSource()
  const [initialValues, setInitialValues] = useState<OrganizationFormValues>(initialValuesFactory())
  const [organizationTypeOptions, setOrganizationTypeOptions] = useState<OrganizationType[]>([])
  const navigate = useDelayedNavigate()
  const urqlClient = useClient()
  const notificationService = useNotificationService()
  const pageTitle =
    organizationRelationType === 'partner'
      ? getMessage('label.title.add.partner.organization')
      : getMessage('label.title.add.responsible.organization')
  const process = Utils.resolveProcessToLowerCase(baseUrl)
  const projectType = Utils.resolveProcess(baseUrl)
  const {
    canEdit,
    loading,
    metadata: { projectBaseId },
  } = usePermissionsForProject(projectType, id)

  const setError = useError()

  const onSubmit = () => {
    // trigger submit event
    document
      .getElementById('organization-form')
      ?.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }))
  }

  const onSaveHandler = async (values: OrganizationFormValues) => {
    // insert
    let error

    if (organizationRelationType === 'partner') {
      error = await insertPartnerOrganization(values)
    } else if (organizationRelationType === 'responsible') {
      error = await insertResponsibleOrganization(values)
    }
    if (error) {
      notificationService.operationFailed()
      return Promise.reject()
    } else {
      setInitialValues({ ...values })
      notificationService.changesSaved()
      onBack()
    }
  }

  const insertPartnerOrganization = async (values: OrganizationFormValues) => {
    const { version, ...mutationVars } = values
    const { error } = await urqlClient
      .mutation<{ insert_organization: Mutation_Root['insert_organization'] }>(insertPartnerOrganizationMutationQuery, {
        ...mutationVars,
      })
      .toPromise()

    return error
  }

  const insertResponsibleOrganization = async (values: OrganizationFormValues) => {
    const { version, ...mutationVars } = values

    const { error } = await urqlClient
      .mutation<{ insert_organization: Mutation_Root['insert_organization'] }>(
        insertResponsibleOrganizationMutationQuery,
        { ...mutationVars },
      )
      .toPromise()

    return error
  }

  const onBack = () => {
    navigate(resolveDetailsRoot(baseUrl).nested.BasicInformation.params({ projectId }))
  }

  useEffect(() => {
    const initializeFormValues = async () => {
      const process = Utils.resolveProcess(baseUrl)
      const { data } = await urqlClient
        .query<{ organization_type: Query_Root['organization_type'] }>(queryOrganizationTypes, { process })
        .toPromise()

      if (data && data.organization_type) {
        const organizationTypes = data.organization_type.map((organization_type) => {
          return {
            label: getMessage(organization_type.key),
            value: organization_type.id,
          }
        })
        setOrganizationTypeOptions(organizationTypes)
      } else {
        setError()
      }

      if (projectBaseId) {
        const organization = initialValuesFactory(projectBaseId)
        setInitialValues(organization)
      }
    }

    initializeFormValues()
  }, [getMessage, baseUrl, id, urqlClient, setError, projectBaseId])

  if (!loading && !canEdit) {
    return <NotAuthorized />
  }

  return (
    <ScreenLayout
      title={pageTitle}
      hasSecondLevelNavigation={false}
      onBack={() => {
        navigate(resolveDetailsRoot(baseUrl).nested.BasicInformation.params({ projectId }))
      }}
      actions={
        <>
          <CreateButton origin="header" onClick={onSubmit} />
        </>
      }
    >
      <PageLayout>
        {!loading && initialValues && (
          <>
            <HelpAndInstructions
              labelKey={
                organizationRelationType === 'partner'
                  ? `label.help.basic.information.partner.organizations.${process}`
                  : `label.help.basic.information.responsible.organization.${process}`
              }
              defaultExpansion
            />
            <OrganizationForm
              projectType={projectType}
              initialValues={initialValues}
              organizationTypes={organizationTypeOptions}
              onSave={onSaveHandler}
            />
          </>
        )}
      </PageLayout>
    </ScreenLayout>
  )
}
