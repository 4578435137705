import { AdministrationRoutes } from 'src/routing/AdministrationRoutes'
import { FactsheetRoutes } from 'src/routing/FactsheetRoutes'
import { KapRoutes } from 'src/routing/KapRoutes'
import { PfKapRoutes } from 'src/routing/PfKapRoutes'
import { PfPgvRoutes } from 'src/routing/PfPgvRoutes'
import { ReportingRoutes } from 'src/routing/ReportingRoutes'
import { SuccessFactorsRoutes } from 'src/routing/SuccessFactorsRoutes'
import { HomePage } from 'src/screens/home/index/HomePage'
import { PublicLandingPage } from 'src/screens/public/PublicLandingPage'

const PublicLanding = {
  path: 'pub/landing',
  Component: PublicLandingPage,
} as const

const HomeIndex = {
  path: '',
  Component: HomePage,
  roles: [
    'PD-EXT_USER',
    'PD-EXT_EXPERT',
    'PD-GFCH_PF-KAP_CONTRIBUTOR',
    'PD-GFCH_PF-KAP_COORDINATOR',
    'PD-GFCH_PF-KAP_READER',
    'PD-GFCH_PF-PGV_CONTRIBUTOR',
    'PD-GFCH_PF-PGV_COORDINATOR',
    'PD-GFCH_PF-PGV_READER',
    'PD-GFCH_FACTSHEET_COORDINATOR',
    'PD-GFCH_KAP_CONTRIBUTOR',
    'PD-GFCH_KAP_COORDINATOR',
    'PD-GFCH_KAP_READER',
    'PD-CANTON_KAP_CONTRIBUTOR',
    'PD-CANTON_KAP_READER',
    'PD-SUCCESS_FACTOR_CONTRIBUTOR',
    'PD-GFCH_SUCCESS_FACTOR_COORDINATOR',
  ],
} as const

export const ROUTES = {
  PublicLandingPage: PublicLanding,
  HomeIndex: HomeIndex,
  ...AdministrationRoutes,
  ...FactsheetRoutes,
  ...KapRoutes,
  ...PfKapRoutes,
  ...PfPgvRoutes,
  ...ReportingRoutes,
  ...SuccessFactorsRoutes,
} as const
