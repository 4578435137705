import { ReactElement } from 'react'
import { useParams } from 'react-router'
import { ProjectGoalsAndMeasures } from 'src/screens/shared/project/details/goals-and-measures/ProjectGoalsAndMeasures'
import { queryProjectGoalsAndMeasuresByProcessAndProjectId } from 'src/screens/shared/project/details/goals-and-measures/projectGoalsAndMeasuresQueries'
import { useNotificationService } from 'src/shared/utils/NotificationService'
import { useQuery } from 'urql'

export const PfKapGoalsAndMeasuresPage = (): ReactElement => {
  const { projectId } = useParams()
  const notificationService = useNotificationService()
  const pf_kap_id = parseInt(projectId as string)

  const [{ data, error }, refetch] = useQuery({
    query: queryProjectGoalsAndMeasuresByProcessAndProjectId,
    variables: { projectBaseWhere: { pf_kap_projects: { id: { _eq: pf_kap_id } } } },
  })

  if (error) {
    notificationService.operationFailed()
  }

  const pf_kap_project_goals = data?.project_base?.[0].project_goals
  const pf_kap_project_measures = data?.project_base?.[0].project_measures

  return (
    <>
      <ProjectGoalsAndMeasures
        projectId={pf_kap_id}
        projectGoals={pf_kap_project_goals}
        baseUrl="/pf-kap"
        refetch={refetch}
        projectMeasures={pf_kap_project_measures}
      />
    </>
  )
}
