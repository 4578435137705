export const LIFE_PHASES = {
  GENERAL: 'GENERAL',
  CHILDHOOD_YOUTH: 'CHILDHOOD_YOUTH',
  ADULTHOOD: 'ADULTHOOD',
  SENIORS: 'SENIORS',
} as const

export type LIFE_PHASE_TYPE = keyof typeof LIFE_PHASES

export const TOPICS = {
  ALCOHOL: 'ALCOHOL',
  TOBACCO: 'TOBACCO',
  NUTRITION_AND_EXERCISE: 'NUTRITION_AND_EXERCISE',
  MENTAL_HEALTH: 'MENTAL_HEALTH',
  ADDICTION: 'ADDICTION',
} as const

export type TOPIC_TYPE = keyof typeof TOPICS

export const RATING_STATUS = {
  VERY_BAD: 'VERY_BAD',
  BAD: 'BAD',
  RATHER_BAD: 'RATHER_BAD',
  RATHER_GOOD: 'RATHER_GOOD',
  GOOD: 'GOOD',
  VERY_GOOD: 'VERY_GOOD',
} as const

export const RATING_PRIORITY = {
  LOW_PRIORITY: 'LOW_PRIORITY',
  MEDIUM_PRIORITY: 'MEDIUM_PRIORITY',
  HIGH_PRIORITY: 'HIGH_PRIORITY',
} as const
