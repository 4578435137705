import { Factsheet, Feature_Type_Config, Feature_Value, Kap_Measure, Maybe, Project_Base } from 'src/@types/graphql'

/**
 * Gets the selected features that deviate from the features selected in the related factsheet.
 *
 * @param selectedFeatures features that are selected in the related entity (Project or KAP measure)
 * @param factsheetFeatures features that are selected in the Factsheet
 * @returns an array of invalid feature values
 */
const getInvalidSelectedFeatures = (
  selectedFeatures: Feature_Value[],
  factsheetFeatures: Feature_Value[],
): Feature_Value[] => {
  return selectedFeatures.filter(
    (feature) =>
      factsheetFeatures
        .map((factsheetFeature) => factsheetFeature.feature_config.id)
        .indexOf(feature.feature_config.id) < 0,
  )
}

/**
 * Validates if a feature type has invalid feature selection.
 *
 * @param featureTypeConfig feature type config data for the feature type being validated
 * @param relatedEntity entity related to the feature base
 * @returns true if the feature type is invalid
 */
const isFeatureTypeWithInvalidSelections = (
  featureTypeConfig: Feature_Type_Config,
  relatedEntity: Kap_Measure | Project_Base | undefined,
): boolean => {
  const selectedFeatures = relatedEntity?.feature_base?.feature_values ?? []
  const factsheetFeatures = relatedEntity?.factsheet?.feature_base?.feature_values ?? []
  const hasFactsheetValidation = featureTypeConfig.has_kap_factsheet_validation
  const invalidFeatures = getInvalidSelectedFeatures(selectedFeatures, factsheetFeatures)

  const featureTypesWithInvalidFeatures = invalidFeatures.map((feature) => feature.feature_config.feature_type_id)

  return hasFactsheetValidation && featureTypesWithInvalidFeatures.includes(featureTypeConfig.id)
}

/**
 * Validates a feature selection compared to the feature selection in a factsheet.
 *
 * @param featureTypeConfig feature type config data for the feature type that contains the feature
 * @param relatedFactsheet factsheet related to the feature base
 * @param featureConfigId feature config data for the feature being validated
 * @returns true if the feature is invalid
 */
const isFeatureSelectionInvalid = (
  featureTypeConfig: Feature_Type_Config | undefined,
  relatedFactsheet: Maybe<Factsheet> | undefined,
  featureConfigId: number,
): boolean => {
  const hasFactsheetValidation = featureTypeConfig?.has_kap_factsheet_validation ?? false
  const factsheetFeatures = relatedFactsheet?.feature_base?.feature_values ?? []

  const featureIsValid = factsheetFeatures
    ?.map((featureValue) => featureValue.feature_config.id)
    .includes(featureConfigId)

  return hasFactsheetValidation && !featureIsValid
}

export { getInvalidSelectedFeatures, isFeatureTypeWithInvalidSelections, isFeatureSelectionInvalid }
