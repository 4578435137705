import { IconButton, Typography } from '@mui/material'
import { Fragment, ReactElement, useState } from 'react'
import { Link } from 'react-router-dom'
import { Mutation_Root, Project_Base } from 'src/@types/graphql'
import { useMessageSource } from 'src/i18n/useMessageSource'
import { resolveDetailsRoot } from 'src/routing/routing-utils'
import { deletePartnerOrganizationMutationQuery } from 'src/screens/shared/project/details/basic-information/organization/projectOrganizationQueries'
import { AddButton, EditButton } from 'src/shared/button/Buttons'
import { DeleteIcon, EditIcon } from 'src/shared/icons/Icons'
import { ConfirmationModalDialog } from 'src/shared/modal-dialog/ConfirmationModalDialog'
import { HelpAndInstructions } from 'src/shared/presentation/HelpAndInstructions'
import { Section } from 'src/shared/presentation/Section'
import { S } from 'src/shared/styled/S'
import { useDelayedNavigate } from 'src/shared/utils/hooks/navigation-hooks'
import { useNotificationService } from 'src/shared/utils/NotificationService'
import { Utils } from 'src/shared/utils/Utils'
import { useClient } from 'urql'

interface ProjectResponsibleAndPartnerOrganizationsProps {
  projectId: number
  baseUrl: '/pf-kap' | '/pf-pgv'
  projectBase: Project_Base
  refetch: () => void
  canEdit: boolean
}

export const ProjectResponsibleAndPartnerOrganizations = ({
  projectId,
  baseUrl,
  projectBase,
  canEdit,
  refetch,
}: ProjectResponsibleAndPartnerOrganizationsProps): ReactElement => {
  const [organizationDeleteConfirmationOpen, setOrganizationDeleteConfirmationOpen] = useState(false)
  const [organizationIdToBeDeleted, setOrganizationIdToBeDeleted] = useState<any>(null)

  const { getMessage } = useMessageSource()
  const navigate = useDelayedNavigate()
  const urqlClient = useClient()
  const notificationService = useNotificationService()
  const process = Utils.resolveProcessToLowerCase(baseUrl)

  const handleAddPartnerButtonClick = () => {
    navigate(
      resolveDetailsRoot(baseUrl).nested.OrganizationNew.params({
        projectId,
        organizationRelationType: 'partner',
      }),
    )
  }

  const handleEditResponsibleOrgButtonClick = () => {
    if (projectBase.organization !== null) {
      navigate(
        resolveDetailsRoot(baseUrl).nested.OrganizationEdit.params({
          projectId,
          organizationId: projectBase.organization!.id,
          organizationRelationType: 'responsible',
        }),
      )
    } else {
      navigate(
        resolveDetailsRoot(baseUrl).nested.OrganizationNew.params({
          projectId,
          organizationRelationType: 'responsible',
        }),
      )
    }
  }

  const handlePartnerCardClick = (projectId: number, organizationId: number) => () => {
    navigate(
      resolveDetailsRoot(baseUrl).nested.Organization.params({
        projectId,
        organizationId,
        organizationRelationType: 'partner',
      }),
    )
  }
  const handleResponsibleCardClick = (projectId: number, organizationId: number) => () => {
    navigate(
      resolveDetailsRoot(baseUrl).nested.Organization.params({
        projectId,
        organizationId,
        organizationRelationType: 'responsible',
      }),
    )
  }

  const handleDeleteOrganization = (organizationId: number) => () => {
    setOrganizationIdToBeDeleted(organizationId)
    setOrganizationDeleteConfirmationOpen(true)
  }
  const handleCancelDeleteOrganization = () => {
    setOrganizationDeleteConfirmationOpen(false)
  }
  const handleDeleteOrganizationConfirmed = async () => {
    setOrganizationDeleteConfirmationOpen(false)
    const { error } = await urqlClient
      .mutation<{
        delete_project_organization: Mutation_Root['delete_project_organization']
        delete_organization_by_pk: Mutation_Root['delete_organization_by_pk']
      }>(deletePartnerOrganizationMutationQuery, {
        project_base_id: projectBase.id,
        organization_id: organizationIdToBeDeleted,
      })
      .toPromise()
    if (error) {
      notificationService.operationFailed()
    } else {
      refetch()
      notificationService.deleteSuccessful()
    }
  }

  return (
    <>
      <Section
        id="responsible-organization"
        title={getMessage('label.responsible.organization')}
        actionButton={<EditButton hidden={!canEdit} onClick={handleEditResponsibleOrgButtonClick} />}
        helpAndInstructions={
          <HelpAndInstructions labelKey={`label.help.basic.information.responsible.organization.${process}`} />
        }
      >
        {projectBase.organization && (
          <S.Card.Container key={projectBase.organization.id}>
            <S.Card.Content
              onClick={handleResponsibleCardClick(projectId, projectBase.organization!.id)}
              tabIndex={0}
              onKeyDown={(event: { key: string }) => {
                if (['Enter', ' '].includes(event.key)) {
                  handleResponsibleCardClick(projectId, projectBase.organization!.id)()
                }
              }}
            >
              <Typography variant="subtitle2">{projectBase.organization.name}</Typography>
            </S.Card.Content>
          </S.Card.Container>
        )}
      </Section>

      <Section
        id="partner-organizations"
        title={getMessage('label.partners')}
        actionButton={
          <AddButton hidden={!canEdit} messageKey="button.add.partner" onClick={handleAddPartnerButtonClick} />
        }
        helpAndInstructions={
          <HelpAndInstructions labelKey={`label.help.basic.information.partner.organizations.${process}`} />
        }
      >
        {projectBase.project_organizations.length > 0 &&
          projectBase.project_organizations.map((value) => {
            return (
              <Fragment key={value.organization_id}>
                <S.Card.Container key={value.id}>
                  <S.Card.Content
                    onClick={handlePartnerCardClick(projectId, value.organization_id)}
                    tabIndex={0}
                    onKeyDown={(event: { key: string }) => {
                      if (['Enter', ' '].includes(event.key)) {
                        handlePartnerCardClick(projectId, value.organization_id)()
                      }
                    }}
                  >
                    <Typography variant="subtitle2">{value.organization.name}</Typography>
                  </S.Card.Content>
                  <S.Card.Actions>
                    {canEdit && (
                      <>
                        <Link
                          tabIndex={-1}
                          to={`${baseUrl}/project/${projectId}/details/organization/${value.organization_id}/edit/partner`}
                        >
                          <IconButton color="primary" size="large">
                            <EditIcon />
                          </IconButton>
                        </Link>
                        <IconButton
                          color="primary"
                          onClick={handleDeleteOrganization(value.organization_id)}
                          size="large"
                        >
                          <DeleteIcon />
                        </IconButton>
                      </>
                    )}
                  </S.Card.Actions>
                </S.Card.Container>
              </Fragment>
            )
          })}
      </Section>

      <ConfirmationModalDialog
        open={organizationDeleteConfirmationOpen}
        onCancel={handleCancelDeleteOrganization}
        onConfirm={handleDeleteOrganizationConfirmed}
      >
        {getMessage('label.delete.confirm')}
      </ConfirmationModalDialog>
    </>
  )
}
