import { Fragment, ReactElement } from 'react'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import { RestrictedRoute } from 'src/routing/RestrictedRoute'
import { ROUTES } from 'src/routing/routes'
import { Routes as AppRoutes } from 'src/routing/routing'
import { DirtyContextProvider } from 'src/shared/form/dirty/DirtyContext'
import { Layout } from 'src/shared/layout/Layout'

export const AllRoutes = (): ReactElement => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="*" element={<Layout />}>
          {Object.values(ROUTES as unknown as AppRoutes).map(({ path, roles, Component, nested }) =>
            roles ? (
              <Route
                path={path}
                key={path}
                element={
                  <DirtyContextProvider>
                    <RestrictedRoute key={path} path={path} component={Component} roles={roles} />
                  </DirtyContextProvider>
                }
              >
                {nested &&
                  Object.keys(nested).map((key) => {
                    const child = nested[key]
                    const { path: nestedPath, Component, primary } = child
                    return (
                      <Fragment key={nestedPath}>
                        <Route
                          path={nestedPath}
                          element={
                            <DirtyContextProvider>
                              <Component key={nestedPath} path={nestedPath} />
                            </DirtyContextProvider>
                          }
                        />
                        {primary && (
                          <Route
                            path="*"
                            element={
                              <DirtyContextProvider>
                                <Component key={nestedPath} path={nestedPath} />
                              </DirtyContextProvider>
                            }
                          />
                        )}
                      </Fragment>
                    )
                  })}
              </Route>
            ) : (
              <Route
                path={path}
                key={path}
                element={
                  <DirtyContextProvider>
                    <Component key={path} path={path} />
                  </DirtyContextProvider>
                }
              />
            ),
          )}
          <Route element={<Navigate to="/" replace={true} />} path="*" />
        </Route>
      </Routes>
    </BrowserRouter>
  )
}
