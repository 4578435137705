import { Card, CardContent } from '@mui/material'
import { useParams } from 'react-router'
import { Query_Root } from 'src/@types/graphql'
import { useMessageSource } from 'src/i18n/useMessageSource'
import { queryOrganizationById } from 'src/screens/shared/organization/organizationQueries'
import { EditButton } from 'src/shared/button/Buttons'
import { PageLayout } from 'src/shared/layout/PageLayout'
import { ScreenLayout } from 'src/shared/layout/ScreenLayout'
import { HelpAndInstructions } from 'src/shared/presentation/HelpAndInstructions'
import { ReadOnlyTextField } from 'src/shared/presentation/ReadOnly'
import { useQuery } from 'urql'

interface Props {
  pageTitle: string
  helpAndInstructionsTitle: string
  canEdit: boolean
  onBack: () => void
  onEdit: () => void
}

export const BaseOrganizationView = ({ pageTitle, helpAndInstructionsTitle, canEdit, onEdit, onBack }: Props) => {
  const { organizationId } = useParams()
  const organization_id = parseInt(organizationId as string)

  const { getMessage } = useMessageSource()

  const [{ data }] = useQuery<{ organization_by_pk: Query_Root['organization_by_pk'] }, { id: number }>({
    query: queryOrganizationById,
    variables: { id: organization_id },
  })

  const organization = data?.organization_by_pk

  return (
    <ScreenLayout
      title={pageTitle}
      hasSecondLevelNavigation={false}
      onBack={onBack}
      actions={
        <>
          {canEdit && (
            <>
              <EditButton origin="header" onClick={onEdit} />
            </>
          )}
        </>
      }
    >
      <PageLayout>
        <>
          {organization && (
            <>
              <HelpAndInstructions labelKey={helpAndInstructionsTitle} />
              <Card>
                <CardContent>
                  <ReadOnlyTextField text={getMessage('label.organization.name')}>
                    {organization.name}
                  </ReadOnlyTextField>
                  <ReadOnlyTextField text={getMessage('label.organization.type')}>
                    {organization.organization_type && getMessage(organization.organization_type.key)}
                  </ReadOnlyTextField>
                  <ReadOnlyTextField text={getMessage('label.organization.department')}>
                    {organization.department}
                  </ReadOnlyTextField>
                  <ReadOnlyTextField text={getMessage('label.organization.address')}>
                    {organization.address}
                  </ReadOnlyTextField>
                  <ReadOnlyTextField text={getMessage('label.organization.additional.address')}>
                    {organization.additional_address}
                  </ReadOnlyTextField>
                  <ReadOnlyTextField text={getMessage('label.organization.postal.code')}>
                    {organization.postal_code}
                  </ReadOnlyTextField>
                  <ReadOnlyTextField text={getMessage('label.organization.city')}>
                    {organization.city}
                  </ReadOnlyTextField>
                  <ReadOnlyTextField text={getMessage('label.organization.phone')}>
                    {organization.phone}
                  </ReadOnlyTextField>
                  <ReadOnlyTextField text={getMessage('label.email')}>{organization.email}</ReadOnlyTextField>
                  <ReadOnlyTextField text={getMessage('label.organization.website')} isLast={true}>
                    {organization.website}
                  </ReadOnlyTextField>
                </CardContent>
              </Card>
            </>
          )}
        </>
      </PageLayout>
    </ScreenLayout>
  )
}
