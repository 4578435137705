import { Box, Tooltip } from '@mui/material'
import { DataGridPro, GridColDef, GridRenderEditCellParams, GridRowId, useGridApiRef } from '@mui/x-data-grid-pro'
import combinedQuery from 'graphql-combine-query'
import { groupBy, sortBy, sumBy } from 'lodash'
import { ReactElement, useEffect, useMemo, useState } from 'react'
import { Form } from 'react-final-form'
import { InternationalizedObject } from 'src/@types'
import {
  Feature_Config_Bool_Exp,
  Feature_Value,
  Mutation_Root,
  Project_Base_Bool_Exp,
  Query_Root,
} from 'src/@types/graphql'
import { useMessageSource } from 'src/i18n/useMessageSource'
import { usePrompt } from 'src/routing/routing-hooks'
import {
  bulkSaveProjectFeatureKpis,
  queryFeatureBaseValuesWithKPIs,
  queryFeatureConfigs,
} from 'src/screens/shared/feature/featureBaseQueries'
import { queryFeatureKPIAnnualReportByProject } from 'src/screens/shared/implementation/details/milestoneQueries'
import { PROJECT } from 'src/shared/constants/constants'
import { NumberInput } from 'src/shared/form/grid/NumberInput'
import { EditableColumnHeader } from 'src/shared/grid/EditableColumnHeader'
import { DefaultSectionTypography } from 'src/shared/presentation/DefaultSectionTypography'
import { HelpAndInstructions } from 'src/shared/presentation/HelpAndInstructions'
import { S } from 'src/shared/styled/S'
import { DataGridUtils } from 'src/shared/utils/DataGridUtils'
import { useGridTranslateHook } from 'src/shared/utils/hooks/grid-translate-hook'
import { useError } from 'src/shared/utils/hooks/page-loading-error-hook'
import { useDelayedSubmit } from 'src/shared/utils/hooks/submit-hook'
import { useNotificationService } from 'src/shared/utils/NotificationService'
import { Utils } from 'src/shared/utils/Utils'
import { useUserLocale } from 'src/user/UserContext'
import { useClient } from 'urql'

export interface RowData {
  id: number
  featureType: InternationalizedObject
  feature: InternationalizedObject
  target?: number | null
  actualValue: string | number
  tooltip: InternationalizedObject
  // not displayed as columns
  sortNumber: number
  featureTypeSortNumber: number
}

interface Props {
  targetEditable: boolean
  projectId: number
  baseUrl: '/pf-kap' | '/pf-pgv'
  onSave?: () => void
  expandedHelpSection?: boolean
  mode?: 'view' | 'edit'
}

export const ProjectFeatureKpiGrid = ({
  targetEditable,
  projectId,
  onSave,
  baseUrl,
  expandedHelpSection,
  mode = 'view',
}: Props): ReactElement => {
  const { getMessage } = useMessageSource()
  const gridTranslations = useGridTranslateHook()
  const urqlClient = useClient()
  const language = useUserLocale()
  const apiRef = useGridApiRef()
  const notificationService = useNotificationService()
  const process = Utils.resolveProcessToLowerCase(baseUrl)
  const projectType = Utils.resolveProcess(baseUrl)
  const setError = useError()
  const submit = useDelayedSubmit()

  const [dirty, setDirty] = useState<boolean>(false)
  const [featureKpis, setFeatureKpis] = useState<(Feature_Value & { actual_value: number })[]>([])

  const kpiRowsData = useMemo(() => {
    const currentRowModels = apiRef.current?.getRowModels?.() ?? new Map()
    const kpisUnsorted = featureKpis.reduce(
      (a, c) => [
        ...a,
        {
          id: c!.id,
          featureType: c.feature_config!.feature_type_config.names as InternationalizedObject,
          feature: c.feature_config!.names as InternationalizedObject,
          otherDescription: c.other_description,
          tooltip: c.feature_config!.tooltips as InternationalizedObject,
          // resolve by priority (guards against changing the user locale while preserving the grid values)
          // -> first is whatever is in the current row models (if there is any)
          // -> then resolve from the DB models
          // -> if there is no such record -> initialize to null
          target: currentRowModels.get(c!.id)?.target ?? c.feature_kpi?.target ?? null,
          actualValue: c?.actual_value ?? '-',
          featureTypeSortNumber: c.feature_config.feature_type_config.sort_number,
          sortNumber: c.feature_config.sort_number,
        } as RowData,
      ],
      [] as RowData[],
    )

    return sortBy(kpisUnsorted, ['featureTypeSortNumber', 'sortNumber'])
  }, [apiRef, featureKpis])

  useEffect(() => {
    const pfKapQuery = {
      pf_kap_projects: {
        id: {
          _eq: projectId,
        },
      },
    }

    const pfPgvQuery = {
      pf_pgv_projects: {
        id: {
          _eq: projectId,
        },
      },
    }

    const projectBaseQuery: Project_Base_Bool_Exp = {
      ...(projectType === PROJECT.PF_KAP && pfKapQuery),
      ...(projectType === PROJECT.PF_PGV && pfPgvQuery),
    }

    const featureConfigWhere: Feature_Config_Bool_Exp = {
      processes: {
        _contains: [projectType],
      },
    }

    const featureConfigKPIWhere: Feature_Config_Bool_Exp = {
      has_kpi_for_processes: {
        _contains: [projectType],
      },
    }

    const fetchData = async () => {
      const { document, variables } = combinedQuery('FeatureValuesAndFeatureAnnualReports')
        .add<
          { feature_base: Query_Root['feature_base'] },
          {
            projectBaseQuery: Project_Base_Bool_Exp
            featureConfigKPIWhere: Feature_Config_Bool_Exp
          }
        >(queryFeatureBaseValuesWithKPIs, { projectBaseQuery, featureConfigKPIWhere })
        .add<
          {
            feature_kpi_all_reports: Query_Root['feature_kpi_annual_report']
          },
          {
            projectBaseWhere: Project_Base_Bool_Exp
          }
        >(queryFeatureKPIAnnualReportByProject, {
          projectBaseWhere: projectBaseQuery,
        })
        .add<
          {
            feature_config: Query_Root['feature_config']
          },
          {
            featureConfigWhere: Feature_Config_Bool_Exp
          }
        >(queryFeatureConfigs, {
          featureConfigWhere: featureConfigWhere,
        })

      const { data, error } = await urqlClient
        .query<
          {
            feature_base: Query_Root['feature_base']
            feature_kpi_all_reports: Query_Root['feature_kpi_annual_report']
            feature_config: Query_Root['feature_config']
          },
          {
            projectBaseQuery: Project_Base_Bool_Exp
            projectBaseWhere: Project_Base_Bool_Exp
            featureConfigWhere: Feature_Config_Bool_Exp
          }
        >(document, variables!)
        .toPromise()

      if (data) {
        const featureConfigByIds = groupBy(data?.feature_kpi_all_reports, 'feature_config_id')
        let features = data.feature_base[0].feature_values
        const reports = data.feature_kpi_all_reports
        const existingFeatures = data.feature_config
          .filter(
            (config) =>
              reports.some((report) => report.feature_config_id === config.id) &&
              !features.some((feature) => feature.feature_config_id === config.id),
          )
          .map((f, i) => ({
            id: i,
            feature_config_id: f.id,
            feature_config: f,
          })) as Feature_Value[]

        if (mode === 'view') {
          features = features.concat(existingFeatures)
          features = Utils.mergeBy(
            { arr: features, key: 'feature_config_id' },
            { arr: reports, key: 'feature_config_id' },
          )
        }

        const featureKpisWithActualValues = features.map((f) => ({
          ...f,
          actual_value: sumBy(featureConfigByIds[f.feature_config_id], 'annual_value'),
        }))
        setFeatureKpis(featureKpisWithActualValues)
      } else if (error) {
        setError()
      }
    }
    fetchData()
  }, [projectId, urqlClient, setError, projectType, mode])

  const columns = useMemo(() => {
    const onRifmChange = (api: GridRenderEditCellParams['api'], id: GridRowId, field: string, value: number) => {
      api.setEditCellValue({ id, field, value })
      setDirty(true)
    }

    return [
      {
        field: 'featureType',
        headerName: getMessage('label.feature.type'),
        sortable: false,
        disableColumnMenu: true,
        flex: 0.6,
        disableColumnResize: true,
        valueFormatter: (params) => {
          const value = params.value as InternationalizedObject
          return value?.[language]
        },
      },
      {
        field: 'feature',
        headerName: getMessage('label.feature'),
        sortable: false,
        disableColumnMenu: true,
        flex: 1,
        renderCell: (params) => {
          let featureName = ''
          const value = params.value as InternationalizedObject
          if (value) {
            // for "Other" features display also the corresponding description
            featureName = params.row.otherDescription
              ? `${value[language]}: ${params.row.otherDescription}`
              : value[language]
          }
          return (
            <>
              <S.Span.TextOverflow $withTooltip>{value && featureName}</S.Span.TextOverflow>
              {params.row.tooltip[language] && (
                <Tooltip placement="right" title={params.row.tooltip[language]}>
                  <S.Icons.GridTooltipInfo />
                </Tooltip>
              )}
            </>
          )
        },
      },
      {
        field: 'target',
        headerAlign: 'right',
        editable: targetEditable,
        sortable: false,
        align: 'right',
        disableColumnMenu: true,
        flex: 0.3,
        renderCell: ({ value }) => {
          if (value === null || value === '' || Number.isNaN(value)) {
            return <DefaultSectionTypography $standAlone />
          }
          return <S.Span.TextOverflow>{value?.toLocaleString('de-CH')}</S.Span.TextOverflow>
        },
        renderEditCell: (params) => {
          const { value, id, field, api } = params
          const isValid = DataGridUtils.validateValue(value)
          return (
            <NumberInput
              value={value as number}
              onChange={(val: number) => onRifmChange(api, id, field, val)}
              error={!isValid}
            />
          )
        },
        renderHeader: () => <EditableColumnHeader headerName={getMessage('label.target')} editMode={targetEditable} />,
      },
      {
        field: 'actualValue',
        headerName: getMessage('label.actual.value'),
        headerAlign: 'right',
        sortable: false,
        align: 'right',
        disableColumnMenu: true,
        cellClassName: 'kpi-actual-value--cell',
        flex: 0.3,
        renderCell: ({ value }) => {
          return <S.Span.TextOverflow>{value?.toLocaleString('de-CH')}</S.Span.TextOverflow>
        },
      },
    ] as GridColDef[]
  }, [getMessage, language, targetEditable])

  usePrompt(getMessage('notification.form.unsaved.changes'), dirty)

  const onSaveLocal = async () => {
    const rows = apiRef.current.getRowModels()
    const toSave = [...rows].map(([k, v]) => ({
      feature_value_id: k,
      target: v.target === '' || v.target === null || Number.isNaN(v.target) ? null : v.target,
    }))

    const { data, error } = await urqlClient
      .mutation<{
        insert_feature_kpi: Mutation_Root['insert_feature_kpi']
      }>(bulkSaveProjectFeatureKpis, {
        featureKpis: toSave,
      })
      .toPromise()
    if (error || data?.insert_feature_kpi?.affected_rows === 0) {
      notificationService.operationFailed()
    } else {
      if (onSave) {
        notificationService.changesSaved()
        setDirty(false)
        onSave()
      }
    }
  }

  return (
    <Form
      onSubmit={submit(onSaveLocal)}
      render={({ handleSubmit }) => (
        <form
          id="edit-form"
          onSubmit={handleSubmit}
          noValidate
          onKeyPress={(event) => {
            if (event.key === 'Enter') {
              event.preventDefault()
            }
          }}
        >
          <Box>
            <HelpAndInstructions
              labelKey={`label.help.and.instructions.kpis.${process}`}
              defaultExpansion={expandedHelpSection}
            />
            <DataGridPro
              rows={kpiRowsData}
              columns={columns}
              autoHeight
              hideFooter
              apiRef={apiRef}
              onCellEditStop={() => setDirty(true)}
              localeText={gridTranslations}
              disableColumnReorder
              disableRowSelectionOnClick
              disableColumnSelector
            />
          </Box>
        </form>
      )}
    />
  )
}
