import { CssBaseline } from '@mui/material'
import { createTheme, ThemeProvider as MuiThemeProvider } from '@mui/material/styles'
import { LicenseInfo } from '@mui/x-data-grid-pro'
import { ReactElement } from 'react'
import { EnvironmentLoader } from 'src/env/EnvironmentLoader'
import { MessageSourceLoader } from 'src/i18n/MessageSourceLoader'
import { AllRoutes } from 'src/routing/AllRoutes'
import { GraphQLClientProvider } from 'src/service/graphql/GraphQLClientProvider'
import { HelpAndInstructionLinksContextProvider } from 'src/service/help-and-instruction-links/HelpAndInstructionLinksContext'
import { SidebarAwareContextProvider } from 'src/shared/sidebar/SidebarAwareContext'
import { Toaster } from 'src/shared/toaster/Toaster'
import { mapUserLanguageToMuiLocale, muiTheme } from 'src/theme/theme'
import { UserContext, UserContextProvider } from 'src/user/UserContext'

LicenseInfo.setLicenseKey(import.meta.env.VITE_MATERIAL_UI_LICENSE_KEY)
export const App = (): ReactElement => {
  return (
    <EnvironmentLoader>
      <Toaster />
      <GraphQLClientProvider>
        <UserContextProvider>
          <UserContext.Consumer>
            {({ user: { language } }) => (
              <MuiThemeProvider theme={createTheme(muiTheme, mapUserLanguageToMuiLocale(language))}>
                <CssBaseline />
                <MessageSourceLoader>
                  <HelpAndInstructionLinksContextProvider>
                    <SidebarAwareContextProvider>
                      <AllRoutes />
                    </SidebarAwareContextProvider>
                  </HelpAndInstructionLinksContextProvider>
                </MessageSourceLoader>
              </MuiThemeProvider>
            )}
          </UserContext.Consumer>
        </UserContextProvider>
      </GraphQLClientProvider>
    </EnvironmentLoader>
  )
}
