import { gql } from '@urql/core'

export const insertProjectGoal = gql`
  mutation (
    $name: String
    $description: String
    $projectBaseId: Int
    $measuresToBeAdded: [project_goal_measure_insert_input!]!
  ) {
    insert_project_goal(
      objects: {
        name: $name
        description: $description
        project_base_id: $projectBaseId
        project_goal_measures: { data: $measuresToBeAdded }
      }
    ) {
      affected_rows
      returning {
        id
      }
    }
  }
`

export const queryProjectGoalById = gql`
  query ($id: Int!) {
    project_goal_by_pk(id: $id) {
      id
      name
      description
      sort_number
      project_base_id
      version
      project_goal_measures {
        project_measure {
          id
          name
          sort_number
        }
      }
      project_base {
        project_measures {
          id
          name
        }
      }
    }
  }
`

export const updateGoal = gql`
  mutation (
    $id: Int!
    $name: String
    $description: String
    $version: Int!
    $measuresToBeAdded: [project_goal_measure_insert_input!]!
  ) {
    update_project_goal(
      _set: { name: $name, description: $description }
      where: { _and: [{ id: { _eq: $id } }, { version: { _eq: $version } }] }
    ) {
      affected_rows
      returning {
        version
      }
    }
    delete_project_goal_measure(where: { project_goal_id: { _eq: $id } }) {
      affected_rows
    }
    insert_project_goal_measure(objects: $measuresToBeAdded) {
      affected_rows
    }
  }
`

export const updateGoalSortNumbers = gql`
  mutation ($goal1Id: Int!, $goal1SortNumber: Int!, $goal2Id: Int!, $goal2SortNumber: Int!) {
    goal1: update_project_goal(where: { id: { _eq: $goal1Id } }, _set: { sort_number: $goal1SortNumber }) {
      affected_rows
    }
    goal2: update_project_goal(where: { id: { _eq: $goal2Id } }, _set: { sort_number: $goal2SortNumber }) {
      affected_rows
    }
  }
`

export const deleteProjectGoalByPk = gql`
  mutation ($goal_id: Int!) {
    delete_project_goal_by_pk(id: $goal_id) {
      id
    }
  }
`

export const queryMeasuresByProject = gql`
  query ($projectBaseId: Int!) {
    project_base(where: { id: { _eq: $projectBaseId } }) {
      project_measures {
        id
        name
        sort_number
      }
    }
  }
`
