import { ReactElement } from 'react'
import { useParams } from 'react-router'
import { ProjectImplementationSummary } from 'src/screens/shared/implementation/details/summary/ProjectImplementationSummary'

export const PfKapImplementationDetailsSummaryPage = (): ReactElement => {
  const { projectId } = useParams()
  const pfKapId = parseInt(projectId as string)

  return <ProjectImplementationSummary baseUrl="/pf-kap" projectId={pfKapId} />
}
