import { TEXT_OVERFLOW_MIXIN } from 'src/shared/constants/styling-constants'
import styled from 'styled-components/macro'

export const TwoLineCellStyled = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-width: 0; // needed for a flex container to constrain the width of the children so text-overflow can work
  & .MuiTypography-root {
    ${TEXT_OVERFLOW_MIXIN};
  }
`
