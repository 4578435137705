import { AxiosResponse } from 'axios'
import { valueof } from 'src/@types/global'
import { Axios } from 'src/service/axios/axios'
import { ENTITY_TYPE, FILE_TYPE } from 'src/shared/constants/constants'

export const FileService = {
  upload: (
    file: Blob,
    fileType: valueof<typeof FILE_TYPE>,
    entityType: valueof<typeof ENTITY_TYPE>,
    entityId: string | number,
    fileTitle: string,
  ): Promise<AxiosResponse> => {
    const formData = new FormData()
    formData.append('uploadFile', file)
    formData.append('fileType', fileType)
    formData.append('entityType', entityType)
    formData.append('entityId', `${entityId}`)
    formData.append('fileTitle', fileTitle)

    return Axios.getAxios()
      .post('/api/file', formData)
      .then((response) => response.data)
  },

  remove: (fileUUID: string): Promise<AxiosResponse> => {
    return Axios.getAxios()
      .delete(`/api/file/${fileUUID}`)
      .then((response) => response.data)
  },
}
