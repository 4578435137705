import { Box, Button, Card, CardContent, Container, Divider, Stack, Typography } from '@mui/material'
import { ReactElement } from 'react'
import { ReactComponent as LogoBAG } from 'src/assets/BAG-logo.svg'
import { ReactComponent as LogoGDK } from 'src/assets/GDK-logo.svg'
import { ReactComponent as LogoGFS } from 'src/assets/logo-gfs.svg'
import sectionImage from 'src/assets/success-factor-tool-kids.jpg'
import headerImage from 'src/assets/success-factors-public-header-illustration.svg'
import { ReactComponent as LogoTPF } from 'src/assets/TPF-logo.svg'
import { useMessageSource } from 'src/i18n/useMessageSource'
import { ROUTES } from 'src/routing/routes'
import { PrimaryButton } from 'src/shared/button/Buttons'
import { Footer } from 'src/shared/footer/Footer'
import { ArrowForwardIcon } from 'src/shared/icons/Icons'
import { HtmlRenderer } from 'src/shared/presentation/HtmlRenderer'
import { Section } from 'src/shared/presentation/Section'
import { useDelayedNavigate, useNavigateToLogin } from 'src/shared/utils/hooks/navigation-hooks'
import { Utils } from 'src/shared/utils/Utils'
import { useUserLocale } from 'src/user/UserContext'
import styled from 'styled-components/macro'

const HeaderSectionStyled = styled(Box)`
  background: radial-gradient(89.91% 143.86% at 46.42% 24.08%, #ffffff 0%, #eef0f0 56.9%, #e6e6e6 100%);
  height: 37.5rem;
  border-bottom: 1px solid ${({ theme }) => theme.colors.grey.divider};

  .MuiContainer-maxWidthLg {
    background-image: url(${headerImage});
    background-repeat: no-repeat;
    background-position: left bottom;
    display: flex;
    align-items: center;
    justify-content: end;
    height: 100%;
  }
`

const HeaderTextBoxStyled = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(3)};
  max-width: 26rem;

  p {
    color: ${({ theme }) => theme.colors.text.secondary};
  }
`

const ButtonGroupStyled = styled(Box)`
  display: flex;
  gap: ${({ theme }) => theme.spacing(1)};
  margin-top: ${({ theme }) => theme.spacing(2)};
`

const PageContentStyled = styled(Container)`
  margin-top: ${({ theme }) => theme.spacing(10)};
  margin-bottom: ${({ theme }) => theme.spacing(12)};

  & > div {
    margin-bottom: ${({ theme }) => theme.spacing(7)};
  }

  & > div > div:first-child {
    margin-bottom: ${({ theme }) => theme.spacing(2)};
  }
`

const CardContentStyled = styled(CardContent)`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(5)};
  align-items: flex-start;
`

const BoxWithMediaStyled = styled(Box)`
  display: grid;
  grid-gap: ${({ theme }) => theme.spacing(2)};
  grid-template-columns: 1fr 1fr;

  .iframe-container {
    position: relative;
    width: 100%;
    overflow: hidden;
  }

  iframe {
    width: 100%;
    height: 100%;
    border: none;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`

const TextBoxStyled = styled(Box)`
  h3 {
    margin-bottom: ${({ theme }) => theme.spacing(2)};
  }

  p {
    color: ${({ theme }) => theme.colors.text.secondary};
  }
`

const PartnersCardContentStyled = styled(CardContent)`
  a {
    min-height: 4rem;
    transition: ${({ theme }) =>
      theme.transitions.create('opacity', { duration: theme.transitions.duration.standard })};
    flex-grow: 1;

    &:hover {
      opacity: 0.6;
    }
  }

  svg {
    height: 100%;
    width: 100%;
  }

  #gfs-logo-link {
    flex-grow: 0;

    svg {
      height: 2rem;
      width: auto;
    }
  }
`

const LogoWrapper = styled(Box)`
  height: 66px;
`

export const SuccessFactorsPublicLandingPage = (): ReactElement => {
  const { getMessage } = useMessageSource()
  const locale = useUserLocale()
  const login = useNavigateToLogin()
  const navigate = useDelayedNavigate()

  const handleViewAllSuccessFactors = () => {
    navigate(`/${ROUTES.SuccessFactorsPublicOverview.path}`)
  }

  const resolveGfsWebsiteLink = () => {
    if (locale === 'fr') {
      return 'https://promotionsante.ch/'
    } else if (locale === 'it') {
      return 'https://promozionesalute.ch/'
    } else return 'https://gesundheitsfoerderung.ch/'
  }

  return (
    <>
      <HeaderSectionStyled>
        <Container maxWidth="lg">
          <HeaderTextBoxStyled>
            <Typography variant="display">{getMessage('label.success.factors.public.landing.page.title')}</Typography>
            <Typography>{getMessage('label.success.factors.public.landing.page.main.description')}</Typography>
            <ButtonGroupStyled>
              <PrimaryButton
                onClick={handleViewAllSuccessFactors}
                messageKey={getMessage('button.view.all.success.factors')}
              />
              <Button variant="outlined" onClick={login}>
                {getMessage('button.login')}
              </Button>
            </ButtonGroupStyled>
          </HeaderTextBoxStyled>
        </Container>
      </HeaderSectionStyled>
      <PageContentStyled maxWidth="lg">
        <Section title={getMessage('label.success.factors.public.section.partners.title')}>
          <Card>
            <PartnersCardContentStyled>
              <Stack direction="row" divider={<Divider orientation="vertical" flexItem />} spacing={2} flexGrow={1}>
                <a href={`https://www.gdk-cds.ch/${locale}/`} target="_blank">
                  <LogoWrapper>
                    <LogoGDK width="6rem" />
                  </LogoWrapper>
                </a>
                <a href={`https://www.bag.admin.ch/bag/${locale}/home.html`} target="_blank">
                  <LogoWrapper>
                    <LogoBAG width="8rem" />
                  </LogoWrapper>
                </a>
                <a href={`https://www.edi.admin.ch/edi/${locale}/home.html`} target="_blank">
                  <LogoWrapper>
                    <LogoTPF width="8rem" />
                  </LogoWrapper>
                </a>
                <a href={resolveGfsWebsiteLink()} target="_blank" id="gfs-logo-link">
                  <LogoWrapper>
                    <LogoGFS width="6rem" />
                  </LogoWrapper>
                </a>
              </Stack>
            </PartnersCardContentStyled>
          </Card>
        </Section>
        <Section title={getMessage('label.success.factors.public.section.1.title')}>
          <Card>
            <CardContentStyled>
              <Box>
                <BoxWithMediaStyled>
                  <TextBoxStyled>
                    <Typography variant="h3">
                      {getMessage('label.success.factors.public.section.1.background.subtitle')}
                    </Typography>
                    <Typography>{getMessage('label.success.factors.public.section.1.background.body.p1')}</Typography>
                    <br />
                    <HtmlRenderer html={getMessage('label.success.factors.public.section.1.background.body.p2')} />
                    <br />
                    <Typography>{getMessage('label.success.factors.public.section.1.background.body.p3')}</Typography>
                  </TextBoxStyled>
                  <img src={sectionImage} alt="Senior man listening to music, running near a lake at the evening" />
                </BoxWithMediaStyled>
              </Box>
              <TextBoxStyled>
                <Typography variant="h3">
                  {getMessage('label.success.factors.public.section.1.aim.subtitle')}
                </Typography>
                <Typography>{getMessage('label.success.factors.public.section.1.aim.body.p1')}</Typography>
                <Typography>{getMessage('label.success.factors.public.section.1.aim.body.p2')}</Typography>
              </TextBoxStyled>
              <PrimaryButton
                onClick={handleViewAllSuccessFactors}
                messageKey={getMessage('button.view.all.success.factors')}
                endIcon={<ArrowForwardIcon />}
              />
            </CardContentStyled>
          </Card>
        </Section>
        <Section title={getMessage('label.success.factors.public.section.2.title')}>
          <Card>
            <CardContentStyled>
              <BoxWithMediaStyled>
                <Box display="flex" flexDirection="column" gap={5} alignItems="flex-start">
                  <TextBoxStyled>
                    <Typography variant="h3">
                      {getMessage('label.success.factors.public.section.2.analysis.subtitle')}
                    </Typography>
                    <Typography>{getMessage('label.success.factors.public.section.2.analysis.body')}</Typography>
                  </TextBoxStyled>
                  <TextBoxStyled>
                    <Typography variant="h3">
                      {getMessage('label.success.factors.public.section.2.instructions.subtitle')}
                    </Typography>
                    <Typography>{getMessage('label.success.factors.public.section.2.instructions.body')}</Typography>
                  </TextBoxStyled>
                  <PrimaryButton
                    messageKey={getMessage('button.login')}
                    endIcon={<ArrowForwardIcon />}
                    onClick={login}
                  />
                </Box>
                <Box className="iframe-container">
                  <iframe
                    src={Utils.adaptYouTubeUrlForEmbedding(getMessage('link.success.factors.public.instructions'))}
                    title="YouTube video player"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  ></iframe>
                </Box>
              </BoxWithMediaStyled>
            </CardContentStyled>
          </Card>
        </Section>
      </PageContentStyled>
      <Footer />
    </>
  )
}
