import {
  createContext,
  MutableRefObject,
  ReactElement,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
} from 'react'
import {
  SidebarAwareState,
  SidebarAwareStore,
  SidebarStoreActions,
  useSidebarAwareStateFromStore,
} from 'src/shared/sidebar/sidebar-store'

interface SidebarAwareContextBag {
  refetchSidebarData: () => void
}

interface SidebarAwareStateValue {
  sidebarState: SidebarAwareState
  toggleSidebar: (open: boolean) => void
}

type SidebarAwareContextValue = MutableRefObject<SidebarAwareContextBag> & SidebarAwareStateValue

const defaultSidebarAwareContextBag: SidebarAwareContextValue = {
  current: {
    refetchSidebarData: () => {},
  },
  sidebarState: {
    open: false,
  },
  toggleSidebar: () => {},
}

const SidebarAwareContext = createContext<SidebarAwareContextValue>(defaultSidebarAwareContextBag)

export const SidebarAwareContextProvider = ({ children }: { children: ReactNode }): ReactElement => {
  const ref = useRef<SidebarAwareContextBag>(defaultSidebarAwareContextBag.current)

  const sidebarState = useSidebarAwareStateFromStore()

  const toggleSidebar = useCallback((open: boolean) => {
    if (open !== SidebarAwareStore.sidebarState.open) {
      SidebarStoreActions.setOpen(open)
    }
  }, [])

  const sidebarContextValue: SidebarAwareStateValue = useMemo(
    () => ({ sidebarState, toggleSidebar }),
    [sidebarState, toggleSidebar],
  )

  const contextValue: SidebarAwareContextValue = {
    ...ref,
    ...sidebarContextValue,
  }

  return <SidebarAwareContext.Provider value={contextValue}>{children}</SidebarAwareContext.Provider>
}

/**
 * Hook for consumers to refresh the sidebar imperatively.
 *
 * @returns the sidebarAPI
 */
export const useSidebarAPI = (): SidebarAwareContextBag => {
  const context = useContext(SidebarAwareContext)
  return context.current
}

/**
 * Hook for consumers to use sidebar state.
 *
 * @returns the sidebarState
 */
export const useSidebarState = (): SidebarAwareStateValue => {
  const context = useContext(SidebarAwareContext)
  return { sidebarState: context.sidebarState, toggleSidebar: context.toggleSidebar }
}

/**
 * Hook for sidebars to register their refetch callback.
 *
 * @param callback the callback to be registered
 * @returns the context itself
 */
export const useSidebarContext = (callback: () => void): SidebarAwareContextValue => {
  const context = useContext(SidebarAwareContext)

  useEffect(() => {
    context.current.refetchSidebarData = callback
    const cleanup = () => {
      context.current.refetchSidebarData = function () {}
    }
    return cleanup
  }, [callback, context])
  return context
}
