import { Box } from '@mui/material'
import { DataGridPro, GridColDef, GridRowModel, GridSortModel } from '@mui/x-data-grid-pro'
import { ReactElement, useMemo, useState } from 'react'
import { Project_Base } from 'src/@types/graphql'
import { useMessageSource } from 'src/i18n/useMessageSource'
import { ROUTES } from 'src/routing/routes'
import { useGridTranslateHook } from 'src/shared/utils/hooks/grid-translate-hook'
import styled from 'styled-components/macro'

interface Props {
  projects: Project_Base[]
}

const DataGridProStyled = styled(DataGridPro)`
  & .MuiDataGrid-row {
    &:hover {
      cursor: pointer;
    }
  }
`

export const FactsheetLinkedPfKapProjectsGrid = ({ projects }: Props): ReactElement => {
  const { getMessage } = useMessageSource()
  const gridTranslations = useGridTranslateHook()
  const [sortModel, setSortModel] = useState<GridSortModel>([
    {
      field: 'id',
      sort: 'desc',
    },
  ])

  const handleRowClick = (params: GridRowModel) => {
    const projectId = params?.id
    // We don't use useDelayedNavigate here because the project needs to open in a new window
    window.open(ROUTES.PfKapDetailsRoot.nested.BasicInformation.params({ projectId }))
  }

  const rows = useMemo(() => {
    return projects.map((projectBase) => {
      return {
        ...projectBase,
        id: projectBase.pf_kap_projects[0].id,
        shortTitle: projectBase.dossier.short_title,
        applicationType: projectBase.pf_kap_projects[0].application_type.key,
        status: projectBase.dossier.status,
        modules: projectBase.pf_kap_projects[0].modules.join(', '),
      }
    })
  }, [projects])

  const columns = useMemo(() => {
    return [
      {
        field: 'id',
        headerName: getMessage('label.project.id'),
        flex: 0.1,
        renderCell: ({ row }) => `${getMessage('label.project.id.character.pf_kap')}${row.id}`,
      },
      {
        field: 'shortTitle',
        headerName: getMessage('label.short.title'),
        flex: 1,
      },
      {
        field: 'applicationType',
        headerName: getMessage('label.application_type'),
        flex: 0.3,
        renderCell: ({ value }) => `${getMessage(value)}`,
      },
      {
        field: 'status',
        headerName: getMessage('label.project.status'),
        flex: 0.2,
        renderCell: ({ value }) => `${getMessage(`label.project.status.${value}`)}`,
      },
      {
        field: 'modules',
        headerName: getMessage('label.modules'),
        flex: 0.2,
      },
    ] as GridColDef[]
  }, [getMessage])

  return (
    <Box>
      <DataGridProStyled
        rows={rows}
        columns={columns}
        autoHeight
        localeText={gridTranslations}
        disableRowSelectionOnClick
        disableColumnReorder
        disableColumnFilter
        disableColumnPinning
        onRowClick={handleRowClick}
        sortModel={sortModel}
        onSortModelChange={(model) => setSortModel(model)}
      />
    </Box>
  )
}
