import { Card, CardContent, CardHeader, ListItem, ListItemIcon, ListItemText, Stack } from '@mui/material'
import { useMemo } from 'react'
import { useMessageSource } from 'src/i18n/useMessageSource'
import { ROUTES } from 'src/routing/routes'
import { useResolveCommitteeData } from 'src/screens/shared/application/committee/common/CommitteeService'
import { usePermissionsForProjectCommittees } from 'src/service/security/PermissionHook'
import { EditButton } from 'src/shared/button/Buttons'
import { COMMITTEE_FORM_MODE } from 'src/shared/constants/constants'
import { CheckIcon } from 'src/shared/icons/Icons'
import { PageLayout } from 'src/shared/layout/PageLayout'
import { ScreenLayout } from 'src/shared/layout/ScreenLayout'
import { ProjectExportMenu } from 'src/shared/menu/ProjectExportMenu'
import { NotAuthorized } from 'src/shared/not-authorized/NotAuthorized'
import { HelpAndInstructions } from 'src/shared/presentation/HelpAndInstructions'
import { HtmlRenderer } from 'src/shared/presentation/HtmlRenderer'
import { ReadOnlySelection, ReadOnlyTextField } from 'src/shared/presentation/ReadOnly'
import { Section } from 'src/shared/presentation/Section'
import { S } from 'src/shared/styled/S'
import { useDelayedNavigate } from 'src/shared/utils/hooks/navigation-hooks'
import { HtmlSanitizer } from 'src/shared/utils/HtmlSanitizer'
import { Utils } from 'src/shared/utils/Utils'
import { useUserLocale } from 'src/user/UserContext'

interface Props {
  projectId: number
  baseUrl: '/pf-kap' | '/pf-pgv'
}

export const ApplicationCommitteePage = ({ projectId, baseUrl }: Props) => {
  const { getMessage } = useMessageSource()
  const process = Utils.resolveProcess(baseUrl)
  const processToLowerCase = Utils.resolveProcessToLowerCase(baseUrl)
  const projectType = Utils.resolveProcess(baseUrl)
  const locale = useUserLocale()
  const navigate = useDelayedNavigate()

  const {
    loading,
    canView,
    canEdit,
    metadata: { projectBaseId },
  } = usePermissionsForProjectCommittees(projectType, projectId)

  const { initialFormValues } = useResolveCommitteeData(
    projectId,
    projectBaseId,
    process,
    false,
    COMMITTEE_FORM_MODE.PROJECT_APPLICATION_COMMITTEE,
    baseUrl,
  )

  const pageData = useMemo(() => {
    return initialFormValues
      ? {
          committeeConfigs: initialFormValues.committees,
          applicationDecision: initialFormValues.projectApplicationDecision,
          qualityCriteria: initialFormValues.projectApplicationDecision.qualityCriteriaIds,
          exclusionCriteria: initialFormValues.projectApplicationDecision.exclusionCriteriaIds,
          gfchResponsible: initialFormValues.projectApplicationDecision.gfchResponsibleValue,
        }
      : null
  }, [initialFormValues])

  const handleEditButtonClick = () => {
    if (baseUrl === '/pf-kap') {
      navigate(ROUTES.PfKapApplicationCommitteeEdit.params({ projectId }))
    } else if (baseUrl === '/pf-pgv') {
      navigate(ROUTES.PfPgvApplicationCommitteeEdit.params({ projectId }))
    }
  }

  if (!loading && !canView) {
    return <NotAuthorized />
  }

  return (
    <ScreenLayout
      title={getMessage('label.navigation.application.committees')}
      actions={<ProjectExportMenu process={projectType} entityId={projectBaseId as number} />}
      hasSecondLevelNavigation={false}
    >
      <PageLayout>
        {!loading && !!pageData && (
          <Section
            id="tbd-committee-1"
            title={getMessage('label.title.application.committees')}
            actionButton={<EditButton hidden={!canEdit} onClick={handleEditButtonClick} />}
            helpAndInstructions={<HelpAndInstructions labelKey={`label.help.committees.${processToLowerCase}`} />}
          >
            <Stack spacing={5} mt={2}>
              {pageData.committeeConfigs?.map((committeeConfigData: any) => (
                <Card key={committeeConfigData.code}>
                  <CardHeader title={getMessage(committeeConfigData.label[locale])} />
                  <CardContent>
                    <ReadOnlyTextField text={getMessage('label.committee.notes')}>
                      {committeeConfigData.description &&
                        !HtmlSanitizer.isEmptyLineHtmlParagraph(committeeConfigData.description) && (
                          <HtmlRenderer html={committeeConfigData.description} />
                        )}
                    </ReadOnlyTextField>
                    <ReadOnlySelection text={getMessage('label.recommendation')} isLast={true}>
                      {committeeConfigData.recommendation &&
                        getMessage(`label.recommendation.${committeeConfigData.recommendation}`)}
                    </ReadOnlySelection>
                  </CardContent>
                </Card>
              ))}
              <Card>
                <CardHeader title={getMessage('label.pros.and.cons')} />
                <CardContent>
                  <ReadOnlyTextField text={getMessage('label.pros')}>
                    {pageData.applicationDecision?.pros &&
                      !HtmlSanitizer.isEmptyLineHtmlParagraph(pageData.applicationDecision.pros) && (
                        <HtmlRenderer html={pageData.applicationDecision?.pros} />
                      )}
                  </ReadOnlyTextField>
                  <ReadOnlyTextField text={getMessage('label.cons')} isLast={true}>
                    {pageData.applicationDecision?.cons &&
                      !HtmlSanitizer.isEmptyLineHtmlParagraph(pageData.applicationDecision.cons) && (
                        <HtmlRenderer html={pageData.applicationDecision?.cons} />
                      )}
                  </ReadOnlyTextField>
                </CardContent>
              </Card>
              <Section id="final-decision">
                <Card>
                  <CardHeader title={getMessage('label.final.decision')} />
                  <CardContent>
                    <ReadOnlySelection text={getMessage('label.decision')}>
                      {pageData.applicationDecision?.finalDecision &&
                        getMessage(`label.recommendation.${pageData.applicationDecision.finalDecision}`)}
                    </ReadOnlySelection>
                    <ReadOnlySelection text={getMessage('label.exclusion.criteria')}>
                      {pageData.exclusionCriteria && pageData.exclusionCriteria?.length > 0 && (
                        <S.List.ReadOnly>
                          {pageData.exclusionCriteria.map((ec: any) => (
                            <ListItem key={ec}>
                              <ListItemIcon>
                                <CheckIcon />
                              </ListItemIcon>
                              <ListItemText primary={ec} />
                            </ListItem>
                          ))}
                        </S.List.ReadOnly>
                      )}
                    </ReadOnlySelection>
                    <ReadOnlySelection text={getMessage('label.quality.criteria')}>
                      {pageData.qualityCriteria && pageData.qualityCriteria?.length > 0 && (
                        <S.List.ReadOnly>
                          {pageData.qualityCriteria.map((qc: any) => (
                            <ListItem key={qc}>
                              <ListItemIcon>
                                <CheckIcon />
                              </ListItemIcon>
                              <ListItemText primary={qc} />
                            </ListItem>
                          ))}
                        </S.List.ReadOnly>
                      )}
                    </ReadOnlySelection>
                    <ReadOnlyTextField text={getMessage('label.decision.letter')}>
                      {pageData.applicationDecision?.decisionLetter &&
                        !HtmlSanitizer.isEmptyLineHtmlParagraph(pageData.applicationDecision.decisionLetter) && (
                          <HtmlRenderer html={pageData.applicationDecision?.decisionLetter} />
                        )}
                    </ReadOnlyTextField>
                    <ReadOnlySelection text={getMessage('label.gfch.responsible')} isLast={true}>
                      {pageData.gfchResponsible}
                    </ReadOnlySelection>
                  </CardContent>
                </Card>
              </Section>
            </Stack>
          </Section>
        )}
      </PageLayout>
    </ScreenLayout>
  )
}
