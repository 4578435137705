import { Box, Rating, Typography } from '@mui/material'
import { ReactElement, ReactNode } from 'react'
import { Field } from 'react-final-form'
import { useMessageSource } from 'src/i18n/useMessageSource'
import { muiTheme } from 'src/theme/theme'
import styled from 'styled-components/macro'

type RatingFieldProps = {
  name: string
  icon: ReactNode
  emptyIcon: ReactNode
  precision: number
  max: number
  ratingMessage: string
  validate?: any
  required?: boolean
}

const RatingStyled = styled(Rating)`
  & .MuiRating-iconFilled {
    color: ${(props) => props.theme.colors.primary.main};
  }
`

export const RatingField = (props: RatingFieldProps): ReactElement => {
  const { name, validate, icon, emptyIcon, precision, max, ratingMessage } = props
  const { getMessage } = useMessageSource()

  return (
    <Field
      name={name}
      allowNull
      validate={validate}
      render={({ input: { value, onFocus, onBlur, onChange }, meta: { touched, error: errorObject } }) => {
        const onChangeLocal = (e: any, value: any): void => {
          onChange(value)
          onBlur()
        }
        const invalid = Boolean(touched && errorObject)
        return (
          <>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'spaceBetween', flexGrow: 1 }}>
              <RatingStyled
                icon={icon}
                emptyIcon={emptyIcon}
                name={name}
                size="medium"
                value={value}
                onFocus={onFocus}
                onBlur={onBlur}
                onChange={onChangeLocal}
                precision={precision}
                max={max}
              />
            </Box>
            <Typography
              component="legend"
              variant="body2"
              color={value ? 'textPrimary' : invalid ? 'error' : muiTheme.palette.text.disabled}
            >
              {value ? ratingMessage : getMessage(`label.success.factor.not.rated`)}
            </Typography>
          </>
        )
      }}
    />
  )
}
