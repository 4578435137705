import { ReactElement } from 'react'
import { useParams } from 'react-router'
import { ApplicationAssessmentCriteria } from 'src/screens/shared/application/assessment/details/assessment-criteria/ApplicationAssessmentCriteria'

export const PfKapAssessmentCriteriaPage = (): ReactElement => {
  const { projectId, assessmentId } = useParams()
  const pf_kap_project_id = parseInt(projectId as string)
  const pf_kap_assessment_id = parseInt(assessmentId as string)

  return (
    <ApplicationAssessmentCriteria
      baseUrl="/pf-kap"
      projectId={pf_kap_project_id}
      assessmentId={pf_kap_assessment_id}
    />
  )
}
