import { ReactElement } from 'react'
import { useParams } from 'react-router'
import { StartingPointAndVision } from 'src/screens/shared/project/details/starting-point-and-vision/StartingPointAndVision'

export const PfPgvStartingPointAndVisionPage = (): ReactElement => {
  const { projectId } = useParams()
  const pf_pgv_id = parseInt(projectId as string)

  return <StartingPointAndVision projectId={pf_pgv_id} baseUrl="/pf-pgv" />
}
