import { Button } from '@mui/material'
import { ReactElement, useCallback, useEffect, useState } from 'react'
import { Dossier, Milestone, Project_Base_Bool_Exp, Query_Root } from 'src/@types/graphql'
import { useMessageSource } from 'src/i18n/useMessageSource'
import { resolveImplementationDetailsRoot, resolveMilestoneRoot, resolvePrimaryRoute } from 'src/routing/routing-utils'
import { SummarySection } from 'src/screens/shared/application/common/SummarySection'
import { ValidationListItemProps } from 'src/screens/shared/common/SummaryValidationUtils'
import { queryDossierAndMilestoneForImplementationSummary } from 'src/screens/shared/implementation/details/summary/implementationSummaryQueries'
import { WorkflowService } from 'src/service/axios/WorkflowService'
import { usePermissionsForProjectImplementation } from 'src/service/security/PermissionHook'
import { WorkflowPermissionService } from 'src/service/security/WorkflowPermissionService'
import { EditButton } from 'src/shared/button/Buttons'
import { DOSSIER_STATUS, PROJECT } from 'src/shared/constants/constants'
import { MILESTONE_TYPE_TYPE } from 'src/shared/constants/milestone-constants'
import { NextState } from 'src/shared/constants/workflow-constants'
import { PageLayout } from 'src/shared/layout/PageLayout'
import { ScreenLayout } from 'src/shared/layout/ScreenLayout'
import { HandoverModalDialog } from 'src/shared/modal-dialog/HandoverModalDialog'
import { NotAuthorized } from 'src/shared/not-authorized/NotAuthorized'
import { useSidebarAPI } from 'src/shared/sidebar/SidebarAwareContext'
import { useDelayedNavigate } from 'src/shared/utils/hooks/navigation-hooks'
import { useNotificationService } from 'src/shared/utils/NotificationService'
import { Utils } from 'src/shared/utils/Utils'
import { NextStateIf } from 'src/shared/workflow/NextStateIf'
import { useUserLocale } from 'src/user/UserContext'
import styled from 'styled-components/macro'
import { useClient } from 'urql'

interface Props {
  baseUrl: '/pf-kap' | '/pf-pgv'
  projectId: number
}

const SpanStyled = styled.span<{ $isCanceled: string }>`
  color: ${({ $isCanceled, theme }) =>
    $isCanceled === `${DOSSIER_STATUS.CANCELED}` ? theme.colors.error.dark : 'inherit'};
`

export const ProjectImplementationSummary = ({ baseUrl, projectId }: Props): ReactElement => {
  const { getMessage } = useMessageSource()
  const language = useUserLocale()
  const process = Utils.resolveProcessToLowerCase(baseUrl)
  const [implementationSummary, setImplementationSummary] = useState<Dossier>()
  const [validationViolations, setValidationViolations] = useState<ValidationListItemProps[]>([])
  const projectType = Utils.resolveProcess(baseUrl)
  const [nextStates, setNextStates] = useState<null | NextState[]>(null)
  const urqlClient = useClient()
  const [endProjectConfirmationOpen, setEndProjectConfirmationOpen] = useState(false)
  const [canceledProjectConfirmationOpen, setCanceledProjectConfirmationOpen] = useState(false)
  const navigate = useDelayedNavigate()
  const sidebarAPI = useSidebarAPI()
  const notificationService = useNotificationService()
  const [transitionToFinished, setTransitionToFinished] = useState(false)
  const [transitionToCanceled, setTransitionToCanceled] = useState(false)

  const {
    loading,
    canView,
    canEdit,
    metadata: { userProjectRoles, userGlobalRoles },
    refetch: refetchPermissions,
  } = usePermissionsForProjectImplementation(projectType, projectId)

  const validateImplementation = useCallback(
    (milestones: Milestone[]): ValidationListItemProps[] => {
      const validationViolation: ValidationListItemProps[] = []
      milestones.forEach((milestone) => {
        const milestoneId = milestone.id
        const milestonePath = Utils.resolveMilestonePath(milestone.type as MILESTONE_TYPE_TYPE)
        const primaryRoute = resolvePrimaryRoute(resolveMilestoneRoot(baseUrl, milestone.type as MILESTONE_TYPE_TYPE))

        if (milestone.status !== 'DONE') {
          const violation = {
            message: getMessage('validation.section.incomplete', [
              `${getMessage(`label.milestone.type.${milestone.type}`)} ${milestone.year_in_focus ?? ''}`,
            ]),
            root: primaryRoute.params({ projectId, milestoneId, milestonePath }),
          }
          validationViolation.push(violation)
        }
      })
      return validationViolation
    },
    [getMessage, baseUrl, projectId],
  )

  const fetchData = useCallback(async () => {
    const projectPfKapBaseWhereClause: Project_Base_Bool_Exp = {
      pf_kap_projects: {
        id: {
          _eq: projectId,
        },
      },
    }

    const projectPfPgvBaseWhereClause: Project_Base_Bool_Exp = {
      pf_pgv_projects: {
        id: {
          _eq: projectId,
        },
      },
    }

    const projectBaseQuery: Project_Base_Bool_Exp = {
      ...(projectType === PROJECT.PF_KAP && projectPfKapBaseWhereClause),
      ...(projectType === PROJECT.PF_PGV && projectPfPgvBaseWhereClause),
    }

    const { data } = await urqlClient
      .query<{ dossier: Query_Root['dossier']; milestone: Query_Root['milestone'] }>(
        queryDossierAndMilestoneForImplementationSummary,
        {
          projectBaseQuery,
        },
      )
      .toPromise()

    const nextStates = await WorkflowService.nextStates(data?.dossier?.[0]?.project_workflow_id ?? '')
    const nextStatesForUser = WorkflowPermissionService.nextStatesForUser(nextStates)(userGlobalRoles, userProjectRoles)

    if (data?.dossier && data?.milestone) {
      const validationViolations = validateImplementation(data.milestone)
      setImplementationSummary(data.dossier[0])
      setNextStates(nextStatesForUser)
      setValidationViolations(validationViolations)
    }
  }, [projectId, projectType, urqlClient, userGlobalRoles, userProjectRoles, validateImplementation])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  const handleViolationClick = (validationItem: ValidationListItemProps) => {
    navigate(validationItem?.root ?? '')
  }

  const openCanceledProjectConfirmationDialog = () => {
    setCanceledProjectConfirmationOpen(true)
  }

  const cancelCanceledProjectConfirmationDialog = () => {
    setCanceledProjectConfirmationOpen(false)
  }

  const refetchPageData = () => {
    fetchData()
  }

  const confirmProjectCanceledStateHandler = async () => {
    try {
      setTransitionToCanceled(true)
      await WorkflowService.transition(
        implementationSummary?.project_workflow_id as string,
        DOSSIER_STATUS.CANCELED,
        language,
      )
      refetchPageData()
      refetchPermissions()
      sidebarAPI.refetchSidebarData()
      setCanceledProjectConfirmationOpen(false)
      notificationService.changesSaved()
    } catch (e) {
      notificationService.operationFailed()
    } finally {
      setTransitionToCanceled(false)
    }
  }

  const openEndProjectConfirmationDialog = () => {
    setEndProjectConfirmationOpen(true)
  }

  const cancelEndProjectConfirmationDialog = () => {
    setEndProjectConfirmationOpen(false)
  }

  const confirmEndProjectHandler = async () => {
    try {
      setTransitionToFinished(true)
      await WorkflowService.transition(
        implementationSummary?.project_workflow_id as string,
        DOSSIER_STATUS.FINISHED,
        language,
      )
      refetchPageData()
      refetchPermissions()
      sidebarAPI.refetchSidebarData()
      setEndProjectConfirmationOpen(false)
      notificationService.changesSaved()
    } catch (e) {
      notificationService.operationFailed()
    } finally {
      setTransitionToFinished(false)
    }
  }

  const summaryCommentsEditHandler = () => {
    navigate(resolveImplementationDetailsRoot(baseUrl).nested.SummaryCommentEdit.params({ projectId }))
  }

  const getValidationCompleteMessage = (): string => {
    if (
      implementationSummary?.status === DOSSIER_STATUS.IMPLEMENTATION ||
      implementationSummary?.status === DOSSIER_STATUS.FINISHED
    ) {
      return getMessage('label.implementation.summary.check.complete')
    } else if (implementationSummary?.status === DOSSIER_STATUS.CANCELED) {
      return getMessage('label.project.is.canceled')
    } else {
      return getMessage('label.project.not.yet.in.implementation')
    }
  }

  return (
    <ScreenLayout title={getMessage('label.title.project.implementation')}>
      <PageLayout>
        {!loading && !canView && <NotAuthorized inSecondLevelNavigationTab={true} />}
        {!loading && canView && implementationSummary && nextStates !== null && (
          <SummarySection
            title={getMessage('label.implementation.summary.title')}
            actionsHelpLabel={`label.help.implementation.summary.${process}`}
            status={
              <>
                {getMessage('label.project.status')}:{' '}
                <SpanStyled $isCanceled={implementationSummary.status}>
                  {getMessage(`label.project.status.${implementationSummary.status}`)}
                </SpanStyled>
              </>
            }
            violations={validationViolations}
            violationClickHandler={handleViolationClick}
            actionsCompleteMessage={getValidationCompleteMessage()}
            noItemsToValidate={
              !(
                implementationSummary.status === DOSSIER_STATUS.IMPLEMENTATION ||
                implementationSummary.status === DOSSIER_STATUS.FINISHED
              )
            }
            statusActions={
              <>
                <NextStateIf
                  forState={DOSSIER_STATUS.CANCELED}
                  nextStates={nextStates}
                  userProjectRoles={userProjectRoles}
                  userGlobalRoles={userGlobalRoles}
                >
                  <Button
                    variant="outlined"
                    color="secondary"
                    onClick={openCanceledProjectConfirmationDialog}
                    sx={{
                      display:
                        implementationSummary.status !== DOSSIER_STATUS.IMPLEMENTATION || !canEdit
                          ? 'none'
                          : 'inline-block',
                    }}
                  >
                    {getMessage('button.set.to.canceled')}
                  </Button>
                </NextStateIf>
                <NextStateIf
                  forState={DOSSIER_STATUS.FINISHED}
                  nextStates={nextStates}
                  userProjectRoles={userProjectRoles}
                  userGlobalRoles={userGlobalRoles}
                >
                  <Button
                    variant="contained"
                    color="secondary"
                    disabled={validationViolations.length > 0}
                    sx={{
                      display:
                        implementationSummary?.status !== DOSSIER_STATUS.IMPLEMENTATION || !canEdit
                          ? 'none'
                          : 'inline-block',
                    }}
                    onClick={openEndProjectConfirmationDialog}
                  >
                    {getMessage('button.end.project')}
                  </Button>
                </NextStateIf>
              </>
            }
            commentsHelpLabel={`label.help.implementation.summary.comments.${process}`}
            commentActions={<EditButton onClick={summaryCommentsEditHandler} hidden={!canEdit} />}
            comment={implementationSummary?.project_bases[0]?.implementation_comment}
          />
        )}
        <HandoverModalDialog
          open={canceledProjectConfirmationOpen}
          onSend={confirmProjectCanceledStateHandler}
          onCancel={cancelCanceledProjectConfirmationDialog}
          entityId={projectId}
          loading={transitionToCanceled}
          processType={projectType}
          titleKey="label.project.confirm.canceled.title"
          contentKey="label.project.confirm.canceled.body"
          buttonLoadingKey="button.confirm"
        />
        <HandoverModalDialog
          open={endProjectConfirmationOpen}
          onSend={confirmEndProjectHandler}
          onCancel={cancelEndProjectConfirmationDialog}
          entityId={projectId}
          loading={transitionToFinished}
          processType={projectType}
          titleKey="label.project.finalize.confirm.title"
          contentKey="label.project.finalize.confirm.body"
          buttonLoadingKey="button.confirm"
        />
      </PageLayout>
    </ScreenLayout>
  )
}
