import { Stack } from '@mui/material'
import { useEffect, useState } from 'react'
import { Form } from 'react-final-form'
import { Evaluation_Milestone, Mutation_Root } from 'src/@types/graphql'
import { useMessageSource } from 'src/i18n/useMessageSource'
import { upsertEvaluationMilestoneMutation } from 'src/screens/shared/project/details/effectivity-management/effectivityManagementQueries'
import {
  EVALUATION_MILESTONE_STATUS_TYPE,
  EVALUATION_MILESTONE_STATUS_TYPE_TYPE,
  PF_KAP_EVALUATION_MILESTONE_TYPE,
  PF_PGV_EVALUATION_MILESTONE_TYPE,
  PROJECT,
  PROJECT_TYPE,
  TEXT_LENGTH,
} from 'src/shared/constants/constants'
import { AutoCompleteField } from 'src/shared/form/control/AutoCompleteField'
import { DateTimePickerField } from 'src/shared/form/control/DateTimePickerField'
import { HtmlEditorField } from 'src/shared/form/control/HtmlEditorField'
import { DirtyFormSpy } from 'src/shared/form/dirty/DirtyFormSpy'
import { createDecorators } from 'src/shared/form/utils/decorators'
import { composeValidators, maxChar, required, validDate } from 'src/shared/form/validation/validators'
import { DateUtils } from 'src/shared/utils/DateUtils'
import { HtmlSanitizer } from 'src/shared/utils/HtmlSanitizer'
import { useNotificationService } from 'src/shared/utils/NotificationService'
import { useClient } from 'urql'

interface FormValues {
  title: string
  date: string
  status: EVALUATION_MILESTONE_STATUS_TYPE_TYPE
  description: string
}

interface Props {
  dossierId: number
  projectType: PROJECT_TYPE
  evaluationMilestone?: Evaluation_Milestone
  onSubmit: (_: number) => void
}

const DEFAULT_FORM_VALUES: FormValues = {
  title: '',
  status: EVALUATION_MILESTONE_STATUS_TYPE.OPEN,
  date: '',
  description: '',
}

const getMilestoneTypes = (isPfKap: boolean) => {
  return isPfKap ? Object.keys(PF_KAP_EVALUATION_MILESTONE_TYPE) : Object.keys(PF_PGV_EVALUATION_MILESTONE_TYPE)
}

const mapEvaluationMilestoneToFormValues = (evaluationMilestone: Evaluation_Milestone): FormValues => {
  return {
    title: evaluationMilestone.title,
    status: evaluationMilestone.status as EVALUATION_MILESTONE_STATUS_TYPE_TYPE,
    date: DateUtils.parseAndFormatDate(evaluationMilestone.date),
    description: evaluationMilestone.description,
  }
}

export const EvaluationMilestoneForm = ({ dossierId, projectType, evaluationMilestone, onSubmit }: Props) => {
  const { getMessage } = useMessageSource()

  const decorators = createDecorators()
  const urqlClient = useClient()
  const notificationService = useNotificationService()

  const evaluationMilestoneTypes = getMilestoneTypes(projectType === PROJECT.PF_KAP)

  const [initialValues, setInitialValues] = useState<FormValues>()

  useEffect(() => {
    const initData = async () => {
      if (evaluationMilestone) {
        setInitialValues(mapEvaluationMilestoneToFormValues(evaluationMilestone))
      } else {
        setInitialValues(DEFAULT_FORM_VALUES)
      }
    }

    initData()
  }, [evaluationMilestone])

  const evaluationMilestoneTypeOptions = evaluationMilestoneTypes.map((it) => ({
    label: `${getMessage(`label.evaluation.milestone.type.${it}`)}`,
    value: it,
  }))

  const evaluationMilestoneStatusOptions = Object.keys(EVALUATION_MILESTONE_STATUS_TYPE).map((it) => ({
    label: `${getMessage(`label.evaluation.milestone.status.${it}`)}`,
    value: it,
  }))

  const onSave = async (values: FormValues) => {
    const mutationObject = {
      ...values,
      id: evaluationMilestone?.id,
      description: HtmlSanitizer.sanitize(values.description),
      dossier_id: dossierId,
      date: DateUtils.parseAndSerializeDate(values.date),
    }

    const { data } = await urqlClient
      .mutation<{ insert_evaluation_milestone_one: Mutation_Root['insert_evaluation_milestone_one'] }>(
        upsertEvaluationMilestoneMutation,
        {
          object: mutationObject,
        },
      )
      .toPromise()

    if (data?.insert_evaluation_milestone_one) {
      setInitialValues(values)
      notificationService.changesSaved()
      onSubmit(data.insert_evaluation_milestone_one.id)
    } else {
      notificationService.operationFailed()
    }
  }

  return (
    <>
      {initialValues && (
        <Form<FormValues>
          initialValues={initialValues}
          onSubmit={onSave}
          decorators={decorators}
          render={({ handleSubmit }) => {
            return (
              <form id="evaluation-milestone-form" onSubmit={handleSubmit}>
                <Stack spacing={2}>
                  <AutoCompleteField
                    name="title"
                    label={getMessage('label.evaluation.milestone.type')}
                    options={evaluationMilestoneTypeOptions}
                    validate={required()}
                    required
                  />
                  <DateTimePickerField
                    name="date"
                    label={getMessage('label.due.date')}
                    validate={composeValidators(required(), validDate())}
                    required
                  />
                  <AutoCompleteField
                    name="status"
                    label={getMessage('label.evaluation.milestone.status')}
                    options={evaluationMilestoneStatusOptions}
                    validate={required()}
                    required
                  />
                  <HtmlEditorField
                    name="description"
                    label={getMessage('label.evaluation.milestone.description')}
                    validate={composeValidators(required(), maxChar(TEXT_LENGTH.XL))}
                    required
                  />
                  <DirtyFormSpy />
                </Stack>
              </form>
            )
          }}
        />
      )}
    </>
  )
}
