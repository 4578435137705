import { ReactElement } from 'react'
import { useParams } from 'react-router'
import { useMessageSource } from 'src/i18n/useMessageSource'
import { resolveDetailsRoot } from 'src/routing/routing-utils'
import { BaseOrganizationView } from 'src/screens/shared/organization/view/BaseOrganizationView'
import { usePermissionsForProject } from 'src/service/security/PermissionHook'
import { useDelayedNavigate } from 'src/shared/utils/hooks/navigation-hooks'
import { Utils } from 'src/shared/utils/Utils'

interface ProjectOrganizationViewPageProps {
  baseUrl: '/pf-kap' | '/pf-pgv'
}

export const ProjectOrganizationViewPage = ({ baseUrl }: ProjectOrganizationViewPageProps): ReactElement => {
  const { getMessage } = useMessageSource()
  const navigate = useDelayedNavigate()
  const { projectId, organizationId, organizationRelationType } = useParams()
  const process = Utils.resolveProcessToLowerCase(baseUrl)
  const projectType = Utils.resolveProcess(baseUrl)
  const { loading, canEdit } = usePermissionsForProject(projectType, parseInt(projectId as string))

  const pageTitle =
    organizationRelationType === 'partner'
      ? getMessage('label.title.view.partner.organization')
      : getMessage('label.title.view.responsible.organization')

  const helpAndInstructionsTitle =
    organizationRelationType === 'partner'
      ? `label.help.basic.information.partner.organizations.${process}`
      : `label.help.basic.information.responsible.organization.${process}`

  const onEdit = () => {
    navigate(
      resolveDetailsRoot(baseUrl).nested.OrganizationEdit.params({
        projectId,
        organizationId,
        organizationRelationType,
      }),
    )
  }

  const onBack = () => {
    navigate(resolveDetailsRoot(baseUrl).nested.BasicInformation.params({ projectId }))
  }

  return (
    <>
      {!loading && (
        <BaseOrganizationView
          pageTitle={pageTitle}
          helpAndInstructionsTitle={helpAndInstructionsTitle}
          canEdit={canEdit}
          onBack={onBack}
          onEdit={onEdit}
        />
      )}
    </>
  )
}
