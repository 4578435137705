import { Location } from 'history'
import { ReactElement, useEffect, useMemo, useState } from 'react'
import { useLocation, useParams } from 'react-router'
import { Outlet } from 'react-router-dom'
import { ROUTES } from 'src/routing/routes'
import { NestedRoute, Route, SecondLevelRoute } from 'src/routing/routing'
import { SecondLevelNavigation } from 'src/routing/SecondLevelNavigation'
import { usePermissionsForKapProgram } from 'src/service/security/PermissionHook'
import { PageLayout } from 'src/shared/layout/PageLayout'
import { SecondLevelNavigationContainer } from 'src/shared/layout/SecondLevelNavigationContainer'
import { NotAuthorized } from 'src/shared/not-authorized/NotAuthorized'

const resolveActiveRoute = (location: Location, programId: string, measureId: string): SecondLevelRoute => {
  const nested = ROUTES.KapMeasureDetailsRoot!.nested! as unknown as NestedRoute
  const activeRoute = Object.keys(nested).find(
    (key) =>
      location.pathname.endsWith(nested[key].path) ||
      (ROUTES.KapMeasureDetailsRoot.params({ programId, measureId }) === location.pathname && nested[key].primary),
  ) as string
  return nested[activeRoute] as SecondLevelRoute
}

export const KapMeasureDetailsIndexPage = (): ReactElement => {
  const { programId, measureId } = useParams()
  const location = useLocation()
  const { canView, loading } = usePermissionsForKapProgram(parseInt(programId as string))

  const routeParams = useMemo(
    () => ({
      programId: programId as string,
      measureId: measureId as string,
    }),
    [programId, measureId],
  )

  const [activeRoute, setActiveRoute] = useState<SecondLevelRoute>(() => {
    return resolveActiveRoute(location, routeParams.programId, routeParams.measureId)
  })

  useEffect(() => {
    const activeRoute = resolveActiveRoute(location, routeParams.programId, routeParams.measureId)
    setActiveRoute(activeRoute)
  }, [location, routeParams])

  const showNavigation = activeRoute && !activeRoute.hideNavigation

  if (!loading && !canView) {
    return <NotAuthorized />
  }

  return (
    <>
      <SecondLevelNavigationContainer $showNavigation={showNavigation}>
        {showNavigation && (
          <PageLayout>
            <SecondLevelNavigation
              activeRoute={activeRoute}
              route={ROUTES.KapMeasureDetailsRoot as unknown as Route}
              baseUrl={ROUTES.KapMeasureDetailsRoot.params({ programId, measureId })}
            />
          </PageLayout>
        )}
        <Outlet />
      </SecondLevelNavigationContainer>
    </>
  )
}
