import { Box } from '@mui/material'
import {
  getGridStringOperators,
  GridColDef,
  GridPaginationModel,
  GridRowSelectionModel,
  GridSortModel,
} from '@mui/x-data-grid-pro'
import { GridApiPro } from '@mui/x-data-grid-pro/models/gridApiPro'
import { MutableRefObject, useCallback, useMemo, useState } from 'react'
import { Dossier, Project_Base } from 'src/@types/graphql'
import { useMessageSource } from 'src/i18n/useMessageSource'
import { PROJECT } from 'src/shared/constants/constants'
import { S } from 'src/shared/styled/S'
import { useGridTranslateHook } from 'src/shared/utils/hooks/grid-translate-hook'
import styled from 'styled-components/macro'

const DataGridProStyled = styled(S.DataGrid.ViewEditMode)`
  & .MuiDataGrid-columnHeader {
    & .MuiButtonBase-root.MuiCheckbox-root {
      display: none;
    }
  }
  & .MuiDataGrid-virtualScroller {
    overflow: hidden;
  }
`

const GridBoxStyled = styled(Box)`
  height: 668px;
`

interface Props {
  dossiers: Dossier[]
  selectionModel: GridRowSelectionModel
  setSelectionModel: React.Dispatch<React.SetStateAction<GridRowSelectionModel>>
  gridRef: MutableRefObject<GridApiPro>
  process: string
}

const PAGE_SIZE = 10

export const RelatedEvaluationGrid = ({ dossiers, selectionModel, setSelectionModel, gridRef, process }: Props) => {
  const { getMessage } = useMessageSource()
  const gridTranslations = useGridTranslateHook()
  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({ page: 0, pageSize: PAGE_SIZE })
  const [sortModel, setSortModel] = useState<GridSortModel>([
    {
      field: 'id',
      sort: 'desc',
    },
  ])

  const rows = useMemo(() => dossiers, [dossiers])

  const resolveProjectIdByProcess = useCallback(
    (project_base: Project_Base) => {
      return process === PROJECT.PF_KAP.toLowerCase()
        ? project_base.pf_kap_projects?.[0]?.id
        : project_base.pf_pgv_projects?.[0]?.id
    },
    [process],
  )

  const columns = useMemo<GridColDef[]>(
    () => [
      {
        field: 'id',
        flex: 1,
        headerName: getMessage('label.id'),
        filterOperators: getGridStringOperators().filter((x) => x.value === 'equals'),
        renderCell: ({ row }) => {
          const projectId = resolveProjectIdByProcess(row.project_bases?.[0])
          return `${getMessage(`label.project.id.character.${process}`)}${projectId}`
        },
      },
      {
        field: 'short_title',
        flex: 4,
        headerName: getMessage('label.short.title'),
        filterOperators: getGridStringOperators().filter((x) => x.value === 'contains'),
      },
    ],
    [getMessage, process, resolveProjectIdByProcess],
  )

  return (
    <GridBoxStyled>
      <DataGridProStyled
        rows={rows}
        columns={columns}
        apiRef={gridRef}
        localeText={gridTranslations}
        disableRowSelectionOnClick
        disableColumnReorder
        disableColumnSelector
        disableColumnPinning
        paginationModel={paginationModel}
        onPaginationModelChange={(model) => setPaginationModel(model)}
        pageSizeOptions={[PAGE_SIZE]}
        pagination
        checkboxSelection
        rowSelectionModel={selectionModel}
        onRowSelectionModelChange={(selectionModel: GridRowSelectionModel) => setSelectionModel(selectionModel)}
        sortModel={sortModel}
        onSortModelChange={(model: React.SetStateAction<GridSortModel>) => setSortModel(model)}
        scrollbarSize={0}
        $viewMode={true}
      />
    </GridBoxStyled>
  )
}
