import { gql } from '@urql/core'

export const queryOrganizationById = gql`
  query ($id: Int!) {
    organization_by_pk(id: $id) {
      additional_address
      address
      city
      department
      email
      id
      name
      postal_code
      version
      phone
      website
      organization_type {
        id
        key
      }
    }
  }
`

export const queryOrganizationTypes = gql`
  query ($process: jsonb) {
    organization_type(where: { process: { _contains: $process } }, order_by: { sort_number: asc }) {
      code
      id
      key
      sort_number
    }
  }
`

export const updateOrganizationMutationQuery = gql`
  mutation (
    $id: Int!
    $version: Int!
    $name: String
    $address: String
    $additionalAddress: String
    $city: String
    $email: String
    $phone: String
    $postalCode: String
    $typeId: Int
    $department: String
    $website: String
  ) {
    update_organization(
      _set: {
        additional_address: $additionalAddress
        address: $address
        city: $city
        department: $department
        email: $email
        name: $name
        organization_type_id: $typeId
        phone: $phone
        postal_code: $postalCode
        website: $website
      }
      where: { _and: [{ id: { _eq: $id } }, { version: { _eq: $version } }] }
    ) {
      affected_rows
      returning {
        version
      }
    }
  }
`
