import { ReactElement, useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { Mutation_Root } from 'src/@types/graphql'
import { useMessageSource } from 'src/i18n/useMessageSource'
import { ROUTES } from 'src/routing/routes'
import { insertKapMeasureMutation } from 'src/screens/kap/program/details/measures/kapMeasuresQueries'
import {
  initialValuesFactory,
  KapMeasureForm,
  KapMeasureFormValues,
} from 'src/screens/kap/program/details/measures/measure/description/edit/KapMeasureForm'
import { usePermissionsForKapProgram } from 'src/service/security/PermissionHook'
import { CreateButton } from 'src/shared/button/Buttons'
import { PageLayout } from 'src/shared/layout/PageLayout'
import { ScreenLayout } from 'src/shared/layout/ScreenLayout'
import { NotAuthorized } from 'src/shared/not-authorized/NotAuthorized'
import { HelpAndInstructions } from 'src/shared/presentation/HelpAndInstructions'
import { useDelayedNavigate } from 'src/shared/utils/hooks/navigation-hooks'
import { HtmlSanitizer } from 'src/shared/utils/HtmlSanitizer'
import { useNotificationService } from 'src/shared/utils/NotificationService'
import { Utils } from 'src/shared/utils/Utils'
import { useClient } from 'urql'

export const KapMeasureCreatePage = (): ReactElement => {
  const { programId } = useParams()
  const program_id = parseInt(programId as string)
  const { getMessage } = useMessageSource()

  const notificationService = useNotificationService()
  const navigate = useDelayedNavigate()
  const urqlClient = useClient()

  const [initialValues, setInitialValues] = useState<KapMeasureFormValues | null>(null)

  const { canEditMeasures, loading } = usePermissionsForKapProgram(program_id)

  const onSubmit = () => {
    // trigger submit event
    document.getElementById('kap-measure-form')?.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }))
  }

  const handleOnSave = async (values: KapMeasureFormValues) => {
    const insertObject = {
      name: values.name,
      shortTitle: values.shortTitle,
      description: HtmlSanitizer.sanitize(values.description),
      modules: Utils.sortModules(values.modules),
      level: values.level,
      programId: values.programId,
      goalsToBeAdded: values.goalIds?.map((goalId) => ({
        kap_goal_id: goalId,
      })),
      measureId: values.measureId,
    }

    const { error } = await urqlClient
      .mutation<{ insert_kap_measure: Mutation_Root['insert_kap_measure'] }>(insertKapMeasureMutation, insertObject)
      .toPromise()

    if (error) {
      notificationService.operationFailed()
    } else {
      setInitialValues(values)
      notificationService.changesSaved()
      onBack()
    }
  }

  useEffect(() => {
    const initializeFormValues = () => {
      const initialValues = initialValuesFactory(program_id)
      setInitialValues(initialValues)
    }

    initializeFormValues()
  }, [program_id])

  const onBack = () => {
    navigate(ROUTES.KapDetailsRoot.nested.Measures.params({ programId }))
  }

  if (!loading && !canEditMeasures) {
    return <NotAuthorized inEditPage />
  }

  return (
    <ScreenLayout
      title={getMessage('label.measure.title.add')}
      hasSecondLevelNavigation={false}
      onBack={onBack}
      actions={
        <>
          <CreateButton origin="header" onClick={onSubmit} />
        </>
      }
    >
      <PageLayout>
        <>
          {!loading && initialValues && (
            <>
              <HelpAndInstructions labelKey="label.help.kap.measures" defaultExpansion />
              <KapMeasureForm initialValues={initialValues} onSave={handleOnSave} mode="create" />
            </>
          )}
        </>
      </PageLayout>
    </ScreenLayout>
  )
}
