import { IconButton, Typography } from '@mui/material'
import { useCallback, useEffect, useState } from 'react'
import { File, Maybe, Query_Root } from 'src/@types/graphql'
import { useEnvironment } from 'src/env/EnvironmentStore'
import { useMessageSource } from 'src/i18n/useMessageSource'
import { queryProjectJournalSecondaryInfoById } from 'src/screens/shared/project/journal/projectJournalQueries'
import { FileService } from 'src/service/axios/FileService'
import { ENTITY_TYPE, FILE_TYPE } from 'src/shared/constants/constants'
import { BOX_SHADOW_ON_WHITE_BG } from 'src/shared/constants/styling-constants'
import { DeleteIcon, DownloadIcon } from 'src/shared/icons/Icons'
import { ConfirmationModalDialog } from 'src/shared/modal-dialog/ConfirmationModalDialog'
import { DefaultSectionTypography } from 'src/shared/presentation/DefaultSectionTypography'
import { queryFiles } from 'src/shared/presentation/fileQueries'
import { DocumentContainer, DownloadLinkStyled } from 'src/shared/presentation/FileSection'
import { HtmlRenderer } from 'src/shared/presentation/HtmlRenderer'
import { ReadOnlySelection, ReadOnlyTextField } from 'src/shared/presentation/ReadOnly'
import { S } from 'src/shared/styled/S'
import { DateUtils } from 'src/shared/utils/DateUtils'
import { useNotificationService } from 'src/shared/utils/NotificationService'
import styled from 'styled-components/macro'
import { useClient } from 'urql'

interface Props {
  journalId: number
  refetchDocs: boolean
  resetRefetchDocs: () => void
}

interface CardContentInfo {
  description: string
  stakeholders?: Maybe<string>
}

const CardStyled = styled(S.Card.Container)<{ $nonClickable?: boolean }>`
  box-shadow: ${BOX_SHADOW_ON_WHITE_BG.default};

  &:first-child {
    margin-top: ${({ theme }) => theme.spacing(1)};
  }

  &:hover {
    box-shadow: ${({ $nonClickable }) =>
      $nonClickable ? BOX_SHADOW_ON_WHITE_BG.default : BOX_SHADOW_ON_WHITE_BG.hover};
  }
`

export const JournalCardContent = ({ journalId, refetchDocs, resetRefetchDocs }: Props) => {
  const { getMessage } = useMessageSource()

  const urqlClient = useClient()
  const notificationService = useNotificationService()
  const { backendUrl } = useEnvironment()

  const [cardContentInfo, setCardContentInfo] = useState<CardContentInfo>()
  const [fileUUIDToBeDeleted, setFileUUIDToBeDeleted] = useState<string>()
  const [fileDeleteConfirmationOpen, setFileDeleteConfirmationOpen] = useState(false)
  const [files, setFiles] = useState<File[]>()

  const fetchDocuments = useCallback(async () => {
    const { data } = await urqlClient
      .query<{ file: Query_Root['file'] }>(queryFiles, {
        fileType: FILE_TYPE.PROJECT_JOURNAL,
        entityType: ENTITY_TYPE.PROJECT_JOURNAL,
        entityId: journalId,
      })
      .toPromise()

    setFiles(data?.file)
  }, [journalId, urqlClient])

  useEffect(() => {
    const fetchData = async () => {
      const { data } = await urqlClient
        .query<{ project_journal_by_pk: Query_Root['project_journal_by_pk'] }>(queryProjectJournalSecondaryInfoById, {
          id: journalId,
        })
        .toPromise()

      if (data?.project_journal_by_pk) {
        setCardContentInfo({
          description: data.project_journal_by_pk.description,
          stakeholders: data.project_journal_by_pk.stakeholders,
        })
      } else {
        notificationService.operationFailed()
      }
    }

    fetchData().then(() => fetchDocuments())
  }, [fetchDocuments, journalId, notificationService, urqlClient])

  useEffect(() => {
    if (refetchDocs) {
      fetchDocuments().then(() => resetRefetchDocs())
    }
  }, [refetchDocs, fetchDocuments, resetRefetchDocs])

  const handleCancelDeleteFile = () => {
    setFileUUIDToBeDeleted(undefined)
    setFileDeleteConfirmationOpen(false)
  }

  const handleDeleteFile = (fileUUID: string) => () => {
    setFileUUIDToBeDeleted(fileUUID)
    setFileDeleteConfirmationOpen(true)
  }

  const handleConfirmDeleteFile = () => {
    if (fileUUIDToBeDeleted !== undefined) {
      FileService.remove(fileUUIDToBeDeleted)
        .then(() => {
          notificationService.deleteSuccessful()
          fetchDocuments()
        })
        .catch(() => {
          notificationService.operationFailed()
        })
      setFileUUIDToBeDeleted(undefined)
    }
    setFileDeleteConfirmationOpen(false)
  }

  return (
    <>
      {cardContentInfo && (
        <>
          <ReadOnlyTextField text={getMessage('label.project.journal.description')}>
            <HtmlRenderer html={cardContentInfo.description} />
          </ReadOnlyTextField>
          <ReadOnlyTextField text={getMessage('label.project.journal.stakeholders')}>
            {cardContentInfo.stakeholders && <HtmlRenderer html={cardContentInfo.stakeholders} />}
          </ReadOnlyTextField>
          <ReadOnlySelection text={getMessage('label.project.journal.documents')} isLast>
            {files && files.length > 0 ? (
              files.map((file) => (
                <CardStyled key={file.id}>
                  <DownloadLinkStyled href={`${backendUrl}/api/file/${file.uuid}`}>
                    <S.Card.Content>
                      <DownloadIcon fontSize="small" />
                      <DocumentContainer>
                        <Typography variant="subtitle2">{file.file_title}</Typography>
                        <Typography variant="body2" color="textSecondary">
                          ({file.file_name})
                        </Typography>
                      </DocumentContainer>
                      <Typography variant="body2" color="textSecondary">
                        {DateUtils.formatDate(DateUtils.parseDateGraphQL(file.upload_date))}
                      </Typography>
                    </S.Card.Content>
                  </DownloadLinkStyled>
                  <S.Card.Actions>
                    <IconButton color="primary" size="large" onClick={handleDeleteFile(file.uuid)}>
                      <DeleteIcon />
                    </IconButton>
                  </S.Card.Actions>
                </CardStyled>
              ))
            ) : (
              <CardStyled $nonClickable>
                <S.Card.Content>
                  <DefaultSectionTypography noEntriesMessageKey="label.file.not.available" $standAlone={true} />
                </S.Card.Content>
              </CardStyled>
            )}
          </ReadOnlySelection>
        </>
      )}

      <ConfirmationModalDialog
        open={fileDeleteConfirmationOpen}
        onCancel={handleCancelDeleteFile}
        onConfirm={handleConfirmDeleteFile}
      >
        {getMessage('label.delete.confirm')}
      </ConfirmationModalDialog>
    </>
  )
}
