import { GridToolbarContainer } from '@mui/x-data-grid-pro'
import styled, { css } from 'styled-components/macro'

export const GridToolbarContainerStyled = styled(GridToolbarContainer)<{
  $reporting?: boolean
  $noActionButton?: boolean
}>`
  && {
    justify-content: space-between;
    padding: ${({ theme }) => theme.spacing(2)};

    ${({ $reporting }) =>
      $reporting &&
      css`
        padding-inline: ${({ theme }) => theme.spacing(2)};
        padding-block: ${({ theme }) => theme.spacing(0.75)};
      `}

    ${({ $noActionButton }) =>
      $noActionButton &&
      css`
        margin-top: 5px;
      `}
  }
`
