import { difference, get, groupBy, isEqual } from 'lodash'
import { Feature_Config, Feature_Type_Config, Feature_Value, Kap_Measure, Project_Base } from 'src/@types/graphql'
import { isFeatureSelectionInvalid } from 'src/screens/shared/feature/utils/FeatureValidationUtils'
import { CheckboxData } from 'src/shared/form/control/CheckboxGroupField'
import { required, ValidationOutput } from 'src/shared/form/validation/validators'

export interface FormValues {
  [key: string]: FeatureGroup
}
export interface FeatureGroup {
  name?: string
  code?: string
  feature_group_id?: string
  features: number[]
  otherFeature?: {
    id: number
    selected: boolean
    text: string | undefined
  }
}

export interface RenderFeatureGroups {
  name: string
  code: string
  features: CheckboxData[]
  otherFeature?: {
    tooltip?: string
    id: number
    selected: boolean
    text: string
    label?: string | undefined
  }
}

const adaptForRendering = (
  allFeatures: Feature_Config[],
  allFeatureValues: Feature_Value[],
  locale: string,
  featureValidation?: boolean,
  featureTypeConfig?: Feature_Type_Config,
  relatedEntity?: Kap_Measure | Project_Base,
): RenderFeatureGroups[] => {
  const groupedByGroupId = groupBy(allFeatures, (x) => x.feature_group_config?.code ?? 'DEFAULT')
  const adapted: RenderFeatureGroups[] = []

  for (const [featureCode, nestedFeatures] of Object.entries(groupedByGroupId)) {
    // first we extract the `SELECT` features and we map them as a `Checkbox` data structure
    const checkBoxFields = nestedFeatures
      .filter((x) => x.selection_type === 'SELECT')
      .map((x) => ({
        label: get(x.names, locale, '') as string,
        value: x.id,
        tooltip: get(x.tooltips, locale, '') as string,
        active: x.active,
        disabled: featureValidation && isFeatureSelectionInvalid(featureTypeConfig, relatedEntity?.factsheet, x.id),
      }))
      .filter(
        (checkBox) =>
          allFeatureValues.some((feature) => feature.feature_config.id === checkBox.value) || checkBox.active,
      )

    // we create a section
    const section: RenderFeatureGroups = {
      name: get(nestedFeatures[0]?.feature_group_config?.names, locale, '') as string,
      code: featureCode,
      features: checkBoxFields,
    }

    const isThereOther = nestedFeatures.find((x) => x.selection_type === 'TEXT')

    if (isThereOther) {
      // there is at most 1 other feature per group (optional) -> we add it the section if it there is some
      const isOtherSelected = allFeatureValues.find((feature) => feature.feature_config.selection_type === 'TEXT')

      if (isThereOther?.active || isOtherSelected?.feature_config.id === isThereOther.id) {
        const otherFeature = {
          selected: false,
          text: '',
          id: isThereOther.id,
          label: get(isThereOther.names, locale, '') as string,
          tooltip: get(isThereOther.tooltips, locale, '') as string,
        }
        section.otherFeature = otherFeature
      }
    }
    adapted.push(section)
  }
  return adapted
}

const adaptInitialValues = (
  savedFeatureValues: Feature_Value[],
  featuresConfig: Feature_Config[],
  onlyAvailableInConfig: boolean,
): FormValues => {
  const groupedByGroupId = groupBy(savedFeatureValues, (x) => x.feature_config.feature_group_config?.code ?? 'DEFAULT')

  const formValues: FormValues = {}

  for (const [featureCode, nestedFeatures] of Object.entries(groupedByGroupId)) {
    let selections = nestedFeatures
      .filter((x) => x.feature_config.selection_type === 'SELECT')
      .map((x) => x.feature_config.id)
    // for setting initial checked values in the form, cross-check in the configuration
    if (onlyAvailableInConfig) {
      selections = selections.filter((featureConfigId) =>
        featuresConfig.some((featureConfig) => featureConfig.id === featureConfigId),
      )
    }
    const section: FeatureGroup = { features: selections }

    const isThereOther = nestedFeatures.find((x) => x.feature_config.selection_type === 'TEXT')
    if (isThereOther) {
      const otherFeature = {
        id: isThereOther.feature_config.id,
        selected: true,
        text: isThereOther.other_description || '',
      }
      const shouldMapOther =
        !onlyAvailableInConfig ||
        featuresConfig.some((featureConfig) => featureConfig.id === isThereOther.feature_config.id)
      if (shouldMapOther) {
        section.otherFeature = otherFeature
      }
    }
    formValues[featureCode] = section
  }

  return formValues
}

/**
 * Produces FormValues containing only the differences.
 *
 * @param currentValues current form values
 * @param initialValues initial form values
 * @param deleted should it search for deletions -> meaning the "source" will be "initialValues" and the "target" will be the "currentValues"
 * @returns {FormValues}
 */
const diffFormValues = (
  currentValues: FormValues,
  initialValues: FormValues,
  deleted: boolean,
): Partial<FormValues> => {
  const obj = Object.keys(currentValues).reduce((agg, key) => {
    agg[key] = {} as FeatureGroup
    if (deleted) {
      agg[key].features = difference(initialValues[key]?.features, currentValues[key]?.features)
    } else {
      agg[key].features = difference(currentValues[key]?.features, initialValues[key]?.features)
    }
    if (!isEqual(currentValues[key]?.otherFeature, initialValues[key]?.otherFeature)) {
      agg[key].otherFeature = currentValues[key].otherFeature || initialValues[key].otherFeature
    }
    return agg
  }, {} as FormValues)
  return obj
}

// partial representation of FeatureGroups with only other features `text` validation
interface FormErrors {
  [key: string]: {
    otherFeature: { text: ValidationOutput }
  }
}

const validateRequiredForOtherFeature = (values: FormValues): FormErrors => {
  const errors: FormErrors = {}
  for (const [featureCode, nestedFeatures] of Object.entries(values)) {
    if (nestedFeatures?.otherFeature?.selected) {
      const violation = required()(nestedFeatures.otherFeature.text as string)
      if (violation) {
        errors[featureCode] = { otherFeature: { text: violation } }
      }
    }
  }
  return errors
}

/**
 * This function is responsible for creating Filter_Value(s) based on difference between currentValues and initialFormValues.
 *
 * @param currentValues the current values
 * @param initialValues the initial values
 * @param featureBaseId the feature base id
 * @param deleted include other feature when this value is *NOT* equal to "otherFeature.selected" value.
 *        this property is also responsible for choosing the source `features` array to be diffed.
 * @param invalidOtherFeature if there is an invalid other feature - include it to the features for deletion.
 * @returns Feature_Value[]
 */
const createFeatureValues = (
  currentValues: FormValues,
  initialValues: FormValues,
  featureBaseId: number,
  deleted: boolean,
  invalidOtherFeature?: boolean,
): Feature_Value[] => {
  const diff = diffFormValues(currentValues, initialValues || {}, deleted)
  return Object.values(diff)
    .map((g) => {
      const features =
        g?.features?.map(
          (f) =>
            ({
              feature_config_id: f,
              feature_base_id: featureBaseId,
            } as Partial<Feature_Value>),
        ) ?? []
      const otherFeature = g?.otherFeature
      if (otherFeature?.selected === !deleted || (otherFeature && invalidOtherFeature)) {
        features.push({
          feature_config_id: otherFeature.id,
          other_description: otherFeature.text,
          feature_base_id: featureBaseId,
        })
      }

      return features
    })
    .flat()
    .filter(Boolean) as Feature_Value[]
}

export const FeatureBaseEditUtils = {
  adaptForRendering,
  adaptInitialValues,
  createFeatureValues,
  validateRequiredForOtherFeature,
  diffFormValues,
}
