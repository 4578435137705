import { LoadingButton } from '@mui/lab'
import { Typography } from '@mui/material'
import { GridColDef, useGridApiRef } from '@mui/x-data-grid-pro'
import { ReactElement, useCallback, useEffect, useMemo, useState } from 'react'
import { GoalResponseData, Mutation_Root, ReportInput } from 'src/@types/graphql'
import { useMessageSource } from 'src/i18n/useMessageSource'
import { PROJECT, PROJECT_TYPE } from 'src/shared/constants/constants'
import { DownloadIcon } from 'src/shared/icons/Icons'
import { S } from 'src/shared/styled/S'
import { useGridTranslateHook } from 'src/shared/utils/hooks/grid-translate-hook'
import { useNotificationService } from 'src/shared/utils/NotificationService'
import { ReportingGridResponseType, Utils } from 'src/shared/utils/Utils'
import { muiTheme } from 'src/theme/theme'
import { gql, useClient } from 'urql'

const fetchGoalsReportDataMutation = gql`
  mutation fetchGoalsReportData($report: ReportInput!, $isPfKap: Boolean!, $isPfPgv: Boolean!, $isKap: Boolean!) {
    fetchPfKapProjectGoalsReportData(report: $report) @include(if: $isPfKap) {
      data {
        year
        green
        orange
        red
        inactive
      }
    }
    fetchPfPgvProjectGoalsReportData(report: $report) @include(if: $isPfPgv) {
      data {
        year
        green
        orange
        red
        inactive
      }
    }
    fetchKapProgramGoalsReportData(report: $report) @include(if: $isKap) {
      data {
        year
        green
        orange
        red
        inactive
      }
    }
  }
`

interface Props {
  reportInput: ReportInput
  process: PROJECT_TYPE
  handleDownload: () => Promise<void>
}

export const ReportGoalGrid = ({ reportInput, process, handleDownload }: Props): ReactElement => {
  const gridTranslations = useGridTranslateHook()
  const { getMessage } = useMessageSource()
  const notificationService = useNotificationService()

  const apiRef = useGridApiRef()

  const urqlClient = useClient()

  const [goalsReport, setGoalsReport] = useState<ReportingGridResponseType[]>([])
  const [loadingDownload, setLoadingDownload] = useState<boolean>(false)

  const fetchData = useCallback(async () => {
    const isPfKap = process === PROJECT.PF_KAP
    const isPfPgv = process === PROJECT.PF_PGV
    const isKap = process === PROJECT.KAP

    const { data } = await urqlClient
      .mutation<{
        fetchPfKapProjectGoalsReportData: Mutation_Root['fetchPfKapProjectGoalsReportData']
        fetchPfPgvProjectGoalsReportData: Mutation_Root['fetchPfPgvProjectGoalsReportData']
        fetchKapProgramGoalsReportData: Mutation_Root['fetchKapProgramGoalsReportData']
      }>(fetchGoalsReportDataMutation, { report: reportInput, isPfKap, isPfPgv, isKap })
      .toPromise()
    if (data) {
      let reportData: GoalResponseData[]
      switch (process) {
        case 'PF_KAP':
          reportData = data?.fetchPfKapProjectGoalsReportData?.data
          break
        case 'PF_PGV':
          reportData = data?.fetchPfPgvProjectGoalsReportData?.data
          break
        case 'KAP':
          reportData = data?.fetchKapProgramGoalsReportData?.data
      }

      const goalsReport = Utils.extendAnnualRatingReportArrayWithPrevAndNextValues(reportData, reportInput)

      setGoalsReport(goalsReport)
    } else {
      notificationService.operationFailed()
    }
  }, [urqlClient, reportInput, notificationService, process])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  const columns = useMemo<GridColDef[]>(
    () => [
      {
        field: 'rating',
        headerName: getMessage('label.rating'),
        flex: 1,
        sortable: false,
        minWidth: 150,
        type: 'string',
        renderCell({ value }) {
          return (
            <Typography variant="subtitle2" sx={{ color: muiTheme.palette.text.disabled }}>
              {value}
            </Typography>
          )
        },
      },
      ...goalsReport.map((report) => ({
        field: `${report.year}`,
        headerName: `${report.year}`,
        flex: 1,
        sortable: false,
        minWidth: 90,
        type: 'number',
      })),
    ],
    [getMessage, goalsReport],
  )

  const rows = useMemo(
    () => [
      {
        id: 1,
        rating: getMessage('label.reporting.goal.rating.GREEN'),
        ...goalsReport.reduce((obj, item) => ({ ...obj, [item.year]: item.green }), {}),
      },
      {
        id: 2,
        rating: getMessage('label.reporting.goal.rating.ORANGE'),
        ...goalsReport.reduce((obj, item) => ({ ...obj, [item.year]: item.orange }), {}),
      },
      {
        id: 3,
        rating: getMessage('label.reporting.goal.rating.RED'),
        ...goalsReport.reduce((obj, item) => ({ ...obj, [item.year]: item.red }), {}),
      },
      {
        id: 4,
        rating: getMessage('label.reporting.goal.rating.INACTIVE'),
        ...goalsReport.reduce((obj, item) => ({ ...obj, [item.year]: item.inactive }), {}),
      },
    ],
    [getMessage, goalsReport],
  )

  const onDownloadHandle = async () => {
    setLoadingDownload(true)
    await handleDownload()
    setLoadingDownload(false)
  }

  return (
    <S.DataGrid.ScrollDataGridStyled
      apiRef={apiRef}
      rows={rows}
      columns={columns}
      initialState={{ pinnedColumns: { left: ['rating'] } }}
      autoHeight
      localeText={gridTranslations}
      disableColumnResize
      disableColumnSelector
      disableColumnReorder
      disableRowSelectionOnClick
      disableColumnMenu
      disableColumnFilter
      disableMultipleColumnsSorting
      hideFooter
      slots={{
        toolbar: () => (
          <S.DataGrid.ToolbarContainer $reporting>
            <Typography>{getMessage('label.reporting.goals')}</Typography>
            <LoadingButton
              variant="text"
              color="primary"
              startIcon={<DownloadIcon />}
              onClick={onDownloadHandle}
              loading={loadingDownload}
            >
              {getMessage('button.download')}
            </LoadingButton>
          </S.DataGrid.ToolbarContainer>
        ),
      }}
    />
  )
}
