import { gql } from 'urql'

export const CHANGE_PROJECT_LEADER_MUTATION = gql`
  mutation ($newProjectLeaderId: Int!, $projectLeaderRole: String!, $newProjectBaseId: Int!) {
    set_current_project_admin: update_project_user(
      where: { _and: [{ user_id: { _eq: $newProjectLeaderId } }, { project_base_id: { _eq: $newProjectBaseId } }] }
      _set: { type: $projectLeaderRole }
    ) {
      affected_rows
    }
  }
`

export const SET_PREVIOUS_PROJECT_LEADER_AS_CONTRIBUTOR_MUTATION = gql`
  mutation ($previousProjectLeaderId: Int!, $projectContributorRole: String!, $prevProjectBaseId: Int!) {
    set_previous_project_admin_as_contributor: update_project_user(
      where: {
        _and: [{ user_id: { _eq: $previousProjectLeaderId } }, { project_base_id: { _eq: $prevProjectBaseId } }]
      }
      _set: { type: $projectContributorRole }
    ) {
      affected_rows
    }
  }
`
