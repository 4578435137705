import { useCallback, useEffect, useState } from 'react'
import { Mutation_Root, Project_Base_Bool_Exp, Query_Root } from 'src/@types/graphql'
import { CommitteeDecisionValues } from 'src/screens/administration/round-management/details/round-information/committee-decision-management/CommitteeDecisionManagementService'
import {
  ApplicationCommitteeUtils,
  CommitteeValues,
} from 'src/screens/shared/application/committee/ApplicationCommiteeUtils'
import {
  fetchCommitteeDataQuery,
  saveCommitteeDataMutationQuery,
} from 'src/screens/shared/application/committee/committeeQueries'
import {
  COMMITTEE_FORM_MODE,
  COMMITTEE_FORM_MODE_TYPE,
  GLOBAL_USER_ROLES,
  PROJECT,
  PROJECT_TYPE,
  USER_ROLES_TYPE,
  USER_STATUS,
} from 'src/shared/constants/constants'
import { Option } from 'src/shared/form/control'
import { useNotificationService } from 'src/shared/utils/NotificationService'
import { useClient } from 'urql'

export const useResolveCommitteeData = (
  projectId: number | undefined,
  projectBaseId: number | null,
  process: PROJECT_TYPE | undefined,
  isEdit: boolean,
  variant: COMMITTEE_FORM_MODE_TYPE,
  baseUrl?: '/pf-kap' | '/pf-pgv',
) => {
  const [optionValues, setOptionValues] = useState<{
    exclusionCriteriaOptions: Option[]
    qualityCriteriaOptions: Option[]
    gfchResponsibleOptions: Option[]
  }>({ exclusionCriteriaOptions: [], qualityCriteriaOptions: [], gfchResponsibleOptions: [] })
  const [initialFormValues, setInitialFormValues] = useState<CommitteeValues | CommitteeDecisionValues>()
  const urqlClient = useClient()

  useEffect(() => {
    const initializeData = async () => {
      if (projectId && projectBaseId && process) {
        const projectPfKapBaseWhereClause: Project_Base_Bool_Exp = {
          pf_kap_projects: {
            id: {
              _eq: projectId,
            },
          },
        }

        const projectPfPgvBaseWhereClause: Project_Base_Bool_Exp = {
          pf_pgv_projects: {
            id: {
              _eq: projectId,
            },
          },
        }

        const projectBaseWhere = process === PROJECT.PF_KAP ? projectPfKapBaseWhereClause : projectPfPgvBaseWhereClause
        const { data } = await urqlClient
          .query<
            {
              committee_config: Query_Root['committee_config']
              project_committee_recommendation: Query_Root['project_committee_recommendation']
              project_application_decision: Query_Root['project_application_decision']
              criteria_config: Query_Root['criteria_config']
              user: Query_Root['user']
            },
            {
              process: PROJECT_TYPE
              projectBaseWhere: Project_Base_Bool_Exp
              role: USER_ROLES_TYPE
              isEdit: boolean
              userStatus: typeof USER_STATUS.ACTIVE
            }
          >(fetchCommitteeDataQuery, {
            process: process,
            projectBaseWhere: projectBaseWhere,
            role:
              process === PROJECT.PF_KAP
                ? GLOBAL_USER_ROLES['PD-GFCH_PF-KAP_CONTRIBUTOR']
                : GLOBAL_USER_ROLES['PD-GFCH_PF-PGV_CONTRIBUTOR'],
            isEdit: isEdit,
            userStatus: USER_STATUS.ACTIVE,
          })
          .toPromise()

        if (data) {
          const criteriaOptions = ApplicationCommitteeUtils.getCriteriaOptions(data.criteria_config)
          let gfchResponsibleOptions: { label: string; value: number }[] = []
          if (isEdit) {
            gfchResponsibleOptions = ApplicationCommitteeUtils.getGFCHResponsibleOptions(data.user)
          }
          setOptionValues({
            exclusionCriteriaOptions: criteriaOptions.exclusionCriteriaOptions,
            qualityCriteriaOptions: criteriaOptions.qualityCriteriaOptions,
            gfchResponsibleOptions: gfchResponsibleOptions,
          })

          if (variant === COMMITTEE_FORM_MODE.PROJECT_APPLICATION_COMMITTEE) {
            setInitialFormValues(ApplicationCommitteeUtils.adaptAsFormValues(projectBaseId, data))
          } else if (variant === COMMITTEE_FORM_MODE.ROUND_MANAGEMENT_COMMITTEE) {
            setInitialFormValues(ApplicationCommitteeUtils.adaptAsFormValues(projectBaseId, data, projectId))
          }
        }
      }
    }
    initializeData()
  }, [projectId, projectBaseId, process, urqlClient, baseUrl, isEdit, variant])

  return {
    initialFormValues,
    optionValues,
    setInitialFormValues,
  }
}
type originType = 'SAVE' | 'SAVE_AND_BACK'

export const useUpdateCommitteeData = () => {
  const urqlClient = useClient()
  const notificationService = useNotificationService()

  return useCallback(
    async (
      values: CommitteeValues | CommitteeDecisionValues,
      projectBaseId: number | null,
      setInitialFormValues: React.Dispatch<React.SetStateAction<CommitteeValues | CommitteeDecisionValues | undefined>>,
      originRef: React.MutableRefObject<originType>,
      onBack: any,
    ) => {
      const updateValues = ApplicationCommitteeUtils.readFormValues(values)
      const { error, data } = await urqlClient
        .mutation<{
          insert_project_committee_recommendation: Mutation_Root['insert_project_committee_recommendation']
          insert_project_application_decision: Mutation_Root['insert_project_application_decision']
          update_project_base: Mutation_Root['update_project_base']
        }>(saveCommitteeDataMutationQuery, {
          projectBaseId: projectBaseId,
          projectCommitteeRecommendations: updateValues.projectCommitteeRecommendations,
          projectApplicationDecision: updateValues.applicationDecision,
          gfchResponsibleId: updateValues.gfchResponsibleId,
        })
        .toPromise()

      if (error || data?.insert_project_application_decision?.affected_rows !== 1) {
        notificationService.operationFailed()
        return Promise.reject(error)
      } else {
        const newVersion = data?.insert_project_application_decision?.returning[0]?.version
        setInitialFormValues({ ...values, version: newVersion })
        notificationService.changesSaved()
        if (originRef.current === 'SAVE_AND_BACK') {
          onBack()
        }
      }
    },
    [notificationService, urqlClient],
  )
}
