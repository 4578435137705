import { DialogActions, DialogContent } from '@mui/material'
import { GridRowSelectionModel, useGridApiRef } from '@mui/x-data-grid-pro'
import { SyntheticEvent, useState } from 'react'
import { Query_Root } from 'src/@types/graphql'
import {
  insertRelatedEvaluationMutation,
  queryDossiers,
} from 'src/screens/shared/project/details/effectivity-management/effectivityManagementQueries'
import { RelatedEvaluationGrid } from 'src/screens/shared/project/details/effectivity-management/related-evaluation/RelatedEvaluationGrid'
import { PrimaryButton, SecondaryButton } from 'src/shared/button/Buttons'
import { PROJECT, PROJECT_TYPE } from 'src/shared/constants/constants'
import { HelpAndInstructions } from 'src/shared/presentation/HelpAndInstructions'
import { useNotificationService } from 'src/shared/utils/NotificationService'
import { Utils } from 'src/shared/utils/Utils'
import { useUser } from 'src/user/UserContext'
import { useClient, useQuery } from 'urql'

interface Props {
  onCancel: () => void
  onSuccess: () => void
  process: PROJECT_TYPE
  dossierId: number
  relatedEvaluations: number[]
}

export const RelatedEvaluationModalDialog = ({
  onCancel,
  onSuccess,
  process,
  dossierId,
  relatedEvaluations,
}: Props) => {
  const { user } = useUser()
  const userId = user.id
  const isGfch = Utils.isInternalRole(user.roles)
  const gridRef = useGridApiRef()
  const [selectionModel, setSelectionModel] = useState<GridRowSelectionModel>([])
  const urqlClient = useClient()
  const notificationService = useNotificationService()

  const [{ data, fetching }] = useQuery<
    { dossier: Query_Root['dossier'] },
    { process: PROJECT_TYPE; related: number[]; isPfKap: boolean; isPfPgv: boolean }
  >({
    query: queryDossiers,
    variables: {
      process,
      related: relatedEvaluations,
      isPfKap: process === PROJECT.PF_KAP,
      isPfPgv: process === PROJECT.PF_PGV,
    },
  })

  const dossiers =
    data?.dossier
      .filter((d) => d.id !== dossierId)
      .filter((d) =>
        isGfch
          ? true
          : d.project_bases?.[0].project_users?.some(
              (u) => u.user_id === userId && ['EXTERNAL_ADMIN', 'EXTERNAL_CONTRIBUTOR'].includes(u.type),
            ),
      ) ?? []

  const onSubmit = () => {
    document
      .getElementById('related-evaluation-form')
      ?.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }))
  }

  const submitHandler = async (event: SyntheticEvent) => {
    event.preventDefault()
    const rows = gridRef.current?.getSelectedRows()
    const objects = [...rows.values()].map((i) => ({
      dossier_id: dossierId,
      related_dossier_id: i.id,
    }))

    const { error } = await urqlClient.mutation(insertRelatedEvaluationMutation, { objects }).toPromise()

    if (error) {
      notificationService.operationFailed()
    } else {
      notificationService.changesSaved()
      onSuccess()
    }
  }

  return (
    <>
      <DialogContent>
        {!fetching && data && (
          <>
            <HelpAndInstructions labelKey={`label.help.related.evaluation.${process}`} defaultExpansion />
            <form
              id="related-evaluation-form"
              onSubmit={submitHandler}
              onKeyDown={(event) => {
                if (event.key === 'Enter') {
                  event.preventDefault()
                }
              }}
            >
              <RelatedEvaluationGrid
                dossiers={dossiers}
                gridRef={gridRef}
                selectionModel={selectionModel}
                setSelectionModel={setSelectionModel}
                process={process.toLowerCase()}
              />
            </form>
          </>
        )}
      </DialogContent>
      <DialogActions>
        <SecondaryButton messageKey={'button.cancel'} onClick={onCancel} />
        <PrimaryButton
          messageKey={'button.link.evaluation'}
          disabled={selectionModel.length === 0}
          onClick={onSubmit}
        />
      </DialogActions>
    </>
  )
}
