import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import { ReactElement } from 'react'
import { useParams } from 'react-router-dom'
import { Query_Root } from 'src/@types/graphql'
import { useMessageSource } from 'src/i18n/useMessageSource'
import { ROUTES } from 'src/routing/routes'
import { ProjectResponsibleAndPartnerOrganizations } from 'src/screens/shared/project/details/basic-information/organization/ProjectResponsibleAndPartnerOrganizations'
import { usePermissionsForProject } from 'src/service/security/PermissionHook'
import { EditButton } from 'src/shared/button/Buttons'
import { PROJECT, USER_STATUS } from 'src/shared/constants/constants'
import { PageLayout } from 'src/shared/layout/PageLayout'
import { ScreenLayout } from 'src/shared/layout/ScreenLayout'
import { ProjectExportMenu } from 'src/shared/menu/ProjectExportMenu'
import { HelpAndInstructions } from 'src/shared/presentation/HelpAndInstructions'
import { HtmlRenderer } from 'src/shared/presentation/HtmlRenderer'
import { ReadOnlySelection, ReadOnlyTextField } from 'src/shared/presentation/ReadOnly'
import { Section } from 'src/shared/presentation/Section'
import { DateUtils } from 'src/shared/utils/DateUtils'
import { useDelayedNavigate } from 'src/shared/utils/hooks/navigation-hooks'
import { useNotificationService } from 'src/shared/utils/NotificationService'
import { gql, useQuery } from 'urql'

const queryPfPgvProjectById = gql`
  query ($pf_pgv_id: Int!) {
    pf_pgv_project_by_pk(id: $pf_pgv_id) {
      id
      application_type {
        key
      }

      project_base {
        id
        dossier {
          id
          title
          short_title
          status
          description
          start_date
          end_date
        }
        user {
          first_name
          last_name
          email
          status
        }
        project_organizations(order_by: { organization: { name: asc } }) {
          organization_id
          organization {
            name
          }
        }
        organization {
          id
          name
        }
      }
    }
  }
`

export const PfPgvBasicInformationPage = (): ReactElement => {
  const { projectId } = useParams()
  const pf_pgv_id = parseInt(projectId as string)
  const { getMessage } = useMessageSource()
  const notificationService = useNotificationService()
  const projectType = PROJECT.PF_PGV
  const {
    loading,
    canEdit,
    metadata: { projectBaseId },
  } = usePermissionsForProject(projectType, pf_pgv_id)
  const [{ data, error }, refetch] = useQuery<
    { pf_pgv_project_by_pk: Query_Root['pf_pgv_project_by_pk'] },
    { pf_pgv_id: number }
  >({
    query: queryPfPgvProjectById,
    variables: { pf_pgv_id },
  })
  const pf_pgv_project_data = data?.pf_pgv_project_by_pk
  if (error) {
    notificationService.operationFailed()
  }

  const navigate = useDelayedNavigate()

  const handleEditButtonClick = (projectId: number) => () => {
    navigate(ROUTES.PfPgvDetailsRoot.nested.BasicInformationEdit.params({ projectId }))
  }

  return (
    <ScreenLayout
      title={getMessage('label.navigation.project.details')}
      actions={<ProjectExportMenu process={PROJECT.PF_PGV} entityId={projectBaseId as number} />}
    >
      <PageLayout>
        <>
          {!loading && pf_pgv_project_data && (
            <>
              <Section
                id="project-information"
                title={getMessage('label.project.information')}
                actionButton={
                  <EditButton
                    origin="content"
                    color="primary"
                    onClick={handleEditButtonClick(pf_pgv_id)}
                    hidden={!canEdit}
                  />
                }
                helpAndInstructions={
                  <HelpAndInstructions labelKey="label.help.basic.information.project.information.pf_pgv" />
                }
              >
                <Card>
                  <CardContent>
                    <Box key={pf_pgv_project_data.id}>
                      <ReadOnlyTextField text={getMessage('label.project.id')}>
                        {`${getMessage('label.project.id.character.pf_pgv')}${pf_pgv_project_data.id}`}
                      </ReadOnlyTextField>
                      <ReadOnlyTextField text={getMessage('label.title')}>
                        {pf_pgv_project_data.project_base.dossier.title}
                      </ReadOnlyTextField>
                      <ReadOnlyTextField text={getMessage('label.short.title')}>
                        {pf_pgv_project_data.project_base.dossier.short_title}
                      </ReadOnlyTextField>
                      <ReadOnlyTextField text={getMessage('label.description')}>
                        {pf_pgv_project_data?.project_base?.dossier?.description && (
                          <HtmlRenderer html={pf_pgv_project_data?.project_base?.dossier?.description} />
                        )}
                      </ReadOnlyTextField>
                      <ReadOnlySelection text={getMessage('label.application_type')}>
                        {getMessage(pf_pgv_project_data.application_type.key)}
                      </ReadOnlySelection>
                      <ReadOnlySelection text={getMessage('label.project.start.date')}>
                        {pf_pgv_project_data?.project_base?.dossier?.start_date &&
                          DateUtils.parseAndFormatDate(pf_pgv_project_data!.project_base.dossier.start_date)}
                      </ReadOnlySelection>
                      <ReadOnlySelection text={getMessage('label.project.end.date')}>
                        {pf_pgv_project_data?.project_base?.dossier?.end_date &&
                          DateUtils.parseAndFormatDate(pf_pgv_project_data!.project_base.dossier.end_date)}
                      </ReadOnlySelection>
                      <ReadOnlySelection text={getMessage('label.project.leader')} isLast={true}>
                        {/* if a GFCH Contributor creates the project, there won't be a project leader initially */}
                        {pf_pgv_project_data?.project_base?.user &&
                          (pf_pgv_project_data?.project_base?.user?.status === USER_STATUS.ACTIVE
                            ? `${pf_pgv_project_data?.project_base?.user.first_name} ${pf_pgv_project_data?.project_base?.user.last_name}, ${pf_pgv_project_data?.project_base?.user.email}`
                            : `${getMessage('label.user.unknown')}, ${pf_pgv_project_data?.project_base?.user?.email}`)}
                      </ReadOnlySelection>
                    </Box>
                  </CardContent>
                </Card>
              </Section>

              <ProjectResponsibleAndPartnerOrganizations
                projectId={pf_pgv_project_data.id}
                baseUrl={'/pf-pgv'}
                projectBase={pf_pgv_project_data.project_base}
                refetch={refetch}
                canEdit={canEdit}
              />
            </>
          )}
        </>
      </PageLayout>
    </ScreenLayout>
  )
}
