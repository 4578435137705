import { Chip } from '@mui/material'
import { TYPOGRAPHY_MIXIN } from 'src/shared/constants/styling-constants'
import styled from 'styled-components/macro'

export const ModuleChipStyled = styled(Chip)`
  cursor: pointer;
  min-width: initial;
  &.MuiChip-filledDefault {
    background-color: ${({ theme }) => theme.colors.action.hover};
    color: ${({ theme }) => theme.colors.text.disabled};
  }
  & .MuiChip-label {
    ${TYPOGRAPHY_MIXIN.subtitle2};
    line-height: initial;
  }
`
