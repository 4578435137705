import { Dictionary, groupBy } from 'lodash'
import { InternationalizedObject } from 'src/@types'
import { Criteria_Config, Query_Root } from 'src/@types/graphql'

export const EXCLUSION_PREFIX = 'EXCLUSION_'
export const QUALITY_PREFIX = 'QUALITY_'

export interface CriteriaFormValues {
  [key: string]: boolean | number | string | undefined
  version?: number
}

export type CriteriaGroups = Dictionary<Array<Criteria_Config & { code: string; names: InternationalizedObject }>>

interface CriteriaGroupsAndData {
  criteriaGroups: CriteriaGroups
  initialValues: CriteriaFormValues
}

export const createGroupsAndInitialData = (
  criteriaSelections:
    | Query_Root['project_assessment_criteria_selection']
    | Query_Root['milestone_assessment_criteria_selection']
    | undefined,
  criteriaGroups: Query_Root['criteria_group_config'] | undefined,
  prefix: string,
  applicationCodeId: number | undefined,
  version?: number | undefined,
): CriteriaGroupsAndData => {
  /**
   * The structure we have *array of criteria groups, that has nested **array as
   * elements that are criteria configs and we need to filter the nested **array
   * to get the desired filter/merge of:
   *
   * 1. All active criteria configs
   * 2. Selected configs that are not active
   * 3. Filtered by application type id of the criteria config and the application type id of the project
   *
   * Example:
   * [ { id: 1, name: ..., criteria_configs:[ { id: 1, active: true }, { id: 2, active: false } ]} ]
   *
   */
  const activeOrSelectedAndFilteredByApplicationCode = criteriaGroups
    ?.flatMap((c) => c.criteria_configs.map((cc) => ({ ...cc, code: c.code, names: c.names })))
    .filter((c) => c.active || criteriaSelections?.some((s) => s.criteria_config_id === c.id))
    .filter((c) => c.application_type_ids?.includes(applicationCodeId))
  const groupConfigs = groupBy(activeOrSelectedAndFilteredByApplicationCode, (c) => c.code)

  const initialValues = (criteriaSelections as any)?.reduce(
    (aggregate: CriteriaFormValues, current: any) => {
      aggregate[`${prefix}${current.criteria_config_id}`] = current.rating ?? true
      return aggregate
    },
    { version: version || 0 },
  ) as CriteriaFormValues

  return {
    criteriaGroups: groupConfigs,
    initialValues,
  }
}
