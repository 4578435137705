import { LoadingButton } from '@mui/lab'
import { Box, Chip, Typography } from '@mui/material'
import {
  DataGridPro,
  GridColDef,
  GridPaginationModel,
  GridRowModel,
  GridSortModel,
  useGridApiRef,
} from '@mui/x-data-grid-pro'
import { ReactElement, useMemo, useState } from 'react'
import { FilteredFactsheetResponseData } from 'src/@types/graphql'
import { useMessageSource } from 'src/i18n/useMessageSource'
import { ROUTES } from 'src/routing/routes'
import { usePermissionsForFactsheets } from 'src/service/security/PermissionHook'
import { DownloadIcon } from 'src/shared/icons/Icons'
import { HtmlRenderer } from 'src/shared/presentation/HtmlRenderer'
import { S } from 'src/shared/styled/S'
import { useGridTranslateHook } from 'src/shared/utils/hooks/grid-translate-hook'
import { useDelayedNavigate } from 'src/shared/utils/hooks/navigation-hooks'
import styled from 'styled-components/macro'

const DataGridProStyled = styled(DataGridPro)`
  & .MuiDataGrid-row {
    &:hover {
      cursor: pointer;
    }
  }
`
interface Props {
  factsheets: FilteredFactsheetResponseData[]
  onDownloadHandle: () => void
  factsheetsCount: number | undefined
}

export const FactsheetsIndexGrid = ({ factsheets, onDownloadHandle, factsheetsCount }: Props): ReactElement => {
  const navigate = useDelayedNavigate()
  const gridTranslations = useGridTranslateHook()
  const gridRef = useGridApiRef()
  const { getMessage } = useMessageSource()
  const [loadingDownload, setLoadingDownload] = useState(false)
  const [sortModel, setSortModel] = useState<GridSortModel>([
    {
      field: 'id',
      sort: 'desc',
    },
  ])
  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({ page: 0, pageSize: 10 })

  const { canViewFactsheetStatus, canDownloadFactsheetsReport } = usePermissionsForFactsheets()

  const rows = useMemo(() => {
    return factsheets.map((factsheet) => {
      return {
        ...factsheet,
        status: getMessage(`label.factsheet.status.${factsheet.status}`),
      }
    })
  }, [factsheets, getMessage])

  const handleRowClick = (params: GridRowModel) => {
    const factsheetId = params?.id
    navigate(ROUTES.FactsheetDescriptionRoot.nested.FactsheetDescription.params({ factsheetId }))
  }

  const columns = useMemo<GridColDef[]>(
    () => [
      {
        field: 'id',
        flex: 0.5,
        headerName: getMessage('label.id'),
        renderCell: ({ row }) => `${getMessage('label.factsheet.id.character')}${row.id}`,
      },
      {
        field: 'title',
        flex: 2,
        headerName: getMessage('label.title'),
      },
      {
        field: 'linkedEntities',
        flex: 0.5,
        headerName: getMessage('label.factsheet.links'),
        renderCell: ({ row }) => {
          return <Chip label={row.linkedEntities} color="secondary" variant="filled" />
        },
      },
      {
        field: 'description',
        flex: 2,
        headerName: getMessage('label.description'),
        renderCell: ({ row }) => {
          return <HtmlRenderer html={row.description} truncate variant="body2" />
        },
      },
      {
        field: 'status',
        flex: 0.5,
        headerName: getMessage('label.status'),
      },
    ],
    [getMessage],
  )

  const localHandleDownload = async () => {
    setLoadingDownload(true)
    await onDownloadHandle()
    setLoadingDownload(false)
  }

  return (
    <DataGridProStyled
      rows={rows}
      columns={columns}
      autoHeight
      apiRef={gridRef}
      localeText={gridTranslations}
      onRowClick={(props) => handleRowClick(props)}
      disableRowSelectionOnClick
      columnVisibilityModel={{
        status: canViewFactsheetStatus,
      }}
      disableColumnFilter
      disableColumnReorder
      disableColumnSelector
      disableColumnPinning
      paginationModel={paginationModel}
      onPaginationModelChange={(model) => setPaginationModel(model)}
      pageSizeOptions={[10, 25, 50]}
      pagination
      sortModel={sortModel}
      onSortModelChange={(model) => setSortModel(model)}
      slots={{
        toolbar: () => (
          <S.DataGrid.ToolbarContainer>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Typography variant="h3">{getMessage('label.factsheets.overview')}</Typography>
              {factsheetsCount !== undefined && (
                <Chip
                  label={`${factsheetsCount} ${getMessage('label.number.of.factsheets')}`}
                  variant="filled"
                  color="secondary"
                  sx={{ marginLeft: 2 }}
                />
              )}
            </Box>
            {canDownloadFactsheetsReport && (
              <LoadingButton
                variant="text"
                startIcon={<DownloadIcon />}
                onClick={localHandleDownload}
                loading={loadingDownload}
              >
                {getMessage('button.download')}
              </LoadingButton>
            )}
          </S.DataGrid.ToolbarContainer>
        ),
      }}
    />
  )
}
