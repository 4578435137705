import { Box, Stack } from '@mui/material'
import { useMemo } from 'react'
import { Form } from 'react-final-form'
import { OnChange } from 'react-final-form-listeners'
import { SupportedLocale } from 'src/@types'
import { useEnvironment } from 'src/env/EnvironmentStore'
import { useMessageSource } from 'src/i18n/useMessageSource'
import {
  GIRDERS_AUTH_NAME_REGEX,
  GIRDERS_AUTH_NAME_REGEX_DESCRIPTIVE_ERROR_MESSAGE_KEY,
} from 'src/screens/public/register/RegisterNewUserModal'
import {
  ALL_MODULES,
  CANTONS,
  GLOBAL_USER_ROLES,
  GLOBAL_USER_ROLES_TRANSLATION_MAP,
  LANGUAGES,
  TEXT_LENGTH,
  USER_TITLE,
  USER_TYPE,
} from 'src/shared/constants/constants'
import { ENVIRONMENT } from 'src/shared/constants/environment-constants'
import { Option } from 'src/shared/form/control'
import { AutoCompleteField } from 'src/shared/form/control/AutoCompleteField'
import { MultiSelectField } from 'src/shared/form/control/MultiSelectField'
import { TextField } from 'src/shared/form/control/TextField'
import { DirtyFormSpy } from 'src/shared/form/dirty/DirtyFormSpy'
import { createDecorators } from 'src/shared/form/utils/decorators'
import {
  composeValidators,
  maxChar,
  minChar,
  required,
  validEmail,
  validPattern,
} from 'src/shared/form/validation/validators'

interface Props {
  initialValues: AdministrationUserFormValues | undefined
  onSave: (values: AdministrationUserFormValues) => void
  mode: string
}

export interface AdministrationUserFormValues {
  type: 'GFCH' | 'EXTERNAL_EXPERT' | 'CANTON' | 'EXTERNAL'
  title: 'MR' | 'MRS' | 'NEUTRAL'
  firstName: string
  lastName: string
  language: SupportedLocale
  email: string
  roles: string[]
  gfchCantonalResponsibility?: string[]
  cantonalUserCanton?: string
  strategicResponsibility?: string[]
  operationalResponsibility?: string[]
}

const decorators = createDecorators()

export const AdministrationUserForm = ({ initialValues, onSave, mode }: Props) => {
  const { getMessage } = useMessageSource()
  const { environment } = useEnvironment()

  const typeOptions: Option[] = useMemo(
    () => [
      { label: getMessage('label.user.type.gfch'), value: USER_TYPE.GFCH },
      { label: getMessage('label.role.external.expert'), value: USER_TYPE.EXTERNAL_EXPERT },
      { label: getMessage('label.user.type.canton'), value: USER_TYPE.CANTON },
      { label: getMessage('label.role.external.user'), value: USER_TYPE.EXTERNAL },
    ],
    [getMessage],
  )

  const titleOptions: Option[] = useMemo(
    () => [
      { label: getMessage('label.mister'), value: USER_TITLE.MR },
      { label: getMessage('label.miss'), value: USER_TITLE.MRS },
      { label: getMessage('label.neutral'), value: USER_TITLE.NEUTRAL },
    ],
    [getMessage],
  )

  const gfchRolesOptions: Option[] = useMemo(
    () =>
      GLOBAL_USER_ROLES_TRANSLATION_MAP.map((role, index) => ({
        label: getMessage(role.messageKey),
        value: role.role,
        sortNumber: index,
      }))
        .filter(
          (r) =>
            r.value !== GLOBAL_USER_ROLES['PD-EXT_EXPERT'] &&
            r.value !== GLOBAL_USER_ROLES['PD-EXT_USER'] &&
            r.value !== GLOBAL_USER_ROLES['PD-SUCCESS_FACTOR_CONTRIBUTOR'],
        )
        .filter((r) => !r.value.includes('CANTON_KAP')),
    [getMessage],
  )

  const languageOptions: Option[] = useMemo(() => {
    const options = [
      { label: getMessage('label.language.de'), value: LANGUAGES.DE.toLowerCase() },
      { label: getMessage('label.language.fr'), value: LANGUAGES.FR.toLowerCase() },
      { label: getMessage('label.language.it'), value: LANGUAGES.IT.toLowerCase() },
    ]

    if (environment === ENVIRONMENT.DEV) {
      options.push({ label: getMessage('label.language.en'), value: LANGUAGES.EN.toLowerCase() })
    }

    return options
  }, [environment, getMessage])

  const cantonalRolesOptions: Option[] = useMemo(() => {
    const includedRoles = [
      GLOBAL_USER_ROLES['PD-CANTON_KAP_CONTRIBUTOR'],
      GLOBAL_USER_ROLES['PD-CANTON_KAP_READER'],
      GLOBAL_USER_ROLES['PD-SUCCESS_FACTOR_CONTRIBUTOR'],
    ]

    return GLOBAL_USER_ROLES_TRANSLATION_MAP.filter((mapItem) =>
      includedRoles.some((role) => mapItem.role.includes(role)),
    ).map((role, index) => ({
      label: getMessage(role.messageKey),
      value: role.role,
      sortNumber: index,
    }))
  }, [getMessage])

  const modulesOptions: Option[] = useMemo(
    () =>
      ALL_MODULES.map((m) => ({
        label: m.startsWith('LIFE_PHASE') ? `${getMessage(`label.module.${m}`)}` : `${getMessage('label.module')} ${m}`,
        value: m,
      })),
    [getMessage],
  )

  const cantonOptions: Option[] = useMemo(
    () =>
      Object.keys(CANTONS).map((c) => ({
        label: c,
        value: c,
      })),
    [],
  )

  const hasKapRoleSelected = (roles: string[]): boolean => {
    return [
      GLOBAL_USER_ROLES['PD-GFCH_KAP_COORDINATOR'],
      GLOBAL_USER_ROLES['PD-GFCH_KAP_CONTRIBUTOR'],
      GLOBAL_USER_ROLES['PD-GFCH_KAP_READER'],
    ].some((r) => roles.includes(r))
  }

  return (
    <Box>
      <Form<AdministrationUserFormValues>
        initialValues={initialValues}
        onSubmit={onSave}
        decorators={decorators}
        mutators={{
          clearRoles: (_, state, utils) => {
            utils.changeValue(state, 'roles', () => [])
          },
          setExternalExpert: (_, state, utils) => {
            utils.changeValue(state, 'roles', () => [GLOBAL_USER_ROLES['PD-EXT_EXPERT']])
          },
          setExternalUser: (_, state, utils) => {
            utils.changeValue(state, 'roles', () => [GLOBAL_USER_ROLES['PD-EXT_USER']])
          },
          clearGfchCantonalResponsibility: (_, state, utils) => {
            utils.changeValue(state, 'gfchCantonalResponsibility', () => [])
          },
          clearCantonalUserCantonAndResponsibilities: (_, state, utils) => {
            utils.changeValue(state, 'cantonalUserCanton', () => null)
            utils.changeValue(state, 'strategicResponsibility', () => [])
            utils.changeValue(state, 'operationalResponsibility', () => [])
          },
        }}
        render={({ handleSubmit, values, form }) => {
          return (
            <form id="administration-edit-form" onSubmit={handleSubmit} noValidate>
              <Stack spacing={2}>
                <AutoCompleteField
                  options={typeOptions}
                  name="type"
                  label={getMessage('label.type')}
                  validate={required()}
                />
                <OnChange name="type">
                  {(value) => {
                    if (value === USER_TYPE.EXTERNAL_EXPERT) {
                      form.mutators.setExternalExpert()
                      form.mutators.clearCantonalUserCantonAndResponsibilities()
                      form.mutators.clearGfchCantonalResponsibility()
                    }
                    if (value === USER_TYPE.EXTERNAL) {
                      form.mutators.clearRoles()
                      form.mutators.setExternalUser()
                      form.mutators.clearCantonalUserCantonAndResponsibilities()
                      form.mutators.clearGfchCantonalResponsibility()
                    }
                    if (value === USER_TYPE.GFCH && value !== initialValues?.type) {
                      form.mutators.clearRoles()
                      form.mutators.clearCantonalUserCantonAndResponsibilities()
                    }
                    if (value === USER_TYPE.CANTON && value !== initialValues?.type) {
                      form.mutators.clearRoles()
                      form.mutators.clearGfchCantonalResponsibility()
                    }
                  }}
                </OnChange>
                <AutoCompleteField
                  options={titleOptions}
                  name="title"
                  label={getMessage('label.title')}
                  validate={required()}
                />
                <TextField
                  name="firstName"
                  label={getMessage('label.first.name')}
                  validate={composeValidators(
                    required(),
                    minChar(2),
                    maxChar(TEXT_LENGTH.GIRDERS_AUTH_NAME),
                    validPattern(GIRDERS_AUTH_NAME_REGEX, GIRDERS_AUTH_NAME_REGEX_DESCRIPTIVE_ERROR_MESSAGE_KEY),
                  )}
                />
                <TextField
                  name="lastName"
                  label={getMessage('label.last.name')}
                  validate={composeValidators(
                    required(),
                    minChar(2),
                    maxChar(TEXT_LENGTH.GIRDERS_AUTH_NAME),
                    validPattern(GIRDERS_AUTH_NAME_REGEX, GIRDERS_AUTH_NAME_REGEX_DESCRIPTIVE_ERROR_MESSAGE_KEY),
                  )}
                />
                <AutoCompleteField
                  options={languageOptions}
                  name="language"
                  label={getMessage('label.language')}
                  validate={required()}
                />
                <TextField
                  name="email"
                  label={getMessage('label.email')}
                  validate={composeValidators(required(), minChar(5), maxChar(TEXT_LENGTH.M), validEmail())}
                  disabled={!!initialValues?.email}
                />
                {values.type === USER_TYPE.GFCH && (
                  <>
                    <MultiSelectField
                      options={gfchRolesOptions}
                      name="roles"
                      label={getMessage('label.roles')}
                      unavailableOptionsMessage={getMessage('label.no.available.roles')}
                      validate={mode === 'ADD' ? required() : () => {}}
                    />
                    <OnChange name="roles">
                      {(value) => {
                        !hasKapRoleSelected(value) && form.mutators.clearGfchCantonalResponsibility()
                      }}
                    </OnChange>
                    <MultiSelectField
                      options={cantonOptions}
                      name="gfchCantonalResponsibility"
                      label={getMessage('label.cantonal.responsibility')}
                      unavailableOptionsMessage={getMessage('label.no.available.cantons')}
                      disabled={!hasKapRoleSelected(values.roles)}
                    />
                  </>
                )}
                {values.type === USER_TYPE.CANTON && (
                  <>
                    <MultiSelectField
                      options={cantonalRolesOptions}
                      name="roles"
                      label={getMessage('label.roles')}
                      unavailableOptionsMessage={getMessage('label.no.available.roles')}
                      validate={mode === 'ADD' ? required() : () => {}}
                    />
                    <AutoCompleteField
                      options={cantonOptions}
                      name="cantonalUserCanton"
                      label={getMessage('label.canton')}
                      validate={required()}
                    />
                    {values.roles.some((role) => role.includes('CANTON_KAP')) && (
                      <>
                        <MultiSelectField
                          options={modulesOptions}
                          name="strategicResponsibility"
                          label={getMessage('label.strategic.responsibility')}
                          unavailableOptionsMessage={getMessage('label.no.available.modules')}
                        />
                        <MultiSelectField
                          options={modulesOptions}
                          name="operationalResponsibility"
                          label={getMessage('label.operational.responsibility')}
                          unavailableOptionsMessage={getMessage('label.no.available.modules')}
                        />
                      </>
                    )}
                  </>
                )}
              </Stack>
              <DirtyFormSpy />
            </form>
          )
        }}
      />
    </Box>
  )
}
