import { Stack } from '@mui/material'
import { useEffect, useState } from 'react'
import { Form } from 'react-final-form'
import { useParams } from 'react-router'
import { Maybe, Mutation_Root, Project_Journal } from 'src/@types/graphql'
import { useMessageSource } from 'src/i18n/useMessageSource'
import { upsertProjectJournalMutation } from 'src/screens/shared/project/journal/projectJournalQueries'
import { usePermissionsForProject } from 'src/service/security/PermissionHook'
import {
  PROJECT_JOURNAL_STATUS,
  PROJECT_JOURNAL_STATUS_TYPE,
  PROJECT_TYPE,
  TEXT_LENGTH,
} from 'src/shared/constants/constants'
import { AutoCompleteField } from 'src/shared/form/control/AutoCompleteField'
import { DateTimePickerField } from 'src/shared/form/control/DateTimePickerField'
import { HtmlEditorField } from 'src/shared/form/control/HtmlEditorField'
import { TextField } from 'src/shared/form/control/TextField'
import { DirtyFormSpy } from 'src/shared/form/dirty/DirtyFormSpy'
import { createDecorators } from 'src/shared/form/utils/decorators'
import { composeValidators, maxChar, required, validDate } from 'src/shared/form/validation/validators'
import { DateUtils } from 'src/shared/utils/DateUtils'
import { HtmlSanitizer } from 'src/shared/utils/HtmlSanitizer'
import { useNotificationService } from 'src/shared/utils/NotificationService'
import { useClient } from 'urql'

interface Props {
  projectType: PROJECT_TYPE
  journal?: Project_Journal
  afterSubmit: (journalId?: number) => void
}

interface FormValues {
  title: string
  journal_date: string
  status: PROJECT_JOURNAL_STATUS_TYPE
  description: string
  stakeholders?: Maybe<string>
}

const DEFAULT_FORM_VALUES: FormValues = {
  title: '',
  journal_date: DateUtils.formatDate(new Date()),
  status: PROJECT_JOURNAL_STATUS.OPEN,
  description: '',
}

const mapProjectJournalToFormValues = (journal: Project_Journal): FormValues => {
  return {
    title: journal.title,
    journal_date: DateUtils.parseAndFormatDate(journal.journal_date),
    status: journal.status as PROJECT_JOURNAL_STATUS_TYPE,
    description: journal.description,
    stakeholders: journal.stakeholders,
  }
}

export const ProjectJournalForm = ({ projectType, journal, afterSubmit }: Props) => {
  const { projectId } = useParams()
  const { getMessage } = useMessageSource()

  const decorators = createDecorators()
  const urqlClient = useClient()
  const notificationService = useNotificationService()

  const [initialValues, setInitialValues] = useState<FormValues>()

  const statusOptions = Object.keys(PROJECT_JOURNAL_STATUS).map((key) => ({
    label: `${getMessage(`label.project.journal.status.${key}`)}`,
    value: key,
  }))

  useEffect(() => {
    const initData = async () => {
      if (journal) {
        setInitialValues(mapProjectJournalToFormValues(journal))
      } else {
        setInitialValues(DEFAULT_FORM_VALUES)
      }
    }

    initData()
  }, [journal])

  const {
    metadata: { projectBaseId },
  } = usePermissionsForProject(projectType, parseInt(projectId as string))

  const onSave = async (values: FormValues) => {
    const mutationObject = {
      ...values,
      id: journal?.id,
      description: HtmlSanitizer.sanitize(values.description),
      stakeholders: values.stakeholders ? HtmlSanitizer.sanitize(values.stakeholders) : null,
      project_base_id: projectBaseId,
      journal_date: DateUtils.parseAndSerializeDate(values.journal_date),
    }

    const { data } = await urqlClient
      .mutation<{ insert_project_journal_one: Mutation_Root['insert_project_journal_one'] }>(
        upsertProjectJournalMutation,
        {
          object: mutationObject,
        },
      )
      .toPromise()

    if (data?.insert_project_journal_one) {
      setInitialValues(values)
      notificationService.changesSaved()
      afterSubmit(data?.insert_project_journal_one?.id)
    } else {
      notificationService.operationFailed()
    }
  }

  return (
    <>
      {(initialValues || !journal) && (
        <Form<FormValues>
          initialValues={initialValues}
          onSubmit={onSave}
          decorators={decorators}
          render={({ handleSubmit }) => {
            return (
              <form id="project-journal-form" onSubmit={handleSubmit}>
                <Stack spacing={2}>
                  <TextField
                    name="title"
                    label={getMessage('label.project.journal.title')}
                    validate={composeValidators(required(), maxChar(TEXT_LENGTH.M))}
                    required
                  />
                  <DateTimePickerField
                    name="journal_date"
                    label={getMessage('label.project.journal.date')}
                    validate={composeValidators(required(), validDate())}
                    required
                  />
                  <AutoCompleteField
                    name="status"
                    label={getMessage('label.project.journal.status')}
                    options={statusOptions}
                    validate={required()}
                    required
                  />
                  <HtmlEditorField
                    name="description"
                    label={getMessage('label.project.journal.description')}
                    validate={composeValidators(required(), maxChar(TEXT_LENGTH.XL))}
                    required
                  />
                  <HtmlEditorField
                    name="stakeholders"
                    label={getMessage('label.project.journal.stakeholders')}
                    validate={maxChar(TEXT_LENGTH.XL)}
                  />
                  <DirtyFormSpy />
                </Stack>
              </form>
            )
          }}
        />
      )}
    </>
  )
}
