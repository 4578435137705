import { Box, Stack } from '@mui/material'
import { useGridApiRef } from '@mui/x-data-grid-pro'
import { useCallback, useEffect, useRef, useState } from 'react'
import { Form } from 'react-final-form'
import { useParams } from 'react-router'
import { Mutation_Root, Query_Root } from 'src/@types/graphql'
import { useMessageSource } from 'src/i18n/useMessageSource'
import { ROUTES } from 'src/routing/routes'
import {
  fetchKapMeasureAnnualPlanningQuery,
  upsertKapMeasureAnnualPlanningWithKpiMutation,
} from 'src/screens/kap/implementation/milestone/kapMilestoneQueries'
import { AnnualPlanFeatureKpiGrid } from 'src/screens/shared/implementation/details/milestone/details/annual/kpi/AnnualPlanFeatureKpiGrid'
import { queryMilestoneById } from 'src/screens/shared/implementation/details/milestoneQueries'
import { usePermissionsForKapMilestones } from 'src/service/security/PermissionHook'
import { SaveAndBackButton, SaveButton } from 'src/shared/button/Buttons'
import { LEVEL_TYPE, MEASURE_CHAR, TEXT_LENGTH } from 'src/shared/constants/constants'
import { MILESTONE_RESPONSIBLE_TYPE_TYPE, MILESTONE_STATUS_TYPE } from 'src/shared/constants/milestone-constants'
import { HtmlEditorField } from 'src/shared/form/control/HtmlEditorField'
import { DirtyFormSpy } from 'src/shared/form/dirty/DirtyFormSpy'
import { createDecorators } from 'src/shared/form/utils/decorators'
import { composeValidators, maxChar, required } from 'src/shared/form/validation/validators'
import { PageLayout } from 'src/shared/layout/PageLayout'
import { ScreenLayout } from 'src/shared/layout/ScreenLayout'
import { NotAuthorized } from 'src/shared/not-authorized/NotAuthorized'
import { HelpAndInstructions } from 'src/shared/presentation/HelpAndInstructions'
import { DataGridUtils } from 'src/shared/utils/DataGridUtils'
import { useDelayedNavigate } from 'src/shared/utils/hooks/navigation-hooks'
import { useDelayedSubmit } from 'src/shared/utils/hooks/submit-hook'
import { HtmlSanitizer } from 'src/shared/utils/HtmlSanitizer'
import { useNotificationService } from 'src/shared/utils/NotificationService'
import { useClient, useQuery } from 'urql'

type originType = 'SAVE' | 'SAVE_AND_BACK'

interface EditForm {
  planning_description?: string
}

const decorators = createDecorators()

export const KapMilestoneAnnualPlanningEditPage = () => {
  const { programId, measureId, milestoneId, milestonePath } = useParams()
  const programIdParsed = parseInt(programId as string)
  const measureIdParsed = parseInt(measureId as string)
  const milestoneIdParsed = parseInt(milestoneId as string)

  const { getMessage } = useMessageSource()
  const notificationService = useNotificationService()
  const navigate = useDelayedNavigate()
  const originRef = useRef<originType>('SAVE')
  const gridApiRef = useGridApiRef()
  const urqlClient = useClient()
  const submit = useDelayedSubmit()

  const [initialValues, setInitialValues] = useState<EditForm>()
  const [measureTitle, setMeasureTitle] = useState<string>('')
  const [measureLevel, setMeasureLevel] = useState<LEVEL_TYPE>()
  const [kpiDirty, setKpiDirty] = useState(false)

  const [{ data, error }] = useQuery<{ milestone_by_pk: Query_Root['milestone_by_pk'] }>({
    query: queryMilestoneById,
    variables: { id: milestoneIdParsed },
  })

  if (error) {
    notificationService.operationFailed()
  }

  const milestone = data?.milestone_by_pk

  const { loading, canEdit } = usePermissionsForKapMilestones(
    programIdParsed,
    milestone?.status as MILESTONE_STATUS_TYPE,
    milestone?.responsible_type as MILESTONE_RESPONSIBLE_TYPE_TYPE,
  )

  useEffect(() => {
    const fetchData = async () => {
      const { data } = await urqlClient
        .query<{
          kap_measure_annual_planning: Query_Root['kap_measure_annual_planning']
          kap_measure_by_pk: Query_Root['kap_measure_by_pk']
        }>(fetchKapMeasureAnnualPlanningQuery, {
          milestoneId: milestoneIdParsed,
          measureId: measureIdParsed,
        })
        .toPromise()

      if (data && data.kap_measure_by_pk && data.kap_measure_annual_planning) {
        setInitialValues({
          planning_description: data.kap_measure_annual_planning[0]?.planning_description,
        })

        setMeasureLevel(data.kap_measure_by_pk.level as LEVEL_TYPE)

        const measure = data.kap_measure_by_pk
        const measureIdCharacter = getMessage(`label.kap.measure.id.character.${measure?.level}`)
        setMeasureTitle(`${data.kap_measure_by_pk?.short_title} (${measureIdCharacter}${measure?.sort_number})`)
      } else {
        notificationService.operationFailed()
      }
    }

    fetchData()
  }, [urqlClient, measureIdParsed, milestoneIdParsed, notificationService, getMessage])

  const onKpiGridUpdate = useCallback(
    (initial: boolean) => {
      if (!initial) {
        setKpiDirty(true)
      }
    },
    [setKpiDirty],
  )

  const onSubmit = (origin: originType) => (_: any) => {
    // trigger submit event
    if (originRef) {
      originRef.current = origin
    }
    document.getElementById('edit-form')?.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }))
  }

  const onBack = () => {
    const route = ROUTES.KapAnnualPlanningMilestoneRoot.nested.Planning.params({
      programId,
      milestoneId,
      milestonePath,
    })

    navigate(`${route}#${MEASURE_CHAR}${measureId}`, { state: { expandedEntityId: `${MEASURE_CHAR}${measureId}` } })
  }

  const onSubmitLocal = async (values: EditForm) => {
    const kpiData = Array.from(gridApiRef.current.getRowModels().values())

    const mappedKpiDataMutationArray = kpiData.map((item) => ({
      feature_config_id: item.id,
      milestone_id: milestoneIdParsed,
      kap_measure_id: measureIdParsed,
      planned_value: DataGridUtils.formatFieldValue(item.planned_value),
    }))

    const isValidPlanning: boolean =
      !!values.planning_description && mappedKpiDataMutationArray.every((item) => Number.isInteger(item.planned_value))

    const kpiDataMutationArray = mappedKpiDataMutationArray.filter((item) => item.planned_value !== null)

    const { data } = await urqlClient
      .mutation<{
        delete_kap_measure_kpi_annual_planning: Mutation_Root['delete_kap_measure_kpi_annual_planning']
        insert_kap_measure_kpi_annual_planning: Mutation_Root['insert_kap_measure_kpi_annual_planning']
        insert_kap_measure_annual_planning_one: Mutation_Root['insert_kap_measure_annual_planning_one']
      }>(upsertKapMeasureAnnualPlanningWithKpiMutation, {
        milestoneId: milestoneIdParsed,
        measureId: measureIdParsed,
        planningDescription: HtmlSanitizer.sanitize(values.planning_description),
        valid: isValidPlanning,
        kpiData: kpiDataMutationArray,
      })
      .toPromise()

    if (data) {
      setInitialValues({
        planning_description: values.planning_description,
      })
      setKpiDirty(false)
      notificationService.changesSaved()

      if (originRef.current === 'SAVE_AND_BACK') {
        onBack()
      }
    } else {
      notificationService.operationFailed()
    }
  }

  if (!loading && !canEdit) {
    return <NotAuthorized inEditPage />
  }

  return (
    !loading &&
    canEdit && (
      <ScreenLayout
        title={getMessage('label.edit.measure.milestone.title', [measureTitle])}
        hasSecondLevelNavigation={false}
        onBack={onBack}
        actions={
          <>
            <SaveAndBackButton origin="header" onClick={onSubmit('SAVE_AND_BACK')} />
            <SaveButton origin="header" onClick={onSubmit('SAVE')} />
          </>
        }
      >
        <PageLayout>
          <>
            {!loading && measureLevel && initialValues && (
              <>
                <HelpAndInstructions labelKey="label.help.kap.annual.plan.measures" defaultExpansion />
                <Box>
                  <Form<EditForm>
                    initialValues={initialValues}
                    onSubmit={submit(onSubmitLocal)}
                    decorators={decorators}
                    render={({ handleSubmit }) => {
                      return (
                        <form id="edit-form" onSubmit={handleSubmit} noValidate>
                          <Stack spacing={2}>
                            <HtmlEditorField
                              required
                              name="planning_description"
                              label={getMessage('label.milestone.annual.plan.description')}
                              validate={composeValidators(maxChar(TEXT_LENGTH.XL), required())}
                            />
                            <AnnualPlanFeatureKpiGrid
                              editable
                              measureId={measureIdParsed}
                              measureLevel={measureLevel}
                              reportYear={milestone?.year_in_focus}
                              onCellEdit={onKpiGridUpdate}
                              gridApiRef={gridApiRef}
                            />
                            <DirtyFormSpy gridDirty={kpiDirty} />
                          </Stack>
                        </form>
                      )
                    }}
                  />
                </Box>
              </>
            )}
          </>
        </PageLayout>
      </ScreenLayout>
    )
  )
}
