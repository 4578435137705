import { GridFilterItem, GridSortItem } from '@mui/x-data-grid-pro'
import { Pf_Pgv_Project_Bool_Exp, Pf_Pgv_Project_Order_By, Query_Root } from 'src/@types/graphql'
import { FetchRowsPromise } from 'src/screens/pf-kap/index/PfKapGridUtils'
import { fetchPfPgvProjectsGrid } from 'src/screens/shared/project/index/projectGridQueries'
import { Client } from 'urql'

export const fetchPfPgvRows =
  (urqlClient: Client) =>
  async (
    rowsPerPage: number,
    filterItems?: GridFilterItem[],
    currentPage = 0,
    sort?: GridSortItem,
  ): Promise<FetchRowsPromise> => {
    const pfPgvCondition = {
      _and: [],
      project_base: { _and: [], dossier: { _and: [] } },
    } as Pf_Pgv_Project_Bool_Exp
    sort = sort || { field: 'id', sort: 'desc' }

    if (filterItems) {
      const groupedMap = filterItems?.reduce(
        (entryMap, e) => entryMap.set(e.field, [...(entryMap.get(e.field) || []), e]),
        new Map(),
      )

      groupedMap.forEach((columnFilters: GridFilterItem[], column: string) => {
        if (column === 'id') {
          const ids = columnFilters.filter((f) => f.value && parseInt(f.value)).map((f) => f.value)
          if (ids?.length) {
            pfPgvCondition._and?.push({ id: { _in: ids } })
          }
        } else if (column === 'short_title') {
          const shortTitles = columnFilters
            .filter((f) => f.value)
            .map((f) => {
              return {
                short_title: { _ilike: `%${f.value}%` },
              }
            })
          if (shortTitles?.length) {
            pfPgvCondition.project_base?.dossier?._and?.push({ _or: shortTitles })
          }
        } else if (column === 'status') {
          const statuses = columnFilters.filter((f) => f.value).map((f) => f.value)
          if (statuses?.length) {
            pfPgvCondition.project_base?.dossier?._and?.push({ status: { _in: statuses } })
          }
        } else if (column === 'funding_round_name') {
          const fundingRounds = columnFilters.filter((f) => f.value).map((f) => f.value)
          if (fundingRounds?.length) {
            pfPgvCondition.project_base?.dossier?._and?.push({ funding_round_id: { _in: fundingRounds } })
          }
        } else if (column === 'application_type') {
          const applicationTypes = columnFilters.filter((f) => f.value).map((f) => f.value)
          if (applicationTypes?.length) {
            pfPgvCondition._and?.push({ application_type: { code: { _in: applicationTypes } } })
          }
        } else if (column === 'gfch_responsible') {
          const gfchResponsibleUsers = columnFilters.filter((f) => f.value).map((f) => f.value)
          if (gfchResponsibleUsers?.length) {
            pfPgvCondition.project_base?._and?.push({
              gfch_responsible_id: { _in: gfchResponsibleUsers },
            })
          }
        }
      })
    }

    const offset = currentPage * rowsPerPage

    let orderBy = [
      {
        id: 'asc',
      },
    ] as Array<Pf_Pgv_Project_Order_By>

    if (sort) {
      if (['id'].includes(sort.field)) {
        // @ts-ignore
        orderBy = [{ id: sort.sort }]
      } else if (['funding_round_name'].includes(sort.field)) {
        // @ts-ignore
        orderBy = [{ project_base: { dossier: { funding_round: { name: sort.sort } } } }, { id: 'asc' }]
      } else if (['application_type'].includes(sort.field)) {
        // @ts-ignore
        orderBy = [{ application_type: { key: sort.sort } }, { id: 'asc' }]
      } else if (['gfch_responsible'].includes(sort.field)) {
        // @ts-ignore
        orderBy = [
          // @ts-ignore
          { project_base: { gfch_responsible: { first_name: sort.sort } } },
          // @ts-ignore
          { project_base: { gfch_responsible: { last_name: sort.sort } } },
          // @ts-ignore
          { id: 'asc' },
        ]
      } else {
        orderBy = [
          {
            project_base: {
              dossier: {
                [sort.field]: sort.sort,
              },
            },
          },
          // @ts-ignore
          { id: 'asc' },
        ]
      }
    }

    const limit = rowsPerPage
    const { data, error } = await urqlClient
      .query<
        {
          pf_pgv_project: Query_Root['pf_pgv_project']
          pf_pgv_project_aggregate: Query_Root['pf_pgv_project_aggregate']
        },
        {
          pfPgvCondition: Pf_Pgv_Project_Bool_Exp | unknown
          offset: number
          limit: number
          orderBy: Pf_Pgv_Project_Order_By | Pf_Pgv_Project_Order_By[]
        }
      >(fetchPfPgvProjectsGrid, {
        pfPgvCondition,
        offset,
        limit,
        orderBy,
      })
      .toPromise()

    if (error) {
      return Promise.reject(error)
    } else {
      const fetchedRows = data!.pf_pgv_project.map((p) => ({
        id: p.id,
        status: p.project_base.dossier.status,
        short_title: p.project_base.dossier.short_title,
        gfch_responsible: `${p?.project_base?.gfch_responsible?.first_name ?? ''} ${
          p?.project_base?.gfch_responsible?.last_name ?? ''
        }`,
        funding_round_name: p?.project_base?.dossier?.funding_round?.name ?? '',
        application_type: p?.application_type?.key ?? '',
      }))
      const rowsCount = data!.pf_pgv_project_aggregate.aggregate?.count ?? 0
      return {
        rowsCount: rowsCount,
        rows: fetchedRows,
      }
    }
  }
