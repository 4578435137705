import { Location } from 'history'
import { useEffect, useState } from 'react'
import { Outlet, useLocation, useParams } from 'react-router'
import { Query_Root } from 'src/@types/graphql'
import { NestedRoute, Route, SecondLevelRoute } from 'src/routing/routing'
import { resolveMilestoneRoot } from 'src/routing/routing-utils'
import { SecondLevelNavigation } from 'src/routing/SecondLevelNavigation'
import { queryMilestoneById } from 'src/screens/shared/implementation/details/milestoneQueries'
import { usePermissionsForMilestones } from 'src/service/security/PermissionHook'
import { MILESTONE_RESPONSIBLE_TYPE_TYPE, MILESTONE_STATUS_TYPE } from 'src/shared/constants/milestone-constants'
import { PageLayout } from 'src/shared/layout/PageLayout'
import { SecondLevelNavigationContainer } from 'src/shared/layout/SecondLevelNavigationContainer'
import { NotAuthorized } from 'src/shared/not-authorized/NotAuthorized'
import { useNotificationService } from 'src/shared/utils/NotificationService'
import { Utils } from 'src/shared/utils/Utils'
import { useQuery } from 'urql'

interface Props {
  baseUrl: '/pf-kap' | '/pf-pgv'
}

const resolveActiveRoute = (
  location: Location,
  detailsRoot: Route,
  projectId: string,
  milestoneId: string,
  milestonePath: string,
  goalId: string,
  measureId: string,
): SecondLevelRoute => {
  const nested = detailsRoot!.nested! as NestedRoute
  const activeRoute = Object.keys(nested).find((key) => {
    let isPathMatching
    if (detailsRoot.params) {
      isPathMatching = detailsRoot.params({ projectId, milestoneId, milestonePath }) === location.pathname
    }
    //@ts-ignore
    const nestedPath = nested[key].params({ projectId, milestoneId, milestonePath, goalId, measureId })
    return location.pathname.endsWith(nestedPath) || (isPathMatching && nested[key].primary)
  }) as string

  return nested[activeRoute] as SecondLevelRoute
}

export const DefaultMilestoneIndex = ({ baseUrl }: Props) => {
  const { projectId, milestoneId, milestonePath, goalId, measureId } = useParams()
  const projectIdParsed = parseInt(projectId as string)
  const milestoneIdParsed = parseInt(milestoneId as string)
  const milestoneType = Utils.resolveMilestoneType(milestonePath)
  const location = useLocation()
  const process = Utils.resolveProcess(baseUrl)
  const notificationService = useNotificationService()

  const [{ data, error }] = useQuery<{ milestone_by_pk: Query_Root['milestone_by_pk'] }, { id: number }>({
    query: queryMilestoneById,
    variables: { id: milestoneIdParsed },
  })

  if (error) {
    notificationService.operationFailed()
  }

  const milestone = data?.milestone_by_pk

  const { loading, canView } = usePermissionsForMilestones(
    process,
    projectIdParsed,
    milestone?.status as MILESTONE_STATUS_TYPE,
    milestone?.responsible_type as MILESTONE_RESPONSIBLE_TYPE_TYPE,
  )

  const [activeRoute, setActiveRoute] = useState<SecondLevelRoute>()

  useEffect(() => {
    const activeRoute = resolveActiveRoute(
      location,
      resolveMilestoneRoot(baseUrl, milestoneType) as unknown as Route,
      projectId as string,
      milestoneId as string,
      milestonePath as string,
      goalId as string,
      measureId as string,
    )
    setActiveRoute(activeRoute)
  }, [baseUrl, projectId, milestoneId, milestonePath, milestoneType, goalId, measureId, location])

  const showNavigation = (activeRoute && !activeRoute.hideNavigation) ?? false

  if (!loading && !canView) {
    return <NotAuthorized />
  }

  return (
    <>
      {!loading && activeRoute && (
        <SecondLevelNavigationContainer $showNavigation={showNavigation}>
          {showNavigation && (
            <PageLayout>
              <SecondLevelNavigation
                activeRoute={activeRoute}
                route={resolveMilestoneRoot(baseUrl, milestoneType) as unknown as Route}
                baseUrl={resolveMilestoneRoot(baseUrl, milestoneType)!.params({
                  projectId,
                  milestoneId,
                  milestonePath,
                })}
              />
            </PageLayout>
          )}
          <Outlet />
        </SecondLevelNavigationContainer>
      )}
    </>
  )
}
