import { ReactElement } from 'react'
import { useParams } from 'react-router'
import { Query_Root } from 'src/@types/graphql'
import { useMessageSource } from 'src/i18n/useMessageSource'
import {
  resolveAnnualReportMilestoneRoot,
  resolveFinalReportMilestoneRoot,
  resolveImplementationDetailsRoot,
} from 'src/routing/routing-utils'
import { MilestoneDetailsView } from 'src/screens/shared/implementation/details/milestone/details/default-milestone/MilestoneDetailsView'
import { queryMilestoneById } from 'src/screens/shared/implementation/details/milestoneQueries'
import { usePermissionsForMilestoneConditions } from 'src/service/security/PermissionHook'
import {
  MILESTONE_RESPONSIBLE_TYPE_TYPE,
  MILESTONE_STATUS_TYPE,
  MILESTONE_TYPE,
} from 'src/shared/constants/milestone-constants'
import { useDelayedNavigate } from 'src/shared/utils/hooks/navigation-hooks'
import { useNotificationService } from 'src/shared/utils/NotificationService'
import { Utils } from 'src/shared/utils/Utils'
import { useQuery } from 'urql'

interface Props {
  baseUrl: '/pf-kap' | '/pf-pgv'
}

export const MilestoneConditionsPage = ({ baseUrl }: Props): ReactElement => {
  const { projectId, milestoneId, milestonePath } = useParams()
  const notificationService = useNotificationService()
  const navigate = useDelayedNavigate()
  const { getMessage } = useMessageSource()

  const milestoneIdParsed = parseInt(milestoneId as string)
  const projectIdParsed = parseInt(projectId as string)
  const milestoneType = Utils.resolveMilestoneType(milestonePath)
  const process = Utils.resolveProcess(baseUrl)

  const [{ data, error }] = useQuery<{ milestone_by_pk: Query_Root['milestone_by_pk'] }, { id: number }>({
    query: queryMilestoneById,
    variables: { id: milestoneIdParsed },
  })

  if (error) {
    notificationService.operationFailed()
  }

  const milestone = data?.milestone_by_pk

  const { loading, canEdit, canUploadFiles } = usePermissionsForMilestoneConditions(
    process,
    projectIdParsed,
    milestone?.status as MILESTONE_STATUS_TYPE,
    milestone?.responsible_type as MILESTONE_RESPONSIBLE_TYPE_TYPE,
  )

  const onEdit = () => {
    const resolveMilestoneRoot = () => {
      switch (milestoneType) {
        case MILESTONE_TYPE.ANNUAL_REPORT:
          return resolveAnnualReportMilestoneRoot(baseUrl)
        case MILESTONE_TYPE.FINAL_REPORT:
          return resolveFinalReportMilestoneRoot(baseUrl)
        default:
          throw Error(`Cannot resolve milestone root because this milestone type doesn't support conditions`)
      }
    }

    const route = resolveMilestoneRoot().nested.ConditionsEdit.params({
      projectId,
      milestoneId,
      milestonePath,
    })
    navigate(route)
  }

  const onBack = () => {
    const route = resolveImplementationDetailsRoot(baseUrl).params({ projectId })
    navigate(route)
  }

  return (
    <>
      {!loading && milestone && (
        <MilestoneDetailsView
          milestone={milestone}
          milestoneId={milestoneIdParsed}
          milestoneType={milestoneType}
          process={process}
          canEdit={canEdit}
          onBack={onBack}
          onEdit={onEdit}
          sectionTitle={getMessage('label.milestone.acceptance.criteria')}
          helpLabel={`label.help.milestone.acceptance.criteria.${milestoneType}.${process}`}
          canUploadFiles={canUploadFiles}
        />
      )}
    </>
  )
}
