import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { ReactElement, ReactNode } from 'react'
import { useMessageSource } from 'src/i18n/useMessageSource'
import { DefaultSectionTypography } from 'src/shared/presentation/DefaultSectionTypography'
import { S } from 'src/shared/styled/S'
import { useHashScroll } from 'src/shared/utils/hooks/navigation-hooks'
import styled from 'styled-components/macro'

interface Props {
  title?: string
  actionButton?: ReactNode
  children: ReactNode
  helpAndInstructions?: ReactNode
  async?: boolean
  id?: string
  optional?: boolean
}

const BoxStyled = styled(Box)`
  &:last-child {
    margin-bottom: 0;
  }
`

export const Section = ({
  title,
  actionButton,
  id,
  children,
  async = false,
  helpAndInstructions,
  optional,
}: Props): ReactElement => {
  const { getMessage } = useMessageSource()

  useHashScroll(id, async)

  return (
    <BoxStyled mb={5} id={id}>
      <Box display="flex" alignItems="flex-end" justifyContent="space-between" mb={1}>
        <Box display="flex" alignItems="center">
          <Typography variant="h2" color="textSecondary" sx={{ flexGrow: 1 }}>
            {title}
          </Typography>
          {optional && (
            <Typography variant="body2" ml={1} color="text.disabled">
              ({getMessage('label.optional')})
            </Typography>
          )}
        </Box>
        <Box display="grid" gridAutoFlow="column" sx={{ gridGap: 9 }}>
          {actionButton}
        </Box>
      </Box>
      {helpAndInstructions}
      {children ? (
        children
      ) : (
        <S.Card.Container $nonClickable={true}>
          <S.Card.Content>
            <DefaultSectionTypography type="TEXT_FIELD" $standAlone={true} />
          </S.Card.Content>
        </S.Card.Container>
      )}
    </BoxStyled>
  )
}
