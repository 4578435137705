import { Box, Typography, TypographyTypeMap } from '@mui/material'
import { AddButton } from 'src/shared/button/Buttons'
import { PersonAddIcon } from 'src/shared/icons/Icons'
import { S } from 'src/shared/styled/S'

export const UsersGridToolbar = ({
  title,
  variant = 'h3',
  addNewUser,
}: {
  title?: string
  variant?: TypographyTypeMap['props']['variant']
  addNewUser: React.MouseEventHandler<HTMLButtonElement>
}) => {
  return (
    <S.DataGrid.ToolbarContainer>
      <Typography variant={variant}>{title}</Typography>
      <Box>
        <AddButton messageKey="button.user.add" startIcon={<PersonAddIcon />} onClick={addNewUser} />
      </Box>
    </S.DataGrid.ToolbarContainer>
  )
}
