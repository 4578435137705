import { ReactElement, useRef } from 'react'
import { useParams } from 'react-router'
import { useMessageSource } from 'src/i18n/useMessageSource'
import { resolveDetailsRoot } from 'src/routing/routing-utils'
import { ProjectFeatureKpiGrid } from 'src/screens/shared/project/details/project-features/ProjectFeatureKpiGrid'
import { usePermissionsForProject } from 'src/service/security/PermissionHook'
import { SaveAndBackButton, SaveButton } from 'src/shared/button/Buttons'
import { PageLayout } from 'src/shared/layout/PageLayout'
import { ScreenLayout } from 'src/shared/layout/ScreenLayout'
import { NotAuthorized } from 'src/shared/not-authorized/NotAuthorized'
import { useDelayedNavigate } from 'src/shared/utils/hooks/navigation-hooks'
import { Utils } from 'src/shared/utils/Utils'

type originType = 'SAVE' | 'SAVE_AND_BACK'

interface Props {
  baseUrl: '/pf-kap' | '/pf-pgv'
}

export const ProjectFeaturesKpiEditPage = ({ baseUrl }: Props): ReactElement => {
  const { getMessage } = useMessageSource()
  const navigate = useDelayedNavigate()
  const originRef = useRef<originType>('SAVE')
  const { projectId: id } = useParams()
  const projectId = parseInt(id as string)
  const projectType = Utils.resolveProcess(baseUrl)
  const { canEdit, loading } = usePermissionsForProject(projectType, projectId)

  const onSubmit = (origin: originType) => (_: any) => {
    // trigger submit event
    if (originRef) {
      originRef.current = origin
    }
    document.getElementById('edit-form')?.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }))
  }

  const onSave = () => {
    if (originRef.current === 'SAVE_AND_BACK') {
      const route = resolveDetailsRoot(baseUrl).nested.ProjectFeatures.params({ projectId })
      navigate(`${route}#kpis`)
    }
  }

  if (!loading && !canEdit) {
    return <NotAuthorized />
  }

  return (
    <ScreenLayout
      title={getMessage('label.navigation.edit.kpis')}
      hasSecondLevelNavigation={false}
      onBack={() => {
        const route = resolveDetailsRoot(baseUrl).nested.ProjectFeatures.params({ projectId })
        navigate(`${route}#kpis`)
      }}
      actions={
        <>
          <SaveAndBackButton origin="header" onClick={onSubmit('SAVE_AND_BACK')} />
          <SaveButton origin="header" onClick={onSubmit('SAVE')} />
        </>
      }
    >
      <PageLayout>
        {!loading && (
          <ProjectFeatureKpiGrid
            targetEditable
            projectId={projectId}
            onSave={onSave}
            baseUrl={baseUrl}
            expandedHelpSection
            mode="edit"
          />
        )}
      </PageLayout>
    </ScreenLayout>
  )
}
