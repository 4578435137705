import { StrictMode } from 'react'
import ReactDOM from 'react-dom/client'
import { App } from 'src/App'
import { configureConsoleError } from 'src/dev/dev-console'

configureConsoleError()

const root = ReactDOM.createRoot(document.getElementById('root')!)
root.render(
  <StrictMode>
    <App />
  </StrictMode>,
)
