import { isEqual, sortBy, unionWith } from 'lodash'
import {
  Committee_Config,
  Criteria_Config,
  Project_Application_Decision,
  Project_Committee_Recommendation,
  Query_Root,
  User,
} from 'src/@types/graphql'
import { CommitteeDecisionValues } from 'src/screens/administration/round-management/details/round-information/committee-decision-management/CommitteeDecisionManagementService'
import { CRITERIA_TYPE } from 'src/shared/constants/assessment-constants'

export interface CommitteeConfigValues {
  committeeConfigId: number
  code: string
  label: string
  description: string | null
  recommendation: string | null
}

export interface ProjectApplicationDecisionValues {
  id: number | null
  pros: string | null
  cons: string | null
  finalDecision: string | null
  exclusionCriteria: string[]
  qualityCriteria: string[]
  exclusionCriteriaIds: string[]
  qualityCriteriaIds: string[]
  decisionLetter: string | null
  gfchResponsibleId: number | null
  gfchResponsibleValue: string | null
}

export interface CommitteeValues {
  projectApplicationDecision: ProjectApplicationDecisionValues
  committees: Array<CommitteeConfigValues>
  projectBaseId: number | null
  version: number | null
}

const htmlEditorFieldInitialValue = '<p><br></p>'

const readHtmlEditorFieldValue = (html: string | null) => {
  if (html === '<p><br></p>') {
    return null
  }
  return html
}

const adaptServerDataAsFormValues = (
  projectBaseId: number | null,
  serverData:
    | {
        committee_config: Committee_Config[]
        project_committee_recommendation: Project_Committee_Recommendation[]
        project_application_decision: Project_Application_Decision[]
        criteria_config: Query_Root['criteria_config']
        user: Query_Root['user']
      }
    | undefined,
  projectId?: number,
): CommitteeValues | CommitteeDecisionValues => {
  const allActiveCommitteeConfigs = serverData?.committee_config ?? []
  const allEnteredCommitteeConfigRecommendations = serverData?.project_committee_recommendation ?? []

  // prepare initial committee config form values
  const committeeConfigsData = getAllActiveAndEnteredCommitteeConfigs(
    allActiveCommitteeConfigs,
    allEnteredCommitteeConfigRecommendations,
  )

  const committeeConfigFormValues: Array<CommitteeConfigValues> = committeeConfigsData.map((committeeConfigData) => ({
    committeeConfigId: committeeConfigData.committeeConfig.id,
    code: committeeConfigData.committeeConfig.code,
    label: committeeConfigData.committeeConfig.descriptions,
    description: committeeConfigData.committeeConfigRecommendation[0]?.description || htmlEditorFieldInitialValue,
    recommendation: committeeConfigData.committeeConfigRecommendation[0]?.recommendation || null,
  }))

  // prepare initial project application decision form values
  const applicationDecision = serverData?.project_application_decision[0]

  let qcCriteria = []
  const qcCodeIds = applicationDecision?.quality_criteria
  if (qcCodeIds) {
    qcCriteria = sortBy(
      qcCodeIds.flatMap((qcCodeId: string) => serverData?.criteria_config.filter((cc) => cc.code === qcCodeId)),
      ['sort_number'],
    ).map((cc) => cc.criteria_id)
  }

  let ecCriteria = []
  const ecCodeIds = applicationDecision?.exclusion_criteria
  if (ecCodeIds) {
    ecCriteria = sortBy(
      ecCodeIds.flatMap((ecCodeId: string) => serverData?.criteria_config.filter((cc) => cc.code === ecCodeId)),
      ['sort_number'],
    ).map((cc: any) => cc.criteria_id)
  }

  const gfchResponsibleValue = applicationDecision?.project_base.gfch_responsible?.first_name
    ? `${applicationDecision.project_base.gfch_responsible.first_name} ${applicationDecision.project_base.gfch_responsible.last_name}`
    : applicationDecision?.project_base.gfch_responsible?.email

  const applicationDecisionFormValues: ProjectApplicationDecisionValues = {
    id: applicationDecision?.id || null,
    pros: applicationDecision?.pros || htmlEditorFieldInitialValue,
    cons: applicationDecision?.cons || htmlEditorFieldInitialValue,
    finalDecision: applicationDecision?.final_decision || null,
    exclusionCriteria: applicationDecision?.exclusion_criteria || [],
    qualityCriteria: applicationDecision?.quality_criteria || [],
    exclusionCriteriaIds: ecCriteria || [],
    qualityCriteriaIds: qcCriteria || [],
    decisionLetter: applicationDecision?.decision_letter_text || htmlEditorFieldInitialValue,
    gfchResponsibleId: applicationDecision?.project_base.gfch_responsible?.id || null,
    gfchResponsibleValue: gfchResponsibleValue || null,
  }

  const version = applicationDecision?.version || 0

  if (projectId) {
    // COMMITTEE DECISION MANAGEMENT CASE
    return {
      project: projectId,
      committees: committeeConfigFormValues,
      projectApplicationDecision: applicationDecisionFormValues,
      version: version,
      projectBaseId: projectBaseId,
    }
  } else {
    // APPLICATION COMMITTEE CASE
    return {
      committees: committeeConfigFormValues,
      projectApplicationDecision: applicationDecisionFormValues,
      version: version,
      projectBaseId: projectBaseId,
    }
  }
}

const getAllActiveAndEnteredCommitteeConfigs = (
  allActiveCommitteeConfigs: Committee_Config[],
  allEnteredCommitteeConfigRecommendations: Project_Committee_Recommendation[],
): {
  committeeConfig: Committee_Config
  committeeConfigRecommendation: Project_Committee_Recommendation[]
  sortNumber: number
}[] => {
  /* create array of unique committee configs with all committee configs that are active with
    addition of all committee configs for which a value is present in the project committee recommendation */
  const allFilteredCommitteeConfigs =
    unionWith(
      allActiveCommitteeConfigs,
      allEnteredCommitteeConfigRecommendations.map((x) => x.committee_config),
      isEqual,
    ) ?? []

  const uniqueCommitteeConfigs = allFilteredCommitteeConfigs.map((committeeConfig) => {
    const committeeConfigRecommendation = allEnteredCommitteeConfigRecommendations.filter(
      (committeeConfigRecommendation) => committeeConfigRecommendation.committee_config_id === committeeConfig.id,
    )
    return {
      committeeConfig: committeeConfig,
      committeeConfigRecommendation: committeeConfigRecommendation,
      sortNumber: committeeConfig.sort_number,
    }
  })

  return sortBy(uniqueCommitteeConfigs, 'sortNumber')
}

const readFormValues = (formValues: CommitteeValues) => {
  const projectCommitteeRecommendations = formValues.committees.map((committeeConfig) => ({
    description: readHtmlEditorFieldValue(committeeConfig.description),
    recommendation: committeeConfig.recommendation,
    project_base_id: formValues.projectBaseId,
    committee_config_id: committeeConfig.committeeConfigId,
  }))

  const projectApplicationDecision = {
    cons: readHtmlEditorFieldValue(formValues.projectApplicationDecision.cons),
    pros: readHtmlEditorFieldValue(formValues.projectApplicationDecision.pros),
    final_decision: formValues.projectApplicationDecision.finalDecision,
    quality_criteria: formValues.projectApplicationDecision.qualityCriteria,
    exclusion_criteria: formValues.projectApplicationDecision.exclusionCriteria,
    decision_letter_text: readHtmlEditorFieldValue(formValues.projectApplicationDecision.decisionLetter),
    project_base_id: formValues.projectBaseId,
    version: formValues.version,
  }

  const gfchResponsibleId = formValues.projectApplicationDecision.gfchResponsibleId || null

  return {
    projectCommitteeRecommendations: projectCommitteeRecommendations,
    applicationDecision: projectApplicationDecision,
    gfchResponsibleId: gfchResponsibleId,
  }
}

const getCriteriaOptions = (exclusionAndQualityCriteriaConfig: Criteria_Config[]) => {
  // prepare exclusion criteria configs options
  const allExclusionCriteriaConfigs = exclusionAndQualityCriteriaConfig.filter(
    (cc) => cc.criteria_type === CRITERIA_TYPE.EXCLUSION,
  )
  const exclusionCriteriaOptions =
    allExclusionCriteriaConfigs?.map((ec) => ({
      label: ec.criteria_id,
      value: ec.code,
      sortNumber: ec.sort_number,
    })) || []

  // prepare quality criteria configs options
  const allQualityCriteriaConfigs = exclusionAndQualityCriteriaConfig.filter(
    (cc) => cc.criteria_type === CRITERIA_TYPE.QUALITY,
  )
  const qualityCriteriaOptions =
    allQualityCriteriaConfigs?.map((qc) => ({
      label: qc.criteria_id,
      value: qc.code,
      sortNumber: qc.sort_number,
    })) || []

  return {
    exclusionCriteriaOptions: exclusionCriteriaOptions,
    qualityCriteriaOptions: qualityCriteriaOptions,
  }
}

const getGFCHResponsibleOptions = (users: User[]) => {
  // prepare gfch responsible options
  const gfchResponsibleOptions =
    users.map((u) => ({
      label: u.first_name ? `${u.first_name} ${u.last_name} (${u.email})` : u.email,
      value: u.id,
    })) || []

  return gfchResponsibleOptions
}
export const ApplicationCommitteeUtils = {
  adaptAsFormValues: adaptServerDataAsFormValues,
  getCommitteeConfigData: getAllActiveAndEnteredCommitteeConfigs,
  getCriteriaOptions,
  getGFCHResponsibleOptions,
  readFormValues,
}
