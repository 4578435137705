import {
  Checkbox as MuiCheckbox,
  CheckboxProps as MuiCheckboxProps,
  FormControl,
  FormControlLabel,
  FormControlLabelProps,
  FormControlProps,
  FormGroup,
  FormGroupProps,
  FormHelperText,
  FormHelperTextProps,
  FormLabel,
  FormLabelProps,
  Tooltip,
} from '@mui/material'
import { ReactElement, ReactNode } from 'react'
import { Field, FieldProps, useField } from 'react-final-form'
import { useMessageSource } from 'src/i18n/useMessageSource'
import { TYPOGRAPHY_MIXIN } from 'src/shared/constants/styling-constants'
import { InfoIcon } from 'src/shared/icons/Icons'
import styled, { css } from 'styled-components/macro'

export interface CheckboxData {
  label?: string
  value?: unknown
  disabled?: boolean
  indeterminate?: boolean
  tooltip?: string
}

export interface CheckboxesProps extends Partial<Omit<MuiCheckboxProps, 'onChange'>> {
  name: string
  data: CheckboxData | CheckboxData[]
  label?: ReactNode
  required?: boolean
  helperText?: string
  fieldProps?: Partial<FieldProps<any, any>>
  formControlProps?: Partial<FormControlProps>
  formGroupProps?: Partial<FormGroupProps>
  formLabelProps?: Partial<FormLabelProps>
  formControlLabelProps?: Partial<FormControlLabelProps>
  formHelperTextProps?: Partial<FormHelperTextProps>
  validate?: any
  children?: ReactNode
  $withTextField?: boolean
  $noLabel?: boolean
}

const config = {
  subscription: {
    error: true,
    submitError: true,
    dirtySinceLastSubmit: true,
    touched: true,
    modified: true,
  },
}

const FormLabelStyled = styled(FormLabel)`
  padding: ${({ theme }) => theme.spacing(1.5)} ${({ theme }) => theme.spacing(2)};
  color: ${({ theme }) => theme.colors.text.primary};
  ${TYPOGRAPHY_MIXIN.subtitle2};
`

const DivStyled = styled('div')<{ $disabled?: boolean; $withTextField?: boolean; $noLabel?: boolean }>`
  padding: 0 ${({ theme }) => theme.spacing(2)};
  display: flex;
  align-items: center;
  gap: ${({ $withTextField, theme }) => ($withTextField ? 0 : theme.spacing(1))};
  &:hover {
    background-color: ${({ $disabled, theme }) => ($disabled ? 'none' : theme.colors.action.hover)};
  }
  ${({ $withTextField }) =>
    $withTextField &&
    css`
      align-items: flex-start;
      & > .MuiFormControlLabel-root {
        height: 3.5rem;
      }
      & > .MuiSvgIcon-root {
        position: relative;
        top: 1rem;
      }
      &:hover {
        background-color: transparent;
      }
      label {
        cursor: default;
      }
    `}
  ${({ $noLabel }) =>
    $noLabel &&
    css`
      display: inline-block;
      &:hover {
        background-color: transparent;
      }
      & > .MuiFormControlLabel-root {
        margin-left: 0;
        cursor: default;
      }
    `}
`

const FormControlLabelStyled = styled(FormControlLabel)`
  height: 3rem;
  margin-right: 0;
  flex-grow: 1;
`

const InfoIconStyled = styled(InfoIcon)`
  font-size: 1.25rem;
`

export const CheckboxGroupField = (props: CheckboxesProps): ReactElement => {
  const {
    required,
    label,
    data,
    name,
    helperText,
    fieldProps,
    formControlProps,
    formGroupProps,
    formLabelProps,
    formControlLabelProps,
    formHelperTextProps,
    validate,
    children,
    $withTextField,
    $noLabel,
    ...restCheckboxes
  } = props

  const itemsData = Array.isArray(data) ? data : [data]
  const single = !Array.isArray(data)
  const field = useField(name, config)
  const { touched, error: errorObject } = field.meta
  const { getMessage } = useMessageSource()
  const error = errorObject && getMessage(errorObject.errorKey, errorObject.params)
  const invalid = Boolean(touched && error)

  return (
    <FormControl required={required} error={invalid} {...formControlProps} fullWidth>
      {label ? <FormLabelStyled {...formLabelProps}>{label}</FormLabelStyled> : <></>}
      <FormGroup {...formGroupProps}>
        {itemsData.map((item: CheckboxData, idx: number) => (
          <DivStyled key={idx} $disabled={item.disabled} $withTextField={$withTextField} $noLabel={$noLabel}>
            <FormControlLabelStyled
              key={idx}
              name={name}
              label={item.label || ''}
              value={single ? undefined : item.value}
              disabled={item.disabled}
              control={
                <Field
                  type="checkbox"
                  name={name}
                  validate={validate}
                  render={({ input: { name, value, onChange, checked, ...restInput } }) => (
                    <MuiCheckbox
                      name={name}
                      value={value}
                      onChange={onChange}
                      checked={checked}
                      disabled={item.disabled}
                      inputProps={{ required, ...restInput }}
                      indeterminate={item.indeterminate}
                      {...restCheckboxes}
                    />
                  )}
                  {...fieldProps}
                />
              }
              {...formControlLabelProps}
            />
            {item.tooltip && (
              <Tooltip title={item.tooltip} placement="right">
                <InfoIconStyled />
              </Tooltip>
            )}
            {children}
          </DivStyled>
        ))}
      </FormGroup>
      <FormHelperText error sx={{ ...($noLabel && { display: 'none' }) }}>
        {(invalid && error) || ' '}
      </FormHelperText>
    </FormControl>
  )
}
