import { createTheme } from '@mui/material'
import { deDE, enUS, frFR, itIT, Localization } from '@mui/material/locale'
import { Shadows } from '@mui/material/styles/shadows'
import { SupportedLocale } from 'src/@types'
import { FOCUS_OUTLINE } from 'src/shared/constants/styling-constants'
import { COLORS } from 'src/theme/colors'
import { SHADOWS } from 'src/theme/shadows'
import { TYPOGRAPHY } from 'src/theme/typography'
import { DefaultTheme } from 'styled-components/macro'

const muiTheme = createTheme({
  colors: COLORS,
  palette: {
    primary: {
      main: COLORS.primary.main,
      contrastText: COLORS.common.white,
    },
    secondary: {
      main: COLORS.secondary.main,
      contrastText: COLORS.common.white,
    },
    error: {
      main: COLORS.error.main,
      contrastText: COLORS.common.white,
    },
    warning: {
      main: COLORS.warning.main,
      contrastText: COLORS.text.primary,
    },
    info: {
      main: COLORS.info.main,
      contrastText: COLORS.common.white,
    },
    success: {
      main: COLORS.success.main,
      contrastText: COLORS.common.white,
    },
    successLight: {
      main: COLORS.successLight.main,
      contrastText: COLORS.common.white,
    },
    // Used by `getContrastText()` to maximize the contrast between
    // the background and the text.
    contrastThreshold: 3,
    // Used by the functions below to shift a color's luminance by approximately
    // two indexes within its tonal palette.
    // E.g., shift from Red 500 to Red 300 or Red 700.
    tonalOffset: 0.2,
    text: {
      primary: COLORS.text.primary,
      secondary: COLORS.text.secondary,
      disabled: COLORS.text.disabled,
    },
    divider: COLORS.grey.divider,
    background: {
      paper: COLORS.common.white,
      default: COLORS.grey.background,
    },
    action: {
      active: COLORS.grey.dark,
      hover: COLORS.action.hover,
      selected: COLORS.primary.light,
      disabled: COLORS.action.disabled,
      focus: COLORS.action.focus,
    },
  },
  shadows: SHADOWS as Shadows,
  typography: TYPOGRAPHY,

  components: {
    MuiCssBaseline: {
      styleOverrides: {
        html: {
          height: '100%',
        },
        body: {
          height: '100%',
          '& #root': {
            height: '100%',
          },
        },
        a: {
          '&:focus-visible': {
            outline: `${FOCUS_OUTLINE.width} dotted ${COLORS.secondary.dark}`,
          },
        },
        p: {
          wordWrap: 'break-word',
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        variant: 'filled',
      },
    },
    MuiButtonBase: {
      // Disables the ripple effect globally
      defaultProps: {
        disableRipple: true,
      },
      styleOverrides: {
        root: {
          '&.Mui-focusVisible': {
            outline: `${FOCUS_OUTLINE.width} dotted ${COLORS.secondary.dark}`,
          },
        },
      },
    },
    MuiButton: {
      defaultProps: {
        disableElevation: true,
        disableRipple: true,
      },
      styleOverrides: {
        root: {
          height: '36px',
        },
        containedPrimary: {
          '&:hover, &.Mui-focusVisible': {
            backgroundColor: COLORS.primary.dark,
          },
        },
        outlinedPrimary: {
          borderColor: COLORS.primary.main,
          '&:hover': {
            backgroundColor: COLORS.primary.light,
            color: COLORS.primary.dark,
          },
          '&.Mui-focusVisible': {
            backgroundColor: COLORS.primary.light,
            color: COLORS.primary.dark,
          },
        },
        textPrimary: {
          '&:hover': {
            backgroundColor: COLORS.primary.light,
            color: COLORS.primary.dark,
          },
          '&.Mui-focusVisible': {
            backgroundColor: COLORS.primary.light,
            color: COLORS.primary.dark,
          },
        },
        containedSecondary: {
          '&:hover, &.Mui-focusVisible': {
            backgroundColor: COLORS.secondary.dark,
          },
        },
        outlinedSecondary: {
          borderColor: COLORS.secondary.main,
          '&:hover': {
            backgroundColor: COLORS.secondary.light,
            color: COLORS.secondary.dark,
          },
          '&.Mui-focusVisible': {
            backgroundColor: COLORS.secondary.light,
            color: COLORS.secondary.dark,
          },
        },
        textSecondary: {
          '&:hover': {
            backgroundColor: COLORS.secondary.light,
            color: COLORS.secondary.dark,
          },
          '&.Mui-focusVisible': {
            backgroundColor: COLORS.secondary.light,
            color: COLORS.secondary.dark,
          },
        },
        containedError: {
          '&:hover, &.Mui-focusVisible': {
            backgroundColor: COLORS.error.dark,
          },
        },
        outlinedError: {
          borderColor: COLORS.error.main,
          '&:hover': {
            backgroundColor: COLORS.error.light,
            color: COLORS.error.dark,
          },
          '&.Mui-focusVisible': {
            backgroundColor: COLORS.error.light,
            color: COLORS.error.dark,
          },
        },
        textError: {
          '&:hover': {
            backgroundColor: COLORS.error.light,
            color: COLORS.error.dark,
          },
          '&.Mui-focusVisible': {
            backgroundColor: COLORS.error.light,
            color: COLORS.error.dark,
          },
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          '&:hover': {
            backgroundColor: COLORS.action.hover,
          },
          '&.Mui-focusVisible': {
            backgroundColor: COLORS.action.focus,
          },
        },
        colorPrimary: {
          '&:hover': {
            backgroundColor: COLORS.primary.light,
            color: COLORS.primary.dark,
          },
        },
      },
    },
    MuiCheckbox: {
      defaultProps: {
        color: 'default',
      },
      styleOverrides: {
        root: {
          '&.MuiCheckbox-colorDefault.Mui-checked': {
            color: COLORS.text.primary,
          },
          '&.MuiCheckbox-colorDefault.Mui-disabled': {
            color: COLORS.text.disabled,
          },
          '&.Mui-focusVisible': {
            backgroundColor: COLORS.action.focus,
            outlineOffset: FOCUS_OUTLINE.inset,
          },
        },
      },
    },
    MuiAppBar: {
      defaultProps: {
        color: 'default',
        elevation: 0,
      },
      styleOverrides: {
        colorDefault: {
          backgroundColor: COLORS.common.white,
          color: COLORS.text.primary,
          borderBottom: `1px solid ${COLORS.grey.divider}`,
        },
      },
    },
    MuiTabs: {
      defaultProps: {
        indicatorColor: 'primary',
      },
      styleOverrides: {
        indicator: {
          height: '4px',
          backgroundColor: COLORS.primary.main,
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          fontWeight: TYPOGRAPHY.subtitle2.fontWeight,
          fontSize: TYPOGRAPHY.subtitle2.fontSize,
          lineHeight: TYPOGRAPHY.subtitle2.lineHeight,
          letterSpacing: TYPOGRAPHY.subtitle2.letterSpacing,
          textTransform: 'none',
          minWidth: 'max-content',
          '@media (min-width: 600px)': {
            minWidth: 'max-content',
          },
          '&:hover': {
            opacity: 0.6,
            backgroundColor: COLORS.action.hover,
            borderRadius: '4px 4px 0 0',
            boxShadow: `inset 0px -4px 0px ${COLORS.action.disabled}`,
          },
          '&.Mui-selected': {
            color: COLORS.text.primary,
            '&:hover': {
              opacity: 1,
            },
          },
          '&.Mui-focusVisible': {
            backgroundColor: COLORS.action.focus,
            outlineOffset: FOCUS_OUTLINE.inset,
            opacity: 1,
          },
        },
        textColorInherit: {
          opacity: 0.48,
        },
      },
    },
    MuiSwitch: {
      defaultProps: {
        color: 'primary',
      },
    },
    MuiTooltip: {
      defaultProps: {
        arrow: true,
      },
      styleOverrides: {
        tooltip: {
          backgroundColor: COLORS.text.primary,
          fontSize: TYPOGRAPHY.caption.fontSize,
          lineHeight: TYPOGRAPHY.caption.lineHeight,
        },
        arrow: {
          color: COLORS.text.primary,
          fontSize: '0.5625rem',
        },
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          backgroundColor: COLORS.action.hover,
          '&:hover:not($disabled)': {
            backgroundColor: COLORS.action.selected,
          },
          '&.Mui-focused': {
            backgroundColor: COLORS.action.hover,
          },
          '&.Mui-focusedVisible': {
            outline: `${FOCUS_OUTLINE.width} dotted ${COLORS.secondary.dark}`,
          },
        },
      },
    },
    MuiInputAdornment: {
      styleOverrides: {
        root: {
          '& .MuiButtonBase-root:hover': {
            backgroundColor: COLORS.action.hover,
          },
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        endAdornment: {
          '& .MuiButtonBase-root:hover': {
            backgroundColor: COLORS.action.hover,
          },
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          fontSize: TYPOGRAPHY.body2.fontSize,
          fontWeight: TYPOGRAPHY.body2.fontWeight,
          letterSpacing: TYPOGRAPHY.body2.letterSpacing,
          '&.MuiChip-filledDefault': {
            color: COLORS.text.secondary,
            backgroundColor: COLORS.grey.chip.light,
          },
          '&.MuiChip-filledPrimary': {
            color: COLORS.primary.dark,
            backgroundColor: COLORS.primary.light,
          },
          '&.MuiChip-filledSecondary': {
            color: COLORS.secondary.dark,
            backgroundColor: COLORS.secondary.light,
          },
          '&.MuiChip-filledSuccess': {
            color: COLORS.success.dark,
            backgroundColor: COLORS.success.light,
          },
          '&.MuiChip-filledError': {
            color: COLORS.error.dark,
            backgroundColor: COLORS.error.light,
          },
          '&.MuiChip-filledWarning': {
            color: COLORS.warning.dark,
            backgroundColor: COLORS.warning.light,
          },
          '&.MuiChip-filledSuccessLight': {
            color: COLORS.successLight.dark,
            backgroundColor: COLORS.successLight.light,
          },
          '&.MuiChip-outlinedDefault': {
            color: COLORS.text.secondary,
          },
        },
        deletableColorPrimary: {
          '& .MuiChip-deleteIcon': {
            color: COLORS.primary.main,
            '&:hover': {
              color: COLORS.primary.dark,
            },
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        filled: {
          '&:focus': {
            backgroundColor: 'transparent',
          },
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          height: '3rem',
          '&.Mui-focusVisible': {
            outlineOffset: FOCUS_OUTLINE.inset,
          },
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            color: COLORS.primary.dark,
          },
          '&.Mui-focusVisible': {
            outlineOffset: FOCUS_OUTLINE.inset,
          },
        },
      },
    },
    // TODO remove @ts-ignore when mui PR #2307 [DataGrid] TypeScript module augmentation for theme will be merged
    //@ts-ignore
    MuiDataGrid: {
      defaultProps: {
        rowHeight: 56,
      },
      styleOverrides: {
        root: {
          backgroundColor: COLORS.common.white,
          boxShadow: SHADOWS[1],
          border: 'none',
        },
        columnHeader: {
          color: COLORS.text.disabled,
          fontWeight: TYPOGRAPHY.subtitle2.fontWeight,
        },
        cell: {
          '&.MuiDataGrid-cell--editing.MuiDataGrid-cell--editable': {
            padding: 0,
          },
          '&.MuiDataGrid-cell--editable': {
            backgroundColor: COLORS.action.hover,
            borderBottom: `1px solid ${COLORS.action.disabled}`,
          },
          '&.MuiDataGrid-cell--editing': {
            backgroundColor: COLORS.common.white,
          },
        },
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          '& .MuiTableCell-root': {
            color: COLORS.text.disabled,
          },
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          '&:last-child .MuiTableCell-body': {
            border: 'none',
          },
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        standardSuccess: {
          backgroundColor: COLORS.success.light,
          '& .MuiAlert-icon': {
            color: COLORS.success.main,
          },
        },
        standardError: {
          backgroundColor: COLORS.error.light,
          '& .MuiAlert-icon': {
            color: COLORS.error.main,
          },
        },
        standardWarning: {
          backgroundColor: COLORS.warning.light,
          '& .MuiAlert-icon': {
            color: COLORS.warning.dark,
          },
        },
        standardInfo: {
          backgroundColor: COLORS.info.light,
          '& .MuiAlert-icon': {
            color: COLORS.info.main,
          },
        },
      },
    },
    MuiCardHeader: {
      defaultProps: {
        titleTypographyProps: {
          variant: 'h3',
        },
      },
      styleOverrides: {
        root: {
          '& .MuiCardHeader-action': {
            margin: 0,
          },
        },
      },
    },
    MuiCalendarPicker: {
      styleOverrides: {
        root: {
          '& .Mui-selected:focus': {
            backgroundColor: COLORS.primary.main,
          },
          '& .Mui-selected:hover': {
            backgroundColor: COLORS.primary.dark,
          },
        },
      },
    },
  },
})

// Overrides for components using muiTheme.spacing

muiTheme.components!.MuiCardContent = {
  styleOverrides: {
    root: {
      padding: muiTheme.spacing(2),
      '&:last-child': {
        paddingBottom: muiTheme.spacing(2),
      },
    },
  },
}

muiTheme.components!.MuiDialogTitle = {
  styleOverrides: {
    root: {
      padding: muiTheme.spacing(3),
    },
  },
}

muiTheme.components!.MuiDialogActions = {
  styleOverrides: {
    root: {
      paddingTop: muiTheme.spacing(2),
      paddingLeft: muiTheme.spacing(3),
      paddingBottom: muiTheme.spacing(2),
      paddingRight: muiTheme.spacing(3),
      '& .MuiButtonBase-root:last-child': {
        marginLeft: muiTheme.spacing(2),
      },
    },
  },
}

muiTheme.components!.MuiDialogContent = {
  styleOverrides: {
    root: {
      paddingBottom: muiTheme.spacing(2),
      '&.MuiDialogContent-root': {
        paddingTop: muiTheme.spacing(2),
      },
      color: COLORS.text.secondary,
    },
  },
}

muiTheme.components!.MuiPaper = {
  styleOverrides: {
    root: {
      '& .MuiGridFilterForm-root': {
        gridGap: muiTheme.spacing(1),
        padding: muiTheme.spacing(1.5),
        paddingLeft: muiTheme.spacing(1),
        '& .MuiFormControl-root:first-child': {
          marginRight: 0,
        },
      },
      '& .MuiGridPanelFooter-root': {
        padding: muiTheme.spacing(1),
      },
    },
  },
}

const styledTheme: DefaultTheme = {
  colors: COLORS,
  spacing: muiTheme.spacing,
  shadows: SHADOWS,
  transitions: muiTheme.transitions,
  zIndex: muiTheme.zIndex,
  typography: TYPOGRAPHY,
}

const mapUserLanguageToMuiLocale = (language: SupportedLocale): Localization => {
  switch (language) {
    case 'en':
      return enUS
    case 'de':
      return deDE
    case 'it':
      return itIT
    case 'fr':
      return frFR
    default:
      throw Error(`Unknown language ${language}`)
  }
}

export { muiTheme, styledTheme, mapUserLanguageToMuiLocale }
