import { Typography, TypographyTypeMap } from '@mui/material'
import { ReactElement } from 'react'
import { usePermissionsForSuccessFactors } from 'src/service/security/PermissionHook'
import { AddButton } from 'src/shared/button/Buttons'
import { S } from 'src/shared/styled/S'

export const SuccessFactorGridToolbar = ({
  title,
  variant = 'h3',
  createAnalysis,
  buttonKey = 'button.success.factor.analysis.add',
  canCreateAnalysis = false,
}: {
  buttonKey?: string
  title?: string
  variant?: TypographyTypeMap['props']['variant']
  createAnalysis: React.MouseEventHandler<HTMLButtonElement>
  canCreateAnalysis: boolean
}): ReactElement => {
  const { canEdit } = usePermissionsForSuccessFactors()

  return (
    <>
      <S.DataGrid.ToolbarContainer>
        <Typography variant={variant}>{title}</Typography>
        {canEdit && <AddButton messageKey={buttonKey} onClick={createAnalysis} hidden={!canCreateAnalysis} />}
      </S.DataGrid.ToolbarContainer>
    </>
  )
}
