import { Stack } from '@mui/material'
import { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { Evaluation_Milestone, Query_Root } from 'src/@types/graphql'
import { useMessageSource } from 'src/i18n/useMessageSource'
import { resolveDetailsRoot } from 'src/routing/routing-utils'
import { queryEvaluationMilestonesByDossierId } from 'src/screens/shared/project/details/effectivity-management/effectivityManagementQueries'
import { ExpandableEvaluationMilestoneCard } from 'src/screens/shared/project/details/effectivity-management/evaluation-milestone/evaluation-milestone-card/ExpandableEvaluationMilestoneCard'
import { AddButton } from 'src/shared/button/Buttons'
import { HelpAndInstructions } from 'src/shared/presentation/HelpAndInstructions'
import { Section } from 'src/shared/presentation/Section'
import { useDelayedNavigate } from 'src/shared/utils/hooks/navigation-hooks'
import { useNotificationService } from 'src/shared/utils/NotificationService'
import { Utils } from 'src/shared/utils/Utils'
import { useClient } from 'urql'

interface Props {
  baseUrl: '/pf-kap' | '/pf-pgv'
  dossierId: number
  canEdit: boolean
}

export const EffectivityEvaluationMilestonesSection = ({ baseUrl, dossierId, canEdit }: Props) => {
  const { projectId } = useParams()
  const { getMessage } = useMessageSource()

  const urqlClient = useClient()
  const notificationService = useNotificationService()
  const navigate = useDelayedNavigate()

  const projectType = Utils.resolveProcess(baseUrl)
  const [evaluationMilestones, setEvaluationMilestones] = useState<Evaluation_Milestone[]>()

  const fetchData = useCallback(async () => {
    const { data } = await urqlClient
      .query<{ evaluation_milestone: Query_Root['evaluation_milestone'] }, { dossierId: number }>(
        queryEvaluationMilestonesByDossierId,
        { dossierId: dossierId },
      )
      .toPromise()

    if (data) {
      setEvaluationMilestones(data.evaluation_milestone)
    } else {
      notificationService.operationFailed()
    }
  }, [dossierId, notificationService, urqlClient])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  const handleAddNewEntry = () => {
    const prePath = resolveDetailsRoot(baseUrl)
    navigate(prePath.nested.EffectivityManagementEvaluationMilestoneNew.params({ projectId }))
  }

  const handleEdit = (evaluationMilestoneId: number) => {
    const prePath = resolveDetailsRoot(baseUrl)
    navigate(prePath.nested.EffectivityManagementEvaluationMilestoneEdit.params({ projectId, evaluationMilestoneId }))
  }

  return (
    <Section
      title={getMessage('label.title.evaluation.milestones')}
      actionButton={
        canEdit && <AddButton messageKey={getMessage('button.add.evaluation.milestone')} onClick={handleAddNewEntry} />
      }
      helpAndInstructions={<HelpAndInstructions labelKey={`label.help.project.evaluation.milestones.${projectType}`} />}
      optional
    >
      {evaluationMilestones?.length && (
        <Stack spacing={1}>
          {evaluationMilestones?.map((it) => {
            return (
              <ExpandableEvaluationMilestoneCard
                key={it.id}
                id={String(it.id)}
                evaluationMilestone={it}
                canEdit={canEdit}
                onEdit={() => handleEdit(it.id)}
                refetch={fetchData}
              />
            )
          })}
        </Stack>
      )}
    </Section>
  )
}
