import { gql } from 'urql'

export const queryFiles = gql`
  query ($fileType: String!, $entityType: String!, $entityId: Int!) {
    file(
      where: { file_type: { _eq: $fileType }, entity_type: { _eq: $entityType }, entity_id: { _eq: $entityId } }
      order_by: { upload_date: desc }
    ) {
      id
      file_name
      file_title
      upload_date
      uuid
    }
  }
`
