import { Box, Typography, TypographyTypeMap } from '@mui/material'
import { ReactElement } from 'react'
import { AddButton } from 'src/shared/button/Buttons'
import { S } from 'src/shared/styled/S'

export const AddProjectToolbar = ({
  title,
  variant = 'h3',
  addProject,
  buttonKey = 'button.project.add',
  canCreateProject = false,
}: {
  buttonKey?: string
  title?: string
  variant?: TypographyTypeMap['props']['variant']
  addProject: React.MouseEventHandler<HTMLButtonElement>
  canCreateProject: boolean
}): ReactElement => {
  return (
    <>
      <S.DataGrid.ToolbarContainer>
        <Typography variant={variant}>{title}</Typography>
        <Box>
          <AddButton messageKey={buttonKey} onClick={addProject} hidden={!canCreateProject} />
        </Box>
      </S.DataGrid.ToolbarContainer>
    </>
  )
}
