import { gql } from '@urql/core'

export const deleteAssessmentMutationQuery = gql`
  mutation ($assessment_id: Int!) {
    delete_project_assessment(where: { id: { _eq: $assessment_id } }) {
      affected_rows
    }
  }
`

export const getProjectAssessmentById = gql`
  query fetchProjectAssessment($assessmentId: Int!, $projectBaseId: Int!) {
    project_assessment(where: { _and: { id: { _eq: $assessmentId }, project_base_id: { _eq: $projectBaseId } } }) {
      id
      user {
        id
        first_name
        last_name
        email
      }
    }
  }
`

export const getProjectAssessmentAndApplicationCodeId = gql`
  query fetchProjectAssessment(
    $assessmentId: Int!
    $projectBaseId: Int!
    $isPfKap: Boolean!
    $isPfPgv: Boolean!
    $projectId: Int!
  ) {
    project_assessment(where: { _and: { id: { _eq: $assessmentId }, project_base_id: { _eq: $projectBaseId } } }) {
      id
      user {
        id
        first_name
        last_name
        email
      }
    }
    pf_kap_project(where: { id: { _eq: $projectId } }) @include(if: $isPfKap) {
      application_type_id
    }
    pf_pgv_project(where: { id: { _eq: $projectId } }) @include(if: $isPfPgv) {
      application_type_id
    }
  }
`

export const getApplicationTypeIdFromProjects = gql`
  query fetchApplicationTypeId($isPfKap: Boolean!, $isPfPgv: Boolean!, $projectId: Int!) {
    pf_kap_project(where: { id: { _eq: $projectId } }) @include(if: $isPfKap) {
      application_type_id
    }
    pf_pgv_project(where: { id: { _eq: $projectId } }) @include(if: $isPfPgv) {
      application_type_id
    }
  }
`

export const getCriteriaConfigByProcessQuery = gql`
  query fetchCriteriaGroupByProcess(
    $process: jsonb!
    $applicationTypeId: jsonb!
    $criteriaType: String!
    $assessment: Int!
  ) {
    project_assessment_by_pk(id: $assessment) {
      id
      version
    }
    project_assessment_criteria_selection(
      where: {
        _and: {
          project_assessment_id: { _eq: $assessment }
          criteria_config: { criteria_type: { _eq: $criteriaType } }
        }
      }
    ) {
      id
      criteria_config_id
      rating
      criteria_config {
        criteria_id
      }
    }
    criteria_group_config(
      where: {
        criteria_configs: { _and: { processes: { _contains: $process }, criteria_type: { _eq: $criteriaType } } }
      }
    ) {
      id
      names
      code
      criteria_configs(
        where: { application_type_ids: { _contains: $applicationTypeId } }
        order_by: { sort_number: asc }
      ) {
        id
        criteria_id
        criteria_type
        description
        processes
        application_type_ids
        code
        active
        sort_number
      }
    }
  }
`

export const saveProjectAssessmentCriteriaSelection = gql`
  mutation insert_selection(
    $assessmentId: Int!
    $selections: [project_assessment_criteria_selection_insert_input!]!
    $criteriaType: String!
    $version: Int!
  ) {
    delete_project_assessment_criteria_selection(
      where: {
        _and: {
          project_assessment_id: { _eq: $assessmentId }
          criteria_config: { criteria_type: { _eq: $criteriaType } }
          project_assessment: { version: { _eq: $version } }
        }
      }
    ) {
      affected_rows
    }
    insert_project_assessment_criteria_selection(
      objects: $selections
      on_conflict: { constraint: criteria_selection_project_assessment_id_criteria_config_id_uq, update_columns: [] }
    ) {
      affected_rows
    }
    update_project_assessment(
      where: { id: { _eq: $assessmentId }, version: { _eq: $version } }
      _set: { version: $version }
    ) {
      affected_rows
      returning {
        version
      }
    }
  }
`

export const getAssessmentTypesQuery = gql`
  query fetchAssessmentTypesByProcess($process: String!) {
    assessment_instance_type(where: { process: { _eq: $process } }) {
      id
      code
      key
      sort_number
      process
    }
  }
`

export const getUsersQuery = gql`
  query fetchUsers($role: String!, $userRoleWhere: user_roles_bool_exp!, $status: String!) {
    external_users: user_roles(where: { role: { _eq: $role }, user: { status: { _eq: $status } } }) {
      user {
        first_name
        last_name
        email
        id
      }
    }
    internal_users: user_roles(where: $userRoleWhere) {
      user {
        first_name
        last_name
        email
        id
      }
    }
  }
`

export const insertMultipleAssessmentsMutation = gql`
  mutation insert_assessments($objects: [project_assessment_insert_input!]!, $users: [project_user_insert_input!]!) {
    insert_project_assessment(
      objects: $objects
      on_conflict: { constraint: project_assessment_project_base_id_assessor_id_instance_id_key, update_columns: [] }
    ) {
      affected_rows
      returning {
        id
        user {
          first_name
          last_name
          email
          language
        }
      }
    }
    insert_project_user(
      objects: $users
      on_conflict: { constraint: project_user_project_base_id_user_id_uq, update_columns: [] }
    ) {
      affected_rows
      returning {
        id
      }
    }
  }
`

export const notifyAssessorsQuery = gql`
  mutation notifyAssessors($assessors: [AssessorInput!]!) {
    notifyAssessors(assessors: $assessors) {
      status
    }
  }
`

export const upsertMilestoneAssessmentQuery = gql`
  mutation upsertMilestoneAssessment($milestoneId: Int!, $assessorType: String!) {
    insert_milestone_assessment_one(
      object: { milestone_id: $milestoneId, assessor_type: $assessorType }
      on_conflict: { constraint: milestone_id_assessor_type_id_uq, update_columns: [milestone_id, assessor_type] }
    ) {
      id
    }
  }
`

export const getCriteriaConfigForMilestoneAssessmentQuery = gql`
  query getCriteriaConfigForMilestoneAssessment(
    $milestoneId: Int!
    $process: jsonb!
    $criteriaType: String!
    $assessorType: String!
    $applicationTypeId: jsonb!
  ) {
    milestone_assessment_criteria_selection(
      where: {
        _and: {
          milestone_assessment: { _and: { milestone_id: { _eq: $milestoneId }, assessor_type: { _eq: $assessorType } } }
        }
      }
    ) {
      id
      criteria_config_id
      rating
      criteria_config {
        criteria_id
      }
    }

    criteria_group_config(
      where: {
        criteria_configs: { _and: { processes: { _contains: $process }, criteria_type: { _eq: $criteriaType } } }
      }
    ) {
      id
      names
      code
      criteria_configs(
        where: { application_type_ids: { _contains: $applicationTypeId } }
        order_by: { sort_number: asc }
      ) {
        id
        criteria_id
        criteria_type
        description
        processes
        code
        active
        sort_number
        application_type_ids
      }
    }
  }
`

export const updateCriteriaSelectionForMilestoneAssessmentQuery = gql`
  mutation updateCriteriaSelectionForMilestoneAssessment(
    $milestoneId: Int!
    $assessorType: String!
    $selections: [milestone_assessment_criteria_selection_insert_input!]!
  ) {
    delete_milestone_assessment_criteria_selection(
      where: {
        milestone_assessment: { _and: { milestone_id: { _eq: $milestoneId }, assessor_type: { _eq: $assessorType } } }
      }
    ) {
      affected_rows
    }
    insert_milestone_assessment_criteria_selection(objects: $selections) {
      affected_rows
    }
  }
`

export const getProjectAssessmentCommentsQuery = gql`
  query fetchProjectAssessmentComments($id: Int!, $isQuality: Boolean!, $isExclusion: Boolean!) {
    project_assessment_by_pk(id: $id) {
      assessment_comment @include(if: $isQuality)
      exclusion_comment @include(if: $isExclusion)
    }
  }
`

export const updateProjectAssessmentCommentsMutation = gql`
  mutation updateProjectAssessmentComments($id: Int!, $object: project_assessment_set_input!) {
    update_project_assessment(where: { id: { _eq: $id } }, _set: $object) {
      affected_rows
    }
  }
`

export const getMilestoneAssessmentCommentsQuery = gql`
  query fetchMilestoneAssessmentComments($id: Int!, $type: String!) {
    milestone_assessment(where: { _and: { milestone_id: { _eq: $id }, assessor_type: { _eq: $type } } }) {
      comment
    }
  }
`

export const upsertMilestoneAssessmentCommentsMutation = gql`
  mutation upsertMilestoneAssessmentComments($object: milestone_assessment_insert_input!) {
    insert_milestone_assessment_one(
      object: $object
      on_conflict: { constraint: milestone_id_assessor_type_id_uq, update_columns: [comment] }
    ) {
      id
    }
  }
`
