import { Box, Chip, Stack } from '@mui/material'
import { get } from 'lodash'
import { ReactElement, ReactNode } from 'react'
import { Form } from 'react-final-form'
import { useMessageSource } from 'src/i18n/useMessageSource'
import { CriteriaConfigTable } from 'src/screens/shared/application/assessment/details/CriteriaConfigTable'
import {
  CriteriaFormValues,
  CriteriaGroups,
  QUALITY_PREFIX,
} from 'src/screens/shared/assessment-criteria/utils/AssessmentUtils'
import { EditButton } from 'src/shared/button/Buttons'
import { PageLayout } from 'src/shared/layout/PageLayout'
import { ScreenLayout } from 'src/shared/layout/ScreenLayout'
import { HelpAndInstructions } from 'src/shared/presentation/HelpAndInstructions'
import { Section } from 'src/shared/presentation/Section'
import { useUserLocale } from 'src/user/UserContext'
import styled from 'styled-components/macro'

const StyledChip = styled(Chip)`
  min-width: 50px;
`

interface Props {
  screenLayoutTitle: string
  onBackHandler: () => void
  actions?: ReactElement
  sectionLayoutTitle: string
  helpText: string
  criteriaGroups: CriteriaGroups | undefined
  initialValues: CriteriaFormValues | undefined
  canEdit: boolean
  onEditHandler: () => void
  loading: boolean
  optional?: boolean
  commentSection?: ReactNode
}

export const BaseAssessmentCriteria = ({
  screenLayoutTitle,
  onBackHandler,
  actions,
  sectionLayoutTitle,
  helpText,
  criteriaGroups,
  initialValues,
  canEdit,
  onEditHandler,
  loading,
  optional,
  commentSection,
}: Props) => {
  const { getMessage } = useMessageSource()

  const language = useUserLocale()

  return (
    <ScreenLayout title={screenLayoutTitle} onBack={onBackHandler} actions={actions}>
      {!loading && (
        <PageLayout>
          <Section
            id="assessment-criteria"
            title={sectionLayoutTitle}
            actionButton={<EditButton origin="content" color="primary" hidden={!canEdit} onClick={onEditHandler} />}
            helpAndInstructions={<HelpAndInstructions labelKey={helpText} />}
            optional={optional}
          >
            <Box mt={2}>
              <Form
                initialValues={initialValues}
                onSubmit={() => {}}
                render={({ handleSubmit, values }) => {
                  return (
                    <form onSubmit={handleSubmit} noValidate>
                      <Stack spacing={5}>
                        {Object.values(criteriaGroups || {})
                          .sort((a, b) => a?.[0].sort_number - b?.[0].sort_number)
                          .map((criteriaSelections) => (
                            <CriteriaConfigTable
                              key={criteriaSelections[0].id}
                              rows={criteriaSelections}
                              groupTitle={get(criteriaSelections[0].names, language, '')}
                              mode="edit" // 2021-06-12 ABF: Just to make chip not disabled
                              EditControl={StyledChip}
                              resolveControlProps={(row) => ({
                                name: `${QUALITY_PREFIX}${row.id}`,
                                label: values[`${QUALITY_PREFIX}${row.id}`] || getMessage('label.field.not.available'),
                              })}
                            />
                          ))}
                      </Stack>
                    </form>
                  )
                }}
              />
            </Box>
          </Section>
          {commentSection}
        </PageLayout>
      )}
    </ScreenLayout>
  )
}
