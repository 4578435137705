export const FILE_MAX_SIZE_IN_BYTES = 10485760
export const FILE_MAX_SIZE_IN_MB = FILE_MAX_SIZE_IN_BYTES / 1048576
export const FILE_EXTENSIONS = '.doc,.docx,.pdf,.xlsx,.pptx'
export const EMAIL_FILE_EXTENSIONS = '.msg,.eml'
// mime types & file extensions must match
export const FILE_MIME_TYPES =
  'application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/pdf,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.openxmlformats-officedocument.presentationml.presentation'

export const FILE_MIME_ACCEPT_TYPES = {
  'application/msword': ['.doc'],
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
  'application/pdf': ['.pdf'],
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
  'application/vnd.openxmlformats-officedocument.presentationml.presentation': ['.pptx'],
}

export const EMAIL_FILE_MIME_ACCEPT_TYPES = {
  '	application/vnd.ms-outlook': ['.msg', '.eml'],
}
