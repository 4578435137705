import { ReactElement } from 'react'
import { useParams } from 'react-router'
import { ApplicationAssessmentRecommendation } from 'src/screens/shared/application/assessment/details/recommendation/ApplicationAssessmentRecommendation'

export const PfPgvRecommendationPage = (): ReactElement => {
  const { projectId, assessmentId } = useParams()
  const pf_pgv_project_id = parseInt(projectId as string)
  const pf_pgv_assessment_id = parseInt(assessmentId as string)

  return (
    <ApplicationAssessmentRecommendation
      baseUrl="/pf-pgv"
      projectId={pf_pgv_project_id}
      assessmentId={pf_pgv_assessment_id}
    />
  )
}
