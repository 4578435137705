import { Stack } from '@mui/material'
import { ReactElement, useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { Kap_Program, Maybe, Principle_Config, Query_Root } from 'src/@types/graphql'
import { ExpandableSubprincipleCard } from 'src/screens/shared/principles/ExpandableSubprincipleCard'
import { fetchPrinciplesWithSubprinciplesQuery } from 'src/screens/shared/principles/kapProgramPrinciplesQueries'
import { PrinciplesPhaseType } from 'src/screens/shared/principles/PrinciplesUtils'
import { PageLayout } from 'src/shared/layout/PageLayout'
import { ScreenLayout } from 'src/shared/layout/ScreenLayout'
import { NoAccessPrinciples } from 'src/shared/not-authorized/NoAccessPrinciples'
import { HelpAndInstructions } from 'src/shared/presentation/HelpAndInstructions'
import { Section } from 'src/shared/presentation/Section'
import { useNotificationService } from 'src/shared/utils/NotificationService'
import { useUserLocale } from 'src/user/UserContext'
import { useClient } from 'urql'

interface Props {
  phase: PrinciplesPhaseType
  screenTitle: string
  loading: boolean
  canEdit: boolean
  onEditSubprinciple: (subprincipleId: number) => void
  actions?: ReactElement
  onBack?: () => void
}

export const KapProgramPrinciples = ({
  phase,
  screenTitle,
  loading,
  canEdit,
  onEditSubprinciple,
  actions,
  onBack,
}: Props): ReactElement => {
  const locale = useUserLocale()
  const urqlClient = useClient()
  const notificationService = useNotificationService()
  const { programId } = useParams()

  const program_id = parseInt(programId as string)
  const [principlesData, setPrinciplesData] = useState<Principle_Config[]>()
  const [programData, setProgramData] = useState<Maybe<Kap_Program> | undefined>()

  useEffect(() => {
    const initData = async () => {
      const { data } = await urqlClient
        .query<{
          principle_config: Query_Root['principle_config']
          kap_program_by_pk: Query_Root['kap_program_by_pk']
        }>(fetchPrinciplesWithSubprinciplesQuery, {
          locale: locale,
          programId: program_id,
        })
        .toPromise()

      if (data) {
        setPrinciplesData(data.principle_config)
        setProgramData(data.kap_program_by_pk)
      } else {
        notificationService.operationFailed()
      }
    }

    initData()
  }, [locale, urqlClient, notificationService, program_id])

  const programCreatedAfterPrinciples: boolean = programData?.created_after_principles === true

  return (
    <>
      <ScreenLayout title={screenTitle} actions={actions} onBack={onBack}>
        <PageLayout>
          <>
            {!loading &&
              programData &&
              (!programCreatedAfterPrinciples ? (
                <NoAccessPrinciples inSecondLevelNavigationTab />
              ) : (
                <>
                  {principlesData?.map((principle) => (
                    <Section
                      key={principle.id}
                      title={principle.names}
                      helpAndInstructions={<HelpAndInstructions labelKey={principle.help_and_instructions} />}
                    >
                      <Stack spacing={1}>
                        {principle.subprinciple_configs.map((subprinciple) => (
                          <ExpandableSubprincipleCard
                            key={subprinciple.id}
                            subprinciple={subprinciple}
                            phase={phase}
                            onEdit={() => onEditSubprinciple(subprinciple.id)}
                            canEdit={canEdit}
                          />
                        ))}
                      </Stack>
                    </Section>
                  ))}
                </>
              ))}
          </>
        </PageLayout>
      </ScreenLayout>
    </>
  )
}
