import { ReactElement, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { Query_Root } from 'src/@types/graphql'
import { useMessageSource } from 'src/i18n/useMessageSource'
import { ROUTES } from 'src/routing/routes'
import { usePermissionsForKapProgram } from 'src/service/security/PermissionHook'
import { BASE_URL, PROJECT } from 'src/shared/constants/constants'
import { Sidebar, SidebarGroupObject } from 'src/shared/sidebar/Sidebar'
import { useSidebarContext } from 'src/shared/sidebar/SidebarAwareContext'
import { useNotificationService } from 'src/shared/utils/NotificationService'
import { gql, useQuery } from 'urql'

const getKapProgramByIdQuery = gql`
  query fetchKapProgram($id: Int!) {
    kap_program_by_pk(id: $id) {
      id
      dossier {
        short_title
        status
      }
    }
  }
`

export const KapSidebar = (): ReactElement => {
  const { programId } = useParams()
  const id = parseInt(programId as string)
  const { getMessage } = useMessageSource()
  const notificationService = useNotificationService()

  const [{ data, error, fetching }, refetchQuery] = useQuery<
    {
      kap_program_by_pk: Query_Root['kap_program_by_pk']
    },
    {
      id: number
    }
  >({ query: getKapProgramByIdQuery, variables: { id } })

  if (error) {
    notificationService.operationFailed()
  }

  const dossier = data?.kap_program_by_pk?.dossier
  const shortTitle = dossier?.short_title
  const kapProgramId = data?.kap_program_by_pk?.id
    ? `${getMessage('label.program.id.character')}${programId}`
    : `${getMessage('label.not.available')}`
  const status = dossier?.status

  useSidebarContext(refetchQuery)

  const { canViewCantonRestricted } = usePermissionsForKapProgram(id)

  const routeGroups: SidebarGroupObject[] = useMemo(
    () => [
      {
        groupMessageKey: 'label.program',
        list: [
          {
            listMessageKey: 'label.navigation.program.details',
            href: ROUTES.KapDetailsRoot.params({ programId }),
            routePath: ROUTES.KapDetailsRoot.path,
          },
        ],
      },
      {
        groupMessageKey: 'label.application',
        list: canViewCantonRestricted
          ? [
              {
                listMessageKey: 'label.navigation.application.details',
                href: ROUTES.KapApplicationDetailsRoot.params({ programId }),
                routePath: ROUTES.KapApplicationDetailsRoot.path,
              },
            ]
          : [],
      },
      {
        groupMessageKey: 'label.implementation',
        list: canViewCantonRestricted
          ? [
              {
                listMessageKey: 'label.navigation.program.implementation',
                href: ROUTES.KapImplementationDetailsRoot.params({ programId }),
                routePath: ROUTES.KapImplementationDetailsRoot.path,
              },
            ]
          : [],
      },
    ],
    [programId, canViewCantonRestricted],
  )

  return (
    <Sidebar
      title={shortTitle}
      entityId={kapProgramId}
      status={status}
      routes={routeGroups}
      process={PROJECT.KAP}
      home={{ url: BASE_URL.KAP, label: getMessage('label.navigation.kap.cockpit') }}
      loading={fetching}
    />
  )
}
