import { gql } from '@urql/core'

export const queryProjectBudgetByProjectBase = gql`
  query FetchProjectBudget($projectBaseQuery: project_base_bool_exp) {
    project_base(where: $projectBaseQuery) {
      id
      project_budget {
        id
        gfch_share_budget
        share_responsible_organization_budget
        other_funding_budget
        gfch_share_approved
        share_responsible_organization_approved
        other_funding_approved
        version
      }
      pf_kap_projects {
        application_type {
          code
        }
      }
    }
  }
`

export const updateProjectBudget = gql`
  mutation ($projectBudget: [project_budget_insert_input!]!, $updateColumns: [project_budget_update_column!]!) {
    insert_project_budget(
      objects: $projectBudget
      on_conflict: { constraint: project_budget_project_base_id_uq, update_columns: $updateColumns }
    ) {
      affected_rows
      returning {
        version
      }
    }
  }
`
