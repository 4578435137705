import { Stack } from '@mui/material'
import { ReactElement, useCallback, useEffect, useState } from 'react'
import { Form } from 'react-final-form'
import { useParams } from 'react-router'
import { FormValidationErrors } from 'src/@types/global'
import {
  Application_Type,
  Funding_Round,
  Funding_Round_Insert_Input,
  Mutation_Root,
  Query_Root,
} from 'src/@types/graphql'
import { useMessageSource } from 'src/i18n/useMessageSource'
import { ROUTES } from 'src/routing/routes'
import {
  getRoundInformationById,
  updateFundingRoundMutation,
} from 'src/screens/administration/round-management/round-management-queries'
import { RoundManagementUtils } from 'src/screens/administration/round-management/RoundManagementUtils'
import { SaveAndBackButton, SaveButton } from 'src/shared/button/Buttons'
import { TEXT_LENGTH } from 'src/shared/constants/constants'
import { Option } from 'src/shared/form/control'
import { AutoCompleteField } from 'src/shared/form/control/AutoCompleteField'
import { DateTimePickerField } from 'src/shared/form/control/DateTimePickerField'
import { TextField } from 'src/shared/form/control/TextField'
import { DirtyFormSpy } from 'src/shared/form/dirty/DirtyFormSpy'
import { createDecorators } from 'src/shared/form/utils/decorators'
import { composeValidators, maxChar, required, validDate } from 'src/shared/form/validation/validators'
import { PageLayout } from 'src/shared/layout/PageLayout'
import { ScreenLayout } from 'src/shared/layout/ScreenLayout'
import { HelpAndInstructions } from 'src/shared/presentation/HelpAndInstructions'
import { DateUtils } from 'src/shared/utils/DateUtils'
import { useDelayedNavigate } from 'src/shared/utils/hooks/navigation-hooks'
import { useNotificationService } from 'src/shared/utils/NotificationService'
import { useClient } from 'urql'

const decorators = createDecorators()

interface EditForm {
  name: string
  type: string
  startDate: string
  endDate: string
  decisionDate: string
  version: number
}

export const RoundInformationEditPage = (): ReactElement => {
  const { getMessage } = useMessageSource()
  const navigate = useDelayedNavigate()
  const { roundId } = useParams()
  const urqlClient = useClient()
  const notificationService = useNotificationService()

  const [initialValues, setInitialValues] = useState<EditForm | null>(null)
  const [typeOptions, setTypeOptions] = useState<Option[]>([])

  const onBackHandler = () => {
    navigate(ROUTES.RoundManagementDetailsRoot.params({ roundId }))
  }

  const mapFundingRoundToFundingRoundEditForm = useCallback(
    (fundingRound: Funding_Round, applicationTypes: Application_Type[]) => ({
      name: fundingRound.name ?? '',
      type: RoundManagementUtils.resolveRoundType(
        fundingRound.process,
        fundingRound.application_types,
        applicationTypes,
      ),
      startDate: DateUtils.parseAndFormatDate(fundingRound.start_date),
      endDate: DateUtils.parseAndFormatDate(fundingRound.end_date),
      decisionDate: DateUtils.parseAndFormatDate(fundingRound.decision_date),
      version: fundingRound.version ?? 0,
    }),
    [],
  )

  const mapValuesToFundingRoundInput = (values: EditForm): Funding_Round_Insert_Input => ({
    name: values.name,
    start_date: DateUtils.parseAndSerializeDate(values.startDate),
    end_date: DateUtils.parseAndSerializeDate(values.endDate),
    decision_date: DateUtils.parseAndSerializeDate(values.decisionDate),
  })

  useEffect(() => {
    const initData = async () => {
      const { data } = await urqlClient
        .query<{
          funding_round: Query_Root['funding_round']
          application_type: Query_Root['application_type']
        }>(getRoundInformationById, { roundId })
        .toPromise()

      if (data?.application_type && data?.funding_round?.length) {
        const round = data.funding_round[0]
        const roundType = RoundManagementUtils.resolveRoundType(
          round.process,
          round.application_types,
          data.application_type,
        )

        setTypeOptions([
          {
            label: getMessage(`label.round.type.${roundType}`),
            value: roundType,
          },
        ])

        const fundingRoundInitialValues = mapFundingRoundToFundingRoundEditForm(round, data.application_type)
        setInitialValues(fundingRoundInitialValues)
      } else {
        notificationService.operationFailed()
      }
    }

    initData()
  }, [urqlClient, roundId, notificationService, getMessage, mapFundingRoundToFundingRoundEditForm])

  const validateForm = (values: EditForm): FormValidationErrors<EditForm> => {
    const error: FormValidationErrors<EditForm> = {}

    const startDate = DateUtils.parseDate(values.startDate)
    const endDate = DateUtils.parseDate(values.endDate)

    if (DateUtils.valid(startDate) && DateUtils.valid(endDate) && DateUtils.after(startDate, endDate)) {
      error.endDate = { errorKey: 'validation.round.end.date.after.start.date' }
    }

    return error
  }

  const handleSubmitLocal = async (values: any) => {
    const object = mapValuesToFundingRoundInput(values)

    const { data } = await urqlClient
      .mutation<{
        update_funding_round: Mutation_Root['update_funding_round']
      }>(updateFundingRoundMutation, {
        roundId: roundId,
        version: initialValues?.version,
        name: object.name,
        startDate: object.start_date,
        endDate: object.end_date,
        decisionDate: object.decision_date,
      })
      .toPromise()

    if (data?.update_funding_round && data?.update_funding_round?.affected_rows > 0) {
      const newVersion = data?.update_funding_round?.returning?.[0].version

      setInitialValues({ ...values, version: newVersion })
      notificationService.changesSaved()
    } else {
      notificationService.operationFailed()
    }
  }

  let submit: any = () => {}
  let formValid = false

  const onSave = (event: any) => {
    submit(event)
  }

  const onSaveAndBack = async (event: any) => {
    try {
      await submit(event)
      formValid && onBackHandler()
    } catch {
      // do nothing
    }
  }

  return (
    <>
      <ScreenLayout
        title={getMessage('label.round.edit')}
        onBack={onBackHandler}
        hasSecondLevelNavigation={false}
        actions={
          <>
            <SaveAndBackButton origin="header" onClick={onSaveAndBack} />
            <SaveButton origin="header" onClick={onSave} />
          </>
        }
      >
        <PageLayout>
          <>
            {initialValues && (
              <>
                <HelpAndInstructions labelKey="label.help.round.information" defaultExpansion />
                <Form<EditForm>
                  initialValues={initialValues}
                  onSubmit={handleSubmitLocal}
                  decorators={decorators}
                  validate={validateForm}
                  render={({ handleSubmit, valid }) => {
                    submit = handleSubmit
                    formValid = valid
                    return (
                      <form onSubmit={handleSubmit} noValidate>
                        <Stack spacing={2}>
                          <TextField
                            name="name"
                            label={getMessage('label.round.name')}
                            required
                            validate={composeValidators(required(), maxChar(TEXT_LENGTH.S))}
                          />
                          <AutoCompleteField
                            name="type"
                            label={getMessage('label.round.type')}
                            options={typeOptions}
                            disabled
                          />
                          <DateTimePickerField
                            name="startDate"
                            label={getMessage('label.start.date')}
                            required
                            validate={composeValidators(required(), validDate())}
                          />
                          <DateTimePickerField
                            name="endDate"
                            label={getMessage('label.end.date')}
                            required
                            validate={composeValidators(required(), validDate())}
                          />
                          <DateTimePickerField
                            name="decisionDate"
                            label={getMessage('label.decision.date')}
                            required
                            validate={composeValidators(required(), validDate())}
                          />
                        </Stack>
                        <DirtyFormSpy />
                      </form>
                    )
                  }}
                />
              </>
            )}
          </>
        </PageLayout>
      </ScreenLayout>
    </>
  )
}
