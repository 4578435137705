import {
  Box,
  Chip,
  IconButton,
  Paper,
  SvgIconProps,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  Typography,
} from '@mui/material'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import { sortBy } from 'lodash'
import { ReactElement, useCallback, useContext, useEffect, useState } from 'react'
import { Mutation_Root, Project_User, Query_Root } from 'src/@types/graphql'
import { useMessageSource } from 'src/i18n/useMessageSource'
import { AddNewUserModalDialog } from 'src/screens/shared/project/permissions/AddNewUserModalDialog'
import {
  deleteUserFromProject,
  queryPfKapProjectUsersAndUserType,
  queryPfPgvProjectUsersAndUserType,
} from 'src/screens/shared/project/permissions/projectPermissionsQueries'
import { usePermissionsForProject } from 'src/service/security/PermissionHook'
import { AddButton } from 'src/shared/button/Buttons'
import { PROJECT_USER_ROLES, USER_STATUS } from 'src/shared/constants/constants'
import { DeleteIcon, PersonAddIcon, PersonIcon } from 'src/shared/icons/Icons'
import { PageLayout } from 'src/shared/layout/PageLayout'
import { ScreenLayout } from 'src/shared/layout/ScreenLayout'
import { ProjectExportMenu } from 'src/shared/menu/ProjectExportMenu'
import { ConfirmationModalDialog } from 'src/shared/modal-dialog/ConfirmationModalDialog'
import { ModalDialog } from 'src/shared/modal-dialog/ModalDialog'
import { NotAuthorized } from 'src/shared/not-authorized/NotAuthorized'
import { HelpAndInstructions } from 'src/shared/presentation/HelpAndInstructions'
import { Section } from 'src/shared/presentation/Section'
import { S } from 'src/shared/styled/S'
import { useNotificationService } from 'src/shared/utils/NotificationService'
import { Utils } from 'src/shared/utils/Utils'
import { UserContext } from 'src/user/UserContext'
import styled from 'styled-components/macro'
import { useClient } from 'urql'

interface ProjectPermissionsProps {
  projectId: number
  baseUrl: '/pf-kap' | '/pf-pgv'
}

const StyledTableRow = styled(TableRow)`
  & .MuiTableCell-root {
    padding-top: ${({ theme }) => theme.spacing(1.5)};
    padding-bottom: ${({ theme }) => theme.spacing(1.5)};
  }
`

const StyledTableCell = styled(TableCell)<{ $active?: boolean }>`
  color: ${({ $active, theme }) => ($active ? theme.colors.text.primary : theme.colors.text.secondary)};
`

const StyledChip = styled(Chip)<{ $active: boolean } & SvgIconProps>`
  background-color: ${({ $active, theme }) => ($active ? theme.colors.success.light : theme.colors.grey.background)};
  color: ${({ $active, theme }) => ($active ? theme.colors.success.dark : theme.colors.text.secondary)};
`

const resolveUserPermissionMessageKey = (user: Project_User): string => {
  if (user.type === PROJECT_USER_ROLES.ADMIN) {
    return 'label.admin'
  } else if (user.type === PROJECT_USER_ROLES.CONTRIBUTOR) {
    return 'label.can.edit'
  } else if (user.type === PROJECT_USER_ROLES.READER) {
    return 'label.can.view'
  }
  throw Error(`Unknown user role ${user.type}`)
}

export const ProjectPermissions = ({ projectId, baseUrl }: ProjectPermissionsProps): ReactElement => {
  const { getMessage } = useMessageSource()
  const urqlClient = useClient()
  const [projectUsers, setProjectUsers] = useState<Project_User[] | undefined>(undefined)
  const [open, setOpen] = useState(false)
  const [projectBaseId, setProjectBaseId] = useState<number | undefined>(undefined)
  const { user } = useContext(UserContext)
  const [userDeleteConfirmationOpen, setUserDeleteConfirmationOpen] = useState(false)
  const [userIdToBeDeleted, setUserIdToBeDeleted] = useState<any>(null)
  const notificationService = useNotificationService()
  const projectType = Utils.resolveProcess(baseUrl)

  const { loading, canView, canModifyProjectUsers } = usePermissionsForProject(projectType, projectId)

  const isUserActive = (user: Project_User): boolean => user.user.status === USER_STATUS.ACTIVE

  const fetchProjectUsers = useCallback(async () => {
    let fetchedProjectUsers
    let projectBaseId
    if (baseUrl === '/pf-kap') {
      const { data, error } = await urqlClient
        .query<
          {
            projectUsers: Query_Root['pf_kap_project_by_pk']
          },
          {
            id: number
          }
        >(queryPfKapProjectUsersAndUserType, { id: projectId })
        .toPromise()

      if (error) {
        notificationService.operationFailed()
        return
      }
      fetchedProjectUsers = data?.projectUsers?.project_base.project_users
      projectBaseId = data?.projectUsers?.project_base.id
    } else if (baseUrl === '/pf-pgv') {
      const { data, error } = await urqlClient
        .query<
          {
            projectUsers: Query_Root['pf_pgv_project_by_pk']
          },
          {
            id: number
          }
        >(queryPfPgvProjectUsersAndUserType, { id: projectId })
        .toPromise()
      if (error) {
        notificationService.operationFailed()
        return
      }
      fetchedProjectUsers = data?.projectUsers?.project_base.project_users
      projectBaseId = data?.projectUsers?.project_base.id
    }
    const filteredFetchedProjectUsers = fetchedProjectUsers?.filter((user) => user.type !== PROJECT_USER_ROLES.EXPERT)
    setProjectUsers(sortBy(filteredFetchedProjectUsers, [(pu: Project_User) => pu.type !== PROJECT_USER_ROLES.ADMIN]))
    setProjectBaseId(projectBaseId)
  }, [baseUrl, projectId, urqlClient, notificationService])

  useEffect(() => {
    const initData = async () => {
      await fetchProjectUsers()
    }
    initData()
  }, [baseUrl, fetchProjectUsers, projectId, urqlClient])

  const handleAddNewUser = () => {
    setOpen(true)
  }

  const closeAddUserDialog = async () => {
    setOpen(false)
    await fetchProjectUsers()
  }

  const handleDeleteUser = (userId: number) => () => {
    setUserIdToBeDeleted(userId)
    setUserDeleteConfirmationOpen(true)
  }
  const handleCancelDeleteUser = () => {
    setUserDeleteConfirmationOpen(false)
  }

  const handleDeleteUserConfirmed = async () => {
    setUserDeleteConfirmationOpen(false)
    const { error } = await urqlClient
      .mutation<{ delete_project_user: Mutation_Root['delete_project_user'] }>(deleteUserFromProject, {
        projectBaseId: projectBaseId,
        userId: userIdToBeDeleted,
      })
      .toPromise()
    if (error) {
      notificationService.operationFailed()
    } else {
      notificationService.deleteSuccessful()
      await fetchProjectUsers()
    }
  }

  if (!loading && !canView) {
    return <NotAuthorized />
  }

  return (
    <>
      {!loading && (
        <ScreenLayout
          title={getMessage('label.navigation.project.permissions')}
          hasSecondLevelNavigation={false}
          actions={<ProjectExportMenu process={projectType} entityId={projectBaseId as number} />}
        >
          <PageLayout>
            {!loading && (
              <Section
                title={getMessage('label.project.permissions.users')}
                actionButton={
                  canModifyProjectUsers && (
                    <AddButton messageKey="button.user.add" startIcon={<PersonAddIcon />} onClick={handleAddNewUser} />
                  )
                }
                helpAndInstructions={<HelpAndInstructions labelKey={`label.help.project.permissions.${projectType}`} />}
              >
                <TableContainer component={Paper}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>{getMessage('label.name')}</TableCell>
                        <TableCell>{getMessage('label.permissions')}</TableCell>
                        <TableCell>{getMessage('label.status')}</TableCell>
                        {canModifyProjectUsers && <TableCell align="right">{getMessage('label.actions')}</TableCell>}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {projectUsers?.map((u, i) => (
                        <StyledTableRow key={i}>
                          <StyledTableCell $active={isUserActive(u)}>
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                              <S.Avatar $randomColorKey={isUserActive(u) ? u?.user.email : ''}>
                                {isUserActive(u) ? u.user.first_name?.charAt(0) : <PersonIcon />}
                              </S.Avatar>
                              <S.DataGrid.TwoLineCell>
                                <Typography variant="subtitle2">
                                  {isUserActive(u)
                                    ? `${u.user.first_name} ${u.user.last_name}`
                                    : getMessage('label.user.unknown')}
                                </Typography>
                                <Typography variant="caption" color="textSecondary">
                                  {u.user.email}
                                </Typography>
                              </S.DataGrid.TwoLineCell>
                            </Box>
                          </StyledTableCell>
                          <StyledTableCell $active={isUserActive(u)}>
                            {getMessage(resolveUserPermissionMessageKey(u))}
                          </StyledTableCell>
                          <StyledTableCell $active={isUserActive(u)}>
                            <StyledChip
                              $active={isUserActive(u)}
                              label={getMessage(`label.${u.user.status.toLowerCase()}`)}
                            />
                          </StyledTableCell>
                          {canModifyProjectUsers && (
                            <StyledTableCell align="right">
                              {user.email !== u.user.email && u.type !== PROJECT_USER_ROLES.ADMIN && (
                                <IconButton color="primary" onClick={handleDeleteUser(u.user.id)}>
                                  <DeleteIcon />
                                </IconButton>
                              )}
                            </StyledTableCell>
                          )}
                        </StyledTableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>

                {projectBaseId && (
                  <>
                    <ModalDialog
                      open={open}
                      title={getMessage('label.add.new.user')}
                      maxWidth="sm"
                      withCloseIcon={false}
                      onClose={closeAddUserDialog}
                    >
                      <AddNewUserModalDialog
                        onCancel={closeAddUserDialog}
                        onSuccess={closeAddUserDialog}
                        projectBaseId={projectBaseId}
                      />
                    </ModalDialog>
                  </>
                )}

                <ConfirmationModalDialog
                  open={userDeleteConfirmationOpen}
                  onCancel={handleCancelDeleteUser}
                  onConfirm={handleDeleteUserConfirmed}
                >
                  {getMessage('label.delete.confirm')}
                </ConfirmationModalDialog>
              </Section>
            )}
          </PageLayout>
        </ScreenLayout>
      )}
    </>
  )
}
