import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Maybe, Query_Root } from 'src/@types/graphql'
import { useMessageSource } from 'src/i18n/useMessageSource'
import { ROUTES } from 'src/routing/routes'
import { queryProjectEvaluationByProjectId } from 'src/screens/shared/project/details/effectivity-management/effectivityManagementQueries'
import { EffectivityEvaluationMilestonesSection } from 'src/screens/shared/project/details/effectivity-management/evaluation-milestone/EffectivityEvaluationMilestonesSection'
import { RelatedEvaluationSection } from 'src/screens/shared/project/details/effectivity-management/related-evaluation/RelatedEvaluationSection'
import { usePermissionsForProject } from 'src/service/security/PermissionHook'
import { EditButton } from 'src/shared/button/Buttons'
import { PROJECT } from 'src/shared/constants/constants'
import { PageLayout } from 'src/shared/layout/PageLayout'
import { ScreenLayout } from 'src/shared/layout/ScreenLayout'
import { ProjectExportMenu } from 'src/shared/menu/ProjectExportMenu'
import { HelpAndInstructions } from 'src/shared/presentation/HelpAndInstructions'
import { HtmlRenderer } from 'src/shared/presentation/HtmlRenderer'
import { Section } from 'src/shared/presentation/Section'
import { useDelayedNavigate } from 'src/shared/utils/hooks/navigation-hooks'
import { useNotificationService } from 'src/shared/utils/NotificationService'
import { Utils } from 'src/shared/utils/Utils'
import { useClient } from 'urql'

interface Props {
  baseUrl: '/pf-kap' | '/pf-pgv'
}

export const EffectivityManagement = ({ baseUrl }: Props) => {
  const { projectId } = useParams()
  const project_id = parseInt(projectId as string)

  const { getMessage } = useMessageSource()

  const notificationService = useNotificationService()
  const urqlClient = useClient()
  const navigate = useDelayedNavigate()

  const process = Utils.resolveProcess(baseUrl)
  const isPfKap = process === PROJECT.PF_KAP

  const [evaluation, setEvaluation] = useState<Maybe<string>>()

  useEffect(() => {
    const fetchEvaluationData = async () => {
      const { data } = await urqlClient
        .query<{ pf_kap_project_by_pk: Query_Root['pf_kap_project_by_pk'] }>(queryProjectEvaluationByProjectId, {
          id: project_id,
        })
        .toPromise()

      if (data?.pf_kap_project_by_pk) {
        setEvaluation(data.pf_kap_project_by_pk.evaluation)
      } else {
        notificationService.operationFailed()
      }
    }

    if (isPfKap) {
      fetchEvaluationData()
    }
  }, [isPfKap, notificationService, project_id, urqlClient])

  const handleEditEvaluation = () => {
    navigate(ROUTES.PfKapDetailsRoot.nested.EffectivityManagementEvaluationEdit.params({ projectId }))
  }

  const {
    loading,
    canEdit,
    metadata: { projectBaseId, dossierId },
  } = usePermissionsForProject(process, project_id)

  return (
    <ScreenLayout
      title={getMessage('label.navigation.project.details')}
      actions={<ProjectExportMenu process={process} entityId={projectBaseId as number} />}
    >
      <PageLayout>
        <>
          {!loading && dossierId && (
            <>
              {isPfKap && (
                <Section
                  id="evaluation"
                  title={getMessage('label.effectivity.evaluation')}
                  actionButton={<EditButton onClick={handleEditEvaluation} hidden={!canEdit} />}
                  helpAndInstructions={<HelpAndInstructions labelKey="label.help.effectivity.evaluation.PF_KAP" />}
                >
                  {evaluation && (
                    <Card>
                      <CardContent>
                        <HtmlRenderer html={evaluation} />
                      </CardContent>
                    </Card>
                  )}
                </Section>
              )}

              <EffectivityEvaluationMilestonesSection baseUrl={baseUrl} dossierId={dossierId} canEdit={canEdit} />

              <RelatedEvaluationSection baseUrl={baseUrl} canEdit={canEdit} dossierId={dossierId} />
            </>
          )}
        </>
      </PageLayout>
    </ScreenLayout>
  )
}
