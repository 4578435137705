import { ReactElement, useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { Maybe, Mutation_Root, Query_Root, Subprinciple_Config } from 'src/@types/graphql'
import { useMessageSource } from 'src/i18n/useMessageSource'
import { ROUTES } from 'src/routing/routes'
import { KapProgramSubprincipleEdit } from 'src/screens/shared/principles/edit/KapProgramSubprincipleEdit'
import {
  fetchKapProgramSubprincipleQuery,
  insertKapProgramSubprincipleConceptPhaseMutation,
} from 'src/screens/shared/principles/kapProgramPrinciplesQueries'
import { SubprincipleEditForm } from 'src/screens/shared/principles/PrinciplesUtils'
import { usePermissionsForKapProgram } from 'src/service/security/PermissionHook'
import { useDelayedNavigate } from 'src/shared/utils/hooks/navigation-hooks'
import { HtmlSanitizer } from 'src/shared/utils/HtmlSanitizer'
import { useNotificationService } from 'src/shared/utils/NotificationService'
import { useUserLocale } from 'src/user/UserContext'
import { useClient } from 'urql'

export const KapProgramDetailsSubprincipleEditPage = (): ReactElement => {
  const { getMessage } = useMessageSource()
  const urqlClient = useClient()
  const { programId, subprincipleId } = useParams()
  const locale = useUserLocale()
  const notificationService = useNotificationService()
  const navigate = useDelayedNavigate()

  const program_id = parseInt(programId as string)
  const subprinciple_id = parseInt(subprincipleId as string)
  const { loading, canEdit } = usePermissionsForKapProgram(program_id)

  const [initialValues, setInitialValues] = useState<SubprincipleEditForm>({
    comment: null,
  })
  const [subprincipleConfig, setSubprincipleConfig] = useState<Maybe<Subprinciple_Config> | undefined>()

  useEffect(() => {
    const initData = async () => {
      const { data } = await urqlClient
        .query<{
          kap_program_subprinciple: Query_Root['kap_program_subprinciple']
          subprinciple_config_by_pk: Query_Root['subprinciple_config_by_pk']
        }>(fetchKapProgramSubprincipleQuery, {
          programId: program_id,
          subprincipleId: subprinciple_id,
        })
        .toPromise()

      if (data) {
        setInitialValues({
          comment: data.kap_program_subprinciple[0]?.concept_comment,
        })
        setSubprincipleConfig(data.subprinciple_config_by_pk)
      } else {
        notificationService.operationFailed()
      }
    }

    initData()
  }, [program_id, subprinciple_id, locale, notificationService, urqlClient])

  const onBack = (subprincipleId: number) => {
    const path = ROUTES.KapDetailsRoot.nested.Principles.params({ programId })
    const cardId = subprincipleConfig?.code.replaceAll('_', '-')

    navigate(`${path}#${cardId}`, {
      state: { expandedSubprincipleId: subprincipleId },
    })
  }

  const onSaveLocal = async (values: any) => {
    const object = {
      subprinciple_config_id: subprinciple_id,
      kap_program_id: program_id,
      concept_comment: values.comment && HtmlSanitizer.sanitize(values.comment),
    }

    const { data, error } = await urqlClient
      .mutation<{
        insert_kap_program_subprinciple: Mutation_Root['insert_kap_program_subprinciple']
      }>(insertKapProgramSubprincipleConceptPhaseMutation, { object: object })
      .toPromise()

    if (data) {
      setInitialValues(values)
      notificationService.changesSaved()
    } else if (error) {
      notificationService.operationFailed()
    }
  }

  return (
    <KapProgramSubprincipleEdit
      loading={loading}
      canEdit={canEdit}
      onBack={onBack}
      onSubmit={onSaveLocal}
      initialValues={initialValues}
      label={getMessage('label.concept.phase.comment')}
    />
  )
}
