import DOMPurify from 'dompurify'

DOMPurify.setConfig({
  ALLOWED_TAGS: ['br', 'p', 'i', 'ul', 'ol', 'li', 'b', 'a', 'strong', 'em'],
  KEEP_TEXT: false,
  FORBID_TAGS: ['pre', 'code', 'script', 'iframe', 'img'],
  REMOVE_TEXT: 'script',
})

// Configure the DOMPurify-er so that when sanitization happens, anchors are opened in a new tab
DOMPurify.addHook('afterSanitizeAttributes', function (node: any) {
  // if the node is <a> element
  if (node.tagName === 'A') {
    node.setAttribute('target', '_blank')
  }
})

/**
 * Main function used for purifying HTML contents.
 *
 * We rely heavily on DOMPurify - that is used by FastMail and they provide security bounties if any vulnerabilities are discovered.
 *
 * https://github.com/cure53/DOMPurify#what-if-i-find-a-security-bug
 *
 * @param html dirty html
 *
 * @returns sanitized html
 */
const sanitize = (html = ''): string => {
  const htmlNullSafe = html ? html : ''
  // we first replace the empty paragraphs with <br /> that seems many Markdown processors support it as forcing new line
  // the <p>&nbsp;</p> will happen when copy-pasting from word empty lines (aka paragraphs)
  const htmlBr = htmlNullSafe.replaceAll('<p>&nbsp;</p>', '<p><br></p>')

  // we secondly decode the HTML entities (&lt; &amp; &gt;) that will be properly escaped by react-quill.
  // we do this step so that DOMPurify can properly purge those tags and its contents from HTML
  // const htmlDecoded = decode(htmlBr)

  // purge all unwanted tags/js
  const htmlSanitized = DOMPurify.sanitize(htmlBr)
  return htmlSanitized.trim()
}

const isEmptyLineHtmlParagraph = (html: string): boolean => {
  return html === '<p><br></p>'
}

/**
 * Resolver for formatting a text from Excel configuration file that contains bullets.
 *
 * @param text the text from the config file
 * @returns the formatted config text with '#' converted into bullets of an unordered HTML list
 */
const resolveConfigTextWithBullets = (text: string) => {
  // Split individual paragraphs into an array of strings
  const paragraphsArray = text.split(/\n\n/g)

  // Iteration of individual strings with HTML tags in order to be represented as lists
  const modifiedParagraphs = paragraphsArray.map((block: string) =>
    block
      .replaceAll(/^# /gm, '</li>\n<li>')
      .replace('</li>\n<li>', '<ul>\n<li>')
      .replaceAll('\n</li>', '</li>')
      .concat('</li>\n</ul>'),
  )

  // Concatenate individual paragraphs
  const joinedParagraphs = modifiedParagraphs.join('\n<br>\n')

  // Check if the string is a list
  if (text.match(/^# /gm) !== null) {
    return joinedParagraphs
  } else return text
}

export const HtmlSanitizer = {
  sanitize,
  isEmptyLineHtmlParagraph,
  resolveConfigTextWithBullets,
}
