import { useCallback } from 'react'
import { useEnvironment } from 'src/env/EnvironmentStore'
import { Utils } from 'src/shared/utils/Utils'
import { User } from 'src/user/user'

export const useFetchCurrentUser = (): (() => Promise<User>) => {
  const { backendUrl } = useEnvironment()

  const fetchUser = useCallback<() => Promise<User>>(async () => {
    let user = null
    const myHeaders = new Headers()
    try {
      const currentUserResponse = await fetch(`${backendUrl}/api/user/current`, {
        mode: 'cors',
        headers: myHeaders,
        credentials: 'include',
      })

      if (currentUserResponse.ok) {
        const currentUser = await currentUserResponse.json()
        user = {
          ...currentUser,
          anonymous: false,
        }
      } else {
        return Promise.reject({ message: currentUserResponse.text, status: currentUserResponse.status })
      }
    } catch (fetchError) {
      // fetch has bad error handling with a message only 'Failed to fetch'
      // if the backend is not accessible
      // so we treat it as 500 error
      return Promise.reject({ message: 'Backend not accessible', status: 500 })
    }

    return user
  }, [backendUrl])

  return fetchUser
}

export const fetcherErrorHandlerWithRedirectToLogin =
  (login: () => void) =>
  (e: { message: string; status: number }): Promise<unknown> => {
    if ([401, 403].includes(e.status) && !Utils.isPublic()) {
      login()
      // unresolvable promise -> because we expect the browser to `eventually` navigate the browser `/pub/landing`
      return new Promise(() => {
        // this will never resolve `intentionally`
      })
    } else {
      // there is an error which is not auth related (401, 403)
      // reject the promise as general system unavailability
      return Promise.reject('Backend system unavailable')
    }
  }
