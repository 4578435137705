import { Box, Rating, Typography } from '@mui/material'
import { ReactElement, ReactNode } from 'react'
import { EmptyHeartIcon, EmptyStarIcon, FullHeartIcon, FullStarIcon } from 'src/shared/icons/Icons'
import { HtmlRenderer } from 'src/shared/presentation/HtmlRenderer'
import { useHashScroll } from 'src/shared/utils/hooks/navigation-hooks'
import { muiTheme } from 'src/theme/theme'
import styled from 'styled-components/macro'

interface Props {
  title: string
  code: string
  children?: ReactNode
  comment?: string
  actionButton?: ReactNode
  id?: string
  ratingStatus?: string
  ratingPriority?: string
}

const TypographyStyled = styled(Typography)`
  color: ${(props) => props.theme.colors.text.disabled};
`

const FocusTopicTitle = styled(Typography)`
  color: ${(props) => props.theme.colors.text.disabled};
  flex-grow: 1;
`

const RatingsContainer = styled(Box)`
  display: flex;
  button {
    margin-right: ${({ theme }) => theme.spacing(0.5)};
  }
`

const RatingStyled = styled(Rating)`
  & .MuiRating-iconFilled {
    color: ${muiTheme.palette.grey['500']};
  }
`

const RatingStatusSection = styled(Box)`
  display: flex;
  align-items: center;
  min-width: ${({ theme }) => theme.spacing(20)};
`

const RatingPrioritySection = styled(Box)`
  display: flex;
  align-items: center;
  min-width: ${({ theme }) => theme.spacing(14)};
`

export const FocusTopicSection = ({
  id,
  title,
  actionButton,
  comment,
  ratingStatus,
  ratingPriority,
  children,
}: Props): ReactElement => {
  useHashScroll(id)

  return (
    <Box mb={4}>
      <Box id={id} display="flex" alignItems="flex-end" justifyContent="space-between" mb={1}>
        <FocusTopicTitle variant="h4">{title}</FocusTopicTitle>

        <RatingsContainer>
          {ratingStatus && ratingPriority && (
            <>
              <RatingStatusSection>
                <RatingStyled
                  icon={<FullStarIcon fontSize="inherit" />}
                  emptyIcon={<EmptyStarIcon fontSize="inherit" />}
                  size="small"
                  readOnly
                  precision={0.1}
                  max={6}
                  defaultValue={parseFloat(ratingStatus)}
                />
                <TypographyStyled variant="body1" sx={{ marginLeft: 1 }}>
                  {ratingStatus}
                </TypographyStyled>
              </RatingStatusSection>
              <RatingPrioritySection>
                <RatingStyled
                  icon={<FullHeartIcon fontSize="inherit" />}
                  emptyIcon={<EmptyHeartIcon fontSize="inherit" />}
                  size="small"
                  readOnly
                  precision={0.1}
                  max={3}
                  defaultValue={parseFloat(ratingPriority)}
                />
                <TypographyStyled variant="body1" sx={{ marginLeft: 1 }}>
                  {ratingPriority}
                </TypographyStyled>
              </RatingPrioritySection>
            </>
          )}
          {actionButton}
        </RatingsContainer>
      </Box>

      {comment && <HtmlRenderer html={comment} variant="body2" mb={1} />}
      {children}
    </Box>
  )
}
