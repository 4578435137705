import { Box, Stack } from '@mui/material'
import { ReactElement, useEffect, useState } from 'react'
import { Form } from 'react-final-form'
import { useParams } from 'react-router'
import { Mutation_Root, Project_Measure_Annual_Report_Insert_Input, Query_Root } from 'src/@types/graphql'
import { useMessageSource } from 'src/i18n/useMessageSource'
import { resolveAnnualReportMilestoneRoot } from 'src/routing/routing-utils'
import {
  deleteMeasureAnnualReportMutation,
  queryMeasureAnnualReportByMeasureAndMilestoneId,
  queryMilestoneById,
  upsertMeasureAnnualReportMutation,
} from 'src/screens/shared/implementation/details/milestoneQueries'
import { usePermissionsForMilestones } from 'src/service/security/PermissionHook'
import { SaveAndBackButton, SaveButton } from 'src/shared/button/Buttons'
import { MEASURE_CHAR, TEXT_LENGTH } from 'src/shared/constants/constants'
import {
  GOAL_MEASURE_RATING,
  MILESTONE_RESPONSIBLE_TYPE_TYPE,
  MILESTONE_STATUS_TYPE,
} from 'src/shared/constants/milestone-constants'
import { Option } from 'src/shared/form/control'
import { AutoCompleteField } from 'src/shared/form/control/AutoCompleteField'
import { HtmlEditorField } from 'src/shared/form/control/HtmlEditorField'
import { DirtyFormSpy } from 'src/shared/form/dirty/DirtyFormSpy'
import { composeValidators, maxChar, required } from 'src/shared/form/validation/validators'
import { PageLayout } from 'src/shared/layout/PageLayout'
import { ScreenLayout } from 'src/shared/layout/ScreenLayout'
import { NotAuthorized } from 'src/shared/not-authorized/NotAuthorized'
import { HelpAndInstructions } from 'src/shared/presentation/HelpAndInstructions'
import { useDelayedNavigate } from 'src/shared/utils/hooks/navigation-hooks'
import { useNotificationService } from 'src/shared/utils/NotificationService'
import { Utils } from 'src/shared/utils/Utils'
import { useClient, useQuery } from 'urql'

interface Props {
  baseUrl: '/pf-kap' | '/pf-pgv'
}

interface MeasureRatingForm {
  rating: string | null
  rating_comment: string | null
}

export const MilestoneMeasureEditPage = ({ baseUrl }: Props): ReactElement => {
  const { projectId, milestoneId, milestonePath, measureId } = useParams()
  const project_id = parseInt(projectId as string)
  const process = Utils.resolveProcess(baseUrl)
  const milestone_id = parseInt(milestoneId as string)
  const measure_id = parseInt(measureId as string)

  const navigate = useDelayedNavigate()
  const { getMessage } = useMessageSource()
  const notificationService = useNotificationService()
  const urqlClient = useClient()

  const [measureTitle, setMeasureTitle] = useState('')

  const [{ data, error }] = useQuery<{ milestone_by_pk: Query_Root['milestone_by_pk'] }, { id: number }>({
    query: queryMilestoneById,
    variables: { id: milestone_id },
  })

  if (error) {
    notificationService.operationFailed()
  }

  const milestone = data?.milestone_by_pk

  const { loading, canEdit } = usePermissionsForMilestones(
    process,
    project_id,
    milestone?.status as MILESTONE_STATUS_TYPE,
    milestone?.responsible_type as MILESTONE_RESPONSIBLE_TYPE_TYPE,
  )

  const [initialValues, setInitialValues] = useState<MeasureRatingForm>()
  const [ratingOptions, setRatingOptions] = useState<Option[]>([])

  useEffect(() => {
    const initData = async () => {
      const ratingOptions = Object.keys(GOAL_MEASURE_RATING).map((key) => ({
        label: `${getMessage(`label.measure.rating.${key}`)}`,
        value: key,
      }))
      setRatingOptions(ratingOptions)

      const { data } = await urqlClient
        .query<
          {
            project_measure_annual_report: Query_Root['project_measure_annual_report']
            project_measure_by_pk: Query_Root['project_measure_by_pk']
          },
          { measureId: number; milestoneId: number }
        >(queryMeasureAnnualReportByMeasureAndMilestoneId, { measureId: measure_id, milestoneId: milestone_id })
        .toPromise()

      if (data && data.project_measure_annual_report && data.project_measure_by_pk) {
        const measureAnnualReport = data?.project_measure_annual_report?.[0]

        setInitialValues({
          rating: measureAnnualReport?.rating ?? null,
          rating_comment: measureAnnualReport?.rating_comment ?? null,
        })

        const measureIdCharacter = getMessage(`label.measure.id.character`)
        setMeasureTitle(
          `${data.project_measure_by_pk.name} (${measureIdCharacter}${data.project_measure_by_pk.sort_number})`,
        )
      } else {
        notificationService.operationFailed()
      }
    }
    initData()
  }, [urqlClient, measure_id, milestone_id, notificationService, getMessage])

  let submit: any = () => {}
  let formValid = false

  const onSave = (event: any) => {
    submit(event)
  }

  const onSaveAndBack = async (event: any) => {
    try {
      await submit(event)
      formValid && onBack()
    } catch {
      // do nothing
    }
  }

  const onBack = () => {
    const goalsAndMeasuresRoute = resolveAnnualReportMilestoneRoot(baseUrl).nested.GoalsAndMeasures.params({
      projectId,
      milestoneId,
      milestonePath,
    })

    navigate(`${goalsAndMeasuresRoute}#${MEASURE_CHAR}${measureId}`, {
      state: { expandedEntityId: `${MEASURE_CHAR}${measureId}` },
    })
  }

  const onSubmitLocal = async (values: MeasureRatingForm) => {
    const emptyRating = !values.rating && !values.rating_comment

    if (!emptyRating) {
      const measureAnnualReportModel = {
        milestone_id: milestone_id,
        project_measure_id: measure_id,
        rating: values.rating,
        rating_comment: values.rating_comment,
      }

      const { data } = await urqlClient
        .mutation<
          { insert_project_measure_annual_report_one: Mutation_Root['insert_project_measure_annual_report_one'] },
          { object: Project_Measure_Annual_Report_Insert_Input }
        >(upsertMeasureAnnualReportMutation, { object: measureAnnualReportModel })
        .toPromise()

      if (data) {
        notificationService.changesSaved()
        setInitialValues(values)
      } else {
        notificationService.operationFailed()
      }
    } else {
      const { data } = await urqlClient
        .mutation<{ delete_project_measure_annual_report: Mutation_Root['delete_project_measure_annual_report'] }>(
          deleteMeasureAnnualReportMutation,
          {
            milestoneId: milestone_id,
            measureId: measure_id,
          },
        )
        .toPromise()

      if (data) {
        notificationService.changesSaved()
        setInitialValues(values)
      } else {
        notificationService.operationFailed()
      }
    }
  }

  if (!loading && !canEdit) {
    return <NotAuthorized />
  }

  return (
    <>
      <ScreenLayout
        title={getMessage('label.edit.measure.milestone.title', [measureTitle])}
        onBack={onBack}
        hasSecondLevelNavigation={false}
        actions={
          <>
            <SaveAndBackButton origin="header" onClick={onSaveAndBack} />
            <SaveButton origin="header" onClick={onSave} />
          </>
        }
      >
        <PageLayout>
          <>
            {!loading && initialValues && (
              <>
                <HelpAndInstructions
                  labelKey={`label.help.milestone.measure.rating.${process.toLowerCase()}`}
                  defaultExpansion
                />
                <Box>
                  <Form<MeasureRatingForm>
                    initialValues={initialValues}
                    onSubmit={onSubmitLocal}
                    render={({ handleSubmit, valid }) => {
                      submit = handleSubmit
                      formValid = valid
                      return (
                        <form onSubmit={handleSubmit} noValidate>
                          <Stack spacing={2}>
                            <AutoCompleteField
                              required
                              name="rating"
                              label={getMessage('label.measure.rating')}
                              options={ratingOptions}
                              validate={required()}
                            />
                            <HtmlEditorField
                              required
                              name="rating_comment"
                              label={getMessage('label.measure.rating.comment')}
                              validate={composeValidators(required(), maxChar(TEXT_LENGTH.L))}
                            />
                            <DirtyFormSpy />
                          </Stack>
                        </form>
                      )
                    }}
                  />
                </Box>
              </>
            )}
          </>
        </PageLayout>
      </ScreenLayout>
    </>
  )
}
