import { Box, Tooltip } from '@mui/material'
import { DataGridPro, GridColDef, GridRenderEditCellParams, GridRowId, useGridApiRef } from '@mui/x-data-grid-pro'
import combinedQuery from 'graphql-combine-query'
import { groupBy, sortBy, sumBy } from 'lodash'
import { ReactElement, useEffect, useMemo, useState } from 'react'
import { Form } from 'react-final-form'
import { InternationalizedObject } from 'src/@types'
import {
  Feature_Config_Bool_Exp,
  Feature_Kpi_Annual_Report,
  Feature_Value,
  Mutation_Root,
  Project_Base_Bool_Exp,
  Query_Root,
} from 'src/@types/graphql'
import { useMessageSource } from 'src/i18n/useMessageSource'
import { usePrompt } from 'src/routing/routing-hooks'
import { queryFeatureBaseValuesWithKPIs, queryFeatureConfigs } from 'src/screens/shared/feature/featureBaseQueries'
import {
  queryFeatureKPIAnnualReportByMilestoneId,
  queryFeatureKPIAnnualReportByProject,
  upsertFeatureKPIAnnualReport,
} from 'src/screens/shared/implementation/details/milestoneQueries'
import { RowData } from 'src/screens/shared/project/details/project-features/ProjectFeatureKpiGrid'
import { PROJECT } from 'src/shared/constants/constants'
import { NumberInput } from 'src/shared/form/grid/NumberInput'
import { EditableColumnHeader } from 'src/shared/grid/EditableColumnHeader'
import { DefaultSectionTypography } from 'src/shared/presentation/DefaultSectionTypography'
import { S } from 'src/shared/styled/S'
import { DataGridUtils } from 'src/shared/utils/DataGridUtils'
import { useGridTranslateHook } from 'src/shared/utils/hooks/grid-translate-hook'
import { useError } from 'src/shared/utils/hooks/page-loading-error-hook'
import { useDelayedSubmit } from 'src/shared/utils/hooks/submit-hook'
import { useNotificationService } from 'src/shared/utils/NotificationService'
import { Utils } from 'src/shared/utils/Utils'
import { useUserLocale } from 'src/user/UserContext'
import { useClient } from 'urql'

interface Props {
  reportEditable: boolean
  projectId: number
  milestoneId: number
  baseUrl: '/pf-kap' | '/pf-pgv'
  onSave?: () => void
  reportYear?: number
}

export const AnnualFeatureKpiGrid = ({
  projectId,
  milestoneId,
  baseUrl,
  onSave,
  reportEditable,
  reportYear,
}: Props): ReactElement => {
  const { getMessage } = useMessageSource()
  const gridTranslations = useGridTranslateHook()
  const apiRef = useGridApiRef()
  const notificationService = useNotificationService()
  const urqlClient = useClient()
  const language = useUserLocale()
  const submit = useDelayedSubmit()

  const [dirty, setDirty] = useState<boolean>(false)
  const [featureKpis, setFeatureKpis] = useState<
    (Feature_Value & Feature_Kpi_Annual_Report & { actual_value?: number })[]
  >([])
  const process = Utils.resolveProcess(baseUrl)
  const setError = useError()

  useEffect(() => {
    const pfKapQuery: Project_Base_Bool_Exp = {
      pf_kap_projects: {
        id: {
          _eq: projectId,
        },
      },
    }

    const pfPgvQuery: Project_Base_Bool_Exp = {
      pf_pgv_projects: {
        id: {
          _eq: projectId,
        },
      },
    }

    const featureConfigWhere: Feature_Config_Bool_Exp = {
      processes: {
        _contains: [process],
      },
    }

    const featureConfigKPIWhere: Feature_Config_Bool_Exp = {
      has_kpi_for_processes: {
        _contains: [process],
      },
    }

    const fetchData = async () => {
      const { document, variables } = combinedQuery('FeatureBaseValuesWithKPIsAndFeatureKPIAnnualReport')
        .add<
          {
            feature_base: Query_Root['feature_base']
          },
          {
            projectBaseQuery: Project_Base_Bool_Exp
            featureConfigKPIWhere: Feature_Config_Bool_Exp
          }
        >(queryFeatureBaseValuesWithKPIs, {
          projectBaseQuery: process === PROJECT.PF_KAP ? pfKapQuery : pfPgvQuery,
          featureConfigKPIWhere: featureConfigKPIWhere,
        })
        .add<{ feature_kpi_annual_report: Query_Root['feature_kpi_annual_report'] }, { milestoneId: number }>(
          queryFeatureKPIAnnualReportByMilestoneId,
          { milestoneId },
        )
        .add<
          {
            feature_config: Query_Root['feature_config']
          },
          {
            featureConfigWhere: Feature_Config_Bool_Exp
          }
        >(queryFeatureConfigs, {
          featureConfigWhere: featureConfigWhere,
        })
        .add<
          {
            feature_kpi_all_reports: Query_Root['feature_kpi_annual_report']
          },
          {
            projectBaseWhere: Project_Base_Bool_Exp
          }
        >(queryFeatureKPIAnnualReportByProject, {
          projectBaseWhere: process === PROJECT.PF_KAP ? pfKapQuery : pfPgvQuery,
        })

      const { data, error } = await urqlClient
        .query<
          {
            feature_base: Query_Root['feature_base']
            feature_kpi_annual_report: Query_Root['feature_kpi_annual_report']
            feature_config: Query_Root['feature_config']
            feature_kpi_all_reports: Query_Root['feature_kpi_annual_report']
          },
          {
            projectBaseQuery: Project_Base_Bool_Exp
            milestoneId: number
            featureConfigWhere: Feature_Config_Bool_Exp
          }
        >(document, variables!)
        .toPromise()

      if (data) {
        let features = data.feature_base[0].feature_values
        const featureConfigByIds = groupBy(data?.feature_kpi_all_reports, 'feature_config_id')
        const reports = data.feature_kpi_annual_report
        const existingFeatures = data.feature_config
          .filter(
            (config) =>
              reports.some((report) => report.feature_config_id === config.id) &&
              !features.some((feature) => feature.feature_config_id === config.id),
          )
          .map((f) => ({
            feature_config_id: f.id,
            feature_config: f,
          })) as Feature_Value[]

        features = features.concat(existingFeatures)
        const mergedValues = Utils.mergeBy(
          { arr: features, key: 'feature_config_id' },
          { arr: reports, key: 'feature_config_id' },
        )

        const featureKpisWithActualValues = mergedValues.map((m) => ({
          ...m,
          actual_value: sumBy(featureConfigByIds[m.feature_config_id], 'annual_value'),
        }))

        setFeatureKpis(featureKpisWithActualValues)
      } else if (error) {
        setError()
      }
    }

    fetchData()
  }, [urqlClient, setError, projectId, milestoneId, process])

  const rows = useMemo(() => {
    const currentRowModels = apiRef.current?.getRowModels?.() ?? new Map()
    const kpisUnsorted = featureKpis.reduce(
      (a, c) => [
        ...a,
        {
          id: c!.feature_config_id,
          featureType: c.feature_config!.feature_type_config.names as InternationalizedObject,
          feature: c.feature_config!.names as InternationalizedObject,
          otherDescription: c.other_description,
          tooltip: c.feature_config!.tooltips as InternationalizedObject,
          annual_value: currentRowModels.get(c!.id)?.annual_value ?? c?.annual_value ?? null,
          target: c.feature_kpi?.target ?? null,
          actualValue: c?.actual_value ?? '-',
          featureTypeSortNumber: c.feature_config.feature_type_config.sort_number,
          sortNumber: c.feature_config.sort_number,
        } as RowData,
      ],
      [] as RowData[],
    )

    return sortBy(kpisUnsorted, ['featureTypeSortNumber', 'sortNumber'])
  }, [apiRef, featureKpis])

  const columns = useMemo(() => {
    const hideActualValueColumn = reportEditable
    const onRifmChange = (api: GridRenderEditCellParams['api'], id: GridRowId, field: string, value: number) => {
      api.setEditCellValue({ id, field, value })
      setDirty(true)
    }

    return [
      {
        field: 'featureType',
        headerName: getMessage('label.feature.type'),
        sortable: false,
        disableColumnMenu: true,
        flex: 0.6,
        disableColumnResize: true,
        valueFormatter: (params) => {
          const value = params.value as InternationalizedObject
          return value?.[language]
        },
      },
      {
        field: 'feature',
        headerName: getMessage('label.feature'),
        sortable: false,
        disableColumnMenu: true,
        flex: 1,
        renderCell: (params) => {
          let featureName = ''
          const value = params.value as InternationalizedObject
          if (value) {
            // for "Other" features display also the corresponding description
            featureName = params.row.otherDescription
              ? `${value[language]}: ${params.row.otherDescription}`
              : value[language]
          }
          return (
            <>
              <S.Span.TextOverflow $withTooltip>{value && featureName}</S.Span.TextOverflow>
              {params.row.tooltip[language] && (
                <Tooltip placement="right" title={params.row.tooltip[language]}>
                  <S.Icons.GridTooltipInfo />
                </Tooltip>
              )}
            </>
          )
        },
      },
      {
        field: 'annual_value',
        headerAlign: 'right',
        editable: reportEditable,
        sortable: false,
        align: 'right',
        disableColumnMenu: true,
        flex: 0.4,
        renderCell: ({ value }) => {
          if (value === null || value === '' || Number.isNaN(value)) {
            return <DefaultSectionTypography $standAlone />
          }
          return <S.Span.TextOverflow>{value?.toLocaleString('de-CH')}</S.Span.TextOverflow>
        },
        renderEditCell: (params) => {
          const { value, id, field, api } = params
          const isValid = DataGridUtils.validateValue(value)
          return (
            <NumberInput
              value={value as number}
              onChange={(val: number) => onRifmChange(api, id, field, val)}
              error={!isValid}
            />
          )
        },
        renderHeader: () => (
          <EditableColumnHeader
            headerName={`${getMessage('label.report')} ${reportYear ?? ''}`}
            editMode={reportEditable}
          />
        ),
      },
      {
        field: 'target',
        headerName: getMessage('label.target'),
        headerAlign: 'right',
        sortable: false,
        align: 'right',
        disableColumnMenu: true,
        flex: 0.3,
        renderCell: ({ value }) => {
          if (value === null || value === '' || Number.isNaN(value)) {
            return '-'
          }
          return <S.Span.TextOverflow>{value?.toLocaleString('de-CH')}</S.Span.TextOverflow>
        },
      },
      {
        field: 'actualValue',
        headerName: getMessage('label.actual.value'),
        headerAlign: 'right',
        hide: hideActualValueColumn,
        sortable: false,
        align: 'right',
        disableColumnMenu: true,
        cellClassName: 'kpi-actual-value--cell',
        flex: 0.3,
        renderCell: ({ value }) => {
          if (value === null || value === '' || Number.isNaN(value)) {
            return '-'
          }
          return <S.Span.TextOverflow>{value?.toLocaleString('de-CH')}</S.Span.TextOverflow>
        },
      },
    ] as GridColDef[]
  }, [getMessage, language, reportEditable, reportYear])

  usePrompt(getMessage('notification.form.unsaved.changes'), dirty)

  const onSaveLocal = async () => {
    const rows = apiRef.current.getRowModels()
    const toSave = [...rows]
      .filter(([, v]) => v.annual_value !== null)
      .map(([k, v]) => ({
        milestone_id: milestoneId,
        feature_config_id: k,
        annual_value: DataGridUtils.formatFieldValue(v.annual_value),
      }))

    const { error } = await urqlClient
      .mutation<{ insert_feature_kpi_annual_report: Mutation_Root['insert_feature_kpi_annual_report'] }>(
        upsertFeatureKPIAnnualReport,
        { milestoneId: milestoneId, objects: toSave },
      )
      .toPromise()

    if (error) {
      notificationService.operationFailed()
    } else {
      if (onSave) {
        notificationService.changesSaved()
        setDirty(false)
        onSave()
      }
    }
  }

  return (
    <>
      <Form
        onSubmit={submit(onSaveLocal)}
        render={({ handleSubmit }) => (
          <form
            id="edit-form"
            onSubmit={handleSubmit}
            onKeyPress={(event) => {
              if (event.key === 'Enter') {
                event.preventDefault()
              }
            }}
          >
            <Box>
              <DataGridPro
                apiRef={apiRef}
                rows={rows}
                columns={columns}
                hideFooter
                autoHeight
                disableColumnReorder
                disableRowSelectionOnClick
                disableColumnSelector
                localeText={gridTranslations}
                onCellEditStop={() => setDirty(true)}
              />
            </Box>
          </form>
        )}
      />
    </>
  )
}
