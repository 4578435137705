import { useCallback, useEffect, useRef } from 'react'
import { NavigateFunction, useNavigate } from 'react-router'
import { useEnvironment } from 'src/env/EnvironmentStore'
import { NavigationUtils } from 'src/shared/utils/NavigationUtils'

export const useNavigateToLogin = (): (() => void) => {
  const { backendUrl } = useEnvironment()

  const navigate = useCallback(() => {
    NavigationUtils.login(backendUrl)
  }, [backendUrl])

  return navigate
}

export const useNavigateToLogout = (): (() => void) => {
  const { backendUrl } = useEnvironment()

  const navigate = useCallback(() => {
    window.location.href = `${backendUrl}/api/init-logout`
  }, [backendUrl])

  return navigate
}

export const useDelayedNavigate = (): NavigateFunction => {
  const navigate = useNavigate()
  const timeoutRef: React.MutableRefObject<number | null> = useRef<number>(null)

  useEffect(() => {
    return () => window.clearTimeout(timeoutRef?.current ?? 0)
  }, [timeoutRef])

  const delayedNavigate = useCallback(
    (...args: Parameters<NavigateFunction>): void => {
      timeoutRef.current = setTimeout(() => navigate(...args), 200)
    },
    [navigate],
  ) as NavigateFunction

  return delayedNavigate
}

export const useHashScroll = (id?: string, async = false, offset = 73) => {
  useEffect(() => {
    const { hash } = window.location

    const scrollToElement = (id: string) => {
      const element = document.getElementById(id as string)

      if (element) {
        const headerOffset = offset
        const elementPosition = element.offsetTop
        const offsetPosition = elementPosition - headerOffset

        window.scrollTo({
          top: offsetPosition,
          behavior: 'smooth',
        })
      }
    }

    if (hash === `#${id}`) {
      if (async) {
        // FIXME SMR 2021-08-09: temporary workaround for async loading of an async / grid data
        setTimeout(() => scrollToElement(id as string), 500)
      } else {
        scrollToElement(id as string)
      }
    }
  }, [id, async, offset])
}
