import { Tabs } from '@mui/material'
import { ReactElement } from 'react'
import { useMessageSource } from 'src/i18n/useMessageSource'
import { NestedRoute, Route, SecondLevelRoute } from 'src/routing/routing'
import { TabNavLink } from 'src/routing/TabNavLink'
import styled from 'styled-components/macro'

interface SecondLevelNavigationProps {
  route: Route
  baseUrl: string
  activeRoute: SecondLevelRoute
}

const TabsStyled = styled(Tabs)`
  border-bottom: 1px solid ${({ theme }) => theme.colors.grey.divider};
  width: 100%;

  & .MuiTabScrollButton-root {
    color: ${({ theme }) => theme.colors.text.secondary};

    &:hover {
      color: ${({ theme }) => theme.colors.text.primary};
    }
  }
`

export const SecondLevelNavigation = ({ route, baseUrl, activeRoute }: SecondLevelNavigationProps): ReactElement => {
  const nested = route?.nested as NestedRoute
  const filteredRoutes = Object.keys(nested).filter((key) => !nested[key].hideNavigation) || []

  const { getMessage } = useMessageSource()

  return (
    <TabsStyled value={activeRoute} textColor="inherit" variant="scrollable" scrollButtons="auto">
      {filteredRoutes.map((route) => (
        <TabNavLink
          to={`${baseUrl}/${nested[route].path}`}
          value={nested[route]}
          label={getMessage(nested[route].navigationKey || '')}
          key={nested[route].path}
        />
      ))}
    </TabsStyled>
  )
}
