import { Milestone_Qualitative_Evaluation } from 'src/@types/graphql'
import { QualitativeEvaluationBaseEdit } from 'src/screens/shared/implementation/details/milestone/details/final/qualitative-evaluation/QualitativeEvaluationBaseEdit'
import { resolveProcessToLowerCase } from 'src/shared/utils/Utils'

interface Props {
  baseUrl: '/pf-kap' | '/pf-pgv'
  field: keyof Pick<
    Milestone_Qualitative_Evaluation,
    'equal_opportunity' | 'sustainability' | 'multiplication_potential'
  >
}

export const QualitativeEvaluationEditPage = ({ baseUrl, field }: Props) => {
  const process = resolveProcessToLowerCase(baseUrl)
  const labeledField = field.replaceAll('_', '.')

  return (
    <QualitativeEvaluationBaseEdit
      baseUrl={baseUrl}
      singleField={field}
      screenLayoutTextLabel={`label.title.edit.${labeledField}`}
      helpAndInstructionsTextLabel={`label.help.qualitative.evaluation.${labeledField}.${process}`}
    />
  )
}
