import { Chip } from '@mui/material'
import styled, { css } from 'styled-components/macro'

export const ClickableChipStyled = styled(Chip)<{ $selected: boolean }>`
  &:not(:last-child) {
    margin-right: ${({ theme }) => theme.spacing(1)};
  }

  & .MuiChip-icon {
    color: ${({ theme }) => theme.colors.grey.inactive};
  }

  ${({ $selected }) =>
    $selected &&
    css`
      &.MuiChip-clickable {
        background-color: ${({ theme }) => theme.colors.primary.main};
        color: ${({ theme }) => theme.colors.common.white};
        border-color: ${({ theme }) => theme.colors.primary.main};
        &:hover {
          background-color: ${({ theme }) => theme.colors.primary.dark};
          border-color: ${({ theme }) => theme.colors.primary.dark};
        }

        & .MuiChip-icon {
          color: ${({ theme }) => theme.colors.common.white};
        }
      }
    `}
`
