import { Box, Stack } from '@mui/material'
import { useGridApiRef } from '@mui/x-data-grid-pro'
import { ReactElement, useCallback, useEffect, useRef, useState } from 'react'
import { Form } from 'react-final-form'
import { useParams } from 'react-router'
import { Mutation_Root, Query_Root } from 'src/@types/graphql'
import { useMessageSource } from 'src/i18n/useMessageSource'
import { ROUTES } from 'src/routing/routes'
import { KapMilestoneMeasureReportKpiGrid } from 'src/screens/kap/implementation/milestone/details/annual-report/measures/KapMilestoneMeasureReportKpiGrid'
import {
  fetchKapMeasureAnnualReportQuery,
  upsertKapMeasureAnnualReportMutation,
} from 'src/screens/kap/implementation/milestone/kapMilestoneQueries'
import { queryMilestoneById } from 'src/screens/shared/implementation/details/milestoneQueries'
import { usePermissionsForKapMilestones } from 'src/service/security/PermissionHook'
import { SaveAndBackButton, SaveButton } from 'src/shared/button/Buttons'
import { LEVEL_TYPE, MEASURE_CHAR, TEXT_LENGTH } from 'src/shared/constants/constants'
import {
  GOAL_MEASURE_RATING,
  MILESTONE_RESPONSIBLE_TYPE_TYPE,
  MILESTONE_STATUS_TYPE,
} from 'src/shared/constants/milestone-constants'
import { Option } from 'src/shared/form/control'
import { AutoCompleteField } from 'src/shared/form/control/AutoCompleteField'
import { HtmlEditorField } from 'src/shared/form/control/HtmlEditorField'
import { DirtyFormSpy } from 'src/shared/form/dirty/DirtyFormSpy'
import { createDecorators } from 'src/shared/form/utils/decorators'
import { composeValidators, maxChar, required } from 'src/shared/form/validation/validators'
import { PageLayout } from 'src/shared/layout/PageLayout'
import { ScreenLayout } from 'src/shared/layout/ScreenLayout'
import { NotAuthorized } from 'src/shared/not-authorized/NotAuthorized'
import { HelpAndInstructions } from 'src/shared/presentation/HelpAndInstructions'
import { DataGridUtils } from 'src/shared/utils/DataGridUtils'
import { useDelayedNavigate } from 'src/shared/utils/hooks/navigation-hooks'
import { useError } from 'src/shared/utils/hooks/page-loading-error-hook'
import { useDelayedSubmit } from 'src/shared/utils/hooks/submit-hook'
import { HtmlSanitizer } from 'src/shared/utils/HtmlSanitizer'
import { useNotificationService } from 'src/shared/utils/NotificationService'
import { useClient, useQuery } from 'urql'

type originType = 'SAVE' | 'SAVE_AND_BACK'

interface MeasureRatingForm {
  rating: string
  reason: string
}

const decorators = createDecorators()

export const KapMilestoneMeasureRatingEditPage = (): ReactElement => {
  const { programId, milestoneId, milestonePath, measureId } = useParams()
  const program_id = parseInt(programId as string)
  const milestone_id = parseInt(milestoneId as string)
  const measure_id = parseInt(measureId as string)

  const { getMessage } = useMessageSource()
  const notificationService = useNotificationService()
  const navigate = useDelayedNavigate()
  const originRef = useRef<originType>('SAVE')
  const urqlClient = useClient()
  const gridApiRef = useGridApiRef()
  const setError = useError()
  const submit = useDelayedSubmit()

  const [initialValues, setInitialValues] = useState<MeasureRatingForm>()
  const [ratingOptions, setRatingOptions] = useState<Option[]>([])
  const [kpiDirty, setKpiDirty] = useState(false)
  const [measureTitle, setMeasureTitle] = useState<string>('')
  const [measureLevel, setMeasureLevel] = useState<LEVEL_TYPE>()
  const [{ data, error }] = useQuery<{ milestone_by_pk: Query_Root['milestone_by_pk'] }, { id: number }>({
    query: queryMilestoneById,
    variables: { id: milestone_id },
  })

  if (error) {
    notificationService.operationFailed()
  }

  const milestone = data?.milestone_by_pk

  const { loading, canEdit } = usePermissionsForKapMilestones(
    program_id,
    milestone?.status as MILESTONE_STATUS_TYPE,
    milestone?.responsible_type as MILESTONE_RESPONSIBLE_TYPE_TYPE,
  )

  useEffect(() => {
    const initData = async () => {
      const ratingOptions = Object.keys(GOAL_MEASURE_RATING).map((key) => ({
        label: `${getMessage(`label.kap.measure.rating.${key}`)}`,
        value: key,
      }))
      setRatingOptions(ratingOptions)

      const { data, error } = await urqlClient
        .query<
          {
            kap_measure_annual_report: Query_Root['kap_measure_annual_report']
            kap_measure_by_pk: Query_Root['kap_measure_by_pk']
          },
          { measureId: number; milestoneId: number }
        >(fetchKapMeasureAnnualReportQuery, { measureId: measure_id, milestoneId: milestone_id })
        .toPromise()

      if (data && data.kap_measure_by_pk && data.kap_measure_annual_report) {
        const measureAnnualReport = data.kap_measure_annual_report[0]
        setInitialValues({
          rating: measureAnnualReport?.rating,
          reason: measureAnnualReport?.reason,
        })

        setMeasureLevel(data.kap_measure_by_pk.level as LEVEL_TYPE)

        const measure = data.kap_measure_by_pk
        const measureIdCharacter = getMessage(`label.kap.measure.id.character.${measure?.level}`)
        setMeasureTitle(`${data.kap_measure_by_pk?.short_title} (${measureIdCharacter}${measure?.sort_number})`)
      } else if (error) {
        setError()
      }
    }
    initData()
  }, [urqlClient, measure_id, milestone_id, setError, getMessage])

  const onKpiGridUpdate = useCallback(
    (initial: boolean) => {
      if (!initial) {
        setKpiDirty(true)
      }
    },
    [setKpiDirty],
  )

  const onSubmit = (origin: originType) => (_: any) => {
    // trigger submit event
    if (originRef) {
      originRef.current = origin
    }
    document.getElementById('edit-form')?.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }))
  }

  const onBack = () => {
    const route = ROUTES.KapAnnualReportMilestoneRoot.nested.MeasureRating.params({
      programId,
      milestoneId,
      milestonePath,
    })

    navigate(`${route}#${MEASURE_CHAR}${measureId}`, { state: { expandedEntityId: `${MEASURE_CHAR}${measureId}` } })
  }

  const onSubmitLocal = async (values: MeasureRatingForm) => {
    const kpiData = Array.from(gridApiRef.current.getRowModels().values())

    const newKpis = kpiData.map((item) => ({
      feature_config_id: item.id,
      milestone_id: milestone_id,
      kap_measure_id: measure_id,
      actual_value: DataGridUtils.formatFieldValue(item.yearActuals),
    }))

    const measureIsValid: boolean =
      !!(values.rating && values.reason) && newKpis.every((item) => Number.isInteger(item.actual_value))

    const newKpisMutationArray = newKpis.filter((item) => item.actual_value !== null)

    const newMeasureRating = {
      milestone_id: milestone_id,
      kap_measure_id: measure_id,
      rating: values.rating,
      reason: HtmlSanitizer.sanitize(values.reason),
      valid: measureIsValid,
    }

    const { data } = await urqlClient
      .mutation<{
        insert_kap_measure_annual_report_one: Mutation_Root['insert_kap_measure_annual_report_one']
        delete_kap_measure_kpi_annual_report: Mutation_Root['delete_kap_measure_kpi_annual_report']
        insert_kap_measure_kpi_annual_report: Mutation_Root['insert_kap_measure_kpi_annual_report']
      }>(upsertKapMeasureAnnualReportMutation, {
        milestoneId: milestone_id,
        measureId: measure_id,
        measureRatingToBeAdded: newMeasureRating,
        kpisToBeAdded: newKpisMutationArray,
      })
      .toPromise()

    if (data && data?.insert_kap_measure_annual_report_one?.id) {
      notificationService.changesSaved()
      setInitialValues(values)
      setKpiDirty(false)
      if (originRef.current === 'SAVE_AND_BACK') {
        onBack()
      }
    } else {
      setError()
    }
  }

  if (!loading && !canEdit) {
    return <NotAuthorized inEditPage />
  }

  return (
    <ScreenLayout
      title={getMessage('label.edit.measure.milestone.title', [measureTitle])}
      onBack={onBack}
      hasSecondLevelNavigation={false}
      actions={
        <>
          <SaveAndBackButton origin="header" onClick={onSubmit('SAVE_AND_BACK')} />
          <SaveButton origin="header" onClick={onSubmit('SAVE')} />
        </>
      }
    >
      <PageLayout>
        <>
          {!loading && measureLevel && initialValues && (
            <>
              <HelpAndInstructions labelKey="label.help.kap.milestone.annual.report.measure.rating" defaultExpansion />
              <Box>
                <Form<MeasureRatingForm>
                  initialValues={initialValues}
                  onSubmit={submit(onSubmitLocal)}
                  decorators={decorators}
                  render={({ handleSubmit }) => {
                    return (
                      <form id="edit-form" onSubmit={handleSubmit} noValidate>
                        <Stack spacing={2}>
                          <AutoCompleteField
                            name="rating"
                            label={getMessage('label.measure.rating')}
                            options={ratingOptions}
                            required
                            validate={required()}
                          />
                          <HtmlEditorField
                            name="reason"
                            label={getMessage('label.reason')}
                            required
                            validate={composeValidators(maxChar(TEXT_LENGTH.XL), required())}
                          />
                          <KapMilestoneMeasureReportKpiGrid
                            measureId={measure_id}
                            measureLevel={measureLevel}
                            reportYear={milestone?.year_in_focus}
                            mode="edit"
                            gridApiRef={gridApiRef}
                            onCellEdit={onKpiGridUpdate}
                          />
                          <DirtyFormSpy gridDirty={kpiDirty} />
                        </Stack>
                      </form>
                    )
                  }}
                />
              </Box>
            </>
          )}
        </>
      </PageLayout>
    </ScreenLayout>
  )
}
