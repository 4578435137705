import { gql } from 'urql'

export const queryProjectJournalById = gql`
  query ($id: Int!) {
    project_journal_by_pk(id: $id) {
      id
      title
      journal_date
      status
      description
      stakeholders
    }
  }
`

export const queryProjectJournalsPrimaryInfoByProjectBaseId = gql`
  query ($project_base_id: Int!) {
    project_journal(where: { project_base_id: { _eq: $project_base_id } }, order_by: { journal_date: desc }) {
      id
      title
      journal_date
      status
    }
  }
`

export const queryProjectJournalSecondaryInfoById = gql`
  query ($id: Int!) {
    project_journal_by_pk(id: $id) {
      description
      stakeholders
    }
  }
`

export const upsertProjectJournalMutation = gql`
  mutation ($object: project_journal_insert_input!) {
    insert_project_journal_one(
      object: $object
      on_conflict: {
        constraint: project_journal_pk
        update_columns: [title, journal_date, status, description, stakeholders]
      }
    ) {
      id
    }
  }
`

export const deleteProjectJournalByIdMutation = gql`
  mutation ($id: Int!) {
    delete_project_journal_by_pk(id: $id) {
      id
    }
  }
`

export const fetchProjectJournalFileUUIDsQuery = gql`
  query ($journalId: Int!) {
    file(where: { _and: { entity_id: { _eq: $journalId }, entity_type: { _eq: "PROJECT_JOURNAL" } } }) {
      uuid
    }
  }
`
