import { useRef } from 'react'
import { useParams } from 'react-router'
import { Query_Root } from 'src/@types/graphql'
import { useMessageSource } from 'src/i18n/useMessageSource'
import { resolveAnnualReportMilestoneRoot } from 'src/routing/routing-utils'
import { AnnualFeatureKpiGrid } from 'src/screens/shared/implementation/details/milestone/details/annual/kpi/AnnualFeatureKpiGrid'
import { queryMilestoneById } from 'src/screens/shared/implementation/details/milestoneQueries'
import { usePermissionsForMilestones } from 'src/service/security/PermissionHook'
import { SaveAndBackButton, SaveButton } from 'src/shared/button/Buttons'
import { MILESTONE_RESPONSIBLE_TYPE_TYPE, MILESTONE_STATUS_TYPE } from 'src/shared/constants/milestone-constants'
import { PageLayout } from 'src/shared/layout/PageLayout'
import { ScreenLayout } from 'src/shared/layout/ScreenLayout'
import { NotAuthorized } from 'src/shared/not-authorized/NotAuthorized'
import { HelpAndInstructions } from 'src/shared/presentation/HelpAndInstructions'
import { Section } from 'src/shared/presentation/Section'
import { useDelayedNavigate } from 'src/shared/utils/hooks/navigation-hooks'
import { useNotificationService } from 'src/shared/utils/NotificationService'
import { Utils } from 'src/shared/utils/Utils'
import { useQuery } from 'urql'

type originType = 'SAVE' | 'SAVE_AND_BACK'

interface Props {
  baseUrl: '/pf-kap' | '/pf-pgv'
}

export const MilestoneFeatureKpiEditPage = ({ baseUrl }: Props) => {
  const { projectId, milestoneId, milestonePath } = useParams()
  const project_id = parseInt(projectId as string)
  const milestone_id = parseInt(milestoneId as string)
  const process = Utils.resolveProcess(baseUrl)
  const originRef = useRef<originType>('SAVE')

  const { getMessage } = useMessageSource()
  const notificationService = useNotificationService()
  const navigate = useDelayedNavigate()

  const [{ data, error }] = useQuery<{ milestone_by_pk: Query_Root['milestone_by_pk'] }, { id: number }>({
    query: queryMilestoneById,
    variables: { id: milestone_id },
  })

  if (error) {
    notificationService.operationFailed()
  }

  const milestone = data?.milestone_by_pk

  const { loading, canEdit } = usePermissionsForMilestones(
    process,
    project_id,
    milestone?.status as MILESTONE_STATUS_TYPE,
    milestone?.responsible_type as MILESTONE_RESPONSIBLE_TYPE_TYPE,
  )

  const onSubmit = (origin: originType) => (_: any) => {
    // trigger submit event
    if (originRef) {
      originRef.current = origin
    }
    document.getElementById('edit-form')?.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }))
  }

  const onSave = () => {
    if (originRef.current === 'SAVE_AND_BACK') {
      onBack()
    }
  }

  const onBack = () => {
    const featureKpiRoute = resolveAnnualReportMilestoneRoot(baseUrl).nested.MilestoneFeatureKpi
    navigate(featureKpiRoute.params({ projectId, milestoneId, milestonePath }))
  }

  if (!loading && !canEdit) {
    return <NotAuthorized inEditPage />
  }

  return (
    <ScreenLayout
      title={getMessage('label.navigation.edit.kpis')}
      onBack={onBack}
      hasSecondLevelNavigation={false}
      actions={
        <>
          <SaveAndBackButton origin="header" onClick={onSubmit('SAVE_AND_BACK')} />
          <SaveButton origin="header" onClick={onSubmit('SAVE')} />
        </>
      }
    >
      <PageLayout>
        <>
          {!loading && (
            <Section
              helpAndInstructions={
                <HelpAndInstructions
                  labelKey={`label.help.milestone.feature.kpi.${process.toLowerCase()}`}
                  defaultExpansion
                />
              }
            >
              <AnnualFeatureKpiGrid
                reportEditable
                baseUrl={baseUrl}
                onSave={onSave}
                projectId={project_id}
                milestoneId={milestone_id}
                reportYear={milestone?.year_in_focus}
              />
            </Section>
          )}
        </>
      </PageLayout>
    </ScreenLayout>
  )
}
