import { Box, Card, CardContent, Divider, Typography } from '@mui/material'
import { get } from 'lodash'
import { ReactElement } from 'react'
import { Success_Factor_Indicator } from 'src/@types/graphql'
import { useMessageSource } from 'src/i18n/useMessageSource'
import { LIFE_PHASES, TOPICS } from 'src/shared/constants/success-factor-constants'
import { HtmlRenderer } from 'src/shared/presentation/HtmlRenderer'
import { S } from 'src/shared/styled/S'
import { HtmlSanitizer } from 'src/shared/utils/HtmlSanitizer'
import { useUserLocale } from 'src/user/UserContext'
import styled from 'styled-components/macro'

interface Props {
  description: string[]
  indicators: Success_Factor_Indicator[]
}

const CardStyled = styled(Card)`
  margin-top: ${({ theme }) => theme.spacing(2)};
`

const CardContentStyled = styled(CardContent)`
  display: flex;
  gap: ${({ theme }) => theme.spacing(3)};
  flex-wrap: wrap;
`

export const SuccessFactorIndicatorsSection = ({ description, indicators }: Props): ReactElement => {
  const { getMessage } = useMessageSource()
  const locale = useUserLocale()

  const lifePhasesArray = Object.values(LIFE_PHASES)
  const topicsArray = Object.values(TOPICS)

  const resolveTitle = (indicator: any) => {
    if (lifePhasesArray.includes(indicator.indicator_code)) {
      return getMessage(`label.success.factor.life.phase.${indicator.indicator_code}`)
    } else if (topicsArray.includes(indicator.indicator_code)) {
      return getMessage(`label.success.factor.topic.${indicator.indicator_code}`)
    }
  }

  return (
    <Box mb={5}>
      <Typography variant="h3" color="textSecondary">
        {getMessage('label.success.factor.description')}
      </Typography>
      <Typography variant="body2" color="textSecondary" mt={0.5}>
        {description}
      </Typography>
      <CardStyled>
        <CardContentStyled>
          {indicators.map((indicator) => (
            <S.List.SuccessFactorIndicators key={indicator.sort_number}>
              <Typography variant="h6" className="indicator-title">
                {resolveTitle(indicator)}
              </Typography>
              <Divider />
              <HtmlRenderer
                html={HtmlSanitizer.resolveConfigTextWithBullets(get(indicator.description, locale, ''))}
                variant="body2"
                className="html-renderer"
              />
            </S.List.SuccessFactorIndicators>
          ))}
        </CardContentStyled>
      </CardStyled>
    </Box>
  )
}
