import { Box, FormControl, FormHelperText, Typography } from '@mui/material'
import { ReactElement, useContext } from 'react'
import { useDropzone } from 'react-dropzone'
import { useField } from 'react-final-form'
import { useMessageSource } from 'src/i18n/useMessageSource'
import {
  EMAIL_FILE_EXTENSIONS,
  EMAIL_FILE_MIME_ACCEPT_TYPES,
  FILE_EXTENSIONS,
  FILE_MAX_SIZE_IN_BYTES,
  FILE_MAX_SIZE_IN_MB,
  FILE_MIME_ACCEPT_TYPES,
} from 'src/shared/constants/file-constants'
import { ValidationError } from 'src/shared/form/validation/validators'
import { CloudUploadIcon, DocumentIcon } from 'src/shared/icons/Icons'
import { useNotificationService } from 'src/shared/utils/NotificationService'
import styled, { CSSProperties, DefaultTheme, ThemeContext } from 'styled-components/macro'

type FileUploadFieldProps = {
  validate?: any
  name: string
  acceptEmailTypes?: boolean
}

const GetBorderColor = ({ $isDragReject, $isDragAccept }: Props): CSSProperties['border'] => {
  const themeContext = useContext(ThemeContext as React.Context<DefaultTheme>)

  if ($isDragReject) {
    return themeContext.colors.error.main
  }
  if ($isDragAccept) {
    return themeContext.colors.success.main
  }
  return themeContext.colors.grey.divider
}

const GetBackgroundColor = ({ $isDragReject, $isDragAccept, error }: Props): CSSProperties['backgroundColor'] => {
  const themeContext = useContext(ThemeContext as React.Context<DefaultTheme>)
  if ($isDragAccept) {
    return themeContext.colors.success.light
  }
  if ($isDragReject || error) {
    return themeContext.colors.error.light
  }

  return themeContext.colors.grey.neutral
}

interface Props {
  $isDragAccept: boolean
  $isDragActive: boolean
  $isDragReject: boolean
  error?: ValidationError
}

const DropzoneWrapper = styled(FormControl)<Props>`
  height: 16rem;
  border: 2px dashed ${(props) => GetBorderColor(props)};
  display: flex;
  grid-gap: ${({ theme }) => theme.spacing(1)};
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: ${({ theme }) => theme.spacing(2)};
  background-color: ${(props) => GetBackgroundColor(props)};
  font-weight: ${({ theme }) => theme.typography.h6.fontWeight};
  transition: ${({ theme }) =>
    theme.transitions.create('border', {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.enteringScreen,
    })};
  &:hover {
    cursor: pointer;
  }
  &:focus-visible {
    outline: none;
    border-color: ${({ theme }) => theme.colors.secondary.dark};
    background-color: ${({ theme }) => theme.colors.action.focus};
  }
`

const CloudUploadIconStyled = styled(CloudUploadIcon)`
  font-size: 3rem;
  color: ${({ theme }) => theme.colors.text.disabled};
`

const TypographyStyled = styled(Typography)`
  color: ${({ theme }) => theme.colors.text.disabled};
`

const DocumentIconStyled = styled(DocumentIcon)`
  color: ${({ theme }) => theme.colors.text.primary};
  font-size: 2.5rem;
  margin: 0.25rem 0;
`

const FileNameTypographyStyled = styled(Typography)`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 100%;
  font-weight: inherit;
  padding: 0 ${({ theme }) => theme.spacing(3)};
  text-align: center;
  color: ${({ theme }) => theme.colors.text.primary};
`

export const FileUploadField = (props: FileUploadFieldProps): ReactElement => {
  const { name, validate, acceptEmailTypes } = props
  const notificationService = useNotificationService()
  const { getMessage } = useMessageSource()

  const {
    input: { onChange, onFocus, onBlur, value },
    meta: { touched, error: errorObject },
  } = useField(name, { validate })

  const error = errorObject && getMessage(errorObject.errorKey, errorObject.params)
  const invalid = Boolean(touched && error)

  const ACCEPT_TYPES = {
    ...FILE_MIME_ACCEPT_TYPES,
    ...(acceptEmailTypes && EMAIL_FILE_MIME_ACCEPT_TYPES),
  }

  const ACCEPT_FILE_EXTENSIONS = acceptEmailTypes ? `${FILE_EXTENSIONS},${EMAIL_FILE_EXTENSIONS}` : `${FILE_EXTENSIONS}`

  const { getRootProps, getInputProps, isDragAccept, isDragActive, isDragReject } = useDropzone({
    multiple: false,
    noDrag: false,
    maxSize: FILE_MAX_SIZE_IN_BYTES,
    minSize: 1,
    accept: ACCEPT_TYPES,
    onDropAccepted: (acceptedFiles) => {
      const singleFile = acceptedFiles[0]
      onChange(singleFile)
    },
    onDrop: (acceptedFiles, rejectedFiles) => {
      const flatErrors = rejectedFiles.map((r) => r.errors).flat()

      const hasTooManyFiles = flatErrors.some((r) => r.code === 'too-many-files')
      const hasAcceptedFile = acceptedFiles.length > 0
      const hasAnyError = flatErrors.length > 0

      if (hasAcceptedFile && hasAnyError) {
        notificationService.info(getMessage('label.file.too.many.files.info'))
      } else if (hasTooManyFiles) {
        notificationService.error(getMessage('validation.file.too.many.files'))
      } else if (!hasAcceptedFile) {
        const firstErrorCode = flatErrors.filter((r) => r.code !== 'too-many-files')?.[0]?.code
        if (firstErrorCode === 'file-invalid-type') {
          notificationService.error(getMessage('validation.file.invalid.type', [ACCEPT_FILE_EXTENSIONS]))
        } else if (firstErrorCode === 'file-too-large') {
          notificationService.error(getMessage('validation.file.max.size', [FILE_MAX_SIZE_IN_MB]))
        } else if (firstErrorCode === 'file-too-small') {
          notificationService.error(getMessage('validation.file.min.size'))
        }
      }
    },
  })
  return (
    <Box>
      <DropzoneWrapper
        {...getRootProps()}
        $isDragAccept={isDragAccept}
        $isDragActive={isDragActive}
        $isDragReject={isDragReject}
        error={invalid}
      >
        <input {...getInputProps()} onFocus={onFocus} onBlur={onBlur} />
        {!value.name && (
          <>
            <CloudUploadIconStyled />
            <TypographyStyled>{getMessage('label.file.upload.modal.dropzone.placeholder')}</TypographyStyled>
          </>
        )}
        {value.name && (
          <>
            <DocumentIconStyled />
            <FileNameTypographyStyled>{value.name}</FileNameTypographyStyled>
          </>
        )}
      </DropzoneWrapper>
      <FormHelperText error>{(invalid && error) || ' '}</FormHelperText>
    </Box>
  )
}
