import { alpha } from '@mui/material'
import { ChartOptions } from 'chart.js'
import { createContext, ReactElement, ReactNode, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router'
import { Focus_Topic, Query_Root } from 'src/@types/graphql'
import { useMessageSource } from 'src/i18n/useMessageSource'
import { AnalysisSuccessFactor } from 'src/screens/success-factor-tool/analysis/details/analysis/AnalysisDetailsPage'
import { AnalysisUtils } from 'src/screens/success-factor-tool/analysis/details/analysis/utils/AnalysisUtils'
import { fetchAnalysisFocusTopicAndSuccessFactorDataQuery } from 'src/screens/success-factor-tool/analysis/details/successFactorAnalysisQueries'
import { ChartUtils } from 'src/shared/charts/ChartUtils'
import { RATING_STATUS } from 'src/shared/constants/success-factor-constants'
import { useNotificationService } from 'src/shared/utils/NotificationService'
import { muiTheme } from 'src/theme/theme'
import { useUserLocale } from 'src/user/UserContext'
import { useClient } from 'urql'

interface ChartContextValue {
  chartData: any
  chartOptions: any
  chartFetching: boolean
  refetchChartData: () => void
}

const defaultContextValue: ChartContextValue = {
  chartData: {},
  chartOptions: {},
  chartFetching: true,
  refetchChartData: () => {},
}

const ChartContext = createContext(defaultContextValue)

const ChartContextProvider = ({ children }: { children: ReactNode }): ReactElement => {
  const { analysisId } = useParams()
  const urqlClient = useClient()
  const locale = useUserLocale()
  const notificationService = useNotificationService()
  const { getMessage } = useMessageSource()
  const [fetching, setFetching] = useState<boolean>(true)

  const [focusTopicData, setFocusTopicData] = useState<Focus_Topic[]>([])
  const [ratingData, setRatingData] = useState<AnalysisSuccessFactor[]>([])

  const fetchChartData = useCallback(async () => {
    try {
      const { data } = await urqlClient
        .query<
          {
            focus_topic: Query_Root['focus_topic']
            analysis_success_factor_rating: Query_Root['analysis_success_factor_rating']
            success_factor: Query_Root['success_factor']
          },
          { locale: string; successFactorAnalysisId: number }
        >(fetchAnalysisFocusTopicAndSuccessFactorDataQuery, {
          locale: locale,
          successFactorAnalysisId: parseInt(analysisId as string),
        })
        .toPromise()

      if (data) {
        const successFactorRatingData = AnalysisUtils.mapSuccessFactorToAnalysisSuccessFactorModel(
          data.success_factor,
          data.analysis_success_factor_rating,
        )

        setFocusTopicData(data.focus_topic)
        setRatingData(successFactorRatingData)
      } else {
        notificationService.operationFailed()
      }
    } catch (e) {
      notificationService.operationFailed()
    } finally {
      setFetching(false)
    }
  }, [analysisId, locale, notificationService, urqlClient])

  useEffect(() => {
    fetchChartData()
  }, [fetchChartData])

  const labels = useMemo(
    () => focusTopicData?.map((focusTopic) => ChartUtils.breakLabel(focusTopic.names)) ?? [],
    [focusTopicData],
  )

  const statusChartData = useMemo(
    () =>
      ChartUtils.adaptStringsForChartData(
        focusTopicData?.map((focusTopic) =>
          AnalysisUtils.resolveAverageRating(
            ratingData.filter((successFactor) => successFactor.focusTopicId === focusTopic.id),
            'ratingStatus',
          ),
        ),
      ) ?? [],
    [focusTopicData, ratingData],
  )

  const priorityChartData = useMemo(
    () =>
      ChartUtils.adaptStringsForChartData(
        focusTopicData?.map((focusTopic) =>
          AnalysisUtils.resolveAverageRating(
            ratingData.filter((successFactor) => successFactor.focusTopicId === focusTopic.id),
            'ratingPriority',
          ),
        ),
      ).map((rating) => rating * 2) ?? [],
    [focusTopicData, ratingData],
  )

  const data = useMemo(
    () => ({
      labels: labels,
      datasets: [
        {
          label: getMessage('label.success.factors.focus.topic.status'),
          data: statusChartData,
          fill: true,
          backgroundColor: alpha(muiTheme.palette.primary.main, 0.2),
          borderColor: muiTheme.palette.primary.main,
          pointBackgroundColor: muiTheme.palette.primary.main,
          pointBorderColor: muiTheme.palette.common.white,
          pointHoverBorderColor: muiTheme.palette.primary.main,
        },
        {
          label: getMessage('label.success.factors.focus.topic.priority'),
          data: priorityChartData,
          fill: true,
          backgroundColor: alpha(muiTheme.palette.secondary.main, 0.2),
          borderColor: muiTheme.palette.secondary.main,
          pointBackgroundColor: muiTheme.palette.secondary.main,
          pointBorderColor: muiTheme.palette.common.white,
          pointHoverBorderColor: muiTheme.palette.secondary.main,
        },
      ],
    }),
    [getMessage, labels, priorityChartData, statusChartData],
  )

  const ratingsCount = Object.keys(RATING_STATUS).length

  const options = useMemo(
    () =>
      ({
        scales: {
          r: {
            max: ratingsCount,
            min: 0,
            ticks: {
              step: 1,
            },
            grid: {
              circular: true,
            },
            pointLabels: {
              padding: 0,
              font: {
                size: 14,
              },
            },
          },
        },
        aspectRatio: 1.5,
      } as ChartOptions),
    [ratingsCount],
  )

  const chartContextValue = useMemo(
    () => ({ chartData: data, chartOptions: options, chartFetching: fetching, refetchChartData: fetchChartData }),
    [data, options, fetching, fetchChartData],
  )

  return <ChartContext.Provider value={chartContextValue}>{children}</ChartContext.Provider>
}

const useChartContext = (): ChartContextValue => {
  return useContext(ChartContext)
}

export { ChartContext, ChartContextProvider, useChartContext }
