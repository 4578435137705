import { Location } from 'history'
import { useEffect, useState } from 'react'
import { useLocation } from 'react-router'
import { NestedRoute, Route, SecondLevelRoute } from 'src/routing/routing'

const resolveActiveRoute = (
  baseUrl: '/pf-kap' | '/pf-pgv',
  location: Location,
  detailsRoot: Route,
  projectId?: string,
): SecondLevelRoute => {
  const nested = detailsRoot!.nested! as NestedRoute
  const activeRoute = Object.keys(nested).find((key) => {
    let isPathMatching
    if (detailsRoot.params) {
      isPathMatching = detailsRoot.params({ projectId }) === location.pathname
    }
    return location.pathname.endsWith(nested[key].path) || (isPathMatching && nested[key].primary)
  }) as string
  return nested[activeRoute] as SecondLevelRoute
}

export const useActiveRoute = (
  baseUrl: '/pf-kap' | '/pf-pgv',
  detailsRoot: Route,
  projectId?: string,
): SecondLevelRoute => {
  const location = useLocation()

  const [activeRoute, setActiveRoute] = useState<SecondLevelRoute>(() => {
    return resolveActiveRoute(baseUrl, location, detailsRoot, projectId)
  })

  useEffect(() => {
    const activeRoute = resolveActiveRoute(baseUrl, location, detailsRoot, projectId)
    setActiveRoute(activeRoute)
  }, [baseUrl, detailsRoot, location, projectId])

  return activeRoute
}
