import { ReactElement } from 'react'
import { useParams } from 'react-router-dom'
import { Query_Root } from 'src/@types/graphql'
import { resolveAnnualReportMilestoneRoot } from 'src/routing/routing-utils'
import { queryMilestoneById } from 'src/screens/shared/implementation/details/milestoneQueries'
import { BaseMeasureCreate } from 'src/screens/shared/measure/BaseMeasureCreate'
import { usePermissionsForMilestones } from 'src/service/security/PermissionHook'
import { MEASURE_CHAR } from 'src/shared/constants/constants'
import { MILESTONE_RESPONSIBLE_TYPE_TYPE, MILESTONE_STATUS_TYPE } from 'src/shared/constants/milestone-constants'
import { useDelayedNavigate } from 'src/shared/utils/hooks/navigation-hooks'
import { useNotificationService } from 'src/shared/utils/NotificationService'
import { Utils } from 'src/shared/utils/Utils'
import { useQuery } from 'urql'

interface MilestoneMeasureCreatePageProps {
  baseUrl: '/pf-kap' | '/pf-pgv'
}

export const MilestoneMeasureCreatePage = ({ baseUrl }: MilestoneMeasureCreatePageProps): ReactElement => {
  const { projectId, milestoneId, milestonePath } = useParams()

  const navigate = useDelayedNavigate()
  const process = Utils.resolveProcessToLowerCase(baseUrl)
  const notificationService = useNotificationService()

  const projectType = Utils.resolveProcess(baseUrl)
  const projectIdParsed = parseInt(projectId as string)
  const milestoneIdParsed = parseInt(milestoneId as string)

  const [{ data, error }] = useQuery<{ milestone_by_pk: Query_Root['milestone_by_pk'] }, { id: number }>({
    query: queryMilestoneById,
    variables: { id: milestoneIdParsed },
  })

  if (error) {
    notificationService.operationFailed()
  }

  const milestone = data?.milestone_by_pk

  const { canEdit, loading } = usePermissionsForMilestones(
    projectType,
    projectIdParsed,
    milestone?.status as MILESTONE_STATUS_TYPE,
    milestone?.responsible_type as MILESTONE_RESPONSIBLE_TYPE_TYPE,
  )

  const onBack = (measureId?: number) => {
    const route = resolveAnnualReportMilestoneRoot(baseUrl).nested.GoalsAndMeasures.params({
      projectId,
      milestoneId,
      milestonePath,
    })

    if (measureId) {
      navigate(`${route}#${MEASURE_CHAR}${measureId}`, { state: { expandedEntityId: `${MEASURE_CHAR}${measureId}` } })
    } else {
      navigate(route)
    }
  }

  return (
    <BaseMeasureCreate
      baseUrl={baseUrl}
      helpText={`label.help.milestone.goals.and.measures.measures.${process}`}
      onBack={onBack}
      canEdit={canEdit}
      loading={loading}
      requiredRelatedGoals
    />
  )
}
