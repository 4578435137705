import { useContext, useMemo, useState } from 'react'
import { valueof } from 'src/@types/global'
import { Maybe, Project_Base_Bool_Exp, Query_Root } from 'src/@types/graphql'
import { PermissionService } from 'src/service/security/PermissionService'
import {
  fetchAssessorId,
  fetchDossierMetadataAndProjectUserRoles,
  fetchProgramCantonAndStatus,
} from 'src/service/security/PermissionServiceQueries'
import {
  CANTON_TYPE,
  DOSSIER_STATUS_TYPE,
  PROJECT_TYPE,
  PROJECT_USER_ROLE_TYPE,
  USER_ROLES_TYPE,
} from 'src/shared/constants/constants'
import { MILESTONE_RESPONSIBLE_TYPE_TYPE, MILESTONE_STATUS_TYPE } from 'src/shared/constants/milestone-constants'
import { UserContext } from 'src/user/UserContext'
import { useClient, useQuery } from 'urql'

const resolveProjectBaseQuery = (projectType: PROJECT_TYPE, entityId: number): Project_Base_Bool_Exp => {
  const projectPfKapBaseWhereClause: Project_Base_Bool_Exp = {
    pf_kap_projects: {
      id: {
        _eq: entityId,
      },
    },
  }

  const projectPfPgvBaseWhereClause: Project_Base_Bool_Exp = {
    pf_pgv_projects: {
      id: {
        _eq: entityId,
      },
    },
  }

  switch (projectType) {
    case 'PF_KAP':
      return projectPfKapBaseWhereClause
    case 'PF_PGV':
      return projectPfPgvBaseWhereClause
    default:
      throw Error(`Unknown project type ${projectType}`)
  }
}

interface ProjectBaseMetadata {
  projectBaseId: Maybe<number>
  dossierId?: Maybe<number>
  userProjectRoles: Array<valueof<PROJECT_USER_ROLE_TYPE>>
  userGlobalRoles: Array<USER_ROLES_TYPE>
}

interface PermissionResponse {
  loading: boolean
  canEdit: boolean
  canView: boolean
  metadata: ProjectBaseMetadata
  refetch: () => unknown
}

interface ProjectDetailsPermissionResponse extends PermissionResponse {
  canEditProjectBudgetApproved: boolean
  canEditCommentInSummary: boolean
  canModifyProjectUsers: boolean
  canTransitToApplication: boolean
  canDeleteProject: boolean
}

interface AdministrationPermissionResponse {
  canEdit: boolean
  canView: boolean
}

interface MilestonePermissionResponse {
  loading: boolean
  canView: boolean
  canEdit: boolean
  metadata: ProjectBaseMetadata
}

interface ApplicationDetailsPermissionResponse extends PermissionResponse {
  canViewSummaryTab: boolean
}

interface AssessmentPermissionResponse extends PermissionResponse {
  canAdd: boolean
  canDelete: boolean
}

type CommitteePermissionResponse = PermissionResponse

type ProjectImplementationResponse = PermissionResponse

interface ProgramDetailsPermissionResponse {
  loading: boolean
  canEdit: boolean
  canEditMeasures: boolean
  canEditApplication: boolean
  canCopyMeasures: boolean
  canViewCantonRestricted: boolean
  canView: boolean
  canManageMilestones: boolean
  canDeleteProgram: boolean
  isOwnCanton: boolean
  userGlobalRoles: Array<USER_ROLES_TYPE>
  refetch: () => unknown
}

interface ProgramMilestonesPermissionResponse {
  loading: boolean
  canEdit: boolean
  canView: boolean
}

interface FinalMilestonePermissionResponse extends MilestonePermissionResponse {
  canEditInternalAssessment: boolean
}

interface MilestoneConditionsPermissionResponse extends MilestonePermissionResponse {
  canUploadFiles: boolean
}

const useResolveProcessData = (projectType: PROJECT_TYPE, entityId: number) => {
  const { user } = useContext(UserContext)
  const email = user.email
  const projectBaseWhere = resolveProjectBaseQuery(projectType, entityId)
  const [{ data, fetching }, refetch] = useQuery<
    { project_base: Query_Root['project_base'] },
    { email: string; projectBaseWhere: Project_Base_Bool_Exp }
  >({
    query: fetchDossierMetadataAndProjectUserRoles,
    variables: {
      email,
      projectBaseWhere,
    },
  })

  const userGlobalRoles = user.roles

  const userId = user.id

  const userProjectRoles = useMemo(
    () => (data?.project_base[0]?.project_users?.map((u) => u.type) ?? []) as Array<valueof<PROJECT_USER_ROLE_TYPE>>,
    [data?.project_base],
  )

  return {
    data,
    userGlobalRoles,
    userProjectRoles,
    userId,
    fetching,
    refetch,
  }
}

const useProgramData = (programId: number) => {
  const { user } = useContext(UserContext)

  const [{ data, fetching }, refetch] = useQuery<{
    kap_program: Query_Root['kap_program']
  }>({
    query: fetchProgramCantonAndStatus,
    variables: {
      programId,
    },
  })

  const userGlobalRoles = user.roles
  const userCanton = user.cantonalUserCanton as CANTON_TYPE | null

  return {
    data,
    userGlobalRoles,
    userCanton,
    fetching,
    refetch,
  }
}

const useResolveAssessorId = async (assessmentId?: number) => {
  const urqlClient = useClient()

  const { data } = await urqlClient
    .query<
      {
        project_assessment: Query_Root['project_assessment']
      },
      { assessmentId?: number }
    >(fetchAssessorId, { assessmentId: assessmentId })
    .toPromise()

  return data?.project_assessment[0]?.assessor_id
}

export const usePermissionsForProjectCommittees = (
  projectType: PROJECT_TYPE,
  entityId: number,
): CommitteePermissionResponse => {
  const { data, userGlobalRoles, userProjectRoles, fetching, refetch } = useResolveProcessData(projectType, entityId)

  if (fetching) {
    return {
      loading: true,
      canView: false,
      canEdit: false,
      metadata: { projectBaseId: null, userProjectRoles: [], userGlobalRoles: [] },
      refetch: () => {},
    }
  }

  const dossierStatus = data?.project_base[0]?.dossier.status as DOSSIER_STATUS_TYPE

  const projectBaseId = data?.project_base[0]?.id as number

  const { canEdit, canView } = PermissionService.permissionsForProjectCommittees(
    userGlobalRoles,
    dossierStatus,
    projectType,
  )

  return {
    loading: false,
    canView,
    canEdit,
    metadata: {
      projectBaseId,
      userProjectRoles,
      userGlobalRoles,
    },
    refetch,
  }
}

export const usePermissionsForProjectAssessment = (
  projectType: PROJECT_TYPE,
  entityId: number,
  assessmentId?: number,
): AssessmentPermissionResponse => {
  const [assessorId, setAssessorId] = useState<number | undefined>()
  const [loading, setLoading] = useState<boolean>(true)

  const { data, userGlobalRoles, userProjectRoles, userId, fetching, refetch } = useResolveProcessData(
    projectType,
    entityId,
  )

  useResolveAssessorId(assessmentId)
    .then((value) => {
      setAssessorId(value)
    })
    .finally(() => {
      setLoading(false)
    })

  if (fetching || loading) {
    return {
      loading: true,
      canView: false,
      canEdit: false,
      canAdd: false,
      canDelete: false,
      metadata: { projectBaseId: null, userProjectRoles: [], userGlobalRoles: [] },
      refetch: () => {},
    }
  }

  const dossierStatus = data?.project_base[0]?.dossier.status as DOSSIER_STATUS_TYPE

  const projectBaseId = data?.project_base[0]?.id as number

  const userIsAssessor = assessorId === userId

  const { canEdit, canView, canAdd, canDelete } = PermissionService.permissionsForProjectAssessment(
    userGlobalRoles,
    userProjectRoles,
    dossierStatus,
    projectType,
    userIsAssessor,
  )

  return {
    loading: false,
    canView,
    canEdit,
    canAdd,
    canDelete,
    metadata: {
      projectBaseId,
      userProjectRoles,
      userGlobalRoles,
    },
    refetch,
  }
}

export const usePermissionsForProjectApplication = (
  projectType: PROJECT_TYPE,
  entityId: number,
): ApplicationDetailsPermissionResponse => {
  const { data, userGlobalRoles, userProjectRoles, fetching, refetch } = useResolveProcessData(projectType, entityId)

  if (fetching) {
    return {
      loading: true,
      canEdit: false,
      canView: false,
      canViewSummaryTab: false,
      metadata: { projectBaseId: null, userProjectRoles: [], userGlobalRoles: [] },
      refetch: () => {},
    }
  }

  const dossierStatus = data?.project_base[0]?.dossier.status as DOSSIER_STATUS_TYPE

  const projectBaseId = data?.project_base[0]?.id as number

  const { canEdit, canView, canViewSummaryTab } = PermissionService.permissionsForProjectApplication(
    userGlobalRoles,
    userProjectRoles,
    dossierStatus,
    projectType,
  )

  return {
    loading: false,
    canView,
    canEdit,
    canViewSummaryTab,
    metadata: {
      projectBaseId,
      userProjectRoles,
      userGlobalRoles,
    },
    refetch,
  }
}

export const usePermissionsForProject = (
  projectType: PROJECT_TYPE,
  entityId: number,
  existsActiveFundingRound = false,
): ProjectDetailsPermissionResponse => {
  const { data, userGlobalRoles, userProjectRoles, fetching, refetch } = useResolveProcessData(projectType, entityId)

  if (fetching) {
    return {
      loading: true,
      canEdit: false,
      canView: false,
      canEditProjectBudgetApproved: false,
      canEditCommentInSummary: false,
      canModifyProjectUsers: false,
      canTransitToApplication: false,
      canDeleteProject: false,
      metadata: { projectBaseId: null, userProjectRoles: [], userGlobalRoles: [] },
      refetch: () => {},
    }
  }

  const dossierStatus = data?.project_base[0]?.dossier?.status as DOSSIER_STATUS_TYPE

  const projectBaseId = data?.project_base[0]?.id as number
  const dossierId = data?.project_base[0]?.dossier.id as number

  const {
    canEdit,
    canView,
    canEditProjectBudgetApproved,
    canEditCommentInSummary,
    canModifyProjectUsers,
    canTransitToApplication,
    canDeleteProject,
  } = PermissionService.permissionsForProjectDescription(
    userGlobalRoles,
    userProjectRoles,
    dossierStatus,
    projectType,
    existsActiveFundingRound,
  )

  return {
    loading: false,
    canView,
    canEdit,
    canEditProjectBudgetApproved,
    canEditCommentInSummary,
    canModifyProjectUsers,
    canTransitToApplication,
    canDeleteProject,
    metadata: {
      projectBaseId,
      dossierId,
      userProjectRoles,
      userGlobalRoles,
    },
    refetch,
  }
}

export const usePermissionsForUserAdministration = (): AdministrationPermissionResponse => {
  const { user } = useContext(UserContext)
  const userGlobalRoles = user.roles

  const { canView, canEdit } = PermissionService.permissionToAdministrateUsers(userGlobalRoles)

  return {
    canView: canView,
    canEdit: canEdit,
  }
}

export const usePermissionsForRoundManagementAdministration = (): AdministrationPermissionResponse => {
  const { user } = useContext(UserContext)
  const userGlobalRoles = user.roles

  const { canView, canEdit } = PermissionService.permissionToAdministrateFundingRounds(userGlobalRoles)

  return {
    canView: canView,
    canEdit: canEdit,
  }
}

export const usePermissionsForFactsheets = (): {
  canEdit: boolean
  canViewFactsheetStatus: boolean
  canDownloadFactsheetsReport: boolean
} => {
  const { user } = useContext(UserContext)
  const userGlobalRoles = user.roles

  const { canEdit, canViewFactsheetStatus, canDownloadFactsheetsReport } =
    PermissionService.permissionToFactsheets(userGlobalRoles)
  return {
    canEdit,
    canViewFactsheetStatus,
    canDownloadFactsheetsReport,
  }
}

export const usePermissionsForProjectImplementation = (
  projectType: PROJECT_TYPE,
  entityId: number,
): ProjectImplementationResponse & { canManageMilestones: boolean } => {
  const { data, userGlobalRoles, userProjectRoles, fetching, refetch } = useResolveProcessData(projectType, entityId)

  if (fetching) {
    return {
      loading: true,
      canEdit: false,
      canView: false,
      canManageMilestones: false,
      metadata: { projectBaseId: null, userProjectRoles: [], userGlobalRoles: [] },
      refetch: () => {},
    }
  }

  const dossierStatus = data?.project_base[0]?.dossier.status as DOSSIER_STATUS_TYPE

  const projectBaseId = data?.project_base[0]?.id as number

  const { canEdit, canView, canManageMilestones } = PermissionService.permissionsForProjectImplementation(
    userGlobalRoles,
    userProjectRoles,
    dossierStatus,
    projectType,
  )

  return {
    loading: false,
    canView,
    canEdit,
    canManageMilestones,
    metadata: { projectBaseId, userProjectRoles, userGlobalRoles },
    refetch,
  }
}

export const usePermissionsForMilestones = (
  projectType: PROJECT_TYPE,
  entityId: number,
  milestoneStatus: MILESTONE_STATUS_TYPE,
  milestoneType: MILESTONE_RESPONSIBLE_TYPE_TYPE,
): MilestonePermissionResponse => {
  const { data, userGlobalRoles, userProjectRoles, fetching } = useResolveProcessData(projectType, entityId)

  if (fetching) {
    return {
      loading: true,
      canView: false,
      canEdit: false,
      metadata: { projectBaseId: null, userProjectRoles: [], userGlobalRoles: [] },
    }
  }

  const dossierStatus = data?.project_base[0]?.dossier.status as DOSSIER_STATUS_TYPE

  const projectBaseId = data?.project_base[0]?.id as number

  const { canView, canEdit } = PermissionService.permissionsForMilestones(
    userGlobalRoles,
    userProjectRoles,
    dossierStatus,
    projectType,
    milestoneStatus,
    milestoneType,
  )

  return {
    loading: false,
    canView: canView,
    canEdit: canEdit,
    metadata: { projectBaseId, userProjectRoles, userGlobalRoles },
  }
}

export const usePermissionsForKapProgram = (programId: number): ProgramDetailsPermissionResponse => {
  const { userGlobalRoles, userCanton, data, fetching, refetch } = useProgramData(programId)

  if (fetching) {
    return {
      loading: true,
      canEdit: false,
      canEditMeasures: false,
      canEditApplication: false,
      canCopyMeasures: false,
      canViewCantonRestricted: false,
      canView: false,
      canManageMilestones: false,
      canDeleteProgram: false,
      isOwnCanton: false,
      userGlobalRoles: [],
      refetch: () => {},
    }
  }

  const dossierStatus = data?.kap_program?.[0]?.dossier?.status as DOSSIER_STATUS_TYPE
  const canton = data?.kap_program?.[0]?.canton_code as CANTON_TYPE
  const isOwnCanton = userCanton === canton

  const {
    canEdit,
    canEditMeasures,
    canEditApplication,
    canCopyMeasures,
    canViewCantonRestricted,
    canView,
    canManageMilestones,
    canDeleteProgram,
  } = PermissionService.permissionsForKapProgramDescription(userGlobalRoles, dossierStatus, isOwnCanton)

  return {
    loading: false,
    canView,
    canEdit,
    canEditMeasures,
    canEditApplication,
    canCopyMeasures,
    canViewCantonRestricted,
    canManageMilestones,
    canDeleteProgram,
    userGlobalRoles,
    isOwnCanton,
    refetch,
  }
}

export const usePermissionsForKapMilestones = (
  programId: number,
  milestoneStatus: MILESTONE_STATUS_TYPE,
  milestoneType: MILESTONE_RESPONSIBLE_TYPE_TYPE,
): ProgramMilestonesPermissionResponse => {
  const { data, userGlobalRoles, userCanton, fetching } = useProgramData(programId)

  if (fetching) {
    return {
      loading: true,
      canView: false,
      canEdit: false,
    }
  }

  const dossierStatus = data?.kap_program?.[0]?.dossier?.status as DOSSIER_STATUS_TYPE
  const canton = data?.kap_program?.[0]?.canton_code as CANTON_TYPE
  const isOwnCanton = userCanton === canton

  const { canView, canEdit } = PermissionService.permissionsForKapMilestones(
    userGlobalRoles,
    dossierStatus,
    milestoneStatus,
    milestoneType,
    isOwnCanton,
  )

  return {
    loading: false,
    canView: canView,
    canEdit: canEdit,
  }
}

export const usePermissionsForFinalMilestone = (
  projectType: PROJECT_TYPE,
  entityId: number,
  milestoneStatus: MILESTONE_STATUS_TYPE,
  milestoneType: MILESTONE_RESPONSIBLE_TYPE_TYPE,
): FinalMilestonePermissionResponse => {
  const { userGlobalRoles, fetching } = useResolveProcessData(projectType, entityId)

  const { canView, canEdit, metadata } = usePermissionsForMilestones(
    projectType,
    entityId,
    milestoneStatus,
    milestoneType,
  )

  if (fetching) {
    return {
      loading: true,
      canView: false,
      canEdit: false,
      canEditInternalAssessment: false,
      metadata: { projectBaseId: null, userProjectRoles: [], userGlobalRoles: [] },
    }
  }

  const { canEditInternalAssessment } = PermissionService.permissionsForFinalMilestoneInternalAssessment(
    userGlobalRoles,
    projectType,
  )

  return {
    loading: false,
    canView,
    canEdit,
    canEditInternalAssessment: canEdit && canEditInternalAssessment,
    metadata,
  }
}

export const usePermissionsForMilestoneConditions = (
  projectType: PROJECT_TYPE,
  entityId: number,
  milestoneStatus: MILESTONE_STATUS_TYPE,
  milestoneType: MILESTONE_RESPONSIBLE_TYPE_TYPE,
): MilestoneConditionsPermissionResponse => {
  const { data, userGlobalRoles, fetching } = useResolveProcessData(projectType, entityId)

  const dossierStatus = data?.project_base[0]?.dossier.status as DOSSIER_STATUS_TYPE

  const {
    canView,
    metadata,
    canEdit: canEditMilestones,
  } = usePermissionsForMilestones(projectType, entityId, milestoneStatus, milestoneType)

  if (fetching) {
    return {
      loading: true,
      canView: false,
      canEdit: false,
      canUploadFiles: false,
      metadata: { projectBaseId: null, userProjectRoles: [], userGlobalRoles: [] },
    }
  }

  const canEdit = PermissionService.permissionToEditProjectMilestoneConditions(
    projectType,
    userGlobalRoles,
    dossierStatus,
    milestoneStatus,
  )

  return {
    loading: false,
    canView: canView,
    canEdit: canEdit,
    canUploadFiles: canEdit || canEditMilestones,
    metadata,
  }
}

export const usePermissionsForSuccessFactors = (): {
  canView: boolean
  canEdit: boolean
} => {
  const { user } = useContext(UserContext)
  const userGlobalRoles = user.roles
  const { canView, canEdit } = PermissionService.permissionsForSuccessFactors(userGlobalRoles)

  return {
    canView,
    canEdit,
  }
}
