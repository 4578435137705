import { Card, CardContent, Stack } from '@mui/material'
import { get } from 'lodash'
import { ReactElement, useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { Kap_Program, Query_Root } from 'src/@types/graphql'
import { useMessageSource } from 'src/i18n/useMessageSource'
import { ROUTES } from 'src/routing/routes'
import { fetchKapStartingPointAndVision } from 'src/screens/kap/program/details/kapProgramQueries'
import { KapStartingPointAndVisionFields } from 'src/screens/kap/program/details/starting-point-and-vision/edit/KapStartingPointAndVisionEditPage'
import { usePermissionsForKapProgram } from 'src/service/security/PermissionHook'
import { EditButton } from 'src/shared/button/Buttons'
import { INITIAL_NATIONAL_SITUATION, MODULE_TYPES } from 'src/shared/constants/constants'
import { PageLayout } from 'src/shared/layout/PageLayout'
import { ScreenLayout } from 'src/shared/layout/ScreenLayout'
import { ProgramExportMenu } from 'src/shared/menu/ProgramExportMenu'
import { HelpAndInstructions } from 'src/shared/presentation/HelpAndInstructions'
import { HtmlRenderer } from 'src/shared/presentation/HtmlRenderer'
import { InitialSituationCard } from 'src/shared/presentation/InitialSituationCard'
import { Section } from 'src/shared/presentation/Section'
import { useDelayedNavigate } from 'src/shared/utils/hooks/navigation-hooks'
import { useNotificationService } from 'src/shared/utils/NotificationService'
import { useUserLocale } from 'src/user/UserContext'
import { useClient } from 'urql'
import { Utils } from 'src/shared/utils/Utils'

export const KapStartingPointAndVisionPage = (): ReactElement => {
  const { programId } = useParams()
  const program_id = parseInt(programId as string)
  const navigate = useDelayedNavigate()
  const { getMessage } = useMessageSource()
  const urqlClient = useClient()
  const notificationService = useNotificationService()
  const locale = useUserLocale()

  const { canEdit, loading, canViewCantonRestricted } = usePermissionsForKapProgram(program_id)

  const [kapProgram, setKapProgram] = useState<Kap_Program | undefined>(undefined)
  const [initialNationalSituation, setInitialNationalSituation] = useState<INITIAL_NATIONAL_SITUATION>()

  useEffect(() => {
    const initData = async () => {
      const { data } = await urqlClient
        .query<
          {
            kap_program_by_pk: Query_Root['kap_program_by_pk']
            initial_national_situation: Query_Root['initial_national_situation']
          },
          {
            id: number
            fetchNationalSituation: boolean
          }
        >(fetchKapStartingPointAndVision, { id: program_id, fetchNationalSituation: true })
        .toPromise()

      const kapProgram = data?.kap_program_by_pk
      const initialNationalSituation = data?.initial_national_situation?.[0].value

      if (kapProgram && initialNationalSituation) {
        setKapProgram(kapProgram)
        setInitialNationalSituation(initialNationalSituation)
      } else {
        notificationService.operationFailed()
      }
    }

    initData()
  }, [urqlClient, notificationService, program_id])

  const handleEdit = (field: KapStartingPointAndVisionFields) => () => {
    const route = ROUTES.KapDetailsRoot.nested.StartingPointAndVision.params({ programId })
    navigate(`${route}/${field.replaceAll('_', '-')}/edit`)
  }

  return (
    <ScreenLayout
      title={getMessage('label.program.description')}
      actions={<>{canViewCantonRestricted && <ProgramExportMenu />}</>}
    >
      <PageLayout>
        <>
          {!loading && kapProgram && initialNationalSituation && (
            <>
              <Section
                id="initial-national-situation"
                title={getMessage('label.initial.national.situation')}
                helpAndInstructions={
                  <HelpAndInstructions labelKey="label.help.and.instructions.initial.national.situation" />
                }
              >
                <Stack spacing={1}>
                  {Utils.sortModules(kapProgram.modules).map((m: MODULE_TYPES) => {
                    const module = {
                      character: m,
                      value: get(initialNationalSituation.modules[m], locale, ''),
                    }
                    return <InitialSituationCard key={m} module={module} />
                  })}
                </Stack>
              </Section>
              <Section
                id="initial-cantonal-situation"
                title={getMessage('label.initial.cantonal.situation')}
                actionButton={<EditButton onClick={handleEdit('initial_cantonal_situation')} hidden={!canEdit} />}
                helpAndInstructions={
                  <HelpAndInstructions labelKey="label.help.and.instructions.initial.cantonal.situation.kap" />
                }
              >
                {kapProgram.initial_cantonal_situation && (
                  <Card>
                    <CardContent>
                      <HtmlRenderer html={kapProgram.initial_cantonal_situation} />
                    </CardContent>
                  </Card>
                )}
              </Section>

              <Section
                id="program-justification"
                title={getMessage('label.program.justification')}
                actionButton={<EditButton onClick={handleEdit('program_justification')} hidden={!canEdit} />}
                helpAndInstructions={
                  <HelpAndInstructions labelKey="label.help.and.instructions.program.justification.kap" />
                }
              >
                {kapProgram.program_justification && (
                  <Card>
                    <CardContent>
                      <HtmlRenderer html={kapProgram.program_justification} />
                    </CardContent>
                  </Card>
                )}
              </Section>

              <Section
                id="sources"
                title={getMessage('label.sources')}
                actionButton={<EditButton onClick={handleEdit('sources')} hidden={!canEdit} />}
                helpAndInstructions={<HelpAndInstructions labelKey="label.help.and.instructions.sources.kap" />}
                optional
              >
                {kapProgram.sources && (
                  <Card>
                    <CardContent>
                      <HtmlRenderer html={kapProgram.sources} />
                    </CardContent>
                  </Card>
                )}
              </Section>

              <Section
                id="vision"
                title={getMessage('label.vision')}
                actionButton={<EditButton onClick={handleEdit('vision')} hidden={!canEdit} />}
                helpAndInstructions={<HelpAndInstructions labelKey="label.help.and.instructions.vision.kap" />}
              >
                {kapProgram.vision && (
                  <Card>
                    <CardContent>
                      <HtmlRenderer html={kapProgram.vision} />
                    </CardContent>
                  </Card>
                )}
              </Section>
            </>
          )}
        </>
      </PageLayout>
    </ScreenLayout>
  )
}
