import { gql } from '@urql/core'

export const fetchDossierMetadataAndProjectUserRoles = gql`
  query FetchDossierMetadataAndProjectUserRoles($projectBaseWhere: project_base_bool_exp!, $email: String!) {
    project_base(where: $projectBaseWhere) {
      id
      dossier {
        id
        status
      }
      project_users(where: { user: { email: { _eq: $email } } }) {
        type
      }
    }
  }
`

export const fetchProgramCantonAndStatus = gql`
  query fetchProgramCantonAndStatus($programId: Int!) {
    kap_program(where: { id: { _eq: $programId } }) {
      canton_code
      dossier {
        status
      }
    }
  }
`

export const fetchAssessorId = gql`
  query FetchAssessorId($assessmentId: Int!) {
    project_assessment(where: { id: { _eq: $assessmentId } }) {
      assessor_id
    }
  }
`
