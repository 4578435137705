import { Card, CardContent } from '@mui/material'
import combinedQuery, { CombinedQueryBuilder } from 'graphql-combine-query'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { Maybe, Milestone_Qualitative_Evaluation, Query_Root } from 'src/@types/graphql'
import { useMessageSource } from 'src/i18n/useMessageSource'
import { resolveFinalReportMilestoneRoot, resolveImplementationDetailsRoot } from 'src/routing/routing-utils'
import {
  fetchQualitativeEvaluationByMilestoneQuery,
  queryMilestoneById,
} from 'src/screens/shared/implementation/details/milestoneQueries'
import { usePermissionsForMilestones } from 'src/service/security/PermissionHook'
import { EditButton } from 'src/shared/button/Buttons'
import {
  MILESTONE_RESPONSIBLE_TYPE_TYPE,
  MILESTONE_STATUS_TYPE,
  MILESTONE_TYPE,
} from 'src/shared/constants/milestone-constants'
import { PageLayout } from 'src/shared/layout/PageLayout'
import { ScreenLayout } from 'src/shared/layout/ScreenLayout'
import { HelpAndInstructions } from 'src/shared/presentation/HelpAndInstructions'
import { HtmlRenderer } from 'src/shared/presentation/HtmlRenderer'
import { ReadOnlyTextField } from 'src/shared/presentation/ReadOnly'
import { Section } from 'src/shared/presentation/Section'
import { useDelayedNavigate } from 'src/shared/utils/hooks/navigation-hooks'
import { useNotificationService } from 'src/shared/utils/NotificationService'
import { Utils } from 'src/shared/utils/Utils'
import { useClient } from 'urql'

interface Props {
  baseUrl: '/pf-kap' | '/pf-pgv'
}

export const FinalReportQualitativeEvaluationPage = ({ baseUrl }: Props) => {
  const { projectId, milestoneId } = useParams()
  const project_id = parseInt(projectId as string)
  const milestone_id = parseInt(milestoneId as string)

  const notificationService = useNotificationService()
  const navigate = useDelayedNavigate()
  const urqlClient = useClient()
  const process = Utils.resolveProcess(baseUrl)

  const { getMessage } = useMessageSource()

  const [qualitativeEvaluation, setQualitativeEvaluation] = useState<
    Maybe<Milestone_Qualitative_Evaluation> | undefined
  >(null)
  const [milestoneData, setMilestoneData] = useState<{
    status: MILESTONE_STATUS_TYPE
    responsible_type: MILESTONE_RESPONSIBLE_TYPE_TYPE
  }>()

  useEffect(() => {
    const fetchData = async () => {
      let queryCombined = combinedQuery('FetchMilestoneAndQualitativeEvaluation') as unknown as CombinedQueryBuilder
      queryCombined = queryCombined
        .add<
          { milestone_by_pk: Query_Root['milestone_by_pk'] },
          {
            id: number
          }
        >(queryMilestoneById, {
          id: milestone_id,
        })
        .add<
          { milestone_qualitative_evaluation: Query_Root['milestone_qualitative_evaluation'] },
          {
            milestoneId: number
          }
        >(fetchQualitativeEvaluationByMilestoneQuery, {
          milestoneId: milestone_id,
        })

      const { document, variables } = queryCombined

      const { data, error } = await urqlClient
        .query<{
          milestone_by_pk: Query_Root['milestone_by_pk']
          milestone_qualitative_evaluation: Query_Root['milestone_qualitative_evaluation']
        }>(document, variables)
        .toPromise()

      if (error) {
        notificationService.operationFailed()
      }

      setQualitativeEvaluation(data?.milestone_qualitative_evaluation[0])
      setMilestoneData({
        status: data?.milestone_by_pk?.status as MILESTONE_STATUS_TYPE,
        responsible_type: data?.milestone_by_pk?.responsible_type as MILESTONE_RESPONSIBLE_TYPE_TYPE,
      })
    }

    fetchData()
  }, [urqlClient, notificationService, milestone_id])

  const { loading, canEdit } = usePermissionsForMilestones(
    process,
    project_id,
    milestoneData?.status as MILESTONE_STATUS_TYPE,
    milestoneData?.responsible_type as MILESTONE_RESPONSIBLE_TYPE_TYPE,
  )

  const onBackHandler = () => {
    navigate(resolveImplementationDetailsRoot(baseUrl).params({ projectId }))
  }

  const navigationParameters = {
    projectId: projectId,
    milestoneId: milestoneId,
    milestonePath: Utils.resolveMilestonePath(MILESTONE_TYPE.FINAL_REPORT),
  }

  const handleEditSwotSection = () => {
    navigate(
      resolveFinalReportMilestoneRoot(baseUrl).nested.QualitativeEvaluationSWOTAnalysisEdit.params(
        navigationParameters,
      ),
    )
  }
  const handleEditEqualOpportunity = () => {
    navigate(
      resolveFinalReportMilestoneRoot(baseUrl).nested.QualitativeEvaluationEqualOpportunityEdit.params(
        navigationParameters,
      ),
    )
  }
  const handleEditSustainability = () => {
    navigate(
      resolveFinalReportMilestoneRoot(baseUrl).nested.QualitativeEvaluationSustainabilityEdit.params(
        navigationParameters,
      ),
    )
  }
  const handleEditMultiplicationPotential = () => {
    navigate(
      resolveFinalReportMilestoneRoot(baseUrl).nested.QualitativeEvaluationMultiplicationPotentialEdit.params(
        navigationParameters,
      ),
    )
  }

  return (
    <ScreenLayout title={getMessage(`label.milestone.type.${MILESTONE_TYPE.FINAL_REPORT}`)} onBack={onBackHandler}>
      {!loading && qualitativeEvaluation !== null && (
        <PageLayout>
          <Section
            id="swot-analysis"
            title={getMessage('label.milestone.qualitative.evaluation.swot.analysis')}
            actionButton={
              <EditButton origin="content" color="primary" hidden={!canEdit} onClick={handleEditSwotSection} />
            }
            helpAndInstructions={
              <HelpAndInstructions
                labelKey={`label.help.qualitative.evaluation.swot.analysis.${process.toLowerCase()}`}
              />
            }
          >
            <Card>
              <CardContent>
                <ReadOnlyTextField text={getMessage('label.milestone.qualitative.evaluation.strength')}>
                  {qualitativeEvaluation?.strength && <HtmlRenderer html={qualitativeEvaluation?.strength} />}
                </ReadOnlyTextField>
                <ReadOnlyTextField text={getMessage('label.milestone.qualitative.evaluation.weakness')}>
                  {qualitativeEvaluation?.weakness && <HtmlRenderer html={qualitativeEvaluation?.weakness} />}
                </ReadOnlyTextField>
                <ReadOnlyTextField text={getMessage('label.milestone.qualitative.evaluation.opportunities')}>
                  {qualitativeEvaluation?.opportunities && <HtmlRenderer html={qualitativeEvaluation?.opportunities} />}
                </ReadOnlyTextField>
                <ReadOnlyTextField text={getMessage('label.milestone.qualitative.evaluation.threats')} isLast>
                  {qualitativeEvaluation?.threats && <HtmlRenderer html={qualitativeEvaluation?.threats} />}
                </ReadOnlyTextField>
              </CardContent>
            </Card>
          </Section>
          <Section
            id="equal-opportunity"
            title={getMessage('label.background.information.equal.opportunity')}
            actionButton={
              <EditButton origin="content" color="primary" hidden={!canEdit} onClick={handleEditEqualOpportunity} />
            }
            helpAndInstructions={
              <HelpAndInstructions
                labelKey={`label.help.qualitative.evaluation.equal.opportunity.${process.toLowerCase()}`}
              />
            }
          >
            {qualitativeEvaluation?.equal_opportunity && (
              <Card>
                <CardContent>
                  <HtmlRenderer html={qualitativeEvaluation?.equal_opportunity} />
                </CardContent>
              </Card>
            )}
          </Section>
          <Section
            id="sustainability"
            title={getMessage('label.background.information.sustainability')}
            actionButton={
              <EditButton origin="content" color="primary" hidden={!canEdit} onClick={handleEditSustainability} />
            }
            helpAndInstructions={
              <HelpAndInstructions
                labelKey={`label.help.qualitative.evaluation.sustainability.${process.toLowerCase()}`}
              />
            }
          >
            {qualitativeEvaluation?.sustainability && (
              <Card>
                <CardContent>
                  <HtmlRenderer html={qualitativeEvaluation?.sustainability} />
                </CardContent>
              </Card>
            )}
          </Section>
          <Section
            id="multiplication-potential"
            title={getMessage('label.background.information.multiplication.potential')}
            actionButton={
              <EditButton
                origin="content"
                color="primary"
                hidden={!canEdit}
                onClick={handleEditMultiplicationPotential}
              />
            }
            helpAndInstructions={
              <HelpAndInstructions
                labelKey={`label.help.qualitative.evaluation.multiplication.potential.${process.toLowerCase()}`}
              />
            }
          >
            {qualitativeEvaluation?.multiplication_potential && (
              <Card>
                <CardContent>
                  <HtmlRenderer html={qualitativeEvaluation?.multiplication_potential} />
                </CardContent>
              </Card>
            )}
          </Section>
        </PageLayout>
      )}
    </ScreenLayout>
  )
}
