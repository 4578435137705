import { Box, IconButton, Typography } from '@mui/material'
import { ReactElement, useEffect, useState } from 'react'
import { useLocation } from 'react-router'
import { Subprinciple_Config } from 'src/@types/graphql'
import { useMessageSource } from 'src/i18n/useMessageSource'
import { PrinciplesPhaseType } from 'src/screens/shared/principles/PrinciplesUtils'
import { DOSSIER_STATUS } from 'src/shared/constants/constants'
import { EditIcon } from 'src/shared/icons/Icons'
import { DefaultSectionTypography } from 'src/shared/presentation/DefaultSectionTypography'
import { ExpandableCard } from 'src/shared/presentation/ExpandableCard'
import { HtmlRenderer } from 'src/shared/presentation/HtmlRenderer'
import { ReadOnlyTextField } from 'src/shared/presentation/ReadOnly'
import { S } from 'src/shared/styled/S'
import { useHashScroll } from 'src/shared/utils/hooks/navigation-hooks'
import { HtmlSanitizer } from 'src/shared/utils/HtmlSanitizer'
import styled from 'styled-components/macro'

interface Props {
  subprinciple: Subprinciple_Config
  phase: PrinciplesPhaseType
  onEdit: () => void
  canEdit: boolean
}

const HeaderStyled = styled(Box)`
  display: flex;
  align-items: center;
  height: 100%;
  padding-inline: ${({ theme }) => theme.spacing(2)};
  gap: ${({ theme }) => theme.spacing(2)};
`

export const ExpandableSubprincipleCard = ({ subprinciple, phase, onEdit, canEdit }: Props): ReactElement => {
  const { getMessage } = useMessageSource()
  const { state } = useLocation()

  const [expanded, setExpanded] = useState(false)

  const expandedSubprincipleId = state?.expandedSubprincipleId

  const kapProgramSubprinciple = subprinciple.kap_program_subprinciples?.[0]
  const conceptComment = kapProgramSubprinciple?.concept_comment
  const implementationComment = kapProgramSubprinciple?.implementation_comment
  const conceptPhase = phase === DOSSIER_STATUS.CONCEPT
  const implementationPhase = phase === DOSSIER_STATUS.IMPLEMENTATION

  useEffect(() => {
    const shouldExpand = parseInt(expandedSubprincipleId as string) === subprinciple.id
    setExpanded(shouldExpand)
  }, [subprinciple, expandedSubprincipleId])

  const validateSubprinciple = (): boolean => {
    if (conceptPhase) {
      return !!conceptComment
    } else if (implementationPhase) {
      return !!implementationComment
    }
    return false
  }

  const cardId = subprinciple.code.replaceAll('_', '-')
  useHashScroll(cardId, true)

  return (
    <ExpandableCard
      id={cardId}
      header={
        <HeaderStyled>
          <S.Icons.Validation $valid={validateSubprinciple()} />
          <Typography variant="subtitle2">{subprinciple.names}</Typography>
        </HeaderStyled>
      }
      content={
        <>
          <ReadOnlyTextField text={getMessage('label.description')}>
            <HtmlRenderer html={HtmlSanitizer.resolveConfigTextWithBullets(subprinciple.descriptions)} />
          </ReadOnlyTextField>
          <ReadOnlyTextField text={getMessage('label.concept.phase.comment')}>
            {conceptComment ? (
              <HtmlRenderer html={conceptComment} />
            ) : (
              implementationPhase && (
                <DefaultSectionTypography noEntriesMessageKey={getMessage('label.not.available')} />
              )
            )}
          </ReadOnlyTextField>
          <ReadOnlyTextField text={getMessage('label.implementation.phase.comment')} isLast>
            {implementationComment ? (
              <HtmlRenderer html={implementationComment} />
            ) : (
              conceptPhase && <DefaultSectionTypography noEntriesMessageKey={getMessage('label.not.available')} />
            )}
          </ReadOnlyTextField>
        </>
      }
      actions={
        canEdit && (
          <IconButton color="primary" onClick={onEdit}>
            <EditIcon />
          </IconButton>
        )
      }
      localExpansion={expanded}
    />
  )
}
