import { Box, Card, CardContent, Typography } from '@mui/material'
import { get } from 'lodash'
import { ReactElement } from 'react'
import { useParams } from 'react-router'
import { Query_Root } from 'src/@types/graphql'
import { useMessageSource } from 'src/i18n/useMessageSource'
import { ROUTES } from 'src/routing/routes'
import { fetchFactsheetByIdQuery } from 'src/screens/factsheets/factsheet/factsheetQueries'
import { usePermissionsForFactsheets } from 'src/service/security/PermissionHook'
import { EditButton } from 'src/shared/button/Buttons'
import { FACTSHEET_DESCRIPTION_EDIT_OPTION } from 'src/shared/constants/factsheet-constants'
import { PageLayout } from 'src/shared/layout/PageLayout'
import { ScreenLayout } from 'src/shared/layout/ScreenLayout'
import { FactsheetExportMenu } from 'src/shared/menu/FactsheetExportMenu'
import { HelpAndInstructions } from 'src/shared/presentation/HelpAndInstructions'
import { HtmlRenderer } from 'src/shared/presentation/HtmlRenderer'
import { ReadOnlySelection, ReadOnlyTextField } from 'src/shared/presentation/ReadOnly'
import { Section } from 'src/shared/presentation/Section'
import { useDelayedNavigate } from 'src/shared/utils/hooks/navigation-hooks'
import { useNotificationService } from 'src/shared/utils/NotificationService'
import { useUserLocale } from 'src/user/UserContext'
import { useQuery } from 'urql'

export const FactsheetDescriptionPage = (): ReactElement => {
  const { factsheetId } = useParams()
  const { getMessage } = useMessageSource()

  const notificationService = useNotificationService()
  const language = useUserLocale()
  const navigate = useDelayedNavigate()

  const [{ data, error, fetching }] = useQuery<{
    factsheet_by_pk: Query_Root['factsheet_by_pk']
  }>({
    query: fetchFactsheetByIdQuery,
    variables: { id: parseInt(factsheetId as string) },
  })

  if (error) {
    notificationService.operationFailed()
  }

  const factsheet = data?.factsheet_by_pk

  const getFactsheetAttributeByLanguage = (attribute: any): any => {
    return attribute?.[language]
  }

  const handleEditDescription = (field: string) => {
    const route = ROUTES.FactsheetDescriptionRoot.params({ factsheetId })
    navigate(`${route}/${field}/edit`)
  }

  const { canEdit } = usePermissionsForFactsheets()

  const renderLanguageAttribute = (field: string) => {
    return (
      getFactsheetAttributeByLanguage(field) && (
        <Card>
          <CardContent>
            <HtmlRenderer html={getFactsheetAttributeByLanguage(field)} />
          </CardContent>
        </Card>
      )
    )
  }

  return (
    <ScreenLayout
      title={get(factsheet?.title, language, '')}
      hasSecondLevelNavigation={true}
      actions={<FactsheetExportMenu />}
    >
      <PageLayout>
        <>
          {!fetching && factsheet && (
            <>
              <Section
                id="factsheet-basic-info"
                title={getMessage('label.factsheet.basic.information')}
                actionButton={
                  <EditButton
                    origin="content"
                    color="primary"
                    onClick={() => handleEditDescription(FACTSHEET_DESCRIPTION_EDIT_OPTION.BASIC_INFO)}
                    hidden={!canEdit}
                  />
                }
                helpAndInstructions={<HelpAndInstructions labelKey="label.help.factsheet.basic.information" />}
              >
                <Card>
                  <CardContent>
                    <Box>
                      <ReadOnlyTextField text={getMessage('label.factsheet.id')}>
                        {`${getMessage('label.factsheet.id.character')}${factsheet.id}`}
                      </ReadOnlyTextField>
                      <ReadOnlySelection text={getMessage('label.factsheet.type')}>
                        {getMessage(`label.factsheet.type.${factsheet.factsheet_type}`)}
                      </ReadOnlySelection>
                      <ReadOnlyTextField text={getMessage('label.level')}>
                        {getMessage(`label.level.${factsheet.level}`)}
                      </ReadOnlyTextField>
                      <ReadOnlySelection text={getMessage('label.modules')}>
                        {factsheet.modules &&
                          factsheet?.modules.map((module: any) => (
                            <Typography key={module}>{getMessage(`label.module.description.${module}`)}</Typography>
                          ))}
                      </ReadOnlySelection>
                      <ReadOnlyTextField text={getMessage('label.status')}>
                        {getMessage(`label.factsheet.status.${factsheet.status}`)}
                      </ReadOnlyTextField>
                      <ReadOnlyTextField text={getMessage('label.organization')}>
                        {factsheet.organization}
                      </ReadOnlyTextField>
                      <ReadOnlyTextField text={getMessage('label.contact')} isLast>
                        {factsheet?.contact && <HtmlRenderer html={factsheet?.contact} />}
                      </ReadOnlyTextField>
                    </Box>
                  </CardContent>
                </Card>
              </Section>
              <Section
                id={FACTSHEET_DESCRIPTION_EDIT_OPTION.TITLE_AND_DESCRIPTION}
                title={getMessage('label.title.and.description')}
                actionButton={
                  <EditButton
                    origin="content"
                    color="primary"
                    onClick={() => handleEditDescription(FACTSHEET_DESCRIPTION_EDIT_OPTION.TITLE_AND_DESCRIPTION)}
                    hidden={!canEdit}
                  />
                }
                helpAndInstructions={<HelpAndInstructions labelKey="label.help.factsheet.title_and_description" />}
              >
                <Card>
                  <CardContent>
                    <ReadOnlyTextField text={getMessage('label.title')}>
                      {getFactsheetAttributeByLanguage(factsheet.title)}
                    </ReadOnlyTextField>
                    <ReadOnlyTextField text={getMessage('label.description')} isLast>
                      {getFactsheetAttributeByLanguage(factsheet.description) && (
                        <HtmlRenderer html={getFactsheetAttributeByLanguage(factsheet.description)} />
                      )}
                    </ReadOnlyTextField>
                  </CardContent>
                </Card>
              </Section>
              <Section
                id={FACTSHEET_DESCRIPTION_EDIT_OPTION.IMPORTANT_INSIGHTS}
                title={getMessage('label.factsheet.important_insights')}
                actionButton={
                  <EditButton
                    origin="content"
                    color="primary"
                    onClick={() => handleEditDescription(FACTSHEET_DESCRIPTION_EDIT_OPTION.IMPORTANT_INSIGHTS)}
                    hidden={!canEdit}
                  />
                }
                helpAndInstructions={<HelpAndInstructions labelKey="label.help.factsheet.important_insights" />}
              >
                {renderLanguageAttribute(factsheet.important_insights)}
              </Section>
              <Section
                id={FACTSHEET_DESCRIPTION_EDIT_OPTION.STRENGTH}
                title={getMessage('label.factsheet.strength')}
                actionButton={
                  <EditButton
                    origin="content"
                    color="primary"
                    onClick={() => handleEditDescription(FACTSHEET_DESCRIPTION_EDIT_OPTION.STRENGTH)}
                    hidden={!canEdit}
                  />
                }
                helpAndInstructions={<HelpAndInstructions labelKey="label.help.factsheet.strength" />}
              >
                {renderLanguageAttribute(factsheet.strength)}
              </Section>
              <Section
                id={FACTSHEET_DESCRIPTION_EDIT_OPTION.FURTHER_INFORMATION}
                title={getMessage('label.factsheet.further_information')}
                actionButton={
                  <EditButton
                    origin="content"
                    color="primary"
                    onClick={() => handleEditDescription(FACTSHEET_DESCRIPTION_EDIT_OPTION.FURTHER_INFORMATION)}
                    hidden={!canEdit}
                  />
                }
                helpAndInstructions={<HelpAndInstructions labelKey="label.help.factsheet.further_information" />}
              >
                {renderLanguageAttribute(factsheet.further_information)}
              </Section>
              <Section
                id={FACTSHEET_DESCRIPTION_EDIT_OPTION.EVIDENCE_OF_EFFECTIVENESS}
                title={getMessage('label.factsheet.evidence_of_effectiveness')}
                actionButton={
                  <EditButton
                    origin="content"
                    color="primary"
                    onClick={() => handleEditDescription(FACTSHEET_DESCRIPTION_EDIT_OPTION.EVIDENCE_OF_EFFECTIVENESS)}
                    hidden={!canEdit}
                  />
                }
                helpAndInstructions={<HelpAndInstructions labelKey="label.help.factsheet.evidence_of_effectiveness" />}
              >
                {renderLanguageAttribute(factsheet.evidence_of_effectiveness)}
              </Section>
            </>
          )}
        </>
      </PageLayout>
    </ScreenLayout>
  )
}
