import { DialogActions, DialogContent, Stack } from '@mui/material'
import combinedQuery from 'graphql-combine-query'
import { get } from 'lodash'
import { ReactElement, useCallback, useEffect, useMemo, useState } from 'react'
import { Form } from 'react-final-form'
import { Feature_Config_Bool_Exp, Query_Root } from 'src/@types/graphql'
import { useMessageSource } from 'src/i18n/useMessageSource'
import { ExpandableFeatureSelectionCard } from 'src/screens/shared/common/filter-card/filters/features/ExpandableFeatureSelectionCard'
import { queryFeatureConfigs, queryFeatureTypes } from 'src/screens/shared/feature/featureBaseQueries'
import { PrimaryButton, SecondaryButton } from 'src/shared/button/Buttons'
import { FeatureFilterItem } from 'src/shared/constants/filter-constants'
import { FEATURE_OPERATOR, FEATURE_OPERATOR_TYPE } from 'src/shared/constants/reporting-constants'
import { Option } from 'src/shared/form/control'
import { AutoCompleteField } from 'src/shared/form/control/AutoCompleteField'
import { CheckboxData } from 'src/shared/form/control/CheckboxGroupField'
import { DirtyFormSpy } from 'src/shared/form/dirty/DirtyFormSpy'
import { HelpAndInstructions } from 'src/shared/presentation/HelpAndInstructions'
import { useNotificationService } from 'src/shared/utils/NotificationService'
import { useUserLocale } from 'src/user/UserContext'
import styled from 'styled-components/macro'
import { useClient } from 'urql'

interface Props {
  onCancel: () => void
  onConfirm: (values: FeatureFilterItem[], operator: FEATURE_OPERATOR_TYPE) => void
  featureConfigWhere: Feature_Config_Bool_Exp
  selectedFeatures: FeatureFilterItem[]
  selectedOperator: FEATURE_OPERATOR_TYPE
  isReset?: boolean
}

interface FormValues {
  operator: FEATURE_OPERATOR_TYPE
  features: FeatureValuesModel
}

interface FeatureValuesModel {
  [featureTypeCode: string]: number[]
}

const AutoCompleteFieldStyled = styled(AutoCompleteField)`
  margin-bottom: ${({ theme }) => theme.spacing(1)};

  .MuiFormHelperText-root {
    display: none;
  }
`

export const AddFeaturesModalDialog = ({
  onCancel,
  onConfirm,
  featureConfigWhere,
  selectedFeatures,
  selectedOperator,
  isReset,
}: Props): ReactElement => {
  const { getMessage } = useMessageSource()
  const language = useUserLocale()
  const urqlClient = useClient()
  const notificationService = useNotificationService()

  const [featuresCheckboxData, setFeaturesCheckboxData] = useState<CheckboxData[]>([])
  const [serverData, setServerData] = useState<
    | {
        feature_type_config: Query_Root['feature_type_config']
        feature_config: Query_Root['feature_config']
      }
    | undefined
  >()
  const [initialFeatureValues, setInitialFeatureValues] = useState<FeatureValuesModel>({})
  const [initialOperator, setInitialOperator] = useState<FEATURE_OPERATOR_TYPE>(FEATURE_OPERATOR.AND)
  const [initialFormValues, setInitialFormValues] = useState<FormValues>({
    operator: initialOperator,
    features: initialFeatureValues,
  })

  const setInitialValues = (featureValues: FeatureValuesModel, operator: FEATURE_OPERATOR_TYPE) => {
    setInitialFeatureValues(featureValues)
    setInitialOperator(operator)
  }

  const adaptInitialFeatureValues = useCallback(
    (values: FeatureFilterItem[]): FeatureValuesModel => {
      const formValuesMap = new Map<string, number[]>()

      if (serverData?.feature_type_config) {
        values.forEach((value) => {
          const featureType = serverData?.feature_type_config.find(
            (featureType) => featureType.id === value.featureTypeId,
          )

          featureType &&
            formValuesMap.set(featureType.code, [value.featureId, ...(formValuesMap.get(featureType.code) ?? [])])
        })
      }

      return (Object.fromEntries(formValuesMap) as FeatureValuesModel) ?? {}
    },
    [serverData],
  )

  const adaptSelectedFeatures = (values: FeatureValuesModel): FeatureFilterItem[] => {
    const selectedFeatures: FeatureFilterItem[] = []

    Object.keys(values).forEach((key) => {
      const featureType = serverData?.feature_type_config.find((featureType) => featureType.code === key)
      Object.values(values[key]).forEach((value) =>
        selectedFeatures.push({
          featureId: value,
          featureTypeId: featureType!.id,
        }),
      )
    })

    return selectedFeatures
  }

  useEffect(() => {
    const initData = async () => {
      const { document, variables } = combinedQuery('fetchFeatureTypesAndFeatureConfigs')
        .add<
          {
            feature_config: Query_Root['feature_config']
          },
          {
            featureConfigWhere: Feature_Config_Bool_Exp
          }
        >(queryFeatureConfigs, {
          featureConfigWhere: featureConfigWhere,
        })
        .add<{ feature_type_config: Query_Root['feature_type_config'] }>(queryFeatureTypes)

      const { data, error } = await urqlClient
        .query<{
          feature_type_config: Query_Root['feature_type_config']
          feature_config: Query_Root['feature_config']
        }>(document, variables)
        .toPromise()

      if (data) {
        setServerData(data)
      } else if (error) {
        notificationService.operationFailed()
      }
    }

    initData()
  }, [urqlClient, notificationService, featureConfigWhere])

  useEffect(() => {
    if (serverData && serverData.feature_config) {
      // Adapt server data for the checkbox input data model
      setFeaturesCheckboxData(
        serverData.feature_config.map((feature) => ({
          label: get(feature.names, language, ''),
          value: feature.id,
          tooltip: get(feature.tooltips, language, ''),
        })),
      )

      setInitialFeatureValues(adaptInitialFeatureValues(selectedFeatures))
    }
  }, [serverData, language, selectedFeatures, adaptInitialFeatureValues])

  // Re-rendering of initial values if search filter is reset
  useEffect(() => {
    if (isReset) {
      setInitialValues(adaptInitialFeatureValues(selectedFeatures), selectedOperator)
    }
  }, [selectedFeatures, adaptInitialFeatureValues, selectedOperator, isReset])

  useEffect(() => {
    setInitialOperator(selectedOperator)
    setInitialFormValues({ features: initialFeatureValues, operator: initialOperator })
  }, [selectedOperator, initialFeatureValues, initialOperator])

  const featureData = useMemo(() => {
    const featuresTypes = serverData?.feature_type_config ?? []
    const featureConfigs = serverData?.feature_config ?? []

    return {
      featuresTypes,
      featureConfigs,
    }
  }, [serverData])

  const onSubmit = () => {
    document
      .getElementById('reporting-feature-selection-form')
      ?.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }))
  }

  const handleSubmitLocal = async (values: FormValues): Promise<any> => {
    setInitialValues(values.features, values.operator)
    onConfirm(adaptSelectedFeatures(values.features), values.operator)
  }

  const featureOperatorOptions: Option[] = useMemo(
    () => [
      {
        label: getMessage('label.operator.AND').toUpperCase(),
        value: FEATURE_OPERATOR.AND,
      },
      {
        label: getMessage('label.operator.OR').toUpperCase(),
        value: FEATURE_OPERATOR.OR,
      },
    ],
    [getMessage],
  )

  return (
    <>
      <DialogContent>
        <HelpAndInstructions labelKey="label.help.select.features" defaultExpansion />
        <Form
          initialValues={initialFormValues}
          onSubmit={handleSubmitLocal}
          render={({ handleSubmit, values }) => {
            return (
              <form id="reporting-feature-selection-form" onSubmit={handleSubmit}>
                <Stack spacing={1}>
                  <AutoCompleteFieldStyled
                    options={featureOperatorOptions}
                    name="operator"
                    label={getMessage('label.reporting.feature.connection.operator')}
                    disableClearable
                  />
                  {featureData &&
                    featureData.featuresTypes
                      .filter((featureType) =>
                        featureData.featureConfigs.find((feature) => feature.feature_type_id === featureType.id),
                      )
                      .map((featureType, index) => (
                        <ExpandableFeatureSelectionCard
                          key={index}
                          featureType={featureType}
                          featureConfigs={featureData.featureConfigs}
                          featuresCheckboxData={featuresCheckboxData}
                          showSelectionWithinGroupIcon={
                            values.features[featureType.code] && values.features[featureType.code].length > 0
                          }
                        />
                      ))}
                </Stack>
                <DirtyFormSpy />
              </form>
            )
          }}
        />
      </DialogContent>
      <DialogActions>
        <SecondaryButton messageKey={'button.cancel'} onClick={onCancel} />
        <PrimaryButton messageKey={'button.add.features'} type="submit" onClick={onSubmit} />
      </DialogActions>
    </>
  )
}
