import { AxiosResponse } from 'axios'
import { ReportInput } from 'src/@types/graphql'
import { Axios } from 'src/service/axios/axios'
import { REPORTING_DOWNLOAD_TYPE } from 'src/shared/constants/reporting-constants'
import { Utils } from 'src/shared/utils/Utils'

export const ReportService = {
  generateReport: (
    reportInput: ReportInput,
    processUrl: '/pf-kap' | '/pf-pgv' | '/kap',
    reportType: REPORTING_DOWNLOAD_TYPE,
    language: string,
    params?: { [key: string]: string | null },
  ): Promise<AxiosResponse> => {
    return Axios.getAxios().post(
      `/api/reporting${processUrl}/${reportType}`,
      { ...reportInput, process: Utils.resolveProcess(processUrl) },
      {
        params: {
          language,
          ...params,
        },
        responseType: 'blob',
      },
    )
  },
}
