import { ReactElement } from 'react'
import { useParams } from 'react-router'
import { ApplicationAssessmentSummary } from 'src/screens/shared/application/assessment/details/summary/ApplicationAssessmentSummary'

export const PfKapAssessmentDetailsSummaryPage = (): ReactElement => {
  const { projectId, assessmentId } = useParams()
  const pf_kap_project_id = parseInt(projectId as string)
  const pf_kap_assessment_id = parseInt(assessmentId as string)

  return (
    <ApplicationAssessmentSummary baseUrl="/pf-kap" projectId={pf_kap_project_id} assessmentId={pf_kap_assessment_id} />
  )
}
