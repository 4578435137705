import { Location } from 'history'
import { ReactElement, useEffect, useState } from 'react'
import { Outlet, useLocation } from 'react-router'
import { useParams } from 'react-router-dom'
import { Query_Root } from 'src/@types/graphql'
import { ROUTES } from 'src/routing/routes'
import { NestedRoute, Route, SecondLevelRoute } from 'src/routing/routing'
import { SecondLevelNavigation } from 'src/routing/SecondLevelNavigation'
import { fetchFactsheetByIdQuery } from 'src/screens/factsheets/factsheet/factsheetQueries'
import { PageLayout } from 'src/shared/layout/PageLayout'
import { SecondLevelNavigationContainer } from 'src/shared/layout/SecondLevelNavigationContainer'
import { NotAuthorized } from 'src/shared/not-authorized/NotAuthorized'
import { useQuery } from 'urql'

const resolveActiveRoute = (location: Location, factsheetId: string): SecondLevelRoute => {
  const nested = ROUTES.FactsheetDescriptionRoot!.nested! as unknown as NestedRoute

  const activeRoute = Object.keys(nested).find(
    (key) =>
      location.pathname.endsWith(nested[key].path) ||
      (ROUTES.FactsheetDescriptionRoot.params({ factsheetId }) === location.pathname && nested[key].primary),
  ) as string
  return nested[activeRoute] as SecondLevelRoute
}

export const FactsheetDescriptionIndexPage = (): ReactElement => {
  const { factsheetId } = useParams()
  const location = useLocation()

  const [activeRoute, setActiveRoute] = useState<SecondLevelRoute>(() => {
    return resolveActiveRoute(location, factsheetId as string)
  })

  const [{ fetching, data }] = useQuery<{
    factsheet_by_pk: Query_Root['factsheet_by_pk']
  }>({
    query: fetchFactsheetByIdQuery,
    variables: { id: parseInt(factsheetId as string) },
  })

  useEffect(() => {
    const activeRoute = resolveActiveRoute(location, factsheetId as string)
    setActiveRoute(activeRoute)
  }, [location, factsheetId])

  const showNavigation = activeRoute && !activeRoute.hideNavigation

  if (!fetching && !data?.factsheet_by_pk) {
    return <NotAuthorized />
  }

  return (
    <>
      {!fetching && (
        <SecondLevelNavigationContainer $showNavigation={showNavigation}>
          {showNavigation && (
            <PageLayout>
              <SecondLevelNavigation
                activeRoute={activeRoute}
                route={ROUTES.FactsheetDescriptionRoot as unknown as Route}
                baseUrl={ROUTES.FactsheetDescriptionRoot.params({ factsheetId })}
              />
            </PageLayout>
          )}
          <Outlet />
        </SecondLevelNavigationContainer>
      )}
    </>
  )
}
