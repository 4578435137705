import { gql } from '@urql/core'

export const fetchKapProgramModulesAndBudgetByProgramIdQuery = gql`
  query fetchKapProgramModulesAndBudgetByProgramId($programId: Int!) {
    kap_program_by_pk(id: $programId) {
      modules
      kap_program_budgets {
        budget_gfch
        budget_canton
        module
      }
    }
  }
`

export const upsertKapProgramBudgetMutation = gql`
  mutation upsertKapProgramBudget(
    $kapProgramBudgetToBeAdded: [kap_program_budget_insert_input!]!
    $updateColumns: [kap_program_budget_update_column!]!
  ) {
    insert_kap_program_budget(
      objects: $kapProgramBudgetToBeAdded
      on_conflict: { constraint: kap_program_budget_kap_program_id_module_uq, update_columns: $updateColumns }
    ) {
      affected_rows
    }
  }
`
