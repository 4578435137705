import { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router'
import { Evaluation_Milestone, Query_Root } from 'src/@types/graphql'
import { useMessageSource } from 'src/i18n/useMessageSource'
import { resolveDetailsRoot } from 'src/routing/routing-utils'
import { queryEvaluationMilestoneById } from 'src/screens/shared/project/details/effectivity-management/effectivityManagementQueries'
import { EvaluationMilestoneForm } from 'src/screens/shared/project/details/effectivity-management/evaluation-milestone/edit/EvaluationMilestoneForm'
import { usePermissionsForProject } from 'src/service/security/PermissionHook'
import { SaveAndBackButton, SaveButton } from 'src/shared/button/Buttons'
import { PageLayout } from 'src/shared/layout/PageLayout'
import { ScreenLayout } from 'src/shared/layout/ScreenLayout'
import { NotAuthorized } from 'src/shared/not-authorized/NotAuthorized'
import { HelpAndInstructions } from 'src/shared/presentation/HelpAndInstructions'
import { useDelayedNavigate } from 'src/shared/utils/hooks/navigation-hooks'
import { useNotificationService } from 'src/shared/utils/NotificationService'
import { Utils } from 'src/shared/utils/Utils'
import { useClient } from 'urql'

interface Props {
  baseUrl: '/pf-kap' | '/pf-pgv'
}

type originType = 'SAVE' | 'SAVE_AND_BACK'

export const EvaluationMilestoneEdit = ({ baseUrl }: Props) => {
  const { projectId, evaluationMilestoneId } = useParams()
  const { getMessage } = useMessageSource()

  const originRef = useRef<originType>('SAVE')
  const urqlClient = useClient()
  const notificationService = useNotificationService()
  const navigate = useDelayedNavigate()

  const projectType = Utils.resolveProcess(baseUrl)
  const [evaluationMilestone, setEvaluationMilestone] = useState<Evaluation_Milestone>()

  const {
    loading,
    canEdit,
    metadata: { dossierId },
  } = usePermissionsForProject(projectType, parseInt(projectId as string))

  useEffect(() => {
    const fetchData = async () => {
      const { data } = await urqlClient
        .query<{ evaluation_milestone_by_pk: Query_Root['evaluation_milestone_by_pk'] }>(queryEvaluationMilestoneById, {
          id: evaluationMilestoneId,
        })
        .toPromise()

      if (data?.evaluation_milestone_by_pk) {
        setEvaluationMilestone(data.evaluation_milestone_by_pk)
      } else {
        notificationService.operationFailed()
      }
    }

    fetchData()
  }, [evaluationMilestoneId, notificationService, urqlClient])

  const onBack = (evaluationMilestoneId: number) => {
    const prePath = resolveDetailsRoot(baseUrl)
    const fullPath = prePath.nested.EffectivityManagement.params({ projectId })

    navigate(`${fullPath}#${evaluationMilestoneId}`, {
      state: { expandedEvaluationMilestoneId: evaluationMilestoneId },
    })
  }

  const onSubmit = (origin: originType) => {
    if (originRef) {
      originRef.current = origin
    }
    // trigger submit event
    document
      .getElementById('evaluation-milestone-form')
      ?.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }))
  }

  const afterSubmit = () => {
    if (originRef.current === 'SAVE_AND_BACK') {
      onBack(parseInt(evaluationMilestoneId as string))
    }
  }

  if (!loading && !canEdit) {
    return <NotAuthorized inEditPage />
  }

  return (
    <ScreenLayout
      title={getMessage('label.edit.evaluation.milestone')}
      actions={
        <>
          <SaveAndBackButton origin="header" onClick={() => onSubmit('SAVE_AND_BACK')} />
          <SaveButton origin="header" onClick={() => onSubmit('SAVE')} />
        </>
      }
      onBack={() => onBack(parseInt(evaluationMilestoneId as string))}
      hasSecondLevelNavigation={false}
    >
      <PageLayout>
        <>
          {evaluationMilestone && dossierId && (
            <>
              <HelpAndInstructions
                labelKey={`label.help.project.evaluation.milestones.${projectType}`}
                defaultExpansion
              />
              <EvaluationMilestoneForm
                dossierId={dossierId}
                projectType={projectType}
                evaluationMilestone={evaluationMilestone}
                onSubmit={afterSubmit}
              />
            </>
          )}
        </>
      </PageLayout>
    </ScreenLayout>
  )
}
