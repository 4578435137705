import { ReactElement, useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router'
import { Kap_Program, Mutation_Root, Query_Root } from 'src/@types/graphql'
import { useMessageSource } from 'src/i18n/useMessageSource'
import { ROUTES } from 'src/routing/routes'
import { fetchKapStartingPointAndVision, updateKapProgramById } from 'src/screens/kap/program/details/kapProgramQueries'
import {
  KapStartingPointAndVisionFieldEditForm,
  KapStartingPointAndVisionFieldFormValues,
} from 'src/screens/kap/program/details/starting-point-and-vision/edit/KapStartingPointAndVisionFieldEditForm'
import { usePermissionsForKapProgram } from 'src/service/security/PermissionHook'
import { SaveAndBackButton, SaveButton } from 'src/shared/button/Buttons'
import { TextLength, TEXT_LENGTH } from 'src/shared/constants/constants'
import { PageLayout } from 'src/shared/layout/PageLayout'
import { ScreenLayout } from 'src/shared/layout/ScreenLayout'
import { NotAuthorized } from 'src/shared/not-authorized/NotAuthorized'
import { HelpAndInstructions } from 'src/shared/presentation/HelpAndInstructions'
import { useDelayedNavigate } from 'src/shared/utils/hooks/navigation-hooks'
import { HtmlSanitizer } from 'src/shared/utils/HtmlSanitizer'
import { useNotificationService } from 'src/shared/utils/NotificationService'
import { useClient } from 'urql'

export type KapStartingPointAndVisionFields = keyof Pick<
  Kap_Program,
  'initial_cantonal_situation' | 'program_justification' | 'sources' | 'vision'
>

interface Props {
  field: KapStartingPointAndVisionFields
}

type originType = 'SAVE' | 'SAVE_AND_BACK'

const fieldTextLength: { [field in KapStartingPointAndVisionFields]: TextLength } = {
  initial_cantonal_situation: TEXT_LENGTH.INITIAL_CANTONAL_SITUATION,
  program_justification: TEXT_LENGTH.XXL,
  sources: TEXT_LENGTH.XL,
  vision: TEXT_LENGTH.XXL,
}

export const KapStartingPointAndVisionEditPage = ({ field }: Props): ReactElement => {
  const { getMessage } = useMessageSource()
  const navigate = useDelayedNavigate()
  const { programId } = useParams()
  const program_id = parseInt(programId as string)
  const urqlClient = useClient()
  const notificationService = useNotificationService()
  const originRef = useRef<originType>('SAVE')
  const [initialValue, setInitialValue] = useState<KapStartingPointAndVisionFieldFormValues | null>(null)

  const { canEdit, loading } = usePermissionsForKapProgram(program_id)

  useEffect(() => {
    const initializeFormValues = async () => {
      const { data } = await urqlClient
        .query<
          {
            kap_program_by_pk: Query_Root['kap_program_by_pk']
          },
          {
            id: number
            fetchNationalSituation: boolean
          }
        >(fetchKapStartingPointAndVision, { id: program_id, fetchNationalSituation: false })
        .toPromise()

      const kapProgram = data?.kap_program_by_pk

      if (kapProgram) {
        setInitialValue({
          value: kapProgram[field] ?? '',
        })
      } else {
        notificationService.operationFailed()
      }
    }
    initializeFormValues()
  }, [program_id, urqlClient, notificationService, field])

  const onSubmit = (origin: originType) => (_: any) => {
    // trigger submit event
    if (originRef) {
      originRef.current = origin
    }
    document.getElementById('edit-form')?.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }))
  }

  const onBack = () => {
    const route = ROUTES.KapDetailsRoot.nested.StartingPointAndVision.params({ programId })
    navigate(`${route}#${field.replaceAll('_', '-')}`)
  }

  const onUpdateHandler = async (values: any) => {
    const updateObject = {
      id: program_id,
      fields: { [field]: HtmlSanitizer.sanitize(values.value) },
    }

    const { error, data } = await urqlClient
      .mutation<{ update_kap_program: Mutation_Root['update_kap_program'] }>(updateKapProgramById, updateObject)
      .toPromise()

    if (error || data?.update_kap_program?.affected_rows !== 1) {
      notificationService.operationFailed()
    } else {
      setInitialValue(values)
      notificationService.changesSaved()
      if (originRef.current === 'SAVE_AND_BACK') {
        onBack()
      }
    }
  }

  if (!loading && !canEdit) {
    return <NotAuthorized inEditPage />
  }

  return (
    <ScreenLayout
      title={getMessage(`label.${field.replaceAll('_', '.')}.title.edit`)}
      onBack={onBack}
      hasSecondLevelNavigation={false}
      actions={
        <>
          <SaveAndBackButton origin="header" onClick={onSubmit('SAVE_AND_BACK')} />
          <SaveButton origin="header" onClick={onSubmit('SAVE')} />
        </>
      }
    >
      <PageLayout>
        <>
          {!loading && initialValue && (
            <>
              <HelpAndInstructions
                labelKey={`label.help.and.instructions.${field.replaceAll('_', '.')}.kap`}
                defaultExpansion
              />
              <KapStartingPointAndVisionFieldEditForm
                value={initialValue.value}
                onSave={onUpdateHandler}
                textLength={fieldTextLength[field]}
                label={getMessage(`label.${field.replaceAll('_', '.')}`)}
                required={field !== 'sources'}
              />
            </>
          )}
        </>
      </PageLayout>
    </ScreenLayout>
  )
}
