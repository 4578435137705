import { Stack } from '@mui/material'
import Box from '@mui/material/Box'
import { ReactElement, useEffect, useState } from 'react'
import { Form } from 'react-final-form'
import { Project_Goal, Query_Root } from 'src/@types/graphql'
import { useMessageSource } from 'src/i18n/useMessageSource'
import { queryMeasuresByProject } from 'src/screens/shared/project/details/goals-and-measures/goal/goalQueries'
import { TEXT_LENGTH } from 'src/shared/constants/constants'
import { Option } from 'src/shared/form/control'
import { HtmlEditorField } from 'src/shared/form/control/HtmlEditorField'
import { MultiSelectField } from 'src/shared/form/control/MultiSelectField'
import { TextField } from 'src/shared/form/control/TextField'
import { DirtyFormSpy } from 'src/shared/form/dirty/DirtyFormSpy'
import { createDecorators } from 'src/shared/form/utils/decorators'
import { composeValidators, maxChar, required } from 'src/shared/form/validation/validators'
import { HtmlSanitizer } from 'src/shared/utils/HtmlSanitizer'
import { useClient } from 'urql'

export interface GoalFormValues {
  projectBaseId: number
  goalId?: number
  name: string
  description: string
  sortNumber: number
  version?: number
  measureIds?: number[]
}

interface ProjectGoalProps {
  initialValues: GoalFormValues
  onSave: (values: GoalFormValues) => Promise<void>
}

export const initialValuesFactory = (projectBaseId: number, goal?: Project_Goal): GoalFormValues => {
  const selectedMeasureIds =
    goal?.project_goal_measures.map((project_goal_measure) => project_goal_measure.project_measure.id) ?? []
  return {
    projectBaseId: projectBaseId,
    goalId: goal?.id || undefined,
    name: goal?.name || '',
    description: HtmlSanitizer.sanitize(goal?.description),
    sortNumber: goal?.sort_number || 1,
    version: goal?.version || 0,
    measureIds: selectedMeasureIds,
  }
}

const decorators = createDecorators()

export const GoalForm = ({ initialValues, onSave }: ProjectGoalProps): ReactElement => {
  const { getMessage } = useMessageSource()
  const urqlClient = useClient()
  const projectBaseId = initialValues.projectBaseId
  const [measureOptions, setMeasureOptions] = useState<Option[] | undefined>()

  useEffect(() => {
    const initializeMeasureOptions = async () => {
      const { data } = await urqlClient
        .query<{ project_base: Query_Root['project_base'] }, { projectBaseId: number }>(queryMeasuresByProject, {
          projectBaseId: projectBaseId,
        })
        .toPromise()

      if (data && data.project_base) {
        const options =
          data?.project_base[0].project_measures.map((project_measure) => ({
            label: `${getMessage('label.measure.id.character')}${project_measure.sort_number}: ${project_measure.name}`,
            value: project_measure.id,
            sortNumber: project_measure.sort_number || 0,
          })) ?? []
        setMeasureOptions(options)
      }
    }
    initializeMeasureOptions()
  }, [getMessage, urqlClient, projectBaseId, setMeasureOptions])

  return (
    <Box>
      {initialValues && measureOptions && (
        <Form<GoalFormValues>
          initialValues={initialValues}
          onSubmit={onSave}
          decorators={decorators}
          render={({ handleSubmit }) => {
            return (
              <form id="goal-form" onSubmit={handleSubmit}>
                <Stack spacing={2}>
                  <TextField
                    required
                    label={getMessage('label.title')}
                    type="text"
                    name="name"
                    validate={composeValidators(required(), maxChar(TEXT_LENGTH.S))}
                  />
                  <HtmlEditorField
                    required
                    label={getMessage('label.description')}
                    name="description"
                    validate={composeValidators(required(), maxChar(TEXT_LENGTH.L))}
                  />
                  <MultiSelectField
                    options={measureOptions}
                    name="measureIds"
                    label={getMessage('label.related.measures')}
                    unavailableOptionsMessage={getMessage('label.related.measures.add.measures')}
                  />
                  <DirtyFormSpy />
                </Stack>
              </form>
            )
          }}
        />
      )}
    </Box>
  )
}
