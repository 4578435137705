import { ReactElement } from 'react'
import { ReactComponent as InfoIllustration } from 'src/assets/info-illustration.svg'
import { useMessageSource } from 'src/i18n/useMessageSource'
import { NoAccessDefault } from 'src/shared/not-authorized/NoAccessDefault'

interface Props {
  inSecondLevelNavigationTab?: boolean
  inEditPage?: boolean
}

export const NoAccessPrinciples = ({ inSecondLevelNavigationTab, inEditPage }: Props): ReactElement => {
  const { getMessage } = useMessageSource()

  return (
    <NoAccessDefault
      illustration={<InfoIllustration height="7rem" width="7rem" />}
      label={getMessage('label.no.access.principles')}
      inSecondLevelNavigationTab={inSecondLevelNavigationTab}
      inEditPage={inEditPage}
    />
  )
}
