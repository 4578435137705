const get = (name: string): string => {
  const cookie: Record<string, string> = {}
  document.cookie.split(';').forEach((el) => {
    const [k, v] = el.split('=')
    cookie[k.trim()] = v
  })
  return cookie[name]
}

export const CookieUtils = {
  get,
}
