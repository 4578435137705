import { LoadingButton } from '@mui/lab'
import { Card, CardContent, DialogActions, DialogContent, Stack, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { Form } from 'react-final-form'
import { Kap_Program, Kap_Program_Bool_Exp, Maybe, Query_Root } from 'src/@types/graphql'
import { useMessageSource } from 'src/i18n/useMessageSource'
import { Axios } from 'src/service/axios/axios'
import { SecondaryButton } from 'src/shared/button/Buttons'
import { MODULE_TYPES } from 'src/shared/constants/constants'
import { Option } from 'src/shared/form/control'
import { AutoCompleteField } from 'src/shared/form/control/AutoCompleteField'
import { createDecorators } from 'src/shared/form/utils/decorators'
import { required } from 'src/shared/form/validation/validators'
import { HelpAndInstructions } from 'src/shared/presentation/HelpAndInstructions'
import { ReadOnlyTextField } from 'src/shared/presentation/ReadOnly'
import { DateUtils } from 'src/shared/utils/DateUtils'
import { useNotificationService } from 'src/shared/utils/NotificationService'
import { Utils } from 'src/shared/utils/Utils'
import { useUser } from 'src/user/UserContext'
import { gql, useClient } from 'urql'

interface FormValues {
  programId: number
}

interface Props {
  currentProgramId: number
  destinationProgramModules: MODULE_TYPES[]
  onCloseDialog: () => void
  refetch: () => void
}

const getSelectedProgramInfoQuery = gql`
  query getSelectedProgramInfo($programId: Int!) {
    kap_program_by_pk(id: $programId) {
      dossier {
        short_title
      }
      modules
    }
  }
`

const getProgramOptionsQuery = gql`
  query getProgramOptionsQuery($where: kap_program_bool_exp) {
    kap_program(where: $where, order_by: [{ dossier: { start_date: desc } }, { dossier: { id: desc } }]) {
      id
      dossier {
        short_title
        start_date
      }
      canton_code
      modules
    }
  }
`

const decorators = createDecorators()

const resolveExtendedMappedModuleLifePhases = (modules: MODULE_TYPES[]): MODULE_TYPES[] => {
  const extendedMappedModules = new Set(modules)

  if (modules.includes('LIFE_PHASE_CHILDHOOD')) {
    extendedMappedModules.add('A').add('C')
  }
  if (modules.includes('LIFE_PHASE_SENIORS')) {
    extendedMappedModules.add('B').add('D')
  }

  return Array.from(extendedMappedModules)
}

export const CopyKapMeasuresModalDialog = ({
  currentProgramId,
  destinationProgramModules,
  onCloseDialog,
  refetch,
}: Props) => {
  const urqlClient = useClient()
  const notificationService = useNotificationService()
  const user = useUser().user

  const { getMessage } = useMessageSource()

  const [selectedKapProgram, setSelectedKapProgram] = useState<Maybe<Kap_Program>>()

  const [confirmationChooseState, setConfirmationChooseState] = useState(false)
  const [actionLoading, setActionLoading] = useState(false)

  const [programOptions, setProgramOptions] = useState<Option[]>([])

  useEffect(() => {
    const initData = async () => {
      const extendedMappedModules = resolveExtendedMappedModuleLifePhases(destinationProgramModules)

      const whereClause: Kap_Program_Bool_Exp = {
        id: { _neq: currentProgramId },
        modules: { _has_keys_any: extendedMappedModules },
        dossier: { start_date: { _is_null: false } },
      }

      Utils.isCantonalRole(user.roles) && (whereClause.canton_code = { _eq: user.cantonalUserCanton })

      const { data } = await urqlClient
        .query<{ kap_program: Query_Root['kap_program'] }>(getProgramOptionsQuery, {
          where: whereClause,
        })
        .toPromise()

      if (data) {
        const programChar = getMessage('label.program.id.character')

        setProgramOptions(
          data.kap_program.map((it) => ({
            label: `${programChar}${it.id} - ${it.dossier.short_title} (${DateUtils.parseAndFormatDate(
              it.dossier.start_date,
            )})`,
            value: it.id,
          })),
        )
      } else {
        notificationService.operationFailed()
      }
    }

    initData()
  }, [
    currentProgramId,
    getMessage,
    destinationProgramModules,
    notificationService,
    urqlClient,
    user.cantonalUserCanton,
    user.roles,
  ])

  const handleSubmitLocal = async (values: FormValues) => {
    setActionLoading(true)
    sendBackendResponse(currentProgramId, values.programId)
      .then(() => {
        notificationService.changesSaved()
        refetch()
        onCloseDialog()
      })
      .catch(() => {
        notificationService.operationFailed()
      })
      .finally(() => {
        setActionLoading(false)
      })
  }

  const sendBackendResponse = (toProgramId: number, fromProgramId: number): Promise<void> => {
    return Axios.getAxios().post(`/api/kap/copy-measures/to/${toProgramId}/from/${fromProgramId}`)
  }

  const onContinue = async (programId: number) => {
    setActionLoading(true)

    const { data } = await urqlClient
      .query<{ kap_program_by_pk: Query_Root['kap_program_by_pk'] }>(getSelectedProgramInfoQuery, { programId })
      .toPromise()

    if (data) {
      setSelectedKapProgram(data.kap_program_by_pk)
      setConfirmationChooseState(true)
    } else {
      notificationService.operationFailed()
    }

    setActionLoading(false)
  }

  const onBack = () => {
    setConfirmationChooseState(false)
  }

  return (
    <DialogContent>
      <HelpAndInstructions labelKey={'label.help.copy.program.measures'} defaultExpansion />
      <Form<FormValues>
        onSubmit={handleSubmitLocal}
        decorators={decorators}
        render={({ handleSubmit, values }) => (
          <form onSubmit={handleSubmit} noValidate>
            {!confirmationChooseState ? (
              <>
                <Stack spacing={2}>
                  <AutoCompleteField
                    options={programOptions}
                    name="programId"
                    label={getMessage('label.program')}
                    required
                    validate={required()}
                  />
                </Stack>
                <DialogActions>
                  <SecondaryButton messageKey={'button.cancel'} onClick={onCloseDialog} />
                  <LoadingButton
                    onClick={() => onContinue(values.programId)}
                    variant="contained"
                    color="primary"
                    loading={actionLoading}
                    disabled={!values.programId}
                  >
                    {getMessage('button.continue')}
                  </LoadingButton>
                </DialogActions>
              </>
            ) : (
              <>
                <Stack spacing={2} sx={{ mt: 3 }}>
                  <Typography variant="h5">{getMessage('label.copy.program.measures.confirmation')}</Typography>
                  <Card>
                    <CardContent>
                      <ReadOnlyTextField text={getMessage('label.short.title')}>
                        {selectedKapProgram?.dossier.short_title}
                      </ReadOnlyTextField>
                      <ReadOnlyTextField text={getMessage('label.modules')} isLast>
                        {selectedKapProgram?.modules.map((module: any) => (
                          <Typography key={module}>{getMessage(`label.module.description.${module}`)}</Typography>
                        ))}
                      </ReadOnlyTextField>
                    </CardContent>
                  </Card>
                </Stack>
                <DialogActions>
                  <SecondaryButton messageKey="button.back" onClick={onBack} />
                  <LoadingButton type="submit" variant="contained" color="primary" loading={actionLoading}>
                    {getMessage('button.confirm')}
                  </LoadingButton>
                </DialogActions>
              </>
            )}
          </form>
        )}
      />
    </DialogContent>
  )
}
