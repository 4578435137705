import { memo, ReactNode } from 'react'
import { SupportedLocale } from 'src/@types'
import { Query_Root, Translation } from 'src/@types/graphql'
import { Provider as MessageSourceProvider } from 'src/i18n/MessageSourceContext'
import { GeneralSystemErrorAlert } from 'src/shared/alert/GeneralSystemErrorAlert'
import { useUserLocale } from 'src/user/UserContext'
import { gql, useQuery } from 'urql'

const resolveTranslations = (translations: Translation[]): Record<string, string> => {
  const initialValue: Record<string, string> = {}
  return translations.reduce((cache, element) => {
    cache[element.key] = element.value
    return cache
  }, initialValue)
}

const translationsQuery = gql`
  query ($language: SupportedLocale!) {
    translations(language: $language) {
      key
      value
    }
  }
`

const MessageSourceLoader = memo(({ children }: { children: ReactNode }) => {
  const locale = useUserLocale()

  const [{ data, error }] = useQuery<{ translations: Query_Root['translations'] }, { language: SupportedLocale }>({
    query: translationsQuery,
    variables: { language: locale },
  })

  if (!data || error) {
    return null
  }

  return (
    <MessageSourceProvider value={resolveTranslations(data.translations)}>
      {data.translations && children}
      {error && <GeneralSystemErrorAlert />}
    </MessageSourceProvider>
  )
})
export { MessageSourceLoader }
