export const ROUND_TYPE = {
  INNOVATION_MULTIPLICATION: 'INNOVATION_MULTIPLICATION',
  OFFER_PROMOTION: 'OFFER_PROMOTION',
  TRANSITION_FUNDING_EXTENSION_DIRECT_COLLABORATION: 'TRANSITION_FUNDING_EXTENSION_DIRECT_COLLABORATION',
  FUNDING_AREA_1_2_3_4: 'FUNDING_AREA_1_2_3_4',
  // This is the old 'PF PGV' round type for already existing rounds
  PF_PGV: 'PF_PGV',
} as const

export const PF_KAP_ROUND_TYPES = [ROUND_TYPE.INNOVATION_MULTIPLICATION, ROUND_TYPE.OFFER_PROMOTION]

export const PF_PGV_ROUND_TYPES = [
  ROUND_TYPE.TRANSITION_FUNDING_EXTENSION_DIRECT_COLLABORATION,
  ROUND_TYPE.FUNDING_AREA_1_2_3_4,
]

export const ROUND_STATUS = {
  UPCOMING: 'UPCOMING',
  CLOSED: 'CLOSED',
  ACTIVE: 'ACTIVE',
} as const

export type ROUND_TYPE_TYPE = keyof typeof ROUND_TYPE
export type ROUND_STATUS_TYPE = keyof typeof ROUND_STATUS
