import { useCallback, useEffect, useRef } from 'react'

/**
 * Taken verbatim from: https://github.com/hupe1980/react-is-mounted-hook/blob/master/src/use-is-mounted.tsx
 *
 * @returns function that invoked will evaluate to true or false
 */
export const useIsMounted = (): (() => boolean) => {
  const ref = useRef(false)

  useEffect(() => {
    ref.current = true
    return () => {
      ref.current = false
    }
  }, [])

  return useCallback(() => ref.current, [ref])
}
