import { gql } from '@urql/core'

export const queryMeasureById = gql`
  query ($id: Int!) {
    project_measure_by_pk(id: $id) {
      id
      name
      description
      sort_number
      project_base_id
      start_date
      end_date
      version
      project_goal_measures {
        project_goal {
          id
          name
          sort_number
        }
      }
      project_base {
        project_goals {
          id
          name
        }
      }
    }
  }
`

export const insertProjectMeasure = gql`
  mutation (
    $name: String
    $description: String
    $projectBaseId: Int
    $startDate: date
    $endDate: date
    $goalsToBeAdded: [project_goal_measure_insert_input!]!
  ) {
    insert_project_measure(
      objects: {
        name: $name
        description: $description
        project_base_id: $projectBaseId
        start_date: $startDate
        end_date: $endDate
        project_goal_measures: { data: $goalsToBeAdded }
      }
    ) {
      affected_rows
      returning {
        id
      }
    }
  }
`

export const updateMeasure = gql`
  mutation (
    $id: Int!
    $version: Int!
    $name: String
    $description: String
    $startDate: date
    $endDate: date
    $goalsToBeAdded: [project_goal_measure_insert_input!]!
  ) {
    update_project_measure(
      _set: { name: $name, description: $description, start_date: $startDate, end_date: $endDate }
      where: { _and: [{ id: { _eq: $id } }, { version: { _eq: $version } }] }
    ) {
      affected_rows
      returning {
        version
      }
    }
    delete_project_goal_measure(where: { project_measure_id: { _eq: $id } }) {
      affected_rows
    }
    insert_project_goal_measure(objects: $goalsToBeAdded) {
      affected_rows
    }
  }
`

export const updateMeasureSortNumbers = gql`
  mutation ($measure1Id: Int!, $measure1SortNumber: Int!, $measure2Id: Int!, $measure2SortNumber: Int!) {
    measure1: update_project_measure(where: { id: { _eq: $measure1Id } }, _set: { sort_number: $measure1SortNumber }) {
      affected_rows
    }
    measure2: update_project_measure(where: { id: { _eq: $measure2Id } }, _set: { sort_number: $measure2SortNumber }) {
      affected_rows
    }
  }
`

export const deleteProjectMeasureByPk = gql`
  mutation ($measure_id: Int!) {
    delete_project_measure_by_pk(id: $measure_id) {
      id
    }
  }
`

export const queryGoalsByProject = gql`
  query ($projectBaseId: Int!) {
    project_base(where: { id: { _eq: $projectBaseId } }) {
      project_goals {
        id
        name
        sort_number
      }
    }
  }
`
