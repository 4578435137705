import { Axios } from 'src/service/axios/axios'
import { DOSSIER_STATUS_TYPE, PROJECT_TYPE } from 'src/shared/constants/constants'
import { NextState } from 'src/shared/constants/workflow-constants'

const BASE_URL = '/api/project/workflow'

const nextStates = async (workflowId: string): Promise<Array<NextState>> => {
  return Axios.getAxios()
    .get(`${BASE_URL}/${workflowId}/next-states`)
    .then((response) => response.data)
}

const transition = async (workflowId: string, state: DOSSIER_STATUS_TYPE, lang: string): Promise<Array<NextState>> => {
  return Axios.getAxios()
    .post(`${BASE_URL}/${workflowId}/transition-to/${state}?lang=${lang}`)
    .then((response) => response.data)
}

const deleteProject = async (projectBaseId: number, process: PROJECT_TYPE): Promise<boolean> => {
  return Axios.getAxios()
    .delete(`${BASE_URL}/${process}/${projectBaseId}`)
    .then((response) => response.data)
}

export const WorkflowService = {
  nextStates,
  transition,
  deleteProject,
}
