import { ReactElement } from 'react'
import { useParams } from 'react-router'
import { useMessageSource } from 'src/i18n/useMessageSource'
import { ROUTES } from 'src/routing/routes'
import { KapProgramPrinciples } from 'src/screens/shared/principles/KapProgramPrinciples'
import { usePermissionsForKapProgram } from 'src/service/security/PermissionHook'
import { DOSSIER_STATUS } from 'src/shared/constants/constants'
import { ProgramExportMenu } from 'src/shared/menu/ProgramExportMenu'
import { useDelayedNavigate } from 'src/shared/utils/hooks/navigation-hooks'

export const KapProgramDetailsPrinciplesPage = (): ReactElement => {
  const { getMessage } = useMessageSource()
  const navigate = useDelayedNavigate()
  const { programId } = useParams()

  const program_id = parseInt(programId as string)
  const { loading, canEdit, canViewCantonRestricted } = usePermissionsForKapProgram(program_id)

  const onEditSubprinciple = (subprincipleId: number) => {
    navigate(ROUTES.KapDetailsRoot.nested.SubprincipleEdit.params({ programId, subprincipleId }))
  }

  return (
    <>
      <KapProgramPrinciples
        phase={DOSSIER_STATUS.CONCEPT}
        screenTitle={getMessage('label.program.description')}
        loading={loading}
        canEdit={canEdit}
        onEditSubprinciple={onEditSubprinciple}
        actions={<>{canViewCantonRestricted && <ProgramExportMenu />}</>}
      />
    </>
  )
}
