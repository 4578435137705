export const STORAGE_KEY = {
  _PF_KAP_STORAGE: '_PF_KAP_STORAGE',
  _PF_PGV_STORAGE: '_PF_PGV_STORAGE',
  _KAP_STORAGE: '_KAP_STORAGE',
  _USER_STORAGE: '_USER_STORAGE',
  _FACTSHEET_FILTER_STORAGE: '_FACTSHEET_FILTER_STORAGE',
} as const

export type STORAGE_KEY_TYPE = keyof typeof STORAGE_KEY

const store = (key: STORAGE_KEY_TYPE, value: any) => {
  const stringValue = JSON.stringify(value)
  localStorage.setItem(key, stringValue)
}

const get = (key: STORAGE_KEY_TYPE) => {
  if (localStorage.getItem(key)) {
    const value = JSON.parse(localStorage.getItem(key) ?? '{}')
    return value
  }

  return null
}

const remove = (key: STORAGE_KEY_TYPE) => {
  localStorage.removeItem(key)
}

export default {
  store,
  get,
  remove,
}
