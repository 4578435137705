import { ReactElement, useContext } from 'react'
import { isPathAllowedForRole } from 'src/routing/routing-utils'
import { NotAuthorized } from 'src/shared/not-authorized/NotAuthorized'
import type { User } from 'src/user/user'
import { UserContext } from 'src/user/UserContext'

export const RestrictedRoute = ({
  path,
  roles,
  component: Component,
  ...forwardProps
}: {
  path: string
  roles: User['roles']
  component: React.FC
}): ReactElement => {
  const { user } = useContext(UserContext)

  if (user.roles.length > 0) {
    const { roles } = user
    if (isPathAllowedForRole(path, roles)) {
      return <Component {...forwardProps} />
    } else {
      return <NotAuthorized />
    }
  }

  return <NotAuthorized />
}
