import { GridRenderEditCellParams, GridRowId } from '@mui/x-data-grid'
import { GridColDef, useGridApiRef } from '@mui/x-data-grid-pro'
import { GridApiPro } from '@mui/x-data-grid-pro/models/gridApiPro'
import combinedQuery from 'graphql-combine-query'
import { sumBy } from 'lodash'
import { MutableRefObject, ReactElement, useEffect, useMemo, useState } from 'react'
import { Form } from 'react-final-form'
import { useParams } from 'react-router'
import {
  Mutation_Root,
  Project_Base,
  Project_Base_Bool_Exp,
  Project_Financial_Report,
  Query_Root,
} from 'src/@types/graphql'
import { useMessageSource } from 'src/i18n/useMessageSource'
import { usePrompt } from 'src/routing/routing-hooks'
import {
  queryProjectFinancialReportByMilestoneId,
  queryProjectFinancialReportsByProject,
  upsertProjectFinancialReportMutation,
} from 'src/screens/shared/implementation/details/milestoneQueries'
import { queryProjectBudgetByProjectBase } from 'src/screens/shared/project/details/financials/projectFinancialsQueries'
import { PROJECT } from 'src/shared/constants/constants'
import { NumberInput } from 'src/shared/form/grid/NumberInput'
import { EditableColumnHeader } from 'src/shared/grid/EditableColumnHeader'
import { DefaultSectionTypography } from 'src/shared/presentation/DefaultSectionTypography'
import { S } from 'src/shared/styled/S'
import { DataGridUtils } from 'src/shared/utils/DataGridUtils'
import { useGridTranslateHook } from 'src/shared/utils/hooks/grid-translate-hook'
import { useError } from 'src/shared/utils/hooks/page-loading-error-hook'
import { useDelayedSubmit } from 'src/shared/utils/hooks/submit-hook'
import { useNotificationService } from 'src/shared/utils/NotificationService'
import { Utils } from 'src/shared/utils/Utils'
import { useClient } from 'urql'

interface RowData {
  id: number
  fundsInCHF: string
  budget: number | null
  approved: number | null
  report: number | null
  totalActuals: number | null
  tooltip: string
}

interface Props {
  baseUrl: '/pf-kap' | '/pf-pgv'
  projectId: number
  mode?: 'view' | 'edit'
  onSave?: () => void
  reportYear?: number
}

export interface MilestoneProjectFundsActuals {
  gfch_share_annual_value?: number | null
  share_responsible_organization_annual_value?: number | null
  other_funding_annual_value?: number | null
}

const TOTAL_ROW_ID = 4

export const MilestoneProjectFundsGrid = ({ baseUrl, projectId, mode, onSave, reportYear }: Props): ReactElement => {
  const process = Utils.resolveProcessToLowerCase(baseUrl)
  const projectType = Utils.resolveProcess(baseUrl)
  const { milestoneId } = useParams()
  const milestone_id = parseInt(milestoneId as string)

  const [projectBudgetData, setProjectBudgetData] = useState<Project_Base | null>(null)
  const [financialReportData, setFinancialReportData] = useState<Project_Financial_Report | null>(null)
  const [totalActualsData, setTotalActualsData] = useState<MilestoneProjectFundsActuals>()
  const [dirty, setDirty] = useState<boolean>(false)
  const [totalData, setTotalData] = useState<{ approved: number; report: number }>()

  const apiRef = useGridApiRef()
  const { getMessage } = useMessageSource()
  const notificationService = useNotificationService()
  const gridTranslations = useGridTranslateHook()
  const urqlClient = useClient()
  const submit = useDelayedSubmit()

  const calculateTotalData = (
    value: number,
    id: GridRowId,
    field: string,
    apiRef: MutableRefObject<GridApiPro>,
  ): number => {
    const rows = apiRef.current
      ?.getAllRowIds()
      .filter((id) => id !== TOTAL_ROW_ID)
      .map((rowId) => ({
        approved:
          field === 'approved' && id === rowId
            ? DataGridUtils.formatFieldValue(value)
            : DataGridUtils.formatFieldValue(apiRef.current?.getRow(rowId).approved),
        report:
          field === 'report' && id === rowId
            ? DataGridUtils.formatFieldValue(value)
            : DataGridUtils.formatFieldValue(apiRef.current?.getRow(rowId).report),
      }))

    const approvedTotal = DataGridUtils.formatNaNNumber(DataGridUtils.sumBy(rows, 'approved'))
    const reportTotal = DataGridUtils.formatNaNNumber(DataGridUtils.sumBy(rows, 'report'))

    setTotalData({
      approved: approvedTotal,
      report: reportTotal,
    })

    return value
  }

  const projectFundsRows = useMemo(() => {
    const currentRowModels = apiRef.current?.getRowModels?.() ?? new Map()

    const rowData = [
      {
        id: 1,
        fundsInCHF: getMessage('label.gfch.share'),
        approved: projectBudgetData?.project_budget?.gfch_share_approved ?? null,
        report: currentRowModels.get(1)?.report ?? financialReportData?.gfch_share_annual_value ?? null,
        totalActuals: totalActualsData?.gfch_share_annual_value ?? '-',
      },
      {
        id: 2,
        fundsInCHF: getMessage('label.share.responsible.organisation'),
        approved: projectBudgetData?.project_budget?.share_responsible_organization_approved ?? null,
        report:
          currentRowModels.get(2)?.report ?? financialReportData?.share_responsible_organization_annual_value ?? null,
        totalActuals: totalActualsData?.share_responsible_organization_annual_value ?? '-',
      },
      {
        id: 3,
        fundsInCHF: getMessage('label.other.fundings'),
        approved: projectBudgetData?.project_budget?.other_funding_approved ?? null,
        report: currentRowModels.get(3)?.report ?? financialReportData?.other_funding_annual_value ?? null,
        totalActuals: totalActualsData?.other_funding_annual_value ?? '-',
      },
    ]

    rowData.push({
      id: TOTAL_ROW_ID,
      fundsInCHF: getMessage('label.total'),
      approved: totalData ? totalData.approved : DataGridUtils.sumBy(rowData, 'approved'),
      report: totalData ? totalData.report : DataGridUtils.sumBy(rowData, 'report'),
      totalActuals: rowData.reduce(
        (initial, { totalActuals }) => initial + (parseInt(totalActuals.toString()) || 0),
        0,
      ),
    })

    return rowData as RowData[]
  }, [apiRef, getMessage, projectBudgetData, financialReportData, totalActualsData, totalData])

  const columns = useMemo(() => {
    const hideColumn = mode === 'edit'
    const onRifmChange = (api: GridRenderEditCellParams['api'], id: GridRowId, field: string, value: number) => {
      calculateTotalData(value, id, field, apiRef)
      api.setEditCellValue({ id, field, value })
      setDirty(true)
    }
    return [
      {
        field: 'fundsInCHF',
        headerName: getMessage('label.funds.chf'),
        sortable: false,
        disableColumnMenu: true,
        flex: 1,
      },
      {
        field: 'approved',
        headerName: getMessage('label.approved'),
        headerAlign: 'right',
        sortable: false,
        disableColumnMenu: true,
        align: 'right',
        flex: 0.25,
        renderCell: ({ value }) => {
          if (value === null || value === '' || Number.isNaN(value)) {
            return '-'
          }
          return <S.Span.TextOverflow>{value?.toLocaleString('de-CH')}</S.Span.TextOverflow>
        },
      },
      {
        field: 'report',
        headerAlign: 'right',
        sortable: false,
        disableColumnMenu: true,
        editable: mode === 'edit',
        align: 'right',
        flex: 0.25,
        renderCell: ({ value }) => {
          if (value) {
            return <S.Span.TextOverflow>{value.toLocaleString('de-CH')}</S.Span.TextOverflow>
          }
          return value === null || Number.isNaN(value) || value === '' ? (
            <DefaultSectionTypography $standAlone />
          ) : (
            value
          )
        },
        renderEditCell: (params) => {
          const { value, id, field, api } = params
          const isValid = DataGridUtils.validateValue(value)
          return (
            <NumberInput
              value={value as number}
              onChange={(val: number) => onRifmChange(api, id, field, val)}
              error={!isValid}
            />
          )
        },
        renderHeader: () => (
          <EditableColumnHeader
            headerName={`${getMessage('label.project.funds.report')} ${reportYear ?? ''}`}
            editMode={mode === 'edit'}
          />
        ),
      },
      {
        field: 'totalActuals',
        headerName: getMessage('label.total.actuals'),
        headerAlign: 'right',
        sortable: false,
        disableColumnMenu: true,
        align: 'right',
        flex: 0.25,
        hide: hideColumn,
        renderCell: ({ value }) => {
          if (value === null || value === '' || Number.isNaN(value)) {
            return '-'
          }
          return <S.Span.TextOverflow>{value?.toLocaleString('de-CH')}</S.Span.TextOverflow>
        },
      },
    ] as GridColDef[]
  }, [getMessage, apiRef, mode, reportYear])

  const setError = useError()

  useEffect(() => {
    const pfKapQuery = {
      pf_kap_projects: {
        id: {
          _eq: projectId,
        },
      },
    }

    const pfPgvQuery = {
      pf_pgv_projects: {
        id: {
          _eq: projectId,
        },
      },
    }

    const projectBaseQuery: Project_Base_Bool_Exp = {
      ...(projectType === PROJECT.PF_KAP && pfKapQuery),
      ...(projectType === PROJECT.PF_PGV && pfPgvQuery),
    }

    const fetchData = async () => {
      const { document, variables } = combinedQuery('projectBudgetAndFinancialReport')
        .add<
          {
            project_base: Query_Root['project_base']
          },
          { projectBaseQuery: Project_Base_Bool_Exp }
        >(queryProjectBudgetByProjectBase, {
          projectBaseQuery: projectBaseQuery,
        })
        .add<
          {
            project_financial_report: Query_Root['project_financial_report']
          },
          {
            milestoneId: number
          }
        >(queryProjectFinancialReportByMilestoneId, { milestoneId: milestone_id })
        .add<
          {
            project_financial_all_reports: Query_Root['project_financial_report']
          },
          {
            projectBaseWhere: Project_Base_Bool_Exp
          }
        >(queryProjectFinancialReportsByProject, { projectBaseWhere: projectBaseQuery })

      const { data, error } = await urqlClient
        .query<
          {
            project_base: Query_Root['project_base']
            project_financial_report: Query_Root['project_financial_report']
            project_financial_all_reports: Query_Root['project_financial_report']
          },
          { projectBaseQuery: Project_Base_Bool_Exp; milestoneId: number; projectBaseWhere: Project_Base_Bool_Exp }
        >(document, variables!)
        .toPromise()

      if (data) {
        const allMilestonesFinancialReports = data?.project_financial_all_reports

        const milestonesFinancialReportsActuals = {
          gfch_share_annual_value: sumBy(allMilestonesFinancialReports, 'gfch_share_annual_value'),
          share_responsible_organization_annual_value: sumBy(
            allMilestonesFinancialReports,
            'share_responsible_organization_annual_value',
          ),
          other_funding_annual_value: sumBy(allMilestonesFinancialReports, 'other_funding_annual_value'),
        }

        setProjectBudgetData(data.project_base[0])
        setFinancialReportData(data.project_financial_report[0])
        setTotalActualsData(milestonesFinancialReportsActuals)
      } else if (error) {
        setError()
      }
    }

    fetchData()
  }, [projectId, urqlClient, projectType, setError, milestone_id, process])

  usePrompt(getMessage('notification.form.unsaved.changes'), dirty)

  const onSaveLocal = async () => {
    const rows = apiRef.current.getRowModels()
    const gfchShareReport = rows.get(1)?.report
    const shareResponsibleOrganizationReport = rows.get(2)?.report
    const otherFundingReport = rows.get(3)?.report

    const newFinancialReport = {
      milestone_id: milestone_id,
      gfch_share_annual_value: DataGridUtils.formatFieldValue(gfchShareReport),
      share_responsible_organization_annual_value: DataGridUtils.formatFieldValue(shareResponsibleOrganizationReport),
      other_funding_annual_value: DataGridUtils.formatFieldValue(otherFundingReport),
    }

    const { data, error } = await urqlClient
      .mutation<{
        insert_project_financial_report: Mutation_Root['insert_project_financial_report']
      }>(upsertProjectFinancialReportMutation, {
        projectFinancialReport: newFinancialReport,
      })
      .toPromise()

    if (error || data?.insert_project_financial_report?.affected_rows !== 1) {
      notificationService.operationFailed()
    } else {
      if (onSave) {
        notificationService.changesSaved()
        setDirty(false)
        onSave()
      }
    }
  }

  return (
    <Form
      onSubmit={submit(onSaveLocal)}
      render={({ handleSubmit }) => (
        <form
          id="edit-form"
          onSubmit={handleSubmit}
          onKeyPress={(event) => {
            if (event.key === 'Enter') {
              event.preventDefault()
            }
          }}
        >
          <S.DataGrid.TotalRow
            rows={projectFundsRows}
            columns={columns}
            autoHeight
            hideFooter
            apiRef={apiRef}
            localeText={gridTranslations}
            disableColumnReorder
            disableRowSelectionOnClick
            disableColumnSelector
            onCellEditStop={() => setDirty(true)}
            isCellEditable={({ row }: { row: any }) => row.id !== TOTAL_ROW_ID}
          />
        </form>
      )}
    />
  )
}
