import {
  Kap_Measure,
  Kap_Measure_Annual_Report,
  Maybe,
  Project_Goal,
  Project_Goal_Annual_Report,
  Project_Measure,
  Project_Measure_Annual_Report,
} from 'src/@types/graphql'

export const MILESTONE_RESPONSIBLE_TYPE = {
  GFCH: 'GFCH',
  PROJECT_ORGANIZATION: 'PROJECT_ORGANIZATION',
} as const

export type MILESTONE_RESPONSIBLE_TYPE_TYPE = keyof typeof MILESTONE_RESPONSIBLE_TYPE

export const MILESTONE_TYPE = {
  CONTRACT: 'CONTRACT',
  INTERMEDIATE_DISCUSSION: 'INTERMEDIATE_DISCUSSION',
  EVALUATION_CONCEPT: 'EVALUATION_CONCEPT',
  FINANCIAL_REPORT: 'FINANCIAL_REPORT',
  ANNUAL_REPORT: 'ANNUAL_REPORT',
  ANNUAL_PLAN: 'ANNUAL_PLAN',
  FINAL_REPORT: 'FINAL_REPORT',
} as const

export type MILESTONE_TYPE_TYPE = keyof typeof MILESTONE_TYPE

export const MILESTONE_STATUS = {
  OPEN: 'OPEN',
  REVIEW: 'REVIEW',
  REWORK: 'REWORK',
  DONE: 'DONE',
} as const

export type MILESTONE_STATUS_TYPE = keyof typeof MILESTONE_STATUS

export interface MilestoneDetailsValidation {
  requiredNotes: boolean
  requiredDocuments: boolean
}

export interface ProjectGoalAnnualReport {
  goal_id: number
  name: string
  description?: string
  rating: string | null
  rating_comment?: string
  goal_measures: undefined | (Project_Measure & Project_Measure_Annual_Report)[]
  sort_number: Maybe<number> | undefined
}

export interface KapGoalAnnualReport {
  goal_id: number
  rating: string | null
  reason?: string
  description: string
  goal_measures?: undefined | (Kap_Measure & Kap_Measure_Annual_Report)[]
}

export interface MeasureAnnualReport {
  measure_id: number
  name: string
  description?: string
  rating: string | null
  rating_comment?: string
  goal_measures: undefined | (Project_Goal & Project_Goal_Annual_Report)[]
  sort_number: Maybe<number> | undefined
}

export const GOAL_MEASURE_RATING = {
  GREEN: 'GREEN',
  ORANGE: 'ORANGE',
  RED: 'RED',
  INACTIVE: 'INACTIVE',
} as const

export type GOAL_MEASURE_RATING_TYPE = keyof typeof GOAL_MEASURE_RATING

export type KapMeasureAndValid = Kap_Measure & { milestoneMeasureValid: boolean }
