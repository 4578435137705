import { ReactElement, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Mutation_Root } from 'src/@types/graphql'
import { useMessageSource } from 'src/i18n/useMessageSource'
import { initialValuesFactory, MeasureForm, MeasureFormValues } from 'src/screens/shared/measure/MeasureForm'
import { insertProjectMeasure } from 'src/screens/shared/measure/measureQueries'
import { usePermissionsForProject } from 'src/service/security/PermissionHook'
import { CreateButton } from 'src/shared/button/Buttons'
import { PageLayout } from 'src/shared/layout/PageLayout'
import { ScreenLayout } from 'src/shared/layout/ScreenLayout'
import { NotAuthorized } from 'src/shared/not-authorized/NotAuthorized'
import { HelpAndInstructions } from 'src/shared/presentation/HelpAndInstructions'
import { DateUtils } from 'src/shared/utils/DateUtils'
import { useIsMounted } from 'src/shared/utils/hooks/react-hooks'
import { HtmlSanitizer } from 'src/shared/utils/HtmlSanitizer'
import { useNotificationService } from 'src/shared/utils/NotificationService'
import { Utils } from 'src/shared/utils/Utils'
import { useClient } from 'urql'

interface Props {
  baseUrl: '/pf-kap' | '/pf-pgv'
  helpText: string
  onBack: (measureId?: number) => void
  canEdit: boolean
  loading: boolean
  requiredRelatedGoals?: boolean
}

export const BaseMeasureCreate = ({
  baseUrl,
  helpText,
  onBack,
  requiredRelatedGoals,
  canEdit,
  loading,
}: Props): ReactElement => {
  const { projectId } = useParams()
  const { getMessage } = useMessageSource()

  const urqlClient = useClient()
  const isMounted = useIsMounted()
  const notificationService = useNotificationService()

  const [initialValues, setInitialValues] = useState<MeasureFormValues | null>(null)

  const project_id = parseInt(projectId as string)
  const process = Utils.resolveProcess(baseUrl)

  const {
    metadata: { projectBaseId },
  } = usePermissionsForProject(process, project_id)

  useEffect(() => {
    const initializeFormValues = async () => {
      if (projectBaseId) {
        const initialValues = initialValuesFactory(projectBaseId)
        setInitialValues(initialValues)
      }
    }

    initializeFormValues()
  }, [project_id, urqlClient, baseUrl, projectBaseId])

  const onSubmit = () => {
    document.getElementById('measure-form')?.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }))
  }

  const onSaveHandler = async (values: MeasureFormValues) => {
    const { error, data } = await insertMeasure({ ...values })
    if (error) {
      notificationService.operationFailed()
    } else if (isMounted()) {
      setInitialValues({ ...values })
      notificationService.changesSaved()
      onBack(data?.insert_project_measure?.returning[0].id)
    }
  }

  const insertMeasure = async (values: MeasureFormValues) => {
    const insertObject = {
      name: values.name,
      description: HtmlSanitizer.sanitize(values.description),
      projectBaseId: values.projectBaseId,
      goalsToBeAdded: values.goalIds?.map((goalId: number) => ({
        project_goal_id: goalId,
      })),
      ...(values.startDate && { startDate: DateUtils.parseAndSerializeDate(values.startDate) }),
      ...(values.endDate && { endDate: DateUtils.parseAndSerializeDate(values.endDate) }),
    }

    const { error, data } = await urqlClient
      .mutation<{
        insert_project_measure: Mutation_Root['insert_project_measure']
      }>(insertProjectMeasure, insertObject)
      .toPromise()

    return { error, data }
  }

  if (!loading && !canEdit) {
    return <NotAuthorized />
  }

  return (
    <ScreenLayout
      title={getMessage('label.measure.title.add')}
      onBack={() => onBack()}
      hasSecondLevelNavigation={false}
      actions={
        <>
          <CreateButton origin="header" onClick={onSubmit} />
        </>
      }
    >
      <PageLayout>
        <>
          {!loading && initialValues && (
            <>
              <HelpAndInstructions labelKey={helpText} defaultExpansion />
              <MeasureForm
                initialValues={initialValues}
                onSave={onSaveHandler}
                requiredRelatedGoals={requiredRelatedGoals}
              />
            </>
          )}
        </>
      </PageLayout>
    </ScreenLayout>
  )
}
