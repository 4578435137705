import { Stack, Typography } from '@mui/material'
import { get } from 'lodash'
import { useMemo } from 'react'
import { FieldArray } from 'react-final-form-arrays'
import { useMessageSource } from 'src/i18n/useMessageSource'
import { CommitteeValues } from 'src/screens/shared/application/committee/ApplicationCommiteeUtils'
import { RECOMMENDATION_OR_DECISION } from 'src/shared/constants/assessment-constants'
import { TEXT_LENGTH } from 'src/shared/constants/constants'
import { Option } from 'src/shared/form/control'
import { AutoCompleteField } from 'src/shared/form/control/AutoCompleteField'
import { HtmlEditorField } from 'src/shared/form/control/HtmlEditorField'
import { MultiSelectField } from 'src/shared/form/control/MultiSelectField'
import { maxChar } from 'src/shared/form/validation/validators'

interface Props {
  values: CommitteeValues
  exclusionCriteriaOptions: Option[]
  qualityCriteriaOptions: Option[]
  gfchResponsibleOptions: Option[]
  locale: string
}
export const CommitteeDecisions = ({
  locale,
  values,
  exclusionCriteriaOptions,
  qualityCriteriaOptions,
  gfchResponsibleOptions,
}: Props) => {
  const { getMessage } = useMessageSource()
  const options: Option[] = useMemo(
    () => [
      { label: getMessage('label.recommendation.EXCLUSION'), value: RECOMMENDATION_OR_DECISION.EXCLUSION },
      { label: getMessage('label.recommendation.REJECT'), value: RECOMMENDATION_OR_DECISION.REJECT },
      { label: getMessage('label.recommendation.ACCEPT'), value: RECOMMENDATION_OR_DECISION.ACCEPT },
      { label: getMessage('label.field.not.available'), value: null },
    ],
    [getMessage],
  )

  return (
    <Stack spacing={5}>
      {values.committees && (
        <>
          <FieldArray name="committees">
            {({ fields }) =>
              fields.map((name, index) => (
                <Stack spacing={1} key={name}>
                  <Typography variant="h3">{get(values.committees[index].label, locale, '') as string}</Typography>
                  <HtmlEditorField
                    name={`${name}.description`}
                    label={getMessage('label.committee.notes')}
                    validate={maxChar(TEXT_LENGTH.XL)}
                  />
                  <AutoCompleteField
                    label={getMessage('label.recommendation')}
                    name={`${name}.recommendation`}
                    options={options}
                  />
                </Stack>
              ))
            }
          </FieldArray>
          {
            <>
              <Stack spacing={1}>
                <Typography variant="h3">{getMessage('label.pros.and.cons')}</Typography>
                <HtmlEditorField
                  name="projectApplicationDecision.pros"
                  label={getMessage('label.pros')}
                  validate={maxChar(TEXT_LENGTH.XL)}
                />
                <HtmlEditorField
                  name="projectApplicationDecision.cons"
                  label={getMessage('label.cons')}
                  validate={maxChar(TEXT_LENGTH.XL)}
                />
              </Stack>
              <Stack spacing={1}>
                <Typography variant="h3">{getMessage('label.final.decision')}</Typography>
                <AutoCompleteField
                  label={getMessage('label.decision')}
                  name="projectApplicationDecision.finalDecision"
                  options={options}
                />
                <MultiSelectField
                  options={exclusionCriteriaOptions}
                  name="projectApplicationDecision.exclusionCriteria"
                  label={getMessage('label.exclusion.criteria')}
                  unavailableOptionsMessage={getMessage('label.field.not.available')}
                />
                <MultiSelectField
                  options={qualityCriteriaOptions}
                  name="projectApplicationDecision.qualityCriteria"
                  label={getMessage('label.quality.criteria')}
                  unavailableOptionsMessage={getMessage('label.field.not.available')}
                />
                <HtmlEditorField
                  name="projectApplicationDecision.decisionLetter"
                  label={getMessage('label.decision.letter')}
                  validate={maxChar(TEXT_LENGTH.XL)}
                />
                <AutoCompleteField
                  label={getMessage('label.gfch.responsible')}
                  name="projectApplicationDecision.gfchResponsibleId"
                  options={gfchResponsibleOptions}
                />
              </Stack>
            </>
          }
        </>
      )}
    </Stack>
  )
}
