import { Box, Card, Chip, ChipProps, Divider, Stack, Tooltip, Typography, TypographyTypeMap } from '@mui/material'
import { ReactElement } from 'react'
import { TEXT_OVERFLOW_MIXIN } from 'src/shared/constants/styling-constants'
import { InfoIcon } from 'src/shared/icons/Icons'
import styled from 'styled-components/macro'

export interface ReportingKpisCardDataModel {
  figure: number
  chip: {
    icon: ReactElement
    label: string
    color: ChipProps['color']
  }
}

interface CardProps {
  title: string
  tooltipLabel: string
  data: ReportingKpisCardDataModel[]
}

const CardContentStyled = styled(Card)`
  height: 10rem;
  display: flex;
  flex-direction: column;
  padding: ${({ theme }) => theme.spacing(2)};
  gap: ${({ theme }) => theme.spacing(2)};
`

const CardHeaderStyled = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: ${({ theme }) => theme.colors.text.secondary};
  gap: ${({ theme }) => theme.spacing(0.5)};

  .MuiSvgIcon-fontSizeSmall {
    color: ${({ theme }) => theme.colors.text.disabled};
  }

  .MuiTypography-subtitle1 {
    ${TEXT_OVERFLOW_MIXIN};
  }
`

const CardBodyStyled = styled(Box)`
  display: flex;
  flex-direction: column;
  flex: 1 1 0;
  justify-content: end;
  gap: ${({ theme }) => theme.spacing(1)};
  width: 100%;
  min-width: 0;

  .MuiChip-root {
    width: fit-content;
    ${TEXT_OVERFLOW_MIXIN};
  }

  .MuiTypography-root {
    ${TEXT_OVERFLOW_MIXIN};
  }
`

export const ReportingKpisCard = ({ title, tooltipLabel, data }: CardProps): ReactElement => {
  // Reduce font-size for large numbers
  const resolveFigureTypographyVariant = (
    figure: number,
    data: ReportingKpisCardDataModel[],
  ): TypographyTypeMap['props']['variant'] => {
    const figureCount = figure.toString().length

    // Multiple module card
    if (data.length > 1) {
      if (figureCount < 6) {
        return 'display'
      } else if (figureCount >= 6 && figureCount <= 7) {
        return 'h1'
      } else return 'h3'
    } else {
      // Single card
      if (figureCount < 8) {
        return 'display'
      } else if (figureCount >= 8 && figureCount <= 10) {
        return 'h1'
      } else return 'h3'
    }
  }

  return (
    <Card className={data.length > 1 ? 'multiple-module-card' : ''} sx={{ flex: '1 1 0' }}>
      <CardContentStyled>
        <CardHeaderStyled>
          <Typography variant="subtitle1">{title}</Typography>
          <Tooltip title={tooltipLabel} arrow>
            <InfoIcon fontSize="small" />
          </Tooltip>
        </CardHeaderStyled>
        <Stack direction="row" spacing={2} divider={<Divider orientation="vertical" flexItem />} sx={{ flexGrow: 1 }}>
          {data.map((item, index) => (
            <CardBodyStyled key={index}>
              <Typography variant={resolveFigureTypographyVariant(item.figure, data)}>
                {item.figure.toLocaleString('de-CH')}
              </Typography>
              <Tooltip title={item.chip.label} arrow={false} placement="bottom-start">
                <Chip label={item.chip.label} size="small" color={item.chip.color} icon={item.chip.icon} />
              </Tooltip>
            </CardBodyStyled>
          ))}
        </Stack>
      </CardContentStyled>
    </Card>
  )
}
