import type { ApplicationConfig } from 'src/env/EnvironmentService'
import { proxy, useSnapshot } from 'valtio'

const defaultStore: ApplicationConfig = {
  backendUrl: '',
  graphqlUrl: '',
  reCaptchaSiteKey: '',
  environment: '',
}
export const EnvironmentStore = proxy<ApplicationConfig>(defaultStore)

export const useEnvironment = () => {
  const snapshot = useSnapshot(EnvironmentStore)
  return snapshot
}
