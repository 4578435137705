import { useContext } from 'react'
import { useMessageSource } from 'src/i18n/useMessageSource'
import { DirtyContext } from 'src/shared/form/dirty/DirtyContext'

export const useModalCancel = (callback: () => void): (() => void) => {
  const { dirty } = useContext(DirtyContext)
  const { getMessage } = useMessageSource()

  return () => {
    if (dirty) {
      if (window.confirm(getMessage('notification.form.unsaved.changes'))) {
        callback()
      }
    } else {
      callback()
    }
  }
}
