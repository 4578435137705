import { List } from '@mui/material'
import styled from 'styled-components/macro'

export const ReadOnlyListStyled = styled(List)`
  padding: 0;
  margin-top: ${({ theme }) => theme.spacing(0.5)};
  & .MuiListItem-root {
    padding: 0 ${({ theme }) => theme.spacing(0.5)};
    margin-bottom: ${({ theme }) => theme.spacing(0.5)};
    &:last-child {
      margin-bottom: 0;
    }
  }
  & .MuiListItemText-root {
    margin: 0;
    padding: 2px 0;
    color: ${({ theme }) => theme.colors.text.secondary};
  }
  & .MuiListItemIcon-root {
    min-width: 30px;
    color: ${({ theme }) => theme.colors.text.primary};
  }
`
