import { Box, IconButton } from '@mui/material'
import { DataGridPro, GridColDef, GridRowModel, GridSortModel } from '@mui/x-data-grid-pro'
import { ReactElement, useCallback, useMemo, useState } from 'react'
import { Application_Type, Funding_Round, Mutation_Root, Query_Root } from 'src/@types/graphql'
import { useMessageSource } from 'src/i18n/useMessageSource'
import { ROUTES } from 'src/routing/routes'
import { deleteFundingRoundMutation } from 'src/screens/administration/round-management/round-management-queries'
import { RoundManagementUtils } from 'src/screens/administration/round-management/RoundManagementUtils'
import { DeleteIcon } from 'src/shared/icons/Icons'
import { ConfirmationModalDialog } from 'src/shared/modal-dialog/ConfirmationModalDialog'
import { DateUtils } from 'src/shared/utils/DateUtils'
import { useGridTranslateHook } from 'src/shared/utils/hooks/grid-translate-hook'
import { useDelayedNavigate } from 'src/shared/utils/hooks/navigation-hooks'
import { useNotificationService } from 'src/shared/utils/NotificationService'
import styled from 'styled-components/macro'
import { useClient } from 'urql'

interface Props {
  rounds: Funding_Round[]
  applicationTypes: Application_Type[]
  canEdit: boolean
  refetch: () => void
}

const DataGridProStyled = styled(DataGridPro)`
  & .MuiDataGrid-row {
    &:hover {
      cursor: pointer;
    }
  }
`

export const RoundManagementGrid = ({ rounds, applicationTypes, canEdit, refetch }: Props): ReactElement => {
  const { getMessage } = useMessageSource()
  const gridTranslations = useGridTranslateHook()
  const [roundDeleteConfirmationOpen, setRoundDeleteConfirmationOpen] = useState(false)
  const [roundId, setRoundId] = useState<number | null>(null)
  const [sortModel, setSortModel] = useState<GridSortModel>([
    {
      field: 'start_date',
      sort: 'desc',
    },
  ])

  const urqlClient = useClient()
  const notificationService = useNotificationService()
  const navigate = useDelayedNavigate()

  const handleDeleteRound = useCallback(
    (event: any, roundId: number, dossiers: Query_Root['dossier']) => {
      event.stopPropagation()

      const linkedProjects = dossiers.length

      if (linkedProjects && linkedProjects > 0) {
        notificationService.error(getMessage('notification.error.deleting.round'))
      } else {
        setRoundId(roundId)
        setRoundDeleteConfirmationOpen(true)
      }
    },
    [getMessage, notificationService],
  )

  const columns = useMemo(() => {
    return [
      { field: 'id', headerName: getMessage('label.id'), sortable: true, flex: 0.5 },
      { field: 'name', headerName: getMessage('label.name'), sortable: true, flex: 1.5 },
      {
        field: 'roundType',
        headerName: getMessage('label.round.type'),
        sortable: true,
        flex: 2.5,
        valueGetter: ({ value }: any) =>
          getMessage(
            `label.round.type.${RoundManagementUtils.resolveRoundType(
              value.process,
              value.roundTypes,
              applicationTypes,
            )}`,
          ),
      },
      {
        field: 'start_date',
        headerName: getMessage('label.start.date'),
        sortable: true,
        flex: 1,
        renderCell: ({ value }) => DateUtils.parseAndFormatDate(value),
      },
      {
        field: 'end_date',
        headerName: getMessage('label.end.date'),
        sortable: true,
        flex: 1,
        renderCell: ({ value }) => DateUtils.parseAndFormatDate(value),
      },
      { field: 'status', headerName: getMessage('label.status'), sortable: true, flex: 1 },
      {
        field: 'action',
        headerName: getMessage('label.actions'),
        sortable: false,
        flex: 0.5,
        headerAlign: 'right',
        align: 'right',
        hide: !canEdit,
        renderCell: ({ value }) => {
          return (
            <IconButton
              color="primary"
              onClick={(event) => handleDeleteRound(event, parseInt(value.id as string), value.dossiers)}
            >
              <DeleteIcon />
            </IconButton>
          )
        },
      },
    ] as GridColDef[]
  }, [getMessage, canEdit, applicationTypes, handleDeleteRound])

  const handleCancelDeleteRound = () => {
    setRoundDeleteConfirmationOpen(false)
    setRoundId(null)
  }

  const handleConfirmDeleteRound = async () => {
    setRoundDeleteConfirmationOpen(false)
    if (roundId) {
      const { error } = await urqlClient
        .mutation<
          {
            delete_funding_round: Mutation_Root['delete_funding_round']
          },
          { fundingRoundId: number }
        >(deleteFundingRoundMutation, {
          fundingRoundId: roundId,
        })
        .toPromise()

      if (error) {
        notificationService.operationFailed()
      } else {
        notificationService.deleteSuccessful()
        refetch()
      }
    }
  }

  const rows = useMemo(
    () =>
      rounds.map((round) => ({
        ...round,
        id: `${getMessage('label.round.id.character')}${round.id}`,
        status: getMessage(
          `label.round.status.${RoundManagementUtils.resolveRoundStatus(
            DateUtils.parseDateGraphQL(round.start_date),
            DateUtils.parseDateGraphQL(round.end_date),
          )}`,
        ),
        roundType: {
          roundTypes: round.application_types,
          process: round.process,
        },
        action: { id: round.id, dossiers: round.dossiers },
      })),

    [rounds, getMessage],
  )

  const handleRowClick = (item: GridRowModel) => {
    const roundId = item?.row?.action.id
    navigate(ROUTES.RoundManagementDetailsRoot.params({ roundId }))
  }

  return (
    <Box>
      <DataGridProStyled
        rows={rows}
        columns={columns}
        autoHeight
        localeText={gridTranslations}
        disableColumnSelector
        disableColumnReorder
        disableColumnPinning
        disableRowSelectionOnClick
        disableColumnMenu
        disableColumnFilter
        onRowClick={(props) => handleRowClick(props)}
        sortModel={sortModel}
        onSortModelChange={(model) => setSortModel(model)}
      />
      <ConfirmationModalDialog
        open={roundDeleteConfirmationOpen}
        onCancel={handleCancelDeleteRound}
        onConfirm={handleConfirmDeleteRound}
      >
        {getMessage('label.delete.confirm')}
      </ConfirmationModalDialog>
    </Box>
  )
}
