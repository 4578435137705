import { gql } from '@urql/core'

export const queryAssessmentSummaryById = gql`
  query ($assessmentId: Int!) {
    project_assessment(where: { id: { _eq: $assessmentId } }) {
      id
      version
      summary_comment
      recommendation
      status
    }
  }
`

export const queryAssessmentSummaryCommentById = gql`
  query ($assessmentId: Int!) {
    project_assessment(where: { id: { _eq: $assessmentId } }) {
      version
      summary_comment
    }
  }
`

export const updateAssessmentStatusById = gql`
  mutation ($assessmentId: Int!, $status: String!, $version: Int!) {
    update_project_assessment(
      where: { _and: { id: { _eq: $assessmentId }, version: { _eq: $version } } }
      _set: { status: $status }
    ) {
      affected_rows
      returning {
        version
        status
      }
    }
  }
`

export const updateAssessmentSummaryCommentById = gql`
  mutation ($assessmentId: Int!, $version: Int!, $comment: String) {
    update_project_assessment(
      where: { _and: { id: { _eq: $assessmentId }, version: { _eq: $version } } }
      _set: { summary_comment: $comment }
    ) {
      affected_rows
      returning {
        version
      }
    }
  }
`
