import { Typography } from '@mui/material'
import Box from '@mui/material/Box'
import { ReactElement, ReactNode } from 'react'
import { DefaultSectionTypography } from 'src/shared/presentation/DefaultSectionTypography'
import styled from 'styled-components/macro'

interface ReadOnlyProps {
  children?: ReactNode
  text: string
  isLast?: boolean
  type: 'TEXT_FIELD' | 'SELECTION'
}

interface ReadOnlyChildProps {
  children?: ReactNode
  text: string
  isLast?: boolean
}

const ReadOnlyBody = styled.div`
  color: ${({ theme }) => theme.colors.text.secondary};
  margin-top: ${({ theme }) => theme.spacing(0.5)};
`

const ReadOnly = ({ children, text, type, isLast = false }: ReadOnlyProps): ReactElement => {
  return (
    <Box mb={isLast ? 0 : 2}>
      <Typography variant="h6" component="p">
        {text}
      </Typography>
      {children ? <ReadOnlyBody>{children}</ReadOnlyBody> : <DefaultSectionTypography type={type} />}
    </Box>
  )
}

const ReadOnlyTextField = ({ children, text, isLast = false }: ReadOnlyChildProps): ReactElement => {
  return (
    <ReadOnly text={text} type="TEXT_FIELD" isLast={isLast}>
      {children}
    </ReadOnly>
  )
}

const ReadOnlySelection = ({ children, text, isLast = false }: ReadOnlyChildProps): ReactElement => {
  return (
    <ReadOnly text={text} type="SELECTION" isLast={isLast}>
      {children}
    </ReadOnly>
  )
}

export { ReadOnlyTextField, ReadOnlySelection }
