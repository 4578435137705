import combinedQuery from 'graphql-combine-query'
import { ReactElement, useEffect, useState } from 'react'
import { Form } from 'react-final-form'
import { useParams } from 'react-router'
import { Kap_Program, Maybe, Query_Root, Subprinciple_Config } from 'src/@types/graphql'
import { useMessageSource } from 'src/i18n/useMessageSource'
import {
  fetchKapProgramCreationQuery,
  fetchSubprincipleConfigByIdQuery,
} from 'src/screens/shared/principles/kapProgramPrinciplesQueries'
import { SubprincipleEditForm } from 'src/screens/shared/principles/PrinciplesUtils'
import { SaveAndBackButton, SaveButton } from 'src/shared/button/Buttons'
import { TEXT_LENGTH } from 'src/shared/constants/constants'
import { HtmlEditorField } from 'src/shared/form/control/HtmlEditorField'
import { DirtyFormSpy } from 'src/shared/form/dirty/DirtyFormSpy'
import { createDecorators } from 'src/shared/form/utils/decorators'
import { maxChar } from 'src/shared/form/validation/validators'
import { PageLayout } from 'src/shared/layout/PageLayout'
import { ScreenLayout } from 'src/shared/layout/ScreenLayout'
import { NoAccessPrinciples } from 'src/shared/not-authorized/NoAccessPrinciples'
import { NotAuthorized } from 'src/shared/not-authorized/NotAuthorized'
import { HelpAndInstructions } from 'src/shared/presentation/HelpAndInstructions'
import { useNotificationService } from 'src/shared/utils/NotificationService'
import { useUserLocale } from 'src/user/UserContext'
import { useClient } from 'urql'

interface Props {
  loading: boolean
  canEdit: boolean
  onBack: (subprincipleId: number) => void
  onSubmit: (values: SubprincipleEditForm) => void
  initialValues: SubprincipleEditForm
  label: string
}

export const KapProgramSubprincipleEdit = ({
  loading,
  canEdit,
  onBack,
  onSubmit,
  initialValues,
  label,
}: Props): ReactElement => {
  const { getMessage } = useMessageSource()
  const urqlClient = useClient()
  const { subprincipleId, programId } = useParams()
  const locale = useUserLocale()
  const notificationService = useNotificationService()
  const decorators = createDecorators()

  const subprinciple_id = parseInt(subprincipleId as string)
  const program_id = parseInt(programId as string)

  const [subprincipleConfig, setSubprincipleConfig] = useState<Maybe<Subprinciple_Config> | undefined>()
  const [program, setProgram] = useState<Maybe<Kap_Program> | undefined>()

  useEffect(() => {
    const initData = async () => {
      const { document, variables } = combinedQuery('fetchSubprincipleConfigAndKapProgramCreation')
        .add<{ subprinciple_config_by_pk: Query_Root['subprinciple_config_by_pk'] }>(fetchSubprincipleConfigByIdQuery, {
          subprincipleId: subprinciple_id,
          locale: locale,
        })
        .add<{ kap_program_by_pk: Query_Root['kap_program_by_pk'] }>(fetchKapProgramCreationQuery, {
          programId: program_id,
        })

      const { data } = await urqlClient
        .query<{
          subprinciple_config_by_pk: Query_Root['subprinciple_config_by_pk']
          kap_program_by_pk: Query_Root['kap_program_by_pk']
        }>(document, variables)
        .toPromise()

      if (data) {
        setSubprincipleConfig(data.subprinciple_config_by_pk)
        setProgram(data.kap_program_by_pk)
      } else {
        notificationService.operationFailed()
      }
    }

    initData()
  }, [subprinciple_id, locale, notificationService, urqlClient, program_id])

  let submit: any = () => {}

  const onSave = (event: any) => {
    submit(event)
  }

  const onSaveAndBack = async (event: any) => {
    try {
      await submit(event)
      onBack(subprinciple_id)
    } catch {
      // do nothing
    }
  }

  const programCreatedAfterPrinciples: boolean = program?.created_after_principles === true

  if (!loading && !canEdit) {
    return <NotAuthorized inEditPage />
  }

  return (
    <ScreenLayout
      title={subprincipleConfig ? `${getMessage('label.edit')} - ${subprincipleConfig?.names}` : ''}
      hasSecondLevelNavigation={false}
      onBack={() => onBack(subprinciple_id)}
      actions={
        <>
          <SaveAndBackButton origin="header" onClick={onSaveAndBack} />
          <SaveButton origin="header" onClick={onSave} />
        </>
      }
    >
      <PageLayout>
        <>
          {!loading &&
            program &&
            (!programCreatedAfterPrinciples ? (
              <NoAccessPrinciples inEditPage />
            ) : (
              <>
                <HelpAndInstructions labelKey={subprincipleConfig?.descriptions} defaultExpansion isConfigText />
                <Form
                  onSubmit={onSubmit}
                  initialValues={initialValues}
                  decorators={decorators}
                  render={({ handleSubmit }) => {
                    submit = handleSubmit
                    return (
                      <form onSubmit={handleSubmit} id="edit-form">
                        <HtmlEditorField name="comment" label={label} validate={maxChar(TEXT_LENGTH.L)} />
                        <DirtyFormSpy />
                      </form>
                    )
                  }}
                />
              </>
            ))}
        </>
      </PageLayout>
    </ScreenLayout>
  )
}
