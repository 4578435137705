import { Box, Card, CardContent, Divider, Stack, Typography } from '@mui/material'
import { ReactElement, useEffect, useState } from 'react'
import { Area_Of_Activity, Query_Root } from 'src/@types/graphql'
import { useMessageSource } from 'src/i18n/useMessageSource'
import { ROUTES } from 'src/routing/routes'
import { getPublicSuccessFactorData } from 'src/screens/success-factor-tool/analysis/details/analysis/analysisQueries'
import { AreaOfActivitySection } from 'src/screens/success-factor-tool/analysis/details/analysis/AreaOfActivitySection'
import { FocusTopicSection } from 'src/screens/success-factor-tool/analysis/details/analysis/FocusTopicSection'
import { SuccessFactorSection } from 'src/screens/success-factor-tool/analysis/details/analysis/SuccessFactorSection'
import { SuccessFactorsOverviewCardContent } from 'src/screens/success-factor-tool/public/overview/content/SuccessFactorsOverviewCardContent'
import { LIFE_PHASES, LIFE_PHASE_TYPE, TOPICS, TOPIC_TYPE } from 'src/shared/constants/success-factor-constants'
import { PageLayout } from 'src/shared/layout/PageLayout'
import { ScreenLayout } from 'src/shared/layout/ScreenLayout'
import { ExpandableCard } from 'src/shared/presentation/ExpandableCard'
import { HelpAndInstructions } from 'src/shared/presentation/HelpAndInstructions'
import { Section } from 'src/shared/presentation/Section'
import { S } from 'src/shared/styled/S'
import { useDelayedNavigate } from 'src/shared/utils/hooks/navigation-hooks'
import { useNotificationService } from 'src/shared/utils/NotificationService'
import { useUserLocale } from 'src/user/UserContext'
import styled from 'styled-components/macro'
import { useClient } from 'urql'

const FilterContainer = styled(Box)`
  display: flex;
  align-items: center;
  min-height: 3rem;
`

const FilterTitle = styled(Typography)`
  min-width: 15%;
`

export const SuccessFactorsOverviewPage = (): ReactElement => {
  const urqlClient = useClient()
  const locale = useUserLocale()
  const notificationService = useNotificationService()
  const navigate = useDelayedNavigate()
  const { getMessage } = useMessageSource()

  const [successFactorsData, setSuccessFactorsData] = useState<Area_Of_Activity[]>([])
  const [activeLifePhases, setActiveLifePhases] = useState<LIFE_PHASE_TYPE[]>([])
  const [activeTopics, setActiveTopics] = useState<TOPIC_TYPE[]>([])

  useEffect(() => {
    const initData = async () => {
      const { data } = await urqlClient
        .query<
          {
            area_of_activity: Query_Root['area_of_activity']
          },
          { language: string }
        >(getPublicSuccessFactorData, { language: locale })
        .toPromise()

      if (!data) {
        notificationService.operationFailed()
      }

      setSuccessFactorsData(data?.area_of_activity ?? [])
    }

    initData()
  }, [urqlClient, locale, notificationService])

  const toggleLifePhase = (lifePhase: LIFE_PHASE_TYPE) => {
    if (activeLifePhases.includes(lifePhase)) {
      setActiveLifePhases(activeLifePhases.filter((activeLifePhase) => activeLifePhase !== lifePhase))
    } else {
      setActiveLifePhases([...activeLifePhases, lifePhase])
    }
  }

  const toggleTopic = (topic: TOPIC_TYPE) => {
    if (activeTopics.includes(topic)) {
      setActiveTopics(activeTopics.filter((activeTopic) => activeTopic !== topic))
    } else {
      setActiveTopics([...activeTopics, topic])
    }
  }

  return (
    <ScreenLayout
      title={getMessage('label.success.factors.public.landing.page.title')}
      onBack={() => navigate(`/${ROUTES.SuccessFactorsPublicLanding.path}`)}
      hasSecondLevelNavigation={false}
    >
      <PageLayout>
        <>
          <Section
            title={getMessage('label.success.factors.public.overview.title')}
            helpAndInstructions={
              <HelpAndInstructions
                labelKey={'label.success.factor.help.and.instructions.public.success.factors.overview'}
              />
            }
          >
            <Card>
              <CardContent>
                <Stack spacing={1}>
                  <FilterContainer>
                    <FilterTitle variant="subtitle2">{getMessage('label.success.factor.life.phase')}</FilterTitle>
                    <Box>
                      {Object.values(LIFE_PHASES)
                        .filter((i) => i !== LIFE_PHASES.GENERAL)
                        .map((lifePhase) => (
                          <S.Chip.Clickable
                            key={lifePhase}
                            label={getMessage(`label.success.factor.life.phase.${lifePhase}`)}
                            variant="outlined"
                            $selected={activeLifePhases.includes(lifePhase)}
                            onClick={() => toggleLifePhase(lifePhase)}
                            clickable
                          />
                        ))}
                    </Box>
                  </FilterContainer>
                  <Divider />
                  <FilterContainer>
                    <FilterTitle variant="subtitle2">{getMessage('label.success.factor.topic')}</FilterTitle>
                    <Box>
                      {Object.values(TOPICS).map((topic) => (
                        <S.Chip.Clickable
                          key={topic}
                          label={getMessage(`label.success.factor.topic.${topic}`)}
                          variant="outlined"
                          $selected={activeTopics.includes(topic)}
                          onClick={() => toggleTopic(topic)}
                          clickable
                        />
                      ))}
                    </Box>
                  </FilterContainer>
                </Stack>
              </CardContent>
            </Card>
          </Section>
          {!!successFactorsData.length && (
            <>
              {successFactorsData.map((areaOfActivity) => (
                <AreaOfActivitySection key={areaOfActivity.id} title={areaOfActivity.names} code={areaOfActivity.code}>
                  {areaOfActivity?.focus_topics?.map((focusTopic) => (
                    <FocusTopicSection key={focusTopic.id} title={focusTopic.names} code={focusTopic.code}>
                      <Stack spacing={1}>
                        {focusTopic?.success_factors?.map((successFactor) => (
                          <ExpandableCard
                            key={successFactor.id}
                            header={
                              <SuccessFactorSection
                                key={successFactor.id}
                                code={successFactor.code}
                                title={successFactor.names}
                                sortNumber={successFactor.sort_number}
                                isExpandable
                              />
                            }
                            content={
                              <SuccessFactorsOverviewCardContent
                                successFactorId={successFactor.id}
                                description={successFactor.description}
                                lifePhases={activeLifePhases}
                                topics={activeTopics}
                              />
                            }
                          />
                        ))}
                      </Stack>
                    </FocusTopicSection>
                  ))}
                </AreaOfActivitySection>
              ))}
            </>
          )}
        </>
      </PageLayout>
    </ScreenLayout>
  )
}
