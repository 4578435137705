import { Axios } from 'src/service/axios/axios'
import { PROJECT_TYPE } from 'src/shared/constants/constants'

const BASE_URL = '/api/dossier'

const addSetOfMilestones = async (dossierId: number, process: PROJECT_TYPE): Promise<void> => {
  return Axios.getAxios()
    .post(`${BASE_URL}/${dossierId}/${process}/add-set-of-milestones`)
    .then((response) => response.data)
}

const deleteMilestone = async (milestoneId: number): Promise<void> => {
  return Axios.getAxios()
    .post(`${BASE_URL}/delete-milestone/${milestoneId}`)
    .then((response) => response.data)
}

export const DossierService = {
  addSetOfMilestones,
  deleteMilestone,
}
