import { Typography } from '@mui/material'
import { GridColDef, useGridApiRef } from '@mui/x-data-grid-pro'
import { ReactElement, useMemo } from 'react'
import { Kap_Measure, Kap_Measure_Annual_Report } from 'src/@types/graphql'
import { useMessageSource } from 'src/i18n/useMessageSource'
import { DefaultSectionTypography } from 'src/shared/presentation/DefaultSectionTypography'
import { S } from 'src/shared/styled/S'
import { useGridTranslateHook } from 'src/shared/utils/hooks/grid-translate-hook'
import { resolveKapMeasureRatingColor } from 'src/shared/utils/StylingUtils'

interface Props {
  measures: (Kap_Measure & Kap_Measure_Annual_Report)[]
}

export const KapMilestoneGoalRelatedMeasuresGrid = ({ measures }: Props): ReactElement => {
  const gridTranslations = useGridTranslateHook()
  const gridRef = useGridApiRef()
  const { getMessage } = useMessageSource()

  const rows = useMemo(() => {
    return measures.map((measure) => {
      return {
        id: `${getMessage(`label.kap.measure.id.character.${measure.level}`)}${measure.sort_number}`,
        title: measure.short_title,
        rating: measure.rating,
      }
    })
  }, [measures, getMessage])

  const columns = useMemo<GridColDef[]>(
    () => [
      {
        field: 'id',
        flex: 0.5,
        headerName: getMessage('label.id'),
      },
      {
        field: 'title',
        flex: 2,
        headerName: getMessage('label.title'),
      },
      {
        field: 'rating',
        flex: 0.8,
        headerName: getMessage('label.rating'),
        renderCell: ({ value }) => {
          if (value) {
            return (
              <Typography color={resolveKapMeasureRatingColor(value)}>
                {getMessage(`label.kap.measure.rating.${value}`)}
              </Typography>
            )
          }
          return <DefaultSectionTypography $standAlone />
        },
      },
    ],
    [getMessage],
  )

  return (
    <S.DataGrid.ViewEditMode
      rows={rows}
      columns={columns}
      autoHeight
      apiRef={gridRef}
      localeText={gridTranslations}
      disableColumnSelector
      disableColumnReorder
      disableRowSelectionOnClick
      hideFooter
      $viewMode={true}
      sx={{ marginTop: 1 }}
    />
  )
}
