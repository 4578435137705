import { ReactElement } from 'react'
import { useParams } from 'react-router'
import { ApplicationAssessmentRecommendation } from 'src/screens/shared/application/assessment/details/recommendation/ApplicationAssessmentRecommendation'

export const PfKapRecommendationPage = (): ReactElement => {
  const { projectId, assessmentId } = useParams()
  const pf_kap_project_id = parseInt(projectId as string)
  const pf_kap_assessment_id = parseInt(assessmentId as string)

  return (
    <ApplicationAssessmentRecommendation
      baseUrl="/pf-kap"
      projectId={pf_kap_project_id}
      assessmentId={pf_kap_assessment_id}
    />
  )
}
