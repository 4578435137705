import { Stack } from '@mui/material'
import { useEffect, useMemo, useState } from 'react'
import { Form } from 'react-final-form'
import { useParams } from 'react-router'
import { Maybe, Mutation_Root, Query_Root } from 'src/@types/graphql'
import { useMessageSource } from 'src/i18n/useMessageSource'
import { ROUTES } from 'src/routing/routes'
import {
  fetchKapProgramApplicationInfoQuery,
  updateKapProgramApplicationInfoMutation,
} from 'src/screens/shared/application/details/application-information/applicationInformationQueries'
import { usePermissionsForKapProgram } from 'src/service/security/PermissionHook'
import { SaveAndBackButton, SaveButton } from 'src/shared/button/Buttons'
import { RECOMMENDATION_OR_DECISION } from 'src/shared/constants/assessment-constants'
import { AutoCompleteField } from 'src/shared/form/control/AutoCompleteField'
import { DateTimePickerField } from 'src/shared/form/control/DateTimePickerField'
import { DirtyFormSpy } from 'src/shared/form/dirty/DirtyFormSpy'
import { createDecorators } from 'src/shared/form/utils/decorators'
import { validDate } from 'src/shared/form/validation/validators'
import { PageLayout } from 'src/shared/layout/PageLayout'
import { ScreenLayout } from 'src/shared/layout/ScreenLayout'
import { NotAuthorized } from 'src/shared/not-authorized/NotAuthorized'
import { HelpAndInstructions } from 'src/shared/presentation/HelpAndInstructions'
import { DateUtils } from 'src/shared/utils/DateUtils'
import { useDelayedNavigate } from 'src/shared/utils/hooks/navigation-hooks'
import { useNotificationService } from 'src/shared/utils/NotificationService'
import { useClient } from 'urql'

interface EditForm {
  board_meeting_date: string
  application_decision?: Maybe<string>
}

const safeParseAndFormatDate = (dateString: string) => (dateString ? DateUtils.parseAndFormatDate(dateString) : '')

const safeParseAndSerializeDate = (dateString: string) =>
  dateString ? DateUtils.parseAndSerializeDate(dateString) : null

export const KapApplicationInformationEditPage = () => {
  const { programId } = useParams()
  const program_id = parseInt(programId as string)

  const { getMessage } = useMessageSource()
  const { decorators } = createDecorators()

  const urqlClient = useClient()
  const notificationService = useNotificationService()
  const navigate = useDelayedNavigate()

  const [initialValues, setInitialValues] = useState<EditForm>()

  const { loading, canEdit } = usePermissionsForKapProgram(program_id)

  const applicationDecisionOptions = useMemo(
    () => [
      { label: getMessage('label.recommendation.ACCEPT'), value: RECOMMENDATION_OR_DECISION.ACCEPT },
      { label: getMessage('label.recommendation.REJECT'), value: RECOMMENDATION_OR_DECISION.REJECT },
      { label: getMessage('label.field.not.available'), value: null },
    ],
    [getMessage],
  )

  useEffect(() => {
    const fetchData = async () => {
      const { data } = await urqlClient
        .query<{ kap_program_by_pk: Query_Root['kap_program_by_pk'] }>(fetchKapProgramApplicationInfoQuery, {
          id: program_id,
          file: false,
        })
        .toPromise()

      if (data && data.kap_program_by_pk) {
        const kapProgram = data.kap_program_by_pk
        setInitialValues({
          board_meeting_date: safeParseAndFormatDate(kapProgram.board_meeting_date),
          application_decision: kapProgram.application_decision,
        })
      } else {
        notificationService.operationFailed()
      }
    }

    fetchData()
  }, [urqlClient, program_id, notificationService])

  const onBack = () => {
    navigate(ROUTES.KapApplicationDetailsRoot.nested.Information.params({ programId }))
  }

  const onSave = (event: any) => {
    submit(event)
  }

  const onSaveAndBack = async (event: any) => {
    try {
      await submit(event)
      formValid && onBack()
    } catch {
      // do nothing
    }
  }

  const onSubmitLocal = async (values: EditForm) => {
    const { error, data } = await urqlClient
      .mutation<{
        update_kap_program: Mutation_Root['update_kap_program']
      }>(updateKapProgramApplicationInfoMutation, {
        id: program_id,
        boardMeetingDate: safeParseAndSerializeDate(values.board_meeting_date),
        applicationDecision: values.application_decision,
      })
      .toPromise()

    if (error || data?.update_kap_program?.affected_rows !== 1) {
      notificationService.operationFailed()
    } else {
      setInitialValues(values)
      notificationService.changesSaved()
    }
  }

  let submit: any = () => {}
  let formValid = false

  if (!loading && !canEdit) {
    return <NotAuthorized inEditPage />
  }

  return (
    <ScreenLayout
      title={getMessage('label.application.info.edit')}
      onBack={onBack}
      hasSecondLevelNavigation={false}
      actions={
        <>
          <SaveAndBackButton origin="header" onClick={onSaveAndBack} />
          <SaveButton origin="header" onClick={onSave} />
        </>
      }
    >
      <PageLayout>
        <>
          {!loading && initialValues && (
            <>
              <HelpAndInstructions labelKey="label.help.kap.application.info" defaultExpansion />
              <Form<EditForm>
                initialValues={initialValues}
                onSubmit={onSubmitLocal}
                decorators={decorators}
                render={({ handleSubmit, valid }) => {
                  formValid = valid
                  submit = handleSubmit
                  return (
                    <form onSubmit={handleSubmit} noValidate id="edit-application-info-form">
                      <Stack spacing={2}>
                        <DateTimePickerField
                          name="board_meeting_date"
                          label={getMessage('label.kap.board.meeting.date')}
                          validate={validDate()}
                        />
                        <AutoCompleteField
                          label={getMessage('label.application.decision')}
                          name="application_decision"
                          options={applicationDecisionOptions}
                        />
                      </Stack>
                      <DirtyFormSpy />
                    </form>
                  )
                }}
              />
            </>
          )}
        </>
      </PageLayout>
    </ScreenLayout>
  )
}
