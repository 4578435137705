import { LoadingButton } from '@mui/lab'
import { Box, Button, DialogActions, DialogContent, Stack } from '@mui/material'
import { ReactElement, useMemo, useState } from 'react'
import { Form } from 'react-final-form'
import { SupportedLocale } from 'src/@types'
import { Mutation_Root, RegisterUserInput } from 'src/@types/graphql'
import { useEnvironment } from 'src/env/EnvironmentStore'
import { useMessageSource } from 'src/i18n/useMessageSource'
import { SecondaryButton } from 'src/shared/button/Buttons'
import { LANGUAGES, TEXT_LENGTH, USER_TITLE } from 'src/shared/constants/constants'
import { ENVIRONMENT } from 'src/shared/constants/environment-constants'
import { Option } from 'src/shared/form/control'
import { AutoCompleteField } from 'src/shared/form/control/AutoCompleteField'
import { CheckboxGroupField } from 'src/shared/form/control/CheckboxGroupField'
import { ReCaptchaField } from 'src/shared/form/control/ReCaptchaField'
import { TextField } from 'src/shared/form/control/TextField'
import { createDecorators } from 'src/shared/form/utils/decorators'
import {
  composeValidators,
  maxChar,
  minChar,
  required,
  validEmail,
  validPattern,
} from 'src/shared/form/validation/validators'
import { ExternalIcon } from 'src/shared/icons/Icons'
import { useNotificationService } from 'src/shared/utils/NotificationService'
import { useUserLocale } from 'src/user/UserContext'
import { gql, useClient } from 'urql'

interface RegistrationFormInitValues {
  email: string
}

interface Props {
  onCancel: () => void
  onSuccess: () => void
  initialValues: RegistrationFormInitValues
}

interface FormValues {
  title: 'MR' | 'MRS' | 'NEUTRAL'
  firstName: string
  lastName: string
  language: SupportedLocale
  email: string
  reCaptchaToken: string
  acceptTermsAndConditions: boolean
}

const registerUser = gql`
  mutation registerUser($user: RegisterUserInput!) {
    registerUser(user: $user) {
      status
      failureKey
    }
  }
`

// copied over the girders-auth name regex, although simplified 🤡
export const GIRDERS_AUTH_NAME_REGEX = /^(([\p{L} .'])|([\p{L} .-])+)$/u

export const GIRDERS_AUTH_NAME_REGEX_DESCRIPTIVE_ERROR_MESSAGE_KEY = 'validation.registration.name.pattern'

const decorators = createDecorators()

export const RegisterNewUserModal = ({ onCancel, onSuccess, initialValues }: Props): ReactElement => {
  const urqlClient = useClient()
  const notificationService = useNotificationService()
  const { reCaptchaSiteKey, environment, backendUrl } = useEnvironment()
  const { getMessage } = useMessageSource()
  const language = useUserLocale()

  const [loading, setLoading] = useState<boolean>(false)

  const handleSubmitLocal = async (values: FormValues) => {
    try {
      setLoading(true)

      const { data } = await urqlClient
        .mutation<{ registerUser: Mutation_Root['registerUser'] }, { user: RegisterUserInput }>(registerUser, {
          user: values as RegisterUserInput,
        })
        .toPromise()

      if (data) {
        const status = data.registerUser.status
        const failureKey = data.registerUser.failureKey

        if (status === 'SUCCESS') {
          notificationService.success(getMessage('notification.registration.success'))
          onSuccess()
        } else {
          if (failureKey) {
            notificationService.error(getMessage(failureKey))
          } else {
            notificationService.error(getMessage('notification.registration.failure'))
          }
        }
      } else {
        notificationService.error(getMessage('notification.registration.failure'))
      }
    } catch (e) {
      notificationService.error(getMessage('notification.registration.failure'))
    } finally {
      setLoading(false)
    }
  }

  const titleOptions = useMemo(
    () => [
      { label: getMessage('label.mister'), value: USER_TITLE.MR },
      { label: getMessage('label.miss'), value: USER_TITLE.MRS },
      { label: getMessage('label.neutral'), value: USER_TITLE.NEUTRAL },
    ],
    [getMessage],
  )

  const languageOptions: Option[] = useMemo(() => {
    const options = [
      { label: getMessage('label.language.de'), value: LANGUAGES.DE.toLowerCase() },
      { label: getMessage('label.language.fr'), value: LANGUAGES.FR.toLowerCase() },
      { label: getMessage('label.language.it'), value: LANGUAGES.IT.toLowerCase() },
    ]

    if (environment === ENVIRONMENT.DEV) {
      options.push({ label: getMessage('label.language.en'), value: LANGUAGES.EN.toLowerCase() })
    }

    return options
  }, [environment, getMessage])

  const onViewTermsAndConditions = () => {
    window.open(`${backendUrl}/api/public/terms-and-conditions?language=${language.toUpperCase()}`, '_blank')
  }

  return (
    <Box>
      <Form<FormValues>
        initialValues={initialValues}
        onSubmit={handleSubmitLocal}
        decorators={decorators}
        render={({ handleSubmit, values }) => (
          <form onSubmit={handleSubmit} noValidate>
            <DialogContent>
              <Stack spacing={2}>
                <AutoCompleteField
                  required
                  label={getMessage('label.user.title')}
                  name="title"
                  options={titleOptions}
                  validate={required()}
                />
                <TextField
                  required
                  label={getMessage('label.first.name')}
                  name="firstName"
                  validate={composeValidators(
                    required(),
                    minChar(2),
                    maxChar(TEXT_LENGTH.GIRDERS_AUTH_NAME),
                    validPattern(GIRDERS_AUTH_NAME_REGEX, GIRDERS_AUTH_NAME_REGEX_DESCRIPTIVE_ERROR_MESSAGE_KEY),
                  )}
                />
                <TextField
                  required
                  label={getMessage('label.last.name')}
                  name="lastName"
                  validate={composeValidators(
                    required(),
                    minChar(2),
                    maxChar(TEXT_LENGTH.GIRDERS_AUTH_NAME),
                    validPattern(GIRDERS_AUTH_NAME_REGEX, GIRDERS_AUTH_NAME_REGEX_DESCRIPTIVE_ERROR_MESSAGE_KEY),
                  )}
                />
                <AutoCompleteField
                  required
                  label={getMessage('label.language')}
                  name="language"
                  options={languageOptions}
                  validate={required()}
                />
                <TextField
                  required
                  label={getMessage('label.email')}
                  name="email"
                  validate={composeValidators(required(), minChar(5), maxChar(TEXT_LENGTH.M), validEmail())}
                  disabled={!!initialValues.email}
                />
                <Box>
                  <Button color="primary" startIcon={<ExternalIcon />} onClick={onViewTermsAndConditions}>
                    {getMessage('label.terms.and.conditions')}
                  </Button>
                </Box>
                <CheckboxGroupField
                  name="acceptTermsAndConditions"
                  validate={required()}
                  data={{ label: getMessage('label.terms.and.conditions.accept') }}
                />
                <ReCaptchaField siteKey={reCaptchaSiteKey} name="reCaptchaToken" />
              </Stack>
            </DialogContent>
            <DialogActions>
              <SecondaryButton messageKey="button.close" onClick={onCancel} />
              <LoadingButton
                type="submit"
                disabled={!values?.reCaptchaToken}
                color="primary"
                variant="contained"
                loading={loading}
              >
                {getMessage('button.register')}
              </LoadingButton>
            </DialogActions>
          </form>
        )}
      />
    </Box>
  )
}
