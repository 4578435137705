import { Box, FormControl, FormLabel } from '@mui/material'
import { ReactNode } from 'react'
import { TEXT_OVERFLOW_MIXIN, TYPOGRAPHY_MIXIN } from 'src/shared/constants/styling-constants'
import { TYPOGRAPHY } from 'src/theme/typography'
import styled from 'styled-components/macro'

interface Props {
  label: string
  children: ReactNode
  required?: boolean
  error?: boolean
}

const itemHeight = '3rem'
const labelLineHeight = TYPOGRAPHY.subtitle2.lineHeight

const FormControlStyled = styled(FormControl)`
  display: flex;
  flex-direction: row;
  min-height: ${itemHeight};
`

const FormLabelStyled = styled(FormLabel)`
  ${TYPOGRAPHY_MIXIN.subtitle2};
  ${TEXT_OVERFLOW_MIXIN};
  color: ${({ theme }) => theme.colors.text.primary};
  min-width: 20%;
  display: flex;
  padding-right: ${({ theme }) => theme.spacing(2)};
  flex-basis: 14px;
  margin-top: calc((${itemHeight} - ${labelLineHeight}) / 2);
`

const BoxStyled = styled(Box)`
  flex-grow: 1;
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  // Hiding helper text only for filtering inputs - not to make this change to all form control components
  .MuiFormHelperText-root {
    display: none;
  }
`

export const FilterFieldWrapper = ({ label, children, required, error }: Props) => {
  return (
    <FormControlStyled error={error}>
      <FormLabelStyled required={required}>{label}</FormLabelStyled>
      <BoxStyled>{children}</BoxStyled>
    </FormControlStyled>
  )
}
