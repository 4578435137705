import { Tooltip } from '@mui/material'
import { ReactElement } from 'react'

interface TooltipWrapperProps {
  children: ReactElement
  title: string
  condition: boolean
}

export const TooltipWrapper = ({ children, title, condition }: TooltipWrapperProps): ReactElement => {
  return condition ? (
    <Tooltip title={title} arrow placement="top-start">
      {children}
    </Tooltip>
  ) : (
    children
  )
}
