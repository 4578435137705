import { ReactElement, useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Milestone, Query_Root } from 'src/@types/graphql'
import {
  resolveDefaultMilestoneRoot,
  resolveImplementationDetailsRoot,
  resolveMilestoneRoot,
} from 'src/routing/routing-utils'
import { getApplicationTypeIdFromProjects } from 'src/screens/shared/assessment-criteria/assessmentQueries'
import { ValidationListItemProps } from 'src/screens/shared/common/SummaryValidationUtils'
import { MilestoneSummaryView } from 'src/screens/shared/implementation/details/milestone/details/summary/MilestoneSummaryView'
import { useValidateMilestone } from 'src/screens/shared/implementation/details/milestone/MilestoneService'
import { milestoneSummaryDataQuery } from 'src/screens/shared/implementation/details/milestoneQueries'
import { MilestoneWorkflowService } from 'src/service/axios/MilestoneWorkflowService'
import {
  usePermissionsForMilestones,
  usePermissionsForProjectImplementation,
} from 'src/service/security/PermissionHook'
import { WorkflowPermissionService } from 'src/service/security/WorkflowPermissionService'
import { PROJECT } from 'src/shared/constants/constants'
import { MILESTONE_RESPONSIBLE_TYPE_TYPE, MILESTONE_STATUS_TYPE } from 'src/shared/constants/milestone-constants'
import { NextState } from 'src/shared/constants/workflow-constants'
import { useDelayedNavigate } from 'src/shared/utils/hooks/navigation-hooks'
import { resolveProcessToLowerCase, Utils } from 'src/shared/utils/Utils'
import { useClient, useQuery } from 'urql'

interface Props {
  baseUrl: '/pf-kap' | '/pf-pgv'
}

export const MilestoneSummaryPage = ({ baseUrl }: Props): ReactElement => {
  const { projectId, milestoneId, milestonePath } = useParams()
  const urqlClient = useClient()
  const navigate = useDelayedNavigate()

  const [milestoneSummary, setMilestoneSummary] = useState<Milestone>()
  const [nextStates, setNextStates] = useState<null | NextState[]>(null)

  const milestoneType = Utils.resolveMilestoneType(milestonePath)
  const process = resolveProcessToLowerCase(baseUrl)
  const processType = Utils.resolveProcess(baseUrl)

  const { canManageMilestones } = usePermissionsForProjectImplementation(processType, parseInt(projectId as string))

  const isPfKap = processType === PROJECT.PF_KAP
  const isPfPgv = processType === PROJECT.PF_PGV
  const [{ data }] = useQuery<{
    pf_kap_project: Query_Root['pf_kap_project']
    pf_pgv_project: Query_Root['pf_pgv_project']
  }>({
    query: getApplicationTypeIdFromProjects,
    variables: { isPfKap, isPfPgv, projectId },
  })
  const applicationTypeId = isPfKap
    ? data?.pf_kap_project?.[0]?.application_type_id
    : data?.pf_pgv_project?.[0]?.application_type_id

  const {
    loading,
    canEdit,
    metadata: { userGlobalRoles, userProjectRoles },
  } = usePermissionsForMilestones(
    processType,
    parseInt(projectId as string),
    milestoneSummary?.status as MILESTONE_STATUS_TYPE,
    milestoneSummary?.responsible_type as MILESTONE_RESPONSIBLE_TYPE_TYPE,
  )

  const validationViolations = useValidateMilestone(
    parseInt(milestoneId as string),
    milestoneType,
    baseUrl,
    parseInt(projectId as string),
    applicationTypeId,
  )

  const fetchData = useCallback(async () => {
    const { data } = await urqlClient
      .query<{ milestone_by_pk: Query_Root['milestone_by_pk'] }>(milestoneSummaryDataQuery, { milestoneId })
      .toPromise()

    const nextStates = await MilestoneWorkflowService.nextStates(data?.milestone_by_pk?.workflow_id ?? '')
    const nextStatesForUser = WorkflowPermissionService.nextStatesForUser(nextStates)(userGlobalRoles, userProjectRoles)

    if (data?.milestone_by_pk) {
      setMilestoneSummary(data.milestone_by_pk)
      setNextStates(nextStatesForUser)
    }
  }, [milestoneId, urqlClient, userGlobalRoles, userProjectRoles])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  const backHandler = () => {
    navigate(resolveImplementationDetailsRoot(baseUrl).nested.Milestone.params({ projectId }))
  }

  const handleViolationClick = (validationItem: ValidationListItemProps) => {
    const route = validationItem.root
      ? validationItem.root
      : resolveMilestoneRoot(baseUrl, milestoneType).params({ projectId, milestoneId, milestonePath })

    navigate(
      `${route}${validationItem.tab ? '/' + validationItem.tab : ''}${
        validationItem.section ? `#${validationItem.section}` : ''
      }`,
    )
  }

  const summaryCommentsEditHandler = () => {
    // TODO: When all milestone types are implemented use resolveMilestoneRoot
    navigate(
      resolveDefaultMilestoneRoot(baseUrl).nested.SummaryCommentEdit.params({ projectId, milestoneId, milestonePath }),
    )
  }

  return (
    <MilestoneSummaryView
      loading={loading}
      milestoneSummary={milestoneSummary}
      milestoneType={milestoneType}
      process={process}
      canEdit={canEdit}
      canDeleteMilestone={canManageMilestones}
      onBack={backHandler}
      onEdit={summaryCommentsEditHandler}
      validationViolations={validationViolations}
      handleViolationClick={handleViolationClick}
      nextStates={nextStates}
      refetchPageData={fetchData}
      userProjectRoles={userProjectRoles}
    />
  )
}
