import { Button, Card, CardContent, Divider, Stack } from '@mui/material'
import { FormApi } from 'final-form'
import { ReactElement, useMemo, useState } from 'react'
import { Form } from 'react-final-form'
import { FormValidationErrors } from 'src/@types/global'
import {
  DossierStatus,
  Feature_Config_Bool_Exp,
  FeaturesFilterInput,
  Mutation_Root,
  ReportInput,
} from 'src/@types/graphql'
import { useMessageSource } from 'src/i18n/useMessageSource'
import { FilterCardErrorText } from 'src/screens/shared/common/filter-card/FilterCardErrorText'
import { ApplicationTypeFilter } from 'src/screens/shared/common/filter-card/filters/ApplicationTypeFilter'
import { FeaturesFilter } from 'src/screens/shared/common/filter-card/filters/features/FeaturesFilter'
import { LevelFilter } from 'src/screens/shared/common/filter-card/filters/LevelFilter'
import { ModulesFilter } from 'src/screens/shared/common/filter-card/filters/ModulesFilter'
import { OthersFilter } from 'src/screens/shared/common/filter-card/filters/OthersFilter'
import { ReportingPeriodFilter } from 'src/screens/shared/common/filter-card/filters/ReportingPeriodFilter'
import { SearchFilter } from 'src/screens/shared/common/filter-card/filters/SearchFilter'
import { StatusFilter } from 'src/screens/shared/common/filter-card/filters/StatusFilter'
import { SearchButton } from 'src/shared/button/Buttons'
import {
  APPLICATION_TYPE,
  APPLICATION_TYPE_PF_PGV,
  APPLICATION_TYPE_PF_PGV_TYPE,
  APPLICATION_TYPE_TYPE,
  DOSSIER_STATUS,
  DOSSIER_STATUS_TYPE,
  KAP_MODULES,
  LEVEL,
  LEVEL_TYPE,
  MODULE_TYPES,
  PF_KAP_MODULES,
  PROJECT,
  PROJECT_TYPE,
} from 'src/shared/constants/constants'
import { FeaturesFilterModel } from 'src/shared/constants/filter-constants'
import { FEATURE_OPERATOR } from 'src/shared/constants/reporting-constants'
import { ResetIcon } from 'src/shared/icons/Icons'
import { S } from 'src/shared/styled/S'
import { DateUtils } from 'src/shared/utils/DateUtils'
import { useNotificationService } from 'src/shared/utils/NotificationService'
import { gql, useClient } from 'urql'

const REPORTING_FIELDS = {
  START_YEAR: 'startYear',
  END_YEAR: 'endYear',
  FUNDING_ROUND: 'fundingRound',
  SEARCH: 'search',
  STATUS: 'status',
  MODULES: 'modules',
  APPLICATION_TYPE: 'applicationType',
  LEVEL: 'level',
  OTHERS: {
    FACTSHEET_RELATION: 'others.factsheetRelation',
  },
  FEATURES: 'features',
}

export interface ReportingFilterForm {
  startYear?: string
  endYear?: string
  fundingRound?: number
  search: string
  status: DOSSIER_STATUS_TYPE[]
  modules?: MODULE_TYPES[]
  applicationType?: APPLICATION_TYPE_TYPE[] | APPLICATION_TYPE_PF_PGV_TYPE[]
  level?: LEVEL_TYPE[]
  others?: {
    factsheetRelation: boolean
  }
  features: FeaturesFilterModel
}

interface Props {
  process: PROJECT_TYPE
  setProcessDataCount: (v: number | undefined) => void
  setRelatedMeasuresCount?: (v: number | undefined) => void
  setReportInput: (v: ReportInput) => void
}

const fetchPfKapReportDataMutation = gql`
  mutation fetchPfKapReportData($report: ReportInput!) {
    fetchPfKapProjectReportData(report: $report) {
      count
    }
  }
`

const fetchPfPgvReportDataMutation = gql`
  mutation fetchPfPgvReportData($report: ReportInput!) {
    fetchPfPgvProjectReportData(report: $report) {
      count
    }
  }
`

const fetchKapReportDataMutation = gql`
  mutation fetchKapReportData($report: ReportInput!) {
    fetchKapProgramReportData(report: $report) {
      programsCount
      measuresCount
    }
  }
`

export const ReportingFilterCard = ({
  process,
  setProcessDataCount,
  setRelatedMeasuresCount,
  setReportInput,
}: Props): ReactElement => {
  const { getMessage } = useMessageSource()
  const urqlClient = useClient()
  const notificationService = useNotificationService()

  const [featuresInput, setFeaturesInput] = useState<FeaturesFilterModel>()
  const [resetFilterState, setResetFilterState] = useState<boolean>(false)
  const [beingSearched, setBeingSearched] = useState(false)

  const modules = useMemo(
    () => (process === 'PF_KAP' || process === 'PF_PGV' ? PF_KAP_MODULES.map((m) => m) : KAP_MODULES.map((m) => m)),
    [process],
  )

  const initialValues: ReportingFilterForm = useMemo(
    () => ({
      search: '',
      modules: modules,
      level: Object.values(LEVEL).map((l) => l),
      features: {
        betweenFeatureTypesOperator: FEATURE_OPERATOR.AND,
        selectedFeatures: [],
        withinFeatureTypeOperator: FEATURE_OPERATOR.AND,
      },
      status: [
        DOSSIER_STATUS.CONCEPT,
        DOSSIER_STATUS.APPLICATION,
        ...(process !== PROJECT.KAP ? [DOSSIER_STATUS.REVISION, DOSSIER_STATUS.ACCEPTED] : []),
        DOSSIER_STATUS.IMPLEMENTATION,
        DOSSIER_STATUS.FINISHED,
      ],
      applicationType:
        process === PROJECT.PF_KAP
          ? (Object.values(APPLICATION_TYPE) as APPLICATION_TYPE_TYPE[])
          : (Object.values(APPLICATION_TYPE_PF_PGV) as APPLICATION_TYPE_PF_PGV_TYPE[]),
    }),
    [modules, process],
  )

  let formValid = false

  const handleSearchSubmit = async (values: ReportingFilterForm) => {
    setBeingSearched(true)

    const report = mapReportFormValuesToReportInput(values)

    let fetchProcessReportDataMutation: any

    switch (process) {
      case PROJECT.PF_KAP:
        fetchProcessReportDataMutation = fetchPfKapReportDataMutation
        break
      case PROJECT.PF_PGV:
        fetchProcessReportDataMutation = fetchPfPgvReportDataMutation
        break
      case PROJECT.KAP:
        fetchProcessReportDataMutation = fetchKapReportDataMutation
    }

    const { data } = await urqlClient
      .mutation<
        {
          fetchPfKapProjectReportData: Mutation_Root['fetchPfKapProjectReportData']
          fetchPfPgvProjectReportData: Mutation_Root['fetchPfPgvProjectReportData']
          fetchKapProgramReportData: Mutation_Root['fetchKapProgramReportData']
        },
        { report: ReportInput }
      >(fetchProcessReportDataMutation, { report })
      .toPromise()

    if (data) {
      let processDataCount: any

      switch (process) {
        case 'PF_KAP':
          processDataCount = data.fetchPfKapProjectReportData.count
          break
        case 'PF_PGV':
          processDataCount = data.fetchPfPgvProjectReportData.count
          break
        case 'KAP':
          processDataCount = data.fetchKapProgramReportData.programsCount
          if (setRelatedMeasuresCount) {
            setRelatedMeasuresCount(data.fetchKapProgramReportData.measuresCount)
          }
      }

      setProcessDataCount(processDataCount)
      setReportInput(report)
      scrollToSearchResults()
    } else {
      notificationService.operationFailed()
    }

    setBeingSearched(false)
  }

  const scrollToSearchResults = () => {
    const element = document.getElementById('search-results')

    if (element) {
      const headerOffset = 73
      const elementPosition = element.offsetTop
      const offsetPosition = elementPosition - headerOffset

      setTimeout(
        () =>
          window.scrollTo({
            top: offsetPosition,
            behavior: 'smooth',
          }),
        500,
      )
    }
  }

  const featureConfigWhereClausePfKap: Feature_Config_Bool_Exp = {
    processes: {
      _contains: [PROJECT.PF_KAP],
    },
  }

  const featureConfigWhereClausePfPgv: Feature_Config_Bool_Exp = {
    processes: {
      _contains: [PROJECT.PF_PGV],
    },
  }

  const featureConfigWhereClauseKap: Feature_Config_Bool_Exp = {
    processes: {
      _contains: [PROJECT.KAP],
    },
  }

  const mapReportFormValuesToReportInput = (reportFormValues: ReportingFilterForm): ReportInput => {
    return {
      startDate: reportFormValues.startYear && DateUtils.parseAndSerializeDate(reportFormValues.startYear),
      endDate: reportFormValues.endYear && DateUtils.parseAndSerializeDate(reportFormValues.endYear),
      fundingRound: reportFormValues.fundingRound,
      search: reportFormValues.search ?? null,
      statuses: reportFormValues.status as DossierStatus[],
      modules: reportFormValues.modules,
      applicationTypes: reportFormValues.applicationType,
      factsheetRelation: reportFormValues.others?.factsheetRelation,
      levels: reportFormValues.level,
      features: featuresInput as FeaturesFilterInput,
    }
  }

  const validateForm = (values: ReportingFilterForm): FormValidationErrors<ReportingFilterForm> => {
    const error: FormValidationErrors<ReportingFilterForm> = {}

    const startYear = values.startYear && DateUtils.parseDate(values.startYear)
    const endYear = values.endYear && DateUtils.parseDate(values.endYear)
    const isStartYearValid = startYear && DateUtils.valid(startYear)
    const isEndYearValid = endYear && DateUtils.valid(endYear)

    if (values.fundingRound) {
      // there is a funding round, check start/end years presence
      if (values.startYear || values.endYear) {
        error.fundingRound = { errorKey: 'validation.reporting.period' }
      }
    } else {
      // there is not a funding round, start/end date must be present
      if (!startYear) {
        error.startYear = { errorKey: 'validation.required' }
      }
      if (!endYear) {
        error.endYear = { errorKey: 'validation.required' }
      }
      if (isStartYearValid && isEndYearValid && DateUtils.after(startYear, endYear)) {
        error.endYear = { errorKey: 'validation.reporting.filter.year.end.before.start' }
      }
    }

    return error
  }

  const onResetFilters = async (form: FormApi<ReportingFilterForm>) => {
    form.restart()

    // Reset rendering of search results
    setProcessDataCount(undefined)
    if (setRelatedMeasuresCount) {
      setRelatedMeasuresCount(undefined)
    }

    // Trigger the function that renders UI interaction states of individual filters
    await setResetFilterState(true)
    setResetFilterState(false)
  }

  const searchPlaceholder = getMessage('label.reporting.filter.search.placeholder')

  return (
    <>
      <Card>
        <Form<ReportingFilterForm>
          initialValues={initialValues}
          onSubmit={handleSearchSubmit}
          validate={validateForm}
          render={({ form, handleSubmit, errors, touched, valid, values }) => {
            formValid = valid

            const startYearError = !!(touched?.[REPORTING_FIELDS.START_YEAR] && errors?.[REPORTING_FIELDS.START_YEAR])
            const endYearError = !!(touched?.[REPORTING_FIELDS.END_YEAR] && errors?.[REPORTING_FIELDS.END_YEAR])
            const fundingRoundError = !!errors?.[REPORTING_FIELDS.FUNDING_ROUND]
            const statusError = !!errors?.[REPORTING_FIELDS.STATUS]
            const modulesError = !!errors?.[REPORTING_FIELDS.MODULES]
            const applicationTypeError = !!errors?.[REPORTING_FIELDS.APPLICATION_TYPE]
            const searchError = !!errors?.[REPORTING_FIELDS.SEARCH]
            const levelError = !!errors?.[REPORTING_FIELDS.LEVEL]

            const fields = {
              startYear: {
                name: REPORTING_FIELDS.START_YEAR,
                label: 'label.start.year',
                error: startYearError,
              },
              endYear: {
                name: REPORTING_FIELDS.END_YEAR,
                label: 'label.end.year',
                error: endYearError,
              },
              fundingRound: {
                name: REPORTING_FIELDS.FUNDING_ROUND,
                label: 'label.reporting.filter.reporting.period',
                error: fundingRoundError,
              },
              search: {
                name: REPORTING_FIELDS.SEARCH,
                label: 'label.search',
                error: searchError,
              },
              modules: {
                name: REPORTING_FIELDS.MODULES,
                label: 'label.modules',
                error: modulesError,
              },
              status: {
                name: REPORTING_FIELDS.STATUS,
                label: 'label.status',
                error: statusError,
              },
              applicationType: {
                name: REPORTING_FIELDS.APPLICATION_TYPE,
                label: 'label.application_type',
                error: applicationTypeError,
              },
              level: {
                name: REPORTING_FIELDS.LEVEL,
                label: 'label.level',
                error: levelError,
              },
            }

            return (
              <form id="reporting-filter-form" onSubmit={handleSubmit} noValidate>
                <CardContent>
                  {process === PROJECT.PF_KAP && (
                    <Stack spacing={1} divider={<Divider />}>
                      <ReportingPeriodFilter
                        startYearName={REPORTING_FIELDS.START_YEAR}
                        endYearName={REPORTING_FIELDS.END_YEAR}
                        fundingRoundName={REPORTING_FIELDS.FUNDING_ROUND}
                        process={process}
                        error={startYearError || endYearError || fundingRoundError}
                      />
                      <SearchFilter
                        name={REPORTING_FIELDS.SEARCH}
                        error={searchError}
                        placeholder={searchPlaceholder}
                      />
                      <StatusFilter name={REPORTING_FIELDS.STATUS} error={statusError} formValues={values.status} />
                      <ModulesFilter
                        name={REPORTING_FIELDS.MODULES}
                        error={modulesError}
                        modules={modules}
                        formValues={values.modules}
                      />
                      <ApplicationTypeFilter
                        name={REPORTING_FIELDS.APPLICATION_TYPE}
                        process={PROJECT.PF_KAP}
                        error={applicationTypeError}
                        formValues={values.applicationType}
                      />
                      <OthersFilter
                        name={REPORTING_FIELDS.OTHERS.FACTSHEET_RELATION}
                        formValue={values.others?.factsheetRelation}
                      />
                      <FeaturesFilter
                        featureConfigWhere={featureConfigWhereClausePfKap}
                        setFeaturesInput={setFeaturesInput}
                        isReset={resetFilterState}
                      />
                    </Stack>
                  )}
                  {process === PROJECT.PF_PGV && (
                    <Stack spacing={1} divider={<Divider />}>
                      <ReportingPeriodFilter
                        startYearName={REPORTING_FIELDS.START_YEAR}
                        endYearName={REPORTING_FIELDS.END_YEAR}
                        fundingRoundName={REPORTING_FIELDS.FUNDING_ROUND}
                        process={process}
                        error={startYearError || endYearError || fundingRoundError}
                      />
                      <SearchFilter
                        name={REPORTING_FIELDS.SEARCH}
                        error={searchError}
                        placeholder={searchPlaceholder}
                      />
                      <StatusFilter name={REPORTING_FIELDS.STATUS} error={statusError} formValues={values.status} />
                      <ApplicationTypeFilter
                        name={REPORTING_FIELDS.APPLICATION_TYPE}
                        process={PROJECT.PF_PGV}
                        error={applicationTypeError}
                        formValues={values.applicationType}
                      />
                      <FeaturesFilter
                        featureConfigWhere={featureConfigWhereClausePfPgv}
                        setFeaturesInput={setFeaturesInput}
                        isReset={resetFilterState}
                      />
                    </Stack>
                  )}
                  {process === PROJECT.KAP && (
                    <Stack spacing={1} divider={<Divider />}>
                      <ReportingPeriodFilter
                        startYearName={REPORTING_FIELDS.START_YEAR}
                        endYearName={REPORTING_FIELDS.END_YEAR}
                        process={process}
                        error={startYearError || endYearError}
                      />
                      <SearchFilter
                        name={REPORTING_FIELDS.SEARCH}
                        error={searchError}
                        placeholder={searchPlaceholder}
                      />
                      <StatusFilter
                        name={REPORTING_FIELDS.STATUS}
                        error={statusError}
                        formValues={values.status}
                        process={PROJECT.KAP}
                      />
                      <ModulesFilter
                        name={REPORTING_FIELDS.MODULES}
                        error={modulesError}
                        formValues={values.modules}
                        modules={modules}
                      />
                      <LevelFilter name={REPORTING_FIELDS.LEVEL} error={levelError} formValues={values.level} />
                      <OthersFilter
                        name={REPORTING_FIELDS.OTHERS.FACTSHEET_RELATION}
                        formValue={values.others?.factsheetRelation}
                      />
                      <FeaturesFilter
                        featureConfigWhere={featureConfigWhereClauseKap}
                        setFeaturesInput={setFeaturesInput}
                        isReset={resetFilterState}
                      />
                    </Stack>
                  )}
                </CardContent>
                <S.FilterCard.Actions>
                  <FilterCardErrorText fields={fields} formErrors={errors} />
                  <Button startIcon={<ResetIcon />} onClick={() => onResetFilters(form)} disabled={beingSearched}>
                    {getMessage('button.reporting.reset.filters')}
                  </Button>
                  <SearchButton type="submit" disabled={!formValid} loading={beingSearched} />
                </S.FilterCard.Actions>
              </form>
            )
          }}
        />
      </Card>
    </>
  )
}
