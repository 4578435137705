import { ReactElement } from 'react'
import { useParams } from 'react-router'
import { ProjectFeatures } from 'src/screens/shared/project/details/project-features/ProjectFeatures'

export const PfPgvProjectFeaturesPage = (): ReactElement => {
  const { projectId } = useParams()
  const pf_pgv_id = parseInt(projectId as string)

  return <ProjectFeatures projectId={pf_pgv_id} baseUrl="/pf-pgv" />
}
