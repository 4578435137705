import { GOAL_MEASURE_RATING } from 'src/shared/constants/milestone-constants'
import { styledTheme } from 'src/theme/theme'

// Used for goal ratings on Milestone goal view page (for both projects and KAP programs)
export const resolveGoalRatingColor = (rating: string | null) => {
  switch (rating) {
    case GOAL_MEASURE_RATING.RED:
      return styledTheme.colors.error.dark
    case GOAL_MEASURE_RATING.ORANGE:
      return styledTheme.colors.primary.dark
    case GOAL_MEASURE_RATING.GREEN:
      return styledTheme.colors.success.dark
    case GOAL_MEASURE_RATING.INACTIVE:
      return styledTheme.colors.text.secondary
    default:
      return styledTheme.colors.text.disabled
  }
}

// Used for measure ratings on Milestone goal view page (for projects only)
export const resolveProjectMeasureRatingColor = (rating: string | null) => {
  switch (rating) {
    case GOAL_MEASURE_RATING.RED:
      return styledTheme.colors.error.dark
    case GOAL_MEASURE_RATING.ORANGE:
      return styledTheme.colors.successLight.dark
    case GOAL_MEASURE_RATING.GREEN:
      return styledTheme.colors.success.dark
    case GOAL_MEASURE_RATING.INACTIVE:
      return styledTheme.colors.text.secondary
    default:
      return styledTheme.colors.text.disabled
  }
}

// Used for measure ratings on KAP Milestone goal view page (for KAP programs only)
export const resolveKapMeasureRatingColor = (rating: string | null) => {
  switch (rating) {
    case GOAL_MEASURE_RATING.RED:
      return styledTheme.colors.error.dark
    case GOAL_MEASURE_RATING.ORANGE:
      return styledTheme.colors.primary.dark
    case GOAL_MEASURE_RATING.GREEN:
      return styledTheme.colors.success.dark
    case GOAL_MEASURE_RATING.INACTIVE:
      return styledTheme.colors.text.secondary
    default:
      return styledTheme.colors.text.disabled
  }
}
