import { gql } from 'urql'

export const fetchEvaluationNeedByMilestoneId = gql`
  query fetchEvaluationNeedByMilestoneId($milestoneId: Int!) {
    intermediate_discussion_evaluation(where: { milestone_id: { _eq: $milestoneId } }) {
      id
      milestone_id
      evaluation_need
      year
      reasoning
    }
  }
`

export const insertEvaluationNeed = gql`
  mutation insertEvaluationNeed($object: [intermediate_discussion_evaluation_insert_input!]!) {
    insert_intermediate_discussion_evaluation(
      objects: $object
      on_conflict: {
        constraint: intermediate_discussion_evaluation_milestone_id_uq
        update_columns: [evaluation_need, year, reasoning]
      }
    ) {
      affected_rows
    }
  }
`
