import { Box } from '@mui/material'
import { gql } from '@urql/core'
import { ReactElement, useContext, useEffect, useState } from 'react'
import { Query_Root, Task } from 'src/@types/graphql'
import { useMessageSource } from 'src/i18n/useMessageSource'
import { ROUTES } from 'src/routing/routes'
import { DashboardCard } from 'src/screens/home/index/DashboardCard'
import { TaskGrid } from 'src/screens/home/index/TaskGrid'
import { usePermissionsForSuccessFactors } from 'src/service/security/PermissionHook'
import { PermissionService } from 'src/service/security/PermissionService'
import { APP_TITLE, PROJECT } from 'src/shared/constants/constants'
import { Footer } from 'src/shared/footer/Footer'
import {
  AssignmentIcon,
  DescriptionIcon,
  EvaluationSuccessIcon,
  FactsheetIcon,
  ReportIcon,
} from 'src/shared/icons/Icons'
import { PageLayout } from 'src/shared/layout/PageLayout'
import { ScreenLayout } from 'src/shared/layout/ScreenLayout'
import { useDelayedNavigate } from 'src/shared/utils/hooks/navigation-hooks'
import { useError } from 'src/shared/utils/hooks/page-loading-error-hook'
import { UserContext } from 'src/user/UserContext'
import styled from 'styled-components/macro'
import { useClient } from 'urql'

export const userTasksQuery = gql`
  query {
    userTasks {
      dueDate
      process
      entityId
      entityShortTitle
      responsiblePerson
      responsibleGroupMessageKey
      taskType
      relatedEntityId
      milestoneType
    }
  }
`

const DashboardGrid = styled(Box)`
  display: flex;
  gap: ${({ theme }) => theme.spacing(3)};
  flex-wrap: wrap;
  margin-bottom: ${({ theme }) => theme.spacing(5)};
`

export const HomePage = (): ReactElement => {
  const { getMessage } = useMessageSource()
  const [tasks, setTasks] = useState<Array<Task>>([])
  const urqlClient = useClient()
  const setError = useError()
  const navigate = useDelayedNavigate()
  const { user } = useContext(UserContext)
  const globalRoles = user.roles
  const { canViewReporting } = PermissionService.permissionToViewNavLinkForReporting(globalRoles)
  const { canView: canViewSuccessFactors } = usePermissionsForSuccessFactors()

  useEffect(() => {
    const initData = async () => {
      const { data } = await urqlClient.query<{ userTasks: Query_Root['userTasks'] }>(userTasksQuery, {}).toPromise()

      if (data?.userTasks) {
        setTasks(data.userTasks.map((t, i) => ({ ...t, id: i })))
      } else {
        setError()
      }
    }
    initData()
  }, [urqlClient, setError])

  return (
    <>
      <ScreenLayout title={APP_TITLE} hasSecondLevelNavigation={false} hasFooter>
        <PageLayout>
          <DashboardGrid>
            {PermissionService.permissionToViewNavLink(PROJECT.PF_KAP, globalRoles) && (
              <DashboardCard
                icon={<DescriptionIcon color="primary" />}
                title={getMessage('label.project.pfkap.list')}
                description={getMessage('label.description.pf_kap')}
                onClick={() => navigate(`/${ROUTES.PfKapIndex.path}`)}
              />
            )}
            {PermissionService.permissionToViewNavLink(PROJECT.PF_PGV, globalRoles) && (
              <DashboardCard
                icon={<DescriptionIcon color="secondary" />}
                title={getMessage('label.project.pfpgv.list')}
                description={getMessage('label.description.pf_pgv')}
                onClick={() => navigate(`/${ROUTES.PfPgvIndex.path}`)}
              />
            )}
            {PermissionService.permissionToViewNavLink(PROJECT.KAP, globalRoles) && (
              <DashboardCard
                icon={<AssignmentIcon color="success" />}
                title={getMessage('label.program.kap.list')}
                description={getMessage('label.description.kap')}
                onClick={() => navigate(`/${ROUTES.KapIndex.path}`)}
              />
            )}
            {canViewSuccessFactors && (
              <DashboardCard
                icon={<EvaluationSuccessIcon color="warning" />}
                title={getMessage('label.navigation.success.factors')}
                description={getMessage('label.description.success.factors')}
                onClick={() => navigate(`/${ROUTES.SuccessFactorsIndex.path}`)}
              />
            )}
            <DashboardCard
              icon={<FactsheetIcon color="error" />}
              title={getMessage('label.navigation.factsheets')}
              description={getMessage('label.description.factsheets')}
              onClick={() => navigate(`/${ROUTES.FactsheetsIndex.path}`)}
            />
            {canViewReporting && (
              <DashboardCard
                icon={<ReportIcon color="info" />}
                title={getMessage('label.navigation.reports')}
                description={getMessage('label.description.reports')}
                onClick={() => navigate(`/${ROUTES.ReportingIndex.path}`)}
              />
            )}
          </DashboardGrid>
          <TaskGrid tasks={tasks} />
        </PageLayout>
      </ScreenLayout>
      <Footer inHomePage />
    </>
  )
}
