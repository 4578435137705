import { ReactElement } from 'react'
import { useParams } from 'react-router'
import { useMessageSource } from 'src/i18n/useMessageSource'
import { ROUTES } from 'src/routing/routes'
import { KapProgramBudgetGrid } from 'src/screens/kap/program/details/financials/KapProgramBudgetGrid'
import { usePermissionsForKapProgram } from 'src/service/security/PermissionHook'
import { EditButton } from 'src/shared/button/Buttons'
import { ENTITY_TYPE, FILE_TYPE } from 'src/shared/constants/constants'
import { PageLayout } from 'src/shared/layout/PageLayout'
import { ScreenLayout } from 'src/shared/layout/ScreenLayout'
import { ProgramExportMenu } from 'src/shared/menu/ProgramExportMenu'
import { NotAuthorized } from 'src/shared/not-authorized/NotAuthorized'
import { FileSection } from 'src/shared/presentation/FileSection'
import { HelpAndInstructions } from 'src/shared/presentation/HelpAndInstructions'
import { Section } from 'src/shared/presentation/Section'
import { useDelayedNavigate } from 'src/shared/utils/hooks/navigation-hooks'

export const KapFinancialsPage = (): ReactElement => {
  const { programId } = useParams()
  const program_id = parseInt(programId as string)

  const { getMessage } = useMessageSource()
  const navigate = useDelayedNavigate()

  const { canEdit, loading, canViewCantonRestricted } = usePermissionsForKapProgram(program_id)

  const handleEdit = () => {
    navigate(ROUTES.KapDetailsRoot.nested.FinancialsEdit.params({ programId }))
  }

  return (
    <ScreenLayout
      title={getMessage('label.program.description')}
      actions={<>{canViewCantonRestricted && <ProgramExportMenu />}</>}
    >
      <PageLayout>
        <>
          {!loading && !canViewCantonRestricted && <NotAuthorized inSecondLevelNavigationTab={true} />}
          {!loading && canViewCantonRestricted && (
            <>
              <Section
                id="program-budget"
                title={getMessage('label.kap.program.budget')}
                actionButton={<EditButton onClick={handleEdit} hidden={!canEdit} />}
                helpAndInstructions={<HelpAndInstructions labelKey="label.help.kap.program.budget" />}
              >
                <KapProgramBudgetGrid mode="view" />
              </Section>
              <FileSection
                optional
                id="kap-detailed-budget"
                title={getMessage('label.financials.detailed.budget')}
                fileType={FILE_TYPE.PROGRAM_DETAILED_BUDGET}
                entityType={ENTITY_TYPE.KAP_PROGRAM}
                entityId={program_id}
                canEdit={canEdit}
                helpAndInstructions={<HelpAndInstructions labelKey="label.help.kap.financials.detailed.budget" />}
                uploadType="SINGLE"
              />
            </>
          )}
        </>
      </PageLayout>
    </ScreenLayout>
  )
}
