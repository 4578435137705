import { ReactElement } from 'react'
import { useParams } from 'react-router'
import { Query_Root } from 'src/@types/graphql'
import { ROUTES } from 'src/routing/routes'
import { MilestoneDetailsView } from 'src/screens/shared/implementation/details/milestone/details/default-milestone/MilestoneDetailsView'
import { queryMilestoneById } from 'src/screens/shared/implementation/details/milestoneQueries'
import { usePermissionsForKapMilestones } from 'src/service/security/PermissionHook'
import { MILESTONE_RESPONSIBLE_TYPE_TYPE, MILESTONE_STATUS_TYPE } from 'src/shared/constants/milestone-constants'
import { useDelayedNavigate } from 'src/shared/utils/hooks/navigation-hooks'
import { useNotificationService } from 'src/shared/utils/NotificationService'
import { Utils } from 'src/shared/utils/Utils'
import { useQuery } from 'urql'

export const KapMilestoneDetailsPage = (): ReactElement => {
  const { programId, milestoneId, milestonePath } = useParams()
  const notificationService = useNotificationService()
  const navigate = useDelayedNavigate()

  const programIdParsed = parseInt(programId as string)
  const milestoneIdParsed = parseInt(milestoneId as string)
  const milestoneType = Utils.resolveMilestoneType(milestonePath)
  const process = Utils.resolveProcess('/kap')

  const [{ data, error }] = useQuery<{ milestone_by_pk: Query_Root['milestone_by_pk'] }, { id: number }>({
    query: queryMilestoneById,
    variables: { id: milestoneIdParsed },
  })

  if (error) {
    notificationService.operationFailed()
  }

  const milestone = data?.milestone_by_pk

  const { loading, canEdit } = usePermissionsForKapMilestones(
    programIdParsed,
    milestone?.status as MILESTONE_STATUS_TYPE,
    milestone?.responsible_type as MILESTONE_RESPONSIBLE_TYPE_TYPE,
  )

  const onEdit = () => {
    const route = ROUTES.KapDefaultMilestoneRoot.nested.DetailsEdit.params({
      programId,
      milestoneId,
      milestonePath,
    })
    navigate(route)
  }

  const onBack = () => {
    const route = ROUTES.KapImplementationDetailsRoot.params({ programId })
    navigate(route)
  }

  return (
    <>
      {!loading && milestone && (
        <MilestoneDetailsView
          milestone={milestone}
          milestoneId={milestoneIdParsed}
          milestoneType={milestoneType}
          process={process}
          canEdit={canEdit}
          onBack={onBack}
          onEdit={onEdit}
        />
      )}
    </>
  )
}
