import { Box, CircularProgress, MenuItem } from '@mui/material'
import styled from 'styled-components/macro'
import { ReactNode } from 'react'

interface Props {
  loading: boolean
  disabled?: boolean
  onClick: () => void
  children: ReactNode
}

const LoadingContainer = styled(Box)`
  display: flex;
  justify-content: center;
  min-width: 100%;
`

export const LoadingMenuItem = ({ loading, disabled = false, onClick, children }: Props) => {
  return (
    <MenuItem disabled={disabled} onClick={onClick}>
      {loading ? (
        <LoadingContainer>
          <CircularProgress size="2rem" />
        </LoadingContainer>
      ) : (
        children
      )}
    </MenuItem>
  )
}
