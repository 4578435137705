import { Box, FormHelperText } from '@mui/material'
import { ValidationErrors } from 'final-form'
import { ReactElement } from 'react'
import { useMessageSource } from 'src/i18n/useMessageSource'
import { ErrorOutlineIcon } from 'src/shared/icons/Icons'
import styled from 'styled-components/macro'

interface FieldErrorModel {
  name: string
  label: string
  error: boolean
}

interface Props {
  fields: Record<string, FieldErrorModel>
  formErrors: ValidationErrors
}

const FormHelperTextWrapperStyled = styled(Box)`
  margin-right: auto;

  .helper-text-item {
    display: flex;
    align-items: center;
    gap: ${({ theme }) => theme.spacing(0.5)};
  }

  p {
    margin-top: 0;
  }
`

export const FilterCardErrorText = ({ fields, formErrors }: Props): ReactElement => {
  const { getMessage } = useMessageSource()

  return (
    <FormHelperTextWrapperStyled>
      {Object.values(fields).map(
        ({ name, label, error }, index) =>
          error && (
            <Box className="helper-text-item" key={index}>
              <ErrorOutlineIcon color="error" fontSize="small" />
              <FormHelperText error>
                {`${getMessage(`${label}`)} - ${getMessage(
                  `${formErrors?.[name]?.errorKey}`,
                  formErrors?.[name]?.params,
                )}`}
              </FormHelperText>
            </Box>
          ),
      )}
    </FormHelperTextWrapperStyled>
  )
}
