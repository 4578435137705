import { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router'
import { Project_Journal, Query_Root } from 'src/@types/graphql'
import { useMessageSource } from 'src/i18n/useMessageSource'
import { ProjectJournalForm } from 'src/screens/shared/project/journal/edit/ProjectJournalForm'
import { queryProjectJournalById } from 'src/screens/shared/project/journal/projectJournalQueries'
import { usePermissionsForProject } from 'src/service/security/PermissionHook'
import { SaveAndBackButton, SaveButton } from 'src/shared/button/Buttons'
import { PROJECT_TYPE } from 'src/shared/constants/constants'
import { PageLayout } from 'src/shared/layout/PageLayout'
import { ScreenLayout } from 'src/shared/layout/ScreenLayout'
import { NotAuthorized } from 'src/shared/not-authorized/NotAuthorized'
import { HelpAndInstructions } from 'src/shared/presentation/HelpAndInstructions'
import { Section } from 'src/shared/presentation/Section'
import { useNotificationService } from 'src/shared/utils/NotificationService'
import { useClient } from 'urql'

type originType = 'SAVE' | 'SAVE_AND_BACK'

interface Props {
  projectType: PROJECT_TYPE
  onBack: (journalId: number) => void
}

export const ProjectJournalEdit = ({ projectType, onBack }: Props) => {
  const { projectId, journalId } = useParams()
  const { getMessage } = useMessageSource()

  const urqlClient = useClient()
  const notificationService = useNotificationService()
  const originRef = useRef<originType>('SAVE')

  const [projectJournal, setProjectJournal] = useState<Project_Journal>()

  const onSubmit = (origin: originType) => {
    if (originRef) {
      originRef.current = origin
    }
    // trigger submit event
    document
      .getElementById('project-journal-form')
      ?.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }))
  }

  useEffect(() => {
    const fetchData = async () => {
      const { data } = await urqlClient
        .query<{ project_journal_by_pk: Query_Root['project_journal_by_pk'] }>(queryProjectJournalById, {
          id: journalId,
        })
        .toPromise()

      if (data?.project_journal_by_pk) {
        setProjectJournal(data.project_journal_by_pk)
      } else {
        notificationService.operationFailed()
      }
    }

    fetchData()
  }, [journalId, notificationService, urqlClient])

  const afterSubmit = () => {
    if (originRef.current === 'SAVE_AND_BACK') {
      onBack(parseInt(journalId as string))
    }
  }

  const { canEdit, loading } = usePermissionsForProject(projectType, parseInt(projectId as string))

  if (!loading && !canEdit) {
    return <NotAuthorized inEditPage />
  }

  return (
    <ScreenLayout
      title={getMessage('label.edit.project.journal')}
      actions={
        <>
          <SaveAndBackButton origin="header" onClick={() => onSubmit('SAVE_AND_BACK')} />
          <SaveButton origin="header" onClick={() => onSubmit('SAVE')} />
        </>
      }
      onBack={() => onBack(parseInt(journalId as string))}
      hasSecondLevelNavigation={false}
    >
      <PageLayout>
        <Section helpAndInstructions={<HelpAndInstructions labelKey="label.help.project.journal" defaultExpansion />}>
          <>
            {projectJournal && (
              <ProjectJournalForm projectType={projectType} journal={projectJournal} afterSubmit={afterSubmit} />
            )}
          </>
        </Section>
      </PageLayout>
    </ScreenLayout>
  )
}
