import { valueof } from 'src/@types/global'
import {
  DOSSIER_STATUS,
  DOSSIER_STATUS_TYPE,
  GLOBAL_USER_ROLES,
  PROJECT,
  PROJECT_TYPE,
  PROJECT_USER_ROLES,
  PROJECT_USER_ROLE_TYPE,
  USER_ROLES_TYPE,
} from 'src/shared/constants/constants'
import {
  MILESTONE_RESPONSIBLE_TYPE,
  MILESTONE_RESPONSIBLE_TYPE_TYPE,
  MILESTONE_STATUS,
  MILESTONE_STATUS_TYPE,
} from 'src/shared/constants/milestone-constants'
import { Utils } from 'src/shared/utils/Utils'

const hasGFCHEditableRoleForProcess = (process: PROJECT_TYPE, roles: Array<USER_ROLES_TYPE>): boolean => {
  switch (process) {
    case 'PF_KAP': {
      return [GLOBAL_USER_ROLES['PD-GFCH_PF-KAP_CONTRIBUTOR'], GLOBAL_USER_ROLES['PD-GFCH_PF-KAP_COORDINATOR']].some(
        (r) => roles.includes(r),
      )
    }
    case 'PF_PGV': {
      return [GLOBAL_USER_ROLES['PD-GFCH_PF-PGV_CONTRIBUTOR'], GLOBAL_USER_ROLES['PD-GFCH_PF-PGV_COORDINATOR']].some(
        (r) => roles.includes(r),
      )
    }
    case 'KAP': {
      return [GLOBAL_USER_ROLES['PD-GFCH_KAP_CONTRIBUTOR'], GLOBAL_USER_ROLES['PD-GFCH_KAP_COORDINATOR']].some((r) =>
        roles.includes(r),
      )
    }
    default:
      return false
  }
}

const hasGFCHViewRoleForProcess = (process: PROJECT_TYPE, roles: Array<USER_ROLES_TYPE>): boolean => {
  switch (process) {
    case 'PF_KAP': {
      return roles.some((r) => r.startsWith('PD-GFCH_PF-KAP'))
    }
    case 'PF_PGV': {
      return roles.some((r) => r.startsWith('PD-GFCH_PF-PGV'))
    }
    case 'KAP': {
      return roles.some((r) => r.startsWith('PD-GFCH_KAP'))
    }
    default:
      return false
  }
}

const hasExternalViewRoleForProcess = (process: PROJECT_TYPE, roles: Array<USER_ROLES_TYPE>): boolean => {
  switch (process) {
    case 'PF_KAP':
    case 'PF_PGV': {
      return [GLOBAL_USER_ROLES['PD-EXT_USER'], GLOBAL_USER_ROLES['PD-EXT_EXPERT']].some((role) => roles.includes(role))
    }
    default:
      return false
  }
}

const permissionToCreateProject = (
  process: PROJECT_TYPE,
  globalRoles: Array<USER_ROLES_TYPE>,
  existActiveRounds: boolean,
): boolean => {
  const hasGfchPermission = hasGFCHEditableRoleForProcess(process, globalRoles)
  const hasExternalPermission =
    (hasCantonViewRole(globalRoles) || globalRoles.includes(GLOBAL_USER_ROLES['PD-EXT_USER'])) && existActiveRounds

  return hasGfchPermission || hasExternalPermission
}

const permissionToViewNavLink = (process: PROJECT_TYPE, globalRoles: Array<USER_ROLES_TYPE>): boolean => {
  switch (process) {
    case 'PF_KAP':
    case 'PF_PGV': {
      return (
        hasGFCHViewRoleForProcess(process, globalRoles) ||
        hasCantonViewRole(globalRoles) ||
        hasExternalViewRoleForProcess(process, globalRoles)
      )
    }
    case 'KAP': {
      return hasGFCHViewRoleForProcess(process, globalRoles) || hasCantonViewRole(globalRoles)
    }
    default:
      return false
  }
}

const hasGFCHCoordinatorRoleForProcess = (process: PROJECT_TYPE, globalRoles: Array<USER_ROLES_TYPE>) => {
  switch (process) {
    case 'PF_KAP': {
      return globalRoles.includes(GLOBAL_USER_ROLES['PD-GFCH_PF-KAP_COORDINATOR'])
    }
    case 'PF_PGV': {
      return globalRoles.includes(GLOBAL_USER_ROLES['PD-GFCH_PF-PGV_COORDINATOR'])
    }
    case 'KAP': {
      return globalRoles.includes(GLOBAL_USER_ROLES['PD-GFCH_KAP_COORDINATOR'])
    }
    default:
      return false
  }
}

const hasGFCHContributorRoleForProcess = (process: PROJECT_TYPE, globalRoles: Array<USER_ROLES_TYPE>) => {
  switch (process) {
    case 'PF_KAP': {
      return globalRoles.includes(GLOBAL_USER_ROLES['PD-GFCH_PF-KAP_CONTRIBUTOR'])
    }
    case 'PF_PGV': {
      return globalRoles.includes(GLOBAL_USER_ROLES['PD-GFCH_PF-PGV_CONTRIBUTOR'])
    }
    case 'KAP': {
      return globalRoles.includes(GLOBAL_USER_ROLES['PD-GFCH_KAP_CONTRIBUTOR'])
    }
    default:
      return false
  }
}

const hasGFCHReaderRoleForProcess = (process: PROJECT_TYPE, globalRoles: Array<USER_ROLES_TYPE>) => {
  switch (process) {
    case 'PF_KAP': {
      return globalRoles.includes(GLOBAL_USER_ROLES['PD-GFCH_PF-KAP_READER'])
    }
    case 'PF_PGV': {
      return globalRoles.includes(GLOBAL_USER_ROLES['PD-GFCH_PF-PGV_READER'])
    }
    case 'KAP': {
      return globalRoles.includes(GLOBAL_USER_ROLES['PD-GFCH_KAP_READER'])
    }
    default:
      return false
  }
}

const hasExternalEditableRole = (projectUserRoles: Array<valueof<PROJECT_USER_ROLE_TYPE>>): boolean => {
  return [PROJECT_USER_ROLES.ADMIN, PROJECT_USER_ROLES.CONTRIBUTOR].some((r) => projectUserRoles.includes(r))
}

const canModifyProjectUsers = (
  roles: Array<USER_ROLES_TYPE>,
  process: PROJECT_TYPE,
  projectUserRoles?: Array<valueof<PROJECT_USER_ROLE_TYPE>>,
): boolean => {
  if (projectUserRoles?.includes(PROJECT_USER_ROLES.ADMIN)) {
    return true
  }
  if (process === PROJECT.PF_KAP) {
    return [GLOBAL_USER_ROLES['PD-GFCH_PF-KAP_CONTRIBUTOR'], GLOBAL_USER_ROLES['PD-GFCH_PF-KAP_COORDINATOR']].some(
      (r) => roles.includes(r),
    )
  } else if (process === PROJECT.PF_PGV) {
    return [GLOBAL_USER_ROLES['PD-GFCH_PF-PGV_CONTRIBUTOR'], GLOBAL_USER_ROLES['PD-GFCH_PF-PGV_COORDINATOR']].some(
      (r) => roles.includes(r),
    )
  }
  return false
}

const hasCantonEditableRole = (roles: Array<USER_ROLES_TYPE>): boolean => {
  return roles.includes(GLOBAL_USER_ROLES['PD-CANTON_KAP_CONTRIBUTOR'])
}

const hasCantonViewRole = (roles: Array<USER_ROLES_TYPE>): boolean => {
  return roles.some((r) => r.startsWith('PD-CANTON'))
}

const permissionsForProjectCommittees = (
  roles: Array<USER_ROLES_TYPE>,
  dossier_status: DOSSIER_STATUS_TYPE | null,
  process: PROJECT_TYPE,
): { canEdit: boolean; canView: boolean } => {
  switch (dossier_status) {
    case 'CONCEPT':
    case 'APPLICATION':
    case 'ACCEPTED':
    case 'REVISION':
    case 'IMPLEMENTATION': {
      const hasGFCHEditableRole = hasGFCHEditableRoleForProcess(process, roles)
      const hasGFCHRoleForProcess = hasGFCHViewRoleForProcess(process, roles)

      return {
        canView: hasGFCHRoleForProcess,
        canEdit: hasGFCHEditableRole,
      }
    }

    case 'CANCELED':
    case 'FINISHED':
    case 'REJECTED':
    case 'WITHDRAWN':
    case 'EXCLUDED': {
      const hasGFCHRoleForProcess = hasGFCHViewRoleForProcess(process, roles)
      const hasGFCHCoordinatorRole = hasGFCHCoordinatorRoleForProcess(process, roles)

      return {
        canView: hasGFCHRoleForProcess,
        canEdit: hasGFCHCoordinatorRole,
      }
    }

    default: {
      return { canEdit: false, canView: false }
    }
  }
}

const permissionsForProjectAssessment = (
  roles: Array<USER_ROLES_TYPE>,
  projectUserRoles: Array<valueof<PROJECT_USER_ROLE_TYPE>>,
  dossier_status: DOSSIER_STATUS_TYPE | null,
  process: PROJECT_TYPE,
  userIsAssessor: boolean,
): { canEdit: boolean; canView: boolean; canAdd: boolean; canDelete: boolean } => {
  switch (dossier_status) {
    case 'CONCEPT':
    case 'APPLICATION':
    case 'IMPLEMENTATION':
    case 'ACCEPTED':
    case 'REVISION': {
      const hasGFCHRoleForProcess = hasGFCHViewRoleForProcess(process, roles)
      const hasGFCHCoordinatorRole = hasGFCHCoordinatorRoleForProcess(process, roles)
      const hasGFCHContributorRole = hasGFCHContributorRoleForProcess(process, roles)
      const hasGFCHReaderRole = hasGFCHReaderRoleForProcess(process, roles)

      return {
        canView: hasGFCHRoleForProcess || projectUserRoles.includes(PROJECT_USER_ROLES['EXPERT']),
        canEdit:
          hasGFCHCoordinatorRole ||
          projectUserRoles.includes(PROJECT_USER_ROLES['EXPERT']) ||
          ((hasGFCHContributorRole || hasGFCHReaderRole) && userIsAssessor),
        canAdd: hasGFCHCoordinatorRole,
        canDelete: hasGFCHCoordinatorRole,
      }
    }

    case 'CANCELED':
    case 'FINISHED':
    case 'REJECTED':
    case 'WITHDRAWN':
    case 'EXCLUDED': {
      const hasGFCHRoleForProcess = hasGFCHViewRoleForProcess(process, roles)
      const hasGFCHCoordinatorRole = hasGFCHCoordinatorRoleForProcess(process, roles)

      return {
        canView: hasGFCHRoleForProcess || projectUserRoles.includes(PROJECT_USER_ROLES['EXPERT']),
        canEdit: hasGFCHCoordinatorRole,
        canAdd: hasGFCHCoordinatorRole,
        canDelete: hasGFCHCoordinatorRole,
      }
    }

    default: {
      return { canEdit: false, canView: false, canAdd: false, canDelete: false }
    }
  }
}

// Permissions for application: https://plaza.netcetera.com/wiki/display/GFS0086/Permissions+V2
const permissionsForProjectApplication = (
  roles: Array<USER_ROLES_TYPE>,
  projectUserRoles: Array<valueof<PROJECT_USER_ROLE_TYPE>>,
  status: DOSSIER_STATUS_TYPE | null,
  process: PROJECT_TYPE,
): { canEdit: boolean; canView: boolean; canViewSummaryTab: boolean } => {
  switch (status) {
    case 'CONCEPT':
    case 'APPLICATION':
    case 'IMPLEMENTATION':
    case 'ACCEPTED':
    case 'REVISION': {
      const hasGFCHEditableRole = hasGFCHEditableRoleForProcess(process, roles)
      const hasAnyRoleOnTheProject = projectUserRoles.length > 0
      const hasGFCHRoleForProcess = hasGFCHViewRoleForProcess(process, roles)

      return {
        canView: hasAnyRoleOnTheProject || hasGFCHRoleForProcess,
        canEdit: hasGFCHEditableRole,
        canViewSummaryTab: hasGFCHRoleForProcess,
      }
    }

    case 'CANCELED':
    case 'FINISHED':
    case 'REJECTED':
    case 'WITHDRAWN':
    case 'EXCLUDED': {
      const hasAnyRoleOnTheProject = projectUserRoles.length > 0
      const hasGFCHRoleForProcess = hasGFCHViewRoleForProcess(process, roles)
      const hasGFCHCoordinatorRole = hasGFCHCoordinatorRoleForProcess(process, roles)

      return {
        canView: hasAnyRoleOnTheProject || hasGFCHRoleForProcess,
        canEdit: hasGFCHCoordinatorRole,
        canViewSummaryTab: hasGFCHRoleForProcess,
      }
    }

    default: {
      return { canEdit: false, canView: false, canViewSummaryTab: false }
    }
  }
}

const permissionsForProjectDescription = (
  roles: Array<USER_ROLES_TYPE>,
  projectUserRoles: Array<valueof<PROJECT_USER_ROLE_TYPE>>,
  status: DOSSIER_STATUS_TYPE,
  process: PROJECT_TYPE,
  existsActiveFundingRound = false,
): {
  canEdit: boolean
  canView: boolean
  canEditProjectBudgetApproved: boolean
  canEditCommentInSummary: boolean
  canModifyProjectUsers: boolean
  canTransitToApplication: boolean
  canDeleteProject: boolean
} => {
  switch (status) {
    case 'CONCEPT': {
      const hasGFCHCoordinatorRole = hasGFCHCoordinatorRoleForProcess(process, roles)

      const hasPerProjectEditableRole = hasExternalEditableRole(projectUserRoles)
      const hasGFCHEditableRole = hasGFCHEditableRoleForProcess(process, roles)
      const isProjectLeader = projectUserRoles?.includes(PROJECT_USER_ROLES.ADMIN)
      const hasAnyRoleOnTheProject = projectUserRoles.length > 0
      const hasGFCHRoleForProcess = hasGFCHViewRoleForProcess(process, roles)
      const hasPermissionToModifyProjectUsers = canModifyProjectUsers(roles, process, projectUserRoles)

      return {
        canEdit: hasPerProjectEditableRole || hasGFCHEditableRole,
        canView: hasAnyRoleOnTheProject || hasGFCHRoleForProcess,
        canEditProjectBudgetApproved: hasGFCHEditableRole,
        canEditCommentInSummary: hasPerProjectEditableRole || hasGFCHEditableRole,
        canModifyProjectUsers: hasPermissionToModifyProjectUsers,
        canTransitToApplication: hasGFCHEditableRole || (existsActiveFundingRound && hasPerProjectEditableRole),
        canDeleteProject: hasGFCHCoordinatorRole || isProjectLeader,
      }
    }
    case 'REVISION': {
      const hasPerProjectEditableRole = hasExternalEditableRole(projectUserRoles)
      const hasGFCHEditableRole = hasGFCHEditableRoleForProcess(process, roles)
      const hasGFCHCoordinatorRole = hasGFCHCoordinatorRoleForProcess(process, roles)
      const hasAnyRoleOnTheProject = projectUserRoles.length > 0
      const hasGFCHRoleForProcess = hasGFCHViewRoleForProcess(process, roles)
      const hasPermissionToModifyProjectUsers = canModifyProjectUsers(roles, process, projectUserRoles)

      return {
        canEdit: hasPerProjectEditableRole || hasGFCHEditableRole,
        canView: hasAnyRoleOnTheProject || hasGFCHRoleForProcess,
        canEditProjectBudgetApproved: hasGFCHEditableRole,
        canEditCommentInSummary: hasPerProjectEditableRole || hasGFCHEditableRole,
        canModifyProjectUsers: hasPermissionToModifyProjectUsers,
        canTransitToApplication: hasGFCHCoordinatorRole || hasPerProjectEditableRole,
        canDeleteProject: false,
      }
    }
    case 'APPLICATION':
    case 'IMPLEMENTATION':
    case 'ACCEPTED': {
      const hasGlobalRole = hasGFCHEditableRoleForProcess(process, roles)
      const hasAnyRoleOnTheProject = projectUserRoles.length > 0
      const hasGFCHRoleForProcess = hasGFCHViewRoleForProcess(process, roles)
      const hasAdminOrContributorProjectRole = hasExternalEditableRole(projectUserRoles)
      const hasPermissionToModifyProjectUsers = canModifyProjectUsers(roles, process, projectUserRoles)
      const hasGFCHCoordinatorRole = hasGFCHCoordinatorRoleForProcess(process, roles)
      return {
        canEdit: hasGlobalRole,
        canView: hasAnyRoleOnTheProject || hasGFCHRoleForProcess,
        canEditProjectBudgetApproved: hasGFCHCoordinatorRole,
        canEditCommentInSummary: hasAdminOrContributorProjectRole || hasGlobalRole,
        canModifyProjectUsers: hasPermissionToModifyProjectUsers,
        canTransitToApplication: false,
        canDeleteProject: false,
      }
    }
    case 'CANCELED':
    case 'FINISHED':
    case 'REJECTED':
    case 'WITHDRAWN':
    case 'EXCLUDED': {
      const hasAnyRoleOnTheProject = projectUserRoles.length > 0
      const hasGFCHRoleForProcess = hasGFCHViewRoleForProcess(process, roles)
      const hasGFCHCoordinatorRole = hasGFCHCoordinatorRoleForProcess(process, roles)

      return {
        canEdit: hasGFCHCoordinatorRole,
        canView: hasAnyRoleOnTheProject || hasGFCHRoleForProcess,
        canEditProjectBudgetApproved: hasGFCHCoordinatorRole,
        canEditCommentInSummary: hasGFCHCoordinatorRole,
        canModifyProjectUsers: hasGFCHCoordinatorRole,
        canTransitToApplication: false,
        canDeleteProject: false,
      }
    }

    default:
      return {
        canEdit: false,
        canView: false,
        canEditProjectBudgetApproved: false,
        canEditCommentInSummary: false,
        canModifyProjectUsers: false,
        canTransitToApplication: false,
        canDeleteProject: false,
      }
  }
}

const permissionToAdministrateUsers = (
  roles: Array<USER_ROLES_TYPE>,
): {
  canView: boolean
  canEdit: boolean
} => {
  const allowedGlobalRolesToViewAndEdit = [
    GLOBAL_USER_ROLES['PD-GFCH_PF-KAP_COORDINATOR'],
    GLOBAL_USER_ROLES['PD-GFCH_PF-PGV_COORDINATOR'],
    GLOBAL_USER_ROLES['PD-GFCH_KAP_COORDINATOR'],
  ]

  const canViewAndEdit = allowedGlobalRolesToViewAndEdit.some((role) => roles.includes(GLOBAL_USER_ROLES[role]))
  return {
    canView: canViewAndEdit,
    canEdit: canViewAndEdit,
  }
}

const permissionToAdministrateFundingRounds = (
  roles: Array<USER_ROLES_TYPE>,
): {
  canView: boolean
  canEdit: boolean
} => {
  const allowedGlobalRolesToView = [
    GLOBAL_USER_ROLES['PD-GFCH_PF-KAP_COORDINATOR'],
    GLOBAL_USER_ROLES['PD-GFCH_PF-PGV_COORDINATOR'],
    GLOBAL_USER_ROLES['PD-GFCH_KAP_COORDINATOR'],
  ]
  const allowedGlobalRolesToEdit = [
    GLOBAL_USER_ROLES['PD-GFCH_PF-KAP_COORDINATOR'],
    GLOBAL_USER_ROLES['PD-GFCH_PF-PGV_COORDINATOR'],
    GLOBAL_USER_ROLES['PD-GFCH_KAP_COORDINATOR'],
  ]

  const canView = allowedGlobalRolesToView.some((role) => roles.includes(GLOBAL_USER_ROLES[role]))
  const canEdit = allowedGlobalRolesToEdit.some((role) => roles.includes(GLOBAL_USER_ROLES[role]))

  return {
    canView: canView,
    canEdit: canEdit,
  }
}

const permissionToViewAdministrationLink = (globalRoles: Array<USER_ROLES_TYPE>): boolean => {
  const { canView: canViewUserAdministration } = permissionToAdministrateUsers(globalRoles)
  const { canView: canViewRoundManagement } = permissionToAdministrateFundingRounds(globalRoles)

  return canViewUserAdministration || canViewRoundManagement
}

const permissionToFactsheets = (
  roles: Array<USER_ROLES_TYPE>,
): { canEdit: boolean; canViewFactsheetStatus: boolean; canDownloadFactsheetsReport: boolean } => {
  const isFactsheetCoordinator = roles.includes(GLOBAL_USER_ROLES['PD-GFCH_FACTSHEET_COORDINATOR'])
  const canEdit = isFactsheetCoordinator
  const canViewFactsheetStatus = isFactsheetCoordinator
  const canDownloadFactsheetsReport = Utils.isInternalRole(roles)
  return {
    canEdit,
    canViewFactsheetStatus,
    canDownloadFactsheetsReport,
  }
}

const permissionsForProjectImplementation = (
  roles: Array<USER_ROLES_TYPE>,
  projectUserRoles: Array<valueof<PROJECT_USER_ROLE_TYPE>>,
  status: DOSSIER_STATUS_TYPE | null,
  process: PROJECT_TYPE,
): { canEdit: boolean; canView: boolean; canManageMilestones: boolean } => {
  const hasGFCHEditableRole = hasGFCHEditableRoleForProcess(process, roles)
  const hasGFCHRoleForProcess = hasGFCHViewRoleForProcess(process, roles)
  const hasGFCHCoordinatorRole = hasGFCHCoordinatorRoleForProcess(process, roles)

  switch (status) {
    case 'CONCEPT':
    case 'APPLICATION':
    case 'ACCEPTED':
    case 'REVISION': {
      return {
        canView: hasGFCHRoleForProcess,
        canEdit: hasGFCHEditableRole,
        canManageMilestones: false,
      }
    }

    case 'IMPLEMENTATION': {
      return {
        canView: hasGFCHRoleForProcess,
        canEdit: hasGFCHEditableRole,
        canManageMilestones: hasGFCHCoordinatorRole,
      }
    }

    case 'CANCELED':
    case 'FINISHED':
    case 'REJECTED':
    case 'WITHDRAWN':
    case 'EXCLUDED': {
      return {
        canView: hasGFCHRoleForProcess,
        canEdit: hasGFCHCoordinatorRole,
        canManageMilestones: false,
      }
    }

    default: {
      return { canEdit: false, canView: false, canManageMilestones: false }
    }
  }
}

const permissionsForMilestones = (
  roles: Array<USER_ROLES_TYPE>,
  projectUserRoles: Array<valueof<PROJECT_USER_ROLE_TYPE>>,
  status: DOSSIER_STATUS_TYPE,
  process: PROJECT_TYPE,
  milestoneStatus: MILESTONE_STATUS_TYPE,
  milestoneResponsibleType: MILESTONE_RESPONSIBLE_TYPE_TYPE,
): { canView: boolean; canEdit: boolean } => {
  switch (status) {
    case DOSSIER_STATUS.IMPLEMENTATION: {
      const hasGFCHRoleForProcess = hasGFCHViewRoleForProcess(process, roles)
      const hasPerProjectEditableRole = hasExternalEditableRole(projectUserRoles)
      const hasAnyRoleOnTheProject = projectUserRoles.length > 0

      const hasCoordinatorRole = hasGFCHCoordinatorRoleForProcess(process, roles)
      const hasGFCHEditableRole = hasGFCHEditableRoleForProcess(process, roles)

      if (milestoneStatus === MILESTONE_STATUS.DONE) {
        return {
          canView: hasGFCHRoleForProcess || hasAnyRoleOnTheProject,
          canEdit: hasCoordinatorRole,
        }
      }

      let canEdit = false
      if (milestoneResponsibleType === MILESTONE_RESPONSIBLE_TYPE.GFCH) {
        canEdit = hasGFCHEditableRole
      } else if (milestoneResponsibleType === MILESTONE_RESPONSIBLE_TYPE.PROJECT_ORGANIZATION) {
        canEdit = hasCoordinatorRole || hasPerProjectEditableRole
      }

      return {
        canView: hasGFCHRoleForProcess || hasAnyRoleOnTheProject,
        canEdit: canEdit,
      }
    }
    case DOSSIER_STATUS.CANCELED:
    case DOSSIER_STATUS.FINISHED: {
      const hasGFCHRoleForProcess = hasGFCHViewRoleForProcess(process, roles)
      const hasAnyRoleOnTheProject = projectUserRoles.length > 0
      const hasGFCHCoordinatorRole = hasGFCHCoordinatorRoleForProcess(process, roles)

      return {
        canView: hasGFCHRoleForProcess || hasAnyRoleOnTheProject,
        canEdit: hasGFCHCoordinatorRole,
      }
    }
    default:
      return {
        canView: false,
        canEdit: false,
      }
  }
}

const permissionsForKapProgramDescription = (
  roles: Array<USER_ROLES_TYPE>,
  status: DOSSIER_STATUS_TYPE,
  isOwnCanton: boolean,
): {
  canEdit: boolean
  canEditMeasures: boolean
  canEditApplication: boolean
  canCopyMeasures: boolean
  canViewCantonRestricted: boolean
  canView: boolean
  canManageMilestones: boolean
  canDeleteProgram: boolean
} => {
  const hasGFCHCoordinatorRole = hasGFCHCoordinatorRoleForProcess(PROJECT.KAP, roles)

  switch (status) {
    case 'CONCEPT':
    case 'CONCEPT_REWORK': {
      const hasPerProgramEditableRole = hasCantonEditableRole(roles)
      const hasGFCHEditableRole = hasGFCHEditableRoleForProcess(PROJECT.KAP, roles)
      const hasPerProgramViewRole = hasCantonViewRole(roles)
      const hasGFCHRoleForProcess = hasGFCHViewRoleForProcess(PROJECT.KAP, roles)

      return {
        canEdit: (isOwnCanton && hasPerProgramEditableRole) || hasGFCHEditableRole,
        canEditMeasures: (isOwnCanton && hasPerProgramEditableRole) || hasGFCHEditableRole,
        canEditApplication: hasGFCHEditableRole,
        canCopyMeasures: (isOwnCanton && hasPerProgramEditableRole) || hasGFCHEditableRole,
        canView: (isOwnCanton && hasPerProgramViewRole) || hasGFCHRoleForProcess,
        canViewCantonRestricted: (isOwnCanton && hasPerProgramViewRole) || hasGFCHRoleForProcess,
        canManageMilestones: false,
        canDeleteProgram: hasGFCHCoordinatorRole,
      }
    }

    case 'CONCEPT_REVIEW': {
      const hasPerProgramEditableRole = hasCantonEditableRole(roles)
      const hasPerProgramViewRole = hasCantonViewRole(roles)
      const hasGFCHRoleForProcess = hasGFCHViewRoleForProcess(PROJECT.KAP, roles)
      const hasGFCHEditableRole = hasGFCHEditableRoleForProcess(PROJECT.KAP, roles)

      return {
        canEdit: hasGFCHEditableRole,
        canEditMeasures: (isOwnCanton && hasPerProgramEditableRole) || hasGFCHEditableRole,
        canEditApplication: hasGFCHEditableRole,
        canCopyMeasures: hasGFCHEditableRole,
        canView: (isOwnCanton && hasPerProgramViewRole) || hasGFCHRoleForProcess,
        canViewCantonRestricted: (isOwnCanton && hasPerProgramViewRole) || hasGFCHRoleForProcess,
        canManageMilestones: false,
        canDeleteProgram: hasGFCHCoordinatorRole,
      }
    }
    case 'APPLICATION': {
      const hasPerProgramViewRole = hasCantonViewRole(roles)
      const hasGFCHRoleForProcess = hasGFCHViewRoleForProcess(PROJECT.KAP, roles)
      const hasGFCHEditableRole = hasGFCHEditableRoleForProcess(PROJECT.KAP, roles)

      return {
        canEdit: hasGFCHEditableRole,
        canEditMeasures: hasGFCHEditableRole,
        canEditApplication: hasGFCHEditableRole,
        canCopyMeasures: false,
        canView: (isOwnCanton && hasPerProgramViewRole) || hasGFCHRoleForProcess,
        canViewCantonRestricted: (isOwnCanton && hasPerProgramViewRole) || hasGFCHRoleForProcess,
        canManageMilestones: hasGFCHCoordinatorRole,
        canDeleteProgram: hasGFCHCoordinatorRole,
      }
    }

    case 'IMPLEMENTATION': {
      const hasPerProgramEditableRole = hasCantonEditableRole(roles)
      const hasGFCHEditableRole = hasGFCHEditableRoleForProcess(PROJECT.KAP, roles)
      const hasPerProgramViewRole = hasCantonViewRole(roles)
      const hasGFCHRoleForProcess = hasGFCHViewRoleForProcess(PROJECT.KAP, roles)

      return {
        canEdit: hasGFCHEditableRole,
        canEditMeasures: (isOwnCanton && hasPerProgramEditableRole) || hasGFCHEditableRole,
        canEditApplication: hasGFCHEditableRole,
        canCopyMeasures: false,
        canView: hasPerProgramViewRole || hasGFCHRoleForProcess,
        canViewCantonRestricted: (isOwnCanton && hasPerProgramViewRole) || hasGFCHRoleForProcess,
        canManageMilestones: hasGFCHCoordinatorRole,
        canDeleteProgram: hasGFCHCoordinatorRole,
      }
    }

    case 'CANCELED': {
      const hasPerProgramViewRole = hasCantonViewRole(roles)
      const hasGFCHRoleForProcess = hasGFCHViewRoleForProcess(PROJECT.KAP, roles)

      return {
        canEdit: hasGFCHCoordinatorRole,
        canEditMeasures: hasGFCHCoordinatorRole,
        canEditApplication: hasGFCHCoordinatorRole,
        canCopyMeasures: false,
        canView: (isOwnCanton && hasPerProgramViewRole) || hasGFCHRoleForProcess,
        canViewCantonRestricted: (isOwnCanton && hasPerProgramViewRole) || hasGFCHRoleForProcess,
        canManageMilestones: false,
        canDeleteProgram: hasGFCHCoordinatorRole,
      }
    }

    case 'FINISHED': {
      const hasPerProgramViewRole = hasCantonViewRole(roles)
      const hasGFCHRoleForProcess = hasGFCHViewRoleForProcess(PROJECT.KAP, roles)

      return {
        canEdit: hasGFCHCoordinatorRole,
        canEditMeasures: hasGFCHCoordinatorRole,
        canEditApplication: hasGFCHCoordinatorRole,
        canCopyMeasures: false,
        canView: hasPerProgramViewRole || hasGFCHRoleForProcess,
        canViewCantonRestricted: (isOwnCanton && hasPerProgramViewRole) || hasGFCHRoleForProcess,
        canManageMilestones: false,
        canDeleteProgram: hasGFCHCoordinatorRole,
      }
    }

    default:
      return {
        canEdit: false,
        canEditMeasures: false,
        canEditApplication: false,
        canCopyMeasures: false,
        canView: false,
        canViewCantonRestricted: false,
        canManageMilestones: false,
        canDeleteProgram: false,
      }
  }
}

const permissionToCreateKapProgram = (globalRoles: Array<USER_ROLES_TYPE>): boolean => {
  return hasGFCHEditableRoleForProcess(PROJECT.KAP, globalRoles) || hasCantonEditableRole(globalRoles)
}

const permissionsForKapMilestones = (
  roles: Array<USER_ROLES_TYPE>,
  status: DOSSIER_STATUS_TYPE,
  milestoneStatus: MILESTONE_STATUS_TYPE,
  milestoneResponsibleType: MILESTONE_RESPONSIBLE_TYPE_TYPE,
  isOwnCanton: boolean,
): { canView: boolean; canEdit: boolean } => {
  switch (status) {
    case 'APPLICATION':
    case 'IMPLEMENTATION': {
      const hasPerProgramViewRole = hasCantonViewRole(roles)
      const hasGFCHRoleForProcess = hasGFCHViewRoleForProcess(PROJECT.KAP, roles)

      const hasPerProgramEditableRole = hasCantonEditableRole(roles)
      const hasGFCHEditableRole = hasGFCHEditableRoleForProcess(PROJECT.KAP, roles)
      const isCantonResponsible = milestoneResponsibleType === 'PROJECT_ORGANIZATION'
      const hasCoordinatorRole = hasGFCHCoordinatorRoleForProcess(PROJECT.KAP, roles)

      if (milestoneStatus === MILESTONE_STATUS.DONE) {
        return {
          canView: hasGFCHRoleForProcess || (isOwnCanton && hasPerProgramViewRole),
          canEdit: hasCoordinatorRole,
        }
      }

      return {
        canEdit:
          hasCoordinatorRole ||
          (!isCantonResponsible && hasGFCHEditableRole) ||
          (isCantonResponsible && isOwnCanton && hasPerProgramEditableRole),
        canView: hasGFCHRoleForProcess || (isOwnCanton && hasPerProgramViewRole),
      }
    }

    case 'CANCELED':
    case 'FINISHED': {
      const hasPerProgramViewRole = hasCantonViewRole(roles)
      const hasGFCHRoleForProcess = hasGFCHViewRoleForProcess(PROJECT.KAP, roles)
      const hasGFCHCoordinatorRole = hasGFCHCoordinatorRoleForProcess(PROJECT.KAP, roles)

      return {
        canEdit: hasGFCHCoordinatorRole,
        canView: hasGFCHRoleForProcess || (isOwnCanton && hasPerProgramViewRole),
      }
    }

    default:
      return {
        canEdit: false,
        canView: false,
      }
  }
}

const permissionToViewNavLinkForReporting = (
  userRoles: Array<USER_ROLES_TYPE>,
): {
  canViewReporting: boolean
  canViewPfKapReporting: boolean
  canViewPfPgvReporting: boolean
  canViewKapReporting: boolean
} => {
  const hasPfKapReportingAccess = hasGFCHViewRoleForProcess(PROJECT.PF_KAP, userRoles)
  const hasPfPgvReportingAccess = hasGFCHViewRoleForProcess(PROJECT.PF_PGV, userRoles)
  const hasKapReportingAccess = hasGFCHViewRoleForProcess(PROJECT.KAP, userRoles) || hasCantonViewRole(userRoles)
  const hasReportingAccess = hasPfKapReportingAccess || hasPfPgvReportingAccess || hasKapReportingAccess

  return {
    canViewReporting: hasReportingAccess,
    canViewPfKapReporting: hasPfKapReportingAccess,
    canViewPfPgvReporting: hasPfPgvReportingAccess,
    canViewKapReporting: hasKapReportingAccess,
  }
}

const permissionsForFinalMilestoneInternalAssessment = (
  roles: Array<USER_ROLES_TYPE>,
  process: PROJECT_TYPE,
): { canEditInternalAssessment: boolean } => {
  const hasGFCHEditableRole = hasGFCHEditableRoleForProcess(process, roles)

  return {
    canEditInternalAssessment: hasGFCHEditableRole,
  }
}

const permissionsForSuccessFactors = (globalRoles: Array<USER_ROLES_TYPE>): { canView: boolean; canEdit: boolean } => {
  const canView = [
    GLOBAL_USER_ROLES['PD-SUCCESS_FACTOR_CONTRIBUTOR'],
    GLOBAL_USER_ROLES['PD-GFCH_SUCCESS_FACTOR_COORDINATOR'],
  ].some((role) => globalRoles.includes(role))

  const canEdit = globalRoles.includes(GLOBAL_USER_ROLES['PD-SUCCESS_FACTOR_CONTRIBUTOR'])

  return {
    canView: canView,
    canEdit: canEdit,
  }
}

const permissionToDownloadKapFinancialsReport = (globalRoles: Array<USER_ROLES_TYPE>): boolean => {
  return hasGFCHViewRoleForProcess(PROJECT.KAP, globalRoles)
}

const permissionToEditProjectMilestoneConditions = (
  process: PROJECT_TYPE,
  globalRoles: Array<USER_ROLES_TYPE>,
  status: DOSSIER_STATUS_TYPE,
  milestoneStatus: MILESTONE_STATUS_TYPE,
): boolean => {
  switch (status) {
    case DOSSIER_STATUS.IMPLEMENTATION: {
      if (milestoneStatus === MILESTONE_STATUS.DONE) {
        return hasGFCHCoordinatorRoleForProcess(process, globalRoles)
      } else return hasGFCHEditableRoleForProcess(process, globalRoles)
    }
    case DOSSIER_STATUS.CANCELED:
    case DOSSIER_STATUS.FINISHED: {
      return hasGFCHCoordinatorRoleForProcess(process, globalRoles)
    }
    default:
      return false
  }
}

export const PermissionService = {
  permissionsForProjectDescription,
  permissionsForProjectApplication,
  permissionsForProjectAssessment,
  permissionsForProjectCommittees,
  permissionToCreateProject,
  permissionToViewNavLink,
  permissionToAdministrateUsers,
  permissionToAdministrateFundingRounds,
  permissionToFactsheets,
  permissionsForProjectImplementation,
  permissionsForMilestones,
  permissionsForKapProgramDescription,
  permissionToCreateKapProgram,
  permissionsForKapMilestones,
  permissionToViewAdministrationLink,
  permissionToViewNavLinkForReporting,
  permissionsForSuccessFactors,
  permissionToDownloadKapFinancialsReport,
  permissionsForFinalMilestoneInternalAssessment,
  permissionToEditProjectMilestoneConditions,
}
