import { gql } from 'urql'

export const fetchKapProgramByIdQuery = gql`
  query fetchKapProgramById($id: Int!) {
    kap_program_by_pk(id: $id) {
      id
      modules
      canton_code
      dossier {
        title
        short_title
        status
        start_date
        end_date
      }
    }
  }
`

export const updateKapProgramQuery = gql`
  mutation updateKapProgram($programId: Int!, $dossierFieldsSetClause: dossier_set_input!) {
    update_dossier(where: { kap_program: { id: { _eq: $programId } } }, _set: $dossierFieldsSetClause) {
      affected_rows
    }
  }
`

export const fetchKapStartingPointAndVision = gql`
  query fetchKapProgramById($id: Int!, $fetchNationalSituation: Boolean!) {
    kap_program_by_pk(id: $id) {
      id
      initial_cantonal_situation
      program_justification
      sources
      vision
      modules
    }
    initial_national_situation @include(if: $fetchNationalSituation) {
      value
    }
  }
`

export const updateKapProgramById = gql`
  mutation updateKapProgramById($id: Int!, $fields: kap_program_set_input!) {
    update_kap_program(where: { id: { _eq: $id } }, _set: $fields) {
      affected_rows
    }
  }
`
