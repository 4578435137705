import { ReactElement } from 'react'
import { useParams } from 'react-router'
import { useMessageSource } from 'src/i18n/useMessageSource'
import { resolveDetailsRoot } from 'src/routing/routing-utils'
import { ProjectBudgetGrid } from 'src/screens/shared/project/details/financials/ProjectBudgetGrid'
import { usePermissionsForProject } from 'src/service/security/PermissionHook'
import { EditButton } from 'src/shared/button/Buttons'
import { ENTITY_TYPE, FILE_TYPE } from 'src/shared/constants/constants'
import { PageLayout } from 'src/shared/layout/PageLayout'
import { ScreenLayout } from 'src/shared/layout/ScreenLayout'
import { ProjectExportMenu } from 'src/shared/menu/ProjectExportMenu'
import { FileSection } from 'src/shared/presentation/FileSection'
import { HelpAndInstructions } from 'src/shared/presentation/HelpAndInstructions'
import { Section } from 'src/shared/presentation/Section'
import { useDelayedNavigate } from 'src/shared/utils/hooks/navigation-hooks'
import { Utils } from 'src/shared/utils/Utils'

interface ProjectFinancialsProps {
  baseUrl: '/pf-kap' | '/pf-pgv'
}

export const ProjectFinancials = ({ baseUrl }: ProjectFinancialsProps): ReactElement => {
  const navigate = useDelayedNavigate()
  const { projectId } = useParams()
  const id = parseInt(projectId as string)
  const { getMessage } = useMessageSource()
  const process = Utils.resolveProcessToLowerCase(baseUrl)
  const projectType = Utils.resolveProcess(baseUrl)

  const {
    loading,
    canEdit,
    canEditProjectBudgetApproved,
    metadata: { projectBaseId },
  } = usePermissionsForProject(projectType, id)

  const handleEditProjectBudget = () => {
    navigate(resolveDetailsRoot(baseUrl).nested.FinancialsProjectBudgetEdit.params({ projectId }))
  }

  return (
    <ScreenLayout
      title={getMessage('label.navigation.project.details')}
      actions={<ProjectExportMenu process={projectType} entityId={projectBaseId as number} />}
    >
      <PageLayout>
        <>
          {!loading && (
            <>
              <Section
                key="project-budget"
                id="project-budget"
                title={getMessage('label.project.budget')}
                actionButton={<EditButton onClick={handleEditProjectBudget} hidden={!canEdit} />}
              >
                <ProjectBudgetGrid
                  budgetEditable={canEdit}
                  projectId={id}
                  baseUrl={baseUrl}
                  approvedEditable={canEditProjectBudgetApproved}
                />
              </Section>
              <FileSection
                id="detailed-budget"
                title={getMessage('label.financials.detailed.budget')}
                fileType={FILE_TYPE.PROJECT_DETAILED_BUDGET}
                entityType={ENTITY_TYPE.PROJECT_BASE}
                entityId={projectBaseId as number}
                canEdit={canEdit}
                helpAndInstructions={
                  <HelpAndInstructions labelKey={`label.help.financials.detailed.budget.${process}`} />
                }
                uploadType="SINGLE"
              />
            </>
          )}
        </>
      </PageLayout>
    </ScreenLayout>
  )
}
