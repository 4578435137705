import { Stack, Typography } from '@mui/material'
import { ReactComponent as GeneralSystemErrorIllustration } from 'src/assets/error-traffic-cone-illustration.svg'
import { useMessageSource } from 'src/i18n/useMessageSource'
import { COLORS } from 'src/theme/colors'
import { TYPOGRAPHY } from 'src/theme/typography'
import styled from 'styled-components/macro'

// In App.tsx the Theme Provider comes after this General System Error would happen
// No variant or color as a prop can be used in Typography component
// CSS declarations must be constants and values can't be linked to the theme
const TypographyStyled = styled(Typography)`
  color: ${COLORS.text.primary};
  font-weight: ${TYPOGRAPHY.h3.fontWeight};
  font-size: ${TYPOGRAPHY.h3.fontSize};
  line-height: ${TYPOGRAPHY.h3.lineHeight};
  letter-spacing: ${TYPOGRAPHY.h3.letterSpacing};
`

export const GeneralSystemErrorAlert = () => {
  const { getMessage } = useMessageSource()
  let message = getMessage('notification.error.general')
  if (message === 'notification.error.general') {
    // the translations are not set, use general english(?) message for know
    // TODO smarkovs 2021-11-08: Figure out what to show here (and in which language)
    message = 'General system error. Please try refreshing the page later.'
  }

  return (
    <Stack spacing={3} alignItems="center" justifyContent="center" height="100%">
      <GeneralSystemErrorIllustration height="7rem" width="7rem" />
      <TypographyStyled variant="h3">{message}</TypographyStyled>
    </Stack>
  )
}
