import { ReactElement } from 'react'
import { useParams } from 'react-router'
import { Outlet } from 'react-router-dom'
import { Route } from 'src/routing/routing'
import { resolveDetailsRoot } from 'src/routing/routing-utils'
import { SecondLevelNavigation } from 'src/routing/SecondLevelNavigation'
import { usePermissionsForProject } from 'src/service/security/PermissionHook'
import { PageLayout } from 'src/shared/layout/PageLayout'
import { SecondLevelNavigationContainer } from 'src/shared/layout/SecondLevelNavigationContainer'
import { NotAuthorized } from 'src/shared/not-authorized/NotAuthorized'
import { useActiveRoute } from 'src/shared/utils/hooks/route-hooks'
import { Utils } from 'src/shared/utils/Utils'

interface Props {
  baseUrl: '/pf-kap' | '/pf-pgv'
}

export const ProjectDetailsIndex = ({ baseUrl }: Props): ReactElement => {
  const { projectId } = useParams()
  const process = Utils.resolveProcess(baseUrl)
  const { loading, canView } = usePermissionsForProject(process, parseInt(projectId as string))
  const activeRoute = useActiveRoute(baseUrl, resolveDetailsRoot(baseUrl) as unknown as Route, projectId)

  const showNavigation = activeRoute && !activeRoute.hideNavigation

  if (!loading && !canView) {
    return <NotAuthorized />
  }

  return (
    <>
      {!loading && (
        <SecondLevelNavigationContainer $showNavigation={showNavigation}>
          {showNavigation && (
            <PageLayout>
              <SecondLevelNavigation
                activeRoute={activeRoute}
                route={resolveDetailsRoot(baseUrl) as unknown as Route}
                baseUrl={resolveDetailsRoot(baseUrl).params({ projectId })}
              />
            </PageLayout>
          )}
          <Outlet />
        </SecondLevelNavigationContainer>
      )}
    </>
  )
}
