import { gql } from 'urql'

export const fetchKapGoalByIdQuery = gql`
  query fetchKapGoalById($id: Int!) {
    kap_goal(where: { id: { _eq: $id } }) {
      id
      name
      description
      sort_number
      kap_goal_measures {
        kap_measure {
          id
          name
          sort_number
          level
        }
      }
    }
  }
`

export const fetchKapGoalsByProgramIdQuery = gql`
  query fetchKapGoalsByProgramId($id: Int!) {
    kap_goal(order_by: { sort_number: asc }, where: { kap_program_id: { _eq: $id } }) {
      id
      name
      description
      sort_number
      kap_goal_measures {
        kap_measure {
          id
          sort_number
          level
        }
      }
      kap_goal_annual_reports {
        id
      }
    }
  }
`

export const updateKapGoalSortNumbersQuery = gql`
  mutation updateKapGoalSortNumbers($goal1Id: Int!, $goal1SortNumber: Int!, $goal2Id: Int!, $goal2SortNumber: Int!) {
    goal1: update_kap_goal(where: { id: { _eq: $goal1Id } }, _set: { sort_number: $goal1SortNumber }) {
      affected_rows
    }
    goal2: update_kap_goal(where: { id: { _eq: $goal2Id } }, _set: { sort_number: $goal2SortNumber }) {
      affected_rows
    }
  }
`

export const fetchKapMeasuresByProgramIdQuery = gql`
  query fetchKapMeasuresByProgramId($programId: Int!) {
    kap_measure(where: { kap_program_id: { _eq: $programId } }) {
      id
      name
      level
      sort_number
    }
  }
`

export const updateKapGoalMutation = gql`
  mutation updateKapGoal(
    $id: Int!
    $name: String
    $description: String
    $measuresToBeAdded: [kap_goal_measure_insert_input!]!
  ) {
    update_kap_goal(where: { id: { _eq: $id } }, _set: { name: $name, description: $description }) {
      affected_rows
    }
    delete_kap_goal_measure(where: { kap_goal_id: { _eq: $id } }) {
      affected_rows
    }
    insert_kap_goal_measure(objects: $measuresToBeAdded) {
      affected_rows
    }
  }
`

export const deleteKapGoalByPkMutation = gql`
  mutation deleteKapGoalByPk($id: Int!) {
    delete_kap_goal(where: { id: { _eq: $id } }) {
      affected_rows
    }
  }
`

export const insertKapGoalMutation = gql`
  mutation insertKapGoal(
    $name: String
    $description: String
    $programId: Int!
    $measuresToBeAdded: [kap_goal_measure_insert_input!]!
  ) {
    insert_kap_goal(
      objects: {
        name: $name
        description: $description
        kap_program_id: $programId
        kap_goal_measures: { data: $measuresToBeAdded }
      }
    ) {
      affected_rows
    }
  }
`
