import { DialogActions, DialogContent, Stack } from '@mui/material'
import { Box } from '@mui/system'
import { FormApi } from 'final-form'
import combinedQuery from 'graphql-combine-query'
import { useEffect, useState } from 'react'
import { Form } from 'react-final-form'
import { OnChange } from 'react-final-form-listeners'
import { AssessorInput, Mutation_Root, Project_Assessment, Query_Root, SupportedLocale } from 'src/@types/graphql'
import { useMessageSource } from 'src/i18n/useMessageSource'
import {
  getAssessmentTypesQuery,
  getUsersQuery,
  insertMultipleAssessmentsMutation,
  notifyAssessorsQuery,
} from 'src/screens/shared/assessment-criteria/assessmentQueries'
import { PrimaryButton, SecondaryButton } from 'src/shared/button/Buttons'
import {
  AddProjectAssessmentType,
  ASSESSMENT_STATUS,
  ASSESSMENT_TYPE,
  internalGfchAssessorsWhereClause,
} from 'src/shared/constants/assessment-constants'
import { GLOBAL_USER_ROLES, PROJECT_TYPE, PROJECT_USER_ROLES, USER_STATUS } from 'src/shared/constants/constants'
import { Option } from 'src/shared/form/control'
import { AutoCompleteField } from 'src/shared/form/control/AutoCompleteField'
import { MultiSelectField } from 'src/shared/form/control/MultiSelectField'
import { DirtyFormSpy } from 'src/shared/form/dirty/DirtyFormSpy'
import { createDecorators } from 'src/shared/form/utils/decorators'
import { required } from 'src/shared/form/validation/validators'
import { useNotificationService } from 'src/shared/utils/NotificationService'
import { useClient } from 'urql'

interface Props {
  onCancel: () => void
  onSuccess: () => void
  onDiscard: () => void
  projectType: PROJECT_TYPE
  projectBaseId: number
  assessments: Query_Root['project_assessment'] | undefined
}

export interface AssessmentFormValues {
  instanceId: number | undefined
  internalAssessmentsIds: number[]
  externalAssessmentsIds: number[]
}

const decorators = createDecorators()

export const AddAssessmentModal = ({
  onCancel,
  onSuccess,
  onDiscard,
  projectType,
  projectBaseId,
  assessments,
}: Props) => {
  const { getMessage } = useMessageSource()
  const urqlClient = useClient()
  const notificationService = useNotificationService()

  const [initialValues] = useState<AssessmentFormValues>({
    instanceId: undefined,
    internalAssessmentsIds: [],
    externalAssessmentsIds: [],
  })
  const [assessmentInstances, setAssessmentInstances] = useState<Option[] | undefined>()
  const [gfchUsers, setGfchUsers] = useState<Option[] | undefined>()
  const [filteredGfchUsers, setFilteredGfchUsers] = useState<Option[] | undefined>()
  const [externalUsers, setExternalUsers] = useState<Option[] | undefined>()
  const [filteredExternalUsers, setFilteredExternalUsers] = useState<Option[] | undefined>()

  const mapValuesToProjectAssessmentType = (
    userIds: number[],
    assessorType: string,
    instanceId: number,
    projectBaseId: number,
  ): AddProjectAssessmentType[] =>
    userIds.map((userId) => ({
      project_base_id: projectBaseId,
      assessor_type: assessorType,
      assessor_id: userId,
      instance_id: instanceId,
      status: ASSESSMENT_STATUS.OPEN,
    })) as AddProjectAssessmentType[]

  useEffect(() => {
    const initializeData = async () => {
      const { document, variables } = combinedQuery('AssessmentInstancesAndUsers')
        .add(getAssessmentTypesQuery, { process: projectType })
        .add(getUsersQuery, {
          role: GLOBAL_USER_ROLES['PD-EXT_EXPERT'],
          userRoleWhere: internalGfchAssessorsWhereClause(projectType),
          status: USER_STATUS.ACTIVE,
        })

      const { data } = await urqlClient
        .query<{
          assessment_instance_type: Query_Root['assessment_instance_type']
          external_users: Query_Root['user_roles']
          internal_users: Query_Root['user_roles']
        }>(document, variables)
        .toPromise()

      if (data?.assessment_instance_type && data?.external_users && data?.internal_users) {
        const assessmentInstances = data?.assessment_instance_type
        const externalUsers = data?.external_users
        const gfchUsers = data?.internal_users

        const assessmentInstancesOptions = assessmentInstances
          ?.sort((a, b) => a.sort_number - b.sort_number)
          .map((item) => ({ label: getMessage(item.key), value: item.id })) as Option[]

        const externalUsersOptions = externalUsers?.map((item, index) => ({
          label: item.user.first_name
            ? `${item.user.first_name} ${item.user.last_name} (${item.user.email})`
            : item.user.email,
          value: item.user.id,
          sortNumber: index + 1,
        })) as Option[]

        const gfchUsersOptions = gfchUsers?.map((item, index) => ({
          label: item.user.first_name
            ? `${item.user.first_name} ${item.user.last_name} (${item.user.email})`
            : item.user.email,
          value: item.user.id,
          sortNumber: index + 1,
        })) as Option[]

        setAssessmentInstances(assessmentInstancesOptions)
        setExternalUsers(externalUsersOptions)
        setFilteredExternalUsers(externalUsersOptions)
        setGfchUsers(gfchUsersOptions)
        setFilteredGfchUsers(gfchUsersOptions)
      }
    }

    initializeData()
  }, [urqlClient, getMessage, projectType])

  const handleSubmitLocal = async (values: AssessmentFormValues, _: FormApi<AssessmentFormValues>): Promise<any> => {
    const { externalAssessmentsIds, internalAssessmentsIds, instanceId } = values
    const externalAssessments = mapValuesToProjectAssessmentType(
      externalAssessmentsIds,
      ASSESSMENT_TYPE.EXTERNAL,
      instanceId as number,
      projectBaseId,
    )
    const internalAssessments = mapValuesToProjectAssessmentType(
      internalAssessmentsIds,
      ASSESSMENT_TYPE.INTERNAL,
      instanceId as number,
      projectBaseId,
    )

    const projectUsersList = values.externalAssessmentsIds.map((item) => ({
      project_base_id: projectBaseId,
      user_id: item,
      type: PROJECT_USER_ROLES.EXPERT,
    }))

    const queryVariables = {
      objects: externalAssessments.concat(internalAssessments),
      users: projectUsersList,
    }

    if (queryVariables.objects.length === 0) {
      onDiscard()
      return
    }

    const { data } = await urqlClient
      .mutation<{
        insert_project_assessment: Mutation_Root['insert_project_assessment']
        insert_project_user: Mutation_Root['insert_project_user']
      }>(insertMultipleAssessmentsMutation, queryVariables)
      .toPromise()

    if (data) {
      const returningList = data?.insert_project_assessment?.returning as Project_Assessment[]
      sendNotificationToAssessors(returningList)
      notificationService.changesSaved()
      onSuccess()
    } else {
      notificationService.operationFailed()
    }
  }

  const sendNotificationToAssessors = async (returningProjectAssessment: Project_Assessment[]) => {
    const assessors: AssessorInput[] = returningProjectAssessment
      .map((projectAssessment) => ({
        firstName: projectAssessment.user.first_name ?? '',
        lastName: projectAssessment.user.last_name ?? '',
        email: projectAssessment.user.email ?? '',
        language: projectAssessment.user.language as SupportedLocale,
      }))
      .filter((_, index, self) => self.findIndex((o: any) => o.email === self[index].email) === index)

    const { data, error } = await urqlClient
      .mutation<{
        notifyAssessors: Mutation_Root['notifyAssessors']
      }>(notifyAssessorsQuery, { assessors })
      .toPromise()

    if (error || (data && data.notifyAssessors.status === 'FAILURE')) {
      notificationService.operationFailed()
    }
  }

  const filterExistingUsers = (instanceId: number) => {
    const currentAssessorsInInstance = assessments?.filter((assessment) => assessment.instance_id === instanceId) || []
    const filteredExternalUsers = externalUsers?.filter(
      (user) => !currentAssessorsInInstance.some((assessment) => assessment.assessor_id === user.value),
    )
    const filteredInternalUsers = gfchUsers?.filter(
      (user) => !currentAssessorsInInstance.some((assessment) => assessment.assessor_id === user.value),
    )
    setFilteredExternalUsers(filteredExternalUsers)
    setFilteredGfchUsers(filteredInternalUsers)
  }

  return (
    <Box>
      {assessmentInstances && filteredExternalUsers && filteredGfchUsers && (
        <Form<AssessmentFormValues>
          initialValues={initialValues}
          onSubmit={handleSubmitLocal}
          decorators={decorators}
          mutators={{
            clearInternalUsers: (_, state, utils) => {
              utils.changeValue(state, 'internalAssessmentsIds', () => [])
            },
            clearExternalUsers: (_, state, utils) => {
              utils.changeValue(state, 'externalAssessmentsIds', () => [])
            },
          }}
          render={({ form, handleSubmit }) => {
            return (
              <form onSubmit={handleSubmit} noValidate>
                <DialogContent>
                  <Stack spacing={2}>
                    <AutoCompleteField
                      required
                      label={getMessage('label.instance')}
                      name="instanceId"
                      options={assessmentInstances}
                      validate={required()}
                    />
                    <OnChange name="instanceId">
                      {(value) => {
                        filterExistingUsers(value)
                        form.mutators.clearInternalUsers()
                        form.mutators.clearExternalUsers()
                      }}
                    </OnChange>
                    <MultiSelectField
                      options={filteredGfchUsers}
                      name="internalAssessmentsIds"
                      label={getMessage('label.users.internal')}
                      unavailableOptionsMessage={getMessage('label.assessment.no.available.users')}
                    />
                    <MultiSelectField
                      options={filteredExternalUsers}
                      name="externalAssessmentsIds"
                      label={getMessage('label.users.external')}
                      unavailableOptionsMessage={getMessage('label.assessment.no.available.users')}
                    />
                    <DirtyFormSpy />
                  </Stack>
                </DialogContent>
                <DialogActions>
                  <SecondaryButton messageKey="button.close" onClick={onCancel} />
                  <PrimaryButton messageKey="button.submit" type="submit" />
                </DialogActions>
              </form>
            )
          }}
        />
      )}
    </Box>
  )
}
