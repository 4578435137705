import { Divider } from '@mui/material'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { ReactElement, ReactNode } from 'react'
import { useEnvironment } from 'src/env/EnvironmentStore'
import { useMessageSource } from 'src/i18n/useMessageSource'
import { BaseButton } from 'src/shared/button/Buttons'
import { BibliographyIcon } from 'src/shared/icons/Icons'
import { HtmlRenderer } from 'src/shared/presentation/HtmlRenderer'
import { useHashScroll } from 'src/shared/utils/hooks/navigation-hooks'
import { useUserLocale } from 'src/user/UserContext'
import styled from 'styled-components/macro'

interface Props {
  title: string
  code: string
  children?: ReactNode
  comment?: string
  actionButton?: ReactNode
  id?: string
}

const BoxStyled = styled(Box)`
  &:last-child {
    margin-bottom: 0;
  }
`

export const AreaOfActivitySection = ({ title, actionButton, comment, code, id, children }: Props): ReactElement => {
  const { backendUrl } = useEnvironment()
  const language = useUserLocale()
  const { getMessage } = useMessageSource()

  const handleBibliographyClick = (link: string) => () => {
    window.open(link, '_blank')
  }

  useHashScroll(id)

  return (
    <BoxStyled mb={5} id={id}>
      <Box display="flex" alignItems="flex-end" justifyContent="space-between">
        <Typography variant="h2">{title}</Typography>
        {actionButton}
      </Box>
      <Divider sx={{ my: 1 }} />
      <BaseButton
        messageKey={getMessage('button.success.factor.bibliography')}
        startIcon={<BibliographyIcon />}
        onClick={handleBibliographyClick(
          `${backendUrl}/api/public/bibliography/${code}?language=${language.toUpperCase()}`,
        )}
        sx={{ marginBottom: comment ? 0 : 2 }}
      />
      {comment && <HtmlRenderer html={comment} />}
      {children}
    </BoxStyled>
  )
}
