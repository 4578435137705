import { Typography } from '@mui/material'
import { ReactElement, useEffect, useState } from 'react'
import { Query_Root } from 'src/@types/graphql'
import { useMessageSource } from 'src/i18n/useMessageSource'
import { KapMilestoneMeasureReportKpiGrid } from 'src/screens/kap/implementation/milestone/details/annual-report/measures/KapMilestoneMeasureReportKpiGrid'
import { fetchKapMeasureAnnualReportsByMilestoneIdQuery } from 'src/screens/kap/implementation/milestone/kapMilestoneQueries'
import { LEVEL_TYPE } from 'src/shared/constants/constants'
import { HtmlRenderer } from 'src/shared/presentation/HtmlRenderer'
import { ReadOnlySelection, ReadOnlyTextField } from 'src/shared/presentation/ReadOnly'
import { useNotificationService } from 'src/shared/utils/NotificationService'
import { resolveKapMeasureRatingColor } from 'src/shared/utils/StylingUtils'
import { useClient } from 'urql'

interface Props {
  measureId: number
  measureLevel: LEVEL_TYPE
  milestoneId: number
  yearInFocus: string
}

interface MeasureReportContentModel {
  description: string
  planning_description: string
  rating: string
  reason: string
}

export const KapMilestoneMeasureReportContent = ({
  measureId,
  measureLevel,
  milestoneId,
  yearInFocus,
}: Props): ReactElement => {
  const yearInFocusParsed = parseInt(yearInFocus as string)

  const { getMessage } = useMessageSource()
  const notificationService = useNotificationService()
  const urqlClient = useClient()

  const [measure, setMeasure] = useState<MeasureReportContentModel>()

  useEffect(() => {
    const initData = async () => {
      const { data } = await urqlClient
        .query<
          {
            kap_measure: Query_Root['kap_measure']
          },
          { measureId: number; milestoneId: number; yearInFocus: number }
        >(fetchKapMeasureAnnualReportsByMilestoneIdQuery, {
          measureId: measureId,
          milestoneId: milestoneId,
          yearInFocus: yearInFocusParsed,
        })
        .toPromise()

      if (data && data?.kap_measure) {
        const kapMeasure = data?.kap_measure?.[0]
        const kapMeasureReport = kapMeasure?.kap_measure_annual_reports?.[0]
        const kapMeasurePlanning = kapMeasure?.kap_measure_annual_plannings?.[0]

        const kapMeasureData = {
          description: kapMeasure?.description,
          planning_description: kapMeasurePlanning?.planning_description,
          rating: kapMeasureReport?.rating,
          reason: kapMeasureReport?.reason,
        }

        setMeasure(kapMeasureData)
      } else {
        notificationService.operationFailed()
      }
    }

    initData()
  }, [urqlClient, notificationService, measureId, milestoneId, yearInFocusParsed])

  return (
    <>
      <ReadOnlyTextField text={getMessage('label.description')}>
        {measure?.description && <HtmlRenderer html={measure.description} />}
      </ReadOnlyTextField>
      <ReadOnlySelection text={getMessage('label.milestone.annual.plan.description')}>
        {measure?.planning_description && <HtmlRenderer html={measure.planning_description} />}
      </ReadOnlySelection>
      <ReadOnlyTextField text={getMessage('label.measure.rating')}>
        {measure?.rating && (
          <Typography color={resolveKapMeasureRatingColor(measure.rating)}>
            {measure.rating && getMessage(`label.kap.measure.rating.${measure.rating}`)}
          </Typography>
        )}
      </ReadOnlyTextField>
      <ReadOnlyTextField text={getMessage('label.reason')}>
        {measure?.reason && <HtmlRenderer html={measure.reason} />}
      </ReadOnlyTextField>
      <Typography variant="h6" component="p" mb={1}>
        {getMessage('label.program.kpis')}
      </Typography>
      <KapMilestoneMeasureReportKpiGrid
        measureId={measureId}
        measureLevel={measureLevel}
        reportYear={parseInt(yearInFocus)}
        mode="view"
      />
    </>
  )
}
