import { Chip, IconButton, Typography } from '@mui/material'
import { sortBy } from 'lodash'
import { useCallback, useEffect, useState } from 'react'
import { Dossier, Maybe, Mutation_Root, Project_Base, Query_Root } from 'src/@types/graphql'
import { useMessageSource } from 'src/i18n/useMessageSource'
import { resolveDetailsRoot } from 'src/routing/routing-utils'
import {
  deleteRelatedEvaluationMutation,
  queryRelatedEvaluations,
} from 'src/screens/shared/project/details/effectivity-management/effectivityManagementQueries'
import { RelatedEvaluationModalDialog } from 'src/screens/shared/project/details/effectivity-management/related-evaluation/RelatedEvaluationModalDialog'
import { BaseButton } from 'src/shared/button/Buttons'
import { PROJECT } from 'src/shared/constants/constants'
import { TYPOGRAPHY_MIXIN } from 'src/shared/constants/styling-constants'
import { DeleteIcon, LinkIcon } from 'src/shared/icons/Icons'
import { ConfirmationModalDialog } from 'src/shared/modal-dialog/ConfirmationModalDialog'
import { ModalDialog } from 'src/shared/modal-dialog/ModalDialog'
import { DefaultSectionTypography } from 'src/shared/presentation/DefaultSectionTypography'
import { HelpAndInstructions } from 'src/shared/presentation/HelpAndInstructions'
import { Section } from 'src/shared/presentation/Section'
import { S } from 'src/shared/styled/S'
import { useModalCancel } from 'src/shared/utils/hooks/modal-hooks'
import { useNotificationService } from 'src/shared/utils/NotificationService'
import { Utils } from 'src/shared/utils/Utils'
import { useUser } from 'src/user/UserContext'
import styled from 'styled-components/macro'
import { useClient } from 'urql'

interface Props {
  baseUrl: '/pf-kap' | '/pf-pgv'
  canEdit: boolean
  dossierId: Maybe<number> | undefined
}

interface RelatedEvaluation {
  id: number
  dossierId: number
  relatedDossierId: number
  relatedProjectId: number
  title: string
  canRemove: boolean
}

const IdChip = styled(Chip)`
  cursor: pointer;
  min-width: 3rem;
  &.MuiChip-filledDefault {
    background-color: ${({ theme }) => theme.colors.action.hover};
    color: ${({ theme }) => theme.colors.text.disabled};
  }
  & .MuiChip-label {
    ${TYPOGRAPHY_MIXIN.subtitle2};
  }
`

export const RelatedEvaluationSection = ({ baseUrl, canEdit, dossierId }: Props) => {
  const { user } = useUser()
  const userId = user.id
  const isGfch = Utils.isInternalRole(user.roles)
  const { getMessage } = useMessageSource()
  const urqlClient = useClient()
  const notificationService = useNotificationService()

  const process = Utils.resolveProcess(baseUrl)
  const processToLowerCase = Utils.resolveProcessToLowerCase(baseUrl)

  const [relatedEvaluations, setRelatedEvaluations] = useState<RelatedEvaluation[]>([])
  const [linkEvaluationModalOpen, setLinkEvaluationModalOpen] = useState<boolean>(false)
  const [evaluationDeleteConfirmationOpen, setEvaluationDeleteConfirmationOpen] = useState<boolean>(false)
  const [relatedEvaluationToRemove, setRelatedEvaluationToRemove] = useState<{
    dossierId: number
    relatedDossierId: number
  }>()

  const relatedEvaluationClickHandler = (projectId: number) => () => {
    const route = resolveDetailsRoot(baseUrl).nested.EffectivityManagement.params({ projectId })
    window.open(route)
  }

  const resolveUserPermissionsOnDossier = useCallback(
    (dossier: Dossier) => {
      return isGfch
        ? true
        : dossier.project_bases.some((pb) =>
            pb.project_users.some(
              (pu) => pu.user_id === userId && ['EXTERNAL_ADMIN', 'EXTERNAL_CONTRIBUTOR'].includes(pu.type),
            ),
          )
    },
    [isGfch, userId],
  )

  const resolveProjectIdByProcess = useCallback(
    (project_base: Project_Base) => {
      return process === PROJECT.PF_KAP ? project_base.pf_kap_projects?.[0].id : project_base.pf_pgv_projects?.[0].id
    },
    [process],
  )

  const mapUniqueRelatedEvaluations = useCallback(
    (
      sourceRelatedEvaluations: Query_Root['related_evaluation'],
      destinationRelatedEvaluations: Query_Root['related_evaluation'],
    ) => {
      return [
        ...sourceRelatedEvaluations.map((i) => ({
          id: i.id,
          dossierId: i.dossier_id,
          relatedDossierId: i.related_dossier_id,
          relatedProjectId: resolveProjectIdByProcess(i.dossierByRelatedDossierId.project_bases?.[0]),
          title: i.dossierByRelatedDossierId.short_title,
          canRemove: resolveUserPermissionsOnDossier(i.dossierByRelatedDossierId),
        })),
        ...destinationRelatedEvaluations.map((i) => ({
          id: i.id,
          dossierId: i.related_dossier_id,
          relatedDossierId: i.dossier_id,
          relatedProjectId: resolveProjectIdByProcess(i.dossier.project_bases?.[0]),
          title: i.dossier.short_title,
          canRemove: resolveUserPermissionsOnDossier(i.dossier),
        })),
      ]
    },
    [resolveUserPermissionsOnDossier, resolveProjectIdByProcess],
  )

  const fetchData = useCallback(async () => {
    if (dossierId) {
      const { data } = await urqlClient
        .query<
          {
            source_related_evaluation: Query_Root['related_evaluation']
            destination_related_evaluation: Query_Root['related_evaluation']
          },
          {
            dossierId: number
            isPfKap: boolean
            isPfPgv: boolean
          }
        >(queryRelatedEvaluations, {
          dossierId,
          isPfKap: process === PROJECT.PF_KAP,
          isPfPgv: process === PROJECT.PF_PGV,
        })
        .toPromise()

      if (data && data?.source_related_evaluation && data?.destination_related_evaluation) {
        const relatedEvaluations = mapUniqueRelatedEvaluations(
          data.source_related_evaluation,
          data.destination_related_evaluation,
        )
        setRelatedEvaluations(sortBy(relatedEvaluations, 'relatedProjectId'))
      } else {
        notificationService.operationFailed()
      }
    }
  }, [urqlClient, notificationService, dossierId, mapUniqueRelatedEvaluations, process])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  const onLinkEvaluationSuccess = () => {
    fetchData()
    setLinkEvaluationModalOpen(false)
  }

  const onLinkEvaluationCancel = useModalCancel(() => setLinkEvaluationModalOpen(false))

  const handleConfirmDeleteEvaluation = async () => {
    setEvaluationDeleteConfirmationOpen(false)
    const { data, error } = await urqlClient
      .mutation<{
        delete_related_evaluation: Mutation_Root['delete_related_evaluation']
      }>(deleteRelatedEvaluationMutation, {
        dossierId: relatedEvaluationToRemove?.dossierId,
        relatedDossierId: relatedEvaluationToRemove?.relatedDossierId,
      })
      .toPromise()
    if (error || data?.delete_related_evaluation?.affected_rows === 0) {
      notificationService.operationFailed()
    } else {
      notificationService.changesSaved()
      fetchData()
    }
  }

  return (
    <>
      <Section
        title={getMessage('label.related.evaluations')}
        helpAndInstructions={<HelpAndInstructions labelKey={`label.help.related.evaluation.${process}`} />}
        actionButton={
          <BaseButton
            messageKey="button.link.evaluation"
            variant="text"
            startIcon={<LinkIcon />}
            onClick={() => setLinkEvaluationModalOpen(true)}
            hidden={!canEdit}
          />
        }
        optional
      >
        {relatedEvaluations && relatedEvaluations?.length > 0 ? (
          <>
            {relatedEvaluations.map(({ id, relatedDossierId, dossierId, title, canRemove, relatedProjectId }) => (
              <S.Card.Container key={id}>
                <S.Card.Content
                  onClick={relatedEvaluationClickHandler(relatedProjectId)}
                  tabIndex={0}
                  onKeyDown={(event: { key: string }) => {
                    if (['Enter', ' '].includes(event.key)) {
                      relatedEvaluationClickHandler(relatedProjectId)()
                    }
                  }}
                >
                  <IdChip
                    size="small"
                    label={`${getMessage(`label.project.id.character.${processToLowerCase}`)}${relatedProjectId}`}
                  />
                  <Typography variant="subtitle2">{title}</Typography>
                </S.Card.Content>
                {canRemove && canEdit && (
                  <S.Card.Actions>
                    <IconButton
                      color="primary"
                      size="large"
                      onClick={() => {
                        setEvaluationDeleteConfirmationOpen(true)
                        setRelatedEvaluationToRemove({ dossierId, relatedDossierId })
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </S.Card.Actions>
                )}
              </S.Card.Container>
            ))}
          </>
        ) : (
          <S.Card.Container $nonClickable={true}>
            <S.Card.Content>
              <DefaultSectionTypography
                noEntriesMessageKey={getMessage('label.no.linked.evaluations')}
                $standAlone={true}
              />
            </S.Card.Content>
          </S.Card.Container>
        )}
      </Section>

      <ModalDialog
        open={linkEvaluationModalOpen}
        onClose={() => setLinkEvaluationModalOpen(false)}
        title={getMessage('label.link.related.evaluation')}
        maxWidth="md"
      >
        <RelatedEvaluationModalDialog
          onCancel={onLinkEvaluationCancel}
          onSuccess={onLinkEvaluationSuccess}
          process={process}
          dossierId={dossierId as number}
          relatedEvaluations={relatedEvaluations?.map((e) => e.relatedDossierId)}
        />
      </ModalDialog>
      <ConfirmationModalDialog
        open={evaluationDeleteConfirmationOpen}
        onCancel={() => setEvaluationDeleteConfirmationOpen(false)}
        onConfirm={handleConfirmDeleteEvaluation}
      >
        {getMessage('label.unlink.related.evaluation.confirm')}
      </ConfirmationModalDialog>
    </>
  )
}
