import { AxiosResponse } from 'axios'
import { saveAs } from 'file-saver'
import { Utils } from 'src/shared/utils/Utils'
import { Axios } from 'src/service/axios/axios'
import { EnvironmentStore } from 'src/env/EnvironmentStore'

export const saveFileAs = (response: AxiosResponse): void => {
  // Blob data
  const data = response?.data
  // Filename is placed in the Content-Disposition header from backend, so we resolve it here
  const fileName = Utils.getDownloadFileName(response)
  // Saving the file using the saveAs function from file-saver
  saveAs(data, fileName)
}

export const downloadFileByUrl = async (path: string): Promise<void> => {
  const backendUrl = EnvironmentStore.backendUrl

  await downloadBlobFile(`${backendUrl}/${path}`)
}

export const downloadBlobFile = (url: string) => {
  return Axios.getAxios()
    .get(url, { responseType: 'blob' })
    .then((response) => {
      saveFileAs(response)
    })
}
