import { Card, CardContent } from '@mui/material'
import { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { Query_Root } from 'src/@types/graphql'
import { useMessageSource } from 'src/i18n/useMessageSource'
import { resolveApplicationAssessmentDetailsRoot } from 'src/routing/routing-utils'
import { getProjectAssessmentCommentsQuery } from 'src/screens/shared/assessment-criteria/assessmentQueries'
import { EditButton } from 'src/shared/button/Buttons'
import { CRITERIA_TYPE, CRITERIA_TYPE_TYPE } from 'src/shared/constants/assessment-constants'
import { HelpAndInstructions } from 'src/shared/presentation/HelpAndInstructions'
import { HtmlRenderer } from 'src/shared/presentation/HtmlRenderer'
import { Section } from 'src/shared/presentation/Section'
import { useNotificationService } from 'src/shared/utils/NotificationService'
import { Utils } from 'src/shared/utils/Utils'
import { useDelayedNavigate } from 'src/shared/utils/hooks/navigation-hooks'
import { useClient } from 'urql'

interface Props {
  baseUrl: '/pf-kap' | '/pf-pgv'
  type: CRITERIA_TYPE_TYPE
  canEdit: boolean
}

export const ApplicationAssessmentCommentSection = ({ baseUrl, type, canEdit }: Props) => {
  const { projectId, assessmentId } = useParams()
  const { getMessage } = useMessageSource()
  const navigate = useDelayedNavigate()
  const urqlClient = useClient()
  const notificationService = useNotificationService()

  const projectType = Utils.resolveProcess(baseUrl)

  const [comment, setComment] = useState<string | null>(null)

  const fetch = useCallback(async () => {
    const id = parseInt(assessmentId as string)
    const { data } = await urqlClient
      .query<
        { project_assessment_by_pk: Query_Root['project_assessment_by_pk'] },
        { id: number; isQuality: boolean; isExclusion: boolean }
      >(getProjectAssessmentCommentsQuery, {
        id,
        isQuality: type === CRITERIA_TYPE.QUALITY,
        isExclusion: type === CRITERIA_TYPE.EXCLUSION,
      })
      .toPromise()

    if (data) {
      const comment =
        type === CRITERIA_TYPE.EXCLUSION
          ? data?.project_assessment_by_pk?.exclusion_comment
          : data?.project_assessment_by_pk?.assessment_comment
      setComment(comment || null)
    } else {
      notificationService.operationFailed()
    }
  }, [urqlClient, notificationService, assessmentId, type])

  useEffect(() => {
    fetch()
  }, [fetch])

  const onEdit = () => {
    const root = resolveApplicationAssessmentDetailsRoot(baseUrl).nested
    const exclusionRoute = root.ExclusionCommentEdit
    const criteriaRoute = root.CriteriaCommentEdit
    const route = type === CRITERIA_TYPE.EXCLUSION ? exclusionRoute : criteriaRoute
    navigate(route.params({ projectId, assessmentId }))
  }

  return (
    <Section
      id="comment"
      title={getMessage('label.summary.comments')}
      helpAndInstructions={
        <HelpAndInstructions labelKey={`label.help.assessment.${type.toLowerCase()}.comment.${projectType}`} />
      }
      actionButton={<EditButton origin="content" color="primary" hidden={!canEdit} onClick={onEdit} />}
      optional
    >
      {comment && (
        <Card>
          <CardContent>
            <HtmlRenderer html={comment} />
          </CardContent>
        </Card>
      )}
    </Section>
  )
}
