import { LoadingButton } from '@mui/lab'
import { Button, DialogActions, DialogContent } from '@mui/material'
import { ReactElement, ReactNode } from 'react'
import { useMessageSource } from 'src/i18n/useMessageSource'
import { ModalDialog } from 'src/shared/modal-dialog/ModalDialog'
import styled from 'styled-components/macro'

interface WithdrawModalDialogProps {
  open: boolean
  onCancel: () => void
  onConfirm: () => void
  titleKey?: string
  contentKey?: string
  children: ReactNode
  confirmButtonKey?: string
  buttonColor?: 'primary' | 'secondary' | 'error'
  loading?: boolean
}

const DialogContentStyled = styled(DialogContent)`
  &.MuiDialogContent-root {
    padding-top: 0;
  }
`

export const SecondaryConfirmationModalDialog = ({
  open,
  onCancel,
  onConfirm,
  titleKey = 'label.confirmation.title',
  children,
  confirmButtonKey = 'button.confirm',
  buttonColor = 'secondary',
  loading,
}: WithdrawModalDialogProps): ReactElement => {
  const { getMessage } = useMessageSource()

  return (
    <ModalDialog open={open} onClose={onCancel} title={getMessage(titleKey)} maxWidth="xs" withCloseIcon={false}>
      <DialogContentStyled>{children}</DialogContentStyled>
      <DialogActions>
        <Button variant="text" color={buttonColor} onClick={onCancel}>
          {getMessage('button.cancel')}
        </Button>
        <LoadingButton variant="contained" color={buttonColor} loading={loading} onClick={onConfirm}>
          {getMessage(confirmButtonKey)}
        </LoadingButton>
      </DialogActions>
    </ModalDialog>
  )
}
