import { useMessageSource } from 'src/i18n/useMessageSource'
import { FilterFieldWrapper } from 'src/screens/shared/common/filter-card/FilterFieldWrapper'
import { FilterCheckboxField } from 'src/shared/form/control/FilterCheckboxField'

interface Props {
  name: string
  formValue: boolean | undefined
}

export const OthersFilter = ({ name, formValue }: Props) => {
  const { getMessage } = useMessageSource()

  const selectedFactsheetInitialValue = false

  return (
    <FilterFieldWrapper label={getMessage('label.reporting.filter.others')}>
      <FilterCheckboxField
        label={getMessage('label.factsheet.relation')}
        initialValue={selectedFactsheetInitialValue}
        name={name}
        formValue={formValue}
      />
    </FilterFieldWrapper>
  )
}
