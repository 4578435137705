import { ReactElement, useCallback, useContext, useEffect, useRef } from 'react'
import { FormSpy } from 'react-final-form'
import { useMessageSource } from 'src/i18n/useMessageSource'
import { usePrompt } from 'src/routing/routing-hooks'
import { DirtyContext } from 'src/shared/form/dirty/DirtyContext'

interface Props {
  gridDirty?: boolean
}

export const DirtyFormSpy = ({ gridDirty }: Props): ReactElement => {
  const { getMessage } = useMessageSource()
  const { dirty, setDirty } = useContext(DirtyContext)
  const timeoutRef: React.MutableRefObject<number | null> = useRef<number>(null)

  const setDirtyLocal = useCallback(
    (newDirty: boolean): void => {
      timeoutRef.current = setTimeout(() => setDirty(newDirty))
    },
    [setDirty],
  )

  useEffect(() => {
    if (gridDirty != undefined) {
      setDirtyLocal(gridDirty)
    }
  }, [gridDirty, setDirtyLocal])

  useEffect(() => {
    return () => window.clearTimeout(timeoutRef?.current ?? 0)
  }, [timeoutRef])

  useEffect(() => {
    setDirty(false)
  }, [setDirty])

  usePrompt(getMessage('notification.form.unsaved.changes'), dirty)

  return (
    <FormSpy
      subscription={{ dirty: true }}
      onChange={(props: { dirty: boolean }) => {
        setDirtyLocal(props.dirty)
      }}
    />
  )
}
