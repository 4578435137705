import { Tab } from '@mui/material'
import { forwardRef, ReactElement, useMemo } from 'react'
import { NavLink, NavLinkProps } from 'react-router-dom'

interface ListItemLinkProps {
  to: string
  value: any
  label: string
  end?: boolean
}

const TabNavLink = (props: ListItemLinkProps): ReactElement => {
  const { to, value, label, ...rest } = props
  const renderLink = useMemo(
    () =>
      forwardRef<any, Omit<NavLinkProps, 'to'>>(({ className: defaultStyling, ...itemRest }, ref) => (
        <NavLink
          to={to}
          ref={ref}
          className={({ isActive }) => `${defaultStyling} ${isActive ? 'Mui-selected' : ''}`}
          {...itemRest}
          end
        />
      )),
    [to],
  )

  return <Tab value={value} label={label} component={renderLink} {...rest} />
}

TabNavLink.displayName = 'TabNavLink'

export { TabNavLink }
