import { ComposePaths, ExtractRouteParams, withParams } from 'src/routing/routing-utils'
import { FactsheetBasicInformationEditPage } from 'src/screens/factsheets/factsheet/description/description/edit/FactsheetBasicInformationEditPage'
import { FactsheetDescriptionEditPage } from 'src/screens/factsheets/factsheet/description/description/edit/FactsheetDescriptionEditPage'
import { FactsheetTitleAndDescriptionEditPage } from 'src/screens/factsheets/factsheet/description/description/edit/FactsheetTitleAndDescriptionEditPage'
import { FactsheetDescriptionPage } from 'src/screens/factsheets/factsheet/description/description/FactsheetDescriptionPage'
import { FactsheetDescriptionIndexPage } from 'src/screens/factsheets/factsheet/description/FactsheetDescriptionIndexPage'
import { FactsheetFeaturesEditPage } from 'src/screens/factsheets/factsheet/description/features/edit/FactsheetFeaturesEditPage'
import { FactsheetFeaturesPage } from 'src/screens/factsheets/factsheet/description/features/FactsheetFeaturesPage'
import { FactsheetRelatedProjectsAndMeasuresPage } from 'src/screens/factsheets/factsheet/related-projects-and-measures/FactsheetRelatedProjectsAndMeasuresPage'
import { FactsheetsIndexPage } from 'src/screens/factsheets/index/FactsheetsIndexPage'
import { FACTSHEET_DESCRIPTION_EDIT_OPTION } from 'src/shared/constants/factsheet-constants'

const FactsheetsIndex = {
  path: 'factsheets',
  Component: FactsheetsIndexPage,
  navigationKey: 'label.navigation.factsheets',
} as const

const FactsheetDescriptionRoot = {
  path: 'factsheets/:factsheetId/description',
  Component: FactsheetDescriptionIndexPage,
  roles: [
    'PD-EXT_USER',
    'PD-EXT_EXPERT',
    'PD-GFCH_FACTSHEET_COORDINATOR',
    'PD-GFCH_PF-KAP_CONTRIBUTOR',
    'PD-GFCH_PF-KAP_COORDINATOR',
    'PD-GFCH_PF-KAP_READER',
    'PD-GFCH_PF-PGV_CONTRIBUTOR',
    'PD-GFCH_PF-PGV_COORDINATOR',
    'PD-GFCH_PF-PGV_READER',
    'PD-GFCH_KAP_CONTRIBUTOR',
    'PD-GFCH_KAP_COORDINATOR',
    'PD-GFCH_KAP_READER',
    'PD-CANTON_KAP_CONTRIBUTOR',
    'PD-CANTON_KAP_READER',
    'PD-SUCCESS_FACTOR_CONTRIBUTOR',
    'PD-GFCH_SUCCESS_FACTOR_COORDINATOR',
  ],
} as const

const FactsheetDescription = {
  path: 'factsheet-description',
  Component: FactsheetDescriptionPage,
  navigationKey: 'label.navigation.factsheet.description',
  primary: true,
} as const

const FactsheetBasicInformationEdit = {
  path: FACTSHEET_DESCRIPTION_EDIT_OPTION.BASIC_INFO + '/edit',
  Component: FactsheetBasicInformationEditPage,
  hideNavigation: true,
} as const

const FactsheetTitleAndDescriptionEdit = {
  path: FACTSHEET_DESCRIPTION_EDIT_OPTION.TITLE_AND_DESCRIPTION + '/edit',
  Component: FactsheetTitleAndDescriptionEditPage,
  hideNavigation: true,
} as const

const FactsheetImportantInsightsEdit = {
  path: FACTSHEET_DESCRIPTION_EDIT_OPTION.IMPORTANT_INSIGHTS + '/edit',
  Component: () => (
    <FactsheetDescriptionEditPage
      field={'important_insights'}
      sectionLabel="label.factsheet.edit.important_insights"
      helpLabel="label.help.factsheet.important_insights"
    />
  ),
  hideNavigation: true,
} as const

const FactsheetStrengthEdit = {
  path: FACTSHEET_DESCRIPTION_EDIT_OPTION.STRENGTH + '/edit',
  Component: () => (
    <FactsheetDescriptionEditPage
      field={'strength'}
      sectionLabel="label.factsheet.edit.strength"
      helpLabel="label.help.factsheet.strength"
    />
  ),
  hideNavigation: true,
} as const

const FactsheetFurtherInformationEdit = {
  path: FACTSHEET_DESCRIPTION_EDIT_OPTION.FURTHER_INFORMATION + '/edit',
  Component: () => (
    <FactsheetDescriptionEditPage
      field={'further_information'}
      sectionLabel="label.factsheet.edit.further_information"
      helpLabel="label.help.factsheet.further_information"
    />
  ),
  hideNavigation: true,
} as const

const FactsheetEvidenceOfEffectivenessEdit = {
  path: FACTSHEET_DESCRIPTION_EDIT_OPTION.EVIDENCE_OF_EFFECTIVENESS + '/edit',
  Component: () => (
    <FactsheetDescriptionEditPage
      field={'evidence_of_effectiveness'}
      sectionLabel="label.factsheet.edit.evidence_of_effectiveness"
      helpLabel="label.help.factsheet.evidence_of_effectiveness"
    />
  ),
  hideNavigation: true,
} as const

const FactsheetFeatures = {
  path: 'factsheet-features',
  Component: FactsheetFeaturesPage,
  navigationKey: 'label.navigation.factsheet.features',
  primary: false,
} as const

const FactsheetFeaturesEdit = {
  path: 'factsheet-features/:featureTypeId/edit',
  Component: FactsheetFeaturesEditPage,
  hideNavigation: true,
} as const

const FactsheetRelatedProjects = {
  path: 'factsheets/:factsheetId/related-projects-and-measures',
  Component: FactsheetRelatedProjectsAndMeasuresPage,
  roles: [
    'PD-EXT_USER',
    'PD-EXT_EXPERT',
    'PD-GFCH_FACTSHEET_COORDINATOR',
    'PD-GFCH_PF-KAP_CONTRIBUTOR',
    'PD-GFCH_PF-KAP_COORDINATOR',
    'PD-GFCH_PF-KAP_READER',
    'PD-GFCH_KAP_CONTRIBUTOR',
    'PD-GFCH_KAP_COORDINATOR',
    'PD-GFCH_KAP_READER',
    'PD-CANTON_KAP_CONTRIBUTOR',
    'PD-CANTON_KAP_READER',
  ],
} as const

export const FactsheetRoutes = {
  FactsheetsIndex: FactsheetsIndex,
  FactsheetDescriptionRoot: {
    ...FactsheetDescriptionRoot,
    params: (props: ExtractRouteParams<typeof FactsheetDescriptionRoot.path>) =>
      withParams(`/${FactsheetDescriptionRoot.path}`)(props),
    nested: {
      FactsheetDescription: {
        ...FactsheetDescription,
        params: (
          props: ExtractRouteParams<
            ComposePaths<typeof FactsheetDescriptionRoot.path, typeof FactsheetDescription.path>
          >,
        ) => withParams(`/${FactsheetDescriptionRoot.path}/${FactsheetDescription.path}`)(props),
      },
      FactsheetBasicInformationEdit: {
        ...FactsheetBasicInformationEdit,
        params: (
          props: ExtractRouteParams<
            ComposePaths<typeof FactsheetDescriptionRoot.path, typeof FactsheetBasicInformationEdit.path>
          >,
        ) => withParams(`/${FactsheetDescriptionRoot.path}/${FactsheetBasicInformationEdit.path}`)(props),
      },
      FactsheetTitleAndDescriptionEdit: {
        ...FactsheetTitleAndDescriptionEdit,
        params: (
          props: ExtractRouteParams<
            ComposePaths<typeof FactsheetDescriptionRoot.path, typeof FactsheetTitleAndDescriptionEdit.path>
          >,
        ) => withParams(`/${FactsheetDescriptionRoot.path}/${FactsheetTitleAndDescriptionEdit.path}`)(props),
      },
      FactsheetImportantInsightsEdit: {
        ...FactsheetImportantInsightsEdit,
        params: (
          props: ExtractRouteParams<
            ComposePaths<typeof FactsheetDescriptionRoot.path, typeof FactsheetImportantInsightsEdit.path>
          >,
        ) => withParams(`/${FactsheetDescriptionRoot.path}/${FactsheetImportantInsightsEdit.path}`)(props),
      },
      FactsheetStrengthEdit: {
        ...FactsheetStrengthEdit,
        params: (
          props: ExtractRouteParams<
            ComposePaths<typeof FactsheetDescriptionRoot.path, typeof FactsheetStrengthEdit.path>
          >,
        ) => withParams(`/${FactsheetDescriptionRoot.path}/${FactsheetStrengthEdit.path}`)(props),
      },
      FactsheetFurtherInformationEdit: {
        ...FactsheetFurtherInformationEdit,
        params: (
          props: ExtractRouteParams<
            ComposePaths<typeof FactsheetDescriptionRoot.path, typeof FactsheetFurtherInformationEdit.path>
          >,
        ) => withParams(`/${FactsheetDescriptionRoot.path}/${FactsheetFurtherInformationEdit.path}`)(props),
      },
      FactsheetEvidenceOfEffectivenessEdit: {
        ...FactsheetEvidenceOfEffectivenessEdit,
        params: (
          props: ExtractRouteParams<
            ComposePaths<typeof FactsheetDescriptionRoot.path, typeof FactsheetEvidenceOfEffectivenessEdit.path>
          >,
        ) => withParams(`/${FactsheetDescriptionRoot.path}/${FactsheetEvidenceOfEffectivenessEdit.path}`)(props),
      },
      FactsheetFeatures: {
        ...FactsheetFeatures,
        params: (
          props: ExtractRouteParams<ComposePaths<typeof FactsheetDescriptionRoot.path, typeof FactsheetFeatures.path>>,
        ) => withParams(`/${FactsheetDescriptionRoot.path}/${FactsheetFeatures.path}`)(props),
      },
      FactsheetFeaturesEdit: {
        ...FactsheetFeaturesEdit,
        params: (
          props: ExtractRouteParams<
            ComposePaths<typeof FactsheetDescriptionRoot.path, typeof FactsheetFeaturesEdit.path>
          >,
        ) => withParams(`/${FactsheetDescriptionRoot.path}/${FactsheetFeaturesEdit.path}`)(props),
      },
    },
  },
  FactsheetRelatedProjects: {
    ...FactsheetRelatedProjects,
    params: (props: ExtractRouteParams<typeof FactsheetRelatedProjects.path>) =>
      withParams(`/${FactsheetRelatedProjects.path}`)(props),
  },
}
