import { ComposePaths, ExtractRouteParams, withParams } from 'src/routing/routing-utils'
import { PfPgvAssessmentCriteriaPage } from 'src/screens/pf-pgv/application/assessment/details/assessment-criteria/PfPgvAssessmentCriteriaPage'
import { PfPgvExclusionPage } from 'src/screens/pf-pgv/application/assessment/details/exclusion/PfPgvExclusionPage'
import { PfPgvAssessmentDetailsIndexPage } from 'src/screens/pf-pgv/application/assessment/details/PfPgvAssessmentDetailsIndexPage'
import { PfPgvRecommendationPage } from 'src/screens/pf-pgv/application/assessment/details/recommendation/PfPgvRecomendationPage'
import { PfPgvAssessmentDetailsSummaryPage } from 'src/screens/pf-pgv/application/assessment/details/summary/PfPgvAssessmentDetailsSummaryPage'
import { PfPgvApplicationAssessmentIndexPage } from 'src/screens/pf-pgv/application/assessment/PfPgvApplicationAssessmentIndexPage'
import { PfPgvApplicationCommitteePage } from 'src/screens/pf-pgv/application/committee/PfPgvApplicationCommitteePage'
import { PfPgvApplicationInformationPage } from 'src/screens/pf-pgv/application/details/application-information/PfPgvApplicationInformationPage'
import { PfPgvApplicationDetailsIndexPage } from 'src/screens/pf-pgv/application/details/PfPgvApplicationDetailsIndexPage'
import { PfPgvApplicationSummaryPage } from 'src/screens/pf-pgv/application/details/summary/PfPgvApplicationSummaryPage'
import { PfPgvImplementationMilestonePage } from 'src/screens/pf-pgv/implementation/details/milestone/PfPgvImplementationMilestonePage'
import { PfPgvImplementationDetailsIndexPage } from 'src/screens/pf-pgv/implementation/details/PfPgvImplementationDetailsIndexPage'
import { PfPgvImplementationDetailsSummaryPage } from 'src/screens/pf-pgv/implementation/details/summary/PfPgvImplementationDetailsSummaryPage'
import { PfPgvIndexPage } from 'src/screens/pf-pgv/index/PfPgvIndexPage'
import { PfPgvBackgroundInformationPage } from 'src/screens/pf-pgv/project/details/background-information/PfPgvBackgroundInformationPage'
import { PfPgvBasicInformationEditPage } from 'src/screens/pf-pgv/project/details/basic-information/edit/PfPgvBasicInformationEditPage'
import { PfPgvBasicInformationPage } from 'src/screens/pf-pgv/project/details/basic-information/PfPgvBasicInformationPage'
import { PfPgvEvaluationMilestoneCreatePage } from 'src/screens/pf-pgv/project/details/effectivity-management/edit/PfPgvEvaluationMilestoneCreatePage'
import { PfPgvEvaluationMilestoneEditPage } from 'src/screens/pf-pgv/project/details/effectivity-management/edit/PfPgvEvaluationMilestoneEditPage'
import { PfPgvEffectivityManagementPage } from 'src/screens/pf-pgv/project/details/effectivity-management/PfPgvEffectivityManagementPage'
import { PfPgvGoalsAndMeasuresPage } from 'src/screens/pf-pgv/project/details/goals-and-measures/PfPgvGoalsAndMeasuresPage'
import { PfPgvProjectDetailsIndexPage } from 'src/screens/pf-pgv/project/details/PfPgvProjectDetailsIndexPage'
import { PfPgvProjectFeaturesPage } from 'src/screens/pf-pgv/project/details/project-features/PfPgvProjectFeaturesPage'
import { PfPgvStartingPointAndVisionPage } from 'src/screens/pf-pgv/project/details/starting-point-and-vision/PfPgvStartingPointAndVisionPage'
import { PfPgvProjectJournalCreatePage } from 'src/screens/pf-pgv/project/journal/edit/PfPgvProjectJournalCreatePage'
import { PfPgvProjectJournalEditPage } from 'src/screens/pf-pgv/project/journal/edit/PfPgvProjectJournalEditPage'
import { PfPgvProjectJournalPage } from 'src/screens/pf-pgv/project/journal/PfPgvProjectJournalPage'
import { PfPgvPermissionsPage } from 'src/screens/pf-pgv/project/permissions/PfPgvPermissionsPage'
import { ApplicationAssessmentCriteriaEditPage } from 'src/screens/shared/application/assessment/details/assessment-criteria/edit/ApplicationAssessmentCriteriaEditPage'
import { ApplicationAssessmentCommentEditPage } from 'src/screens/shared/application/assessment/details/comment/ApplicationAssessmentCommentEditPage'
import { ApplicationAssessmentExclusionEditPage } from 'src/screens/shared/application/assessment/details/exclusion/edit/ApplicationAssessmentExclusionEditPage'
import { ApplicationAssessmentRecommendationEditPage } from 'src/screens/shared/application/assessment/details/recommendation/edit/ApplicationAssessmentRecommendationEditPage'
import { ApplicationAssessmentSummaryCommentEditPage } from 'src/screens/shared/application/assessment/details/summary/edit/ApplicationAssessmentSummaryCommentEditPage'
import { ApplicationCommitteeEditPage } from 'src/screens/shared/application/committee/edit-committee/ApplicationCommitteeEditPage'
import { ApplicationInformationEditPage } from 'src/screens/shared/application/details/application-information/edit/ApplicationInformationEditPage'
import { ApplicationSummaryEditPage } from 'src/screens/shared/application/details/summary/edit/ApplicationSummaryEditPage'
import { MilestoneGoalEditPage } from 'src/screens/shared/implementation/details/milestone/details/annual/goal/edit/MilestoneGoalEditPage'
import { MilestoneFeatureKpiEditPage } from 'src/screens/shared/implementation/details/milestone/details/annual/kpi/edit/MilestoneFeatureKpiEditPage'
import { MilestoneFeatureKpiPage } from 'src/screens/shared/implementation/details/milestone/details/annual/kpi/MilestoneFeatureKpiPage'
import { MilestoneMeasureEditPage } from 'src/screens/shared/implementation/details/milestone/details/annual/measure/edit/MilestoneMeasureEditPage'
import { MilestoneMeasureCreatePage } from 'src/screens/shared/implementation/details/milestone/details/annual/measure/MilestoneMeasureCreatePage'
import { MilestonesGoalsAndMeasuresPage } from 'src/screens/shared/implementation/details/milestone/details/annual/MilestoneGoalsAndMeasuresPage'
import { MilestoneConditionsEditPage } from 'src/screens/shared/implementation/details/milestone/details/conditions/edit/MilestoneConditionsEditPage'
import { MilestoneConditionsPage } from 'src/screens/shared/implementation/details/milestone/details/conditions/MilestoneConditionsPage'
import { MilestoneDetailsEditPage } from 'src/screens/shared/implementation/details/milestone/details/default-milestone/edit/MilestoneDetailsEditPage'
import { MilestoneDetailsPage } from 'src/screens/shared/implementation/details/milestone/details/default-milestone/MilestoneDetailsPage'
import { DefaultMilestoneIndex } from 'src/screens/shared/implementation/details/milestone/details/DefaultMilestoneIndex'
import { MilestoneEvaluationEditPage } from 'src/screens/shared/implementation/details/milestone/details/evaluation/edit/MilestoneEvaluationEditPage'
import { MilestoneEvaluationPage } from 'src/screens/shared/implementation/details/milestone/details/evaluation/MilestoneEvaluationPage'
import { MilestoneAssessmentCriteriaEditPage } from 'src/screens/shared/implementation/details/milestone/details/final/assessment-criteria/edit/MilestoneAssessmentCriteriaEditPage'
import { MilestoneAssessmentCriteriaPage } from 'src/screens/shared/implementation/details/milestone/details/final/assessment-criteria/MilestoneAssessmentCriteriaPage'
import { MilestoneAssessmentCommentEditPage } from 'src/screens/shared/implementation/details/milestone/details/final/comment/MilestoneAssessmentCommentEditPage'
import { QualitativeEvaluationEditPage } from 'src/screens/shared/implementation/details/milestone/details/final/qualitative-evaluation/edit/QualitativeEvaluationEditPage'
import { QualitativeEvaluationSWOTAnalysisEditPage } from 'src/screens/shared/implementation/details/milestone/details/final/qualitative-evaluation/edit/QualitativeEvaluationSWOTAnalysisEditPage'
import { FinalReportQualitativeEvaluationPage } from 'src/screens/shared/implementation/details/milestone/details/final/qualitative-evaluation/FinalReportQualitativeEvaluationPage'
import { MilestoneFinancialsEditPage } from 'src/screens/shared/implementation/details/milestone/details/financial/edit/MilestoneFinancialsEditPage'
import { MilestoneFinancialsPage } from 'src/screens/shared/implementation/details/milestone/details/financial/MilestoneFinancialsPage'
import { MilestoneSummaryCommentEditPage } from 'src/screens/shared/implementation/details/milestone/details/summary/edit/MilestoneSummaryCommentEditPage'
import { MilestoneSummaryPage } from 'src/screens/shared/implementation/details/milestone/details/summary/MilestoneSummaryPage'
import { ImplementationSummaryCommentEditPage } from 'src/screens/shared/implementation/details/summary/edit/ImplementationSummaryCommentEditPage'
import { BackgroundInformationEditPage } from 'src/screens/shared/project/details/background-information/edit/BackgroundInformationEditPage'
import { ProjectOrganizationCreatePage } from 'src/screens/shared/project/details/basic-information/organization/edit/ProjectOrganizationCreatePage'
import { ProjectOrganizationEditPage } from 'src/screens/shared/project/details/basic-information/organization/edit/ProjectOrganizationEditPage'
import { ProjectOrganizationViewPage } from 'src/screens/shared/project/details/basic-information/organization/view/ProjectOrganizationViewPage'
import { ProjectBudgetEditPage } from 'src/screens/shared/project/details/financials/edit/ProjectBudgetEditPage'
import { ProjectFinancials } from 'src/screens/shared/project/details/financials/ProjectFinancials'
import { ProjectGoalCreatePage } from 'src/screens/shared/project/details/goals-and-measures/goal/edit/ProjectGoalCreatePage'
import { ProjectGoalEditPage } from 'src/screens/shared/project/details/goals-and-measures/goal/edit/ProjectGoalEditPage'
import { ProjectMeasureCreatePage } from 'src/screens/shared/project/details/goals-and-measures/measure/edit/ProjectMeasureCreatePage'
import { ProjectMeasureEditPage } from 'src/screens/shared/project/details/goals-and-measures/measure/edit/ProjectMeasureEditPage'
import { ProjectFeaturesEditPage } from 'src/screens/shared/project/details/project-features/edit/ProjectFeaturesEditPage'
import { ProjectFeaturesKpiEditPage } from 'src/screens/shared/project/details/project-features/edit/ProjectFeaturesKpiEditPage'
import { InitialSituationEditPage } from 'src/screens/shared/project/details/starting-point-and-vision/initial-situation/InitialSituationEditPage'
import { ProjectJustificationEditPage } from 'src/screens/shared/project/details/starting-point-and-vision/project-justification/ProjectJustificationEditPage'
import { SourcesEditPage } from 'src/screens/shared/project/details/starting-point-and-vision/sources/SourcesEditPage'
import { VisionEditPage } from 'src/screens/shared/project/details/starting-point-and-vision/vision/VisionEditPage'
import { ProjectSummaryCommentEditPage } from 'src/screens/shared/project/details/summary/edit/ProjectSummaryCommentEditPage'
import { ProjectSummaryPage } from 'src/screens/shared/project/details/summary/ProjectSummaryPage'
import { ASSESSMENT_TYPE, CRITERIA_TYPE } from 'src/shared/constants/assessment-constants'

const PfPgvIndex = {
  path: 'pf-pgv',
  Component: PfPgvIndexPage,
  roles: [
    'PD-EXT_USER',
    'PD-EXT_EXPERT',
    'PD-GFCH_PF-PGV_CONTRIBUTOR',
    'PD-GFCH_PF-PGV_COORDINATOR',
    'PD-GFCH_PF-PGV_READER',
    'PD-CANTON_KAP_READER',
    'PD-CANTON_KAP_CONTRIBUTOR',
  ],
} as const

const PfPgvDetailsRoot = {
  path: 'pf-pgv/project/:projectId/details',
  Component: PfPgvProjectDetailsIndexPage,
  roles: [
    'PD-EXT_USER',
    'PD-EXT_EXPERT',
    'PD-GFCH_PF-PGV_CONTRIBUTOR',
    'PD-GFCH_PF-PGV_COORDINATOR',
    'PD-GFCH_PF-PGV_READER',
    'PD-CANTON_KAP_READER',
    'PD-CANTON_KAP_CONTRIBUTOR',
  ],
} as const

const PfPgvBasicInformation = {
  path: 'basic-information',
  Component: PfPgvBasicInformationPage,
  navigationKey: 'label.navigation.project.basic.information',
  primary: true,
} as const

const PfPgvBasicInformationEdit = {
  path: 'basic-information/edit',
  Component: PfPgvBasicInformationEditPage,
  hideNavigation: true,
} as const

const PfPgvOrganization = {
  path: 'organization/:organizationId/:organizationRelationType',
  Component: () => <ProjectOrganizationViewPage baseUrl="/pf-pgv" />,
  hideNavigation: true,
} as const

const PfPgvOrganizationNew = {
  path: 'organization/new/:organizationRelationType',
  Component: () => <ProjectOrganizationCreatePage baseUrl="/pf-pgv" />,
  hideNavigation: true,
} as const

const PfPgvOrganizationEdit = {
  path: 'organization/:organizationId/edit/:organizationRelationType',
  Component: () => <ProjectOrganizationEditPage baseUrl="/pf-pgv" />,
  hideNavigation: true,
} as const

const PfPgvStartingPointAndVision = {
  path: 'starting-point-and-vision',
  Component: PfPgvStartingPointAndVisionPage,
  navigationKey: 'label.navigation.project.starting.point.and.vision',
} as const

const PfPgvStartingPointAndVisionInitialSituationEdit = {
  path: 'starting-point-and-vision/initial-situation/edit',
  Component: () => <InitialSituationEditPage baseUrl="/pf-pgv" />,
  hideNavigation: true,
} as const

const PfPgvStartingPointAndVisionProjectJustificationEdit = {
  path: 'starting-point-and-vision/project-justification/edit',
  Component: () => <ProjectJustificationEditPage baseUrl="/pf-pgv" />,
  hideNavigation: true,
} as const

const PfPgvStartingPointAndVisionSourcesEdit = {
  path: 'starting-point-and-vision/sources/edit',
  Component: () => <SourcesEditPage baseUrl="/pf-pgv" />,
  hideNavigation: true,
} as const

const PfPgvStartingPointAndVisionVisionEdit = {
  path: 'starting-point-and-vision/vision/edit',
  Component: () => <VisionEditPage baseUrl="/pf-pgv" />,
  hideNavigation: true,
} as const

const PfPgvProjectFeatures = {
  path: 'project-features',
  Component: PfPgvProjectFeaturesPage,
  navigationKey: 'label.navigation.project.features',
} as const

const PfPgvProjectFeaturesEdit = {
  path: 'project-features/:featureTypeId/edit',
  Component: () => <ProjectFeaturesEditPage baseUrl="/pf-pgv" />,
  hideNavigation: true,
} as const

const PfPgvProjectFeaturesKpiEdit = {
  path: 'project-features/kpi/edit',
  Component: () => <ProjectFeaturesKpiEditPage baseUrl="/pf-pgv" />,
  hideNavigation: true,
} as const

const PfPgvGoalsAndMeasures = {
  path: 'goals-and-measures',
  Component: PfPgvGoalsAndMeasuresPage,
  navigationKey: 'label.navigation.project.goals.and.measures',
} as const

const PfPgvProjectGoalNew = {
  path: 'goals-and-measures/goal/new',
  Component: () => <ProjectGoalCreatePage baseUrl="/pf-pgv" />,
  hideNavigation: true,
} as const

const PfPgvProjectGoalEdit = {
  path: 'goals-and-measures/goal/:goalId/edit',
  Component: () => <ProjectGoalEditPage baseUrl="/pf-pgv" />,
  hideNavigation: true,
} as const

const PfPgvProjectMeasureNew = {
  path: 'goals-and-measures/measure/new',
  Component: () => <ProjectMeasureCreatePage baseUrl="/pf-pgv" />,
  hideNavigation: true,
} as const

const PfPgvProjectMeasureEdit = {
  path: 'goals-and-measures/measure/:measureId/edit',
  Component: () => <ProjectMeasureEditPage baseUrl="/pf-pgv" />,
  hideNavigation: true,
} as const

const PfPgvFinancials = {
  path: 'financials',
  Component: () => <ProjectFinancials baseUrl="/pf-pgv" />,
  navigationKey: 'label.navigation.project.financials',
} as const

const PfPgvFinancialsProjectBudgetEdit = {
  path: 'financials/project-budget/edit',
  Component: () => <ProjectBudgetEditPage baseUrl="/pf-pgv" />,
  hideNavigation: true,
} as const

const PfPgvBackgroundInformation = {
  path: 'background-information',
  Component: PfPgvBackgroundInformationPage,
  navigationKey: 'label.navigation.project.background.information',
} as const

const PfPgvBackgroundInformationSustainabilityEdit = {
  path: 'background-information/sustainability/edit',
  Component: () => <BackgroundInformationEditPage baseUrl="/pf-pgv" field="sustainability" />,
  hideNavigation: true,
} as const

const PfPgvBackgroundInformationParticipationEdit = {
  path: 'background-information/participation/edit',
  Component: () => <BackgroundInformationEditPage baseUrl="/pf-pgv" field="participation" />,
  hideNavigation: true,
} as const

const PfPgvBackgroundInformationEqualOpportunityEdit = {
  path: 'background-information/equal_opportunity/edit',
  Component: () => <BackgroundInformationEditPage baseUrl="/pf-pgv" field="equal_opportunity" />,
  hideNavigation: true,
} as const

const PfPgvBackgroundInformationMultiplicationPotentialEdit = {
  path: 'background-information/multiplication_potential/edit',
  Component: () => <BackgroundInformationEditPage baseUrl="/pf-pgv" field="multiplication_potential" />,
  hideNavigation: true,
} as const

const PfPgvBackgroundInformationAdditionalInformationEdit = {
  path: 'background-information/additional_information/edit',
  Component: () => <BackgroundInformationEditPage baseUrl="/pf-pgv" field="additional_information" />,
  hideNavigation: true,
} as const

const PfPgvEffectivityManagement = {
  path: 'effectivity',
  Component: PfPgvEffectivityManagementPage,
  navigationKey: 'label.navigation.project.effectivity',
} as const

const PfPgvEffectivityManagementEvaluationMilestoneNew = {
  path: 'effectivity/evaluation-milestone/new',
  Component: () => <PfPgvEvaluationMilestoneCreatePage />,
  hideNavigation: true,
} as const

const PfPgvEffectivityManagementEvaluationMilestoneEdit = {
  path: 'effectivity/evaluation-milestone/:evaluationMilestoneId/edit',
  Component: () => <PfPgvEvaluationMilestoneEditPage />,
  hideNavigation: true,
} as const

const PfPgvSummary = {
  path: 'summary',
  Component: () => <ProjectSummaryPage baseUrl="/pf-pgv" />,
  navigationKey: 'label.navigation.project.summary',
} as const

const PfPgvSummaryCommentEdit = {
  path: 'summary/comment/edit',
  Component: () => <ProjectSummaryCommentEditPage baseUrl="/pf-pgv" />,
  hideNavigation: true,
} as const

const PfPgvProjectPermissions = {
  path: 'pf-pgv/project/:projectId/permissions',
  Component: PfPgvPermissionsPage,
  roles: [
    'PD-EXT_USER',
    'PD-EXT_EXPERT',
    'PD-GFCH_PF-PGV_CONTRIBUTOR',
    'PD-GFCH_PF-PGV_COORDINATOR',
    'PD-GFCH_PF-PGV_READER',
    'PD-CANTON_KAP_READER',
    'PD-CANTON_KAP_CONTRIBUTOR',
  ],
} as const

const PfPgvProjectJournal = {
  path: 'pf-pgv/project/:projectId/project-journal',
  Component: PfPgvProjectJournalPage,
  roles: ['PD-GFCH_PF-PGV_CONTRIBUTOR', 'PD-GFCH_PF-PGV_COORDINATOR', 'PD-GFCH_PF-PGV_READER'],
} as const

const PfPgvProjectJournalNew = {
  path: 'pf-pgv/project/:projectId/project-journal/new',
  Component: PfPgvProjectJournalCreatePage,
  roles: ['PD-GFCH_PF-PGV_CONTRIBUTOR', 'PD-GFCH_PF-PGV_COORDINATOR'],
} as const

const PfPgvProjectJournalEdit = {
  path: 'pf-pgv/project/:projectId/project-journal/:journalId/edit',
  Component: PfPgvProjectJournalEditPage,
  roles: ['PD-GFCH_PF-PGV_CONTRIBUTOR', 'PD-GFCH_PF-PGV_COORDINATOR'],
} as const

const PfPgvApplicationDetailsRoot = {
  path: 'pf-pgv/project/:projectId/application/details',
  Component: PfPgvApplicationDetailsIndexPage,
  roles: [
    'PD-EXT_USER',
    'PD-EXT_EXPERT',
    'PD-GFCH_PF-PGV_CONTRIBUTOR',
    'PD-GFCH_PF-PGV_COORDINATOR',
    'PD-GFCH_PF-PGV_READER',
    'PD-CANTON_KAP_READER',
    'PD-CANTON_KAP_CONTRIBUTOR',
  ],
} as const

const PfPgvApplicationInformation = {
  path: 'information',
  Component: PfPgvApplicationInformationPage,
  navigationKey: 'label.navigation.application.information',
  primary: true,
} as const

const PfPgvApplicationInformationEdit = {
  path: 'information/edit',
  Component: () => <ApplicationInformationEditPage baseUrl="/pf-pgv" />,
  hideNavigation: true,
} as const

const PfPgvApplicationSummary = {
  path: 'summary',
  Component: PfPgvApplicationSummaryPage,
  navigationKey: 'label.navigation.project.summary',
} as const

const PfPgvApplicationSummaryCommentEdit = {
  path: 'summary/edit',
  Component: () => <ApplicationSummaryEditPage baseUrl="/pf-pgv" />,
  hideNavigation: true,
} as const

const PfPgvApplicationAssessment = {
  path: 'pf-pgv/project/:projectId/application/assessment',
  Component: PfPgvApplicationAssessmentIndexPage,
  roles: ['PD-EXT_EXPERT', 'PD-GFCH_PF-PGV_CONTRIBUTOR', 'PD-GFCH_PF-PGV_COORDINATOR', 'PD-GFCH_PF-PGV_READER'],
} as const

const PfPgvApplicationAssessmentDetailsRoot = {
  path: 'pf-pgv/project/:projectId/application/assessment/:assessmentId/details',
  Component: PfPgvAssessmentDetailsIndexPage,
  roles: ['PD-EXT_EXPERT', 'PD-GFCH_PF-PGV_CONTRIBUTOR', 'PD-GFCH_PF-PGV_COORDINATOR', 'PD-GFCH_PF-PGV_READER'],
} as const

const PfPgvApplicationAssessmentExclusion = {
  path: 'exclusion',
  Component: PfPgvExclusionPage,
  navigationKey: 'label.navigation.assessment.exclusion',
  primary: true,
} as const

const PfPgvApplicationAssessmentExclusionEdit = {
  path: 'exclusion/edit',
  Component: () => <ApplicationAssessmentExclusionEditPage baseUrl="/pf-pgv" />,
  hideNavigation: true,
} as const

const PfPgvApplicationAssessmentExclusionCommentEdit = {
  path: 'exclusion/comment/edit',
  Component: () => <ApplicationAssessmentCommentEditPage baseUrl="/pf-pgv" type={CRITERIA_TYPE.EXCLUSION} />,
  hideNavigation: true,
} as const

const PfPgvApplicationAssessmentCriteria = {
  path: 'criteria',
  Component: PfPgvAssessmentCriteriaPage,
  navigationKey: 'label.navigation.assessment',
} as const

const PfPgvApplicationAssessmentCriteriaEdit = {
  path: 'criteria/edit',
  Component: () => <ApplicationAssessmentCriteriaEditPage baseUrl="/pf-pgv" />,
  hideNavigation: true,
} as const

const PfPgvApplicationAssessmentCriteriaCommentEdit = {
  path: 'criteria/comment/edit',
  Component: () => <ApplicationAssessmentCommentEditPage baseUrl="/pf-pgv" type={CRITERIA_TYPE.QUALITY} />,
  hideNavigation: true,
} as const

const PfPgvApplicationAssessmentRecommendation = {
  path: 'recommendation',
  Component: PfPgvRecommendationPage,
  navigationKey: 'label.navigation.assessment.recommendation',
} as const

const PfPgvApplicationAssessmentRecommendationEdit = {
  path: 'recommendation/edit',
  Component: () => <ApplicationAssessmentRecommendationEditPage baseUrl="/pf-pgv" />,
  hideNavigation: true,
} as const

const PfPgvApplicationAssessmentSummary = {
  path: 'summary',
  Component: PfPgvAssessmentDetailsSummaryPage,
  navigationKey: 'label.navigation.project.summary',
} as const

const PfPgvApplicationCommittee = {
  path: 'pf-pgv/project/:projectId/committee',
  Component: PfPgvApplicationCommitteePage,
  roles: ['PD-GFCH_PF-PGV_CONTRIBUTOR', 'PD-GFCH_PF-PGV_COORDINATOR', 'PD-GFCH_PF-PGV_READER'],
} as const

const PfPgvApplicationCommitteeEdit = {
  path: 'pf-pgv/project/:projectId/committee/edit',
  Component: () => <ApplicationCommitteeEditPage baseUrl="/pf-pgv" />,
  roles: ['PD-GFCH_PF-PGV_CONTRIBUTOR', 'PD-GFCH_PF-PGV_COORDINATOR'],
} as const

const PfPgvApplicationAssessmentSummaryCommentEdit = {
  path: 'summary/comment/edit',
  Component: () => <ApplicationAssessmentSummaryCommentEditPage baseUrl="/pf-pgv" />,
  hideNavigation: true,
} as const

const PfPgvImplementationDetailsRoot = {
  path: 'pf-pgv/project/:projectId/implementation/details',
  Component: PfPgvImplementationDetailsIndexPage,
  roles: [
    'PD-EXT_USER',
    'PD-GFCH_PF-PGV_CONTRIBUTOR',
    'PD-GFCH_PF-PGV_COORDINATOR',
    'PD-GFCH_PF-PGV_READER',
    'PD-CANTON_KAP_READER',
    'PD-CANTON_KAP_CONTRIBUTOR',
  ],
} as const

const PfPgvImplementationMilestone = {
  path: 'milestones',
  Component: PfPgvImplementationMilestonePage,
  navigationKey: 'label.navigation.implementation.milestones',
  primary: true,
} as const

const PfPgvImplementationSummary = {
  path: 'summary',
  Component: PfPgvImplementationDetailsSummaryPage,
  navigationKey: 'label.navigation.implementation.summary',
} as const

const PfPgvImplementationSummaryCommentEdit = {
  path: 'summary/edit',
  Component: () => <ImplementationSummaryCommentEditPage baseUrl="/pf-pgv" />,
  hideNavigation: true,
} as const

const PfPgvMilestoneDetailsRoot = {
  path: 'pf-pgv/project/:projectId/implementation/details/milestone/:milestoneId/:milestonePath',
  Component: () => <DefaultMilestoneIndex baseUrl="/pf-pgv" />,
  roles: [
    'PD-EXT_USER',
    'PD-GFCH_PF-PGV_CONTRIBUTOR',
    'PD-GFCH_PF-PGV_COORDINATOR',
    'PD-GFCH_PF-PGV_READER',
    'PD-CANTON_KAP_READER',
    'PD-CANTON_KAP_CONTRIBUTOR',
  ],
} as const

const PfPgvDefaultMilestoneDetails = {
  path: 'details',
  Component: () => <MilestoneDetailsPage baseUrl="/pf-pgv" />,
  navigationKey: 'label.navigation.implementation.details',
  primary: true,
} as const

const PfPgvDefaultMilestoneDetailsEdit = {
  path: 'details/edit',
  Component: () => <MilestoneDetailsEditPage baseUrl="/pf-pgv" />,
  hideNavigation: true,
} as const

const PfPgvIntermediateDiscussionMilestoneEvaluation = {
  path: 'evaluation',
  Component: () => <MilestoneEvaluationPage baseUrl="/pf-pgv" />,
  navigationKey: 'label.navigation.implementation.evaluation',
} as const

const PfPgvIntermediateDiscussionMilestoneEvaluationEdit = {
  path: 'evaluation/edit',
  Component: () => <MilestoneEvaluationEditPage baseUrl="/pf-pgv" />,
  hideNavigation: true,
} as const

const PfPgvMilestoneFinancials = {
  path: 'financials',
  Component: () => <MilestoneFinancialsPage baseUrl="/pf-pgv" />,
  navigationKey: 'label.navigation.project.financials',
  primary: true,
} as const

const PfPgvMilestoneSummary = {
  path: 'summary',
  Component: () => <MilestoneSummaryPage baseUrl="/pf-pgv" />,
  navigationKey: 'label.navigation.implementation.summary',
} as const

const PfPgvMilestoneSummaryCommentEdit = {
  path: 'summary/comment/edit',
  Component: () => <MilestoneSummaryCommentEditPage baseUrl="/pf-pgv" />,
  hideNavigation: true,
} as const

const PfPgvFinalReportMilestoneExternalAssessment = {
  path: 'external-assessment',
  Component: () => <MilestoneAssessmentCriteriaPage baseUrl="/pf-pgv" assessorType={ASSESSMENT_TYPE.EXTERNAL} />,
  navigationKey: 'label.assessment.type.external_assessment',
  primary: true,
} as const

const PfPgvFinalReportMilestoneInternalAssessment = {
  path: 'internal-assessment',
  Component: () => <MilestoneAssessmentCriteriaPage baseUrl="/pf-pgv" assessorType={ASSESSMENT_TYPE.INTERNAL} />,
  navigationKey: 'label.assessment.type.internal_assessment',
} as const

const PfPgvFinalReportMilestoneExternalAssessmentEdit = {
  path: 'external-assessment/edit',
  Component: () => <MilestoneAssessmentCriteriaEditPage baseUrl="/pf-pgv" assessorType={ASSESSMENT_TYPE.EXTERNAL} />,
  hideNavigation: true,
} as const

const PfPgvFinalReportMilestoneExternalAssessmentCommentEdit = {
  path: 'external-assessment/comment/edit',
  Component: () => <MilestoneAssessmentCommentEditPage baseUrl="/pf-pgv" type={ASSESSMENT_TYPE.EXTERNAL} />,
  hideNavigation: true,
} as const

const PfPgvFinalReportMilestoneInternalAssessmentEdit = {
  path: 'internal-assessment/edit',
  Component: () => <MilestoneAssessmentCriteriaEditPage baseUrl="/pf-pgv" assessorType={ASSESSMENT_TYPE.INTERNAL} />,
  hideNavigation: true,
} as const

const PfPgvFinalReportMilestoneInternalAssessmentCommentEdit = {
  path: 'internal-assessment/comment/edit',
  Component: () => <MilestoneAssessmentCommentEditPage baseUrl="/pf-pgv" type={ASSESSMENT_TYPE.INTERNAL} />,
  hideNavigation: true,
} as const

const PfPgvFinalReportQualitativeEvaluation = {
  path: 'qualitative-evaluation',
  Component: () => <FinalReportQualitativeEvaluationPage baseUrl={'/pf-pgv'} />,
  navigationKey: 'label.navigation.milestone.qualitative.evaluation',
} as const

const PfPgvFinalReportQualitativeEvaluationSWOTAnalysisEdit = {
  path: 'qualitative-evaluation/swot-analysis/edit',
  Component: () => <QualitativeEvaluationSWOTAnalysisEditPage baseUrl={'/pf-pgv'} />,
  hideNavigation: true,
} as const

const PfPgvFinalReportQualitativeEvaluationEqualOpportunityEdit = {
  path: 'qualitative-evaluation/equal-opportunity/edit',
  Component: () => <QualitativeEvaluationEditPage baseUrl={'/pf-pgv'} field={'equal_opportunity'} />,
  hideNavigation: true,
} as const

const PfPgvFinalReportQualitativeEvaluationSustainabilityEdit = {
  path: 'qualitative-evaluation/sustainability/edit',
  Component: () => <QualitativeEvaluationEditPage baseUrl={'/pf-pgv'} field={'sustainability'} />,
  hideNavigation: true,
} as const

const PfPgvFinalReportQualitativeEvaluationMultiplicationPotentialEdit = {
  path: 'qualitative-evaluation/multiplication-potential/edit',
  Component: () => <QualitativeEvaluationEditPage baseUrl={'/pf-pgv'} field={'multiplication_potential'} />,
  hideNavigation: true,
} as const

const PfPgvMilestoneGoalsAndMeasures = {
  path: 'goals-and-measures',
  Component: () => <MilestonesGoalsAndMeasuresPage baseUrl="/pf-pgv" />,
  navigationKey: 'label.navigation.project.goals.and.measures',
} as const

const PfPgvMilestoneMeasureNew = {
  path: 'goals-and-measures/measure/new',
  Component: () => <MilestoneMeasureCreatePage baseUrl="/pf-pgv" />,
  hideNavigation: true,
} as const

const PfPgvMilestoneGoalEdit = {
  path: 'goals-and-measures/goal/:goalId/edit',
  Component: () => <MilestoneGoalEditPage baseUrl="/pf-pgv" />,
  hideNavigation: true,
} as const

const PfPgvMilestoneMeasureEdit = {
  path: 'goals-and-measures/measure/:measureId/edit',
  Component: () => <MilestoneMeasureEditPage baseUrl="/pf-pgv" />,
  hideNavigation: true,
} as const

const PfPgvMilestoneFeatureKpi = {
  path: 'kpi',
  Component: () => <MilestoneFeatureKpiPage baseUrl="/pf-pgv" />,
  navigationKey: 'label.navigation.milestone.feature.kpi',
} as const

const PfPgvMilestoneFeatureKpiEdit = {
  path: 'kpi/edit',
  Component: () => <MilestoneFeatureKpiEditPage baseUrl="/pf-pgv" />,
  hideNavigation: true,
} as const

const PfPgvMilestoneFinancialsEdit = {
  path: 'financials/edit',
  Component: () => <MilestoneFinancialsEditPage baseUrl="/pf-pgv" />,
  hideNavigation: true,
} as const

const PfPgvMilestoneConditions = {
  path: 'conditions',
  Component: () => <MilestoneConditionsPage baseUrl="/pf-pgv" />,
  navigationKey: 'label.navigation.milestone.conditions',
  primary: true,
} as const

const PfPgvMilestoneConditionsEdit = {
  path: 'conditions/edit',
  Component: () => <MilestoneConditionsEditPage baseUrl="/pf-pgv" />,
  hideNavigation: true,
} as const

export const PfPgvRoutes = {
  PfPgvIndex: PfPgvIndex,
  PfPgvDetailsRoot: {
    ...PfPgvDetailsRoot,
    params: (props: ExtractRouteParams<typeof PfPgvDetailsRoot.path>) => withParams(`/${PfPgvDetailsRoot.path}`)(props),
    nested: {
      BasicInformation: {
        ...PfPgvBasicInformation,
        params: (
          props: ExtractRouteParams<ComposePaths<typeof PfPgvDetailsRoot.path, typeof PfPgvBasicInformation.path>>,
        ) => withParams(`/${PfPgvDetailsRoot.path}/${PfPgvBasicInformation.path}`)(props),
      },
      BasicInformationEdit: {
        ...PfPgvBasicInformationEdit,
        params: (
          props: ExtractRouteParams<ComposePaths<typeof PfPgvDetailsRoot.path, typeof PfPgvBasicInformationEdit.path>>,
        ) => withParams(`/${PfPgvDetailsRoot.path}/${PfPgvBasicInformationEdit.path}`)(props),
      },
      Organization: {
        ...PfPgvOrganization,
        params: (
          props: ExtractRouteParams<ComposePaths<typeof PfPgvDetailsRoot.path, typeof PfPgvOrganization.path>>,
        ) => withParams(`/${PfPgvDetailsRoot.path}/${PfPgvOrganization.path}`)(props),
      },
      OrganizationNew: {
        ...PfPgvOrganizationNew,
        params: (
          props: ExtractRouteParams<ComposePaths<typeof PfPgvDetailsRoot.path, typeof PfPgvOrganizationNew.path>>,
        ) => withParams(`/${PfPgvDetailsRoot.path}/${PfPgvOrganizationNew.path}`)(props),
      },
      OrganizationEdit: {
        ...PfPgvOrganizationEdit,
        params: (
          props: ExtractRouteParams<ComposePaths<typeof PfPgvDetailsRoot.path, typeof PfPgvOrganizationEdit.path>>,
        ) => withParams(`/${PfPgvDetailsRoot.path}/${PfPgvOrganizationEdit.path}`)(props),
      },
      StartingPointAndVision: {
        ...PfPgvStartingPointAndVision,
        params: (
          props: ExtractRouteParams<
            ComposePaths<typeof PfPgvDetailsRoot.path, typeof PfPgvStartingPointAndVision.path>
          >,
        ) => withParams(`/${PfPgvDetailsRoot.path}/${PfPgvStartingPointAndVision.path}`)(props),
      },
      StartingPointAndVisionInitialSituationEdit: {
        ...PfPgvStartingPointAndVisionInitialSituationEdit,
        params: (
          props: ExtractRouteParams<
            ComposePaths<typeof PfPgvDetailsRoot.path, typeof PfPgvStartingPointAndVisionInitialSituationEdit.path>
          >,
        ) => withParams(`/${PfPgvDetailsRoot.path}/${PfPgvStartingPointAndVisionInitialSituationEdit.path}`)(props),
      },
      StartingPointAndVisionProjectJustificationEdit: {
        ...PfPgvStartingPointAndVisionProjectJustificationEdit,
        params: (
          props: ExtractRouteParams<
            ComposePaths<typeof PfPgvDetailsRoot.path, typeof PfPgvStartingPointAndVisionProjectJustificationEdit.path>
          >,
        ) => withParams(`/${PfPgvDetailsRoot.path}/${PfPgvStartingPointAndVisionProjectJustificationEdit.path}`)(props),
      },
      StartingPointAndVisionSourcesEdit: {
        ...PfPgvStartingPointAndVisionSourcesEdit,
        params: (
          props: ExtractRouteParams<
            ComposePaths<typeof PfPgvDetailsRoot.path, typeof PfPgvStartingPointAndVisionSourcesEdit.path>
          >,
        ) => withParams(`/${PfPgvDetailsRoot.path}/${PfPgvStartingPointAndVisionSourcesEdit.path}`)(props),
      },
      StartingPointAndVisionVisionEdit: {
        ...PfPgvStartingPointAndVisionVisionEdit,
        params: (
          props: ExtractRouteParams<
            ComposePaths<typeof PfPgvDetailsRoot.path, typeof PfPgvStartingPointAndVisionVisionEdit.path>
          >,
        ) => withParams(`/${PfPgvDetailsRoot.path}/${PfPgvStartingPointAndVisionVisionEdit.path}`)(props),
      },
      ProjectFeatures: {
        ...PfPgvProjectFeatures,
        params: (
          props: ExtractRouteParams<ComposePaths<typeof PfPgvDetailsRoot.path, typeof PfPgvProjectFeatures.path>>,
        ) => withParams(`/${PfPgvDetailsRoot.path}/${PfPgvProjectFeatures.path}`)(props),
      },
      ProjectFeaturesEdit: {
        ...PfPgvProjectFeaturesEdit,
        params: (
          props: ExtractRouteParams<ComposePaths<typeof PfPgvDetailsRoot.path, typeof PfPgvProjectFeaturesEdit.path>>,
        ) => withParams(`/${PfPgvDetailsRoot.path}/${PfPgvProjectFeaturesEdit.path}`)(props),
      },
      ProjectFeaturesKpiEdit: {
        ...PfPgvProjectFeaturesKpiEdit,
        params: (
          props: ExtractRouteParams<
            ComposePaths<typeof PfPgvDetailsRoot.path, typeof PfPgvProjectFeaturesKpiEdit.path>
          >,
        ) => withParams(`/${PfPgvDetailsRoot.path}/${PfPgvProjectFeaturesKpiEdit.path}`)(props),
      },
      GoalsAndMeasures: {
        ...PfPgvGoalsAndMeasures,
        params: (
          props: ExtractRouteParams<ComposePaths<typeof PfPgvDetailsRoot.path, typeof PfPgvGoalsAndMeasures.path>>,
        ) => withParams(`/${PfPgvDetailsRoot.path}/${PfPgvGoalsAndMeasures.path}`)(props),
      },
      ProjectGoalNew: {
        ...PfPgvProjectGoalNew,
        params: (
          props: ExtractRouteParams<ComposePaths<typeof PfPgvDetailsRoot.path, typeof PfPgvProjectGoalNew.path>>,
        ) => withParams(`/${PfPgvDetailsRoot.path}/${PfPgvProjectGoalNew.path}`)(props),
      },
      ProjectGoalEdit: {
        ...PfPgvProjectGoalEdit,
        params: (
          props: ExtractRouteParams<ComposePaths<typeof PfPgvDetailsRoot.path, typeof PfPgvProjectGoalEdit.path>>,
        ) => withParams(`/${PfPgvDetailsRoot.path}/${PfPgvProjectGoalEdit.path}`)(props),
      },
      ProjectMeasureNew: {
        ...PfPgvProjectMeasureNew,
        params: (
          props: ExtractRouteParams<ComposePaths<typeof PfPgvDetailsRoot.path, typeof PfPgvProjectMeasureNew.path>>,
        ) => withParams(`/${PfPgvDetailsRoot.path}/${PfPgvProjectMeasureNew.path}`)(props),
      },
      ProjectMeasureEdit: {
        ...PfPgvProjectMeasureEdit,
        params: (
          props: ExtractRouteParams<ComposePaths<typeof PfPgvDetailsRoot.path, typeof PfPgvProjectMeasureEdit.path>>,
        ) => withParams(`/${PfPgvDetailsRoot.path}/${PfPgvProjectMeasureEdit.path}`)(props),
      },
      Financials: {
        ...PfPgvFinancials,
        params: (props: ExtractRouteParams<ComposePaths<typeof PfPgvDetailsRoot.path, typeof PfPgvFinancials.path>>) =>
          withParams(`/${PfPgvDetailsRoot.path}/${PfPgvFinancials.path}`)(props),
      },
      FinancialsProjectBudgetEdit: {
        ...PfPgvFinancialsProjectBudgetEdit,
        params: (
          props: ExtractRouteParams<
            ComposePaths<typeof PfPgvDetailsRoot.path, typeof PfPgvFinancialsProjectBudgetEdit.path>
          >,
        ) => withParams(`/${PfPgvDetailsRoot.path}/${PfPgvFinancialsProjectBudgetEdit.path}`)(props),
      },
      EffectivityManagement: {
        ...PfPgvEffectivityManagement,
        params: (
          props: ExtractRouteParams<ComposePaths<typeof PfPgvDetailsRoot.path, typeof PfPgvEffectivityManagement.path>>,
        ) => withParams(`/${PfPgvDetailsRoot.path}/${PfPgvEffectivityManagement.path}`)(props),
      },
      EffectivityManagementEvaluationMilestoneNew: {
        ...PfPgvEffectivityManagementEvaluationMilestoneNew,
        params: (
          props: ExtractRouteParams<
            ComposePaths<typeof PfPgvDetailsRoot.path, typeof PfPgvEffectivityManagementEvaluationMilestoneNew.path>
          >,
        ) => withParams(`/${PfPgvDetailsRoot.path}/${PfPgvEffectivityManagementEvaluationMilestoneNew.path}`)(props),
      },
      EffectivityManagementEvaluationMilestoneEdit: {
        ...PfPgvEffectivityManagementEvaluationMilestoneEdit,
        params: (
          props: ExtractRouteParams<
            ComposePaths<typeof PfPgvDetailsRoot.path, typeof PfPgvEffectivityManagementEvaluationMilestoneEdit.path>
          >,
        ) => withParams(`/${PfPgvDetailsRoot.path}/${PfPgvEffectivityManagementEvaluationMilestoneEdit.path}`)(props),
      },
      BackgroundInformation: {
        ...PfPgvBackgroundInformation,
        params: (
          props: ExtractRouteParams<ComposePaths<typeof PfPgvDetailsRoot.path, typeof PfPgvBackgroundInformation.path>>,
        ) => withParams(`/${PfPgvDetailsRoot.path}/${PfPgvBackgroundInformation.path}`)(props),
      },
      BackgroundInformationSustainabilityEdit: {
        ...PfPgvBackgroundInformationSustainabilityEdit,
        params: (
          props: ExtractRouteParams<
            ComposePaths<typeof PfPgvDetailsRoot.path, typeof PfPgvBackgroundInformationSustainabilityEdit.path>
          >,
        ) => withParams(`/${PfPgvDetailsRoot.path}/${PfPgvBackgroundInformationSustainabilityEdit.path}`)(props),
      },
      BackgroundInformationParticipationEdit: {
        ...PfPgvBackgroundInformationParticipationEdit,
        params: (
          props: ExtractRouteParams<
            ComposePaths<typeof PfPgvDetailsRoot.path, typeof PfPgvBackgroundInformationParticipationEdit.path>
          >,
        ) => withParams(`/${PfPgvDetailsRoot.path}/${PfPgvBackgroundInformationParticipationEdit.path}`)(props),
      },
      BackgroundInformationEqualOpportunintyEdit: {
        ...PfPgvBackgroundInformationEqualOpportunityEdit,
        params: (
          props: ExtractRouteParams<
            ComposePaths<typeof PfPgvDetailsRoot.path, typeof PfPgvBackgroundInformationEqualOpportunityEdit.path>
          >,
        ) => withParams(`/${PfPgvDetailsRoot.path}/${PfPgvBackgroundInformationEqualOpportunityEdit.path}`)(props),
      },
      BackgroundInformationMultiplicationPotentialEdit: {
        ...PfPgvBackgroundInformationMultiplicationPotentialEdit,
        params: (
          props: ExtractRouteParams<
            ComposePaths<
              typeof PfPgvDetailsRoot.path,
              typeof PfPgvBackgroundInformationMultiplicationPotentialEdit.path
            >
          >,
        ) =>
          withParams(`/${PfPgvDetailsRoot.path}/${PfPgvBackgroundInformationMultiplicationPotentialEdit.path}`)(props),
      },
      BackgroundInformationAdditionalInformationEdit: {
        ...PfPgvBackgroundInformationAdditionalInformationEdit,
        params: (
          props: ExtractRouteParams<
            ComposePaths<typeof PfPgvDetailsRoot.path, typeof PfPgvBackgroundInformationAdditionalInformationEdit.path>
          >,
        ) => withParams(`/${PfPgvDetailsRoot.path}/${PfPgvBackgroundInformationAdditionalInformationEdit.path}`)(props),
      },
      ProjectSummary: {
        ...PfPgvSummary,
        params: (props: ExtractRouteParams<ComposePaths<typeof PfPgvDetailsRoot.path, typeof PfPgvSummary.path>>) =>
          withParams(`/${PfPgvDetailsRoot.path}/${PfPgvSummary.path}`)(props),
      },
      ProjectSummaryCommentEdit: {
        ...PfPgvSummaryCommentEdit,
        params: (
          props: ExtractRouteParams<ComposePaths<typeof PfPgvDetailsRoot.path, typeof PfPgvSummaryCommentEdit.path>>,
        ) => withParams(`/${PfPgvDetailsRoot.path}/${PfPgvSummaryCommentEdit.path}`)(props),
      },
    },
  },
  PfPgvPermissions: {
    ...PfPgvProjectPermissions,
    params: (props: ExtractRouteParams<typeof PfPgvProjectPermissions.path>) =>
      withParams(`/${PfPgvProjectPermissions.path}`)(props),
  },
  PfPgvProjectJournal: {
    ...PfPgvProjectJournal,
    params: (props: ExtractRouteParams<typeof PfPgvProjectJournal.path>) =>
      withParams(`/${PfPgvProjectJournal.path}`)(props),
  },
  PfPgvProjectJournalNew: {
    ...PfPgvProjectJournalNew,
    params: (props: ExtractRouteParams<typeof PfPgvProjectJournalNew.path>) =>
      withParams(`/${PfPgvProjectJournalNew.path}`)(props),
  },
  PfPgvProjectJournalEdit: {
    ...PfPgvProjectJournalEdit,
    params: (props: ExtractRouteParams<typeof PfPgvProjectJournalEdit.path>) =>
      withParams(`/${PfPgvProjectJournalEdit.path}`)(props),
  },
  PfPgvApplicationDetailsRoot: {
    ...PfPgvApplicationDetailsRoot,
    params: (props: ExtractRouteParams<typeof PfPgvApplicationDetailsRoot.path>) =>
      withParams(`/${PfPgvApplicationDetailsRoot.path}`)(props),
    nested: {
      Information: {
        ...PfPgvApplicationInformation,
        params: (
          props: ExtractRouteParams<
            ComposePaths<typeof PfPgvApplicationDetailsRoot.path, typeof PfPgvApplicationInformation.path>
          >,
        ) => withParams(`/${PfPgvApplicationDetailsRoot.path}/${PfPgvApplicationInformation.path}`)(props),
      },
      InformationEdit: {
        ...PfPgvApplicationInformationEdit,
        params: (
          props: ExtractRouteParams<
            ComposePaths<typeof PfPgvApplicationDetailsRoot.path, typeof PfPgvApplicationInformationEdit.path>
          >,
        ) => withParams(`/${PfPgvApplicationDetailsRoot.path}/${PfPgvApplicationInformationEdit.path}`)(props),
      },
      Summary: {
        ...PfPgvApplicationSummary,
        params: (
          props: ExtractRouteParams<
            ComposePaths<typeof PfPgvApplicationDetailsRoot.path, typeof PfPgvApplicationSummary.path>
          >,
        ) => withParams(`/${PfPgvApplicationDetailsRoot.path}/${PfPgvApplicationSummary.path}`)(props),
      },
      SummaryCommentEdit: {
        ...PfPgvApplicationSummaryCommentEdit,
        params: (
          props: ExtractRouteParams<
            ComposePaths<typeof PfPgvApplicationDetailsRoot.path, typeof PfPgvApplicationSummaryCommentEdit.path>
          >,
        ) => withParams(`/${PfPgvApplicationDetailsRoot.path}/${PfPgvApplicationSummaryCommentEdit.path}`)(props),
      },
    },
  },
  PfPgvApplicationCommittee: {
    ...PfPgvApplicationCommittee,
    params: (props: ExtractRouteParams<typeof PfPgvApplicationCommittee.path>) =>
      withParams(`/${PfPgvApplicationCommittee.path}`)(props),
  },
  PfPgvApplicationCommitteeEdit: {
    ...PfPgvApplicationCommitteeEdit,
    params: (props: ExtractRouteParams<typeof PfPgvApplicationCommitteeEdit.path>) =>
      withParams(`/${PfPgvApplicationCommitteeEdit.path}`)(props),
  },
  PfPgvApplicationAssessment: {
    ...PfPgvApplicationAssessment,
    params: (props: ExtractRouteParams<typeof PfPgvApplicationAssessment.path>) =>
      withParams(`/${PfPgvApplicationAssessment.path}`)(props),
  },
  PfPgvApplicationAssessmentDetailsRoot: {
    ...PfPgvApplicationAssessmentDetailsRoot,
    params: (props: ExtractRouteParams<typeof PfPgvApplicationAssessmentDetailsRoot.path>) =>
      withParams(`/${PfPgvApplicationAssessmentDetailsRoot.path}`)(props),
    nested: {
      Exclusion: {
        ...PfPgvApplicationAssessmentExclusion,
        params: (
          props: ExtractRouteParams<
            ComposePaths<
              typeof PfPgvApplicationAssessmentDetailsRoot.path,
              typeof PfPgvApplicationAssessmentExclusion.path
            >
          >,
        ) =>
          withParams(`/${PfPgvApplicationAssessmentDetailsRoot.path}/${PfPgvApplicationAssessmentExclusion.path}`)(
            props,
          ),
      },
      ExclusionEdit: {
        ...PfPgvApplicationAssessmentExclusionEdit,
        params: (
          props: ExtractRouteParams<
            ComposePaths<
              typeof PfPgvApplicationAssessmentDetailsRoot.path,
              typeof PfPgvApplicationAssessmentExclusionEdit.path
            >
          >,
        ) =>
          withParams(`/${PfPgvApplicationAssessmentDetailsRoot.path}/${PfPgvApplicationAssessmentExclusionEdit.path}`)(
            props,
          ),
      },
      ExclusionCommentEdit: {
        ...PfPgvApplicationAssessmentExclusionCommentEdit,
        params: (
          props: ExtractRouteParams<
            ComposePaths<
              typeof PfPgvApplicationAssessmentDetailsRoot.path,
              typeof PfPgvApplicationAssessmentExclusionCommentEdit.path
            >
          >,
        ) =>
          withParams(
            `/${PfPgvApplicationAssessmentDetailsRoot.path}/${PfPgvApplicationAssessmentExclusionCommentEdit.path}`,
          )(props),
      },
      Criteria: {
        ...PfPgvApplicationAssessmentCriteria,
        params: (
          props: ExtractRouteParams<
            ComposePaths<
              typeof PfPgvApplicationAssessmentDetailsRoot.path,
              typeof PfPgvApplicationAssessmentCriteria.path
            >
          >,
        ) =>
          withParams(`/${PfPgvApplicationAssessmentDetailsRoot.path}/${PfPgvApplicationAssessmentCriteria.path}`)(
            props,
          ),
      },
      CriteriaEdit: {
        ...PfPgvApplicationAssessmentCriteriaEdit,
        params: (
          props: ExtractRouteParams<
            ComposePaths<
              typeof PfPgvApplicationAssessmentDetailsRoot.path,
              typeof PfPgvApplicationAssessmentCriteriaEdit.path
            >
          >,
        ) =>
          withParams(`/${PfPgvApplicationAssessmentDetailsRoot.path}/${PfPgvApplicationAssessmentCriteriaEdit.path}`)(
            props,
          ),
      },
      CriteriaCommentEdit: {
        ...PfPgvApplicationAssessmentCriteriaCommentEdit,
        params: (
          props: ExtractRouteParams<
            ComposePaths<
              typeof PfPgvApplicationAssessmentDetailsRoot.path,
              typeof PfPgvApplicationAssessmentCriteriaCommentEdit.path
            >
          >,
        ) =>
          withParams(
            `/${PfPgvApplicationAssessmentDetailsRoot.path}/${PfPgvApplicationAssessmentCriteriaCommentEdit.path}`,
          )(props),
      },
      Recommendation: {
        ...PfPgvApplicationAssessmentRecommendation,
        params: (
          props: ExtractRouteParams<
            ComposePaths<
              typeof PfPgvApplicationAssessmentDetailsRoot.path,
              typeof PfPgvApplicationAssessmentRecommendation.path
            >
          >,
        ) =>
          withParams(`/${PfPgvApplicationAssessmentDetailsRoot.path}/${PfPgvApplicationAssessmentRecommendation.path}`)(
            props,
          ),
      },
      RecommendationEdit: {
        ...PfPgvApplicationAssessmentRecommendationEdit,
        params: (
          props: ExtractRouteParams<
            ComposePaths<
              typeof PfPgvApplicationAssessmentDetailsRoot.path,
              typeof PfPgvApplicationAssessmentRecommendationEdit.path
            >
          >,
        ) =>
          withParams(
            `/${PfPgvApplicationAssessmentDetailsRoot.path}/${PfPgvApplicationAssessmentRecommendationEdit.path}`,
          )(props),
      },
      Summary: {
        ...PfPgvApplicationAssessmentSummary,
        params: (
          props: ExtractRouteParams<
            ComposePaths<
              typeof PfPgvApplicationAssessmentDetailsRoot.path,
              typeof PfPgvApplicationAssessmentSummary.path
            >
          >,
        ) =>
          withParams(`/${PfPgvApplicationAssessmentDetailsRoot.path}/${PfPgvApplicationAssessmentSummary.path}`)(props),
      },
      SummaryCommentEdit: {
        ...PfPgvApplicationAssessmentSummaryCommentEdit,
        params: (
          props: ExtractRouteParams<
            ComposePaths<
              typeof PfPgvApplicationAssessmentDetailsRoot.path,
              typeof PfPgvApplicationAssessmentSummaryCommentEdit.path
            >
          >,
        ) =>
          withParams(
            `/${PfPgvApplicationAssessmentDetailsRoot.path}/${PfPgvApplicationAssessmentSummaryCommentEdit.path}`,
          )(props),
      },
    },
  },
  PfPgvImplementationDetailsRoot: {
    ...PfPgvImplementationDetailsRoot,
    params: (props: ExtractRouteParams<typeof PfPgvImplementationDetailsRoot.path>) =>
      withParams(`/${PfPgvImplementationDetailsRoot.path}`)(props),
    nested: {
      Milestone: {
        ...PfPgvImplementationMilestone,
        params: (
          props: ExtractRouteParams<
            ComposePaths<typeof PfPgvImplementationDetailsRoot.path, typeof PfPgvImplementationMilestone.path>
          >,
        ) => withParams(`/${PfPgvImplementationDetailsRoot.path}/${PfPgvImplementationMilestone.path}`)(props),
      },
      Summary: {
        ...PfPgvImplementationSummary,
        params: (
          props: ExtractRouteParams<
            ComposePaths<typeof PfPgvImplementationDetailsRoot.path, typeof PfPgvImplementationSummary.path>
          >,
        ) => withParams(`/${PfPgvImplementationDetailsRoot.path}/${PfPgvImplementationSummary.path}`)(props),
      },
      SummaryCommentEdit: {
        ...PfPgvImplementationSummaryCommentEdit,
        params: (
          props: ExtractRouteParams<
            ComposePaths<typeof PfPgvImplementationDetailsRoot.path, typeof PfPgvImplementationSummaryCommentEdit.path>
          >,
        ) => withParams(`/${PfPgvImplementationDetailsRoot.path}/${PfPgvImplementationSummaryCommentEdit.path}`)(props),
      },
    },
  },
  PfPgvDefaultMilestoneRoot: {
    ...PfPgvMilestoneDetailsRoot,
    params: (props: ExtractRouteParams<typeof PfPgvMilestoneDetailsRoot.path>) =>
      withParams(`/${PfPgvMilestoneDetailsRoot.path}`)(props),
    nested: {
      Details: {
        ...PfPgvDefaultMilestoneDetails,
        params: (
          props: ExtractRouteParams<
            ComposePaths<typeof PfPgvMilestoneDetailsRoot.path, typeof PfPgvDefaultMilestoneDetails.path>
          >,
        ) => withParams(`/${PfPgvMilestoneDetailsRoot.path}/${PfPgvDefaultMilestoneDetails.path}`)(props),
      },
      DetailsEdit: {
        ...PfPgvDefaultMilestoneDetailsEdit,
        params: (
          props: ExtractRouteParams<
            ComposePaths<typeof PfPgvMilestoneDetailsRoot.path, typeof PfPgvDefaultMilestoneDetailsEdit.path>
          >,
        ) => withParams(`/${PfPgvMilestoneDetailsRoot.path}/${PfPgvDefaultMilestoneDetailsEdit.path}`)(props),
      },
      Summary: {
        ...PfPgvMilestoneSummary,
        params: (
          props: ExtractRouteParams<
            ComposePaths<typeof PfPgvMilestoneDetailsRoot.path, typeof PfPgvMilestoneSummary.path>
          >,
        ) => withParams(`/${PfPgvMilestoneDetailsRoot.path}/${PfPgvMilestoneSummary.path}`)(props),
      },
      SummaryCommentEdit: {
        ...PfPgvMilestoneSummaryCommentEdit,
        params: (
          props: ExtractRouteParams<
            ComposePaths<typeof PfPgvMilestoneDetailsRoot.path, typeof PfPgvMilestoneSummaryCommentEdit.path>
          >,
        ) => withParams(`/${PfPgvMilestoneDetailsRoot.path}/${PfPgvMilestoneSummaryCommentEdit.path}`)(props),
      },
    },
  },
  PfPgvIntermediateMilestoneRoot: {
    ...PfPgvMilestoneDetailsRoot,
    params: (props: ExtractRouteParams<typeof PfPgvMilestoneDetailsRoot.path>) =>
      withParams(`/${PfPgvMilestoneDetailsRoot.path}`)(props),
    nested: {
      Details: {
        ...PfPgvDefaultMilestoneDetails,
        params: (
          props: ExtractRouteParams<
            ComposePaths<typeof PfPgvMilestoneDetailsRoot.path, typeof PfPgvDefaultMilestoneDetails.path>
          >,
        ) => withParams(`/${PfPgvMilestoneDetailsRoot.path}/${PfPgvDefaultMilestoneDetails.path}`)(props),
      },
      DetailsEdit: {
        ...PfPgvDefaultMilestoneDetailsEdit,
        params: (
          props: ExtractRouteParams<
            ComposePaths<typeof PfPgvMilestoneDetailsRoot.path, typeof PfPgvDefaultMilestoneDetailsEdit.path>
          >,
        ) => withParams(`/${PfPgvMilestoneDetailsRoot.path}/${PfPgvDefaultMilestoneDetailsEdit.path}`)(props),
      },
      Evaluation: {
        ...PfPgvIntermediateDiscussionMilestoneEvaluation,
        params: (
          props: ExtractRouteParams<
            ComposePaths<
              typeof PfPgvMilestoneDetailsRoot.path,
              typeof PfPgvIntermediateDiscussionMilestoneEvaluation.path
            >
          >,
        ) =>
          withParams(`/${PfPgvMilestoneDetailsRoot.path}/${PfPgvIntermediateDiscussionMilestoneEvaluation.path}`)(
            props,
          ),
      },
      EvaluationEdit: {
        ...PfPgvIntermediateDiscussionMilestoneEvaluationEdit,
        params: (
          props: ExtractRouteParams<
            ComposePaths<
              typeof PfPgvMilestoneDetailsRoot.path,
              typeof PfPgvIntermediateDiscussionMilestoneEvaluationEdit.path
            >
          >,
        ) =>
          withParams(`/${PfPgvMilestoneDetailsRoot.path}/${PfPgvIntermediateDiscussionMilestoneEvaluationEdit.path}`)(
            props,
          ),
      },
      Summary: {
        ...PfPgvMilestoneSummary,
        params: (
          props: ExtractRouteParams<
            ComposePaths<typeof PfPgvMilestoneDetailsRoot.path, typeof PfPgvMilestoneSummary.path>
          >,
        ) => withParams(`/${PfPgvMilestoneDetailsRoot.path}/${PfPgvMilestoneSummary.path}`)(props),
      },
      SummaryCommentEdit: {
        ...PfPgvMilestoneSummaryCommentEdit,
        params: (
          props: ExtractRouteParams<
            ComposePaths<typeof PfPgvMilestoneDetailsRoot.path, typeof PfPgvMilestoneSummaryCommentEdit.path>
          >,
        ) => withParams(`/${PfPgvMilestoneDetailsRoot.path}/${PfPgvMilestoneSummaryCommentEdit.path}`)(props),
      },
    },
  },
  PfPgvFinancialReportMilestoneRoot: {
    ...PfPgvMilestoneDetailsRoot,
    params: (props: ExtractRouteParams<typeof PfPgvMilestoneDetailsRoot.path>) =>
      withParams(`/${PfPgvMilestoneDetailsRoot.path}`)(props),
    nested: {
      Financials: {
        ...PfPgvMilestoneFinancials,
        params: (
          props: ExtractRouteParams<
            ComposePaths<typeof PfPgvMilestoneDetailsRoot.path, typeof PfPgvMilestoneFinancials.path>
          >,
        ) => withParams(`/${PfPgvMilestoneDetailsRoot.path}/${PfPgvMilestoneFinancials.path}`)(props),
      },
      FinancialsEdit: {
        ...PfPgvMilestoneFinancialsEdit,
        params: (
          props: ExtractRouteParams<
            ComposePaths<typeof PfPgvMilestoneDetailsRoot.path, typeof PfPgvMilestoneFinancialsEdit.path>
          >,
        ) => withParams(`/${PfPgvMilestoneDetailsRoot.path}/${PfPgvMilestoneFinancialsEdit.path}`)(props),
      },
      Summary: {
        ...PfPgvMilestoneSummary,
        params: (
          props: ExtractRouteParams<
            ComposePaths<typeof PfPgvMilestoneDetailsRoot.path, typeof PfPgvMilestoneSummary.path>
          >,
        ) => withParams(`/${PfPgvMilestoneDetailsRoot.path}/${PfPgvMilestoneSummary.path}`)(props),
      },
      SummaryCommentEdit: {
        ...PfPgvMilestoneSummaryCommentEdit,
        params: (
          props: ExtractRouteParams<
            ComposePaths<typeof PfPgvMilestoneDetailsRoot.path, typeof PfPgvMilestoneSummaryCommentEdit.path>
          >,
        ) => withParams(`/${PfPgvMilestoneDetailsRoot.path}/${PfPgvMilestoneSummaryCommentEdit.path}`)(props),
      },
    },
  },
  PfPgvFinalReportMilestoneRoot: {
    ...PfPgvMilestoneDetailsRoot,
    params: (props: ExtractRouteParams<typeof PfPgvMilestoneDetailsRoot.path>) =>
      withParams(`/${PfPgvMilestoneDetailsRoot.path}`)(props),
    nested: {
      Conditions: {
        ...PfPgvMilestoneConditions,
        params: (
          props: ExtractRouteParams<
            ComposePaths<typeof PfPgvMilestoneDetailsRoot.path, typeof PfPgvMilestoneConditions.path>
          >,
        ) => withParams(`/${PfPgvMilestoneDetailsRoot.path}/${PfPgvMilestoneConditions.path}`)(props),
      },
      ConditionsEdit: {
        ...PfPgvMilestoneConditionsEdit,
        params: (
          props: ExtractRouteParams<
            ComposePaths<typeof PfPgvMilestoneDetailsRoot.path, typeof PfPgvMilestoneConditionsEdit.path>
          >,
        ) => withParams(`/${PfPgvMilestoneDetailsRoot.path}/${PfPgvMilestoneConditionsEdit.path}`)(props),
      },
      ExternalAssessment: {
        ...PfPgvFinalReportMilestoneExternalAssessment,
        params: (
          props: ExtractRouteParams<
            ComposePaths<typeof PfPgvMilestoneDetailsRoot.path, typeof PfPgvFinalReportMilestoneExternalAssessment.path>
          >,
        ) =>
          withParams(`/${PfPgvMilestoneDetailsRoot.path}/${PfPgvFinalReportMilestoneExternalAssessment.path}`)(props),
      },
      ExternalAssessmentEdit: {
        ...PfPgvFinalReportMilestoneExternalAssessmentEdit,
        params: (
          props: ExtractRouteParams<
            ComposePaths<
              typeof PfPgvMilestoneDetailsRoot.path,
              typeof PfPgvFinalReportMilestoneExternalAssessmentEdit.path
            >
          >,
        ) =>
          withParams(`/${PfPgvMilestoneDetailsRoot.path}/${PfPgvFinalReportMilestoneExternalAssessmentEdit.path}`)(
            props,
          ),
      },
      ExternalAssessmentCommentEdit: {
        ...PfPgvFinalReportMilestoneExternalAssessmentCommentEdit,
        params: (
          props: ExtractRouteParams<
            ComposePaths<
              typeof PfPgvMilestoneDetailsRoot.path,
              typeof PfPgvFinalReportMilestoneExternalAssessmentCommentEdit.path
            >
          >,
        ) =>
          withParams(
            `/${PfPgvMilestoneDetailsRoot.path}/${PfPgvFinalReportMilestoneExternalAssessmentCommentEdit.path}`,
          )(props),
      },
      InternalAssessment: {
        ...PfPgvFinalReportMilestoneInternalAssessment,
        params: (
          props: ExtractRouteParams<
            ComposePaths<typeof PfPgvMilestoneDetailsRoot.path, typeof PfPgvFinalReportMilestoneInternalAssessment.path>
          >,
        ) =>
          withParams(`/${PfPgvMilestoneDetailsRoot.path}/${PfPgvFinalReportMilestoneInternalAssessment.path}`)(props),
      },
      InternalAssessmentEdit: {
        ...PfPgvFinalReportMilestoneInternalAssessmentEdit,
        params: (
          props: ExtractRouteParams<
            ComposePaths<
              typeof PfPgvMilestoneDetailsRoot.path,
              typeof PfPgvFinalReportMilestoneInternalAssessmentEdit.path
            >
          >,
        ) =>
          withParams(`/${PfPgvMilestoneDetailsRoot.path}/${PfPgvFinalReportMilestoneInternalAssessmentEdit.path}`)(
            props,
          ),
      },
      InternalAssessmentCommentEdit: {
        ...PfPgvFinalReportMilestoneInternalAssessmentCommentEdit,
        params: (
          props: ExtractRouteParams<
            ComposePaths<
              typeof PfPgvMilestoneDetailsRoot.path,
              typeof PfPgvFinalReportMilestoneInternalAssessmentCommentEdit.path
            >
          >,
        ) =>
          withParams(
            `/${PfPgvMilestoneDetailsRoot.path}/${PfPgvFinalReportMilestoneInternalAssessmentCommentEdit.path}`,
          )(props),
      },
      QualitativeEvaluation: {
        ...PfPgvFinalReportQualitativeEvaluation,
        params: (
          props: ExtractRouteParams<
            ComposePaths<typeof PfPgvMilestoneDetailsRoot.path, typeof PfPgvFinalReportQualitativeEvaluation.path>
          >,
        ) => withParams(`/${PfPgvMilestoneDetailsRoot.path}/${PfPgvFinalReportQualitativeEvaluation.path}`)(props),
      },
      QualitativeEvaluationSWOTAnalysisEdit: {
        ...PfPgvFinalReportQualitativeEvaluationSWOTAnalysisEdit,
        params: (
          props: ExtractRouteParams<
            ComposePaths<
              typeof PfPgvMilestoneDetailsRoot.path,
              typeof PfPgvFinalReportQualitativeEvaluationSWOTAnalysisEdit.path
            >
          >,
        ) =>
          withParams(
            `/${PfPgvMilestoneDetailsRoot.path}/${PfPgvFinalReportQualitativeEvaluationSWOTAnalysisEdit.path}`,
          )(props),
      },
      QualitativeEvaluationEqualOpportunityEdit: {
        ...PfPgvFinalReportQualitativeEvaluationEqualOpportunityEdit,
        params: (
          props: ExtractRouteParams<
            ComposePaths<
              typeof PfPgvMilestoneDetailsRoot.path,
              typeof PfPgvFinalReportQualitativeEvaluationEqualOpportunityEdit.path
            >
          >,
        ) =>
          withParams(
            `/${PfPgvMilestoneDetailsRoot.path}/${PfPgvFinalReportQualitativeEvaluationEqualOpportunityEdit.path}`,
          )(props),
      },
      QualitativeEvaluationSustainabilityEdit: {
        ...PfPgvFinalReportQualitativeEvaluationSustainabilityEdit,
        params: (
          props: ExtractRouteParams<
            ComposePaths<
              typeof PfPgvMilestoneDetailsRoot.path,
              typeof PfPgvFinalReportQualitativeEvaluationSustainabilityEdit.path
            >
          >,
        ) =>
          withParams(
            `/${PfPgvMilestoneDetailsRoot.path}/${PfPgvFinalReportQualitativeEvaluationSustainabilityEdit.path}`,
          )(props),
      },
      QualitativeEvaluationMultiplicationPotentialEdit: {
        ...PfPgvFinalReportQualitativeEvaluationMultiplicationPotentialEdit,
        params: (
          props: ExtractRouteParams<
            ComposePaths<
              typeof PfPgvMilestoneDetailsRoot.path,
              typeof PfPgvFinalReportQualitativeEvaluationMultiplicationPotentialEdit.path
            >
          >,
        ) =>
          withParams(
            `/${PfPgvMilestoneDetailsRoot.path}/${PfPgvFinalReportQualitativeEvaluationMultiplicationPotentialEdit.path}`,
          )(props),
      },
      Summary: {
        ...PfPgvMilestoneSummary,
        params: (
          props: ExtractRouteParams<
            ComposePaths<typeof PfPgvMilestoneDetailsRoot.path, typeof PfPgvMilestoneSummary.path>
          >,
        ) => withParams(`/${PfPgvMilestoneDetailsRoot.path}/${PfPgvMilestoneSummary.path}`)(props),
      },
      SummaryCommentEdit: {
        ...PfPgvMilestoneSummaryCommentEdit,
        params: (
          props: ExtractRouteParams<
            ComposePaths<typeof PfPgvMilestoneDetailsRoot.path, typeof PfPgvMilestoneSummaryCommentEdit.path>
          >,
        ) => withParams(`/${PfPgvMilestoneDetailsRoot.path}/${PfPgvMilestoneSummaryCommentEdit.path}`)(props),
      },
    },
  },
  PfPgvAnnualReportMilestoneRoot: {
    ...PfPgvMilestoneDetailsRoot,
    params: (props: ExtractRouteParams<typeof PfPgvMilestoneDetailsRoot.path>) =>
      withParams(`/${PfPgvMilestoneDetailsRoot.path}`)(props),
    nested: {
      Conditions: {
        ...PfPgvMilestoneConditions,
        params: (
          props: ExtractRouteParams<
            ComposePaths<typeof PfPgvMilestoneDetailsRoot.path, typeof PfPgvMilestoneConditions.path>
          >,
        ) => withParams(`/${PfPgvMilestoneDetailsRoot.path}/${PfPgvMilestoneConditions.path}`)(props),
      },
      ConditionsEdit: {
        ...PfPgvMilestoneConditionsEdit,
        params: (
          props: ExtractRouteParams<
            ComposePaths<typeof PfPgvMilestoneDetailsRoot.path, typeof PfPgvMilestoneConditionsEdit.path>
          >,
        ) => withParams(`/${PfPgvMilestoneDetailsRoot.path}/${PfPgvMilestoneConditionsEdit.path}`)(props),
      },
      GoalsAndMeasures: {
        ...PfPgvMilestoneGoalsAndMeasures,
        params: (
          props: ExtractRouteParams<
            ComposePaths<typeof PfPgvMilestoneDetailsRoot.path, typeof PfPgvMilestoneGoalsAndMeasures.path>
          >,
        ) => withParams(`/${PfPgvMilestoneDetailsRoot.path}/${PfPgvMilestoneGoalsAndMeasures.path}`)(props),
      },
      MilestoneMeasureNew: {
        ...PfPgvMilestoneMeasureNew,
        params: (
          props: ExtractRouteParams<
            ComposePaths<typeof PfPgvMilestoneDetailsRoot.path, typeof PfPgvMilestoneMeasureNew.path>
          >,
        ) => withParams(`/${PfPgvMilestoneDetailsRoot.path}/${PfPgvMilestoneMeasureNew.path}`)(props),
      },
      MilestoneGoalEdit: {
        ...PfPgvMilestoneGoalEdit,
        params: (
          props: ExtractRouteParams<
            ComposePaths<typeof PfPgvMilestoneDetailsRoot.path, typeof PfPgvMilestoneGoalEdit.path>
          >,
        ) => withParams(`/${PfPgvMilestoneDetailsRoot.path}/${PfPgvMilestoneGoalEdit.path}`)(props),
      },
      MilestoneMeasureEdit: {
        ...PfPgvMilestoneMeasureEdit,
        params: (
          props: ExtractRouteParams<
            ComposePaths<typeof PfPgvMilestoneDetailsRoot.path, typeof PfPgvMilestoneMeasureEdit.path>
          >,
        ) => withParams(`/${PfPgvMilestoneDetailsRoot.path}/${PfPgvMilestoneMeasureEdit.path}`)(props),
      },
      MilestoneFeatureKpi: {
        ...PfPgvMilestoneFeatureKpi,
        params: (
          props: ExtractRouteParams<
            ComposePaths<typeof PfPgvMilestoneDetailsRoot.path, typeof PfPgvMilestoneFeatureKpi.path>
          >,
        ) => withParams(`/${PfPgvMilestoneDetailsRoot.path}/${PfPgvMilestoneFeatureKpi.path}`)(props),
      },
      MilestoneFeatureKpiEdit: {
        ...PfPgvMilestoneFeatureKpiEdit,
        params: (
          props: ExtractRouteParams<
            ComposePaths<typeof PfPgvMilestoneDetailsRoot.path, typeof PfPgvMilestoneFeatureKpiEdit.path>
          >,
        ) => withParams(`/${PfPgvMilestoneDetailsRoot.path}/${PfPgvMilestoneFeatureKpiEdit.path}`)(props),
      },
      Summary: {
        ...PfPgvMilestoneSummary,
        params: (
          props: ExtractRouteParams<
            ComposePaths<typeof PfPgvMilestoneDetailsRoot.path, typeof PfPgvMilestoneSummary.path>
          >,
        ) => withParams(`/${PfPgvMilestoneDetailsRoot.path}/${PfPgvMilestoneSummary.path}`)(props),
      },
      SummaryCommentEdit: {
        ...PfPgvMilestoneSummaryCommentEdit,
        params: (
          props: ExtractRouteParams<
            ComposePaths<typeof PfPgvMilestoneDetailsRoot.path, typeof PfPgvMilestoneSummaryCommentEdit.path>
          >,
        ) => withParams(`/${PfPgvMilestoneDetailsRoot.path}/${PfPgvMilestoneSummaryCommentEdit.path}`)(props),
      },
    },
  },
}
