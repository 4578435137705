export const COLORS = {
  primary: {
    light: '#FEF0DB',
    main: '#F48802',
    dark: '#F07401',
  },
  secondary: {
    light: '#F0FAFF',
    main: '#29B4DA',
    dark: '#039FCF',
    lightHover: '#E1F4FA',
  },
  tertiary: {
    main: '#B167E7',
  },
  error: {
    light: '#FCEBEC',
    main: '#E45A5D',
    dark: '#D12E30',
  },
  warning: {
    light: '#FDF9E8',
    main: '#F1CA3D',
    dark: '#E7AB1A',
  },
  info: {
    light: '#E7F0FE',
    main: '#3A83F4',
    dark: '#1853EC',
  },
  success: {
    light: '#EBF6EE',
    main: '#58B570',
    dark: '#2D8D41',
  },
  text: {
    primary: 'rgba(0, 0, 0, 0.87)',
    secondary: 'rgba(0, 0, 0, 0.6)',
    disabled: 'rgba(0, 0, 0, 0.38)',
  },
  grey: {
    background: '#F9F9F9',
    divider: 'rgba(0, 0, 0, 0.1)',
    neutral: '#F5F5F5',
    dark: 'rgba(0, 0, 0, 0.54)',
    inactive: 'rgba(0, 0, 0, 0.12)',
    chip: {
      main: '#E0E0E0', // used for chips in Multiselect
      light: '#EBEBEB',
    },
  },
  action: {
    disabled: 'rgba(0, 0, 0, 0.24)',
    focus: 'rgba(0, 0, 0, 0.08)',
    selected: 'rgba(0, 0, 0, 0.08)',
    hover: 'rgba(0, 0, 0, 0.04)',
  },
  common: {
    white: '#FFF',
    black: '#000',
  },
  successLight: {
    light: '#EAFAF4',
    main: '#54DDA8',
    dark: '#29C77D',
  },
  gradient: {
    primaryLightToNeutral: 'linear-gradient(180deg, #F5F5F5 53%, #FEF0DB 100%)',
  },
} as const

export type COLORS_TYPE = typeof COLORS
