import { LoadingButton } from '@mui/lab'
import { DialogActions, DialogContent, Stack } from '@mui/material'
import { ReactElement, useState } from 'react'
import { Form } from 'react-final-form'
import { valueof } from 'src/@types/global'
import { useMessageSource } from 'src/i18n/useMessageSource'
import { FileService } from 'src/service/axios/FileService'
import { SecondaryButton } from 'src/shared/button/Buttons'
import { ENTITY_TYPE, FILE_TYPE, TEXT_LENGTH } from 'src/shared/constants/constants'
import { FileUploadField } from 'src/shared/form/control/FileUploadField'
import { TextField } from 'src/shared/form/control/TextField'
import { composeValidators, maxChar, required } from 'src/shared/form/validation/validators'
import { ModalDialog } from 'src/shared/modal-dialog/ModalDialog'
import { useNotificationService } from 'src/shared/utils/NotificationService'

interface Props {
  open: boolean
  onCancel: () => void
  onSave: () => void
  title?: string
  fileType: valueof<typeof FILE_TYPE>
  entityType: valueof<typeof ENTITY_TYPE>
  entityId: number
  acceptEmailTypes?: boolean
}

interface FormValues {
  title: string
  file: File
}

export const UploadModalDialog = ({
  open,
  onCancel,
  fileType,
  entityType,
  entityId,
  onSave,
  title,
  acceptEmailTypes,
}: Props): ReactElement => {
  const { getMessage } = useMessageSource()
  const notificationService = useNotificationService()

  const [loading, setLoading] = useState<boolean>(false)

  const defaultTitleKey = getMessage('label.file.upload.modal.title')

  const resolvedTitle = title || defaultTitleKey

  const onSaveLocal = async ({ file, title }: FormValues) => {
    try {
      setLoading(true)

      await FileService.upload(file, fileType, entityType, entityId, title)
      onSave()
      notificationService.changesSaved()
    } catch (e) {
      console.error(e)
      notificationService.operationFailed()
    } finally {
      setLoading(false)
    }
  }

  return (
    <ModalDialog open={open} onClose={onCancel} title={resolvedTitle} maxWidth="xs" withCloseIcon={true}>
      <Form<FormValues>
        onSubmit={onSaveLocal}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <DialogContent>
              <Stack spacing={2}>
                <TextField
                  name="title"
                  label={getMessage('label.title')}
                  validate={composeValidators(required(), maxChar(TEXT_LENGTH.S))}
                />
                <FileUploadField name="file" validate={required()} acceptEmailTypes={acceptEmailTypes} />
              </Stack>
            </DialogContent>
            <DialogActions>
              <SecondaryButton messageKey="button.cancel" onClick={onCancel} />
              <LoadingButton variant="contained" color="primary" type="submit" loading={loading}>
                {getMessage('button.save')}
              </LoadingButton>
            </DialogActions>
          </form>
        )}
      />
    </ModalDialog>
  )
}
