import { Stack } from '@mui/material'
import { Box } from '@mui/system'
import { ReactElement, useEffect, useMemo, useRef, useState } from 'react'
import { Form } from 'react-final-form'
import { useParams } from 'react-router'
import { Mutation_Root, Query_Root } from 'src/@types/graphql'
import { useMessageSource } from 'src/i18n/useMessageSource'
import { resolveApplicationDetailsRoot } from 'src/routing/routing-utils'
import {
  queryAllFundingRounds,
  saveProjectApplicationInformation,
} from 'src/screens/shared/application/details/application-information/applicationInformationQueries'
import {
  ApplicationInformationValues,
  useResolveApplicationInformationData,
} from 'src/screens/shared/application/details/application-information/ApplicationInformationService'
import { usePermissionsForProjectApplication } from 'src/service/security/PermissionHook'
import { SaveAndBackButton, SaveButton } from 'src/shared/button/Buttons'
import { RECOMMENDATION_OR_DECISION } from 'src/shared/constants/assessment-constants'
import { DOSSIER_STATUS, PROJECT } from 'src/shared/constants/constants'
import { Option } from 'src/shared/form/control'
import { AutoCompleteField } from 'src/shared/form/control/AutoCompleteField'
import { DirtyFormSpy } from 'src/shared/form/dirty/DirtyFormSpy'
import { PageLayout } from 'src/shared/layout/PageLayout'
import { ScreenLayout } from 'src/shared/layout/ScreenLayout'
import { NotAuthorized } from 'src/shared/not-authorized/NotAuthorized'
import { HelpAndInstructions } from 'src/shared/presentation/HelpAndInstructions'
import { useDelayedNavigate } from 'src/shared/utils/hooks/navigation-hooks'
import { useNotificationService } from 'src/shared/utils/NotificationService'
import { Utils } from 'src/shared/utils/Utils'
import { useClient } from 'urql'

type originType = 'SAVE' | 'SAVE_AND_BACK'

interface Props {
  baseUrl: '/pf-kap' | '/pf-pgv'
}

export const ApplicationInformationEditPage = ({ baseUrl }: Props): ReactElement => {
  const { projectId } = useParams()
  const { getMessage } = useMessageSource()
  const process = Utils.resolveProcessToLowerCase(baseUrl)
  const projectType = Utils.resolveProcess(baseUrl)
  const {
    loading,
    canEdit,
    metadata: { projectBaseId },
  } = usePermissionsForProjectApplication(projectType, parseInt(projectId as string))
  const navigate = useDelayedNavigate()
  const urqlClient = useClient()
  const notificationService = useNotificationService()
  const originRef = useRef<originType>('SAVE')
  const applicationDecisionOptions = useMemo(
    () => [
      { label: getMessage('label.recommendation.ACCEPT'), value: RECOMMENDATION_OR_DECISION.ACCEPT },
      { label: getMessage('label.recommendation.REJECT'), value: RECOMMENDATION_OR_DECISION.REJECT },
      { label: getMessage('label.recommendation.EXCLUSION'), value: RECOMMENDATION_OR_DECISION.EXCLUSION },
      { label: getMessage('label.field.not.available'), value: null },
    ],
    [getMessage],
  )
  const [fundingRoundOptions, setFundingRoundOptions] = useState<Option[]>([])

  const { applicationInformation, setApplicationInformation } = useResolveApplicationInformationData(projectBaseId)

  useEffect(() => {
    const fetchActiveFundingRounds = async () => {
      const { data } = await urqlClient
        .query<{
          funding_round: Query_Root['funding_round']
          pf_kap_project_by_pk: Query_Root['pf_kap_project_by_pk']
          pf_pgv_project_by_pk: Query_Root['pf_pgv_project_by_pk']
        }>(queryAllFundingRounds, {
          process: projectType,
          projectId,
          isPfKap: projectType === PROJECT.PF_KAP,
        })
        .toPromise()

      const fundingRoundOptions = []
      let applicationTypeCode: string

      if (projectType === PROJECT.PF_KAP) {
        applicationTypeCode = data?.pf_kap_project_by_pk?.application_type.code ?? ''
      } else if (projectType === PROJECT.PF_PGV) {
        applicationTypeCode = data?.pf_pgv_project_by_pk?.application_type.code ?? ''
      } else {
        throw Error(`Unsupported projectType [${projectType}]`)
      }

      data?.funding_round
        .filter(
          (fundingRound) =>
            // If there aren't application types for a round, it means that it is an old round of type
            // 'PG PGV' (global for all PF PGV projects). Include them while filtering.
            fundingRound.application_types?.includes(applicationTypeCode) ?? true,
        )
        .forEach((fundingRound) => {
          fundingRoundOptions.push({
            label: `${getMessage('label.round.id.character')}${fundingRound.id} ${fundingRound.name}`,
            value: fundingRound.id,
          })
        })

      fundingRoundOptions.push({ label: getMessage('label.field.not.available'), value: null })
      setFundingRoundOptions(fundingRoundOptions)
    }

    fetchActiveFundingRounds()
  }, [baseUrl, getMessage, projectId, projectType, urqlClient])

  const onBack = () => {
    navigate(resolveApplicationDetailsRoot(baseUrl).nested.Information.params({ projectId }))
  }

  const handleSubmitLocal = async (values: ApplicationInformationValues) => {
    const projectApplicationDecision = {
      project_base_id: projectBaseId,
      application_decision: values.applicationDecision,
    }
    const version = applicationInformation?.version ?? 0
    const fundingRoundId = values.fundingRoundId

    const { error, data } = await urqlClient
      .mutation<{
        insert_project_application_decision: Mutation_Root['insert_project_application_decision']
        update_dossier: Mutation_Root['update_dossier']
      }>(saveProjectApplicationInformation, { projectBaseId, version, projectApplicationDecision, fundingRoundId })
      .toPromise()

    if (
      error ||
      data?.insert_project_application_decision?.affected_rows !== 1 ||
      data?.update_dossier?.affected_rows !== 1
    ) {
      notificationService.operationFailed()
    } else {
      const newVersion = data!.insert_project_application_decision?.returning[0].version
      const newApplicationInformationValues = { ...values, version: newVersion } as ApplicationInformationValues
      setApplicationInformation(newApplicationInformationValues)
      notificationService.changesSaved()
      if (originRef.current === 'SAVE_AND_BACK') {
        navigate(resolveApplicationDetailsRoot(baseUrl).nested.Information.params({ projectId }))
      }
    }
  }

  const onSubmit = (origin: originType) => (_: any) => {
    // trigger submit event
    if (originRef) {
      originRef.current = origin
    }
    document.getElementById('edit-form')?.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }))
  }

  if (!loading && !canEdit) {
    return <NotAuthorized />
  }

  return (
    <ScreenLayout
      title={getMessage('label.application.info.edit')}
      onBack={onBack}
      hasSecondLevelNavigation={false}
      actions={
        <>
          <SaveAndBackButton origin="header" onClick={onSubmit('SAVE_AND_BACK')} />
          <SaveButton origin="header" onClick={onSubmit('SAVE')} />
        </>
      }
    >
      <PageLayout>
        {!loading && !!applicationInformation && (
          <>
            <HelpAndInstructions labelKey={`label.help.application.information.${process}`} defaultExpansion />
            <Box>
              <Form<ApplicationInformationValues>
                initialValues={applicationInformation}
                onSubmit={handleSubmitLocal}
                render={({ handleSubmit }) => {
                  return (
                    <form onSubmit={handleSubmit} id="edit-form">
                      <Stack spacing={2}>
                        <AutoCompleteField
                          label={getMessage('label.funding.round')}
                          name="fundingRoundId"
                          options={fundingRoundOptions}
                          disabled={applicationInformation?.dossierStatus === DOSSIER_STATUS.CONCEPT}
                        />
                        <AutoCompleteField
                          label={getMessage('label.application.decision')}
                          name="applicationDecision"
                          options={applicationDecisionOptions}
                        />
                      </Stack>
                      <DirtyFormSpy />
                    </form>
                  )
                }}
              />
            </Box>
          </>
        )}
      </PageLayout>
    </ScreenLayout>
  )
}
