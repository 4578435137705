import { Box } from '@mui/material'
import styled from 'styled-components/macro'

export const SuccessFactorIndicatorsStyled = styled(Box)`
  flex: 1 0 28%;

  // Layout styling if there are 4 indicator sections
  &:first-child:nth-last-child(4),
  &:first-child:nth-last-child(4) ~ & {
    flex: 1 0 42%;
  }

  .indicator-title {
    color: ${({ theme }) => theme.colors.text.disabled};
  }

  hr {
    margin-block: ${({ theme }) => theme.spacing(1)};
  }

  .html-renderer {
    color: ${({ theme }) => theme.colors.text.secondary};

    ul {
      padding-inline-start: ${({ theme }) => theme.spacing(2)};
      margin-top: ${({ theme }) => theme.spacing(1)};

      li::marker {
        color: ${({ theme }) => theme.colors.text.secondary};
      }
    }
  }
`
