import { useParams } from 'react-router'
import { useMessageSource } from 'src/i18n/useMessageSource'
import { ROUTES } from 'src/routing/routes'
import { BaseOrganizationView } from 'src/screens/shared/organization/view/BaseOrganizationView'
import { usePermissionsForKapProgram } from 'src/service/security/PermissionHook'
import { KAP_ORGANIZATION_TYPE, KAP_ORGANIZATION_TYPE_TYPE } from 'src/shared/constants/constants'
import { useDelayedNavigate } from 'src/shared/utils/hooks/navigation-hooks'

interface Props {
  kapOrganizationType: KAP_ORGANIZATION_TYPE_TYPE
}

export interface KapOrganizationLabelData {
  titleLabel: string
  helpTextLabel: string
}

export const kapOrganizationLabelDataFactory = (
  type: KAP_ORGANIZATION_TYPE_TYPE,
  mode: 'view' | 'edit' | 'add',
): KapOrganizationLabelData => {
  const labeledType = type.toLowerCase().replace('_', '.')

  return {
    titleLabel: `label.title.${mode}.${labeledType}`,
    helpTextLabel: `label.help.kap.program.${labeledType}`,
  }
}

export const KapOrganizationViewPage = ({ kapOrganizationType }: Props) => {
  const { programId, organizationId } = useParams()
  const program_id = parseInt(programId as string)

  const { getMessage } = useMessageSource()
  const navigate = useDelayedNavigate()
  const { loading, canEdit } = usePermissionsForKapProgram(program_id)

  const labelData = kapOrganizationLabelDataFactory(kapOrganizationType, 'view')

  const onEdit = () => {
    switch (kapOrganizationType) {
      case KAP_ORGANIZATION_TYPE.CONTACT_ORGANIZATION:
        navigate(ROUTES.KapDetailsRoot.nested.ContactCantonEdit.params({ programId, organizationId }))
        break
      case KAP_ORGANIZATION_TYPE.RESPONSIBLE_ORGANIZATION:
        navigate(ROUTES.KapDetailsRoot.nested.ResponsibleOrganizationEdit.params({ programId, organizationId }))
        break
      case KAP_ORGANIZATION_TYPE.PARTNER_ORGANIZATION:
        navigate(ROUTES.KapDetailsRoot.nested.PartnerOrganizationEdit.params({ programId, organizationId }))
        break
      default:
        throw Error(`Unknown KAP organization type ${kapOrganizationType}`)
    }
  }

  const onBack = () => {
    const route = ROUTES.KapDetailsRoot.nested.Organization.params({ programId })
    navigate(`${route}#${kapOrganizationType.toLowerCase().replace('_', '-')}`)
  }

  return (
    <>
      {!loading && (
        <BaseOrganizationView
          pageTitle={getMessage(labelData.titleLabel)}
          helpAndInstructionsTitle={labelData.helpTextLabel}
          canEdit={canEdit}
          onBack={onBack}
          onEdit={onEdit}
        />
      )}
    </>
  )
}
