import { gql } from '@urql/core'

export const fetchPrinciplesWithSubprinciplesQuery = gql`
  query fetchPrincipleConfigs($locale: String!, $programId: Int!) {
    principle_config(
      where: {
        _or: [
          { active: { _eq: true } }
          { subprinciple_configs: { kap_program_subprinciples: { kap_program_id: { _eq: $programId } } } }
        ]
      }
      order_by: { sort_number: asc }
    ) {
      id
      names(path: $locale)
      help_and_instructions(path: $locale)
      subprinciple_configs(order_by: { sort_number: asc }) {
        id
        code
        names(path: $locale)
        descriptions(path: $locale)
        kap_program_subprinciples(where: { kap_program_id: { _eq: $programId } }) {
          id
          concept_comment
          implementation_comment
        }
      }
    }
    kap_program_by_pk(id: $programId) {
      created_after_principles
    }
  }
`

export const fetchSubprincipleConfigByIdQuery = gql`
  query fetchSubprincipleByProgram($subprincipleId: Int!, $locale: String!) {
    subprinciple_config_by_pk(id: $subprincipleId) {
      id
      names(path: $locale)
      descriptions(path: $locale)
    }
  }
`

export const fetchKapProgramSubprincipleQuery = gql`
  query fetchKapProgramSubprincipleConceptPhase($subprincipleId: Int!, $programId: Int!) {
    kap_program_subprinciple(
      where: { _and: { subprinciple_config_id: { _eq: $subprincipleId }, kap_program_id: { _eq: $programId } } }
    ) {
      id
      concept_comment
      implementation_comment
    }
    subprinciple_config_by_pk(id: $subprincipleId) {
      code
    }
  }
`

export const insertKapProgramSubprincipleConceptPhaseMutation = gql`
  mutation insertKapProgramSubprincipleConceptPhase($object: [kap_program_subprinciple_insert_input!]!) {
    insert_kap_program_subprinciple(
      objects: $object
      on_conflict: { constraint: kap_program_subprinciple_uq, update_columns: concept_comment }
    ) {
      affected_rows
    }
  }
`

export const insertKapProgramSubprincipleImplementationPhaseMutation = gql`
  mutation insertKapProgramSubprincipleImplementationPhase($object: [kap_program_subprinciple_insert_input!]!) {
    insert_kap_program_subprinciple(
      objects: $object
      on_conflict: { constraint: kap_program_subprinciple_uq, update_columns: implementation_comment }
    ) {
      affected_rows
    }
  }
`

export const fetchKapProgramCreationQuery = gql`
  query fetchKapProgramCreation($programId: Int!) {
    kap_program_by_pk(id: $programId) {
      created_after_principles
    }
  }
`
