import { Query_Root } from 'src/@types/graphql'
import { fetchKapMeasureAnnualPlanningsValidityByMilestoneIdQuery } from 'src/screens/kap/implementation/milestone/kapMilestoneQueries'
import { ValidationListItemProps } from 'src/screens/shared/common/SummaryValidationUtils'
import { Client } from 'urql'

export const getKapMeasureAnnualPlanningValidation = async (
  milestoneId: number,
  urqlClient: Client,
  getMessage: (key: string) => string,
) => {
  const violations: ValidationListItemProps[] = []

  const { data } = await urqlClient
    .query<{
      kap_measure: Query_Root['kap_measure']
    }>(fetchKapMeasureAnnualPlanningsValidityByMilestoneIdQuery, { milestoneId })
    .toPromise()
  if (data) {
    const measures = data.kap_measure

    const measureIsInvalid = measures.some((measure) => {
      const plannings = measure.kap_measure_annual_plannings
      const valid = plannings?.[0]?.valid
      return !valid
    })

    if (measureIsInvalid) {
      const violation = {
        message: getMessage('validation.kap.program.incomplete.measure'),
        tab: 'planning',
      }
      violations.push(violation)
    }
  }

  return violations
}
