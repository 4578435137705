import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import { ReactElement, useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { Kap_Program, ProgramValidationResponse, Query_Root } from 'src/@types/graphql'
import { useMessageSource } from 'src/i18n/useMessageSource'
import { ROUTES } from 'src/routing/routes'
import { queryKapProgramAndValidationsByProgramId } from 'src/screens/kap/program/details/summary/programSummaryQueries'
import { ProgramSummarySection } from 'src/screens/kap/program/details/summary/ProgramSummarySection'
import { ValidationListItemProps } from 'src/screens/shared/common/SummaryValidationUtils'
import { ProgramWorkflowService } from 'src/service/axios/ProgramWorkflowService'
import { usePermissionsForKapProgram } from 'src/service/security/PermissionHook'
import { ProgramWorkflowPermissionService } from 'src/service/security/ProgramWorkflowPermissionService'
import { EditButton } from 'src/shared/button/Buttons'
import { DOSSIER_STATUS, DOSSIER_STATUS_TYPE } from 'src/shared/constants/constants'
import { NextState } from 'src/shared/constants/workflow-constants'
import { PageLayout } from 'src/shared/layout/PageLayout'
import { ScreenLayout } from 'src/shared/layout/ScreenLayout'
import { ProgramExportMenu } from 'src/shared/menu/ProgramExportMenu'
import { HelpAndInstructions } from 'src/shared/presentation/HelpAndInstructions'
import { HtmlRenderer } from 'src/shared/presentation/HtmlRenderer'
import { Section } from 'src/shared/presentation/Section'
import { useDelayedNavigate } from 'src/shared/utils/hooks/navigation-hooks'
import { useNotificationService } from 'src/shared/utils/NotificationService'
import { useClient } from 'urql'

export const KapSummaryPage = (): ReactElement => {
  const { programId } = useParams()
  const [programSummary, setProgramSummary] = useState<Kap_Program | undefined>()
  const [validationViolations, setValidationViolations] = useState<ProgramValidationResponse[]>([])
  const { getMessage } = useMessageSource()
  const navigate = useDelayedNavigate()
  const urqlClient = useClient()
  const notificationService = useNotificationService()
  const [nextStates, setNextStates] = useState<null | NextState[]>(null)

  const programIdParsed = parseInt(programId as string)
  const { loading, canEdit, userGlobalRoles, canViewCantonRestricted, canDeleteProgram, refetch } =
    usePermissionsForKapProgram(programIdParsed)

  const fetchData = useCallback(async () => {
    const { data, error } = await urqlClient
      .query<
        {
          kap_program_by_pk: Query_Root['kap_program_by_pk']
          validateProgramDescription: Query_Root['validateProgramDescription']
        },
        { programId: number }
      >(queryKapProgramAndValidationsByProgramId, { programId: programIdParsed })
      .toPromise()

    const nextStates = await ProgramWorkflowService.nextStates(
      data?.kap_program_by_pk?.dossier?.project_workflow_id ?? '',
    )
    const nextStatesForUser = ProgramWorkflowPermissionService.nextStatesForUser(nextStates)(userGlobalRoles, canEdit)
    setNextStates(nextStatesForUser)
    if (data?.kap_program_by_pk && data?.validateProgramDescription) {
      setProgramSummary(data.kap_program_by_pk)
      setValidationViolations(
        data.kap_program_by_pk.dossier.status === DOSSIER_STATUS.CANCELED ? [] : data.validateProgramDescription,
      )
    } else if (error) {
      notificationService.operationFailed()
    }
  }, [urqlClient, canEdit, userGlobalRoles, notificationService, programIdParsed])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  const handleEditComment = () => {
    navigate(ROUTES.KapDetailsRoot.nested.ProgramSummaryCommentEdit.params({ programId }))
  }

  const handleViolationClick = (validationItem: ValidationListItemProps) => {
    const route = validationItem.root ? validationItem.root : ROUTES.KapDetailsRoot.params({ programId })

    navigate(
      `${route}${validationItem.tab ? '/' + validationItem.tab : ''}${
        validationItem.section ? `#${validationItem.section}` : ''
      }`,
    )
  }

  const getValidationCompleteMessage = () => {
    if (programSummary?.dossier?.status === DOSSIER_STATUS.CANCELED) {
      return getMessage('label.program.is.canceled')
    } else {
      return getMessage('label.program.summary.description.completion.check.complete')
    }
  }

  return (
    <ScreenLayout
      title={getMessage('label.program.description')}
      actions={<>{canViewCantonRestricted && <ProgramExportMenu />}</>}
    >
      <PageLayout>
        <>
          {!loading && programSummary && nextStates !== null && (
            <>
              <ProgramSummarySection
                title={getMessage('label.program.summary.title')}
                status={programSummary.dossier.status as DOSSIER_STATUS_TYPE}
                helpAndInstructions={<HelpAndInstructions labelKey="label.help.program.summary.kap" />}
                actionsCompleteMessage={getValidationCompleteMessage()}
                violations={validationViolations}
                noItemsToValidate={programSummary.dossier.status === DOSSIER_STATUS.CANCELED}
                violationClickHandler={handleViolationClick}
                workflowId={programSummary.dossier.project_workflow_id as string}
                nextStates={nextStates}
                userGlobalRoles={userGlobalRoles}
                canEdit={canEdit}
                canDeleteProgram={canDeleteProgram}
                refetchPageData={() => {
                  fetchData()
                  refetch()
                }}
                programId={programIdParsed}
              />
              <Section
                title={getMessage('label.summary.comments')}
                helpAndInstructions={<HelpAndInstructions labelKey="label.help.kap.program.summary.comments" />}
                actionButton={<EditButton onClick={handleEditComment} hidden={!canEdit} />}
              >
                {programSummary.concept_comment && (
                  <Card>
                    <CardContent>
                      <HtmlRenderer html={programSummary.concept_comment} />
                    </CardContent>
                  </Card>
                )}
              </Section>
            </>
          )}
        </>
      </PageLayout>
    </ScreenLayout>
  )
}
