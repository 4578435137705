import { useParams } from 'react-router'
import { useMessageSource } from 'src/i18n/useMessageSource'
import { resolveDetailsRoot } from 'src/routing/routing-utils'
import { EvaluationMilestoneForm } from 'src/screens/shared/project/details/effectivity-management/evaluation-milestone/edit/EvaluationMilestoneForm'
import { usePermissionsForProject } from 'src/service/security/PermissionHook'
import { CreateButton } from 'src/shared/button/Buttons'
import { PageLayout } from 'src/shared/layout/PageLayout'
import { ScreenLayout } from 'src/shared/layout/ScreenLayout'
import { NotAuthorized } from 'src/shared/not-authorized/NotAuthorized'
import { HelpAndInstructions } from 'src/shared/presentation/HelpAndInstructions'
import { useDelayedNavigate } from 'src/shared/utils/hooks/navigation-hooks'
import { Utils } from 'src/shared/utils/Utils'

interface Props {
  baseUrl: '/pf-kap' | '/pf-pgv'
}

export const EvaluationMilestoneCreate = ({ baseUrl }: Props) => {
  const { projectId } = useParams()
  const { getMessage } = useMessageSource()

  const navigate = useDelayedNavigate()

  const projectType = Utils.resolveProcess(baseUrl)

  const {
    loading,
    canEdit,
    metadata: { dossierId },
  } = usePermissionsForProject(projectType, projectId as unknown as number)

  const onSubmit = () => {
    // trigger submit event
    document
      .getElementById('evaluation-milestone-form')
      ?.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }))
  }

  const onBack = (evaluationMilestoneId?: number) => {
    const prePath = resolveDetailsRoot(baseUrl)
    const fullPath = prePath.nested.EffectivityManagement.params({ projectId })

    if (evaluationMilestoneId) {
      navigate(`${fullPath}#${evaluationMilestoneId}`, {
        state: { expandedEvaluationMilestoneId: evaluationMilestoneId },
      })
    } else {
      navigate(fullPath)
    }
  }

  if (!loading && !canEdit) {
    return <NotAuthorized inEditPage />
  }

  return (
    <ScreenLayout
      title={getMessage('label.add.evaluation.milestone')}
      actions={<CreateButton origin="header" onClick={onSubmit} />}
      onBack={() => onBack()}
      hasSecondLevelNavigation={false}
    >
      <PageLayout>
        <>
          {!loading && dossierId && (
            <>
              <HelpAndInstructions
                labelKey={`label.help.project.evaluation.milestones.${projectType}`}
                defaultExpansion
              />
              <EvaluationMilestoneForm dossierId={dossierId} projectType={projectType} onSubmit={onBack} />
            </>
          )}
        </>
      </PageLayout>
    </ScreenLayout>
  )
}
