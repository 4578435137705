import { useRef } from 'react'
import { useParams } from 'react-router'
import { useMessageSource } from 'src/i18n/useMessageSource'
import { ROUTES } from 'src/routing/routes'
import { CommitteeValues } from 'src/screens/shared/application/committee/ApplicationCommiteeUtils'
import {
  useResolveCommitteeData,
  useUpdateCommitteeData,
} from 'src/screens/shared/application/committee/common/CommitteeService'
import { CommitteeEditForm } from 'src/screens/shared/application/committee/edit-committee/CommitteeEditForm'
import { usePermissionsForProjectCommittees } from 'src/service/security/PermissionHook'
import { SaveAndBackButton, SaveButton } from 'src/shared/button/Buttons'
import { COMMITTEE_FORM_MODE } from 'src/shared/constants/constants'
import { PageLayout } from 'src/shared/layout/PageLayout'
import { ScreenLayout } from 'src/shared/layout/ScreenLayout'
import { NotAuthorized } from 'src/shared/not-authorized/NotAuthorized'
import { HelpAndInstructions } from 'src/shared/presentation/HelpAndInstructions'
import { useDelayedNavigate } from 'src/shared/utils/hooks/navigation-hooks'
import { Utils } from 'src/shared/utils/Utils'
import { useUserLocale } from 'src/user/UserContext'

interface Props {
  baseUrl: '/pf-kap' | '/pf-pgv'
}

type originType = 'SAVE' | 'SAVE_AND_BACK'

export const ApplicationCommitteeEditPage = ({ baseUrl }: Props) => {
  const { projectId } = useParams()
  const id = parseInt(projectId as string)
  const { getMessage } = useMessageSource()
  const navigate = useDelayedNavigate()
  const originRef = useRef<originType>('SAVE')
  const locale = useUserLocale()

  const processLowerCase = Utils.resolveProcessToLowerCase(baseUrl)
  const process = Utils.resolveProcess(baseUrl)
  const {
    canEdit,
    loading,
    metadata: { projectBaseId },
  } = usePermissionsForProjectCommittees(process, id)

  const { initialFormValues, optionValues, setInitialFormValues } = useResolveCommitteeData(
    id,
    projectBaseId,
    process,
    true,
    COMMITTEE_FORM_MODE.PROJECT_APPLICATION_COMMITTEE,
    baseUrl,
  )
  const updateCommitteeData = useUpdateCommitteeData()

  const onUpdateHandler = async (values: CommitteeValues) => {
    await updateCommitteeData(values, projectBaseId, setInitialFormValues, originRef, onBack)
  }

  const onSubmit = (origin: originType) => (_: any) => {
    // trigger submit event
    if (originRef) {
      originRef.current = origin
    }
    document.getElementById('committees-form')?.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }))
  }

  const onBack = () => {
    if (process === 'PF_KAP') {
      navigate(ROUTES.PfKapApplicationCommittee.params({ projectId }))
    } else if (process === 'PF_PGV') {
      navigate(ROUTES.PfPgvApplicationCommittee.params({ projectId }))
    }
  }

  if (!loading && !canEdit) {
    return <NotAuthorized />
  }

  return (
    <ScreenLayout
      title={getMessage('label.title.edit.application.committees')}
      onBack={onBack}
      hasSecondLevelNavigation={false}
      actions={
        <>
          <SaveAndBackButton origin="header" onClick={onSubmit('SAVE_AND_BACK')} />
          <SaveButton origin="header" onClick={onSubmit('SAVE')} />
        </>
      }
    >
      <PageLayout>
        {!loading && (
          <>
            <HelpAndInstructions labelKey={`label.help.committees.${processLowerCase}`} defaultExpansion />
            <CommitteeEditForm
              locale={locale}
              initialValues={initialFormValues}
              qualityCriteriaOptions={optionValues.qualityCriteriaOptions}
              exclusionCriteriaOptions={optionValues.exclusionCriteriaOptions}
              gfchResponsibleOptions={optionValues.gfchResponsibleOptions}
              onSave={onUpdateHandler}
            />
          </>
        )}
      </PageLayout>
    </ScreenLayout>
  )
}
