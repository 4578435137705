import { Button } from '@mui/material'
import { ReactElement, useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { Dossier, Maybe, Milestone, Query_Root } from 'src/@types/graphql'
import { useMessageSource } from 'src/i18n/useMessageSource'
import { ROUTES } from 'src/routing/routes'
import { resolveKapMilestoneRoot, resolvePrimaryRoute } from 'src/routing/routing-utils'
import { queryDossierAndMilestoneForKapImplementationSummary } from 'src/screens/kap/implementation/summary/kapImplementationSummaryQueries'
import { SummarySection } from 'src/screens/shared/application/common/SummarySection'
import { ValidationListItemProps } from 'src/screens/shared/common/SummaryValidationUtils'
import { ProgramWorkflowService } from 'src/service/axios/ProgramWorkflowService'
import { usePermissionsForKapProgram } from 'src/service/security/PermissionHook'
import { ProgramWorkflowPermissionService } from 'src/service/security/ProgramWorkflowPermissionService'
import { EditButton } from 'src/shared/button/Buttons'
import { DOSSIER_STATUS, PROJECT } from 'src/shared/constants/constants'
import { MILESTONE_TYPE_TYPE } from 'src/shared/constants/milestone-constants'
import { NextState } from 'src/shared/constants/workflow-constants'
import { PageLayout } from 'src/shared/layout/PageLayout'
import { ScreenLayout } from 'src/shared/layout/ScreenLayout'
import { HandoverModalDialog } from 'src/shared/modal-dialog/HandoverModalDialog'
import { useSidebarAPI } from 'src/shared/sidebar/SidebarAwareContext'
import { useDelayedNavigate } from 'src/shared/utils/hooks/navigation-hooks'
import { useNotificationService } from 'src/shared/utils/NotificationService'
import { Utils } from 'src/shared/utils/Utils'
import { NextStateIf } from 'src/shared/workflow/NextStateIf'
import { useUserLocale } from 'src/user/UserContext'
import styled from 'styled-components/macro'
import { useClient } from 'urql'
import { getMilestoneTitleLabel } from 'src/screens/shared/implementation/details/milestone/utils/MilestoneUtils'

const SpanStyled = styled.span<{ $isCanceled: string }>`
  color: ${({ $isCanceled, theme }) =>
    $isCanceled === `${DOSSIER_STATUS.CANCELED}` ? theme.colors.error.dark : 'inherit'};
`

export const KapImplementationSummaryPage = (): ReactElement => {
  const { programId } = useParams()
  const { getMessage } = useMessageSource()

  const language = useUserLocale()
  const urqlClient = useClient()
  const navigate = useDelayedNavigate()
  const notificationService = useNotificationService()
  const sidebarAPI = useSidebarAPI()

  const [implementationSummary, setImplementationSummary] = useState<Maybe<Dossier>>()
  const [validationViolations, setValidationViolations] = useState<ValidationListItemProps[]>()
  const [nextStates, setNextStates] = useState<NextState[]>([])
  const [withdrawConfirmationOpen, setWithdrawConfirmationOpen] = useState(false)
  const [transitionToWithdraw, setTransitionToWithdraw] = useState(false)
  const [endProgramConfirmationOpen, setEndProgramConfirmationOpen] = useState(false)
  const [transitionToFinished, setTransitionToFinished] = useState(false)

  const {
    loading,
    canEdit,
    userGlobalRoles,
    refetch: refetchPermissions,
  } = usePermissionsForKapProgram(parseInt(programId as string))

  const validateImplementation = useCallback(
    (milestones: Milestone[]): ValidationListItemProps[] => {
      const validationViolation: ValidationListItemProps[] = []
      milestones.forEach((milestone) => {
        const milestoneId = milestone.id
        const milestonePath = Utils.resolveMilestonePath(milestone.type as MILESTONE_TYPE_TYPE)
        const primaryRoute = resolvePrimaryRoute(resolveKapMilestoneRoot(milestone.type as MILESTONE_TYPE_TYPE))

        if (milestone.status !== 'DONE') {
          const milestoneTitleLabel = getMilestoneTitleLabel(PROJECT.KAP, milestone.type)
          const violationMilestoneTitle = `${getMessage(milestoneTitleLabel)} ${milestone.year_in_focus ?? ''}`

          const violation = {
            message: getMessage('validation.section.incomplete', [violationMilestoneTitle]),
            root: primaryRoute.params({ programId, milestoneId, milestonePath }),
          }
          validationViolation.push(violation)
        }
      })
      return validationViolation
    },
    [getMessage, programId],
  )

  const fetchData = useCallback(async () => {
    const { data } = await urqlClient
      .query<{ dossier: Query_Root['dossier'] }>(queryDossierAndMilestoneForKapImplementationSummary, {
        programId,
      })
      .toPromise()

    const nextStates = await ProgramWorkflowService.nextStates(data?.dossier?.[0]?.project_workflow_id ?? '')
    const nextStatesForUser = ProgramWorkflowPermissionService.nextStatesForUser(nextStates)(userGlobalRoles, canEdit)

    if (data && data?.dossier?.[0]?.milestones) {
      const validationViolations = validateImplementation(data?.dossier?.[0]?.milestones)
      setImplementationSummary(data.dossier[0])
      setNextStates(nextStatesForUser)
      setValidationViolations(validationViolations)
    } else {
      notificationService.operationFailed()
    }
  }, [canEdit, programId, urqlClient, userGlobalRoles, validateImplementation, notificationService])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  const getValidationCompleteMessage = (): string => {
    if (
      implementationSummary?.status === DOSSIER_STATUS.APPLICATION ||
      implementationSummary?.status === DOSSIER_STATUS.IMPLEMENTATION ||
      implementationSummary?.status === DOSSIER_STATUS.FINISHED
    ) {
      return getMessage('label.kap.implementation.summary.check.complete')
    } else if (implementationSummary?.status === DOSSIER_STATUS.CANCELED) {
      return getMessage('label.kap.program.is.canceled')
    } else {
      return getMessage('label.program.not.yet.in.application.or.implementation')
    }
  }

  const refetchPageData = () => {
    fetchData()
    refetchPermissions()
    sidebarAPI.refetchSidebarData()
  }

  const handleViolationClick = (validationItem: ValidationListItemProps) => {
    navigate(validationItem?.root ?? '')
  }

  const handleEditComment = () => {
    navigate(ROUTES.KapImplementationDetailsRoot.nested.SummaryEdit.params({ programId }))
  }

  const openDialogWithdrawProgramHandler = () => {
    setWithdrawConfirmationOpen(true)
  }

  const cancelWithdrawProgramHandler = () => {
    setWithdrawConfirmationOpen(false)
  }

  const confirmWithdrawProgramHandler = async () => {
    try {
      setTransitionToWithdraw(true)

      await ProgramWorkflowService.transition(
        implementationSummary?.project_workflow_id as string,
        DOSSIER_STATUS.CANCELED,
        language,
      )

      refetchPageData()

      setWithdrawConfirmationOpen(false)
      notificationService.changesSaved()
    } catch (e) {
      notificationService.operationFailed()
    } finally {
      setTransitionToWithdraw(false)
    }
  }

  const openEndProgramConfirmationDialog = () => {
    setEndProgramConfirmationOpen(true)
  }

  const cancelEndProgramHandler = () => {
    setEndProgramConfirmationOpen(false)
  }

  const confirmEndProgramHandler = async () => {
    try {
      setTransitionToFinished(true)

      await ProgramWorkflowService.transition(
        implementationSummary?.project_workflow_id as string,
        DOSSIER_STATUS.FINISHED,
        language,
      )

      refetchPageData()

      setEndProgramConfirmationOpen(false)
      notificationService.changesSaved()
    } catch (e) {
      notificationService.operationFailed()
    } finally {
      setTransitionToFinished(false)
    }
  }

  return (
    <>
      <ScreenLayout title={getMessage('label.navigation.program.implementation')}>
        <PageLayout>
          <>
            {!loading && implementationSummary && validationViolations && (
              <SummarySection
                title={getMessage('label.kap.implementation.summary.title')}
                actionsHelpLabel={'label.help.kap.implementation.summary'}
                status={
                  <>
                    {getMessage('label.project.status')}:{' '}
                    <SpanStyled $isCanceled={implementationSummary.status}>
                      {getMessage(`label.project.status.${implementationSummary.status}`)}
                    </SpanStyled>
                  </>
                }
                violations={validationViolations}
                violationClickHandler={handleViolationClick}
                actionsCompleteMessage={getValidationCompleteMessage()}
                noItemsToValidate={
                  !(
                    implementationSummary.status === DOSSIER_STATUS.APPLICATION ||
                    implementationSummary.status === DOSSIER_STATUS.IMPLEMENTATION ||
                    implementationSummary.status === DOSSIER_STATUS.FINISHED
                  )
                }
                statusActions={
                  <>
                    <NextStateIf
                      forState={DOSSIER_STATUS.CANCELED}
                      nextStates={nextStates}
                      userGlobalRoles={userGlobalRoles}
                    >
                      <Button
                        variant="outlined"
                        color="secondary"
                        onClick={openDialogWithdrawProgramHandler}
                        sx={{
                          display:
                            implementationSummary.status !== DOSSIER_STATUS.IMPLEMENTATION || !canEdit
                              ? 'none'
                              : 'inline-block',
                        }}
                      >
                        {getMessage('button.withdraw.program')}
                      </Button>
                    </NextStateIf>
                    <NextStateIf
                      forState={DOSSIER_STATUS.FINISHED}
                      nextStates={nextStates}
                      userGlobalRoles={userGlobalRoles}
                    >
                      <Button
                        variant="contained"
                        color="secondary"
                        disabled={validationViolations.length > 0}
                        sx={{
                          display:
                            implementationSummary?.status !== DOSSIER_STATUS.IMPLEMENTATION || !canEdit
                              ? 'none'
                              : 'inline-block',
                        }}
                        onClick={openEndProgramConfirmationDialog}
                      >
                        {getMessage('button.end.program')}
                      </Button>
                    </NextStateIf>
                  </>
                }
                commentsHelpLabel={'label.help.kap.implementation.summary.comments'}
                commentActions={<EditButton onClick={handleEditComment} hidden={!canEdit} />}
                comment={implementationSummary.kap_program?.implementation_comment}
              />
            )}
            <HandoverModalDialog
              open={withdrawConfirmationOpen}
              onSend={confirmWithdrawProgramHandler}
              onCancel={cancelWithdrawProgramHandler}
              entityId={parseInt(programId as string)}
              loading={transitionToWithdraw}
              processType={PROJECT.KAP}
              titleKey="label.program.withdraw.confirm.title"
              contentKey="label.program.withdraw.confirm.body"
              buttonLoadingKey="button.withdraw.program"
            />
            <HandoverModalDialog
              open={endProgramConfirmationOpen}
              onSend={confirmEndProgramHandler}
              onCancel={cancelEndProgramHandler}
              entityId={parseInt(programId as string)}
              loading={transitionToFinished}
              processType={PROJECT.KAP}
              titleKey="label.program.finalize.confirm.title"
              contentKey="label.program.finalize.confirm.body"
              buttonLoadingKey="button.end.program"
            />
          </>
        </PageLayout>
      </ScreenLayout>
    </>
  )
}
