import { IconButton } from '@mui/material'
import { AxiosResponse } from 'axios'
import { ReactElement, useEffect, useState } from 'react'
import { useLocation } from 'react-router'
import { Mutation_Root, Project_Journal, Query_Root } from 'src/@types/graphql'
import { useMessageSource } from 'src/i18n/useMessageSource'
import { JournalCardContent } from 'src/screens/shared/project/journal/journal-card/JournalCardContent'
import {
  deleteProjectJournalByIdMutation,
  fetchProjectJournalFileUUIDsQuery,
} from 'src/screens/shared/project/journal/projectJournalQueries'
import { FileService } from 'src/service/axios/FileService'
import { ENTITY_TYPE, FILE_TYPE } from 'src/shared/constants/constants'
import { DeleteIcon, EditIcon, JournalIcon, UploadIcon } from 'src/shared/icons/Icons'
import { ConfirmationModalDialog } from 'src/shared/modal-dialog/ConfirmationModalDialog'
import { UploadModalDialog } from 'src/shared/modal-dialog/UploadModalDialog'
import { ExpandableCard } from 'src/shared/presentation/ExpandableCard'
import { S } from 'src/shared/styled/S'
import { DateUtils } from 'src/shared/utils/DateUtils'
import { useNotificationService } from 'src/shared/utils/NotificationService'
import { useClient } from 'urql'
import { useHashScroll } from 'src/shared/utils/hooks/navigation-hooks'

interface Props {
  id: string
  journal: Project_Journal
  canEdit: boolean
  onEdit: () => void
  refetch: () => void
}

export const ExpandableJournalCard = ({ id, journal, canEdit, onEdit, refetch }: Props): ReactElement => {
  const { state } = useLocation()
  const expandedJournalId = state?.expandedJournalId

  const { getMessage } = useMessageSource()

  const urqlClient = useClient()
  const notificationService = useNotificationService()

  const [expanded, setExpanded] = useState(false)
  const [deleteConfirmation, setDeleteConfirmation] = useState(false)
  const [uploadModalOpen, setUploadModelOpen] = useState<boolean>(false)
  const [refetchDocs, setRefetchDocs] = useState<boolean>(false)

  useEffect(() => {
    const shouldExpand = parseInt(expandedJournalId as string) === journal.id
    setExpanded(shouldExpand)
  }, [journal, expandedJournalId])

  const handleDeleteJournalEntry = () => {
    setDeleteConfirmation(true)
  }

  const handleCancelDeleteJournalEntry = () => {
    setDeleteConfirmation(false)
  }

  const deleteJournalFiles = async () => {
    const { data } = await urqlClient
      .query<{ file: Query_Root['file'] }>(fetchProjectJournalFileUUIDsQuery, {
        journalId: journal.id,
      })
      .toPromise()

    if (data?.file && data.file.length > 0) {
      const promises: Promise<AxiosResponse>[] = []
      data.file.forEach((file) => promises.push(FileService.remove(file.uuid)))
      await Promise.all(promises)
    }
  }

  const deleteJournalEntry = async () => {
    const { data } = await urqlClient
      .mutation<{ delete_project_journal_by_pk: Mutation_Root['delete_project_journal_by_pk'] }>(
        deleteProjectJournalByIdMutation,
        {
          id: journal.id,
        },
      )
      .toPromise()

    if (data?.delete_project_journal_by_pk) {
      refetch()
      notificationService.deleteSuccessful()
    } else {
      notificationService.operationFailed()
    }
  }

  const handleConfirmDeleteJournalEntry = async () => {
    setDeleteConfirmation(false)
    deleteJournalFiles().then(() => deleteJournalEntry())
  }

  const onFileSave = async () => {
    setExpanded(true)
    setUploadModelOpen(false)
    setRefetchDocs(true)
  }

  useHashScroll(id, true)

  return (
    <>
      <ExpandableCard
        id={id}
        header={
          <S.JournalCard.Content>
            <JournalIcon fontSize="small" />
            <S.JournalCard.ItemContainer>
              <S.JournalCard.ItemTitle variant="subtitle2">{journal.title}</S.JournalCard.ItemTitle>
              <S.JournalCard.ItemDate variant="body2">
                {DateUtils.parseAndFormatDate(journal.journal_date)}
              </S.JournalCard.ItemDate>
              <S.JournalCard.ItemStatusChip
                size="small"
                label={getMessage(`label.project.journal.status.${journal.status}`)}
              />
            </S.JournalCard.ItemContainer>
          </S.JournalCard.Content>
        }
        actions={
          canEdit && (
            <>
              <IconButton color="primary" onClick={onEdit}>
                <EditIcon />
              </IconButton>
              <IconButton color="primary" onClick={() => setUploadModelOpen(true)}>
                <UploadIcon />
              </IconButton>
              <IconButton color="primary" onClick={handleDeleteJournalEntry}>
                <DeleteIcon />
              </IconButton>
              <ConfirmationModalDialog
                open={deleteConfirmation}
                onCancel={handleCancelDeleteJournalEntry}
                onConfirm={handleConfirmDeleteJournalEntry}
              >
                {getMessage('label.delete.confirm')}
              </ConfirmationModalDialog>
            </>
          )
        }
        content={
          <JournalCardContent
            journalId={journal.id}
            refetchDocs={refetchDocs}
            resetRefetchDocs={() => setRefetchDocs(false)}
          />
        }
        localExpansion={expanded}
      />

      <UploadModalDialog
        open={uploadModalOpen}
        onCancel={() => setUploadModelOpen(false)}
        onSave={onFileSave}
        fileType={FILE_TYPE.PROJECT_JOURNAL}
        entityType={ENTITY_TYPE.PROJECT_JOURNAL}
        entityId={journal.id}
        acceptEmailTypes
      />
    </>
  )
}
