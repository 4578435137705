import { Box } from '@mui/material'
import { ReactElement, useMemo } from 'react'
import { Form } from 'react-final-form'
import { TextLength } from 'src/shared/constants/constants'
import { HtmlEditorField } from 'src/shared/form/control/HtmlEditorField'
import { DirtyFormSpy } from 'src/shared/form/dirty/DirtyFormSpy'
import { createDecorators } from 'src/shared/form/utils/decorators'
import { composeValidators, maxChar, required as requiredValidator } from 'src/shared/form/validation/validators'

interface Props {
  value: string
  onSave: (values: KapStartingPointAndVisionFieldFormValues) => Promise<void>
  textLength: TextLength
  label: string
  required: boolean
}

export interface KapStartingPointAndVisionFieldFormValues {
  value: string
}

const decorators = createDecorators()

export const KapStartingPointAndVisionFieldEditForm = ({
  value,
  onSave,
  textLength,
  label,
  required,
}: Props): ReactElement => {
  const initialValues = useMemo(() => ({ value }), [value])

  return (
    <Box>
      {initialValues && (
        <Form<KapStartingPointAndVisionFieldFormValues>
          initialValues={initialValues}
          onSubmit={onSave}
          decorators={decorators}
          render={({ handleSubmit }) => {
            return (
              <form id="edit-form" onSubmit={handleSubmit}>
                <HtmlEditorField
                  label={label}
                  required={required}
                  name="value"
                  validate={
                    required ? composeValidators(requiredValidator(), maxChar(textLength)) : maxChar(textLength)
                  }
                />
                <DirtyFormSpy />
              </form>
            )
          }}
        />
      )}
    </Box>
  )
}
