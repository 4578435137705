import combinedQuery from 'graphql-combine-query'
import { ReactElement, useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { Query_Root } from 'src/@types/graphql'
import { useMessageSource } from 'src/i18n/useMessageSource'
import { ROUTES } from 'src/routing/routes'
import { KapMilestoneMeasurePlanningContent } from 'src/screens/kap/implementation/milestone/details/annual-planning/KapMilestoneMeasurePlanningContent'
import { KapMilestoneMeasureGroup } from 'src/screens/kap/implementation/milestone/details/section-group/KapMilestoneMeasureGroup'
import {
  fetchKapMilestoneAnnualPlanningValidityQuery,
  fetchKapMilestoneMeasuresByProgramIdQuery,
} from 'src/screens/kap/implementation/milestone/kapMilestoneQueries'
import { KapMeasuresSection } from 'src/screens/shared/common/KapMeasuresSection'
import { queryMilestoneById } from 'src/screens/shared/implementation/details/milestoneQueries'
import { usePermissionsForKapMilestones } from 'src/service/security/PermissionHook'
import { LEVEL } from 'src/shared/constants/constants'
import {
  KapMeasureAndValid,
  MILESTONE_RESPONSIBLE_TYPE_TYPE,
  MILESTONE_STATUS_TYPE,
} from 'src/shared/constants/milestone-constants'
import { PageLayout } from 'src/shared/layout/PageLayout'
import { ScreenLayout } from 'src/shared/layout/ScreenLayout'
import { KapMilestoneExportMenu } from 'src/shared/menu/KapMilestoneExportMenu'
import { NotAuthorized } from 'src/shared/not-authorized/NotAuthorized'
import { useDelayedNavigate } from 'src/shared/utils/hooks/navigation-hooks'
import { useNotificationService } from 'src/shared/utils/NotificationService'
import { Utils } from 'src/shared/utils/Utils'
import { useClient, useQuery } from 'urql'

export const KapMilestoneAnnualPlanningPage = (): ReactElement => {
  const { programId, milestoneId, milestonePath } = useParams()
  const navigate = useDelayedNavigate()
  const { getMessage } = useMessageSource()
  const notificationService = useNotificationService()
  const urqlClient = useClient()

  const program_id = parseInt(programId as string)
  const milestone_id = parseInt(milestoneId as string)
  const milestoneType = Utils.resolveMilestoneType(milestonePath)

  const [measures, setMeasures] = useState<KapMeasureAndValid[]>([])

  const [{ data, error }] = useQuery<{ milestone_by_pk: Query_Root['milestone_by_pk'] }, { id: number }>({
    query: queryMilestoneById,
    variables: { id: milestone_id },
  })

  if (error) {
    notificationService.operationFailed()
  }

  const milestone = data?.milestone_by_pk

  const { loading, canView, canEdit } = usePermissionsForKapMilestones(
    program_id,
    milestone?.status as MILESTONE_STATUS_TYPE,
    milestone?.responsible_type as MILESTONE_RESPONSIBLE_TYPE_TYPE,
  )

  useEffect(() => {
    const initData = async () => {
      const { document, variables } = combinedQuery('fetchKapMeasuresAndKapMilestoneAnnualPlanning')
        .add<{ kap_measure: Query_Root['kap_measure'] }, { programId: number }>(
          fetchKapMilestoneMeasuresByProgramIdQuery,
          {
            programId: program_id,
          },
        )
        .add<{ kap_measure_annual_planning: Query_Root['kap_measure_annual_planning'] }, { milestoneId: number }>(
          fetchKapMilestoneAnnualPlanningValidityQuery,
          { milestoneId: milestone_id },
        )

      const { data } = await urqlClient
        .query<
          {
            kap_measure: Query_Root['kap_measure']
            kap_measure_annual_planning: Query_Root['kap_measure_annual_planning']
          },
          { programId: number; milestoneId: number }
        >(document, variables!)
        .toPromise()

      if (data && data?.kap_measure && data?.kap_measure_annual_planning) {
        const measures = data?.kap_measure.map((m) => ({
          ...m,
          milestoneMeasureValid:
            data?.kap_measure_annual_planning?.find((v) => v.kap_measure_id === m.id)?.valid ?? false,
        }))
        setMeasures(measures)
      } else {
        notificationService.operationFailed()
      }
    }

    initData()
  }, [urqlClient, program_id, milestone_id, notificationService])

  const onBack = () => {
    navigate(ROUTES.KapImplementationDetailsRoot.params({ programId }))
  }

  const onEditHandle = (measureId: number) => {
    const route = ROUTES.KapAnnualPlanningMilestoneRoot.nested.PlanningEdit.params({
      programId,
      milestoneId,
      milestonePath,
      measureId,
    })
    navigate(route)
  }

  if (!loading && !canView) {
    return <NotAuthorized inSecondLevelNavigationTab />
  }

  return (
    <>
      <ScreenLayout
        title={`${getMessage(`label.milestone.type.${milestoneType}`)} ${milestone?.year_in_focus ?? ''}`}
        onBack={onBack}
        actions={<>{canView && <KapMilestoneExportMenu year={milestone?.year_in_focus} />}</>}
      >
        <PageLayout>
          <>
            <KapMeasuresSection measures={measures} helpAndInstructionsKey="label.help.kap.annual.plan.measures">
              {({ activeLevels, communicationMeasures, interventionMeasures, networkMeasures, policyMeasures }) => (
                <>
                  {activeLevels.includes(LEVEL.INTERVENTION) && (
                    <KapMilestoneMeasureGroup
                      idCharacter={getMessage(`label.kap.measure.id.character.${LEVEL.INTERVENTION}`)}
                      title={getMessage(`label.level.${LEVEL.INTERVENTION}`)}
                      canEditMeasure={canEdit}
                      onEdit={onEditHandle}
                      measures={interventionMeasures}
                    >
                      {({ measureId }) => (
                        <KapMilestoneMeasurePlanningContent
                          measureId={measureId}
                          measureLevel={LEVEL.INTERVENTION}
                          milestoneId={milestone_id}
                          yearInFocus={milestone?.year_in_focus ?? ''}
                        />
                      )}
                    </KapMilestoneMeasureGroup>
                  )}
                  {activeLevels.includes(LEVEL.POLICY) && (
                    <KapMilestoneMeasureGroup
                      idCharacter={getMessage(`label.kap.measure.id.character.${LEVEL.POLICY}`)}
                      title={getMessage(`label.level.${LEVEL.POLICY}`)}
                      canEditMeasure={canEdit}
                      onEdit={onEditHandle}
                      measures={policyMeasures}
                    >
                      {({ measureId }) => (
                        <KapMilestoneMeasurePlanningContent
                          measureId={measureId}
                          measureLevel={LEVEL.POLICY}
                          milestoneId={milestone_id}
                          yearInFocus={milestone?.year_in_focus ?? ''}
                        />
                      )}
                    </KapMilestoneMeasureGroup>
                  )}
                  {activeLevels.includes(LEVEL.NETWORK) && (
                    <KapMilestoneMeasureGroup
                      idCharacter={getMessage(`label.kap.measure.id.character.${LEVEL.NETWORK}`)}
                      title={getMessage(`label.level.${LEVEL.NETWORK}`)}
                      canEditMeasure={canEdit}
                      onEdit={onEditHandle}
                      measures={networkMeasures}
                    >
                      {({ measureId }) => (
                        <KapMilestoneMeasurePlanningContent
                          measureId={measureId}
                          measureLevel={LEVEL.NETWORK}
                          milestoneId={milestone_id}
                          yearInFocus={milestone?.year_in_focus ?? ''}
                        />
                      )}
                    </KapMilestoneMeasureGroup>
                  )}
                  {activeLevels.includes(LEVEL.COMMUNICATION) && (
                    <KapMilestoneMeasureGroup
                      idCharacter={getMessage(`label.kap.measure.id.character.${LEVEL.COMMUNICATION}`)}
                      title={getMessage(`label.level.${LEVEL.COMMUNICATION}`)}
                      canEditMeasure={canEdit}
                      onEdit={onEditHandle}
                      measures={communicationMeasures}
                    >
                      {({ measureId }) => (
                        <KapMilestoneMeasurePlanningContent
                          measureId={measureId}
                          measureLevel={LEVEL.COMMUNICATION}
                          milestoneId={milestone_id}
                          yearInFocus={milestone?.year_in_focus ?? ''}
                        />
                      )}
                    </KapMilestoneMeasureGroup>
                  )}
                </>
              )}
            </KapMeasuresSection>
          </>
        </PageLayout>
      </ScreenLayout>
    </>
  )
}
