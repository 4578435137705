import { LoadingButton } from '@mui/lab'
import { Box, Card, Typography } from '@mui/material'
import { ReactElement, ReactNode, useState } from 'react'
import { useMessageSource } from 'src/i18n/useMessageSource'
import { DownloadIcon } from 'src/shared/icons/Icons'
import { S } from 'src/shared/styled/S'

interface Props {
  reportName?: string
  handleDownload?: () => Promise<void>
  children?: ReactNode
  reportGrid?: ReactNode
}

export const ReportCard = ({ reportName, handleDownload, children, reportGrid }: Props): ReactElement => {
  const { getMessage } = useMessageSource()
  const [loadingDownload, setLoadingDownload] = useState(false)

  const localHandleDownload = async () => {
    setLoadingDownload(true)
    await (handleDownload && handleDownload())
    setLoadingDownload(false)
  }

  return (
    <Card>
      {reportName && (
        <S.Card.Content sx={{ justifyContent: 'space-between' }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography>{reportName}</Typography>
            {children}
          </Box>
          {handleDownload && (
            <LoadingButton
              variant="text"
              color="primary"
              startIcon={<DownloadIcon />}
              onClick={localHandleDownload}
              loading={loadingDownload}
            >
              {getMessage('button.download')}
            </LoadingButton>
          )}
        </S.Card.Content>
      )}
      {reportGrid}
    </Card>
  )
}
