import { Stack } from '@mui/material'
import { DataGridPro, GridColDef, GridRowParams, useGridApiRef } from '@mui/x-data-grid-pro'
import { ReactElement, useMemo } from 'react'
import { useParams } from 'react-router'
import { useMessageSource } from 'src/i18n/useMessageSource'
import { resolveKapMilestoneRoot, resolvePrimaryRoute } from 'src/routing/routing-utils'
import { KapMilestoneViewModel } from 'src/screens/kap/implementation/milestone/KapImplementationMilestonePage'
import { DateUtils } from 'src/shared/utils/DateUtils'
import { useGridTranslateHook } from 'src/shared/utils/hooks/grid-translate-hook'
import { useDelayedNavigate } from 'src/shared/utils/hooks/navigation-hooks'
import { Utils } from 'src/shared/utils/Utils'
import styled from 'styled-components/macro'

const DataGridProStyled = styled(DataGridPro)`
  & .MuiDataGrid-row {
    &:hover {
      cursor: pointer;
    }
  }
`

interface Props {
  milestones: KapMilestoneViewModel[]
  programId: number
}

export const KapMilestoneGrid = ({ milestones }: Props): ReactElement => {
  const { programId } = useParams()
  const { getMessage } = useMessageSource()
  const apiRef = useGridApiRef()
  const navigate = useDelayedNavigate()

  const gridTranslations = useGridTranslateHook()

  const rows = useMemo(() => milestones, [milestones])

  const columns = useMemo(
    () =>
      [
        {
          field: 'title',
          headerName: getMessage('label.milestone'),
          flex: 3,
        },
        {
          field: 'end_date',
          headerName: getMessage('label.end.date'),
          flex: 1,
          renderCell: ({ value }) => DateUtils.parseAndFormatDate(value),
        },
        {
          field: 'status',
          headerName: getMessage('label.status'),
          flex: 1,
          renderCell: ({ value }) => getMessage(`label.milestone.status.${value}`),
        },
        {
          field: 'organization',
          headerName: getMessage('label.organization'),
          flex: 1,
        },
      ] as GridColDef[],
    [getMessage],
  )

  const onRowClick = ({ row }: GridRowParams) => {
    const milestoneId = row?.id
    const milestonePath = Utils.resolveMilestonePath(row?.type)
    const primaryRoute = resolvePrimaryRoute(resolveKapMilestoneRoot(row?.type))
    navigate(primaryRoute.params({ programId, milestoneId, milestonePath }))
  }

  return (
    <div style={{ height: rows.length > 0 ? 'auto' : 200, width: '100%' }}>
      <DataGridProStyled
        apiRef={apiRef}
        rows={rows}
        columns={columns}
        localeText={gridTranslations}
        onRowClick={(props) => onRowClick(props)}
        components={{
          NoRowsOverlay: () => (
            <Stack height="100%" justifyContent="center" alignItems="center">
              {getMessage('label.program.not.yet.in.application.or.implementation')}
            </Stack>
          ),
        }}
        autoHeight={rows.length > 0}
        disableRowSelectionOnClick
        disableColumnReorder
        disableColumnFilter
        disableColumnPinning
        disableColumnSelector
        hideFooter
      />
    </div>
  )
}
