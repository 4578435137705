import localEnv from './local-environment.json'

export interface ApplicationConfig {
  graphqlUrl: string
  backendUrl: string
  reCaptchaSiteKey: string
  environment: string
}

const loadEnvironment = async (): Promise<ApplicationConfig> => {
  if (isDevMode()) {
    return localEnv
  }

  return fetch('/environment/environment.json').then((response) => response.json())
}

export const EnvironmentService = {
  loadEnvironment,
}

export const isDevMode = (): boolean => {
  return import.meta.env.DEV
}
