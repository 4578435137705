import { Stack } from '@mui/material'
import { FormApi } from 'final-form'
import combinedQuery, { CombinedQueryBuilder } from 'graphql-combine-query'
import { ReactElement, useEffect, useMemo, useState } from 'react'
import { Form } from 'react-final-form'
import { useParams } from 'react-router-dom'
import { FormValidationErrors } from 'src/@types/global'
import { Mutation_Root, Pf_Kap_Project, Query_Root } from 'src/@types/graphql'
import { useMessageSource } from 'src/i18n/useMessageSource'
import { ROUTES } from 'src/routing/routes'
import {
  CHANGE_PROJECT_LEADER_MUTATION,
  SET_PREVIOUS_PROJECT_LEADER_AS_CONTRIBUTOR_MUTATION,
} from 'src/screens/shared/graphql/SharedProjectMutations'
import { usePermissionsForProject } from 'src/service/security/PermissionHook'
import { SaveAndBackButton, SaveButton } from 'src/shared/button/Buttons'
import {
  MODULE_TYPES,
  PF_KAP_LIFE_PHASES,
  PF_KAP_MODULES,
  PROJECT,
  PROJECT_USER_ROLES,
  TEXT_LENGTH,
} from 'src/shared/constants/constants'
import { AutoCompleteField } from 'src/shared/form/control/AutoCompleteField'
import { DateTimePickerField } from 'src/shared/form/control/DateTimePickerField'
import { HtmlEditorField } from 'src/shared/form/control/HtmlEditorField'
import { MultiSelectField } from 'src/shared/form/control/MultiSelectField'
import { TextField } from 'src/shared/form/control/TextField'
import { DirtyFormSpy } from 'src/shared/form/dirty/DirtyFormSpy'
import { createDecorators } from 'src/shared/form/utils/decorators'
import { composeValidators, maxChar, required, validDate } from 'src/shared/form/validation/validators'
import { PageLayout } from 'src/shared/layout/PageLayout'
import { ScreenLayout } from 'src/shared/layout/ScreenLayout'
import { NotAuthorized } from 'src/shared/not-authorized/NotAuthorized'
import { HelpAndInstructions } from 'src/shared/presentation/HelpAndInstructions'
import { DateUtils } from 'src/shared/utils/DateUtils'
import { useDelayedNavigate } from 'src/shared/utils/hooks/navigation-hooks'
import { useError } from 'src/shared/utils/hooks/page-loading-error-hook'
import { useIsMounted } from 'src/shared/utils/hooks/react-hooks'
import { HtmlSanitizer } from 'src/shared/utils/HtmlSanitizer'
import { useNotificationService } from 'src/shared/utils/NotificationService'
import { Utils } from 'src/shared/utils/Utils'
import { gql, useClient } from 'urql'

export const queryPfKapProjectById = gql`
  query ($pf_kap_id: Int!) {
    pf_kap_project_by_pk(id: $pf_kap_id) {
      id
      modules
      application_type {
        id
        key
      }
      project_base {
        id
        project_leader_id
        dossier {
          title
          short_title
          description
          start_date
          end_date
        }
        project_users {
          type
          user_id
          user {
            email
            first_name
            last_name
          }
        }
      }
    }
  }
`

export const editDossier = gql`
  mutation (
    $description: String
    $projectEndDate: date
    $projectStartDate: date
    $shortTitle: String!
    $title: String!
    $pfKapId: Int!
    $projectLeaderId: Int!
    $modules: jsonb!
  ) {
    update_dossier(
      _set: {
        start_date: $projectStartDate
        description: $description
        end_date: $projectEndDate
        short_title: $shortTitle
        title: $title
      }
      where: { project_bases: { pf_kap_projects: { _and: [{ id: { _eq: $pfKapId } }] } } }
    ) {
      affected_rows
    }
    update_project_base(
      where: { pf_kap_projects: { _and: [{ id: { _eq: $pfKapId } }] } }
      _set: { project_leader_id: $projectLeaderId }
    ) {
      affected_rows
    }
    update_pf_kap_project(where: { _and: [{ id: { _eq: $pfKapId } }] }, _set: { modules: $modules }) {
      returning {
        id
      }
    }
  }
`

interface EditDossierTypes {
  title: string
  shortTitle: string
  description?: string | null
  projectStartDate?: string
  projectEndDate?: string
  pfKapId: number
  projectLeaderId: number | null
  modules: MODULE_TYPES[]
}

interface EditFormValues {
  title: string
  shortTitle: string
  description?: string | null
  modules: MODULE_TYPES[]
  projectStartDate?: string
  projectEndDate?: string
  pfKapId: number
  projectLeaderId: number | null
  applicationTypeId: number
}

const initialProjectValues = (pf_kap_project_data: Pf_Kap_Project, modules: MODULE_TYPES[]): EditFormValues => {
  return {
    title: pf_kap_project_data.project_base.dossier.title,
    shortTitle: pf_kap_project_data.project_base.dossier.short_title,
    description: HtmlSanitizer.sanitize(pf_kap_project_data?.project_base?.dossier?.description as string) ?? null,
    modules: modules,
    projectStartDate: pf_kap_project_data.project_base.dossier.start_date
      ? DateUtils.parseAndFormatDate(pf_kap_project_data.project_base.dossier.start_date)
      : pf_kap_project_data.project_base.dossier.start_date,
    projectEndDate: pf_kap_project_data.project_base.dossier.end_date
      ? DateUtils.parseAndFormatDate(pf_kap_project_data.project_base.dossier.end_date)
      : pf_kap_project_data.project_base.dossier.end_date,
    pfKapId: pf_kap_project_data.id,
    projectLeaderId: pf_kap_project_data?.project_base?.project_leader_id ?? null,
    applicationTypeId: pf_kap_project_data.application_type.id,
  }
}

const decorators = createDecorators()

export const PfKapBasicInformationEditPage = (): ReactElement => {
  const isMounted = useIsMounted()
  const { projectId } = useParams()
  const pf_kap_id = parseInt(projectId as string)
  const [initialValues, setInitialValues] = useState<EditFormValues | null>(null)
  const { getMessage } = useMessageSource()
  const urqlClient = useClient()
  const notificationService = useNotificationService()
  const [project, setProject] = useState<Pf_Kap_Project | null>(null)
  const {
    canEdit,
    loading,
    metadata: { userGlobalRoles, userProjectRoles },
    refetch: refetchUserPermissions,
  } = usePermissionsForProject(PROJECT.PF_KAP, pf_kap_id)

  const setError = useError()

  useEffect(() => {
    const fetchAndSetData = async () => {
      const { data } = await urqlClient
        .query<
          {
            pf_kap_project_by_pk: Query_Root['pf_kap_project_by_pk']
          },
          {
            pf_kap_id: number
          }
        >(queryPfKapProjectById, { pf_kap_id })
        .toPromise()

      if (data) {
        setProject(data!.pf_kap_project_by_pk as Pf_Kap_Project)
      } else {
        setError()
      }
    }
    fetchAndSetData()
  }, [pf_kap_id, setError, urqlClient])

  useEffect(() => {
    const initializeFormValues = async () => {
      if (project) {
        const initialValues = initialProjectValues(project, project.modules)
        setInitialValues(initialValues)
      }
    }
    initializeFormValues()
  }, [project])

  const options = useMemo(() => {
    const projectUsersOptions =
      project?.project_base?.project_users
        .filter(
          (project_user) =>
            project_user.type === PROJECT_USER_ROLES.CONTRIBUTOR || project_user.type === PROJECT_USER_ROLES.ADMIN,
        )
        .map((project_user) => ({
          label: project_user.user.email,
          value: project_user.user_id,
        }))
        .filter(
          (project_user, index, self) =>
            self.findIndex((project_user) => project_user.label === self[index].label) === index,
        ) ?? []

    const moduleOptions =
      PF_KAP_LIFE_PHASES.map((module) => ({
        label: getMessage(`label.module.description.${module}`),
        value: module,
      })) ?? []

    const oldModules: MODULE_TYPES[] = ['A', 'B', 'C', 'D']
    const containsSomeOldModule = project?.modules.some((v: any) => oldModules.includes(v))
    const containsSomeNewLifePhase = project?.modules.some((v: any) => PF_KAP_LIFE_PHASES.includes(v))

    if (containsSomeOldModule) {
      const additionalModuleOptions = containsSomeNewLifePhase
        ? project?.modules.filter((m: any) => oldModules.includes(m))
        : PF_KAP_MODULES.filter((m: any) => !PF_KAP_LIFE_PHASES.includes(m))

      moduleOptions.push(
        ...additionalModuleOptions.map((module: MODULE_TYPES) => ({
          label: getMessage(`label.module.description.${module}`),
          value: module,
        })),
      )
    }

    const applicationTypesOptions = [
      { label: getMessage(project?.application_type?.key || ''), value: project?.application_type?.id || '' },
    ]

    return {
      projectUsersOptions,
      moduleOptions,
      applicationTypesOptions,
    } as const
  }, [project, getMessage])

  const handleSubmitLocal = async (values: EditFormValues, _: FormApi<EditFormValues>): Promise<any> => {
    const { modules, projectStartDate, description, projectEndDate, applicationTypeId, ...variables } = values

    const newMutationVars: EditDossierTypes = {
      projectStartDate: projectStartDate && DateUtils.parseAndSerializeDate(projectStartDate),
      projectEndDate: projectEndDate && DateUtils.parseAndSerializeDate(projectEndDate),
      description: description ? HtmlSanitizer.sanitize(description) : null,
      modules: Utils.sortModules(values.modules),
      ...variables,
    }

    let combinedMutation = combinedQuery('UpdateProjectDescriptionAndLeader') as unknown as CombinedQueryBuilder

    const { projectLeaderId } = newMutationVars
    if (projectLeaderId && projectLeaderId !== initialValues?.projectLeaderId) {
      combinedMutation = combinedMutation.add<
        {
          set_current_project_admin: Mutation_Root['update_project_user']
        },
        {
          newProjectLeaderId: number
          projectLeaderRole: string
          newProjectBaseId: number
        }
      >(CHANGE_PROJECT_LEADER_MUTATION, {
        newProjectLeaderId: projectLeaderId as number,
        projectLeaderRole: PROJECT_USER_ROLES.ADMIN,
        newProjectBaseId: project!.project_base.id,
      })

      // if a GFCH role created the project there won't be an initial project leader
      if (initialValues?.projectLeaderId) {
        combinedMutation = combinedMutation.add<
          {
            set_previous_project_admin_as_contributor: Mutation_Root['update_project_user']
          },
          {
            previousProjectLeaderId: number
            projectContributorRole: string
            prevProjectBaseId: number
          }
        >(SET_PREVIOUS_PROJECT_LEADER_AS_CONTRIBUTOR_MUTATION, {
          previousProjectLeaderId: initialValues!.projectLeaderId,
          projectContributorRole: PROJECT_USER_ROLES.CONTRIBUTOR,
          prevProjectBaseId: project!.project_base.id,
        })
      }
    }

    combinedMutation = combinedMutation.add<
      { update_project_base: Mutation_Root['update_project_base']; update_dossier: Mutation_Root['update_dossier'] },
      EditDossierTypes
    >(editDossier, newMutationVars)

    const { document, variables: vars } = combinedMutation

    const { error, data } = await urqlClient
      .mutation<{
        update_project_base: Mutation_Root['update_project_base']
        update_dossier: Mutation_Root['update_dossier']
        update_pf_kap_project: Mutation_Root['update_pf_kap_project']
      }>(document, vars)
      .toPromise()
    if (error || data?.update_dossier?.affected_rows !== 1) {
      notificationService.operationFailed()
      return Promise.reject(error)
    } else if (isMounted()) {
      setInitialValues(values)
      notificationService.changesSaved()
      refetchUserPermissions()
      return Promise.resolve()
    }
  }

  const navigate = useDelayedNavigate()

  const onBack = () => {
    navigate(ROUTES.PfKapDetailsRoot.nested.BasicInformation.params({ projectId }))
  }

  let submit: any = () => {}
  let formValid = false

  const onSave = (event: any) => {
    submit(event)
  }

  const onSaveAndBack = async (event: any) => {
    try {
      await submit(event)
      formValid && onBack()
    } catch {
      // do nothing
    }
  }

  const validateForm = (values: EditFormValues): FormValidationErrors<EditFormValues> => {
    const error: FormValidationErrors<EditFormValues> = {}

    const projectStartDate = values.projectStartDate && DateUtils.parseDate(values.projectStartDate)
    const projectEndDate = values.projectEndDate && DateUtils.parseDate(values.projectEndDate)

    if (
      projectStartDate &&
      projectEndDate &&
      DateUtils.valid(projectStartDate) &&
      DateUtils.valid(projectEndDate) &&
      DateUtils.after(projectStartDate, projectEndDate)
    ) {
      error.projectEndDate = { errorKey: 'validation.project.date.end.before.start' }
    }

    return error
  }

  if (!loading && !canEdit) {
    return <NotAuthorized />
  }

  const changeProjectLeaderIsDisabled = !Utils.canExternalUserChangeProjectAdmin(userGlobalRoles, userProjectRoles)

  return (
    <ScreenLayout
      title={getMessage('label.project.basic.information.edit.title')}
      onBack={onBack}
      hasSecondLevelNavigation={false}
      actions={
        <>
          <SaveAndBackButton origin="header" onClick={onSaveAndBack} />
          <SaveButton origin="header" onClick={onSave} />
        </>
      }
    >
      <PageLayout>
        <>
          {!loading && initialValues && (
            <>
              <HelpAndInstructions
                labelKey="label.help.basic.information.project.information.pf_kap"
                defaultExpansion
              />
              <Form<EditFormValues>
                initialValues={initialValues}
                onSubmit={handleSubmitLocal}
                validate={validateForm}
                decorators={decorators}
                render={({ handleSubmit, valid }) => {
                  submit = handleSubmit
                  formValid = valid
                  return (
                    <form onSubmit={handleSubmit} noValidate id="edit-basic-information-form">
                      <Stack spacing={2}>
                        <TextField
                          required
                          label={getMessage('label.title')}
                          name="title"
                          validate={composeValidators(required(), maxChar(TEXT_LENGTH.M))}
                        />
                        <TextField
                          required
                          label={getMessage('label.short.title')}
                          name="shortTitle"
                          validate={composeValidators(required(), maxChar(TEXT_LENGTH.S))}
                        />
                        <HtmlEditorField
                          label={getMessage('label.description')}
                          name="description"
                          validate={maxChar(TEXT_LENGTH.L)}
                        />
                        <AutoCompleteField
                          disabled={true}
                          label={getMessage('label.application_type')}
                          name="applicationTypeId"
                          options={options.applicationTypesOptions}
                        />
                        <MultiSelectField
                          options={options.moduleOptions}
                          name="modules"
                          label={getMessage('label.modules')}
                          validate={required()}
                        />
                        <DateTimePickerField
                          label={getMessage('label.project.start.date')}
                          name="projectStartDate"
                          validate={validDate()}
                        />
                        <DateTimePickerField
                          label={getMessage('label.project.end.date')}
                          name="projectEndDate"
                          validate={validDate()}
                        />
                        <AutoCompleteField
                          label={getMessage('label.project.leader')}
                          name="projectLeaderId"
                          options={options.projectUsersOptions}
                          disabled={changeProjectLeaderIsDisabled}
                        />
                        <DirtyFormSpy />
                      </Stack>
                    </form>
                  )
                }}
              />
            </>
          )}
        </>
      </PageLayout>
    </ScreenLayout>
  )
}
