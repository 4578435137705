import { useMemo } from 'react'
import { useParams } from 'react-router'
import { Feature_Base_Bool_Exp, Feature_Config_Bool_Exp, Mutation_Root, Query_Root } from 'src/@types/graphql'
import { ROUTES } from 'src/routing/routes'
import {
  fetchKapMeasureByIdWithFeaturesQuery,
  updateKapMeasureValidFieldMutation,
} from 'src/screens/kap/program/details/measures/kapMeasuresQueries'
import { FeatureBaseEdit } from 'src/screens/shared/feature/edit/FeatureBaseEdit'
import { PROJECT } from 'src/shared/constants/constants'
import { useDelayedNavigate } from 'src/shared/utils/hooks/navigation-hooks'
import { useNotificationService } from 'src/shared/utils/NotificationService'
import { useClient, useQuery } from 'urql'

export const KapMeasureFeaturesEditPage = () => {
  const { programId, measureId, featureTypeId } = useParams()
  const navigate = useDelayedNavigate()
  const urqlClient = useClient()
  const notificationService = useNotificationService()

  const [{ fetching, data }] = useQuery<{
    kap_measure_by_pk: Query_Root['kap_measure_by_pk']
  }>({
    query: fetchKapMeasureByIdWithFeaturesQuery,
    variables: { measureId: parseInt(measureId as string) },
  })

  const kapMeasure = data?.kap_measure_by_pk

  const measureFeatureBaseWhereClause: Feature_Base_Bool_Exp = useMemo(() => {
    return {
      kap_measures: { id: { _eq: parseInt(measureId as string) } },
    }
  }, [measureId])

  const measuresLevelProcessWhereClause: Feature_Config_Bool_Exp = useMemo(() => {
    return {
      _and: [{ levels: { _contains: kapMeasure?.level } }, { processes: { _contains: PROJECT.KAP } }],
    }
  }, [kapMeasure])

  const onBack = (featureGroupConfigCode: string) => {
    const route = ROUTES.KapMeasureDetailsRoot.nested.Features.params({ programId, measureId })
    navigate(`${route}#${featureGroupConfigCode}`)
  }

  // ABF: Updating the measure with the same data only to trigger the validation check function
  const measureUpdate = async () => {
    const { error } = await urqlClient
      .mutation<{
        update_kap_measure: Mutation_Root['update_kap_measure']
      }>(updateKapMeasureValidFieldMutation, { measureId: parseInt(measureId as string) })
      .toPromise()

    if (error) {
      notificationService.operationFailed()
    }
  }

  return (
    <>
      {!fetching && kapMeasure && (
        <FeatureBaseEdit
          featureBaseWhere={measureFeatureBaseWhereClause}
          featureConfigWhere={measuresLevelProcessWhereClause}
          onBack={onBack}
          featureTypeId={parseInt(featureTypeId as string)}
          onSaveCallback={measureUpdate}
          relatedEntity={kapMeasure}
        />
      )}
    </>
  )
}
