import { AccordionStyled } from 'src/shared/styled/accordion/AccordionStyled'
import { AvatarStyled } from 'src/shared/styled/avatar/AvatarStyled'
import { CardActionsStyled, CardContentStyled, CardStyled } from 'src/shared/styled/card/CardStyled'
import { FilterCardActionsStyled } from 'src/shared/styled/card/FilterCardActionsStyled'
import {
  IdChipStyled,
  ItemContainerStyled,
  ItemTitleStyled,
  MoveButtonsStyled,
  RelatedItemStyled,
} from 'src/shared/styled/card/GoalMeasureCardStyled'
import {
  JournalAndEvaluationMilestoneCardContentStyled,
  JournalAndEvaluationMilestoneCardItemContainerStyled,
  JournalAndEvaluationMilestoneCardItemDateStyled,
  JournalAndEvaluationMilestoneCardItemStatusChipStyled,
  JournalAndEvaluationMilestoneCardItemTitleStyled,
} from 'src/shared/styled/card/JournalAndEvaluationMilestoneCardStyled'
import { ClickableChipStyled } from 'src/shared/styled/chip/ClickableChipStyled'
import { ModuleChipStyled } from 'src/shared/styled/chip/ModuleChipStyled'
import { DataGridTotalRowStyled } from 'src/shared/styled/data-grid/DataGridTotalRowStyled'
import { DataGridViewEditModeStyled } from 'src/shared/styled/data-grid/DataGridViewEditModeStyled'
import { EditableColumnHeaderStyled } from 'src/shared/styled/data-grid/EditableColumnHeaderStyled'
import { GridToolbarContainerStyled } from 'src/shared/styled/data-grid/GridToolbarContainerStyled'
import { ReportGridContainer } from 'src/shared/styled/data-grid/ReportGridContainerStyled'
import { ScrollDataGridStyled } from 'src/shared/styled/data-grid/ScrollDataGridStyled'
import { TwoLineCellStyled } from 'src/shared/styled/data-grid/TwoLineCellStyled'
import { FormLabelStyled } from 'src/shared/styled/form/FormLabelStyled'
import { GridTooltipInfoIconStyled } from 'src/shared/styled/icons/GridTooltipInfoIconStyled'
import { ValidationIconStyled } from 'src/shared/styled/icons/ValidationIcon'
import { FilterCheckboxStyled } from 'src/shared/styled/input/FilterCheckboxStyled'
import { LinkStyled } from 'src/shared/styled/link/LinkStyled'
import {
  ProjectGoalRatingListItemTextStyled,
  ProjectMeasureRatingListItemTextStyled,
} from 'src/shared/styled/list-item/ProjectRatingListItemTextStyled'
import { SidebarHomeListItemStyled } from 'src/shared/styled/list-item/SidebarHomeListItemStyled'
import { ReadOnlyListStyled } from 'src/shared/styled/list/ReadOnlyListStyled'
import { SuccessFactorIndicatorsStyled } from 'src/shared/styled/list/SuccessFactorIndicatorsStyled'
import { TextOverflowSpanStyled } from 'src/shared/styled/span/TextOverflowSpanStyled'
import { ExportFormatTypographyStyled } from 'src/shared/styled/typography/ExportFormatTypographyStyled'

const CustomStyledComponents = {
  Card: {
    Container: CardStyled,
    Content: CardContentStyled,
    Actions: CardActionsStyled,
  },
  Accordion: AccordionStyled,
  Icons: {
    GridTooltipInfo: GridTooltipInfoIconStyled,
    Validation: ValidationIconStyled,
  },
  Span: {
    TextOverflow: TextOverflowSpanStyled,
  },
  ListItem: {
    SidebarHome: SidebarHomeListItemStyled,
  },
  List: {
    ReadOnly: ReadOnlyListStyled,
    SuccessFactorIndicators: SuccessFactorIndicatorsStyled,
  },
  Avatar: AvatarStyled,
  ModuleChip: ModuleChipStyled,
  DataGrid: {
    TwoLineCell: TwoLineCellStyled,
    EditableColumnHeader: EditableColumnHeaderStyled,
    ToolbarContainer: GridToolbarContainerStyled,
    TotalRow: DataGridTotalRowStyled,
    ViewEditMode: DataGridViewEditModeStyled,
    ReportGridContainer: ReportGridContainer,
    ScrollDataGridStyled: ScrollDataGridStyled,
  },
  GoalMeasureCard: {
    IdChip: IdChipStyled,
    MoveButtons: MoveButtonsStyled,
    ItemContainer: ItemContainerStyled,
    ItemTitle: ItemTitleStyled,
    RelatedItem: RelatedItemStyled,
  },
  JournalCard: {
    Content: JournalAndEvaluationMilestoneCardContentStyled,
    ItemContainer: JournalAndEvaluationMilestoneCardItemContainerStyled,
    ItemTitle: JournalAndEvaluationMilestoneCardItemTitleStyled,
    ItemDate: JournalAndEvaluationMilestoneCardItemDateStyled,
    ItemStatusChip: JournalAndEvaluationMilestoneCardItemStatusChipStyled,
  },
  EvaluationMilestoneCard: {
    Content: JournalAndEvaluationMilestoneCardContentStyled,
    ItemContainer: JournalAndEvaluationMilestoneCardItemContainerStyled,
    ItemTitle: JournalAndEvaluationMilestoneCardItemTitleStyled,
    ItemDate: JournalAndEvaluationMilestoneCardItemDateStyled,
    ItemStatusChip: JournalAndEvaluationMilestoneCardItemStatusChipStyled,
  },
  Link: LinkStyled,
  ListItemText: {
    GoalRating: ProjectGoalRatingListItemTextStyled,
    MeasureRating: ProjectMeasureRatingListItemTextStyled,
  },
  Form: {
    Label: FormLabelStyled,
  },
  Chip: {
    Clickable: ClickableChipStyled,
  },
  Input: {
    FilterCheckbox: FilterCheckboxStyled,
  },
  Typography: {
    ExportFormat: ExportFormatTypographyStyled,
  },
  FilterCard: {
    Actions: FilterCardActionsStyled,
  },
}

type CustomStyledComponentsType = typeof CustomStyledComponents

export const S: CustomStyledComponentsType = CustomStyledComponents
