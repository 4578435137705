import { ReactElement } from 'react'
import { Feature_Base_Bool_Exp, Feature_Config_Bool_Exp } from 'src/@types/graphql'
import { useMessageSource } from 'src/i18n/useMessageSource'
import { resolveDetailsRoot } from 'src/routing/routing-utils'
import { FeatureBase } from 'src/screens/shared/feature/FeatureBase'
import { ProjectFeatureKpiGrid } from 'src/screens/shared/project/details/project-features/ProjectFeatureKpiGrid'
import { usePermissionsForProject } from 'src/service/security/PermissionHook'
import { EditButton } from 'src/shared/button/Buttons'
import { PROJECT } from 'src/shared/constants/constants'
import { PageLayout } from 'src/shared/layout/PageLayout'
import { ScreenLayout } from 'src/shared/layout/ScreenLayout'
import { ProjectExportMenu } from 'src/shared/menu/ProjectExportMenu'
import { Section } from 'src/shared/presentation/Section'
import { useDelayedNavigate } from 'src/shared/utils/hooks/navigation-hooks'
import { Utils } from 'src/shared/utils/Utils'

interface ProjectFeaturesProps {
  projectId: number
  baseUrl: '/pf-kap' | '/pf-pgv'
}

export const ProjectFeatures = ({ projectId, baseUrl }: ProjectFeaturesProps): ReactElement => {
  const navigate = useDelayedNavigate()
  const process = Utils.resolveProcess(baseUrl)

  const { getMessage } = useMessageSource()
  const {
    loading,
    canEdit,
    metadata: { projectBaseId },
  } = usePermissionsForProject(process, projectId)

  const handleEditFeatureTypeSection = (featureTypeId: number) => {
    navigate(resolveDetailsRoot(baseUrl).nested.ProjectFeaturesEdit.params({ projectId, featureTypeId }))
  }

  const handleEditKPIs = () => {
    navigate(resolveDetailsRoot(baseUrl).nested.ProjectFeaturesKpiEdit.params({ projectId }))
  }

  const projectPfKapBaseWhereClause: Feature_Base_Bool_Exp = {
    project_base: {
      pf_kap_projects: {
        id: {
          _eq: projectId,
        },
      },
    },
  }

  const projectPfPgvBaseWhereClause: Feature_Base_Bool_Exp = {
    project_base: {
      pf_pgv_projects: {
        id: {
          _eq: projectId,
        },
      },
    },
  }

  const processWhereClause: Feature_Config_Bool_Exp = {
    processes: {
      _contains: [process],
    },
  }

  return (
    <>
      {!loading && (
        <ScreenLayout
          title={getMessage('label.navigation.project.details')}
          actions={<ProjectExportMenu process={process} entityId={projectBaseId as number} />}
        >
          <PageLayout>
            <>
              <FeatureBase
                featureBaseWhere={
                  process === PROJECT.PF_KAP ? projectPfKapBaseWhereClause : projectPfPgvBaseWhereClause
                }
                featureConfigWhere={processWhereClause}
                onEditFeature={handleEditFeatureTypeSection}
                canEdit={canEdit}
                kpiSection={
                  <Section
                    async
                    title={getMessage('label.kpis')}
                    actionButton={<EditButton onClick={handleEditKPIs} hidden={!canEdit} />}
                  >
                    <ProjectFeatureKpiGrid targetEditable={false} projectId={projectId} baseUrl={baseUrl} />
                  </Section>
                }
              />
            </>
          </PageLayout>
        </ScreenLayout>
      )}
    </>
  )
}
