import { Box } from '@mui/material'
import { useMessageSource } from 'src/i18n/useMessageSource'
import { FilterFieldWrapper } from 'src/screens/shared/common/filter-card/FilterFieldWrapper'
import { TEXT_LENGTH } from 'src/shared/constants/constants'
import { TextField } from 'src/shared/form/control/TextField'
import { maxChar } from 'src/shared/form/validation/validators'
import styled from 'styled-components/macro'

interface Props {
  name: string
  error: boolean
  placeholder: string
}

const BoxStyled = styled(Box)`
  width: 100%;

  input[type='search'] {
    height: 2.5rem;
    padding-bottom: ${({ theme }) => theme.spacing(0.5)};
  }

  // Styling of clear button for search type input
  input[type='search']::-webkit-search-cancel-button {
    -webkit-appearance: none;
    height: 1em;
    width: 1em;
    border-radius: 50em;
    background: linear-gradient(
        45deg,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0) 43%,
        #000 45%,
        #000 55%,
        rgba(0, 0, 0, 0) 57%,
        rgba(0, 0, 0, 0) 100%
      ),
      linear-gradient(135deg, transparent 0%, transparent 43%, #000 45%, #000 55%, transparent 57%, transparent 100%);
    background-size: contain;
    opacity: 0.38;
    pointer-events: none;

    &:hover {
      cursor: pointer;
    }
  }

  input[type='search']:focus::-webkit-search-cancel-button {
    opacity: 0.54;
    pointer-events: all;
  }
`

export const SearchFilter = ({ name, error, placeholder }: Props) => {
  const { getMessage } = useMessageSource()

  return (
    <FilterFieldWrapper label={getMessage('label.search')} error={error}>
      <BoxStyled>
        <TextField
          name={name}
          type="search"
          variant="standard"
          placeholder={placeholder}
          validate={maxChar(TEXT_LENGTH.M)}
        />
      </BoxStyled>
    </FilterFieldWrapper>
  )
}
