import { ReactElement } from 'react'
import { useParams } from 'react-router-dom'
import { resolveDetailsRoot } from 'src/routing/routing-utils'
import { BaseMeasureCreate } from 'src/screens/shared/measure/BaseMeasureCreate'
import { usePermissionsForProject } from 'src/service/security/PermissionHook'
import { MEASURE_CHAR } from 'src/shared/constants/constants'
import { useDelayedNavigate } from 'src/shared/utils/hooks/navigation-hooks'
import { Utils } from 'src/shared/utils/Utils'

interface ProjectMeasureCreatePageProps {
  baseUrl: '/pf-kap' | '/pf-pgv'
}

export const ProjectMeasureCreatePage = ({ baseUrl }: ProjectMeasureCreatePageProps): ReactElement => {
  const { projectId } = useParams()

  const navigate = useDelayedNavigate()
  const process = Utils.resolveProcessToLowerCase(baseUrl)

  const projectType = Utils.resolveProcess(baseUrl)
  const project_id = parseInt(projectId as string)

  const { canEdit, loading } = usePermissionsForProject(projectType, project_id)

  const onBack = (measureId?: number) => {
    const route = resolveDetailsRoot(baseUrl).nested.GoalsAndMeasures.params({ projectId })
    if (measureId) {
      navigate(`${route}#${MEASURE_CHAR}${measureId}`, { state: { expandedEntityId: `${MEASURE_CHAR}${measureId}` } })
    } else {
      navigate(route)
    }
  }

  return (
    <BaseMeasureCreate
      baseUrl={baseUrl}
      helpText={`label.help.goals.and.measures.measures.${process}`}
      canEdit={canEdit}
      loading={loading}
      onBack={onBack}
    />
  )
}
