import { IconButton } from '@mui/material'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { sortBy } from 'lodash'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { Kap_Goal_Measure, Kap_Measure, Mutation_Root } from 'src/@types/graphql'
import { useMessageSource } from 'src/i18n/useMessageSource'
import { ROUTES } from 'src/routing/routes'
import {
  deleteKapMeasureByMeasureId,
  updateKapMeasureSortNumbers,
} from 'src/screens/kap/program/details/measures/kapMeasuresQueries'
import { GoalMeasureCard } from 'src/screens/shared/common/GoalMeasureCard'
import { areGoalsMeasuresSortedLatest } from 'src/screens/shared/common/utils/GoalMeasureUtils'
import { DeleteIcon, MoveDownIcon, MoveUpIcon } from 'src/shared/icons/Icons'
import { ConfirmationModalDialog } from 'src/shared/modal-dialog/ConfirmationModalDialog'
import { DefaultSectionTypography } from 'src/shared/presentation/DefaultSectionTypography'
import { S } from 'src/shared/styled/S'
import { useDelayedNavigate } from 'src/shared/utils/hooks/navigation-hooks'
import { useNotificationService } from 'src/shared/utils/NotificationService'
import { useClient } from 'urql'

interface Props {
  idCharacter: string
  title: string
  measures: Kap_Measure[]
  refetch: () => void
  canEditMeasures: boolean
}

export const KapMeasureGroupSection = ({ idCharacter, title, measures, refetch, canEditMeasures }: Props) => {
  const { programId } = useParams()
  const { getMessage } = useMessageSource()
  const navigate = useDelayedNavigate()
  const urqlClient = useClient()
  const notificationService = useNotificationService()

  const [kapMeasureDeleteConfirmationOpen, setKapMeasureDeleteConfirmationOpen] = useState(false)
  const [kapMeasureIdToBeDeleted, setKapMeasureIdToBeDeleted] = useState<number | null>(null)
  const [goalsLinked, setGoalsLinked] = useState<boolean>(false)

  const [beingSwappedIds, setBeingSwappedIds] = useState<{ id1: number; id2: number }>()

  useEffect(() => {
    if (beingSwappedIds) {
      const areSortedLatest = areGoalsMeasuresSortedLatest(measures, beingSwappedIds.id1, beingSwappedIds.id2)

      if (areSortedLatest) {
        setBeingSwappedIds(undefined)
      }
    }
  }, [beingSwappedIds, measures])

  const moveMeasureUp = async (event: any, measureId: number) => {
    event.stopPropagation()
    const measure1Index = measures.findIndex((measure: Kap_Measure) => measure.id === measureId)
    if (measure1Index > 0) {
      const measure1 = measures[measure1Index]
      const measure2 = measures[measure1Index - 1]
      if (measure1 && measure2) {
        setBeingSwappedIds({ id1: measure1.id, id2: measure2.id })
        moveMeasure(measure1, measure2)
      }
    }
  }

  const moveMeasureDown = (event: any, measureId: number) => {
    event.stopPropagation()
    const measure1Index = measures.findIndex((measure: Kap_Measure) => measure.id === measureId)
    if (measure1Index < measures.length - 1) {
      const measure1 = measures[measure1Index]
      const measure2 = measures[measure1Index + 1]
      if (measure1 && measure2) {
        setBeingSwappedIds({ id1: measure2.id, id2: measure1.id })
        moveMeasure(measure1, measure2)
      }
    }
  }

  const moveMeasure = async (measure1: Kap_Measure, measure2: Kap_Measure) => {
    const { error } = await urqlClient
      .mutation<{ update_kap_measure: Mutation_Root['update_kap_measure'] }>(updateKapMeasureSortNumbers, {
        measure1Id: measure1.id,
        measure2Id: measure2.id,
        measure1SortNumber: measure2.sort_number,
        measure2SortNumber: measure1.sort_number,
      })
      .toPromise()
    if (error) {
      notificationService.operationFailed()
    } else {
      notificationService.changesSaved()
      refetch()
    }
  }

  const handleMeasureCardClick = (measureId: number) => {
    navigate(ROUTES.KapMeasureDetailsRoot.params({ programId, measureId }))
  }

  const handleDeleteMeasure = (measureId: number, linkedGoals: Kap_Goal_Measure[]) => {
    setKapMeasureIdToBeDeleted(measureId)
    setGoalsLinked(linkedGoals.length > 0)
    setKapMeasureDeleteConfirmationOpen(true)
  }

  const handleCancelDeleteProjectMeasure = () => {
    setKapMeasureDeleteConfirmationOpen(false)
  }

  const handleDeleteProjectMeasureConfirmed = async () => {
    setKapMeasureDeleteConfirmationOpen(false)
    const { error } = await urqlClient
      .mutation<{
        delete_kap_measure_by_pk: Mutation_Root['delete_kap_measure_by_pk']
      }>(deleteKapMeasureByMeasureId, {
        measureId: kapMeasureIdToBeDeleted,
      })
      .toPromise()
    if (error) {
      notificationService.operationFailed()
    } else {
      refetch()
      notificationService.deleteSuccessful()
      setGoalsLinked(false)
    }
  }

  return (
    <>
      <Box>
        <Typography variant="h3" color="textSecondary" mb={1.5}>
          {title}
        </Typography>
        {measures && measures.length > 0 ? (
          measures.map((measure: Kap_Measure, index: number) => {
            const measureHasPlannings =
              measure.kap_measure_annual_plannings.length || measure.kap_measure_kpi_annual_plannings.length
            const measureHasReports =
              measure.kap_measure_annual_reports.length || measure.kap_measure_kpi_annual_reports.length
            const canDeleteMeasure = !measureHasPlannings && !measureHasReports

            return (
              <GoalMeasureCard id={`${measure.id}`} key={measure.id}>
                <S.Card.Content
                  onClick={() => handleMeasureCardClick(measure.id)}
                  tabIndex={0}
                  onKeyDown={(event: { key: string }) => {
                    if (['Enter', ' '].includes(event.key)) {
                      handleMeasureCardClick(measure.id)
                    }
                  }}
                >
                  <S.Icons.Validation $valid={measure.valid} />
                  <S.GoalMeasureCard.IdChip size="small" label={`${idCharacter}${measure.sort_number}`} />
                  <S.GoalMeasureCard.MoveButtons>
                    <IconButton
                      color="inherit"
                      disabled={index === 0 || !canEditMeasures || !!beingSwappedIds}
                      onClick={(event) => moveMeasureUp(event, measure.id)}
                      size="large"
                    >
                      <MoveUpIcon />
                    </IconButton>
                    <IconButton
                      color="inherit"
                      disabled={index === measures.length - 1 || !canEditMeasures || !!beingSwappedIds}
                      onClick={(event) => moveMeasureDown(event, measure.id)}
                      size="large"
                    >
                      <MoveDownIcon />
                    </IconButton>
                  </S.GoalMeasureCard.MoveButtons>
                  <S.GoalMeasureCard.ItemContainer>
                    <S.GoalMeasureCard.ItemTitle variant="subtitle2">{measure.short_title}</S.GoalMeasureCard.ItemTitle>
                    {measure.kap_goal_measures.length > 0 ? (
                      <S.GoalMeasureCard.RelatedItem variant="body2">
                        {sortBy(measure.kap_goal_measures, ['kap_goal.sort_number'])
                          .map(
                            (x: Kap_Goal_Measure) =>
                              `${getMessage('label.goal.id.character')}${x.kap_goal.sort_number}`,
                          )
                          .join(', ')}
                      </S.GoalMeasureCard.RelatedItem>
                    ) : (
                      <DefaultSectionTypography
                        noEntriesMessageKey={getMessage('label.not.available.goals')}
                        $standAlone={true}
                      />
                    )}
                  </S.GoalMeasureCard.ItemContainer>
                </S.Card.Content>
                <S.Card.Actions $numberOfButtonsToAlign={1}>
                  {canEditMeasures && canDeleteMeasure && (
                    <>
                      <IconButton
                        color="primary"
                        onClick={() => handleDeleteMeasure(measure.id, measure.kap_goal_measures)}
                        size="large"
                      >
                        <DeleteIcon />
                      </IconButton>
                    </>
                  )}
                </S.Card.Actions>
              </GoalMeasureCard>
            )
          })
        ) : (
          <S.Card.Container $nonClickable={true}>
            <S.Card.Content>
              <DefaultSectionTypography type="TEXT_FIELD" $standAlone={true} />
            </S.Card.Content>
          </S.Card.Container>
        )}
      </Box>
      <ConfirmationModalDialog
        open={kapMeasureDeleteConfirmationOpen}
        onCancel={handleCancelDeleteProjectMeasure}
        onConfirm={handleDeleteProjectMeasureConfirmed}
      >
        {goalsLinked ? getMessage('label.delete.confirm.measures.found') : getMessage('label.delete.confirm')}
      </ConfirmationModalDialog>
    </>
  )
}
