import { Card, CardContent, Typography } from '@mui/material'
import { ReactElement, useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { Kap_Program, Maybe, Query_Root } from 'src/@types/graphql'
import { useMessageSource } from 'src/i18n/useMessageSource'
import { ROUTES } from 'src/routing/routes'
import { fetchKapProgramByIdQuery } from 'src/screens/kap/program/details/kapProgramQueries'
import { usePermissionsForKapProgram } from 'src/service/security/PermissionHook'
import { EditButton } from 'src/shared/button/Buttons'
import { ENTITY_TYPE, FILE_TYPE, MODULE_TYPES } from 'src/shared/constants/constants'
import { PageLayout } from 'src/shared/layout/PageLayout'
import { ScreenLayout } from 'src/shared/layout/ScreenLayout'
import { ProgramExportMenu } from 'src/shared/menu/ProgramExportMenu'
import { FileSection } from 'src/shared/presentation/FileSection'
import { HelpAndInstructions } from 'src/shared/presentation/HelpAndInstructions'
import { ReadOnlyTextField } from 'src/shared/presentation/ReadOnly'
import { Section } from 'src/shared/presentation/Section'
import { DateUtils } from 'src/shared/utils/DateUtils'
import { useDelayedNavigate } from 'src/shared/utils/hooks/navigation-hooks'
import { useNotificationService } from 'src/shared/utils/NotificationService'
import { useClient } from 'urql'
import { Utils } from 'src/shared/utils/Utils'

export const KapBasicInformationPage = (): ReactElement => {
  const { programId } = useParams()
  const { getMessage } = useMessageSource()
  const urqlClient = useClient()
  const notificationService = useNotificationService()
  const navigate = useDelayedNavigate()

  const program_id = parseInt(programId as string)

  const [kapProgram, setKapProgram] = useState<Maybe<Kap_Program>>()
  const { canEdit, canViewCantonRestricted, loading } = usePermissionsForKapProgram(program_id)

  useEffect(() => {
    const fetchData = async () => {
      const { data } = await urqlClient
        .query<{ kap_program_by_pk: Query_Root['kap_program_by_pk'] }>(fetchKapProgramByIdQuery, { id: program_id })
        .toPromise()

      if (data?.kap_program_by_pk) {
        setKapProgram(data.kap_program_by_pk)
      } else {
        notificationService.operationFailed()
      }
    }

    fetchData()
  }, [program_id, notificationService, urqlClient])

  const handleEdit = () => {
    navigate(ROUTES.KapDetailsRoot.nested.BasicInformationEdit.params({ programId }))
  }

  return (
    <ScreenLayout
      title={getMessage('label.program.description')}
      actions={<>{canViewCantonRestricted && <ProgramExportMenu />}</>}
    >
      <PageLayout>
        <>
          {!loading && kapProgram && (
            <>
              <Section
                id="program-information"
                title={getMessage('label.kap.program.information')}
                actionButton={<EditButton onClick={handleEdit} hidden={!canEdit} />}
                helpAndInstructions={<HelpAndInstructions labelKey="label.help.kap.program.basic.information" />}
              >
                <Card>
                  <CardContent>
                    <ReadOnlyTextField text={getMessage('label.program.id')}>
                      {`${getMessage('label.program.id.character')}${kapProgram.id}`}
                    </ReadOnlyTextField>
                    <ReadOnlyTextField text={getMessage('label.title')}>{kapProgram.dossier.title}</ReadOnlyTextField>
                    <ReadOnlyTextField text={getMessage('label.short.title')}>
                      {kapProgram.dossier.short_title}
                    </ReadOnlyTextField>
                    <ReadOnlyTextField text={getMessage('label.modules')}>
                      {Utils.sortModules(kapProgram.modules).map((module: MODULE_TYPES) => (
                        <Typography key={module}>{getMessage(`label.module.description.${module}`)}</Typography>
                      ))}
                    </ReadOnlyTextField>
                    <ReadOnlyTextField text={getMessage('label.canton')}>
                      {getMessage(`label.canton.${kapProgram.canton_code}`)} {`(${kapProgram.canton_code})`}
                    </ReadOnlyTextField>
                    <ReadOnlyTextField text={getMessage('label.kap.program.start.date')}>
                      {kapProgram.dossier.start_date && DateUtils.parseAndFormatDate(kapProgram.dossier.start_date)}
                    </ReadOnlyTextField>
                    <ReadOnlyTextField text={getMessage('label.kap.program.end.date')} isLast>
                      {kapProgram.dossier.end_date && DateUtils.parseAndFormatDate(kapProgram.dossier.end_date)}
                    </ReadOnlyTextField>
                  </CardContent>
                </Card>
              </Section>
              <FileSection
                optional
                id="documents"
                title={getMessage('label.kap.program.document')}
                fileType={FILE_TYPE.PROGRAM_DOCUMENT}
                entityType={ENTITY_TYPE.KAP_PROGRAM}
                entityId={program_id}
                canEdit={canEdit}
                helpAndInstructions={<HelpAndInstructions labelKey="label.help.kap.basic.information.documents" />}
              />
            </>
          )}
        </>
      </PageLayout>
    </ScreenLayout>
  )
}
