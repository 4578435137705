import { Location } from 'history'
import { useEffect, useState } from 'react'
import { Outlet, useLocation, useParams } from 'react-router'
import { ROUTES } from 'src/routing/routes'
import { NestedRoute, Route, SecondLevelRoute } from 'src/routing/routing'
import { SecondLevelNavigation } from 'src/routing/SecondLevelNavigation'
import { usePermissionsForKapProgram } from 'src/service/security/PermissionHook'
import { PageLayout } from 'src/shared/layout/PageLayout'
import { SecondLevelNavigationContainer } from 'src/shared/layout/SecondLevelNavigationContainer'
import { NotAuthorized } from 'src/shared/not-authorized/NotAuthorized'

const resolveActiveRoute = (location: Location, programId: string): SecondLevelRoute => {
  const nested = ROUTES.KapImplementationDetailsRoot!.nested! as unknown as NestedRoute
  const activeRoute = Object.keys(nested).find(
    (key) =>
      location.pathname.endsWith(nested[key].path) ||
      (ROUTES.KapImplementationDetailsRoot.params({ programId }) === location.pathname && nested[key].primary),
  ) as string
  return nested[activeRoute] as SecondLevelRoute
}

export const KapImplementationDetailsIndexPage = () => {
  const { programId } = useParams()
  const program_id = parseInt(programId as string)
  const location = useLocation()

  const { loading, canViewCantonRestricted } = usePermissionsForKapProgram(program_id)

  const [activeRoute, setActiveRoute] = useState<SecondLevelRoute>(() => {
    return resolveActiveRoute(location, programId as string)
  })

  useEffect(() => {
    const activeRoute = resolveActiveRoute(location, programId as string)
    setActiveRoute(activeRoute)
  }, [location, programId])

  const showNavigation = activeRoute && !activeRoute.hideNavigation

  if (!loading && !canViewCantonRestricted) {
    return <NotAuthorized />
  }

  return (
    <>
      {!loading && (
        <SecondLevelNavigationContainer $showNavigation={showNavigation}>
          {showNavigation && (
            <PageLayout>
              <SecondLevelNavigation
                activeRoute={activeRoute}
                route={ROUTES.KapImplementationDetailsRoot as unknown as Route}
                baseUrl={ROUTES.KapImplementationDetailsRoot.params({ programId })}
              />
            </PageLayout>
          )}
          <Outlet />
        </SecondLevelNavigationContainer>
      )}
    </>
  )
}
