import { gql } from '@urql/core'

export const queryDossierAndMilestoneForKapImplementationSummary = gql`
  query queryDossierAndMilestoneForKapImplementationSummary($programId: Int!) {
    dossier(where: { kap_program: { id: { _eq: $programId } } }) {
      status
      project_workflow_id
      kap_program {
        implementation_comment
      }
      milestones(order_by: [{ due_date: asc }, { year_in_focus: asc }]) {
        id
        status
        type
        year_in_focus
      }
    }
  }
`

export const fetchKapProgramImplementationCommentQuery = gql`
  query fetchKapProgramImplementationComment($programId: Int!) {
    kap_program_by_pk(id: $programId) {
      implementation_comment
    }
  }
`

export const updateKapProgramImplementationCommentMutation = gql`
  mutation updateKapProgramApplicationComment($programId: Int!, $implementationComment: String) {
    update_kap_program(where: { id: { _eq: $programId } }, _set: { implementation_comment: $implementationComment }) {
      affected_rows
    }
  }
`
