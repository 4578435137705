import { Avatar } from '@mui/material'
import styled from 'styled-components/macro'

// implementation for deterministically generating colors
// based on a `string` is taken from the official mui demo page
// https://mui.com/components/avatars/#letter-avatars
const stringToColor = (string: string) => {
  let hash = 0
  let i

  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash)
  }

  let color = '#'

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff
    color += `00${value.toString(16)}`.substr(-2)
  }

  return color
}

export const AvatarStyled = styled(Avatar)<{ $randomColorKey?: string }>`
  background-color: ${({ $randomColorKey }) => ($randomColorKey ? stringToColor($randomColorKey) : '')};
  width: ${({ theme }) => theme.spacing(3)};
  height: ${({ theme }) => theme.spacing(3)};
  margin-right: ${({ theme }) => theme.spacing(1.5)};
  font-size: ${({ theme }) => theme.typography.body2.fontSize};
`
