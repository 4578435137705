// Break labels with more than 2 words in two lines to optimize space in chart
// Pass to the dataset as an array of strings
const breakLabel = (label: string) => {
  const wordCount = label.split(' ').length

  if (wordCount > 2 && wordCount <= 4) {
    const splitLabel = label.split(' ')
    const firstLine = splitLabel.slice(0, 2).join(' ')
    const secondLine = splitLabel.slice(2).join(' ')

    return [firstLine, secondLine]
  } else if (wordCount > 4) {
    const splitLabel = label.split(' ')
    const firstLine = splitLabel.slice(0, 3).join(' ')
    const secondLine = splitLabel.slice(3).join(' ')

    return [firstLine, secondLine]
  } else {
    return label
  }
}

const adaptStringsForChartData = (data: (string | undefined)[]): number[] => {
  return data.map((string) => (string ? parseFloat(string) : 0))
}

export const ChartUtils = {
  breakLabel,
  adaptStringsForChartData,
}
