import { ListItemText } from '@mui/material'
import { useRef, useState } from 'react'
import { useParams } from 'react-router'
import { useEnvironment } from 'src/env/EnvironmentStore'
import { useMessageSource } from 'src/i18n/useMessageSource'
import { Axios } from 'src/service/axios/axios'
import { EXPORT_FORMAT } from 'src/shared/constants/constants'
import { ExportMenu } from 'src/shared/menu/ExportMenu'
import { S } from 'src/shared/styled/S'
import { useNotificationService } from 'src/shared/utils/NotificationService'
import { useUserLocale } from 'src/user/UserContext'
import { LoadingMenuItem } from 'src/shared/menu/item/LoadingMenuItem'
import { saveFileAs } from 'src/shared/utils/BlobUtils'

interface Props {
  chartBase64Image?: string
}

export const AnalysisExportMenu = ({ chartBase64Image }: Props) => {
  const { getMessage } = useMessageSource()
  const { backendUrl } = useEnvironment()
  const { analysisId } = useParams()
  const language = useUserLocale()
  const notificationService = useNotificationService()

  const exportMenuRef = useRef<{ closeMenu: () => void }>({
    closeMenu: () => {},
  })

  const [loadingExportAnalysis, setLoadingExportAnalysis] = useState(false)

  const handleAnalysisExport = () => {
    setLoadingExportAnalysis(true)
    Axios.getAxios()
      .post(`${backendUrl}/api/success-factors/analysis/${analysisId}/export`, chartBase64Image, {
        headers: {
          'content-type': 'text/plain',
        },
        params: {
          language,
        },
        responseType: 'blob',
      })
      .then((response) => {
        saveFileAs(response)
      })
      .catch(() => {
        notificationService.operationFailed()
      })
      .finally(() => {
        setLoadingExportAnalysis(false)
        exportMenuRef.current.closeMenu()
      })
  }

  return (
    <ExportMenu ref={exportMenuRef}>
      <LoadingMenuItem loading={loadingExportAnalysis} disabled={loadingExportAnalysis} onClick={handleAnalysisExport}>
        <ListItemText>{getMessage('label.export.success.factor.analysis')}</ListItemText>
        <S.Typography.ExportFormat>({EXPORT_FORMAT.WORD})</S.Typography.ExportFormat>
      </LoadingMenuItem>
    </ExportMenu>
  )
}
