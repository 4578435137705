import { Kap_Goal, Kap_Measure, Project_Goal, Project_Measure } from 'src/@types/graphql'

export const getProjectGoalsViolations = (goals: Array<Project_Goal> | undefined, milestoneId: number) => {
  return (
    goals &&
    goals.some((goal) => {
      const goalReports = goal.project_goal_annual_reports?.filter((report) => report.milestone_id === milestoneId)
      const goalRatings = goalReports?.[0]?.rating ?? null
      return goalRatings === null
    })
  )
}

export const getKapGoalsViolations = (goals?: Array<Kap_Goal> | undefined) => {
  return (
    goals &&
    goals.some((goal) => {
      const goalReports = goal.kap_goal_annual_reports
      const goalRatings = goalReports?.[0]?.rating
      const goalReasons = goalReports?.[0]?.reason
      return !goalRatings || !goalReasons
    })
  )
}

export const getProjectMeasuresViolations = (measures: Array<Project_Measure> | undefined, milestoneId: number) => {
  return (
    measures &&
    measures.some((measure) => {
      const measureReports = measure.project_measure_annual_reports?.filter(
        (report) => report.milestone_id === milestoneId,
      )
      const measureRatings = measureReports?.[0]?.rating ?? null
      return measureRatings === null
    })
  )
}

export const getKapMeasuresViolations = (measures?: Array<Kap_Measure> | undefined) => {
  return (
    measures &&
    measures.some((measure) => {
      const measureReports = measure.kap_measure_annual_reports
      return !measureReports?.[0]?.valid
    })
  )
}
