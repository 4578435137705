import { useParams } from 'react-router'
import { ROUTES } from 'src/routing/routes'
import { ProjectJournalEdit } from 'src/screens/shared/project/journal/edit/ProjectJournalEdit'
import { PROJECT } from 'src/shared/constants/constants'
import { useDelayedNavigate } from 'src/shared/utils/hooks/navigation-hooks'

export const PfPgvProjectJournalEditPage = () => {
  const { projectId } = useParams()

  const navigate = useDelayedNavigate()

  const onBack = (journalId: number) => {
    const route = ROUTES.PfPgvProjectJournal.params({ projectId })
    navigate(`${route}#${journalId}`, { state: { expandedJournalId: journalId } })
  }

  return <ProjectJournalEdit projectType={PROJECT.PF_PGV} onBack={onBack} />
}
