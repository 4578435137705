import { gql } from 'urql'

export const fetchPfKapProjectsGrid = gql`
  query ($pfKapCondition: pf_kap_project_bool_exp, $offset: Int!, $limit: Int!, $orderBy: [pf_kap_project_order_by!]) {
    pf_kap_project(where: $pfKapCondition, offset: $offset, limit: $limit, order_by: $orderBy) {
      id
      modules
      application_type {
        key
        code
      }
      project_base {
        gfch_responsible {
          first_name
          last_name
        }
        dossier {
          id
          type
          short_title
          status
          description
          start_date
          end_date
          funding_round {
            id
            name
          }
        }
        user {
          first_name
          last_name
        }
      }
    }
    pf_kap_project_aggregate(where: $pfKapCondition, order_by: $orderBy) {
      aggregate {
        count
      }
    }
  }
`

export const fetchPfPgvProjectsGrid = gql`
  query ($pfPgvCondition: pf_pgv_project_bool_exp, $offset: Int!, $limit: Int!, $orderBy: [pf_pgv_project_order_by!]) {
    pf_pgv_project(where: $pfPgvCondition, offset: $offset, limit: $limit, order_by: $orderBy) {
      id
      application_type {
        key
        code
      }
      project_base {
        gfch_responsible {
          first_name
          last_name
        }
        dossier {
          id
          type
          short_title
          status
          description
          start_date
          end_date
          funding_round {
            id
            name
          }
        }
        user {
          first_name
          last_name
        }
      }
    }
    pf_pgv_project_aggregate(where: $pfPgvCondition, order_by: $orderBy) {
      aggregate {
        count
      }
    }
  }
`

export const fetchGfchResponsibleUsersForProcessQuery = gql`
  query fetchGfchResponsibleUsersForProcess($dossierType: String!) {
    project_base(
      where: { _and: { dossier: { type: { _eq: $dossierType } }, gfch_responsible: { id: { _is_null: false } } } }
      distinct_on: gfch_responsible_id
    ) {
      gfch_responsible_id
      gfch_responsible {
        first_name
        last_name
      }
    }
  }
`
