import { Chart, Filler, Legend, LineElement, PointElement, RadarController, RadialLinearScale, Tooltip } from 'chart.js'
import { ReactElement } from 'react'
import { Chart as ReactChartWrapper, ChartProps } from 'react-chartjs-2'
import { FONT_FAMILY } from 'src/shared/constants/styling-constants'
import { muiTheme } from 'src/theme/theme'

export const BaseChart = ({ ...props }: ChartProps): ReactElement => {
  // Register only the chart.js controllers, elements, scales, and plugins that are used to decrease the bundle size
  Chart.register(RadialLinearScale, PointElement, LineElement, Filler, Legend, Tooltip, RadarController)

  // Global settings and styling in line with the theme

  const FONT_SIZE_NORMAL = 14 // in px - matches the font-size of body2 and subtitle2
  const FONT_SIZE_SMALL = 12 // in px - matches the font-size of caption

  // Fonts
  Chart.defaults.font.family = FONT_FAMILY
  Chart.defaults.font.size = FONT_SIZE_NORMAL

  // Colors
  Chart.defaults.borderColor = muiTheme.palette.divider
  Chart.defaults.color = muiTheme.palette.text.secondary

  // Points
  Chart.defaults.elements.point.radius = 4
  Chart.defaults.elements.point.hoverRadius = 5
  Chart.defaults.elements.point.hoverBackgroundColor = muiTheme.palette.common.white

  // Legend
  Chart.defaults.plugins.legend.align = 'start'
  Chart.defaults.plugins.legend.position = 'bottom'
  Chart.defaults.plugins.legend.labels.boxWidth = FONT_SIZE_NORMAL
  Chart.defaults.plugins.legend.labels.font = { weight: '500' }
  Chart.defaults.plugins.legend.labels.color = muiTheme.palette.text.primary

  // Tooltips
  Chart.defaults.plugins.tooltip.backgroundColor = muiTheme.palette.text.primary
  Chart.defaults.plugins.tooltip.titleColor = muiTheme.palette.common.white
  Chart.defaults.plugins.tooltip.titleFont = { weight: '500' }
  Chart.defaults.plugins.tooltip.bodyColor = muiTheme.palette.common.white
  Chart.defaults.plugins.tooltip.bodyFont = { size: FONT_SIZE_SMALL }
  Chart.defaults.plugins.tooltip.boxWidth = FONT_SIZE_SMALL
  Chart.defaults.plugins.tooltip.boxHeight = FONT_SIZE_SMALL
  Chart.defaults.plugins.tooltip.boxPadding = 4

  return <ReactChartWrapper {...props} />
}
