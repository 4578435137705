import { LoadingButton } from '@mui/lab'
import { DialogActions, DialogContent, Stack } from '@mui/material'
import { Box } from '@mui/system'
import { ReactElement, useMemo, useState } from 'react'
import { Form } from 'react-final-form'
import { OnChange } from 'react-final-form-listeners'
import { Mutation_Root, Success_Factor_Analysis_Insert_Input } from 'src/@types/graphql'
import { useMessageSource } from 'src/i18n/useMessageSource'
import { SecondaryButton } from 'src/shared/button/Buttons'
import { TEXT_LENGTH } from 'src/shared/constants/constants'
import { LIFE_PHASES, LIFE_PHASE_TYPE, TOPICS, TOPIC_TYPE } from 'src/shared/constants/success-factor-constants'
import { Option } from 'src/shared/form/control'
import { AutoCompleteField } from 'src/shared/form/control/AutoCompleteField'
import { MultiSelectField } from 'src/shared/form/control/MultiSelectField'
import { TextField } from 'src/shared/form/control/TextField'
import { composeValidators, maxChar, required } from 'src/shared/form/validation/validators'
import { DateUtils } from 'src/shared/utils/DateUtils'
import { useNotificationService } from 'src/shared/utils/NotificationService'
import { useUser } from 'src/user/UserContext'
import { gql, useClient } from 'urql'

interface Props {
  onCancel: () => void
  onSuccess: (id: number) => void
}

interface FormValues {
  title: string
  lifePhase: LIFE_PHASE_TYPE
  topics: TOPIC_TYPE[]
}

const insertAnalysisMutation = gql`
  mutation insertAnalysis($analysis: [success_factor_analysis_insert_input!]!) {
    insert_success_factor_analysis(objects: $analysis) {
      affected_rows
      returning {
        id
      }
    }
  }
`

export const CreateAnalysisModal = ({ onCancel, onSuccess }: Props): ReactElement => {
  const { getMessage } = useMessageSource()
  const urqlClient = useClient()
  const notificationService = useNotificationService()
  const { user } = useUser()

  const [beingSaved, setBeingSaved] = useState(false)

  const [initialValues, setInitialValues] = useState<FormValues>({
    title: '',
    lifePhase: LIFE_PHASES.GENERAL,
    topics: [],
  })
  const [topicOptions, setTopicOptions] = useState<Option[]>(
    Object.keys(TOPICS).map((topic) => ({
      label: getMessage(`label.success.factor.topic.${topic}`),
      value: topic,
    })),
  )

  const toggleTopicOptions = (existingValues: string[], enable: boolean) => {
    setTopicOptions(
      topicOptions.map((topic) => ({
        ...topic,
        disabled: enable ? false : !existingValues.includes(topic.value as string),
      })),
    )
  }

  const lifePhaseOptions: Option[] = useMemo(() => {
    const options = Object.keys(LIFE_PHASES).map((lifePhase) => ({
      label: getMessage(`label.success.factor.life.phase.${lifePhase}`),
      value: lifePhase,
    }))

    options.find((lifePhase) => lifePhase.value === LIFE_PHASES.GENERAL)!.label = getMessage(
      'label.success.factor.no.specific.life.phase',
    )

    return options
  }, [getMessage])

  const onSubmitLocal = async (values: FormValues) => {
    setBeingSaved(true)

    const analysis: Success_Factor_Analysis_Insert_Input = {
      title: values.title,
      life_phase_indicator: values.lifePhase,
      topic_indicator: values.topics,
      creation_date: DateUtils.serializeGraphQLDate(new Date()),
      canton_code: user?.cantonalUserCanton,
    }

    const { data } = await urqlClient
      .mutation<
        { insert_success_factor_analysis: Mutation_Root['insert_success_factor_analysis'] },
        { analysis: Success_Factor_Analysis_Insert_Input[] }
      >(insertAnalysisMutation, { analysis: [analysis] })
      .toPromise()

    if (data && data?.insert_success_factor_analysis && data?.insert_success_factor_analysis?.affected_rows > 0) {
      setInitialValues(values)
      const analysisId = data?.insert_success_factor_analysis?.returning?.[0]?.id
      onSuccess(analysisId)
    } else {
      notificationService.operationFailed()
      setBeingSaved(false)
    }
  }

  return (
    <Box>
      <Form<FormValues>
        onSubmit={onSubmitLocal}
        initialValues={initialValues}
        render={({ handleSubmit }) => {
          return (
            <form onSubmit={handleSubmit} noValidate>
              <DialogContent>
                <Stack spacing={2}>
                  <TextField
                    name="title"
                    label={getMessage('label.title')}
                    required
                    validate={composeValidators(required(), maxChar(TEXT_LENGTH.M))}
                  />
                  <AutoCompleteField
                    name="lifePhase"
                    label={getMessage('label.success.factor.life.phase')}
                    options={lifePhaseOptions}
                    required
                    validate={required()}
                  />
                  <MultiSelectField
                    options={topicOptions}
                    name="topics"
                    label={getMessage('label.success.factor.topic')}
                  />
                  <OnChange name="topics">
                    {(value) => {
                      if (value.length > 1) {
                        // Disable all other options that are not selected
                        toggleTopicOptions(value, false)
                      } else {
                        // Enable all options
                        toggleTopicOptions(value, true)
                      }
                    }}
                  </OnChange>
                </Stack>
              </DialogContent>
              <DialogActions>
                <SecondaryButton messageKey={'button.cancel'} onClick={onCancel} disabled={beingSaved} />
                <LoadingButton type="submit" loading={beingSaved} color="primary" variant="contained">
                  {getMessage('button.create')}
                </LoadingButton>
              </DialogActions>
            </form>
          )
        }}
      />
    </Box>
  )
}
