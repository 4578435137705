import { EditIcon } from 'src/shared/icons/Icons'
import { S } from 'src/shared/styled/S'

export const EditableColumnHeader = ({ headerName, editMode }: { headerName: string; editMode: boolean }) => {
  return (
    <S.DataGrid.EditableColumnHeader>
      {editMode && <EditIcon fontSize="small" />}
      <span>{headerName}</span>
    </S.DataGrid.EditableColumnHeader>
  )
}
