import { ReactElement, useEffect, useState } from 'react'
import { Query_Root } from 'src/@types/graphql'
import { useMessageSource } from 'src/i18n/useMessageSource'
import { fetchKapMilestoneReportsByMeasureIdAndMilestoneIdQuery } from 'src/screens/kap/implementation/milestone/kapMilestoneQueries'
import { AnnualPlanFeatureKpiGrid } from 'src/screens/shared/implementation/details/milestone/details/annual/kpi/AnnualPlanFeatureKpiGrid'
import { LEVEL_TYPE } from 'src/shared/constants/constants'
import { HtmlRenderer } from 'src/shared/presentation/HtmlRenderer'
import { ReadOnlySelection, ReadOnlyTextField } from 'src/shared/presentation/ReadOnly'
import { useNotificationService } from 'src/shared/utils/NotificationService'
import { useClient } from 'urql'

interface Props {
  measureId: number
  measureLevel: LEVEL_TYPE
  milestoneId: number
  yearInFocus: string
}

export const KapMilestoneMeasurePlanningContent = ({
  measureId,
  measureLevel,
  milestoneId,
  yearInFocus,
}: Props): ReactElement => {
  const { getMessage } = useMessageSource()
  const urqlClient = useClient()
  const notificationService = useNotificationService()

  const [measure, setMeasure] = useState<{ description: string; planning_description: string }>()

  useEffect(() => {
    const initData = async () => {
      const { data } = await urqlClient
        .query<
          {
            kap_measure: Query_Root['kap_measure']
            kap_measure_annual_planning: Query_Root['kap_measure_annual_planning']
          },
          { measureId: number; milestoneId: number }
        >(fetchKapMilestoneReportsByMeasureIdAndMilestoneIdQuery, { measureId, milestoneId })
        .toPromise()

      if (data && data?.kap_measure && data?.kap_measure_annual_planning) {
        const kapMeasure = data?.kap_measure?.[0]
        const kapMeasurePlanning = data?.kap_measure_annual_planning?.[0]
        setMeasure({
          description: kapMeasure?.description,
          planning_description: kapMeasurePlanning?.planning_description,
        })
      } else {
        notificationService.operationFailed()
      }
    }

    initData()
  }, [urqlClient, notificationService, measureId, milestoneId])

  return (
    <>
      <ReadOnlyTextField text={getMessage('label.description')}>
        {measure?.description && <HtmlRenderer html={measure.description} />}
      </ReadOnlyTextField>
      <ReadOnlyTextField text={`${getMessage('label.plan')} ${yearInFocus}`}>
        {measure?.planning_description && <HtmlRenderer html={measure.planning_description} />}
      </ReadOnlyTextField>
      <ReadOnlySelection text={getMessage('label.program.kpis')} isLast>
        <AnnualPlanFeatureKpiGrid
          editable={false}
          measureId={measureId}
          measureLevel={measureLevel}
          reportYear={parseInt(yearInFocus)}
        />
      </ReadOnlySelection>
    </>
  )
}
