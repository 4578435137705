import { ListItemText } from '@mui/material'
import { useRef, useState } from 'react'
import { useParams } from 'react-router'
import { useMessageSource } from 'src/i18n/useMessageSource'
import { EXPORT_FORMAT, PROJECT_TYPE } from 'src/shared/constants/constants'
import { ExportMenu } from 'src/shared/menu/ExportMenu'
import { S } from 'src/shared/styled/S'
import { useUserLocale } from 'src/user/UserContext'
import { LoadingMenuItem } from 'src/shared/menu/item/LoadingMenuItem'
import { downloadFileByUrl } from 'src/shared/utils/BlobUtils'

interface Props {
  process: PROJECT_TYPE
  year: number
}

export const ProjectMilestoneExportMenu = ({ process, year }: Props) => {
  const { getMessage } = useMessageSource()
  const { projectId } = useParams()
  const language = useUserLocale()

  const exportMenuRef = useRef<{ closeMenu: () => void }>({ closeMenu: () => {} })

  const [loadingExport, setLoadingExport] = useState(false)

  const handleAnnualAndFinancialReportingExport = () => {
    setLoadingExport(true)
    downloadFileByUrl(
      `api/${process}/project/${projectId}/milestone/annual-and-financial/${year}/export?lang=${language}`,
    )
      .then(() => exportMenuRef.current.closeMenu())
      .finally(() => setLoadingExport(false))
  }

  return (
    <ExportMenu ref={exportMenuRef}>
      <LoadingMenuItem
        loading={loadingExport}
        disabled={loadingExport}
        onClick={handleAnnualAndFinancialReportingExport}
      >
        <ListItemText>{`${getMessage('label.export.milestone.annual.and.financials')} ${year}`}</ListItemText>
        <S.Typography.ExportFormat>({EXPORT_FORMAT.WORD})</S.Typography.ExportFormat>
      </LoadingMenuItem>
    </ExportMenu>
  )
}
