import { LoadingButton } from '@mui/lab'
import { Typography } from '@mui/material'
import { GridColDef, useGridApiRef } from '@mui/x-data-grid-pro'
import { ReactElement, useCallback, useEffect, useMemo, useState } from 'react'
import { MeasureResponseData, Mutation_Root, ReportInput } from 'src/@types/graphql'
import { useMessageSource } from 'src/i18n/useMessageSource'
import { PROJECT, PROJECT_TYPE } from 'src/shared/constants/constants'
import { DownloadIcon } from 'src/shared/icons/Icons'
import { S } from 'src/shared/styled/S'
import { useGridTranslateHook } from 'src/shared/utils/hooks/grid-translate-hook'
import { useNotificationService } from 'src/shared/utils/NotificationService'
import { ReportingGridResponseType, Utils } from 'src/shared/utils/Utils'
import { muiTheme } from 'src/theme/theme'
import { gql, useClient } from 'urql'

const fetchMeasuresReportDataMutation = gql`
  mutation fetchPfKapProjectMeasuresReportData(
    $report: ReportInput!
    $isPfKap: Boolean!
    $isPfPgv: Boolean!
    $isKap: Boolean!
  ) {
    fetchPfKapProjectMeasuresReportData(report: $report) @include(if: $isPfKap) {
      data {
        year
        green
        orange
        red
        inactive
      }
    }
    fetchPfPgvProjectMeasuresReportData(report: $report) @include(if: $isPfPgv) {
      data {
        year
        green
        orange
        red
        inactive
      }
    }
    fetchKapProgramMeasuresReportData(report: $report) @include(if: $isKap) {
      data {
        year
        green
        orange
        red
        inactive
      }
    }
  }
`

interface Props {
  reportInput: ReportInput
  process: PROJECT_TYPE
  handleDownload: () => Promise<void>
}

export const ReportMeasureGrid = ({ reportInput, process, handleDownload }: Props): ReactElement => {
  const gridTranslations = useGridTranslateHook()
  const { getMessage } = useMessageSource()
  const apiRef = useGridApiRef()
  const urqlClient = useClient()
  const notificationService = useNotificationService()

  const [measuresReport, setMeasuresReport] = useState<ReportingGridResponseType[]>([])
  const [loadingDownload, setLoadingDownload] = useState<boolean>(false)

  const fetchData = useCallback(async () => {
    const isPfKap = process === PROJECT.PF_KAP
    const isPfPgv = process === PROJECT.PF_PGV
    const isKap = process === PROJECT.KAP

    const { data } = await urqlClient
      .mutation<{
        fetchPfKapProjectMeasuresReportData: Mutation_Root['fetchPfKapProjectMeasuresReportData']
        fetchPfPgvProjectMeasuresReportData: Mutation_Root['fetchPfPgvProjectMeasuresReportData']
        fetchKapProgramMeasuresReportData: Mutation_Root['fetchKapProgramMeasuresReportData']
      }>(fetchMeasuresReportDataMutation, { report: reportInput, isPfKap, isPfPgv, isKap })
      .toPromise()

    if (data) {
      let reportData: MeasureResponseData[]
      switch (process) {
        case 'PF_KAP':
          reportData = data?.fetchPfKapProjectMeasuresReportData?.data
          break
        case 'PF_PGV':
          reportData = data?.fetchPfPgvProjectMeasuresReportData?.data
          break
        case 'KAP':
          reportData = data?.fetchKapProgramMeasuresReportData?.data
      }

      const measuresReport = Utils.extendAnnualRatingReportArrayWithPrevAndNextValues(reportData, reportInput)

      setMeasuresReport(measuresReport)
    } else {
      notificationService.operationFailed()
    }
  }, [urqlClient, reportInput, notificationService, process])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  const columns = useMemo<GridColDef[]>(
    () => [
      {
        field: 'rating',
        headerName: getMessage('label.rating'),
        flex: 1,
        sortable: false,
        minWidth: 150,
        type: 'string',
        renderCell({ value }) {
          return (
            <Typography variant="subtitle2" sx={{ color: muiTheme.palette.text.disabled }}>
              {value}
            </Typography>
          )
        },
      },
      ...measuresReport.map((report) => ({
        field: `${report.year}`,
        headerName: `${report.year}`,
        flex: 1,
        sortable: false,
        minWidth: 90,
        type: 'number',
      })),
    ],
    [getMessage, measuresReport],
  )

  const rows = useMemo(
    () => [
      {
        id: 1,
        rating: getMessage('label.reporting.measure.rating.GREEN'),
        ...measuresReport.reduce((obj, item) => ({ ...obj, [item.year]: item.green }), {}),
      },
      {
        id: 2,
        rating: getMessage('label.reporting.measure.rating.ORANGE'),
        ...measuresReport.reduce((obj, item) => ({ ...obj, [item.year]: item.orange }), {}),
      },
      {
        id: 3,
        rating: getMessage('label.reporting.measure.rating.RED'),
        ...measuresReport.reduce((obj, item) => ({ ...obj, [item.year]: item.red }), {}),
      },
      {
        id: 4,
        rating: getMessage('label.reporting.measure.rating.INACTIVE'),
        ...measuresReport.reduce((obj, item) => ({ ...obj, [item.year]: item.inactive }), {}),
      },
    ],
    [getMessage, measuresReport],
  )

  const onDownloadHandle = async () => {
    setLoadingDownload(true)
    await handleDownload()
    setLoadingDownload(false)
  }

  return (
    <S.DataGrid.ScrollDataGridStyled
      apiRef={apiRef}
      rows={rows}
      columns={columns}
      initialState={{ pinnedColumns: { left: ['rating'] } }}
      autoHeight
      localeText={gridTranslations}
      disableColumnResize
      disableColumnSelector
      disableColumnReorder
      disableRowSelectionOnClick
      disableColumnMenu
      disableColumnFilter
      disableMultipleColumnsSorting
      hideFooter
      slots={{
        toolbar: () => (
          <S.DataGrid.ToolbarContainer $reporting>
            <Typography>{getMessage('label.reporting.measures')}</Typography>
            <LoadingButton
              variant="text"
              color="primary"
              startIcon={<DownloadIcon />}
              onClick={onDownloadHandle}
              loading={loadingDownload}
            >
              {getMessage('button.download')}
            </LoadingButton>
          </S.DataGrid.ToolbarContainer>
        ),
      }}
    />
  )
}
