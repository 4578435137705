import { CardHeader, Typography } from '@mui/material'
import { get } from 'lodash'
import { ReactElement } from 'react'
import { Feature_Config, Feature_Type_Config } from 'src/@types/graphql'
import { BOX_SHADOW_ON_WHITE_BG } from 'src/shared/constants/styling-constants'
import { CheckboxData, CheckboxGroupField } from 'src/shared/form/control/CheckboxGroupField'
import { ListSelectionIcon } from 'src/shared/icons/Icons'
import { ExpandableCard } from 'src/shared/presentation/ExpandableCard'
import { useUserLocale } from 'src/user/UserContext'
import styled from 'styled-components/macro'

interface Props {
  featureType: Feature_Type_Config
  featureConfigs: Feature_Config[]
  featuresCheckboxData: CheckboxData[]
  showSelectionWithinGroupIcon: boolean
}

const ExpandableCardStyled = styled(ExpandableCard)`
  box-shadow: ${BOX_SHADOW_ON_WHITE_BG.default};

  &:hover {
    box-shadow: ${BOX_SHADOW_ON_WHITE_BG.hover};
  }

  .MuiCardContent-root {
    padding: 0;

    &:last-child {
      padding-bottom: 0;
    }

    .MuiFormHelperText-root {
      display: none;
    }
  }
`

const CardHeaderStyled = styled(CardHeader)`
  height: 3rem;

  .MuiCardHeader-content {
    display: flex;
    gap: ${({ theme }) => theme.spacing(2)};
    align-items: center;
  }
`

export const ExpandableFeatureSelectionCard = ({
  featureType,
  featureConfigs,
  featuresCheckboxData,
  showSelectionWithinGroupIcon,
}: Props): ReactElement => {
  const language = useUserLocale()

  const featureConfigsByType = featureConfigs.filter(
    (featureConfig) => featureConfig.feature_type_id === featureType.id,
  )

  const getFeaturesByType = () => {
    const featureConfigIdsArray = featureConfigsByType.map((featureConfig) => featureConfig.id)
    return featuresCheckboxData.filter((feature) => featureConfigIdsArray.includes(feature.value as number))
  }

  return (
    <ExpandableCardStyled
      header={
        <CardHeaderStyled
          title={
            <>
              <Typography variant="subtitle2">{get(featureType.names, language, '')}</Typography>
              {showSelectionWithinGroupIcon && <ListSelectionIcon color="action" />}
            </>
          }
          disableTypography
        />
      }
      content={<CheckboxGroupField name={`features.${featureType.code}`} data={getFeaturesByType()} />}
    />
  )
}
