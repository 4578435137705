import { gql } from 'urql'

export const queryEvaluationMilestonesByDossierId = gql`
  query ($dossierId: Int!) {
    evaluation_milestone(where: { dossier_id: { _eq: $dossierId } }, order_by: { date: desc }) {
      id
      title
      date
      status
    }
  }
`

export const queryEvaluationMilestoneSecondaryInfoById = gql`
  query ($evaluationMilestoneId: Int!) {
    evaluation_milestone_by_pk(id: $evaluationMilestoneId) {
      description
    }
  }
`

export const queryEvaluationMilestoneById = gql`
  query ($id: Int!) {
    evaluation_milestone_by_pk(id: $id) {
      id
      title
      date
      status
      description
    }
  }
`

export const upsertEvaluationMilestoneMutation = gql`
  mutation ($object: evaluation_milestone_insert_input!) {
    insert_evaluation_milestone_one(
      object: $object
      on_conflict: { constraint: evaluation_milestone_pk, update_columns: [title, date, status, description] }
    ) {
      id
    }
  }
`

export const queryDossiers = gql`
  query fetchDossiers($process: String!, $related: [Int!]!, $isPfKap: Boolean!, $isPfPgv: Boolean!) {
    dossier(where: { _and: { type: { _eq: $process }, id: { _nin: $related } } }) {
      id
      short_title
      project_bases {
        id
        pf_kap_projects @include(if: $isPfKap) {
          id
        }
        pf_pgv_projects @include(if: $isPfPgv) {
          id
        }
        project_users {
          user_id
          type
        }
      }
    }
  }
`

export const queryProjectEvaluationByProjectId = gql`
  query ($id: Int!) {
    pf_kap_project_by_pk(id: $id) {
      evaluation
    }
  }
`

export const queryRelatedEvaluations = gql`
  query fetchRelatedEvaluations($dossierId: Int!, $isPfKap: Boolean!, $isPfPgv: Boolean!) {
    source_related_evaluation: related_evaluation(where: { dossier: { id: { _eq: $dossierId } } }) {
      id
      dossier_id
      related_dossier_id
      dossier {
        short_title
      }
      dossierByRelatedDossierId {
        short_title
        project_bases {
          pf_kap_projects @include(if: $isPfKap) {
            id
          }
          pf_pgv_projects @include(if: $isPfPgv) {
            id
          }
          project_users {
            user_id
            type
          }
        }
      }
    }
    destination_related_evaluation: related_evaluation(
      where: { dossierByRelatedDossierId: { id: { _eq: $dossierId } } }
    ) {
      id
      dossier_id
      related_dossier_id
      dossier {
        short_title
        project_bases {
          pf_kap_projects @include(if: $isPfKap) {
            id
          }
          pf_pgv_projects @include(if: $isPfPgv) {
            id
          }
          project_users {
            user_id
            type
          }
        }
      }
      dossierByRelatedDossierId {
        short_title
      }
    }
  }
`

export const insertRelatedEvaluationMutation = gql`
  mutation insertRelatedEvaluation($objects: [related_evaluation_insert_input!]!) {
    insert_related_evaluation(objects: $objects) {
      affected_rows
    }
  }
`

export const deleteRelatedEvaluationMutation = gql`
  mutation deleteRelatedEvaluation($dossierId: Int!, $relatedDossierId: Int!) {
    delete_related_evaluation(
      where: {
        _or: [
          { _and: [{ dossier_id: { _eq: $dossierId } }, { related_dossier_id: { _eq: $relatedDossierId } }] }
          { _and: [{ dossier_id: { _eq: $relatedDossierId } }, { related_dossier_id: { _eq: $dossierId } }] }
        ]
      }
    ) {
      affected_rows
    }
  }
`

export const updateProjectEvaluationByProjectId = gql`
  mutation ($projectId: Int!, $setInput: pf_kap_project_set_input) {
    update_pf_kap_project(where: { id: { _eq: $projectId } }, _set: $setInput) {
      affected_rows
    }
  }
`

export const deleteEvaluationMilestoneByIdMutation = gql`
  mutation ($id: Int!) {
    delete_evaluation_milestone_by_pk(id: $id) {
      id
    }
  }
`

export const fetchEvaluationMilestoneFileUUIDsQuery = gql`
  query ($evaluationMilestoneId: Int!) {
    file(
      where: { _and: { entity_id: { _eq: $evaluationMilestoneId }, entity_type: { _eq: "EVALUATION_MILESTONE" } } }
    ) {
      uuid
    }
  }
`
