import { Kap_Goal, Kap_Measure, Project_Goal, Project_Measure } from 'src/@types/graphql'

/**
 * Check if the fetched data (list of goals or measures) contains the correct order after performing reordering (swap).
 *
 * @param data List of goals or measures.
 * @param id1 Id of the already swapped first goal/measure.
 * @param id2 Id of the already swapped second goal/measure.
 */
const areGoalsMeasuresSortedLatest = (
  data: Kap_Goal[] | Project_Goal[] | Kap_Measure[] | Project_Measure[],
  id1: number,
  id2: number,
): boolean => {
  for (let i = 0; i < data.length; i++) {
    if (i > 0 && data[i].id === id2 && data[i - 1].id === id1) {
      return true
    }
  }
  return false
}

export { areGoalsMeasuresSortedLatest }
