import { ReactElement, useCallback, useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { Milestone, Query_Root } from 'src/@types/graphql'
import { ROUTES } from 'src/routing/routes'
import { resolveKapMilestoneRoot } from 'src/routing/routing-utils'
import { ValidationListItemProps } from 'src/screens/shared/common/SummaryValidationUtils'
import { MilestoneSummaryView } from 'src/screens/shared/implementation/details/milestone/details/summary/MilestoneSummaryView'
import { useValidateMilestone } from 'src/screens/shared/implementation/details/milestone/MilestoneService'
import { milestoneSummaryDataQuery } from 'src/screens/shared/implementation/details/milestoneQueries'
import { MilestoneWorkflowService } from 'src/service/axios/MilestoneWorkflowService'
import { usePermissionsForKapMilestones, usePermissionsForKapProgram } from 'src/service/security/PermissionHook'
import { ProgramWorkflowPermissionService } from 'src/service/security/ProgramWorkflowPermissionService'
import { MILESTONE_RESPONSIBLE_TYPE_TYPE, MILESTONE_STATUS_TYPE } from 'src/shared/constants/milestone-constants'
import { NextState } from 'src/shared/constants/workflow-constants'
import { useDelayedNavigate } from 'src/shared/utils/hooks/navigation-hooks'
import { resolveProcessToLowerCase, Utils } from 'src/shared/utils/Utils'
import { UserContext } from 'src/user/UserContext'
import { useClient } from 'urql'

export const KapMilestoneSummaryPage = (): ReactElement => {
  const { programId, milestoneId, milestonePath } = useParams()
  const navigate = useDelayedNavigate()
  const urqlClient = useClient()
  const { user } = useContext(UserContext)

  const [milestoneSummary, setMilestoneSummary] = useState<Milestone>()
  const [nextStates, setNextStates] = useState<null | NextState[]>(null)

  const process = resolveProcessToLowerCase('/kap')
  const milestoneType = Utils.resolveMilestoneType(milestonePath)
  const userGlobalRoles = user.roles

  const { isOwnCanton, canManageMilestones } = usePermissionsForKapProgram(parseInt(programId as string))

  const fetchData = useCallback(async () => {
    const { data } = await urqlClient
      .query<{ milestone_by_pk: Query_Root['milestone_by_pk'] }>(milestoneSummaryDataQuery, { milestoneId })
      .toPromise()

    const nextStates = await MilestoneWorkflowService.nextStates(data?.milestone_by_pk?.workflow_id ?? '')
    const nextStatesForUser = ProgramWorkflowPermissionService.nextStatesForUser(nextStates)(
      userGlobalRoles,
      isOwnCanton,
    )

    if (data?.milestone_by_pk) {
      setMilestoneSummary(data.milestone_by_pk)
      setNextStates(nextStatesForUser)
    }
  }, [milestoneId, urqlClient, userGlobalRoles, isOwnCanton])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  const { loading, canEdit } = usePermissionsForKapMilestones(
    parseInt(programId as string),
    milestoneSummary?.status as MILESTONE_STATUS_TYPE,
    milestoneSummary?.responsible_type as MILESTONE_RESPONSIBLE_TYPE_TYPE,
  )

  const onBack = () => {
    const route = ROUTES.KapImplementationDetailsRoot.params({ programId })
    navigate(route)
  }

  const onEdit = () => {
    const route = ROUTES.KapDefaultMilestoneRoot.nested.SummaryCommentEdit.params({
      programId,
      milestoneId,
      milestonePath,
    })
    navigate(route)
  }

  const handleViolationClick = (validationItem: ValidationListItemProps) => {
    const route = validationItem.root
      ? validationItem.root
      : resolveKapMilestoneRoot(milestoneType).params({ programId, milestoneId, milestonePath })

    navigate(
      `${route}${validationItem.tab ? '/' + validationItem.tab : ''}${
        validationItem.section ? `#${validationItem.section}` : ''
      }`,
    )
  }

  const validationViolations = useValidateMilestone(parseInt(milestoneId as string), milestoneType, '/kap')

  return (
    <MilestoneSummaryView
      loading={loading}
      milestoneSummary={milestoneSummary}
      milestoneType={milestoneType}
      process={process}
      canEdit={canEdit}
      canDeleteMilestone={canManageMilestones}
      onBack={onBack}
      onEdit={onEdit}
      validationViolations={validationViolations}
      handleViolationClick={handleViolationClick}
      nextStates={nextStates}
      refetchPageData={fetchData}
    />
  )
}
