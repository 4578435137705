import { Link } from 'react-router-dom'
import styled from 'styled-components/macro'

export const LinkStyled = styled(Link)<{ $isLast?: boolean }>`
  text-decoration: none;
  display: inline-flex;
  margin-bottom: ${({ $isLast, theme }) => ($isLast ? 0 : theme.spacing(2))};
  gap: ${({ theme }) => theme.spacing(1)};
  align-items: center;
  color: ${({ theme }) => theme.colors.secondary.dark};

  &:hover {
    text-decoration: underline;
  }

  &:active {
    color: ${({ theme }) => theme.colors.primary.dark};
  }

  .MuiSvgIcon-root {
    font-size: 1.25rem;
  }
`
