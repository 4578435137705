import { Card, CardContent, ListItem, ListItemIcon, ListItemText } from '@mui/material'
import { useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router'
import { Kap_Goal, Kap_Goal_Measure, Kap_Measure, Query_Root } from 'src/@types/graphql'
import { useMessageSource } from 'src/i18n/useMessageSource'
import { ROUTES } from 'src/routing/routes'
import { fetchKapGoalByIdQuery } from 'src/screens/kap/program/details/goals/kapGoalQueries'
import { sortKapMeasures } from 'src/screens/shared/measure/utils/KapMeasureUtils'
import { usePermissionsForKapProgram } from 'src/service/security/PermissionHook'
import { EditButton } from 'src/shared/button/Buttons'
import { LinkIcon } from 'src/shared/icons/Icons'
import { PageLayout } from 'src/shared/layout/PageLayout'
import { ScreenLayout } from 'src/shared/layout/ScreenLayout'
import { DefaultSectionTypography } from 'src/shared/presentation/DefaultSectionTypography'
import { HelpAndInstructions } from 'src/shared/presentation/HelpAndInstructions'
import { HtmlRenderer } from 'src/shared/presentation/HtmlRenderer'
import { ReadOnlySelection, ReadOnlyTextField } from 'src/shared/presentation/ReadOnly'
import { S } from 'src/shared/styled/S'
import { useDelayedNavigate } from 'src/shared/utils/hooks/navigation-hooks'
import { useNotificationService } from 'src/shared/utils/NotificationService'
import { useClient } from 'urql'

export const KapGoalViewPage = () => {
  const { programId, goalId } = useParams()
  const { getMessage } = useMessageSource()
  const navigate = useDelayedNavigate()
  const urqlClient = useClient()
  const notificationService = useNotificationService()

  const program_id = parseInt(programId as string)
  const goal_id = parseInt(goalId as string)

  const { loading, canEdit } = usePermissionsForKapProgram(program_id)

  const [goal, setGoal] = useState<Kap_Goal>()

  const sortedMeasuresLinked: Kap_Goal_Measure[] = useMemo(
    () => sortKapMeasures(goal?.kap_goal_measures, 'kap_measure'),
    [goal?.kap_goal_measures],
  )

  useEffect(() => {
    const fetchData = async () => {
      const { data } = await urqlClient
        .query<{ kap_goal: Query_Root['kap_goal'] }>(fetchKapGoalByIdQuery, {
          id: goal_id,
        })
        .toPromise()

      if (data?.kap_goal[0]) {
        setGoal(data?.kap_goal[0])
      } else {
        notificationService.operationFailed()
      }
    }

    fetchData()
  }, [urqlClient, goal_id, notificationService])

  const getRelatedMeasureDetailsString = (measure: Kap_Measure) => {
    const measureIdCharacter = `label.kap.measure.id.character.${measure.level}`

    return `${getMessage(measureIdCharacter)}${measure.sort_number}: ${measure.name}`
  }

  const handleEdit = () => {
    navigate(ROUTES.KapDetailsRoot.nested.GoalEdit.params({ programId, goalId }))
  }

  const onBack = () => {
    const route = ROUTES.KapDetailsRoot.nested.Goals.params({ programId })
    navigate(`${route}#${goal_id}`)
  }

  return (
    <ScreenLayout
      title={getMessage('label.goal.title.view')}
      hasSecondLevelNavigation={false}
      onBack={onBack}
      actions={
        <>
          <EditButton origin="header" onClick={handleEdit} hidden={!canEdit} />
        </>
      }
    >
      <PageLayout>
        <>
          {!loading && goal && (
            <>
              <HelpAndInstructions labelKey="label.help.kap.program.goals" />
              <Card>
                <CardContent>
                  <ReadOnlyTextField text={getMessage('label.goal.id')}>
                    {getMessage('label.goal.id.character')}
                    {goal.sort_number}
                  </ReadOnlyTextField>
                  <ReadOnlyTextField text={getMessage('label.title')}>{goal.name}</ReadOnlyTextField>
                  <ReadOnlyTextField text={getMessage('label.description')}>
                    <HtmlRenderer html={goal.description} />
                  </ReadOnlyTextField>
                  <ReadOnlySelection text={getMessage('label.related.measures')} isLast={true}>
                    <S.List.ReadOnly>
                      {sortedMeasuresLinked.length ? (
                        sortedMeasuresLinked.map((x: Kap_Goal_Measure) => (
                          <ListItem key={x.kap_measure.id}>
                            <ListItemIcon>
                              <LinkIcon />
                            </ListItemIcon>
                            <ListItemText primary={getRelatedMeasureDetailsString(x.kap_measure)} />
                          </ListItem>
                        ))
                      ) : (
                        <DefaultSectionTypography
                          noEntriesMessageKey={getMessage('label.not.available.measures')}
                          $standAlone={false}
                        />
                      )}
                    </S.List.ReadOnly>
                  </ReadOnlySelection>
                </CardContent>
              </Card>
            </>
          )}
        </>
      </PageLayout>
    </ScreenLayout>
  )
}
