import { DataGridPro } from '@mui/x-data-grid-pro'
import { TYPOGRAPHY_MIXIN } from 'src/shared/constants/styling-constants'
import styled from 'styled-components/macro'

export const DataGridTotalRowStyled = styled(DataGridPro)`
  & .MuiDataGrid-row:nth-last-child(2) .MuiDataGrid-cell {
    border-bottom-color: ${({ theme }) => theme.colors.action.disabled};
  }
  & .MuiDataGrid-row:last-child {
    &:hover {
      background-color: transparent;
    }
    .MuiDataGrid-cell {
      ${TYPOGRAPHY_MIXIN.subtitle2};
    }
  }
`
