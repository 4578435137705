import { Card, CardContent } from '@mui/material'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { Intermediate_Discussion_Evaluation, Query_Root } from 'src/@types/graphql'
import { useMessageSource } from 'src/i18n/useMessageSource'
import { resolveImplementationDetailsRoot, resolveIntermediateMilestoneRoot } from 'src/routing/routing-utils'
import { fetchEvaluationNeedByMilestoneId } from 'src/screens/shared/implementation/details/milestone/details/evaluation/milestoneEvaluationQueries'
import { queryMilestoneById } from 'src/screens/shared/implementation/details/milestoneQueries'
import { usePermissionsForMilestones } from 'src/service/security/PermissionHook'
import { EditButton } from 'src/shared/button/Buttons'
import { PROJECT } from 'src/shared/constants/constants'
import { MILESTONE_RESPONSIBLE_TYPE_TYPE, MILESTONE_STATUS_TYPE } from 'src/shared/constants/milestone-constants'
import { PageLayout } from 'src/shared/layout/PageLayout'
import { ScreenLayout } from 'src/shared/layout/ScreenLayout'
import { NotAuthorized } from 'src/shared/not-authorized/NotAuthorized'
import { HelpAndInstructions } from 'src/shared/presentation/HelpAndInstructions'
import { HtmlRenderer } from 'src/shared/presentation/HtmlRenderer'
import { ReadOnlyTextField } from 'src/shared/presentation/ReadOnly'
import { Section } from 'src/shared/presentation/Section'
import { DateUtils } from 'src/shared/utils/DateUtils'
import { useDelayedNavigate } from 'src/shared/utils/hooks/navigation-hooks'
import { useNotificationService } from 'src/shared/utils/NotificationService'
import { Utils } from 'src/shared/utils/Utils'
import { useClient, useQuery } from 'urql'

interface Props {
  baseUrl: '/pf-kap' | '/pf-pgv'
}

export const MilestoneEvaluationPage = ({ baseUrl }: Props) => {
  const { projectId, milestoneId, milestonePath } = useParams()
  const milestone_id = parseInt(milestoneId as string)
  const project_id = parseInt(projectId as string)
  const milestoneType = Utils.resolveMilestoneType(milestonePath)
  const process = Utils.resolveProcess(baseUrl)

  const { getMessage } = useMessageSource()
  const navigate = useDelayedNavigate()
  const notificationService = useNotificationService()
  const urqlClient = useClient()

  const [evaluation, setEvaluation] = useState<Intermediate_Discussion_Evaluation>()

  const [{ data, error }] = useQuery<{ milestone_by_pk: Query_Root['milestone_by_pk'] }, { id: number }>({
    query: queryMilestoneById,
    variables: { id: milestone_id },
  })

  if (error) {
    notificationService.operationFailed()
  }

  const milestone = data?.milestone_by_pk

  useEffect(() => {
    const initData = async () => {
      const { data, error } = await urqlClient
        .query<{ intermediate_discussion_evaluation: Query_Root['intermediate_discussion_evaluation'] }>(
          fetchEvaluationNeedByMilestoneId,
          { milestoneId: milestone_id },
        )
        .toPromise()

      if (data && data?.intermediate_discussion_evaluation.length > 0) {
        const evaluation = data.intermediate_discussion_evaluation?.[0]
        setEvaluation(evaluation)
      } else if (error) {
        notificationService.operationFailed()
      }
    }

    initData()
  }, [milestone_id, notificationService, urqlClient])

  const { loading, canEdit, canView } = usePermissionsForMilestones(
    process,
    project_id,
    milestone?.status as MILESTONE_STATUS_TYPE,
    milestone?.responsible_type as MILESTONE_RESPONSIBLE_TYPE_TYPE,
  )

  const onEdit = () => {
    const route = resolveIntermediateMilestoneRoot(baseUrl).nested.EvaluationEdit.params({
      projectId,
      milestoneId,
      milestonePath,
    })
    navigate(route)
  }

  const onBack = () => {
    const route = resolveImplementationDetailsRoot(baseUrl).params({ projectId })
    navigate(route)
  }

  if (!loading && !canView) {
    return <NotAuthorized inSecondLevelNavigationTab />
  }

  return (
    <ScreenLayout
      title={`${getMessage(`label.milestone.type.${milestoneType}`)} ${milestone?.year_in_focus ?? ''}`}
      onBack={onBack}
    >
      <PageLayout>
        <>
          {!loading && (
            <>
              <Section
                title={getMessage('label.title.evaluation')}
                helpAndInstructions={<HelpAndInstructions labelKey={`label.help.milestone.evaluation.${process}`} />}
                actionButton={<EditButton onClick={onEdit} hidden={!canEdit} />}
                optional={process === PROJECT.PF_PGV}
              >
                <Card>
                  <CardContent>
                    <ReadOnlyTextField text={getMessage('label.evaluation.need')}>
                      {evaluation?.evaluation_need &&
                        getMessage(`label.evaluation.need.${evaluation?.evaluation_need}`)}
                    </ReadOnlyTextField>
                    <ReadOnlyTextField text={getMessage('label.planned.year')}>
                      {evaluation?.year &&
                        DateUtils.formatDate(DateUtils.parseDateGraphQL(evaluation?.year), DateUtils.YEAR_FORMAT)}
                    </ReadOnlyTextField>
                    <ReadOnlyTextField text={getMessage('label.reasoning')} isLast>
                      {evaluation?.reasoning && <HtmlRenderer html={evaluation?.reasoning} />}
                    </ReadOnlyTextField>
                  </CardContent>
                </Card>
              </Section>
            </>
          )}
        </>
      </PageLayout>
    </ScreenLayout>
  )
}
