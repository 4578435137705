import { Typography } from '@mui/material'
import combinedQuery from 'graphql-combine-query'
import { sortBy } from 'lodash'
import { ReactElement, useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { Query_Root } from 'src/@types/graphql'
import { useMessageSource } from 'src/i18n/useMessageSource'
import { MilestoneRelatedGoalsMeasuresGrid } from 'src/screens/shared/implementation/details/milestone/details/annual/MilestoneRelatedGoalsMeasuresGrid'
import { queryGoalsAndMeasuresAnnualReport } from 'src/screens/shared/implementation/details/milestoneQueries'
import { queryMeasureById } from 'src/screens/shared/measure/measureQueries'
import { MeasureAnnualReport } from 'src/shared/constants/milestone-constants'
import { DefaultSectionTypography } from 'src/shared/presentation/DefaultSectionTypography'
import { HtmlRenderer } from 'src/shared/presentation/HtmlRenderer'
import { ReadOnlySelection, ReadOnlyTextField } from 'src/shared/presentation/ReadOnly'
import { useNotificationService } from 'src/shared/utils/NotificationService'
import { resolveProjectMeasureRatingColor } from 'src/shared/utils/StylingUtils'
import { Utils } from 'src/shared/utils/Utils'
import { useClient } from 'urql'

interface Props {
  measureId: number
}

export const MilestoneMeasureCardContent = ({ measureId }: Props): ReactElement => {
  const [measure, setMeasure] = useState<MeasureAnnualReport>()
  const { milestoneId } = useParams()
  const { getMessage } = useMessageSource()
  const urqlClient = useClient()
  const notificationService = useNotificationService()
  const milestone_id = parseInt(milestoneId as string)

  useEffect(() => {
    const initData = async () => {
      const { document, variables } = combinedQuery('projectMeasureAndAnnualReportAndMilestone')
        .add<{ project_measure_by_pk: Query_Root['project_measure_by_pk'] }, { id: number }>(queryMeasureById, {
          id: measureId,
        })
        .add<
          {
            project_goal_annual_report: Query_Root['project_goal_annual_report']
            project_measure_annual_report: Query_Root['project_measure_annual_report']
          },
          { milestoneId: number }
        >(queryGoalsAndMeasuresAnnualReport, { milestoneId: milestone_id })

      const { data } = await urqlClient
        .query<{
          project_measure_by_pk: Query_Root['project_measure_by_pk']
          project_measure_annual_report: Query_Root['project_measure_annual_report']
          project_goal_annual_report: Query_Root['project_goal_annual_report']
        }>(document, variables)
        .toPromise()

      if (data && data?.project_measure_by_pk && data?.project_measure_annual_report) {
        const measure = data?.project_measure_by_pk

        const linkedGoalsIds = measure.project_goal_measures.map((m) => m.project_goal.id)
        const measureReport = data?.project_measure_annual_report?.filter((m) => m.project_measure_id === measureId)[0]

        const goalsAnnualReport = data?.project_goal_annual_report.filter((pg) =>
          linkedGoalsIds.some((id) => id === pg.project_goal_id),
        )

        const goalMeasures = measure.project_goal_measures.map((x) => x.project_goal)

        const mergedGoalsAndReports = Utils.mergeBy(
          { arr: goalMeasures, key: 'id' },
          { arr: goalsAnnualReport, key: 'project_goal_id' },
        )

        setMeasure({
          measure_id: measure.id,
          name: measure.name,
          description: measure.description,
          rating: measureReport?.rating ?? null,
          rating_comment: measureReport?.rating_comment ?? '',
          goal_measures: sortBy(mergedGoalsAndReports, ['sort_number']),
          sort_number: measure.sort_number,
        })
      } else {
        notificationService.operationFailed()
      }
    }
    initData()
  }, [urqlClient, notificationService, milestone_id, measureId])

  return (
    <>
      {measure && (
        <>
          <ReadOnlyTextField text={getMessage('label.description')}>
            {measure.description && <HtmlRenderer html={measure.description} />}
          </ReadOnlyTextField>
          <ReadOnlyTextField text={getMessage('label.measure.rating')}>
            {measure.rating && (
              <Typography color={resolveProjectMeasureRatingColor(measure.rating)}>
                {measure.rating && getMessage(`label.measure.rating.${measure.rating}`)}
              </Typography>
            )}
          </ReadOnlyTextField>
          <ReadOnlyTextField text={getMessage('label.measure.rating.comment')}>
            {measure.rating_comment && <HtmlRenderer html={measure.rating_comment} />}
          </ReadOnlyTextField>
          <ReadOnlySelection text={getMessage('label.related.goals')} isLast>
            {measure.goal_measures && measure.goal_measures.length > 0 ? (
              <MilestoneRelatedGoalsMeasuresGrid values={measure.goal_measures ?? []} type="goal" />
            ) : (
              <DefaultSectionTypography noEntriesMessageKey={getMessage('label.not.available.goals')} $standAlone />
            )}
          </ReadOnlySelection>
        </>
      )}
    </>
  )
}
