import { Box, Tooltip } from '@mui/material'
import { GridColDef, GridRenderEditCellParams, GridRowId, useGridApiRef } from '@mui/x-data-grid-pro'
import combinedQuery from 'graphql-combine-query'
import { groupBy, keyBy, merge, sortBy, sumBy } from 'lodash'
import { ReactElement, useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router'
import { InternationalizedObject } from 'src/@types'
import {
  Feature_Config_Bool_Exp,
  Feature_Value,
  Kap_Measure_Kpi_Annual_Planning,
  Kap_Measure_Kpi_Annual_Report,
  Query_Root,
} from 'src/@types/graphql'
import { useMessageSource } from 'src/i18n/useMessageSource'
import {
  fetchAllKapMeasureAnnualReportsByMeasureIdQuery,
  fetchKapMeasureFeatureValuesAndMilestoneKPIValuesQuery,
} from 'src/screens/kap/implementation/milestone/kapMilestoneQueries'
import { queryFeatureConfigs } from 'src/screens/shared/feature/featureBaseQueries'
import { LEVEL_TYPE, PROJECT } from 'src/shared/constants/constants'
import { NumberInput } from 'src/shared/form/grid/NumberInput'
import { EditableColumnHeader } from 'src/shared/grid/EditableColumnHeader'
import { DefaultSectionTypography } from 'src/shared/presentation/DefaultSectionTypography'
import { S } from 'src/shared/styled/S'
import { DataGridUtils } from 'src/shared/utils/DataGridUtils'
import { useGridTranslateHook } from 'src/shared/utils/hooks/grid-translate-hook'
import { useError } from 'src/shared/utils/hooks/page-loading-error-hook'
import { useUserLocale } from 'src/user/UserContext'
import { useClient } from 'urql'

interface RowData {
  id: number
  featureType: InternationalizedObject
  feature: InternationalizedObject
  tooltip: InternationalizedObject
  plan: number
  yearActuals: number
  actualsToDate: number
  // not displayed as columns
  sortNumber: number
  featureTypeSortNumber: number
}

interface Props {
  measureId: number
  measureLevel: LEVEL_TYPE
  reportYear: number
  mode: 'view' | 'edit'
  gridApiRef?: any
  onCellEdit?: (initial: boolean) => void
}

export const KapMilestoneMeasureReportKpiGrid = ({
  measureId,
  measureLevel,
  reportYear,
  mode,
  gridApiRef,
  onCellEdit,
}: Props): ReactElement => {
  const { milestoneId } = useParams()
  const milestone_id = parseInt(milestoneId as string)

  const { getMessage } = useMessageSource()
  const gridTranslations = useGridTranslateHook()
  const urqlClient = useClient()
  const language = useUserLocale()
  const setError = useError()

  const localGridApiRef = useGridApiRef()
  const apiRef = mode === 'edit' && gridApiRef ? gridApiRef : localGridApiRef

  const [featureKpis, setFeatureKpis] = useState<
    (Feature_Value & Kap_Measure_Kpi_Annual_Planning & Kap_Measure_Kpi_Annual_Report & { actuals_to_date?: number })[]
  >([])

  useEffect(() => {
    const featureConfigWhere: Feature_Config_Bool_Exp = {
      processes: {
        _contains: [PROJECT.KAP],
      },
    }

    const fetchData = async () => {
      const featureConfigKPIWhere: Feature_Config_Bool_Exp = {
        has_kpi_for_processes: {
          _contains: [PROJECT.KAP],
        },
        has_kpi_for_levels: {
          _contains: [measureLevel],
        },
      }

      const { document, variables } = combinedQuery('FeatureBaseValuesWithKPIsAndAnnualReports')
        .add<
          {
            feature_config: Query_Root['feature_config']
          },
          {
            featureConfigWhere: Feature_Config_Bool_Exp
          }
        >(queryFeatureConfigs, {
          featureConfigWhere: featureConfigWhere,
        })
        .add<
          { feature_base: Query_Root['feature_base']; kap_measure_by_pk: Query_Root['kap_measure_by_pk'] },
          {
            measureId: number
            milestoneId: number
            yearInFocus: number
            featureConfigKPIWhere: Feature_Config_Bool_Exp
          }
        >(fetchKapMeasureFeatureValuesAndMilestoneKPIValuesQuery, {
          measureId: measureId,
          milestoneId: milestone_id,
          yearInFocus: reportYear,
          featureConfigKPIWhere: featureConfigKPIWhere,
        })
        .add<{ kap_measure_kpi_annual_all_reports: Query_Root['kap_measure_kpi_annual_report'] }, { id: number }>(
          fetchAllKapMeasureAnnualReportsByMeasureIdQuery,
          {
            id: measureId,
          },
        )

      const { data, error } = await urqlClient
        .query<
          {
            feature_base: Query_Root['feature_base']
            feature_config: Query_Root['feature_config']
            kap_measure_by_pk: Query_Root['kap_measure_by_pk']
            kap_measure_kpi_annual_all_reports: Query_Root['kap_measure_kpi_annual_report']
          },
          {
            featureConfigWhere: Feature_Config_Bool_Exp
            measureId: number
            milestoneId: number
            yearInFocus: number
            id: number
          }
        >(document, variables!)
        .toPromise()

      if (data) {
        let features = data.feature_base[0].feature_values
        const featureConfigByIds = groupBy(data?.kap_measure_kpi_annual_all_reports, 'feature_config_id')
        const annualReports = data.kap_measure_by_pk?.kap_measure_kpi_annual_reports ?? []
        const annualPlannings = data.kap_measure_by_pk?.kap_measure_kpi_annual_plannings ?? []
        const existingFeatures = data.feature_config
          .filter(
            (config) =>
              (annualReports.some((report) => report.feature_config_id === config.id) ||
                annualPlannings.some((report) => report.feature_config_id === config.id)) &&
              !features.some((feature) => feature.feature_config_id === config.id),
          )
          .map((f) => ({
            feature_config_id: f.id,
            feature_config: f,
          })) as Feature_Value[]

        features = features.concat(existingFeatures)
        const mergedValues = Object.values(
          merge(
            keyBy(features, 'feature_config_id'),
            keyBy(annualReports, 'feature_config_id'),
            keyBy(annualPlannings, 'feature_config_id'),
          ),
        )

        const featuresWithActualsToDate = mergedValues.map((feature) => ({
          ...feature,
          actuals_to_date: sumBy(featureConfigByIds[feature.feature_config_id], 'actual_value'),
        }))

        setFeatureKpis(featuresWithActualsToDate)
      } else if (error) {
        setError()
      }
    }
    fetchData()
  }, [urqlClient, measureId, measureLevel, milestone_id, setError, reportYear])

  const rows = useMemo(() => {
    const currentRowModels = apiRef.current?.getRowModels?.() ?? new Map()
    const kpisUnsorted = featureKpis.reduce(
      (a, c) => [
        ...a,
        {
          id: c!.feature_config_id,
          featureType: c.feature_config!.feature_type_config.names as InternationalizedObject,
          feature: c.feature_config!.names as InternationalizedObject,
          otherDescription: c.other_description,
          tooltip: c.feature_config!.tooltips as InternationalizedObject,
          plan: c.planned_value ?? '-',
          yearActuals: currentRowModels.get(c.id)?.actual_value ?? c.actual_value ?? null,
          actualsToDate: c?.actuals_to_date ?? '-',
          featureTypeSortNumber: c.feature_config.feature_type_config.sort_number,
          sortNumber: c.feature_config.sort_number,
        } as RowData,
      ],
      [] as RowData[],
    )
    onCellEdit && onCellEdit(true)

    return sortBy(kpisUnsorted, ['featureTypeSortNumber', 'sortNumber'])
  }, [featureKpis, apiRef, onCellEdit])

  const columns = useMemo(() => {
    const hideColumn = mode === 'edit'
    const onRifmChange = (api: GridRenderEditCellParams['api'], id: GridRowId, field: string, value: number) => {
      api.setEditCellValue({ id, field, value })
    }

    return [
      {
        field: 'featureType',
        headerName: getMessage('label.feature.type'),
        sortable: false,
        disableColumnMenu: true,
        flex: 0.6,
        disableColumnResize: true,
        valueFormatter: (params) => {
          const value = params.value as InternationalizedObject
          return value?.[language]
        },
      },
      {
        field: 'feature',
        headerName: getMessage('label.feature'),
        sortable: false,
        disableColumnMenu: true,
        flex: 0.9,
        renderCell: (params) => {
          let featureName = ''
          const value = params.value as InternationalizedObject
          if (value) {
            // for "Other" features display also the corresponding description
            featureName = params.row.otherDescription
              ? `${value[language]}: ${params.row.otherDescription}`
              : value[language]
          }
          return (
            <>
              <S.Span.TextOverflow $withTooltip>{value && featureName}</S.Span.TextOverflow>
              {params.row.tooltip[language] && (
                <Tooltip placement="right" title={params.row.tooltip[language]}>
                  <S.Icons.GridTooltipInfo />
                </Tooltip>
              )}
            </>
          )
        },
      },
      {
        field: 'plan',
        headerName: `${getMessage('label.plan')} ${reportYear}`,
        headerAlign: 'right',
        sortable: false,
        align: 'right',
        disableColumnMenu: true,
        flex: 0.3,
        renderCell: ({ value }) => {
          return <S.Span.TextOverflow>{value?.toLocaleString('de-CH')}</S.Span.TextOverflow>
        },
      },
      {
        field: 'yearActuals',
        headerAlign: 'right',
        sortable: false,
        editable: mode === 'edit',
        align: 'right',
        disableColumnMenu: true,
        flex: 0.3,
        renderCell: ({ value }) => {
          if (value === null || value === '' || Number.isNaN(value)) {
            return <DefaultSectionTypography $standAlone />
          }
          return <S.Span.TextOverflow>{value?.toLocaleString('de-CH')}</S.Span.TextOverflow>
        },
        renderEditCell: (params) => {
          const { value, id, field, api } = params
          const isValid = DataGridUtils.validateValue(value)
          return (
            <NumberInput
              value={value as number}
              onChange={(val: number) => onRifmChange(api, id, field, val)}
              error={!isValid}
            />
          )
        },
        renderHeader: () => (
          <EditableColumnHeader
            headerName={`${getMessage('label.report')} ${reportYear ?? ''}`}
            editMode={mode === 'edit'}
          />
        ),
      },
      {
        field: 'actualsToDate',
        headerName: getMessage('label.actuals.to.date'),
        headerAlign: 'right',
        sortable: false,
        align: 'right',
        disableColumnMenu: true,
        flex: 0.3,
        hide: hideColumn,
        renderCell: ({ value }) => {
          return <S.Span.TextOverflow>{value?.toLocaleString('de-CH')}</S.Span.TextOverflow>
        },
      },
    ] as GridColDef[]
  }, [getMessage, language, reportYear, mode])

  return (
    <Box>
      {mode === 'edit' && (
        <S.Form.Label htmlFor="kap-measure-report-grid" form="edit-form">
          {getMessage('label.program.kpis')}
        </S.Form.Label>
      )}
      <Box id="kap-measure-report-grid">
        <S.DataGrid.ViewEditMode
          rows={rows}
          columns={columns}
          autoHeight
          hideFooter
          disableColumnReorder
          disableRowSelectionOnClick
          disableColumnSelector
          apiRef={apiRef}
          onCellEditStop={() => {
            onCellEdit && onCellEdit(false)
          }}
          localeText={gridTranslations}
          $viewMode={mode === 'view'}
        />
      </Box>
    </Box>
  )
}
