import { FormControl, FormHelperText, FormLabel } from '@mui/material'
import { ReactElement, useContext, useEffect, useRef, useState } from 'react'
import { Field, useField } from 'react-final-form'
import ReactQuill, { Quill } from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import { useMessageSource } from 'src/i18n/useMessageSource'
import { FONT_FAMILY, STYLED_FOCUS_OUTLINE, TYPOGRAPHY_MIXIN } from 'src/shared/constants/styling-constants'
import { DirtyContext } from 'src/shared/form/dirty/DirtyContext'
import styled, { css } from 'styled-components/macro'

const Link = Quill.import('formats/link')

// custom quill link format implementation that would prepend http:// if it's not there
// and there are no other valid protocols in the beginning of the link
class CustomLink extends Link {
  static sanitize(url: string) {
    const value = super.sanitize(url)
    if (this.PROTOCOL_WHITELIST.every((p: string) => !value?.startsWith(p))) {
      return `http://${value}`
    }
    return value
  }
}

Quill.register('formats/link', CustomLink)

type HtmlEditorFieldProps = {
  validate?: any
  name: string
  label?: string
  required?: boolean
}

type MessageKeyProps = {
  enterLinkLabel?: string
  saveButtonLabel?: string
  visitUrlLabel?: string
  editButtonLabel?: string
  removeButtonLabel?: string
  boldTooltip?: string
  italicTooltip?: string
  linkTooltip?: string
  orderedListTooltip?: string
  bulletedListTooltip?: string
  clearTooltip?: string
}

/**
 * Function responsible for properly inserting copy-pasted text from ms word.
 *
 * Copied over from: https://github.com/quilljs/quill/issues/1225#issuecomment-748673109
 * @param node node
 * @param delta delta
 * @returns new delta
 */
const matchMsWordList = (node: any, delta: any): any => {
  // Clone the operations
  const ops = delta.ops.map((op: any) => Object.assign({}, op))

  // Trim the front of the first op to remove the bullet/number
  const first: any = ops[0]
  first.insert = first.insert.trimLeft()
  const firstMatch = first.insert.match(/^(\S+)\s+/)
  if (!firstMatch) return delta
  first.insert = first.insert.substring(firstMatch[0].length, first.insert.length)

  // Trim the newline off the last op
  const last = ops[ops.length - 1]
  last.insert = last.insert.substring(0, last.insert.length - 1)

  // Determine the list type
  const prefix = firstMatch[1]
  const listType = prefix.match(/\S+\./) ? 'ordered' : 'bullet'

  // Determine the list indent
  const style = node.getAttribute('style').replace(/\n+/g, '')
  const levelMatch = style.match(/level(\d+)/)
  const indent = levelMatch ? levelMatch[1] - 1 : 0

  // Add the list attribute
  ops.push({ insert: '\n', attributes: { list: listType, indent } })
  //@ts-ignore
  const Delta = Quill.import('delta')
  return new Delta(ops)
}

const MSWORD_MATCHERS = [
  ['p.MsoListParagraphCxSpFirst', matchMsWordList],
  ['p.MsoListParagraphCxSpMiddle', matchMsWordList],
  ['p.MsoListParagraphCxSpLast', matchMsWordList],
]

const QUILL_MODULES = {
  clipboard: {
    allowed: {
      tags: ['b', 'i', 'p', 'a'],
    },
    matchers: MSWORD_MATCHERS,
    keepSelection: true,
    magicPasteLinks: false,
    matchVisual: false,
  },
  toolbar: [['bold', 'italic', 'link'], [{ list: 'ordered' }, { list: 'bullet' }], ['clean']],
}

const QUILL_FORMATS = ['bold', 'italic', 'list', 'br', 'link']

const HTML_EDITOR_SVG_DEFAULT_COLOR = '#606060' // same as ${COLORS.text.secondary} on background
const HTML_EDITOR_SVG_ACTIVE_COLOR = '#1F1F1F' // same as ${COLORS.text.primary} on background

const ReactQuillStyled = styled(ReactQuill)<MessageKeyProps>`
  box-shadow: inset 0 -1px 0 ${({ theme }) => theme.colors.text.disabled};
  a {
    color: ${({ theme }) => theme.colors.secondary.dark};
  }
  &:focus-within {
    box-shadow: inset 0px -2px 0px ${({ theme }) => theme.colors.primary.main};
  }
  .ql-toolbar {
    background-color: ${({ theme }) => theme.colors.action.hover};
    border: none;
    margin-bottom: 2px;
    padding: ${({ theme }) => theme.spacing(1)};
    border-radius: 4px 4px 0 0;
    & > span.ql-formats {
      margin-right: 0;
      & > button {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 40px;
        width: 40px;
        padding: ${({ theme }) => theme.spacing(1)};
        margin-right: ${({ theme }) => theme.spacing(1)};
        border-radius: 4px;
        .ql-fill {
          fill: ${HTML_EDITOR_SVG_DEFAULT_COLOR};
        }
        .ql-stroke {
          stroke: ${HTML_EDITOR_SVG_DEFAULT_COLOR};
        }
        &:hover,
        &:focus,
        &.ql-active {
          background-color: ${({ theme }) => theme.colors.action.selected};
          .ql-fill {
            fill: ${HTML_EDITOR_SVG_ACTIVE_COLOR};
          }
          .ql-stroke {
            stroke: ${HTML_EDITOR_SVG_ACTIVE_COLOR};
          }
        }
        & > svg {
          height: 18px;
        }
        &:focus-visible {
          ${STYLED_FOCUS_OUTLINE};
        }
        // Tooltip for keyboard shortcuts
        &::before {
          content: '';
          position: absolute;
          white-space: pre;
          top: 12px;
          transform: translateY(-50%);
          z-index: 100;
          padding: ${({ theme }) => theme.spacing(0.5)} ${({ theme }) => theme.spacing(1)};
          border-radius: 4px;
          background: ${({ theme }) => theme.colors.text.primary};
          color: ${({ theme }) => theme.colors.common.white};
          display: none;
          ${TYPOGRAPHY_MIXIN.caption};
          font-family: ${FONT_FAMILY};
        }
        &::after {
          content: '';
          position: absolute;
          top: 29px;
          transform: translateY(-50%);
          border-width: 6px;
          border-style: solid;
          border-color: ${({ theme }) => theme.colors.text.primary} transparent transparent transparent;
          display: none;
        }
        &:hover::before,
        &:hover::after {
          display: inline-block;
          animation-name: tooltip;
          animation-duration: 2s;
        }
        @keyframes tooltip {
          0% {
            opacity: 0;
          }
          70% {
            opacity: 0;
          }
          100% {
            opacity: 1;
          }
        }
        // Messages for individual toolbar buttons
        &.ql-bold::before {
          content: '${(props) => props.boldTooltip}';
        }
        &.ql-italic::before {
          content: '${(props) => props.italicTooltip}';
        }
        &.ql-link::before {
          content: '${(props) => props.linkTooltip}';
        }
        &.ql-list[value='ordered']::before {
          content: '${(props) => props.orderedListTooltip}';
        }
        &.ql-list[value='bullet']::before {
          content: '${(props) => props.bulletedListTooltip}';
        }
        &.ql-clean::before {
          content: '${(props) => props.clearTooltip}';
        }
      }
    }
  }
  .ql-container {
    border: none;
    .ql-editor {
      background-color: ${({ theme }) => theme.colors.action.hover};
      min-height: 10rem;
      font-family: ${FONT_FAMILY};
      padding: ${({ theme }) => theme.spacing(2)};
      ${TYPOGRAPHY_MIXIN.body1};
      &:hover {
        background-color: ${({ theme }) => theme.colors.action.selected};
        box-shadow: inset 0 -1px 0 ${({ theme }) => theme.colors.text.primary};
      }
      &:focus {
        background-color: ${({ theme }) => theme.colors.action.hover};
        box-shadow: inset 0 -2px 0 ${({ theme }) => theme.colors.primary.main};
      }
      ul,
      ol,
      li {
        padding-left: ${({ theme }) => theme.spacing(2)};
      }
      a {
        text-decoration: none;
        &:hover {
          text-decoration: underline;
        }
      }
      &.ql-blank::before {
        color: ${({ theme }) => theme.colors.text.disabled};
        font-style: normal;
        left: ${({ theme }) => theme.spacing(2)};
      }
    }
  }
  .ql-tooltip {
    ${TYPOGRAPHY_MIXIN.body2};
    font-family: ${FONT_FAMILY};
    border: none;
    box-shadow: ${({ theme }) => theme.shadows[0]};
    height: 40px;
    border-radius: 4px;
    padding: ${({ theme }) => theme.spacing(0.5)};
    padding-left: ${({ theme }) => theme.spacing(1.5)};
    left: 152px !important;
    top: -60px !important;
    input[type='text'] {
      color: ${({ theme }) => theme.colors.text.primary};
      background-color: transparent;
      height: 28px;
      width: 320px;
      border: none;
      box-shadow: inset 0px -1px 0px ${({ theme }) => theme.colors.text.disabled};
      font-family: ${FONT_FAMILY};
      ${TYPOGRAPHY_MIXIN.body2};
      padding: 4px 0;
      &:focus-visible {
        outline: none;
      }
      &:hover {
        box-shadow: inset 0px -2px 0px ${({ theme }) => theme.colors.text.primary};
      }
    }
    a.ql-action {
      display: inline-block;
      line-height: 32px;
      margin-left: ${({ theme }) => theme.spacing(2)};
      &:focus-visible {
        background-color: ${({ theme }) => theme.colors.primary.light};
        outline-offset: 0;
        border-radius: 4px;
      }
    }
    a.ql-preview {
      ${TYPOGRAPHY_MIXIN.subtitle2};
      max-width: 320px;
      line-height: 32px;
      &:hover {
        text-decoration: underline;
      }
    }
  }
  .ql-tooltip.ql-editing::before {
    line-height: 32px;
    content: '${(props) => props.enterLinkLabel}:';
    color: ${({ theme }) => theme.colors.text.secondary};
  }
  .ql-tooltip::before {
    line-height: 32px;
    content: '${(props) => props.visitUrlLabel}:';
    color: ${({ theme }) => theme.colors.text.secondary};
  }
  .ql-tooltip.ql-editing a.ql-action::after,
  .ql-tooltip a.ql-action::after,
  .ql-tooltip a.ql-remove::before {
    padding: ${({ theme }) => theme.spacing(1)};
    color: ${({ theme }) => theme.colors.primary.main};
    border-radius: 4px;
    height: 32px;
  }
  .ql-tooltip.ql-editing a.ql-action:hover::after,
  .ql-tooltip a.ql-action:hover::after,
  .ql-tooltip a.ql-remove:hover::before {
    background-color: ${({ theme }) => theme.colors.primary.light};
    color: ${({ theme }) => theme.colors.primary.dark};
  }
  .ql-tooltip.ql-editing a.ql-action {
    &::after {
      content: '${(props) => props.saveButtonLabel}';
      ${TYPOGRAPHY_MIXIN.button};
    }
  }
  .ql-tooltip a.ql-action {
    &::after {
      content: '${(props) => props.editButtonLabel}';
      margin: 0;
      border: none;
      ${TYPOGRAPHY_MIXIN.button};
    }
  }
  .ql-tooltip a.ql-remove {
    display: inline-block;
    margin-left: ${({ theme }) => theme.spacing(1)};
    line-height: 32px;
    &::before {
      content: '${(props) => props.removeButtonLabel}';
      margin-left: 0;
      ${TYPOGRAPHY_MIXIN.button};
    }
    &:focus-visible {
      background-color: ${({ theme }) => theme.colors.primary.light};
      outline-offset: 0;
      border-radius: 4px;
    }
  }
  input::-webkit-input-placeholder {
    color: transparent;
  }
  input:-moz-placeholder {
    color: transparent;
  }
  input::-moz-placeholder {
    color: transparent;
  }
  input:-ms-input-placeholder {
    color: transparent;
  }
`

const FormControlStyled = styled(FormControl)`
  ${({ error }) =>
    error &&
    css`
      .quill,
      .ql-container .ql-editor:hover,
      .ql-container .ql-editor:focus {
        box-shadow: inset 0px -2px 0px ${({ theme }) => theme.colors.error.main};
      }
    `}
`

function onLinkActionButtonClick(this: HTMLElement, e: KeyboardEvent) {
  if (['Enter', ' '].includes(e.key)) {
    e.stopPropagation()
    e.preventDefault()
    const target = e.target as HTMLAnchorElement
    target?.click()
  }
}

export const HtmlEditorField = (props: HtmlEditorFieldProps): ReactElement => {
  const { name, validate, required, label } = props
  const { getMessage } = useMessageSource()

  const { setDirty } = useContext(DirtyContext)
  const [changeByUser, setChangeByUser] = useState(false)

  const { onBlur } = useField(name)
  const quillRef = useRef<any>(null)
  useEffect(() => {
    const editor: any = quillRef.current?.editor
    let all: HTMLElement[] = []
    if (editor) {
      editor.root.setAttribute('spellcheck', 'false')

      // Quill renders the buttons in the ql.tooltip as links (<a> tags) without href attribute
      // Therefore they do not receive focus and do not participate in sequential keyboard navigation
      // To improve this, we manually get these elements' CSS classes and specify tabindex attribute
      const actions = editor.container.querySelectorAll('.ql-tooltip a.ql-action')
      const removes = editor.container.querySelectorAll('.ql-tooltip a.ql-remove')
      all = [...actions, ...removes]
      all.forEach((e: HTMLElement) => {
        e.setAttribute('tabindex', '0')
        e.addEventListener('keypress', onLinkActionButtonClick)
      })

      // remove tabbing functionality from the editor
      // in order to preserve ease of use when tabbing in forms through different elements
      delete editor.keyboard.bindings['9']
      editor.root.addEventListener('blur', onBlur)
    }
    return () => {
      editor?.root?.removeEventListener('blur', onBlur)
      all.forEach((e: HTMLElement) => {
        e.removeEventListener('keypress', onLinkActionButtonClick)
      })
    }
  }, [onBlur])

  const operatingSystem = window.navigator.userAgent.includes('Mac') ? 'Mac' : 'Windows'

  return (
    <Field
      name={name}
      validate={validate}
      render={({ input: { value, onChange, onBlur, onFocus }, meta: { error: errorObject, touched, dirty } }) => {
        const error = errorObject && getMessage(errorObject.errorKey, errorObject.params)
        const invalid = Boolean(touched && error)

        // Setting the DirtyContext to true only if the changes are coming from the user
        if (dirty && changeByUser) {
          setDirty(dirty)
        }

        const localOnChange = (html: string, _: any, source: any) => {
          // if the field has any value and the user deletes the content via BACKSPACE
          // quill will report the change as <p><br></p> -> we treat that value as an empty string

          // If the source is user then we control the dirty status
          if (source === 'user') {
            setChangeByUser(true)
          }
          if (html === '<p><br></p>') {
            onChange('')
          } else {
            onChange(html)
          }
        }

        // Toolbar button tooltip messages with keyboard shortcuts depending on the Operating System
        const messageBold = getMessage('tooltip.toolbar.bold')
        const messageItalic = getMessage('tooltip.toolbar.italic')
        const messageLink = getMessage('tooltip.toolbar.link')
        const shortcutBoldMac = '(\u2318 B)'
        const shortcutBoldWin = '(Ctrl + B)'
        const shortcutItalicMac = '(\u2318 I)'
        const shortcutItalicWin = '(Ctrl + I)'
        const shortcutLinkMac = '(\u2318 K)'
        const shortcutLinkWin = '(Ctrl + K)'
        const boldTooltip = messageBold + ' ' + (operatingSystem === 'Mac' ? shortcutBoldMac : shortcutBoldWin)
        const italicTooltip = messageItalic + ' ' + (operatingSystem === 'Mac' ? shortcutItalicMac : shortcutItalicWin)
        const linkTooltip = messageLink + ' ' + (operatingSystem === 'Mac' ? shortcutLinkMac : shortcutLinkWin)

        return (
          <FormControlStyled error={invalid} data-custom-input="html-editor" data-name={name} fullWidth>
            <FormLabel required={required} sx={{ mb: 0.5 }}>
              {label}
            </FormLabel>
            <ReactQuillStyled
              value={value}
              onChange={localOnChange}
              onFocus={onFocus as any}
              onBlur={onBlur as any}
              ref={quillRef}
              formats={QUILL_FORMATS}
              modules={QUILL_MODULES}
              placeholder={getMessage('label.html.editor.start.typing')}
              enterLinkLabel={getMessage('label.html.editor.enter.link')}
              visitUrlLabel={getMessage('label.html.editor.visit.url')}
              saveButtonLabel={getMessage('button.save')}
              editButtonLabel={getMessage('button.edit')}
              removeButtonLabel={getMessage('button.remove')}
              boldTooltip={boldTooltip}
              italicTooltip={italicTooltip}
              linkTooltip={linkTooltip}
              orderedListTooltip={getMessage('tooltip.toolbar.list.ordered')}
              bulletedListTooltip={getMessage('tooltip.toolbar.list.bulleted')}
              clearTooltip={getMessage('tooltip.toolbar.clear')}
            />
            <FormHelperText error>{(invalid && error) || ' '}</FormHelperText>
          </FormControlStyled>
        )
      }}
    />
  )
}
