import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import combinedQuery from 'graphql-combine-query'
import { ReactElement, useCallback, useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router'
import {
  Dossier,
  Feature_Type_Config,
  ProjectValidationResponse,
  Project_Base_Bool_Exp,
  Query_Root,
} from 'src/@types/graphql'
import { useMessageSource } from 'src/i18n/useMessageSource'
import { resolveDetailsRoot } from 'src/routing/routing-utils'
import { queryActiveFundingRoundsByProcess } from 'src/screens/administration/round-management/round-management-queries'
import { ValidationListItemProps } from 'src/screens/shared/common/SummaryValidationUtils'
import { queryFeatureTypes } from 'src/screens/shared/feature/featureBaseQueries'
import {
  queryDossierAndProjectByProjectId,
  validateProject,
} from 'src/screens/shared/project/details/summary/projectSummaryQueries'
import { ProjectSummarySection } from 'src/screens/shared/project/details/summary/ProjectSummarySection'
import { WorkflowService } from 'src/service/axios/WorkflowService'
import { usePermissionsForProject } from 'src/service/security/PermissionHook'
import { WorkflowPermissionService } from 'src/service/security/WorkflowPermissionService'
import { EditButton } from 'src/shared/button/Buttons'
import { DOSSIER_STATUS, DOSSIER_STATUS_TYPE, PROJECT, PROJECT_TYPE } from 'src/shared/constants/constants'
import { NextState } from 'src/shared/constants/workflow-constants'
import { PageLayout } from 'src/shared/layout/PageLayout'
import { ScreenLayout } from 'src/shared/layout/ScreenLayout'
import { ProjectExportMenu } from 'src/shared/menu/ProjectExportMenu'
import { HelpAndInstructions } from 'src/shared/presentation/HelpAndInstructions'
import { HtmlRenderer } from 'src/shared/presentation/HtmlRenderer'
import { Section } from 'src/shared/presentation/Section'
import { useDelayedNavigate } from 'src/shared/utils/hooks/navigation-hooks'
import { useNotificationService } from 'src/shared/utils/NotificationService'
import { Utils } from 'src/shared/utils/Utils'
import { useClient } from 'urql'

interface Props {
  baseUrl: '/pf-kap' | '/pf-pgv'
}

export const ProjectSummaryPage = ({ baseUrl }: Props): ReactElement => {
  const { projectId } = useParams()
  const [projectSummary, setProjectSummary] = useState<Dossier>()
  const [validationViolations, setValidationViolations] = useState<ProjectValidationResponse[]>([])
  const [projectFeatureTypes, setProjectFeatureTypes] = useState<Feature_Type_Config[]>([])
  const { getMessage } = useMessageSource()
  const navigate = useDelayedNavigate()
  const urqlClient = useClient()
  const projectType = Utils.resolveProcess(baseUrl)
  const notificationService = useNotificationService()
  const process = Utils.resolveProcessToLowerCase(baseUrl)
  const [nextStates, setNextStates] = useState<null | NextState[]>(null)
  const [existsActiveFundingRound, setExistsActiveFundingRound] = useState<boolean>()

  const projectIdParsed = parseInt(projectId as string)

  const {
    loading,
    canEditCommentInSummary,
    canDeleteProject,
    metadata: { projectBaseId, userProjectRoles, userGlobalRoles },
    refetch: refetchPermissions,
  } = usePermissionsForProject(projectType, projectIdParsed)

  const fetchAndSetData = useCallback(async () => {
    const projectPfKapBaseWhereClause: Project_Base_Bool_Exp = {
      pf_kap_projects: {
        id: {
          _eq: projectIdParsed,
        },
      },
    }

    const projectPfPgvBaseWhereClause: Project_Base_Bool_Exp = {
      pf_pgv_projects: {
        id: {
          _eq: projectIdParsed,
        },
      },
    }

    const projectBaseQuery: Project_Base_Bool_Exp = {
      ...(projectType === PROJECT.PF_KAP && projectPfKapBaseWhereClause),
      ...(projectType === PROJECT.PF_PGV && projectPfPgvBaseWhereClause),
    }

    const combinedQueryResult = combinedQuery('ProjectDescriptionSummaryData')
      .add<
        { dossier: Query_Root['dossier'] },
        { projectBaseQuery: Project_Base_Bool_Exp; isPfKap: boolean; isPfPgv: boolean }
      >(queryDossierAndProjectByProjectId, {
        projectBaseQuery,
        isPfKap: projectType === PROJECT.PF_KAP,
        isPfPgv: projectType === PROJECT.PF_PGV,
      })
      .add<
        { validateProjectDescription: Query_Root['validateProjectDescription'] },
        { projectId: number; process: string }
      >(validateProject, {
        projectId: projectIdParsed,
        process: projectType as PROJECT_TYPE,
      })
      .add<{ feature_type_config: Query_Root['feature_type_config'] }>(queryFeatureTypes)

    const { document, variables } = combinedQueryResult

    const { data, error } = await urqlClient
      .query<{
        dossier: Query_Root['dossier']
        validateProjectDescription: Query_Root['validateProjectDescription']
        feature_type_config: Query_Root['feature_type_config']
      }>(document, variables)
      .toPromise()

    const nextStates = await WorkflowService.nextStates(data?.dossier?.[0]?.project_workflow_id ?? '')

    const nextStatesForUser = WorkflowPermissionService.nextStatesForUser(nextStates)(userGlobalRoles, userProjectRoles)

    setNextStates(nextStatesForUser)

    if (data?.dossier && data?.validateProjectDescription) {
      setProjectSummary(data.dossier[0])
      setValidationViolations(data.dossier[0].status === DOSSIER_STATUS.CANCELED ? [] : data.validateProjectDescription)
      setProjectFeatureTypes(data.feature_type_config)
    } else if (error) {
      notificationService.operationFailed()
    }
  }, [notificationService, projectIdParsed, projectType, urqlClient, userGlobalRoles, userProjectRoles])

  useEffect(() => {
    fetchAndSetData()
  }, [fetchAndSetData])

  useEffect(() => {
    const checkExistentActiveRound = async () => {
      const { data } = await urqlClient
        .query<{ funding_round: Query_Root['funding_round'] }>(queryActiveFundingRoundsByProcess, {
          process: projectType,
        })
        .toPromise()

      if (data?.funding_round) {
        const allActiveRounds = data.funding_round

        const projectApplicationType =
          projectType === PROJECT.PF_KAP
            ? projectSummary?.project_bases[0].pf_kap_projects[0].application_type.code
            : projectSummary?.project_bases[0].pf_pgv_projects[0].application_type.code

        const filteredActiveRounds = allActiveRounds.filter((it) =>
          it.application_types?.includes(projectApplicationType),
        )

        setExistsActiveFundingRound(filteredActiveRounds.length > 0)
      }
    }

    if (projectSummary) {
      checkExistentActiveRound()
    }
  }, [projectSummary, projectType, urqlClient])

  const handleEditComment = () => {
    navigate(resolveDetailsRoot(baseUrl).nested.ProjectSummaryCommentEdit.params({ projectId }))
  }

  const handleViolationClick = (validationItem: ValidationListItemProps) => {
    const route = validationItem.root ? validationItem.root : resolveDetailsRoot(baseUrl).params({ projectId })

    navigate(
      `${route}${validationItem.tab ? '/' + validationItem.tab : ''}${
        validationItem.section ? `#${validationItem.section}` : ''
      }`,
    )
  }

  const exportMenuRef = useRef<{ refetch: () => void }>({ refetch: () => {} })

  const lowerCaseProcess = Utils.resolveProcessToLowerCase(baseUrl)

  const getValidationCompleteMessage = () => {
    if (projectSummary?.status === DOSSIER_STATUS.CANCELED) {
      return getMessage('label.project.is.canceled')
    } else {
      return getMessage('label.project.summary.description.completion.check.complete')
    }
  }

  return (
    <ScreenLayout
      title={getMessage('label.navigation.project.details')}
      actions={<ProjectExportMenu process={projectType} entityId={projectBaseId as number} ref={exportMenuRef} />}
    >
      <PageLayout>
        <>
          {!loading && projectSummary && nextStates !== null && existsActiveFundingRound != undefined && (
            <>
              <ProjectSummarySection
                title={getMessage('label.project.summary.title')}
                status={projectSummary.status as DOSSIER_STATUS_TYPE}
                helpAndInstructions={
                  <HelpAndInstructions labelKey={`label.help.project.summary.${lowerCaseProcess}`} />
                }
                actionsCompleteMessage={getValidationCompleteMessage()}
                violations={validationViolations}
                noItemsToValidate={projectSummary.status === DOSSIER_STATUS.CANCELED}
                featureTypes={projectFeatureTypes}
                violationClickHandler={handleViolationClick}
                workflowId={projectSummary.project_workflow_id as string}
                nextStates={nextStates}
                userProjectRoles={userProjectRoles}
                userGlobalRoles={userGlobalRoles}
                baseUrl={baseUrl}
                refetchPageData={() => {
                  fetchAndSetData()
                  refetchPermissions()
                  exportMenuRef.current.refetch()
                }}
                projectId={projectIdParsed}
                process={projectType}
                existsActiveFundingRound={existsActiveFundingRound}
                canDeleteProject={canDeleteProject}
              />

              <Section
                title={getMessage('label.summary.comments')}
                helpAndInstructions={
                  <HelpAndInstructions labelKey={`label.help.project.summary.comments.${process}`} />
                }
                actionButton={<EditButton onClick={handleEditComment} hidden={!canEditCommentInSummary} />}
              >
                {projectSummary.project_bases[0].comment && (
                  <Card>
                    <CardContent>
                      <HtmlRenderer html={projectSummary.project_bases[0].comment} />
                    </CardContent>
                  </Card>
                )}
              </Section>
            </>
          )}
        </>
      </PageLayout>
    </ScreenLayout>
  )
}
