import { Typography } from '@mui/material'
import { useContext } from 'react'
import { useMessageSource } from 'src/i18n/useMessageSource'
import { ROUTES } from 'src/routing/routes'
import { ReportingKpisHeaderSection } from 'src/screens/reporting/index/ReportingKpisHeaderSection'
import { PermissionService } from 'src/service/security/PermissionService'
import { PROJECT } from 'src/shared/constants/constants'
import { PageLayout } from 'src/shared/layout/PageLayout'
import { ScreenLayout } from 'src/shared/layout/ScreenLayout'
import { HelpAndInstructions } from 'src/shared/presentation/HelpAndInstructions'
import { Section } from 'src/shared/presentation/Section'
import { S } from 'src/shared/styled/S'
import { useDelayedNavigate } from 'src/shared/utils/hooks/navigation-hooks'
import { UserContext } from 'src/user/UserContext'

interface ReportingCard {
  type: string
  route: string
  show: boolean
}

export const ReportingIndexPage = () => {
  const { getMessage } = useMessageSource()
  const navigate = useDelayedNavigate()

  const {
    user: { roles },
  } = useContext(UserContext)

  const { canViewKapReporting, canViewPfKapReporting, canViewPfPgvReporting } =
    PermissionService.permissionToViewNavLinkForReporting(roles)

  const reportingCards: ReportingCard[] = [
    {
      type: PROJECT.KAP,
      route: ROUTES.KapReportingRoot.params({}),
      show: canViewKapReporting,
    },
    {
      type: PROJECT.PF_KAP,
      route: ROUTES.PfKapReportingRoot.params({}),
      show: canViewPfKapReporting,
    },
    {
      type: PROJECT.PF_PGV,
      route: ROUTES.PfPgvReportingRoot.params({}),
      show: canViewPfPgvReporting,
    },
  ]

  return (
    <ScreenLayout
      title={getMessage('label.title.reporting.cockpit')}
      hasSecondLevelNavigation={false}
      fullWidthHeaderSection={<ReportingKpisHeaderSection />}
    >
      <PageLayout>
        <Section
          title={getMessage('label.title.reporting.selection')}
          helpAndInstructions={<HelpAndInstructions labelKey="label.help.reporting.selection" defaultExpansion />}
        >
          {reportingCards
            .filter((report) => report.show)
            .map((report) => (
              <S.Card.Container key={report.type}>
                <S.Card.Content
                  onClick={() => navigate(report.route)}
                  tabIndex={0}
                  onKeyDown={(event: { key: string }) => {
                    if (['Enter', ' '].includes(event.key)) {
                      navigate(report.route)
                    }
                  }}
                >
                  <Typography variant="subtitle2">{getMessage(`label.reporting.${report.type}`)}</Typography>
                </S.Card.Content>
              </S.Card.Container>
            ))}
        </Section>
      </PageLayout>
    </ScreenLayout>
  )
}
