import { Box, Button, Chip } from '@mui/material'
import { get } from 'lodash'
import { useEffect, useState } from 'react'
import { Feature_Config, Feature_Config_Bool_Exp, Query_Root } from 'src/@types/graphql'
import { useMessageSource } from 'src/i18n/useMessageSource'
import { FilterFieldWrapper } from 'src/screens/shared/common/filter-card/FilterFieldWrapper'
import { AddFeaturesModalDialog } from 'src/screens/shared/common/filter-card/filters/features/AddFeaturesModalDialog'
import { FeatureFilterItem, FeaturesFilterModel } from 'src/shared/constants/filter-constants'
import { FEATURE_OPERATOR, FEATURE_OPERATOR_TYPE } from 'src/shared/constants/reporting-constants'
import { AddCircleIcon } from 'src/shared/icons/Icons'
import { ModalDialog } from 'src/shared/modal-dialog/ModalDialog'
import { useModalCancel } from 'src/shared/utils/hooks/modal-hooks'
import { useUserLocale } from 'src/user/UserContext'
import styled from 'styled-components/macro'
import { gql, useClient } from 'urql'

interface Props {
  featureConfigWhere: Feature_Config_Bool_Exp
  setFeaturesInput: (v: FeaturesFilterModel) => void
  isReset?: boolean
  initialState?: FeaturesFilterModel
}

const BoxStyled = styled(Box)`
  .MuiChip-root {
    margin-block: ${({ theme }) => theme.spacing(0.25)};
    margin-right: ${({ theme }) => theme.spacing(0.5)};
  }

  .MuiButton-root {
    height: 32px;
  }
`

export const FeaturesFilter = ({ featureConfigWhere, setFeaturesInput, isReset, initialState }: Props) => {
  const { getMessage } = useMessageSource()
  const urqlClient = useClient()
  const language = useUserLocale()

  const [featureData, setFeatureData] = useState<Feature_Config[]>([])
  const [selectedFeatures, setSelectedFeatures] = useState<FeatureFilterItem[]>([])
  const [selectedOperator, setSelectedOperator] = useState<FEATURE_OPERATOR_TYPE>(FEATURE_OPERATOR.AND)
  const [addFeaturesModalOpen, setAddFeaturesModalOpen] = useState(false)

  const fetchFeatureConfigsQuery = gql`
    query fetchFeatureConfigs {
      feature_config {
        id
        names
      }
    }
  `

  useEffect(() => {
    if (initialState) {
      setSelectedFeatures(initialState?.selectedFeatures)
      setSelectedOperator(initialState?.withinFeatureTypeOperator)
    }
  }, [initialState])

  useEffect(() => {
    const fetchData = async () => {
      const { data } = await urqlClient
        .query<{ feature_config: Query_Root['feature_config'] }>(fetchFeatureConfigsQuery, undefined)
        .toPromise()

      if (data) {
        setFeatureData(data.feature_config)
      }
    }

    fetchData()
  }, [urqlClient, fetchFeatureConfigsQuery])

  useEffect(() => {
    setFeaturesInput({
      withinFeatureTypeOperator: selectedOperator,
      betweenFeatureTypesOperator: selectedOperator,
      selectedFeatures: selectedFeatures,
    })
  }, [selectedFeatures, selectedOperator, setFeaturesInput])

  const handleOpenAddFeaturesModal = () => {
    setAddFeaturesModalOpen(true)
  }

  const handleDelete = (featureToDelete: Feature_Config) => () => {
    setSelectedFeatures((features) => features.filter((feature) => feature.featureId !== featureToDelete.id))
  }

  const handleConfirmAddFeatures = (values: FeatureFilterItem[], operator: FEATURE_OPERATOR_TYPE) => {
    setSelectedFeatures(values)
    setSelectedOperator(operator)
    setAddFeaturesModalOpen(false)
  }

  // Re-rendering of initial values if search filter is reset
  useEffect(() => {
    if (isReset) {
      setSelectedFeatures([])
      setSelectedOperator(FEATURE_OPERATOR.AND)
    }
  }, [isReset])

  const handleCloseModal = useModalCancel(() => setAddFeaturesModalOpen(false))

  const resolveOperatorKey = (selectedOperator: FEATURE_OPERATOR_TYPE): string =>
    getMessage(`label.operator.${selectedOperator}`).toUpperCase()

  return (
    <>
      <FilterFieldWrapper label={getMessage('label.reporting.filter.features')}>
        <BoxStyled>
          {featureData
            .filter((feature) => selectedFeatures.some((selectedFeature) => feature.id === selectedFeature.featureId))
            .map((feature) => (
              <Chip
                key={feature.id}
                label={get(feature.names, language, '')}
                onDelete={handleDelete(feature)}
                color="primary"
              />
            ))}
          {selectedFeatures.length > 0 && <Chip label={resolveOperatorKey(selectedOperator)} color="primary" />}
          <Button variant="text" startIcon={<AddCircleIcon />} onClick={handleOpenAddFeaturesModal}>
            {getMessage('button.add')}
          </Button>
        </BoxStyled>
      </FilterFieldWrapper>
      <ModalDialog
        open={addFeaturesModalOpen}
        onClose={handleCloseModal}
        title={getMessage('label.select.features.modal.title')}
        maxWidth="md"
      >
        <AddFeaturesModalDialog
          onCancel={handleCloseModal}
          onConfirm={handleConfirmAddFeatures}
          featureConfigWhere={featureConfigWhere}
          selectedFeatures={selectedFeatures}
          selectedOperator={selectedOperator}
          isReset={isReset}
        />
      </ModalDialog>
    </>
  )
}
