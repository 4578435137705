import { gql } from '@urql/core'

export const queryKapProgramAndValidationsByProgramId = gql`
  query ($programId: Int!) {
    kap_program_by_pk(id: $programId) {
      id
      concept_comment
      dossier {
        id
        status
        project_workflow_id
      }
    }
    validateProgramDescription(programId: $programId) {
      validationViolationMessageKey
      programDescriptionTab
      section
      labelArguments
    }
  }
`

export const queryKapProgramComment = gql`
  query ($programId: Int!) {
    kap_program_by_pk(id: $programId) {
      id
      concept_comment
    }
  }
`

export const updateComment = gql`
  mutation ($programId: Int!, $comment: String) {
    update_kap_program(where: { id: { _eq: $programId } }, _set: { concept_comment: $comment }) {
      affected_rows
      returning {
        id
      }
    }
  }
`
