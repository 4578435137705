import { Avatar, Divider, ListItemIcon, ListItemText, Menu, MenuItem, Tab, Tabs, Typography } from '@mui/material'
import IconButton from '@mui/material/IconButton'
import Toolbar from '@mui/material/Toolbar'
import React, { ReactElement, useContext, useEffect, useState } from 'react'
import { Route, Routes, useLocation } from 'react-router'
import { Link, useSearchParams } from 'react-router-dom'
import { SupportedLocale } from 'src/@types'
import { ReactComponent as Logo } from 'src/assets/logo-gfs.svg'
import { useEnvironment } from 'src/env/EnvironmentStore'
import { useMessageSource } from 'src/i18n/useMessageSource'
import { ROUTES } from 'src/routing/routes'
import { usePermissionsForSuccessFactors } from 'src/service/security/PermissionHook'
import { PermissionService } from 'src/service/security/PermissionService'
import { BASE_URL, PROJECT } from 'src/shared/constants/constants'
import { ENVIRONMENT } from 'src/shared/constants/environment-constants'
import { TYPOGRAPHY_MIXIN } from 'src/shared/constants/styling-constants'
import {
  AccountIcon,
  CheckIcon,
  EvaluationSuccessIcon,
  ExpandMoreIcon,
  FactsheetIcon,
  LanguageIcon,
  LoginIcon,
  LogoutIcon,
  ReportIcon,
  SettingsIcon,
} from 'src/shared/icons/Icons'
import { useDelayedNavigate, useNavigateToLogin, useNavigateToLogout } from 'src/shared/utils/hooks/navigation-hooks'
import { UserContext } from 'src/user/UserContext'
import styled from 'styled-components/macro'
import { S } from '../styled/S'

export const APPLICATION_TOOLBAR_HEIGHT = '65px'

const LogoWrapperStyled = styled('div')`
  flex-grow: 1;
  height: ${APPLICATION_TOOLBAR_HEIGHT};

  &,
  a {
    display: flex;
    align-items: center;
  }

  svg {
    height: 60px;
    width: 200px;
  }
`

const AccountMenu = styled(Menu)`
  & .MuiListItemText-inset {
    padding-left: ${({ theme }) => theme.spacing(5)};
  }
`

const ListItemIconStyled = styled(ListItemIcon)`
  &.MuiListItemIcon-root {
    min-width: ${({ theme }) => theme.spacing(5)};
  }
`

const TabStyled = styled(Tab)`
  height: ${APPLICATION_TOOLBAR_HEIGHT};
  margin-left: ${({ theme }) => theme.spacing(4)};
  padding-left: 0;
  padding-right: 0;
  letter-spacing: ${({ theme }) => theme.typography.button.letterSpacing};

  &:hover {
    background-color: transparent;
  }

  &.MuiTab-labelIcon {
    min-height: auto;
  }

  & .MuiSvgIcon-root {
    margin-left: ${({ theme }) => theme.spacing(0.5)};
  }

  &.MuiButtonBase-root {
    line-height: 100%;
  }
`

const InsightsMenu = styled(Menu)`
  & .MuiPaper-root {
    min-width: 15rem;
  }
`

const AvatarStyled = styled(Avatar)`
  background-color: ${({ theme }) => theme.colors.primary.main};
  ${TYPOGRAPHY_MIXIN.button};
`

export const ApplicationToolbar = (): ReactElement => {
  const [anchorElAccountMenu, setAnchorElAccountMenu] = useState<null | HTMLElement>(null)
  const [anchorElInsightsMenu, setAnchorElInsightsMenu] = useState<null | HTMLElement>(null)
  const openAccountMenu = Boolean(anchorElAccountMenu)
  const navigate = useDelayedNavigate()
  const logout = useNavigateToLogout()
  const { user } = useContext(UserContext)
  const { getMessage } = useMessageSource()
  const globalRoles = user.roles
  const { environment } = useEnvironment()
  const openInsightsMenu = Boolean(anchorElInsightsMenu)
  const { pathname } = useLocation()
  const [selectedLocale, setSelectedLocale] = useState<SupportedLocale>(user.language)
  const [searchParams, setSearchParams] = useSearchParams()
  const canViewAdministration = PermissionService.permissionToViewAdministrationLink(globalRoles)
  const { canViewReporting } = PermissionService.permissionToViewNavLinkForReporting(globalRoles)
  const { canView: canViewSuccessFactors } = usePermissionsForSuccessFactors()
  const login = useNavigateToLogin()

  const {
    changeLocale,
    user: { firstName, lastName, anonymous },
  } = useContext(UserContext)

  const successFactorsPublicSpace = anonymous && pathname.startsWith(BASE_URL.PUBLIC_SUCCESS_FACTOR_TOOL)

  useEffect(() => {
    const locale = searchParams.get('lang') as SupportedLocale

    if (locale && locale !== user.language) {
      changeLocale(locale)
      setSelectedLocale(locale)
    }
  }, [changeLocale, searchParams, user.language])

  const handleAccountMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElAccountMenu(event.currentTarget)
  }

  let value: string

  if (pathname.startsWith('/kap')) {
    value = '/kap'
  } else if (pathname.startsWith('/pf-pgv')) {
    value = '/pf-pgv'
  } else if (pathname.startsWith('/pf-kap')) {
    value = '/pf-kap'
  } else if (pathname.startsWith('/factsheets')) {
    value = '/factsheets'
  } else if (pathname.startsWith('/reporting')) {
    value = '/reporting'
  } else if (pathname.startsWith('/success-factors')) {
    value = '/success-factors'
  } else {
    value = '/'
  }

  const handleCloseAccountMenu = () => {
    setAnchorElAccountMenu(null)
  }

  const handleChangeLocale = (locale: SupportedLocale) => () => {
    const landingPage = `/${ROUTES.PublicLandingPage.path}`
    const sftLandingPage = `/${ROUTES.SuccessFactorsPublicLanding.path}`

    handleCloseAccountMenu()
    if (pathname === landingPage || pathname === sftLandingPage) {
      if (searchParams.has('lang')) {
        setSearchParams({ lang: locale })
      }
      if (searchParams.has('email') && searchParams.has('lang')) {
        setSearchParams({ email: searchParams.get('email') as string, lang: locale })
      }
    }
    changeLocale(locale)
    setSelectedLocale(locale)
  }

  const handleLogout = () => {
    handleCloseAccountMenu()
    logout()
  }

  const handleAdministrationClick = () => {
    handleCloseAccountMenu()
    navigate(ROUTES.RoundManagementIndex.path)
  }

  const handleInsightsMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElInsightsMenu(event.currentTarget)
  }

  const handleCloseInsightsMenu = () => {
    setAnchorElInsightsMenu(null)
  }

  const [selectedIndex, setSelectedIndex] = useState<number | null>(null)
  const handleInsightsMenuItemClick = (event: React.MouseEvent<HTMLElement, MouseEvent>, index: number, to: string) => {
    handleCloseInsightsMenu()
    setSelectedIndex(index)
    navigate(to)
  }

  return (
    <>
      <Toolbar>
        <LogoWrapperStyled>
          <Routes>
            <Route
              path="/*"
              element={
                <Link to="/">
                  <Logo />
                </Link>
              }
            />
            {/* Logo not to be clickable and users not to be directed to an error page */}
            <Route path="/pub/*" element={<Logo />} />
          </Routes>
        </LogoWrapperStyled>

        {!anonymous && (
          <>
            <Tabs sx={{ marginRight: 3 }} value={value} variant="fullWidth" textColor="inherit">
              {/* Using this hidden tab to resolve the error from the console  */}
              <TabStyled value="/" sx={{ visibility: 'hidden' }} />
              {PermissionService.permissionToViewNavLink(PROJECT.KAP, globalRoles) && (
                <TabStyled
                  label={getMessage('label.navigation.kap')}
                  value="/kap"
                  onClick={() => navigate(`/${ROUTES.KapIndex.path}`)}
                />
              )}

              {PermissionService.permissionToViewNavLink(PROJECT.PF_KAP, globalRoles) && (
                <TabStyled
                  label={getMessage('label.navigation.pf_kap')}
                  value="/pf-kap"
                  onClick={() => navigate(`/${ROUTES.PfKapIndex.path}`)}
                />
              )}

              {PermissionService.permissionToViewNavLink(PROJECT.PF_PGV, globalRoles) && (
                <TabStyled
                  label={getMessage('label.navigation.pf_pgv')}
                  value="/pf-pgv"
                  onClick={() => navigate(`/${ROUTES.PfPgvIndex.path}`)}
                />
              )}

              <TabStyled
                label={getMessage('label.navigation.insights')}
                value={
                  value === '/factsheets' || value === '/reporting' || value === '/success-factors' ? value : false
                }
                onClick={handleInsightsMenu}
                icon={<ExpandMoreIcon fontSize="small" />}
                iconPosition="end"
              />
            </Tabs>
            <InsightsMenu
              anchorEl={anchorElInsightsMenu}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
              transformOrigin={{ vertical: 'top', horizontal: 'right' }}
              open={openInsightsMenu}
              MenuListProps={{
                variant: 'menu',
              }}
              onClose={handleCloseInsightsMenu}
              elevation={4}
              keepMounted
              PaperProps={{
                sx: { mt: -1 },
              }}
            >
              {canViewSuccessFactors && (
                <MenuItem
                  selected={value === '/success-factors' ? selectedIndex === 0 : false}
                  onClick={(event) => handleInsightsMenuItemClick(event, 0, `/${ROUTES.SuccessFactorsIndex.path}`)}
                >
                  <ListItemIcon>
                    <EvaluationSuccessIcon />
                  </ListItemIcon>
                  <ListItemText>{getMessage('label.navigation.success.factors')}</ListItemText>
                </MenuItem>
              )}
              <MenuItem
                selected={value === '/factsheets' ? selectedIndex === 1 : false}
                onClick={(event) => handleInsightsMenuItemClick(event, 1, `/${ROUTES.FactsheetsIndex.path}`)}
              >
                <ListItemIcon>
                  <FactsheetIcon />
                </ListItemIcon>
                <ListItemText>{getMessage('label.navigation.factsheets')}</ListItemText>
              </MenuItem>
              {canViewReporting && (
                <MenuItem
                  selected={value === '/reporting' ? selectedIndex === 2 : false}
                  onClick={(event) => handleInsightsMenuItemClick(event, 2, `/${ROUTES.ReportingIndex.path}`)}
                >
                  <ListItemIcon>
                    <ReportIcon />
                  </ListItemIcon>
                  <ListItemText>{getMessage('label.navigation.reports')}</ListItemText>
                </MenuItem>
              )}
            </InsightsMenu>
            <IconButton onClick={handleAccountMenu} size="small">
              <AvatarStyled>{`${firstName[0].toUpperCase()}${lastName[0].toUpperCase()}`}</AvatarStyled>
            </IconButton>
          </>
        )}

        {anonymous && (
          <IconButton onClick={handleAccountMenu} size="small">
            {pathname === BASE_URL.PUBLIC_LANDING ? (
              <LanguageIcon />
            ) : (
              <AvatarStyled>
                <AccountIcon />
              </AvatarStyled>
            )}
          </IconButton>
        )}

        <AccountMenu
          anchorEl={anchorElAccountMenu}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          keepMounted
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
          open={openAccountMenu}
          MenuListProps={{
            variant: 'menu',
          }}
          onClose={handleCloseAccountMenu}
          elevation={4}
          PaperProps={{
            sx: {
              minWidth: anonymous ? '10rem' : '20rem',
            },
          }}
        >
          {!anonymous && (
            <MenuItem
              sx={{
                paddingLeft: 1.5,
                '&:hover, &:focus-visible': { backgroundColor: 'transparent', cursor: 'default' },
              }}
            >
              <AvatarStyled
                sx={{
                  marginRight: 1.5,
                  height: '2rem',
                  width: '2rem',
                }}
              >{`${firstName[0].toUpperCase()}${lastName[0].toUpperCase()}`}</AvatarStyled>
              <S.DataGrid.TwoLineCell>
                <Typography>{`${firstName} ${lastName}`}</Typography>
                <Typography variant="caption" color="textSecondary">
                  {user.email}
                </Typography>
              </S.DataGrid.TwoLineCell>
            </MenuItem>
          )}
          {!anonymous && <Divider />}
          {!anonymous && canViewAdministration && (
            <MenuItem onClick={handleAdministrationClick}>
              <ListItemIconStyled>
                <SettingsIcon />
              </ListItemIconStyled>
              <ListItemText>{getMessage('label.administration')}</ListItemText>
            </MenuItem>
          )}
          {!anonymous && canViewAdministration && <Divider />}
          {successFactorsPublicSpace && (
            <MenuItem onClick={login}>
              <ListItemIconStyled>
                <LoginIcon />
              </ListItemIconStyled>
              <ListItemText>{getMessage('button.login')}</ListItemText>
            </MenuItem>
          )}
          {successFactorsPublicSpace && <Divider />}
          <MenuItem onClick={handleChangeLocale('de')}>
            {selectedLocale === 'de' && (
              <ListItemIconStyled>
                <CheckIcon />
              </ListItemIconStyled>
            )}
            <ListItemText inset={!(selectedLocale === 'de')}>Deutsch</ListItemText>
          </MenuItem>
          <MenuItem onClick={handleChangeLocale('fr')}>
            {selectedLocale === 'fr' && (
              <ListItemIconStyled>
                <CheckIcon />
              </ListItemIconStyled>
            )}
            <ListItemText inset={!(selectedLocale === 'fr')}>Français</ListItemText>
          </MenuItem>
          {!successFactorsPublicSpace && (
            <MenuItem onClick={handleChangeLocale('it')}>
              {selectedLocale === 'it' && (
                <ListItemIconStyled>
                  <CheckIcon />
                </ListItemIconStyled>
              )}
              <ListItemText inset={!(selectedLocale === 'it')}>Italiano</ListItemText>
            </MenuItem>
          )}
          {environment === ENVIRONMENT.DEV && (
            <MenuItem onClick={handleChangeLocale('en')}>
              {selectedLocale === 'en' && (
                <ListItemIconStyled>
                  <CheckIcon />
                </ListItemIconStyled>
              )}
              <ListItemText inset={!(selectedLocale === 'en')}>English</ListItemText>
            </MenuItem>
          )}
          {!anonymous && <Divider />}
          {!anonymous && (
            <MenuItem onClick={handleLogout}>
              <ListItemIconStyled>
                <LogoutIcon />
              </ListItemIconStyled>
              <ListItemText>Logout</ListItemText>
            </MenuItem>
          )}
        </AccountMenu>
      </Toolbar>
    </>
  )
}
