import { Card, CardContent, Stack, Typography } from '@mui/material'
import { ReactElement, ReactNode } from 'react'
import { ReactComponent as ReportingNoSearchResultsIllustration } from 'src/assets/reporting-no-search-results-illustration.svg'
import { useMessageSource } from 'src/i18n/useMessageSource'
import { GoToTopIcon } from 'src/shared/icons/Icons'
import { HelpAndInstructions } from 'src/shared/presentation/HelpAndInstructions'
import { Section } from 'src/shared/presentation/Section'
import styled from 'styled-components/macro'

const CardStyled = styled(Card)`
  padding: ${({ theme }) => theme.spacing(2)};

  h3 {
    text-align: center;
    color: ${({ theme }) => theme.colors.text.disabled};
    margin-bottom: ${({ theme }) => theme.spacing(4)};
  }

  svg {
    max-width: 560px;
  }

  .MuiCardContent-root {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`

export const ReportingSearchResultsSection = ({ children }: { children: ReactNode }): ReactElement => {
  const { getMessage } = useMessageSource()

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }

  return (
    <Section
      id="search-results"
      title={getMessage('label.reporting.search.results')}
      helpAndInstructions={<HelpAndInstructions labelKey="label.help.reporting.search.results" />}
    >
      {children ? (
        <>
          <Stack spacing={1} marginBottom={1}>
            {children}
          </Stack>
          <Stack direction="row" justifyContent="end">
            <GoToTopIcon fontSize="large" color="primary" sx={{ cursor: 'pointer' }} onClick={scrollToTop} />
          </Stack>
        </>
      ) : (
        <CardStyled>
          <CardContent>
            <Typography variant="h3">{getMessage('label.reporting.no.search.results')}</Typography>
            <ReportingNoSearchResultsIllustration height="25rem" width="25rem" />
          </CardContent>
        </CardStyled>
      )}
    </Section>
  )
}
