import { DataGridPro } from '@mui/x-data-grid-pro'
import styled from 'styled-components/macro'

export const ScrollDataGridStyled = styled(DataGridPro)`
  & .MuiDataGrid-virtualScroller::-webkit-scrollbar {
    height: 0.5em;
  }

  & .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb {
    background-color: gray;
    border-radius: 10px;
  }

  & .MuiDataGrid-virtualScroller::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
`
