import { Button } from '@mui/material'
import { ReactElement, useCallback, useEffect, useState } from 'react'
import { Milestone, Project_Base_Bool_Exp, Query_Root } from 'src/@types/graphql'
import { useMessageSource } from 'src/i18n/useMessageSource'
import { MilestoneGrid } from 'src/screens/shared/implementation/details/milestone/MilestoneGrid'
import {
  fetchDossierIdByProjectIdQuery,
  getMilestonesQuery,
} from 'src/screens/shared/implementation/details/milestoneQueries'
import { DossierService } from 'src/service/axios/DossierService'
import { usePermissionsForProjectImplementation } from 'src/service/security/PermissionHook'
import { PROJECT, USER_STATUS } from 'src/shared/constants/constants'
import { MILESTONE_RESPONSIBLE_TYPE } from 'src/shared/constants/milestone-constants'
import { AddIcon } from 'src/shared/icons/Icons'
import { PageLayout } from 'src/shared/layout/PageLayout'
import { ScreenLayout } from 'src/shared/layout/ScreenLayout'
import { SecondaryConfirmationModalDialog } from 'src/shared/modal-dialog/SecondaryConfirmationModalDialog'
import { HelpAndInstructions } from 'src/shared/presentation/HelpAndInstructions'
import { Section } from 'src/shared/presentation/Section'
import { useNotificationService } from 'src/shared/utils/NotificationService'
import { Utils } from 'src/shared/utils/Utils'
import { useClient } from 'urql'

interface Props {
  baseUrl: '/pf-kap' | '/pf-pgv'
  projectId: number
}
export interface MilestoneViewModel {
  id: number
  type: string
  title: string
  end_date: Date
  status: string
  organization: string
  assignee: string | null
}

export const ProjectImplementationMilestone = ({ baseUrl, projectId }: Props): ReactElement => {
  const { getMessage } = useMessageSource()
  const process = Utils.resolveProcess(baseUrl)
  const urqlClient = useClient()
  const notificationService = useNotificationService()

  const {
    canManageMilestones,
    metadata: { projectBaseId },
  } = usePermissionsForProjectImplementation(process, projectId)

  const [milestones, setMilestones] = useState<MilestoneViewModel[]>([])
  const [addNewMilestoneSetConfirmationOpen, setAddNewMilestoneSetConfirmationOpen] = useState(false)
  const [addNewMilestoneSetLoading, setAddNewMilestoneSetLoading] = useState(false)

  const openAddNewMilestoneSetConfirmation = () => {
    setAddNewMilestoneSetConfirmationOpen(true)
  }

  const handleCancelAddNewMilestoneSet = () => {
    setAddNewMilestoneSetConfirmationOpen(false)
  }

  const handleConfirmAddNewMilestoneSet = async () => {
    setAddNewMilestoneSetLoading(true)

    const { data } = await urqlClient
      .query<{ dossier: Query_Root['dossier'] }>(fetchDossierIdByProjectIdQuery, {
        projectBaseId: projectBaseId,
      })
      .toPromise()

    if (data && data?.dossier) {
      const dossierId = data?.dossier[0]?.id

      DossierService.addSetOfMilestones(dossierId, process)
        .then(() => {
          setAddNewMilestoneSetConfirmationOpen(false)
          fetchData()
          notificationService.changesSaved()
        })
        .catch(() => {
          notificationService.operationFailed()
        })
        .finally(() => {
          setAddNewMilestoneSetLoading(false)
        })
    } else {
      notificationService.operationFailed()
    }
  }

  const mapMilestoneToMilestoneViewModel = useCallback(
    (data: Milestone[]): MilestoneViewModel[] => {
      const resolveAssignee = (milestone: Milestone, type: string) => {
        if (type === MILESTONE_RESPONSIBLE_TYPE.GFCH) {
          const responsible = milestone?.dossier?.project_bases?.[0]?.gfch_responsible
          return responsible ? `${responsible.first_name} ${responsible.last_name}` : null
        } else {
          const responsible = milestone?.dossier?.project_bases?.[0]?.user
          return responsible?.status === USER_STATUS.PENDING
            ? `${getMessage('label.user.unknown')}, ${responsible?.email}`
            : `${responsible?.first_name} ${responsible?.last_name}`
        }
      }

      return data.map((milestone) => ({
        id: milestone.id,
        type: milestone.type,
        title: `${getMessage(`label.milestone.type.${milestone.type}`)} ${milestone.year_in_focus ?? ''}`,
        end_date: milestone.due_date,
        status: milestone.status,
        organization: getMessage(`label.milestone.responsible.${milestone.responsible_type}`),
        assignee: resolveAssignee(milestone, milestone.responsible_type),
      }))
    },
    [getMessage],
  )

  const fetchData = useCallback(async () => {
    const pfKapWhereClause: Project_Base_Bool_Exp = {
      pf_kap_projects: { id: { _eq: projectId } },
    }

    const pfPgvWhereClause: Project_Base_Bool_Exp = {
      pf_pgv_projects: { id: { _eq: projectId } },
    }

    const { data } = await urqlClient
      .query<
        {
          milestone: Query_Root['milestone']
        },
        {
          projectBaseWhere: Project_Base_Bool_Exp
        }
      >(getMilestonesQuery, {
        projectBaseWhere: process === PROJECT.PF_KAP ? pfKapWhereClause : pfPgvWhereClause,
      })
      .toPromise()

    if (data && data?.milestone) {
      setMilestones(mapMilestoneToMilestoneViewModel(data?.milestone))
    } else {
      notificationService.operationFailed()
    }
  }, [urqlClient, notificationService, mapMilestoneToMilestoneViewModel, projectId, process])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  return (
    <ScreenLayout title={getMessage('label.title.project.implementation')}>
      <PageLayout>
        <Section
          id="milestone"
          title={getMessage('label.milestones')}
          helpAndInstructions={<HelpAndInstructions labelKey={`label.help.implementation.milestone.${process}`} />}
          actionButton={
            canManageMilestones && (
              <Button
                variant="text"
                color="primary"
                onClick={openAddNewMilestoneSetConfirmation}
                startIcon={<AddIcon />}
              >
                {getMessage('button.milestone.set.add')}
              </Button>
            )
          }
        >
          <MilestoneGrid milestones={milestones ?? []} baseUrl={baseUrl} projectId={projectId} />
        </Section>
        <SecondaryConfirmationModalDialog
          buttonColor="primary"
          open={addNewMilestoneSetConfirmationOpen}
          onCancel={handleCancelAddNewMilestoneSet}
          onConfirm={handleConfirmAddNewMilestoneSet}
          loading={addNewMilestoneSetLoading}
        >
          {getMessage('label.confirm.add.milestone.set')}
        </SecondaryConfirmationModalDialog>
      </PageLayout>
    </ScreenLayout>
  )
}
