import combinedQuery from 'graphql-combine-query'
import { Feature_Config_Bool_Exp, Project_Base_Bool_Exp, Query_Root } from 'src/@types/graphql'
import { ValidationListItemProps } from 'src/screens/shared/common/SummaryValidationUtils'
import { queryFeatureBaseValuesWithKPIs } from 'src/screens/shared/feature/featureBaseQueries'
import {
  getKapGoalsViolations,
  getKapMeasuresViolations,
  getProjectGoalsViolations,
  getProjectMeasuresViolations,
} from 'src/screens/shared/implementation/details/milestone/utils/AnnualReportValidationUtils'
import {
  queryFeatureKPIAnnualReportByMilestoneId,
  queryGoalsAndMeasuresAnnualReportRating,
  queryKapGoalsAnnualReportRatingAndReason,
  queryKapMeasuresAnnualReportValid,
} from 'src/screens/shared/implementation/details/milestoneQueries'
import { PROJECT, PROJECT_TYPE } from 'src/shared/constants/constants'
import { Client } from 'urql'

interface Props {
  milestoneId: number
  getMessage(key: string): string
  urqlClient: Client
}
export const getKapGoalRatingValidation = async ({
  milestoneId,
  getMessage,
  urqlClient,
}: Props): Promise<ValidationListItemProps[]> => {
  const violations: ValidationListItemProps[] = []

  const { data } = await urqlClient
    .query<{
      kap_program: Query_Root['kap_program']
    }>(queryKapGoalsAnnualReportRatingAndReason, { milestoneId })
    .toPromise()

  const goalsViolations = getKapGoalsViolations(data?.kap_program[0].kap_goals)

  if (goalsViolations) {
    const violation = {
      message: getMessage('validation.milestone.kap.annual.report.fill.out.goals.rating'),
      tab: 'goal-rating',
      section: 'goals',
    }
    violations.push(violation)
  }
  return violations
}

export const getKapMeasureRatingValidation = async ({
  milestoneId,
  getMessage,
  urqlClient,
}: Props): Promise<ValidationListItemProps[]> => {
  const violations: ValidationListItemProps[] = []
  const { data } = await urqlClient
    .query<{
      kap_program: Query_Root['kap_program']
    }>(queryKapMeasuresAnnualReportValid, { milestoneId })
    .toPromise()
  const measuresViolations = getKapMeasuresViolations(data?.kap_program[0].kap_measures)

  if (measuresViolations) {
    const violation = {
      message: getMessage('validation.milestone.kap.annual.report.fill.out.measures.rating'),
      tab: 'measure-rating',
      section: 'measures',
    }
    violations.push(violation)
  }
  return violations
}

export const getProjectGoalMeasureRatingValidation = async ({
  milestoneId,
  getMessage,
  urqlClient,
}: Props): Promise<ValidationListItemProps[]> => {
  const violations: ValidationListItemProps[] = []
  const { data } = await urqlClient
    .query<{
      project_base: Query_Root['project_base']
    }>(queryGoalsAndMeasuresAnnualReportRating, { milestoneId })
    .toPromise()

  const goalsViolations = getProjectGoalsViolations(data?.project_base[0].project_goals, milestoneId)

  if (goalsViolations) {
    const violation = {
      message: getMessage('validation.milestone.annual.report.fill.out.goals.rating'),
      tab: 'goals-and-measures',
      section: 'goals',
    }
    violations.push(violation)
  }

  const measuresViolations = getProjectMeasuresViolations(data?.project_base[0].project_measures, milestoneId)

  if (measuresViolations) {
    const violation = {
      message: getMessage('validation.milestone.annual.report.fill.out.measures.rating'),
      tab: 'goals-and-measures',
      section: 'measures',
    }
    violations.push(violation)
  }
  return violations
}

export const getFeatureKpiValidation = async ({
  milestoneId,
  getMessage,
  urqlClient,
  projectId,
  processType,
}: Props & { projectId: number; processType: PROJECT_TYPE }): Promise<ValidationListItemProps[]> => {
  const violations: ValidationListItemProps[] = []
  const pfKapQuery: Project_Base_Bool_Exp = {
    pf_kap_projects: {
      id: {
        _eq: projectId,
      },
    },
  }

  const pfPgvQuery: Project_Base_Bool_Exp = {
    pf_pgv_projects: {
      id: {
        _eq: projectId,
      },
    },
  }

  const featureConfigKPIWhere: Feature_Config_Bool_Exp = {
    has_kpi_for_processes: {
      _contains: [processType],
    },
  }

  const { document, variables } = combinedQuery('FeatureBaseValuesWithKPIsAndFeatureKPIAnnualReport')
    .add<
      {
        feature_base: Query_Root['feature_base']
      },
      {
        projectBaseQuery: Project_Base_Bool_Exp
        featureConfigKPIWhere: Feature_Config_Bool_Exp
      }
    >(queryFeatureBaseValuesWithKPIs, {
      projectBaseQuery: processType === PROJECT.PF_KAP ? pfKapQuery : pfPgvQuery,
      featureConfigKPIWhere: featureConfigKPIWhere,
    })
    .add<{ feature_kpi_annual_report: Query_Root['feature_kpi_annual_report'] }, { milestoneId: number }>(
      queryFeatureKPIAnnualReportByMilestoneId,
      { milestoneId },
    )

  const { data } = await urqlClient
    .query<
      {
        feature_base: Query_Root['feature_base']
        feature_kpi_annual_report: Query_Root['feature_kpi_annual_report']
      },
      {
        projectBaseQuery: Project_Base_Bool_Exp
        milestoneId: number
      }
    >(document, variables!)
    .toPromise()

  if (data) {
    const features = data?.feature_base[0].feature_values
    const reports = data.feature_kpi_annual_report
    const hasUncompleted = features.some((feature) => {
      const filteredReport = reports.find((report) => report.feature_config_id === feature.feature_config_id)
      return filteredReport === null || filteredReport === undefined || filteredReport.annual_value === null
    })
    if (hasUncompleted) {
      const violation = {
        message: getMessage('validation.milestone.annual.report.fill.out.feature.kpi'),
        tab: 'kpi',
      }
      violations.push(violation)
    }
  }

  return violations
}
