import { Card, CardContent, IconButton, Typography } from '@mui/material'
import Box from '@mui/material/Box'
import { Fragment, ReactElement, useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { Kap_Program, Maybe, Mutation_Root, Query_Root, User } from 'src/@types/graphql'
import { useMessageSource } from 'src/i18n/useMessageSource'
import { ROUTES } from 'src/routing/routes'
import {
  deleteKapProgramOrganizationQuery,
  fetchKapProgramOrganizationResponsibilityQuery,
  fetchUserProgramResponsibilityQuery,
} from 'src/screens/kap/program/details/organization/kapOrganizationQueries'
import { usePermissionsForKapProgram } from 'src/service/security/PermissionHook'
import { AddButton } from 'src/shared/button/Buttons'
import { ENTITY_TYPE, FILE_TYPE, KAP_ORGANIZATION_TYPE, USER_TYPE } from 'src/shared/constants/constants'
import { DeleteIcon, EditIcon } from 'src/shared/icons/Icons'
import { PageLayout } from 'src/shared/layout/PageLayout'
import { ScreenLayout } from 'src/shared/layout/ScreenLayout'
import { ProgramExportMenu } from 'src/shared/menu/ProgramExportMenu'
import { ConfirmationModalDialog } from 'src/shared/modal-dialog/ConfirmationModalDialog'
import { DefaultSectionTypography } from 'src/shared/presentation/DefaultSectionTypography'
import { FileSection } from 'src/shared/presentation/FileSection'
import { HelpAndInstructions } from 'src/shared/presentation/HelpAndInstructions'
import { ReadOnlyTextField } from 'src/shared/presentation/ReadOnly'
import { Section } from 'src/shared/presentation/Section'
import { S } from 'src/shared/styled/S'
import { useDelayedNavigate } from 'src/shared/utils/hooks/navigation-hooks'
import { useNotificationService } from 'src/shared/utils/NotificationService'
import { Utils } from 'src/shared/utils/Utils'
import { useClient } from 'urql'

export const KapOrganizationPage = (): ReactElement => {
  const { programId } = useParams()
  const { getMessage } = useMessageSource()

  const navigate = useDelayedNavigate()
  const urqlClient = useClient()
  const notificationService = useNotificationService()

  const program_id = parseInt(programId as string)

  const [kapProgram, setKapProgram] = useState<Maybe<Kap_Program>>()
  const [strategicResponsibles, setStrategicResponsibles] = useState<User[]>([])
  const [operationalResponsibles, setOperationalResponsibles] = useState<User[]>([])
  const [gfchResponsibles, setGfchResponsibles] = useState<User[]>([])

  const [organizationDeleteConfirmation, setOrganizationDeleteConfirmation] = useState(false)
  const [organizationIdToBeDeleted, setOrganizationIdToBeDeleted] = useState<number>()

  const { loading, canEdit, canViewCantonRestricted } = usePermissionsForKapProgram(program_id)

  const fetchData = useCallback(async () => {
    const { data } = await urqlClient
      .query<{
        kap_program_by_pk: Query_Root['kap_program_by_pk']
      }>(fetchKapProgramOrganizationResponsibilityQuery, {
        id: program_id,
      })
      .toPromise()

    if (data) {
      setKapProgram(data.kap_program_by_pk)
    } else {
      notificationService.operationFailed()
    }
  }, [program_id, urqlClient, notificationService])

  useEffect(() => {
    const fetchUserProgramResponsibility = async () => {
      if (!kapProgram) {
        return
      }

      const queryData = {
        canton: kapProgram.canton_code,
        modules: kapProgram.modules,
      }

      const { data } = await urqlClient
        .query<{
          user: Query_Root['user']
        }>(fetchUserProgramResponsibilityQuery, queryData)
        .toPromise()

      if (data) {
        const users = data.user

        const gfchResponsibles: User[] = users.filter((u) => u.type === USER_TYPE.GFCH)
        setGfchResponsibles(gfchResponsibles)

        const strategicResponsibles: User[] = users.filter(
          (u) =>
            u.type === USER_TYPE.CANTON &&
            u.strategic_responsibility &&
            u.strategic_responsibility.some((item: string) => kapProgram.modules.includes(item)),
        )
        setStrategicResponsibles(strategicResponsibles)

        const operationalResponsibles: User[] = users.filter(
          (u) =>
            u.type === USER_TYPE.CANTON &&
            u.operational_responsibility &&
            u.operational_responsibility.some((item: string) => kapProgram.modules.includes(item)),
        )
        setOperationalResponsibles(operationalResponsibles)
      } else {
        notificationService.operationFailed()
      }
    }

    fetchUserProgramResponsibility()
  }, [kapProgram, urqlClient, notificationService])

  useEffect(() => {
    fetchData()
  }, [program_id, urqlClient, notificationService, fetchData])

  // Contact canton organization management.
  const handleCreateContactCanton = () => {
    navigate(ROUTES.KapDetailsRoot.nested.ContactCantonCreate.params({ programId }))
  }
  const handleViewContactCanton = () => {
    const organizationId = kapProgram?.organization_by_contact_organization?.id
    navigate(ROUTES.KapDetailsRoot.nested.ContactCanton.params({ programId, organizationId }))
  }
  const handleEditContactCanton = () => {
    const organizationId = kapProgram?.organization_by_contact_organization?.id
    navigate(ROUTES.KapDetailsRoot.nested.ContactCantonEdit.params({ programId, organizationId }))
  }

  // Responsible organization management.
  const handleCreateResponsibleOrganization = () => {
    navigate(ROUTES.KapDetailsRoot.nested.ResponsibleOrganizationCreate.params({ programId }))
  }
  const handleViewResponsibleOrganization = (organizationId: number) => {
    navigate(ROUTES.KapDetailsRoot.nested.ResponsibleOrganization.params({ programId, organizationId }))
  }
  const handleEditResponsibleOrganization = (organizationId: number) => {
    navigate(ROUTES.KapDetailsRoot.nested.ResponsibleOrganizationEdit.params({ programId, organizationId }))
  }

  // Partner organization management.
  const handleCreatePartnerOrganization = () => {
    navigate(ROUTES.KapDetailsRoot.nested.PartnerOrganizationCreate.params({ programId }))
  }
  const handleViewPartnerOrganization = (organizationId: number) => {
    navigate(ROUTES.KapDetailsRoot.nested.PartnerOrganization.params({ programId, organizationId }))
  }
  const handleEditPartnerOrganization = (organizationId: number) => {
    navigate(ROUTES.KapDetailsRoot.nested.PartnerOrganizationEdit.params({ programId, organizationId }))
  }

  // Mutual organization management.
  const handleDeleteOrganization = (organizationId: number) => {
    setOrganizationIdToBeDeleted(organizationId)
    setOrganizationDeleteConfirmation(true)
  }
  const handleDeleteOrganizationCancel = () => {
    setOrganizationDeleteConfirmation(false)
  }
  const handleDeleteOrganizationConfirm = async () => {
    setOrganizationDeleteConfirmation(false)

    const { data } = await urqlClient
      .mutation<{
        delete_organization_by_pk: Mutation_Root['delete_organization_by_pk']
      }>(deleteKapProgramOrganizationQuery, {
        organization_id: organizationIdToBeDeleted,
      })
      .toPromise()

    if (data?.delete_organization_by_pk) {
      notificationService.deleteSuccessful()
      fetchData()
    } else {
      notificationService.operationFailed()
    }
  }

  const responsibleOrganizations = kapProgram?.kap_program_organizations.filter(
    (it) => it.type === KAP_ORGANIZATION_TYPE.RESPONSIBLE_ORGANIZATION,
  )
  const partnerOrganizations = kapProgram?.kap_program_organizations.filter(
    (it) => it.type === KAP_ORGANIZATION_TYPE.PARTNER_ORGANIZATION,
  )

  return (
    <ScreenLayout
      title={getMessage('label.program.description')}
      actions={<>{canViewCantonRestricted && <ProgramExportMenu />}</>}
    >
      <PageLayout>
        <>
          {!loading && kapProgram && (
            <>
              <Section
                id="responsible-users"
                title={getMessage('label.kap.program.responsible.users')}
                helpAndInstructions={<HelpAndInstructions labelKey="label.help.kap.program.responsible.users" />}
              >
                <Card>
                  <CardContent>
                    <Box>
                      <ReadOnlyTextField text={getMessage('label.strategic.responsibility')}>
                        {strategicResponsibles.length ? (
                          strategicResponsibles.map((user) => {
                            return (
                              <Typography key={user.id}>
                                {user.first_name} {user.last_name} (
                                {Utils.sortModules(
                                  user.strategic_responsibility.map((m: string) => getMessage(`label.module.${m}`)),
                                ).join(', ')}
                                )
                              </Typography>
                            )
                          })
                        ) : (
                          <DefaultSectionTypography noEntriesMessageKey={getMessage('label.not.available')} />
                        )}
                      </ReadOnlyTextField>
                      <ReadOnlyTextField text={getMessage('label.operational.responsibility')}>
                        {operationalResponsibles.length ? (
                          operationalResponsibles.map((user) => {
                            return (
                              <Typography key={user.id}>
                                {user.first_name} {user.last_name} (
                                {Utils.sortModules(
                                  user.operational_responsibility.map((m: string) => getMessage(`label.module.${m}`)),
                                ).join(', ')}
                                )
                              </Typography>
                            )
                          })
                        ) : (
                          <DefaultSectionTypography noEntriesMessageKey={getMessage('label.not.available')} />
                        )}
                      </ReadOnlyTextField>
                      <ReadOnlyTextField text={getMessage('label.kap.program.gfch.responsible')} isLast>
                        {gfchResponsibles.length ? (
                          gfchResponsibles.map((user) => {
                            return (
                              <Typography key={user.id}>
                                {user.first_name} {user.last_name}
                              </Typography>
                            )
                          })
                        ) : (
                          <DefaultSectionTypography noEntriesMessageKey={getMessage('label.not.available')} />
                        )}
                      </ReadOnlyTextField>
                    </Box>
                  </CardContent>
                </Card>
              </Section>
              <Section
                id="contact-organization"
                title={getMessage('label.kap.program.contact.organization')}
                actionButton={
                  !kapProgram.organization_by_contact_organization && (
                    <AddButton
                      messageKey={'label.title.add.contact.organization'}
                      onClick={handleCreateContactCanton}
                      hidden={!canEdit}
                    />
                  )
                }
                helpAndInstructions={<HelpAndInstructions labelKey="label.help.kap.program.contact.organization" />}
              >
                {kapProgram.organization_by_contact_organization && (
                  <S.Card.Container key={kapProgram.organization_by_contact_organization.id}>
                    <S.Card.Content
                      onClick={handleViewContactCanton}
                      tabIndex={0}
                      onKeyDown={(event: { key: string }) => {
                        if (['Enter', ' '].includes(event.key)) {
                          handleViewContactCanton()
                        }
                      }}
                    >
                      <Typography variant="subtitle2">
                        {kapProgram.organization_by_contact_organization.name}
                      </Typography>
                    </S.Card.Content>
                    <S.Card.Actions>
                      {canEdit && (
                        <>
                          <IconButton color="primary" onClick={handleEditContactCanton} size="large">
                            <EditIcon />
                          </IconButton>
                        </>
                      )}
                    </S.Card.Actions>
                  </S.Card.Container>
                )}
              </Section>
              <Section
                optional
                id="responsible-organization"
                title={getMessage('label.responsible.organizations')}
                actionButton={
                  <AddButton
                    messageKey={'label.title.add.responsible.organization'}
                    onClick={handleCreateResponsibleOrganization}
                    hidden={!canEdit}
                  />
                }
                helpAndInstructions={<HelpAndInstructions labelKey="label.help.kap.program.responsible.organization" />}
              >
                {responsibleOrganizations?.length &&
                  responsibleOrganizations?.map((value) => {
                    return (
                      <Fragment key={value.organization.id}>
                        <S.Card.Container key={value.organization.id}>
                          <S.Card.Content
                            onClick={() => handleViewResponsibleOrganization(value.organization.id)}
                            tabIndex={0}
                            onKeyDown={(event: { key: string }) => {
                              if (['Enter', ' '].includes(event.key)) {
                                handleViewResponsibleOrganization(value.organization.id)
                              }
                            }}
                          >
                            <Typography variant="subtitle2">{value.organization.name}</Typography>
                          </S.Card.Content>
                          <S.Card.Actions>
                            {canEdit && (
                              <>
                                <IconButton
                                  color="primary"
                                  onClick={() => handleEditResponsibleOrganization(value.organization.id)}
                                  size="large"
                                >
                                  <EditIcon />
                                </IconButton>
                                <IconButton
                                  color="primary"
                                  onClick={() => handleDeleteOrganization(value.organization.id)}
                                  size="large"
                                >
                                  <DeleteIcon />
                                </IconButton>
                              </>
                            )}
                          </S.Card.Actions>
                        </S.Card.Container>
                      </Fragment>
                    )
                  })}
              </Section>
              <Section
                optional
                id="partner-organization"
                title={getMessage('label.partners')}
                actionButton={
                  <AddButton
                    messageKey={'label.title.add.partner.organization'}
                    onClick={handleCreatePartnerOrganization}
                    hidden={!canEdit}
                  />
                }
                helpAndInstructions={<HelpAndInstructions labelKey="label.help.kap.program.partner.organization" />}
              >
                {partnerOrganizations?.length &&
                  partnerOrganizations?.map((value) => {
                    return (
                      <Fragment key={value.organization.id}>
                        <S.Card.Container key={value.id}>
                          <S.Card.Content
                            onClick={() => handleViewPartnerOrganization(value.organization.id)}
                            tabIndex={0}
                            onKeyDown={(event: { key: string }) => {
                              if (['Enter', ' '].includes(event.key)) {
                                handleViewPartnerOrganization(value.organization.id)
                              }
                            }}
                          >
                            <Typography variant="subtitle2">{value.organization.name}</Typography>
                          </S.Card.Content>
                          <S.Card.Actions>
                            {canEdit && (
                              <>
                                <IconButton
                                  color="primary"
                                  onClick={() => handleEditPartnerOrganization(value.organization.id)}
                                  size="large"
                                >
                                  <EditIcon />
                                </IconButton>
                                <IconButton
                                  color="primary"
                                  onClick={() => handleDeleteOrganization(value.organization.id)}
                                  size="large"
                                >
                                  <DeleteIcon />
                                </IconButton>
                              </>
                            )}
                          </S.Card.Actions>
                        </S.Card.Container>
                      </Fragment>
                    )
                  })}
              </Section>
              <ConfirmationModalDialog
                open={organizationDeleteConfirmation}
                onCancel={handleDeleteOrganizationCancel}
                onConfirm={handleDeleteOrganizationConfirm}
              >
                {getMessage('label.delete.confirm')}
              </ConfirmationModalDialog>
              <FileSection
                id="structure-plan"
                title={getMessage('label.background.information.structure.plan')}
                fileType={FILE_TYPE.PROGRAM_STRUCTURE_PLAN}
                entityType={ENTITY_TYPE.KAP_PROGRAM}
                entityId={program_id}
                canEdit={canEdit}
                helpAndInstructions={<HelpAndInstructions labelKey="label.help.kap.program.structure.plan" />}
                optional
              />
            </>
          )}
        </>
      </PageLayout>
    </ScreenLayout>
  )
}
