import { gql } from 'urql'

export const getMilestonesQuery = gql`
  query fetchMilestones($projectBaseWhere: project_base_bool_exp) {
    milestone(
      where: { dossier: { project_bases: $projectBaseWhere } }
      order_by: [{ due_date: asc }, { year_in_focus: asc }, { type: asc }]
    ) {
      id
      type
      due_date
      status
      responsible_type
      year_in_focus
      dossier {
        type
        id
        project_bases {
          gfch_responsible {
            first_name
            last_name
          }
          user {
            first_name
            last_name
            email
            status
          }
        }
      }
    }
  }
`

export const queryMilestoneById = gql`
  query fetchMilestoneById($id: Int!) {
    milestone_by_pk(id: $id) {
      id
      type
      due_date
      status
      responsible_type
      notes
      year_in_focus
    }
  }
`

export const updateMilestoneNotesMutation = gql`
  mutation updateMilestone($id: Int, $notes: String!) {
    update_milestone(where: { id: { _eq: $id } }, _set: { notes: $notes }) {
      affected_rows
    }
  }
`

export const milestoneSummaryDataQuery = gql`
  query ($milestoneId: Int!) {
    milestone_by_pk(id: $milestoneId) {
      id
      comment
      status
      workflow_id
      year_in_focus
      responsible_type
    }
  }
`
export const updateMilestoneSummaryCommentById = gql`
  mutation ($milestoneId: Int!, $comment: String) {
    update_milestone(where: { _and: { id: { _eq: $milestoneId } } }, _set: { comment: $comment }) {
      affected_rows
    }
  }
`

export const milestoneNotesQuery = gql`
  query fetchMilestoneById($milestoneId: Int!) {
    milestone_by_pk(id: $milestoneId) {
      notes
    }
  }
`

export const milestoneEvaluationQuery = gql`
  query fetchIntermediateDiscussionEvaluationById($milestoneId: Int!) {
    intermediate_discussion_evaluation(where: { milestone_id: { _eq: $milestoneId } }) {
      evaluation_need
    }
  }
`

export const queryGoalsAndMeasuresAnnualReport = gql`
  query fetchGoalsAndMeasuresAnnualReport($milestoneId: Int!) {
    project_goal_annual_report(where: { milestone_id: { _eq: $milestoneId } }) {
      id
      rating
      rating_comment
      project_goal_id
    }
    project_measure_annual_report(where: { milestone_id: { _eq: $milestoneId } }) {
      id
      rating
      rating_comment
      project_measure_id
    }
  }
`

export const queryGoalAnnualReportByGoalAndMilestoneId = gql`
  query fetchGoalAnnualReport($goalId: Int!, $milestoneId: Int!) {
    project_goal_annual_report(
      where: { _and: { milestone_id: { _eq: $milestoneId }, project_goal_id: { _eq: $goalId } } }
    ) {
      id
      rating
      rating_comment
      project_goal_id
    }
    project_goal_by_pk(id: $goalId) {
      name
      sort_number
    }
  }
`

export const upsertGoalAnnualReportMutation = gql`
  mutation upsertGoalAnnualReport($object: project_goal_annual_report_insert_input!) {
    insert_project_goal_annual_report_one(
      object: $object
      on_conflict: { constraint: milestone_id_project_goal_id_uq, update_columns: [rating, rating_comment] }
    ) {
      id
    }
  }
`

export const deleteGoalAnnualReportMutation = gql`
  mutation deleteGoalAnnualReport($milestoneId: Int!, $goalId: Int!) {
    delete_project_goal_annual_report(
      where: { _and: [{ milestone_id: { _eq: $milestoneId } }, { project_goal_id: { _eq: $goalId } }] }
    ) {
      affected_rows
    }
  }
`

export const queryMeasureAnnualReportByMeasureAndMilestoneId = gql`
  query fetchMeasureAnnualReport($measureId: Int!, $milestoneId: Int!) {
    project_measure_annual_report(
      where: { _and: { milestone_id: { _eq: $milestoneId }, project_measure_id: { _eq: $measureId } } }
    ) {
      id
      rating
      rating_comment
      project_measure_id
    }
    project_measure_by_pk(id: $measureId) {
      name
      sort_number
    }
  }
`

export const upsertMeasureAnnualReportMutation = gql`
  mutation upsertMeasureAnnualReport($object: project_measure_annual_report_insert_input!) {
    insert_project_measure_annual_report_one(
      object: $object
      on_conflict: { constraint: milestone_id_project_measure_id_uq, update_columns: [rating, rating_comment] }
    ) {
      id
    }
  }
`

export const deleteMeasureAnnualReportMutation = gql`
  mutation deleteMeasureAnnualReport($milestoneId: Int!, $measureId: Int!) {
    delete_project_measure_annual_report(
      where: { _and: [{ milestone_id: { _eq: $milestoneId } }, { project_measure_id: { _eq: $measureId } }] }
    ) {
      affected_rows
    }
  }
`

export const queryFeatureKPIAnnualReportByMilestoneId = gql`
  query fetchFeatureKPIAnnualReport($milestoneId: Int!) {
    feature_kpi_annual_report(where: { milestone_id: { _eq: $milestoneId } }) {
      id
      milestone_id
      feature_config_id
      annual_value
    }
  }
`

export const upsertFeatureKPIAnnualReport = gql`
  mutation upsertFeatureKPIAnnualReport($milestoneId: Int!, $objects: [feature_kpi_annual_report_insert_input!]!) {
    delete_feature_kpi_annual_report(where: { milestone_id: { _eq: $milestoneId } }) {
      affected_rows
    }
    insert_feature_kpi_annual_report(
      objects: $objects
      on_conflict: { constraint: milestone_id_feature_config_id_uq, update_columns: annual_value }
    ) {
      affected_rows
    }
  }
`
export const queryGoalsAndMeasuresAnnualReportRating = gql`
  query fetchGoalsMeasuresAnnualReport($milestoneId: Int!) {
    project_base(where: { dossier: { milestones: { id: { _eq: $milestoneId } } } }) {
      project_goals {
        project_goal_annual_reports {
          milestone_id
          rating
        }
      }
      project_measures {
        project_measure_annual_reports {
          milestone_id
          rating
        }
      }
    }
  }
`
export const queryKapGoalsAnnualReportRatingAndReason = gql`
  query fetchKapGoalsAnnualReportRatingAndReason($milestoneId: Int!) {
    kap_program(where: { dossier: { milestones: { id: { _eq: $milestoneId } } } }) {
      kap_goals {
        kap_goal_annual_reports(where: { milestone_id: { _eq: $milestoneId } }) {
          rating
          reason
        }
      }
    }
  }
`

export const queryKapMeasuresAnnualReportValid = gql`
  query fetchKapMeasuresAnnualReportValid($milestoneId: Int!) {
    kap_program(where: { dossier: { milestones: { id: { _eq: $milestoneId } } } }) {
      kap_measures {
        kap_measure_annual_reports(where: { milestone_id: { _eq: $milestoneId } }) {
          valid
        }
      }
    }
  }
`

export const queryFeatureKPIAnnualReportByProject = gql`
  query fetchAnnualReportSum($projectBaseWhere: project_base_bool_exp!) {
    feature_kpi_all_reports: feature_kpi_annual_report(
      where: { milestone: { dossier: { project_bases: $projectBaseWhere } } }
    ) {
      annual_value
      feature_config_id
    }
  }
`
export const fetchQualitativeEvaluationByMilestoneQuery = gql`
  query fetchQualitativeEvaluationByMilestone($milestoneId: Int!) {
    milestone_qualitative_evaluation(where: { milestone_id: { _eq: $milestoneId } }) {
      id
      strength
      weakness
      opportunities
      threats
      equal_opportunity
      sustainability
      multiplication_potential
    }
  }
`

export const upsertMilestoneQualitativeEvaluationQuery = gql`
  mutation upsertMilestoneQualitativeEvaluation(
    $object: milestone_qualitative_evaluation_insert_input!
    $onConflictUpdateColumns: [milestone_qualitative_evaluation_update_column!]!
  ) {
    insert_milestone_qualitative_evaluation_one(
      object: $object
      on_conflict: {
        constraint: milestone_qualitative_evaluation_milestone_id_uq
        update_columns: $onConflictUpdateColumns
      }
    ) {
      id
    }
  }
`

export const queryProjectFinancialReportByMilestoneId = gql`
  query fetchProjectFinancialReport($milestoneId: Int!) {
    project_financial_report(where: { milestone_id: { _eq: $milestoneId } }) {
      id
      gfch_share_annual_value
      share_responsible_organization_annual_value
      other_funding_annual_value
    }
  }
`

export const upsertProjectFinancialReportMutation = gql`
  mutation upsertProjectFinancialReport($projectFinancialReport: [project_financial_report_insert_input!]!) {
    insert_project_financial_report(
      objects: $projectFinancialReport
      on_conflict: {
        constraint: milestone_id_uq
        update_columns: [
          gfch_share_annual_value
          share_responsible_organization_annual_value
          other_funding_annual_value
        ]
      }
    ) {
      affected_rows
    }
  }
`

export const queryProjectFinancialReportsByProject = gql`
  query fetchFinancialReportsByProject($projectBaseWhere: project_base_bool_exp!) {
    project_financial_all_reports: project_financial_report(
      where: { milestone: { dossier: { project_bases: $projectBaseWhere } } }
    ) {
      gfch_share_annual_value
      share_responsible_organization_annual_value
      other_funding_annual_value
    }
  }
`

export const fetchFinancialReportsByMilestoneIdQuery = gql`
  query fetchFinancialReportsByMilestoneId($milestoneId: Int!, $isProgram: Boolean!) {
    project_financial_report(where: { milestone_id: { _eq: $milestoneId } }) @skip(if: $isProgram) {
      id
      gfch_share_annual_value
      share_responsible_organization_annual_value
      other_funding_annual_value
    }
    kap_program_financial_report(where: { milestone_id: { _eq: $milestoneId } }) @include(if: $isProgram) {
      id
      module
      gfch_actual_value
      canton_actual_value
    }
  }
`

export const fetchDossierIdByProjectIdQuery = gql`
  query fetchDossierIdByProjectId($projectBaseId: Int!) {
    dossier(where: { project_bases: { id: { _eq: $projectBaseId } } }) {
      id
    }
  }
`
