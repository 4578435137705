import { ReactElement, useRef } from 'react'
import { useParams } from 'react-router'
import { useMessageSource } from 'src/i18n/useMessageSource'
import { ROUTES } from 'src/routing/routes'
import { KapProgramBudgetGrid } from 'src/screens/kap/program/details/financials/KapProgramBudgetGrid'
import { usePermissionsForKapProgram } from 'src/service/security/PermissionHook'
import { SaveAndBackButton, SaveButton } from 'src/shared/button/Buttons'
import { PageLayout } from 'src/shared/layout/PageLayout'
import { ScreenLayout } from 'src/shared/layout/ScreenLayout'
import { NotAuthorized } from 'src/shared/not-authorized/NotAuthorized'
import { HelpAndInstructions } from 'src/shared/presentation/HelpAndInstructions'
import { useDelayedNavigate } from 'src/shared/utils/hooks/navigation-hooks'

type originType = 'SAVE' | 'SAVE_AND_BACK'

export const KapFinancialsEditPage = (): ReactElement => {
  const { programId } = useParams()
  const program_id = parseInt(programId as string)

  const originRef = useRef<originType>('SAVE')
  const { getMessage } = useMessageSource()
  const navigate = useDelayedNavigate()

  const { canEdit, loading } = usePermissionsForKapProgram(program_id)

  const onSubmit = (origin: originType) => (_: any) => {
    // trigger submit event
    if (originRef) {
      originRef.current = origin
    }
    document.getElementById('edit-form')?.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }))
  }

  const onSave = () => {
    if (originRef.current === 'SAVE_AND_BACK') {
      onBack()
    }
  }

  const onBack = () => {
    navigate(ROUTES.KapDetailsRoot.nested.Financials.params({ programId }))
  }

  if (!loading && !canEdit) {
    return <NotAuthorized inEditPage />
  }

  return (
    <ScreenLayout
      title={getMessage('label.title.edit.kap.program.budget')}
      hasSecondLevelNavigation={false}
      onBack={onBack}
      actions={
        <>
          <SaveAndBackButton origin="header" onClick={onSubmit('SAVE_AND_BACK')} />
          <SaveButton origin="header" onClick={onSubmit('SAVE')} />
        </>
      }
    >
      <PageLayout>
        {!loading && (
          <>
            <HelpAndInstructions labelKey="label.help.kap.program.budget" defaultExpansion />
            <KapProgramBudgetGrid mode="edit" onSave={onSave} />
          </>
        )}
      </PageLayout>
    </ScreenLayout>
  )
}
