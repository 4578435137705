import { IconButton } from '@mui/material'
import Typography from '@mui/material/Typography'
import { ReactElement, ReactNode, useState } from 'react'
import { SupportedLocale } from 'src/@types'
import { valueof } from 'src/@types/global'
import { File, Query_Root } from 'src/@types/graphql'
import { useEnvironment } from 'src/env/EnvironmentStore'
import { useMessageSource } from 'src/i18n/useMessageSource'
import { FileService } from 'src/service/axios/FileService'
import { UploadButton } from 'src/shared/button/Buttons'
import { ENTITY_TYPE, FILE_TYPE } from 'src/shared/constants/constants'
import { DeleteIcon, DownloadIcon } from 'src/shared/icons/Icons'
import { ConfirmationModalDialog } from 'src/shared/modal-dialog/ConfirmationModalDialog'
import { UploadModalDialog } from 'src/shared/modal-dialog/UploadModalDialog'
import { DefaultSectionTypography } from 'src/shared/presentation/DefaultSectionTypography'
import { queryFiles } from 'src/shared/presentation/fileQueries'
import { Section } from 'src/shared/presentation/Section'
import { S } from 'src/shared/styled/S'
import { DateUtils } from 'src/shared/utils/DateUtils'
import { useModalCancel } from 'src/shared/utils/hooks/modal-hooks'
import { useNotificationService } from 'src/shared/utils/NotificationService'
import { useUserLocale } from 'src/user/UserContext'
import styled from 'styled-components/macro'
import { useQuery } from 'urql'

export const DownloadLinkStyled = styled.a`
  text-decoration: none;
  color: inherit;
  flex-grow: 1;
  &:hover .MuiSvgIcon-fontSizeSmall {
    transition: ${({ theme }) => theme.transitions.create('color', { duration: theme.transitions.duration.standard })};
    color: ${({ theme }) => theme.colors.primary.main};
  }
`

export const DocumentContainer = styled.div`
  display: flex;
  grid-gap: ${({ theme }) => theme.spacing(0.5)};
`

interface Props {
  title: string
  fileType: valueof<typeof FILE_TYPE>
  entityType: valueof<typeof ENTITY_TYPE>
  entityId: number
  canEdit?: boolean
  uploadType?: 'SINGLE' | 'MULTIPLE'
  helpAndInstructions: ReactNode
  id?: string
  optional?: boolean
}

const resolveActionButtons = (
  language: SupportedLocale,
  uploadType: string,
  files: File[],
  canEdit: boolean,
  openUploadModalDialog: () => void,
) => {
  return (
    <>
      {canEdit && (
        <UploadButton disabled={uploadType === 'SINGLE' && files.length > 0} onClick={openUploadModalDialog} />
      )}
    </>
  )
}

export const FileSection = ({
  title,
  fileType,
  entityType,
  entityId,
  canEdit = false,
  uploadType = 'MULTIPLE',
  helpAndInstructions,
  id,
  optional,
}: Props): ReactElement => {
  const [{ data }, refetch] = useQuery<
    { file: Query_Root['file'] },
    {
      fileType: string
      entityType: string
      entityId: number
    }
  >({
    query: queryFiles,
    variables: { fileType, entityType, entityId },
  })
  const [open, setOpen] = useState(false)
  const [fileUUIDToBeDeleted, setFileUUIDToBeDeleted] = useState<string | undefined>(undefined)
  const [fileDeleteConfirmationOpen, setFileDeleteConfirmationOpen] = useState(false)
  const { backendUrl } = useEnvironment()
  const { getMessage } = useMessageSource()
  const onCancel = useModalCancel(() => setOpen(false))
  const notificationService = useNotificationService()
  const language = useUserLocale()

  const openUploadModalDialog = () => {
    setOpen(true)
  }

  const saveFile = () => {
    setOpen(false)
    refetch()
  }

  const handleDeleteFile = (fileUUID: string) => () => {
    setFileUUIDToBeDeleted(fileUUID)
    setFileDeleteConfirmationOpen(true)
  }

  const handleCancelDeleteFile = () => {
    setFileUUIDToBeDeleted(undefined)
    setFileDeleteConfirmationOpen(false)
  }

  const handleConfirmDeleteFile = () => {
    if (fileUUIDToBeDeleted !== undefined) {
      FileService.remove(fileUUIDToBeDeleted)
        .then(() => {
          notificationService.deleteSuccessful()
          refetch()
        })
        .catch(() => {
          notificationService.operationFailed()
        })
      setFileUUIDToBeDeleted(undefined)
    }
    setFileDeleteConfirmationOpen(false)
  }

  return (
    <>
      {data && (
        <>
          <Section
            id={id}
            title={title}
            actionButton={resolveActionButtons(language, uploadType, data?.file, canEdit, openUploadModalDialog)}
            helpAndInstructions={helpAndInstructions}
            optional={optional}
          >
            {data?.file?.length > 0 ? (
              data.file.map((file) => (
                <S.Card.Container key={file.id}>
                  <DownloadLinkStyled href={`${backendUrl}/api/file/${file.uuid}`}>
                    <S.Card.Content>
                      <DownloadIcon fontSize="small" />
                      <DocumentContainer>
                        <Typography variant="subtitle2">{file.file_title}</Typography>
                        <Typography variant="body2" color="textSecondary">
                          ({file.file_name})
                        </Typography>
                      </DocumentContainer>
                      <Typography variant="body2" color="textSecondary">
                        {DateUtils.formatDate(DateUtils.parseDateGraphQL(file.upload_date))}
                      </Typography>
                    </S.Card.Content>
                  </DownloadLinkStyled>
                  <S.Card.Actions>
                    {canEdit && (
                      <IconButton color="primary" size="large" onClick={handleDeleteFile(file.uuid)}>
                        <DeleteIcon />
                      </IconButton>
                    )}
                  </S.Card.Actions>
                </S.Card.Container>
              ))
            ) : (
              <S.Card.Container $nonClickable={true}>
                <S.Card.Content>
                  <DefaultSectionTypography noEntriesMessageKey="label.file.not.available" $standAlone={true} />
                </S.Card.Content>
              </S.Card.Container>
            )}
          </Section>

          <UploadModalDialog
            open={open}
            onCancel={onCancel}
            onSave={saveFile}
            fileType={fileType}
            entityType={entityType}
            entityId={entityId}
          />

          <ConfirmationModalDialog
            open={fileDeleteConfirmationOpen}
            onCancel={handleCancelDeleteFile}
            onConfirm={handleConfirmDeleteFile}
          >
            {getMessage('label.delete.confirm')}
          </ConfirmationModalDialog>
        </>
      )}
    </>
  )
}
