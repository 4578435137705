import { Location } from 'history'
import { useLocation, useParams } from 'react-router'
import { Outlet } from 'react-router-dom'
import { ROUTES } from 'src/routing/routes'
import { NestedRoute, Route, SecondLevelRoute } from 'src/routing/routing'
import { SecondLevelNavigation } from 'src/routing/SecondLevelNavigation'
import { usePermissionsForKapProgram } from 'src/service/security/PermissionHook'
import { PageLayout } from 'src/shared/layout/PageLayout'
import { SecondLevelNavigationContainer } from 'src/shared/layout/SecondLevelNavigationContainer'
import { NotAuthorized } from 'src/shared/not-authorized/NotAuthorized'

const resolveActiveRoute = (location: Location, programId: string): SecondLevelRoute => {
  const nested = ROUTES.KapApplicationDetailsRoot.nested as unknown as NestedRoute
  const activeRoute = Object.keys(nested).find(
    (key) =>
      location.pathname.endsWith(nested[key].path) ||
      (ROUTES.KapApplicationDetailsRoot.params({ programId }) === location.pathname && nested[key].primary),
  ) as string
  return nested[activeRoute] as SecondLevelRoute
}

export const KapApplicationDetailsIndexPage = () => {
  const { programId } = useParams()
  const program_id = parseInt(programId as string)

  const location = useLocation()

  const activeRoute = resolveActiveRoute(location, programId as string)
  const showNavigation = activeRoute && !activeRoute.hideNavigation

  const { loading, canViewCantonRestricted } = usePermissionsForKapProgram(program_id)

  if (!loading && !canViewCantonRestricted) {
    return <NotAuthorized />
  }

  return (
    <>
      {!loading && (
        <SecondLevelNavigationContainer $showNavigation={showNavigation}>
          {showNavigation && (
            <PageLayout>
              <SecondLevelNavigation
                activeRoute={activeRoute}
                route={ROUTES.KapApplicationDetailsRoot as unknown as Route}
                baseUrl={ROUTES.KapApplicationDetailsRoot.params({ programId })}
              />
            </PageLayout>
          )}
          <Outlet />
        </SecondLevelNavigationContainer>
      )}
    </>
  )
}
