import { gql } from 'urql'

export const fetchFactsheetByIdQuery = gql`
  query fetchFactsheetQuery($id: Int!) {
    factsheet_by_pk(id: $id) {
      id
      title
      description
      modules
      factsheet_type
      level
      status
      organization
      contact
      important_insights
      strength
      further_information
      evidence_of_effectiveness
      feature_update_version
    }
  }
`

export const updateFactsheetMutation = gql`
  mutation updateFactsheet($id: Int, $object: factsheet_set_input) {
    update_factsheet(where: { id: { _eq: $id } }, _set: $object) {
      affected_rows
    }
  }
`

export const relatedPfKapProjectsAndKapMeasuresQuery = gql`
  query ($factsheetId: Int!) {
    project_base(where: { factsheet_id: { _eq: $factsheetId } }) {
      dossier {
        short_title
        status
      }
      pf_kap_projects {
        application_type {
          key
        }
        modules
        id
      }
    }
    kap_measure(where: { factsheet_id: { _eq: $factsheetId } }) {
      id
      level
      short_title
      modules
      kap_program {
        id
        dossier {
          short_title
          status
        }
      }
    }
  }
`

export const createFactsheetMutation = gql`
  mutation ($title: jsonb, $status: String!, $factsheetType: String!, $factsheetLevel: String!, $modules: jsonb) {
    insert_factsheet_one(
      object: {
        title: $title
        status: $status
        factsheet_type: $factsheetType
        level: $factsheetLevel
        modules: $modules
        feature_base: { data: {} }
      }
    ) {
      id
    }
  }
`

export const queryAllFactsheets = gql`
  query ($locale: String!, $where: factsheet_bool_exp) {
    factsheet(where: $where, order_by: { id: desc }) {
      id
      title(path: $locale)
      modules
      level
      status
    }
  }
`

export const fetchFactsheetSearchDataMutation = gql`
  mutation fetchFactsheetSearchData($input: FactsheetSearchInput!) {
    fetchFactsheetSearchData(search: $input) {
      data {
        id
        title
        description
        status
        linkedEntities
      }
    }
  }
`
