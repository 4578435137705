import { Box } from '@mui/material'
import { useCallback, useEffect, useRef, useState } from 'react'
import { Form } from 'react-final-form'
import { useParams } from 'react-router'
import { Mutation_Root, Project_Assessment_Set_Input, Query_Root } from 'src/@types/graphql'
import { useMessageSource } from 'src/i18n/useMessageSource'
import { resolveApplicationAssessmentDetailsRoot } from 'src/routing/routing-utils'
import {
  getProjectAssessmentCommentsQuery,
  updateProjectAssessmentCommentsMutation,
} from 'src/screens/shared/assessment-criteria/assessmentQueries'
import { usePermissionsForProjectAssessment } from 'src/service/security/PermissionHook'
import { SaveAndBackButton, SaveButton } from 'src/shared/button/Buttons'
import { CRITERIA_TYPE, CRITERIA_TYPE_TYPE } from 'src/shared/constants/assessment-constants'
import { TEXT_LENGTH } from 'src/shared/constants/constants'
import { HtmlEditorField } from 'src/shared/form/control/HtmlEditorField'
import { DirtyFormSpy } from 'src/shared/form/dirty/DirtyFormSpy'
import { maxChar } from 'src/shared/form/validation/validators'
import { PageLayout } from 'src/shared/layout/PageLayout'
import { ScreenLayout } from 'src/shared/layout/ScreenLayout'
import { NotAuthorized } from 'src/shared/not-authorized/NotAuthorized'
import { HelpAndInstructions } from 'src/shared/presentation/HelpAndInstructions'
import { HtmlSanitizer } from 'src/shared/utils/HtmlSanitizer'
import { useNotificationService } from 'src/shared/utils/NotificationService'
import { Utils } from 'src/shared/utils/Utils'
import { useDelayedNavigate } from 'src/shared/utils/hooks/navigation-hooks'
import { useClient } from 'urql'

interface Props {
  baseUrl: '/pf-kap' | '/pf-pgv'
  type: CRITERIA_TYPE_TYPE
}

type originType = 'SAVE' | 'SAVE_AND_BACK'

export const ApplicationAssessmentCommentEditPage = ({ baseUrl, type }: Props) => {
  const { projectId, assessmentId } = useParams()
  const navigate = useDelayedNavigate()
  const urqlClient = useClient()
  const notificationService = useNotificationService()
  const { getMessage } = useMessageSource()

  const project_id = parseInt(projectId as string)
  const assessment_id = parseInt(assessmentId as string)
  const projectType = Utils.resolveProcess(baseUrl)

  const { loading, canEdit } = usePermissionsForProjectAssessment(projectType, project_id, assessment_id)

  const originRef = useRef<originType>('SAVE')

  const [comment, setComment] = useState<string | null>(null)

  const onBack = () => {
    const root = resolveApplicationAssessmentDetailsRoot(baseUrl).nested
    const exclusionRoute = root.Exclusion
    const criteriaRoute = root.Criteria
    const route = type === CRITERIA_TYPE.EXCLUSION ? exclusionRoute : criteriaRoute
    navigate(route.params({ projectId, assessmentId }))
  }

  const fetch = useCallback(async () => {
    const id = parseInt(assessmentId as string)
    const { data } = await urqlClient
      .query<
        { project_assessment_by_pk: Query_Root['project_assessment_by_pk'] },
        { id: number; isQuality: boolean; isExclusion: boolean }
      >(getProjectAssessmentCommentsQuery, {
        id,
        isQuality: type === CRITERIA_TYPE.QUALITY,
        isExclusion: type === CRITERIA_TYPE.EXCLUSION,
      })
      .toPromise()

    if (data) {
      const comment =
        type === CRITERIA_TYPE.EXCLUSION
          ? data?.project_assessment_by_pk?.exclusion_comment
          : data?.project_assessment_by_pk?.assessment_comment
      setComment(comment || null)
    } else {
      notificationService.operationFailed()
    }
  }, [urqlClient, notificationService, assessmentId, type])

  useEffect(() => {
    fetch()
  }, [fetch])

  const onSubmit = (origin: originType) => (_: any) => {
    // trigger submit event
    if (originRef) {
      originRef.current = origin
    }
    document.getElementById('edit-form')?.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }))
  }

  const onSave = async ({ comment }: { comment: string | null }) => {
    const id = parseInt(assessmentId as string)

    const object = {
      ...(type === CRITERIA_TYPE.EXCLUSION && { exclusion_comment: HtmlSanitizer.sanitize(comment ?? '') }),
      ...(type === CRITERIA_TYPE.QUALITY && { assessment_comment: HtmlSanitizer.sanitize(comment ?? '') }),
    }

    const { data } = await urqlClient
      .mutation<
        { update_project_assessment: Mutation_Root['update_project_assessment'] },
        { id: number; object: Project_Assessment_Set_Input }
      >(updateProjectAssessmentCommentsMutation, {
        id,
        object,
      })
      .toPromise()

    if (data && data.update_project_assessment?.affected_rows) {
      setComment(comment)
      notificationService.changesSaved()
      if (originRef.current === 'SAVE_AND_BACK') {
        onBack()
      }
    } else {
      notificationService.operationFailed()
    }
  }

  if (!loading && !canEdit) {
    return <NotAuthorized inEditPage />
  }

  return (
    <ScreenLayout
      title={getMessage('label.title.edit.comments')}
      onBack={onBack}
      hasSecondLevelNavigation={false}
      actions={
        <>
          <SaveAndBackButton origin="header" onClick={onSubmit('SAVE_AND_BACK')} />
          <SaveButton origin="header" onClick={onSubmit('SAVE')} />
        </>
      }
    >
      <PageLayout>
        <>
          {!loading && (
            <>
              <HelpAndInstructions
                labelKey={`label.help.assessment.${type.toLowerCase()}.comment.${projectType}`}
                defaultExpansion
              />
              <Box>
                <Form<{ comment: string | null }>
                  initialValues={{ comment }}
                  onSubmit={onSave}
                  render={({ handleSubmit }) => {
                    return (
                      <form id="edit-form" onSubmit={handleSubmit}>
                        <HtmlEditorField
                          name="comment"
                          validate={maxChar(TEXT_LENGTH.XL)}
                          label={getMessage('label.summary.comments')}
                        />
                        <DirtyFormSpy />
                      </form>
                    )
                  }}
                />
              </Box>
            </>
          )}
        </>
      </PageLayout>
    </ScreenLayout>
  )
}
