import { Button } from '@mui/material'
import { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { File, Kap_Program, Maybe, Query_Root } from 'src/@types/graphql'
import { useMessageSource } from 'src/i18n/useMessageSource'
import { ROUTES } from 'src/routing/routes'
import { SummarySection } from 'src/screens/shared/application/common/SummarySection'
import { fetchKapProgramApplicationSummaryQuery } from 'src/screens/shared/application/details/application-information/applicationInformationQueries'
import { ValidationListItemProps } from 'src/screens/shared/common/SummaryValidationUtils'
import { ProgramWorkflowService } from 'src/service/axios/ProgramWorkflowService'
import { usePermissionsForKapProgram } from 'src/service/security/PermissionHook'
import { ProgramWorkflowPermissionService } from 'src/service/security/ProgramWorkflowPermissionService'
import { EditButton } from 'src/shared/button/Buttons'
import { DOSSIER_STATUS, DOSSIER_STATUS_TYPE, ENTITY_TYPE, FILE_TYPE, PROJECT } from 'src/shared/constants/constants'
import { NextState } from 'src/shared/constants/workflow-constants'
import { PageLayout } from 'src/shared/layout/PageLayout'
import { ScreenLayout } from 'src/shared/layout/ScreenLayout'
import { ProgramExportMenu } from 'src/shared/menu/ProgramExportMenu'
import { HandoverModalDialog } from 'src/shared/modal-dialog/HandoverModalDialog'
import { useSidebarAPI } from 'src/shared/sidebar/SidebarAwareContext'
import { useDelayedNavigate } from 'src/shared/utils/hooks/navigation-hooks'
import { useNotificationService } from 'src/shared/utils/NotificationService'
import { NextStateIf } from 'src/shared/workflow/NextStateIf'
import { useUserLocale } from 'src/user/UserContext'
import styled from 'styled-components/macro'
import { useClient } from 'urql'

const SpanStyled = styled.span<{ $isCanceled: string }>`
  color: ${({ $isCanceled, theme }) =>
    $isCanceled === `${DOSSIER_STATUS.CANCELED}` ? theme.colors.error.dark : 'inherit'};
`

export const KapApplicationSummaryPage = () => {
  const { programId } = useParams()
  const program_id = parseInt(programId as string)

  const { getMessage } = useMessageSource()

  const language = useUserLocale()
  const urqlClient = useClient()
  const notificationService = useNotificationService()
  const navigate = useDelayedNavigate()
  const sidebarAPI = useSidebarAPI()

  const [applicationSummary, setApplicationSummary] = useState<Maybe<Kap_Program>>()
  const [validationViolations, setValidationViolations] = useState<ValidationListItemProps[]>()
  const [implementationConfirmationOpen, setImplementationConfirmationOpen] = useState(false)
  const [withdrawConfirmationOpen, setWithdrawConfirmationOpen] = useState(false)
  const [transitionToImplementation, setTransitionToImplementation] = useState(false)
  const [transitionToWithdraw, setTransitionToWithdraw] = useState(false)
  const [nextStates, setNextStates] = useState<NextState[]>([])

  const {
    loading,
    canEditApplication,
    userGlobalRoles,
    canViewCantonRestricted,
    refetch: refetchPermissions,
  } = usePermissionsForKapProgram(program_id)

  const validateApplication = useCallback(
    (kapProgram: Kap_Program, programStatus: DOSSIER_STATUS_TYPE, file?: File): ValidationListItemProps[] => {
      const validationViolations: ValidationListItemProps[] = []
      if (programStatus === DOSSIER_STATUS.CANCELED) {
        return validationViolations
      } else {
        if (kapProgram.board_meeting_date === null) {
          const violation = {
            message: getMessage('validation.fill.out.section', [
              getMessage('label.navigation.application.information'),
              getMessage('label.kap.board.meeting.date'),
            ]),
            tab: 'information',
            section: 'application-information',
          }
          validationViolations.push(violation)
        }
        if (kapProgram.application_decision === null) {
          const violation = {
            message: getMessage('validation.fill.out.section', [
              getMessage('label.navigation.application.information'),
              getMessage('label.application.decision'),
            ]),
            tab: 'information',
            section: 'application-information',
          }
          validationViolations.push(violation)
        }
        if (!file) {
          const violation = {
            message: getMessage('validation.section.missing.upload', [
              getMessage('label.navigation.application.information'),
              getMessage('label.kap.letter.of.intent'),
            ]),
            tab: 'information',
            section: 'documents',
          }
          validationViolations.push(violation)
        }
        return validationViolations
      }
    },
    [getMessage],
  )

  const fetchData = useCallback(async () => {
    const queryParams = {
      id: program_id,
      fileType: FILE_TYPE.PROGRAM_APPLICATION_LETTER_OF_INTENT,
      entityType: ENTITY_TYPE.KAP_PROGRAM,
    }

    const { data } = await urqlClient
      .query<{
        kap_program_by_pk: Query_Root['kap_program_by_pk']
        file: Query_Root['file']
      }>(fetchKapProgramApplicationSummaryQuery, queryParams)
      .toPromise()

    if (data && data.kap_program_by_pk && data.file) {
      const kapProgram = data.kap_program_by_pk

      setApplicationSummary(kapProgram)

      const file = data.file[0]

      const validationViolations = validateApplication(
        kapProgram,
        kapProgram.dossier.status as DOSSIER_STATUS_TYPE,
        file,
      )
      setValidationViolations(validationViolations)

      const nextStates = await ProgramWorkflowService.nextStates(kapProgram.dossier.project_workflow_id ?? '')
      const nextStatesForUser = ProgramWorkflowPermissionService.nextStatesForUser(nextStates)(
        userGlobalRoles,
        canEditApplication,
      )

      setNextStates(nextStatesForUser)
    } else {
      notificationService.operationFailed()
    }
  }, [program_id, userGlobalRoles, canEditApplication, urqlClient, notificationService, validateApplication])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  const handleEditComment = () => {
    navigate(ROUTES.KapApplicationDetailsRoot.nested.SummaryEdit.params({ programId }))
  }

  const handleViolationClick = (validationItem: ValidationListItemProps) => {
    const route = ROUTES.KapApplicationDetailsRoot.params({ programId })

    navigate(
      `${route}${validationItem.tab ? '/' + validationItem.tab : ''}${
        validationItem.section ? `#${validationItem.section}` : ''
      }`,
    )
  }

  const onOpenDialogSetToImplementation = () => {
    setImplementationConfirmationOpen(true)
  }
  const onConfirmSetToImplementation = async () => {
    try {
      setTransitionToImplementation(true)

      await ProgramWorkflowService.transition(
        applicationSummary?.dossier.project_workflow_id as string,
        DOSSIER_STATUS.IMPLEMENTATION,
        language,
      )

      refetchPageData()

      setImplementationConfirmationOpen(false)
      notificationService.changesSaved()
      navigate(ROUTES.KapImplementationDetailsRoot.params({ programId }))
    } catch (e) {
      notificationService.operationFailed()
    } finally {
      setTransitionToImplementation(false)
    }
  }
  const onCancelSetToImplementation = () => {
    setImplementationConfirmationOpen(false)
  }

  const onOpenDialogWithdrawProgram = () => {
    setWithdrawConfirmationOpen(true)
  }
  const onConfirmWithdrawProgram = async () => {
    try {
      setTransitionToWithdraw(true)

      await ProgramWorkflowService.transition(
        applicationSummary?.dossier.project_workflow_id as string,
        DOSSIER_STATUS.CANCELED,
        language,
      )

      refetchPageData()

      setWithdrawConfirmationOpen(false)
      notificationService.changesSaved()
    } catch (e) {
      notificationService.operationFailed()
    } finally {
      setTransitionToWithdraw(false)
    }
  }
  const onCancelWithdrawProgram = () => {
    setWithdrawConfirmationOpen(false)
  }

  const refetchPageData = () => {
    fetchData()
    refetchPermissions()
    sidebarAPI.refetchSidebarData()
  }

  const getValidationCompleteMessage = (): string => {
    if (applicationSummary?.dossier.status === DOSSIER_STATUS.CANCELED) {
      return getMessage('label.kap.program.is.canceled')
    } else {
      return getMessage('label.kap.application.summary.check.complete')
    }
  }

  return (
    <ScreenLayout
      title={getMessage('label.navigation.application.details')}
      actions={<>{canViewCantonRestricted && <ProgramExportMenu />}</>}
    >
      <PageLayout>
        <>
          {!loading && applicationSummary && validationViolations && (
            <>
              <SummarySection
                title={getMessage('label.kap.application.summary.title')}
                actionsHelpLabel={'label.help.kap.application.summary'}
                status={
                  <>
                    {getMessage('label.project.status')}:{' '}
                    <SpanStyled $isCanceled={applicationSummary.dossier.status}>
                      {getMessage(`label.project.status.${applicationSummary.dossier.status}`)}
                    </SpanStyled>
                  </>
                }
                violations={validationViolations}
                violationClickHandler={handleViolationClick}
                actionsCompleteMessage={getValidationCompleteMessage()}
                noItemsToValidate={applicationSummary.dossier.status === DOSSIER_STATUS.CANCELED}
                statusActions={
                  <>
                    <NextStateIf
                      forState={DOSSIER_STATUS.CANCELED}
                      nextStates={nextStates}
                      userGlobalRoles={userGlobalRoles}
                      canEditOwnCanton={canEditApplication}
                    >
                      <Button
                        variant="outlined"
                        color="secondary"
                        onClick={onOpenDialogWithdrawProgram}
                        sx={{
                          display:
                            applicationSummary.dossier.status !== DOSSIER_STATUS.APPLICATION || !canEditApplication
                              ? 'none'
                              : 'inline-block',
                        }}
                      >
                        {getMessage('button.withdraw.program')}
                      </Button>
                    </NextStateIf>
                    <NextStateIf
                      forState={DOSSIER_STATUS.IMPLEMENTATION}
                      nextStates={nextStates}
                      userGlobalRoles={userGlobalRoles}
                      canEditOwnCanton={canEditApplication}
                    >
                      <Button
                        variant="contained"
                        color="secondary"
                        disabled={validationViolations.length > 0}
                        sx={{
                          display:
                            applicationSummary.dossier.status !== DOSSIER_STATUS.APPLICATION || !canEditApplication
                              ? 'none'
                              : 'inline-block',
                        }}
                        onClick={onOpenDialogSetToImplementation}
                      >
                        {getMessage('button.set.to.implementation')}
                      </Button>
                    </NextStateIf>
                  </>
                }
                commentsHelpLabel={'label.help.kap.application.summary.comments'}
                commentActions={<EditButton onClick={handleEditComment} hidden={!canEditApplication} />}
                comment={applicationSummary.application_comment}
              />
              <HandoverModalDialog
                open={withdrawConfirmationOpen}
                onSend={onConfirmWithdrawProgram}
                onCancel={onCancelWithdrawProgram}
                entityId={program_id}
                loading={transitionToWithdraw}
                processType={PROJECT.KAP}
                titleKey="label.program.withdraw.confirm.title"
                contentKey="label.program.withdraw.confirm.body"
                buttonLoadingKey="button.withdraw.program"
              />
              <HandoverModalDialog
                open={implementationConfirmationOpen}
                onSend={onConfirmSetToImplementation}
                onCancel={onCancelSetToImplementation}
                entityId={program_id}
                loading={transitionToImplementation}
                processType={PROJECT.KAP}
                titleKey="label.application.set.to.implementation.confirm.title"
                contentKey="label.kap.application.set.to.implementation.confirm.body"
                buttonLoadingKey="button.set.to.implementation"
              />
            </>
          )}
        </>
      </PageLayout>
    </ScreenLayout>
  )
}
