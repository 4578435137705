import { ReactElement, useEffect, useState } from 'react'
import { Query_Root } from 'src/@types/graphql'
import { useMessageSource } from 'src/i18n/useMessageSource'
import { resolveApplicationAssessmentDetailsRoot, resolveApplicationAssessmentRoot } from 'src/routing/routing-utils'
import { ApplicationAssessmentCommentSection } from 'src/screens/shared/application/assessment/details/comment/ApplicationAssessmentCommentSection'
import {
  getCriteriaConfigByProcessQuery,
  getProjectAssessmentAndApplicationCodeId,
} from 'src/screens/shared/assessment-criteria/assessmentQueries'
import { BaseAssessmentCriteria } from 'src/screens/shared/assessment-criteria/BaseAssessmentCriteria'
import {
  createGroupsAndInitialData,
  CriteriaFormValues,
  CriteriaGroups,
  QUALITY_PREFIX,
} from 'src/screens/shared/assessment-criteria/utils/AssessmentUtils'
import { usePermissionsForProjectAssessment } from 'src/service/security/PermissionHook'
import { CRITERIA_TYPE } from 'src/shared/constants/assessment-constants'
import { PROJECT } from 'src/shared/constants/constants'
import { ProjectExportMenu } from 'src/shared/menu/ProjectExportMenu'
import { useDelayedNavigate } from 'src/shared/utils/hooks/navigation-hooks'
import { Utils } from 'src/shared/utils/Utils'
import { useClient, useQuery } from 'urql'

interface Props {
  baseUrl: '/pf-kap' | '/pf-pgv'
  projectId: number
  assessmentId: number
}

export const ApplicationAssessmentCriteria = ({ projectId, assessmentId, baseUrl }: Props): ReactElement => {
  const { getMessage } = useMessageSource()

  const navigate = useDelayedNavigate()
  const urqlClient = useClient()
  const projectType = Utils.resolveProcess(baseUrl)

  const [criteriaGroups, setCriteriaGroups] = useState<CriteriaGroups | undefined>()
  const [initialValues, setInitialValues] = useState<CriteriaFormValues | undefined>()
  const {
    loading,
    canEdit,
    metadata: { projectBaseId },
  } = usePermissionsForProjectAssessment(projectType, projectId as unknown as number, assessmentId)

  const onBackHandler = () => {
    navigate(resolveApplicationAssessmentRoot(baseUrl).params({ projectId }))
  }

  const onEditHandler = () => {
    navigate(resolveApplicationAssessmentDetailsRoot(baseUrl).nested.CriteriaEdit.params({ projectId, assessmentId }))
  }
  const isPfKap = projectType === PROJECT.PF_KAP
  const isPfPgv = projectType === PROJECT.PF_PGV
  const [{ data }] = useQuery<{
    project_assessment: Query_Root['project_assessment']
    pf_kap_project: Query_Root['pf_kap_project']
    pf_pgv_project: Query_Root['pf_pgv_project']
  }>({
    query: getProjectAssessmentAndApplicationCodeId,
    variables: { assessmentId, projectBaseId, isPfKap, isPfPgv, projectId },
  })

  const projectAssessment = data?.project_assessment?.[0]
  const firstName = projectAssessment?.user?.first_name
  const lastName = projectAssessment?.user?.last_name
  const email = projectAssessment?.user?.email
  const userFullName = !firstName ? email : `${firstName} ${lastName}`
  const applicationType = isPfKap
    ? data?.pf_kap_project?.[0]?.application_type_id
    : data?.pf_pgv_project?.[0]?.application_type_id
  const title = isPfKap
    ? getMessage('label.assessment.criteria.pf-kap')
    : getMessage('label.assessment.criteria.pf-pgv')
  useEffect(() => {
    const initData = async () => {
      const { data } = await urqlClient
        .query<{
          project_assessment_criteria_selection: Query_Root['project_assessment_criteria_selection']
          criteria_group_config: Query_Root['criteria_group_config']
        }>(getCriteriaConfigByProcessQuery, {
          process: projectType,
          criteriaType: CRITERIA_TYPE.QUALITY,
          assessment: assessmentId,
          applicationTypeId: applicationType,
        })
        .toPromise()

      const selectedQualities = data?.project_assessment_criteria_selection
      const criteriaGroupedConfigs = data?.criteria_group_config

      const { criteriaGroups, initialValues } = createGroupsAndInitialData(
        selectedQualities,
        criteriaGroupedConfigs,
        QUALITY_PREFIX,
        applicationType,
      )

      setCriteriaGroups(criteriaGroups)
      setInitialValues(initialValues)
    }
    initData()
  }, [urqlClient, assessmentId, projectType, applicationType])

  return (
    <BaseAssessmentCriteria
      screenLayoutTitle={`${getMessage('label.navigation.application.assessment')}: ${userFullName || ''}`}
      onBackHandler={onBackHandler}
      actions={<ProjectExportMenu process={projectType} entityId={projectBaseId as number} />}
      sectionLayoutTitle={title}
      helpText={`label.help.assessment.criteria.${projectType.toLowerCase()}`}
      criteriaGroups={criteriaGroups}
      initialValues={initialValues}
      canEdit={canEdit}
      onEditHandler={onEditHandler}
      loading={loading}
      commentSection={
        <ApplicationAssessmentCommentSection baseUrl={baseUrl} canEdit={canEdit} type={CRITERIA_TYPE.QUALITY} />
      }
    />
  )
}
