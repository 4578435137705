import { Typography } from '@mui/material'
import { ReactElement, useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { Query_Root } from 'src/@types/graphql'
import { useMessageSource } from 'src/i18n/useMessageSource'
import { queryKapAnnualReportAngGoalReportAndMeasures } from 'src/screens/kap/implementation/milestone/details/annual-report/goals/kapMilestoneGoalQueries'
import { sortKapMeasures } from 'src/screens/shared/measure/utils/KapMeasureUtils'
import { KapGoalAnnualReport } from 'src/shared/constants/milestone-constants'
import { HtmlRenderer } from 'src/shared/presentation/HtmlRenderer'
import { ReadOnlySelection, ReadOnlyTextField } from 'src/shared/presentation/ReadOnly'
import { useNotificationService } from 'src/shared/utils/NotificationService'
import { resolveGoalRatingColor } from 'src/shared/utils/StylingUtils'
import { Utils } from 'src/shared/utils/Utils'
import { useClient } from 'urql'
import { KapMilestoneGoalRelatedMeasuresGrid } from './KapMilestoneGoalRelatedMeasuresGrid'

interface Props {
  goalId: number
}

export const KapMilestoneGoalCardContent = ({ goalId }: Props): ReactElement => {
  const [goal, setGoal] = useState<KapGoalAnnualReport>()
  const { milestoneId } = useParams()
  const { getMessage } = useMessageSource()
  const urqlClient = useClient()
  const notificationService = useNotificationService()
  const milestone_id = parseInt(milestoneId as string)

  useEffect(() => {
    const initData = async () => {
      const { data } = await urqlClient
        .query<
          {
            kap_goal_by_pk: Query_Root['kap_goal_by_pk']
            kap_goal_annual_report: Query_Root['kap_goal_annual_report']
            kap_measure_annual_report: Query_Root['kap_measure_annual_report']
          },
          { goalId: number; milestoneId: number }
        >(queryKapAnnualReportAngGoalReportAndMeasures, { goalId, milestoneId: milestone_id })
        .toPromise()

      if (data && data.kap_goal_by_pk && data.kap_goal_annual_report && data.kap_measure_annual_report) {
        const linkedMeasuredIds = data.kap_goal_by_pk.kap_goal_measures.map((m) => m.kap_measure.id)
        const measureAnnualReport = data.kap_measure_annual_report.filter((r) =>
          linkedMeasuredIds.some((id) => id === r.kap_measure_id),
        )
        const goalMeasures = data.kap_goal_by_pk.kap_goal_measures.map((g) => g.kap_measure)

        const mergedMeasuresAndReports = Utils.mergeBy(
          { arr: goalMeasures, key: 'id' },
          { arr: measureAnnualReport, key: 'kap_measure_id' },
        )

        setGoal({
          goal_id: goalId,
          reason: data.kap_goal_annual_report?.[0]?.reason ?? '',
          rating: data.kap_goal_annual_report?.[0]?.rating ?? '',
          description: data.kap_goal_by_pk?.description,
          goal_measures: sortKapMeasures(mergedMeasuresAndReports),
        })
      } else {
        notificationService.operationFailed()
      }
    }
    initData()
  }, [urqlClient, notificationService, milestone_id, goalId])

  return (
    <>
      {goal && (
        <>
          <ReadOnlyTextField text={getMessage('label.goal.rating.description.kap')}>
            {goal.description && <HtmlRenderer html={goal.description} />}
          </ReadOnlyTextField>
          <ReadOnlyTextField text={getMessage('label.goal.rating')}>
            {goal.rating && (
              <Typography color={resolveGoalRatingColor(goal.rating)}>
                {goal.rating && getMessage(`label.goal.rating.${goal.rating}`)}
              </Typography>
            )}
          </ReadOnlyTextField>
          <ReadOnlyTextField text={getMessage('label.goal.rating.reason.kap')}>
            {goal.reason && <HtmlRenderer html={goal.reason} />}
          </ReadOnlyTextField>
          <ReadOnlySelection text={getMessage('label.related.measures')} isLast>
            <KapMilestoneGoalRelatedMeasuresGrid measures={goal.goal_measures ?? []} />
          </ReadOnlySelection>
        </>
      )}
    </>
  )
}
