import { AccordionDetails, AccordionSummary, Box, Card, CardContent, css, Tooltip, Typography } from '@mui/material'
import combinedQuery from 'graphql-combine-query'
import { get } from 'lodash'
import { ReactElement, ReactNode, useEffect, useMemo, useState } from 'react'
import {
  Feature_Base_Bool_Exp,
  Feature_Config_Bool_Exp,
  Kap_Measure,
  Project_Base,
  Query_Root,
} from 'src/@types/graphql'
import { useMessageSource } from 'src/i18n/useMessageSource'
import {
  queryFeatureConfigs,
  queryFeatureTypes,
  queryFeatureValuesByFeatureBase,
} from 'src/screens/shared/feature/featureBaseQueries'
import { differenceFeaturesList } from 'src/screens/shared/feature/utils/FeatureBaseUtils'
import {
  isFeatureSelectionInvalid,
  isFeatureTypeWithInvalidSelections,
} from 'src/screens/shared/feature/utils/FeatureValidationUtils'
import { EditButton } from 'src/shared/button/Buttons'
import { CheckIcon, ErrorOutlineIcon, ExpandMoreIcon, InfoIcon } from 'src/shared/icons/Icons'
import { DefaultSectionTypography } from 'src/shared/presentation/DefaultSectionTypography'
import { HelpAndInstructions } from 'src/shared/presentation/HelpAndInstructions'
import { Section } from 'src/shared/presentation/Section'
import { S } from 'src/shared/styled/S'
import { useNotificationService } from 'src/shared/utils/NotificationService'
import { useUserLocale } from 'src/user/UserContext'
import styled from 'styled-components/macro'
import { useClient } from 'urql'

const FeaturesContainer = styled(Box)`
  margin-bottom: ${({ theme }) => theme.spacing(4)};
`

const AvailableFeaturesContainer = styled(Box)`
  color: ${({ theme }) => theme.colors.text.disabled};
  margin-left: ${({ theme }) => theme.spacing(1.5)};
  margin-top: ${({ theme }) => theme.spacing(1.5)};
`

const TitleStyled = styled(Typography)`
  margin-bottom: ${({ theme }) => theme.spacing(1.5)};
`

const FeatureListItem = styled(Typography)<{ $selected?: boolean; $isInvalid?: boolean }>`
  color: ${({ $isInvalid, theme }) => ($isInvalid ? theme.colors.error.dark : 'inherit')};
  display: grid;
  grid-auto-flow: column;
  grid-gap: ${({ theme }) => theme.spacing(1)};
  align-items: center;
  min-height: 1.75rem;
  width: fit-content;
  ${({ $selected }) =>
    $selected &&
    css`
      &:not(:last-child) {
        margin-bottom: ${({ theme }) => theme.spacing(0.625)};
      }
    `}
`

const InfoIconStyled = styled(InfoIcon)`
  font-size: 1.25rem;
`

interface FeatureBaseProps {
  featureBaseWhere: Feature_Base_Bool_Exp
  featureConfigWhere: Feature_Config_Bool_Exp
  onEditFeature: (featureTypeId: number) => void
  canEdit: boolean
  kpiSection?: ReactNode
  relatedEntity?: Kap_Measure | Project_Base
}

export const FeatureBase = ({
  featureBaseWhere,
  featureConfigWhere,
  onEditFeature,
  canEdit,
  kpiSection,
  relatedEntity,
}: FeatureBaseProps): ReactElement => {
  const language = useUserLocale()
  const urqlClient = useClient()
  const notificationService = useNotificationService()
  const { getMessage } = useMessageSource()

  const [featuresData, setFeaturesData] = useState<
    | {
        feature_type_config: Query_Root['feature_type_config']
        feature_value: Query_Root['feature_value']
        feature_config: Query_Root['feature_config']
      }
    | undefined
  >()

  useEffect(() => {
    const initData = async () => {
      const combinedQueryResult = combinedQuery('FeatureBaseRelatedData')
        .add<
          {
            feature_value: Query_Root['feature_value']
          },
          {
            featureBaseWhere: Feature_Base_Bool_Exp
          }
        >(queryFeatureValuesByFeatureBase, {
          featureBaseWhere: featureBaseWhere,
        })
        .add<
          {
            feature_config: Query_Root['feature_config']
          },
          {
            featureConfigWhere: Feature_Config_Bool_Exp
          }
        >(queryFeatureConfigs, {
          featureConfigWhere: featureConfigWhere,
        })
        .add<{ feature_type_config: Query_Root['feature_type_config'] }>(queryFeatureTypes)

      const { document, variables } = combinedQueryResult

      const { data } = await urqlClient
        .query<{
          feature_type_config: Query_Root['feature_type_config']
          feature_value: Query_Root['feature_value']
          feature_config: Query_Root['feature_config']
        }>(document, variables)
        .toPromise()

      if (data) {
        setFeaturesData(data)
      } else {
        notificationService.operationFailed()
      }
    }

    initData()
  }, [urqlClient, notificationService, featureBaseWhere, featureConfigWhere])

  const feature_data = useMemo(() => {
    const featuresTypes = featuresData?.feature_type_config ?? []
    const unfilteredSelectedFeaturesData = featuresData?.feature_value ?? []

    const allFeatures = featuresData?.feature_config ?? []
    const availableFeatures =
      differenceFeaturesList(
        allFeatures,
        unfilteredSelectedFeaturesData.map((x) => x.feature_config),
      ) ?? []

    const selectedFeatures = unfilteredSelectedFeaturesData.filter(
      (feature) => feature.feature_config.selection_type === 'SELECT',
    )
    const customFeatures = unfilteredSelectedFeaturesData.filter(
      (feature) => feature.feature_config.selection_type === 'TEXT',
    )

    return {
      featuresTypes,
      selectedFeatures,
      availableFeatures,
      customFeatures,
      allFeatures,
    }
  }, [featuresData])

  const featureValidation = relatedEntity && relatedEntity?.factsheet !== null

  return (
    <>
      {feature_data &&
        feature_data.featuresTypes
          .filter(
            (featureType) =>
              feature_data.allFeatures.find(
                (feature) => feature.feature_type_id === featureType.id && feature.active,
              ) ||
              feature_data.selectedFeatures.find(
                (feature) => feature.feature_config.feature_type_id === featureType.id,
              ),
          )
          .map((featureType) => {
            return (
              <Section
                key={featureType.code}
                id={featureType.code}
                title={get(featureType.names, language, '')}
                actionButton={<EditButton onClick={() => onEditFeature(featureType.id)} hidden={!canEdit} />}
                helpAndInstructions={
                  <HelpAndInstructions
                    labelKey={featureType.description && get(featureType.description, language, '')}
                  />
                }
              >
                <Card>
                  <CardContent>
                    <FeaturesContainer>
                      <TitleStyled variant="h6">{getMessage('label.your.selection')}</TitleStyled>
                      {feature_data.selectedFeatures.find(
                        (feature) => feature.feature_config.feature_type_id === featureType.id,
                      ) ? (
                        feature_data.selectedFeatures
                          .filter((feature) => feature.feature_config.feature_type_id === featureType.id)
                          .sort((a, b) => {
                            if (a.feature_config.sort_number > b.feature_config.sort_number) {
                              return 1
                            } else {
                              return -1
                            }
                          })
                          .map((feature) => {
                            return (
                              <FeatureListItem
                                $selected={true}
                                variant="body2"
                                key={feature.feature_config_id}
                                $isInvalid={
                                  featureValidation &&
                                  isFeatureSelectionInvalid(
                                    featureType,
                                    relatedEntity?.factsheet,
                                    feature.feature_config_id,
                                  )
                                }
                              >
                                <CheckIcon /> {get(feature.feature_config.names, language, '')}
                                {feature.feature_config.tooltips &&
                                  get(feature.feature_config.tooltips, language, '') !== '' &&
                                  get(feature.feature_config.tooltips, language, '') !== null && (
                                    <Tooltip
                                      placement="right"
                                      title={get(feature.feature_config.tooltips, language, '')}
                                    >
                                      <InfoIconStyled />
                                    </Tooltip>
                                  )}
                              </FeatureListItem>
                            )
                          })
                      ) : (
                        <DefaultSectionTypography type="SELECTION" $standAlone={false} />
                      )}
                    </FeaturesContainer>

                    <FeaturesContainer>
                      {feature_data.customFeatures.find(
                        (feature) => feature.feature_config.feature_type_id === featureType.id,
                      ) && <TitleStyled variant="h6">{getMessage('label.others.features')}</TitleStyled>}
                      {feature_data.customFeatures
                        .filter((feature) => feature.feature_config.feature_type_id === featureType.id)
                        .map((customFeature) => {
                          return (
                            <FeatureListItem
                              $selected={true}
                              variant="body2"
                              key={customFeature.feature_config_id}
                              $isInvalid={
                                featureValidation &&
                                isFeatureSelectionInvalid(
                                  featureType,
                                  relatedEntity?.factsheet,
                                  customFeature.feature_config_id,
                                )
                              }
                            >
                              <CheckIcon />
                              {customFeature.other_description}
                              {customFeature.feature_config.tooltips &&
                                get(customFeature.feature_config.tooltips, language, '') !== '' &&
                                get(customFeature.feature_config.tooltips, language, '') !== null && (
                                  <Tooltip
                                    placement="right"
                                    title={get(customFeature.feature_config.tooltips, language, '')}
                                  >
                                    <InfoIconStyled />
                                  </Tooltip>
                                )}
                            </FeatureListItem>
                          )
                        })}
                    </FeaturesContainer>

                    <S.Accordion>
                      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography variant="h6">{getMessage('label.available.features')}</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <AvailableFeaturesContainer>
                          {(() => {
                            let availableFeaturesForType = feature_data.availableFeatures
                              .filter(
                                (availableFeature) =>
                                  availableFeature.feature_type_id === featureType.id && availableFeature.active,
                              )
                              .sort((a, b) => {
                                if (a.sort_number > b.sort_number) {
                                  return 1
                                } else {
                                  return -1
                                }
                              })

                            if (featureValidation) {
                              availableFeaturesForType = availableFeaturesForType.filter(
                                (feature) =>
                                  !isFeatureSelectionInvalid(featureType, relatedEntity?.factsheet, feature.id),
                              )
                            }

                            if (availableFeaturesForType.length === 0) {
                              return (
                                <FeatureListItem variant="body2" color="inherit">
                                  {getMessage('label.available.features.none')}
                                </FeatureListItem>
                              )
                            }

                            return availableFeaturesForType.map((availableFeature) => {
                              return (
                                <FeatureListItem variant="body2" key={availableFeature.id} color="inherit">
                                  {get(availableFeature.names, language, '')}
                                  {availableFeature.tooltips &&
                                    get(availableFeature.tooltips, language, '') !== '' &&
                                    get(availableFeature.tooltips, language, '') !== null && (
                                      <Tooltip placement="right" title={get(availableFeature.tooltips, language, '')}>
                                        <InfoIconStyled />
                                      </Tooltip>
                                    )}
                                </FeatureListItem>
                              )
                            })
                          })()}
                        </AvailableFeaturesContainer>
                      </AccordionDetails>
                    </S.Accordion>

                    {featureValidation && isFeatureTypeWithInvalidSelections(featureType, relatedEntity) && (
                      <FeatureListItem variant="body2" $isInvalid sx={{ mt: 4 }}>
                        <ErrorOutlineIcon />
                        {getMessage('validation.deviating.features')}
                      </FeatureListItem>
                    )}
                  </CardContent>
                </Card>
              </Section>
            )
          })}
      {feature_data.featuresTypes.length > 0 && kpiSection}
    </>
  )
}
