import { Box } from '@mui/material'
import { useState } from 'react'
import { useMessageSource } from 'src/i18n/useMessageSource'
import { AddUserModal } from 'src/screens/administration/user-administration/add-user/AddUserModal'
import { UsersGrid } from 'src/screens/administration/user-administration/UsersGrid'
import { usePermissionsForUserAdministration } from 'src/service/security/PermissionHook'
import { PageLayout } from 'src/shared/layout/PageLayout'
import { ScreenLayout } from 'src/shared/layout/ScreenLayout'
import { ModalDialog } from 'src/shared/modal-dialog/ModalDialog'
import { NotAuthorized } from 'src/shared/not-authorized/NotAuthorized'

export const UserAdministrationPage = () => {
  const { getMessage } = useMessageSource()
  const { canView, canEdit } = usePermissionsForUserAdministration()

  const [openModal, setOpenModal] = useState(false)
  const [isUserAdded, setIsUserAdded] = useState(false)

  if (!canView) {
    return <NotAuthorized />
  }

  const handleAddNewUser = () => {
    setOpenModal(true)
  }

  const closeModal = () => {
    setOpenModal(false)
  }

  const onSuccess = () => {
    setOpenModal(false)
    setIsUserAdded(true)
  }

  return (
    <ScreenLayout title={getMessage('label.navigation.gfch.user.administration')} hasSecondLevelNavigation={false}>
      <PageLayout>
        <Box>
          <UsersGrid
            canEdit={canEdit}
            onAddUser={handleAddNewUser}
            isUserAdded={isUserAdded}
            setIsUserAdded={setIsUserAdded}
          />
        </Box>

        <ModalDialog
          open={openModal}
          title={getMessage('label.add.new.user')}
          maxWidth="sm"
          withCloseIcon={false}
          onClose={closeModal}
        >
          <AddUserModal onCancel={closeModal} onSuccess={onSuccess} />
        </ModalDialog>
      </PageLayout>
    </ScreenLayout>
  )
}
