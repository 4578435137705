import { Box, DialogActions, DialogContent, Stack } from '@mui/material'
import { uniqBy } from 'lodash'
import { useContext, useEffect, useMemo, useState } from 'react'
import { Form } from 'react-final-form'
import { FormValidationErrors } from 'src/@types/global'
import { Funding_Round_Insert_Input, Mutation_Root, Query_Root } from 'src/@types/graphql'
import { useMessageSource } from 'src/i18n/useMessageSource'
import {
  createFundingRoundMutation,
  getApplicationTypesQuery,
} from 'src/screens/administration/round-management/round-management-queries'
import { RoundManagementUtils } from 'src/screens/administration/round-management/RoundManagementUtils'
import { PrimaryButton, SecondaryButton } from 'src/shared/button/Buttons'
import { PROJECT, TEXT_LENGTH } from 'src/shared/constants/constants'
import { ROUND_TYPE_TYPE } from 'src/shared/constants/round-management-constants'
import { Option } from 'src/shared/form/control'
import { AutoCompleteField } from 'src/shared/form/control/AutoCompleteField'
import { DateTimePickerField } from 'src/shared/form/control/DateTimePickerField'
import { TextField } from 'src/shared/form/control/TextField'
import { composeValidators, maxChar, required, validDate } from 'src/shared/form/validation/validators'
import { DateUtils } from 'src/shared/utils/DateUtils'
import { useNotificationService } from 'src/shared/utils/NotificationService'
import { Utils } from 'src/shared/utils/Utils'
import { UserContext } from 'src/user/UserContext'
import { useClient } from 'urql'

interface Props {
  onCancel: () => void
  onSuccess: () => void
}

interface FormValues {
  name: string
  type: string
  startDate: string
  endDate: string
  decisionDate: string
}

export const CreateRoundModal = ({ onCancel, onSuccess }: Props) => {
  const { getMessage } = useMessageSource()
  const notificationService = useNotificationService()
  const { user } = useContext(UserContext)
  const globalRoles = user.roles
  const urqlClient = useClient()
  const [typeOptions, setTypeOptions] = useState<Option[]>([])
  const [roundTypes, setRoundTypes] = useState<{ roundType: ROUND_TYPE_TYPE | null; code: string[] | null }[]>([])
  const [initialType, setInitialType] = useState<string | undefined>(undefined)

  const processes = useMemo(() => {
    return Utils.resolveProcessFromInternalGfchRole(globalRoles)
  }, [globalRoles])

  useEffect(() => {
    const initData = async () => {
      const { data } = await urqlClient
        .query<
          {
            application_type: Query_Root['application_type']
          },
          { userProcesses: string[] }
        >(getApplicationTypesQuery, { userProcesses: processes })
        .toPromise()

      if (data) {
        const allRoundTypes = uniqBy(
          data.application_type.map((applicationType) =>
            RoundManagementUtils.resolveApplicationTypeToRoundType(applicationType),
          ),
          'roundType',
        )

        setRoundTypes(allRoundTypes)
        setTypeOptions(
          allRoundTypes.map((roundType: any) => ({
            label: getMessage(`label.round.type.${roundType.roundType}`),
            value: roundType.roundType,
          })),
        )
        if (processes.length === 1 && processes[0] === PROJECT.PF_PGV && allRoundTypes) {
          setInitialType(allRoundTypes[0].roundType as string)
        }
      }
    }

    initData()
  }, [urqlClient, processes, getMessage])

  const validateForm = (values: FormValues): FormValidationErrors<FormValues> => {
    const error: FormValidationErrors<FormValues> = {}

    const startDate = DateUtils.parseDate(values.startDate)
    const endDate = DateUtils.parseDate(values.endDate)

    if (DateUtils.valid(startDate) && DateUtils.valid(endDate) && DateUtils.after(startDate, endDate)) {
      error.endDate = { errorKey: 'validation.round.end.date.after.start.date' }
    }

    return error
  }

  const mapValuesToFundingRoundInput = (values: FormValues): Funding_Round_Insert_Input => ({
    name: values.name,
    start_date: DateUtils.parseAndSerializeDate(values.startDate),
    end_date: DateUtils.parseAndSerializeDate(values.endDate),
    decision_date: DateUtils.parseAndSerializeDate(values.decisionDate),
    application_types: roundTypes
      .filter((roundType) => roundType.roundType === values.type)
      .flatMap((roundType) => roundType.code),
    process: RoundManagementUtils.resolveRoundProcess(values.type as ROUND_TYPE_TYPE),
  })

  const handleSubmitLocal = async (values: FormValues) => {
    const object = mapValuesToFundingRoundInput(values)

    const { data } = await urqlClient
      .mutation<
        {
          insert_funding_round: Mutation_Root['insert_funding_round']
        },
        {
          objects: Funding_Round_Insert_Input[]
        }
      >(createFundingRoundMutation, { objects: [object] })
      .toPromise()

    if (data?.insert_funding_round && data?.insert_funding_round.affected_rows > 0) {
      notificationService.changesSaved()
      onSuccess()
    } else {
      notificationService.operationFailed()
    }
  }

  return (
    <Box>
      <Form<FormValues>
        onSubmit={handleSubmitLocal}
        validate={validateForm}
        initialValues={{ type: initialType }}
        render={({ handleSubmit }) => {
          return (
            <form onSubmit={handleSubmit} noValidate>
              <DialogContent>
                <Stack spacing={2}>
                  <TextField
                    name="name"
                    label={getMessage('label.name')}
                    required
                    validate={composeValidators(required(), maxChar(TEXT_LENGTH.S))}
                  />
                  <AutoCompleteField
                    name="type"
                    label={getMessage('label.round.type')}
                    options={typeOptions}
                    validate={required()}
                    required
                    disabled={processes[0] === PROJECT.PF_PGV}
                  />
                  <DateTimePickerField
                    name="startDate"
                    label={getMessage('label.start.date')}
                    required
                    validate={composeValidators(required(), validDate())}
                  />
                  <DateTimePickerField
                    name="endDate"
                    label={getMessage('label.end.date')}
                    required
                    validate={composeValidators(required(), validDate())}
                  />
                  <DateTimePickerField
                    name="decisionDate"
                    label={getMessage('label.decision.date')}
                    required
                    validate={composeValidators(required(), validDate())}
                  />
                </Stack>
              </DialogContent>
              <DialogActions>
                <SecondaryButton messageKey={'button.cancel'} onClick={onCancel} />
                <PrimaryButton messageKey={'button.create'} type="submit" />
              </DialogActions>
            </form>
          )
        }}
      />
    </Box>
  )
}
