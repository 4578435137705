import { ReactElement, useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router'
import { Mutation_Root, Query_Root } from 'src/@types/graphql'
import { useMessageSource } from 'src/i18n/useMessageSource'
import { ROUTES } from 'src/routing/routes'
import {
  fetchKapMeasureByIdQuery,
  updateKapMeasureMutation,
} from 'src/screens/kap/program/details/measures/kapMeasuresQueries'
import {
  initialValuesFactory,
  KapMeasureForm,
  KapMeasureFormValues,
} from 'src/screens/kap/program/details/measures/measure/description/edit/KapMeasureForm'
import { usePermissionsForKapProgram } from 'src/service/security/PermissionHook'
import { SaveAndBackButton, SaveButton } from 'src/shared/button/Buttons'
import { PageLayout } from 'src/shared/layout/PageLayout'
import { ScreenLayout } from 'src/shared/layout/ScreenLayout'
import { NotAuthorized } from 'src/shared/not-authorized/NotAuthorized'
import { HelpAndInstructions } from 'src/shared/presentation/HelpAndInstructions'
import { useDelayedNavigate } from 'src/shared/utils/hooks/navigation-hooks'
import { useError } from 'src/shared/utils/hooks/page-loading-error-hook'
import { HtmlSanitizer } from 'src/shared/utils/HtmlSanitizer'
import { useNotificationService } from 'src/shared/utils/NotificationService'
import { Utils } from 'src/shared/utils/Utils'
import { useClient } from 'urql'

type originType = 'SAVE' | 'SAVE_AND_BACK'

export const KapMeasureDescriptionEditPage = (): ReactElement => {
  const { measureId, programId } = useParams()
  const program_id = parseInt(programId as string)
  const measure_id = parseInt(measureId as string)

  const { getMessage } = useMessageSource()
  const navigate = useDelayedNavigate()
  const originRef = useRef<originType>('SAVE')
  const urqlClient = useClient()
  const notificationService = useNotificationService()
  const setError = useError()

  const [initialValues, setInitialValues] = useState<KapMeasureFormValues | null>(null)

  const { canEditMeasures, loading } = usePermissionsForKapProgram(program_id)

  useEffect(() => {
    const initializeFormValues = async () => {
      const { data } = await urqlClient
        .query<{ kap_measure_by_pk: Query_Root['kap_measure_by_pk'] }, { id: number }>(fetchKapMeasureByIdQuery, {
          id: measure_id,
        })
        .toPromise()

      if (data?.kap_measure_by_pk) {
        const measure = data.kap_measure_by_pk
        const initialValues = initialValuesFactory(program_id, measure)
        setInitialValues(initialValues)
      } else {
        setError()
      }
    }

    initializeFormValues()
  }, [measure_id, urqlClient, program_id, setError])

  const onSubmit = (origin: originType) => (_: any) => {
    // trigger submit event
    if (originRef) {
      originRef.current = origin
    }
    document.getElementById('kap-measure-form')?.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }))
  }

  const onUpdateHandler = async (values: any) => {
    const goals = values.goalIds?.map((goalId: number) => ({
      kap_goal_id: goalId,
      kap_measure_id: values.measureId,
    }))

    const updateObject = {
      id: values.measureId,
      name: values.name,
      shortTitle: values.shortTitle,
      description: HtmlSanitizer.sanitize(values.description),
      modules: Utils.sortModules(values.modules),
      goalsToBeAdded: goals,
    }

    const { error, data } = await urqlClient
      .mutation<{
        update_kap_measure: Mutation_Root['update_kap_measure']
        delete_kap_goal_measure: Mutation_Root['delete_kap_goal_measure']
        insert_kap_goal_measure: Mutation_Root['insert_kap_goal_measure']
      }>(updateKapMeasureMutation, updateObject)
      .toPromise()

    if (error || data?.update_kap_measure?.affected_rows !== 1) {
      notificationService.operationFailed()
    } else {
      setInitialValues(values)
      notificationService.changesSaved()
      if (originRef.current === 'SAVE_AND_BACK') {
        onBack()
      }
    }
  }

  const onBack = () => {
    navigate(ROUTES.KapMeasureDetailsRoot.nested.Description.params({ programId, measureId }))
  }

  if (!loading && !canEditMeasures) {
    return <NotAuthorized inEditPage />
  }

  return (
    <ScreenLayout
      title={getMessage('label.measure.title.edit')}
      hasSecondLevelNavigation={false}
      onBack={onBack}
      actions={
        <>
          <SaveAndBackButton origin="header" onClick={onSubmit('SAVE_AND_BACK')} />
          <SaveButton origin="header" onClick={onSubmit('SAVE')} />
        </>
      }
    >
      <PageLayout>
        <>
          {!loading && initialValues && (
            <>
              <HelpAndInstructions labelKey="label.help.kap.measures" defaultExpansion />
              <KapMeasureForm initialValues={initialValues} onSave={onUpdateHandler} mode="edit" />
            </>
          )}
        </>
      </PageLayout>
    </ScreenLayout>
  )
}
