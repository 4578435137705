import { GridRenderEditCellParams, GridRowId } from '@mui/x-data-grid'
import { GridColDef, useGridApiRef } from '@mui/x-data-grid-pro'
import { GridApiPro } from '@mui/x-data-grid-pro/models/gridApiPro'
import combinedQuery from 'graphql-combine-query'
import { groupBy, merge, sumBy } from 'lodash'
import { MutableRefObject, ReactElement, useEffect, useMemo, useState } from 'react'
import { Form } from 'react-final-form'
import { useParams } from 'react-router'
import { Kap_Program_Budget, Mutation_Root, Query_Root } from 'src/@types/graphql'
import { useMessageSource } from 'src/i18n/useMessageSource'
import { usePrompt } from 'src/routing/routing-hooks'
import { fetchKapProgramFinancialReportsByProgramIdQuery } from 'src/screens/kap/implementation/milestone/kapMilestoneQueries'
import {
  fetchKapProgramModulesAndBudgetByProgramIdQuery,
  upsertKapProgramBudgetMutation,
} from 'src/screens/kap/program/details/financials/kapProgramFinancialsQueries'
import { NumberInput } from 'src/shared/form/grid/NumberInput'
import { EditableColumnHeader } from 'src/shared/grid/EditableColumnHeader'
import { DefaultSectionTypography } from 'src/shared/presentation/DefaultSectionTypography'
import { S } from 'src/shared/styled/S'
import { DataGridUtils } from 'src/shared/utils/DataGridUtils'
import { useGridTranslateHook } from 'src/shared/utils/hooks/grid-translate-hook'
import { useError } from 'src/shared/utils/hooks/page-loading-error-hook'
import { useDelayedSubmit } from 'src/shared/utils/hooks/submit-hook'
import { useNotificationService } from 'src/shared/utils/NotificationService'
import { Utils } from 'src/shared/utils/Utils'
import { useClient } from 'urql'
import { KAP_MODULES } from 'src/shared/constants/constants'

interface KapProgramBudgetRowData {
  module: string
  budget_gfch: number | null
  budget_canton: number | null
  budget_total: number | null
  actuals: number | null
}

interface Props {
  mode: 'view' | 'edit'
  onSave?: () => void
}

export const KapProgramBudgetGrid = ({ mode, onSave }: Props): ReactElement => {
  const { programId } = useParams()
  const program_id = parseInt(programId as string)

  const apiRef = useGridApiRef()
  const { getMessage } = useMessageSource()
  const urqlClient = useClient()
  const notificationService = useNotificationService()
  const gridTranslations = useGridTranslateHook()
  const setError = useError()
  const submit = useDelayedSubmit()

  const [dirty, setDirty] = useState<boolean>(false)
  const [serverData, setServerData] = useState<KapProgramBudgetRowData[]>([])
  const [totalData, setTotalData] = useState<{ budget_gfch: number; budget_canton: number }>()
  const labelTotal = getMessage('label.total')

  // simultaneously calculating total budget as user enters values
  const calculateTotalBudgetValue = (
    value: number,
    id: GridRowId,
    field: string,
    apiRef: MutableRefObject<GridApiPro>,
  ): number => {
    const lastRowId = apiRef.current.getRowsCount() - 1
    const rows = apiRef.current
      ?.getAllRowIds()
      .filter((id) => id !== lastRowId)
      .map((rowId) => ({
        budget_gfch:
          field === 'budget_gfch' && id === rowId
            ? DataGridUtils.formatFieldValue(value)
            : DataGridUtils.formatFieldValue(apiRef.current?.getRow(rowId).budget_gfch),
        budget_canton:
          field === 'budget_canton' && id === rowId
            ? DataGridUtils.formatFieldValue(value)
            : DataGridUtils.formatFieldValue(apiRef.current?.getRow(rowId).budget_canton),
      }))

    const budgetGfchTotal = DataGridUtils.formatNaNNumber(DataGridUtils.sumBy(rows, 'budget_gfch'))
    const budgetCantonTotal = DataGridUtils.formatNaNNumber(DataGridUtils.sumBy(rows, 'budget_canton'))

    setTotalData({
      budget_gfch: budgetGfchTotal,
      budget_canton: budgetCantonTotal,
    })

    return value
  }

  const rows = useMemo(() => {
    const currentRowModels = apiRef.current?.getRowModels?.() ?? new Map()

    if (serverData && serverData.length > 0) {
      const rowData = serverData.map((row, index) => {
        return {
          id: index,
          module: row.module,
          budget_gfch: currentRowModels.get(index)?.budget_gfch ?? row.budget_gfch ?? null,
          budget_canton: currentRowModels.get(index)?.budget_canton ?? row.budget_canton ?? null,
          budget_total: (row?.budget_gfch || 0) + (row?.budget_canton || 0),
          actuals: row?.actuals ?? '-',
        }
      })

      rowData.push({
        id: rowData.length,
        module: labelTotal,
        budget_gfch: totalData ? totalData.budget_gfch : DataGridUtils.sumBy(rowData, 'budget_gfch'),
        budget_canton: totalData ? totalData.budget_canton : DataGridUtils.sumBy(rowData, 'budget_canton'),
        budget_total: sumBy(rowData, 'budget_total'),
        actuals: sumBy(rowData, 'actuals'),
      })

      return rowData
    }
    return []
  }, [serverData, apiRef, totalData, labelTotal])

  const columns = useMemo(() => {
    const hideColumn = mode === 'edit'
    const onRifmChange = (api: GridRenderEditCellParams['api'], id: GridRowId, field: string, value: number) => {
      calculateTotalBudgetValue(value, id, field, apiRef)
      api.setEditCellValue({ id, field, value })
      setDirty(true)
    }
    return [
      {
        field: 'module',
        headerName: getMessage('label.module'),
        sortable: false,
        disableColumnMenu: true,
        flex: 1,
        renderCell: ({ value }) => {
          if (value === labelTotal) {
            return labelTotal
          }
          return `${getMessage(`label.module.description.${value}`)}`
        },
      },
      {
        field: 'budget_gfch',
        headerAlign: 'right',
        sortable: false,
        editable: mode === 'edit',
        disableColumnMenu: true,
        align: 'right',
        flex: 0.25,
        renderCell: ({ value }) => {
          if (value === null || value === '' || Number.isNaN(value)) {
            return <DefaultSectionTypography $standAlone />
          }
          return <S.Span.TextOverflow>{value?.toLocaleString('de-CH')}</S.Span.TextOverflow>
        },
        renderEditCell: (params) => {
          const { value, id, field, api } = params
          const isValid = DataGridUtils.validateValue(value)
          return (
            <NumberInput
              value={value as number}
              onChange={(val: number) => onRifmChange(api, id, field, val)}
              error={!isValid}
            />
          )
        },
        renderHeader: () => (
          <EditableColumnHeader headerName={getMessage('label.budget.gfch')} editMode={mode === 'edit'} />
        ),
      },
      {
        field: 'budget_canton',
        headerAlign: 'right',
        sortable: false,
        editable: mode === 'edit',
        disableColumnMenu: true,
        align: 'right',
        flex: 0.25,
        renderCell: ({ value }) => {
          if (value === null || value === '' || Number.isNaN(value)) {
            return <DefaultSectionTypography $standAlone />
          }
          return <S.Span.TextOverflow>{value?.toLocaleString('de-CH')}</S.Span.TextOverflow>
        },
        renderEditCell: (params) => {
          const { value, id, field, api } = params
          const isValid = DataGridUtils.validateValue(value)
          return (
            <NumberInput
              value={value as number}
              onChange={(val: number) => onRifmChange(api, id, field, val)}
              error={!isValid}
            />
          )
        },
        renderHeader: () => (
          <EditableColumnHeader headerName={getMessage('label.budget.canton')} editMode={mode === 'edit'} />
        ),
      },
      {
        field: 'budget_total',
        headerName: getMessage('label.budget.total'),
        headerAlign: 'right',
        sortable: false,
        disableColumnMenu: true,
        align: 'right',
        flex: 0.25,
        hide: hideColumn,
        renderCell: ({ value }) => <S.Span.TextOverflow>{value?.toLocaleString('de-CH')}</S.Span.TextOverflow>,
      },
      {
        field: 'actuals',
        headerName: getMessage('label.actuals'),
        headerAlign: 'right',
        sortable: false,
        disableColumnMenu: true,
        align: 'right',
        flex: 0.25,
        renderCell: ({ value }) => <S.Span.TextOverflow>{value?.toLocaleString('de-CH')}</S.Span.TextOverflow>,
      },
    ] as GridColDef[]
  }, [apiRef, getMessage, mode, labelTotal])

  useEffect(() => {
    const fetchData = async () => {
      const { document, variables } = combinedQuery('kapProgramFinancialReports')
        .add<
          {
            kap_program_by_pk: Query_Root['kap_program_by_pk']
          },
          { programId: number }
        >(fetchKapProgramModulesAndBudgetByProgramIdQuery, { programId: program_id })
        .add<
          {
            kap_program_financial_all_reports: Query_Root['kap_program_financial_report']
          },
          { id: number }
        >(fetchKapProgramFinancialReportsByProgramIdQuery, { id: program_id })

      const { data } = await urqlClient
        .query<{
          kap_program_by_pk: Query_Root['kap_program_by_pk']
          kap_program_financial_all_reports: Query_Root['kap_program_financial_report']
        }>(document, variables)
        .toPromise()

      if (data?.kap_program_by_pk) {
        const programBudget = data?.kap_program_by_pk.kap_program_budgets
        let programModules = data?.kap_program_by_pk.modules.map((value: string) => ({
          module: value,
        }))

        programModules = Utils.mergeBy({ arr: programModules, key: 'module' }, { arr: programBudget, key: 'module' })
        const financialReports = groupBy(data?.kap_program_financial_all_reports, 'module')
        const mergedData = programModules.map((moduleData: any) => ({
          ...moduleData,
          actuals:
            sumBy(financialReports[moduleData.module], 'gfch_actual_value') +
            sumBy(financialReports[moduleData.module], 'canton_actual_value'),
        }))

        setServerData(
          mergedData.sort((m1: any, m2: any) => KAP_MODULES.indexOf(m1.module) - KAP_MODULES.indexOf(m2.module)),
        )
      } else {
        setError()
      }
    }
    fetchData()
  }, [urqlClient, setError, program_id])

  const onSaveLocal = async () => {
    const allRows = apiRef.current.getRowModels()
    const lastRowId = apiRef.current.getRowsCount() - 1
    const editableRows = Array.from(allRows.values()).filter((row) => row.id !== lastRowId)
    const newProgramBudgetData = editableRows.map((row) => ({
      module: row.module,
      kap_program_id: program_id,
      budget_gfch: DataGridUtils.formatFieldValue(row.budget_gfch),
      budget_canton: DataGridUtils.formatFieldValue(row.budget_canton),
    }))

    const updateColumns: Array<keyof Kap_Program_Budget> = ['budget_gfch', 'budget_canton']

    if (totalData && totalData?.budget_gfch > totalData?.budget_canton) {
      notificationService.error(getMessage('validation.kap.program.total.budget'))
      return
    }

    const { data, error } = await urqlClient
      .mutation<{
        insert_kap_program_budget: Mutation_Root['insert_kap_program_budget']
      }>(upsertKapProgramBudgetMutation, {
        kapProgramBudgetToBeAdded: newProgramBudgetData,
        updateColumns: updateColumns,
      })
      .toPromise()

    if (error || data?.insert_kap_program_budget?.affected_rows === 0) {
      setError()
    } else {
      if (onSave) {
        const newServerData = newProgramBudgetData.map((data) => ({
          module: data.module,
          budget_gfch: data.budget_gfch,
          budget_canton: data.budget_canton,
        })) as typeof serverData

        merge(
          newServerData,
          editableRows.map((row) => ({
            actuals: row.actuals,
          })),
        )

        notificationService.changesSaved()
        setServerData(newServerData)
        setDirty(false)
        onSave()
      }
    }
  }

  usePrompt(getMessage('notification.form.unsaved.changes'), dirty)

  return (
    <Form
      onSubmit={submit(onSaveLocal)}
      render={({ handleSubmit }) => (
        <form
          id="edit-form"
          onSubmit={handleSubmit}
          onKeyPress={(event) => {
            if (event.key === 'Enter') {
              event.preventDefault()
            }
          }}
        >
          <S.DataGrid.TotalRow
            rows={rows}
            columns={columns}
            autoHeight
            hideFooter
            disableColumnReorder
            disableRowSelectionOnClick
            disableColumnSelector
            apiRef={apiRef}
            onCellEditStop={() => setDirty(true)}
            localeText={gridTranslations}
            isCellEditable={({ row }: { row: any }) => row.id !== rows.length - 1}
          />
        </form>
      )}
    />
  )
}
