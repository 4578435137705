import { Card, CardContent } from '@mui/material'
import { ReactElement } from 'react'
import { Query_Root } from 'src/@types/graphql'
import { useMessageSource } from 'src/i18n/useMessageSource'
import { ROUTES } from 'src/routing/routes'
import { resolveApplicationAssessmentDetailsRoot } from 'src/routing/routing-utils'
import { queryAssessmentRecommendationById } from 'src/screens/shared/application/assessment/details/recommendation/assessmentRecomendationQueries'
import { getProjectAssessmentById } from 'src/screens/shared/assessment-criteria/assessmentQueries'
import { usePermissionsForProjectAssessment } from 'src/service/security/PermissionHook'
import { EditButton } from 'src/shared/button/Buttons'
import { PROJECT } from 'src/shared/constants/constants'
import { PageLayout } from 'src/shared/layout/PageLayout'
import { ScreenLayout } from 'src/shared/layout/ScreenLayout'
import { ProjectExportMenu } from 'src/shared/menu/ProjectExportMenu'
import { HelpAndInstructions } from 'src/shared/presentation/HelpAndInstructions'
import { HtmlRenderer } from 'src/shared/presentation/HtmlRenderer'
import { ReadOnlySelection, ReadOnlyTextField } from 'src/shared/presentation/ReadOnly'
import { Section } from 'src/shared/presentation/Section'
import { useDelayedNavigate } from 'src/shared/utils/hooks/navigation-hooks'
import { Utils } from 'src/shared/utils/Utils'
import { useQuery } from 'urql'

interface Props {
  baseUrl: '/pf-kap' | '/pf-pgv'
  projectId: number
  assessmentId: number
}

export const ApplicationAssessmentRecommendation = ({ baseUrl, projectId, assessmentId }: Props): ReactElement => {
  const { getMessage } = useMessageSource()
  const projectType = Utils.resolveProcess(baseUrl)
  const process = Utils.resolveProcessToLowerCase(baseUrl)
  const navigate = useDelayedNavigate()

  const {
    loading,
    canEdit,
    metadata: { projectBaseId },
  } = usePermissionsForProjectAssessment(projectType, projectId, assessmentId)

  const backHandler = () => {
    if (projectType === PROJECT.PF_KAP) {
      navigate(ROUTES.PfKapApplicationAssessment.params({ projectId }))
    } else if (projectType === PROJECT.PF_PGV) {
      navigate(ROUTES.PfPgvApplicationAssessment.params({ projectId }))
    } else {
      throw Error(`Unknown project type ${projectType}`)
    }
  }

  const [{ data: projectAssessmentBaseData }] = useQuery<{
    project_assessment: Query_Root['project_assessment']
  }>({
    query: getProjectAssessmentById,
    variables: { assessmentId, projectBaseId },
  })

  const [{ data: assessmentRecommendationData }] = useQuery<{
    project_assessment: Query_Root['project_assessment']
  }>({
    query: queryAssessmentRecommendationById,
    variables: { assessmentId },
  })

  const projectAssessment = assessmentRecommendationData?.project_assessment?.[0]
  const firstName = projectAssessmentBaseData?.project_assessment[0]?.user?.first_name
  const lastName = projectAssessmentBaseData?.project_assessment[0]?.user?.last_name
  const email = projectAssessmentBaseData?.project_assessment[0]?.user?.email
  const userFullName = !firstName ? email : `${firstName} ${lastName}`

  const personalRecommendationEditHandler = () => {
    navigate(
      resolveApplicationAssessmentDetailsRoot(baseUrl).nested.RecommendationEdit.params({ projectId, assessmentId }),
    )
  }

  return (
    <ScreenLayout
      title={`${getMessage('label.navigation.application.assessment')}: ${userFullName || ''}`}
      onBack={backHandler}
      actions={<ProjectExportMenu process={projectType} entityId={projectBaseId as number} />}
    >
      {!loading && (
        <PageLayout>
          <Section
            id="personal-assessment"
            title={getMessage('label.assessment.personal.recommendation')}
            helpAndInstructions={<HelpAndInstructions labelKey={`label.help.assessment.recommendation.${process}`} />}
            actionButton={<EditButton onClick={personalRecommendationEditHandler} hidden={!canEdit} />}
          >
            <Card>
              <CardContent>
                <ReadOnlySelection text={getMessage('label.recommendation')}>
                  {projectAssessment?.recommendation &&
                    getMessage(`label.recommendation.${projectAssessment!.recommendation}`)}
                </ReadOnlySelection>
                <ReadOnlyTextField text={getMessage('label.pros')}>
                  {projectAssessment?.pros && <HtmlRenderer html={projectAssessment!.pros} />}
                </ReadOnlyTextField>
                <ReadOnlyTextField text={getMessage('label.cons')}>
                  {projectAssessment?.cons && <HtmlRenderer html={projectAssessment!.cons} />}
                </ReadOnlyTextField>
                <ReadOnlyTextField text={getMessage('label.assessment.recommendation.comments')} isLast>
                  {projectAssessment?.recommendation_comment && (
                    <HtmlRenderer html={projectAssessment!.recommendation_comment} />
                  )}
                </ReadOnlyTextField>
              </CardContent>
            </Card>
          </Section>
        </PageLayout>
      )}
    </ScreenLayout>
  )
}
