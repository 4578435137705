import { devtoolsExchange } from '@urql/devtools'
import { ReactElement, ReactNode, useMemo } from 'react'
import { useEnvironment } from 'src/env/EnvironmentStore'
import { CookieUtils } from 'src/shared/utils/CookieUtils'
import { useNavigateToLogin } from 'src/shared/utils/hooks/navigation-hooks'
import { UserStoreActions } from 'src/user/user'
import { fetcherErrorHandlerWithRedirectToLogin, useFetchCurrentUser } from 'src/user/user-hooks'
import { cacheExchange, CombinedError, createClient, dedupExchange, errorExchange, fetchExchange, Provider } from 'urql'

export const GraphQLClientProvider = ({ children }: { children: ReactNode }): ReactElement => {
  const fetchCurrentUser = useFetchCurrentUser()
  const login = useNavigateToLogin()

  const URQL_EXCHANGES = useMemo(() => {
    const onError = async (e: CombinedError) => {
      const isDenied = e?.graphQLErrors.some((x) => x.extensions?.code === 'access-denied')
      if (isDenied) {
        login()
      }

      // is the user on a logged-in-only page and his session simply expired...
      try {
        const refetchedUser = await fetchCurrentUser()
        UserStoreActions.setUser({ ...refetchedUser, anonymous: false })
      } catch (e: any) {
        // the user is not authenticated and his session expired
        // he is already trying to access a restricted page
        // redirect him to girders auth login
        fetcherErrorHandlerWithRedirectToLogin(login)(e).catch(() => {
          // do nothing
        })
      }
    }

    return [devtoolsExchange, dedupExchange, cacheExchange, errorExchange({ onError }), fetchExchange]
  }, [fetchCurrentUser, login])

  const { graphqlUrl } = useEnvironment()
  const urqlClient = useMemo(
    () =>
      createClient({
        url: graphqlUrl,
        exchanges: URQL_EXCHANGES,
        requestPolicy: 'network-only',
        fetchOptions: () => ({
          mode: 'cors',
          credentials: 'include',
          headers: {
            'X-XSRF-TOKEN': CookieUtils.get('XSRF-TOKEN'),
          },
        }),
      }),
    [URQL_EXCHANGES, graphqlUrl],
  )
  return <Provider value={urqlClient}>{children}</Provider>
}
