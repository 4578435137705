import { proxy, useSnapshot } from 'valtio'

export interface SidebarAwareState {
  open: boolean
}

const defaultSidebarState: SidebarAwareState = {
  open: true,
}

interface Store {
  sidebarState: SidebarAwareState
}

export const SidebarAwareStore = proxy<Store>({ sidebarState: defaultSidebarState })

export const useSidebarAwareStateFromStore = (): SidebarAwareState => {
  const sidebarStore = useSnapshot(SidebarAwareStore)
  return sidebarStore.sidebarState as SidebarAwareState
}

export const SidebarStoreActions = {
  setOpen(open: boolean) {
    SidebarAwareStore.sidebarState.open = open
  },
}
