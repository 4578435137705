import { ExtractRouteParams, withParams } from 'src/routing/routing-utils'
import { ReportingIndexPage } from 'src/screens/reporting/index/ReportingIndexPage'
import { KapReportingPage } from 'src/screens/reporting/report/kap/KapReportingPage'
import { PfKapReportingPage } from 'src/screens/reporting/report/pf-kap/PfKapReportingPage'
import { PfPgvReportingPage } from 'src/screens/reporting/report/pf-pgv/PfPgvReportingPage'

const ReportingIndex = {
  path: 'reporting',
  Component: ReportingIndexPage,
  roles: [
    'PD-GFCH_PF-KAP_COORDINATOR',
    'PD-GFCH_PF-KAP_CONTRIBUTOR',
    'PD-GFCH_PF-KAP_READER',
    'PD-GFCH_PF-PGV_COORDINATOR',
    'PD-GFCH_PF-PGV_CONTRIBUTOR',
    'PD-GFCH_PF-PGV_READER',
    'PD-GFCH_KAP_COORDINATOR',
    'PD-GFCH_KAP_CONTRIBUTOR',
    'PD-GFCH_KAP_READER',
    'PD-CANTON_KAP_CONTRIBUTOR',
    'PD-CANTON_KAP_READER',
  ],
} as const

const PfKapReportingRoot = {
  path: 'reporting/type/pf-kap',
  Component: PfKapReportingPage,
  roles: ['PD-GFCH_PF-KAP_COORDINATOR', 'PD-GFCH_PF-KAP_CONTRIBUTOR', 'PD-GFCH_PF-KAP_READER'],
}

const PfPgvReportingRoot = {
  path: 'reporting/type/pf-pgv',
  Component: PfPgvReportingPage,
  roles: ['PD-GFCH_PF-PGV_COORDINATOR', 'PD-GFCH_PF-PGV_CONTRIBUTOR', 'PD-GFCH_PF-PGV_READER'],
}

const KapReportingRoot = {
  path: 'reporting/type/kap',
  Component: KapReportingPage,
  roles: [
    'PD-GFCH_KAP_COORDINATOR',
    'PD-GFCH_KAP_CONTRIBUTOR',
    'PD-GFCH_KAP_READER',
    'PD-CANTON_KAP_CONTRIBUTOR',
    'PD-CANTON_KAP_READER',
  ],
}

export const ReportingRoutes = {
  ReportingIndex: ReportingIndex,
  PfKapReportingRoot: {
    ...PfKapReportingRoot,
    params: (props: ExtractRouteParams<typeof PfKapReportingRoot.path>) =>
      withParams(`/${PfKapReportingRoot.path}`)(props),
  },
  PfPgvReportingRoot: {
    ...PfPgvReportingRoot,
    params: (props: ExtractRouteParams<typeof PfPgvReportingRoot.path>) =>
      withParams(`/${PfPgvReportingRoot.path}`)(props),
  },
  KapReportingRoot: {
    ...KapReportingRoot,
    params: (props: ExtractRouteParams<typeof KapReportingRoot.path>) => withParams(`/${KapReportingRoot.path}`)(props),
  },
}
