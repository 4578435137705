import {
  Box,
  Chip,
  FilledInput,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  SelectProps,
} from '@mui/material'
import { sortBy } from 'lodash'
import { ReactElement, useContext, useMemo } from 'react'
import { Field } from 'react-final-form'
import { useMessageSource } from 'src/i18n/useMessageSource'
import { TYPOGRAPHY_MIXIN } from 'src/shared/constants/styling-constants'
import { FinalFormInput, Option } from 'src/shared/form/control/index'
import styled, { DefaultTheme, ThemeContext } from 'styled-components/macro'

type MuiSelectPropsToBeExcluded = FinalFormInput | 'renderValue' | 'input'

type MultiSelectFieldProps = Omit<SelectProps, MuiSelectPropsToBeExcluded> & {
  name: string
  label: string
  options: Option[]
  validate?: any
  required?: boolean
  unavailableOptionsMessage?: string
}

// we want to store the values in an array, but in case of a browser autofill
// the value will be 'value1,value2,value3` as string
type MultiSelectFieldValueType = Array<string | number | null>

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 5.5 + ITEM_PADDING_TOP,
    },
  },
}

const FormControlStyled = styled(FormControl)<{ $disabled?: boolean }>`
  &:hover .MuiChip-filledDefault {
    background-color: ${({ $disabled, theme }) =>
      $disabled ? theme.colors.action.selected : theme.colors.action.selected};
  }
  .MuiChip-filledDefault {
    background-color: ${({ $disabled, theme }) =>
      $disabled ? theme.colors.action.selected : theme.colors.grey.chip.main};
  }
  // Styles to increase the input height to min 1 row of selected chips
  & .MuiInputBase-root {
    min-height: 77px;
  }
  & .MuiSelect-select {
    min-height: 44px;
  }
  & .MuiFormLabel-root {
    top: 11px;
    &.MuiInputLabel-shrink {
      transform: translate(12px, -4px) scale(0.75);
    }
  }
`

const BoxStyled = styled(Box)`
  margin-top: ${({ theme }) => theme.spacing(0.5)};
  display: flex;
  flex-wrap: wrap;
`

const ChipStyled = styled(Chip)`
  ${TYPOGRAPHY_MIXIN.body1};
  margin: ${({ theme }) => theme.spacing(0.5)} ${({ theme }) => theme.spacing(0.5)} ${({ theme }) => theme.spacing(0.5)}
    0;
`

const getStyles = (
  singleValue: string | number | null,
  allSelectedValues: MultiSelectFieldValueType,
  theme: DefaultTheme,
) => {
  if (allSelectedValues) {
    return {
      fontWeight: allSelectedValues.find((a) => a === singleValue)
        ? theme.typography.subtitle2.fontWeight
        : theme.typography.body1.fontWeight,
    }
  }
  return { fontWeight: theme.typography.body1.fontWeight }
}

export const MultiSelectField = (props: MultiSelectFieldProps): ReactElement => {
  const { name, unavailableOptionsMessage, validate, options, label, required } = props
  const { getMessage } = useMessageSource()
  const sortedOptions = useMemo(() => sortBy(options, ['sortNumber']), [options])
  const theme = useContext(ThemeContext as React.Context<DefaultTheme>)
  return (
    <Field
      validate={validate}
      name={name}
      allowNull
      render={({ input: { value, onBlur, onFocus, onChange }, meta: { error: errorObject, touched } }) => {
        const error = errorObject && getMessage(errorObject.errorKey, errorObject.params)

        const invalid = Boolean(touched && error)
        const defaultNoOptionsMessage = getMessage('label.options.none')
        const resolvedNoOptionsMessage = unavailableOptionsMessage || defaultNoOptionsMessage
        const noOptions = options.length === 0 ? resolvedNoOptionsMessage : null
        // https://final-form.org/docs/react-final-form/types/FieldProps#allownull
        // if there is no value for the field RFF will pass an empty string '' as a value
        // to ensure that the input is controlled -> that's why we convert it to an empty array in that case
        const resolvedValue = (typeof value as MultiSelectFieldValueType & string) === 'string' ? [] : value
        return (
          <FormControlStyled variant="filled" error={!!(invalid && error)} $disabled={props.disabled}>
            <InputLabel required={required}>{label}</InputLabel>
            <Select<MultiSelectFieldValueType>
              readOnly={options.length === 0}
              multiple
              value={resolvedValue}
              onBlur={onBlur}
              onFocus={onFocus}
              disabled={props.disabled}
              onChange={(event: SelectChangeEvent<MultiSelectFieldValueType>): void => {
                const {
                  target: { value },
                } = event
                // the value will have all selected values as an array
                // but in case of a browser autofill the value will be 'value1,value2,value3` as string
                const resolvedValue = typeof value === 'string' ? value.split(',') : value
                onChange(resolvedValue)
              }}
              input={<FilledInput error={invalid} />}
              renderValue={(selected) => (
                <BoxStyled>
                  {sortedOptions
                    .filter((o) => selected.includes(o.value))
                    .map((selectedOption) => {
                      if (selectedOption) {
                        return <ChipStyled key={selectedOption.value} label={selectedOption.label} />
                      }
                      return null
                    })}
                </BoxStyled>
              )}
              MenuProps={MenuProps}
            >
              {sortedOptions?.map((o) => (
                <MenuItem
                  key={o.value}
                  value={o?.value ?? ''}
                  style={getStyles(o.value, value, theme)}
                  disabled={o?.disabled}
                >
                  {o.label}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText>{(invalid && error) || noOptions || ' '}</FormHelperText>
          </FormControlStyled>
        )
      }}
    />
  )
}
