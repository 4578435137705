import { Card, CardContent, Tooltip } from '@mui/material'
import { Mutator } from 'final-form'
import combinedQuery from 'graphql-combine-query'
import { get, merge } from 'lodash'
import { Fragment, ReactElement, useEffect, useMemo, useRef, useState } from 'react'
import { Form } from 'react-final-form'
import {
  Feature_Base,
  Feature_Base_Bool_Exp,
  Feature_Config,
  Feature_Config_Bool_Exp,
  Feature_Type_Config,
  Feature_Value,
  Kap_Measure,
  Mutation_Root,
  Project_Base,
  Query_Root,
} from 'src/@types/graphql'
import { useMessageSource } from 'src/i18n/useMessageSource'
import {
  bulkSaveFeatures,
  queryFeatureBase,
  queryFeaturesByFeatureType,
} from 'src/screens/shared/feature/featureBaseQueries'
import {
  FeatureBaseEditUtils,
  FormValues,
  RenderFeatureGroups,
} from 'src/screens/shared/feature/utils/FeatureBaseEditUtils'
import { isFeatureSelectionInvalid } from 'src/screens/shared/feature/utils/FeatureValidationUtils'
import { SaveAndBackButton, SaveButton } from 'src/shared/button/Buttons'
import { TEXT_LENGTH } from 'src/shared/constants/constants'
import { CheckboxGroupField } from 'src/shared/form/control/CheckboxGroupField'
import { TextField } from 'src/shared/form/control/TextField'
import { DirtyFormSpy } from 'src/shared/form/dirty/DirtyFormSpy'
import { createDecorators } from 'src/shared/form/utils/decorators'
import { composeValidators, maxChar, required } from 'src/shared/form/validation/validators'
import { InfoIcon } from 'src/shared/icons/Icons'
import { PageLayout } from 'src/shared/layout/PageLayout'
import { ScreenLayout } from 'src/shared/layout/ScreenLayout'
import { HelpAndInstructions } from 'src/shared/presentation/HelpAndInstructions'
import { useNotificationService } from 'src/shared/utils/NotificationService'
import { useUserLocale } from 'src/user/UserContext'
import styled from 'styled-components/macro'
import { CombinedError, useClient } from 'urql'

const CardContentStyled = styled(CardContent)`
  padding: 0;
  &:last-child {
    padding-bottom: ${({ theme }) => theme.spacing(0.5)};
  }
`

const InfoIconStyled = styled(InfoIcon)`
  margin-left: ${({ theme }) => theme.spacing(1)};
`

type originType = 'SAVE' | 'SAVE_AND_BACK'

interface FeatureBaseEditProps {
  onBack: (featureGroupConfigCode: string) => void
  onSaveCallback?: () => void
  featureBaseWhere: Feature_Base_Bool_Exp
  featureConfigWhere: Feature_Config_Bool_Exp
  featureTypeId: number
  requiredSelection?: boolean
  relatedEntity?: Kap_Measure | Project_Base
}

const decorators = createDecorators()

export const FeatureBaseEdit = ({
  onBack,
  featureBaseWhere,
  featureConfigWhere,
  featureTypeId,
  requiredSelection,
  onSaveCallback,
  relatedEntity,
}: FeatureBaseEditProps): ReactElement => {
  const notificationService = useNotificationService()
  const locale = useUserLocale()
  const urqlClient = useClient()
  const { getMessage } = useMessageSource()

  const originRef = useRef<originType>('SAVE')
  const [serverSavedValues, setServerSavedValues] = useState<FormValues | undefined>(undefined)
  const [initialFormValues, setInitialFormValues] = useState<FormValues | undefined>(undefined)
  const [serverData, setServerData] = useState<{
    featureBase: Feature_Base
    featureConfigs: Feature_Config[]
    featureTypeConfig: Feature_Type_Config
  }>()
  const [serverError, setServerError] = useState<CombinedError>()
  const [allFeatures, setAllFeatures] = useState<RenderFeatureGroups[]>([])

  const featureValidation = relatedEntity && relatedEntity?.factsheet !== null

  const relatedEntityFactsheetFeatures = useMemo(() => {
    return (
      relatedEntity?.factsheet?.feature_base?.feature_values.map((featureValue) => featureValue.feature_config.id) ?? []
    )
  }, [relatedEntity])

  useEffect(() => {
    const initData = async () => {
      const { document, variables } = combinedQuery('FeatureBasesAndFeatureConfigs')
        .add(queryFeatureBase, {
          featureBaseWhere: featureBaseWhere,
          featureTypeId: featureTypeId,
        })
        .add(queryFeaturesByFeatureType, {
          featureType: featureTypeId,
          featureConfigWhere: featureConfigWhere,
        })

      const { data, error } = await urqlClient
        .query<
          {
            feature_base: Query_Root['feature_base']
            feature_config: Query_Root['feature_config']
            feature_type_config: Query_Root['feature_type_config']
          },
          {
            featureBaseWhere: Feature_Base_Bool_Exp
            featureTypeId: number
            featureType: number
            featureConfigWhere: Feature_Config_Bool_Exp
          }
        >(document, variables!)
        .toPromise()

      if (data) {
        setServerData({
          featureConfigs: data.feature_config,
          featureTypeConfig: data.feature_type_config[0],
          featureBase: data.feature_base[0],
        })

        const serverFormValues = FeatureBaseEditUtils.adaptInitialValues(
          data?.feature_base?.[0]?.feature_values ?? [],
          data?.feature_config ?? [],
          false,
        )

        const initialFormValues = FeatureBaseEditUtils.adaptInitialValues(
          data?.feature_base?.[0]?.feature_values ?? [],
          data?.feature_config ?? [],
          true,
        )

        if (featureValidation) {
          const validSelectedFeatures = Object.values(initialFormValues)
            .flatMap((featureGroup) => featureGroup.features)
            .filter((featureConfigId) => relatedEntityFactsheetFeatures.includes(featureConfigId))

          const validSelectedOtherFeature = Object.values(initialFormValues)
            .map((featureGroup) => featureGroup.otherFeature)
            .filter((feature) => feature !== undefined && relatedEntityFactsheetFeatures.includes(feature.id))
            .map((feature) => feature?.id)

          const validFeatureConfigs = data?.feature_config.filter(
            (feature) => validSelectedFeatures.includes(feature.id) || validSelectedOtherFeature.includes(feature.id),
          )

          const validServerFormValues = FeatureBaseEditUtils.adaptInitialValues(
            data?.feature_base?.[0]?.feature_values ?? [],
            validFeatureConfigs,
            false,
          )

          const validInitialFormValues = FeatureBaseEditUtils.adaptInitialValues(
            data?.feature_base?.[0]?.feature_values ?? [],
            validFeatureConfigs,
            true,
          )

          setServerSavedValues(validServerFormValues)
          setInitialFormValues(validInitialFormValues)
        } else {
          setServerSavedValues(serverFormValues)
          setInitialFormValues(initialFormValues)
        }
      } else {
        setServerError(error)
      }
    }

    initData()
  }, [
    urqlClient,
    featureTypeId,
    featureBaseWhere,
    featureConfigWhere,
    featureValidation,
    relatedEntity,
    relatedEntityFactsheetFeatures,
  ])

  useEffect(() => {
    if (serverError) {
      notificationService.operationFailed(serverError)
    }
    if (serverData?.featureConfigs) {
      setAllFeatures(
        FeatureBaseEditUtils.adaptForRendering(
          serverData.featureConfigs,
          serverData.featureBase?.feature_values ?? [],
          locale,
          featureValidation,
          serverData?.featureTypeConfig,
          relatedEntity,
        ),
      )
    }
  }, [
    locale,
    notificationService,
    serverData?.featureConfigs,
    serverData?.featureBase,
    serverError,
    relatedEntity,
    featureTypeId,
    featureValidation,
    serverData?.featureTypeConfig,
  ])

  const updateOtherFeature: Mutator<FormValues, Partial<FormValues>> = (args, state, { changeValue }) => {
    const [groupCode, checked, featureId] = args
    changeValue(state, `${groupCode}.otherFeature.id`, () => featureId)
    if (
      !checked ||
      (relatedEntity &&
        serverData &&
        !isFeatureSelectionInvalid(serverData?.featureTypeConfig, relatedEntity?.factsheet, featureId))
    ) {
      changeValue(state, `${groupCode}.otherFeature.text`, () => '')
    } else {
      // you can't focus a field that's been disabled -> we schedule the focusing to be done in the next microtask
      setTimeout(() => document.getElementById(`${groupCode}.otherFeature.text`)?.focus(), 0)
    }
  }

  const onSubmit = (origin: originType) => (_: any) => {
    // trigger submit event
    if (originRef) {
      originRef.current = origin
    }
    document
      .getElementById('features-base-form')
      ?.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }))
  }

  const handleSubmitLocal = async (values: FormValues): Promise<any> => {
    const selectedItems = Object.keys(values).flatMap((key) => values[key].features)
    const otherFeatures = Object.keys(values)
      .flatMap((key) => values[key].otherFeature)
      .filter((value) => value != undefined && value.selected === true)

    if (selectedItems.length === 0 && otherFeatures.length === 0 && requiredSelection) {
      notificationService.error(getMessage('notification.required.features'))
      return
    }

    const serverSavedOtherFeatures =
      serverSavedValues &&
      Object.values(serverSavedValues)
        .map((fg) => fg.otherFeature)
        .flat()
        .filter(Boolean)

    const invalidOtherFeatures =
      serverSavedOtherFeatures?.filter(
        (savedFeature) => savedFeature && relatedEntityFactsheetFeatures.indexOf(savedFeature.id) < 0,
      ) ?? []

    const featuresToBeAdded = FeatureBaseEditUtils.createFeatureValues(
      values,
      initialFormValues || ({} as FormValues),
      serverData!.featureBase!.id,
      false,
    )

    // in order to check what should be marked for deletion, we compare against the saved data
    const featuresToBeDeleted = FeatureBaseEditUtils.createFeatureValues(
      values,
      serverSavedValues || ({} as FormValues),
      serverData!.featureBase!.id,
      true,
      invalidOtherFeatures.length > 0,
    ).map((x: Feature_Value) => x.feature_config_id)

    const { error } = await urqlClient
      .mutation<
        {
          insert_feature_value: Mutation_Root['insert_feature_value']
          delete_feature_value: Mutation_Root['delete_feature_value']
        },
        {
          featureBaseId: number | undefined
          featuresToBeAdded: Feature_Value[]
          featuresToBeDeleted: number[]
        }
      >(bulkSaveFeatures, {
        featureBaseId: serverData?.featureBase.id,
        featuresToBeAdded,
        featuresToBeDeleted,
      })
      .toPromise()

    if (error) {
      notificationService.operationFailed()
    } else {
      setInitialFormValues(values)
      const newServerData = {} as typeof serverData
      merge(newServerData, serverData)
      setServerData(newServerData)
      notificationService.changesSaved()
      if (onSaveCallback) {
        onSaveCallback()
      }
      const featureGroupConfigCode = serverData?.featureTypeConfig?.code ?? ''
      if (originRef.current === 'SAVE_AND_BACK') {
        onBack(featureGroupConfigCode)
      }
    }
  }

  const disabledOtherFeature = (featureConfigId: number) =>
    featureValidation &&
    isFeatureSelectionInvalid(serverData?.featureTypeConfig, relatedEntity?.factsheet, featureConfigId)

  return (
    <>
      <ScreenLayout
        title={getMessage('label.edit.feature.type', [get(serverData?.featureTypeConfig?.names, locale, '')])}
        hasSecondLevelNavigation={false}
        onBack={() => onBack(serverData?.featureTypeConfig?.code ?? '')}
        actions={
          <>
            <SaveAndBackButton origin="header" onClick={onSubmit('SAVE_AND_BACK')} />
            <SaveButton origin="header" onClick={onSubmit('SAVE')} />
          </>
        }
      >
        <PageLayout>
          <HelpAndInstructions
            labelKey={get(serverData?.featureTypeConfig?.description, locale, '')}
            defaultExpansion
          />
          <Card>
            <CardContentStyled>
              <>
                {initialFormValues && (
                  <Form
                    initialValues={initialFormValues}
                    onSubmit={handleSubmitLocal}
                    decorators={decorators}
                    mutators={{
                      updateOtherFeature,
                    }}
                    render={({
                      handleSubmit,
                      values,
                      form: {
                        mutators: { updateOtherFeature },
                        resetFieldState,
                      },
                    }) => {
                      return (
                        <form onSubmit={handleSubmit} id="features-base-form">
                          {allFeatures.map((x: RenderFeatureGroups, i: number) => {
                            return (
                              (x.features.length || x.otherFeature) && (
                                <Fragment key={i}>
                                  <CheckboxGroupField name={`${x.code}.features`} data={x.features} label={x.name} />
                                  {x.otherFeature && (
                                    <CheckboxGroupField
                                      name={`${x.code}.otherFeature.selected`}
                                      data={{
                                        value: x.otherFeature.selected,
                                      }}
                                      onClick={(e) => {
                                        // we intercept the click call so we can clear the corresponding Other TextField
                                        const target = e.target as HTMLFormElement
                                        const checked = target.checked
                                        updateOtherFeature(x.code, checked, x.otherFeature?.id)
                                        resetFieldState(`${x.code}.otherFeature.text`)
                                      }}
                                      disabled={disabledOtherFeature(x.otherFeature.id)}
                                      $withTextField={true}
                                    >
                                      {/* inspired by: https://final-form.org/docs/react-final-form/types/FieldProps#validate */}
                                      <TextField
                                        key={values[x.code]?.otherFeature?.selected ? 1 : 0}
                                        name={`${x.code}.otherFeature.text`}
                                        label={x.otherFeature.label}
                                        id={`${x.code}.otherFeature.text`}
                                        validate={
                                          values[x.code]?.otherFeature?.selected
                                            ? composeValidators(required(), maxChar(TEXT_LENGTH.S))
                                            : () => {}
                                        }
                                        disabled={!values[x.code]?.otherFeature?.selected ?? false}
                                        required={values[x.code]?.otherFeature?.selected}
                                      />
                                      {x.otherFeature.tooltip && (
                                        <Tooltip title={x.otherFeature.tooltip ?? ''}>
                                          <InfoIconStyled />
                                        </Tooltip>
                                      )}
                                    </CheckboxGroupField>
                                  )}
                                </Fragment>
                              )
                            )
                          })}
                          <DirtyFormSpy />
                        </form>
                      )
                    }}
                  />
                )}
              </>
            </CardContentStyled>
          </Card>
        </PageLayout>
      </ScreenLayout>
    </>
  )
}
