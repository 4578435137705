import { Button } from '@mui/material'
import { ReactElement, useCallback, useEffect, useState } from 'react'
import { Mutation_Root, Project_Assessment, Query_Root } from 'src/@types/graphql'
import { useMessageSource } from 'src/i18n/useMessageSource'
import { ROUTES } from 'src/routing/routes'
import { resolveApplicationAssessmentDetailsRoot } from 'src/routing/routing-utils'
import {
  queryAssessmentSummaryById,
  updateAssessmentStatusById,
} from 'src/screens/shared/application/assessment/details/summary/assessmentSummaryQueries'
import { SummarySection } from 'src/screens/shared/application/common/SummarySection'
import { getProjectAssessmentById } from 'src/screens/shared/assessment-criteria/assessmentQueries'
import { ValidationListItemProps } from 'src/screens/shared/common/SummaryValidationUtils'
import { usePermissionsForProjectAssessment } from 'src/service/security/PermissionHook'
import { EditButton } from 'src/shared/button/Buttons'
import { ASSESSMENT_STATUS } from 'src/shared/constants/assessment-constants'
import { PROJECT } from 'src/shared/constants/constants'
import { PageLayout } from 'src/shared/layout/PageLayout'
import { ScreenLayout } from 'src/shared/layout/ScreenLayout'
import { ProjectExportMenu } from 'src/shared/menu/ProjectExportMenu'
import { SecondaryConfirmationModalDialog } from 'src/shared/modal-dialog/SecondaryConfirmationModalDialog'
import { useDelayedNavigate } from 'src/shared/utils/hooks/navigation-hooks'
import { useNotificationService } from 'src/shared/utils/NotificationService'
import { resolveProcessToLowerCase, Utils } from 'src/shared/utils/Utils'
import { useClient, useQuery } from 'urql'

interface Props {
  baseUrl: '/pf-kap' | '/pf-pgv'
  projectId: number
  assessmentId: number
}

export const ApplicationAssessmentSummary = ({ baseUrl, projectId, assessmentId }: Props): ReactElement => {
  const { getMessage } = useMessageSource()
  const process = resolveProcessToLowerCase(baseUrl)
  const navigate = useDelayedNavigate()
  const [validationViolations, setValidationViolations] = useState<ValidationListItemProps[]>([])
  const projectType = Utils.resolveProcess(baseUrl)
  const urqlClient = useClient()
  const [projectAssessment, setProjectAssessment] = useState<Project_Assessment>()
  const [assessmentFinalizeConfirmationOpen, setAssessmentFinalizeConfirmationOpen] = useState(false)
  const notificationService = useNotificationService()

  const {
    loading,
    canEdit,
    metadata: { projectBaseId },
  } = usePermissionsForProjectAssessment(projectType, projectId, assessmentId)

  const [{ data }] = useQuery<{
    project_assessment: Query_Root['project_assessment']
  }>({
    query: getProjectAssessmentById,
    variables: { assessmentId, projectBaseId },
  })

  const firstName = data?.project_assessment[0]?.user?.first_name
  const lastName = data?.project_assessment[0]?.user?.last_name
  const email = data?.project_assessment[0]?.user?.email
  const userFullName = !firstName ? email : `${firstName} ${lastName}`

  const validateAssessment = useCallback(
    (projectAssessment: Project_Assessment): ValidationListItemProps[] => {
      const validationViolations: ValidationListItemProps[] = []
      if (projectAssessment.recommendation === null) {
        const violation = {
          message: getMessage('validation.fill.out.section', [
            getMessage('label.navigation.assessment.recommendation'),
            getMessage('label.assessment.personal.recommendation'),
          ]),
          tab: 'recommendation',
          section: 'personal-recommendation',
        }
        validationViolations.push(violation)
      }
      return validationViolations
    },
    [getMessage],
  )

  useEffect(() => {
    const initData = async () => {
      const { data } = await urqlClient
        .query<{ project_assessment: Query_Root['project_assessment'] }>(queryAssessmentSummaryById, { assessmentId })
        .toPromise()

      if (data && data.project_assessment) {
        const projectAssessment = data.project_assessment[0]
        setProjectAssessment(projectAssessment)
        const validationViolations = validateAssessment(projectAssessment)
        setValidationViolations(validationViolations)
      }
    }
    initData()
  }, [assessmentId, getMessage, urqlClient, validateAssessment])

  const backHandler = () => {
    if (projectType === PROJECT.PF_KAP) {
      navigate(ROUTES.PfKapApplicationAssessment.params({ projectId }))
    } else {
      navigate(ROUTES.PfPgvApplicationAssessment.params({ projectId }))
    }
  }

  const summaryCommentsEditHandler = () => {
    navigate(
      resolveApplicationAssessmentDetailsRoot(baseUrl).nested.SummaryCommentEdit.params({ projectId, assessmentId }),
    )
  }

  const openConfirmationModalDialog = () => {
    setAssessmentFinalizeConfirmationOpen(true)
  }

  const cancelAssessmentFinalization = () => {
    setAssessmentFinalizeConfirmationOpen(false)
  }

  const confirmAssessmentFinalization = async () => {
    const { data, error } = await urqlClient
      .mutation<{ update_project_assessment: Mutation_Root['update_project_assessment'] }>(updateAssessmentStatusById, {
        assessmentId,
        status: ASSESSMENT_STATUS.DONE,
        version: projectAssessment?.version,
      })
      .toPromise()

    if (error || data?.update_project_assessment?.affected_rows !== 1) {
      notificationService.operationFailed()
    } else {
      const newVersion = data?.update_project_assessment.returning[0]?.version
      const newStatus = data?.update_project_assessment.returning[0]?.status
      const finalizedProjectAssessment = {
        ...projectAssessment,
        version: newVersion,
        status: newStatus,
      } as Project_Assessment
      setProjectAssessment(finalizedProjectAssessment)
      setAssessmentFinalizeConfirmationOpen(false)
      notificationService.changesSaved()
    }
  }

  const handleViolationClick = (validationItem: ValidationListItemProps) => {
    const route = validationItem.root
      ? validationItem.root
      : resolveApplicationAssessmentDetailsRoot(baseUrl).params({ projectId, assessmentId })

    navigate(
      `${route}${validationItem.tab ? '/' + validationItem.tab : ''}${
        validationItem.section ? `#${validationItem.section}` : ''
      }`,
    )
  }

  return (
    <ScreenLayout
      title={`${getMessage('label.navigation.application.assessment')}: ${userFullName || ''}`}
      onBack={backHandler}
      actions={<ProjectExportMenu process={projectType} entityId={projectBaseId as number} />}
    >
      {!loading && projectAssessment && (
        <PageLayout>
          <SummarySection
            title={getMessage('label.assessment.summary.title')}
            actionsHelpLabel={`label.help.assessment.summary.actions.${process}`}
            status={
              <span>
                {getMessage('label.status')}: {getMessage(`label.assessment.status.${projectAssessment.status}`)}
              </span>
            }
            violations={validationViolations}
            violationClickHandler={handleViolationClick}
            actionsCompleteMessage={getMessage('label.assessment.summary.check.complete')}
            statusActions={
              projectAssessment.status === ASSESSMENT_STATUS.OPEN && (
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={openConfirmationModalDialog}
                  disabled={validationViolations.length > 0}
                >
                  {getMessage('button.set.to.done')}
                </Button>
              )
            }
            commentsHelpLabel={`label.help.assessment.summary.comments.${process}`}
            commentActions={<EditButton onClick={summaryCommentsEditHandler} hidden={!canEdit} />}
            comment={projectAssessment.summary_comment ?? null}
          />
          <SecondaryConfirmationModalDialog
            open={assessmentFinalizeConfirmationOpen}
            onCancel={cancelAssessmentFinalization}
            onConfirm={confirmAssessmentFinalization}
            titleKey="label.assessment.set.to.done.confirm.title"
            confirmButtonKey="button.set.to.done"
          >
            {getMessage('label.assessment.set.to.done.confirm.body')}
          </SecondaryConfirmationModalDialog>
        </PageLayout>
      )}
    </ScreenLayout>
  )
}
