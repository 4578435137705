import { useContext } from 'react'
import { HelpAndInstructionLinksContext } from './HelpAndInstructionLinksContext'

export const useHelpAndInstructionLinks = () => {
  const context = useContext(HelpAndInstructionLinksContext)

  if (context === undefined) {
    throw new Error('useHelpAndInstructionLinks must be used within a HelpAndInstructionLinksContext')
  }

  return context
}
