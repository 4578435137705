import { ReactElement } from 'react'
import { useParams } from 'react-router'
import { Feature_Base_Bool_Exp, Feature_Config_Bool_Exp, Mutation_Root, Query_Root } from 'src/@types/graphql'
import { ROUTES } from 'src/routing/routes'
import { fetchFactsheetByIdQuery, updateFactsheetMutation } from 'src/screens/factsheets/factsheet/factsheetQueries'
import { FeatureBaseEdit } from 'src/screens/shared/feature/edit/FeatureBaseEdit'
import { useDelayedNavigate } from 'src/shared/utils/hooks/navigation-hooks'
import { useNotificationService } from 'src/shared/utils/NotificationService'
import { useClient, useQuery } from 'urql'

export const FactsheetFeaturesEditPage = (): ReactElement => {
  const { factsheetId, featureTypeId } = useParams()
  const navigate = useDelayedNavigate()
  const urqlClient = useClient()
  const notificationService = useNotificationService()

  const [{ fetching, data }] = useQuery<{
    factsheet_by_pk: Query_Root['factsheet_by_pk']
  }>({
    query: fetchFactsheetByIdQuery,
    variables: { id: parseInt(factsheetId as string) },
  })

  const factsheet = data?.factsheet_by_pk

  const onBack = (featureGroupConfigCode: string) => {
    const route = ROUTES.FactsheetDescriptionRoot.nested.FactsheetFeatures.params({ factsheetId })
    navigate(`${route}#${featureGroupConfigCode}`)
  }

  const factsheetFeatureBaseWhereClause: Feature_Base_Bool_Exp = {
    factsheets: { id: { _eq: parseInt(factsheetId as string) } },
  }

  const factsheetFeatureConfigWhereClause: Feature_Config_Bool_Exp = {
    levels: {
      _contains: [factsheet?.level],
    },
  }

  // ABJ: Updating the factsheet's feature update version field and trigger the validation check function
  const factsheetUpdate = async () => {
    const { error } = await urqlClient
      .mutation<{
        update_factsheet: Mutation_Root['update_factsheet']
      }>(updateFactsheetMutation, {
        id: parseInt(factsheetId as string),
        object: {
          feature_update_version: ++factsheet!.feature_update_version,
        },
      })
      .toPromise()

    if (error) {
      notificationService.operationFailed()
    }
  }

  return (
    <>
      {!fetching && (
        <FeatureBaseEdit
          featureBaseWhere={factsheetFeatureBaseWhereClause}
          featureConfigWhere={factsheetFeatureConfigWhereClause}
          onBack={onBack}
          featureTypeId={parseInt(featureTypeId as string)}
          requiredSelection
          onSaveCallback={factsheetUpdate}
        />
      )}
    </>
  )
}
