import { Box, Card, CardContent, Typography } from '@mui/material'
import { useParams } from 'react-router'
import { Application_Type, Query_Root } from 'src/@types/graphql'
import { useMessageSource } from 'src/i18n/useMessageSource'
import { ROUTES } from 'src/routing/routes'
import { getRoundInformationById } from 'src/screens/administration/round-management/round-management-queries'
import { RoundManagementUtils } from 'src/screens/administration/round-management/RoundManagementUtils'
import { EditButton } from 'src/shared/button/Buttons'
import { PageLayout } from 'src/shared/layout/PageLayout'
import { ScreenLayout } from 'src/shared/layout/ScreenLayout'
import { RoundManagementExportMenu } from 'src/shared/menu/RoundManagementExportMenu'
import { HelpAndInstructions } from 'src/shared/presentation/HelpAndInstructions'
import { ReadOnlySelection, ReadOnlyTextField } from 'src/shared/presentation/ReadOnly'
import { Section } from 'src/shared/presentation/Section'
import { S } from 'src/shared/styled/S'
import { DateUtils } from 'src/shared/utils/DateUtils'
import { useDelayedNavigate } from 'src/shared/utils/hooks/navigation-hooks'
import { useQuery } from 'urql'

export const RoundInformationPage = () => {
  const navigate = useDelayedNavigate()
  const { roundId } = useParams()
  const { getMessage } = useMessageSource()

  const [{ data }] = useQuery<{
    funding_round: Query_Root['funding_round']
    application_type: Query_Root['application_type']
  }>({
    query: getRoundInformationById,
    variables: { roundId },
  })

  const round_data = data?.funding_round?.[0]
  const roundName = round_data?.name ?? ''
  const applicationTypes = data?.application_type as Application_Type[]

  const onBackHandler = () => {
    navigate(`/${ROUTES.RoundManagementIndex.path}`)
  }

  const onEditHandler = () => {
    const route = ROUTES.RoundManagementDetailsRoot.nested.RoundInformationEdit.params({ roundId })
    navigate(route)
  }

  const recommendationAndDecisionClickHandler = () => {
    navigate(ROUTES.RoundManagementDetailsRoot.nested.CommitteeDecisionManagement.params({ roundId }))
  }

  return (
    <ScreenLayout title={roundName} onBack={onBackHandler} actions={<RoundManagementExportMenu />}>
      <PageLayout>
        <>
          {round_data && (
            <>
              <Section
                id="round-information"
                title={getMessage('label.navigation.round.information')}
                actionButton={<EditButton origin="content" color="primary" onClick={onEditHandler} />}
                helpAndInstructions={<HelpAndInstructions labelKey="label.help.round.information" />}
              >
                <Box mt={2}>
                  <Card>
                    <CardContent>
                      <ReadOnlyTextField text={getMessage('label.round.id')}>
                        {`${getMessage('label.round.id.character')}${round_data.id}`}
                      </ReadOnlyTextField>
                      <ReadOnlyTextField text={getMessage('label.round.name')}>{round_data.name}</ReadOnlyTextField>
                      <ReadOnlySelection text={getMessage('label.round.information.type')}>
                        {getMessage(
                          `label.round.type.${RoundManagementUtils.resolveRoundType(
                            round_data.process,
                            round_data.application_types,
                            applicationTypes,
                          )}`,
                        )}
                      </ReadOnlySelection>
                      <ReadOnlySelection text={getMessage('label.status')}>
                        {getMessage(
                          `label.round.status.${RoundManagementUtils.resolveRoundStatus(
                            DateUtils.parseDateGraphQL(round_data.start_date),
                            DateUtils.parseDateGraphQL(round_data.end_date),
                          )}`,
                        )}
                      </ReadOnlySelection>
                      <ReadOnlySelection text={getMessage('label.start.date')}>
                        {DateUtils.formatDate(DateUtils.parseDateGraphQL(round_data.start_date))}
                      </ReadOnlySelection>
                      <ReadOnlySelection text={getMessage('label.end.date')}>
                        {DateUtils.formatDate(DateUtils.parseDateGraphQL(round_data.end_date))}
                      </ReadOnlySelection>
                      <ReadOnlySelection text={getMessage('label.decision.date')} isLast>
                        {DateUtils.formatDate(DateUtils.parseDateGraphQL(round_data.decision_date))}
                      </ReadOnlySelection>
                    </CardContent>
                  </Card>
                </Box>
              </Section>
              <Section
                id="committee-decision-management"
                title={getMessage('label.committee.decision.management')}
                helpAndInstructions={<HelpAndInstructions labelKey="label.help.committee.decision.management" />}
              >
                <S.Card.Container key={round_data.id}>
                  <S.Card.Content
                    onClick={recommendationAndDecisionClickHandler}
                    tabIndex={0}
                    onKeyDown={(event: { key: string }) => {
                      if (['Enter', ' '].includes(event.key)) {
                        recommendationAndDecisionClickHandler()
                      }
                    }}
                  >
                    <Typography variant="subtitle2">{`${getMessage('label.recommendations.and.decisions.for')} ${
                      round_data.name
                    }`}</Typography>
                  </S.Card.Content>
                </S.Card.Container>
              </Section>
            </>
          )}
        </>
      </PageLayout>
    </ScreenLayout>
  )
}
