import { TextField as MuiTextField } from '@mui/material'
import FormControl from '@mui/material/FormControl'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { DatePicker, DatePickerProps } from '@mui/x-date-pickers/DatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { getYear, setYear } from 'date-fns'
import deLocale from 'date-fns/locale/de'
import enLocale from 'date-fns/locale/en-US'
import frLocale from 'date-fns/locale/fr'
import itLocale from 'date-fns/locale/it'
import { ReactElement } from 'react'
import { Field } from 'react-final-form'
import { useMessageSource } from 'src/i18n/useMessageSource'
import { FinalFormInput } from 'src/shared/form/control/index'
import { DateUtils } from 'src/shared/utils/DateUtils'
import { useUserLocale } from 'src/user/UserContext'

const LOCALE_MAP = {
  en: enLocale,
  de: deLocale,
  it: itLocale,
  fr: frLocale,
}

type MuiDatePickerPropsToBeExcluded =
  | FinalFormInput
  | 'autoOk'
  | 'maxDateMessage'
  | 'minDateMessage'
  | 'format'
  | 'error'
  | 'renderInput'
  | 'rawValue'
  | 'openPicker'

type DatePickerVariant = 'standard' | 'filled' | 'outlined' | undefined

type DateTimePickerFieldProps = Omit<DatePickerProps<Date | null, Date>, MuiDatePickerPropsToBeExcluded> & {
  name: string
  label: string
  validate?: any
  required?: boolean
  variant?: DatePickerVariant
  yearOnly?: 'start' | 'end'
}

export const DateTimePickerField = (props: DateTimePickerFieldProps): ReactElement => {
  const { getMessage } = useMessageSource()
  const locale = useUserLocale()
  const { required, name, validate, variant, yearOnly, ...forwardProps } = props
  return (
    <Field
      name={name}
      validate={validate}
      render={({ input: { value, onChange, onFocus, onBlur }, meta: { touched, error: errorObject } }) => {
        const localOnChange = (date: Date | null, dateAsString: string | null | undefined) => {
          if (dateAsString) {
            if (yearOnly) {
              if (yearOnly === 'start') {
                dateAsString =
                  dateAsString.length < 4
                    ? dateAsString
                    : DateUtils.formatDate(setYear(DateUtils.MIN_DATE, Number(dateAsString)))
              } else if (yearOnly === 'end') {
                dateAsString =
                  dateAsString.length < 4
                    ? dateAsString
                    : DateUtils.formatDate(setYear(DateUtils.MAX_DATE, Number(dateAsString)))
              }
            }
            onChange(dateAsString)
          } else {
            if (date === null) {
              onChange(null)
            } else {
              if (yearOnly) {
                if (yearOnly === 'start') {
                  date = setYear(DateUtils.MIN_DATE, getYear(date))
                } else if (yearOnly === 'end') {
                  date = setYear(DateUtils.MAX_DATE, getYear(date))
                }
              }
              onChange(DateUtils.formatDate(date))
            }
          }
        }

        let resolvedValue: Date | null
        const parsedDateValue = DateUtils.parseDate(value)
        const yearValue = getYear(parsedDateValue)

        if (value) {
          if (yearOnly === 'start') {
            resolvedValue = setYear(DateUtils.MIN_DATE, yearValue)
          } else if (yearOnly === 'end') {
            resolvedValue = setYear(DateUtils.MAX_DATE, yearValue)
          } else {
            resolvedValue = parsedDateValue
          }
        } else {
          resolvedValue = null
        }

        const error = errorObject && getMessage(errorObject.errorKey, errorObject.params)
        const invalid = Boolean(touched && error)
        return (
          <FormControl error={invalid} onFocus={onFocus} onBlur={onBlur} fullWidth>
            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={LOCALE_MAP[locale]}>
              <DatePicker<Date | null, Date>
                {...forwardProps}
                mask={yearOnly ? '____' : '__.__.____'}
                minDate={DateUtils.MIN_DATE}
                maxDate={DateUtils.MAX_DATE}
                value={resolvedValue}
                onChange={localOnChange}
                inputFormat={yearOnly ? DateUtils.YEAR_FORMAT : DateUtils.DATE_FORMAT}
                views={yearOnly ? ['year'] : undefined}
                // we want to control when the error is shown from above
                // so we exclude the error & helperText that material-ui will show if the date is invalid
                // and rely on our own validation mechanism (dirty + error) to display the error
                renderInput={({ error: muiError, helperText: muiHelperText, ...rest }) => (
                  <MuiTextField
                    name={name}
                    error={invalid}
                    helperText={(invalid && error) || ' '}
                    required={required}
                    variant={variant}
                    {...rest}
                  />
                )}
              />
            </LocalizationProvider>
          </FormControl>
        )
      }}
    />
  )
}
