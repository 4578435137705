import { Box } from '@mui/material'
import styled from 'styled-components/macro'

export const ReportGridContainer = styled(Box)`
  display: flex;
  gap: ${({ theme }) => theme.spacing(1)};

  & > div {
    flex: 1;
  }
`
