import { uniq } from 'lodash'
import { Application_Type, Maybe } from 'src/@types/graphql'
import {
  APPLICATION_TYPE,
  APPLICATION_TYPE_PF_PGV,
  APPLICATION_TYPE_TYPE,
  PROJECT,
} from 'src/shared/constants/constants'
import {
  PF_KAP_ROUND_TYPES,
  PF_PGV_ROUND_TYPES,
  ROUND_STATUS,
  ROUND_STATUS_TYPE,
  ROUND_TYPE,
  ROUND_TYPE_TYPE,
} from 'src/shared/constants/round-management-constants'
import { DateUtils } from 'src/shared/utils/DateUtils'

// TODO aboykov Should this file be moved to another directory?
const resolveRoundStatus = (startDate: Date, endDate: Date): ROUND_STATUS_TYPE => {
  const today = new Date()

  const isActiveByStartDate = DateUtils.before(startDate, today) || DateUtils.equals(startDate, today)
  const isActiveByEndDate = DateUtils.after(endDate, today) || DateUtils.equals(endDate, today)

  if (isActiveByStartDate && isActiveByEndDate) {
    return ROUND_STATUS.ACTIVE
  } else if (DateUtils.before(today, startDate)) {
    return ROUND_STATUS.UPCOMING
  } else {
    return ROUND_STATUS.CLOSED
  }
}

const resolvePFKapRoundType = (applicationTypeCode: string) => {
  if (applicationTypeCode === APPLICATION_TYPE.INNOVATION) {
    return ROUND_TYPE['INNOVATION_MULTIPLICATION']
  } else if (applicationTypeCode === APPLICATION_TYPE.MULTIPLICATION) {
    return ROUND_TYPE['INNOVATION_MULTIPLICATION']
  } else if (applicationTypeCode === APPLICATION_TYPE.OFFER_PROMOTION) {
    return ROUND_TYPE['OFFER_PROMOTION']
  }
}

const resolvePfPgvRoundType = (applicationTypeCode: string) => {
  switch (applicationTypeCode) {
    case APPLICATION_TYPE_PF_PGV.TRANSITION_FUNDING:
    case APPLICATION_TYPE_PF_PGV.DIRECT_COLLABORATION:
    case APPLICATION_TYPE_PF_PGV.EXTENSION:
      return ROUND_TYPE.TRANSITION_FUNDING_EXTENSION_DIRECT_COLLABORATION
    case APPLICATION_TYPE_PF_PGV.FUNDING_AREA_1:
    case APPLICATION_TYPE_PF_PGV.FUNDING_AREA_2:
    case APPLICATION_TYPE_PF_PGV.FUNDING_AREA_3:
    case APPLICATION_TYPE_PF_PGV.FUNDING_AREA_4:
      return ROUND_TYPE.FUNDING_AREA_1_2_3_4
  }
}

const resolveRoundType = (
  process: string,
  roundApplicationTypes: APPLICATION_TYPE_TYPE[],
  allApplicationTypes: Application_Type[],
): ROUND_TYPE_TYPE => {
  const resolveFunction = process === PROJECT.PF_KAP ? resolvePFKapRoundType : resolvePfPgvRoundType

  if (roundApplicationTypes) {
    return uniq(
      roundApplicationTypes.flatMap((roundType) =>
        allApplicationTypes
          .filter((appType) => appType.code === roundType)
          .map((appType) => resolveFunction(appType.code)),
      ),
    )[0] as ROUND_TYPE_TYPE
  } else {
    // For old 'PF PGV' round types
    return ROUND_TYPE.PF_PGV
  }
}

const resolveRoundProcess = (roundType: ROUND_TYPE_TYPE) => {
  if (PF_KAP_ROUND_TYPES.some((it) => it === roundType)) {
    return PROJECT.PF_KAP
  } else if (PF_PGV_ROUND_TYPES.some((it) => it === roundType)) {
    return PROJECT.PF_PGV
  }
}

const resolveApplicationTypeToRoundType = (
  applicationType: Application_Type,
): {
  roundType: Maybe<ROUND_TYPE_TYPE>
  code: Maybe<string[]>
} => {
  switch (applicationType.code) {
    // PF KAP ROUND TYPES
    case APPLICATION_TYPE.INNOVATION:
    case APPLICATION_TYPE.MULTIPLICATION:
      return {
        roundType: ROUND_TYPE.INNOVATION_MULTIPLICATION,
        code: [APPLICATION_TYPE.INNOVATION, APPLICATION_TYPE.MULTIPLICATION],
      }
    case APPLICATION_TYPE.OFFER_PROMOTION:
      return {
        roundType: ROUND_TYPE.OFFER_PROMOTION,
        code: [APPLICATION_TYPE.OFFER_PROMOTION],
      }

    // PF PGV ROUND TYPES
    case APPLICATION_TYPE_PF_PGV.TRANSITION_FUNDING:
    case APPLICATION_TYPE_PF_PGV.DIRECT_COLLABORATION:
    case APPLICATION_TYPE_PF_PGV.EXTENSION:
      return {
        roundType: ROUND_TYPE.TRANSITION_FUNDING_EXTENSION_DIRECT_COLLABORATION,
        code: [
          APPLICATION_TYPE_PF_PGV.TRANSITION_FUNDING,
          APPLICATION_TYPE_PF_PGV.DIRECT_COLLABORATION,
          APPLICATION_TYPE_PF_PGV.EXTENSION,
        ],
      }
    case APPLICATION_TYPE_PF_PGV.FUNDING_AREA_1:
    case APPLICATION_TYPE_PF_PGV.FUNDING_AREA_2:
    case APPLICATION_TYPE_PF_PGV.FUNDING_AREA_3:
    case APPLICATION_TYPE_PF_PGV.FUNDING_AREA_4:
      return {
        roundType: ROUND_TYPE.FUNDING_AREA_1_2_3_4,
        code: [
          APPLICATION_TYPE_PF_PGV.FUNDING_AREA_1,
          APPLICATION_TYPE_PF_PGV.FUNDING_AREA_2,
          APPLICATION_TYPE_PF_PGV.FUNDING_AREA_3,
          APPLICATION_TYPE_PF_PGV.FUNDING_AREA_4,
        ],
      }

    // DEFAULT
    default: {
      return {
        roundType: null,
        code: null,
      }
    }
  }
}

export const RoundManagementUtils = {
  resolveRoundStatus,
  resolveRoundType,
  resolveRoundProcess,
  resolveApplicationTypeToRoundType,
}
