import { gql } from 'urql'

export const queryFeatureValuesByFeatureBase = gql`
  query fetchFeatureValues($featureBaseWhere: feature_base_bool_exp!) {
    feature_value(where: { feature_base: $featureBaseWhere }) {
      feature_config_id
      other_description
      feature_config {
        id
        code
        sort_number
        feature_type_id
        names
        tooltips
        processes
        selection_type
        active
      }
    }
  }
`

export const queryFeatureConfigs = gql`
  query fetchFeatureConfigs($featureConfigWhere: feature_config_bool_exp!) {
    feature_config(where: $featureConfigWhere, order_by: { sort_number: asc }) {
      id
      code
      sort_number
      feature_type_id
      feature_type_config {
        sort_number
        code
        names
      }
      names
      tooltips
      processes
      selection_type
      active
    }
  }
`

export const queryFeatureBase = gql`
  query fetchFeatureBase($featureBaseWhere: feature_base_bool_exp!, $featureTypeId: Int!) {
    feature_base(where: $featureBaseWhere) {
      id
      feature_values(where: { feature_config: { feature_type_id: { _eq: $featureTypeId } } }) {
        other_description
        feature_config {
          id
          names
          selection_type
          code
          tooltips
          sort_number
          active
          feature_group_id
          feature_group_config {
            names
            code
          }
          feature_type_config {
            description
          }
        }
      }
    }
  }
`

export const queryFeatureTypes = gql`
  query {
    feature_type_config(order_by: { sort_number: asc }) {
      id
      names
      sort_number
      description
      code
      has_kap_factsheet_validation
    }
  }
`

export const queryFeaturesByFeatureType = gql`
  query fetchFeaturesByFeatureType($featureType: Int!, $featureConfigWhere: feature_config_bool_exp!) {
    feature_config(
      where: { _and: [{ feature_type_config: { id: { _eq: $featureType } } }, $featureConfigWhere] }
      order_by: { sort_number: asc }
    ) {
      id
      feature_group_id
      code
      names
      tooltips
      selection_type
      sort_number
      active
      feature_group_config {
        code
        names
      }
    }
    feature_type_config(where: { id: { _eq: $featureType } }) {
      names
      description
      code
      has_kap_factsheet_validation
    }
  }
`

export const bulkSaveFeatures = gql`
  mutation ($featureBaseId: Int!, $featuresToBeAdded: [feature_value_insert_input!]!, $featuresToBeDeleted: [Int!]) {
    delete_feature_value(
      where: { feature_config_id: { _in: $featuresToBeDeleted }, feature_base: { id: { _eq: $featureBaseId } } }
    ) {
      affected_rows
    }

    insert_feature_value(
      objects: $featuresToBeAdded
      on_conflict: {
        constraint: feature_value_feature_config_id_feature_base_id_uq
        update_columns: other_description
        where: { feature_base: { id: { _eq: $featureBaseId } } }
      }
    ) {
      affected_rows
    }
  }
`
export const queryFeatureBaseValuesWithKPIs = gql`
  query FetchFeatureBaseValuesWithKPIs(
    $projectBaseQuery: project_base_bool_exp
    $featureConfigKPIWhere: feature_config_bool_exp
  ) {
    feature_base(where: { project_base: $projectBaseQuery }) {
      id
      feature_values(
        where: { feature_config: $featureConfigKPIWhere }
        order_by: { feature_config: { sort_number: asc } }
      ) {
        id
        feature_config_id
        other_description
        feature_config {
          code
          names
          tooltips
          sort_number
          feature_type_config {
            sort_number
            code
            names
          }
        }
        feature_kpi {
          id
          target
        }
      }
    }
  }
`

export const bulkSaveProjectFeatureKpis = gql`
  mutation ($featureKpis: [feature_kpi_insert_input!]!) {
    insert_feature_kpi(
      objects: $featureKpis
      on_conflict: { constraint: feature_kpi_feature_value_id_uq, update_columns: target }
    ) {
      affected_rows
    }
  }
`
