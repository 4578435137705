import { Menu } from '@mui/material'
import { forwardRef, ReactNode, useImperativeHandle, useState } from 'react'
import { BaseButton } from 'src/shared/button/Buttons'
import { ExpandMoreIcon } from 'src/shared/icons/Icons'
import styled from 'styled-components/macro'

const MenuStyled = styled(Menu)`
  & .MuiPaper-root {
    min-width: 20rem;
  }

  & .MuiListItemText-root {
    flex: initial;
  }

  & .MuiListItemIcon-root {
    color: ${({ theme }) => theme.colors.text.secondary};
  }
`

interface Props {
  children: ReactNode
}

interface RefHandle {
  closeMenu: () => void
}

export const ExportMenu = forwardRef<RefHandle, Props>(({ children }, ref) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const open = Boolean(anchorEl)
  const handleMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  useImperativeHandle(ref, () => ({
    closeMenu() {
      handleClose()
    },
  }))

  return (
    <div>
      <BaseButton messageKey="button.export" endIcon={<ExpandMoreIcon />} onClick={handleMenu} />
      <MenuStyled
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          variant: 'menu',
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        elevation={4}
        keepMounted
      >
        {children}
      </MenuStyled>
    </div>
  )
})
