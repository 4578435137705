import { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { Mutation_Root, Query_Root } from 'src/@types/graphql'
import { useMessageSource } from 'src/i18n/useMessageSource'
import { ROUTES } from 'src/routing/routes'
import {
  insertOrganizationKapProgramQuery,
  updateKapProgramsByContactOrganizationOnConflictClause,
} from 'src/screens/kap/program/details/organization/kapOrganizationQueries'
import { kapOrganizationLabelDataFactory } from 'src/screens/kap/program/details/organization/view/KapOrganizationViewPage'
import { OrganizationForm } from 'src/screens/shared/organization/edit/OrganizationForm'
import { queryOrganizationTypes } from 'src/screens/shared/organization/organizationQueries'
import {
  initialValuesFactory,
  OrganizationFormValues,
  OrganizationType,
} from 'src/screens/shared/organization/utils/OrganizationFormUtils'
import { usePermissionsForKapProgram } from 'src/service/security/PermissionHook'
import { CreateButton } from 'src/shared/button/Buttons'
import { KAP_ORGANIZATION_TYPE, KAP_ORGANIZATION_TYPE_TYPE, PROJECT } from 'src/shared/constants/constants'
import { PageLayout } from 'src/shared/layout/PageLayout'
import { ScreenLayout } from 'src/shared/layout/ScreenLayout'
import { NotAuthorized } from 'src/shared/not-authorized/NotAuthorized'
import { HelpAndInstructions } from 'src/shared/presentation/HelpAndInstructions'
import { useDelayedNavigate } from 'src/shared/utils/hooks/navigation-hooks'
import { useNotificationService } from 'src/shared/utils/NotificationService'
import { useClient } from 'urql'

interface Props {
  kapOrganizationType: KAP_ORGANIZATION_TYPE_TYPE
}

export const KapOrganizationCreatePage = ({ kapOrganizationType }: Props) => {
  const { programId } = useParams()
  const { getMessage } = useMessageSource()

  const navigate = useDelayedNavigate()
  const urqlClient = useClient()
  const notificationService = useNotificationService()

  const program_id = parseInt(programId as string)

  const labelData = kapOrganizationLabelDataFactory(kapOrganizationType, 'add')
  const [initialValues, setInitialValues] = useState<OrganizationFormValues>(initialValuesFactory(program_id))
  const [organizationTypeOptions, setOrganizationTypeOptions] = useState<OrganizationType[]>([])

  const { loading, canEdit } = usePermissionsForKapProgram(program_id)

  useEffect(() => {
    const initApplicationTypeOptions = async () => {
      const { data } = await urqlClient
        .query<{ organization_type: Query_Root['organization_type'] }>(queryOrganizationTypes, { process: PROJECT.KAP })
        .toPromise()

      if (data && data.organization_type) {
        const organizationTypes = data.organization_type.map((organization_type) => {
          return {
            label: getMessage(organization_type.key),
            value: organization_type.id,
          }
        })
        setOrganizationTypeOptions(organizationTypes)
      } else {
        notificationService.operationFailed()
      }
    }

    initApplicationTypeOptions()
  }, [urqlClient, getMessage, notificationService])

  const onSubmit = () => {
    // trigger submit event
    document
      .getElementById('organization-form')
      ?.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }))
  }

  const onSaveHandler = async (values: OrganizationFormValues) => {
    const isContactOrganization = kapOrganizationType === KAP_ORGANIZATION_TYPE.CONTACT_ORGANIZATION
    const isResponsibleOrganization = kapOrganizationType === KAP_ORGANIZATION_TYPE.RESPONSIBLE_ORGANIZATION
    const isPartnerOrganization = kapOrganizationType === KAP_ORGANIZATION_TYPE.PARTNER_ORGANIZATION

    const mutationObject = {
      organization: {
        version: values.version,
        name: values.name,
        organization_type_id: values.typeId,
        department: values.department,
        address: values.address,
        additional_address: values.additionalAddress,
        postal_code: values.postalCode,
        city: values.city,
        phone: values.phone,
        email: values.email,
        website: values.website,
        kap_programs_by_contact_organization: isContactOrganization
          ? {
              // The data object must be valid with all non-null values, so that is why sample dummy data is used.
              // The conflict will always happen on the 'id', that's why 'on_conflict' will be triggered.
              data: [
                { id: values.entityId, dossier_id: 0, canton_code: '', modules: [], created_after_principles: false },
              ],
              on_conflict: updateKapProgramsByContactOrganizationOnConflictClause,
            }
          : undefined,
        kap_program_organizations:
          isResponsibleOrganization || isPartnerOrganization
            ? {
                data: [{ kap_program_id: values.entityId, type: kapOrganizationType }],
              }
            : undefined,
      },
    }

    const { error } = await urqlClient
      .mutation<{ insert_organization_one: Mutation_Root['insert_organization_one'] }>(
        insertOrganizationKapProgramQuery,
        mutationObject,
      )
      .toPromise()

    if (error) {
      notificationService.operationFailed()
      return Promise.reject()
    } else {
      setInitialValues(values)
      notificationService.changesSaved()
      onBack()
    }
  }

  const onBack = () => {
    const route = ROUTES.KapDetailsRoot.nested.Organization.params({ programId })

    navigate(`${route}#${kapOrganizationType.toLowerCase().replace('_', '-')}`)
  }

  if (!loading && !canEdit) {
    return <NotAuthorized inEditPage />
  }

  return (
    <ScreenLayout
      title={getMessage(labelData.titleLabel)}
      hasSecondLevelNavigation={false}
      onBack={onBack}
      actions={
        <>
          <CreateButton origin="header" onClick={onSubmit} />
        </>
      }
    >
      <PageLayout>
        {!loading && initialValues && organizationTypeOptions && (
          <>
            <HelpAndInstructions labelKey={labelData.helpTextLabel} defaultExpansion />
            <OrganizationForm
              projectType={PROJECT.KAP}
              initialValues={initialValues}
              organizationTypes={organizationTypeOptions}
              onSave={onSaveHandler}
            />
          </>
        )}
      </PageLayout>
    </ScreenLayout>
  )
}
