import { Box, Stack } from '@mui/material'
import { ReactElement, useMemo } from 'react'
import { Form } from 'react-final-form'
import { TextLength } from 'src/shared/constants/constants'
import { HtmlEditorField } from 'src/shared/form/control/HtmlEditorField'
import { DirtyFormSpy } from 'src/shared/form/dirty/DirtyFormSpy'
import { createDecorators } from 'src/shared/form/utils/decorators'
import { composeValidators, maxChar, required as requiredValidator } from 'src/shared/form/validation/validators'

interface Props {
  id: number
  version: number
  value: string
  onSave: (values: FormValues) => Promise<void>
  textLength: TextLength
  label?: string
  required?: boolean
}

interface FormValues {
  id: number
  value: string
  version: number
}

const decorators = createDecorators()

export const BackgroundInformationFieldEditForm = ({
  onSave,
  id,
  value,
  version,
  textLength,
  label,
  required,
}: Props): ReactElement => {
  const initialValues = useMemo(() => ({ id, value, version }), [id, value, version])

  return (
    <Box>
      {initialValues && (
        <Form<FormValues>
          initialValues={initialValues}
          onSubmit={onSave}
          decorators={decorators}
          render={({ handleSubmit }) => {
            return (
              <form id="edit-form" onSubmit={handleSubmit}>
                <Stack spacing={2}>
                  <HtmlEditorField
                    label={label}
                    required={required}
                    name="value"
                    validate={
                      required ? composeValidators(requiredValidator(), maxChar(textLength)) : maxChar(textLength)
                    }
                  />
                  <DirtyFormSpy />
                </Stack>
              </form>
            )
          }}
        />
      )}
    </Box>
  )
}
