import { ReactElement, useRef } from 'react'
import { useParams } from 'react-router'
import { Query_Root } from 'src/@types/graphql'
import { useMessageSource } from 'src/i18n/useMessageSource'
import { ROUTES } from 'src/routing/routes'
import { KapMilestoneProgramActualsGrid } from 'src/screens/kap/implementation/milestone/details/financial/KapMilestoneProgramActualsGrid'
import { queryMilestoneById } from 'src/screens/shared/implementation/details/milestoneQueries'
import { usePermissionsForKapMilestones } from 'src/service/security/PermissionHook'
import { SaveAndBackButton, SaveButton } from 'src/shared/button/Buttons'
import { MILESTONE_RESPONSIBLE_TYPE_TYPE, MILESTONE_STATUS_TYPE } from 'src/shared/constants/milestone-constants'
import { PageLayout } from 'src/shared/layout/PageLayout'
import { ScreenLayout } from 'src/shared/layout/ScreenLayout'
import { NotAuthorized } from 'src/shared/not-authorized/NotAuthorized'
import { HelpAndInstructions } from 'src/shared/presentation/HelpAndInstructions'
import { useDelayedNavigate } from 'src/shared/utils/hooks/navigation-hooks'
import { useNotificationService } from 'src/shared/utils/NotificationService'
import { useQuery } from 'urql'

type originType = 'SAVE' | 'SAVE_AND_BACK'

export const KapMilestoneFinancialsEditPage = (): ReactElement => {
  const { programId, milestoneId, milestonePath } = useParams()
  const program_id = parseInt(programId as string)
  const milestone_id = parseInt(milestoneId as string)

  const originRef = useRef<originType>('SAVE')
  const { getMessage } = useMessageSource()
  const navigate = useDelayedNavigate()
  const notificationService = useNotificationService()

  const [{ data, error }] = useQuery<{ milestone_by_pk: Query_Root['milestone_by_pk'] }, { id: number }>({
    query: queryMilestoneById,
    variables: { id: milestone_id },
  })

  if (error) {
    notificationService.operationFailed()
  }

  const milestone = data?.milestone_by_pk

  const { loading, canEdit } = usePermissionsForKapMilestones(
    program_id,
    milestone?.status as MILESTONE_STATUS_TYPE,
    milestone?.responsible_type as MILESTONE_RESPONSIBLE_TYPE_TYPE,
  )

  const onSubmit = (origin: originType) => (_: any) => {
    // trigger submit event
    if (originRef) {
      originRef.current = origin
    }
    document.getElementById('edit-form')?.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }))
  }

  const onSave = () => {
    if (originRef.current === 'SAVE_AND_BACK') {
      onBack()
    }
  }

  const onBack = () => {
    navigate(ROUTES.KapFinancialReportMilestoneRoot.nested.Financials.params({ programId, milestoneId, milestonePath }))
  }

  if (!loading && !canEdit) {
    return <NotAuthorized inEditPage />
  }

  return (
    <ScreenLayout
      title={getMessage('label.milestone.edit.kap.program.financial.report')}
      hasSecondLevelNavigation={false}
      onBack={onBack}
      actions={
        <>
          <SaveAndBackButton origin="header" onClick={onSubmit('SAVE_AND_BACK')} />
          <SaveButton origin="header" onClick={onSubmit('SAVE')} />
        </>
      }
    >
      <PageLayout>
        {!loading && (
          <>
            <HelpAndInstructions labelKey="label.help.milestone.kap.program.actuals" defaultExpansion />
            <KapMilestoneProgramActualsGrid mode="edit" onSave={onSave} />
          </>
        )}
      </PageLayout>
    </ScreenLayout>
  )
}
