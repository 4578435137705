import { QualitativeEvaluationBaseEdit } from 'src/screens/shared/implementation/details/milestone/details/final/qualitative-evaluation/QualitativeEvaluationBaseEdit'
import { resolveProcessToLowerCase } from 'src/shared/utils/Utils'

interface Props {
  baseUrl: '/pf-kap' | '/pf-pgv'
}

export const QualitativeEvaluationSWOTAnalysisEditPage = ({ baseUrl }: Props) => {
  const process = resolveProcessToLowerCase(baseUrl)

  return (
    <QualitativeEvaluationBaseEdit
      baseUrl={baseUrl}
      screenLayoutTextLabel="label.milestone.qualitative.evaluation.edit.swot.analysis"
      helpAndInstructionsTextLabel={`label.help.qualitative.evaluation.swot.analysis.${process}`}
    />
  )
}
