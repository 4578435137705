import { gql } from '@urql/core'

export const queryEditingUserById = gql`
  query ($userId: Int!) {
    user(where: { id: { _eq: $userId } }) {
      first_name
      last_name
      email
      language
      title
      type
      gfch_cantonal_responsibility
      cantonal_user_canton
      operational_responsibility
      strategic_responsibility
      user_roles {
        role
      }
    }
  }
`
export const editUserByGfch = gql`
  mutation editUserByGfch($user: EditUserByGfchInput!) {
    editUserByGfch(user: $user) {
      status
      failureKey
    }
  }
`

export const deleteUserByGfch = gql`
  mutation deleteUserByGfch($email: String!) {
    deleteUserByGfch(email: $email) {
      status
      failureKey
    }
  }
`
