import { ListItem, ListItemIcon, ListItemText, Typography } from '@mui/material'
import { forwardRef, ReactElement, useMemo } from 'react'
import { NavLink, NavLinkProps } from 'react-router-dom'

interface ListItemLinkProps {
  primary: string
  to: string
  icon?: React.ReactElement
  className?: string
  end?: boolean
}

const ListItemLink = (props: ListItemLinkProps): ReactElement => {
  const { icon, primary, to, className, ...rest } = props
  const renderLink = useMemo(
    () =>
      forwardRef<any, Omit<NavLinkProps, 'to'>>(({ className: defaultStyling, ...itemRest }, ref) => (
        <NavLink
          to={to}
          ref={ref}
          {...itemRest}
          className={({ isActive }) => `${defaultStyling} ${isActive ? 'Mui-selected' : ''}`}
        />
      )),
    [to],
  )

  return (
    <ListItem className={className} button component={renderLink} {...rest} sx={{ paddingLeft: 3, height: '40px' }}>
      {icon ? <ListItemIcon sx={{ minWidth: '40px' }}>{icon}</ListItemIcon> : null}
      <Typography variant="subtitle2" sx={{ lineHeight: '100%' }}>
        <ListItemText disableTypography primary={primary} />
      </Typography>
    </ListItem>
  )
}

ListItemLink.displayName = 'ListItemLink'

export { ListItemLink }
