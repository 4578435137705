import { Box, Dialog, DialogTitle, IconButton, Typography } from '@mui/material'
import { ReactElement, ReactNode, useCallback } from 'react'
import { CloseIcon } from 'src/shared/icons/Icons'
import styled from 'styled-components/macro'

interface ModalDialogProps {
  open: boolean
  onClose: () => void
  maxWidth?: false | 'xs' | 'sm' | 'md' | 'lg' | 'xl'
  fullWidth?: boolean
  withCloseIcon?: boolean
  title: string
  children: ReactNode
  className?: string
  action?: ReactNode
}

const ActionContainer = styled(Box)`
  position: absolute;
  top: ${({ theme }) => theme.spacing(2)};
  right: ${({ theme }) => theme.spacing(2)};
`

const ActionButton = styled.span`
  margin-right: ${({ theme }) => theme.spacing(2)};
`

export const ModalDialog = ({
  children,
  maxWidth = 'md',
  open,
  title,
  onClose,
  fullWidth = true,
  withCloseIcon = true,
  className,
  action,
}: ModalDialogProps): ReactElement => {
  const onCloseLocal = useCallback(
    (_: any, reason: any) => {
      if (reason !== 'backdropClick') {
        onClose()
      }
    },
    [onClose],
  )
  return (
    <Dialog
      scroll="paper"
      fullWidth={fullWidth}
      maxWidth={maxWidth}
      open={open}
      onClose={onCloseLocal}
      className={className}
      onKeyDown={({ key }) => {
        if (key === 'Escape') {
          onClose()
        }
      }}
    >
      <DialogTitle>
        <Typography variant="h3" component="span">
          {title}
        </Typography>
        <ActionContainer>
          {action && <ActionButton>{action}</ActionButton>}
          {withCloseIcon && (
            <IconButton onClick={onCloseLocal as any}>
              <CloseIcon />
            </IconButton>
          )}
        </ActionContainer>
      </DialogTitle>
      {children}
    </Dialog>
  )
}
