import { Box, Card, CardContent, Paper, Stack, Typography } from '@mui/material'
import { ReactElement, useMemo } from 'react'
import { Form } from 'react-final-form'
import { Mutation_Root } from 'src/@types/graphql'
import { useMessageSource } from 'src/i18n/useMessageSource'
import { useChartContext } from 'src/shared/charts/ChartContext'
import { TEXT_LENGTH } from 'src/shared/constants/constants'
import { RATING_PRIORITY, RATING_STATUS } from 'src/shared/constants/success-factor-constants'
import { Option } from 'src/shared/form/control'
import { AutoCompleteField } from 'src/shared/form/control/AutoCompleteField'
import { HtmlEditorField } from 'src/shared/form/control/HtmlEditorField'
import { RatingField } from 'src/shared/form/control/RatingField'
import { DirtyFormSpy } from 'src/shared/form/dirty/DirtyFormSpy'
import { createDecorators } from 'src/shared/form/utils/decorators'
import { maxChar, required } from 'src/shared/form/validation/validators'
import {
  CheckIcon,
  EmptyHeartIcon,
  EmptyStarIcon,
  ErrorOutlineIcon,
  FullHeartIcon,
  FullStarIcon,
  InProgressIcon,
} from 'src/shared/icons/Icons'
import { HelpAndInstructions } from 'src/shared/presentation/HelpAndInstructions'
import { Section } from 'src/shared/presentation/Section'
import { HtmlSanitizer } from 'src/shared/utils/HtmlSanitizer'
import { useNotificationService } from 'src/shared/utils/NotificationService'
import styled from 'styled-components/macro'
import { useClient } from 'urql'
import { upsertAnalysisSuccessFactorRating } from '../analysisQueries'
import { SUCCESS_FACTOR_STATUS } from '../utils/AnalysisUtils'

type originType = 'SAVE' | 'SAVE_AND_BACK'

const CardTextStyled = styled(Box)`
  width: 75%;

  .MuiTypography-root {
    display: inline;

    &:first-child {
      margin-right: ${({ theme }) => theme.spacing(0.5)};
    }
  }
`

const RatingErrorBoxStyled = styled(Box)`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(0.5)};
`

const CardInputStyled = styled(Box)`
  width: 25%;
  display: flex;
  align-items: center;

  .MuiAutocomplete-root {
    flex-grow: 1;
  }

  .MuiFormHelperText-root {
    display: none;
  }

  legend {
    width: 7rem;
    line-height: inherit;
    padding-inline: 0;
  }
`

const CardContentStyled = styled(CardContent)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(2)};
`

const PaperStyled = styled(Paper)`
  .MuiAutocomplete-listbox {
    li {
      display: flex;
      align-items: center;

      svg {
        margin-right: ${({ theme }) => theme.spacing(2)};
      }

      &:last-child {
        padding-left: calc(20px + 16px + 16px); // icon size + li padding + spacing between icon and text
      }
    }
  }
`

export const resolveRatingStatus = (rating: number | null) => {
  switch (rating) {
    case 1:
      return RATING_STATUS.VERY_BAD
    case 2:
      return RATING_STATUS.BAD
    case 3:
      return RATING_STATUS.RATHER_BAD
    case 4:
      return RATING_STATUS.RATHER_GOOD
    case 5:
      return RATING_STATUS.GOOD
    case 6:
      return RATING_STATUS.VERY_GOOD
  }
}

export const resolveRatingPriority = (rating: number | null) => {
  switch (rating) {
    case 1:
      return RATING_PRIORITY.LOW_PRIORITY
    case 2:
      return RATING_PRIORITY.MEDIUM_PRIORITY
    case 3:
      return RATING_PRIORITY.HIGH_PRIORITY
  }
}

export interface SuccessFactorRatingFormValues {
  comment: string | null
  status: string
  ratingPriority: number
  ratingStatus: number
}

interface Props {
  successFactorId: number
  analysisId: number
  initialValues: SuccessFactorRatingFormValues | undefined
  setInitialValues: (v: SuccessFactorRatingFormValues) => void
  onBack: () => void
  originRef: React.MutableRefObject<originType>
}

const decorators = createDecorators()

export const SuccessFactorRatingForm = ({
  successFactorId,
  analysisId,
  initialValues,
  setInitialValues,
  onBack,
  originRef,
}: Props): ReactElement => {
  const { getMessage } = useMessageSource()
  const notificationService = useNotificationService()
  const urqlClient = useClient()
  const { refetchChartData } = useChartContext()

  const key = `label.success.factor.status.`
  const statusOptions: Option[] = useMemo(
    () => [
      {
        label: `${getMessage(`${key}${SUCCESS_FACTOR_STATUS.IMPLEMENTATION}`)}`,
        value: SUCCESS_FACTOR_STATUS.IMPLEMENTATION,
      },
      {
        label: `${getMessage(`${key}${SUCCESS_FACTOR_STATUS.DEVELOPMENT}`)}`,
        value: SUCCESS_FACTOR_STATUS.DEVELOPMENT,
      },
      {
        label: `${getMessage(`${key}${SUCCESS_FACTOR_STATUS.NONE}`)}`,
        value: SUCCESS_FACTOR_STATUS.NONE,
      },
    ],
    [getMessage, key],
  )

  const onSubmitHandle = async (values: SuccessFactorRatingFormValues) => {
    const { error } = await urqlClient
      .mutation<
        { insert_analysis_success_factor_rating_one: Mutation_Root['insert_analysis_success_factor_rating_one'] },
        {
          successFactorAnalysisId: number
          successFactorId: number
          status: string
          ratingCurrentStatus: number
          ratingPriority: number
          comment: string
        }
      >(upsertAnalysisSuccessFactorRating, {
        successFactorAnalysisId: analysisId,
        successFactorId: successFactorId,
        status: values.status,
        ratingCurrentStatus: values.ratingStatus,
        ratingPriority: values.ratingPriority,
        comment: HtmlSanitizer.sanitize(values.comment ?? ''),
      })
      .toPromise()

    if (error) {
      notificationService.operationFailed()
    } else {
      setInitialValues(values)
      refetchChartData()
      notificationService.changesSaved()
      if (originRef.current === 'SAVE_AND_BACK') {
        onBack()
      }
    }
  }

  return (
    <>
      {initialValues && (
        <Form<SuccessFactorRatingFormValues>
          initialValues={initialValues}
          decorators={decorators}
          onSubmit={onSubmitHandle}
          render={({ values, handleSubmit, errors, touched }) => {
            const statusError = !!(touched?.status && errors?.status)
            const ratingStatus = !!(touched?.ratingStatus && errors?.ratingStatus)
            const ratingPriority = !!(touched?.ratingPriority && errors?.ratingPriority)
            const error = statusError || ratingStatus || ratingPriority
            return (
              <form id="edit-form" onSubmit={handleSubmit}>
                <Section
                  id="rating-section"
                  title={getMessage('label.success.factor.rating.title')}
                  helpAndInstructions={
                    <HelpAndInstructions labelKey="label.success.factor.help.and.instructions.rating" />
                  }
                >
                  <Stack spacing={1}>
                    <Card>
                      <CardContentStyled>
                        <CardTextStyled>
                          <Typography variant="h6">{getMessage(`label.success.factor.status.title`)}:</Typography>
                          <Typography variant="subtitle1">
                            {getMessage(`label.success.factor.status.question`)}
                          </Typography>
                        </CardTextStyled>
                        <CardInputStyled>
                          <AutoCompleteField
                            options={statusOptions}
                            name="status"
                            label={getMessage('label.type')}
                            validate={required()}
                            size="small"
                            renderOption={(option, props) => (
                              <li {...option}>
                                {props.value !== SUCCESS_FACTOR_STATUS.NONE &&
                                  (props.value === SUCCESS_FACTOR_STATUS.IMPLEMENTATION ? (
                                    <InProgressIcon fontSize="small" />
                                  ) : (
                                    <CheckIcon fontSize="small" />
                                  ))}
                                {props.label}
                              </li>
                            )}
                            PaperComponent={PaperStyled}
                          />
                        </CardInputStyled>
                      </CardContentStyled>
                    </Card>
                    <Card>
                      <CardContentStyled>
                        <CardTextStyled>
                          <Typography variant="h6">
                            {getMessage(`label.success.factor.rating.status.title`)}:
                          </Typography>
                          <Typography variant="subtitle1">
                            {getMessage(`label.success.factor.rating.status.question`)}
                          </Typography>
                        </CardTextStyled>
                        <CardInputStyled>
                          <RatingField
                            name="ratingStatus"
                            icon={<FullStarIcon fontSize="inherit" />}
                            emptyIcon={<EmptyStarIcon fontSize="inherit" />}
                            precision={1}
                            max={6}
                            ratingMessage={getMessage(
                              `label.success.factor.rating.status.${resolveRatingStatus(values.ratingStatus)}`,
                            )}
                            validate={required()}
                          />
                        </CardInputStyled>
                      </CardContentStyled>
                    </Card>
                    <Card>
                      <CardContentStyled>
                        <CardTextStyled>
                          <Typography variant="h6">
                            {getMessage(`label.success.factor.rating.priority.title`)}:
                          </Typography>
                          <Typography variant="subtitle1">
                            {getMessage(`label.success.factor.rating.priority.question`)}
                          </Typography>
                        </CardTextStyled>
                        <CardInputStyled>
                          <>
                            <RatingField
                              name="ratingPriority"
                              icon={<FullHeartIcon fontSize="inherit" />}
                              emptyIcon={<EmptyHeartIcon fontSize="inherit" />}
                              precision={1}
                              max={3}
                              validate={required()}
                              ratingMessage={getMessage(
                                `label.success.factor.rating.priority.${resolveRatingPriority(values.ratingPriority)}`,
                              )}
                            />
                          </>
                        </CardInputStyled>
                      </CardContentStyled>
                    </Card>
                    {error && (
                      <RatingErrorBoxStyled>
                        <ErrorOutlineIcon color="error" fontSize="small" />
                        <Typography variant="subtitle2" color="error">
                          {getMessage('label.success.factor.rating.error')}
                        </Typography>
                      </RatingErrorBoxStyled>
                    )}
                  </Stack>
                </Section>
                <Section
                  id="comment-section"
                  title={getMessage('label.success.factor.analysis.information')}
                  helpAndInstructions={
                    <HelpAndInstructions labelKey="label.help.success.factor.edit.analysis.information" />
                  }
                >
                  <HtmlEditorField
                    name="comment"
                    validate={maxChar(TEXT_LENGTH.XL)}
                    label={getMessage('label.success.factor.section.comment')}
                  />
                </Section>
                <DirtyFormSpy />
              </form>
            )
          }}
        />
      )}
    </>
  )
}
