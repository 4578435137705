import { Stack, Tooltip, Typography } from '@mui/material'
import { DataGridPro, GridColDef, GridRowParams, useGridApiRef } from '@mui/x-data-grid-pro'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { EvaluationResponseData, Mutation_Root, ReportInput } from 'src/@types/graphql'
import { useMessageSource } from 'src/i18n/useMessageSource'
import { ReportEvaluationDetails } from 'src/screens/reporting/shared/report-card-content/report-evaluation/details/ReportEvaluationDetails'
import {
  PF_KAP_EVALUATION_MILESTONE_TYPE,
  PF_PGV_EVALUATION_MILESTONE_TYPE,
  PROJECT,
  PROJECT_TYPE,
} from 'src/shared/constants/constants'
import { S } from 'src/shared/styled/S'
import { useGridTranslateHook } from 'src/shared/utils/hooks/grid-translate-hook'
import { useNotificationService } from 'src/shared/utils/NotificationService'
import { muiTheme } from 'src/theme/theme'
import styled from 'styled-components/macro'
import { gql, useClient } from 'urql'

const DataGridProStyled = styled(DataGridPro)`
  & .MuiDataGrid-row {
    &:hover {
      cursor: pointer;
      background: ${({ theme }) => theme.colors.secondary.lightHover};
    }
  }
`

const fetchEvaluationReportDataMutation = gql`
  mutation fetchEvaluationReportData($report: ReportInput!, $isPfKap: Boolean!) {
    fetchPfKapProjectEvaluationReportData(report: $report) @include(if: $isPfKap) {
      data {
        type
        count
      }
    }
    fetchPfPgvProjectEvaluationReportData(report: $report) @skip(if: $isPfKap) {
      data {
        type
        count
      }
    }
  }
`

interface Props {
  reportInput: ReportInput
  process: PROJECT_TYPE
}

export const ReportEvaluationGrid = ({ reportInput, process }: Props) => {
  const { getMessage } = useMessageSource()

  const apiRef = useGridApiRef()
  const gridTranslations = useGridTranslateHook()
  const urqlClient = useClient()
  const notificationService = useNotificationService()

  const [evaluationReport, setEvaluationReport] = useState<EvaluationResponseData[]>([])
  const [selectedEvaluationType, setSelectedEvaluationType] = useState<string>()
  const [modalOpen, setModalOpen] = useState<boolean>(false)

  const fetchData = useCallback(async () => {
    const isPfKap = process === PROJECT.PF_KAP

    const { data } = await urqlClient
      .mutation<{
        fetchPfKapProjectEvaluationReportData: Mutation_Root['fetchPfKapProjectEvaluationReportData']
        fetchPfPgvProjectEvaluationReportData: Mutation_Root['fetchPfPgvProjectEvaluationReportData']
      }>(fetchEvaluationReportDataMutation, {
        report: reportInput,
        isPfKap: isPfKap,
      })
      .toPromise()

    if (data) {
      const evaluationMilestoneTypes = isPfKap ? PF_KAP_EVALUATION_MILESTONE_TYPE : PF_PGV_EVALUATION_MILESTONE_TYPE
      const actualData = isPfKap
        ? data.fetchPfKapProjectEvaluationReportData.data
        : data.fetchPfPgvProjectEvaluationReportData.data

      const mappedData = Object.keys(evaluationMilestoneTypes).map((evaluationType) => ({
        type: evaluationType,
        count: actualData.filter((responseItem) => responseItem.type === evaluationType)[0]?.count ?? 0,
      }))

      setEvaluationReport(mappedData)
    } else {
      notificationService.operationFailed()
    }
  }, [urqlClient, reportInput, process, notificationService])

  const rows = useMemo(() => evaluationReport.map((type, i) => ({ ...type, id: i })), [evaluationReport])

  const columns = useMemo(
    () =>
      [
        {
          field: 'type',
          flex: 1,
          sortable: false,
          renderCell({ value }) {
            return (
              <Typography variant="subtitle2" sx={{ color: muiTheme.palette.text.disabled }}>
                {getMessage(`label.evaluation.milestone.type.${value}`)}
              </Typography>
            )
          },
        },
        {
          field: 'count',
          flex: 1,
          sortable: false,
          type: 'number',
        },
      ] as GridColDef[],
    [getMessage],
  )

  useEffect(() => {
    fetchData()
  }, [fetchData])

  const handleRowClick = ({ row }: GridRowParams) => {
    const { type } = row
    setSelectedEvaluationType(type)
    setModalOpen(true)
  }

  return (
    <>
      <DataGridProStyled
        apiRef={apiRef}
        rows={rows}
        columns={columns}
        autoHeight
        localeText={gridTranslations}
        disableColumnResize
        disableColumnSelector
        disableColumnReorder
        disableColumnPinning
        disableRowSelectionOnClick
        disableColumnMenu
        disableColumnFilter
        disableMultipleColumnsSorting
        hideFooter
        onRowClick={handleRowClick}
        slots={{
          columnHeaders: () => null,
          toolbar: () => (
            <S.DataGrid.ToolbarContainer $reporting $noActionButton>
              <Stack direction="row" alignItems="left" mb={1}>
                <Typography>{getMessage('label.effectivity.evaluation')}</Typography>
                <Tooltip title={getMessage('tooltip.reporting.evaluation')}>
                  <S.Icons.GridTooltipInfo />
                </Tooltip>
              </Stack>
            </S.DataGrid.ToolbarContainer>
          ),
        }}
      />
      <ReportEvaluationDetails
        reportInput={reportInput}
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        evaluationType={selectedEvaluationType}
        process={process}
      />
    </>
  )
}
