import { get } from 'lodash'
import { useEffect, useRef, useState } from 'react'
import { Form } from 'react-final-form'
import { useParams } from 'react-router'
import { Mutation_Root, Query_Root } from 'src/@types/graphql'
import { useMessageSource } from 'src/i18n/useMessageSource'
import { ROUTES } from 'src/routing/routes'
import {
  getAnalysisAreaOfActivityComment,
  upsertAnalysisAreaOfActivityComment,
} from 'src/screens/success-factor-tool/analysis/details/analysis/analysisQueries'
import { usePermissionsForSuccessFactors } from 'src/service/security/PermissionHook'
import { SaveAndBackButton, SaveButton } from 'src/shared/button/Buttons'
import { TEXT_LENGTH } from 'src/shared/constants/constants'
import { HtmlEditorField } from 'src/shared/form/control/HtmlEditorField'
import { DirtyFormSpy } from 'src/shared/form/dirty/DirtyFormSpy'
import { maxChar } from 'src/shared/form/validation/validators'
import { PageLayout } from 'src/shared/layout/PageLayout'
import { ScreenLayout } from 'src/shared/layout/ScreenLayout'
import { NotAuthorized } from 'src/shared/not-authorized/NotAuthorized'
import { HelpAndInstructions } from 'src/shared/presentation/HelpAndInstructions'
import { useDelayedNavigate } from 'src/shared/utils/hooks/navigation-hooks'
import { HtmlSanitizer } from 'src/shared/utils/HtmlSanitizer'
import { useNotificationService } from 'src/shared/utils/NotificationService'
import { useUserLocale } from 'src/user/UserContext'
import { useClient } from 'urql'

type originType = 'SAVE' | 'SAVE_AND_BACK'

interface AreaOfActivityData {
  names: string
  code: string
}

interface CommentFormValues {
  comment: string | null
}

export const AnalysisAreaOfActivityEditPage = () => {
  const { analysisId, areaOfActivityId } = useParams()
  const { getMessage } = useMessageSource()
  const navigate = useDelayedNavigate()
  const notificationService = useNotificationService()
  const urqlClient = useClient()
  const locale = useUserLocale()
  const originRef = useRef<originType>('SAVE')
  const { canEdit } = usePermissionsForSuccessFactors()

  const [areaOfActivityData, setAreaOfActivityData] = useState<AreaOfActivityData | null>(null)
  const [initialValues, setInitialValues] = useState<CommentFormValues | null>(null)

  useEffect(() => {
    const initData = async () => {
      const { data, error } = await urqlClient
        .query<
          {
            analysis_area_of_activity: Query_Root['analysis_area_of_activity']
            area_of_activity_by_pk: Query_Root['area_of_activity_by_pk']
          },
          { analysisId: number; areaOfActivityId: number }
        >(getAnalysisAreaOfActivityComment, {
          analysisId: parseInt(analysisId as string),
          areaOfActivityId: parseInt(areaOfActivityId as string),
        })
        .toPromise()

      if (data) {
        const names = data.area_of_activity_by_pk?.names ?? null
        const code = data.area_of_activity_by_pk?.code ?? null
        const comment = data.analysis_area_of_activity?.[0]?.comment ?? null
        setAreaOfActivityData({ names, code } as AreaOfActivityData)
        setInitialValues({ comment } as CommentFormValues)
      } else if (error) {
        notificationService.operationFailed()
      }
    }
    initData()
  }, [analysisId, areaOfActivityId, notificationService, urqlClient])

  const onSubmit = (origin: originType) => (_: any) => {
    // trigger submit event
    if (originRef) {
      originRef.current = origin
    }
    document.getElementById('edit-form')?.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }))
  }

  const onBackHandle = () => {
    const route = ROUTES.SuccessFactorAnalysisRoot.nested.AnalysisDetails.params({ analysisId })
    navigate(`${route}#${areaOfActivityData?.code?.replaceAll('_', '-')}`)
  }

  const updateHandler = async (values: CommentFormValues) => {
    const updateObject = {
      areaOfActivityId: parseInt(areaOfActivityId as string),
      analysisId: parseInt(analysisId as string),
      comment: HtmlSanitizer.sanitize(values.comment ?? ''),
    }

    const { error } = await urqlClient
      .mutation<{ insert_analysis_area_of_activity_one: Mutation_Root['insert_analysis_area_of_activity_one'] }>(
        upsertAnalysisAreaOfActivityComment,
        updateObject,
      )
      .toPromise()

    if (error) {
      notificationService.operationFailed()
    } else {
      setInitialValues(values)
      notificationService.changesSaved()
      if (originRef.current === 'SAVE_AND_BACK') {
        onBackHandle()
      }
    }
  }

  if (!canEdit) {
    return <NotAuthorized inEditPage />
  }

  return (
    <ScreenLayout
      title={getMessage('label.success.factor.analysis.section.edit', [get(areaOfActivityData?.names, locale, '')])}
      onBack={onBackHandle}
      hasSecondLevelNavigation={false}
      actions={
        <>
          <SaveAndBackButton origin="header" onClick={onSubmit('SAVE_AND_BACK')} />
          <SaveButton origin="header" onClick={onSubmit('SAVE')} />
        </>
      }
    >
      <PageLayout>
        <>
          {initialValues && (
            <>
              <HelpAndInstructions
                labelKey={'label.success.factor.help.and.instructions.area.of.activity'}
                defaultExpansion
              />
              <Form<CommentFormValues>
                initialValues={initialValues}
                onSubmit={updateHandler}
                render={({ handleSubmit }) => {
                  return (
                    <form id="edit-form" onSubmit={handleSubmit}>
                      <HtmlEditorField
                        name="comment"
                        validate={maxChar(TEXT_LENGTH.L)}
                        label={getMessage('label.success.factor.section.comment')}
                      />
                      <DirtyFormSpy />
                    </form>
                  )
                }}
              />
            </>
          )}
        </>
      </PageLayout>
    </ScreenLayout>
  )
}
