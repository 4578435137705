import { Card, CardActions, CardContent } from '@mui/material'
import { STYLED_FOCUS_OUTLINE } from 'src/shared/constants/styling-constants'
import styled, { css } from 'styled-components/macro'

const CARD_HEIGHT = '3rem'

export const CardStyled = styled(Card)<{ $nonClickable?: boolean }>`
  display: flex;
  align-items: center;
  &:not(:last-child) {
    margin-bottom: ${({ theme }) => theme.spacing(1)};
  }
  &:hover {
    cursor: pointer;
    box-shadow: ${({ theme }) => theme.shadows[4]};
  }
  ${({ $nonClickable }) =>
    $nonClickable &&
    css`
      &:hover {
        cursor: auto;
        box-shadow: ${({ theme }) => theme.shadows[1]};
      }
    `}
`

export const CardContentStyled = styled(CardContent)`
  height: ${CARD_HEIGHT};
  display: flex;
  align-items: center;
  flex-grow: 1;
  grid-gap: ${({ theme }) => theme.spacing(2)};
  &:focus-visible {
    ${STYLED_FOCUS_OUTLINE}
  }
`

const iconButtonWidth = 5 // 5 spacing units == 40px
const getOriginalNumberOfButtons = (number: number) => number * iconButtonWidth

export const CardActionsStyled = styled(CardActions)<{ $numberOfButtonsToAlign?: number }>`
  height: ${CARD_HEIGHT};
  padding: 0;
  margin-right: ${({ theme }) => theme.spacing(0.5)};
  & .MuiIconButton-root {
    height: 2.5rem;
    width: 2.5rem;
    margin: 0;
  }

  // Additional styling to align dynamic card content when some buttons are hidden
  ${({ $numberOfButtonsToAlign }) =>
    $numberOfButtonsToAlign &&
    css`
      width: ${({ theme }) => theme.spacing(getOriginalNumberOfButtons($numberOfButtonsToAlign))};
      justify-content: end;
    `}
`
