import { ReactElement } from 'react'
import { useParams } from 'react-router'
import { Query_Root } from 'src/@types/graphql'
import { useMessageSource } from 'src/i18n/useMessageSource'
import { ROUTES } from 'src/routing/routes'
import { KapMilestoneProgramActualsGrid } from 'src/screens/kap/implementation/milestone/details/financial/KapMilestoneProgramActualsGrid'
import { queryMilestoneById } from 'src/screens/shared/implementation/details/milestoneQueries'
import { usePermissionsForKapMilestones } from 'src/service/security/PermissionHook'
import { EditButton } from 'src/shared/button/Buttons'
import { ENTITY_TYPE, FILE_TYPE } from 'src/shared/constants/constants'
import { MILESTONE_RESPONSIBLE_TYPE_TYPE, MILESTONE_STATUS_TYPE } from 'src/shared/constants/milestone-constants'
import { PageLayout } from 'src/shared/layout/PageLayout'
import { ScreenLayout } from 'src/shared/layout/ScreenLayout'
import { KapMilestoneExportMenu } from 'src/shared/menu/KapMilestoneExportMenu'
import { NotAuthorized } from 'src/shared/not-authorized/NotAuthorized'
import { FileSection } from 'src/shared/presentation/FileSection'
import { HelpAndInstructions } from 'src/shared/presentation/HelpAndInstructions'
import { Section } from 'src/shared/presentation/Section'
import { useDelayedNavigate } from 'src/shared/utils/hooks/navigation-hooks'
import { useNotificationService } from 'src/shared/utils/NotificationService'
import { Utils } from 'src/shared/utils/Utils'
import { useQuery } from 'urql'

export const KapMilestoneFinancialsPage = (): ReactElement => {
  const { programId, milestoneId, milestonePath } = useParams()
  const program_id = parseInt(programId as string)
  const milestone_id = parseInt(milestoneId as string)
  const milestoneType = Utils.resolveMilestoneType(milestonePath)

  const { getMessage } = useMessageSource()
  const notificationService = useNotificationService()
  const navigate = useDelayedNavigate()

  const [{ data, error }] = useQuery<{ milestone_by_pk: Query_Root['milestone_by_pk'] }, { id: number }>({
    query: queryMilestoneById,
    variables: { id: milestone_id },
  })

  if (error) {
    notificationService.operationFailed()
  }

  const milestone = data?.milestone_by_pk

  const { loading, canView, canEdit } = usePermissionsForKapMilestones(
    program_id,
    milestone?.status as MILESTONE_STATUS_TYPE,
    milestone?.responsible_type as MILESTONE_RESPONSIBLE_TYPE_TYPE,
  )

  const onEdit = () => {
    navigate(
      ROUTES.KapFinancialReportMilestoneRoot.nested.FinancialsEdit.params({ programId, milestoneId, milestonePath }),
    )
  }

  const onBack = () => {
    navigate(ROUTES.KapImplementationDetailsRoot.params({ programId }))
  }

  if (!loading && !canView) {
    return <NotAuthorized inSecondLevelNavigationTab />
  }

  return (
    <>
      <ScreenLayout
        title={`${getMessage(`label.milestone.type.${milestoneType}`)} ${milestone?.year_in_focus ?? ''}`}
        onBack={onBack}
        actions={<>{canView && <KapMilestoneExportMenu year={milestone?.year_in_focus} />}</>}
      >
        <PageLayout>
          <>
            {!loading && (
              <>
                <Section
                  id="program-actuals"
                  title={getMessage('label.milestone.kap.program.actuals')}
                  actionButton={<EditButton onClick={onEdit} hidden={!canEdit} />}
                  helpAndInstructions={<HelpAndInstructions labelKey="label.help.milestone.kap.program.actuals" />}
                >
                  <KapMilestoneProgramActualsGrid mode="view" reportYear={milestone?.year_in_focus} />
                </Section>
                <FileSection
                  optional
                  id="documents"
                  title={getMessage('label.milestone.detailed.financial.report')}
                  fileType={FILE_TYPE.MILESTONE_DETAILS_DOCUMENT}
                  entityType={ENTITY_TYPE.MILESTONE}
                  entityId={milestone_id}
                  canEdit={canEdit}
                  helpAndInstructions={
                    <HelpAndInstructions labelKey="label.help.milestone.documents.FINANCIAL_REPORT.KAP" />
                  }
                />
              </>
            )}
          </>
        </PageLayout>
      </ScreenLayout>
    </>
  )
}
