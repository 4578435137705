import { gql } from '@urql/core'

export const queryDossierAndMilestoneForImplementationSummary = gql`
  query ($projectBaseQuery: project_base_bool_exp!) {
    dossier(where: { project_bases: $projectBaseQuery }) {
      status
      project_workflow_id
      project_bases {
        implementation_comment
      }
    }
    milestone(where: { dossier: { project_bases: $projectBaseQuery } }) {
      status
      type
      year_in_focus
      id
    }
  }
`

export const queryImplementationCommentByProjectBaseId = gql`
  query ($projectBaseQuery: project_base_bool_exp!) {
    dossier(where: { project_bases: $projectBaseQuery }) {
      project_bases {
        implementation_comment
      }
    }
  }
`

export const updateImplementationComment = gql`
  mutation ($comment: String, $projectBaseQuery: project_base_bool_exp!) {
    update_project_base(_set: { implementation_comment: $comment }, where: $projectBaseQuery) {
      affected_rows
    }
  }
`
