import { ReactElement, useEffect } from 'react'
import { Rifm } from 'rifm'
import { FONT_FAMILY } from 'src/shared/constants/styling-constants'
import styled from 'styled-components/macro'

const parseInteger = (string: string): string => (string.match(integerAccept) || []).join('')
const formatInteger = (string: string) => {
  const parsed = parseInteger(string)
  const number = Number.parseInt(parsed, 10)
  if (Number.isNaN(number)) {
    return ''
  }
  return number.toLocaleString('de-CH')
}

interface Props {
  value: number
  onChange: (_: number) => void
  error?: boolean
}
const integerAccept = /\d+/g

const InputStyled = styled.input<{ $error: boolean }>`
  width: 100%;
  text-align: right;
  outline: none;
  border: none;
  padding: 0 10px;
  font-family: ${FONT_FAMILY};
  font-size: ${({ theme }) => theme.typography.body2.fontSize};
  line-height: ${({ theme }) => theme.typography.body2.lineHeight};
  letter-spacing: ${({ theme }) => theme.typography.body2.letterSpacing};
  background-color: ${({ $error, theme }) => ($error ? theme.colors.error.light : 'inherit')};
  color: ${({ $error, theme }) => ($error ? theme.colors.error.dark : 'inherit')};
  font-weight: ${({ $error, theme }) => ($error ? theme.typography.subtitle2.fontWeight : 'inherit')};
`

export const NumberInput = ({ value, onChange, error = false }: Props): ReactElement => {
  const handleRef: React.RefCallback<HTMLInputElement> = (element: HTMLInputElement | null) => {
    if (element) {
      element?.focus()
    }
  }

  useEffect(() => {
    onChange(value)
  }, [value]) // eslint-disable-line

  return (
    <Rifm
      accept={/\d/g}
      format={formatInteger}
      value={String(value)}
      onChange={(val: string) => {
        onChange(Number.parseInt(parseInteger(val)))
      }}
    >
      {({ value, onChange }) => (
        <InputStyled type="tel" ref={handleRef} value={value} onChange={onChange} $error={error} />
      )}
    </Rifm>
  )
}
