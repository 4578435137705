import { Box, CardContent, Chip, Rating, Tooltip, Typography } from '@mui/material'
import { ReactNode } from 'react'
import { useMessageSource } from 'src/i18n/useMessageSource'
import { SUCCESS_FACTOR_STATUS } from 'src/screens/success-factor-tool/analysis/details/analysis/utils/AnalysisUtils'
import { usePermissionsForSuccessFactors } from 'src/service/security/PermissionHook'
import { STYLED_FOCUS_OUTLINE, TEXT_OVERFLOW_MIXIN } from 'src/shared/constants/styling-constants'
import {
  CheckIcon,
  EmptyHeartIcon,
  EmptyStarIcon,
  FullHeartIcon,
  FullStarIcon,
  InProgressIcon,
} from 'src/shared/icons/Icons'
import { HtmlRenderer } from 'src/shared/presentation/HtmlRenderer'
import { IdChipStyled } from 'src/shared/styled/card/GoalMeasureCardStyled'
import { S } from 'src/shared/styled/S'
import { useHashScroll } from 'src/shared/utils/hooks/navigation-hooks'
import { muiTheme } from 'src/theme/theme'
import styled, { css } from 'styled-components/macro'

interface Props {
  id?: string
  code: string
  sortNumber: number
  title: string
  comment?: string
  status?: string
  ratingStatus?: number
  ratingPriority?: number
  actionButton?: ReactNode
  children?: ReactNode
  onClick?: () => void
  isExpandable?: boolean
}

const ContainerStyled = styled(S.Card.Container)<{ $isExpandable?: boolean }>`
  ${({ $isExpandable }) =>
    $isExpandable &&
    css`
      box-shadow: none;

      &:hover {
        box-shadow: none;
      }
    `}
`

const CardContentStyled = styled(CardContent)`
  padding-top: ${({ theme }) => theme.spacing(0.5)};
`

const SuccessFactorSectionStyled = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;

  &:focus-visible {
    ${STYLED_FOCUS_OUTLINE}
  }

  .MuiChip-root:hover {
    cursor: auto;
  }
`

const BoxStyled = styled(Box)`
  height: 3rem;
  display: flex;
  align-items: center;
  padding-left: ${({ theme }) => theme.spacing(2)};
  flex-grow: 1;
`

const SuccessFactorTitleStyled = styled(Typography)<{ $notRated?: boolean }>`
  ${TEXT_OVERFLOW_MIXIN};

  ${({ $notRated }) =>
    $notRated &&
    css`
      color: ${(props) => props.theme.colors.text.disabled};
    `}
`

const RatingStatus = styled(Rating)`
  min-width: ${({ theme }) => theme.spacing(20)};
  margin-left: ${({ theme }) => theme.spacing(5)};
  & .MuiRating-iconFilled {
    color: ${(props) => props.theme.colors.primary.main};
  }
`

const RatingPriority = styled(Rating)`
  min-width: ${({ theme }) => theme.spacing(14)};
  & .MuiRating-iconFilled {
    color: ${(props) => props.theme.colors.primary.main};
  }
`

const NotRatedTypographyStyled = styled(Typography)`
  color: ${(props) => props.theme.colors.text.disabled};
  min-width: ${({ theme }) => theme.spacing(44)};
  text-align: center;
`

const ChipStyled = styled(Chip)`
  padding-inline: ${({ theme }) => theme.spacing(0.75)};

  span {
    display: none;
  }

  .MuiChip-icon {
    margin-right: ${({ theme }) => theme.spacing(0.5)};
  }
`

export const SuccessFactorSection = ({
  id,
  sortNumber,
  title,
  status,
  comment,
  ratingStatus,
  ratingPriority,
  actionButton,
  children,
  onClick,
  isExpandable,
}: Props) => {
  const { getMessage } = useMessageSource()
  const { canEdit } = usePermissionsForSuccessFactors()

  const isRated = status && ratingStatus && ratingPriority

  useHashScroll(id)

  return (
    <ContainerStyled id={id} $nonClickable={!isExpandable} $isExpandable={isExpandable}>
      <SuccessFactorSectionStyled onClick={onClick}>
        <BoxStyled>
          <Box sx={{ display: 'flex', gap: muiTheme.spacing(2), flexGrow: 1, minWidth: 0 }}>
            <IdChipStyled size="small" label={`${sortNumber}`} />
            <SuccessFactorTitleStyled variant="body1" $notRated={!isRated && !isExpandable}>
              {title}
            </SuccessFactorTitleStyled>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
            {isRated ? (
              <>
                {status !== SUCCESS_FACTOR_STATUS.NONE && (
                  <Tooltip title={getMessage(`label.success.factor.status.${status}`)} placement="left">
                    <ChipStyled
                      size="small"
                      color="primary"
                      icon={status === SUCCESS_FACTOR_STATUS.IMPLEMENTATION ? <InProgressIcon /> : <CheckIcon />}
                    />
                  </Tooltip>
                )}
                <RatingStatus
                  icon={<FullStarIcon fontSize="inherit" />}
                  emptyIcon={<EmptyStarIcon fontSize="inherit" />}
                  size="small"
                  readOnly
                  precision={0.1}
                  defaultValue={ratingStatus}
                  max={6}
                />
                <RatingPriority
                  icon={<FullHeartIcon fontSize="inherit" />}
                  emptyIcon={<EmptyHeartIcon fontSize="inherit" />}
                  size="small"
                  readOnly
                  precision={0.1}
                  defaultValue={ratingPriority}
                  max={3}
                />
              </>
            ) : !isExpandable ? (
              <NotRatedTypographyStyled variant="body1" color="disabled">
                {getMessage('label.success.factor.not.rated')}
              </NotRatedTypographyStyled>
            ) : null}
          </Box>
          {canEdit && <S.Card.Actions>{actionButton}</S.Card.Actions>}
        </BoxStyled>
        {comment && (
          <CardContentStyled>
            <HtmlRenderer html={comment} variant="body2" />
          </CardContentStyled>
        )}
        {children}
      </SuccessFactorSectionStyled>
    </ContainerStyled>
  )
}
