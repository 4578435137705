import { gql } from 'urql'

export const getApplicationTypesQuery = gql`
  query get_application_types($userProcesses: [String!]!) {
    application_type(where: { process: { _in: $userProcesses } }, order_by: { sort_number: asc }) {
      id
      code
      key
      process
      public
    }
  }
`

export const getFundingRoundsByProcess = gql`
  query fetch_funding_rounds($process: String!) {
    funding_round(where: { process: { _eq: $process } }) {
      id
      name
    }
  }
`

export const queryActiveFundingRoundsByProcess = gql`
  query ($process: String!) {
    funding_round(
      where: { _and: { start_date: { _lte: "now()" }, process: { _eq: $process }, end_date: { _gte: "now()" } } }
    ) {
      id
      application_types
    }
  }
`

export const createFundingRoundMutation = gql`
  mutation create_funding_round($objects: [funding_round_insert_input!]!) {
    insert_funding_round(objects: $objects) {
      affected_rows
    }
  }
`

export const getRoundOverviewDataQuery = gql`
  query fetchRoundOverview($userProcesses: [String!]!) {
    funding_round(where: { process: { _in: $userProcesses } }, order_by: { start_date: desc }) {
      id
      name
      application_types
      process
      start_date
      end_date
      decision_date
      dossiers {
        id
      }
    }

    application_type {
      id
      code
      process
    }
  }
`

export const deleteFundingRoundMutation = gql`
  mutation ($fundingRoundId: Int!) {
    delete_funding_round(where: { id: { _eq: $fundingRoundId } }) {
      affected_rows
    }
  }
`

export const getRoundInformationById = gql`
  query fetchFundingRoundById($roundId: Int!) {
    funding_round(where: { id: { _eq: $roundId } }) {
      id
      name
      start_date
      end_date
      decision_date
      process
      application_types
      version
    }
    application_type {
      id
      code
      process
    }
  }
`

export const updateFundingRoundMutation = gql`
  mutation ($roundId: Int!, $version: Int!, $name: String!, $startDate: date!, $endDate: date!, $decisionDate: date!) {
    update_funding_round(
      where: { id: { _eq: $roundId }, version: { _eq: $version } }
      _set: { name: $name, start_date: $startDate, end_date: $endDate, decision_date: $decisionDate }
    ) {
      affected_rows
      returning {
        version
      }
    }
  }
`

export const getProjectsAndAssessorsQuery = gql`
  query fetchProjectsAndAssessorsByProcess($process: String!, $isPfKap: Boolean!, $isPfPgv: Boolean!, $roundId: Int!) {
    project_assessment(
      where: {
        assessment_instance_type: { process: { _eq: $process } }
        project_base: { dossier: { funding_round_id: { _eq: $roundId } } }
      }
    ) {
      id
      project_base_id
      assessment_instance_type {
        id
        key
        process
        code
      }
      user {
        first_name
        last_name
        email
      }
    }
    pf_kap_project(
      where: {
        project_base: {
          dossier: {
            status: { _nin: ["FINISHED", "CANCELED", "REVISION", "WITHDRAWN", "REJECTED", "EXCLUDED"] }
            funding_round_id: { _eq: $roundId }
          }
        }
      }
    ) @include(if: $isPfKap) {
      id
      project_base {
        id
        dossier {
          short_title
        }
      }
    }
    pf_pgv_project(
      where: {
        project_base: { dossier: { status: { _nin: ["FINISHED", "CANCELED"] }, funding_round_id: { _eq: $roundId } } }
      }
    ) @include(if: $isPfPgv) {
      id
      project_base {
        id
        dossier {
          short_title
        }
      }
    }
  }
`

export const getFundingRoundByIdAndAssignedProjects = gql`
  query ($roundId: Int!) {
    funding_round_by_pk(id: $roundId) {
      id
      name
      process
      application_types
      start_date
      end_date
      decision_date
      version
      dossiers(order_by: { id: desc }) {
        id
        title
        type
        project_bases {
          id
          pf_kap_projects {
            id
            version
          }
          pf_pgv_projects {
            id
            version
          }
        }
      }
    }
  }
`
