import { ReactElement, useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router'
import { Mutation_Root, Pf_Kap_Project, Pf_Pgv_Project, Query_Root } from 'src/@types/graphql'
import { useMessageSource } from 'src/i18n/useMessageSource'
import { resolveDetailsRoot } from 'src/routing/routing-utils'
import {
  queryPfKapBackgroundInformationByProjectId,
  queryPfPgvBackgroundInformationByProjectId,
  updatePfKapFieldByProjectId,
  updatePfPgvFieldByProjectId,
} from 'src/screens/shared/project/details/background-information/backgroundInformationQueries'
import { BackgroundInformationFieldEditForm } from 'src/screens/shared/project/details/background-information/edit/BackgroundInformationFieldEditForm'
import { usePermissionsForProject } from 'src/service/security/PermissionHook'
import { SaveAndBackButton, SaveButton } from 'src/shared/button/Buttons'
import { TextLength, TEXT_LENGTH } from 'src/shared/constants/constants'
import { PageLayout } from 'src/shared/layout/PageLayout'
import { ScreenLayout } from 'src/shared/layout/ScreenLayout'
import { NotAuthorized } from 'src/shared/not-authorized/NotAuthorized'
import { HelpAndInstructions } from 'src/shared/presentation/HelpAndInstructions'
import { useDelayedNavigate } from 'src/shared/utils/hooks/navigation-hooks'
import { useIsMounted } from 'src/shared/utils/hooks/react-hooks'
import { HtmlSanitizer } from 'src/shared/utils/HtmlSanitizer'
import { useNotificationService } from 'src/shared/utils/NotificationService'
import { Utils } from 'src/shared/utils/Utils'
import { useClient } from 'urql'

type PfKapFields = keyof Pick<
  Pf_Kap_Project,
  'sustainability' | 'participation' | 'equal_opportunity' | 'multiplication_potential' | 'additional_information'
>
type PfPgvFields = keyof Pick<
  Pf_Pgv_Project,
  'sustainability' | 'participation' | 'equal_opportunity' | 'multiplication_potential' | 'additional_information'
>

type PfKapProps = {
  baseUrl: '/pf-kap'
  field: PfKapFields
}

type PfPgvProps = {
  baseUrl: '/pf-pgv'
  field: PfPgvFields
}

type Props = PfKapProps | PfPgvProps

type originType = 'SAVE' | 'SAVE_AND_BACK'

type fieldTextLengthMap = { [field in PfKapFields | PfPgvFields]: TextLength }

const fieldTextLength: fieldTextLengthMap = {
  sustainability: TEXT_LENGTH.L,
  participation: TEXT_LENGTH.L,
  equal_opportunity: TEXT_LENGTH.L,
  multiplication_potential: TEXT_LENGTH.L,
  additional_information: TEXT_LENGTH.XL,
}

export const BackgroundInformationEditPage = ({ baseUrl, field }: Props): ReactElement => {
  const { projectId } = useParams()
  const [fieldData, setFieldData] = useState<{ id: number; version: number; value: string } | null>(null)
  const originRef = useRef<originType>('SAVE')
  const { getMessage } = useMessageSource()
  const navigate = useDelayedNavigate()
  const notificationService = useNotificationService()
  const urqlClient = useClient()
  const isMounted = useIsMounted()
  const process = Utils.resolveProcessToLowerCase(baseUrl)
  const projectType = Utils.resolveProcess(baseUrl)

  const { canEdit, loading } = usePermissionsForProject(projectType, parseInt(projectId as string))

  useEffect(() => {
    const initFormValues = async () => {
      switch (baseUrl) {
        case '/pf-kap': {
          const { data } = await urqlClient
            .query<{ pf_kap_project_by_pk: Query_Root['pf_kap_project_by_pk'] }, { id: number }>(
              queryPfKapBackgroundInformationByProjectId,
              { id: parseInt(projectId as string) },
            )
            .toPromise()

          if (data && data.pf_kap_project_by_pk) {
            const project = data.pf_kap_project_by_pk
            const fieldDataFetched = {
              id: project.id,
              version: project.version,
              value: project[field as PfKapFields] ?? '',
            }
            setFieldData(fieldDataFetched)
          } else {
            notificationService.operationFailed()
          }
          break
        }
        case '/pf-pgv': {
          const { data } = await urqlClient
            .query<{ pf_pgv_project_by_pk: Query_Root['pf_pgv_project_by_pk'] }, { id: number }>(
              queryPfPgvBackgroundInformationByProjectId,
              { id: parseInt(projectId as string) },
            )
            .toPromise()

          if (data && data.pf_pgv_project_by_pk) {
            const project = data.pf_pgv_project_by_pk
            const fieldDataFetched = {
              id: project.id,
              version: project.version,
              value: project[field as PfPgvFields] ?? '',
            }
            setFieldData(fieldDataFetched)
          } else {
            notificationService.operationFailed()
          }
          break
        }
        default:
          throw Error(`Unsupported base url [${baseUrl}]`)
      }
    }
    initFormValues()
  }, [baseUrl, field, projectId, urqlClient, notificationService])

  const onSubmit = (origin: originType) => (_: any) => {
    // trigger submit event
    if (originRef) {
      originRef.current = origin
    }
    document.getElementById('edit-form')?.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }))
  }

  const onUpdateHandler = async (values: any) => {
    const updateObject = {
      projectId: parseInt(projectId as string),
      version: values.version,
      projectBackgroundInformationFieldSetClause: { [field]: HtmlSanitizer.sanitize(values.value) },
    }

    let mutationError
    let projectBaseData
    let affectedRows
    let newVersion

    switch (baseUrl) {
      case '/pf-kap': {
        const { error, data } = await urqlClient
          .mutation<{ update_pf_kap_project: Mutation_Root['update_pf_kap_project'] }>(
            updatePfKapFieldByProjectId,
            updateObject,
          )
          .toPromise()

        mutationError = error
        projectBaseData = data
        affectedRows = projectBaseData?.update_pf_kap_project?.affected_rows
        newVersion = projectBaseData?.update_pf_kap_project?.returning[0]?.version

        break
      }
      case '/pf-pgv': {
        const { error, data } = await urqlClient
          .mutation<{ update_pf_pgv_project: Mutation_Root['update_pf_pgv_project'] }>(
            updatePfPgvFieldByProjectId,
            updateObject,
          )
          .toPromise()

        mutationError = error
        projectBaseData = data
        affectedRows = projectBaseData?.update_pf_pgv_project?.affected_rows
        newVersion = projectBaseData?.update_pf_pgv_project?.returning[0]?.version

        break
      }
      default:
        throw Error(`Unsupported base url [${baseUrl}]`)
    }

    if (mutationError || affectedRows !== 1) {
      notificationService.operationFailed()
    } else if (isMounted()) {
      const newInitialValues = { ...values, version: newVersion }
      setFieldData(newInitialValues)
      notificationService.changesSaved()
      if (originRef.current === 'SAVE_AND_BACK') {
        const route = resolveDetailsRoot(baseUrl).nested.BackgroundInformation.params({ projectId })
        navigate(`${route}#${field.replaceAll('_', '-')}`)
      }
    }
  }

  if (!loading && !canEdit) {
    return <NotAuthorized />
  }

  return (
    <ScreenLayout
      title={getMessage(`label.title.edit.${field.replaceAll('_', '.')}`)}
      onBack={() => {
        const route = resolveDetailsRoot(baseUrl).nested.BackgroundInformation.params({ projectId })
        navigate(`${route}#${field.replaceAll('_', '-')}`)
      }}
      hasSecondLevelNavigation={false}
      actions={
        <>
          <SaveAndBackButton origin="header" onClick={onSubmit('SAVE_AND_BACK')} />
          <SaveButton origin="header" onClick={onSubmit('SAVE')} />
        </>
      }
    >
      <PageLayout>
        <>
          {!loading && fieldData && (
            <>
              <HelpAndInstructions
                labelKey={`label.help.background.information.${field.replaceAll('_', '.')}.${process}`}
                defaultExpansion
              />
              <BackgroundInformationFieldEditForm
                onSave={onUpdateHandler}
                id={fieldData.id}
                version={fieldData.version}
                value={fieldData.value}
                textLength={fieldTextLength[field]}
                label={getMessage(`label.background.information.${field.replaceAll('_', '.')}`)}
                required={field !== 'additional_information'}
              />
            </>
          )}
        </>
      </PageLayout>
    </ScreenLayout>
  )
}
