import {
  DataGridPro,
  getGridStringOperators,
  GridColDef,
  GridFilterInputValueProps,
  GridFilterItem,
  GridPaginationModel,
  GridRowModel,
  GridSortItem,
  useGridApiRef,
} from '@mui/x-data-grid-pro'
import combinedQuery from 'graphql-combine-query'
import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { Query_Root, User } from 'src/@types/graphql'
import { useMessageSource } from 'src/i18n/useMessageSource'
import { ROUTES } from 'src/routing/routes'
import { AddKapProgramToolbar } from 'src/screens/kap/index/add-program/AddKapProgramToolbar'
import { queryAllGfchCantonsResponsibles, queryKapProgramsDates } from 'src/screens/kap/index/kapProgramGridQueries'
import { fetchKapRows } from 'src/screens/kap/index/KapProgramGridUtils'
import { OverflowTooltip } from 'src/screens/kap/index/tooltip/OverflowTooltip'
import { PermissionService } from 'src/service/security/PermissionService'
import { CANTONS, KAP_MODULES, PROGRAM_STATUSES } from 'src/shared/constants/constants'
import { Option } from 'src/shared/form/control'
import { AutoCompleteFilterInput } from 'src/shared/form/grid/filter/AutoCompleteFilterInput'
import { DateUtils } from 'src/shared/utils/DateUtils'
import { useGridTranslateHook } from 'src/shared/utils/hooks/grid-translate-hook'
import { useDelayedNavigate } from 'src/shared/utils/hooks/navigation-hooks'
import { useNotificationService } from 'src/shared/utils/NotificationService'
import StorageUtils, { STORAGE_KEY } from 'src/shared/utils/StorageUtils'
import { UserContext } from 'src/user/UserContext'
import styled from 'styled-components/macro'
import { useClient } from 'urql'

const DataGridProStyled = styled(DataGridPro)`
  & .MuiDataGrid-row {
    &:hover {
      cursor: pointer;
    }
  }
`

interface Props {
  addProgram: () => void
}

const PAGE_SIZE = 10

export const KapProgramCockpitGrid = ({ addProgram }: Props) => {
  const { getMessage } = useMessageSource()
  const gridRef = useGridApiRef()
  const gridTranslations = useGridTranslateHook()
  const notificationService = useNotificationService()
  const urqlClient = useClient()
  const navigate = useDelayedNavigate()

  const [loading, setLoading] = useState(true)
  const [rows, setRows] = useState<GridRowModel[]>([])
  const [rowsCount, setRowsCount] = useState(0)
  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({ page: 0, pageSize: PAGE_SIZE })
  const [gfchCantonResponsibles, setGfchCantonResponsibles] = useState<User[]>([])
  const [startDateYears, setStartDateYears] = useState<string[]>([])
  const [endDateYears, setEndDateYears] = useState<string[]>([])

  const { user } = useContext(UserContext)
  const globalRoles = user.roles
  const canCreateKapProgram = PermissionService.permissionToCreateKapProgram(globalRoles)

  const handleRowClick = (params: GridRowModel) => {
    const programId = params?.id
    navigate(ROUTES.KapDetailsRoot.nested.BasicInformation.params({ programId }))
  }

  const autoCompleteFilterOperator = (options: Option[], label: string, placeholder: string) =>
    getGridStringOperators()
      .filter((operator) => operator.value === 'contains')
      .map((operator) => ({
        ...operator,
        InputComponent: (gridFilterProps: GridFilterInputValueProps) => (
          <AutoCompleteFilterInput {...gridFilterProps} options={options} label={label} placeholder={placeholder} />
        ),
      }))

  const columns = useMemo<GridColDef[]>(
    () => [
      {
        field: 'id',
        flex: 0.6,
        headerName: getMessage('label.program.id'),
        filterOperators: getGridStringOperators().filter((x) => x.value === 'equals'),
        renderCell: ({ row }) => `${getMessage(`label.program.id.character`)}${row.id}`,
      },
      {
        field: 'short_title',
        flex: 2,
        headerName: getMessage('label.short.title'),
        filterOperators: getGridStringOperators().filter((x) => x.value === 'contains'),
      },
      {
        field: 'status',
        flex: 0.5,
        headerName: getMessage('label.project.status'),
        filterOperators: autoCompleteFilterOperator(
          PROGRAM_STATUSES.map((x) => ({
            label: getMessage(`label.project.status.${x}`),
            value: x,
          })),
          getMessage('label.grid.value'),
          getMessage('label.grid.filter.value'),
        ),
      },
      {
        field: 'canton_code',
        flex: 0.5,
        headerName: getMessage('label.canton'),
        filterOperators: autoCompleteFilterOperator(
          Object.keys(CANTONS).map((x) => ({
            label: x,
            value: x,
          })),
          getMessage('label.grid.value'),
          getMessage('label.grid.filter.value'),
        ),
      },
      {
        field: 'start_date',
        headerName: getMessage('label.start.date'),
        flex: 1,
        type: 'date',
        valueGetter: ({ value }) => value && new Date(value),
        renderCell: ({ value }) =>
          value && DateUtils.parseDateGraphQL(DateUtils.serializeGraphQLDate(value)).getFullYear(),
        filterOperators: autoCompleteFilterOperator(
          startDateYears.map((x) => ({
            label: x ? DateUtils.parseDateGraphQL(x).getFullYear().toString() : getMessage('label.field.not.available'),
            value: x,
          })),
          getMessage('label.grid.value'),
          getMessage('label.grid.filter.value'),
        ),
      },
      {
        field: 'end_date',
        headerName: getMessage('label.end.date'),
        flex: 1,
        type: 'date',
        valueGetter: ({ value }) => value && new Date(value),
        renderCell: ({ value }) =>
          value && DateUtils.parseDateGraphQL(DateUtils.serializeGraphQLDate(value)).getFullYear(),
        filterOperators: autoCompleteFilterOperator(
          endDateYears.map((x) => ({
            label: x ? DateUtils.parseDateGraphQL(x).getFullYear().toString() : getMessage('label.field.not.available'),
            value: x,
          })),
          getMessage('label.grid.value'),
          getMessage('label.grid.filter.value'),
        ),
      },
      {
        field: 'gfch_responsible',
        flex: 1.5,
        headerName: getMessage('label.kap.program.gfch.responsible'),
        renderCell: ({ value }) => {
          return <OverflowTooltip>{value}</OverflowTooltip>
        },
        sortable: false,
        filterOperators: autoCompleteFilterOperator(
          gfchCantonResponsibles.map((resp) => ({
            label: `${resp.first_name} ${resp.last_name}`,
            value: resp.id,
          })),
          getMessage('label.grid.value'),
          getMessage('label.grid.filter.value'),
        ),
      },
      {
        field: 'modules',
        flex: 0.5,
        headerName: getMessage('label.modules'),
        filterOperators: autoCompleteFilterOperator(
          KAP_MODULES.map((x) => ({
            label: getMessage(`label.module.${x}`),
            value: x,
          })),
          getMessage('label.grid.value'),
          getMessage('label.grid.filter.value'),
        ),
      },
    ],
    [endDateYears, getMessage, gfchCantonResponsibles, startDateYears],
  )

  const fetchRows = useCallback(
    async (rowsPerPage = PAGE_SIZE, items?: GridFilterItem[], pageParams?: number, sort?: GridSortItem) => {
      setLoading(true)
      try {
        const { rows, rowsCount } = await fetchKapRows(urqlClient)(
          gfchCantonResponsibles,
          rowsPerPage,
          items,
          pageParams,
          sort,
        )
        const rowsAdapted = rows.map((r) => {
          const programResponsibleUsers =
            gfchCantonResponsibles.filter(
              (responsible) => responsible.gfch_cantonal_responsibility?.includes(r.canton_code),
            ) ?? []
          return {
            ...r,
            status: getMessage(`label.project.status.${r.status}`),
            modules: r.modules?.map((m) => getMessage(`label.module.${m}`)).join(', '),
            gfch_responsible: {
              users: programResponsibleUsers,
              usersDisplayText: programResponsibleUsers
                .map((resp: User) => resp.first_name + ' ' + resp.last_name)
                .join(',  '),
            },
          }
        })
        setRows(rowsAdapted)
        setRowsCount(rowsCount)
      } catch (e) {
        notificationService.operationFailed()
      }
      setLoading(false)
    },
    [urqlClient, getMessage, gfchCantonResponsibles, notificationService],
  )

  const onGridChange = useCallback(
    async (reason?: string) => {
      if (gridRef?.current) {
        const sort = gridRef.current.state.sorting.sortModel?.[0] ?? { field: 'id', sort: 'desc' }
        const filtering = gridRef.current.state.filter.filterModel

        const page = gridRef.current.state.pagination.paginationModel.page
        const rowsPerPage = gridRef.current.state.pagination.paginationModel.pageSize
        setPaginationModel({ page: page, pageSize: rowsPerPage })

        if (reason !== 'restoreState') {
          StorageUtils.store(STORAGE_KEY._KAP_STORAGE, { sort, filtering, rowsPerPage })
        }

        await fetchRows(rowsPerPage, filtering.items, page, sort)
      }
    },
    [fetchRows, gridRef],
  )

  const applyFilters = useCallback(
    async (savedParams: any) => {
      const sort = savedParams?.sort
      const filtering = savedParams?.filtering
      const rowsPerPage = savedParams?.rowsPerPage ?? PAGE_SIZE

      await fetchRows(rowsPerPage, filtering.items, 0, sort)

      gridRef.current.setFilterModel(filtering, 'restoreState')
      gridRef.current.setSortModel([sort])
      gridRef.current.setPageSize(rowsPerPage)
    },
    [fetchRows, gridRef],
  )

  useEffect(() => {
    const savedParams = StorageUtils.get(STORAGE_KEY._KAP_STORAGE)
    if (savedParams) {
      applyFilters(savedParams)
      return
    }
    fetchRows()
  }, [fetchRows, applyFilters])

  useEffect(() => {
    const initData = async () => {
      const { document } = combinedQuery('fetchCantonResponsiblesAndProgramDates')
        .add(queryAllGfchCantonsResponsibles)
        .add(queryKapProgramsDates)

      const { data, error } = await urqlClient
        .query<{
          user: Query_Root['user']
          dossierStartDate: Query_Root['dossier']
          dossierEndDate: Query_Root['dossier']
        }>(document, undefined)
        .toPromise()

      if (error) {
        notificationService.operationFailed()
      } else {
        const startDates = data?.dossierStartDate.map((dossier) => dossier.start_date) ?? []
        const endDates = data?.dossierEndDate.map((dossier) => dossier.end_date) ?? []
        const gfchCantonResponsibleUsers =
          data?.user.filter((user) => user.gfch_cantonal_responsibility.length !== 0) ?? []
        setStartDateYears(startDates)
        setEndDateYears(endDates)
        setGfchCantonResponsibles(gfchCantonResponsibleUsers)
      }
    }
    initData()
  }, [notificationService, urqlClient])

  return (
    <DataGridProStyled
      initialState={{
        pagination: {
          paginationModel: paginationModel,
        },
      }}
      rows={rows}
      columns={columns}
      rowCount={rowsCount}
      apiRef={gridRef}
      localeText={gridTranslations}
      loading={loading}
      onRowClick={(params) => handleRowClick(params)}
      autoHeight
      pagination
      disableColumnPinning
      disableRowSelectionOnClick
      filterMode="server"
      paginationMode="server"
      sortingMode="server"
      pageSizeOptions={[10, 25, 50]}
      onPaginationModelChange={() => onGridChange()}
      onSortModelChange={(_, { reason }) => onGridChange(reason)}
      onFilterModelChange={(_, { reason }) => onGridChange(reason)}
      components={{
        Toolbar: () => (
          <AddKapProgramToolbar
            title={getMessage('label.program.kap.list')}
            addProgram={addProgram}
            canCreateProgram={canCreateKapProgram}
          />
        ),
      }}
    />
  )
}
