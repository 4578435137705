import { ReactElement } from 'react'
import { useParams } from 'react-router'
import { ProjectImplementationMilestone } from 'src/screens/shared/implementation/details/milestone/ProjectImplementationMilestone'

export const PfPgvImplementationMilestonePage = (): ReactElement => {
  const { projectId } = useParams()
  const pfPgvId = parseInt(projectId as string)

  return <ProjectImplementationMilestone baseUrl="/pf-pgv" projectId={pfPgvId} />
}
