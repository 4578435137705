import { useParams } from 'react-router'
import { Query_Root } from 'src/@types/graphql'
import { useMessageSource } from 'src/i18n/useMessageSource'
import { ROUTES } from 'src/routing/routes'
import { queryMilestoneById } from 'src/screens/shared/implementation/details/milestoneQueries'
import { KapProgramPrinciples } from 'src/screens/shared/principles/KapProgramPrinciples'
import { usePermissionsForKapMilestones } from 'src/service/security/PermissionHook'
import { DOSSIER_STATUS } from 'src/shared/constants/constants'
import { MILESTONE_RESPONSIBLE_TYPE_TYPE, MILESTONE_STATUS_TYPE } from 'src/shared/constants/milestone-constants'
import { useDelayedNavigate } from 'src/shared/utils/hooks/navigation-hooks'
import { useNotificationService } from 'src/shared/utils/NotificationService'
import { useQuery } from 'urql'

export const KapMilestonePrinciplesPage = () => {
  const { programId, milestoneId, milestonePath } = useParams()
  const program_id = parseInt(programId as string)
  const milestone_id = parseInt(milestoneId as string)

  const { getMessage } = useMessageSource()
  const notificationService = useNotificationService()
  const navigate = useDelayedNavigate()

  const [{ data, error }] = useQuery<{ milestone_by_pk: Query_Root['milestone_by_pk'] }, { id: number }>({
    query: queryMilestoneById,
    variables: { id: milestone_id },
  })

  if (error) {
    notificationService.operationFailed()
  }

  const milestone = data?.milestone_by_pk

  const { loading, canEdit } = usePermissionsForKapMilestones(
    program_id,
    milestone?.status as MILESTONE_STATUS_TYPE,
    milestone?.responsible_type as MILESTONE_RESPONSIBLE_TYPE_TYPE,
  )

  const onBack = () => {
    navigate(ROUTES.KapImplementationDetailsRoot.params({ programId }))
  }

  const onEditSubprinciple = (subprincipleId: number) => {
    const route = ROUTES.KapFinalReportMilestoneRoot.nested.PrinciplesEdit.params({
      programId,
      milestoneId,
      milestonePath,
      subprincipleId,
    })
    navigate(route)
  }

  return (
    <KapProgramPrinciples
      phase={DOSSIER_STATUS.IMPLEMENTATION}
      screenTitle={getMessage('label.program.milestone.type.FINAL_REPORT')}
      onBack={onBack}
      loading={loading}
      canEdit={canEdit}
      onEditSubprinciple={onEditSubprinciple}
    />
  )
}
