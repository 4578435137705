import { Container, Typography } from '@mui/material'
import { Link } from 'react-router-dom'
import { useEnvironment } from 'src/env/EnvironmentStore'
import { useMessageSource } from 'src/i18n/useMessageSource'
import { TYPOGRAPHY_MIXIN } from 'src/shared/constants/styling-constants'
import { BulletIcon } from 'src/shared/icons/Icons'
import { useUserLocale } from 'src/user/UserContext'
import styled from 'styled-components/macro'

const FooterStyled = styled('footer')`
  background-color: ${({ theme }) => theme.colors.common.white};
  border-top: 1px solid ${({ theme }) => theme.colors.grey.divider};

  .MuiContainer-maxWidthLg {
    padding-block: ${({ theme }) => theme.spacing(5)};
    display: flex;
    gap: ${({ theme }) => theme.spacing(1.5)};
    justify-content: center;
    align-items: center;
    color: ${({ theme }) => theme.colors.text.secondary};

    .MuiSvgIcon-root {
      font-size: 4px;
    }
  }
`

const LinkStyled = styled(Link)`
  ${TYPOGRAPHY_MIXIN.body2};
  color: ${({ theme }) => theme.colors.text.secondary};
  text-decoration: none;

  &:visited {
    color: ${({ theme }) => theme.colors.text.secondary};
  }

  &:hover {
    text-decoration: underline;
  }
`

export const Footer = ({ inHomePage }: { inHomePage?: boolean }) => {
  const { getMessage } = useMessageSource()
  const { backendUrl } = useEnvironment()
  const language = useUserLocale()
  const currentYear = new Date().getFullYear()

  return (
    <FooterStyled id="footer">
      <Container maxWidth="lg">
        <Typography variant="body2">{`${getMessage('label.gfch')} © ${currentYear}`}</Typography>
        {inHomePage && (
          <>
            <BulletIcon /> <Typography variant="body2">{`v${RELEASE_VERSION}`}</Typography>
            <BulletIcon />
            <LinkStyled
              to={`${backendUrl}/api/public/terms-and-conditions?language=${language.toUpperCase()}`}
              target="_blank"
            >
              {getMessage('label.terms.and.conditions')}
            </LinkStyled>
          </>
        )}
      </Container>
    </FooterStyled>
  )
}
