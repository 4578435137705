import { ListItemText } from '@mui/material'
import { useRef, useState } from 'react'
import { useParams } from 'react-router'
import { useMessageSource } from 'src/i18n/useMessageSource'
import { EXPORT_FORMAT } from 'src/shared/constants/constants'
import { ExportMenu } from 'src/shared/menu/ExportMenu'
import { S } from 'src/shared/styled/S'
import { useUserLocale } from 'src/user/UserContext'
import { LoadingMenuItem } from 'src/shared/menu/item/LoadingMenuItem'
import { downloadFileByUrl } from 'src/shared/utils/BlobUtils'

interface Props {
  year: number
}

export const KapMilestoneExportMenu = ({ year }: Props) => {
  const { programId } = useParams()
  const { getMessage } = useMessageSource()
  const language = useUserLocale()

  const exportMenuRef = useRef<{ closeMenu: () => void }>({ closeMenu: () => {} })

  const [loadingExport, setLoadingExport] = useState(false)

  const handlePlanningAndReportingExport = () => {
    setLoadingExport(true)
    downloadFileByUrl(`api/kap/program/${programId}/milestone/planning-and-reporting/${year}/export?lang=${language}`)
      .then(() => exportMenuRef.current.closeMenu())
      .finally(() => setLoadingExport(false))
  }

  return (
    <ExportMenu ref={exportMenuRef}>
      <LoadingMenuItem loading={loadingExport} disabled={loadingExport} onClick={handlePlanningAndReportingExport}>
        <ListItemText>{`${getMessage('label.export.kap.milestone.planning.and.reporting')} ${year}`}</ListItemText>
        <S.Typography.ExportFormat>({EXPORT_FORMAT.WORD})</S.Typography.ExportFormat>
      </LoadingMenuItem>
    </ExportMenu>
  )
}
