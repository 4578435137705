import { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { Criteria_Config, Query_Root } from 'src/@types/graphql'
import { useMessageSource } from 'src/i18n/useMessageSource'
import { getCriteriaConfigForMilestoneAssessmentQuery } from 'src/screens/shared/assessment-criteria/assessmentQueries'
import { ValidationListItemProps } from 'src/screens/shared/common/SummaryValidationUtils'
import { getKapMeasureAnnualPlanningValidation } from 'src/screens/shared/implementation/details/milestone/utils/AnnualPlanningValidationUtils'
import {
  getFeatureKpiValidation,
  getKapGoalRatingValidation,
  getKapMeasureRatingValidation,
  getProjectGoalMeasureRatingValidation,
} from 'src/screens/shared/implementation/details/milestone/utils/MilestoneAnnualReportValidation'
import {
  fetchFinancialReportsByMilestoneIdQuery,
  fetchQualitativeEvaluationByMilestoneQuery,
  milestoneEvaluationQuery,
  milestoneNotesQuery,
} from 'src/screens/shared/implementation/details/milestoneQueries'
import { fetchPrinciplesWithSubprinciplesQuery } from 'src/screens/shared/principles/kapProgramPrinciplesQueries'
import { ASSESSMENT_TYPE, CRITERIA_TYPE } from 'src/shared/constants/assessment-constants'
import { ENTITY_TYPE, FILE_TYPE, PROJECT } from 'src/shared/constants/constants'
import { MILESTONE_TYPE, MILESTONE_TYPE_TYPE } from 'src/shared/constants/milestone-constants'
import { queryFiles } from 'src/shared/presentation/fileQueries'
import { Utils } from 'src/shared/utils/Utils'
import { useUserLocale } from 'src/user/UserContext'
import { useClient } from 'urql'

export const useValidateMilestone = (
  milestoneId: number,
  milestoneType: MILESTONE_TYPE_TYPE,
  baseUrl: '/pf-kap' | '/pf-pgv' | '/kap',
  projectId?: number,
  applicationTypeId?: number,
): ValidationListItemProps[] => {
  const [validationViolations, setValidationViolations] = useState<ValidationListItemProps[]>([])
  const { getMessage } = useMessageSource()
  const urqlClient = useClient()
  const { programId } = useParams()
  const locale = useUserLocale()

  const getNotesValidation = useCallback(async () => {
    const violations: ValidationListItemProps[] = []

    const { data } = await urqlClient
      .query<{ milestone_by_pk: Query_Root['milestone_by_pk'] }>(milestoneNotesQuery, { milestoneId })
      .toPromise()

    if (data?.milestone_by_pk?.notes === null) {
      const violation = {
        message: getMessage('validation.fill.out.section', [
          getMessage('label.navigation.implementation.details'),
          getMessage('label.notes'),
        ]),
        tab: 'details',
        section: 'notes',
      }
      violations.push(violation)
    }
    return violations
  }, [getMessage, milestoneId, urqlClient])

  const getEvaluationNeedValidation = useCallback(async () => {
    const violations: ValidationListItemProps[] = []

    const { data } = await urqlClient
      .query<{ intermediate_discussion_evaluation: Query_Root['intermediate_discussion_evaluation'] }>(
        milestoneEvaluationQuery,
        { milestoneId },
      )
      .toPromise()

    if (
      data?.intermediate_discussion_evaluation.length === 0 ||
      data?.intermediate_discussion_evaluation?.[0]?.evaluation_need === null
    ) {
      const violation = {
        message: getMessage('validation.fill.out.section', [
          getMessage('label.navigation.implementation.evaluation'),
          getMessage('label.evaluation.need'),
        ]),
        tab: 'evaluation',
      }
      violations.push(violation)
    }
    return violations
  }, [getMessage, milestoneId, urqlClient])

  const getFileUploadValidation = useCallback(
    async (tabMessageLabel: string, sectionMessageLabel: string, tab: string) => {
      const violations: ValidationListItemProps[] = []
      const fileType = FILE_TYPE.MILESTONE_DETAILS_DOCUMENT
      const entityType = ENTITY_TYPE.MILESTONE
      const entityId = milestoneId

      const { data } = await urqlClient
        .query<
          { file: Query_Root['file'] },
          {
            fileType: string
            entityType: string
            entityId: number
          }
        >(queryFiles, { fileType, entityType, entityId })
        .toPromise()

      if (data?.file.length === 0) {
        const violation = {
          message: getMessage('validation.section.missing.upload', [
            getMessage(tabMessageLabel),
            getMessage(sectionMessageLabel),
          ]),
          tab: tab,
          section: 'documents',
        }
        violations.push(violation)
      }
      return violations
    },
    [getMessage, milestoneId, urqlClient],
  )

  const getExternalAssessmentValidation = useCallback(async () => {
    const violations: ValidationListItemProps[] = []

    // This validation is only for PF-KAP.
    const projectType = Utils.resolveProcess(baseUrl)
    if (projectType !== PROJECT.PF_KAP) {
      return violations
    }

    const { data } = await urqlClient
      .query<{
        milestone_assessment_criteria_selection: Query_Root['milestone_assessment_criteria_selection']
        criteria_group_config: Query_Root['criteria_group_config']
      }>(getCriteriaConfigForMilestoneAssessmentQuery, {
        milestoneId: milestoneId,
        process: projectType,
        criteriaType: CRITERIA_TYPE.QUALITY,
        assessorType: ASSESSMENT_TYPE.EXTERNAL,
        applicationTypeId: applicationTypeId,
      })
      .toPromise()

    if (data) {
      const mergedCriteriaConfigs: Criteria_Config[] = data.criteria_group_config.flatMap(
        (conf) => conf.criteria_configs,
      )

      const criteriaConfigSelections = mergedCriteriaConfigs.map((it) => {
        const configId = it.id
        const selectionValue = data.milestone_assessment_criteria_selection.find(
          (it) => it.criteria_config_id === configId,
        )?.rating

        return {
          config_id: configId,
          selection: selectionValue,
        }
      })

      if (criteriaConfigSelections.some((it) => !it.selection)) {
        const violation = {
          message: getMessage('validation.fill.out.section', [
            getMessage('label.assessment.type.external_assessment'),
            getMessage('label.assessment.type.external_assessment'),
          ]),
          tab: 'external-assessment',
        }
        violations.push(violation)
      }
    }

    return violations
  }, [baseUrl, getMessage, milestoneId, urqlClient, applicationTypeId])

  const getQualitativeEvaluationValidation = useCallback(async () => {
    const violations: ValidationListItemProps[] = []

    const { data } = await urqlClient
      .query<{ milestone_qualitative_evaluation: Query_Root['milestone_qualitative_evaluation'] }>(
        fetchQualitativeEvaluationByMilestoneQuery,
        { milestoneId },
      )
      .toPromise()

    if (baseUrl === '/pf-kap' || baseUrl == '/pf-pgv') {
      const qualitativeEvaluation = data?.milestone_qualitative_evaluation[0]

      if (
        !qualitativeEvaluation?.strength ||
        !qualitativeEvaluation?.weakness ||
        !qualitativeEvaluation?.opportunities ||
        !qualitativeEvaluation?.threats
      ) {
        const violation = {
          message: getMessage('validation.fill.out.section', [
            getMessage('label.navigation.milestone.qualitative.evaluation'),
            getMessage('label.milestone.qualitative.evaluation.swot.analysis'),
          ]),
          tab: 'qualitative-evaluation',
          section: 'swot-analysis',
        }
        violations.push(violation)
      }

      if (!qualitativeEvaluation?.equal_opportunity) {
        const violation = {
          message: getMessage('validation.fill.out.section', [
            getMessage('label.navigation.milestone.qualitative.evaluation'),
            getMessage('label.background.information.equal.opportunity'),
          ]),
          tab: 'qualitative-evaluation',
          section: 'equal-opportunity',
        }
        violations.push(violation)
      }

      if (!qualitativeEvaluation?.sustainability) {
        const violation = {
          message: getMessage('validation.fill.out.section', [
            getMessage('label.navigation.milestone.qualitative.evaluation'),
            getMessage('label.background.information.sustainability'),
          ]),
          tab: 'qualitative-evaluation',
          section: 'sustainability',
        }
        violations.push(violation)
      }

      if (!qualitativeEvaluation?.multiplication_potential) {
        const violation = {
          message: getMessage('validation.fill.out.section', [
            getMessage('label.navigation.milestone.qualitative.evaluation'),
            getMessage('label.background.information.multiplication.potential'),
          ]),
          tab: 'qualitative-evaluation',
          section: 'multiplication-potential',
        }
        violations.push(violation)
      }
    }

    return violations
  }, [baseUrl, getMessage, milestoneId, urqlClient])

  const getFinancialsValidation = useCallback(async () => {
    const violations: ValidationListItemProps[] = []
    const isProgram = baseUrl === '/kap'

    const { data } = await urqlClient
      .query<{
        project_financial_report: Query_Root['project_financial_report']
        kap_program_financial_report: Query_Root['kap_program_financial_report']
      }>(fetchFinancialReportsByMilestoneIdQuery, {
        milestoneId: milestoneId,
        isProgram: isProgram,
      })
      .toPromise()

    // PF KAP - PF PGV Projects violation
    const projectFinancialReport = data?.project_financial_report?.[0]
    const gfchShareField = projectFinancialReport?.gfch_share_annual_value ?? null
    const shareResponsibleOrganizationField =
      projectFinancialReport?.share_responsible_organization_annual_value ?? null
    const otherFundingField = projectFinancialReport?.other_funding_annual_value ?? null
    if (
      !isProgram &&
      (gfchShareField === null || shareResponsibleOrganizationField === null || otherFundingField === null)
    ) {
      const violation = {
        message: getMessage('validation.milestone.fill.out.financial.report'),
        tab: 'financials',
        section: 'milestone-project-financial-report',
      }
      violations.push(violation)
    }

    // KAP Programs violation
    const kapProgramFinancialReport = data?.kap_program_financial_report ?? []
    const hasAllGfchActualValues = kapProgramFinancialReport?.every(
      (moduleReport) => moduleReport.gfch_actual_value !== null,
    )
    const hasAllCantonActualValues = kapProgramFinancialReport?.every(
      (moduleReport) => moduleReport.canton_actual_value !== null,
    )
    if (isProgram && (kapProgramFinancialReport?.length < 1 || !hasAllGfchActualValues || !hasAllCantonActualValues)) {
      const violation = {
        message: getMessage('validation.milestone.fill.out.program.actuals'),
        tab: 'financials',
        section: 'program-actuals',
      }
      violations.push(violation)
    }

    return violations
  }, [getMessage, milestoneId, urqlClient, baseUrl])

  const getPrinciplesValidation = useCallback(async () => {
    const violations: ValidationListItemProps[] = []

    const { data } = await urqlClient
      .query<{
        principle_config: Query_Root['principle_config']
      }>(fetchPrinciplesWithSubprinciplesQuery, {
        locale: locale,
        programId: programId,
      })
      .toPromise()

    const principleConfigs = data?.principle_config
    const hasViolation = principleConfigs?.some((pc) =>
      pc.subprinciple_configs?.some((sc) => sc.kap_program_subprinciples?.[0].implementation_comment === null),
    )

    if (hasViolation) {
      const violation = {
        message: getMessage('validation.milestone.fill.out.principles'),
        tab: 'principles',
      }

      violations.push(violation)
    }

    return violations
  }, [getMessage, urqlClient, programId, locale])

  useEffect(() => {
    const validateMilestone = async () => {
      const processType = Utils.resolveProcess(baseUrl)
      switch (milestoneType) {
        case MILESTONE_TYPE.INTERMEDIATE_DISCUSSION: {
          if (processType === PROJECT.KAP) {
            const fileViolation = await getFileUploadValidation(
              'label.navigation.implementation.details',
              `label.${milestoneType}.documents`,
              'details',
            )
            setValidationViolations(fileViolation)
          } else if (processType === PROJECT.PF_KAP) {
            const [notesViolation, evaluationNeedValidations] = await Promise.all([
              getNotesValidation(),
              getEvaluationNeedValidation(),
            ])
            setValidationViolations([...notesViolation, ...evaluationNeedValidations])
          } else if (processType === PROJECT.PF_PGV) {
            const notesViolation = await getNotesValidation()
            setValidationViolations(notesViolation)
          }
          break
        }
        case MILESTONE_TYPE.CONTRACT:
        case MILESTONE_TYPE.EVALUATION_CONCEPT: {
          const violations = await getFileUploadValidation(
            'label.navigation.implementation.details',
            `label.${milestoneType}.documents`,
            'details',
          )
          setValidationViolations(violations)
          break
        }
        case MILESTONE_TYPE.FINAL_REPORT: {
          if (processType === PROJECT.KAP) {
            const principlesValidation = await getPrinciplesValidation()
            setValidationViolations(principlesValidation)
          } else {
            const [getExternalAssessmentValidations, getQualitativeEvaluationValidations] = await Promise.all([
              getExternalAssessmentValidation(),
              getQualitativeEvaluationValidation(),
            ])
            setValidationViolations([...getExternalAssessmentValidations, ...getQualitativeEvaluationValidations])
          }
          break
        }
        case MILESTONE_TYPE.ANNUAL_REPORT: {
          if (processType === PROJECT.KAP) {
            const [getKapMeasureRatingViolations, getKapGoalRatingViolations] = await Promise.all([
              getKapMeasureRatingValidation({ milestoneId, getMessage, urqlClient }),
              getKapGoalRatingValidation({ milestoneId, getMessage, urqlClient }),
            ])
            setValidationViolations([...getKapMeasureRatingViolations, ...getKapGoalRatingViolations])
            break
          }
          if (projectId) {
            const [goalMeasureRatingViolations, kpiViolations] = await Promise.all([
              getProjectGoalMeasureRatingValidation({ milestoneId, getMessage, urqlClient }),
              getFeatureKpiValidation({ milestoneId, getMessage, urqlClient, projectId, processType }),
            ])
            setValidationViolations([...goalMeasureRatingViolations, ...kpiViolations])
          }
          break
        }
        case MILESTONE_TYPE.FINANCIAL_REPORT: {
          if (processType === PROJECT.KAP) {
            const financialsViolations = await getFinancialsValidation()

            setValidationViolations(financialsViolations)
          } else {
            const [financialsViolations, fileUploadViolations] = await Promise.all([
              getFinancialsValidation(),
              getFileUploadValidation(
                'label.navigation.project.financials',
                'label.milestone.detailed.financial.report',
                'financials',
              ),
            ])
            setValidationViolations([...financialsViolations, ...fileUploadViolations])
          }
          break
        }
        case MILESTONE_TYPE.ANNUAL_PLAN: {
          const violations = await getKapMeasureAnnualPlanningValidation(milestoneId, urqlClient, getMessage)
          setValidationViolations(violations)
          break
        }
        default:
          throw Error(`Unsupported milestone type [${milestoneType}]`)
      }
    }
    validateMilestone()
  }, [
    milestoneType,
    baseUrl,
    getNotesValidation,
    getEvaluationNeedValidation,
    getFileUploadValidation,
    getExternalAssessmentValidation,
    getQualitativeEvaluationValidation,
    getFinancialsValidation,
    getPrinciplesValidation,
    getMessage,
    milestoneId,
    projectId,
    urqlClient,
  ])

  return validationViolations
}
