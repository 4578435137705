import { Stack } from '@mui/material'
import Box from '@mui/material/Box'
import { ReactElement } from 'react'
import { Form } from 'react-final-form'
import { Maybe } from 'src/@types/graphql'
import { TextLength } from 'src/shared/constants/constants'
import { HtmlEditorField } from 'src/shared/form/control/HtmlEditorField'
import { DirtyFormSpy } from 'src/shared/form/dirty/DirtyFormSpy'
import { createDecorators } from 'src/shared/form/utils/decorators'
import { composeValidators, maxChar, required as requiredValidator } from 'src/shared/form/validation/validators'
import { HtmlSanitizer } from 'src/shared/utils/HtmlSanitizer'

export interface InitialSituationAndVisionEditFormValues {
  projectBaseId: number
  value: string
  version: number
}
export const initialValuesFactoryInitialPageAndVision = (
  projectBaseId: number,
  value?: Maybe<string>,
  version?: number,
): InitialSituationAndVisionEditFormValues => {
  return {
    projectBaseId: projectBaseId,
    value: HtmlSanitizer.sanitize(value || ''),
    version: version || 0,
  }
}

interface FieldProps {
  initialValue: InitialSituationAndVisionEditFormValues
  onSave: (values: InitialSituationAndVisionEditFormValues) => Promise<void>
  textLength: TextLength
  label?: string
  required?: boolean
}

const decorators = createDecorators()

export const InitialSituationAndVisionFieldEditForm = ({
  initialValue,
  onSave,
  textLength,
  label,
  required,
}: FieldProps): ReactElement => {
  return (
    <Box>
      {initialValue && (
        <Form<InitialSituationAndVisionEditFormValues>
          initialValues={initialValue}
          onSubmit={onSave}
          decorators={decorators}
          render={({ handleSubmit }) => {
            return (
              <form id="edit-form" onSubmit={handleSubmit}>
                <Stack spacing={2}>
                  <HtmlEditorField
                    label={label}
                    required={required}
                    name="value"
                    validate={
                      required ? composeValidators(requiredValidator(), maxChar(textLength)) : maxChar(textLength)
                    }
                  />
                  <DirtyFormSpy />
                </Stack>
              </form>
            )
          }}
        />
      )}
    </Box>
  )
}
