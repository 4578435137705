import { Box, Typography, TypographyProps } from '@mui/material'
import { memo, ReactElement } from 'react'
import { HtmlSanitizer } from 'src/shared/utils/HtmlSanitizer'
import styled from 'styled-components/macro'

interface Props {
  html?: string
  truncate?: boolean
}

const TypographyStyled = styled(Typography)`
  color: ${({ theme }) => theme.colors.text.secondary};
  margin-top: ${({ theme }) => theme.spacing(0.5)};
  & > *,
  &:first-child {
    margin: 0;
  }

  & a {
    color: ${({ theme }) => theme.colors.secondary.dark};
    text-decoration: none;
    :hover {
      text-decoration: underline;
    }
  }
`

const HtmlRendererWrapperStyled = styled(Box)`
  min-width: 0;
  height: 100%;
  padding-block: ${({ theme }) => theme.spacing(1)};

  & > p:first-child {
    height: 100%;
    overflow: hidden;
  }
`

const HtmlRendererLocal = ({ html, truncate = false, ...rest }: Props & TypographyProps): ReactElement => {
  // this is an expensive operation but we are protected by memorizing the component before exporting it
  // that means that the re-render will happen only if the markdown prop changed.
  const sanitizedHtml = HtmlSanitizer.sanitize(html as string)

  if (truncate) {
    return (
      <HtmlRendererWrapperStyled>
        <HtmlRendererStyled html={html} {...rest} />
      </HtmlRendererWrapperStyled>
    )
  }

  return <TypographyStyled {...rest} dangerouslySetInnerHTML={{ __html: sanitizedHtml }} />
}

const HtmlRendererStyled = styled(HtmlRendererLocal)`
  &,
  ol {
    min-width: 0;
  }

  // Decrease padding for lists to adapt to the display within grid
  ol,
  ul {
    padding-left: ${({ theme }) => theme.spacing(2)};
    overflow: hidden;
  }

  p {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: break-spaces;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  li {
    overflow: hidden;
    text-overflow: ellipsis;
    list-style-position: inside;
  }
`

export const HtmlRenderer = memo(HtmlRendererLocal)
