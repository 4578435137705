import {
  Card,
  CardContent,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material'
import Box from '@mui/material/Box'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { File, Kap_Program, Maybe, Query_Root } from 'src/@types/graphql'
import { useMessageSource } from 'src/i18n/useMessageSource'
import { ROUTES } from 'src/routing/routes'
import { fetchKapProgramApplicationInfoQuery } from 'src/screens/shared/application/details/application-information/applicationInformationQueries'
import { usePermissionsForKapProgram } from 'src/service/security/PermissionHook'
import { EditButton } from 'src/shared/button/Buttons'
import { ENTITY_TYPE, FILE_TYPE } from 'src/shared/constants/constants'
import { DownloadIcon } from 'src/shared/icons/Icons'
import { PageLayout } from 'src/shared/layout/PageLayout'
import { ScreenLayout } from 'src/shared/layout/ScreenLayout'
import { ProgramExportMenu } from 'src/shared/menu/ProgramExportMenu'
import { FileSection } from 'src/shared/presentation/FileSection'
import { HelpAndInstructions } from 'src/shared/presentation/HelpAndInstructions'
import { ReadOnlyTextField } from 'src/shared/presentation/ReadOnly'
import { Section } from 'src/shared/presentation/Section'
import { DateUtils } from 'src/shared/utils/DateUtils'
import { useDelayedNavigate } from 'src/shared/utils/hooks/navigation-hooks'
import { useNotificationService } from 'src/shared/utils/NotificationService'
import { Utils } from 'src/shared/utils/Utils'
import styled from 'styled-components/macro'
import { useClient } from 'urql'

const ListItemButtonStyled = styled(ListItemButton)`
  border-radius: 4px;
  background-color: ${({ theme }) => theme.colors.action.hover};
  &:hover {
    background-color: ${({ theme }) => theme.colors.action.focus};
    & > .MuiListItemIcon-root {
      color: ${({ theme }) => theme.colors.text.primary};
    }
  }
`

export const KapApplicationInformationPage = () => {
  const { programId } = useParams()
  const program_id = parseInt(programId as string)

  const { getMessage } = useMessageSource()

  const urqlClient = useClient()
  const notificationService = useNotificationService()
  const navigate = useDelayedNavigate()

  const { canEditApplication, loading, canViewCantonRestricted } = usePermissionsForKapProgram(program_id)

  const [kapProgram, setKapProgram] = useState<Maybe<Kap_Program>>()
  const [file, setFile] = useState<File>()

  useEffect(() => {
    const fetchData = async () => {
      const queryParams = {
        id: program_id,
        file: true,
        fileType: FILE_TYPE.PROGRAM_APPLICATION_PDF,
        entityType: ENTITY_TYPE.KAP_PROGRAM,
      }

      const { data } = await urqlClient
        .query<{
          kap_program_by_pk: Query_Root['kap_program_by_pk']
          file: Query_Root['file']
        }>(fetchKapProgramApplicationInfoQuery, queryParams)
        .toPromise()

      if (data) {
        setKapProgram(data.kap_program_by_pk)
        setFile(data.file?.[0])
      } else {
        notificationService.operationFailed()
      }
    }

    fetchData()
  }, [urqlClient, program_id, notificationService])

  const handleEdit = () => {
    navigate(ROUTES.KapApplicationDetailsRoot.nested.InformationEdit.params({ programId }))
  }

  return (
    <ScreenLayout
      title={getMessage('label.navigation.application.details')}
      actions={<>{canViewCantonRestricted && <ProgramExportMenu />}</>}
    >
      <PageLayout>
        <>
          {!loading && kapProgram && (
            <>
              <Section
                id="application-information"
                title={getMessage('label.application.info')}
                helpAndInstructions={<HelpAndInstructions labelKey="label.help.kap.application.info" />}
                actionButton={
                  <EditButton origin="content" color="primary" hidden={!canEditApplication} onClick={handleEdit} />
                }
              >
                <Card>
                  <CardContent>
                    <Box>
                      <ReadOnlyTextField text={getMessage('label.kap.board.meeting.date')}>
                        {kapProgram.board_meeting_date && DateUtils.parseAndFormatDate(kapProgram.board_meeting_date)}
                      </ReadOnlyTextField>
                      <ReadOnlyTextField text={getMessage('label.application.decision')}>
                        {kapProgram.application_decision &&
                          getMessage(`label.recommendation.${kapProgram.application_decision}`)}
                      </ReadOnlyTextField>
                      <List disablePadding>
                        <ListItem disablePadding>
                          <ListItemButtonStyled disabled={!file} onClick={() => Utils.downloadFile(file?.uuid)}>
                            <ListItemIcon sx={{ minWidth: '40px' }}>
                              <DownloadIcon />
                            </ListItemIcon>
                            <ListItemText sx={{ flexGrow: 'initial' }}>
                              {getMessage('button.download.application')}
                            </ListItemText>
                            <Typography color="textSecondary">&nbsp;(pdf)</Typography>
                          </ListItemButtonStyled>
                        </ListItem>
                      </List>
                    </Box>
                  </CardContent>
                </Card>
              </Section>
              <FileSection
                id="documents"
                title={getMessage('label.kap.letter.of.intent')}
                fileType={FILE_TYPE.PROGRAM_APPLICATION_LETTER_OF_INTENT}
                entityType={ENTITY_TYPE.KAP_PROGRAM}
                entityId={program_id}
                canEdit={canEditApplication}
                helpAndInstructions={<HelpAndInstructions labelKey="label.help.kap.letter.of.intent" />}
              />
            </>
          )}
        </>
      </PageLayout>
    </ScreenLayout>
  )
}
