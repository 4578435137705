import { createContext, ReactElement, ReactNode, useMemo, useState } from 'react'

interface DirtyContextType {
  dirty: boolean
  setDirty: (_: boolean) => void
}

const defaultDirtyContext: DirtyContextType = {
  dirty: false,
  setDirty: (_: boolean) => {},
}
const DirtyContext = createContext<DirtyContextType>(defaultDirtyContext)
const DirtyContextProvider = ({ children }: { children: ReactNode }): ReactElement => {
  const [dirty, setDirty] = useState(false)

  const value = useMemo(() => {
    return {
      dirty,
      setDirty,
    }
  }, [dirty, setDirty])

  return <DirtyContext.Provider value={value}>{children}</DirtyContext.Provider>
}

DirtyContextProvider.displayName = 'DirtyContextProvider'

export { DirtyContext, DirtyContextProvider }
