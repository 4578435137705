import { get } from 'lodash'
import { ReactElement, useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router'
import { Query_Root, Success_Factor, Success_Factor_Analysis, Success_Factor_Indicator } from 'src/@types/graphql'
import { ROUTES } from 'src/routing/routes'
import { fetchAnalysisSuccessFactorDataQuery } from 'src/screens/success-factor-tool/analysis/details/analysis/analysisQueries'
import { SuccessFactorIndicatorsSection } from 'src/screens/success-factor-tool/analysis/details/analysis/edit/SuccessFactorIndicatorsSection'
import {
  SuccessFactorRatingForm,
  SuccessFactorRatingFormValues,
} from 'src/screens/success-factor-tool/analysis/details/analysis/edit/SuccessFactorRatingForm'
import { AnalysisUtils } from 'src/screens/success-factor-tool/analysis/details/analysis/utils/AnalysisUtils'
import { usePermissionsForSuccessFactors } from 'src/service/security/PermissionHook'
import { SaveAndBackButton, SaveButton } from 'src/shared/button/Buttons'
import { LIFE_PHASES } from 'src/shared/constants/success-factor-constants'
import { PageLayout } from 'src/shared/layout/PageLayout'
import { ScreenLayout } from 'src/shared/layout/ScreenLayout'
import { NotAuthorized } from 'src/shared/not-authorized/NotAuthorized'
import { useDelayedNavigate } from 'src/shared/utils/hooks/navigation-hooks'
import { useNotificationService } from 'src/shared/utils/NotificationService'
import { useUserLocale } from 'src/user/UserContext'
import { useClient } from 'urql'

type originType = 'SAVE' | 'SAVE_AND_BACK'

export const AnalysisSuccessFactorEditPage = (): ReactElement => {
  const { analysisId, successFactorId } = useParams()
  const originRef = useRef<originType>('SAVE')
  const urqlClient = useClient()
  const notificationService = useNotificationService()
  const navigate = useDelayedNavigate()
  const locale = useUserLocale()
  const { canEdit } = usePermissionsForSuccessFactors()

  const [successFactorData, setSuccessFactorData] = useState<Success_Factor | null>(null)
  const [indicatorData, setIndicatorData] = useState<Success_Factor_Indicator[] | null>(null)
  const [analysisData, setAnalysisData] = useState<Success_Factor_Analysis | null>(null)
  const [initialValues, setInitialValues] = useState<SuccessFactorRatingFormValues>()

  useEffect(() => {
    const initData = async () => {
      const { data, error } = await urqlClient
        .query<
          {
            success_factor_by_pk: Query_Root['success_factor_by_pk']
            success_factor_indicator: Query_Root['success_factor_indicator']
            success_factor_analysis_by_pk: Query_Root['success_factor_analysis_by_pk']
            analysis_success_factor_rating: Query_Root['analysis_success_factor_rating']
          },
          { successFactorId: number; analysisId: number }
        >(fetchAnalysisSuccessFactorDataQuery, {
          successFactorId: parseInt(successFactorId as string),
          analysisId: parseInt(analysisId as string),
        })
        .toPromise()

      if (data) {
        const indicatorData = data.success_factor_indicator ?? null
        const analysisData = data.success_factor_analysis_by_pk ?? null
        const successFactorData = data.success_factor_by_pk ?? null
        setSuccessFactorData(successFactorData)
        setIndicatorData(indicatorData)
        setAnalysisData(analysisData)
        if (data.success_factor_by_pk) {
          const successFactorAnalysisData = AnalysisUtils.mapSuccessFactorToAnalysisSuccessFactorModel(
            [data.success_factor_by_pk],
            data.analysis_success_factor_rating,
          )
          const comment = successFactorAnalysisData?.[0]?.comment ?? null
          const status = successFactorAnalysisData?.[0]?.status ?? null
          const ratingPriority = successFactorAnalysisData?.[0]?.ratingPriority ?? 0
          const ratingStatus = successFactorAnalysisData?.[0]?.ratingStatus ?? 0
          setInitialValues({ comment, status, ratingStatus, ratingPriority } as SuccessFactorRatingFormValues)
        }
      } else if (error) {
        notificationService.operationFailed()
      }
    }
    initData()
  }, [successFactorId, analysisId, urqlClient, notificationService])

  const pageTitle = successFactorData
    ? `${successFactorData?.sort_number}. ${[get(successFactorData?.names, locale, '')]}`
    : ''
  const topicIndicators = analysisData?.topic_indicator ?? []

  const resolveLifePhaseIndicators = () => {
    if (analysisData?.life_phase_indicator !== LIFE_PHASES.GENERAL) {
      return [analysisData?.life_phase_indicator, LIFE_PHASES.GENERAL]
    }
    return [analysisData?.life_phase_indicator]
  }

  const analysisIndicators =
    indicatorData
      ?.filter(
        (indicator) =>
          topicIndicators.includes(indicator.indicator_code) ||
          resolveLifePhaseIndicators().includes(indicator.indicator_code),
      )
      .sort((a, b) => a.sort_number - b.sort_number) ?? []

  const onSubmit = (origin: originType) => (_: any) => {
    // trigger submit event
    if (originRef) {
      originRef.current = origin
    }
    document.getElementById('edit-form')?.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }))
  }

  const onBack = () => {
    const route = ROUTES.SuccessFactorAnalysisRoot.nested.AnalysisDetails.params({ analysisId })
    navigate(`${route}#${successFactorData?.code?.replaceAll('_', '-')}`)
  }

  if (!canEdit) {
    return <NotAuthorized inEditPage />
  }

  return (
    <ScreenLayout
      title={pageTitle}
      onBack={onBack}
      hasSecondLevelNavigation={false}
      actions={
        <>
          <SaveAndBackButton origin="header" onClick={onSubmit('SAVE_AND_BACK')} />
          <SaveButton origin="header" onClick={onSubmit('SAVE')} />
        </>
      }
    >
      <PageLayout>
        <SuccessFactorIndicatorsSection
          description={[get(successFactorData?.description, locale, '')]}
          indicators={analysisIndicators}
        />
        <SuccessFactorRatingForm
          successFactorId={parseInt(successFactorId as string)}
          analysisId={parseInt(analysisId as string)}
          initialValues={initialValues}
          setInitialValues={setInitialValues}
          onBack={onBack}
          originRef={originRef}
        />
      </PageLayout>
    </ScreenLayout>
  )
}
