import { LoadingButton } from '@mui/lab'
import { Alert, Button, DialogActions, DialogContent, Stack } from '@mui/material'
import { ReactElement, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Form } from 'react-final-form'
import { Dossier, Maybe, Project_Application_Decision, Project_Base_Bool_Exp, Query_Root } from 'src/@types/graphql'
import { useMessageSource } from 'src/i18n/useMessageSource'
import {
  resolveApplicationCommitteeRoot,
  resolveApplicationDetailsRoot,
  resolveImplementationDetailsRoot,
} from 'src/routing/routing-utils'
import { SummarySection } from 'src/screens/shared/application/common/SummarySection'
import { queryDossierAndProjectApplicationByProjectBaseId } from 'src/screens/shared/application/details/summary/applicationSummaryQueries'
import { ValidationListItemProps } from 'src/screens/shared/common/SummaryValidationUtils'
import { WorkflowService } from 'src/service/axios/WorkflowService'
import { usePermissionsForProjectApplication } from 'src/service/security/PermissionHook'
import { WorkflowPermissionService } from 'src/service/security/WorkflowPermissionService'
import { EditButton, SecondaryButton } from 'src/shared/button/Buttons'
import { DOSSIER_STATUS, DOSSIER_STATUS_TYPE, PROJECT } from 'src/shared/constants/constants'
import { NextState } from 'src/shared/constants/workflow-constants'
import { AutoCompleteField } from 'src/shared/form/control/AutoCompleteField'
import { required } from 'src/shared/form/validation/validators'
import { ErrorOutlineIcon } from 'src/shared/icons/Icons'
import { PageLayout } from 'src/shared/layout/PageLayout'
import { ScreenLayout } from 'src/shared/layout/ScreenLayout'
import { ProjectExportMenu } from 'src/shared/menu/ProjectExportMenu'
import { HandoverModalDialog } from 'src/shared/modal-dialog/HandoverModalDialog'
import { ModalDialog } from 'src/shared/modal-dialog/ModalDialog'
import { NotAuthorized } from 'src/shared/not-authorized/NotAuthorized'
import { useSidebarAPI } from 'src/shared/sidebar/SidebarAwareContext'
import { useDelayedNavigate } from 'src/shared/utils/hooks/navigation-hooks'
import { useNotificationService } from 'src/shared/utils/NotificationService'
import { resolveProcessToLowerCase, Utils } from 'src/shared/utils/Utils'
import { NextStateIf } from 'src/shared/workflow/NextStateIf'
import { useUserLocale } from 'src/user/UserContext'
import styled from 'styled-components/macro'
import { useClient } from 'urql'

interface Props {
  baseUrl: '/pf-kap' | '/pf-pgv'
  projectId: number
}

interface FinalDecisionForm {
  decision: DOSSIER_STATUS_TYPE | ''
}

const SpanStyled = styled.span<{ $isCanceled: string }>`
  color: ${({ $isCanceled, theme }) =>
    $isCanceled === `${DOSSIER_STATUS.CANCELED}` ? theme.colors.error.dark : 'inherit'};
`

export const ApplicationSummaryPage = ({ baseUrl, projectId }: Props): ReactElement => {
  const { getMessage } = useMessageSource()
  const language = useUserLocale()
  const process = resolveProcessToLowerCase(baseUrl)
  const projectType = Utils.resolveProcess(baseUrl)
  const navigate = useDelayedNavigate()
  const urqlClient = useClient()
  const notificationService = useNotificationService()
  const sidebarAPI = useSidebarAPI()
  const [applicationSummary, setApplicationSummary] = useState<Dossier>()
  const [validationViolations, setValidationViolations] = useState<ValidationListItemProps[]>([])
  const [nextStates, setNextStates] = useState<Maybe<NextState[]>>(null)

  const [implementationConfirmationOpen, setImplementationConfirmationOpen] = useState<boolean>(false)
  const [transitionToImplementation, setTransitionToImplementation] = useState<boolean>(false)

  const [revisionConfirmationOpen, setRevisionConfirmationOpen] = useState<boolean>(false)
  const [transitionToRevisionLoading, setTransitionToRevisionLoading] = useState<boolean>(false)

  const [withdrawnConfirmationOpen, setWithdrawnConfirmationOpen] = useState<boolean>(false)
  const [transitionToWithdrawnLoading, setTransitionToWithdrawnLoading] = useState<boolean>(false)

  const [decideModalOpen, setDecideModalOpen] = useState<boolean>(false)
  const [decideTransitionLoading, setDecideTransitionLoading] = useState<boolean>(false)

  const decisionOptions = useMemo(
    () =>
      [DOSSIER_STATUS.ACCEPTED, DOSSIER_STATUS.EXCLUDED, DOSSIER_STATUS.REJECTED].map((status) => ({
        label: getMessage(`button.set.to.${status.toLowerCase()}`),
        value: status,
      })),
    [getMessage],
  )

  const {
    loading,
    canView,
    canEdit,
    canViewSummaryTab,
    metadata: { projectBaseId, userProjectRoles, userGlobalRoles },
    refetch: refetchPermissions,
  } = usePermissionsForProjectApplication(projectType, projectId)

  const validateApplication = useCallback(
    (
      applicationInformation: Project_Application_Decision,
      projectStatus: DOSSIER_STATUS_TYPE,
    ): ValidationListItemProps[] => {
      const validationViolations: ValidationListItemProps[] = []
      if (projectStatus === DOSSIER_STATUS.CANCELED) {
        return validationViolations
      } else {
        if (!applicationInformation.application_decision) {
          const violation = {
            message: getMessage('validation.fill.out.section', [
              getMessage('label.navigation.application.information'),
              getMessage('label.application.decision'),
            ]),
            tab: 'application-information',
            section: 'information',
          }
          validationViolations.push(violation)
        }
        if (!applicationInformation.project_base.gfch_responsible_id && projectStatus === DOSSIER_STATUS.ACCEPTED) {
          const violation = {
            message: getMessage('validation.fill.out.section', [
              getMessage('label.final.decision'),
              getMessage('label.gfch.responsible'),
            ]),
            root: resolveApplicationCommitteeRoot(baseUrl).params({ projectId }),
            section: 'final-decision',
          }
          validationViolations.push(violation)
        }
        return validationViolations
      }
    },
    [baseUrl, getMessage, projectId],
  )

  const fetchData = useCallback(async () => {
    const projectPfKapBaseWhereClause: Project_Base_Bool_Exp = {
      pf_kap_projects: {
        id: {
          _eq: projectId,
        },
      },
    }

    const projectPfPgvBaseWhereClause: Project_Base_Bool_Exp = {
      pf_pgv_projects: {
        id: {
          _eq: projectId,
        },
      },
    }

    const projectBaseQuery: Project_Base_Bool_Exp = {
      ...(projectType === PROJECT.PF_KAP && projectPfKapBaseWhereClause),
      ...(projectType === PROJECT.PF_PGV && projectPfPgvBaseWhereClause),
    }

    const { data } = await urqlClient
      .query<{ dossier: Query_Root['dossier'] }>(queryDossierAndProjectApplicationByProjectBaseId, { projectBaseQuery })
      .toPromise()

    const nextStates = await WorkflowService.nextStates(data?.dossier?.[0]?.project_workflow_id ?? '')
    const nextStatesForUser = WorkflowPermissionService.nextStatesForUser(nextStates)(userGlobalRoles, userProjectRoles)

    const validationViolations = validateApplication(
      {
        application_decision:
          data?.dossier[0]?.project_bases?.[0]?.project_application_decisions?.[0]?.application_decision,
        project_base: data?.dossier[0]?.project_bases?.[0],
      } as Project_Application_Decision,
      data?.dossier[0]?.status as DOSSIER_STATUS_TYPE,
    )

    setApplicationSummary(data?.dossier[0])
    setNextStates(nextStatesForUser)
    setValidationViolations(validationViolations)
  }, [projectId, projectType, urqlClient, userGlobalRoles, userProjectRoles, validateApplication])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  const handleViolationClick = (validationItem: ValidationListItemProps) => {
    const route = validationItem.root
      ? validationItem.root
      : resolveApplicationDetailsRoot(baseUrl).params({ projectId })

    navigate(
      `${route}${validationItem.tab ? '/' + validationItem.tab : ''}${
        validationItem.section ? `#${validationItem.section}` : ''
      }`,
    )
  }

  const summaryCommentsEditHandler = () => {
    navigate(resolveApplicationDetailsRoot(baseUrl).nested.SummaryCommentEdit.params({ projectId }))
  }

  const openRevisionConfirmationModalDialog = () => {
    setRevisionConfirmationOpen(true)
  }

  const cancelRevisionConfirmationModalDialog = () => {
    setRevisionConfirmationOpen(false)
  }

  const confirmRevisionHandler = async () => {
    try {
      setTransitionToRevisionLoading(true)
      await WorkflowService.transition(
        applicationSummary?.project_workflow_id as string,
        DOSSIER_STATUS.REVISION,
        language,
      )
      refetchPageData()
      sidebarAPI.refetchSidebarData()
      setRevisionConfirmationOpen(false)
      notificationService.changesSaved()
    } catch (e) {
      notificationService.operationFailed()
    } finally {
      setTransitionToRevisionLoading(false)
    }
  }

  const confirmDecisionHandler = async (decision: DOSSIER_STATUS_TYPE) => {
    try {
      setDecideTransitionLoading(true)
      await WorkflowService.transition(applicationSummary?.project_workflow_id as string, decision, language)
      refetchPageData()
      sidebarAPI.refetchSidebarData()
      setDecideModalOpen(false)
      notificationService.changesSaved()
    } catch (e) {
      notificationService.operationFailed()
    } finally {
      setDecideTransitionLoading(false)
    }
  }

  const openWithdrawnConfirmationModalDialog = () => {
    setWithdrawnConfirmationOpen(true)
  }

  const cancelWithdrawnConfirmationModalDialog = () => {
    setWithdrawnConfirmationOpen(false)
  }

  const confirmWithdrawnHandler = async () => {
    try {
      setTransitionToWithdrawnLoading(true)
      await WorkflowService.transition(
        applicationSummary?.project_workflow_id as string,
        DOSSIER_STATUS.WITHDRAWN,
        language,
      )
      refetchPageData()
      sidebarAPI.refetchSidebarData()
      setWithdrawnConfirmationOpen(false)
      notificationService.changesSaved()
    } catch (e) {
      notificationService.operationFailed()
    } finally {
      setTransitionToWithdrawnLoading(false)
    }
  }

  const refetchPageData = () => {
    fetchData()
    refetchPermissions()
    exportMenuRef.current.refetch()
  }

  const openImplementationConfirmationModalDialog = () => {
    setImplementationConfirmationOpen(true)
  }

  const cancelImplementationModalDialogHandler = () => {
    setImplementationConfirmationOpen(false)
  }

  const confirmSetToImplementationHandler = async () => {
    try {
      setTransitionToImplementation(true)
      await WorkflowService.transition(
        applicationSummary?.project_workflow_id as string,
        DOSSIER_STATUS.IMPLEMENTATION,
        language,
      )
      refetchPageData()
      sidebarAPI.refetchSidebarData()
      navigate(resolveImplementationDetailsRoot(baseUrl).params({ projectId }))
      setImplementationConfirmationOpen(false)
      notificationService.changesSaved()
    } catch (e) {
      notificationService.operationFailed()
    } finally {
      setTransitionToImplementation(false)
    }
  }

  const onSubmitDecision = (values: FinalDecisionForm) => {
    const { decision } = values
    if (decision !== '') {
      confirmDecisionHandler(decision)
    }
  }

  const exportMenuRef = useRef<{ refetch: () => void }>({ refetch: () => {} })

  if (!loading && !canView) {
    return <NotAuthorized />
  }

  const getValidationCompleteMessage = (): string => {
    if (applicationSummary?.status === DOSSIER_STATUS.CANCELED) {
      return getMessage('label.project.is.canceled')
    } else {
      return getMessage('label.application.summary.check.complete')
    }
  }

  return (
    <ScreenLayout
      title={getMessage('label.navigation.application.details')}
      actions={<ProjectExportMenu process={projectType} entityId={projectBaseId as number} ref={exportMenuRef} />}
    >
      {!loading && !canViewSummaryTab && <NotAuthorized inSecondLevelNavigationTab={true} />}
      {!loading && canViewSummaryTab && applicationSummary && nextStates !== null && (
        <PageLayout>
          <SummarySection
            title={getMessage('label.application.summary.title')}
            actionsHelpLabel={`label.help.application.summary.${process}`}
            status={
              <>
                {`${getMessage('label.project.status')}: `}
                <SpanStyled $isCanceled={applicationSummary.status}>
                  {getMessage(`label.project.status.${applicationSummary.status}`)}
                </SpanStyled>
              </>
            }
            violations={validationViolations}
            violationClickHandler={handleViolationClick}
            actionsCompleteMessage={getValidationCompleteMessage()}
            noItemsToValidate={applicationSummary.status === DOSSIER_STATUS.CANCELED}
            statusActions={
              <>
                <NextStateIf
                  forState={[DOSSIER_STATUS.WITHDRAWN]}
                  nextStates={nextStates}
                  userGlobalRoles={userGlobalRoles}
                  userProjectRoles={userProjectRoles}
                >
                  <Button
                    variant="outlined"
                    color="secondary"
                    sx={{
                      display:
                        (applicationSummary.status !== DOSSIER_STATUS.APPLICATION &&
                          applicationSummary.status !== DOSSIER_STATUS.ACCEPTED) ||
                        !canEdit
                          ? 'none'
                          : 'inline-block',
                    }}
                    onClick={openWithdrawnConfirmationModalDialog}
                  >
                    {getMessage('button.set.to.withdrawn')}
                  </Button>
                </NextStateIf>
                <NextStateIf
                  forState={[DOSSIER_STATUS.REVISION]}
                  nextStates={nextStates}
                  userGlobalRoles={userGlobalRoles}
                  userProjectRoles={userProjectRoles}
                >
                  <Button
                    variant="outlined"
                    color="secondary"
                    sx={{
                      display:
                        applicationSummary.status !== DOSSIER_STATUS.APPLICATION || !canEdit ? 'none' : 'inline-block',
                    }}
                    onClick={openRevisionConfirmationModalDialog}
                  >
                    {getMessage('button.set.to.revision')}
                  </Button>
                </NextStateIf>
                <NextStateIf
                  forState={[DOSSIER_STATUS.ACCEPTED, DOSSIER_STATUS.EXCLUDED, DOSSIER_STATUS.REJECTED]}
                  nextStates={nextStates}
                  userGlobalRoles={userGlobalRoles}
                  userProjectRoles={userProjectRoles}
                >
                  <Button
                    variant="contained"
                    color="secondary"
                    disabled={validationViolations.length > 0}
                    sx={{
                      display:
                        applicationSummary.status !== DOSSIER_STATUS.APPLICATION || !canEdit ? 'none' : 'inline-block',
                    }}
                    onClick={() => setDecideModalOpen(true)}
                  >
                    {getMessage('button.decide')}
                  </Button>
                </NextStateIf>
                <NextStateIf
                  forState={[DOSSIER_STATUS.IMPLEMENTATION]}
                  nextStates={nextStates}
                  userGlobalRoles={userGlobalRoles}
                  userProjectRoles={userProjectRoles}
                >
                  <Button
                    variant="contained"
                    color="secondary"
                    disabled={validationViolations.length > 0}
                    sx={{
                      display:
                        applicationSummary.status !== DOSSIER_STATUS.ACCEPTED || !canEdit ? 'none' : 'inline-block',
                    }}
                    onClick={openImplementationConfirmationModalDialog}
                  >
                    {getMessage('button.set.to.implementation')}
                  </Button>
                </NextStateIf>
              </>
            }
            commentsHelpLabel={`label.help.application.summary.comments.${process}`}
            commentActions={<EditButton onClick={summaryCommentsEditHandler} hidden={!canEdit} />}
            comment={applicationSummary.project_bases[0]?.project_application_decisions[0]?.application_comment}
          />
          <HandoverModalDialog
            open={implementationConfirmationOpen}
            onSend={confirmSetToImplementationHandler}
            onCancel={cancelImplementationModalDialogHandler}
            entityId={projectId}
            loading={transitionToImplementation}
            processType={projectType}
            titleKey="label.application.set.to.implementation.confirm.title"
            contentKey="label.application.set.to.implementation.confirm.body"
            buttonLoadingKey="button.confirm"
          />
          <HandoverModalDialog
            open={revisionConfirmationOpen}
            onSend={confirmRevisionHandler}
            onCancel={cancelRevisionConfirmationModalDialog}
            entityId={projectId}
            loading={transitionToRevisionLoading}
            processType={projectType}
            titleKey="label.project.confirm.revision.title"
            contentKey="label.project.confirm.revision.body"
            buttonLoadingKey="button.confirm"
          />
          <HandoverModalDialog
            open={withdrawnConfirmationOpen}
            onSend={confirmWithdrawnHandler}
            onCancel={cancelWithdrawnConfirmationModalDialog}
            entityId={projectId}
            loading={transitionToWithdrawnLoading}
            processType={projectType}
            titleKey="label.project.confirm.withdrawn.title"
            contentKey="label.project.confirm.withdrawn.body"
            buttonLoadingKey="button.confirm"
          />
          <ModalDialog
            title={getMessage('label.final.decision')}
            onClose={() => setDecideModalOpen(false)}
            open={decideModalOpen}
            withCloseIcon
            maxWidth="sm"
          >
            <Form<FinalDecisionForm>
              initialValues={{ decision: '' }}
              onSubmit={onSubmitDecision}
              render={({ handleSubmit, valid }) => {
                return (
                  <form noValidate onSubmit={handleSubmit}>
                    <DialogContent>
                      <Stack spacing={2}>
                        <AutoCompleteField
                          required
                          label={getMessage('label.decision')}
                          name="decision"
                          options={decisionOptions}
                          validate={required()}
                        />
                        <Alert severity="warning" icon={<ErrorOutlineIcon />}>
                          {getMessage('label.action.cannot.be.undone')}
                        </Alert>
                      </Stack>
                    </DialogContent>
                    <DialogActions>
                      <SecondaryButton messageKey={'button.cancel'} onClick={() => setDecideModalOpen(false)} />
                      <LoadingButton
                        type="submit"
                        variant="contained"
                        color="primary"
                        loading={decideTransitionLoading}
                        disabled={!valid}
                      >
                        {getMessage('button.publish')}
                      </LoadingButton>
                    </DialogActions>
                  </form>
                )
              }}
            />
          </ModalDialog>
        </PageLayout>
      )}
    </ScreenLayout>
  )
}
