import { ReactElement } from 'react'
import { ReactComponent as NotAuthorizedIllustration } from 'src/assets/error-lock-illustration.svg'
import { useMessageSource } from 'src/i18n/useMessageSource'
import { NoAccessDefault } from 'src/shared/not-authorized/NoAccessDefault'

interface Props {
  inSecondLevelNavigationTab?: boolean
  inEditPage?: boolean
}

export const NotAuthorized = ({ inSecondLevelNavigationTab, inEditPage }: Props): ReactElement => {
  const { getMessage } = useMessageSource()

  return (
    <NoAccessDefault
      illustration={<NotAuthorizedIllustration height="7rem" width="7rem" />}
      label={getMessage('label.not.authorized')}
      inSecondLevelNavigationTab={inSecondLevelNavigationTab}
      inEditPage={inEditPage}
    />
  )
}
