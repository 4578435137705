import { ReactElement } from 'react'
import { useParams } from 'react-router'
import { ApplicationCommitteePage } from 'src/screens/shared/application/committee/ApplicationCommitteePage'

export const PfKapApplicationCommitteePage = (): ReactElement => {
  const { projectId } = useParams()
  const pf_kap_id = parseInt(projectId as string)

  return <ApplicationCommitteePage baseUrl="/pf-kap" projectId={pf_kap_id} />
}
