import createDecorator from 'final-form-focus'

export const createDecorators = (): any => {
  return [createDecorator(getAllInputs)]
}

/**
 * Gets all the inputs inside all forms on the page
 */
export const getAllInputs: GetInputs = () => {
  if (typeof document === 'undefined') {
    return []
  }

  const customFormControls = Array.from(document.querySelectorAll('input, [data-custom-input="html-editor"]')).reduce<
    FocusableInput[]
  >((accumulator, current) => {
    let focusableInput: FocusableInput

    // check if the input is "custom" -> at the moment of writing this is only the `HtmlEditorField`
    if (current.getAttribute('data-custom-input')) {
      const name = current.getAttribute('data-name')

      if (!name) {
        throw Error('You are using a custom input without an attribute "data-name" which is not allowed.')
      }
      focusableInput = {
        focus: () => {
          const htmlEditor = current.querySelector('.ql-editor') as HTMLElement
          htmlEditor.focus()
        },
        name,
      }
    } else {
      focusableInput = current as any as FocusableInput
    }

    return accumulator.concat(focusableInput)
  }, [])

  return [...customFormControls]
}

export type FocusableInput = { name: string; focus: () => void }

export type GetInputs = () => FocusableInput[]
