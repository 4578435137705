import { Location } from 'history'
import { ReactElement, useEffect, useState } from 'react'
import { Outlet, useLocation, useParams } from 'react-router'
import { ROUTES } from 'src/routing/routes'
import { NestedRoute, Route, SecondLevelRoute } from 'src/routing/routing'
import { SecondLevelNavigation } from 'src/routing/SecondLevelNavigation'
import { ChartContextProvider } from 'src/shared/charts/ChartContext'
import { PageLayout } from 'src/shared/layout/PageLayout'
import { SecondLevelNavigationContainer } from 'src/shared/layout/SecondLevelNavigationContainer'

const resolveActiveRoute = (location: Location, analysisId: string): SecondLevelRoute => {
  const nested = ROUTES.SuccessFactorAnalysisRoot!.nested! as unknown as NestedRoute

  const activeRoute = Object.keys(nested).find(
    (key) =>
      location.pathname.endsWith(nested[key].path) ||
      (ROUTES.SuccessFactorAnalysisRoot.params({ analysisId }) === location.pathname && nested[key].primary),
  ) as string
  return nested[activeRoute] as SecondLevelRoute
}

export const SuccessFactorsDetailsIndexPage = (): ReactElement => {
  const { analysisId } = useParams()
  const location = useLocation()

  const [activeRoute, setActiveRoute] = useState<SecondLevelRoute>(() => {
    return resolveActiveRoute(location, analysisId as string)
  })

  useEffect(() => {
    const activeRoute = resolveActiveRoute(location, analysisId as string)
    setActiveRoute(activeRoute)
  }, [location, analysisId])

  const showNavigation = activeRoute && !activeRoute.hideNavigation

  return (
    <SecondLevelNavigationContainer $showNavigation={showNavigation}>
      {showNavigation && (
        <PageLayout>
          <SecondLevelNavigation
            route={ROUTES.SuccessFactorAnalysisRoot as unknown as Route}
            baseUrl={ROUTES.SuccessFactorAnalysisRoot.params({ analysisId })}
            activeRoute={activeRoute}
          />
        </PageLayout>
      )}
      <ChartContextProvider>
        <Outlet />
      </ChartContextProvider>
    </SecondLevelNavigationContainer>
  )
}
