import { Stack } from '@mui/material'
import { ReactElement, useEffect, useState } from 'react'
import { Form } from 'react-final-form'
import { useParams } from 'react-router'
import { Mutation_Root, Query_Root } from 'src/@types/graphql'
import { useMessageSource } from 'src/i18n/useMessageSource'
import { ROUTES } from 'src/routing/routes'
import { fetchFactsheetByIdQuery, updateFactsheetMutation } from 'src/screens/factsheets/factsheet/factsheetQueries'
import { usePermissionsForFactsheets } from 'src/service/security/PermissionHook'
import { SaveAndBackButton, SaveButton } from 'src/shared/button/Buttons'
import { ALL_MODULES, LEVEL, LEVEL_TYPE, MODULE_TYPES, TEXT_LENGTH } from 'src/shared/constants/constants'
import { FACTSHEET_STATUS, FACTSHEET_STATUS_TYPE, FACTSHEET_TYPE } from 'src/shared/constants/factsheet-constants'
import { AutoCompleteField } from 'src/shared/form/control/AutoCompleteField'
import { HtmlEditorField } from 'src/shared/form/control/HtmlEditorField'
import { MultiSelectField } from 'src/shared/form/control/MultiSelectField'
import { TextField } from 'src/shared/form/control/TextField'
import { DirtyFormSpy } from 'src/shared/form/dirty/DirtyFormSpy'
import { createDecorators } from 'src/shared/form/utils/decorators'
import { maxChar, required } from 'src/shared/form/validation/validators'
import { PageLayout } from 'src/shared/layout/PageLayout'
import { ScreenLayout } from 'src/shared/layout/ScreenLayout'
import { NotAuthorized } from 'src/shared/not-authorized/NotAuthorized'
import { HelpAndInstructions } from 'src/shared/presentation/HelpAndInstructions'
import { useDelayedNavigate } from 'src/shared/utils/hooks/navigation-hooks'
import { useNotificationService } from 'src/shared/utils/NotificationService'
import { Utils } from 'src/shared/utils/Utils'
import { useClient } from 'urql'

interface FactsheetEditForm {
  factsheet_type: string
  level: LEVEL_TYPE
  modules: MODULE_TYPES[]
  status: FACTSHEET_STATUS_TYPE
  organization?: string
  contact?: string
}

const decorators = createDecorators()

export const FactsheetBasicInformationEditPage = (): ReactElement => {
  const { factsheetId } = useParams()
  const { getMessage } = useMessageSource()
  const navigate = useDelayedNavigate()
  const urqlClient = useClient()
  const notificationService = useNotificationService()

  const [initialValues, setInitialValues] = useState<FactsheetEditForm>()

  const [loading, setLoading] = useState<boolean>(true)

  useEffect(() => {
    const initData = async () => {
      const { data } = await urqlClient
        .query<{
          factsheet_by_pk: Query_Root['factsheet_by_pk']
        }>(fetchFactsheetByIdQuery, { id: parseInt(factsheetId as string) })
        .toPromise()

      setInitialValues({
        factsheet_type: data?.factsheet_by_pk?.factsheet_type ?? '',
        level: data?.factsheet_by_pk?.level as LEVEL_TYPE,
        modules: data?.factsheet_by_pk?.modules as MODULE_TYPES[],
        status: data?.factsheet_by_pk?.status as FACTSHEET_STATUS_TYPE,
        organization: data?.factsheet_by_pk?.organization ?? '',
        contact: data?.factsheet_by_pk?.contact ?? '',
      })

      setLoading(false)
    }

    initData()
  }, [urqlClient, factsheetId, getMessage])

  let submit: any = () => {}
  let formValid = false

  const onSave = (event: any) => {
    submit(event)
  }

  const onSaveAndBack = async (event: any) => {
    try {
      await submit(event)
      formValid && onBack()
    } catch {
      // do nothing
    }
  }

  const onSubmitLocal = async (values: FactsheetEditForm) => {
    const mutationObject = {
      modules: Utils.sortModules(values.modules),
      status: values.status,
      organization: values.organization ?? '',
      contact: values.contact ?? '',
    }

    const { data } = await urqlClient
      .mutation<{
        update_factsheet: Mutation_Root['update_factsheet']
      }>(updateFactsheetMutation, { id: parseInt(factsheetId as string), object: mutationObject })
      .toPromise()

    if (data && data.update_factsheet?.affected_rows === 1) {
      setInitialValues(values)
      notificationService.changesSaved()
    } else {
      notificationService.operationFailed()
    }
  }

  const onBack = () => {
    navigate(ROUTES.FactsheetDescriptionRoot.params({ factsheetId }))
  }

  const { canEdit } = usePermissionsForFactsheets()

  if (!loading && !canEdit) {
    return <NotAuthorized />
  }

  return (
    <>
      <ScreenLayout
        title={getMessage('label.factsheet.title.edit')}
        onBack={onBack}
        hasSecondLevelNavigation={false}
        actions={
          <>
            <SaveAndBackButton origin="header" onClick={onSaveAndBack} />
            <SaveButton origin="header" onClick={onSave} />
          </>
        }
      >
        <PageLayout>
          <>
            {!loading && initialValues && (
              <>
                <HelpAndInstructions labelKey="label.help.factsheet.basic.information" defaultExpansion />
                <Form<FactsheetEditForm>
                  initialValues={initialValues}
                  onSubmit={onSubmitLocal}
                  decorators={decorators}
                  render={({ handleSubmit, valid }) => {
                    submit = handleSubmit
                    formValid = valid
                    return (
                      <form onSubmit={handleSubmit} noValidate id="edit-factsheet-form">
                        <Stack spacing={2}>
                          <AutoCompleteField
                            name="factsheet_type"
                            label={getMessage('label.factsheet.type')}
                            disabled
                            options={Object.keys(FACTSHEET_TYPE).map((type) => ({
                              label: getMessage(`label.factsheet.type.${type}`),
                              value: type,
                            }))}
                          />
                          <AutoCompleteField
                            name="level"
                            label={getMessage('label.level')}
                            disabled
                            options={Object.keys(LEVEL).map((level) => ({
                              label: getMessage(`label.level.${level}`),
                              value: level,
                            }))}
                          />
                          <MultiSelectField
                            name="modules"
                            label={getMessage('label.modules')}
                            options={ALL_MODULES.map((module) => ({
                              label: getMessage(`label.module.description.${module}`),
                              value: module,
                            }))}
                            unavailableOptionsMessage={getMessage('label.field.no.value')}
                            required
                            validate={required()}
                          />
                          <AutoCompleteField
                            name="status"
                            label={getMessage('label.status')}
                            options={Object.keys(FACTSHEET_STATUS).map((status) => ({
                              label: getMessage(`label.factsheet.status.${status}`),
                              value: status,
                            }))}
                            validate={required()}
                          />
                          <TextField
                            name="organization"
                            label={getMessage('label.organization')}
                            validate={maxChar(TEXT_LENGTH.M)}
                          />
                          <HtmlEditorField
                            name="contact"
                            label={getMessage('label.contact')}
                            validate={maxChar(TEXT_LENGTH.XL)}
                          />
                          <DirtyFormSpy />
                        </Stack>
                      </form>
                    )
                  }}
                />
              </>
            )}
          </>
        </PageLayout>
      </ScreenLayout>
    </>
  )
}
