import { ReactElement, useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { Mutation_Root, Query_Root } from 'src/@types/graphql'
import { useMessageSource } from 'src/i18n/useMessageSource'
import { resolveApplicationAssessmentDetailsRoot } from 'src/routing/routing-utils'
import {
  getApplicationTypeIdFromProjects,
  getCriteriaConfigByProcessQuery,
  saveProjectAssessmentCriteriaSelection,
} from 'src/screens/shared/assessment-criteria/assessmentQueries'
import { BaseAssessmentCriteriaEdit } from 'src/screens/shared/assessment-criteria/edit/BaseAssessmentCriteriaEdit'
import {
  createGroupsAndInitialData,
  CriteriaFormValues,
  CriteriaGroups,
  QUALITY_PREFIX,
} from 'src/screens/shared/assessment-criteria/utils/AssessmentUtils'
import { usePermissionsForProjectAssessment } from 'src/service/security/PermissionHook'
import { CRITERIA_TYPE } from 'src/shared/constants/assessment-constants'
import { PROJECT } from 'src/shared/constants/constants'
import { NotAuthorized } from 'src/shared/not-authorized/NotAuthorized'
import { useDelayedNavigate } from 'src/shared/utils/hooks/navigation-hooks'
import { useNotificationService } from 'src/shared/utils/NotificationService'
import { Utils } from 'src/shared/utils/Utils'
import { useClient, useQuery } from 'urql'

interface Props {
  baseUrl: '/pf-kap' | '/pf-pgv'
}

export const ApplicationAssessmentCriteriaEditPage = ({ baseUrl }: Props): ReactElement => {
  const { getMessage } = useMessageSource()
  const { projectId, assessmentId } = useParams()
  const project_id = parseInt(projectId as string)
  const assessment_id = parseInt(assessmentId as string)

  const notificationService = useNotificationService()
  const navigate = useDelayedNavigate()
  const urqlClient = useClient()
  const projectType = Utils.resolveProcess(baseUrl)

  const [criteriaGroups, setCriteriaGroups] = useState<CriteriaGroups | undefined>()
  const [initialValues, setInitialValues] = useState<CriteriaFormValues | undefined>()

  const { loading, canEdit } = usePermissionsForProjectAssessment(projectType, project_id, assessment_id)

  const isPfKap = projectType === PROJECT.PF_KAP
  const isPfPgv = projectType === PROJECT.PF_PGV
  const [{ data }] = useQuery<{
    pf_kap_project: Query_Root['pf_kap_project']
    pf_pgv_project: Query_Root['pf_pgv_project']
  }>({
    query: getApplicationTypeIdFromProjects,
    variables: { isPfKap, isPfPgv, projectId },
  })
  const applicationTypeId = isPfKap
    ? data?.pf_kap_project?.[0]?.application_type_id
    : data?.pf_pgv_project?.[0]?.application_type_id

  useEffect(() => {
    const initData = async () => {
      const { data } = await urqlClient
        .query<{
          project_assessment_by_pk: Query_Root['project_assessment_by_pk']
          project_assessment_criteria_selection: Query_Root['project_assessment_criteria_selection']
          criteria_group_config: Query_Root['criteria_group_config']
        }>(getCriteriaConfigByProcessQuery, {
          process: projectType,
          criteriaType: CRITERIA_TYPE.QUALITY,
          assessment: assessment_id,
          applicationTypeId: applicationTypeId,
        })
        .toPromise()

      const selectedQualities = data?.project_assessment_criteria_selection
      const criteriaGroupedConfigs = data?.criteria_group_config
      const projectAssessmentVersion = data?.project_assessment_by_pk?.version || 0

      const { criteriaGroups, initialValues } = createGroupsAndInitialData(
        selectedQualities,
        criteriaGroupedConfigs,
        QUALITY_PREFIX,
        applicationTypeId,
        projectAssessmentVersion,
      )

      setCriteriaGroups(criteriaGroups)
      setInitialValues(initialValues)
    }

    initData()
  }, [urqlClient, projectType, assessment_id, applicationTypeId])

  const handleSubmitLocal = async (formValues: CriteriaFormValues): Promise<any> => {
    const { version, ...values } = formValues

    const selections = Object.entries(values)
      .filter(([_, value]) => value)
      .map(([key, value]) => ({
        project_assessment_id: assessment_id,
        criteria_config_id: parseInt(key.split(QUALITY_PREFIX)[1]),
        rating: value,
      }))

    const { data, error } = await urqlClient
      .mutation<{
        delete_project_assessment_criteria_selection: Mutation_Root['delete_project_assessment_criteria_selection']
        insert_project_assessment_criteria_selection: Mutation_Root['insert_project_assessment_criteria_selection']
        update_project_assessment: Mutation_Root['update_project_assessment']
      }>(saveProjectAssessmentCriteriaSelection, {
        assessmentId: assessment_id,
        selections,
        version: version,
        criteriaType: CRITERIA_TYPE.QUALITY,
      })
      .toPromise()

    if (error || data?.update_project_assessment?.affected_rows !== 1) {
      notificationService.operationFailed()
    } else {
      const newVersion = data?.update_project_assessment.returning[0]?.version ?? 0
      setInitialValues({ ...values, version: newVersion })
      notificationService.changesSaved()
    }
  }

  const onBack = () => {
    navigate(resolveApplicationAssessmentDetailsRoot(baseUrl).nested.Criteria.params({ projectId, assessmentId }))
  }

  if (!loading && !canEdit) {
    return <NotAuthorized />
  }

  return (
    <BaseAssessmentCriteriaEdit
      screenLayoutText={getMessage('label.assessment.criteria.edit')}
      helpText={`label.help.assessment.criteria.${projectType.toLowerCase()}`}
      criteriaGroups={criteriaGroups}
      initialValues={initialValues}
      handleSubmitLocal={handleSubmitLocal}
      onBack={onBack}
      loading={loading}
    />
  )
}
