import { Checkbox as MuiCheckbox, FormControlLabel } from '@mui/material'
import { Field } from 'react-final-form'
import { ValidationIcon } from 'src/shared/icons/Icons'
import { S } from 'src/shared/styled/S'

interface Props {
  label: string
  initialValue: boolean
  name: string
  formValue: boolean | undefined
}

export const FilterCheckboxField = ({ label, initialValue, name, formValue }: Props) => {
  return (
    <S.Input.FilterCheckbox $checked={formValue}>
      <FormControlLabel
        name={name}
        label={label}
        control={
          <Field
            type="checkbox"
            name={name}
            initialValue={initialValue}
            render={({ input: { name, onChange, checked } }) => {
              return (
                <>
                  <ValidationIcon />
                  <MuiCheckbox name={name} onChange={onChange} checked={checked} />
                </>
              )
            }}
          />
        }
      />
    </S.Input.FilterCheckbox>
  )
}
