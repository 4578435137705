import { Box, Stack } from '@mui/material'
import { useEffect, useRef, useState } from 'react'
import { Form } from 'react-final-form'
import { useParams } from 'react-router'
import { Maybe, Mutation_Root, Query_Root } from 'src/@types/graphql'
import { useMessageSource } from 'src/i18n/useMessageSource'
import { ROUTES } from 'src/routing/routes'
import {
  queryProjectEvaluationByProjectId,
  updateProjectEvaluationByProjectId,
} from 'src/screens/shared/project/details/effectivity-management/effectivityManagementQueries'
import { usePermissionsForProject } from 'src/service/security/PermissionHook'
import { SaveAndBackButton, SaveButton } from 'src/shared/button/Buttons'
import { PROJECT, TEXT_LENGTH } from 'src/shared/constants/constants'
import { HtmlEditorField } from 'src/shared/form/control/HtmlEditorField'
import { DirtyFormSpy } from 'src/shared/form/dirty/DirtyFormSpy'
import { createDecorators } from 'src/shared/form/utils/decorators'
import { composeValidators, maxChar, required as requiredValidator } from 'src/shared/form/validation/validators'
import { PageLayout } from 'src/shared/layout/PageLayout'
import { ScreenLayout } from 'src/shared/layout/ScreenLayout'
import { NotAuthorized } from 'src/shared/not-authorized/NotAuthorized'
import { HelpAndInstructions } from 'src/shared/presentation/HelpAndInstructions'
import { useDelayedNavigate } from 'src/shared/utils/hooks/navigation-hooks'
import { HtmlSanitizer } from 'src/shared/utils/HtmlSanitizer'
import { useNotificationService } from 'src/shared/utils/NotificationService'
import { useClient } from 'urql'

type originType = 'SAVE' | 'SAVE_AND_BACK'

interface FormValues {
  evaluation: Maybe<string>
}

const decorators = createDecorators()

export const PfKapEvaluationEditPage = () => {
  const { projectId } = useParams()
  const project_id = parseInt(projectId as string)

  const { getMessage } = useMessageSource()

  const navigate = useDelayedNavigate()
  const notificationService = useNotificationService()
  const urqlClient = useClient()

  const originRef = useRef<originType>('SAVE')

  const { loading, canEdit } = usePermissionsForProject(PROJECT.PF_KAP, project_id)

  const [initialValues, setInitialValues] = useState<FormValues>()

  useEffect(() => {
    const fetchData = async () => {
      const { data } = await urqlClient
        .query<{ pf_kap_project_by_pk: Query_Root['pf_kap_project_by_pk'] }>(queryProjectEvaluationByProjectId, {
          id: project_id,
        })
        .toPromise()

      if (data?.pf_kap_project_by_pk) {
        setInitialValues({
          evaluation: data.pf_kap_project_by_pk.evaluation ?? '',
        })
      } else {
        notificationService.operationFailed()
      }
    }

    fetchData()
  }, [notificationService, project_id, urqlClient])

  const onBack = () => {
    const route = ROUTES.PfKapDetailsRoot.nested.EffectivityManagement.params({ projectId })
    navigate(route)
  }

  const onSubmit = (origin: originType) => () => {
    // trigger submit event
    if (originRef) {
      originRef.current = origin
    }
    document.getElementById('edit-form')?.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }))
  }

  const onSave = async (values: FormValues) => {
    const updateObject = {
      projectId: project_id,
      setInput: {
        evaluation: values?.evaluation && HtmlSanitizer.sanitize(values.evaluation),
      },
    }

    const { data } = await urqlClient
      .mutation<{ update_pf_kap_project: Mutation_Root['update_pf_kap_project'] }>(
        updateProjectEvaluationByProjectId,
        updateObject,
      )
      .toPromise()

    const affectedRows = data?.update_pf_kap_project?.affected_rows

    if (affectedRows && affectedRows == 1) {
      setInitialValues({
        evaluation: values.evaluation,
      })

      notificationService.changesSaved()

      if (originRef.current === 'SAVE_AND_BACK') {
        onBack()
      }
    } else {
      notificationService.operationFailed()
    }
  }

  if (!loading && !canEdit) {
    return <NotAuthorized />
  }

  return (
    <ScreenLayout
      title={getMessage('label.title.edit.effectivity.evaluation')}
      onBack={onBack}
      hasSecondLevelNavigation={false}
      actions={
        <>
          <SaveAndBackButton origin="header" onClick={onSubmit('SAVE_AND_BACK')} />
          <SaveButton origin="header" onClick={onSubmit('SAVE')} />
        </>
      }
    >
      <PageLayout>
        <>
          {!loading && initialValues && (
            <>
              <HelpAndInstructions labelKey={getMessage('label.help.effectivity.evaluation.PF_KAP')} defaultExpansion />
              <Box>
                {initialValues && (
                  <Form<FormValues>
                    initialValues={initialValues}
                    onSubmit={onSave}
                    decorators={decorators}
                    render={({ handleSubmit }) => {
                      return (
                        <form id="edit-form" onSubmit={handleSubmit}>
                          <Stack spacing={2}>
                            <HtmlEditorField
                              label={getMessage('label.effectivity.evaluation')}
                              required
                              name="evaluation"
                              validate={composeValidators(requiredValidator(), maxChar(TEXT_LENGTH.L))}
                            />
                            <DirtyFormSpy />
                          </Stack>
                        </form>
                      )
                    }}
                  />
                )}
              </Box>
            </>
          )}
        </>
      </PageLayout>
    </ScreenLayout>
  )
}
