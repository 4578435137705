import { Location } from 'history'
import { ReactElement, useEffect, useState } from 'react'
import { Outlet, useLocation, useParams } from 'react-router'
import { Query_Root } from 'src/@types/graphql'
import { NestedRoute, Route, SecondLevelRoute } from 'src/routing/routing'
import { resolveKapMilestoneRoot } from 'src/routing/routing-utils'
import { SecondLevelNavigation } from 'src/routing/SecondLevelNavigation'
import { queryMilestoneById } from 'src/screens/shared/implementation/details/milestoneQueries'
import { usePermissionsForKapMilestones } from 'src/service/security/PermissionHook'
import { MILESTONE_RESPONSIBLE_TYPE_TYPE, MILESTONE_STATUS_TYPE } from 'src/shared/constants/milestone-constants'
import { PageLayout } from 'src/shared/layout/PageLayout'
import { SecondLevelNavigationContainer } from 'src/shared/layout/SecondLevelNavigationContainer'
import { NotAuthorized } from 'src/shared/not-authorized/NotAuthorized'
import { useNotificationService } from 'src/shared/utils/NotificationService'
import { Utils } from 'src/shared/utils/Utils'
import { useQuery } from 'urql'

const resolveActiveRoute = (
  location: Location,
  detailsRoot: Route,
  programId: string,
  milestoneId: string,
  milestonePath: string,
  measureId: string,
  goalId: string,
  subprincipleId: string,
): SecondLevelRoute => {
  const nested = detailsRoot!.nested! as NestedRoute
  const activeRoute = Object.keys(nested).find((key) => {
    let isPathMatching
    if (detailsRoot.params) {
      isPathMatching = detailsRoot.params({ programId, milestoneId, milestonePath }) === location.pathname
    }
    //@ts-ignore
    const nestedPath = nested[key].params({ programId, milestoneId, milestonePath, measureId, goalId, subprincipleId })
    return location.pathname.endsWith(nestedPath) || (isPathMatching && nested[key].primary)
  }) as string

  return nested[activeRoute] as SecondLevelRoute
}

export const KapMilestoneDetailsIndex = (): ReactElement => {
  const { programId, milestoneId, milestonePath, measureId, goalId, subprincipleId } = useParams()
  const program_id = parseInt(programId as string)
  const milestoneType = Utils.resolveMilestoneType(milestonePath)

  const notificationService = useNotificationService()
  const location = useLocation()

  const [activeRoute, setActiveRoute] = useState<SecondLevelRoute>()

  const [{ data, error }] = useQuery<{ milestone_by_pk: Query_Root['milestone_by_pk'] }, { id: number }>({
    query: queryMilestoneById,
    variables: { id: program_id },
  })

  if (error) {
    notificationService.operationFailed()
  }

  const milestone = data?.milestone_by_pk

  const { loading, canView } = usePermissionsForKapMilestones(
    program_id,
    milestone?.status as MILESTONE_STATUS_TYPE,
    milestone?.responsible_type as MILESTONE_RESPONSIBLE_TYPE_TYPE,
  )

  useEffect(() => {
    const activeRoute = resolveActiveRoute(
      location,
      resolveKapMilestoneRoot(milestoneType) as unknown as Route,
      programId as string,
      milestoneId as string,
      milestonePath as string,
      measureId as string,
      goalId as string,
      subprincipleId as string,
    )
    setActiveRoute(activeRoute)
  }, [location, programId, milestoneId, milestonePath, milestoneType, measureId, goalId, subprincipleId])

  const showNavigation = (activeRoute && !activeRoute.hideNavigation) ?? false

  if (!loading && !canView) {
    return <NotAuthorized />
  }

  return (
    <>
      {!loading && activeRoute && (
        <SecondLevelNavigationContainer $showNavigation={showNavigation}>
          {showNavigation && (
            <PageLayout>
              <SecondLevelNavigation
                activeRoute={activeRoute}
                route={resolveKapMilestoneRoot(milestoneType) as unknown as Route}
                baseUrl={resolveKapMilestoneRoot(milestoneType)!.params({
                  programId,
                  milestoneId,
                  milestonePath,
                })}
              />
            </PageLayout>
          )}
          <Outlet />
        </SecondLevelNavigationContainer>
      )}
    </>
  )
}
