import { Button } from '@mui/material'
import { ReactElement, useContext, useMemo, useState } from 'react'
import { valueof } from 'src/@types/global'
import { useMessageSource } from 'src/i18n/useMessageSource'
import { SummarySection } from 'src/screens/shared/application/common/SummarySection'
import { ValidationListItemProps } from 'src/screens/shared/common/SummaryValidationUtils'
import { DossierService } from 'src/service/axios/DossierService'
import { MilestoneWorkflowService } from 'src/service/axios/MilestoneWorkflowService'
import { EditButton } from 'src/shared/button/Buttons'
import { PROJECT, PROJECT_TYPE, PROJECT_USER_ROLE_TYPE } from 'src/shared/constants/constants'
import { MILESTONE_STATUS, MILESTONE_TYPE_TYPE } from 'src/shared/constants/milestone-constants'
import { NextState } from 'src/shared/constants/workflow-constants'
import { DeleteIcon } from 'src/shared/icons/Icons'
import { PageLayout } from 'src/shared/layout/PageLayout'
import { ScreenLayout } from 'src/shared/layout/ScreenLayout'
import { KapMilestoneExportMenu } from 'src/shared/menu/KapMilestoneExportMenu'
import { ProjectMilestoneExportMenu } from 'src/shared/menu/ProjectMilestoneExportMenu'
import { SecondaryConfirmationModalDialog } from 'src/shared/modal-dialog/SecondaryConfirmationModalDialog'
import { useNotificationService } from 'src/shared/utils/NotificationService'
import { Utils } from 'src/shared/utils/Utils'
import { NextStateIf } from 'src/shared/workflow/NextStateIf'
import { UserContext } from 'src/user/UserContext'
import {
  getMilestoneSummaryTitleLabel,
  getMilestoneTitleLabel,
} from 'src/screens/shared/implementation/details/milestone/utils/MilestoneUtils'

interface Props {
  loading: boolean
  milestoneSummary: any | undefined
  milestoneType: MILESTONE_TYPE_TYPE
  process: string
  canEdit: boolean
  canDeleteMilestone: boolean
  onBack: () => void
  onEdit: () => void
  validationViolations: ValidationListItemProps[]
  handleViolationClick: (item: ValidationListItemProps) => void
  userProjectRoles?: Array<valueof<PROJECT_USER_ROLE_TYPE>>
  nextStates: NextState[] | null
  refetchPageData: () => void
}

export const MilestoneSummaryView = ({
  loading,
  milestoneSummary,
  milestoneType,
  process,
  canEdit,
  canDeleteMilestone,
  onBack,
  onEdit,
  validationViolations,
  handleViolationClick,
  userProjectRoles,
  nextStates,
  refetchPageData,
}: Props): ReactElement => {
  const { user } = useContext(UserContext)
  const { getMessage } = useMessageSource()
  const notificationService = useNotificationService()

  const [doneConfirmationOpen, setDoneConfirmationOpen] = useState(false)
  const [reviewConfirmationOpen, setReviewConfirmationOpen] = useState(false)
  const [reworkConfirmationOpen, setReworkConfirmationOpen] = useState(false)
  const [deleteMilestoneConfirmationOpen, setDeleteMilestoneConfirmationOpen] = useState(false)
  const [deleteMilestoneLoading, setDeleteMilestoneLoading] = useState(false)

  const userGlobalRoles = user.roles

  const confirmSetToDoneHandler = async () => {
    try {
      await MilestoneWorkflowService.transition(milestoneSummary?.workflow_id as string, MILESTONE_STATUS.DONE)
      refetchPageData()
      setDoneConfirmationOpen(false)
      notificationService.changesSaved()
    } catch (e) {
      notificationService.operationFailed()
    }
  }

  const confirmSetToReviewHandler = async () => {
    try {
      await MilestoneWorkflowService.transition(milestoneSummary?.workflow_id as string, MILESTONE_STATUS.REVIEW)
      refetchPageData()
      setReviewConfirmationOpen(false)
      notificationService.changesSaved()
    } catch (e) {
      notificationService.operationFailed()
    }
  }

  const confirmSetToReworkHandler = async () => {
    try {
      await MilestoneWorkflowService.transition(milestoneSummary?.workflow_id as string, MILESTONE_STATUS.REWORK)
      refetchPageData()
      setReworkConfirmationOpen(false)
      notificationService.changesSaved()
    } catch (e) {
      notificationService.operationFailed()
    }
  }

  const openDeleteMilestoneConfirmationModalDialog = () => {
    setDeleteMilestoneConfirmationOpen(true)
  }

  const onCancelDeleteMilestone = () => {
    setDeleteMilestoneConfirmationOpen(false)
  }

  const onConfirmDeleteMilestone = () => {
    setDeleteMilestoneLoading(true)

    DossierService.deleteMilestone(milestoneSummary.id)
      .then(() => {
        notificationService.deleteSuccessful()
        onBack()
      })
      .catch(() => {
        notificationService.operationFailed()
      })
      .finally(() => {
        setDeleteMilestoneLoading(false)
      })
  }

  const title = useMemo(() => {
    const milestoneTitleLabel = getMilestoneTitleLabel(process.toUpperCase() as PROJECT_TYPE, milestoneType)
    return `${getMessage(milestoneTitleLabel)} ${milestoneSummary?.year_in_focus ?? ''}`
  }, [getMessage, milestoneSummary, milestoneType, process])

  const summaryTitle = useMemo(() => {
    const milestoneSummaryTitleLabel = getMilestoneSummaryTitleLabel(
      process.toUpperCase() as PROJECT_TYPE,
      milestoneType,
    )
    return `${getMessage(milestoneSummaryTitleLabel, [milestoneSummary?.year_in_focus ?? ''])}`
  }, [getMessage, milestoneSummary, milestoneType, process])

  return (
    <>
      <ScreenLayout
        title={title}
        onBack={onBack}
        actions={
          <>
            {process === PROJECT.KAP.toLocaleLowerCase()
              ? Utils.canExportKapMilestone(milestoneType) && (
                  <KapMilestoneExportMenu year={milestoneSummary?.year_in_focus} />
                )
              : Utils.canExportMilestone(milestoneType) && (
                  <ProjectMilestoneExportMenu
                    process={process.toLocaleUpperCase() as PROJECT_TYPE}
                    year={milestoneSummary?.year_in_focus}
                  />
                )}
          </>
        }
      >
        <PageLayout>
          <>
            {!loading && milestoneSummary && nextStates !== null && (
              <>
                <SummarySection
                  title={summaryTitle}
                  actionsHelpLabel={`label.help.milestone.summary.${process}.${milestoneType}`}
                  status={
                    <span>
                      {getMessage('label.status')}: {getMessage(`label.milestone.status.${milestoneSummary.status}`)}
                    </span>
                  }
                  violations={validationViolations}
                  violationClickHandler={handleViolationClick}
                  actionsCompleteMessage={getMessage(`label.milestone.summary.check.complete.${milestoneType}`, [
                    milestoneSummary.year_in_focus ?? '',
                  ])}
                  statusActions={
                    <>
                      <NextStateIf
                        forState={MILESTONE_STATUS.REVIEW}
                        nextStates={nextStates}
                        userGlobalRoles={userGlobalRoles}
                        userProjectRoles={userProjectRoles}
                        canEditOwnCanton={canEdit}
                      >
                        <Button
                          variant="contained"
                          color="secondary"
                          disabled={validationViolations.length > 0}
                          onClick={() => setReviewConfirmationOpen(true)}
                        >
                          {getMessage('button.set.to.review')}
                        </Button>
                      </NextStateIf>
                      <NextStateIf
                        forState={MILESTONE_STATUS.REWORK}
                        nextStates={nextStates}
                        userGlobalRoles={userGlobalRoles}
                        userProjectRoles={userProjectRoles}
                        canEditOwnCanton={canEdit}
                      >
                        <Button variant="outlined" color="secondary" onClick={() => setReworkConfirmationOpen(true)}>
                          {getMessage('button.set.to.rework')}
                        </Button>
                      </NextStateIf>
                      <NextStateIf
                        forState={MILESTONE_STATUS.DONE}
                        nextStates={nextStates}
                        userGlobalRoles={userGlobalRoles}
                        userProjectRoles={userProjectRoles}
                        canEditOwnCanton={canEdit}
                      >
                        <Button
                          variant="contained"
                          color="secondary"
                          disabled={validationViolations.length > 0}
                          onClick={() => setDoneConfirmationOpen(true)}
                        >
                          {getMessage('button.set.to.done')}
                        </Button>
                      </NextStateIf>
                    </>
                  }
                  commentsHelpLabel={`label.help.milestone.summary.comments.${process}.${milestoneType}`}
                  commentActions={<EditButton onClick={onEdit} hidden={!canEdit} />}
                  comment={milestoneSummary.comment}
                  sectionActionButton={
                    <>
                      {canDeleteMilestone && (
                        <Button
                          variant="contained"
                          color="error"
                          onClick={openDeleteMilestoneConfirmationModalDialog}
                          startIcon={<DeleteIcon />}
                        >
                          {getMessage('button.milestone.delete')}
                        </Button>
                      )}
                    </>
                  }
                />
                <SecondaryConfirmationModalDialog
                  open={doneConfirmationOpen}
                  onCancel={() => setDoneConfirmationOpen(false)}
                  onConfirm={confirmSetToDoneHandler}
                  titleKey="label.milestone.set.to.done.confirm.title"
                  confirmButtonKey="button.set.to.done"
                >
                  {getMessage('label.milestone.set.to.done.confirm.body')}
                </SecondaryConfirmationModalDialog>
                <SecondaryConfirmationModalDialog
                  open={reviewConfirmationOpen}
                  onCancel={() => setReviewConfirmationOpen(false)}
                  onConfirm={confirmSetToReviewHandler}
                  titleKey="label.milestone.set.to.review.confirm.title"
                  confirmButtonKey="button.set.to.review"
                >
                  {getMessage('label.milestone.set.to.review.confirm.body')}
                </SecondaryConfirmationModalDialog>
                <SecondaryConfirmationModalDialog
                  open={reworkConfirmationOpen}
                  onCancel={() => setReworkConfirmationOpen(false)}
                  onConfirm={confirmSetToReworkHandler}
                  titleKey="label.milestone.set.to.rework.confirm.title"
                  confirmButtonKey="button.set.to.rework"
                >
                  {getMessage('label.milestone.set.to.rework.confirm.body')}
                </SecondaryConfirmationModalDialog>
                <SecondaryConfirmationModalDialog
                  open={deleteMilestoneConfirmationOpen}
                  onCancel={onCancelDeleteMilestone}
                  onConfirm={onConfirmDeleteMilestone}
                  titleKey={'label.delete.confirm.milestone.title'}
                  confirmButtonKey={'button.milestone.delete'}
                  buttonColor={'error'}
                  loading={deleteMilestoneLoading}
                >
                  {getMessage('label.delete.confirm.milestone')}
                </SecondaryConfirmationModalDialog>
              </>
            )}
          </>
        </PageLayout>
      </ScreenLayout>
    </>
  )
}
