import { Box, Stack } from '@mui/material'
import { useEffect, useMemo, useRef, useState } from 'react'
import { Form } from 'react-final-form'
import { useParams } from 'react-router'
import { Maybe, Mutation_Root, Query_Root } from 'src/@types/graphql'
import { useMessageSource } from 'src/i18n/useMessageSource'
import { resolveIntermediateMilestoneRoot } from 'src/routing/routing-utils'
import {
  fetchEvaluationNeedByMilestoneId,
  insertEvaluationNeed,
} from 'src/screens/shared/implementation/details/milestone/details/evaluation/milestoneEvaluationQueries'
import { queryMilestoneById } from 'src/screens/shared/implementation/details/milestoneQueries'
import { usePermissionsForMilestones } from 'src/service/security/PermissionHook'
import { SaveAndBackButton, SaveButton } from 'src/shared/button/Buttons'
import { EVALUATION_NEEDS, PROJECT, TEXT_LENGTH } from 'src/shared/constants/constants'
import { MILESTONE_RESPONSIBLE_TYPE_TYPE, MILESTONE_STATUS_TYPE } from 'src/shared/constants/milestone-constants'
import { AutoCompleteField } from 'src/shared/form/control/AutoCompleteField'
import { DateTimePickerField } from 'src/shared/form/control/DateTimePickerField'
import { HtmlEditorField } from 'src/shared/form/control/HtmlEditorField'
import { DirtyFormSpy } from 'src/shared/form/dirty/DirtyFormSpy'
import { maxChar, required, validDate } from 'src/shared/form/validation/validators'
import { PageLayout } from 'src/shared/layout/PageLayout'
import { ScreenLayout } from 'src/shared/layout/ScreenLayout'
import { NotAuthorized } from 'src/shared/not-authorized/NotAuthorized'
import { HelpAndInstructions } from 'src/shared/presentation/HelpAndInstructions'
import { DateUtils } from 'src/shared/utils/DateUtils'
import { useDelayedNavigate } from 'src/shared/utils/hooks/navigation-hooks'
import { HtmlSanitizer } from 'src/shared/utils/HtmlSanitizer'
import { useNotificationService } from 'src/shared/utils/NotificationService'
import { Utils } from 'src/shared/utils/Utils'
import { useClient, useQuery } from 'urql'

type originType = 'SAVE' | 'SAVE_AND_BACK'

interface Props {
  baseUrl: '/pf-kap' | '/pf-pgv'
}

interface EvaluationMilestoneForm {
  milestone_id: number
  evaluation_need?: Maybe<string>
  year?: Maybe<string>
  reasoning?: Maybe<string>
}

export const MilestoneEvaluationEditPage = ({ baseUrl }: Props) => {
  const { projectId, milestoneId, milestonePath } = useParams()
  const milestone_id = parseInt(milestoneId as string)
  const project_id = parseInt(projectId as string)
  const process = Utils.resolveProcess(baseUrl)

  const originRef = useRef<originType>('SAVE')
  const { getMessage } = useMessageSource()
  const notificationService = useNotificationService()
  const navigate = useDelayedNavigate()
  const urqlClient = useClient()

  const [initialValues, setInitialValues] = useState<EvaluationMilestoneForm>({
    milestone_id,
    evaluation_need: null,
    year: null,
    reasoning: null,
  })

  const [{ data, error }] = useQuery<{ milestone_by_pk: Query_Root['milestone_by_pk'] }, { id: number }>({
    query: queryMilestoneById,
    variables: { id: milestone_id },
  })

  if (error) {
    notificationService.operationFailed()
  }

  const milestone = data?.milestone_by_pk

  useEffect(() => {
    const initData = async () => {
      const { data, error } = await urqlClient
        .query<{ intermediate_discussion_evaluation: Query_Root['intermediate_discussion_evaluation'] }>(
          fetchEvaluationNeedByMilestoneId,
          { milestoneId: milestone_id },
        )
        .toPromise()

      if (data && data?.intermediate_discussion_evaluation.length > 0) {
        const evaluation = data.intermediate_discussion_evaluation?.[0]
        setInitialValues({
          milestone_id: evaluation?.milestone_id,
          evaluation_need: evaluation?.evaluation_need,
          year: evaluation?.year ? DateUtils.parseAndFormatDate(evaluation.year) : null,
          reasoning: evaluation?.reasoning,
        })
      } else if (error) {
        notificationService.operationFailed()
      }
    }

    initData()
  }, [urqlClient, notificationService, milestone_id])

  const { loading, canEdit } = usePermissionsForMilestones(
    process,
    project_id,
    milestone?.status as MILESTONE_STATUS_TYPE,
    milestone?.responsible_type as MILESTONE_RESPONSIBLE_TYPE_TYPE,
  )

  const evaluationNeedsOptions = useMemo(
    () =>
      Object.keys(EVALUATION_NEEDS[process]).map((value) => ({
        label: getMessage(`label.evaluation.need.${value}`),
        value: value,
      })),
    [getMessage, process],
  )

  const onSubmit = (origin: originType) => (_: any) => {
    // trigger submit event
    if (originRef) {
      originRef.current = origin
    }
    document.getElementById('edit-form')?.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }))
  }

  const onBack = () => {
    const evaluationRoute = resolveIntermediateMilestoneRoot(baseUrl).nested.Evaluation
    navigate(evaluationRoute.params({ projectId, milestoneId, milestonePath }))
  }

  const onSaveLocal = async (values: EvaluationMilestoneForm) => {
    const object = {
      ...values,
      year: values.year && DateUtils.parseAndSerializeDate(values.year),
      reasoning: values.reasoning && HtmlSanitizer.sanitize(values.reasoning),
    }

    const { data } = await urqlClient
      .mutation<{ intermediate_discussion_evaluation: Mutation_Root['insert_intermediate_discussion_evaluation'] }>(
        insertEvaluationNeed,
        { object: [object] },
      )
      .toPromise()

    if (data) {
      setInitialValues(values)
      notificationService.changesSaved()
      if (originRef.current === 'SAVE_AND_BACK') {
        onBack()
      }
    } else {
      notificationService.operationFailed()
    }
  }

  if (!loading && !canEdit) {
    return <NotAuthorized inEditPage />
  }

  return (
    <ScreenLayout
      title={getMessage('label.milestone.edit.evaluation.needs')}
      hasSecondLevelNavigation={false}
      onBack={onBack}
      actions={
        <>
          <SaveAndBackButton origin="header" onClick={onSubmit('SAVE_AND_BACK')} />
          <SaveButton origin="header" onClick={onSubmit('SAVE')} />
        </>
      }
    >
      <PageLayout>
        <>
          {!loading && (
            <>
              <HelpAndInstructions labelKey={`label.help.milestone.evaluation.${process}`} defaultExpansion />
              <Box>
                <Form<EvaluationMilestoneForm>
                  initialValues={initialValues}
                  onSubmit={onSaveLocal}
                  render={({ handleSubmit }) => {
                    return (
                      <form onSubmit={handleSubmit} noValidate id="edit-form">
                        <Stack spacing={2}>
                          <AutoCompleteField
                            name="evaluation_need"
                            validate={process === PROJECT.PF_KAP ? required() : undefined}
                            label={getMessage('label.evaluation.need')}
                            options={evaluationNeedsOptions}
                            required={process === PROJECT.PF_KAP}
                          />
                          <DateTimePickerField
                            name="year"
                            label={getMessage('label.planned.year')}
                            yearOnly="start"
                            validate={validDate()}
                          />
                          <HtmlEditorField
                            label={getMessage('label.reasoning')}
                            name="reasoning"
                            validate={maxChar(TEXT_LENGTH.L)}
                          />
                          <DirtyFormSpy />
                        </Stack>
                      </form>
                    )
                  }}
                />
              </Box>
            </>
          )}
        </>
      </PageLayout>
    </ScreenLayout>
  )
}
