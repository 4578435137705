import { gql } from 'urql'

export const fetchSuccessFactorAnalysisById = gql`
  query fetchSuccessFactorAnalysisById($id: Int!) {
    success_factor_analysis_by_pk(id: $id) {
      title
      responsibility
      further_information
    }
  }
`

export const updateSuccessFactorAnalysisById = gql`
  mutation updateSuccessFactorAnalysisById(
    $id: Int!
    $title: String!
    $responsibility: String!
    $further_information: String!
  ) {
    update_success_factor_analysis(
      where: { id: { _eq: $id } }
      _set: { title: $title, responsibility: $responsibility, further_information: $further_information }
    ) {
      affected_rows
    }
  }
`

export const fetchAnalysisFocusTopicAndSuccessFactorDataQuery = gql`
  query analysisFocusTopicAndSuccessFactorDataQuery($locale: String!, $successFactorAnalysisId: Int!) {
    focus_topic(order_by: { sort_number: asc }) {
      id
      code
      names(path: $locale)
    }
    analysis_success_factor_rating(where: { success_factor_analysis_id: { _eq: $successFactorAnalysisId } }) {
      success_factor_id
      rating_current_status
      rating_priority
    }
    success_factor(order_by: { sort_number: asc }) {
      id
      focus_topic_id
    }
  }
`
