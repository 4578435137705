import { gql } from '@urql/core'

export const insertPartnerOrganizationMutationQuery = gql`
  mutation (
    $name: String!
    $address: String!
    $additionalAddress: String
    $city: String!
    $email: String!
    $phone: String!
    $postalCode: String!
    $typeId: Int!
    $department: String
    $website: String
    $entityId: Int!
  ) {
    insert_organization(
      objects: {
        additional_address: $additionalAddress
        address: $address
        city: $city
        department: $department
        email: $email
        name: $name
        phone: $phone
        postal_code: $postalCode
        website: $website
        organization_type_id: $typeId
        project_organizations: { data: [{ project_base_id: $entityId }] }
      }
    ) {
      affected_rows
    }
  }
`

export const insertResponsibleOrganizationMutationQuery = gql`
  mutation (
    $name: String!
    $address: String!
    $additionalAddress: String
    $city: String!
    $email: String!
    $phone: String!
    $postalCode: String!
    $typeId: Int!
    $department: String
    $website: String
    $entityId: Int!
  ) {
    insert_organization_one(
      object: {
        additional_address: $additionalAddress
        address: $address
        city: $city
        department: $department
        email: $email
        name: $name
        phone: $phone
        postal_code: $postalCode
        website: $website
        organization_type_id: $typeId
        project_bases: {
          data: { id: $entityId, dossier_id: 0 }
          on_conflict: { constraint: project_base_pk, update_columns: responsible_organization_id }
        }
      }
    ) {
      id
    }
  }
`

export const deletePartnerOrganizationMutationQuery = gql`
  mutation ($organization_id: Int!, $project_base_id: Int!) {
    delete_project_organization(
      where: { _and: {}, organization_id: { _eq: $organization_id }, project_base_id: { _eq: $project_base_id } }
    ) {
      affected_rows
    }
    delete_organization_by_pk(id: $organization_id) {
      id
    }
  }
`
