export const FACTSHEET_TYPE = {
  ORIENTATION_LIST: 'ORIENTATION_LIST',
} as const

export type FACTSHEET_TYPE_TYPE = keyof typeof FACTSHEET_TYPE

export const FACTSHEET_STATUS = {
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
} as const

export type FACTSHEET_STATUS_TYPE = keyof typeof FACTSHEET_STATUS

const FACTSHEET_DESCRIPTION_EDIT_OPTION = {
  BASIC_INFO: 'basic-info',
  TITLE_AND_DESCRIPTION: 'title-and-description',
  IMPORTANT_INSIGHTS: 'important-insights',
  STRENGTH: 'strength',
  FURTHER_INFORMATION: 'further-information',
  EVIDENCE_OF_EFFECTIVENESS: 'evidence-of-effectiveness',
} as const

export { FACTSHEET_DESCRIPTION_EDIT_OPTION }
