import { Stack } from '@mui/material'
import combinedQuery from 'graphql-combine-query'
import { sortBy } from 'lodash'
import { ReactElement, useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { Project_Base_Bool_Exp, Query_Root } from 'src/@types/graphql'
import { useMessageSource } from 'src/i18n/useMessageSource'
import { resolveAnnualReportMilestoneRoot, resolveImplementationDetailsRoot } from 'src/routing/routing-utils'
import { MilestoneGoalCardContent } from 'src/screens/shared/implementation/details/milestone/details/annual/goal/MilestoneGoalCardContent'
import { MilestoneMeasureCardContent } from 'src/screens/shared/implementation/details/milestone/details/annual/measure/MilestoneMeasureCardContent'
import {
  queryGoalsAndMeasuresAnnualReport,
  queryMilestoneById,
} from 'src/screens/shared/implementation/details/milestoneQueries'
import { queryProjectGoalsAndMeasuresByProcessAndProjectId } from 'src/screens/shared/project/details/goals-and-measures/projectGoalsAndMeasuresQueries'
import { usePermissionsForMilestones } from 'src/service/security/PermissionHook'
import { AddButton } from 'src/shared/button/Buttons'
import { GOAL_CHAR, MEASURE_CHAR, PROJECT } from 'src/shared/constants/constants'
import {
  MeasureAnnualReport,
  MILESTONE_RESPONSIBLE_TYPE_TYPE,
  MILESTONE_STATUS_TYPE,
  ProjectGoalAnnualReport,
} from 'src/shared/constants/milestone-constants'
import { PageLayout } from 'src/shared/layout/PageLayout'
import { ScreenLayout } from 'src/shared/layout/ScreenLayout'
import { ProjectMilestoneExportMenu } from 'src/shared/menu/ProjectMilestoneExportMenu'
import { ExpandableGoalMeasureCard } from 'src/shared/presentation/ExpandableGoalMeasureCard'
import { HelpAndInstructions } from 'src/shared/presentation/HelpAndInstructions'
import { Section } from 'src/shared/presentation/Section'
import { useDelayedNavigate } from 'src/shared/utils/hooks/navigation-hooks'
import { useNotificationService } from 'src/shared/utils/NotificationService'
import { Utils } from 'src/shared/utils/Utils'
import { useClient, useQuery } from 'urql'

interface Props {
  baseUrl: '/pf-kap' | '/pf-pgv'
}

export const MilestonesGoalsAndMeasuresPage = ({ baseUrl }: Props): ReactElement => {
  const { projectId, milestoneId, milestonePath } = useParams()
  const milestone_id = parseInt(milestoneId as string)
  const project_id = parseInt(projectId as string)
  const milestoneType = Utils.resolveMilestoneType(milestonePath)
  const process = Utils.resolveProcess(baseUrl)

  const { getMessage } = useMessageSource()
  const navigate = useDelayedNavigate()
  const notificationService = useNotificationService()
  const urqlClient = useClient()

  const [goals, setGoals] = useState<ProjectGoalAnnualReport[]>([])
  const [measures, setMeasures] = useState<MeasureAnnualReport[]>([])

  useEffect(() => {
    const initData = async () => {
      const pfKapWhereClause: Project_Base_Bool_Exp = { pf_kap_projects: { id: { _eq: project_id } } }
      const pfPgvWhereClause: Project_Base_Bool_Exp = { pf_pgv_projects: { id: { _eq: project_id } } }

      const { document, variables } = combinedQuery('projectGoalsAndMeasuresAndReports')
        .add<
          {
            project_goal_annual_report: Query_Root['project_goal_annual_report']
            project_measure_annual_report: Query_Root['project_measure_annual_report']
          },
          { milestoneId: number }
        >(queryGoalsAndMeasuresAnnualReport, { milestoneId: milestone_id })
        .add<
          {
            pf_kap_project: Query_Root['pf_kap_project']
          },
          {
            projectBaseWhere: Project_Base_Bool_Exp
          }
        >(queryProjectGoalsAndMeasuresByProcessAndProjectId, {
          projectBaseWhere: process === PROJECT.PF_KAP ? pfKapWhereClause : pfPgvWhereClause,
        })

      const { data } = await urqlClient
        .query<
          {
            project_base: Query_Root['project_base']
            project_goal_annual_report: Query_Root['project_goal_annual_report']
            project_measure_annual_report: Query_Root['project_measure_annual_report']
          },
          {
            projectBaseWhere: Project_Base_Bool_Exp
            milestoneId: number
          }
        >(document, variables!)
        .toPromise()

      if (data) {
        const projectGoals = data?.project_base?.[0].project_goals.map((goal) => ({
          ...goal,
          project_goal_id: goal.id,
        }))
        const goalAnnualReports = data?.project_goal_annual_report

        const mergedGoalsAndReports = Utils.mergeBy(
          { arr: projectGoals, key: 'id' },
          { arr: goalAnnualReports, key: 'project_goal_id' },
        )

        setGoals(
          sortBy(mergedGoalsAndReports, 'sort_number').map((goal) => ({
            goal_id: goal.project_goal_id,
            name: goal.name,
            sort_number: goal.sort_number,
            goal_measures: undefined,
            rating: goal?.rating ?? null,
          })),
        )

        const projectMeasures = data?.project_base?.[0]?.project_measures.map((measure) => ({
          ...measure,
          project_measure_id: measure.id,
        }))
        const measureAnnualReports = data?.project_measure_annual_report
        const mergedMeasuresAndReports = Utils.mergeBy(
          { arr: projectMeasures, key: 'id' },
          { arr: measureAnnualReports, key: 'project_measure_id' },
        )
        setMeasures(
          sortBy(mergedMeasuresAndReports, 'sort_number').map((measure) => ({
            measure_id: measure.project_measure_id,
            name: measure.name,
            sort_number: measure.sort_number,
            goal_measures: undefined,
            rating: measure?.rating ?? null,
          })),
        )
      }
    }

    initData()
  }, [urqlClient, notificationService, process, project_id, milestone_id])

  const [{ data, error }] = useQuery<{ milestone_by_pk: Query_Root['milestone_by_pk'] }, { id: number }>({
    query: queryMilestoneById,
    variables: { id: milestone_id },
  })

  if (error) {
    notificationService.operationFailed()
  }

  const milestone = data?.milestone_by_pk

  const { loading, canEdit } = usePermissionsForMilestones(
    process,
    project_id,
    milestone?.status as MILESTONE_STATUS_TYPE,
    milestone?.responsible_type as MILESTONE_RESPONSIBLE_TYPE_TYPE,
  )

  const handleEditGoal = (goalId: number) => {
    const route = resolveAnnualReportMilestoneRoot(baseUrl).nested.MilestoneGoalEdit.params({
      projectId,
      milestoneId,
      milestonePath,
      goalId,
    })
    navigate(route)
  }

  const handleEditMeasure = (measureId: number) => {
    const route = resolveAnnualReportMilestoneRoot(baseUrl).nested.MilestoneMeasureEdit.params({
      projectId,
      milestoneId,
      milestonePath,
      measureId,
    })
    navigate(route)
  }

  const onBack = () => {
    const route = resolveImplementationDetailsRoot(baseUrl).params({ projectId })
    navigate(route)
  }

  const handleAddMeasureClick = () => {
    navigate(
      resolveAnnualReportMilestoneRoot(baseUrl).nested.MilestoneMeasureNew.params({
        projectId,
        milestoneId,
        milestonePath,
      }),
    )
  }

  return (
    <>
      <ScreenLayout
        title={`${getMessage(`label.milestone.type.${milestoneType}`)} ${milestone?.year_in_focus ?? ''}`}
        onBack={onBack}
        actions={<ProjectMilestoneExportMenu process={process} year={milestone?.year_in_focus} />}
      >
        <PageLayout>
          <>
            {!loading && milestone && (
              <>
                <Section
                  id="goals"
                  title={getMessage('label.project.goals')}
                  helpAndInstructions={
                    <HelpAndInstructions
                      labelKey={`label.help.milestone.goals.and.measures.goals.${process.toLowerCase()}`}
                    />
                  }
                >
                  {goals && goals.length > 0 && (
                    <Stack spacing={1}>
                      {goals.map((value: ProjectGoalAnnualReport) => {
                        return (
                          <ExpandableGoalMeasureCard
                            key={value.goal_id}
                            type={GOAL_CHAR}
                            title={value.name}
                            id={`${getMessage('label.goal.id.character')}${value.sort_number}`}
                            entityId={value.goal_id}
                            onEdit={() => handleEditGoal(value.goal_id)}
                            canEdit={canEdit}
                          >
                            <MilestoneGoalCardContent goalId={value.goal_id} />
                          </ExpandableGoalMeasureCard>
                        )
                      })}
                    </Stack>
                  )}
                </Section>
                <Section
                  id="measures"
                  title={getMessage('label.project.measures')}
                  actionButton={
                    <AddButton messageKey="button.measure.add" onClick={handleAddMeasureClick} hidden={!canEdit} />
                  }
                  helpAndInstructions={
                    <HelpAndInstructions
                      labelKey={`label.help.milestone.goals.and.measures.measures.${process.toLowerCase()}`}
                    />
                  }
                >
                  {measures && measures.length > 0 && (
                    <Stack spacing={1}>
                      {measures.map((value: MeasureAnnualReport) => {
                        return (
                          <ExpandableGoalMeasureCard
                            key={value.measure_id}
                            type={MEASURE_CHAR}
                            title={value.name}
                            id={`${getMessage('label.measure.id.character')}${value.sort_number}`}
                            entityId={value.measure_id}
                            onEdit={() => handleEditMeasure(value.measure_id)}
                            canEdit={canEdit}
                          >
                            <MilestoneMeasureCardContent measureId={value.measure_id} />
                          </ExpandableGoalMeasureCard>
                        )
                      })}
                    </Stack>
                  )}
                </Section>
              </>
            )}
          </>
        </PageLayout>
      </ScreenLayout>
    </>
  )
}
