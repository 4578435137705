import { IconButton, Stack } from '@mui/material'
import { ReactElement, useEffect, useState } from 'react'
import { Mutation_Root, Project_Goal, Project_Goal_Measure, Project_Measure } from 'src/@types/graphql'
import { useMessageSource } from 'src/i18n/useMessageSource'
import { resolveDetailsRoot } from 'src/routing/routing-utils'
import { areGoalsMeasuresSortedLatest } from 'src/screens/shared/common/utils/GoalMeasureUtils'
import { deleteProjectMeasureByPk, updateMeasureSortNumbers } from 'src/screens/shared/measure/measureQueries'
import {
  deleteProjectGoalByPk,
  updateGoalSortNumbers,
} from 'src/screens/shared/project/details/goals-and-measures/goal/goalQueries'
import { ProjectGoalRelatedMeasuresGrid } from 'src/screens/shared/project/details/goals-and-measures/goal/ProjectGoalRelatedMeasuresGrid'
import { ProjectMeasureRelatedGoalsGrid } from 'src/screens/shared/project/details/goals-and-measures/measure/ProjectMeasureRelatedGoalsGrid'
import { usePermissionsForProject } from 'src/service/security/PermissionHook'
import { AddButton } from 'src/shared/button/Buttons'
import { GOAL_CHAR, MEASURE_CHAR } from 'src/shared/constants/constants'
import { DeleteIcon } from 'src/shared/icons/Icons'
import { PageLayout } from 'src/shared/layout/PageLayout'
import { ScreenLayout } from 'src/shared/layout/ScreenLayout'
import { ProjectExportMenu } from 'src/shared/menu/ProjectExportMenu'
import { ConfirmationModalDialog } from 'src/shared/modal-dialog/ConfirmationModalDialog'
import { DefaultSectionTypography } from 'src/shared/presentation/DefaultSectionTypography'
import { ExpandableGoalMeasureCard } from 'src/shared/presentation/ExpandableGoalMeasureCard'
import { HelpAndInstructions } from 'src/shared/presentation/HelpAndInstructions'
import { HtmlRenderer } from 'src/shared/presentation/HtmlRenderer'
import { ReadOnlySelection, ReadOnlyTextField } from 'src/shared/presentation/ReadOnly'
import { Section } from 'src/shared/presentation/Section'
import { DateUtils } from 'src/shared/utils/DateUtils'
import { useDelayedNavigate } from 'src/shared/utils/hooks/navigation-hooks'
import { useNotificationService } from 'src/shared/utils/NotificationService'
import { Utils } from 'src/shared/utils/Utils'
import { useClient } from 'urql'

interface ProjectGoalsAndMeasuresProps {
  projectId: number
  baseUrl: '/pf-kap' | '/pf-pgv'
  projectGoals: Project_Goal[]
  refetch: () => void
  projectMeasures: Array<Project_Measure>
}

export const ProjectGoalsAndMeasures = ({
  projectId,
  baseUrl,
  projectGoals,
  refetch,
  projectMeasures,
}: ProjectGoalsAndMeasuresProps): ReactElement => {
  const { getMessage } = useMessageSource()
  const urqlClient = useClient()
  const notificationService = useNotificationService()
  const navigate = useDelayedNavigate()
  const process = Utils.resolveProcessToLowerCase(baseUrl)
  const projectType = Utils.resolveProcess(baseUrl)
  const {
    loading,
    canEdit,
    metadata: { projectBaseId },
  } = usePermissionsForProject(projectType, projectId)

  const [projectMeasureDeleteConfirmationOpen, setProjectMeasureDeleteConfirmationOpen] = useState(false)
  const [projectMeasureIdToBeDeleted, setProjectMeasureIdToBeDeleted] = useState<any>(null)

  const [projectGoalDeleteConfirmationOpen, setProjectGoalDeleteConfirmationOpen] = useState(false)
  const [projectGoalIdToBeDeleted, setProjectGoalIdToBeDeleted] = useState<any>(null)

  const [measuresLinked, setMeasuresLinked] = useState<boolean>(false)
  const [goalsLinked, setGoalsLinked] = useState<boolean>(false)

  const [goalsBeingSwappedIds, setGoalsBeingSwappedIds] = useState<{ id1: number; id2: number }>()
  const [measuresBeingSwappedIds, setMeasuresBeingSwappedIds] = useState<{ id1: number; id2: number }>()

  useEffect(() => {
    if (goalsBeingSwappedIds && projectGoals) {
      const areGoalsSortedLatest = areGoalsMeasuresSortedLatest(
        projectGoals,
        goalsBeingSwappedIds.id1,
        goalsBeingSwappedIds.id2,
      )

      if (areGoalsSortedLatest) {
        setGoalsBeingSwappedIds(undefined)
      }
    }

    if (measuresBeingSwappedIds && projectMeasures) {
      const areMeasuresSortedLatest = areGoalsMeasuresSortedLatest(
        projectMeasures,
        measuresBeingSwappedIds.id1,
        measuresBeingSwappedIds.id2,
      )

      if (areMeasuresSortedLatest) {
        setMeasuresBeingSwappedIds(undefined)
      }
    }
  }, [goalsBeingSwappedIds, measuresBeingSwappedIds, projectGoals, projectMeasures])

  const moveGoalUp = async (event: any, goalId: number) => {
    event.stopPropagation()
    const goal1Index = projectGoals.findIndex((goal: Project_Goal) => goal.id === goalId)
    if (goal1Index > 0) {
      const goal1 = projectGoals[goal1Index]
      const goal2 = projectGoals[goal1Index - 1]
      if (goal1 && goal2) {
        setGoalsBeingSwappedIds({ id1: goal1.id, id2: goal2.id })
        moveGoal(goal1, goal2)
      }
    }
  }

  const moveGoal = async (goal1: Project_Goal, goal2: Project_Goal) => {
    const { error } = await urqlClient
      .mutation<{ update_project_goal: Mutation_Root['update_project_goal'] }>(updateGoalSortNumbers, {
        goal1Id: goal1.id,
        goal2Id: goal2.id,
        goal1SortNumber: goal2.sort_number,
        goal2SortNumber: goal1.sort_number,
      })
      .toPromise()
    if (error) {
      notificationService.operationFailed()
    } else {
      notificationService.changesSaved()
      refetch()
    }
  }

  const moveGoalDown = (event: any, goalId: number) => {
    event.stopPropagation()
    const goal1Index = projectGoals.findIndex((goal: Project_Goal) => goal.id === goalId)
    if (goal1Index < projectGoals.length - 1) {
      const goal1 = projectGoals[goal1Index]
      const goal2 = projectGoals[goal1Index + 1]
      if (goal1 && goal2) {
        setGoalsBeingSwappedIds({ id1: goal2.id, id2: goal1.id })
        moveGoal(goal1, goal2)
      }
    }
  }

  const handleAddGoalButtonClick = () => {
    navigate(resolveDetailsRoot(baseUrl).nested.ProjectGoalNew.params({ projectId }))
  }

  const handleAddMeasureButtonClick = () => {
    navigate(resolveDetailsRoot(baseUrl).nested.ProjectMeasureNew.params({ projectId }))
  }

  const moveMeasureUp = async (event: any, measureId: number) => {
    event.stopPropagation()
    const measure1Index = projectMeasures.findIndex((measure: Project_Measure) => measure.id === measureId)
    if (measure1Index > 0) {
      const measure1 = projectMeasures[measure1Index]
      const measure2 = projectMeasures[measure1Index - 1]
      if (measure1 && measure2) {
        setMeasuresBeingSwappedIds({ id1: measure1.id, id2: measure2.id })
        moveMeasure(measure1, measure2)
      }
    }
  }

  const moveMeasure = async (measure1: Project_Measure, measure2: Project_Measure) => {
    const { error } = await urqlClient
      .mutation<{ update_project_measure: Mutation_Root['update_project_measure'] }>(updateMeasureSortNumbers, {
        measure1Id: measure1.id,
        measure2Id: measure2.id,
        measure1SortNumber: measure2.sort_number,
        measure2SortNumber: measure1.sort_number,
      })
      .toPromise()
    if (error) {
      notificationService.operationFailed()
    } else {
      notificationService.changesSaved()
      refetch()
    }
  }

  const moveMeasureDown = (event: any, measureId: number) => {
    event.stopPropagation()
    const measure1Index = projectMeasures.findIndex((measure: Project_Measure) => measure.id === measureId)
    if (measure1Index < projectMeasures.length - 1) {
      const measure1 = projectMeasures[measure1Index]
      const measure2 = projectMeasures[measure1Index + 1]
      if (measure1 && measure2) {
        setMeasuresBeingSwappedIds({ id1: measure2.id, id2: measure1.id })
        moveMeasure(measure1, measure2)
      }
    }
  }

  const handleDeleteGoal = (goalId: number, linkedMeasures: Project_Goal_Measure[]) => {
    setProjectGoalIdToBeDeleted(goalId)
    setMeasuresLinked(linkedMeasures.length > 0)
    setProjectGoalDeleteConfirmationOpen(true)
  }
  const handleCancelDeleteProjectGoal = () => {
    setProjectGoalDeleteConfirmationOpen(false)
  }
  const handleDeleteProjectGoalConfirmed = async () => {
    setProjectGoalDeleteConfirmationOpen(false)
    const { error } = await urqlClient
      .mutation<{
        delete_project_goal_by_pk: Mutation_Root['delete_project_goal_by_pk']
      }>(deleteProjectGoalByPk, {
        goal_id: projectGoalIdToBeDeleted,
      })
      .toPromise()
    if (error) {
      notificationService.operationFailed()
    } else {
      refetch()
      notificationService.deleteSuccessful()
      setMeasuresLinked(false)
    }
  }
  const handleEditGoal = (projectId: number, goalId: number) => {
    navigate(resolveDetailsRoot(baseUrl).nested.ProjectGoalEdit.params({ projectId, goalId }))
  }

  const handleDeleteMeasure = (measureId: number, linkedGoals: Project_Goal_Measure[]) => {
    setProjectMeasureIdToBeDeleted(measureId)
    setGoalsLinked(linkedGoals.length > 0)
    setProjectMeasureDeleteConfirmationOpen(true)
  }
  const handleCancelDeleteProjectMeasure = () => {
    setProjectMeasureDeleteConfirmationOpen(false)
  }
  const handleDeleteProjectMeasureConfirmed = async () => {
    setProjectMeasureDeleteConfirmationOpen(false)
    const { error } = await urqlClient
      .mutation<{
        delete_project_measure_by_pk: Mutation_Root['delete_project_measure_by_pk']
      }>(deleteProjectMeasureByPk, {
        measure_id: projectMeasureIdToBeDeleted,
      })
      .toPromise()
    if (error) {
      notificationService.operationFailed()
    } else {
      refetch()
      notificationService.deleteSuccessful()
      setGoalsLinked(false)
    }
  }
  const handleEditMeasure = (projectId: number, measureId: number) => {
    navigate(resolveDetailsRoot(baseUrl).nested.ProjectMeasureEdit.params({ projectId, measureId }))
  }

  return (
    <ScreenLayout
      title={getMessage('label.navigation.project.details')}
      actions={<ProjectExportMenu process={projectType} entityId={projectBaseId as number} />}
    >
      <PageLayout>
        <>
          {!loading && (
            <>
              <Section
                id="goals"
                title={getMessage('label.project.goals')}
                actionButton={
                  <AddButton messageKey="button.goal.add" onClick={handleAddGoalButtonClick} hidden={!canEdit} />
                }
                helpAndInstructions={
                  <HelpAndInstructions labelKey={`label.help.goals.and.measures.goals.${process}`} />
                }
              >
                {projectGoals && projectGoals.length > 0 && (
                  <Stack spacing={1}>
                    {projectGoals.map((goal: Project_Goal, index: number) => {
                      const goalHasRatings = !!goal.project_goal_annual_reports.length

                      return (
                        <ExpandableGoalMeasureCard
                          key={goal.id}
                          type={GOAL_CHAR}
                          title={goal.name}
                          id={`${getMessage('label.goal.id.character')}${goal.sort_number}`}
                          entityId={goal.id}
                          onEdit={() => handleEditGoal(projectId, goal.id)}
                          canEdit={canEdit}
                          sorting={{
                            moveUp: moveGoalUp,
                            moveDown: moveGoalDown,
                            index: index,
                            listLength: projectGoals.length,
                            beingSwappedIds: goalsBeingSwappedIds,
                          }}
                          deleteAction={
                            <>
                              {canEdit && !goalHasRatings && (
                                <IconButton
                                  color="primary"
                                  onClick={() => handleDeleteGoal(goal.id, goal.project_goal_measures)}
                                  size="large"
                                >
                                  <DeleteIcon />
                                </IconButton>
                              )}
                            </>
                          }
                        >
                          <ReadOnlyTextField text={getMessage('label.goal.rating.description.kap')}>
                            {goal.description && <HtmlRenderer html={goal.description} />}
                          </ReadOnlyTextField>
                          <ReadOnlySelection text={getMessage('label.related.measures')} isLast>
                            {goal.project_goal_measures.length > 0 ? (
                              <ProjectGoalRelatedMeasuresGrid measures={goal.project_goal_measures ?? []} />
                            ) : (
                              <DefaultSectionTypography
                                noEntriesMessageKey={getMessage('label.not.available.measures')}
                                $standAlone={true}
                              />
                            )}
                          </ReadOnlySelection>
                        </ExpandableGoalMeasureCard>
                      )
                    })}
                  </Stack>
                )}
              </Section>
              <Section
                id="measures"
                title={getMessage('label.project.measures')}
                actionButton={
                  <AddButton messageKey="button.measure.add" onClick={handleAddMeasureButtonClick} hidden={!canEdit} />
                }
                helpAndInstructions={
                  <HelpAndInstructions labelKey={`label.help.goals.and.measures.measures.${process}`} />
                }
              >
                {projectMeasures && projectMeasures.length > 0 && (
                  <Stack spacing={1}>
                    {projectMeasures.map((measure: Project_Measure, index: number) => {
                      const measureHasRatings = !!measure.project_measure_annual_reports.length

                      return (
                        <ExpandableGoalMeasureCard
                          key={measure.id}
                          type={MEASURE_CHAR}
                          title={measure.name}
                          id={`${getMessage('label.measure.id.character')}${measure.sort_number}`}
                          entityId={measure.id}
                          onEdit={() => handleEditMeasure(projectId, measure.id)}
                          canEdit={canEdit}
                          sorting={{
                            moveUp: moveMeasureUp,
                            moveDown: moveMeasureDown,
                            index: index,
                            listLength: projectMeasures.length,
                            beingSwappedIds: measuresBeingSwappedIds,
                          }}
                          deleteAction={
                            <>
                              {canEdit && !measureHasRatings && (
                                <IconButton
                                  color="primary"
                                  onClick={() => handleDeleteMeasure(measure.id, measure.project_goal_measures)}
                                  size="large"
                                >
                                  <DeleteIcon />
                                </IconButton>
                              )}
                            </>
                          }
                        >
                          <ReadOnlyTextField text={getMessage('label.description')}>
                            {measure.description && <HtmlRenderer html={measure.description} />}
                          </ReadOnlyTextField>
                          <ReadOnlySelection text={getMessage('label.start.date')}>
                            {measure?.start_date && DateUtils.parseAndFormatDate(measure.start_date)}
                          </ReadOnlySelection>
                          <ReadOnlySelection text={getMessage('label.end.date')}>
                            {measure?.end_date && DateUtils.parseAndFormatDate(measure.end_date)}
                          </ReadOnlySelection>
                          <ReadOnlySelection text={getMessage('label.related.goals')} isLast>
                            {measure.project_goal_measures.length > 0 ? (
                              <ProjectMeasureRelatedGoalsGrid goals={measure.project_goal_measures ?? []} />
                            ) : (
                              <DefaultSectionTypography
                                noEntriesMessageKey={getMessage('label.not.available.goals')}
                                $standAlone={true}
                              />
                            )}
                          </ReadOnlySelection>
                        </ExpandableGoalMeasureCard>
                      )
                    })}
                  </Stack>
                )}
              </Section>
            </>
          )}
        </>
      </PageLayout>
      <ConfirmationModalDialog
        open={projectGoalDeleteConfirmationOpen}
        onCancel={handleCancelDeleteProjectGoal}
        onConfirm={handleDeleteProjectGoalConfirmed}
      >
        {measuresLinked ? getMessage('label.delete.confirm.goals.found') : getMessage('label.delete.confirm')}
      </ConfirmationModalDialog>
      <ConfirmationModalDialog
        open={projectMeasureDeleteConfirmationOpen}
        onCancel={handleCancelDeleteProjectMeasure}
        onConfirm={handleDeleteProjectMeasureConfirmed}
      >
        {goalsLinked ? getMessage('label.delete.confirm.measures.found') : getMessage('label.delete.confirm')}
      </ConfirmationModalDialog>
    </ScreenLayout>
  )
}
