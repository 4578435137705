import { Button } from '@mui/material'
import { ReactElement, useState } from 'react'
import { useParams } from 'react-router'
import { useMessageSource } from 'src/i18n/useMessageSource'
import { ROUTES } from 'src/routing/routes'
import { KapMeasureGroupSection } from 'src/screens/kap/program/details/measures/KapMeasureGroupSection'
import { fetchKapMeasuresByProgramId } from 'src/screens/kap/program/details/measures/kapMeasuresQueries'
import { CopyKapMeasuresModalDialog } from 'src/screens/kap/program/details/measures/measure/copy-measures/CopyKapMeasuresModalDialog'
import { KapMeasuresSection } from 'src/screens/shared/common/KapMeasuresSection'
import { usePermissionsForKapProgram } from 'src/service/security/PermissionHook'
import { AddButton } from 'src/shared/button/Buttons'
import { LEVEL } from 'src/shared/constants/constants'
import { CopyIcon } from 'src/shared/icons/Icons'
import { PageLayout } from 'src/shared/layout/PageLayout'
import { ScreenLayout } from 'src/shared/layout/ScreenLayout'
import { ProgramExportMenu } from 'src/shared/menu/ProgramExportMenu'
import { ModalDialog } from 'src/shared/modal-dialog/ModalDialog'
import { useDelayedNavigate } from 'src/shared/utils/hooks/navigation-hooks'
import { useNotificationService } from 'src/shared/utils/NotificationService'
import { useQuery } from 'urql'

export const KapMeasuresPage = (): ReactElement => {
  const { programId } = useParams()
  const { getMessage } = useMessageSource()
  const notificationService = useNotificationService()
  const navigate = useDelayedNavigate()
  const { canEditMeasures, canCopyMeasures, loading, canViewCantonRestricted } = usePermissionsForKapProgram(
    parseInt(programId as string),
  )

  const [copyMeasuresModalOpen, setCopyMeasuresModalOpen] = useState(false)

  const [{ data, error }, refetch] = useQuery({
    query: fetchKapMeasuresByProgramId,
    variables: { programId },
  })

  if (error) {
    notificationService.operationFailed()
  }

  const handleAddMeasure = () => {
    navigate(ROUTES.KapDetailsRoot.nested.MeasureNew.params({ programId }))
  }

  const handleCopyMeasures = () => {
    setCopyMeasuresModalOpen(true)
  }

  const onCancelCopyMeasures = () => {
    setCopyMeasuresModalOpen(false)
  }

  return (
    <ScreenLayout
      title={getMessage('label.program.description')}
      actions={<>{canViewCantonRestricted && <ProgramExportMenu />}</>}
    >
      <PageLayout>
        <>
          {!loading && data?.kap_measure && (
            <>
              <KapMeasuresSection
                measures={data?.kap_measure}
                helpAndInstructionsKey="label.help.kap.measures"
                actionButton={
                  <>
                    {canCopyMeasures && (
                      <Button onClick={handleCopyMeasures} variant="text" startIcon={<CopyIcon />}>
                        {getMessage('button.copy.program.measures')}
                      </Button>
                    )}
                    <AddButton messageKey="button.measure.add" onClick={handleAddMeasure} hidden={!canEditMeasures} />
                  </>
                }
              >
                {({ activeLevels, communicationMeasures, interventionMeasures, networkMeasures, policyMeasures }) => (
                  <>
                    {activeLevels.includes(LEVEL.INTERVENTION) && (
                      <KapMeasureGroupSection
                        idCharacter={getMessage(`label.kap.measure.id.character.${LEVEL.INTERVENTION}`)}
                        title={getMessage(`label.level.${LEVEL.INTERVENTION}`)}
                        measures={interventionMeasures}
                        refetch={refetch}
                        canEditMeasures={canEditMeasures}
                      />
                    )}
                    {activeLevels.includes(LEVEL.POLICY) && (
                      <KapMeasureGroupSection
                        idCharacter={getMessage(`label.kap.measure.id.character.${LEVEL.POLICY}`)}
                        title={getMessage(`label.level.${LEVEL.POLICY}`)}
                        measures={policyMeasures}
                        refetch={refetch}
                        canEditMeasures={canEditMeasures}
                      />
                    )}
                    {activeLevels.includes(LEVEL.NETWORK) && (
                      <KapMeasureGroupSection
                        idCharacter={getMessage(`label.kap.measure.id.character.${LEVEL.NETWORK}`)}
                        title={getMessage(`label.level.${LEVEL.NETWORK}`)}
                        measures={networkMeasures}
                        refetch={refetch}
                        canEditMeasures={canEditMeasures}
                      />
                    )}
                    {activeLevels.includes(LEVEL.COMMUNICATION) && (
                      <KapMeasureGroupSection
                        idCharacter={getMessage(`label.kap.measure.id.character.${LEVEL.COMMUNICATION}`)}
                        title={getMessage(`label.level.${LEVEL.COMMUNICATION}`)}
                        measures={communicationMeasures}
                        refetch={refetch}
                        canEditMeasures={canEditMeasures}
                      />
                    )}
                  </>
                )}
              </KapMeasuresSection>
            </>
          )}
          {programId && (
            <ModalDialog
              open={copyMeasuresModalOpen}
              onClose={onCancelCopyMeasures}
              title={getMessage('label.copy.program.measures')}
            >
              <CopyKapMeasuresModalDialog
                currentProgramId={parseInt(programId)}
                destinationProgramModules={data?.kap_program_by_pk?.modules}
                onCloseDialog={() => setCopyMeasuresModalOpen(false)}
                refetch={refetch}
              />
            </ModalDialog>
          )}
        </>
      </PageLayout>
    </ScreenLayout>
  )
}
