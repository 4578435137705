import { InfoOutlined } from '@mui/icons-material'
import { CardActions, CardHeader, Typography } from '@mui/material'
import Alert from '@mui/material/Alert'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Stack from '@mui/material/Stack'
import { ReactElement, ReactNode } from 'react'
import { useMessageSource } from 'src/i18n/useMessageSource'
import { ValidationListItemProps } from 'src/screens/shared/common/SummaryValidationUtils'
import { STYLED_FOCUS_OUTLINE } from 'src/shared/constants/styling-constants'
import { CheckIcon, ErrorOutlineIcon, GoToPageIcon } from 'src/shared/icons/Icons'
import { HelpAndInstructions } from 'src/shared/presentation/HelpAndInstructions'
import { HtmlRenderer } from 'src/shared/presentation/HtmlRenderer'
import { Section } from 'src/shared/presentation/Section'
import styled from 'styled-components/macro'

interface Props {
  title: string
  actionsHelpLabel: string
  status: ReactNode
  violations: ValidationListItemProps[]
  violationClickHandler: (validationItem: ValidationListItemProps) => void
  actionsCompleteMessage: string
  statusActions: ReactNode
  commentsHelpLabel: string
  commentActions: ReactNode
  comment: string | null | undefined
  noItemsToValidate?: boolean
  sectionActionButton?: ReactNode
}

interface AlertProps {
  message: string
  onClick?: () => void
  onKeyPress?: (event: any) => void
  noItemsToValidate?: boolean
}

const listHeight = '2.5rem'

const ListTitle = styled(Typography)`
  height: ${listHeight};
  display: flex;
  align-items: center;
`

const AlertStyled = styled(Alert)`
  height: ${listHeight};
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.text.primary};

  & .MuiAlert-action {
    padding-top: 0;
  }
`

const AlertWarningStyled = styled(AlertStyled)`
  &:hover {
    cursor: pointer;
    background-color: ${({ theme }) => theme.colors.primary.light};
  }

  &:hover .MuiAlert-action {
    & .MuiSvgIcon-root {
      color: ${({ theme }) => theme.colors.primary.main};
    }
  }

  &:focus-visible {
    ${STYLED_FOCUS_OUTLINE};
    outline-offset: 0;
    background-color: ${({ theme }) => theme.colors.primary.light};
  }
`

const AlertSuccessStyled = styled(AlertStyled)`
  background-color: ${({ theme }) => theme.colors.secondary.light};

  & .MuiSvgIcon-root {
    color: ${({ theme }) => theme.colors.secondary.main};
  }
`

const AlertInfoStyled = styled(AlertStyled)`
  background-color: ${({ theme }) => theme.colors.grey.neutral};

  & .MuiSvgIcon-root {
    color: ${({ theme }) => theme.colors.text.disabled};
  }
`

const CardHeaderStyled = styled(CardHeader)`
  span {
    line-height: 2.25rem;
  }
`

const ListItemIncomplete = ({ message, ...rest }: AlertProps): ReactElement => {
  return (
    <AlertWarningStyled severity="warning" icon={<ErrorOutlineIcon />} action={<GoToPageIcon />} {...rest} tabIndex={0}>
      {message}
    </AlertWarningStyled>
  )
}

const ListItemComplete = ({ message, noItemsToValidate, ...rest }: AlertProps): ReactElement => {
  return noItemsToValidate ? (
    <AlertInfoStyled severity="info" icon={<InfoOutlined />} {...rest}>
      {message}
    </AlertInfoStyled>
  ) : (
    <AlertSuccessStyled severity="success" icon={<CheckIcon />} {...rest}>
      {message}
    </AlertSuccessStyled>
  )
}

export const SummarySection = ({
  title,
  actionsHelpLabel,
  status,
  violations,
  violationClickHandler,
  actionsCompleteMessage,
  statusActions,
  commentsHelpLabel,
  commentActions,
  comment,
  noItemsToValidate,
  sectionActionButton,
}: Props) => {
  const { getMessage } = useMessageSource()

  return (
    <>
      <Section
        title={title}
        helpAndInstructions={<HelpAndInstructions labelKey={actionsHelpLabel} />}
        actionButton={sectionActionButton}
      >
        {violations && (
          <Card>
            <CardHeaderStyled
              title={<>{status}</>}
              action={<CardActions sx={{ padding: 0 }}>{statusActions}</CardActions>}
            />
            <CardContent>
              <Stack spacing={1}>
                <ListTitle variant="h6">{getMessage('label.validation')}</ListTitle>

                {violations.length === 0 ? (
                  <ListItemComplete message={actionsCompleteMessage} noItemsToValidate={noItemsToValidate} />
                ) : (
                  violations.map((violation) => {
                    return (
                      <ListItemIncomplete
                        message={violation.message}
                        key={violation.message}
                        onClick={() => violationClickHandler(violation)}
                        onKeyPress={(event) => {
                          if (['Enter', ' '].includes(event.key)) {
                            violationClickHandler(violation)
                          }
                        }}
                      />
                    )
                  })
                )}
              </Stack>
            </CardContent>
          </Card>
        )}
      </Section>
      <Section
        id="comments"
        title={getMessage('label.summary.comments')}
        helpAndInstructions={<HelpAndInstructions labelKey={commentsHelpLabel} />}
        actionButton={commentActions}
      >
        {comment && (
          <Card>
            <CardContent>
              <HtmlRenderer html={comment} />
            </CardContent>
          </Card>
        )}
      </Section>
    </>
  )
}
