import { LoadingButton } from '@mui/lab'
import { Button, ButtonProps } from '@mui/material'
import { FC, ReactElement } from 'react'
import { useMessageSource } from 'src/i18n/useMessageSource'
import { AddIcon, DeleteIcon, DownloadIcon, EditIcon, SaveIcon, SearchIcon, UploadIcon } from 'src/shared/icons/Icons'

interface ButtonCustomProps {
  origin?: 'header' | 'content' | 'dialog'
  loading?: boolean
}

interface BaseButtonCustomProps {
  messageKey: string
}

export const BaseButton: FC<BaseButtonCustomProps & ButtonProps> = (props): ReactElement => {
  const { getMessage } = useMessageSource()
  const { messageKey, hidden, ...restProps } = props

  const defaultSaveMessage = getMessage(messageKey)
  const resolvedText = props.children ?? defaultSaveMessage
  const resolvedColor = props.color ?? 'primary'
  return (
    <>
      {!hidden && (
        <Button {...restProps} color={resolvedColor}>
          {resolvedText}
        </Button>
      )}
    </>
  )
}

export const EditButton: FC<ButtonCustomProps & Omit<ButtonProps, 'variant' | 'startIcon'>> = ({
  origin = 'content',
  ...restProps
}): ReactElement => {
  return (
    <BaseButton
      messageKey="button.edit"
      variant={origin === 'header' ? 'contained' : 'text'}
      startIcon={origin === 'content' && <EditIcon />}
      {...restProps}
    />
  )
}

export const SaveButton: FC<ButtonCustomProps & Omit<ButtonProps, 'variant' | 'startIcon' | 'color'>> = ({
  origin = 'content',
  ...restProps
}): ReactElement => {
  return (
    <BaseButton
      messageKey="button.save"
      variant={origin === 'dialog' ? 'contained' : 'text'}
      startIcon={origin === 'content' && <SaveIcon />}
      {...restProps}
    />
  )
}

export const SaveAndBackButton: FC<ButtonCustomProps & Omit<ButtonProps, 'variant' | 'startIcon' | 'color'>> = ({
  origin = 'content',
  ...restProps
}): ReactElement => {
  return (
    <BaseButton
      messageKey="button.save.and.back"
      variant={origin === 'header' ? 'contained' : 'text'}
      startIcon={origin === 'content' && <SaveIcon />}
      {...restProps}
    />
  )
}

export const DeleteButton: FC<ButtonCustomProps & Omit<ButtonProps, 'variant' | 'startIcon'>> = ({
  origin = 'content',
  ...restProps
}): ReactElement => {
  return (
    <BaseButton
      messageKey="button.delete"
      variant={origin === 'header' ? 'contained' : 'text'}
      startIcon={origin === 'content' && <DeleteIcon />}
      {...restProps}
    />
  )
}

export const CreateButton: FC<ButtonCustomProps & Omit<ButtonProps, 'variant'>> = ({
  origin = 'content',
  ...restProps
}): ReactElement => {
  return (
    <BaseButton
      messageKey="button.create"
      variant={origin === 'header' ? 'contained' : 'text'}
      startIcon={origin === 'content' && <SaveIcon />}
      {...restProps}
    />
  )
}

export const AddButton: FC<ButtonCustomProps & BaseButtonCustomProps & Omit<ButtonProps, 'variant'>> = ({
  origin = 'content',
  messageKey,
  startIcon = <AddIcon />,
  ...restProps
}): ReactElement => {
  return (
    <BaseButton
      messageKey={messageKey}
      variant={origin === 'header' ? 'contained' : 'text'}
      startIcon={origin === 'content' && startIcon}
      {...restProps}
    />
  )
}

export const UploadButton: FC<ButtonCustomProps & Omit<ButtonProps, 'variant' | 'startIcon'>> = ({
  origin = 'content',
  ...restProps
}): ReactElement => {
  return (
    <BaseButton
      messageKey="button.upload"
      variant={origin === 'header' ? 'contained' : 'text'}
      startIcon={origin === 'content' && <UploadIcon />}
      {...restProps}
    />
  )
}

export const DownloadButton: FC<ButtonCustomProps & Omit<ButtonProps, 'variant' | 'startIcon'>> = ({
  origin = 'content',
  ...restProps
}): ReactElement => {
  return (
    <BaseButton
      messageKey="button.download"
      variant={origin === 'header' ? 'contained' : 'text'}
      startIcon={origin === 'content' && <DownloadIcon />}
      {...restProps}
    />
  )
}

export const SearchButton: FC<ButtonCustomProps & Omit<ButtonProps, 'variant' | 'startIcon'>> = ({
  ...restProps
}): ReactElement => {
  const { getMessage } = useMessageSource()
  return (
    <LoadingButton variant="contained" startIcon={<SearchIcon />} {...restProps}>
      {getMessage('button.search')}
    </LoadingButton>
  )
}

export const PrimaryButton: FC<BaseButtonCustomProps & Omit<ButtonProps, 'variant' | 'color'>> = ({
  messageKey,
  ...restProps
}): ReactElement => {
  return <BaseButton messageKey={messageKey} variant="contained" {...restProps} />
}
export const SecondaryButton: FC<BaseButtonCustomProps & Omit<ButtonProps, 'variant' | 'color'>> = ({
  messageKey,
  ...restProps
}): ReactElement => {
  return <BaseButton messageKey={messageKey} variant="text" {...restProps} />
}
