import { GridLocaleText, GRID_DEFAULT_LOCALE_TEXT } from '@mui/x-data-grid-pro'
import { useMemo } from 'react'
import { useMessageSource } from 'src/i18n/useMessageSource'

export function useGridTranslateHook(): GridLocaleText {
  const { getMessage } = useMessageSource()

  return useMemo(() => {
    return {
      ...GRID_DEFAULT_LOCALE_TEXT,
      noRowsLabel: getMessage('label.grid.no.rows'),
      noResultsOverlayLabel: getMessage('label.grid.no.results.found.'),
      errorOverlayDefaultLabel: getMessage('label.grid.an.error.occurred.'),

      toolbarDensity: getMessage('label.grid.density'),
      toolbarDensityLabel: getMessage('label.grid.density'),
      toolbarDensityCompact: getMessage('label.grid.compact'),
      toolbarDensityStandard: getMessage('label.grid.standard'),
      toolbarDensityComfortable: getMessage('label.grid.comfortable'),

      toolbarColumns: getMessage('label.grid.columns'),
      toolbarColumnsLabel: getMessage('label.grid.select.columns'),

      toolbarFilters: getMessage('label.grid.filters'),
      toolbarFiltersLabel: getMessage('label.grid.show.filters'),
      toolbarFiltersTooltipHide: getMessage('label.grid.hide.filters'),
      toolbarFiltersTooltipShow: getMessage('label.grid.show.filters'),
      toolbarFiltersTooltipActive: (count) =>
        count !== 1
          ? `${count} ${getMessage('label.grid.active.filters')}`
          : `${count} ${getMessage('label.grid.active.filter')}`,

      toolbarExport: getMessage('label.grid.export'),
      toolbarExportLabel: getMessage('label.grid.export'),
      toolbarExportCSV: getMessage('label.grid.download.as.csv'),
      toolbarExportPrint: getMessage('label.grid.print'),

      columnsPanelTextFieldLabel: getMessage('label.grid.find.column'),
      columnsPanelTextFieldPlaceholder: getMessage('label.grid.column.title'),
      columnsPanelDragIconLabel: getMessage('label.grid.reorder.column'),
      columnsPanelShowAllButton: getMessage('label.grid.show.all'),
      columnsPanelHideAllButton: getMessage('label.grid.hide.all'),

      filterPanelAddFilter: getMessage('label.grid.add.filter'),
      filterPanelDeleteIconLabel: getMessage('label.grid.delete'),
      filterPanelOperators: getMessage('label.grid.operators'),
      filterPanelOperatorAnd: getMessage('label.operator.AND'),
      filterPanelOperatorOr: getMessage('label.operator.OR'),
      filterPanelColumns: getMessage('label.grid.columns'),
      filterPanelInputLabel: getMessage('label.grid.value'),
      filterPanelInputPlaceholder: getMessage('label.grid.filter.value'),
      filterPanelRemoveAll: getMessage('label.grid.filter.remove.all'),

      filterOperatorContains: getMessage('label.grid.contains'),
      filterOperatorEquals: getMessage('label.grid.equals'),
      filterOperatorStartsWith: getMessage('label.grid.starts.with'),
      filterOperatorEndsWith: getMessage('label.grid.ends.with'),
      filterOperatorIs: getMessage('label.grid.is'),
      filterOperatorNot: getMessage('label.grid.is.not'),
      filterOperatorAfter: getMessage('label.grid.is.after'),
      filterOperatorOnOrAfter: getMessage('label.grid.is.on.or.after'),
      filterOperatorBefore: getMessage('label.grid.is.before'),
      filterOperatorOnOrBefore: getMessage('label.grid.is.on.or.before'),
      filterOperatorIsEmpty: getMessage('label.grid.is.empty'),
      filterOperatorIsNotEmpty: getMessage('label.grid.is.not.empty'),

      filterValueAny: getMessage('label.grid.any'),

      columnMenuLabel: getMessage('label.grid.menu'),
      columnMenuShowColumns: getMessage('label.grid.show.columns'),
      columnMenuFilter: getMessage('label.grid.filter'),
      columnMenuHideColumn: getMessage('label.grid.hide'),
      columnMenuUnsort: getMessage('label.grid.unsort'),
      columnMenuSortAsc: getMessage('label.grid.sort.by.asc'),
      columnMenuSortDesc: getMessage('label.grid.sort.by.desc'),
      columnMenuManageColumns: getMessage('label.grid.manage.columns'),

      columnHeaderFiltersTooltipActive: (count) =>
        count !== 1
          ? `${count} ${getMessage('label.grid.active.filters')}`
          : `${count} ${getMessage('label.grid.active.filter')}`,
      columnHeaderFiltersLabel: getMessage('label.grid.show.filters'),
      columnHeaderSortIconLabel: getMessage('label.grid.sort'),

      footerRowSelected: (count) =>
        count !== 1
          ? `${count.toLocaleString()} ${getMessage('label.grid.rows.selected')}`
          : `${count.toLocaleString()} ${getMessage('label.grid.row.selected')}`,

      footerTotalRows: getMessage('label.grid.total.rows:'),

      footerTotalVisibleRows: (visibleCount, totalCount) =>
        `${visibleCount.toLocaleString()} ${getMessage('label.grid.of')} ${totalCount.toLocaleString()}`,

      checkboxSelectionHeaderName: getMessage('label.grid.checkbox.selection'),
      actionsCellMore: getMessage('label.grid.more'),
    }
  }, [getMessage])
}
