import { ReactElement } from 'react'
import { useParams } from 'react-router'
import { ProjectFeatures } from 'src/screens/shared/project/details/project-features/ProjectFeatures'

export const PfKapProjectFeaturesPage = (): ReactElement => {
  const { projectId } = useParams()
  const pf_kap_id = parseInt(projectId as string)

  return <ProjectFeatures projectId={pf_kap_id} baseUrl="/pf-kap" />
}
