import { GridColDef, useGridApiRef } from '@mui/x-data-grid-pro'
import { sortBy } from 'lodash'
import { ReactElement, useMemo } from 'react'
import { Project_Goal_Measure } from 'src/@types/graphql'
import { useMessageSource } from 'src/i18n/useMessageSource'
import { S } from 'src/shared/styled/S'
import { useGridTranslateHook } from 'src/shared/utils/hooks/grid-translate-hook'

interface Props {
  measures: Project_Goal_Measure[]
}

export const ProjectGoalRelatedMeasuresGrid = ({ measures }: Props): ReactElement => {
  const gridTranslations = useGridTranslateHook()
  const gridRef = useGridApiRef()
  const { getMessage } = useMessageSource()

  const rows = useMemo(() => {
    return sortBy(measures, ['project_measure.sort_number']).map((measure) => {
      return {
        id: `${getMessage(`label.measure.id.character`)}${measure.project_measure.sort_number}`,
        title: measure.project_measure.name,
      }
    })
  }, [measures, getMessage])

  const columns = useMemo<GridColDef[]>(
    () => [
      {
        field: 'id',
        flex: 0.5,
        headerName: getMessage('label.id'),
      },
      {
        field: 'title',
        flex: 2,
        headerName: getMessage('label.title'),
      },
    ],
    [getMessage],
  )

  return (
    <S.DataGrid.ViewEditMode
      rows={rows}
      columns={columns}
      autoHeight
      apiRef={gridRef}
      localeText={gridTranslations}
      disableColumnSelector
      disableColumnReorder
      disableColumnPinning
      disableRowSelectionOnClick
      hideFooter
      $viewMode
      sx={{ marginTop: 1 }}
    />
  )
}
