import axios, { AxiosInstance, AxiosResponse, InternalAxiosRequestConfig } from 'axios'
import { CookieUtils } from 'src/shared/utils/CookieUtils'
import { NavigationUtils } from 'src/shared/utils/NavigationUtils'

let instance: AxiosInstance

const setAxios = (backendUrl: string): void => {
  instance = axios.create({
    baseURL: backendUrl,
    withCredentials: true,
  })

  const errorHandler = (error: any) => {
    if (error.response.status === 401) {
      NavigationUtils.login(backendUrl)
      // return unresolvable promise
      return new Promise(() => {})
    }
    return Promise.reject(error.response.data)
  }

  instance.interceptors.request.use((config: InternalAxiosRequestConfig<any>) => {
    // @ts-ignore
    config!.headers!['X-XSRF-TOKEN'] = CookieUtils.get('XSRF-TOKEN')
    return config
  })

  instance.interceptors.response.use(
    async (response: AxiosResponse<unknown>) => response,
    (error) => errorHandler(error),
  )
}

const getAxios = (): AxiosInstance => {
  if (!instance) {
    throw Error('You called axios before environment context has been loaded!')
  }
  return instance
}

export const Axios = {
  getAxios,
  setAxios,
}
