import { gql } from '@urql/core'

export const queryAssessmentRecommendationById = gql`
  query ($assessmentId: Int!) {
    project_assessment(where: { id: { _eq: $assessmentId } }) {
      id
      version
      recommendation
      pros
      cons
      recommendation_comment
    }
  }
`

export const updateAssessmentRecommendationById = gql`
  mutation (
    $assessmentId: Int!
    $recommendation: String
    $pros: String
    $cons: String
    $comment: String
    $version: Int!
  ) {
    update_project_assessment(
      where: { _and: { id: { _eq: $assessmentId }, version: { _eq: $version } } }
      _set: { recommendation_comment: $comment, pros: $pros, cons: $cons, recommendation: $recommendation }
    ) {
      affected_rows
      returning {
        version
      }
    }
  }
`
