import { Box, CircularProgress, IconButton, Typography } from '@mui/material'
import combinedQuery from 'graphql-combine-query'
import { useCallback, useEffect, useState } from 'react'
import { File, Query_Root } from 'src/@types/graphql'
import { useEnvironment } from 'src/env/EnvironmentStore'
import { useMessageSource } from 'src/i18n/useMessageSource'
import { queryEvaluationMilestoneSecondaryInfoById } from 'src/screens/shared/project/details/effectivity-management/effectivityManagementQueries'
import { FileService } from 'src/service/axios/FileService'
import { ENTITY_TYPE, FILE_TYPE } from 'src/shared/constants/constants'
import { DeleteIcon, DownloadIcon } from 'src/shared/icons/Icons'
import { ConfirmationModalDialog } from 'src/shared/modal-dialog/ConfirmationModalDialog'
import { DefaultSectionTypography } from 'src/shared/presentation/DefaultSectionTypography'
import { queryFiles } from 'src/shared/presentation/fileQueries'
import { DocumentContainer, DownloadLinkStyled } from 'src/shared/presentation/FileSection'
import { HtmlRenderer } from 'src/shared/presentation/HtmlRenderer'
import { ReadOnlySelection, ReadOnlyTextField } from 'src/shared/presentation/ReadOnly'
import { CardStyled } from 'src/shared/styled/card/CardStyled'
import { S } from 'src/shared/styled/S'
import { DateUtils } from 'src/shared/utils/DateUtils'
import { useNotificationService } from 'src/shared/utils/NotificationService'
import styled from 'styled-components/macro'
import { useClient } from 'urql'

const LoadingContainer = styled(Box)`
  display: flex;
  justify-content: center;
  padding-bottom: ${({ theme }) => theme.spacing(2)};
  border-radius: 4px;
`

interface Props {
  evaluationMilestoneId: number
  canEdit: boolean
  refetchDocs: boolean
  resetRefetchDocs: () => void
}

interface CardContentInfo {
  description: string
}

export const EvaluationMilestoneCardContent = ({
  evaluationMilestoneId,
  canEdit,
  refetchDocs,
  resetRefetchDocs,
}: Props) => {
  const { getMessage } = useMessageSource()
  const { backendUrl } = useEnvironment()

  const urqlClient = useClient()
  const notificationService = useNotificationService()

  const [cardContentInfo, setCardContentInfo] = useState<CardContentInfo>()

  const [fileUUIDToBeDeleted, setFileUUIDToBeDeleted] = useState<string>()
  const [fileDeleteConfirmationOpen, setFileDeleteConfirmationOpen] = useState(false)
  const [files, setFiles] = useState<File[]>()

  const fetchData = useCallback(async () => {
    const { document, variables } = combinedQuery('ProjectEvaluationMilestoneWithDocuments')
      .add<{
        file: Query_Root['file']
      }>(queryFiles, {
        fileType: FILE_TYPE.EVALUATION_MILESTONE_DOCUMENT,
        entityType: ENTITY_TYPE.EVALUATION_MILESTONE,
        entityId: evaluationMilestoneId,
      })
      .add<{
        evaluation_milestone_by_pk: Query_Root['evaluation_milestone_by_pk']
      }>(queryEvaluationMilestoneSecondaryInfoById, {
        evaluationMilestoneId,
      })

    const { data } = await urqlClient
      .query<{
        file: Query_Root['file']
        evaluation_milestone_by_pk: Query_Root['evaluation_milestone_by_pk']
      }>(document, variables)
      .toPromise()

    if (data?.evaluation_milestone_by_pk) {
      setCardContentInfo({
        description: data.evaluation_milestone_by_pk.description,
      })
      setFiles(data?.file)
    } else {
      notificationService.operationFailed()
    }
  }, [evaluationMilestoneId, notificationService, urqlClient])

  useEffect(() => {
    fetchData()
  }, [evaluationMilestoneId, fetchData, notificationService, urqlClient])

  useEffect(() => {
    if (refetchDocs) {
      fetchData().then(() => resetRefetchDocs())
    }
  }, [refetchDocs, resetRefetchDocs, fetchData])

  const onDeleteFile = (uuid: string) => {
    setFileUUIDToBeDeleted(uuid)
    setFileDeleteConfirmationOpen(true)
  }

  const onCancelDeleteFile = () => {
    setFileUUIDToBeDeleted(undefined)
    setFileDeleteConfirmationOpen(false)
  }

  const onConfirmDeleteFile = () => {
    if (fileUUIDToBeDeleted) {
      FileService.remove(fileUUIDToBeDeleted)
        .then(() => {
          notificationService.deleteSuccessful()
          fetchData()
        })
        .catch(() => {
          notificationService.operationFailed()
        })
      setFileUUIDToBeDeleted(undefined)
    }
    setFileDeleteConfirmationOpen(false)
  }

  return (
    <>
      {cardContentInfo ? (
        <>
          <ReadOnlyTextField text={getMessage('label.evaluation.milestone.description')}>
            {cardContentInfo.description && <HtmlRenderer html={cardContentInfo.description} />}
          </ReadOnlyTextField>
          <ReadOnlySelection text={getMessage('label.documents')} isLast>
            {files?.length ? (
              files?.map((file) => (
                <CardStyled key={file.id}>
                  <DownloadLinkStyled href={`${backendUrl}/api/file/${file.uuid}`}>
                    <S.Card.Content>
                      <DownloadIcon fontSize="small" />
                      <DocumentContainer>
                        <Typography variant="subtitle2">{file.file_title}</Typography>
                        <Typography variant="body2" color="textSecondary">
                          ({file.file_name})
                        </Typography>
                      </DocumentContainer>
                      <Typography variant="body2" color="textSecondary">
                        {DateUtils.formatDate(DateUtils.parseDateGraphQL(file.upload_date))}
                      </Typography>
                    </S.Card.Content>
                  </DownloadLinkStyled>
                  <S.Card.Actions>
                    {canEdit && (
                      <IconButton color="primary" size="large" onClick={() => onDeleteFile(file.uuid)}>
                        <DeleteIcon />
                      </IconButton>
                    )}
                  </S.Card.Actions>
                </CardStyled>
              ))
            ) : (
              <CardStyled $nonClickable>
                <S.Card.Content>
                  <DefaultSectionTypography noEntriesMessageKey="label.file.not.available" $standAlone={true} />
                </S.Card.Content>
              </CardStyled>
            )}
          </ReadOnlySelection>
          <ConfirmationModalDialog
            open={fileDeleteConfirmationOpen}
            onCancel={onCancelDeleteFile}
            onConfirm={onConfirmDeleteFile}
          >
            {getMessage('label.delete.confirm')}
          </ConfirmationModalDialog>
        </>
      ) : (
        <LoadingContainer>
          <CircularProgress />
        </LoadingContainer>
      )}
    </>
  )
}
