import { ReactElement, useRef } from 'react'
import { Milestone, Mutation_Root } from 'src/@types/graphql'
import { useMessageSource } from 'src/i18n/useMessageSource'
import { SummaryCommentEditForm, SummaryCommentFormValues } from 'src/screens/shared/common/SummaryCommentEditForm'
import { updateMilestoneSummaryCommentById } from 'src/screens/shared/implementation/details/milestoneQueries'
import { SaveAndBackButton, SaveButton } from 'src/shared/button/Buttons'
import { MILESTONE_TYPE_TYPE } from 'src/shared/constants/milestone-constants'
import { PageLayout } from 'src/shared/layout/PageLayout'
import { ScreenLayout } from 'src/shared/layout/ScreenLayout'
import { HelpAndInstructions } from 'src/shared/presentation/HelpAndInstructions'
import { useNotificationService } from 'src/shared/utils/NotificationService'
import { useClient } from 'urql'

type originType = 'SAVE' | 'SAVE_AND_BACK'

interface Props {
  loading: boolean
  milestoneSummaryData: Milestone | undefined
  setMilestoneSummaryData: (v: Milestone) => void
  milestoneId: number
  milestoneType: MILESTONE_TYPE_TYPE
  process: string
  onBack: () => void
}

export const MilestoneSummaryCommentEditForm = ({
  loading,
  milestoneSummaryData,
  milestoneId,
  milestoneType,
  process,
  onBack,
  setMilestoneSummaryData,
}: Props): ReactElement => {
  const { getMessage } = useMessageSource()

  const originRef = useRef<originType>('SAVE')
  const urqlClient = useClient()
  const notificationService = useNotificationService()

  const onSubmit = (origin: originType) => (_: any) => {
    // trigger submit event
    if (originRef) {
      originRef.current = origin
    }
    document.getElementById('edit-form')?.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }))
  }

  const updateHandler = async (values: SummaryCommentFormValues) => {
    const { data, error } = await urqlClient
      .mutation<{ update_milestone: Mutation_Root['update_milestone'] }>(updateMilestoneSummaryCommentById, {
        milestoneId,
        comment: values.comment,
      })
      .toPromise()

    if (error || data?.update_milestone?.affected_rows !== 1) {
      notificationService.operationFailed()
    } else {
      setMilestoneSummaryData({ ...milestoneSummaryData, comment: values.comment } as Milestone)
      notificationService.changesSaved()
      if (originRef.current === 'SAVE_AND_BACK') {
        onBack()
      }
    }
  }

  return (
    <>
      <ScreenLayout
        title={getMessage('label.title.edit.comments')}
        onBack={onBack}
        hasSecondLevelNavigation={false}
        actions={
          <>
            <SaveAndBackButton origin="header" onClick={onSubmit('SAVE_AND_BACK')} />
            <SaveButton origin="header" onClick={onSubmit('SAVE')} />
          </>
        }
      >
        <PageLayout>
          <>
            {!loading && milestoneSummaryData && (
              <>
                <HelpAndInstructions
                  labelKey={`label.help.milestone.summary.comments.${process}.${milestoneType}`}
                  defaultExpansion
                />
                <SummaryCommentEditForm comment={milestoneSummaryData.comment ?? null} onSave={updateHandler} />
              </>
            )}
          </>
        </PageLayout>
      </ScreenLayout>
    </>
  )
}
