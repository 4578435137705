import { ReactElement, useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Maybe, Mutation_Root, Project_Base_Bool_Exp, Query_Root } from 'src/@types/graphql'
import { useMessageSource } from 'src/i18n/useMessageSource'
import { resolveApplicationDetailsRoot } from 'src/routing/routing-utils'
import {
  queryApplicationCommentByProjectBaseId,
  saveProjectApplicationSummaryComment,
} from 'src/screens/shared/application/details/summary/applicationSummaryQueries'
import { SummaryCommentEditForm, SummaryCommentFormValues } from 'src/screens/shared/common/SummaryCommentEditForm'
import { usePermissionsForProjectApplication } from 'src/service/security/PermissionHook'
import { SaveAndBackButton, SaveButton } from 'src/shared/button/Buttons'
import { PROJECT } from 'src/shared/constants/constants'
import { PageLayout } from 'src/shared/layout/PageLayout'
import { ScreenLayout } from 'src/shared/layout/ScreenLayout'
import { NotAuthorized } from 'src/shared/not-authorized/NotAuthorized'
import { HelpAndInstructions } from 'src/shared/presentation/HelpAndInstructions'
import { useDelayedNavigate } from 'src/shared/utils/hooks/navigation-hooks'
import { useNotificationService } from 'src/shared/utils/NotificationService'
import { resolveProcessToLowerCase, Utils } from 'src/shared/utils/Utils'
import { useClient } from 'urql'

type originType = 'SAVE' | 'SAVE_AND_BACK'

interface Props {
  baseUrl: '/pf-kap' | '/pf-pgv'
}

// TODO: I want to export this and reuse it in all Summary Comment Edit Pages. Please suggest a better naming :)
type SummaryComment = {
  comment: Maybe<string>
  version: Maybe<number>
}

export const ApplicationSummaryEditPage = ({ baseUrl }: Props): ReactElement => {
  const { projectId } = useParams()
  const { getMessage } = useMessageSource()
  const notificationService = useNotificationService()
  const navigate = useDelayedNavigate()
  const urqlClient = useClient()
  const originRef = useRef<originType>('SAVE')
  const process = resolveProcessToLowerCase(baseUrl)
  const projectType = Utils.resolveProcess(baseUrl)
  const [applicationSummaryComment, setApplicationSummaryComment] = useState<SummaryComment>()

  const {
    loading,
    canEdit,
    metadata: { projectBaseId },
  } = usePermissionsForProjectApplication(projectType, parseInt(projectId as string))

  useEffect(() => {
    const initFormValues = async () => {
      const projectPfKapBaseWhereClause: Project_Base_Bool_Exp = {
        pf_kap_projects: {
          id: {
            _eq: projectBaseId,
          },
        },
      }

      const projectPfPgvBaseWhereClause: Project_Base_Bool_Exp = {
        pf_pgv_projects: {
          id: {
            _eq: projectBaseId,
          },
        },
      }

      const projectBaseQuery: Project_Base_Bool_Exp = {
        ...(projectType === PROJECT.PF_KAP && projectPfKapBaseWhereClause),
        ...(projectType === PROJECT.PF_PGV && projectPfPgvBaseWhereClause),
      }

      const { data } = await urqlClient
        .query<{ dossier: Query_Root['dossier'] }>(queryApplicationCommentByProjectBaseId, { projectBaseQuery })
        .toPromise()

      if (data) {
        const applicationSummaryComment = {
          comment: data.dossier[0]?.project_bases?.[0]?.project_application_decisions?.[0]?.application_comment ?? null,
          version: data.dossier[0]?.project_bases?.[0]?.project_application_decisions?.[0]?.version ?? null,
        } as SummaryComment
        setApplicationSummaryComment(applicationSummaryComment)
      }
    }
    initFormValues()
  }, [projectBaseId, projectType, urqlClient])

  const onSubmit = (origin: originType) => (_: any) => {
    // trigger submit event
    if (originRef) {
      originRef.current = origin
    }
    document.getElementById('edit-form')?.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }))
  }

  const updateHandler = async (values: SummaryCommentFormValues) => {
    const projectApplicationDecision = {
      project_base_id: projectBaseId,
      application_comment: values.comment,
    }
    const version = applicationSummaryComment?.version ?? 0

    const { error, data } = await urqlClient
      .mutation<{ insert_project_application_decision: Mutation_Root['insert_project_application_decision'] }>(
        saveProjectApplicationSummaryComment,
        { projectBaseId, version, projectApplicationDecision },
      )
      .toPromise()

    if (error || data?.insert_project_application_decision?.affected_rows !== 1) {
      notificationService.operationFailed()
    } else {
      const newVersion = data!.insert_project_application_decision?.returning[0].version
      const newApplicationSummaryCommentValues = { ...values, version: newVersion } as SummaryComment
      setApplicationSummaryComment(newApplicationSummaryCommentValues)
      notificationService.changesSaved()
      if (originRef.current === 'SAVE_AND_BACK') {
        navigate(resolveApplicationDetailsRoot(baseUrl).nested.Summary.params({ projectId }))
      }
    }
  }

  if (!loading && !canEdit) {
    return <NotAuthorized />
  }

  return (
    <ScreenLayout
      title={getMessage('label.title.edit.comments')}
      onBack={() => {
        navigate(resolveApplicationDetailsRoot(baseUrl).nested.Summary.params({ projectId }))
      }}
      hasSecondLevelNavigation={false}
      actions={
        <>
          <SaveAndBackButton origin="header" onClick={onSubmit('SAVE_AND_BACK')} />
          <SaveButton origin="header" onClick={onSubmit('SAVE')} />
        </>
      }
    >
      <PageLayout>
        <>
          {!loading && applicationSummaryComment && (
            <>
              <HelpAndInstructions labelKey={`label.help.application.summary.comments.${process}`} defaultExpansion />
              <SummaryCommentEditForm comment={applicationSummaryComment.comment} onSave={updateHandler} />
            </>
          )}
        </>
      </PageLayout>
    </ScreenLayout>
  )
}
