import { Stack } from '@mui/material'
import { useEffect, useState } from 'react'
import { Form } from 'react-final-form'
import { useParams } from 'react-router'
import { Maybe, Mutation_Root, Query_Root } from 'src/@types/graphql'
import { useMessageSource } from 'src/i18n/useMessageSource'
import { ROUTES } from 'src/routing/routes'
import {
  fetchSuccessFactorAnalysisById,
  updateSuccessFactorAnalysisById,
} from 'src/screens/success-factor-tool/analysis/details/successFactorAnalysisQueries'
import { usePermissionsForSuccessFactors } from 'src/service/security/PermissionHook'
import { SaveAndBackButton, SaveButton } from 'src/shared/button/Buttons'
import { TEXT_LENGTH } from 'src/shared/constants/constants'
import { HtmlEditorField } from 'src/shared/form/control/HtmlEditorField'
import { TextField } from 'src/shared/form/control/TextField'
import { DirtyFormSpy } from 'src/shared/form/dirty/DirtyFormSpy'
import { composeValidators, maxChar, required } from 'src/shared/form/validation/validators'
import { PageLayout } from 'src/shared/layout/PageLayout'
import { ScreenLayout } from 'src/shared/layout/ScreenLayout'
import { NotAuthorized } from 'src/shared/not-authorized/NotAuthorized'
import { HelpAndInstructions } from 'src/shared/presentation/HelpAndInstructions'
import { Section } from 'src/shared/presentation/Section'
import { useSidebarAPI } from 'src/shared/sidebar/SidebarAwareContext'
import { useDelayedNavigate } from 'src/shared/utils/hooks/navigation-hooks'
import { HtmlSanitizer } from 'src/shared/utils/HtmlSanitizer'
import { useNotificationService } from 'src/shared/utils/NotificationService'
import { useClient } from 'urql'

interface EditFormValues {
  title: string
  responsibility?: Maybe<string>
  further_information?: Maybe<string>
}

export const AnalysisCoverEditPage = () => {
  const { getMessage } = useMessageSource()
  const { analysisId } = useParams()
  const notificationService = useNotificationService()
  const navigate = useDelayedNavigate()
  const urqlClient = useClient()
  const sidebarAPI = useSidebarAPI()
  const { canEdit } = usePermissionsForSuccessFactors()

  const id = parseInt(analysisId as string)
  const [initialValues, setInitialValues] = useState<EditFormValues>()

  useEffect(() => {
    const initData = async () => {
      const { data } = await urqlClient
        .query<{ success_factor_analysis_by_pk: Query_Root['success_factor_analysis_by_pk'] }, { id: number }>(
          fetchSuccessFactorAnalysisById,
          { id },
        )
        .toPromise()

      if (data && data?.success_factor_analysis_by_pk) {
        setInitialValues({
          title: data?.success_factor_analysis_by_pk?.title,
          responsibility: data?.success_factor_analysis_by_pk?.responsibility,
          further_information: data?.success_factor_analysis_by_pk?.further_information,
        })
      } else {
        notificationService.operationFailed()
      }
    }

    initData()
  }, [id, urqlClient, notificationService])

  const onBack = () => {
    navigate(ROUTES.SuccessFactorAnalysisRoot.nested.AnalysisCover.params({ analysisId }))
  }

  let submit: any = () => {}
  let formValid = false

  const onSave = (event: any) => {
    submit(event)
  }

  const onSaveAndBack = async (event: any) => {
    try {
      await submit(event)
      formValid && onBack()
    } catch {
      // do nothing
    }
  }

  const handleSubmitLocal = async (values: EditFormValues) => {
    const { title, responsibility, further_information } = values
    const { data } = await urqlClient
      .mutation<
        { update_success_factor_analysis: Mutation_Root['update_success_factor_analysis'] },
        { id: number; title: string; responsibility: string; further_information: string }
      >(updateSuccessFactorAnalysisById, {
        id,
        title: title,
        responsibility: HtmlSanitizer.sanitize(responsibility ?? ''),
        further_information: HtmlSanitizer.sanitize(further_information ?? ''),
      })
      .toPromise()

    if (data && data?.update_success_factor_analysis && data?.update_success_factor_analysis?.affected_rows > 0) {
      notificationService.changesSaved()
      setInitialValues(values)
      sidebarAPI.refetchSidebarData()
    } else {
      notificationService.operationFailed()
    }
  }

  if (!canEdit) {
    return <NotAuthorized inEditPage />
  }

  return (
    <>
      <ScreenLayout
        title={getMessage('label.success.factors.analysis.details.edit')}
        onBack={onBack}
        hasSecondLevelNavigation={false}
        actions={
          <>
            <SaveAndBackButton origin="header" onClick={onSaveAndBack} />
            <SaveButton origin="header" onClick={onSave} />
          </>
        }
      >
        <PageLayout>
          <>
            {initialValues && (
              <>
                <Form<EditFormValues>
                  initialValues={initialValues}
                  onSubmit={handleSubmitLocal}
                  render={({ handleSubmit, valid }) => {
                    submit = handleSubmit
                    formValid = valid
                    return (
                      <form onSubmit={handleSubmit} noValidate id="edit-basic-information-form">
                        <Section
                          helpAndInstructions={
                            <HelpAndInstructions
                              labelKey="label.help.success.factor.cover.analysis.information"
                              defaultExpansion
                            />
                          }
                        >
                          <Stack spacing={2}>
                            <TextField
                              label={getMessage('label.title')}
                              name="title"
                              validate={composeValidators(required(), maxChar(TEXT_LENGTH.M))}
                            />
                            <HtmlEditorField
                              label={getMessage('label.success.factor.responsibility')}
                              name="responsibility"
                              validate={maxChar(TEXT_LENGTH.XL)}
                            />

                            <HtmlEditorField
                              label={getMessage('label.success.factor.further.information')}
                              name="further_information"
                              validate={maxChar(TEXT_LENGTH.XL)}
                            />
                          </Stack>
                          <DirtyFormSpy />
                        </Section>
                      </form>
                    )
                  }}
                />
              </>
            )}
          </>
        </PageLayout>
      </ScreenLayout>
    </>
  )
}
