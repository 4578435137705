import { ReactElement, useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Mutation_Root, Query_Root } from 'src/@types/graphql'
import { useMessageSource } from 'src/i18n/useMessageSource'
import { resolveApplicationAssessmentDetailsRoot } from 'src/routing/routing-utils'
import {
  queryAssessmentSummaryCommentById,
  updateAssessmentSummaryCommentById,
} from 'src/screens/shared/application/assessment/details/summary/assessmentSummaryQueries'
import { SummaryCommentEditForm, SummaryCommentFormValues } from 'src/screens/shared/common/SummaryCommentEditForm'
import { usePermissionsForProjectAssessment } from 'src/service/security/PermissionHook'
import { SaveAndBackButton, SaveButton } from 'src/shared/button/Buttons'
import { PageLayout } from 'src/shared/layout/PageLayout'
import { ScreenLayout } from 'src/shared/layout/ScreenLayout'
import { NotAuthorized } from 'src/shared/not-authorized/NotAuthorized'
import { HelpAndInstructions } from 'src/shared/presentation/HelpAndInstructions'
import { useDelayedNavigate } from 'src/shared/utils/hooks/navigation-hooks'
import { useNotificationService } from 'src/shared/utils/NotificationService'
import { resolveProcessToLowerCase, Utils } from 'src/shared/utils/Utils'
import { useClient } from 'urql'

type originType = 'SAVE' | 'SAVE_AND_BACK'

interface Props {
  baseUrl: '/pf-kap' | '/pf-pgv'
}

export const ApplicationAssessmentSummaryCommentEditPage = ({ baseUrl }: Props): ReactElement => {
  const { projectId, assessmentId } = useParams()
  const { getMessage } = useMessageSource()
  const navigate = useDelayedNavigate()
  const process = resolveProcessToLowerCase(baseUrl)
  const projectType = Utils.resolveProcess(baseUrl)
  const { loading, canEdit } = usePermissionsForProjectAssessment(
    projectType,
    parseInt(projectId as string),
    parseInt(assessmentId as string),
  )
  const originRef = useRef<originType>('SAVE')
  const urqlClient = useClient()
  const notificationService = useNotificationService()
  const [assessmentSummaryComment, setAssessmentSummaryComment] = useState<any>()

  useEffect(() => {
    const initFormValues = async () => {
      const { data } = await urqlClient
        .query<{ project_assessment: Query_Root['project_assessment'] }>(queryAssessmentSummaryCommentById, {
          assessmentId,
        })
        .toPromise()

      if (data && data.project_assessment[0]) {
        const assessment = data.project_assessment[0]
        setAssessmentSummaryComment({
          comment: assessment?.summary_comment || null,
          version: assessment?.version || 0,
        })
      }
    }

    initFormValues()
  }, [assessmentId, urqlClient])

  const onSubmit = (origin: originType) => (_: any) => {
    // trigger submit event
    if (originRef) {
      originRef.current = origin
    }
    document.getElementById('edit-form')?.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }))
  }

  const updateHandler = async (values: SummaryCommentFormValues) => {
    const updateObject = {
      assessmentId: assessmentId,
      comment: values.comment,
      version: assessmentSummaryComment.version,
    }

    const { data, error } = await urqlClient
      .mutation<{ update_project_assessment: Mutation_Root['update_project_assessment'] }>(
        updateAssessmentSummaryCommentById,
        updateObject,
      )
      .toPromise()

    if (error || data?.update_project_assessment?.affected_rows !== 1) {
      notificationService.operationFailed()
    } else {
      const newFormData = { ...values, version: data.update_project_assessment.returning[0].version }
      setAssessmentSummaryComment(newFormData)
      notificationService.changesSaved()
      if (originRef.current === 'SAVE_AND_BACK') {
        navigate(resolveApplicationAssessmentDetailsRoot(baseUrl).nested.Summary.params({ projectId, assessmentId }))
      }
    }
  }

  if (!loading && !canEdit) {
    return <NotAuthorized />
  }

  return (
    <ScreenLayout
      title={getMessage('label.title.edit.comments')}
      onBack={() => {
        navigate(resolveApplicationAssessmentDetailsRoot(baseUrl).nested.Summary.params({ projectId, assessmentId }))
      }}
      hasSecondLevelNavigation={false}
      actions={
        <>
          <SaveAndBackButton origin="header" onClick={onSubmit('SAVE_AND_BACK')} />
          <SaveButton origin="header" onClick={onSubmit('SAVE')} />
        </>
      }
    >
      <PageLayout>
        <>
          {!loading && assessmentSummaryComment && (
            <>
              <HelpAndInstructions labelKey={`label.help.assessment.summary.comments.${process}`} defaultExpansion />
              <SummaryCommentEditForm comment={assessmentSummaryComment.comment} onSave={updateHandler} />
            </>
          )}
        </>
      </PageLayout>
    </ScreenLayout>
  )
}
