import { Alert, Box, Stack } from '@mui/material'
import { ReactElement, useState } from 'react'
import { useMessageSource } from 'src/i18n/useMessageSource'
import { LEVEL, LEVEL_TYPE } from 'src/shared/constants/constants'
import { KapMeasureAndValid } from 'src/shared/constants/milestone-constants'
import { HelpAndInstructions } from 'src/shared/presentation/HelpAndInstructions'
import { Section } from 'src/shared/presentation/Section'
import { S } from 'src/shared/styled/S'
import styled from 'styled-components/macro'

const AlertInfoStyled = styled(Alert)`
  background-color: ${({ theme }) => theme.colors.common.white};
  .MuiAlert-message {
    color: ${({ theme }) => theme.colors.text.secondary};
  }

  & .MuiSvgIcon-root {
    color: ${({ theme }) => theme.colors.text.disabled};
  }
`

interface ChildrenProps {
  activeLevels: LEVEL_TYPE[]
  communicationMeasures: KapMeasureAndValid[]
  interventionMeasures: KapMeasureAndValid[]
  networkMeasures: KapMeasureAndValid[]
  policyMeasures: KapMeasureAndValid[]
}

interface Props {
  measures: KapMeasureAndValid[]
  helpAndInstructionsKey: string
  actionButton?: React.ReactNode
  children: ({
    activeLevels,
    communicationMeasures,
    interventionMeasures,
    networkMeasures,
    policyMeasures,
  }: ChildrenProps) => ReactElement
}

export const KapMeasuresSection = ({
  measures,
  helpAndInstructionsKey,
  actionButton,
  children,
}: Props): ReactElement => {
  const { getMessage } = useMessageSource()

  const [activeLevels, setActiveLevels] = useState<LEVEL_TYPE[]>([
    LEVEL.COMMUNICATION,
    LEVEL.INTERVENTION,
    LEVEL.NETWORK,
    LEVEL.POLICY,
  ])

  const toggleLevel = (level: LEVEL_TYPE) => {
    if (activeLevels.includes(level)) {
      // logic to hide a level
      setActiveLevels(activeLevels.filter((activeLevel) => activeLevel !== level))
    } else {
      // logic to make level visible
      setActiveLevels([...activeLevels, level])
    }
  }

  const communicationMeasures = measures?.filter((measure: KapMeasureAndValid) => measure.level === LEVEL.COMMUNICATION)
  const interventionMeasures = measures?.filter((measure: KapMeasureAndValid) => measure.level === LEVEL.INTERVENTION)
  const networkMeasures = measures?.filter((measure: KapMeasureAndValid) => measure.level === LEVEL.NETWORK)
  const policyMeasures = measures?.filter((measure: KapMeasureAndValid) => measure.level === LEVEL.POLICY)

  return (
    <>
      <Section
        id="measures"
        title={getMessage('label.project.measures')}
        helpAndInstructions={<HelpAndInstructions labelKey={helpAndInstructionsKey} />}
        actionButton={actionButton}
      >
        <>
          <Box mt={2} mb={4}>
            <S.Chip.Clickable
              label={getMessage(`label.level.${LEVEL.INTERVENTION}`)}
              variant="outlined"
              $selected={activeLevels.includes(LEVEL.INTERVENTION)}
              onClick={() => {
                toggleLevel(LEVEL.INTERVENTION)
              }}
              clickable
            />
            <S.Chip.Clickable
              label={getMessage(`label.level.${LEVEL.POLICY}`)}
              variant="outlined"
              $selected={activeLevels.includes(LEVEL.POLICY)}
              onClick={() => {
                toggleLevel(LEVEL.POLICY)
              }}
              clickable
            />
            <S.Chip.Clickable
              label={getMessage(`label.level.${LEVEL.NETWORK}`)}
              variant="outlined"
              $selected={activeLevels.includes(LEVEL.NETWORK)}
              onClick={() => {
                toggleLevel(LEVEL.NETWORK)
              }}
              clickable
            />
            <S.Chip.Clickable
              label={getMessage(`label.level.${LEVEL.COMMUNICATION}`)}
              variant="outlined"
              $selected={activeLevels.includes(LEVEL.COMMUNICATION)}
              onClick={() => {
                toggleLevel(LEVEL.COMMUNICATION)
              }}
              clickable
            />
          </Box>
          <Stack spacing={5}>
            <>
              {children({ activeLevels, communicationMeasures, interventionMeasures, networkMeasures, policyMeasures })}
              {activeLevels.length === 0 && (
                <AlertInfoStyled severity="info">{getMessage('label.kap.measures.no.levels.selected')}</AlertInfoStyled>
              )}
            </>
          </Stack>
        </>
      </Section>
    </>
  )
}
