import { ReactElement } from 'react'
import { useParams } from 'react-router'
import { Feature_Base_Bool_Exp, Feature_Config_Bool_Exp } from 'src/@types/graphql'
import { useMessageSource } from 'src/i18n/useMessageSource'
import { ROUTES } from 'src/routing/routes'
import {
  fetchKapMeasureByIdWithFeaturesQuery,
  fetchKapMeasureShortTitleAndLevelByIdQuery,
} from 'src/screens/kap/program/details/measures/kapMeasuresQueries'
import { KapMeasureFeaturesKpiGrid } from 'src/screens/kap/program/details/measures/measure/features/KapMeasureFeaturesKpiGrid'
import { FeatureBase } from 'src/screens/shared/feature/FeatureBase'
import { usePermissionsForKapProgram } from 'src/service/security/PermissionHook'
import { PROJECT } from 'src/shared/constants/constants'
import { PageLayout } from 'src/shared/layout/PageLayout'
import { ScreenLayout } from 'src/shared/layout/ScreenLayout'
import { Section } from 'src/shared/presentation/Section'
import { useDelayedNavigate } from 'src/shared/utils/hooks/navigation-hooks'
import { useNotificationService } from 'src/shared/utils/NotificationService'
import { useQuery } from 'urql'

export const KapMeasureFeaturesPage = (): ReactElement => {
  const { getMessage } = useMessageSource()
  const { programId, measureId } = useParams()
  const { canEditMeasures, loading } = usePermissionsForKapProgram(parseInt(programId as string))
  const navigate = useDelayedNavigate()
  const notificationService = useNotificationService()

  const [{ data, error, fetching }] = useQuery({
    query: fetchKapMeasureByIdWithFeaturesQuery,
    variables: { measureId: measureId },
  })

  const [{ data: measureShortTitleData, error: measureShortTitleError }] = useQuery({
    query: fetchKapMeasureShortTitleAndLevelByIdQuery,
    variables: { measureId: measureId },
  })

  if (error || measureShortTitleError) {
    notificationService.operationFailed()
  }

  const kapMeasure = data?.kap_measure_by_pk
  const pageTitle = measureShortTitleData
    ? `${getMessage('label.measure')}: ${measureShortTitleData.kap_measure_by_pk?.short_title}`
    : ''

  const measuresBaseWhereClause: Feature_Base_Bool_Exp = {
    kap_measures: {
      id: {
        _eq: parseInt(measureId as string),
      },
    },
  }

  const measuresLevelProcessWhereClause: Feature_Config_Bool_Exp = {
    _and: [{ levels: { _contains: kapMeasure?.level } }, { processes: { _contains: PROJECT.KAP } }],
  }

  const handleEditFeatureTypeSection = (featureTypeId: number) => {
    navigate(ROUTES.KapMeasureDetailsRoot.nested.FeaturesEdit.params({ programId, measureId, featureTypeId }))
  }

  const backHandler = () => {
    navigate(ROUTES.KapDetailsRoot.nested.Measures.params({ programId }))
  }

  return (
    <>
      <ScreenLayout title={pageTitle} onBack={backHandler}>
        <PageLayout>
          {!loading && !fetching && (
            <>
              <FeatureBase
                featureBaseWhere={measuresBaseWhereClause}
                featureConfigWhere={measuresLevelProcessWhereClause}
                canEdit={canEditMeasures}
                onEditFeature={handleEditFeatureTypeSection}
                kpiSection={
                  <Section id="measure-features" async title={getMessage('label.program.kpis')}>
                    <KapMeasureFeaturesKpiGrid measureLevel={kapMeasure.level} />
                  </Section>
                }
                relatedEntity={kapMeasure}
              />
            </>
          )}
        </PageLayout>
      </ScreenLayout>
    </>
  )
}
