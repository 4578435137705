import {
  Card,
  CardContent,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material'
import { ReactElement } from 'react'
import { Query_Root } from 'src/@types/graphql'
import { useMessageSource } from 'src/i18n/useMessageSource'
import { resolveApplicationDetailsRoot } from 'src/routing/routing-utils'
import { useResolveApplicationInformationData } from 'src/screens/shared/application/details/application-information/ApplicationInformationService'
import { usePermissionsForProjectApplication } from 'src/service/security/PermissionHook'
import { EditButton } from 'src/shared/button/Buttons'
import { ENTITY_TYPE, FILE_TYPE } from 'src/shared/constants/constants'
import { DownloadIcon } from 'src/shared/icons/Icons'
import { PageLayout } from 'src/shared/layout/PageLayout'
import { ScreenLayout } from 'src/shared/layout/ScreenLayout'
import { ProjectExportMenu } from 'src/shared/menu/ProjectExportMenu'
import { queryFiles } from 'src/shared/presentation/fileQueries'
import { HelpAndInstructions } from 'src/shared/presentation/HelpAndInstructions'
import { ReadOnlySelection } from 'src/shared/presentation/ReadOnly'
import { Section } from 'src/shared/presentation/Section'
import { DateUtils } from 'src/shared/utils/DateUtils'
import { useDelayedNavigate } from 'src/shared/utils/hooks/navigation-hooks'
import { Utils } from 'src/shared/utils/Utils'
import styled from 'styled-components/macro'
import { useQuery } from 'urql'

interface Props {
  projectId: number
  baseUrl: '/pf-kap' | '/pf-pgv'
}

const ListItemButtonStyled = styled(ListItemButton)`
  border-radius: 4px;
  background-color: ${({ theme }) => theme.colors.action.hover};
  &:hover {
    background-color: ${({ theme }) => theme.colors.action.focus};
    & > .MuiListItemIcon-root {
      color: ${({ theme }) => theme.colors.text.primary};
    }
  }
`

export const ApplicationInformation = ({ baseUrl, projectId }: Props): ReactElement => {
  const { getMessage } = useMessageSource()
  const navigate = useDelayedNavigate()
  const process = Utils.resolveProcessToLowerCase(baseUrl)
  const projectType = Utils.resolveProcess(baseUrl)
  const {
    loading,
    canEdit,
    metadata: { projectBaseId },
  } = usePermissionsForProjectApplication(projectType, projectId)

  const fileType = FILE_TYPE.PROJECT_APPLICATION_PDF
  const entityType = ENTITY_TYPE.PROJECT_BASE
  const entityId = projectBaseId as number

  const [{ data, fetching }] = useQuery<
    { file: Query_Root['file'] },
    {
      fileType: string
      entityType: string
      entityId: number
    }
  >({
    query: queryFiles,
    variables: { fileType, entityType, entityId },
  })

  const { applicationInformation } = useResolveApplicationInformationData(projectBaseId)

  const handleEdit = () => {
    navigate(resolveApplicationDetailsRoot(baseUrl).nested.InformationEdit.params({ projectId }))
  }

  return (
    <ScreenLayout
      title={getMessage('label.navigation.application.details')}
      actions={<ProjectExportMenu process={projectType} entityId={projectBaseId as number} />}
    >
      <PageLayout>
        <>
          {!loading && !fetching && applicationInformation && (
            <Section
              id="information"
              title={getMessage('label.application.info')}
              actionButton={<EditButton origin="content" color="primary" hidden={!canEdit} onClick={handleEdit} />}
              helpAndInstructions={
                <HelpAndInstructions labelKey={`label.help.basic.information.application.information.${process}`} />
              }
            >
              <Card>
                <CardContent>
                  <ReadOnlySelection text={getMessage('label.funding.round')}>
                    {applicationInformation.fundingRoundId &&
                      `${getMessage('label.round.id.character')}${applicationInformation.fundingRoundId} ${
                        applicationInformation.fundingRoundName
                      }`}
                  </ReadOnlySelection>
                  <ReadOnlySelection text={getMessage('label.latest.decision.date')}>
                    {applicationInformation.fundingRoundDecisionDate &&
                      DateUtils.parseAndFormatDate(applicationInformation.fundingRoundDecisionDate)}
                  </ReadOnlySelection>
                  <ReadOnlySelection text={getMessage('label.application.decision')}>
                    {applicationInformation.applicationDecision &&
                      getMessage(`label.recommendation.${applicationInformation.applicationDecision}`)}
                  </ReadOnlySelection>
                  <List disablePadding>
                    <ListItem disablePadding>
                      <ListItemButtonStyled
                        disabled={data?.file?.length === 0 ?? true}
                        onClick={() => Utils.downloadFile(data?.file?.[0].uuid)}
                      >
                        <ListItemIcon sx={{ minWidth: '40px' }}>
                          <DownloadIcon />
                        </ListItemIcon>
                        <ListItemText sx={{ flexGrow: 'initial' }}>
                          {getMessage('button.download.application')}
                        </ListItemText>
                        <Typography color="textSecondary">&nbsp;(pdf)</Typography>
                      </ListItemButtonStyled>
                    </ListItem>
                  </List>
                </CardContent>
              </Card>
            </Section>
          )}
        </>
      </PageLayout>
    </ScreenLayout>
  )
}
