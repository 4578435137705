import { ReactElement } from 'react'
import { useParams } from 'react-router'
import { ApplicationAssessmentExclusion } from 'src/screens/shared/application/assessment/details/exclusion/ApplicationAssessmentExclusion'

export const PfKapExclusionPage = (): ReactElement => {
  const { projectId, assessmentId } = useParams()
  const pfKapProjectId = parseInt(projectId as string)
  const pfKapAssessmentId = parseInt(assessmentId as string)

  return (
    <ApplicationAssessmentExclusion baseUrl="/pf-kap" projectId={pfKapProjectId} assessmentId={pfKapAssessmentId} />
  )
}
