import { ReactElement } from 'react'
import { useParams } from 'react-router'
import { StartingPointAndVision } from 'src/screens/shared/project/details/starting-point-and-vision/StartingPointAndVision'

export const PfKapStartingPointAndVisionPage = (): ReactElement => {
  const { projectId } = useParams()
  const pf_kap_id = parseInt(projectId as string)

  return <StartingPointAndVision projectId={pf_kap_id} baseUrl="/pf-kap" />
}
