import { Box, Tooltip } from '@mui/material'
import { GridColDef, GridRenderEditCellParams, GridRowId, useGridApiRef } from '@mui/x-data-grid-pro'
import combinedQuery from 'graphql-combine-query'
import { groupBy, sortBy, sumBy } from 'lodash'
import { useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router'
import { InternationalizedObject } from 'src/@types'
import { Feature_Config_Bool_Exp, Feature_Value, Kap_Measure_Kpi_Annual_Planning, Query_Root } from 'src/@types/graphql'
import { useMessageSource } from 'src/i18n/useMessageSource'
import { fetchFeatureBaseValuesWithKPIsAndAnnualPlanForKapMeasureQuery } from 'src/screens/kap/implementation/milestone/kapMilestoneQueries'
import { queryFeatureConfigs } from 'src/screens/shared/feature/featureBaseQueries'
import { RowData } from 'src/screens/shared/project/details/project-features/ProjectFeatureKpiGrid'
import { LEVEL_TYPE, PROJECT } from 'src/shared/constants/constants'
import { MILESTONE_TYPE } from 'src/shared/constants/milestone-constants'
import { NumberInput } from 'src/shared/form/grid/NumberInput'
import { EditableColumnHeader } from 'src/shared/grid/EditableColumnHeader'
import { DefaultSectionTypography } from 'src/shared/presentation/DefaultSectionTypography'
import { S } from 'src/shared/styled/S'
import { DataGridUtils } from 'src/shared/utils/DataGridUtils'
import { useGridTranslateHook } from 'src/shared/utils/hooks/grid-translate-hook'
import { useError } from 'src/shared/utils/hooks/page-loading-error-hook'
import { Utils } from 'src/shared/utils/Utils'
import { useUserLocale } from 'src/user/UserContext'
import { useClient } from 'urql'

interface Props {
  editable: boolean
  measureId: number
  measureLevel: LEVEL_TYPE
  onCellEdit?: (initial: boolean) => void
  reportYear?: number
  gridApiRef?: any
}

export const AnnualPlanFeatureKpiGrid = ({
  editable,
  measureId,
  measureLevel,
  onCellEdit,
  reportYear,
  gridApiRef,
}: Props) => {
  const { milestoneId } = useParams()
  const milestoneIdParsed = parseInt(milestoneId as string)

  const { getMessage } = useMessageSource()
  const language = useUserLocale()
  const gridTranslations = useGridTranslateHook()
  const setError = useError()
  const urqlClient = useClient()

  const localGridApiRef = useGridApiRef()
  const apiRef = editable && gridApiRef ? gridApiRef : localGridApiRef

  const [featureKpis, setFeatureKpis] = useState<
    (Feature_Value & Kap_Measure_Kpi_Annual_Planning & { actualValue: number })[]
  >([])

  useEffect(() => {
    const fetchData = async () => {
      const featureConfigKPIWhere: Feature_Config_Bool_Exp = {
        has_kpi_for_processes: {
          _contains: [PROJECT.KAP],
        },
        has_kpi_for_levels: {
          _contains: [measureLevel],
        },
      }

      const { document, variables } = combinedQuery('FeatureBaseValuesWithKPIsAndFeatureKPIAnnualPlanning')
        .add<{
          kap_measure_kpi_annual_planning: Query_Root['kap_measure_kpi_annual_planning']
          all_kap_measure_kpi_annual_report: Query_Root['kap_measure_kpi_annual_report']
        }>(fetchFeatureBaseValuesWithKPIsAndAnnualPlanForKapMeasureQuery, {
          milestoneId: milestoneIdParsed,
          measureId: measureId,
          featureConfigKPIWhere: featureConfigKPIWhere,
        })
        .add<{ feature_config: Query_Root['feature_config'] }>(queryFeatureConfigs, {
          featureConfigWhere: { processes: { _contains: [PROJECT.KAP] } },
        })

      const { data, error } = await urqlClient
        .query<{
          feature_base: Query_Root['feature_base']
          kap_measure_kpi_annual_planning: Query_Root['kap_measure_kpi_annual_planning']
          all_kap_measure_kpi_annual_report: Query_Root['kap_measure_kpi_annual_report']
          feature_config: Query_Root['feature_config']
        }>(document, variables)
        .toPromise()

      if (data) {
        let features = data.feature_base[0].feature_values
        const featureConfigByIds = groupBy(data?.all_kap_measure_kpi_annual_report, 'feature_config_id')
        const plannings = data.kap_measure_kpi_annual_planning
        const existingFeatures = data.feature_config
          .filter(
            (config) =>
              plannings.some((planning) => planning.feature_config_id === config.id) &&
              !features.some((feature) => feature.feature_config_id === config.id),
          )
          .map((f) => ({
            feature_config_id: f.id,
            feature_config: f,
          })) as Feature_Value[]

        features = features.concat(existingFeatures)
        const mergedValues = Utils.mergeBy(
          { arr: features, key: 'feature_config_id' },
          { arr: plannings, key: 'feature_config_id' },
        )

        const featureKpisWithActualValues = mergedValues.map((m) => ({
          ...m,
          actualValue: sumBy(featureConfigByIds[m.feature_config_id], 'actual_value'),
        }))

        setFeatureKpis(featureKpisWithActualValues)
      } else if (error) {
        setError()
      }
    }

    fetchData()
  }, [urqlClient, setError, measureId, measureLevel, milestoneIdParsed])

  const rows = useMemo(() => {
    const currentRowModels = apiRef.current?.getRowModels?.() ?? new Map()

    const kpisUnsorted = featureKpis.reduce(
      (a, c) => [
        ...a,
        {
          id: c!.feature_config_id,
          featureType: c.feature_config!.feature_type_config.names as InternationalizedObject,
          feature: c.feature_config!.names as InternationalizedObject,
          otherDescription: c.other_description,
          tooltip: c.feature_config!.tooltips as InternationalizedObject,
          planned_value: currentRowModels.get(c!.id)?.planned_value ?? c?.planned_value ?? null,
          actualValue: c?.actualValue ?? '-',
          featureTypeSortNumber: c.feature_config.feature_type_config.sort_number,
          sortNumber: c.feature_config.sort_number,
        } as RowData,
      ],
      [] as RowData[],
    )

    const kpisSorted = sortBy(kpisUnsorted, ['featureTypeSortNumber', 'sortNumber'])

    onCellEdit && onCellEdit(true)

    return kpisSorted
  }, [apiRef, featureKpis, onCellEdit])

  const columns = useMemo(() => {
    const onRifmChange = (api: GridRenderEditCellParams['api'], id: GridRowId, field: string, value: number) => {
      api.setEditCellValue({ id, field, value })
    }

    return [
      {
        field: 'featureType',
        headerName: getMessage('label.feature.type'),
        sortable: false,
        disableColumnMenu: true,
        flex: 0.6,
        disableColumnResize: true,
        valueFormatter: (params) => {
          const value = params.value as InternationalizedObject
          return value?.[language]
        },
      },
      {
        field: 'feature',
        headerName: getMessage('label.feature'),
        sortable: false,
        disableColumnMenu: true,
        flex: 1,
        renderCell: (params) => {
          let featureName = ''
          const value = params.value as InternationalizedObject
          if (value) {
            // for "Other" features display also the corresponding description
            featureName = params.row.otherDescription
              ? `${value[language]}: ${params.row.otherDescription}`
              : value[language]
          }
          return (
            <>
              <S.Span.TextOverflow $withTooltip>{value && featureName}</S.Span.TextOverflow>
              {params.row.tooltip[language] && (
                <Tooltip placement="right" title={params.row.tooltip[language]}>
                  <S.Icons.GridTooltipInfo />
                </Tooltip>
              )}
            </>
          )
        },
      },
      {
        field: 'planned_value',
        headerAlign: 'right',
        editable: editable,
        sortable: false,
        align: 'right',
        disableColumnMenu: true,
        flex: 0.4,
        renderCell: ({ value }) => {
          if (value === null || value === '' || Number.isNaN(value)) {
            return <DefaultSectionTypography $standAlone />
          }
          return <S.Span.TextOverflow>{value?.toLocaleString('de-CH')}</S.Span.TextOverflow>
        },
        renderEditCell: (params) => {
          const { value, id, field, api } = params
          const isValid = DataGridUtils.validateValue(value)
          return (
            <NumberInput
              value={value as number}
              onChange={(val: number) => onRifmChange(api, id, field, val)}
              error={!isValid}
            />
          )
        },
        renderHeader: () => {
          const headerPrimaryTitle = getMessage(`label.milestone.type.${MILESTONE_TYPE.ANNUAL_PLAN}`)
          const headerSecondaryTitle = reportYear ?? ''

          return (
            <EditableColumnHeader headerName={`${headerPrimaryTitle} ${headerSecondaryTitle}`} editMode={editable} />
          )
        },
      },
      {
        field: 'actualValue',
        headerAlign: 'right',
        headerName: getMessage('label.actuals.to.date'),
        sortable: false,
        align: 'right',
        disableColumnMenu: true,
        flex: 0.4,
        hide: editable,
        renderCell: ({ value }) => {
          if (value === null || value === '' || Number.isNaN(value)) {
            return '-'
          }
          return <S.Span.TextOverflow>{value?.toLocaleString('de-CH')}</S.Span.TextOverflow>
        },
      },
    ] as GridColDef[]
  }, [getMessage, language, editable, reportYear])

  return (
    <Box>
      {editable && <S.Form.Label htmlFor="kap-measure-report-grid">{getMessage('label.program.kpis')}</S.Form.Label>}
      <Box id="kap-annual-plan-grid">
        <S.DataGrid.ViewEditMode
          apiRef={apiRef}
          rows={rows}
          columns={columns}
          hideFooter
          autoHeight
          disableColumnReorder
          disableColumnSelector
          disableRowSelectionOnClick
          localeText={gridTranslations}
          onCellEditStop={() => {
            onCellEdit && onCellEdit(false)
          }}
          $viewMode={!editable}
          sx={{ mt: 1 }}
        />
      </Box>
    </Box>
  )
}
