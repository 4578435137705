import { Organization } from 'src/@types/graphql'

export interface OrganizationFormValues {
  entityId?: number
  organizationId?: number
  version?: number
  name: string
  typeId?: number
  department?: string
  address?: string
  additionalAddress?: string
  postalCode?: string
  city?: string
  phone?: string
  email?: string
  website?: string
}

export interface OrganizationType {
  label: string
  value: number
}

export const initialValuesFactory = (entityId?: number, organization?: Organization): OrganizationFormValues => {
  return {
    entityId: entityId,
    organizationId: organization?.id || undefined,
    version: organization?.version || 0,
    name: organization?.name || '',
    typeId: organization?.organization_type?.id || undefined,
    department: organization?.department || undefined,
    address: organization?.address || undefined,
    additionalAddress: organization?.additional_address || undefined,
    postalCode: organization?.postal_code || undefined,
    city: organization?.city || undefined,
    phone: organization?.phone || undefined,
    email: organization?.email || undefined,
    website: organization?.website || undefined,
  }
}
