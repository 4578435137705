import {
  Checkbox as MuiCheckbox,
  FormControlLabel,
  FormControlLabelProps,
  FormGroup,
  FormGroupProps,
} from '@mui/material'
import { ReactElement } from 'react'
import { Field, FieldProps } from 'react-final-form'
import { useMessageSource } from 'src/i18n/useMessageSource'
import { ValidationIcon } from 'src/shared/icons/Icons'
import { S } from 'src/shared/styled/S'
import { TooltipWrapper } from 'src/shared/tooltip/TooltipWrapper'
import styled from 'styled-components/macro'

export interface FilterCheckboxModel {
  label: string
  value: string
  tooltipLabel?: string
}

interface Props {
  data: FilterCheckboxModel[]
  name: string
  tooltip?: boolean
  validate?: any
  initialValue?: string[]
  fieldProps?: Partial<FieldProps<any, any>>
  formGroupProps?: Partial<FormGroupProps>
  formControlLabelProps?: Partial<FormControlLabelProps>
  formValues: string[] | undefined
}

const FormGroupStyled = styled(FormGroup)`
  flex-direction: row;
`

export const FilterCheckboxGroupField = ({
  data,
  name,
  tooltip,
  validate,
  initialValue,
  fieldProps,
  formGroupProps,
  formControlLabelProps,
  formValues,
}: Props): ReactElement => {
  const { getMessage } = useMessageSource()

  return (
    <FormGroupStyled {...formGroupProps}>
      {data.map((item, index) => (
        <S.Input.FilterCheckbox $checked={formValues?.includes(item.value)} key={index}>
          <TooltipWrapper title={item.tooltipLabel ?? ''} condition={tooltip ?? false}>
            <FormControlLabel
              name={name}
              label={getMessage(item.label)}
              value={item.value}
              control={
                <Field
                  type="checkbox"
                  name={name}
                  initialValue={initialValue}
                  validate={validate}
                  render={({ input: { name, value, onChange, checked } }) => {
                    return (
                      <>
                        <ValidationIcon />
                        <MuiCheckbox name={name} value={value} onChange={onChange} checked={checked} />
                      </>
                    )
                  }}
                  {...fieldProps}
                />
              }
              {...formControlLabelProps}
            />
          </TooltipWrapper>
        </S.Input.FilterCheckbox>
      ))}
    </FormGroupStyled>
  )
}
