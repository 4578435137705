import { Box } from '@mui/material'
import { get } from 'lodash'
import { ReactElement, useEffect, useState } from 'react'
import { Form } from 'react-final-form'
import { useParams } from 'react-router'
import { Mutation_Root, Query_Root } from 'src/@types/graphql'
import { useMessageSource } from 'src/i18n/useMessageSource'
import { resolveApplicationAssessmentDetailsRoot } from 'src/routing/routing-utils'
import { CriteriaConfigTable } from 'src/screens/shared/application/assessment/details/CriteriaConfigTable'
import {
  getApplicationTypeIdFromProjects,
  getCriteriaConfigByProcessQuery,
  saveProjectAssessmentCriteriaSelection,
} from 'src/screens/shared/assessment-criteria/assessmentQueries'
import {
  createGroupsAndInitialData,
  CriteriaFormValues,
  CriteriaGroups,
  EXCLUSION_PREFIX,
} from 'src/screens/shared/assessment-criteria/utils/AssessmentUtils'
import { usePermissionsForProjectAssessment } from 'src/service/security/PermissionHook'
import { SaveAndBackButton, SaveButton } from 'src/shared/button/Buttons'
import { CRITERIA_TYPE } from 'src/shared/constants/assessment-constants'
import { PROJECT } from 'src/shared/constants/constants'
import { CheckboxGroupField } from 'src/shared/form/control/CheckboxGroupField'
import { DirtyFormSpy } from 'src/shared/form/dirty/DirtyFormSpy'
import { PageLayout } from 'src/shared/layout/PageLayout'
import { ScreenLayout } from 'src/shared/layout/ScreenLayout'
import { NotAuthorized } from 'src/shared/not-authorized/NotAuthorized'
import { HelpAndInstructions } from 'src/shared/presentation/HelpAndInstructions'
import { Section } from 'src/shared/presentation/Section'
import { useDelayedNavigate } from 'src/shared/utils/hooks/navigation-hooks'
import { useNotificationService } from 'src/shared/utils/NotificationService'
import { Utils } from 'src/shared/utils/Utils'
import { useUserLocale } from 'src/user/UserContext'
import { useClient, useQuery } from 'urql'

interface Props {
  baseUrl: '/pf-kap' | '/pf-pgv'
}

export const ApplicationAssessmentExclusionEditPage = ({ baseUrl }: Props): ReactElement => {
  const { getMessage } = useMessageSource()
  const { projectId, assessmentId } = useParams()
  const project_id = parseInt(projectId as string)
  const assessment_id = parseInt(assessmentId as string)

  const language = useUserLocale()
  const notificationService = useNotificationService()

  const navigate = useDelayedNavigate()
  const urqlClient = useClient()

  const [criteriaGroups, setCriteriaGroups] = useState<CriteriaGroups | undefined>()
  const [initialValues, setInitialValues] = useState<CriteriaFormValues | undefined>()

  const process = Utils.resolveProcessToLowerCase(baseUrl)
  const projectType = Utils.resolveProcess(baseUrl)
  const { loading, canEdit } = usePermissionsForProjectAssessment(projectType, project_id, assessment_id)
  const isPfKap = projectType === PROJECT.PF_KAP
  const isPfPgv = projectType === PROJECT.PF_PGV
  const [{ data }] = useQuery<{
    pf_kap_project: Query_Root['pf_kap_project']
    pf_pgv_project: Query_Root['pf_pgv_project']
  }>({
    query: getApplicationTypeIdFromProjects,
    variables: { isPfKap, isPfPgv, projectId },
  })
  const applicationTypeId = isPfKap
    ? data?.pf_kap_project?.[0]?.application_type_id
    : data?.pf_pgv_project?.[0]?.application_type_id

  useEffect(() => {
    const initData = async () => {
      const { data } = await urqlClient
        .query<{
          project_assessment_by_pk: Query_Root['project_assessment_by_pk']
          project_assessment_criteria_selection: Query_Root['project_assessment_criteria_selection']
          criteria_group_config: Query_Root['criteria_group_config']
        }>(getCriteriaConfigByProcessQuery, {
          process: projectType,
          criteriaType: CRITERIA_TYPE.EXCLUSION,
          assessment: assessment_id,
          applicationTypeId: applicationTypeId,
        })
        .toPromise()

      const selectedExclusions = data?.project_assessment_criteria_selection
      const criteriaGroupedConfigs = data?.criteria_group_config
      const projectAssessmentVersion = data?.project_assessment_by_pk?.version || 0

      const { criteriaGroups, initialValues } = createGroupsAndInitialData(
        selectedExclusions,
        criteriaGroupedConfigs,
        EXCLUSION_PREFIX,
        applicationTypeId,
        projectAssessmentVersion,
      )

      setCriteriaGroups(criteriaGroups)
      setInitialValues(initialValues)
    }

    initData()
  }, [urqlClient, projectType, assessment_id, applicationTypeId])

  const onBack = () => {
    navigate(resolveApplicationAssessmentDetailsRoot(baseUrl).nested.Exclusion.params({ projectId, assessmentId }))
  }

  const handleSubmitLocal = async (formValues: CriteriaFormValues): Promise<any> => {
    const { version, ...values } = formValues

    const selections = Object.entries(values)
      .filter(([_, value]) => value)
      .map(([key]) => ({
        project_assessment_id: assessment_id,
        criteria_config_id: parseInt(key.split(EXCLUSION_PREFIX)[1]),
      }))

    const { data, error } = await urqlClient
      .mutation<{
        delete_project_assessment_criteria_selection: Mutation_Root['delete_project_assessment_criteria_selection']
        insert_project_assessment_criteria_selection: Mutation_Root['insert_project_assessment_criteria_selection']
        update_project_assessment: Mutation_Root['update_project_assessment']
      }>(saveProjectAssessmentCriteriaSelection, {
        assessmentId: assessment_id,
        selections,
        version: version,
        criteriaType: CRITERIA_TYPE.EXCLUSION,
      })
      .toPromise()

    if (error || data?.update_project_assessment?.affected_rows !== 1) {
      notificationService.operationFailed()
    } else {
      const newVersion = data?.update_project_assessment.returning[0]?.version ?? 0
      setInitialValues({ ...values, version: newVersion })
      notificationService.changesSaved()
    }
  }

  let submit: any = () => {}
  let formValid = false

  const onSave = (event: any) => {
    submit(event)
  }

  const onSaveAndBack = async (event: any) => {
    try {
      await submit(event)
      formValid && onBack()
    } catch {
      // do nothing
    }
  }

  if (!loading && !canEdit) {
    return <NotAuthorized />
  }

  return (
    <ScreenLayout
      title={getMessage('label.exclusion.criteria.edit')}
      onBack={onBack}
      hasSecondLevelNavigation={false}
      actions={
        <>
          <SaveAndBackButton origin="header" onClick={onSaveAndBack} />
          <SaveButton origin="header" onClick={onSave} />
        </>
      }
    >
      <PageLayout>
        {!loading && (
          <Section
            id="exclusion-criteria"
            helpAndInstructions={
              <HelpAndInstructions labelKey={`label.help.assessment.exclusion.criteria.${process}`} defaultExpansion />
            }
          >
            <Box mt={2}>
              <Form<CriteriaFormValues>
                initialValues={initialValues}
                onSubmit={handleSubmitLocal}
                render={({ valid, handleSubmit }) => {
                  submit = handleSubmit
                  formValid = valid
                  return (
                    <form onSubmit={handleSubmit} noValidate>
                      {Object.values(criteriaGroups || {})
                        .sort((a, b) => a?.[0].sort_number - b?.[0].sort_number)
                        .map((criteriaSelections) => (
                          <CriteriaConfigTable
                            key={criteriaSelections[0].id}
                            rows={criteriaSelections}
                            groupTitle={get(criteriaSelections[0].names, language, '')}
                            mode="edit"
                            EditControl={CheckboxGroupField}
                            resolveControlProps={(row) => ({
                              name: `${EXCLUSION_PREFIX}${row.id}`,
                              data: { name: `${EXCLUSION_PREFIX}${row.id}` },
                              $noLabel: true,
                            })}
                          />
                        ))}
                      <DirtyFormSpy />
                    </form>
                  )
                }}
              />
            </Box>
          </Section>
        )}
      </PageLayout>
    </ScreenLayout>
  )
}
