import { css } from 'styled-components/macro'

export const TEXT_OVERFLOW_MIXIN = css`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`

const SUBTITLE_2_MIXIN = css`
  font-size: ${({ theme }) => theme.typography.subtitle2.fontSize};
  font-weight: ${({ theme }) => theme.typography.subtitle2.fontWeight};
  line-height: ${({ theme }) => theme.typography.subtitle2.lineHeight};
  letter-spacing: ${({ theme }) => theme.typography.subtitle2.letterSpacing};
`

const BODY_1_MIXIN = css`
  font-size: ${({ theme }) => theme.typography.body1.fontSize};
  font-weight: ${({ theme }) => theme.typography.body1.fontWeight};
  line-height: ${({ theme }) => theme.typography.body1.lineHeight};
  letter-spacing: ${({ theme }) => theme.typography.body1.letterSpacing};
`

const BODY_2_MIXIN = css`
  font-size: ${({ theme }) => theme.typography.body2.fontSize};
  font-weight: ${({ theme }) => theme.typography.body2.fontWeight};
  line-height: ${({ theme }) => theme.typography.body2.lineHeight};
  letter-spacing: ${({ theme }) => theme.typography.body2.letterSpacing};
`

const BUTTON_MIXIN = css`
  font-size: ${({ theme }) => theme.typography.button.fontSize};
  font-weight: ${({ theme }) => theme.typography.button.fontWeight};
  letter-spacing: ${({ theme }) => theme.typography.button.letterSpacing};
  text-transform: uppercase;
`

const CAPTION_MIXIN = css`
  font-size: ${({ theme }) => theme.typography.caption.fontSize};
  font-weight: ${({ theme }) => theme.typography.caption.fontWeight};
  line-height: ${({ theme }) => theme.typography.caption.lineHeight};
  letter-spacing: ${({ theme }) => theme.typography.caption.letterSpacing};
`

export const TYPOGRAPHY_MIXIN = {
  body1: BODY_1_MIXIN,
  body2: BODY_2_MIXIN,
  subtitle2: SUBTITLE_2_MIXIN,
  button: BUTTON_MIXIN,
  caption: CAPTION_MIXIN,
}

const WIDTH = 2
const OUTLINE_WIDTH = WIDTH + 'px'
const OUTLINE_INSET = -WIDTH + 'px'
const OUTLINE_OFFSET = '4px'

export const FOCUS_OUTLINE = {
  width: OUTLINE_WIDTH,
  inset: OUTLINE_INSET,
  offset: OUTLINE_OFFSET,
}

export const STYLED_FOCUS_OUTLINE = css`
  outline: ${FOCUS_OUTLINE.width} dotted ${({ theme }) => theme.colors.secondary.dark};
  outline-offset: ${FOCUS_OUTLINE.inset};
`

export const BOX_SHADOW_ON_WHITE_BG = {
  default: '0 0 4px 0 rgba(0, 0, 0, 0.12)',
  hover: '0 0 4px 0 rgba(0,0,0,0.2),0 0 2px 0 rgba(0,0,0,0.14),0 0 5px 0 rgba(0,0,0,0.12)',
}

export const FONT_FAMILY = `'Roboto', 'Helvetica', 'Arial', sans-serif`
