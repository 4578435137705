import { ReactElement } from 'react'
import { Slide, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { COLORS } from 'src/theme/colors'
import styled from 'styled-components/macro'

const ToastContainerStyled = styled(ToastContainer).attrs({
  bodyClassName: 'body',
})`
  .Toastify__toast--success {
    background-color: ${COLORS.success.light};
    .Toastify__toast-icon > svg {
      fill: ${COLORS.success.main};
    }
  }
  .Toastify__toast--error {
    background-color: ${COLORS.error.light};
    .Toastify__toast-icon > svg {
      fill: ${COLORS.error.dark};
    }
  }
  .Toastify__toast--info {
    background-color: ${COLORS.secondary.light};
    .Toastify__toast-icon > svg {
      fill: ${COLORS.secondary.main};
    }
  }
  button[aria-label='close'] {
    margin: auto;
    & > svg {
      color: ${COLORS.text.secondary};
      height: 20px;
      width: 20px;
    }
  }
  .body {
    color: ${COLORS.text.secondary};
  }
`

export const Toaster = (): ReactElement => {
  return (
    <ToastContainerStyled
      position="top-center"
      autoClose={5000}
      hideProgressBar
      transition={Slide}
      newestOnTop
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      limit={3}
    />
  )
}
