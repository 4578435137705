import { Stack } from '@mui/material'
import { ReactElement, useEffect, useState } from 'react'
import { Form } from 'react-final-form'
import { useParams } from 'react-router'
import { Factsheet, Mutation_Root, Query_Root } from 'src/@types/graphql'
import { useEnvironment } from 'src/env/EnvironmentStore'
import { useMessageSource } from 'src/i18n/useMessageSource'
import { ROUTES } from 'src/routing/routes'
import { fetchFactsheetByIdQuery, updateFactsheetMutation } from 'src/screens/factsheets/factsheet/factsheetQueries'
import { usePermissionsForFactsheets } from 'src/service/security/PermissionHook'
import { SaveAndBackButton, SaveButton } from 'src/shared/button/Buttons'
import { TEXT_LENGTH } from 'src/shared/constants/constants'
import { ENVIRONMENT } from 'src/shared/constants/environment-constants'
import { HtmlEditorField } from 'src/shared/form/control/HtmlEditorField'
import { DirtyFormSpy } from 'src/shared/form/dirty/DirtyFormSpy'
import { createDecorators } from 'src/shared/form/utils/decorators'
import { maxChar } from 'src/shared/form/validation/validators'
import { PageLayout } from 'src/shared/layout/PageLayout'
import { ScreenLayout } from 'src/shared/layout/ScreenLayout'
import { NotAuthorized } from 'src/shared/not-authorized/NotAuthorized'
import { HelpAndInstructions } from 'src/shared/presentation/HelpAndInstructions'
import { useDelayedNavigate } from 'src/shared/utils/hooks/navigation-hooks'
import { useNotificationService } from 'src/shared/utils/NotificationService'
import { useClient } from 'urql'

interface FactsheetDescriptionEditForm {
  en: string
  de: string
  fr: string
  it: string
}

type DatabaseFieldType = keyof Pick<
  Factsheet,
  'important_insights' | 'strength' | 'further_information' | 'evidence_of_effectiveness'
>

interface Props {
  field: DatabaseFieldType
  sectionLabel: string
  helpLabel: string
}

export const FactsheetDescriptionEditPage = ({ field, sectionLabel, helpLabel }: Props): ReactElement => {
  const { getMessage } = useMessageSource()
  const { factsheetId } = useParams()
  const { environment } = useEnvironment()
  const { canEdit } = usePermissionsForFactsheets()

  const decorators = createDecorators()
  const navigate = useDelayedNavigate()
  const urqlClient = useClient()
  const notificationService = useNotificationService()

  const [initialValues, setInitialValues] = useState<FactsheetDescriptionEditForm>()
  const [loading, setLoading] = useState<boolean>(true)

  useEffect(() => {
    const initData = async () => {
      const { data } = await urqlClient
        .query<{
          factsheet_by_pk: Query_Root['factsheet_by_pk']
        }>(fetchFactsheetByIdQuery, { id: parseInt(factsheetId as string) })
        .toPromise()

      const values = data?.factsheet_by_pk?.[field!]

      setInitialValues(values)

      setLoading(false)
    }

    initData()
  }, [urqlClient, factsheetId, getMessage, field])

  const onSubmitLocal = async (values: FactsheetDescriptionEditForm) => {
    const mutationObject = {
      [field]: values,
    }

    const { data } = await urqlClient
      .mutation<{
        update_factsheet: Mutation_Root['update_factsheet']
      }>(updateFactsheetMutation, { id: parseInt(factsheetId as string), object: mutationObject })
      .toPromise()

    if (data && data.update_factsheet?.affected_rows === 1) {
      setInitialValues(values)
      notificationService.changesSaved()
    } else {
      notificationService.operationFailed()
    }
  }

  const onSave = (event: any) => {
    submit(event)
  }

  const onSaveAndBack = async (event: any) => {
    try {
      await submit(event)
      onBack()
    } catch {
      // do nothing
    }
  }

  const onBack = () => {
    const route = ROUTES.FactsheetDescriptionRoot.params({ factsheetId })
    navigate(`${route}#${field.replaceAll('_', '-')}`)
  }

  let submit: any = () => {}

  if (!loading && !canEdit) {
    return <NotAuthorized />
  }

  return (
    <>
      <ScreenLayout
        title={getMessage(sectionLabel)}
        onBack={onBack}
        hasSecondLevelNavigation={false}
        actions={
          <>
            <SaveAndBackButton origin="header" onClick={onSaveAndBack} />
            <SaveButton origin="header" onClick={onSave} />
          </>
        }
      >
        <PageLayout>
          {!loading && (
            <>
              <HelpAndInstructions labelKey={helpLabel} defaultExpansion />
              <Form<FactsheetDescriptionEditForm>
                initialValues={initialValues}
                onSubmit={onSubmitLocal}
                decorators={decorators}
                render={({ handleSubmit }) => {
                  submit = handleSubmit
                  return (
                    <form onSubmit={handleSubmit} noValidate id="edit-factsheet-form">
                      <Stack spacing={2}>
                        {environment === ENVIRONMENT.DEV && (
                          <HtmlEditorField
                            name="en"
                            label={getMessage('label.language.en')}
                            validate={maxChar(TEXT_LENGTH.XL)}
                          />
                        )}
                        <HtmlEditorField
                          name="de"
                          label={getMessage('label.language.de')}
                          validate={maxChar(TEXT_LENGTH.XL)}
                        />
                        <HtmlEditorField
                          name="fr"
                          label={getMessage('label.language.fr')}
                          validate={maxChar(TEXT_LENGTH.XL)}
                        />
                        <HtmlEditorField
                          name="it"
                          label={getMessage('label.language.it')}
                          validate={maxChar(TEXT_LENGTH.XL)}
                        />
                        <DirtyFormSpy />
                      </Stack>
                    </form>
                  )
                }}
              />
            </>
          )}
        </PageLayout>
      </ScreenLayout>
    </>
  )
}
