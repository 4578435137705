import { ReactElement } from 'react'
import { useParams } from 'react-router'
import { ProjectImplementationMilestone } from 'src/screens/shared/implementation/details/milestone/ProjectImplementationMilestone'

export const PfKapImplementationMilestonePage = (): ReactElement => {
  const { projectId } = useParams()
  const pfKapId = parseInt(projectId as string)

  return <ProjectImplementationMilestone baseUrl="/pf-kap" projectId={pfKapId} />
}
