import { Stack } from '@mui/material'
import Box from '@mui/material/Box'
import { ReactElement, useEffect, useState } from 'react'
import { Form } from 'react-final-form'
import { useParams } from 'react-router'
import { Kap_Goal, Query_Root } from 'src/@types/graphql'
import { useMessageSource } from 'src/i18n/useMessageSource'
import { fetchKapMeasuresByProgramIdQuery } from 'src/screens/kap/program/details/goals/kapGoalQueries'
import { sortKapMeasures } from 'src/screens/shared/measure/utils/KapMeasureUtils'
import { TEXT_LENGTH } from 'src/shared/constants/constants'
import { Option } from 'src/shared/form/control'
import { HtmlEditorField } from 'src/shared/form/control/HtmlEditorField'
import { MultiSelectField } from 'src/shared/form/control/MultiSelectField'
import { TextField } from 'src/shared/form/control/TextField'
import { DirtyFormSpy } from 'src/shared/form/dirty/DirtyFormSpy'
import { createDecorators } from 'src/shared/form/utils/decorators'
import { composeValidators, maxChar, required } from 'src/shared/form/validation/validators'
import { HtmlSanitizer } from 'src/shared/utils/HtmlSanitizer'
import { useClient } from 'urql'

export interface KapGoalFormValues {
  goalId?: number
  name: string
  description: string
  measureIds?: number[]
  programId?: number
}

interface Props {
  initialValues: KapGoalFormValues
  onSave: (values: KapGoalFormValues) => Promise<void>
}

export const initialValuesFactory = (programId: number, goal?: Kap_Goal): KapGoalFormValues => {
  const selectedMeasureIds = goal?.kap_goal_measures.map((kapGoalMeasure) => kapGoalMeasure.kap_measure.id) ?? []
  return {
    goalId: goal?.id || undefined,
    name: goal?.name || '',
    description: HtmlSanitizer.sanitize(goal?.description),
    measureIds: selectedMeasureIds,
    programId: programId,
  }
}

const decorators = createDecorators()

export const KapGoalForm = ({ initialValues, onSave }: Props): ReactElement => {
  const { getMessage } = useMessageSource()
  const urqlClient = useClient()
  const [measureOptions, setMeasureOptions] = useState<Option[] | undefined>()
  const { programId } = useParams()
  const program_id = parseInt(programId as string)

  useEffect(() => {
    const initializeKapMeasureOptions = async () => {
      const { data } = await urqlClient
        .query<{ kap_measure: Query_Root['kap_measure'] }, { programId: number }>(fetchKapMeasuresByProgramIdQuery, {
          programId: program_id,
        })
        .toPromise()

      if (data && data.kap_measure) {
        sortKapMeasures(data.kap_measure)

        const options =
          data.kap_measure.map((kapMeasure) => {
            const measureIdCharacter = `label.kap.measure.id.character.${kapMeasure.level}`
            return {
              label: `${getMessage(measureIdCharacter)}${kapMeasure.sort_number}: ${kapMeasure.name}`,
              value: kapMeasure.id,
            }
          }) ?? []

        setMeasureOptions(options)
      }
    }

    initializeKapMeasureOptions()
  }, [urqlClient, program_id, setMeasureOptions, getMessage])

  return (
    <Box>
      {initialValues && measureOptions && (
        <Form<KapGoalFormValues>
          initialValues={initialValues}
          onSubmit={onSave}
          decorators={decorators}
          render={({ handleSubmit }) => {
            return (
              <form id="goal-form" onSubmit={handleSubmit}>
                <Stack spacing={2}>
                  <TextField
                    required
                    label={getMessage('label.title')}
                    type="text"
                    name="name"
                    validate={composeValidators(required(), maxChar(TEXT_LENGTH.M))}
                  />
                  <HtmlEditorField
                    required
                    label={getMessage('label.description')}
                    name="description"
                    validate={composeValidators(required(), maxChar(TEXT_LENGTH.XXL))}
                  />
                  <MultiSelectField
                    options={measureOptions}
                    name="measureIds"
                    label={getMessage('label.related.measures')}
                    unavailableOptionsMessage={getMessage('label.related.measures.add.measures')}
                  />
                  <DirtyFormSpy />
                </Stack>
              </form>
            )
          }}
        />
      )}
    </Box>
  )
}
