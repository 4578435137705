import { useEffect, useState } from 'react'
import { useMessageSource } from 'src/i18n/useMessageSource'
import { FilterFieldWrapper } from 'src/screens/shared/common/filter-card/FilterFieldWrapper'
import { DOSSIER_STATUS, DOSSIER_STATUS_TYPE, PROJECT, PROJECT_TYPE } from 'src/shared/constants/constants'
import { FilterCheckboxGroupField, FilterCheckboxModel } from 'src/shared/form/control/FilterCheckboxGroupField'
import { requiredSelection } from 'src/shared/form/validation/validators'

interface Props {
  name: string
  error?: boolean
  formValues: DOSSIER_STATUS_TYPE[] | undefined
  process?: PROJECT_TYPE
}

export const StatusFilter = ({ name, error, formValues, process }: Props) => {
  const { getMessage } = useMessageSource()

  const [statusData, setStatusData] = useState<FilterCheckboxModel[]>([])

  useEffect(() => {
    const initializeValues = () => {
      const statusesArray = [
        DOSSIER_STATUS.CONCEPT,
        DOSSIER_STATUS.APPLICATION,
        ...(process !== PROJECT.KAP ? [DOSSIER_STATUS.REVISION, DOSSIER_STATUS.ACCEPTED] : []),
        DOSSIER_STATUS.IMPLEMENTATION,
        DOSSIER_STATUS.FINISHED,
        DOSSIER_STATUS.CANCELED,
        ...(process !== PROJECT.KAP
          ? [DOSSIER_STATUS.REJECTED, DOSSIER_STATUS.WITHDRAWN, DOSSIER_STATUS.EXCLUDED]
          : []),
      ]

      const statuses = statusesArray.map((status) => ({
        label: getMessage(`label.project.status.${status}`),
        value: status,
      }))

      setStatusData(statuses)
    }

    initializeValues()
  }, [getMessage, process])

  return (
    <FilterFieldWrapper label={getMessage('label.status')} required error={error}>
      <FilterCheckboxGroupField data={statusData} name={name} validate={requiredSelection()} formValues={formValues} />
    </FilterFieldWrapper>
  )
}
