import { Stack } from '@mui/material'
import { ReactElement, useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { Kap_Goal, Query_Root } from 'src/@types/graphql'
import { useMessageSource } from 'src/i18n/useMessageSource'
import { ROUTES } from 'src/routing/routes'
import { KapMilestoneGoalCardContent } from 'src/screens/kap/implementation/milestone/details/annual-report/goals/KapMilestoneGoalCardContent'
import { queryKapGoalByProgramId } from 'src/screens/kap/implementation/milestone/details/annual-report/goals/kapMilestoneGoalQueries'
import { queryMilestoneById } from 'src/screens/shared/implementation/details/milestoneQueries'
import { usePermissionsForKapMilestones } from 'src/service/security/PermissionHook'
import { GOAL_CHAR, PROJECT } from 'src/shared/constants/constants'
import { MILESTONE_RESPONSIBLE_TYPE_TYPE, MILESTONE_STATUS_TYPE } from 'src/shared/constants/milestone-constants'
import { PageLayout } from 'src/shared/layout/PageLayout'
import { ScreenLayout } from 'src/shared/layout/ScreenLayout'
import { KapMilestoneExportMenu } from 'src/shared/menu/KapMilestoneExportMenu'
import { NotAuthorized } from 'src/shared/not-authorized/NotAuthorized'
import { ExpandableGoalMeasureCard } from 'src/shared/presentation/ExpandableGoalMeasureCard'
import { HelpAndInstructions } from 'src/shared/presentation/HelpAndInstructions'
import { Section } from 'src/shared/presentation/Section'
import { useDelayedNavigate } from 'src/shared/utils/hooks/navigation-hooks'
import { useNotificationService } from 'src/shared/utils/NotificationService'
import { Utils } from 'src/shared/utils/Utils'
import { useClient, useQuery } from 'urql'

export const KapMilestoneGoalReportPage = (): ReactElement => {
  const { programId, milestoneId, milestonePath } = useParams()
  const program_id = parseInt(programId as string)
  const milestone_id = parseInt(milestoneId as string)
  const milestoneType = Utils.resolveMilestoneType(milestonePath)
  const process = PROJECT.KAP
  const navigate = useDelayedNavigate()
  const notificationService = useNotificationService()
  const { getMessage } = useMessageSource()
  const urqlClient = useClient()

  const [goals, setGoals] = useState<Kap_Goal[]>()

  const [{ data, error }] = useQuery<{ milestone_by_pk: Query_Root['milestone_by_pk'] }, { id: number }>({
    query: queryMilestoneById,
    variables: { id: milestone_id },
  })

  if (error) {
    notificationService.operationFailed()
  }

  const milestone = data?.milestone_by_pk

  const { loading, canEdit, canView } = usePermissionsForKapMilestones(
    program_id,
    milestone?.status as MILESTONE_STATUS_TYPE,
    milestone?.responsible_type as MILESTONE_RESPONSIBLE_TYPE_TYPE,
  )

  useEffect(() => {
    const initData = async () => {
      const { data } = await urqlClient
        .query<{ kap_goal: Query_Root['kap_goal'] }, { programId: number }>(queryKapGoalByProgramId, {
          programId: program_id,
        })
        .toPromise()

      if (data && data.kap_goal) {
        setGoals(data.kap_goal)
      } else {
        notificationService.operationFailed()
      }
    }

    initData()
  }, [urqlClient, notificationService, milestone_id, program_id])

  const onBack = () => {
    navigate(ROUTES.KapImplementationDetailsRoot.params({ programId }))
  }

  const onEdit = (goalId: number) => {
    navigate(
      ROUTES.KapAnnualReportMilestoneRoot.nested.GoalRatingEdit.params({
        programId,
        milestoneId,
        milestonePath,
        goalId,
      }),
    )
  }

  const onNavigation = (goalId: number) => {
    window.open(
      ROUTES.KapDetailsRoot.nested.GoalDetails.params({
        programId,
        goalId,
      }),
    )
  }

  if (!loading && !canView) {
    return <NotAuthorized />
  }

  return (
    <>
      <ScreenLayout
        title={`${getMessage(`label.milestone.type.${milestoneType}`)} ${milestone?.year_in_focus ?? ''}`}
        onBack={onBack}
        actions={<>{canView && <KapMilestoneExportMenu year={milestone?.year_in_focus} />}</>}
      >
        <PageLayout>
          <>
            {goals && goals.length > 0 && (
              <Section
                title={`${getMessage('label.goal.title.kap')}`}
                helpAndInstructions={
                  <HelpAndInstructions labelKey={`label.help.milestone.goal.rating.${process.toLowerCase()}`} />
                }
              >
                <Stack spacing={1}>
                  {goals.map((goal) => (
                    <ExpandableGoalMeasureCard
                      key={goal.id}
                      type={GOAL_CHAR}
                      title={goal.name}
                      id={`${getMessage('label.goal.id.character').concat(String(goal.sort_number))}`}
                      entityId={goal.id}
                      canEdit={canEdit}
                      onEdit={() => onEdit(goal.id)}
                      onNavigate={() => onNavigation(goal.id)}
                    >
                      <KapMilestoneGoalCardContent goalId={goal.id} />
                    </ExpandableGoalMeasureCard>
                  ))}
                </Stack>
              </Section>
            )}
          </>
        </PageLayout>
      </ScreenLayout>
    </>
  )
}
