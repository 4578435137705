import { gql } from '@urql/core'

export const getAnalysisAreaOfActivityData = gql`
  query fetchAnalysisAreaOfActivityData($analysisId: Int!, $language: String!) {
    area_of_activity(where: { active: { _eq: true } }, order_by: { sort_number: asc }) {
      id
      names(path: $language)
      sort_number
      code
    }
    analysis_area_of_activity(where: { success_factor_analysis_id: { _eq: $analysisId } }) {
      area_of_activity_id
      comment
    }
  }
`

export const getAnalysisFocusTopicData = gql`
  query fetchAnalysisFocusTopicData($focusTopicAnalysisId: Int!, $focusTopicLanguage: String!) {
    focus_topic(where: { active: { _eq: true } }, order_by: { sort_number: asc }) {
      id
      names(path: $focusTopicLanguage)
      sort_number
      code
      area_of_activity_id
    }
    analysis_focus_topic(where: { success_factor_analysis_id: { _eq: $focusTopicAnalysisId } }) {
      focus_topic_id
      comment
    }
  }
`

export const getAnalysisSuccessFactorData = gql`
  query fetchAnalysisSuccessFactorData($successFactorAnalysisId: Int!, $successFactorLanguage: String!) {
    success_factor(where: { active: { _eq: true } }, order_by: { sort_number: asc }) {
      id
      names(path: $successFactorLanguage)
      sort_number
      code
      focus_topic_id
    }
    analysis_success_factor_rating(where: { success_factor_analysis_id: { _eq: $successFactorAnalysisId } }) {
      success_factor_id
      status
      rating_current_status
      rating_priority
      comment
    }
  }
`

export const getAnalysisAreaOfActivityComment = gql`
  query fetchAllAreaOfActivity($analysisId: Int!, $areaOfActivityId: Int!) {
    analysis_area_of_activity(
      where: {
        _and: { success_factor_analysis_id: { _eq: $analysisId }, area_of_activity_id: { _eq: $areaOfActivityId } }
      }
    ) {
      comment
    }
    area_of_activity_by_pk(id: $areaOfActivityId) {
      names
      code
    }
  }
`

export const getAnalysisFocusTopicComment = gql`
  query fetchAllFocusTopic($analysisId: Int!, $focusTopicId: Int!) {
    analysis_focus_topic(
      where: { _and: { success_factor_analysis_id: { _eq: $analysisId }, focus_topic_id: { _eq: $focusTopicId } } }
    ) {
      comment
    }
    focus_topic_by_pk(id: $focusTopicId) {
      names
      code
    }
  }
`

export const upsertAnalysisAreaOfActivityComment = gql`
  mutation upsertAreaOfActivityComment($areaOfActivityId: Int!, $analysisId: Int!, $comment: String!) {
    insert_analysis_area_of_activity_one(
      object: { area_of_activity_id: $areaOfActivityId, success_factor_analysis_id: $analysisId, comment: $comment }
      on_conflict: { constraint: analysis_area_of_activity_uq, update_columns: [comment] }
    ) {
      id
    }
  }
`

export const upsertAnalysisFocusTopicComment = gql`
  mutation upsertFocusTopicComment($focusTopicId: Int!, $analysisId: Int!, $comment: String!) {
    insert_analysis_focus_topic_one(
      object: { focus_topic_id: $focusTopicId, success_factor_analysis_id: $analysisId, comment: $comment }
      on_conflict: { constraint: analysis_focus_topic_uq, update_columns: [comment] }
    ) {
      id
    }
  }
`

export const fetchAnalysisSuccessFactorDataQuery = gql`
  query fetchAnalysisSuccessFactorData($successFactorId: Int!, $analysisId: Int!) {
    success_factor_by_pk(id: $successFactorId) {
      code
      names
      description
      sort_number
      id
    }
    success_factor_indicator(where: { success_factor_id: { _eq: $successFactorId } }) {
      indicator_code
      description
      sort_number
    }
    success_factor_analysis_by_pk(id: $analysisId) {
      life_phase_indicator
      topic_indicator
    }
    analysis_success_factor_rating(
      where: {
        _and: { success_factor_analysis_id: { _eq: $analysisId }, success_factor_id: { _eq: $successFactorId } }
      }
    ) {
      success_factor_id
      comment
      status
      rating_current_status
      rating_priority
    }
  }
`
export const upsertAnalysisSuccessFactorRating = gql`
  mutation upsertAnalysisSuccessFactorRating(
    $successFactorAnalysisId: Int
    $successFactorId: Int
    $status: String!
    $ratingCurrentStatus: Int
    $ratingPriority: Int
    $comment: String!
  ) {
    insert_analysis_success_factor_rating_one(
      object: {
        rating_current_status: $ratingCurrentStatus
        rating_priority: $ratingPriority
        status: $status
        success_factor_analysis_id: $successFactorAnalysisId
        success_factor_id: $successFactorId
        comment: $comment
      }
      on_conflict: {
        constraint: analysis_success_factor_rating_uq
        update_columns: [comment, rating_priority, rating_current_status, status]
      }
    ) {
      id
    }
  }
`

export const getPublicSuccessFactorData = gql`
  query fetchPublicSuccessFactorData($language: String!) {
    area_of_activity(where: { active: { _eq: true } }, order_by: { sort_number: asc }) {
      id
      names(path: $language)
      sort_number
      code
      focus_topics(where: { active: { _eq: true } }, order_by: { sort_number: asc }) {
        id
        names(path: $language)
        sort_number
        code
        success_factors(where: { active: { _eq: true } }, order_by: { sort_number: asc }) {
          id
          names(path: $language)
          description(path: $language)
          sort_number
          code
        }
      }
    }
  }
`

export const getSuccessFactorIndicators = gql`
  query fetchSuccessFactorIndicators($successFactorId: Int!, $language: String) {
    success_factor_indicator(where: { success_factor_id: { _eq: $successFactorId } }) {
      indicator_code
      description(path: $language)
      sort_number
    }
  }
`
