import { Box, Card, CardActionArea, CardContent, Tooltip, Typography } from '@mui/material'
import { ReactElement } from 'react'
import { STYLED_FOCUS_OUTLINE, TEXT_OVERFLOW_MIXIN } from 'src/shared/constants/styling-constants'
import styled from 'styled-components/macro'

interface Props {
  title?: string
  description?: any
  icon?: ReactElement
  onClick?: () => void
}

const CardStyled = styled(Card)`
  &:hover {
    cursor: pointer;
    box-shadow: ${({ theme }) => theme.shadows[4]};
  }
  & .MuiButtonBase-root {
    &:hover .MuiCardActionArea-focusHighlight {
      opacity: 0;
    }
    &:focus-visible {
      ${STYLED_FOCUS_OUTLINE}
      & .MuiCardActionArea-focusHighlight {
        opacity: 0;
      }
    }
  }
  .MuiSvgIcon-root {
    font-size: 36px;
    margin-bottom: ${({ theme }) => theme.spacing(1.5)};
  }

  // Styles for the behavior inside the grid

  flex: 1 0 21%;

  // 4 cards
  &:first-child:nth-last-child(4),
  &:first-child:nth-last-child(4) ~ & {
    flex: 1 0 42%;
  }

  // 5 cards
  &:first-child:nth-last-child(5),
  &:first-child:nth-last-child(5) ~ & {
    flex: 1 0 28%;
  }

  // 6 cards
  &:first-child:nth-last-child(6),
  &:first-child:nth-last-child(6) ~ & {
    flex: 1 0 28%;
  }

  // 7 cards
  &:first-child:nth-last-child(7),
  &:first-child:nth-last-child(7) ~ &:nth-child(-n + 3) {
    flex: 1 0 28%;
  }
  &:first-child:nth-last-child(7) ~ &:nth-child(n + 5) {
    flex: 1 0 21%;
  }
`

const TextWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  grid-gap: ${({ theme }) => theme.spacing(1)};
  min-width: 0;
  & .MuiTypography-root {
    ${TEXT_OVERFLOW_MIXIN};
  }
`

export const DashboardCard = ({ title, description, icon, onClick }: Props): ReactElement => {
  return (
    <Tooltip title={description} arrow={false} enterDelay={1000} enterNextDelay={1000}>
      <CardStyled>
        <CardActionArea onClick={onClick}>
          <CardContent>
            {icon}
            <TextWrapper>
              <Typography variant="h3">{title}</Typography>
              <Typography variant="body2" color="textSecondary">
                {description}
              </Typography>
            </TextWrapper>
          </CardContent>
        </CardActionArea>
      </CardStyled>
    </Tooltip>
  )
}
