import { gql } from '@urql/core'

export const queryProjectBaseByPfKapProjectId = gql`
  query ($id: Int!) {
    pf_kap_project_by_pk(id: $id) {
      project_base {
        id
        initial_situation
        project_justification
        sources
        vision
      }
      version
    }
  }
`

export const queryProjectBaseByPfPgvProjectId = gql`
  query ($id: Int!) {
    pf_pgv_project_by_pk(id: $id) {
      project_base {
        id
        initial_situation
        project_justification
        sources
        vision
      }
      version
    }
  }
`

// UPDATE QUERIES - INITIAL SITUATION

export const updateInitialSituationPfKapByProjectId = gql`
  mutation ($projectId: Int!, $projectBaseId: Int!, $initialSituation: String, $version: Int!) {
    update_project_base(
      where: { _and: { pf_kap_projects: { project_base_id: { _eq: $projectBaseId }, version: { _eq: $version } } } }
      _set: { initial_situation: $initialSituation }
    ) {
      affected_rows
    }
    update_pf_kap_project(
      where: { _and: { id: { _eq: $projectId }, version: { _eq: $version } } }
      _set: { version: $version }
    ) {
      affected_rows
      returning {
        version
      }
    }
  }
`

export const updateInitialSituationPfPgvByProjectId = gql`
  mutation ($projectId: Int!, $projectBaseId: Int!, $initialSituation: String, $version: Int!) {
    update_project_base(
      where: { _and: { pf_pgv_projects: { project_base_id: { _eq: $projectBaseId }, version: { _eq: $version } } } }
      _set: { initial_situation: $initialSituation }
    ) {
      affected_rows
    }
    update_pf_pgv_project(
      where: { _and: { id: { _eq: $projectId }, version: { _eq: $version } } }
      _set: { version: $version }
    ) {
      affected_rows
      returning {
        version
      }
    }
  }
`

// UPDATE QUERIES - PROJECT JUSTIFICATION

export const updateProjectJustificationPfKapByProjectId = gql`
  mutation ($projectId: Int!, $projectBaseId: Int!, $projectJustification: String, $version: Int!) {
    update_project_base(
      where: { _and: { pf_kap_projects: { project_base_id: { _eq: $projectBaseId }, version: { _eq: $version } } } }
      _set: { project_justification: $projectJustification }
    ) {
      affected_rows
    }
    update_pf_kap_project(
      where: { _and: { id: { _eq: $projectId }, version: { _eq: $version } } }
      _set: { version: $version }
    ) {
      affected_rows
      returning {
        version
      }
    }
  }
`

export const updateProjectJustificationPfPgvByProjectId = gql`
  mutation ($projectId: Int!, $projectBaseId: Int!, $projectJustification: String, $version: Int!) {
    update_project_base(
      where: { _and: { pf_pgv_projects: { project_base_id: { _eq: $projectBaseId }, version: { _eq: $version } } } }
      _set: { project_justification: $projectJustification }
    ) {
      affected_rows
    }
    update_pf_pgv_project(
      where: { _and: { id: { _eq: $projectId }, version: { _eq: $version } } }
      _set: { version: $version }
    ) {
      affected_rows
      returning {
        version
      }
    }
  }
`

// UPDATE QUERIES - SOURCES

export const updateSourcesPfKapByProjectId = gql`
  mutation ($projectId: Int!, $projectBaseId: Int!, $sources: String, $version: Int!) {
    update_project_base(
      where: { _and: { pf_kap_projects: { project_base_id: { _eq: $projectBaseId }, version: { _eq: $version } } } }
      _set: { sources: $sources }
    ) {
      affected_rows
    }
    update_pf_kap_project(
      where: { _and: { id: { _eq: $projectId }, version: { _eq: $version } } }
      _set: { version: $version }
    ) {
      affected_rows
      returning {
        version
      }
    }
  }
`

export const updateSourcesPfPgvByProjectId = gql`
  mutation ($projectId: Int!, $projectBaseId: Int!, $sources: String, $version: Int!) {
    update_project_base(
      where: { _and: { pf_pgv_projects: { project_base_id: { _eq: $projectBaseId }, version: { _eq: $version } } } }
      _set: { sources: $sources }
    ) {
      affected_rows
    }
    update_pf_pgv_project(
      where: { _and: { id: { _eq: $projectId }, version: { _eq: $version } } }
      _set: { version: $version }
    ) {
      affected_rows
      returning {
        version
      }
    }
  }
`

// UPDATE QUERIES - VISION

export const updateVisionPfKapByProjectId = gql`
  mutation ($projectId: Int!, $projectBaseId: Int!, $vision: String, $version: Int!) {
    update_project_base(
      where: { _and: { pf_kap_projects: { project_base_id: { _eq: $projectBaseId }, version: { _eq: $version } } } }
      _set: { vision: $vision }
    ) {
      affected_rows
    }
    update_pf_kap_project(
      where: { _and: { id: { _eq: $projectId }, version: { _eq: $version } } }
      _set: { version: $version }
    ) {
      affected_rows
      returning {
        version
      }
    }
  }
`

export const updateVisionPfPgvByProjectId = gql`
  mutation ($projectId: Int!, $projectBaseId: Int!, $vision: String, $version: Int!) {
    update_project_base(
      where: { _and: { pf_pgv_projects: { project_base_id: { _eq: $projectBaseId }, version: { _eq: $version } } } }
      _set: { vision: $vision }
    ) {
      affected_rows
    }
    update_pf_pgv_project(
      where: { _and: { id: { _eq: $projectId }, version: { _eq: $version } } }
      _set: { version: $version }
    ) {
      affected_rows
      returning {
        version
      }
    }
  }
`
