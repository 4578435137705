import { ReactElement } from 'react'
import { useParams } from 'react-router'
import { ApplicationAssessmentCriteria } from 'src/screens/shared/application/assessment/details/assessment-criteria/ApplicationAssessmentCriteria'

export const PfPgvAssessmentCriteriaPage = (): ReactElement => {
  const { projectId, assessmentId } = useParams()
  const pf_pgv_project_id = parseInt(projectId as string)
  const pf_pgv_assessment_id = parseInt(assessmentId as string)

  return (
    <ApplicationAssessmentCriteria
      baseUrl="/pf-pgv"
      projectId={pf_pgv_project_id}
      assessmentId={pf_pgv_assessment_id}
    />
  )
}
