import { ReactElement, useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router'
import { Mutation_Root, Project_Base, Query_Root } from 'src/@types/graphql'
import { useMessageSource } from 'src/i18n/useMessageSource'
import { resolveDetailsRoot } from 'src/routing/routing-utils'
import {
  InitialSituationAndVisionEditFormValues,
  InitialSituationAndVisionFieldEditForm,
  initialValuesFactoryInitialPageAndVision,
} from 'src/screens/shared/project/details/starting-point-and-vision/InitialSituationAndVisionFieldEditForm'
import {
  queryProjectBaseByPfKapProjectId,
  queryProjectBaseByPfPgvProjectId,
  updateSourcesPfKapByProjectId,
  updateSourcesPfPgvByProjectId,
} from 'src/screens/shared/project/details/starting-point-and-vision/startingPointAndVisionQueries'
import { usePermissionsForProject } from 'src/service/security/PermissionHook'
import { SaveAndBackButton, SaveButton } from 'src/shared/button/Buttons'
import { TEXT_LENGTH } from 'src/shared/constants/constants'
import { PageLayout } from 'src/shared/layout/PageLayout'
import { ScreenLayout } from 'src/shared/layout/ScreenLayout'
import { NotAuthorized } from 'src/shared/not-authorized/NotAuthorized'
import { HelpAndInstructions } from 'src/shared/presentation/HelpAndInstructions'
import { useDelayedNavigate } from 'src/shared/utils/hooks/navigation-hooks'
import { useError } from 'src/shared/utils/hooks/page-loading-error-hook'
import { useIsMounted } from 'src/shared/utils/hooks/react-hooks'
import { HtmlSanitizer } from 'src/shared/utils/HtmlSanitizer'
import { useNotificationService } from 'src/shared/utils/NotificationService'
import { Utils } from 'src/shared/utils/Utils'
import { useClient } from 'urql'

type originType = 'SAVE' | 'SAVE_AND_BACK'

interface SourcesEditPageProps {
  baseUrl: '/pf-kap' | '/pf-pgv'
}

export const SourcesEditPage = ({ baseUrl }: SourcesEditPageProps): ReactElement => {
  const isMounted = useIsMounted()
  const { projectId } = useParams()
  const { getMessage } = useMessageSource()
  const urqlClient = useClient()
  const navigate = useDelayedNavigate()
  const [initialValue, setInitialValue] = useState<InitialSituationAndVisionEditFormValues | null>(null)
  const [projectBase, setProjectBase] = useState<Project_Base | undefined>(undefined)
  const notificationService = useNotificationService()
  const pageTitle = getMessage('label.sources.title.edit')
  const originRef = useRef<originType>('SAVE')
  const id = parseInt(projectId as string)
  const process = Utils.resolveProcessToLowerCase(baseUrl)
  const projectType = Utils.resolveProcess(baseUrl)
  const { canEdit, loading } = usePermissionsForProject(projectType, id)
  const setError = useError()

  useEffect(() => {
    const initializeFormValues = async () => {
      switch (baseUrl) {
        case '/pf-kap': {
          const { data } = await urqlClient
            .query<
              {
                pf_kap_project_by_pk: Query_Root['pf_kap_project_by_pk']
              },
              {
                id: number
              }
            >(queryProjectBaseByPfKapProjectId, { id })
            .toPromise()

          if (data && data.pf_kap_project_by_pk) {
            const pf_kap_project_base_data = data?.pf_kap_project_by_pk?.project_base
            setProjectBase(pf_kap_project_base_data)

            const initialValue = initialValuesFactoryInitialPageAndVision(
              pf_kap_project_base_data.id,
              pf_kap_project_base_data.sources,
              data?.pf_kap_project_by_pk?.version,
            )
            setInitialValue(initialValue)
          } else {
            setError()
          }
          break
        }
        case '/pf-pgv': {
          const { data } = await urqlClient
            .query<
              {
                pf_pgv_project_by_pk: Query_Root['pf_pgv_project_by_pk']
              },
              {
                id: number
              }
            >(queryProjectBaseByPfPgvProjectId, { id })
            .toPromise()

          const pf_pgv_project_base_data = data?.pf_pgv_project_by_pk?.project_base
          setProjectBase(pf_pgv_project_base_data)

          if (data && data.pf_pgv_project_by_pk) {
            const pf_kap_project_base_data = data?.pf_pgv_project_by_pk?.project_base
            setProjectBase(pf_kap_project_base_data)

            const initialValue = initialValuesFactoryInitialPageAndVision(
              pf_kap_project_base_data.id,
              pf_kap_project_base_data.sources,
              data?.pf_pgv_project_by_pk?.version,
            )
            setInitialValue(initialValue)
          } else {
            setError()
          }
          break
        }
        default:
          throw Error(`Unsupported base url [${baseUrl}]`)
      }
    }
    initializeFormValues()
  }, [baseUrl, getMessage, id, projectId, urqlClient, setError])

  const onSubmit = (origin: originType) => (_: any) => {
    // trigger submit event
    if (originRef) {
      originRef.current = origin
    }
    document.getElementById('edit-form')?.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }))
  }

  const onBack = () => {
    const route = resolveDetailsRoot(baseUrl).nested.StartingPointAndVision.params({ projectId })
    navigate(`${route}#sources`)
  }

  const onUpdateHandler = async (values: any) => {
    const updateObject = {
      projectId: id,
      projectBaseId: projectBase?.id,
      sources: HtmlSanitizer.sanitize(values.value),
      version: values.version,
    }

    let mutationError
    let projectBaseData
    let affectedRows
    let newVersion

    switch (baseUrl) {
      case '/pf-kap': {
        const { error, data } = await urqlClient
          .mutation<{ update_pf_kap_project: Mutation_Root['update_pf_kap_project'] }>(
            updateSourcesPfKapByProjectId,
            updateObject,
          )
          .toPromise()

        mutationError = error
        projectBaseData = data
        affectedRows = projectBaseData?.update_pf_kap_project?.affected_rows
        newVersion = projectBaseData?.update_pf_kap_project?.returning[0]?.version
        break
      }
      case '/pf-pgv': {
        const { error, data } = await urqlClient
          .mutation<{ update_pf_pgv_project: Mutation_Root['update_pf_pgv_project'] }>(
            updateSourcesPfPgvByProjectId,
            updateObject,
          )
          .toPromise()

        mutationError = error
        projectBaseData = data
        affectedRows = projectBaseData?.update_pf_pgv_project?.affected_rows
        newVersion = projectBaseData?.update_pf_pgv_project?.returning[0]?.version
        break
      }
      default:
        throw Error(`Unsupported base url [${baseUrl}]`)
    }

    if (mutationError || affectedRows !== 1) {
      notificationService.operationFailed()
    } else if (isMounted()) {
      const newInitialValues = { ...values, version: newVersion }
      setInitialValue(newInitialValues)
      notificationService.changesSaved()
      if (originRef.current === 'SAVE_AND_BACK') {
        onBack()
      }
    }
  }

  if (!loading && !canEdit) {
    return <NotAuthorized />
  }

  return (
    <ScreenLayout
      title={pageTitle}
      hasSecondLevelNavigation={false}
      onBack={onBack}
      actions={
        <>
          <SaveAndBackButton origin="header" onClick={onSubmit('SAVE_AND_BACK')} />
          <SaveButton origin="header" onClick={onSubmit('SAVE')} />
        </>
      }
    >
      <PageLayout>
        <>
          {!loading && initialValue && (
            <>
              <HelpAndInstructions labelKey={`label.help.and.instructions.sources.${process}`} defaultExpansion />
              <InitialSituationAndVisionFieldEditForm
                initialValue={initialValue}
                onSave={onUpdateHandler}
                textLength={TEXT_LENGTH.XL}
                label={getMessage('label.sources')}
              />
            </>
          )}
        </>
      </PageLayout>
    </ScreenLayout>
  )
}
