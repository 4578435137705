import { gql } from '@urql/core'

export const queryApplicationInformationByProjectBaseId = gql`
  query ($projectBaseId: Int!) {
    project_application_decision(where: { project_base_id: { _eq: $projectBaseId } }) {
      application_decision
      version
    }
    dossier(where: { project_bases: { id: { _eq: $projectBaseId } } }) {
      status
      funding_round_id
      funding_round {
        name
        decision_date
      }
    }
  }
`

export const saveProjectApplicationInformation = gql`
  mutation (
    $projectBaseId: Int!
    $version: Int!
    $projectApplicationDecision: [project_application_decision_insert_input!]!
    $fundingRoundId: Int!
  ) {
    update_dossier(
      where: { project_bases: { id: { _eq: $projectBaseId } } }
      _set: { funding_round_id: $fundingRoundId }
    ) {
      affected_rows
    }
    insert_project_application_decision(
      objects: $projectApplicationDecision
      on_conflict: {
        constraint: project_application_decision_project_base_id_uq
        update_columns: [application_decision]
        where: { _and: { project_base: { id: { _eq: $projectBaseId } }, version: { _eq: $version } } }
      }
    ) {
      returning {
        id
        version
      }
      affected_rows
    }
  }
`

export const queryAllFundingRounds = gql`
  query ($process: String!, $projectId: Int!, $isPfKap: Boolean!) {
    funding_round(where: { process: { _eq: $process } }, order_by: { start_date: desc }) {
      id
      name
      process
      application_types
      start_date
      end_date
      decision_date
    }
    pf_kap_project_by_pk(id: $projectId) @include(if: $isPfKap) {
      application_type {
        code
      }
    }
    pf_pgv_project_by_pk(id: $projectId) @skip(if: $isPfKap) {
      application_type {
        code
      }
    }
  }
`

export const fetchKapProgramApplicationInfoQuery = gql`
  query fetchKapProgramApplicationInfo($id: Int!, $file: Boolean!, $fileType: String, $entityType: String) {
    kap_program_by_pk(id: $id) {
      board_meeting_date
      application_decision
    }
    file(
      where: { file_type: { _eq: $fileType }, entity_type: { _eq: $entityType }, entity_id: { _eq: $id } }
      order_by: { upload_date: desc }
    ) @include(if: $file) {
      id
      file_name
      file_title
      upload_date
      uuid
    }
  }
`

export const fetchKapProgramApplicationSummaryQuery = gql`
  query fetchKapProgramApplicationSummary($id: Int!, $fileType: String!, $entityType: String!) {
    kap_program_by_pk(id: $id) {
      id
      board_meeting_date
      application_decision
      application_comment
      dossier {
        status
        project_workflow_id
      }
    }
    file(
      where: { file_type: { _eq: $fileType }, entity_type: { _eq: $entityType }, entity_id: { _eq: $id } }
      order_by: { upload_date: desc }
    ) {
      id
      file_name
      file_title
      upload_date
      uuid
    }
  }
`

export const fetchKapProgramApplicationCommentQuery = gql`
  query fetchKapProgramApplicationComment($id: Int!) {
    kap_program_by_pk(id: $id) {
      application_comment
    }
  }
`

export const updateKapProgramApplicationInfoMutation = gql`
  mutation updateKapProgramApplicationInfo($id: Int!, $boardMeetingDate: date, $applicationDecision: String!) {
    update_kap_program(
      where: { id: { _eq: $id } }
      _set: { board_meeting_date: $boardMeetingDate, application_decision: $applicationDecision }
    ) {
      affected_rows
    }
  }
`

export const updateKapProgramApplicationCommentMutation = gql`
  mutation updateKapProgramApplicationComment($id: Int!, $applicationComment: String) {
    update_kap_program(where: { id: { _eq: $id } }, _set: { application_comment: $applicationComment }) {
      affected_rows
    }
  }
`
