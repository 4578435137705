import { gql } from '@urql/core'

export const queryPfKapProjectUsersAndUserType = gql`
  query ($id: Int!) {
    projectUsers: pf_kap_project_by_pk(id: $id) {
      project_base {
        project_users(order_by: { user: { email: asc } }) {
          user {
            first_name
            last_name
            email
            status
            id
          }
          type
        }
        id
      }
    }
  }
`

export const insertProjectUser = gql`
  mutation ($projectBaseId: Int!, $email: String!, $type: UserProjectRole!) {
    addUserToProject(projectBaseId: $projectBaseId, email: $email, type: $type) {
      status
    }
  }
`

export const queryPfPgvProjectUsersAndUserType = gql`
  query ($id: Int!) {
    projectUsers: pf_pgv_project_by_pk(id: $id) {
      project_base {
        project_users(order_by: { user: { email: asc } }) {
          user {
            first_name
            last_name
            email
            status
            id
          }
          type
        }
        id
      }
    }
  }
`

export const deleteUserFromProject = gql`
  mutation ($projectBaseId: Int!, $userId: Int!) {
    delete_project_user(where: { _and: { project_base_id: { _eq: $projectBaseId }, user_id: { _eq: $userId } } }) {
      affected_rows
    }
  }
`
