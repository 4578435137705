import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import { get } from 'lodash'
import React, { ReactElement } from 'react'
import { Query_Root } from 'src/@types/graphql'
import { useMessageSource } from 'src/i18n/useMessageSource'
import { useUserLocale } from 'src/user/UserContext'

export interface CriteriaConfigRow {
  criteria_config_id: number
  criteria: string
  question: string
}

interface Props {
  groupTitle: string
  mode: 'view' | 'edit'
  rows: Query_Root['criteria_config']
  EditControl: React.FC<any>
  resolveControlProps: (row: any) => any
}

export const CriteriaConfigTable = ({
  groupTitle,
  rows,
  mode = 'view',
  EditControl,
  resolveControlProps,
}: Props): ReactElement => {
  const { getMessage } = useMessageSource()
  const language = useUserLocale()

  return (
    <Box>
      <Typography variant="h3" color="textSecondary" mb={1.5} sx={{ flexGrow: 1 }}>
        {groupTitle}
      </Typography>
      <TableContainer component={Paper}>
        <Table style={{ overflow: 'wrap' }}>
          <TableHead>
            <TableRow>
              <TableCell align="center" sx={{ width: '10%' }}>
                {getMessage('label.criteria')}
              </TableCell>
              <TableCell sx={{ width: '75%' }}>{getMessage('label.question')}</TableCell>
              <TableCell align="center" sx={{ width: '15%' }}>
                {getMessage('label.rating')}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((item) => (
              <TableRow key={item.id.toString()}>
                <TableCell align="center">
                  <Typography variant="subtitle2">{item.criteria_id}</Typography>
                </TableCell>
                <TableCell>{get(item.description, language, '')}</TableCell>
                <TableCell align="center" sx={{ lineHeight: '100%' }}>
                  {resolveControlProps(item).hide || (
                    <EditControl disabled={mode === 'view'} {...resolveControlProps(item)} />
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  )
}
