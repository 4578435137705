import { Stack } from '@mui/material'
import Box from '@mui/material/Box'
import { ReactElement, useEffect, useState } from 'react'
import { Form } from 'react-final-form'
import { FormValidationErrors } from 'src/@types/global'
import { Project_Measure, Query_Root } from 'src/@types/graphql'
import { useMessageSource } from 'src/i18n/useMessageSource'
import { queryGoalsByProject } from 'src/screens/shared/measure/measureQueries'
import { TEXT_LENGTH } from 'src/shared/constants/constants'
import { Option } from 'src/shared/form/control'
import { DateTimePickerField } from 'src/shared/form/control/DateTimePickerField'
import { HtmlEditorField } from 'src/shared/form/control/HtmlEditorField'
import { MultiSelectField } from 'src/shared/form/control/MultiSelectField'
import { TextField } from 'src/shared/form/control/TextField'
import { DirtyFormSpy } from 'src/shared/form/dirty/DirtyFormSpy'
import { createDecorators } from 'src/shared/form/utils/decorators'
import { composeValidators, maxChar, required, validDate } from 'src/shared/form/validation/validators'
import { DateUtils } from 'src/shared/utils/DateUtils'
import { HtmlSanitizer } from 'src/shared/utils/HtmlSanitizer'
import { useClient } from 'urql'

export interface MeasureFormValues {
  name: string
  description: string
  sortNumber: number
  projectBaseId: number
  measureId?: number
  version?: number
  goalIds?: number[]
  startDate?: string
  endDate?: string
}

export const initialValuesFactory = (projectBaseId: number, measure?: Project_Measure): MeasureFormValues => {
  const selectedGoalIds =
    measure?.project_goal_measures.map((project_goal_measure) => project_goal_measure.project_goal.id) ?? []
  return {
    name: measure?.name || '',
    description: HtmlSanitizer.sanitize(measure?.description),
    sortNumber: measure?.sort_number || 1,
    projectBaseId: projectBaseId,
    measureId: measure?.id || undefined,
    version: measure?.version || 0,
    goalIds: selectedGoalIds,
    startDate: measure?.start_date ? DateUtils.parseAndFormatDate(measure.start_date) : measure?.start_date,
    endDate: measure?.end_date ? DateUtils.parseAndFormatDate(measure.end_date) : measure?.end_date,
  }
}

interface ProjectMeasureProps {
  initialValues: MeasureFormValues
  onSave: (values: MeasureFormValues) => Promise<void>
  requiredRelatedGoals?: boolean
}

const decorators = createDecorators()

export const MeasureForm = ({ initialValues, onSave, requiredRelatedGoals }: ProjectMeasureProps): ReactElement => {
  const { getMessage } = useMessageSource()
  const urqlClient = useClient()
  const projectBaseId = initialValues.projectBaseId
  const [goalOptions, setGoalOptions] = useState<Option[] | undefined>()

  useEffect(() => {
    const initializeGoalOptions = async () => {
      const { data } = await urqlClient
        .query<{ project_base: Query_Root['project_base'] }, { projectBaseId: number }>(queryGoalsByProject, {
          projectBaseId: projectBaseId,
        })
        .toPromise()

      if (data && data.project_base) {
        const options =
          data?.project_base[0].project_goals.map((project_goal) => ({
            label: `${getMessage('label.goal.id.character')}${project_goal.sort_number}: ${project_goal.name}`,
            value: project_goal.id,
            sortNumber: project_goal.sort_number || 0,
          })) ?? []
        setGoalOptions(options)
      }
    }
    initializeGoalOptions()
  }, [getMessage, urqlClient, projectBaseId, setGoalOptions])

  const validateForm = (values: MeasureFormValues): FormValidationErrors<MeasureFormValues> => {
    const error: FormValidationErrors<MeasureFormValues> = {}

    const startDate = values.startDate ? DateUtils.parseDate(values.startDate) : null
    const endDate = values.endDate ? DateUtils.parseDate(values.endDate) : null

    if (
      startDate &&
      endDate &&
      DateUtils.valid(startDate) &&
      DateUtils.valid(endDate) &&
      DateUtils.after(startDate, endDate)
    ) {
      error.endDate = { errorKey: 'validation.measure.date.end.before.start' }
    }

    return error
  }

  return (
    <Box>
      {initialValues && goalOptions && (
        <Form<MeasureFormValues>
          validate={validateForm}
          initialValues={initialValues}
          onSubmit={onSave}
          decorators={decorators}
          render={({ handleSubmit }) => {
            return (
              <form id="measure-form" onSubmit={handleSubmit}>
                <Stack spacing={2}>
                  <TextField
                    required
                    label={getMessage('label.title')}
                    type="text"
                    name="name"
                    validate={composeValidators(required(), maxChar(TEXT_LENGTH.S))}
                  />
                  <HtmlEditorField
                    required
                    label={getMessage('label.description')}
                    name="description"
                    validate={composeValidators(required(), maxChar(TEXT_LENGTH.L))}
                  />
                  <DateTimePickerField
                    label={getMessage('label.start.date')}
                    name="startDate"
                    validate={composeValidators(validDate())}
                  />
                  <DateTimePickerField
                    label={getMessage('label.end.date')}
                    name="endDate"
                    validate={composeValidators(validDate())}
                  />
                  <MultiSelectField
                    required={requiredRelatedGoals}
                    options={goalOptions}
                    name="goalIds"
                    label={getMessage('label.related.goals')}
                    unavailableOptionsMessage={getMessage('label.related.goals.add.goals')}
                    validate={requiredRelatedGoals && required()}
                  />
                  <DirtyFormSpy />
                </Stack>
              </form>
            )
          }}
        />
      )}
    </Box>
  )
}
