import { Card, CardContent } from '@mui/material'
import { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { Query_Root } from 'src/@types/graphql'
import { useMessageSource } from 'src/i18n/useMessageSource'
import { resolveFinalReportMilestoneRoot } from 'src/routing/routing-utils'
import { getMilestoneAssessmentCommentsQuery } from 'src/screens/shared/assessment-criteria/assessmentQueries'
import { EditButton } from 'src/shared/button/Buttons'
import { ASSESSMENT_TYPE, ASSESSMENT_TYPE_TYPE } from 'src/shared/constants/assessment-constants'
import { HelpAndInstructions } from 'src/shared/presentation/HelpAndInstructions'
import { HtmlRenderer } from 'src/shared/presentation/HtmlRenderer'
import { Section } from 'src/shared/presentation/Section'
import { useNotificationService } from 'src/shared/utils/NotificationService'
import { Utils } from 'src/shared/utils/Utils'
import { useDelayedNavigate } from 'src/shared/utils/hooks/navigation-hooks'
import { useClient } from 'urql'

interface Props {
  baseUrl: '/pf-kap' | '/pf-pgv'
  type: ASSESSMENT_TYPE_TYPE
  canEdit: boolean
}

export const MilestoneAssessmentCommentSection = ({ baseUrl, type, canEdit }: Props) => {
  const { projectId, milestoneId, milestonePath } = useParams()
  const { getMessage } = useMessageSource()
  const navigate = useDelayedNavigate()
  const urqlClient = useClient()
  const notificationService = useNotificationService()

  const projectType = Utils.resolveProcess(baseUrl)

  const [comment, setComment] = useState<string | null>(null)

  const fetch = useCallback(async () => {
    const id = parseInt(milestoneId as string)
    const { data } = await urqlClient
      .query<{ milestone_assessment: Query_Root['milestone_assessment'] }, { id: number; type: ASSESSMENT_TYPE_TYPE }>(
        getMilestoneAssessmentCommentsQuery,
        {
          id,
          type,
        },
      )
      .toPromise()

    if (data) {
      const comment = data?.milestone_assessment?.[0]?.comment
      setComment(comment || null)
    } else {
      notificationService.operationFailed()
    }
  }, [urqlClient, notificationService, milestoneId, type])

  useEffect(() => {
    fetch()
  }, [fetch])

  const onEdit = () => {
    const root = resolveFinalReportMilestoneRoot(baseUrl).nested
    const internalCommentRoute = root.InternalAssessmentCommentEdit
    const externalCommentRoute = root.ExternalAssessmentCommentEdit
    const route = type === ASSESSMENT_TYPE.INTERNAL ? internalCommentRoute : externalCommentRoute
    navigate(route.params({ projectId, milestoneId, milestonePath }))
  }

  return (
    <Section
      id="comment"
      title={getMessage('label.summary.comments')}
      helpAndInstructions={
        <HelpAndInstructions labelKey={`label.help.assessment.${type.toLowerCase()}.comment.${projectType}`} />
      }
      actionButton={<EditButton origin="content" color="primary" hidden={!canEdit} onClick={onEdit} />}
      optional
    >
      {comment && (
        <Card>
          <CardContent>
            <HtmlRenderer html={comment} />
          </CardContent>
        </Card>
      )}
    </Section>
  )
}
