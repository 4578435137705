import combinedQuery from 'graphql-combine-query'
import { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router'
import { Mutation_Root, Query_Root } from 'src/@types/graphql'
import { useMessageSource } from 'src/i18n/useMessageSource'
import { OrganizationForm } from 'src/screens/shared/organization/edit/OrganizationForm'
import {
  queryOrganizationById,
  queryOrganizationTypes,
  updateOrganizationMutationQuery,
} from 'src/screens/shared/organization/organizationQueries'
import {
  initialValuesFactory,
  OrganizationFormValues,
  OrganizationType,
} from 'src/screens/shared/organization/utils/OrganizationFormUtils'
import { SaveAndBackButton, SaveButton } from 'src/shared/button/Buttons'
import { PROJECT, PROJECT_TYPE } from 'src/shared/constants/constants'
import { PageLayout } from 'src/shared/layout/PageLayout'
import { ScreenLayout } from 'src/shared/layout/ScreenLayout'
import { HelpAndInstructions } from 'src/shared/presentation/HelpAndInstructions'
import { useError } from 'src/shared/utils/hooks/page-loading-error-hook'
import { useNotificationService } from 'src/shared/utils/NotificationService'
import { useClient } from 'urql'

type originType = 'SAVE' | 'SAVE_AND_BACK'

interface Props {
  projectType: PROJECT_TYPE
  pageTitle: string
  helpAndInstructionsTitle: string
  onBack: () => void
  loading: boolean
}

export const BaseOrganizationEdit = ({ projectType, pageTitle, helpAndInstructionsTitle, onBack, loading }: Props) => {
  const { projectId, programId, organizationId } = useParams()
  const entity_id = projectType === PROJECT.KAP ? parseInt(programId as string) : parseInt(projectId as string)
  const organization_id = parseInt(organizationId as string)

  const { getMessage } = useMessageSource()
  const urqlClient = useClient()
  const notificationService = useNotificationService()
  const setError = useError()

  const [initialValues, setInitialValues] = useState<OrganizationFormValues>()
  const [organizationTypeOptions, setOrganizationTypeOptions] = useState<OrganizationType[]>([])

  const originRef = useRef<originType>('SAVE')

  useEffect(() => {
    const initializeFormValues = async () => {
      const { document, variables } = combinedQuery('OrganizationAndTypes')
        .add(queryOrganizationById, {
          id: organization_id,
        })
        .add(queryOrganizationTypes, { process: projectType })

      const { data } = await urqlClient
        .query<{
          organization_type: Query_Root['organization_type']
          organization_by_pk: Query_Root['organization_by_pk']
        }>(document, variables)
        .toPromise()

      if (data && data.organization_type && data.organization_by_pk) {
        const organizationTypes = data.organization_type.map((organization_type) => {
          return {
            label: getMessage(organization_type.key),
            value: organization_type.id,
          }
        })
        setOrganizationTypeOptions(organizationTypes)
        const organization = initialValuesFactory(entity_id, data.organization_by_pk)
        setInitialValues(organization)
      } else {
        setError()
      }
    }

    initializeFormValues()
  }, [organization_id, entity_id, projectType, urqlClient, getMessage, setError])

  const onUpdateHandler = async (values: any) => {
    const mutationObject = {
      id: values.organizationId,
      version: values.version,
      name: values.name,
      address: values.address,
      additionalAddress: values.additionalAddress,
      city: values.city,
      email: values.email,
      phone: values.phone,
      postalCode: values.postalCode,
      typeId: values.typeId,
      department: values.department,
      website: values.website,
    }

    const { error, data } = await urqlClient
      .mutation<{ update_organization: Mutation_Root['update_organization'] }>(
        updateOrganizationMutationQuery,
        mutationObject,
      )
      .toPromise()

    if (error || data?.update_organization?.affected_rows !== 1) {
      notificationService.operationFailed()
    } else {
      setInitialValues(values)
      notificationService.changesSaved()
      if (originRef.current === 'SAVE_AND_BACK') {
        onBack()
      }
    }
  }

  const onSubmit = (origin: originType) => (_: any) => {
    // trigger submit event
    if (originRef) {
      originRef.current = origin
    }
    document
      .getElementById('organization-form')
      ?.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }))
  }

  return (
    <ScreenLayout
      title={pageTitle}
      hasSecondLevelNavigation={false}
      onBack={onBack}
      actions={
        <>
          <SaveAndBackButton origin="header" onClick={onSubmit('SAVE_AND_BACK')} />
          <SaveButton origin="header" onClick={onSubmit('SAVE')} />
        </>
      }
    >
      <PageLayout>
        <>
          {!loading && initialValues && (
            <>
              <HelpAndInstructions labelKey={helpAndInstructionsTitle} defaultExpansion />
              <OrganizationForm
                projectType={projectType}
                initialValues={initialValues}
                organizationTypes={organizationTypeOptions}
                onSave={onUpdateHandler}
              />
            </>
          )}
        </>
      </PageLayout>
    </ScreenLayout>
  )
}
