import { Box, Divider, Typography } from '@mui/material'
import { useMessageSource } from 'src/i18n/useMessageSource'
import { ROUTES } from 'src/routing/routes'
import { SettingsIcon } from 'src/shared/icons/Icons'
import { PAGE_HEADER_HEIGHT } from 'src/shared/layout/ScreenLayout'
import { Sidebar, SidebarGroupObject } from 'src/shared/sidebar/Sidebar'
import styled from 'styled-components/macro'

const BoxStyled = styled(Box)`
  height: ${PAGE_HEADER_HEIGHT};
  display: flex;
  align-items: center;
  padding-block: ${({ theme }) => theme.spacing(1)};
  padding-inline: ${({ theme }) => theme.spacing(3)};
  gap: ${({ theme }) => theme.spacing(2)};

  & .MuiTypography-root {
    line-height: 24px;
  }
`

export const AdministrationSidebar = () => {
  const { getMessage } = useMessageSource()

  const routeGroups: SidebarGroupObject[] = [
    {
      groupMessageKey: 'label.administration',
      list: [
        {
          listMessageKey: 'label.navigation.round.management',
          href: ROUTES.RoundManagementIndex.params({}),
          routePath: ROUTES.RoundManagementIndex.path,
        },
        {
          listMessageKey: 'label.navigation.gfch.user.administration',
          href: ROUTES.UserAdministrationIndex.params({}),
          routePath: ROUTES.UserAdministrationIndex.path,
        },
      ],
    },
  ]

  return (
    <>
      <BoxStyled>
        <SettingsIcon />
        <Typography variant="subtitle2">{getMessage('label.administration')}</Typography>
      </BoxStyled>
      <Divider />
      <Sidebar routes={routeGroups} hideDetailedInformation />
    </>
  )
}
