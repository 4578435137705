import { differenceWith, isEqual } from 'lodash'
import { Feature_Config } from 'src/@types/graphql'

export const differenceFeaturesList = (
  array: Feature_Config[] | null | undefined,
  values: Feature_Config[],
): Feature_Config[] => {
  return differenceWith(array, values, isEqual) ?? []
}

export const FeatureBaseUtils = {
  differenceFeaturesList,
}
