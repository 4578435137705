import { Typography } from '@mui/material'
import { GridColDef, useGridApiRef } from '@mui/x-data-grid-pro'
import { ReactElement, useCallback, useMemo } from 'react'
import {
  Project_Goal,
  Project_Goal_Annual_Report,
  Project_Measure,
  Project_Measure_Annual_Report,
} from 'src/@types/graphql'
import { useMessageSource } from 'src/i18n/useMessageSource'
import { DefaultSectionTypography } from 'src/shared/presentation/DefaultSectionTypography'
import { S } from 'src/shared/styled/S'
import { useGridTranslateHook } from 'src/shared/utils/hooks/grid-translate-hook'
import { resolveGoalRatingColor, resolveProjectMeasureRatingColor } from 'src/shared/utils/StylingUtils'

interface Props {
  values: (Project_Measure & Project_Measure_Annual_Report)[] | (Project_Goal & Project_Goal_Annual_Report)[]
  type: 'goal' | 'measure'
}

export const MilestoneRelatedGoalsMeasuresGrid = ({ values, type }: Props): ReactElement => {
  const gridTranslations = useGridTranslateHook()
  const gridRef = useGridApiRef()
  const { getMessage } = useMessageSource()

  const resolveRatingColor = useCallback(
    (value: string) => {
      return type === 'goal' ? resolveGoalRatingColor(value) : resolveProjectMeasureRatingColor(value)
    },
    [type],
  )

  const rows = useMemo(() => {
    return values.map((value) => {
      return {
        id: `${getMessage(`label.${type}.id.character`)}${value.sort_number}`,
        title: value.name,
        rating: value.rating,
      }
    })
  }, [values, type, getMessage])

  const columns = useMemo<GridColDef[]>(
    () => [
      {
        field: 'id',
        flex: 0.5,
        headerName: getMessage('label.id'),
      },
      {
        field: 'title',
        flex: 2,
        headerName: getMessage('label.title'),
      },
      {
        field: 'rating',
        flex: 0.8,
        headerName: getMessage('label.rating'),
        renderCell: ({ value }) => {
          if (value) {
            return (
              <Typography color={resolveRatingColor(value)}>{getMessage(`label.${type}.rating.${value}`)}</Typography>
            )
          }
          return <DefaultSectionTypography $standAlone />
        },
      },
    ],
    [type, resolveRatingColor, getMessage],
  )

  return (
    <S.DataGrid.ViewEditMode
      rows={rows}
      columns={columns}
      autoHeight
      apiRef={gridRef}
      localeText={gridTranslations}
      disableColumnSelector
      disableColumnReorder
      disableColumnPinning
      disableRowSelectionOnClick
      hideFooter
      $viewMode
      sx={{ marginTop: 1 }}
    />
  )
}
