import { Box } from '@mui/material'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Form } from 'react-final-form'
import { useParams } from 'react-router'
import { Milestone_Assessment_Insert_Input, Mutation_Root, Query_Root } from 'src/@types/graphql'
import { useMessageSource } from 'src/i18n/useMessageSource'
import { resolveFinalReportMilestoneRoot } from 'src/routing/routing-utils'
import {
  getMilestoneAssessmentCommentsQuery,
  upsertMilestoneAssessmentCommentsMutation,
} from 'src/screens/shared/assessment-criteria/assessmentQueries'
import { queryMilestoneById } from 'src/screens/shared/implementation/details/milestoneQueries'
import { usePermissionsForFinalMilestone } from 'src/service/security/PermissionHook'
import { SaveAndBackButton, SaveButton } from 'src/shared/button/Buttons'
import { ASSESSMENT_TYPE, ASSESSMENT_TYPE_TYPE } from 'src/shared/constants/assessment-constants'
import { TEXT_LENGTH } from 'src/shared/constants/constants'
import { MILESTONE_RESPONSIBLE_TYPE_TYPE, MILESTONE_STATUS_TYPE } from 'src/shared/constants/milestone-constants'
import { HtmlEditorField } from 'src/shared/form/control/HtmlEditorField'
import { DirtyFormSpy } from 'src/shared/form/dirty/DirtyFormSpy'
import { maxChar } from 'src/shared/form/validation/validators'
import { PageLayout } from 'src/shared/layout/PageLayout'
import { ScreenLayout } from 'src/shared/layout/ScreenLayout'
import { NotAuthorized } from 'src/shared/not-authorized/NotAuthorized'
import { HelpAndInstructions } from 'src/shared/presentation/HelpAndInstructions'
import { HtmlSanitizer } from 'src/shared/utils/HtmlSanitizer'
import { useNotificationService } from 'src/shared/utils/NotificationService'
import { Utils } from 'src/shared/utils/Utils'
import { useDelayedNavigate } from 'src/shared/utils/hooks/navigation-hooks'
import { useClient, useQuery } from 'urql'

interface Props {
  baseUrl: '/pf-kap' | '/pf-pgv'
  type: ASSESSMENT_TYPE_TYPE
}

type originType = 'SAVE' | 'SAVE_AND_BACK'

export const MilestoneAssessmentCommentEditPage = ({ baseUrl, type }: Props) => {
  const { projectId, milestoneId, milestonePath } = useParams()
  const project_id = parseInt(projectId as string)
  const milestone_id = parseInt(milestoneId as string)
  const { getMessage } = useMessageSource()
  const navigate = useDelayedNavigate()
  const urqlClient = useClient()
  const notificationService = useNotificationService()

  const projectType = Utils.resolveProcess(baseUrl)

  const originRef = useRef<originType>('SAVE')

  const [comment, setComment] = useState<string | null>(null)

  const fetch = useCallback(async () => {
    const id = parseInt(milestoneId as string)
    const { data } = await urqlClient
      .query<{ milestone_assessment: Query_Root['milestone_assessment'] }, { id: number; type: ASSESSMENT_TYPE_TYPE }>(
        getMilestoneAssessmentCommentsQuery,
        {
          id,
          type,
        },
      )
      .toPromise()

    if (data) {
      const comment = data?.milestone_assessment?.[0]?.comment
      setComment(comment || null)
    } else {
      notificationService.operationFailed()
    }
  }, [urqlClient, notificationService, milestoneId, type])

  useEffect(() => {
    fetch()
  }, [fetch])

  const [{ data, error }] = useQuery<{ milestone_by_pk: Query_Root['milestone_by_pk'] }, { id: number }>({
    query: queryMilestoneById,
    variables: { id: milestone_id },
  })

  if (error) {
    notificationService.operationFailed()
  }

  const milestone = data?.milestone_by_pk

  const { loading, canEdit, canEditInternalAssessment } = usePermissionsForFinalMilestone(
    projectType,
    project_id,
    milestone?.status as MILESTONE_STATUS_TYPE,
    milestone?.responsible_type as MILESTONE_RESPONSIBLE_TYPE_TYPE,
  )

  const onBack = () => {
    const root = resolveFinalReportMilestoneRoot(baseUrl).nested
    const internalAssessmentRoute = root.InternalAssessment
    const externalAssessmentRoute = root.ExternalAssessment
    const route = type === ASSESSMENT_TYPE.INTERNAL ? internalAssessmentRoute : externalAssessmentRoute
    navigate(route.params({ projectId, milestoneId, milestonePath }))
  }

  const onSubmit = (origin: originType) => (_: any) => {
    // trigger submit event
    if (originRef) {
      originRef.current = origin
    }
    document.getElementById('edit-form')?.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }))
  }

  const onSave = async ({ comment }: { comment: string | null }) => {
    const object: Milestone_Assessment_Insert_Input = {
      milestone_id: parseInt(milestoneId as string),
      assessor_type: type,
      comment: HtmlSanitizer.sanitize(comment ?? ''),
    }

    const { data } = await urqlClient
      .mutation<
        { insert_milestone_assessment_one: Mutation_Root['insert_milestone_assessment_one'] },
        { object: Milestone_Assessment_Insert_Input }
      >(upsertMilestoneAssessmentCommentsMutation, {
        object,
      })
      .toPromise()

    if (data && data.insert_milestone_assessment_one?.id) {
      setComment(comment)
      notificationService.changesSaved()
      if (originRef.current === 'SAVE_AND_BACK') {
        onBack()
      }
    } else {
      notificationService.operationFailed()
    }
  }

  const canEditComment = useMemo(
    () => (type === ASSESSMENT_TYPE.INTERNAL ? canEditInternalAssessment : canEdit),
    [canEdit, canEditInternalAssessment, type],
  )

  if (!loading && !canEditComment) {
    return <NotAuthorized inEditPage />
  }

  return (
    <ScreenLayout
      title={getMessage('label.title.edit.comments')}
      onBack={onBack}
      hasSecondLevelNavigation={false}
      actions={
        <>
          <SaveAndBackButton origin="header" onClick={onSubmit('SAVE_AND_BACK')} />
          <SaveButton origin="header" onClick={onSubmit('SAVE')} />
        </>
      }
    >
      <PageLayout>
        <>
          {!loading && (
            <>
              <HelpAndInstructions
                labelKey={`label.help.assessment.${type.toLowerCase()}.comment.${projectType}`}
                defaultExpansion
              />
              <Box>
                <Form<{ comment: string | null }>
                  initialValues={{ comment }}
                  onSubmit={onSave}
                  render={({ handleSubmit }) => {
                    return (
                      <form id="edit-form" onSubmit={handleSubmit}>
                        <HtmlEditorField
                          name="comment"
                          validate={maxChar(TEXT_LENGTH.XL)}
                          label={getMessage('label.summary.comments')}
                        />
                        <DirtyFormSpy />
                      </form>
                    )
                  }}
                />
              </Box>
            </>
          )}
        </>
      </PageLayout>
    </ScreenLayout>
  )
}
