import { Box, Stack } from '@mui/material'
import { get } from 'lodash'
import { Form } from 'react-final-form'
import { useMessageSource } from 'src/i18n/useMessageSource'
import { CriteriaConfigTable } from 'src/screens/shared/application/assessment/details/CriteriaConfigTable'
import {
  CriteriaFormValues,
  CriteriaGroups,
  QUALITY_PREFIX,
} from 'src/screens/shared/assessment-criteria/utils/AssessmentUtils'
import { SaveAndBackButton, SaveButton } from 'src/shared/button/Buttons'
import { Option } from 'src/shared/form/control'
import { AutoCompleteField } from 'src/shared/form/control/AutoCompleteField'
import { DirtyFormSpy } from 'src/shared/form/dirty/DirtyFormSpy'
import { PageLayout } from 'src/shared/layout/PageLayout'
import { ScreenLayout } from 'src/shared/layout/ScreenLayout'
import { HelpAndInstructions } from 'src/shared/presentation/HelpAndInstructions'
import { Section } from 'src/shared/presentation/Section'
import { useUserLocale } from 'src/user/UserContext'
import styled from 'styled-components/macro'

const BoxStyled = styled(Box)`
  .MuiFormHelperText-root {
    display: none;
  }
`

interface Props {
  screenLayoutText: string
  helpText: string
  criteriaGroups: CriteriaGroups | undefined
  initialValues: CriteriaFormValues | undefined
  handleSubmitLocal: (values: CriteriaFormValues) => Promise<any>
  onBack: () => void
  loading: boolean
}

export const BaseAssessmentCriteriaEdit = ({
  screenLayoutText,
  helpText,
  criteriaGroups,
  initialValues,
  handleSubmitLocal,
  onBack,
  loading,
}: Props) => {
  const { getMessage } = useMessageSource()
  const language = useUserLocale()

  let submit: any = () => {}
  let formValid = false

  const onSave = (event: any) => {
    submit(event)
  }

  const onSaveAndBack = async (event: any) => {
    try {
      await submit(event)
      formValid && onBack()
    } catch {
      // do nothing
    }
  }

  const ratingOptions: Option[] = [
    { label: '++', value: '++' },
    { label: '+', value: '+' },
    { label: '-', value: '-' },
    { label: '--', value: '--' },
    { label: getMessage('label.field.not.available'), value: null },
  ]

  return (
    <ScreenLayout
      title={screenLayoutText}
      onBack={onBack}
      hasSecondLevelNavigation={false}
      actions={
        <>
          <SaveAndBackButton origin="header" onClick={onSaveAndBack} />
          <SaveButton origin="header" onClick={onSave} />
        </>
      }
    >
      <PageLayout>
        {!loading && (
          <Section
            id="assessment-criteria"
            helpAndInstructions={<HelpAndInstructions labelKey={helpText} defaultExpansion />}
          >
            <BoxStyled mt={2}>
              <Form<CriteriaFormValues>
                initialValues={initialValues}
                onSubmit={handleSubmitLocal}
                render={({ valid, handleSubmit }) => {
                  submit = handleSubmit
                  formValid = valid
                  return (
                    <form onSubmit={handleSubmit} noValidate>
                      <Stack spacing={5}>
                        {Object.values(criteriaGroups || {})
                          .sort((a, b) => a?.[0].sort_number - b?.[0].sort_number)
                          .map((criteriaSelections) => (
                            <CriteriaConfigTable
                              key={criteriaSelections[0].id}
                              rows={criteriaSelections}
                              groupTitle={get(criteriaSelections[0].names, language, '')}
                              mode="edit"
                              EditControl={AutoCompleteField}
                              resolveControlProps={(row) => ({
                                name: `${QUALITY_PREFIX}${row.id}`,
                                options: ratingOptions,
                                label: getMessage('label.rating'),
                              })}
                            />
                          ))}
                      </Stack>
                      <DirtyFormSpy />
                    </form>
                  )
                }}
              />
            </BoxStyled>
          </Section>
        )}
      </PageLayout>
    </ScreenLayout>
  )
}
