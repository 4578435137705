import { gql } from '@urql/core'

export const queryPfKapBackgroundInformationByProjectId = gql`
  query ($id: Int!) {
    pf_kap_project_by_pk(id: $id) {
      id
      sustainability
      participation
      equal_opportunity
      multiplication_potential
      additional_information
      version
      project_base {
        id
      }
    }
  }
`

export const queryPfPgvBackgroundInformationByProjectId = gql`
  query ($id: Int!) {
    pf_pgv_project_by_pk(id: $id) {
      id
      sustainability
      participation
      equal_opportunity
      multiplication_potential
      additional_information
      version
      project_base {
        id
      }
    }
  }
`

export const updatePfKapFieldByProjectId = gql`
  mutation ($projectId: Int!, $version: Int!, $projectBackgroundInformationFieldSetClause: pf_kap_project_set_input) {
    update_pf_kap_project(
      where: { _and: [{ id: { _eq: $projectId } }, { version: { _eq: $version } }] }
      _set: $projectBackgroundInformationFieldSetClause
    ) {
      affected_rows
      returning {
        version
      }
    }
  }
`

export const updatePfPgvFieldByProjectId = gql`
  mutation ($projectId: Int!, $version: Int!, $projectBackgroundInformationFieldSetClause: pf_pgv_project_set_input) {
    update_pf_pgv_project(
      where: { _and: [{ id: { _eq: $projectId } }, { version: { _eq: $version } }] }
      _set: $projectBackgroundInformationFieldSetClause
    ) {
      affected_rows
      returning {
        version
      }
    }
  }
`
