import { TextField as MuiTextField, TextFieldProps as MuiTextFieldProps } from '@mui/material'
import { ReactElement } from 'react'
import { Field } from 'react-final-form'
import { useMessageSource } from 'src/i18n/useMessageSource'
import { FinalFormInput } from 'src/shared/form/control/index'

type TextFieldType = 'password' | 'text' | 'email' | 'search'

type TextFieldVariant = 'standard' | 'filled' | 'outlined' | undefined

type TextFieldPropsToBeExcluded = FinalFormInput | 'variant'

type TextFieldProps = Omit<MuiTextFieldProps, TextFieldPropsToBeExcluded> & {
  validate?: any
  name: string
  type?: TextFieldType
  variant?: TextFieldVariant
}

export const TextField = (props: TextFieldProps): ReactElement => {
  const { name, validate, type = 'text', variant, ...rest } = props
  const { getMessage } = useMessageSource()

  return (
    <Field
      name={name}
      validate={validate}
      render={({ input: { value, onFocus, onBlur, onChange }, meta: { touched, error: errorObject } }) => {
        const error = errorObject && getMessage(errorObject.errorKey, errorObject.params)
        const invalid = Boolean(touched && error)
        return (
          <MuiTextField
            {...rest}
            fullWidth
            name={name}
            helperText={(invalid && error) || ' '}
            onChange={onChange}
            error={invalid}
            type={type}
            value={value}
            onFocus={onFocus}
            onBlur={onBlur}
            autoComplete="nope"
            variant={variant}
          />
        )
      }}
    />
  )
}
