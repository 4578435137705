import { useEffect, useState } from 'react'
import { useMessageSource } from 'src/i18n/useMessageSource'
import { FilterFieldWrapper } from 'src/screens/shared/common/filter-card/FilterFieldWrapper'
import { LEVEL, LEVEL_TYPE } from 'src/shared/constants/constants'
import { FilterCheckboxGroupField, FilterCheckboxModel } from 'src/shared/form/control/FilterCheckboxGroupField'
import { requiredSelection } from 'src/shared/form/validation/validators'

interface Props {
  name: string
  error?: boolean
  formValues: LEVEL_TYPE[] | undefined
}

export const LevelFilter = ({ name, error, formValues }: Props) => {
  const { getMessage } = useMessageSource()

  const [levelData, setLevelData] = useState<FilterCheckboxModel[]>([])

  useEffect(() => {
    const initializeData = () => {
      const levelArray = [LEVEL.INTERVENTION, LEVEL.POLICY, LEVEL.NETWORK, LEVEL.COMMUNICATION]

      const levels = levelArray.map((level) => ({
        label: getMessage(`label.level.${level}`),
        value: level,
      }))

      setLevelData(levels)
    }

    initializeData()
  }, [getMessage])

  return (
    <FilterFieldWrapper label={getMessage('label.level')} required error={error}>
      <FilterCheckboxGroupField data={levelData} name={name} validate={requiredSelection()} formValues={formValues} />
    </FilterFieldWrapper>
  )
}
