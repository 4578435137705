import { AxiosResponse } from 'axios'
import { FactsheetSearchInput } from 'src/@types/graphql'
import { Axios } from 'src/service/axios/axios'

export const FactsheetsReportService = {
  generateReport: (reportInput: FactsheetSearchInput, language: string): Promise<AxiosResponse> => {
    return Axios.getAxios().post(
      `api/factsheet/search/report`,
      { ...reportInput },
      {
        params: {
          language,
        },
        responseType: 'blob',
      },
    )
  },
}
