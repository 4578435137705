import { ReactElement, useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router'
import { Mutation_Root, Project_Base_Bool_Exp, Query_Root } from 'src/@types/graphql'
import { useMessageSource } from 'src/i18n/useMessageSource'
import { resolveDetailsRoot } from 'src/routing/routing-utils'
import { SummaryCommentEditForm } from 'src/screens/shared/common/SummaryCommentEditForm'
import {
  queryDossierAndProjectByProjectId,
  updateCommentPfKap,
  updateCommentPfPgv,
} from 'src/screens/shared/project/details/summary/projectSummaryQueries'
import { usePermissionsForProject } from 'src/service/security/PermissionHook'
import { SaveAndBackButton, SaveButton } from 'src/shared/button/Buttons'
import { PROJECT } from 'src/shared/constants/constants'
import { PageLayout } from 'src/shared/layout/PageLayout'
import { ScreenLayout } from 'src/shared/layout/ScreenLayout'
import { NotAuthorized } from 'src/shared/not-authorized/NotAuthorized'
import { HelpAndInstructions } from 'src/shared/presentation/HelpAndInstructions'
import { useDelayedNavigate } from 'src/shared/utils/hooks/navigation-hooks'
import { useIsMounted } from 'src/shared/utils/hooks/react-hooks'
import { useNotificationService } from 'src/shared/utils/NotificationService'
import { Utils } from 'src/shared/utils/Utils'
import { useClient } from 'urql'

type originType = 'SAVE' | 'SAVE_AND_BACK'

interface Props {
  baseUrl: '/pf-kap' | '/pf-pgv'
}

export const ProjectSummaryCommentEditPage = ({ baseUrl }: Props): ReactElement => {
  const { projectId } = useParams()
  const id = parseInt(projectId as string)
  const { getMessage } = useMessageSource()
  const navigate = useDelayedNavigate()
  const urqlClient = useClient()
  const originRef = useRef<originType>('SAVE')
  const notificationService = useNotificationService()
  const isMounted = useIsMounted()
  const [commentData, setCommentData] = useState<{
    projectId: number
    version: number
    comment: string
  } | null>(null)
  const projectType = Utils.resolveProcess(baseUrl)
  const process = Utils.resolveProcessToLowerCase(baseUrl)
  const { canEditCommentInSummary, loading } = usePermissionsForProject(projectType, id)

  useEffect(() => {
    const projectPfKapBaseWhereClause: Project_Base_Bool_Exp = {
      pf_kap_projects: {
        id: {
          _eq: id,
        },
      },
    }

    const projectPfPgvBaseWhereClause: Project_Base_Bool_Exp = {
      pf_pgv_projects: {
        id: {
          _eq: id,
        },
      },
    }
    const projectBaseQuery: Project_Base_Bool_Exp = {
      ...(projectType === PROJECT.PF_KAP && projectPfKapBaseWhereClause),
      ...(projectType === PROJECT.PF_PGV && projectPfPgvBaseWhereClause),
    }

    const initData = async () => {
      const { data, error } = await urqlClient
        .query<
          { dossier: Query_Root['dossier'] },
          { projectBaseQuery: Project_Base_Bool_Exp; isPfKap: boolean; isPfPgv: boolean }
        >(queryDossierAndProjectByProjectId, {
          projectBaseQuery: projectBaseQuery,
          isPfKap: projectType === PROJECT.PF_KAP,
          isPfPgv: projectType === PROJECT.PF_PGV,
        })
        .toPromise()

      if (data?.dossier) {
        const dossier = data.dossier[0]

        const projectFields: any = {
          ...(projectType === PROJECT.PF_KAP && {
            projectId: dossier.project_bases[0].pf_kap_projects[0].id,
            version: dossier.project_bases[0].pf_kap_projects[0].version,
          }),
          ...(projectType === PROJECT.PF_PGV && {
            projectId: dossier.project_bases[0].pf_pgv_projects[0].id,
            version: dossier.project_bases[0].pf_pgv_projects[0].version,
          }),
        }

        const commentDataFetched = {
          comment: dossier.project_bases[0].comment ?? '',
          ...projectFields,
        }

        setCommentData(commentDataFetched)
      } else if (error) {
        notificationService.operationFailed()
      }
    }
    initData()
  }, [baseUrl, notificationService, id, projectType, urqlClient])

  const onSubmit = (origin: originType) => (_: any) => {
    // trigger submit event
    if (originRef) {
      originRef.current = origin
    }
    document.getElementById('edit-form')?.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }))
  }

  const onUpdateHandler = async (values: any) => {
    const updateObject = {
      projectId: id,
      version: commentData?.version,
      comment: values.comment,
    }

    let mutationError
    let affectedRows
    let newVersion

    switch (baseUrl) {
      case '/pf-kap': {
        const { error, data } = await urqlClient
          .mutation<{
            update_pf_kap_project: Mutation_Root['update_pf_kap_project']
          }>(updateCommentPfKap, updateObject)
          .toPromise()

        mutationError = error
        affectedRows = data?.update_pf_kap_project?.affected_rows
        newVersion = data?.update_pf_kap_project?.returning[0].version

        break
      }
      case '/pf-pgv': {
        const { error, data } = await urqlClient
          .mutation<{
            update_pf_pgv_project: Mutation_Root['update_pf_pgv_project']
          }>(updateCommentPfPgv, updateObject)
          .toPromise()

        mutationError = error
        affectedRows = data?.update_pf_pgv_project?.affected_rows
        newVersion = data?.update_pf_pgv_project?.returning[0].version

        break
      }
      default:
        throw Error(`Unsupported base url [${baseUrl}]`)
    }

    if (mutationError || affectedRows !== 1) {
      notificationService.operationFailed()
    } else if (isMounted()) {
      const newInitialValues = { ...values, version: newVersion }
      setCommentData(newInitialValues)
      notificationService.changesSaved()
      if (originRef.current === 'SAVE_AND_BACK') {
        navigate(resolveDetailsRoot(baseUrl).nested.ProjectSummary.params({ projectId }))
      }
    }
  }

  if (!loading && !canEditCommentInSummary) {
    return <NotAuthorized />
  }

  return (
    <ScreenLayout
      title={getMessage('label.title.edit.comments')}
      onBack={() => {
        navigate(resolveDetailsRoot(baseUrl).nested.ProjectSummary.params({ projectId }))
      }}
      hasSecondLevelNavigation={false}
      actions={
        <>
          <SaveAndBackButton origin="header" onClick={onSubmit('SAVE_AND_BACK')} />
          <SaveButton origin="header" onClick={onSubmit('SAVE')} />
        </>
      }
    >
      <PageLayout>
        <>
          {!loading && commentData && (
            <>
              <HelpAndInstructions labelKey={`label.help.project.summary.comments.${process}`} defaultExpansion />
              <SummaryCommentEditForm comment={commentData.comment} onSave={onUpdateHandler} />
            </>
          )}
        </>
      </PageLayout>
    </ScreenLayout>
  )
}
