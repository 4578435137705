import { gql } from '@urql/core'

export const queryDossierAndProjectByProjectId = gql`
  query ($projectBaseQuery: project_base_bool_exp, $isPfKap: Boolean!, $isPfPgv: Boolean!) {
    dossier(where: { project_bases: $projectBaseQuery }) {
      status
      project_workflow_id
      project_bases {
        comment
        pf_kap_projects @include(if: $isPfKap) {
          id
          version
          application_type {
            code
          }
        }
        pf_pgv_projects @include(if: $isPfPgv) {
          id
          version
          application_type {
            code
          }
        }
      }
    }
  }
`

export const updateCommentPfKap = gql`
  mutation ($projectId: Int!, $comment: String, $version: Int!) {
    update_project_base(
      _set: { comment: $comment }
      where: { pf_kap_projects: { _and: { id: { _eq: $projectId }, version: { _eq: $version } } } }
    ) {
      affected_rows
    }
    update_pf_kap_project(
      where: { _and: { id: { _eq: $projectId }, version: { _eq: $version } } }
      _set: { version: $version }
    ) {
      affected_rows
      returning {
        version
      }
    }
  }
`

export const updateCommentPfPgv = gql`
  mutation ($projectId: Int!, $comment: String, $version: Int!) {
    update_project_base(
      _set: { comment: $comment }
      where: { pf_pgv_projects: { _and: { id: { _eq: $projectId }, version: { _eq: $version } } } }
    ) {
      affected_rows
    }
    update_pf_pgv_project(
      where: { _and: { id: { _eq: $projectId }, version: { _eq: $version } } }
      _set: { version: $version }
    ) {
      affected_rows
      returning {
        version
      }
    }
  }
`

export const validateProject = gql`
  query ($projectId: Int!, $process: Process!) {
    validateProjectDescription(projectId: $projectId, processType: $process) {
      validationViolationMessageKey
      projectDescriptionTab
      section
      missingFeatureTypes
      labelArguments
    }
  }
`
