import { Box, Chip } from '@mui/material'
import { GridColDef, GridRenderEditCellParams, GridRowId } from '@mui/x-data-grid'
import { useGridApiRef } from '@mui/x-data-grid-pro'
import { GridApiPro } from '@mui/x-data-grid-pro/models/gridApiPro'
import combinedQuery from 'graphql-combine-query'
import { merge, sumBy } from 'lodash'
import { MutableRefObject, ReactElement, useCallback, useEffect, useMemo, useState } from 'react'
import { Form } from 'react-final-form'
import { Mutation_Root, Project_Base, Project_Base_Bool_Exp, Query_Root } from 'src/@types/graphql'
import { useMessageSource } from 'src/i18n/useMessageSource'
import { usePrompt } from 'src/routing/routing-hooks'
import { MilestoneProjectFundsActuals } from 'src/screens/shared/implementation/details/milestone/details/financial/MilestoneProjectFundsGrid'
import { queryProjectFinancialReportsByProject } from 'src/screens/shared/implementation/details/milestoneQueries'
import {
  queryProjectBudgetByProjectBase,
  updateProjectBudget,
} from 'src/screens/shared/project/details/financials/projectFinancialsQueries'
import { PROJECT } from 'src/shared/constants/constants'
import { NumberInput } from 'src/shared/form/grid/NumberInput'
import { EditableColumnHeader } from 'src/shared/grid/EditableColumnHeader'
import { DefaultSectionTypography } from 'src/shared/presentation/DefaultSectionTypography'
import { HelpAndInstructions } from 'src/shared/presentation/HelpAndInstructions'
import { S } from 'src/shared/styled/S'
import { DataGridUtils } from 'src/shared/utils/DataGridUtils'
import { useGridTranslateHook } from 'src/shared/utils/hooks/grid-translate-hook'
import { useError } from 'src/shared/utils/hooks/page-loading-error-hook'
import { useDelayedSubmit } from 'src/shared/utils/hooks/submit-hook'
import { useNotificationService } from 'src/shared/utils/NotificationService'
import { Utils } from 'src/shared/utils/Utils'
import { muiTheme } from 'src/theme/theme'
import { useClient } from 'urql'

export interface RowData {
  id: number
  fundsInCHF: string
  budget: number | null
  approved: number | null
  actuals: number | null
  percentage: number | null
}

interface Props {
  budgetEditable: boolean
  approvedEditable: boolean
  mode?: 'view' | 'edit'
  projectId: number
  baseUrl: '/pf-kap' | '/pf-pgv'
  onSave?: () => void
  expandedHelpSection?: boolean
}

const TOTAL_ROW_ID = 4

export const ProjectBudgetGrid = ({
  budgetEditable,
  approvedEditable,
  mode = 'view',
  projectId,
  onSave,
  baseUrl,
  expandedHelpSection,
}: Props): ReactElement => {
  const { getMessage } = useMessageSource()
  const gridTranslations = useGridTranslateHook()
  const urqlClient = useClient()
  const apiRef = useGridApiRef()
  const notificationService = useNotificationService()
  const submit = useDelayedSubmit()

  const projectType = Utils.resolveProcess(baseUrl)
  const process = Utils.resolveProcessToLowerCase(baseUrl)

  const [dirty, setDirty] = useState<boolean>(false)
  const [serverData, setServerData] = useState<Project_Base | null>(null)
  const [totalActualsData, setTotalActualsData] = useState<MilestoneProjectFundsActuals>()
  const [totalData, setTotalData] = useState<{ budget: number; approved: number }>()

  const calculateTotalBudgetValue = (
    value: number,
    id: GridRowId,
    field: string,
    apiRef: MutableRefObject<GridApiPro>,
  ): number => {
    const rows = apiRef.current
      ?.getAllRowIds()
      .filter((id) => id !== TOTAL_ROW_ID)
      .map((rowId) => ({
        budget:
          field === 'budget' && id === rowId
            ? DataGridUtils.formatFieldValue(value)
            : DataGridUtils.formatFieldValue(apiRef.current?.getRow(rowId).budget),
        approved:
          field === 'approved' && id === rowId
            ? DataGridUtils.formatFieldValue(value)
            : DataGridUtils.formatFieldValue(apiRef.current?.getRow(rowId).approved),
      }))

    const budgetTotal = DataGridUtils.formatNaNNumber(DataGridUtils.sumBy(rows, 'budget'))
    const approvedTotal = DataGridUtils.formatNaNNumber(DataGridUtils.sumBy(rows, 'approved'))

    setTotalData({
      budget: budgetTotal,
      approved: approvedTotal,
    })

    return value
  }

  const calculateActualsPercentage = useCallback(
    (currentActual: number): number | null => {
      if (totalActualsData) {
        const totalValue =
          (totalActualsData?.gfch_share_annual_value ?? 0) +
          (totalActualsData?.share_responsible_organization_annual_value ?? 0) +
          (totalActualsData?.other_funding_annual_value ?? 0)

        const value = (currentActual / totalValue) * 100
        const parsedValue = parseInt(value.toString())

        const precision = value === parsedValue ? 0 : 1
        const multiplier = Math.pow(10, precision)

        const result = Math.round(value * multiplier) / multiplier

        return DataGridUtils.formatFieldValue(result)
      }
      return 0
    },
    [totalActualsData],
  )

  const projectBudgetRowsData = useMemo(() => {
    const currentRowModels = apiRef.current?.getRowModels?.() ?? new Map()

    const rowData = [
      {
        id: 1,
        fundsInCHF: getMessage('label.gfch.share'),
        budget: currentRowModels.get(1)?.budget ?? serverData?.project_budget?.gfch_share_budget ?? null,
        approved: currentRowModels.get(1)?.approved ?? serverData?.project_budget?.gfch_share_approved ?? null,
        actuals: totalActualsData?.gfch_share_annual_value ?? '-',
        percentage: calculateActualsPercentage(totalActualsData?.gfch_share_annual_value ?? 0) ?? 0,
      },
      {
        id: 2,
        fundsInCHF: getMessage('label.share.responsible.organisation'),
        budget:
          currentRowModels.get(2)?.budget ?? serverData?.project_budget?.share_responsible_organization_budget ?? null,
        approved:
          currentRowModels.get(2)?.approved ??
          serverData?.project_budget?.share_responsible_organization_approved ??
          null,
        actuals: totalActualsData?.share_responsible_organization_annual_value ?? '-',
        percentage: calculateActualsPercentage(totalActualsData?.share_responsible_organization_annual_value ?? 0) ?? 0,
      },
      {
        id: 3,
        fundsInCHF: getMessage('label.other.fundings'),
        budget: currentRowModels.get(3)?.budget ?? serverData?.project_budget?.other_funding_budget ?? null,
        approved: currentRowModels.get(3)?.approved ?? serverData?.project_budget?.other_funding_approved ?? null,
        actuals: totalActualsData?.other_funding_annual_value ?? '-',
        percentage: calculateActualsPercentage(totalActualsData?.other_funding_annual_value ?? 0) ?? 0,
      },
    ]

    rowData.push({
      id: TOTAL_ROW_ID,
      fundsInCHF: getMessage('label.total'),
      budget: totalData ? totalData.budget : DataGridUtils.sumBy(rowData, 'budget'),
      approved: totalData ? totalData.approved : DataGridUtils.sumBy(rowData, 'approved'),
      actuals: rowData.reduce((initial, { actuals }) => initial + (parseInt(actuals.toString()) || 0), 0),
      percentage: 100,
    })

    return rowData as RowData[]
  }, [apiRef, getMessage, serverData, totalData, totalActualsData, calculateActualsPercentage])

  const columns = useMemo(() => {
    const onRifmChange = (api: GridRenderEditCellParams['api'], id: GridRowId, field: string, value: number) => {
      calculateTotalBudgetValue(value, id, field, apiRef)
      api.setEditCellValue({ id, field, value })
      setDirty(true)
    }

    return [
      {
        field: 'fundsInCHF',
        headerName: getMessage('label.funds.chf'),
        sortable: false,
        disableColumnMenu: true,
        flex: 1.6,
      },
      {
        field: 'budget',
        headerAlign: 'right',
        align: 'right',
        editable: mode === 'view' ? false : budgetEditable,
        sortable: false,
        disableColumnMenu: true,
        flex: 0.3,
        renderCell: ({ value }) => {
          return (
            <>
              {value === null || Number.isNaN(value) || value === '' ? (
                <DefaultSectionTypography $standAlone />
              ) : (
                <S.Span.TextOverflow>{value.toLocaleString('de-CH')}</S.Span.TextOverflow>
              )}
            </>
          )
        },
        renderEditCell: (params) => {
          const { value, id, field, api } = params
          const isValid = DataGridUtils.validateValue(value)
          return (
            <NumberInput
              value={value as number}
              onChange={(val: number) => onRifmChange(api, id, field, val)}
              error={!isValid}
            />
          )
        },
        renderHeader: () => (
          <EditableColumnHeader headerName={getMessage('label.budget')} editMode={mode === 'edit' && budgetEditable} />
        ),
      },
      {
        field: 'approved',
        editable: mode === 'view' ? false : approvedEditable,
        headerAlign: 'right',
        sortable: false,
        disableColumnMenu: true,
        align: 'right',
        flex: 0.3,
        renderCell: ({ value }) => {
          if (value) {
            return <S.Span.TextOverflow> {value.toLocaleString('de-CH')}</S.Span.TextOverflow>
          }
          return value === null || Number.isNaN(value) || value === '' ? (
            approvedEditable ? (
              <DefaultSectionTypography $standAlone />
            ) : (
              '-'
            )
          ) : (
            value
          )
        },
        renderEditCell: (params) => {
          const { value, id, field, api } = params
          const isValid = DataGridUtils.validateValue(value)
          return (
            <NumberInput
              value={value as number}
              onChange={(val: number) => onRifmChange(api, id, field, val)}
              error={!isValid}
            />
          )
        },
        renderHeader: () => (
          <EditableColumnHeader
            headerName={getMessage('label.approved')}
            editMode={mode === 'edit' && approvedEditable}
          />
        ),
      },
      {
        field: 'actuals',
        headerName: getMessage('label.actuals'),
        headerAlign: 'right',
        hide: mode === 'edit',
        sortable: false,
        disableColumnMenu: true,
        align: 'right',
        flex: 0.4,
        renderCell: ({ value, row }) => {
          const columnValue =
            value === null || Number.isNaN(value) || value === '' ? (
              '-'
            ) : (
              <S.Span.TextOverflow>{value?.toLocaleString('de-CH')}</S.Span.TextOverflow>
            )
          return (
            <Box sx={{ display: 'flex', alignItems: 'center', gap: muiTheme.spacing(2), minWidth: 0 }}>
              {columnValue}
              {row?.actuals > 0 ? (
                <Chip label={`${row?.percentage}%`} color="secondary" sx={{ width: 70 }} />
              ) : (
                <Chip label={`-`} color="secondary" sx={{ width: 70 }} />
              )}
            </Box>
          )
        },
      },
    ] as GridColDef[]
  }, [apiRef, getMessage, budgetEditable, mode, approvedEditable])

  const setError = useError()

  useEffect(() => {
    const pfKapQuery = {
      pf_kap_projects: {
        id: {
          _eq: projectId,
        },
      },
    }

    const pfPgvQuery = {
      pf_pgv_projects: {
        id: {
          _eq: projectId,
        },
      },
    }

    const projectBaseQuery: Project_Base_Bool_Exp = {
      ...(projectType === PROJECT.PF_KAP && pfKapQuery),
      ...(projectType === PROJECT.PF_PGV && pfPgvQuery),
    }

    const fetchData = async () => {
      const { document, variables } = combinedQuery('projectBudgetAndFinancialReport')
        .add<{ project_base: Query_Root['project_base'] }, { projectBaseQuery: Project_Base_Bool_Exp }>(
          queryProjectBudgetByProjectBase,
          {
            projectBaseQuery,
          },
        )
        .add<
          {
            project_financial_all_reports: Query_Root['project_financial_report']
          },
          {
            projectBaseWhere: Project_Base_Bool_Exp
          }
        >(queryProjectFinancialReportsByProject, { projectBaseWhere: projectBaseQuery })

      const { data, error } = await urqlClient
        .query<
          {
            project_base: Query_Root['project_base']
            project_financial_all_reports: Query_Root['project_financial_report']
          },
          { projectBaseQuery: Project_Base_Bool_Exp; projectBaseWhere: Project_Base_Bool_Exp } | undefined
        >(document, variables)
        .toPromise()

      if (data) {
        const allMilestonesFinancialReports = data?.project_financial_all_reports

        const milestonesFinancialReportsActuals = {
          gfch_share_annual_value: sumBy(allMilestonesFinancialReports, 'gfch_share_annual_value'),
          share_responsible_organization_annual_value: sumBy(
            allMilestonesFinancialReports,
            'share_responsible_organization_annual_value',
          ),
          other_funding_annual_value: sumBy(allMilestonesFinancialReports, 'other_funding_annual_value'),
        }

        setServerData(data.project_base[0])
        setTotalActualsData(milestonesFinancialReportsActuals)
      } else if (error) {
        setError()
      }
    }

    fetchData()
  }, [projectId, urqlClient, projectType, setError])

  usePrompt(getMessage('notification.form.unsaved.changes'), dirty)

  const onSaveLocal = async () => {
    const rows = apiRef.current.getRowModels()
    const gfchShareBudget = rows.get(1)?.budget
    const shareResponsibleOrganizationBudget = rows.get(2)?.budget
    const otherFundingBudget = rows.get(3)?.budget

    const gfchShareApproved = rows.get(1)?.approved
    const shareResponsibleOrganizationApproved = rows.get(2)?.approved
    const otherFundingApproved = rows.get(3)?.approved

    const newProjectBudget = {
      project_base_id: serverData?.id,
      gfch_share_budget: DataGridUtils.formatFieldValue(gfchShareBudget),
      share_responsible_organization_budget: DataGridUtils.formatFieldValue(shareResponsibleOrganizationBudget),
      other_funding_budget: DataGridUtils.formatFieldValue(otherFundingBudget),
      ...(approvedEditable && {
        gfch_share_approved: DataGridUtils.formatFieldValue(gfchShareApproved),
        share_responsible_organization_approved: DataGridUtils.formatFieldValue(shareResponsibleOrganizationApproved),
        other_funding_approved: DataGridUtils.formatFieldValue(otherFundingApproved),
      }),
    }

    const updateColumns = Object.keys(newProjectBudget).filter((k: string) => k !== 'project_base_id')

    const { data, error } = await urqlClient
      .mutation<{
        insert_project_budget: Mutation_Root['insert_project_budget']
      }>(updateProjectBudget, {
        projectBudget: newProjectBudget,
        updateColumns: updateColumns,
      })
      .toPromise()

    if (error || data?.insert_project_budget?.affected_rows !== 1) {
      notificationService.operationFailed()
    } else {
      if (onSave) {
        notificationService.changesSaved()
        const newServerData = {} as typeof serverData
        merge(newServerData, serverData, {
          project_budget: {
            gfch_share_budget: newProjectBudget.gfch_share_budget,
            gfch_share_approved: newProjectBudget.gfch_share_approved,
            share_responsible_organization_budget: newProjectBudget.share_responsible_organization_budget,
            share_responsible_organization_approved: newProjectBudget.share_responsible_organization_approved,
            other_funding_budget: newProjectBudget.other_funding_budget,
            other_funding_approved: newProjectBudget.other_funding_approved,
          },
        })
        setServerData(newServerData)
        setDirty(false)
        onSave()
      }
    }
  }

  return (
    <Form
      onSubmit={submit(onSaveLocal)}
      render={({ handleSubmit }) => (
        <form
          id="edit-form"
          onSubmit={handleSubmit}
          onKeyPress={(event) => {
            if (event.key === 'Enter') {
              event.preventDefault()
            }
          }}
        >
          <Box>
            <HelpAndInstructions
              labelKey={`label.help.and.instructions.project.budget.${process}`}
              defaultExpansion={expandedHelpSection}
            />
            <S.DataGrid.TotalRow
              rows={projectBudgetRowsData}
              columns={columns}
              autoHeight
              hideFooter
              apiRef={apiRef}
              localeText={gridTranslations}
              disableColumnReorder
              disableRowSelectionOnClick
              disableColumnSelector
              isCellEditable={({ row }: { row: any }) => row.id !== TOTAL_ROW_ID}
            />
          </Box>
        </form>
      )}
    />
  )
}
