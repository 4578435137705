import { Box } from '@mui/material'
import { ReactElement, useEffect, useState } from 'react'
import { Query_Root, Reporting_Kpis_Features_View, Reporting_Kpis_Goals_View } from 'src/@types/graphql'
import { useMessageSource } from 'src/i18n/useMessageSource'
import { ReportingKpisCard, ReportingKpisCardDataModel } from 'src/screens/reporting/index/ReportingKpisCard'
import { CalendarIcon, GoalIcon, LinkIcon } from 'src/shared/icons/Icons'
import { Section } from 'src/shared/presentation/Section'
import { useNotificationService } from 'src/shared/utils/NotificationService'
import styled from 'styled-components/macro'
import { gql, useClient } from 'urql'

const BoxStyled = styled(Box)`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(1)};

  .multiple-module-card {
    flex: 1 1 auto;
    max-width: 30rem;

    @media screen and (max-width: 1200px) {
      max-width: 25rem;
    }

    @media screen and (max-width: 900px) {
      max-width: 22rem;
    }
  }
`

const fetchReportingKpisQuery = gql`
  query reportingKpisQuery {
    reporting_kpis_factsheet_links_view {
      number_of_entities
    }
    reporting_kpis_goals_view {
      reported_green
      reported_orange
      reported_red
    }
    reporting_kpis_features_view {
      target_group
      multiplier
    }
  }
`

export const ReportingKpisHeaderSection = (): ReactElement => {
  const { getMessage } = useMessageSource()
  const urqlClient = useClient()
  const notificationService = useNotificationService()

  const [factsheetLinks, setFactsheetLinks] = useState<number>()
  const [goals, setGoals] = useState<Reporting_Kpis_Goals_View>()
  const [features, setFeatures] = useState<Reporting_Kpis_Features_View>()

  useEffect(() => {
    const initData = async () => {
      const { data } = await urqlClient
        .query<{
          reporting_kpis_factsheet_links_view: Query_Root['reporting_kpis_factsheet_links_view']
          reporting_kpis_goals_view: Query_Root['reporting_kpis_goals_view']
          reporting_kpis_features_view: Query_Root['reporting_kpis_features_view']
        }>(fetchReportingKpisQuery, {})
        .toPromise()

      if (data) {
        const factsheetLinks = data.reporting_kpis_factsheet_links_view[0].number_of_entities
        const goals = data.reporting_kpis_goals_view[0]
        const features = data.reporting_kpis_features_view[0]

        setFactsheetLinks(factsheetLinks)
        setGoals(goals)
        setFeatures(features)
      } else {
        notificationService.operationFailed()
      }
    }
    initData()
  }, [urqlClient, notificationService])

  const appendPreviousYear = (label: string) => {
    const previousYear = new Date().getFullYear() - 1
    return `${label} ${previousYear}`
  }

  const cards = [
    {
      // Factsheet links
      title: getMessage('label.reporting.kpis.factsheet.links'),
      tooltipLabel: getMessage('tooltip.reporting.kpis.factsheet.links'),
      data: [
        {
          figure: factsheetLinks ?? '',
          chip: {
            label: getMessage('label.reporting.kpis.linked.entities'),
            icon: <LinkIcon />,
            color: 'secondary',
          },
        },
      ],
    },
    {
      // Goals
      title: appendPreviousYear(getMessage('label.reporting.goals.reported')),
      tooltipLabel: getMessage('tooltip.reporting.kpis.goals'),
      data: [
        {
          figure: goals ? goals.reported_green : '',
          chip: {
            label: getMessage('label.reporting.goal.rating.GREEN'),
            icon: <GoalIcon />,
            color: 'success',
          },
        },
        {
          figure: goals ? goals.reported_orange : '',
          chip: {
            label: getMessage('label.reporting.goal.rating.ORANGE'),
            icon: <GoalIcon />,
            color: 'primary',
          },
        },
        {
          figure: goals ? goals.reported_red : '',
          chip: {
            label: getMessage('label.reporting.goal.rating.RED'),
            icon: <GoalIcon />,
            color: 'error',
          },
        },
      ],
    },
    {
      // Target groups
      title: getMessage('label.reporting.kpis.target.groups'),
      tooltipLabel: getMessage('tooltip.reporting.kpis.target.groups'),
      data: [
        {
          figure: features ? features.target_group : '',
          chip: {
            label: appendPreviousYear(getMessage('label.reporting.kpis.reported')),
            icon: <CalendarIcon />,
            color: 'secondary',
          },
        },
      ],
    },
    {
      // Multipliers
      title: getMessage('label.reporting.kpis.multipliers'),
      tooltipLabel: getMessage('tooltip.reporting.kpis.multipliers'),
      data: [
        {
          figure: features ? features.multiplier : '',
          chip: {
            label: appendPreviousYear(getMessage('label.reporting.kpis.reported')),
            icon: <CalendarIcon />,
            color: 'secondary',
          },
        },
      ],
    },
  ]

  return (
    <>
      <Section title={getMessage('label.reporting.kpis.title')}>
        <BoxStyled>
          {cards.map((card, index) => (
            <ReportingKpisCard
              key={index}
              data={card.data as ReportingKpisCardDataModel[]}
              title={card.title}
              tooltipLabel={card.tooltipLabel}
            />
          ))}
        </BoxStyled>
      </Section>
    </>
  )
}
