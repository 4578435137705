import { Tooltip } from '@mui/material'
import { useEffect, useRef, useState } from 'react'
import { User } from 'src/@types/graphql'

interface Props {
  children: { users: User[]; usersDisplayText: string }
}

export const OverflowTooltip = ({ children }: Props) => {
  const textElementRef = useRef<null | HTMLDivElement>() as React.MutableRefObject<HTMLInputElement>
  const [isOverflowed, setIsOverflow] = useState(false)

  const compareSize = () => {
    if (textElementRef.current) {
      const compare = textElementRef.current.scrollWidth > textElementRef.current.clientWidth
      setIsOverflow(compare)
    }
  }

  useEffect(() => {
    compareSize()
    window.addEventListener('resize', compareSize)

    return () => {
      window.removeEventListener('resize', compareSize)
    }
  }, [children])

  const getTooltipValue = (users: User[]) =>
    users?.map((value, i) => (
      <span key={i}>
        {`${value?.first_name} ${value?.last_name}`}
        <br />
      </span>
    ))

  return (
    <Tooltip title={getTooltipValue(children.users)} disableHoverListener={!isOverflowed} arrow>
      <div
        ref={textElementRef}
        style={{
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
      >
        {children.usersDisplayText}
      </div>
    </Tooltip>
  )
}
