import { gql } from 'urql'

export const createKapProgramMutation = gql`
  mutation insertKapProgram(
    $title: String!
    $shortTitle: String!
    $status: String!
    $type: String!
    $modules: jsonb
    $canton: String!
  ) {
    insert_dossier(
      objects: {
        title: $title
        short_title: $shortTitle
        status: $status
        type: $type
        kap_program: { data: { canton_code: $canton, modules: $modules, created_after_principles: true } }
      }
    ) {
      affected_rows
      returning {
        id
        kap_program {
          id
        }
      }
    }
  }
`

export const queryKapProgramsDates = gql`
  query {
    dossierStartDate: dossier(
      where: { type: { _eq: "KAP" } }
      distinct_on: [start_date]
      order_by: { start_date: asc }
    ) {
      start_date
    }
    dossierEndDate: dossier(where: { type: { _eq: "KAP" } }, distinct_on: [end_date], order_by: { end_date: asc }) {
      end_date
    }
  }
`

export const queryAllGfchCantonsResponsibles = gql`
  query {
    user(where: { gfch_cantonal_responsibility: { _is_null: false } }) {
      id
      last_name
      first_name
      gfch_cantonal_responsibility
    }
  }
`
