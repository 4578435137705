import { Button } from '@mui/material'
import { ReactElement, useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { Milestone, Query_Root } from 'src/@types/graphql'
import { useMessageSource } from 'src/i18n/useMessageSource'
import { KapMilestoneGrid } from 'src/screens/kap/implementation/milestone/KapMilestoneGrid'
import {
  fetchDossierIdByProgramIdQuery,
  fetchProgramMilestonesQuery,
} from 'src/screens/kap/implementation/milestone/kapMilestoneQueries'
import { DossierService } from 'src/service/axios/DossierService'
import { usePermissionsForKapProgram } from 'src/service/security/PermissionHook'
import { PROJECT } from 'src/shared/constants/constants'
import { AddIcon } from 'src/shared/icons/Icons'
import { PageLayout } from 'src/shared/layout/PageLayout'
import { ScreenLayout } from 'src/shared/layout/ScreenLayout'
import { SecondaryConfirmationModalDialog } from 'src/shared/modal-dialog/SecondaryConfirmationModalDialog'
import { HelpAndInstructions } from 'src/shared/presentation/HelpAndInstructions'
import { Section } from 'src/shared/presentation/Section'
import { useNotificationService } from 'src/shared/utils/NotificationService'
import { useClient } from 'urql'
import { getMilestoneTitleLabel } from 'src/screens/shared/implementation/details/milestone/utils/MilestoneUtils'

export interface KapMilestoneViewModel {
  id: number
  type: string
  title: string
  end_date: Date
  status: string
  organization: string
}

export const KapImplementationMilestonePage = (): ReactElement => {
  const { getMessage } = useMessageSource()
  const { programId } = useParams()
  const program_id = parseInt(programId as string)

  const urqlClient = useClient()
  const notificationService = useNotificationService()

  const [milestones, setMilestones] = useState<KapMilestoneViewModel[]>([])
  const [addNewMilestoneSetConfirmationOpen, setAddNewMilestoneSetConfirmationOpen] = useState(false)
  const [addNewMilestoneSetLoading, setAddNewMilestoneSetLoading] = useState(false)

  const { canManageMilestones } = usePermissionsForKapProgram(program_id)

  const openAddNewMilestoneSetConfirmation = () => {
    setAddNewMilestoneSetConfirmationOpen(true)
  }

  const handleCancelAddNewMilestoneSet = () => {
    setAddNewMilestoneSetConfirmationOpen(false)
  }

  const handleConfirmAddNewMilestoneSet = async () => {
    setAddNewMilestoneSetLoading(true)

    const { data } = await urqlClient
      .query<{ dossier: Query_Root['dossier'] }>(fetchDossierIdByProgramIdQuery, {
        programId: program_id,
      })
      .toPromise()

    if (data && data?.dossier) {
      const dossierId = data?.dossier[0]?.id

      DossierService.addSetOfMilestones(dossierId, PROJECT.KAP)
        .then(() => {
          setAddNewMilestoneSetConfirmationOpen(false)
          fetchData()
          notificationService.changesSaved()
        })
        .catch(() => {
          notificationService.operationFailed()
        })
        .finally(() => {
          setAddNewMilestoneSetLoading(false)
        })
    } else {
      notificationService.operationFailed()
    }
  }

  const mapMilestoneToKapMilestoneViewModel = useCallback(
    (data: Milestone[]): KapMilestoneViewModel[] =>
      data.map((milestone) => {
        const milestoneTitleLabel = getMilestoneTitleLabel(PROJECT.KAP, milestone.type)
        const title = `${getMessage(milestoneTitleLabel)} ${milestone.year_in_focus ?? ''}`

        return {
          id: milestone.id,
          type: milestone.type,
          title: title,
          end_date: milestone.due_date,
          status: milestone.status,
          organization:
            milestone.responsible_type === 'GFCH'
              ? getMessage(`label.milestone.responsible.${milestone.responsible_type}`)
              : getMessage(`label.kap.milestone.responsible.${milestone.responsible_type}`),
        }
      }),
    [getMessage],
  )

  const fetchData = useCallback(async () => {
    const { data } = await urqlClient
      .query<
        {
          milestone: Query_Root['milestone']
        },
        {
          programId: number
        }
      >(fetchProgramMilestonesQuery, { programId: program_id })
      .toPromise()

    if (data && data?.milestone) {
      setMilestones(mapMilestoneToKapMilestoneViewModel(data.milestone))
    } else {
      notificationService.operationFailed()
    }
  }, [mapMilestoneToKapMilestoneViewModel, notificationService, program_id, urqlClient])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  return (
    <>
      <ScreenLayout title={getMessage('label.navigation.program.implementation')}>
        <PageLayout>
          <Section
            id="milestone"
            title={getMessage('label.milestones')}
            helpAndInstructions={<HelpAndInstructions labelKey="label.help.implementation.milestone.kap" />}
            actionButton={
              canManageMilestones && (
                <Button
                  variant="text"
                  color="primary"
                  onClick={openAddNewMilestoneSetConfirmation}
                  startIcon={<AddIcon />}
                >
                  {getMessage('button.milestone.set.add')}
                </Button>
              )
            }
          >
            <KapMilestoneGrid milestones={milestones ?? []} programId={program_id} />
          </Section>
          <SecondaryConfirmationModalDialog
            buttonColor="primary"
            open={addNewMilestoneSetConfirmationOpen}
            onCancel={handleCancelAddNewMilestoneSet}
            onConfirm={handleConfirmAddNewMilestoneSet}
            loading={addNewMilestoneSetLoading}
          >
            {getMessage('label.confirm.add.milestone.set')}
          </SecondaryConfirmationModalDialog>
        </PageLayout>
      </ScreenLayout>
    </>
  )
}
