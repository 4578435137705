import { Location } from 'history'
import { ReactElement, useEffect, useMemo, useState } from 'react'
import { Outlet, useLocation, useParams } from 'react-router'
import { Query_Root } from 'src/@types/graphql'
import { NestedRoute, Route, SecondLevelRoute } from 'src/routing/routing'
import { resolveApplicationAssessmentDetailsRoot } from 'src/routing/routing-utils'
import { SecondLevelNavigation } from 'src/routing/SecondLevelNavigation'
import { getProjectAssessmentById } from 'src/screens/shared/assessment-criteria/assessmentQueries'
import { usePermissionsForProjectApplication } from 'src/service/security/PermissionHook'
import { PageLayout } from 'src/shared/layout/PageLayout'
import { SecondLevelNavigationContainer } from 'src/shared/layout/SecondLevelNavigationContainer'
import { NotAuthorized } from 'src/shared/not-authorized/NotAuthorized'
import { Utils } from 'src/shared/utils/Utils'
import { useQuery } from 'urql'

interface Props {
  baseUrl: '/pf-kap' | '/pf-pgv'
}

const resolveActiveRoute = (
  baseUrl: '/pf-kap' | '/pf-pgv',
  location: Location,
  projectId: string,
  assessmentId: string,
): SecondLevelRoute => {
  const nested = resolveApplicationAssessmentDetailsRoot(baseUrl)!.nested! as NestedRoute
  const activeRoute = Object.keys(nested).find(
    (key) =>
      location.pathname.endsWith(nested[key].path) ||
      (resolveApplicationAssessmentDetailsRoot(baseUrl).params({ projectId, assessmentId }) === location.pathname &&
        nested[key].primary),
  ) as string
  return nested[activeRoute] as SecondLevelRoute
}

export const ApplicationAssessmentDetailsIndex = ({ baseUrl }: Props): ReactElement => {
  const { projectId, assessmentId } = useParams()
  const location = useLocation()
  const process = Utils.resolveProcess(baseUrl)
  const {
    loading,
    canView,
    metadata: { projectBaseId },
  } = usePermissionsForProjectApplication(process, parseInt(projectId as string))

  const routeParams = useMemo(
    () => ({
      projectId: projectId as string,
      assessmentId: assessmentId as string,
    }),
    [projectId, assessmentId],
  )

  const [activeRoute, setActiveRoute] = useState<SecondLevelRoute>(() => {
    return resolveActiveRoute(baseUrl, location, routeParams.projectId, routeParams.assessmentId)
  })

  useEffect(() => {
    const activeRoute = resolveActiveRoute(baseUrl, location, routeParams.projectId, routeParams.assessmentId)
    setActiveRoute(activeRoute)
  }, [baseUrl, location, routeParams])

  const showNavigation = activeRoute && !activeRoute.hideNavigation

  const [{ fetching, data }] = useQuery<{
    project_assessment: Query_Root['project_assessment']
  }>({
    query: getProjectAssessmentById,
    variables: { assessmentId, projectBaseId },
  })

  if (!fetching && !loading) {
    if (!canView || data?.project_assessment?.length === 0) {
      return <NotAuthorized />
    }
  }

  return (
    <>
      {!loading && !fetching && (
        <SecondLevelNavigationContainer $showNavigation={showNavigation}>
          {showNavigation && (
            <PageLayout>
              <SecondLevelNavigation
                activeRoute={activeRoute}
                route={resolveApplicationAssessmentDetailsRoot(baseUrl) as unknown as Route}
                baseUrl={resolveApplicationAssessmentDetailsRoot(baseUrl).params(routeParams)}
              />
            </PageLayout>
          )}
          <Outlet />
        </SecondLevelNavigationContainer>
      )}
    </>
  )
}
