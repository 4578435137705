import { ListItemText } from '@mui/material'
import { GOAL_MEASURE_RATING } from 'src/shared/constants/milestone-constants'
import styled, { css } from 'styled-components/macro'

const RATING_LIST_ITEM_MIXIN = css`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(0.5)};
`

export const ProjectGoalRatingListItemTextStyled = styled(ListItemText)<{
  $rating?: string | null
}>`
  ${RATING_LIST_ITEM_MIXIN};
  .MuiListItemText-secondary {
    color: ${({ $rating, theme }) =>
      $rating === GOAL_MEASURE_RATING.RED
        ? theme.colors.error.dark
        : $rating === GOAL_MEASURE_RATING.ORANGE
        ? theme.colors.primary.dark
        : $rating === GOAL_MEASURE_RATING.GREEN
        ? theme.colors.success.dark
        : $rating === GOAL_MEASURE_RATING.INACTIVE
        ? theme.colors.text.secondary
        : theme.colors.text.disabled};
  }
`

export const ProjectMeasureRatingListItemTextStyled = styled(ListItemText)<{
  $rating?: string | null
}>`
  ${RATING_LIST_ITEM_MIXIN};
  .MuiListItemText-secondary {
    color: ${({ $rating, theme }) =>
      $rating === GOAL_MEASURE_RATING.RED
        ? theme.colors.error.dark
        : $rating === GOAL_MEASURE_RATING.ORANGE
        ? theme.colors.successLight.dark
        : $rating === GOAL_MEASURE_RATING.GREEN
        ? theme.colors.success.dark
        : $rating === GOAL_MEASURE_RATING.INACTIVE
        ? theme.colors.text.secondary
        : theme.colors.text.disabled};
  }
`
