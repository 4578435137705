import { useParams } from 'react-router'
import { useMessageSource } from 'src/i18n/useMessageSource'
import { ROUTES } from 'src/routing/routes'
import { kapOrganizationLabelDataFactory } from 'src/screens/kap/program/details/organization/view/KapOrganizationViewPage'
import { BaseOrganizationEdit } from 'src/screens/shared/organization/edit/BaseOrganizationEdit'
import { usePermissionsForKapProgram } from 'src/service/security/PermissionHook'
import { KAP_ORGANIZATION_TYPE_TYPE, PROJECT } from 'src/shared/constants/constants'
import { NotAuthorized } from 'src/shared/not-authorized/NotAuthorized'
import { useDelayedNavigate } from 'src/shared/utils/hooks/navigation-hooks'

interface Props {
  kapOrganizationType: KAP_ORGANIZATION_TYPE_TYPE
}

export const KapOrganizationEditPage = ({ kapOrganizationType }: Props) => {
  const { programId } = useParams()
  const program_id = parseInt(programId as string)

  const navigate = useDelayedNavigate()
  const { getMessage } = useMessageSource()

  const labelData = kapOrganizationLabelDataFactory(kapOrganizationType, 'edit')

  const { loading, canEdit } = usePermissionsForKapProgram(program_id)

  const onBack = () => {
    const route = ROUTES.KapDetailsRoot.nested.Organization.params({ programId })
    navigate(`${route}#${kapOrganizationType.toLowerCase().replace('_', '-')}`)
  }

  if (!loading && !canEdit) {
    return <NotAuthorized inEditPage />
  }

  return (
    <BaseOrganizationEdit
      projectType={PROJECT.KAP}
      pageTitle={getMessage(labelData.titleLabel)}
      helpAndInstructionsTitle={labelData.helpTextLabel}
      onBack={onBack}
      loading={loading}
    />
  )
}
