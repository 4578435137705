import { ReactElement } from 'react'
import { useParams } from 'react-router'
import { ApplicationAssessmentExclusion } from 'src/screens/shared/application/assessment/details/exclusion/ApplicationAssessmentExclusion'

export const PfPgvExclusionPage = (): ReactElement => {
  const { projectId, assessmentId } = useParams()
  const pfPgvProjectId = parseInt(projectId as string)
  const pfPgvAssessmentId = parseInt(assessmentId as string)

  return (
    <ApplicationAssessmentExclusion baseUrl="/pf-pgv" projectId={pfPgvProjectId} assessmentId={pfPgvAssessmentId} />
  )
}
