import { get } from 'lodash'
import React, { ReactElement } from 'react'
import { useParams } from 'react-router'
import { Feature_Base_Bool_Exp, Feature_Config_Bool_Exp, Query_Root } from 'src/@types/graphql'
import { ROUTES } from 'src/routing/routes'
import { fetchFactsheetByIdQuery } from 'src/screens/factsheets/factsheet/factsheetQueries'
import { FeatureBase } from 'src/screens/shared/feature/FeatureBase'
import { usePermissionsForFactsheets } from 'src/service/security/PermissionHook'
import { PageLayout } from 'src/shared/layout/PageLayout'
import { ScreenLayout } from 'src/shared/layout/ScreenLayout'
import { FactsheetExportMenu } from 'src/shared/menu/FactsheetExportMenu'
import { useDelayedNavigate } from 'src/shared/utils/hooks/navigation-hooks'
import { useUserLocale } from 'src/user/UserContext'
import { useQuery } from 'urql'

export const FactsheetFeaturesPage = (): ReactElement => {
  const { factsheetId } = useParams()
  const language = useUserLocale()
  const { canEdit } = usePermissionsForFactsheets()
  const navigate = useDelayedNavigate()

  const [{ fetching, data }] = useQuery<{
    factsheet_by_pk: Query_Root['factsheet_by_pk']
  }>({
    query: fetchFactsheetByIdQuery,
    variables: { id: parseInt(factsheetId as string) },
  })

  const factsheet = data?.factsheet_by_pk

  const handleEditFeatureTypeSection = (featureTypeId: number) => {
    navigate(ROUTES.FactsheetDescriptionRoot.nested.FactsheetFeaturesEdit.params({ factsheetId, featureTypeId }))
  }

  const factsheetBaseWhereClause: Feature_Base_Bool_Exp = {
    factsheets: {
      id: {
        _eq: parseInt(factsheetId as string),
      },
    },
  }

  const factsheetLevelWhereClause: Feature_Config_Bool_Exp = {
    levels: {
      _contains: [factsheet?.level],
    },
  }

  return (
    <>
      {!fetching && (
        <ScreenLayout title={get(factsheet?.title, language, '')} actions={<FactsheetExportMenu />}>
          <PageLayout>
            <FeatureBase
              featureBaseWhere={factsheetBaseWhereClause}
              featureConfigWhere={factsheetLevelWhereClause}
              canEdit={canEdit}
              onEditFeature={handleEditFeatureTypeSection}
            />
          </PageLayout>
        </ScreenLayout>
      )}
    </>
  )
}
