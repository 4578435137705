import { ReactElement, useCallback, useEffect, useState } from 'react'
import { Query_Root, Success_Factor_Analysis } from 'src/@types/graphql'
import { useMessageSource } from 'src/i18n/useMessageSource'
import { ROUTES } from 'src/routing/routes'
import { CreateAnalysisModal } from 'src/screens/success-factor-tool/index/add-analysis/CreateAnalysisModal'
import { SuccessFactorAnalysisGrid } from 'src/screens/success-factor-tool/index/SuccessFactorAnalysisGrid'
import { LIFE_PHASES, LIFE_PHASE_TYPE, TOPIC_TYPE } from 'src/shared/constants/success-factor-constants'
import { PageLayout } from 'src/shared/layout/PageLayout'
import { ScreenLayout } from 'src/shared/layout/ScreenLayout'
import { ModalDialog } from 'src/shared/modal-dialog/ModalDialog'
import { HelpAndInstructions } from 'src/shared/presentation/HelpAndInstructions'
import { DateUtils } from 'src/shared/utils/DateUtils'
import { useDelayedNavigate } from 'src/shared/utils/hooks/navigation-hooks'
import { useNotificationService } from 'src/shared/utils/NotificationService'
import { gql, useClient } from 'urql'

export interface SuccessFactorAnalysisModel {
  id: number
  title: string
  creationDate: string
  canton: string
  lifePhase: string
  topic: TOPIC_TYPE[] | string
}

const fetchQuery = gql`
  query fetchAnalyses {
    success_factor_analysis {
      id
      title
      creation_date
      canton_code
      life_phase_indicator
      topic_indicator
    }
  }
`

export const SuccessFactorsIndexPage = (): ReactElement => {
  const { getMessage } = useMessageSource()
  const urqlClient = useClient()
  const notificationService = useNotificationService()
  const navigate = useDelayedNavigate()

  const [analyses, setAnalyses] = useState<SuccessFactorAnalysisModel[]>([])
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)

  const mapSuccessFactorAnalysesToAnalysesModel = useCallback(
    (analyses: Success_Factor_Analysis[]): SuccessFactorAnalysisModel[] => {
      const resolveTopicIndicators = (topics: TOPIC_TYPE[]) => {
        if (topics && topics?.length > 0) {
          return topics?.map((topic: string) => getMessage(`label.success.factor.topic.${topic}`)).join(', ')
        }
        return '-'
      }

      const resolveLifePhaseIndicator = (indicator: LIFE_PHASE_TYPE) => {
        if (indicator === LIFE_PHASES.GENERAL) {
          return getMessage('label.success.factor.no.specific.life.phase')
        } else return getMessage(`label.success.factor.life.phase.${indicator}`)
      }

      return analyses.map((analysis) => ({
        id: analysis.id,
        title: analysis.title,
        creationDate: DateUtils.parseAndFormatDate(analysis.creation_date),
        canton: analysis.canton_code,
        lifePhase: resolveLifePhaseIndicator(analysis.life_phase_indicator as LIFE_PHASE_TYPE),
        topic: resolveTopicIndicators(analysis?.topic_indicator),
      }))
    },
    [getMessage],
  )

  useEffect(() => {
    const initData = async () => {
      const { data } = await urqlClient
        .query<{ success_factor_analysis: Query_Root['success_factor_analysis'] }>(fetchQuery, {})
        .toPromise()

      if (data && data?.success_factor_analysis) {
        const analyses = mapSuccessFactorAnalysesToAnalysesModel(data?.success_factor_analysis)
        setAnalyses(analyses)
      } else {
        notificationService.operationFailed()
      }
    }

    initData()
  }, [urqlClient, notificationService, mapSuccessFactorAnalysesToAnalysesModel])

  const onSuccess = (analysisId: number) => {
    setIsModalOpen(false)
    navigate(ROUTES.SuccessFactorAnalysisRoot.params({ analysisId }))
  }

  return (
    <ScreenLayout title={getMessage('label.success.factors.cockpit')} hasSecondLevelNavigation={false}>
      <PageLayout>
        <ModalDialog
          title={getMessage('label.success.factors.create.new.analysis')}
          open={isModalOpen}
          onClose={() => setIsModalOpen(false)}
        >
          <CreateAnalysisModal onCancel={() => setIsModalOpen(false)} onSuccess={onSuccess} />
        </ModalDialog>
        <HelpAndInstructions labelKey="label.success.factor.help.and.instructions.cockpit" defaultExpansion />
        <SuccessFactorAnalysisGrid analyses={analyses} onCreate={() => setIsModalOpen(true)} />
      </PageLayout>
    </ScreenLayout>
  )
}
