import { GridRenderEditCellParams, GridRowId } from '@mui/x-data-grid'
import { GridColDef, useGridApiRef } from '@mui/x-data-grid-pro'
import { GridApiPro } from '@mui/x-data-grid-pro/models/gridApiPro'
import combinedQuery from 'graphql-combine-query'
import { groupBy } from 'lodash'
import { MutableRefObject, ReactElement, useEffect, useMemo, useState } from 'react'
import { Form } from 'react-final-form'
import { useParams } from 'react-router'
import { Kap_Program_Financial_Report, Mutation_Root, Query_Root } from 'src/@types/graphql'
import { useMessageSource } from 'src/i18n/useMessageSource'
import { usePrompt } from 'src/routing/routing-hooks'
import {
  fetchKapProgramFinancialReportsByProgramIdQuery,
  fetchKapProgramModulesAndFinancialReportQuery,
  upsertKapProgramFinancialReportMutation,
} from 'src/screens/kap/implementation/milestone/kapMilestoneQueries'
import { TYPOGRAPHY_MIXIN } from 'src/shared/constants/styling-constants'
import { NumberInput } from 'src/shared/form/grid/NumberInput'
import { EditableColumnHeader } from 'src/shared/grid/EditableColumnHeader'
import { DefaultSectionTypography } from 'src/shared/presentation/DefaultSectionTypography'
import { S } from 'src/shared/styled/S'
import { DataGridUtils } from 'src/shared/utils/DataGridUtils'
import { useGridTranslateHook } from 'src/shared/utils/hooks/grid-translate-hook'
import { useError } from 'src/shared/utils/hooks/page-loading-error-hook'
import { useDelayedSubmit } from 'src/shared/utils/hooks/submit-hook'
import { useNotificationService } from 'src/shared/utils/NotificationService'
import { Utils } from 'src/shared/utils/Utils'
import styled from 'styled-components/macro'
import { useClient } from 'urql'

interface KapProgramActualsRowData {
  module: string
  gfch_actual_value: number | null
  canton_actual_value: number | null
  total_actual_value: number | null
  overall_actuals: number | null
}

interface Props {
  mode: 'view' | 'edit'
  onSave?: () => void
  reportYear?: number
}

const OverallActualsCellStyled = styled(S.Span.TextOverflow)`
  ${TYPOGRAPHY_MIXIN.subtitle2};
`

export const KapMilestoneProgramActualsGrid = ({ mode, onSave, reportYear }: Props): ReactElement => {
  const { programId, milestoneId } = useParams()
  const program_id = parseInt(programId as string)
  const milestone_id = parseInt(milestoneId as string)

  const apiRef = useGridApiRef()
  const { getMessage } = useMessageSource()
  const urqlClient = useClient()
  const notificationService = useNotificationService()
  const gridTranslations = useGridTranslateHook()
  const setError = useError()
  const submit = useDelayedSubmit()

  const [totalData, setTotalData] = useState<{ gfch_actual_value: number; canton_actual_value: number }>()
  const [financialReportData, setFinancialReportData] = useState<KapProgramActualsRowData[]>([])
  const [dirty, setDirty] = useState<boolean>(false)
  const labelTotal = getMessage('label.total')

  // simultaneously calculating total budget as user enters values
  const calculateTotalActuals = (
    value: number,
    id: GridRowId,
    field: string,
    apiRef: MutableRefObject<GridApiPro>,
  ): number => {
    const lastRowId = apiRef.current.getRowsCount() - 1
    const rows = apiRef.current
      ?.getAllRowIds()
      .filter((id) => id !== lastRowId)
      .map((rowId) => ({
        gfch_actual_value:
          field === 'gfch_actual_value' && id === rowId
            ? DataGridUtils.formatFieldValue(value)
            : DataGridUtils.formatFieldValue(apiRef.current?.getRow(rowId).gfch_actual_value),
        canton_actual_value:
          field === 'canton_actual_value' && id === rowId
            ? DataGridUtils.formatFieldValue(value)
            : DataGridUtils.formatFieldValue(apiRef.current?.getRow(rowId).canton_actual_value),
      }))

    const gfchActualsTotal = DataGridUtils.formatNaNNumber(DataGridUtils.sumBy(rows, 'gfch_actual_value'))
    const cantonActualsTotal = DataGridUtils.formatNaNNumber(DataGridUtils.sumBy(rows, 'canton_actual_value'))

    setTotalData({
      gfch_actual_value: gfchActualsTotal,
      canton_actual_value: cantonActualsTotal,
    })

    return value
  }

  const rows = useMemo(() => {
    const currentRowModels = apiRef.current?.getRowModels?.() ?? new Map()

    if (financialReportData && financialReportData.length > 0) {
      const rowData = financialReportData.map((row, index) => {
        return {
          id: index,
          module: row.module,
          gfch_actual_value: currentRowModels.get(index)?.gfch_actual_value ?? row.gfch_actual_value ?? null,
          canton_actual_value: currentRowModels.get(index)?.canton_actual_value ?? row.canton_actual_value ?? null,
          total_actual_value: (row.gfch_actual_value || 0) + (row.canton_actual_value || 0),
          overall_actuals: row.overall_actuals ?? '-',
        }
      })

      rowData.push({
        id: rowData.length,
        module: labelTotal,
        gfch_actual_value: totalData ? totalData.gfch_actual_value : DataGridUtils.sumBy(rowData, 'gfch_actual_value'),
        canton_actual_value: totalData
          ? totalData.canton_actual_value
          : DataGridUtils.sumBy(rowData, 'canton_actual_value'),
        total_actual_value: DataGridUtils.sumBy(rowData, 'total_actual_value'),
        overall_actuals: DataGridUtils.sumBy(rowData, 'overall_actuals') || '-',
      })

      return rowData
    }
    return []
  }, [apiRef, financialReportData, totalData, labelTotal])

  const columns = useMemo(() => {
    const hideColumn = mode === 'edit'
    const onRifmChange = (api: GridRenderEditCellParams['api'], id: GridRowId, field: string, value: number) => {
      calculateTotalActuals(value, id, field, apiRef)
      api.setEditCellValue({ id, field, value })
      setDirty(true)
    }
    return [
      {
        field: 'module',
        headerName: getMessage('label.module'),
        sortable: false,
        disableColumnMenu: true,
        flex: 1,
        renderCell: ({ value }) => {
          if (value === labelTotal) {
            return labelTotal
          }
          return ` ${getMessage(`label.module.description.${value}`)}`
        },
      },
      {
        field: 'gfch_actual_value',
        headerAlign: 'right',
        sortable: false,
        editable: mode === 'edit',
        disableColumnMenu: true,
        align: 'right',
        flex: 0.25,
        renderCell: ({ value }) => {
          if (value === null || value === '' || Number.isNaN(value)) {
            return <DefaultSectionTypography $standAlone />
          }
          return <S.Span.TextOverflow>{value?.toLocaleString('de-CH')}</S.Span.TextOverflow>
        },
        renderEditCell: (params) => {
          const { value, id, field, api } = params
          const isValid = DataGridUtils.validateValue(value)
          return (
            <NumberInput
              value={value as number}
              onChange={(val: number) => onRifmChange(api, id, field, val)}
              error={!isValid}
            />
          )
        },
        renderHeader: () => (
          <EditableColumnHeader headerName={getMessage('label.actuals.gfch')} editMode={mode === 'edit'} />
        ),
      },
      {
        field: 'canton_actual_value',
        headerAlign: 'right',
        sortable: false,
        editable: mode === 'edit',
        disableColumnMenu: true,
        align: 'right',
        flex: 0.25,
        renderCell: ({ value }) => {
          if (value === null || value === '' || Number.isNaN(value)) {
            return <DefaultSectionTypography $standAlone />
          }
          return <S.Span.TextOverflow>{value?.toLocaleString('de-CH')}</S.Span.TextOverflow>
        },
        renderEditCell: (params) => {
          const { value, id, field, api } = params
          const isValid = DataGridUtils.validateValue(value)
          return (
            <NumberInput
              value={value as number}
              onChange={(val: number) => onRifmChange(api, id, field, val)}
              error={!isValid}
            />
          )
        },
        renderHeader: () => (
          <EditableColumnHeader headerName={getMessage('label.actuals.canton')} editMode={mode === 'edit'} />
        ),
      },
      {
        field: 'total_actual_value',
        headerName: `${getMessage('label.total.actuals')} ${reportYear}`,
        headerAlign: 'right',
        sortable: false,
        disableColumnMenu: true,
        align: 'right',
        flex: 0.25,
        hide: hideColumn,
        renderCell: ({ row }) => {
          const gfchActualValue = DataGridUtils.formatNaNNumber(row.gfch_actual_value)?.toString() || '0'
          const cantonActualValue = DataGridUtils.formatNaNNumber(row.canton_actual_value)?.toString() || '0'

          const totalActualValue = DataGridUtils.formatFieldValue(
            parseInt(gfchActualValue) + parseInt(cantonActualValue),
          )
          return <S.Span.TextOverflow>{totalActualValue?.toLocaleString('de-CH')}</S.Span.TextOverflow>
        },
      },
      {
        field: 'overall_actuals',
        headerName: getMessage('label.actuals.overall'),
        headerAlign: 'right',
        sortable: false,
        disableColumnMenu: true,
        align: 'right',
        flex: 0.25,
        hide: hideColumn,
        renderCell: ({ value }) => (
          <OverallActualsCellStyled>{value?.toLocaleString('de-CH')}</OverallActualsCellStyled>
        ),
      },
    ] as GridColDef[]
  }, [apiRef, getMessage, mode, labelTotal, reportYear])

  useEffect(() => {
    const fetchData = async () => {
      const { document, variables } = combinedQuery('kapProgramFinancialReports')
        .add<
          {
            kap_program_by_pk: Query_Root['kap_program_by_pk']
          },
          { programId: number; milestoneId: number }
        >(fetchKapProgramModulesAndFinancialReportQuery, { programId: program_id, milestoneId: milestone_id })
        .add<
          {
            kap_program_financial_all_reports: Query_Root['kap_program_financial_report']
          },
          { id: number }
        >(fetchKapProgramFinancialReportsByProgramIdQuery, { id: program_id })

      const { data } = await urqlClient
        .query<{
          kap_program_by_pk: Query_Root['kap_program_by_pk']
          kap_program_financial_all_reports: Query_Root['kap_program_financial_report']
        }>(document, variables)
        .toPromise()

      if (data?.kap_program_by_pk) {
        const financialReport = data?.kap_program_by_pk.dossier.milestones?.[0]?.kap_program_financial_reports
        let programModules = data?.kap_program_by_pk.modules.map((value: string) => ({
          module: value,
        }))

        programModules = Utils.mergeBy({ arr: programModules, key: 'module' }, { arr: financialReport, key: 'module' })
        const allFinancialReports = groupBy(data?.kap_program_financial_all_reports, 'module')
        const financialReportDataWithOverallActuals = programModules.map((moduleReport: any) => ({
          ...moduleReport,
          overall_actuals:
            DataGridUtils.sumBy(allFinancialReports[moduleReport.module], 'gfch_actual_value') +
            DataGridUtils.sumBy(allFinancialReports[moduleReport.module], 'canton_actual_value'),
        }))

        setFinancialReportData(financialReportDataWithOverallActuals)
      } else {
        setError()
      }
    }
    fetchData()
  }, [urqlClient, setError, program_id, milestone_id])

  const onSaveLocal = async () => {
    const allRows = apiRef.current.getRowModels()
    const lastRowId = apiRef.current.getRowsCount() - 1
    const editableRows = Array.from(allRows.values()).filter((row) => row.id !== lastRowId)
    const newFinancialReportData = editableRows.map((row) => ({
      milestone_id: milestone_id,
      module: row.module,
      gfch_actual_value: DataGridUtils.formatFieldValue(row.gfch_actual_value),
      canton_actual_value: DataGridUtils.formatFieldValue(row.canton_actual_value),
    }))

    const updateColumns: Array<keyof Kap_Program_Financial_Report> = ['gfch_actual_value', 'canton_actual_value']

    const { data, error } = await urqlClient
      .mutation<{
        insert_kap_program_financial_report: Mutation_Root['insert_kap_program_financial_report']
      }>(upsertKapProgramFinancialReportMutation, {
        kapProgramFinancialReport: newFinancialReportData,
        updateColumns: updateColumns,
      })
      .toPromise()

    if (error || data?.insert_kap_program_financial_report?.affected_rows === 0) {
      setError()
    } else {
      if (onSave) {
        const newData = newFinancialReportData.map((data) => ({
          module: data.module,
          gfch_actual_value: data.gfch_actual_value,
          canton_actual_value: data.canton_actual_value,
        })) as typeof financialReportData

        notificationService.changesSaved()
        setFinancialReportData(newData)
        setDirty(false)
        onSave()
      }
    }
  }

  usePrompt(getMessage('notification.form.unsaved.changes'), dirty)

  return (
    <Form
      onSubmit={submit(onSaveLocal)}
      render={({ handleSubmit }) => (
        <form
          id="edit-form"
          onSubmit={handleSubmit}
          onKeyPress={(event) => {
            if (event.key === 'Enter') {
              event.preventDefault()
            }
          }}
        >
          <S.DataGrid.TotalRow
            rows={rows}
            columns={columns}
            autoHeight
            hideFooter
            disableColumnReorder
            disableRowSelectionOnClick
            disableColumnSelector
            apiRef={apiRef}
            onCellEditStop={() => setDirty(true)}
            localeText={gridTranslations}
            isCellEditable={({ row }: { row: any }) => row.id !== rows.length - 1}
          />
        </form>
      )}
    />
  )
}
