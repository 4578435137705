import { LoadingButton } from '@mui/lab'
import { Box, DialogActions, DialogContent } from '@mui/material'
import { useMemo, useState } from 'react'
import { Mutation_Root, RegisterUserByGfchInput } from 'src/@types/graphql'
import { useMessageSource } from 'src/i18n/useMessageSource'
import {
  AdministrationUserForm,
  AdministrationUserFormValues,
} from 'src/screens/administration/user-administration/common/AdministrationUserForm'
import { SecondaryButton } from 'src/shared/button/Buttons'
import { useNotificationService } from 'src/shared/utils/NotificationService'
import { gql, useClient } from 'urql'

interface Props {
  onCancel: () => void
  onSuccess: () => void
}

const registerUserByGfch = gql`
  mutation registerUserByGfch($user: registerUserByGfchInput!) {
    registerUserByGfch(user: $user) {
      status
      failureKey
    }
  }
`

export const AddUserModal = ({ onCancel, onSuccess }: Props) => {
  const { getMessage } = useMessageSource()
  const urqlClient = useClient()
  const notificationService = useNotificationService()

  const [loading, setLoading] = useState<boolean>(false)
  const initialValues = useMemo(() => {
    return { roles: [] } as unknown as AdministrationUserFormValues
  }, [])

  const onSubmit = () => {
    document
      .getElementById('administration-edit-form')
      ?.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }))
  }

  const handleSubmitLocal = async (values: AdministrationUserFormValues) => {
    const refinedValue = {
      ...values,
      gfchCantonalResponsibility: values?.gfchCantonalResponsibility?.length
        ? values.gfchCantonalResponsibility.sort()
        : null,
      strategicResponsibility: values?.strategicResponsibility?.length ? values.strategicResponsibility.sort() : null,
      operationalResponsibility: values?.operationalResponsibility?.length
        ? values.operationalResponsibility.sort()
        : null,
    }

    try {
      setLoading(true)

      const { data } = await urqlClient
        .mutation<{ registerUserByGfch: Mutation_Root['registerUserByGfch'] }, { user: RegisterUserByGfchInput }>(
          registerUserByGfch,
          {
            user: refinedValue as RegisterUserByGfchInput,
          },
        )
        .toPromise()

      if (data) {
        const status = data.registerUserByGfch.status
        const failureKey = data.registerUserByGfch.failureKey

        if (status === 'SUCCESS') {
          notificationService.success(getMessage('notification.user.registration.success'))
          onSuccess()
        } else {
          if (failureKey) {
            notificationService.error(getMessage(failureKey))
          } else {
            notificationService.error(getMessage('notification.registration.failure'))
          }
        }
      } else {
        notificationService.error(getMessage('notification.registration.failure'))
      }
    } catch (e) {
      notificationService.error(getMessage('notification.registration.failure'))
    } finally {
      setLoading(false)
    }
  }

  return (
    <Box>
      <DialogContent>
        <AdministrationUserForm onSave={handleSubmitLocal} initialValues={initialValues} mode={'ADD'} />
      </DialogContent>
      <DialogActions>
        <SecondaryButton messageKey={'button.cancel'} onClick={onCancel} />
        <LoadingButton variant="contained" color="primary" onClick={onSubmit} loading={loading}>
          {getMessage('button.save')}
        </LoadingButton>
      </DialogActions>
    </Box>
  )
}
