import { Box, Typography, TypographyTypeMap } from '@mui/material'
import { AddButton } from 'src/shared/button/Buttons'
import { S } from 'src/shared/styled/S'

export const AddKapProgramToolbar = ({
  title,
  variant = 'h3',
  addProgram,
  canCreateProgram,
}: {
  title?: string
  variant?: TypographyTypeMap['props']['variant']
  addProgram: React.MouseEventHandler<HTMLButtonElement>
  canCreateProgram: boolean
}) => {
  return (
    <S.DataGrid.ToolbarContainer>
      <Typography variant={variant}>{title}</Typography>
      <Box>
        <AddButton messageKey="button.kap.program.add" onClick={addProgram} hidden={!canCreateProgram} />
      </Box>
    </S.DataGrid.ToolbarContainer>
  )
}
