import { InfoOutlined } from '@mui/icons-material'
import { Box, Button, Card, CardActions, CardContent, CardHeader, Typography } from '@mui/material'
import Alert from '@mui/material/Alert'
import Stack from '@mui/material/Stack'
import { ReactElement, ReactNode, useState } from 'react'
import { valueof } from 'src/@types/global'
import { Feature_Type_Config, ProjectValidationResponse } from 'src/@types/graphql'
import { useMessageSource } from 'src/i18n/useMessageSource'
import { ROUTES } from 'src/routing/routes'
import { resolveApplicationDetailsRoot } from 'src/routing/routing-utils'
import { ValidationListItemProps } from 'src/screens/shared/common/SummaryValidationUtils'
import { WorkflowService } from 'src/service/axios/WorkflowService'
import { usePermissionsForProject } from 'src/service/security/PermissionHook'
import {
  DOSSIER_STATUS,
  DOSSIER_STATUS_TYPE,
  PROJECT,
  PROJECT_TYPE,
  PROJECT_USER_ROLE_TYPE,
  USER_ROLES_TYPE,
} from 'src/shared/constants/constants'
import { STYLED_FOCUS_OUTLINE } from 'src/shared/constants/styling-constants'
import { NextState } from 'src/shared/constants/workflow-constants'
import { CheckIcon, DeleteIcon, ErrorOutlineIcon, GoToPageIcon } from 'src/shared/icons/Icons'
import { HandoverModalDialog } from 'src/shared/modal-dialog/HandoverModalDialog'
import { SecondaryConfirmationModalDialog } from 'src/shared/modal-dialog/SecondaryConfirmationModalDialog'
import { Section } from 'src/shared/presentation/Section'
import { useSidebarAPI } from 'src/shared/sidebar/SidebarAwareContext'
import { useModalCancel } from 'src/shared/utils/hooks/modal-hooks'
import { useDelayedNavigate } from 'src/shared/utils/hooks/navigation-hooks'
import { useNotificationService } from 'src/shared/utils/NotificationService'
import { NextStateIf } from 'src/shared/workflow/NextStateIf'
import { useUserLocale } from 'src/user/UserContext'
import styled from 'styled-components/macro'

interface ProjectSummarySectionProps {
  title: string
  status: DOSSIER_STATUS_TYPE
  helpAndInstructions: ReactNode
  actionsCompleteMessage: string
  violations: ProjectValidationResponse[]
  featureTypes: Feature_Type_Config[]
  refetchPageData: () => void
  workflowId: string
  projectId: number
  process: PROJECT_TYPE
  nextStates: NextState[]
  baseUrl: '/pf-kap' | '/pf-pgv'
  userProjectRoles: Array<valueof<PROJECT_USER_ROLE_TYPE>>
  userGlobalRoles: Array<USER_ROLES_TYPE>
  violationClickHandler: (validationItem: ValidationListItemProps) => void
  noItemsToValidate: boolean
  existsActiveFundingRound: boolean
  canDeleteProject: boolean
}

interface AlertProps {
  message: string
  onClick?: () => void
  onKeyPress?: (event: any) => void
  noItemsToValidate?: boolean
}

const listHeight = '2.5rem'

const ListTitle = styled(Typography)`
  height: ${listHeight};
  display: flex;
  align-items: center;
`

const AlertStyled = styled(Alert)`
  height: ${listHeight};
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.text.primary};

  & .MuiAlert-action {
    padding-top: 0;
  }
`

const AlertWarningStyled = styled(AlertStyled)`
  &:hover {
    cursor: pointer;
    background-color: ${({ theme }) => theme.colors.primary.light};
  }

  &:hover .MuiAlert-action {
    & .MuiSvgIcon-root {
      color: ${({ theme }) => theme.colors.primary.main};
    }
  }

  &:focus-visible {
    ${STYLED_FOCUS_OUTLINE};
    outline-offset: 0;
    background-color: ${({ theme }) => theme.colors.primary.light};
  }
`

const AlertSuccessStyled = styled(AlertStyled)`
  background-color: ${({ theme }) => theme.colors.secondary.light};

  & .MuiSvgIcon-root {
    color: ${({ theme }) => theme.colors.secondary.main};
  }
`

const AlertInfoStyled = styled(AlertStyled)`
  background-color: ${({ theme }) => theme.colors.grey.neutral};

  & .MuiSvgIcon-root {
    color: ${({ theme }) => theme.colors.text.disabled};
  }
`

const ListItemIncomplete = ({ message, ...rest }: AlertProps): ReactElement => {
  return (
    <AlertWarningStyled severity="warning" icon={<ErrorOutlineIcon />} action={<GoToPageIcon />} {...rest} tabIndex={0}>
      {message}
    </AlertWarningStyled>
  )
}

const ListItemComplete = ({ message, noItemsToValidate, ...rest }: AlertProps): ReactElement => {
  return noItemsToValidate ? (
    <AlertInfoStyled severity="info" icon={<InfoOutlined />} {...rest}>
      {message}
    </AlertInfoStyled>
  ) : (
    <AlertSuccessStyled severity="success" icon={<CheckIcon />} {...rest}>
      {message}
    </AlertSuccessStyled>
  )
}

const SpanStyled = styled.span<{ $isCanceled: string }>`
  color: ${({ $isCanceled, theme }) =>
    $isCanceled === `${DOSSIER_STATUS.CANCELED}` ? theme.colors.error.dark : 'inherit'};
  line-height: 2.25rem;
`

export const ProjectSummarySection = ({
  title,
  status,
  helpAndInstructions,
  actionsCompleteMessage,
  violations,
  featureTypes,
  violationClickHandler,
  workflowId,
  projectId,
  nextStates,
  process,
  userGlobalRoles,
  userProjectRoles,
  refetchPageData,
  baseUrl,
  noItemsToValidate,
  existsActiveFundingRound,
  canDeleteProject,
}: ProjectSummarySectionProps): ReactElement => {
  const { getMessage } = useMessageSource()

  const language = useUserLocale()
  const sidebarAPI = useSidebarAPI()
  const notificationService = useNotificationService()
  const navigate = useDelayedNavigate()

  const {
    canEdit,
    canTransitToApplication,
    metadata: { projectBaseId },
  } = usePermissionsForProject(process, projectId, existsActiveFundingRound)
  const shouldShowNoActiveRoundWarning =
    canEdit && !canTransitToApplication && nextStates.some((state) => state.state === DOSSIER_STATUS.APPLICATION)

  const [applicationConfirmationOpen, setApplicationConfirmationOpen] = useState(false)
  const [sendingToGfch, setSendingToGfch] = useState(false)
  const [deleteProjectConfirmationOpen, setDeleteProjectConfirmationOpen] = useState(false)
  const [deleteProjectLoading, setDeleteProjectLoading] = useState(false)

  const [withdrawnConfirmationOpen, setWithdrawnConfirmationOpen] = useState(false)
  const [transitionToWithdrawnLoading, setTransitionToWithdrawnLoading] = useState(false)

  const openApplicationConfirmationModalDialog = () => {
    setApplicationConfirmationOpen(true)
  }

  const cancelApplicationConfirmationModalDialog = useModalCancel(() => setApplicationConfirmationOpen(false))

  const onSendToGfch = async () => {
    try {
      setSendingToGfch(true)
      await WorkflowService.transition(workflowId, DOSSIER_STATUS.APPLICATION, language)
      refetchPageData()
      sidebarAPI.refetchSidebarData()
      navigate(resolveApplicationDetailsRoot(baseUrl).params({ projectId }))
      setApplicationConfirmationOpen(false)
      notificationService.changesSaved()
    } catch (e) {
      notificationService.operationFailed()
    } finally {
      setSendingToGfch(false)
    }
  }

  const openWithdrawnConfirmationModalDialog = () => {
    setWithdrawnConfirmationOpen(true)
  }

  const cancelWithdrawnConfirmationModalDialog = useModalCancel(() => setWithdrawnConfirmationOpen(false))

  const confirmWithdrawnHandler = async () => {
    try {
      setTransitionToWithdrawnLoading(true)
      await WorkflowService.transition(workflowId, DOSSIER_STATUS.WITHDRAWN, language)
      refetchPageData()
      sidebarAPI.refetchSidebarData()
      setWithdrawnConfirmationOpen(false)
      notificationService.changesSaved()
    } catch (e) {
      notificationService.operationFailed()
    } finally {
      setTransitionToWithdrawnLoading(false)
    }
  }

  const openDeleteProjectConfirmationModalDialog = () => {
    setDeleteProjectConfirmationOpen(true)
  }

  const onCancelDelete = () => {
    setDeleteProjectConfirmationOpen(false)
  }

  const onConfirmDelete = () => {
    if (projectBaseId) {
      setDeleteProjectLoading(true)

      WorkflowService.deleteProject(projectBaseId, process)
        .then(() => {
          notificationService.deleteSuccessful()
          if (process === PROJECT.PF_KAP) {
            navigate(`/${ROUTES.PfKapIndex.path}`)
          } else {
            navigate(`/${ROUTES.PfPgvIndex.path}`)
          }
        })
        .catch(() => {
          notificationService.operationFailed()
        })
        .finally(() => {
          setDeleteProjectLoading(false)
        })
    }
  }

  return (
    <>
      <Section
        title={title}
        helpAndInstructions={helpAndInstructions}
        actionButton={
          canDeleteProject && (
            <Button
              variant="contained"
              color="error"
              onClick={openDeleteProjectConfirmationModalDialog}
              startIcon={<DeleteIcon />}
            >
              {getMessage('button.project.delete')}
            </Button>
          )
        }
      >
        <Card>
          {shouldShowNoActiveRoundWarning && (
            <Box sx={{ m: 2 }}>
              <Alert severity={'error'}>{getMessage('label.project.application.no.active.funding.round')}</Alert>
            </Box>
          )}
          <CardHeader
            title={
              <>
                {getMessage('label.project.status')}:{' '}
                <SpanStyled $isCanceled={status}>{getMessage(`label.project.status.${status}`)}</SpanStyled>
              </>
            }
            action={
              <CardActions sx={{ padding: 0 }}>
                <NextStateIf
                  forState={DOSSIER_STATUS.WITHDRAWN}
                  nextStates={nextStates}
                  userGlobalRoles={userGlobalRoles}
                  userProjectRoles={userProjectRoles}
                >
                  <Button
                    variant="outlined"
                    color="secondary"
                    sx={{
                      display: status !== DOSSIER_STATUS.REVISION || !canEdit ? 'none' : 'inline-block',
                    }}
                    onClick={openWithdrawnConfirmationModalDialog}
                  >
                    {getMessage('button.set.to.withdrawn')}
                  </Button>
                </NextStateIf>

                <NextStateIf
                  forState={DOSSIER_STATUS.APPLICATION}
                  nextStates={nextStates}
                  userGlobalRoles={userGlobalRoles}
                  userProjectRoles={userProjectRoles}
                >
                  <Button
                    variant="contained"
                    color="secondary"
                    disabled={violations.length > 0 || !canTransitToApplication}
                    onClick={openApplicationConfirmationModalDialog}
                  >
                    {getMessage('button.generate.application')}
                  </Button>
                </NextStateIf>
              </CardActions>
            }
          />
          <CardContent>
            <Stack spacing={1}>
              <ListTitle variant="h6">
                {getMessage('label.project.summary.description.system.check.for.completion')}
              </ListTitle>

              {violations.length === 0 ? (
                <ListItemComplete message={actionsCompleteMessage} noItemsToValidate={noItemsToValidate} />
              ) : (
                violations?.map((violation) => {
                  const listItemsProps: ValidationListItemProps[] = []

                  if (violation.missingFeatureTypes.length > 0) {
                    for (const missingFeatureTypeCode of violation?.missingFeatureTypes ?? []) {
                      const featureType = featureTypes.find(
                        (featureType) => featureType.code === missingFeatureTypeCode,
                      )
                      if (featureType !== undefined) {
                        const message = getMessage(violation.validationViolationMessageKey, [
                          featureType.names[language],
                        ])
                        listItemsProps.push({
                          message: message,
                          tab: violation.projectDescriptionTab,
                          section: missingFeatureTypeCode,
                        })
                      }
                    }
                  } else {
                    const labelArguments = violation.labelArguments.map((labelArgument) => getMessage(labelArgument))
                    const message = getMessage(violation.validationViolationMessageKey, labelArguments)
                    listItemsProps.push({
                      message: message,
                      tab: violation.projectDescriptionTab,
                      section: violation.section ?? undefined,
                    })
                  }

                  return listItemsProps.map((listItemProps) => {
                    return (
                      <ListItemIncomplete
                        message={listItemProps.message}
                        key={listItemProps.message}
                        onClick={() => violationClickHandler(listItemProps)}
                        onKeyPress={(event) => {
                          if (['Enter', ' '].includes(event.key)) {
                            violationClickHandler(listItemProps)
                          }
                        }}
                      />
                    )
                  })
                })
              )}
            </Stack>
          </CardContent>
        </Card>
      </Section>
      <HandoverModalDialog
        open={applicationConfirmationOpen}
        onSend={onSendToGfch}
        onCancel={cancelApplicationConfirmationModalDialog}
        entityId={projectId}
        loading={sendingToGfch}
        processType={process}
        buttonLoadingKey="button.project.send.to.gfch"
        titleKey="label.project.application.confirm.title"
        contentKey="label.project.application.confirm.body"
        pdfDownloadVisible
      />
      <HandoverModalDialog
        open={withdrawnConfirmationOpen}
        onSend={confirmWithdrawnHandler}
        onCancel={cancelWithdrawnConfirmationModalDialog}
        entityId={projectId}
        loading={transitionToWithdrawnLoading}
        processType={process}
        titleKey="label.project.confirm.withdrawn.title"
        contentKey="label.project.confirm.withdrawn.body"
        buttonLoadingKey="button.confirm"
      />
      <SecondaryConfirmationModalDialog
        open={deleteProjectConfirmationOpen}
        onCancel={onCancelDelete}
        onConfirm={onConfirmDelete}
        titleKey={'label.delete.confirm.project.title'}
        confirmButtonKey={'button.project.delete'}
        buttonColor={'error'}
        loading={deleteProjectLoading}
      >
        {getMessage('label.delete.confirm.project')}
      </SecondaryConfirmationModalDialog>
    </>
  )
}
