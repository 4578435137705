import { Box, Divider, Stack, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { Query_Root } from 'src/@types/graphql'
import { useMessageSource } from 'src/i18n/useMessageSource'
import { FilterFieldWrapper } from 'src/screens/shared/common/filter-card/FilterFieldWrapper'
import { PROJECT, PROJECT_TYPE } from 'src/shared/constants/constants'
import { Option } from 'src/shared/form/control'
import { AutoCompleteField } from 'src/shared/form/control/AutoCompleteField'
import { DateTimePickerField } from 'src/shared/form/control/DateTimePickerField'
import { validYear } from 'src/shared/form/validation/validators'
import { useNotificationService } from 'src/shared/utils/NotificationService'
import styled from 'styled-components/macro'
import { gql, useClient } from 'urql'

interface Props {
  startYearName: string
  endYearName: string
  fundingRoundName?: string
  process: PROJECT_TYPE
  error?: boolean
}

const BoxStyled = styled(Box)`
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  align-items: center;
  flex-basis: 150%;

  .MuiTypography-root {
    margin-inline: ${({ theme }) => theme.spacing(2)};
    margin-bottom: 5px; // to align with label of standard text field (margin-bottom is 5px)
    align-self: end;
  }
`

const fetchFundingRoundsQuery = gql`
  query ($process: String!) {
    funding_round(where: { process: { _eq: $process } }, order_by: [{ end_date: desc }, { name: asc }]) {
      id
      name
      end_date
      process
    }
  }
`

export const ReportingPeriodFilter = ({ startYearName, endYearName, fundingRoundName, process, error }: Props) => {
  const { getMessage } = useMessageSource()
  const urqlClient = useClient()
  const notificationService = useNotificationService()

  const isKapProgram = process === PROJECT.KAP

  const [fundingRounds, setFundingRounds] = useState<Option[]>([])

  useEffect(() => {
    const fetchData = async () => {
      const { data } = await urqlClient
        .query<{ funding_round: Query_Root['funding_round'] }>(fetchFundingRoundsQuery, {
          process: process,
        })
        .toPromise()

      if (data && data.funding_round) {
        setFundingRounds(
          data.funding_round.map((it) => ({
            label: it.name,
            value: it.id,
          })),
        )
      } else {
        notificationService.operationFailed()
      }
    }

    fetchData()
  }, [getMessage, notificationService, process, urqlClient])

  return (
    <FilterFieldWrapper label={getMessage('label.reporting.filter.reporting.period')} required error={error}>
      <Stack spacing={2} direction="row" divider={<Divider orientation="vertical" flexItem />} flexGrow={1}>
        <BoxStyled>
          <DateTimePickerField
            name={startYearName}
            label={getMessage('label.start.year')}
            variant="standard"
            validate={validYear()}
            yearOnly="start"
            required={isKapProgram}
          />
          <Typography>{getMessage('label.to.year')}</Typography>
          <DateTimePickerField
            name={endYearName}
            label={getMessage('label.end.year')}
            variant="standard"
            validate={validYear()}
            yearOnly="end"
            required={isKapProgram}
          />
        </BoxStyled>
        {!isKapProgram && (
          <AutoCompleteField
            name={fundingRoundName ?? ''}
            label={getMessage('label.funding.round')}
            options={fundingRounds}
            variant="standard"
            fullWidth
          />
        )}
      </Stack>
    </FilterFieldWrapper>
  )
}
