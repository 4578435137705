import { Divider, ListItemIcon, ListItemText } from '@mui/material'
import { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react'
import { useParams } from 'react-router'
import { Query_Root } from 'src/@types/graphql'
import { useMessageSource } from 'src/i18n/useMessageSource'
import { ENTITY_TYPE, EXPORT_FORMAT, FILE_TYPE, PROJECT_TYPE } from 'src/shared/constants/constants'
import { DownloadIcon } from 'src/shared/icons/Icons'
import { ExportMenu } from 'src/shared/menu/ExportMenu'
import { queryFiles } from 'src/shared/presentation/fileQueries'
import { S } from 'src/shared/styled/S'
import { Utils } from 'src/shared/utils/Utils'
import { useUserLocale } from 'src/user/UserContext'
import { useClient } from 'urql'
import { LoadingMenuItem } from 'src/shared/menu/item/LoadingMenuItem'
import { downloadFileByUrl } from 'src/shared/utils/BlobUtils'

interface Props {
  entityId: number
  process: PROJECT_TYPE
}

interface RefHandle {
  refetch: () => void
}

export const ProjectExportMenu = forwardRef<RefHandle, Props>(({ entityId, process }, ref) => {
  const { getMessage } = useMessageSource()
  const { projectId } = useParams()
  const language = useUserLocale()
  const urqlClient = useClient()

  const exportMenuRef = useRef<{ closeMenu: () => void }>({ closeMenu: () => {} })

  const fileType = useMemo(() => FILE_TYPE.PROJECT_APPLICATION_PDF, [])
  const entityType = useMemo(() => ENTITY_TYPE.PROJECT_BASE, [])

  const [data, setData] = useState<{ file: Query_Root['file'] }>()
  const [loadingExportApplication, setLoadingExportApplication] = useState(false)
  const [loadingExportDescription, setLoadingExportDescription] = useState(false)

  const fetchFiles = useCallback(async () => {
    const { data } = await urqlClient
      .query<
        { file: Query_Root['file'] },
        {
          fileType: string
          entityType: string
          entityId: number
        }
      >(queryFiles, { fileType, entityType, entityId })
      .toPromise()

    if (data) {
      setData(data)
    }
  }, [urqlClient, fileType, entityType, entityId])

  useEffect(() => {
    fetchFiles()
  }, [fetchFiles])

  useImperativeHandle(ref, () => ({
    refetch() {
      fetchFiles()
    },
  }))

  const handleProjectApplicationExport = () => {
    setLoadingExportApplication(true)
    Utils.downloadFile(data?.file?.[0].uuid)
      .then(() => exportMenuRef.current.closeMenu())
      .finally(() => setLoadingExportApplication(false))
  }

  const handleProjectDescriptionExport = () => {
    setLoadingExportDescription(true)
    downloadFileByUrl(`api/${process}/project/${projectId}/export/description?lang=${language}`)
      .then(() => exportMenuRef.current.closeMenu())
      .finally(() => setLoadingExportDescription(false))
  }

  return (
    <ExportMenu ref={exportMenuRef}>
      <LoadingMenuItem
        loading={loadingExportDescription}
        disabled={loadingExportDescription}
        onClick={handleProjectDescriptionExport}
      >
        <ListItemText>{getMessage('button.export.description')}</ListItemText>
        <S.Typography.ExportFormat>({EXPORT_FORMAT.WORD})</S.Typography.ExportFormat>
      </LoadingMenuItem>
      <Divider />
      <LoadingMenuItem
        loading={loadingExportApplication}
        disabled={(data?.file?.length === 0 ?? true) || loadingExportApplication}
        onClick={handleProjectApplicationExport}
      >
        <ListItemIcon>
          <DownloadIcon />
        </ListItemIcon>
        <ListItemText>{getMessage('button.download.application')}</ListItemText>
        <S.Typography.ExportFormat>({EXPORT_FORMAT.PDF})</S.Typography.ExportFormat>
      </LoadingMenuItem>
    </ExportMenu>
  )
})
