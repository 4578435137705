import { Stack } from '@mui/material'
import { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { Project_Journal, Query_Root } from 'src/@types/graphql'
import { useMessageSource } from 'src/i18n/useMessageSource'
import { ROUTES } from 'src/routing/routes'
import { ExpandableJournalCard } from 'src/screens/shared/project/journal/journal-card/ExpandableJournalCard'
import { queryProjectJournalsPrimaryInfoByProjectBaseId } from 'src/screens/shared/project/journal/projectJournalQueries'
import { usePermissionsForProject } from 'src/service/security/PermissionHook'
import { AddButton } from 'src/shared/button/Buttons'
import { PROJECT, PROJECT_TYPE } from 'src/shared/constants/constants'
import { PageLayout } from 'src/shared/layout/PageLayout'
import { ScreenLayout } from 'src/shared/layout/ScreenLayout'
import { ProjectExportMenu } from 'src/shared/menu/ProjectExportMenu'
import { NotAuthorized } from 'src/shared/not-authorized/NotAuthorized'
import { HelpAndInstructions } from 'src/shared/presentation/HelpAndInstructions'
import { Section } from 'src/shared/presentation/Section'
import { useDelayedNavigate } from 'src/shared/utils/hooks/navigation-hooks'
import { useNotificationService } from 'src/shared/utils/NotificationService'
import { useClient } from 'urql'

interface Props {
  projectType: PROJECT_TYPE
}

export const ProjectJournal = ({ projectType }: Props) => {
  const { projectId } = useParams()
  const { getMessage } = useMessageSource()

  const urqlClient = useClient()

  const navigate = useDelayedNavigate()
  const notificationService = useNotificationService()

  const [journals, setJournals] = useState<Project_Journal[]>()

  const {
    canView,
    canEdit,
    loading,
    metadata: { projectBaseId },
  } = usePermissionsForProject(projectType, parseInt(projectId as string))

  const fetchData = useCallback(async () => {
    const { data } = await urqlClient
      .query<{ project_journal: Query_Root['project_journal'] }>(queryProjectJournalsPrimaryInfoByProjectBaseId, {
        project_base_id: projectBaseId,
      })
      .toPromise()

    if (data?.project_journal) {
      setJournals(data?.project_journal)
    } else {
      notificationService.operationFailed()
    }
  }, [notificationService, projectBaseId, urqlClient])

  useEffect(() => {
    if (projectBaseId) {
      fetchData()
    }
  }, [projectBaseId, fetchData])

  const handleAddJournalEntry = () => {
    if (projectType === PROJECT.PF_KAP) {
      navigate(ROUTES.PfKapProjectJournalNew.params({ projectId }))
    } else if (projectType === PROJECT.PF_PGV) {
      navigate(ROUTES.PfPgvProjectJournalNew.params({ projectId }))
    }
  }

  const handleEditJournalEntry = (journalId: number) => {
    if (projectType === PROJECT.PF_KAP) {
      navigate(ROUTES.PfKapProjectJournalEdit.params({ projectId, journalId }))
    } else if (projectType === PROJECT.PF_PGV) {
      navigate(ROUTES.PfPgvProjectJournalEdit.params({ projectId, journalId }))
    }
  }

  if (!loading && !canView) {
    return <NotAuthorized />
  }

  return (
    <ScreenLayout
      title={getMessage('label.navigation.project.journal')}
      actions={<ProjectExportMenu process={projectType} entityId={parseInt(projectId as string)} />}
      hasSecondLevelNavigation={false}
    >
      <PageLayout>
        {!loading && !!journals && (
          <Section
            title={getMessage('label.project.journal.entries')}
            actionButton={
              <AddButton
                messageKey={getMessage('button.add.project.journal')}
                onClick={handleAddJournalEntry}
                hidden={!canEdit}
              />
            }
            helpAndInstructions={<HelpAndInstructions labelKey="label.help.project.journal" />}
          >
            {journals && journals.length && (
              <Stack spacing={1}>
                {journals.map((journal) => {
                  return (
                    <ExpandableJournalCard
                      key={journal.id}
                      id={String(journal.id)}
                      journal={journal}
                      canEdit={canEdit}
                      onEdit={() => handleEditJournalEntry(journal.id)}
                      refetch={fetchData}
                    />
                  )
                })}
              </Stack>
            )}
          </Section>
        )}
      </PageLayout>
    </ScreenLayout>
  )
}
