import { Kap_Program_Bool_Exp } from 'src/@types/graphql'
import { gql } from 'urql'

interface KapProgramConflictProps {
  constraint: string
  update_columns: keyof Kap_Program_Bool_Exp
}

export const updateKapProgramsByContactOrganizationOnConflictClause: KapProgramConflictProps = {
  constraint: 'kap_program_pk',
  update_columns: 'contact_organization_id',
}

export const fetchKapProgramOrganizationResponsibilityQuery = gql`
  query fetchKapProgramOrganizationResponsibility($id: Int!) {
    kap_program_by_pk(id: $id) {
      id
      canton_code
      modules
      organization_by_contact_organization {
        id
        name
      }
      kap_program_organizations {
        organization {
          id
          name
        }
        type
      }
    }
  }
`

export const fetchUserProgramResponsibilityQuery = gql`
  query fetchUserProgramResponsibility($canton: String!, $modules: [String!]) {
    user(
      where: {
        _or: [
          { gfch_cantonal_responsibility: { _has_keys_any: [$canton] } }
          {
            _and: [
              { cantonal_user_canton: { _eq: $canton } }
              {
                _or: [
                  { strategic_responsibility: { _has_keys_any: $modules } }
                  { operational_responsibility: { _has_keys_any: $modules } }
                ]
              }
            ]
          }
        ]
      }
    ) {
      id
      last_name
      first_name
      type
      cantonal_user_canton
      gfch_cantonal_responsibility
      strategic_responsibility
      operational_responsibility
    }
  }
`

export const insertOrganizationKapProgramQuery = gql`
  mutation insertOrganizationKapProgram($organization: organization_insert_input!) {
    insert_organization_one(object: $organization) {
      id
    }
  }
`

export const deleteKapProgramOrganizationQuery = gql`
  mutation deleteKapProgramOrganization($organization_id: Int!) {
    delete_organization_by_pk(id: $organization_id) {
      id
    }
  }
`
