import { Accordion, AccordionDetails, AccordionSummary, Button, Divider, Typography } from '@mui/material'
import { Fragment, ReactElement } from 'react'
import { useMessageSource } from 'src/i18n/useMessageSource'
import { useHelpAndInstructionLinks } from 'src/service/help-and-instruction-links/useHelpAndInstructionLinks'
import { ExpandMoreIcon, ExternalIcon, InfoIcon } from 'src/shared/icons/Icons'
import { HtmlRenderer } from 'src/shared/presentation/HtmlRenderer'
import { HtmlSanitizer } from 'src/shared/utils/HtmlSanitizer'
import styled from 'styled-components/macro'

const AccordionStyled = styled(Accordion)`
  background-color: inherit;
  box-shadow: ${({ theme }) => theme.shadows[0]};
  margin-bottom: ${({ theme }) => theme.spacing(1)};
  &:before {
    display: none;
  }
  &.Mui-expanded {
    margin: 0;
  }
  & .MuiAccordionSummary-root {
    padding: 0;
    display: flex;
    justify-content: flex-start;
    & > .MuiAccordionSummary-content {
      flex-grow: 0;
      margin: 0;
    }
    &.Mui-expanded {
      min-height: 0;
    }
    & > .MuiAccordionSummary-content.Mui-expanded {
      margin: 0;
    }
    & .MuiTypography-caption {
      display: flex;
      align-items: center;
      font-weight: 500;
      & .MuiSvgIcon-root {
        margin-right: ${({ theme }) => theme.spacing(1)};
        font-size: 1.125rem;
      }
    }
    &:hover .MuiTypography-root,
    &:focus-visible .MuiTypography-root {
      color: ${({ theme }) => theme.colors.primary.dark};
    }
    &:hover .MuiSvgIcon-root,
    &:focus-visible .MuiSvgIcon-root {
      color: ${({ theme }) => theme.colors.primary.dark};
    }
  }
  & .MuiAccordionSummary-expandIcon {
    padding: 0;
    margin: 0 ${({ theme }) => theme.spacing(0.5)};
    & .MuiSvgIcon-root {
      font-size: 1.2rem;
    }
  }
  & .MuiAccordionDetails-root {
    margin-top: ${({ theme }) => theme.spacing(0.5)};
    margin-bottom: ${({ theme }) => theme.spacing(1.5)};
    padding: 0;
  }
  & .MuiButtonBase-root {
    min-height: max-content;
    width: max-content;
  }
`

interface HelpAndInstructionProps {
  labelKey: string
  defaultExpansion?: boolean
  isConfigText?: boolean
}

export const HelpAndInstructions = ({
  labelKey,
  defaultExpansion,
  isConfigText,
}: HelpAndInstructionProps): ReactElement => {
  const { getMessage } = useMessageSource()
  const { getLinks } = useHelpAndInstructionLinks()

  const helpAndInstructionLinks = getLinks(labelKey)
  const hasHelpAndInstructionLinks = !!helpAndInstructionLinks?.length
  const hasAnyLinkLabel = helpAndInstructionLinks.some(({ label }) => label !== null)

  return (
    <AccordionStyled defaultExpanded={hasAnyLinkLabel || defaultExpansion}>
      <AccordionSummary expandIcon={<ExpandMoreIcon color="primary" />}>
        <Typography variant="caption" color="primary">
          <InfoIcon />
          {getMessage('label.help.and.instructions')}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        {isConfigText ? (
          <HtmlRenderer
            html={HtmlSanitizer.resolveConfigTextWithBullets(labelKey)}
            variant="body2"
            color="textSecondary"
            className="html-renderer"
          />
        ) : (
          <Typography variant="body2" color="textSecondary">
            {getMessage(labelKey)}
          </Typography>
        )}
        {hasHelpAndInstructionLinks && (
          <>
            {hasAnyLinkLabel && <Divider sx={{ marginTop: 1, marginBottom: 1 }} />}
            {helpAndInstructionLinks?.map(({ id, label, link }) => (
              <Fragment key={id}>
                {label && link && (
                  <Button
                    color="secondary"
                    endIcon={<ExternalIcon />}
                    onClick={() => {
                      window.open(link, '_blank')
                    }}
                    sx={{ marginRight: 1 }}
                  >
                    {label}
                  </Button>
                )}
              </Fragment>
            ))}
          </>
        )}
      </AccordionDetails>
    </AccordionStyled>
  )
}
