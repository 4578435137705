import { Typography, TypographyProps } from '@mui/material'
import { ReactElement } from 'react'
import { useMessageSource } from 'src/i18n/useMessageSource'
import styled from 'styled-components/macro'

interface Props {
  type?: 'TEXT_FIELD' | 'SELECTION'
  $standAlone?: boolean
  noEntriesMessageKey?: string
  className?: string
}

const TypographyStyled = styled(Typography)<{ $standAlone?: boolean } & TypographyProps<'p', { component: 'p' }>>`
  font-style: italic;
  color: ${({ theme }) => theme.colors.text.disabled};
  margin-top: ${({ $standAlone, theme }) => ($standAlone ? 0 : theme.spacing(0.5))};
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  &::after {
    content: '';
    padding: 1px;
  }
`

export const DefaultSectionTypography = ({
  $standAlone,
  className,
  type = 'TEXT_FIELD',
  noEntriesMessageKey = type === 'TEXT_FIELD' ? 'label.section.not.available' : 'label.field.not.available',
}: Props): ReactElement => {
  const { getMessage } = useMessageSource()

  return (
    <TypographyStyled variant="subtitle2" $standAlone={$standAlone} className={className} component="p">
      {getMessage(noEntriesMessageKey)}
    </TypographyStyled>
  )
}
