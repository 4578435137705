import { ReactElement, useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { Milestone, Query_Root } from 'src/@types/graphql'
import { ROUTES } from 'src/routing/routes'
import { MilestoneSummaryCommentEditForm } from 'src/screens/shared/implementation/details/milestone/details/summary/edit/MilestoneSummaryCommentEditForm'
import { milestoneSummaryDataQuery } from 'src/screens/shared/implementation/details/milestoneQueries'
import { usePermissionsForKapMilestones } from 'src/service/security/PermissionHook'
import { MILESTONE_RESPONSIBLE_TYPE_TYPE, MILESTONE_STATUS_TYPE } from 'src/shared/constants/milestone-constants'
import { NotAuthorized } from 'src/shared/not-authorized/NotAuthorized'
import { useDelayedNavigate } from 'src/shared/utils/hooks/navigation-hooks'
import { resolveProcessToLowerCase, Utils } from 'src/shared/utils/Utils'
import { useClient } from 'urql'

export const KapMilestoneSummaryEditPage = (): ReactElement => {
  const { programId, milestoneId, milestonePath } = useParams()
  const navigate = useDelayedNavigate()
  const urqlClient = useClient()

  const process = resolveProcessToLowerCase('/kap')
  const milestoneType = Utils.resolveMilestoneType(milestonePath)

  const [milestoneSummaryData, setMilestoneSummaryData] = useState<Milestone>()

  useEffect(() => {
    const initData = async () => {
      const { data } = await urqlClient
        .query<{ milestone_by_pk: Query_Root['milestone_by_pk'] }>(milestoneSummaryDataQuery, { milestoneId })
        .toPromise()

      if (data?.milestone_by_pk) {
        setMilestoneSummaryData(data.milestone_by_pk)
      }
    }
    initData()
  }, [milestoneId, urqlClient])

  const { loading, canEdit } = usePermissionsForKapMilestones(
    parseInt(programId as string),
    milestoneSummaryData?.status as MILESTONE_STATUS_TYPE,
    milestoneSummaryData?.responsible_type as MILESTONE_RESPONSIBLE_TYPE_TYPE,
  )

  const onBack = () => {
    const route = ROUTES.KapDefaultMilestoneRoot.nested.Summary.params({ programId, milestoneId, milestonePath })
    navigate(route)
  }

  if (milestoneSummaryData && !loading && !canEdit) {
    return <NotAuthorized />
  }

  return (
    <>
      <MilestoneSummaryCommentEditForm
        loading={loading}
        milestoneSummaryData={milestoneSummaryData}
        setMilestoneSummaryData={setMilestoneSummaryData}
        milestoneId={parseInt(milestoneId as string)}
        milestoneType={milestoneType}
        process={process}
        onBack={onBack}
      />
    </>
  )
}
