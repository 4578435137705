import { format, isAfter, isBefore, isEqual, isValid, parse } from 'date-fns'

const DATE_FORMAT = 'dd.MM.yyyy'
const DATE_TIME_FORMAT = 'dd.MM.yyyy HH:mm'
const GRAPHQL_DATE_FORMAT = 'yyyy-MM-dd'

const MIN_DATE = new Date('2000-01-01T00:00:00.000')
const MAX_DATE = new Date('2100-12-31T00:00:00.000')

const YEAR_FORMAT = 'yyyy'

const parseDate = (dateString: string): Date => parse(dateString, DATE_FORMAT, 0)
const parseDateTime = (dateTimeString: string): Date => parse(dateTimeString, DATE_TIME_FORMAT, 0)
const parseDateGraphQL = (dateString: string): Date => parse(dateString, GRAPHQL_DATE_FORMAT, 0)

const formatDate = (date: Date, dateFormat?: string): string => format(date, dateFormat ? dateFormat : DATE_FORMAT)
const formatDateTime = (dateTime: Date): string => format(dateTime, DATE_TIME_FORMAT)

const serializeGraphQLDate = (date: Date): string => format(date, GRAPHQL_DATE_FORMAT)

// to be used only in URL navigation because it's friendly
const formatISODate = (date: Date): string => format(date, 'yyyy-MM-dd')

const after = (date: Date, dateToCompare: Date): boolean => isAfter(date, dateToCompare)
const before = (date: Date, dateToCompare: Date): boolean => isBefore(date, dateToCompare)
const equals = (date: Date, dateToCompare: Date): boolean =>
  isEqual(new Date(date.toDateString()), new Date(dateToCompare.toDateString()))

const valid = (date: Date): boolean => isValid(date)
const parseAndFormatDate = (dateString: string): string => {
  const parsedDate = parseDateGraphQL(dateString)
  return formatDate(parsedDate)
}
const parseAndSerializeDate = (dateString: string): string => {
  const parsedDate = parseDate(dateString)
  return serializeGraphQLDate(parsedDate)
}

export const DateUtils = {
  parseDate,
  parseDateTime,
  parseDateGraphQL,
  formatDate,
  formatDateTime,
  serializeGraphQLDate,
  formatISODate,
  after,
  before,
  equals,
  valid,
  parseAndFormatDate,
  parseAndSerializeDate,
  DATE_FORMAT,
  MIN_DATE,
  MAX_DATE,
  YEAR_FORMAT,
}
