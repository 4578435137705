import { useParams } from 'react-router'
import { useMessageSource } from 'src/i18n/useMessageSource'
import { ProjectJournalForm } from 'src/screens/shared/project/journal/edit/ProjectJournalForm'
import { usePermissionsForProject } from 'src/service/security/PermissionHook'
import { CreateButton } from 'src/shared/button/Buttons'
import { PROJECT_TYPE } from 'src/shared/constants/constants'
import { PageLayout } from 'src/shared/layout/PageLayout'
import { ScreenLayout } from 'src/shared/layout/ScreenLayout'
import { NotAuthorized } from 'src/shared/not-authorized/NotAuthorized'
import { HelpAndInstructions } from 'src/shared/presentation/HelpAndInstructions'
import { Section } from 'src/shared/presentation/Section'

interface Props {
  projectType: PROJECT_TYPE
  onBack: (journalId?: number) => void
}

export const ProjectJournalCreate = ({ projectType, onBack }: Props) => {
  const { projectId } = useParams()
  const { getMessage } = useMessageSource()

  const onSubmit = () => {
    // trigger submit event
    document
      .getElementById('project-journal-form')
      ?.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }))
  }

  const { canEdit, loading } = usePermissionsForProject(projectType, parseInt(projectId as string))

  if (!loading && !canEdit) {
    return <NotAuthorized inEditPage />
  }

  return (
    <ScreenLayout
      title={getMessage('label.add.project.journal')}
      actions={<CreateButton origin="header" onClick={onSubmit} />}
      onBack={() => onBack()}
      hasSecondLevelNavigation={false}
    >
      <PageLayout>
        <Section helpAndInstructions={<HelpAndInstructions labelKey="label.help.project.journal" defaultExpansion />}>
          <ProjectJournalForm projectType={projectType} afterSubmit={onBack} />
        </Section>
      </PageLayout>
    </ScreenLayout>
  )
}
