import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import { ReactElement, useEffect, useState } from 'react'
import { Project_Base, Query_Root } from 'src/@types/graphql'
import { useMessageSource } from 'src/i18n/useMessageSource'
import { resolveDetailsRoot } from 'src/routing/routing-utils'
import {
  queryProjectBaseByPfKapProjectId,
  queryProjectBaseByPfPgvProjectId,
} from 'src/screens/shared/project/details/starting-point-and-vision/startingPointAndVisionQueries'
import { usePermissionsForProject } from 'src/service/security/PermissionHook'
import { EditButton } from 'src/shared/button/Buttons'
import { ENTITY_TYPE, FILE_TYPE, PROJECT } from 'src/shared/constants/constants'
import { PageLayout } from 'src/shared/layout/PageLayout'
import { ScreenLayout } from 'src/shared/layout/ScreenLayout'
import { ProjectExportMenu } from 'src/shared/menu/ProjectExportMenu'
import { FileSection } from 'src/shared/presentation/FileSection'
import { HelpAndInstructions } from 'src/shared/presentation/HelpAndInstructions'
import { HtmlRenderer } from 'src/shared/presentation/HtmlRenderer'
import { Section } from 'src/shared/presentation/Section'
import { useDelayedNavigate } from 'src/shared/utils/hooks/navigation-hooks'
import { useNotificationService } from 'src/shared/utils/NotificationService'
import { Utils } from 'src/shared/utils/Utils'
import { useClient } from 'urql'

interface StartingPointAndVisionProps {
  projectId: number
  baseUrl: '/pf-kap' | '/pf-pgv'
}

export const StartingPointAndVision = ({ projectId, baseUrl }: StartingPointAndVisionProps): ReactElement => {
  const { getMessage } = useMessageSource()
  const urqlClient = useClient()
  const [projectBase, setProjectBase] = useState<Project_Base | undefined>(undefined)
  const navigate = useDelayedNavigate()
  const notificationService = useNotificationService()
  const process = Utils.resolveProcessToLowerCase(baseUrl)
  const projectType = Utils.resolveProcess(baseUrl)
  const {
    loading,
    canEdit,
    metadata: { projectBaseId },
  } = usePermissionsForProject(projectType, projectId)

  useEffect(() => {
    const initData = async () => {
      if (baseUrl === '/pf-kap') {
        const { data } = await urqlClient
          .query<
            {
              pf_kap_project_by_pk: Query_Root['pf_kap_project_by_pk']
            },
            {
              id: number
            }
          >(queryProjectBaseByPfKapProjectId, { id: projectId })
          .toPromise()

        if (data) {
          const pf_kap_project_base_data = data?.pf_kap_project_by_pk?.project_base
          setProjectBase(pf_kap_project_base_data)
        } else {
          notificationService.operationFailed()
        }
      } else if (baseUrl === '/pf-pgv') {
        const { data } = await urqlClient
          .query<
            {
              pf_pgv_project_by_pk: Query_Root['pf_pgv_project_by_pk']
            },
            {
              id: number
            }
          >(queryProjectBaseByPfPgvProjectId, { id: projectId })
          .toPromise()

        if (data) {
          const pf_pgv_project_base_data = data?.pf_pgv_project_by_pk?.project_base
          setProjectBase(pf_pgv_project_base_data)
        } else {
          notificationService.operationFailed()
        }
      }
    }
    initData()
  }, [baseUrl, projectId, urqlClient, notificationService])

  const handleEditInitialSituation = () => {
    navigate(resolveDetailsRoot(baseUrl).nested.StartingPointAndVisionInitialSituationEdit.params({ projectId }))
  }

  const handleEditProjectJustification = () => {
    navigate(resolveDetailsRoot(baseUrl).nested.StartingPointAndVisionProjectJustificationEdit.params({ projectId }))
  }

  const handleEditSources = () => {
    navigate(resolveDetailsRoot(baseUrl).nested.StartingPointAndVisionSourcesEdit.params({ projectId }))
  }

  const handleEditVision = () => {
    navigate(resolveDetailsRoot(baseUrl).nested.StartingPointAndVisionVisionEdit.params({ projectId }))
  }

  return (
    <ScreenLayout
      title={getMessage('label.navigation.project.details')}
      actions={<ProjectExportMenu process={projectType} entityId={projectBaseId as number} />}
    >
      <PageLayout>
        <>
          {!loading && projectBase && (
            <>
              <Section
                id="initial-situation"
                title={getMessage('label.initial.situation')}
                actionButton={<EditButton onClick={handleEditInitialSituation} hidden={!canEdit} />}
                helpAndInstructions={
                  <HelpAndInstructions labelKey={`label.help.and.instructions.initial.situation.${process}`} />
                }
              >
                {projectBase.initial_situation && (
                  <Card>
                    <CardContent>
                      <HtmlRenderer html={projectBase.initial_situation} />
                    </CardContent>
                  </Card>
                )}
              </Section>

              <Section
                id="project-justification"
                title={getMessage('label.project.justification')}
                actionButton={<EditButton onClick={handleEditProjectJustification} hidden={!canEdit} />}
                helpAndInstructions={
                  <HelpAndInstructions labelKey={`label.help.and.instructions.project.justification.${process}`} />
                }
              >
                {projectBase.project_justification && (
                  <Card>
                    <CardContent>
                      <HtmlRenderer html={projectBase.project_justification} />
                    </CardContent>
                  </Card>
                )}
              </Section>

              {projectType === PROJECT.PF_PGV && (
                <FileSection
                  id="cantonal-statement"
                  title={getMessage('label.cantonal.statement')}
                  fileType={FILE_TYPE.PROJECT_CANTONAL_STATEMENT}
                  entityType={ENTITY_TYPE.PROJECT_BASE}
                  entityId={projectBaseId as number}
                  canEdit={canEdit}
                  helpAndInstructions={<HelpAndInstructions labelKey={`label.help.project.cantonal.statement`} />}
                  uploadType="SINGLE"
                />
              )}

              <Section
                id="sources"
                title={getMessage('label.sources')}
                actionButton={<EditButton onClick={handleEditSources} hidden={!canEdit} />}
                helpAndInstructions={
                  <HelpAndInstructions labelKey={`label.help.and.instructions.sources.${process}`} />
                }
                optional
              >
                {projectBase.sources && (
                  <Card>
                    <CardContent>
                      <HtmlRenderer html={projectBase.sources} />
                    </CardContent>
                  </Card>
                )}
              </Section>

              <Section
                id="vision"
                title={getMessage('label.vision')}
                actionButton={<EditButton onClick={handleEditVision} hidden={!canEdit} />}
                helpAndInstructions={<HelpAndInstructions labelKey={`label.help.and.instructions.vision.${process}`} />}
              >
                {projectBase.vision && (
                  <Card>
                    <CardContent>
                      <HtmlRenderer html={projectBase.vision} />
                    </CardContent>
                  </Card>
                )}
              </Section>
            </>
          )}
        </>
      </PageLayout>
    </ScreenLayout>
  )
}
