import { Box } from '@mui/material'
import { grey } from '@mui/material/colors'
import { TYPOGRAPHY_MIXIN } from 'src/shared/constants/styling-constants'
import styled, { css } from 'styled-components/macro'

const chipBorderColor = grey[400]

export const FilterCheckboxStyled = styled(Box)<{ $checked?: boolean }>`
  border: 1px solid ${chipBorderColor};
  height: 2rem;
  display: flex;
  align-items: center;
  border-radius: 1rem;
  transition: ${({ theme }) => theme.transitions.create('all', { duration: theme.transitions.duration.standard })};
  margin-block: ${({ theme }) => theme.spacing(0.5)};

  &:not(:last-child) {
    margin-right: ${({ theme }) => theme.spacing(1)};
  }

  &:hover {
    background-color: ${({ theme }) => theme.colors.action.hover};
  }

  .MuiButtonBase-root {
    display: none;
  }

  .MuiFormControlLabel-root {
    margin-inline: 0;
    padding-left: ${({ theme }) => theme.spacing(0.5)};
    padding-right: ${({ theme }) => theme.spacing(1.5)};
    height: 100%;

    .MuiFormControlLabel-label {
      color: ${({ theme }) => theme.colors.text.secondary};
      margin-left: 0;
      ${TYPOGRAPHY_MIXIN.body2};
    }

    .MuiSvgIcon-root {
      color: ${({ theme }) => theme.colors.grey.inactive};
      margin-right: ${({ theme }) => theme.spacing(0.75)};
    }
  }

  ${({ $checked }) =>
    $checked &&
    css`
      background-color: ${({ theme }) => theme.colors.primary.main};
      border-color: ${({ theme }) => theme.colors.primary.main};

      &:hover {
        background-color: ${({ theme }) => theme.colors.primary.dark};
        border-color: ${({ theme }) => theme.colors.primary.dark};
      }

      .MuiFormControlLabel-root {
        .MuiFormControlLabel-label,
        .MuiSvgIcon-root {
          color: ${({ theme }) => theme.colors.common.white};
        }
      }
    `}
`
