import { ReactElement, useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router'
import { Query_Root } from 'src/@types/graphql'
import { ROUTES } from 'src/routing/routes'
import {
  MilestoneDetailsEditForm,
  MilestoneDetailsFormValues,
  originType,
} from 'src/screens/shared/implementation/details/milestone/details/default-milestone/edit/MilestoneDetailsEditForm'
import { queryMilestoneById } from 'src/screens/shared/implementation/details/milestoneQueries'
import { usePermissionsForKapMilestones } from 'src/service/security/PermissionHook'
import { MILESTONE_RESPONSIBLE_TYPE_TYPE, MILESTONE_STATUS_TYPE } from 'src/shared/constants/milestone-constants'
import { NotAuthorized } from 'src/shared/not-authorized/NotAuthorized'
import { useDelayedNavigate } from 'src/shared/utils/hooks/navigation-hooks'
import { useNotificationService } from 'src/shared/utils/NotificationService'
import { Utils } from 'src/shared/utils/Utils'
import { useClient } from 'urql'

export const KapMilestoneDetailsEditPage = (): ReactElement => {
  const { programId, milestoneId, milestonePath } = useParams()
  const urqlClient = useClient()
  const notificationService = useNotificationService()
  const navigate = useDelayedNavigate()
  const originRef = useRef<originType>('SAVE')

  const parsedProgramId = parseInt(programId as string)
  const parsedMilestoneId = parseInt(milestoneId as string)
  const milestoneType = Utils.resolveMilestoneType(milestonePath)
  const process = Utils.resolveProcess('/kap')
  const { requiredNotes } = Utils.resolveDefaultMilestoneValidationFields(milestoneType, process)

  const [initialValues, setInitialValues] = useState<MilestoneDetailsFormValues>()

  useEffect(() => {
    const initData = async () => {
      const { data } = await urqlClient
        .query<{ milestone_by_pk: Query_Root['milestone_by_pk'] }, { id: number }>(queryMilestoneById, {
          id: parsedMilestoneId,
        })
        .toPromise()

      if (data && data?.milestone_by_pk) {
        const initialValue = {
          notes: data?.milestone_by_pk?.notes ?? '',
          type: data?.milestone_by_pk?.type ?? '',
          status: data?.milestone_by_pk?.status ?? '',
          responsibleType: data?.milestone_by_pk?.responsible_type ?? '',
        }
        setInitialValues(initialValue)
      } else {
        notificationService.operationFailed()
      }
    }

    initData()
  }, [parsedMilestoneId, urqlClient, notificationService])

  const { loading, canEdit } = usePermissionsForKapMilestones(
    parsedProgramId,
    initialValues?.status as MILESTONE_STATUS_TYPE,
    initialValues?.responsibleType as MILESTONE_RESPONSIBLE_TYPE_TYPE,
  )

  const onBack = () => {
    const route = ROUTES.KapDefaultMilestoneRoot.nested.Details.params({ programId, milestoneId, milestonePath })
    navigate(route)
  }

  if (initialValues && !loading && !canEdit) {
    return <NotAuthorized inEditPage />
  }

  return (
    <>
      <MilestoneDetailsEditForm
        loading={loading}
        process={process}
        milestoneId={parseInt(milestoneId as string)}
        milestoneType={milestoneType}
        requiredNotes={requiredNotes}
        initialValues={initialValues}
        setInitialValues={setInitialValues}
        onBack={onBack}
        originRef={originRef}
      />
    </>
  )
}
