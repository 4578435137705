import { GridFilterItem, GridSortItem } from '@mui/x-data-grid-pro'
import { Kap_Program_Bool_Exp, Kap_Program_Order_By, Query_Root, User } from 'src/@types/graphql'
import { Client, gql } from 'urql'

const fetchProgramsGrid = gql`
  query ($kapCondition: kap_program_bool_exp, $offset: Int!, $limit: Int!, $orderBy: [kap_program_order_by!]) {
    kap_program(where: $kapCondition, offset: $offset, limit: $limit, order_by: $orderBy) {
      id
      modules
      canton_code
      dossier {
        short_title
        status
        start_date
        end_date
      }
    }
    kap_program_aggregate(where: $kapCondition, order_by: $orderBy) {
      aggregate {
        count
      }
    }
  }
`

export interface KapFetchRowsPromise {
  rowsCount: number
  rows: Array<{
    id: number
    short_title: string
    canton_code: string
    status: string
    start_date: string
    end_date: string
    modules?: string[]
  }>
}

export const fetchKapRows =
  (urqlClient: Client) =>
  async (
    gfchCantonResponsibles: User[],
    rowsPerPage: number,
    filterItems?: GridFilterItem[],
    currentPage = 0,
    sort?: GridSortItem,
  ): Promise<KapFetchRowsPromise> => {
    const kapCondition = { _and: [], dossier: { _and: [] } } as Kap_Program_Bool_Exp
    sort = sort || { field: 'id', sort: 'desc' }
    if (filterItems) {
      const groupedMap = filterItems?.reduce(
        (entryMap, e) => entryMap.set(e.field, [...(entryMap.get(e.field) || []), e]),
        new Map(),
      )
      groupedMap.forEach((columnFilters: GridFilterItem[], column: string) => {
        if (column === 'id') {
          const ids = columnFilters.filter((f) => f.value && parseInt(f.value)).map((f) => f.value)
          if (ids?.length) {
            kapCondition._and?.push({ id: { _in: ids } })
          }
        } else if (column === 'short_title') {
          const shortTitles = columnFilters
            .filter((f) => f.value)
            .map((f) => {
              return {
                short_title: { _ilike: `%${f.value}%` },
              }
            })
          if (shortTitles?.length) {
            kapCondition.dossier?._and?.push({ _or: shortTitles })
          }
        } else if (column === 'status') {
          const statuses = columnFilters.filter((f) => f.value).map((f) => f.value)
          if (statuses?.length) {
            kapCondition.dossier?._and?.push({ status: { _in: statuses } })
          }
        } else if (column === 'canton_code') {
          const cantons = columnFilters.filter((f) => f.value).map((f) => f.value)
          if (cantons?.length) {
            kapCondition._and?.push({ canton_code: { _in: cantons } })
          }
        } else if (column === 'start_date') {
          const dates = columnFilters.map((f) => {
            if (f.value === null) {
              return {
                start_date: { _is_null: true },
              }
            } else {
              return {
                start_date: { _eq: f.value },
              }
            }
          })
          if (dates?.length) {
            kapCondition.dossier?._and?.push({ _or: dates })
          }
        } else if (column === 'end_date') {
          const dates = columnFilters.map((f) => {
            if (f.value === null) {
              return {
                end_date: { _is_null: true },
              }
            } else {
              return {
                end_date: { _eq: f.value },
              }
            }
          })
          if (dates?.length) {
            kapCondition.dossier?._and?.push({ _or: dates })
          }
        } else if (column === 'modules') {
          const modules = columnFilters
            .filter((f) => f.value)
            .map((f) => {
              return {
                modules: {
                  _contains: f.value,
                },
              }
            })
          if (modules?.length) {
            kapCondition._and?.push({ _or: modules })
          }
        } else if (column === 'gfch_responsible') {
          const responsibleCantons = columnFilters
            .filter((f) => f.value)
            .map((f) => {
              const foundUser = gfchCantonResponsibles.find((user) => user.id === f.value)
              return foundUser?.gfch_cantonal_responsibility
            })
            .map((f) => {
              return {
                canton_code: { _in: f },
              }
            })
          if (responsibleCantons?.length) {
            kapCondition._and?.push({ _or: responsibleCantons })
          }
        }
      })
    }

    const offset = currentPage * rowsPerPage

    let orderBy = [
      {
        id: 'asc',
      },
    ] as Array<Kap_Program_Order_By>

    if (sort) {
      if (['id'].includes(sort.field)) {
        // @ts-ignore
        orderBy = [{ id: sort.sort }]
      } else if (['modules', 'canton_code'].includes(sort.field)) {
        orderBy = [
          {
            [sort.field]: sort.sort,
          },
          // @ts-ignore
          { id: 'asc' },
        ]
      } else {
        orderBy = [
          {
            dossier: {
              [sort.field]: sort.sort,
            },
          },
          // @ts-ignore
          { id: 'asc' },
        ]
      }
    }

    const limit = rowsPerPage
    const { data, error } = await urqlClient
      .query<
        {
          kap_program: Query_Root['kap_program']
          kap_program_aggregate: Query_Root['kap_program_aggregate']
        },
        {
          kapCondition: Kap_Program_Bool_Exp | unknown
          offset: number
          limit: number
          orderBy: Kap_Program_Order_By | Kap_Program_Order_By[]
        }
      >(fetchProgramsGrid, {
        kapCondition: kapCondition,
        offset,
        limit,
        orderBy: orderBy,
      })
      .toPromise()

    if (error) {
      return Promise.reject(error)
    } else {
      const fetchedRows = data!.kap_program.map((program) => ({
        id: program.id,
        short_title: program.dossier.short_title,
        canton_code: program.canton_code,
        status: program.dossier.status,
        modules: program.modules,
        start_date: program.dossier.start_date,
        end_date: program.dossier.end_date,
      }))
      const rowsCount = data!.kap_program_aggregate.aggregate?.count ?? 0
      return {
        rowsCount: rowsCount,
        rows: fetchedRows,
      }
    }
  }
