import { gql } from 'urql'

export const queryProjectGoalsAndMeasuresByProcessAndProjectId = gql`
  query ($projectBaseWhere: project_base_bool_exp!) {
    project_base(where: $projectBaseWhere) {
      project_goals(order_by: { sort_number: asc }) {
        id
        name
        description
        version
        sort_number
        project_goal_measures {
          id
          project_measure {
            sort_number
            name
          }
        }
        project_goal_annual_reports {
          id
        }
      }
      project_measures(order_by: { sort_number: asc }) {
        id
        name
        description
        version
        sort_number
        start_date
        end_date
        project_goal_measures {
          id
          project_goal {
            sort_number
            name
          }
        }
        project_measure_annual_reports {
          id
        }
      }
    }
  }
`
