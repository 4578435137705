import { Box, Stack } from '@mui/material'
import { ReactElement, useMemo } from 'react'
import { Form } from 'react-final-form'
import { useMessageSource } from 'src/i18n/useMessageSource'
import { RECOMMENDATION_OR_DECISION } from 'src/shared/constants/assessment-constants'
import { TEXT_LENGTH } from 'src/shared/constants/constants'
import { AutoCompleteField } from 'src/shared/form/control/AutoCompleteField'
import { HtmlEditorField } from 'src/shared/form/control/HtmlEditorField'
import { DirtyFormSpy } from 'src/shared/form/dirty/DirtyFormSpy'
import { createDecorators } from 'src/shared/form/utils/decorators'
import { maxChar } from 'src/shared/form/validation/validators'

interface Props {
  initialValues: AssessmentRecommendationFormValues
  onSave: (values: AssessmentRecommendationFormValues) => void
}

export interface AssessmentRecommendationFormValues {
  recommendation: string | null
  pros: string | null
  cons: string | null
  comment: string | null
  version: number
}

export const ApplicationAssessmentRecommendationEditForm = ({ initialValues, onSave }: Props): ReactElement => {
  const { getMessage } = useMessageSource()
  const decorator = createDecorators()
  const recommendationOptions = useMemo(
    () => [
      { label: getMessage('label.recommendation.ACCEPT'), value: RECOMMENDATION_OR_DECISION.ACCEPT },
      { label: getMessage('label.recommendation.REJECT'), value: RECOMMENDATION_OR_DECISION.REJECT },
      { label: getMessage('label.recommendation.EXCLUSION'), value: RECOMMENDATION_OR_DECISION.EXCLUSION },
      { label: getMessage('label.field.not.available'), value: null },
    ],
    [getMessage],
  )

  return (
    <Box>
      {initialValues && recommendationOptions && (
        <Form<AssessmentRecommendationFormValues>
          initialValues={initialValues}
          onSubmit={onSave}
          decorators={decorator}
          render={({ handleSubmit }) => {
            return (
              <form id="edit-form" onSubmit={handleSubmit}>
                <Stack spacing={2}>
                  <AutoCompleteField
                    label={getMessage('label.recommendation')}
                    name="recommendation"
                    options={recommendationOptions}
                  />
                  <HtmlEditorField name="pros" validate={maxChar(TEXT_LENGTH.XL)} label={getMessage('label.pros')} />
                  <HtmlEditorField name="cons" validate={maxChar(TEXT_LENGTH.XL)} label={getMessage('label.cons')} />
                  <HtmlEditorField
                    name="comment"
                    validate={maxChar(TEXT_LENGTH.XL)}
                    label={getMessage('label.assessment.recommendation.comments')}
                  />
                </Stack>
                <DirtyFormSpy />
              </form>
            )
          }}
        />
      )}
    </Box>
  )
}
