import { MAX_ALLOWED_INT } from 'src/shared/constants/constants'

const validateValue = (value: number): boolean => {
  // the only way the user can have NaN in the Grid is if the user deleted the whole content inside a cell
  // so albeit a bit counterintuitive but when it is NaN -> the value is actually VALID
  return (value <= MAX_ALLOWED_INT && value >= 0) || Number.isNaN(value)
}

const formatFieldValue = (fieldValue: any): number | null => {
  return fieldValue === '-' || fieldValue === null || Number.isNaN(fieldValue) || fieldValue === ''
    ? null
    : parseInt(fieldValue)
}

const formatNaNNumber = (value: number): number => {
  return Number.isNaN(value) ? 0 : value
}

/**
 * Calculates total value of an array by field.
 *
 * @param array array.
 * @param field field to calculate the total within the array.
 * @return {number} the total value
 */
const sumBy = <T>(array: T[], field: keyof T): number => {
  return (
    array?.reduce((prev, curr) => prev + (curr?.[field] ? formatNaNNumber(parseInt(curr?.[field] as string)) : 0), 0) ??
    0
  )
}

export const DataGridUtils = {
  validateValue,
  formatFieldValue,
  formatNaNNumber,
  sumBy,
}
