import { Box, Drawer } from '@mui/material'
import AppBar from '@mui/material/AppBar'
import IconButton from '@mui/material/IconButton'
import { ReactElement, useEffect, useState } from 'react'
import { Outlet, Route, Routes, useLocation } from 'react-router'
import { AdministrationSidebar } from 'src/screens/administration/sidebar/AdministrationSidebar'
import { FactsheetsSidebar } from 'src/screens/factsheets/sidebar/FactsheetsSidebar'
import { KapSidebar } from 'src/screens/kap/sidebar/KapSidebar'
import { PfKapSidebar } from 'src/screens/pf-kap/sidebar/PfKapSidebar'
import { PfPgvSidebar } from 'src/screens/pf-pgv/sidebar/PfPgvSidebar'
import { ReportingSidebar } from 'src/screens/reporting/sidebar/ReportingSidebar'
import { SuccessFactorsSidebar } from 'src/screens/success-factor-tool/sidebar/SuccessFactorsSidebar'
import { BASE_URL, DETAILS_URL } from 'src/shared/constants/constants'
import { ExpandMoreIcon } from 'src/shared/icons/Icons'
import { ApplicationToolbar, APPLICATION_TOOLBAR_HEIGHT } from 'src/shared/layout/ApplicationToolbar'
import { useSidebarState } from 'src/shared/sidebar/SidebarAwareContext'
import styled from 'styled-components/macro'

const drawerWidth = 300

const AppBarStyled = styled(AppBar)<{ $open: boolean }>`
  z-index: ${({ theme }) => theme.zIndex.drawer + 1};
`

const ContentStyled = styled(Box)<{ $open: boolean }>`
  min-height: calc(100vh - ${APPLICATION_TOOLBAR_HEIGHT});
  flex-grow: 1;
  transition: ${({ $open, theme }) =>
    $open
      ? theme.transitions.create('margin', {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.enteringScreen,
        })
      : theme.transitions.create('margin', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        })};

  margin-left: ${({ $open }) => ($open ? 0 : -drawerWidth)}px;
  /* the 'transform' and 'overflow' rules are needed as a hack for Safari
  -> else the ScreenHeader becomes buggy if you open/collapse the Drawer
  and you need to disable/enable any css rule for the ScreenHeader to force Safari
  to repaint and recalculate the ScreenHeader position. This hack ensures same behavior 
  as in Chrome and Firefox. 🤡 */
  // HBA: It seems this bug does is not affected by the overflow property anymore
  //overflow: hidden;
  transform: translate3d(0, 0, 0);
`

const DrawerHeaderOffset = styled(Box)`
  display: flex;
  align-items: center;
  padding: ${({ theme }) => theme.spacing(1) + ' ' + theme.spacing(2)};
  // START
  // copied over muiTheme.mixins.toolbar
  // since it's only needed here it's not added to the styled-components theme
  @media (min-width: 0px) and (orientation: landscape) {
    min-height: 48px;
  }
  @media (min-width: 600px) {
    min-height: 64px;
  }
  min-height: 56px;
  justify-content: flex-end;
  // END
`

const DrawerStyled = styled(Drawer)`
  width: ${drawerWidth}px;
  flex-shrink: 0;
  & .MuiPaper-root {
    width: ${drawerWidth}px;
    overflow: visible;
  }
`

const DrawerHandlerWrapperStyled = styled(Box)<{ $open: boolean }>`
  position: fixed;
  width: ${({ $open }) => ($open ? `${drawerWidth}px` : '3rem')};
  height: 3rem;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: end;
  border-right: 1px solid ${({ theme }) => theme.colors.grey.divider};
  border-top: 1px solid ${({ theme }) => theme.colors.grey.divider};
  border-bottom: 1px solid ${({ theme }) => theme.colors.grey.divider};
  background-color: ${({ theme }) => theme.colors.common.white};
  padding-inline: ${({ theme }) => theme.spacing(0.5)};
  z-index: ${({ theme }) => theme.zIndex.drawer + 1};
  transition: ${({ $open, theme }) =>
    $open
      ? theme.transitions.create('all', {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.enteringScreen,
        })
      : theme.transitions.create('all', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        })};

  .MuiSvgIcon-root {
    transform: ${({ $open }) => ($open ? 'rotate(90deg)' : 'rotate(270deg)')};
  }
`

export const Layout = (): ReactElement => {
  const location = useLocation()
  const activeRoute = location.pathname

  const { sidebarState, toggleSidebar } = useSidebarState()
  const [open, setOpen] = useState(sidebarState.open)
  const [isBaseRoute, setIsBaseRoute] = useState<boolean | null>(null)

  const handleToggleDrawer = () => {
    toggleSidebar(!sidebarState.open)
    setOpen(!sidebarState.open)
  }

  useEffect(() => {
    const isBaseRoute = Object.values(BASE_URL).some((route) => {
      return route === activeRoute
    })
    setIsBaseRoute(isBaseRoute)
  }, [activeRoute])

  return (
    <>
      {isBaseRoute !== null && (
        <Box display="flex">
          <AppBarStyled position="fixed" $open={isBaseRoute ? false : open}>
            <ApplicationToolbar />
          </AppBarStyled>
          <DrawerStyled variant="persistent" anchor="left" open={isBaseRoute ? false : open}>
            <DrawerHeaderOffset />
            <Routes>
              <Route path={DETAILS_URL.KAP} element={<KapSidebar />} />
              <Route path={DETAILS_URL.PF_KAP} element={<PfKapSidebar />} />
              <Route path={DETAILS_URL.PF_PGV} element={<PfPgvSidebar />} />
              <Route path={DETAILS_URL.FACTSHEETS} element={<FactsheetsSidebar />} />
              <Route path={DETAILS_URL.ADMINISTRATION} element={<AdministrationSidebar />} />
              <Route path={DETAILS_URL.REPORTING} element={<ReportingSidebar />} />
              <Route path={DETAILS_URL.SUCCESS_FACTOR_ANALYSIS} element={<SuccessFactorsSidebar />} />
            </Routes>
          </DrawerStyled>
          <Routes>
            {Object.values(DETAILS_URL).map((path, index) => (
              <Route
                path={path}
                key={index}
                element={
                  <DrawerHandlerWrapperStyled $open={isBaseRoute ? false : open}>
                    <IconButton onClick={handleToggleDrawer}>
                      <ExpandMoreIcon />
                    </IconButton>
                  </DrawerHandlerWrapperStyled>
                }
              />
            ))}
          </Routes>
          <ContentStyled $open={isBaseRoute ? false : open}>
            <DrawerHeaderOffset />
            <Outlet />
          </ContentStyled>
        </Box>
      )}
    </>
  )
}
