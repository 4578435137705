import { Box, Chip, Tooltip } from '@mui/material'
import { DataGridPro, GridColDef, GridRowSelectionModel, GridSortModel, useGridApiRef } from '@mui/x-data-grid-pro'
import { ReactElement, useMemo, useState } from 'react'
import { useMessageSource } from 'src/i18n/useMessageSource'
import {
  AssessmentViewModel,
  ProjectViewModel,
} from 'src/screens/administration/round-management/details/assessor-management/AssessorManagementPage'
import { PROJECT } from 'src/shared/constants/constants'
import { useGridTranslateHook } from 'src/shared/utils/hooks/grid-translate-hook'

interface Props {
  projects: ProjectViewModel[]
  onRowSelection: (selection: GridRowSelectionModel) => void
}

export const AssessorManagementGrid = ({ projects, onRowSelection }: Props): ReactElement => {
  const apiRef = useGridApiRef()
  const gridTranslations = useGridTranslateHook()
  const { getMessage } = useMessageSource()

  const [sortModel, setSortModel] = useState<GridSortModel>([
    {
      field: 'pid',
      sort: 'asc',
    },
  ])

  const columns = useMemo(() => {
    const pfKapFields = ['instance_1', 'instance_2']
    const pfPgvFields = ['workgroup', 'expert_assessment']

    const fields = projects?.[0]?.process === PROJECT.PF_KAP ? pfKapFields : pfPgvFields

    const getTooltipValue = (users: AssessmentViewModel[]) =>
      users?.map((value, i) => (
        <span key={i}>
          {value?.user?.firstName ? `${value?.user?.firstName} ${value?.user?.lastName}` : `${value?.user?.email}`}
          <br />
        </span>
      ))

    const assessorsChip = (users: AssessmentViewModel[], value: number) => {
      return (
        <>
          <Tooltip title={users.length > 0 ? getTooltipValue(users) : ''} arrow>
            <Chip
              label={`${value} ${getMessage(`label.assessment.${value === 1 ? 'assessor' : 'assessors'}`)}`}
              variant="outlined"
            />
          </Tooltip>
        </>
      )
    }

    return [
      {
        field: 'pid',
        flex: 0.2,
        headerName: getMessage('label.id'),
        renderCell: ({ row }) =>
          `${
            row.process === PROJECT.PF_KAP
              ? getMessage('label.project.id.character.pf_kap')
              : getMessage('label.project.id.character.pf_pgv')
          }${row.pid}`,
      },
      {
        field: 'project',
        flex: 1,
        headerName: getMessage('label.project'),
      },
      {
        field: fields[0],
        flex: 1,
        headerName: getMessage(`label.assessment.type.${fields[0]}`),
        valueGetter: (params: any) => params?.value?.length ?? 0,
        renderCell: ({ value, row }) => {
          const users = row?.[fields[0]] ?? []
          return assessorsChip(users, value)
        },
      },
      {
        field: fields[1],
        flex: 1,
        headerName: getMessage(`label.assessment.type.${fields[1]}`),
        valueGetter: (params: any) => params?.value?.length ?? 0,
        renderCell: ({ value, row }) => {
          const users = row?.[fields[1]] ?? []
          return assessorsChip(users, value)
        },
      },
    ] as GridColDef[]
  }, [projects, getMessage])

  const rows = useMemo(() => projects, [projects])

  return (
    <>
      <Box>
        <DataGridPro
          rows={rows}
          columns={columns}
          autoHeight
          apiRef={apiRef}
          localeText={gridTranslations}
          disableColumnSelector
          disableColumnReorder
          disableColumnPinning
          disableRowSelectionOnClick
          disableColumnMenu
          disableColumnFilter
          checkboxSelection
          sortModel={sortModel}
          onSortModelChange={(model) => setSortModel(model)}
          onRowSelectionModelChange={(selectionModel) => onRowSelection(selectionModel)}
        />
      </Box>
    </>
  )
}
