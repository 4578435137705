import { Typography, TypographyTypeMap } from '@mui/material'
import { GridToolbarFilterButton } from '@mui/x-data-grid-pro'
import { S } from 'src/shared/styled/S'

export const GridToolbar = ({
  title,
  variant = 'h3',
}: {
  title: string
  variant?: TypographyTypeMap['props']['variant']
}) => {
  return (
    <S.DataGrid.ToolbarContainer>
      <Typography variant={variant}>{title}</Typography>
      {/* ABF: MUI DataGrid Pro update needs these params as required */}
      <GridToolbarFilterButton nonce={undefined} onResize={undefined} onResizeCapture={undefined} />
    </S.DataGrid.ToolbarContainer>
  )
}
