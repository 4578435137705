import { ReactElement } from 'react'
import { useParams } from 'react-router'
import { useMessageSource } from 'src/i18n/useMessageSource'
import { resolveDetailsRoot } from 'src/routing/routing-utils'
import { BaseOrganizationEdit } from 'src/screens/shared/organization/edit/BaseOrganizationEdit'
import { usePermissionsForProject } from 'src/service/security/PermissionHook'
import { NotAuthorized } from 'src/shared/not-authorized/NotAuthorized'
import { useDelayedNavigate } from 'src/shared/utils/hooks/navigation-hooks'
import { Utils } from 'src/shared/utils/Utils'

interface ProjectOrganizationEditPageProps {
  baseUrl: '/pf-kap' | '/pf-pgv'
}

export const ProjectOrganizationEditPage = ({ baseUrl }: ProjectOrganizationEditPageProps): ReactElement => {
  const { projectId, organizationRelationType } = useParams()
  const project_id = parseInt(projectId as string)

  const { getMessage } = useMessageSource()
  const navigate = useDelayedNavigate()
  const process = Utils.resolveProcessToLowerCase(baseUrl)
  const projectType = Utils.resolveProcess(baseUrl)

  const { canEdit, loading } = usePermissionsForProject(projectType, project_id)

  const pageTitle =
    organizationRelationType === 'partner'
      ? getMessage('label.title.edit.partner.organization')
      : getMessage('label.title.edit.responsible.organization')

  const helpAndInstructionsTitle =
    organizationRelationType === 'partner'
      ? `label.help.basic.information.partner.organizations.${process}`
      : `label.help.basic.information.responsible.organization.${process}`

  const onBack = () => {
    navigate(resolveDetailsRoot(baseUrl).nested.BasicInformation.params({ projectId }))
  }

  if (!loading && !canEdit) {
    return <NotAuthorized inEditPage />
  }

  return (
    <BaseOrganizationEdit
      projectType={projectType}
      pageTitle={pageTitle}
      helpAndInstructionsTitle={helpAndInstructionsTitle}
      onBack={onBack}
      loading={loading}
    />
  )
}
