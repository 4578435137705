import combinedQuery from 'graphql-combine-query'
import { ReactElement, useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { Mutation_Root, Query_Root } from 'src/@types/graphql'
import { useMessageSource } from 'src/i18n/useMessageSource'
import { resolveFinalReportMilestoneRoot } from 'src/routing/routing-utils'
import {
  getApplicationTypeIdFromProjects,
  getCriteriaConfigForMilestoneAssessmentQuery,
  updateCriteriaSelectionForMilestoneAssessmentQuery,
  upsertMilestoneAssessmentQuery,
} from 'src/screens/shared/assessment-criteria/assessmentQueries'
import { BaseAssessmentCriteriaEdit } from 'src/screens/shared/assessment-criteria/edit/BaseAssessmentCriteriaEdit'
import {
  createGroupsAndInitialData,
  CriteriaFormValues,
  CriteriaGroups,
  QUALITY_PREFIX,
} from 'src/screens/shared/assessment-criteria/utils/AssessmentUtils'
import { queryMilestoneById } from 'src/screens/shared/implementation/details/milestoneQueries'
import { usePermissionsForFinalMilestone } from 'src/service/security/PermissionHook'
import { ASSESSMENT_TYPE, ASSESSMENT_TYPE_TYPE, CRITERIA_TYPE } from 'src/shared/constants/assessment-constants'
import { PROJECT } from 'src/shared/constants/constants'
import {
  MILESTONE_RESPONSIBLE_TYPE_TYPE,
  MILESTONE_STATUS_TYPE,
  MILESTONE_TYPE,
} from 'src/shared/constants/milestone-constants'
import { NotAuthorized } from 'src/shared/not-authorized/NotAuthorized'
import { useDelayedNavigate } from 'src/shared/utils/hooks/navigation-hooks'
import { useNotificationService } from 'src/shared/utils/NotificationService'
import { Utils } from 'src/shared/utils/Utils'
import { useClient, useQuery } from 'urql'

interface Props {
  baseUrl: '/pf-kap' | '/pf-pgv'
  assessorType: ASSESSMENT_TYPE_TYPE
}

export const MilestoneAssessmentCriteriaEditPage = ({ baseUrl, assessorType }: Props): ReactElement => {
  const { projectId, milestoneId } = useParams()
  const { getMessage } = useMessageSource()
  const project_id = parseInt(projectId as string)
  const milestone_id = parseInt(milestoneId as string)

  const notificationService = useNotificationService()
  const navigate = useDelayedNavigate()
  const urqlClient = useClient()

  const [criteriaGroups, setCriteriaGroups] = useState<CriteriaGroups | undefined>()
  const [initialValues, setInitialValues] = useState<CriteriaFormValues | undefined>()

  const projectType = Utils.resolveProcess(baseUrl)

  const isPfKap = projectType === PROJECT.PF_KAP
  const isPfPgv = projectType === PROJECT.PF_PGV

  const { document } = combinedQuery('fetchMilestoneAndAppCodeId')
    .add<{ milestone_by_pk: Query_Root['milestone_by_pk'] }, { id: number }>(queryMilestoneById, {
      id: milestone_id,
    })
    .add<
      {
        pf_kap_project: Query_Root['pf_kap_project']
        pf_pgv_project: Query_Root['pf_pgv_project']
      },
      { isPfKap: boolean; isPfPgv: boolean; projectId: number }
    >(getApplicationTypeIdFromProjects, { isPfKap: isPfKap, isPfPgv: isPfPgv, projectId: project_id })

  const [{ data, error }] = useQuery<
    {
      milestone_by_pk: Query_Root['milestone_by_pk']
      pf_kap_project: Query_Root['pf_kap_project']
      pf_pgv_project: Query_Root['pf_pgv_project']
    },
    { id: number; isPfKap: boolean; isPfPgv: boolean; projectId: number }
  >({
    query: document,
    variables: { id: milestone_id, isPfKap: isPfKap, isPfPgv: isPfPgv, projectId: project_id },
  })

  const applicationTypeId = isPfKap
    ? data?.pf_kap_project?.[0]?.application_type_id
    : data?.pf_pgv_project?.[0]?.application_type_id

  if (error) {
    notificationService.operationFailed()
  }

  const milestone = data?.milestone_by_pk

  const { loading, canEdit, canEditInternalAssessment } = usePermissionsForFinalMilestone(
    projectType,
    project_id,
    milestone?.status as MILESTONE_STATUS_TYPE,
    milestone?.responsible_type as MILESTONE_RESPONSIBLE_TYPE_TYPE,
  )

  const assessmentLabels = {
    screenLayoutTitle: `label.milestone.assessment.${assessorType.toLowerCase()}.edit`,
    helpText: `label.help.milestone.assessment.${assessorType.toLowerCase()}.${projectType.toLowerCase()}`,
  }

  useEffect(() => {
    const initData = async () => {
      const { data } = await urqlClient
        .query<{
          milestone_assessment_criteria_selection: Query_Root['milestone_assessment_criteria_selection']
          criteria_group_config: Query_Root['criteria_group_config']
        }>(getCriteriaConfigForMilestoneAssessmentQuery, {
          milestoneId: milestoneId,
          process: projectType,
          criteriaType: CRITERIA_TYPE.QUALITY,
          assessorType: assessorType,
          applicationTypeId: applicationTypeId,
        })
        .toPromise()

      const selectedQualities = data?.milestone_assessment_criteria_selection
      const criteriaGroupedConfigs = data?.criteria_group_config

      const { criteriaGroups, initialValues } = createGroupsAndInitialData(
        selectedQualities,
        criteriaGroupedConfigs,
        QUALITY_PREFIX,
        applicationTypeId,
      )

      setCriteriaGroups(criteriaGroups)
      setInitialValues(initialValues)
    }

    initData()
  }, [urqlClient, projectType, milestoneId, assessorType, applicationTypeId])

  const handleSubmitLocal = async (formValues: CriteriaFormValues): Promise<any> => {
    const { data } = await urqlClient
      .mutation<{
        insert_milestone_assessment_one: Mutation_Root['insert_milestone_assessment_one']
      }>(upsertMilestoneAssessmentQuery, {
        milestoneId,
        assessorType,
      })
      .toPromise()

    const milestoneAssessmentId = data?.insert_milestone_assessment_one?.id

    const selections = Object.entries(formValues)
      .filter(([_, value]) => value)
      .map(([key, value]) => ({
        milestone_assessment_id: milestoneAssessmentId,
        criteria_config_id: parseInt(key.split(QUALITY_PREFIX)[1]),
        rating: value,
      }))

    const { error } = await urqlClient
      .mutation<{
        delete_milestone_assessment_criteria_selection: Mutation_Root['delete_milestone_assessment_criteria_selection']
        insert_project_assessment_criteria_selection: Mutation_Root['insert_milestone_assessment_criteria_selection']
      }>(updateCriteriaSelectionForMilestoneAssessmentQuery, {
        milestoneId,
        assessorType,
        selections,
      })
      .toPromise()

    if (error) {
      notificationService.operationFailed()
    } else {
      setInitialValues(formValues)
      notificationService.changesSaved()
    }
  }

  const onBack = () => {
    const parameters = {
      projectId,
      milestoneId,
      milestonePath: Utils.resolveMilestonePath(MILESTONE_TYPE.FINAL_REPORT),
    }

    if (assessorType === ASSESSMENT_TYPE.EXTERNAL) {
      navigate(resolveFinalReportMilestoneRoot(baseUrl).nested.ExternalAssessment.params(parameters))
    } else {
      navigate(resolveFinalReportMilestoneRoot(baseUrl).nested.InternalAssessment.params(parameters))
    }
  }

  if (!loading && (!canEdit || (assessorType === ASSESSMENT_TYPE.INTERNAL && !canEditInternalAssessment))) {
    return <NotAuthorized inEditPage />
  }

  return (
    <BaseAssessmentCriteriaEdit
      screenLayoutText={getMessage(assessmentLabels.screenLayoutTitle)}
      helpText={assessmentLabels.helpText}
      criteriaGroups={criteriaGroups}
      initialValues={initialValues}
      handleSubmitLocal={handleSubmitLocal}
      onBack={onBack}
      loading={false}
    />
  )
}
