import { IconButton } from '@mui/material'
import { AxiosResponse } from 'axios'
import { ReactElement, useEffect, useState } from 'react'
import { useLocation } from 'react-router'
import { Evaluation_Milestone, Mutation_Root, Query_Root } from 'src/@types/graphql'
import { useMessageSource } from 'src/i18n/useMessageSource'
import {
  deleteEvaluationMilestoneByIdMutation,
  fetchEvaluationMilestoneFileUUIDsQuery,
} from 'src/screens/shared/project/details/effectivity-management/effectivityManagementQueries'
import { EvaluationMilestoneCardContent } from 'src/screens/shared/project/details/effectivity-management/evaluation-milestone/evaluation-milestone-card/EvaluationMilestoneCardContent'
import { FileService } from 'src/service/axios/FileService'
import { ENTITY_TYPE, FILE_TYPE } from 'src/shared/constants/constants'
import { DeleteIcon, EditIcon, EvaluationMilestoneIcon, UploadIcon } from 'src/shared/icons/Icons'
import { ConfirmationModalDialog } from 'src/shared/modal-dialog/ConfirmationModalDialog'
import { UploadModalDialog } from 'src/shared/modal-dialog/UploadModalDialog'
import { ExpandableCard } from 'src/shared/presentation/ExpandableCard'
import { S } from 'src/shared/styled/S'
import { DateUtils } from 'src/shared/utils/DateUtils'
import { useHashScroll } from 'src/shared/utils/hooks/navigation-hooks'
import { useNotificationService } from 'src/shared/utils/NotificationService'
import { useClient } from 'urql'

interface Props {
  id: string
  evaluationMilestone: Evaluation_Milestone
  canEdit: boolean
  onEdit: () => void
  refetch: () => void
}

export const ExpandableEvaluationMilestoneCard = ({
  id,
  evaluationMilestone,
  canEdit,
  onEdit,
  refetch,
}: Props): ReactElement => {
  const { state } = useLocation()
  const expandedEvaluationMilestoneId = state?.expandedEvaluationMilestoneId

  const urqlClient = useClient()
  const notificationService = useNotificationService()

  const { getMessage } = useMessageSource()

  const [expanded, setExpanded] = useState(false)
  const [uploadModalOpen, setUploadModelOpen] = useState<boolean>(false)
  const [refetchDocs, setRefetchDocs] = useState<boolean>(false)

  const [deleteConfirmation, setDeleteConfirmation] = useState(false)

  useEffect(() => {
    const shouldExpand = (expandedEvaluationMilestoneId as number) === evaluationMilestone.id
    setExpanded(shouldExpand)
  }, [evaluationMilestone, expandedEvaluationMilestoneId])

  const onFileSave = async () => {
    setExpanded(true)
    setUploadModelOpen(false)
    setRefetchDocs(true)
  }

  const onDeleteEntry = () => {
    setDeleteConfirmation(true)
  }

  const onCancelDeleteEntry = () => {
    setDeleteConfirmation(false)
  }

  const onConfirmDeleteEntry = async () => {
    setDeleteConfirmation(false)
    deleteEvaluationMilestoneFiles().then(() => deleteEvaluationMilestoneEntry())
  }

  const deleteEvaluationMilestoneEntry = async () => {
    const { data } = await urqlClient
      .mutation<{ delete_evaluation_milestone_by_pk: Mutation_Root['delete_evaluation_milestone_by_pk'] }>(
        deleteEvaluationMilestoneByIdMutation,
        {
          id: evaluationMilestone.id,
        },
      )
      .toPromise()

    if (data?.delete_evaluation_milestone_by_pk) {
      refetch()
      notificationService.deleteSuccessful()
    } else {
      notificationService.operationFailed()
    }
  }

  const deleteEvaluationMilestoneFiles = async () => {
    const { data } = await urqlClient
      .query<{ file: Query_Root['file'] }>(fetchEvaluationMilestoneFileUUIDsQuery, {
        evaluationMilestoneId: evaluationMilestone.id,
      })
      .toPromise()

    if (data?.file && data.file.length > 0) {
      const promises: Promise<AxiosResponse>[] = []
      data.file.forEach((file) => promises.push(FileService.remove(file.uuid)))
      await Promise.all(promises)
    }
  }

  useHashScroll(id, true)

  return (
    <>
      <ExpandableCard
        id={id}
        header={
          <S.EvaluationMilestoneCard.Content>
            <EvaluationMilestoneIcon fontSize="small" />
            <S.EvaluationMilestoneCard.ItemContainer>
              <S.EvaluationMilestoneCard.ItemTitle variant="subtitle2">
                {getMessage(`label.evaluation.milestone.type.${evaluationMilestone.title}`)}
              </S.EvaluationMilestoneCard.ItemTitle>
              <S.EvaluationMilestoneCard.ItemDate variant="body2">
                {DateUtils.parseAndFormatDate(evaluationMilestone.date)}
              </S.EvaluationMilestoneCard.ItemDate>
              <S.EvaluationMilestoneCard.ItemStatusChip
                size="small"
                label={getMessage(`label.evaluation.milestone.status.${evaluationMilestone.status}`)}
              />
            </S.EvaluationMilestoneCard.ItemContainer>
          </S.EvaluationMilestoneCard.Content>
        }
        actions={
          canEdit && (
            <>
              <IconButton color="primary" onClick={onEdit}>
                <EditIcon />
              </IconButton>
              <IconButton color="primary" onClick={() => setUploadModelOpen(true)}>
                <UploadIcon />
              </IconButton>
              <IconButton color="primary" onClick={onDeleteEntry}>
                <DeleteIcon />
              </IconButton>
            </>
          )
        }
        content={
          <EvaluationMilestoneCardContent
            evaluationMilestoneId={evaluationMilestone.id}
            canEdit={canEdit}
            refetchDocs={refetchDocs}
            resetRefetchDocs={() => setRefetchDocs(false)}
          />
        }
        localExpansion={expanded}
      />

      <UploadModalDialog
        open={uploadModalOpen}
        onCancel={() => setUploadModelOpen(false)}
        onSave={onFileSave}
        fileType={FILE_TYPE.EVALUATION_MILESTONE_DOCUMENT}
        entityType={ENTITY_TYPE.EVALUATION_MILESTONE}
        entityId={evaluationMilestone.id}
        acceptEmailTypes
      />

      <ConfirmationModalDialog
        open={deleteConfirmation}
        onCancel={onCancelDeleteEntry}
        onConfirm={onConfirmDeleteEntry}
      >
        {getMessage('label.delete.confirm')}
      </ConfirmationModalDialog>
    </>
  )
}
