import { Container } from '@mui/material'
import { ReactElement, ReactNode } from 'react'

interface PageLayoutProps {
  children: NonNullable<ReactNode>
}

export const PageLayout = ({ children }: PageLayoutProps): ReactElement => {
  return (
    <Container maxWidth="lg" sx={{ height: '100%' }}>
      {children}
    </Container>
  )
}
