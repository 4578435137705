import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import { ReactElement, useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { Pf_Kap_Project, Pf_Pgv_Project, Query_Root } from 'src/@types/graphql'
import { useMessageSource } from 'src/i18n/useMessageSource'
import { resolveDetailsRoot } from 'src/routing/routing-utils'
import {
  queryPfKapBackgroundInformationByProjectId,
  queryPfPgvBackgroundInformationByProjectId,
} from 'src/screens/shared/project/details/background-information/backgroundInformationQueries'
import { usePermissionsForProject } from 'src/service/security/PermissionHook'
import { EditButton } from 'src/shared/button/Buttons'
import { ENTITY_TYPE, FILE_TYPE } from 'src/shared/constants/constants'
import { PageLayout } from 'src/shared/layout/PageLayout'
import { ScreenLayout } from 'src/shared/layout/ScreenLayout'
import { ProjectExportMenu } from 'src/shared/menu/ProjectExportMenu'
import { FileSection } from 'src/shared/presentation/FileSection'
import { HelpAndInstructions } from 'src/shared/presentation/HelpAndInstructions'
import { HtmlRenderer } from 'src/shared/presentation/HtmlRenderer'
import { Section } from 'src/shared/presentation/Section'
import { useDelayedNavigate } from 'src/shared/utils/hooks/navigation-hooks'
import { useError } from 'src/shared/utils/hooks/page-loading-error-hook'
import { Utils } from 'src/shared/utils/Utils'
import { useClient } from 'urql'

interface Props {
  baseUrl: '/pf-kap' | '/pf-pgv'
}

type PfKapFields = keyof Pick<
  Pf_Kap_Project,
  'sustainability' | 'participation' | 'equal_opportunity' | 'multiplication_potential' | 'additional_information'
>

type PfPgvFields = keyof Pick<
  Pf_Pgv_Project,
  'sustainability' | 'participation' | 'equal_opportunity' | 'multiplication_potential' | 'additional_information'
>

export const ProjectBackgroundInformation = ({ baseUrl }: Props): ReactElement => {
  const { projectId } = useParams()
  const id = parseInt(projectId as string)
  const { getMessage } = useMessageSource()
  const urqlClient = useClient()
  const [projectBackgroundInfo, setProjectBackgroundInfo] = useState<Pf_Kap_Project | Pf_Pgv_Project | undefined>(
    undefined,
  )
  const navigate = useDelayedNavigate()
  const process = Utils.resolveProcessToLowerCase(baseUrl)
  const projectType = Utils.resolveProcess(baseUrl)

  const {
    loading,
    canEdit,
    metadata: { projectBaseId },
  } = usePermissionsForProject(projectType, id)

  const setError = useError()

  useEffect(() => {
    const initData = async () => {
      switch (baseUrl) {
        case '/pf-kap': {
          const { data } = await urqlClient
            .query<{ pf_kap_project_by_pk: Query_Root['pf_kap_project_by_pk'] }, { id: number }>(
              queryPfKapBackgroundInformationByProjectId,
              { id },
            )
            .toPromise()

          if (data) {
            setProjectBackgroundInfo(data!.pf_kap_project_by_pk as Pf_Kap_Project)
          } else {
            setError()
          }
          break
        }
        case '/pf-pgv': {
          const { data } = await urqlClient
            .query<{ pf_pgv_project_by_pk: Query_Root['pf_pgv_project_by_pk'] }, { id: number }>(
              queryPfPgvBackgroundInformationByProjectId,
              { id },
            )
            .toPromise()

          if (data) {
            setProjectBackgroundInfo(data!.pf_pgv_project_by_pk as Pf_Pgv_Project)
          } else {
            setError()
          }
          break
        }
        default:
          throw Error(`Unsupported base url [${baseUrl}]`)
      }
    }
    initData()
  }, [baseUrl, id, urqlClient, setError])

  const handleEdit = (field: PfKapFields | PfPgvFields) => () => {
    const route = resolveDetailsRoot(baseUrl).nested.BackgroundInformation.params({ projectId })
    navigate(`${route}/${field}/edit`)
  }

  return (
    <ScreenLayout
      title={getMessage('label.navigation.project.details')}
      actions={<ProjectExportMenu process={projectType} entityId={projectBaseId as number} />}
    >
      <PageLayout>
        <>
          {!loading && projectBackgroundInfo && (
            <>
              <Section
                id="sustainability"
                title={getMessage('label.background.information.sustainability')}
                actionButton={<EditButton onClick={handleEdit('sustainability')} hidden={!canEdit} />}
                helpAndInstructions={
                  <HelpAndInstructions labelKey={`label.help.background.information.sustainability.${process}`} />
                }
              >
                {projectBackgroundInfo?.sustainability && (
                  <Card>
                    <CardContent>
                      <HtmlRenderer html={projectBackgroundInfo.sustainability} />
                    </CardContent>
                  </Card>
                )}
              </Section>
              <Section
                id="participation"
                title={getMessage('label.background.information.participation')}
                actionButton={<EditButton onClick={handleEdit('participation')} hidden={!canEdit} />}
                helpAndInstructions={
                  <HelpAndInstructions labelKey={`label.help.background.information.participation.${process}`} />
                }
              >
                {projectBackgroundInfo?.participation && (
                  <Card>
                    <CardContent>
                      <HtmlRenderer html={projectBackgroundInfo.participation} />
                    </CardContent>
                  </Card>
                )}
              </Section>
              <Section
                id="equal-opportunity"
                title={getMessage('label.background.information.equal.opportunity')}
                actionButton={<EditButton onClick={handleEdit('equal_opportunity')} hidden={!canEdit} />}
                helpAndInstructions={
                  <HelpAndInstructions labelKey={`label.help.background.information.equal.opportunity.${process}`} />
                }
              >
                {projectBackgroundInfo?.equal_opportunity && (
                  <Card>
                    <CardContent>
                      <HtmlRenderer html={projectBackgroundInfo.equal_opportunity} />
                    </CardContent>
                  </Card>
                )}
              </Section>
              <Section
                id="multiplication-potential"
                title={getMessage('label.background.information.multiplication.potential')}
                actionButton={<EditButton onClick={handleEdit('multiplication_potential')} hidden={!canEdit} />}
                helpAndInstructions={
                  <HelpAndInstructions
                    labelKey={`label.help.background.information.multiplication.potential.${process}`}
                  />
                }
              >
                {projectBackgroundInfo?.multiplication_potential && (
                  <Card>
                    <CardContent>
                      <HtmlRenderer html={projectBackgroundInfo.multiplication_potential} />
                    </CardContent>
                  </Card>
                )}
              </Section>
              <Section
                id="additional-information"
                title={getMessage('label.background.information.additional.information')}
                actionButton={<EditButton onClick={handleEdit('additional_information')} hidden={!canEdit} />}
                helpAndInstructions={
                  <HelpAndInstructions
                    labelKey={`label.help.background.information.additional.information.${process}`}
                  />
                }
                optional
              >
                {projectBackgroundInfo?.additional_information && (
                  <Card>
                    <CardContent>
                      <HtmlRenderer html={projectBackgroundInfo.additional_information} />
                    </CardContent>
                  </Card>
                )}
              </Section>
              <FileSection
                id="structure-plan"
                title={getMessage('label.background.information.structure.plan')}
                fileType={FILE_TYPE.PROJECT_BACKGROUND_INFORMATION_STRUCTURE_PLAN}
                entityType={ENTITY_TYPE.PROJECT_BASE}
                entityId={projectBackgroundInfo.project_base.id}
                canEdit={canEdit}
                helpAndInstructions={
                  <HelpAndInstructions labelKey={`label.help.background.information.structure.plan.${process}`} />
                }
                uploadType="SINGLE"
              />
              <FileSection
                id="other-documents"
                title={getMessage('label.background.information.other.documents')}
                fileType={FILE_TYPE.PROJECT_BACKGROUND_INFORMATION_OTHER}
                entityType={ENTITY_TYPE.PROJECT_BASE}
                entityId={projectBackgroundInfo.project_base.id}
                canEdit={canEdit}
                helpAndInstructions={
                  <HelpAndInstructions labelKey={`label.help.background.information.other.documents.${process}`} />
                }
                optional
              />
            </>
          )}
        </>
      </PageLayout>
    </ScreenLayout>
  )
}
