import { useParams } from 'react-router'
import { Query_Root } from 'src/@types/graphql'
import { useMessageSource } from 'src/i18n/useMessageSource'
import { resolveAnnualReportMilestoneRoot, resolveImplementationDetailsRoot } from 'src/routing/routing-utils'
import { AnnualFeatureKpiGrid } from 'src/screens/shared/implementation/details/milestone/details/annual/kpi/AnnualFeatureKpiGrid'
import { queryMilestoneById } from 'src/screens/shared/implementation/details/milestoneQueries'
import { usePermissionsForMilestones } from 'src/service/security/PermissionHook'
import { EditButton } from 'src/shared/button/Buttons'
import { MILESTONE_RESPONSIBLE_TYPE_TYPE, MILESTONE_STATUS_TYPE } from 'src/shared/constants/milestone-constants'
import { PageLayout } from 'src/shared/layout/PageLayout'
import { ScreenLayout } from 'src/shared/layout/ScreenLayout'
import { ProjectMilestoneExportMenu } from 'src/shared/menu/ProjectMilestoneExportMenu'
import { NotAuthorized } from 'src/shared/not-authorized/NotAuthorized'
import { HelpAndInstructions } from 'src/shared/presentation/HelpAndInstructions'
import { Section } from 'src/shared/presentation/Section'
import { useDelayedNavigate } from 'src/shared/utils/hooks/navigation-hooks'
import { useNotificationService } from 'src/shared/utils/NotificationService'
import { Utils } from 'src/shared/utils/Utils'
import { useQuery } from 'urql'

interface Props {
  baseUrl: '/pf-kap' | '/pf-pgv'
}

export const MilestoneFeatureKpiPage = ({ baseUrl }: Props) => {
  const { projectId, milestoneId, milestonePath } = useParams()
  const project_id = parseInt(projectId as string)
  const milestone_id = parseInt(milestoneId as string)
  const milestoneType = Utils.resolveMilestoneType(milestonePath)
  const process = Utils.resolveProcess(baseUrl)

  const { getMessage } = useMessageSource()
  const notificationService = useNotificationService()
  const navigate = useDelayedNavigate()

  const [{ data, error }] = useQuery<{ milestone_by_pk: Query_Root['milestone_by_pk'] }, { id: number }>({
    query: queryMilestoneById,
    variables: { id: milestone_id },
  })

  if (error) {
    notificationService.operationFailed()
  }

  const milestone = data?.milestone_by_pk

  const { loading, canEdit, canView } = usePermissionsForMilestones(
    process,
    project_id,
    milestone?.status as MILESTONE_STATUS_TYPE,
    milestone?.responsible_type as MILESTONE_RESPONSIBLE_TYPE_TYPE,
  )

  const onBack = () => {
    const route = resolveImplementationDetailsRoot(baseUrl).params({ projectId })
    navigate(route)
  }

  const onEditHandler = () => {
    const featureKpiEditRoute = resolveAnnualReportMilestoneRoot(baseUrl).nested.MilestoneFeatureKpiEdit
    navigate(featureKpiEditRoute.params({ projectId, milestoneId, milestonePath }))
  }

  if (!loading && !canView) {
    return <NotAuthorized inSecondLevelNavigationTab />
  }

  return (
    <ScreenLayout
      title={`${getMessage(`label.milestone.type.${milestoneType}`)} ${milestone?.year_in_focus ?? ''}`}
      onBack={onBack}
      actions={<ProjectMilestoneExportMenu process={process} year={milestone?.year_in_focus} />}
    >
      <PageLayout>
        <>
          {!loading && (
            <Section
              title={getMessage('label.feature.kpi')}
              actionButton={<EditButton hidden={!canEdit} onClick={onEditHandler} />}
              helpAndInstructions={
                <HelpAndInstructions labelKey={`label.help.milestone.feature.kpi.${process.toLowerCase()}`} />
              }
            >
              <AnnualFeatureKpiGrid
                baseUrl={baseUrl}
                projectId={project_id}
                milestoneId={milestone_id}
                reportEditable={false}
                reportYear={milestone?.year_in_focus}
              />
            </Section>
          )}
        </>
      </PageLayout>
    </ScreenLayout>
  )
}
