import { ReactElement, useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router'
import { Mutation_Root, Query_Root } from 'src/@types/graphql'
import { useMessageSource } from 'src/i18n/useMessageSource'
import { resolveDetailsRoot } from 'src/routing/routing-utils'
import {
  GoalForm,
  GoalFormValues,
  initialValuesFactory,
} from 'src/screens/shared/project/details/goals-and-measures/goal/GoalForm'
import {
  queryProjectGoalById,
  updateGoal,
} from 'src/screens/shared/project/details/goals-and-measures/goal/goalQueries'
import { usePermissionsForProject } from 'src/service/security/PermissionHook'
import { SaveAndBackButton, SaveButton } from 'src/shared/button/Buttons'
import { GOAL_CHAR } from 'src/shared/constants/constants'
import { PageLayout } from 'src/shared/layout/PageLayout'
import { ScreenLayout } from 'src/shared/layout/ScreenLayout'
import { NotAuthorized } from 'src/shared/not-authorized/NotAuthorized'
import { HelpAndInstructions } from 'src/shared/presentation/HelpAndInstructions'
import { useDelayedNavigate } from 'src/shared/utils/hooks/navigation-hooks'
import { useError } from 'src/shared/utils/hooks/page-loading-error-hook'
import { HtmlSanitizer } from 'src/shared/utils/HtmlSanitizer'
import { useNotificationService } from 'src/shared/utils/NotificationService'
import { Utils } from 'src/shared/utils/Utils'
import { useClient } from 'urql'

type originType = 'SAVE' | 'SAVE_AND_BACK'

interface ProjectGoalEditPageProps {
  baseUrl: '/pf-kap' | '/pf-pgv'
}

export const ProjectGoalEditPage = ({ baseUrl }: ProjectGoalEditPageProps): ReactElement => {
  const { projectId, goalId } = useParams()
  const { getMessage } = useMessageSource()
  const [initialValues, setInitialValues] = useState<GoalFormValues | null>(null)
  const originRef = useRef<originType>('SAVE')
  const urqlClient = useClient()
  const notificationService = useNotificationService()
  const pageTitle = getMessage('label.goal.title.edit')
  const navigate = useDelayedNavigate()
  const process = Utils.resolveProcessToLowerCase(baseUrl)
  const projectType = Utils.resolveProcess(baseUrl)
  const { canEdit, loading } = usePermissionsForProject(projectType, parseInt(projectId as string))
  const setError = useError()

  useEffect(() => {
    const initializeFormValues = async () => {
      if (goalId) {
        const { data } = await urqlClient
          .query<{ project_goal_by_pk: Query_Root['project_goal_by_pk'] }, { id: number }>(queryProjectGoalById, {
            id: parseInt(goalId),
          })
          .toPromise()

        if (data && data.project_goal_by_pk) {
          const goal = initialValuesFactory(data.project_goal_by_pk.project_base_id, data.project_goal_by_pk)
          setInitialValues(goal)
        } else {
          setError()
        }
      }
    }

    initializeFormValues()
  }, [goalId, projectId, urqlClient, setError])

  const onSubmit = (origin: originType) => (_: any) => {
    // trigger submit event
    if (originRef) {
      originRef.current = origin
    }
    document.getElementById('goal-form')?.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }))
  }

  const onUpdateHandler = async (values: any) => {
    const measures = values.measureIds?.map((measureId: number) => ({
      project_goal_id: values.goalId,
      project_measure_id: measureId,
    }))

    const updateObject = {
      id: values.goalId,
      name: values.name,
      description: HtmlSanitizer.sanitize(values.description),
      version: values.version,
      measuresToBeAdded: measures,
    }

    const { error, data } = await urqlClient
      .mutation<{
        update_project_goal: Mutation_Root['update_project_goal']
        delete_project_goal_measure: Mutation_Root['delete_project_goal_measure']
        insert_project_goal_measure: Mutation_Root['insert_project_goal_measure']
      }>(updateGoal, updateObject)
      .toPromise()

    if (error || data?.update_project_goal?.affected_rows !== 1) {
      notificationService.operationFailed()
    } else {
      const newVersion = data?.update_project_goal.returning[0]?.version
      const newInitialValues = { ...values, version: newVersion }
      setInitialValues(newInitialValues)
      notificationService.changesSaved()
      if (originRef.current === 'SAVE_AND_BACK') {
        onBack()
      }
    }
  }

  const onBack = () => {
    const route = resolveDetailsRoot(baseUrl).nested.GoalsAndMeasures.params({ projectId })
    navigate(`${route}#${GOAL_CHAR}${goalId}`, { state: { expandedEntityId: `${GOAL_CHAR}${goalId}` } })
  }

  if (!loading && !canEdit) {
    return <NotAuthorized />
  }

  return (
    <ScreenLayout
      hasSecondLevelNavigation={false}
      title={pageTitle}
      onBack={onBack}
      actions={
        <>
          <SaveAndBackButton origin="header" onClick={onSubmit('SAVE_AND_BACK')} />
          <SaveButton origin="header" onClick={onSubmit('SAVE')} />
        </>
      }
    >
      <PageLayout>
        <>
          {!loading && initialValues && (
            <>
              <HelpAndInstructions labelKey={`label.help.goals.and.measures.goals.${process}`} defaultExpansion />
              <GoalForm initialValues={initialValues} onSave={onUpdateHandler} />
            </>
          )}
        </>
      </PageLayout>
    </ScreenLayout>
  )
}
