import { gql } from 'urql'

export const fetchProgramMilestonesQuery = gql`
  query fetchProgramMilestones($programId: Int!) {
    milestone(
      where: { dossier: { kap_program: { id: { _eq: $programId } } } }
      order_by: [{ due_date: asc }, { year_in_focus: asc }, { type: asc }]
    ) {
      id
      type
      due_date
      status
      responsible_type
      year_in_focus
    }
  }
`

export const fetchKapProgramModulesAndFinancialReportQuery = gql`
  query fetchKapProgramModulesAndFinancialReport($programId: Int!, $milestoneId: Int!) {
    kap_program_by_pk(id: $programId) {
      modules
      dossier {
        milestones(where: { id: { _eq: $milestoneId }, type: { _eq: "FINANCIAL_REPORT" } }) {
          kap_program_financial_reports {
            module
            gfch_actual_value
            canton_actual_value
          }
        }
      }
    }
  }
`

export const upsertKapProgramFinancialReportMutation = gql`
  mutation upsertKapProgramFinancialReport(
    $kapProgramFinancialReport: [kap_program_financial_report_insert_input!]!
    $updateColumns: [kap_program_financial_report_update_column!]!
  ) {
    insert_kap_program_financial_report(
      objects: $kapProgramFinancialReport
      on_conflict: { constraint: kap_program_financial_report_milestone_id_module_uq, update_columns: $updateColumns }
    ) {
      affected_rows
    }
  }
`

export const fetchKapProgramFinancialReportsByProgramIdQuery = gql`
  query fetchKapProgramFinancialReportsByProgramId($id: Int!) {
    kap_program_financial_all_reports: kap_program_financial_report(
      where: { milestone: { dossier: { kap_program: { id: { _eq: $id } } } } }
    ) {
      module
      gfch_actual_value
      canton_actual_value
    }
  }
`

export const fetchKapMilestoneReportsByMeasureIdAndMilestoneIdQuery = gql`
  query fetchKapMilestoneReportsByMeasureIdAndMilestoneId($measureId: Int!, $milestoneId: Int!) {
    kap_measure(where: { id: { _eq: $measureId } }) {
      description
    }
    kap_measure_annual_planning(
      where: { _and: [{ kap_measure_id: { _eq: $measureId } }, { milestone_id: { _eq: $milestoneId } }] }
    ) {
      kap_measure_id
      planning_description
    }
  }
`

export const fetchFeatureBaseValuesWithKPIsAndAnnualPlanForKapMeasureQuery = gql`
  query fetchFeatureBaseValuesWithKPIsAndAnnualPlanForKapMeasure(
    $milestoneId: Int!
    $measureId: Int!
    $featureConfigKPIWhere: feature_config_bool_exp
  ) {
    feature_base(where: { kap_measures: { id: { _eq: $measureId } } }) {
      id
      feature_values(
        where: { feature_config: $featureConfigKPIWhere }
        order_by: { feature_config: { sort_number: asc } }
      ) {
        id
        feature_config_id
        other_description
        feature_config {
          names
          tooltips
          sort_number
          feature_type_config {
            sort_number
            names
          }
        }
      }
    }
    kap_measure_kpi_annual_planning(
      where: { _and: [{ milestone_id: { _eq: $milestoneId } }, { kap_measure_id: { _eq: $measureId } }] }
    ) {
      id
      milestone_id
      feature_config_id
      planned_value
    }
    all_kap_measure_kpi_annual_report: kap_measure_kpi_annual_report(where: { kap_measure_id: { _eq: $measureId } }) {
      id
      milestone_id
      feature_config_id
      actual_value
    }
  }
`

export const fetchKapMeasureAnnualPlanningQuery = gql`
  query fetchKapMeasureAnnualPlanning($milestoneId: Int!, $measureId: Int!) {
    kap_measure_annual_planning(
      where: { _and: [{ milestone_id: { _eq: $milestoneId } }, { kap_measure: { id: { _eq: $measureId } } }] }
    ) {
      planning_description
    }
    kap_measure_by_pk(id: $measureId) {
      id
      short_title
      level
      sort_number
    }
  }
`

export const upsertKapMeasureAnnualPlanningWithKpiMutation = gql`
  mutation upsertKapMeasureAnnualPlanningWithKpi(
    $milestoneId: Int!
    $measureId: Int!
    $planningDescription: String
    $valid: Boolean!
    $kpiData: [kap_measure_kpi_annual_planning_insert_input!]!
  ) {
    insert_kap_measure_annual_planning_one(
      object: {
        milestone_id: $milestoneId
        kap_measure_id: $measureId
        planning_description: $planningDescription
        valid: $valid
      }
      on_conflict: {
        constraint: kap_measure_annual_planning_milestone_id_kap_measure_id_uq
        update_columns: [planning_description, valid]
      }
    ) {
      id
    }
    delete_kap_measure_kpi_annual_planning(
      where: { _and: [{ milestone_id: { _eq: $milestoneId } }, { kap_measure_id: { _eq: $measureId } }] }
    ) {
      affected_rows
    }
    insert_kap_measure_kpi_annual_planning(
      objects: $kpiData
      on_conflict: {
        constraint: kap_measure_kpi_annual_planning_milestone_measure_feature_uq
        update_columns: [planned_value]
      }
    ) {
      affected_rows
    }
  }
`

export const fetchKapMilestoneAnnualPlanningValidityQuery = gql`
  query fetchKapMilestoneAnnualPlanningValidity($milestoneId: Int!) {
    kap_measure_annual_planning(where: { milestone_id: { _eq: $milestoneId } }) {
      kap_measure_id
      valid
    }
  }
`

export const fetchKapMilestoneMeasuresByProgramIdQuery = gql`
  query fetchKapMilestoneMeasuresByProgramId($programId: Int!) {
    kap_measure(where: { kap_program_id: { _eq: $programId } }, order_by: { sort_number: asc }) {
      id
      sort_number
      short_title
      level
      modules
    }
  }
`

export const fetchKapMeasureAnnualReportsByMilestoneIdQuery = gql`
  query fetchKapMeasureAnnualReportsByMilestoneI($measureId: Int!, $milestoneId: Int!, $yearInFocus: smallint) {
    kap_measure(where: { id: { _eq: $measureId } }) {
      description
      kap_measure_annual_plannings(where: { milestone: { year_in_focus: { _eq: $yearInFocus } } }) {
        planning_description
      }
      kap_measure_kpi_annual_plannings(where: { milestone: { year_in_focus: { _eq: $yearInFocus } } }) {
        planned_value
      }
      kap_measure_annual_reports(where: { milestone_id: { _eq: $milestoneId } }) {
        rating
        reason
      }
      kap_measure_kpi_annual_reports(where: { milestone_id: { _eq: $milestoneId } }) {
        actual_value
      }
    }
  }
`

export const fetchKapMeasureFeatureValuesAndMilestoneKPIValuesQuery = gql`
  query fetchKapMeasureFeatureValuesAndMilestoneKPIValues(
    $measureId: Int!
    $milestoneId: Int!
    $yearInFocus: smallint
    $featureConfigKPIWhere: feature_config_bool_exp!
  ) {
    feature_base(where: { kap_measures: { id: { _eq: $measureId } } }) {
      id
      feature_values(
        where: { feature_config: $featureConfigKPIWhere }
        order_by: { feature_config: { sort_number: asc } }
      ) {
        id
        feature_config_id
        other_description
        feature_config {
          names
          tooltips
          sort_number
          feature_type_config {
            sort_number
            names
          }
        }
      }
    }
    kap_measure_by_pk(id: $measureId) {
      kap_measure_kpi_annual_plannings(where: { milestone: { year_in_focus: { _eq: $yearInFocus } } }) {
        id
        feature_config_id
        planned_value
      }
      kap_measure_kpi_annual_reports(where: { milestone_id: { _eq: $milestoneId } }) {
        id
        feature_config_id
        actual_value
      }
    }
  }
`

export const upsertKapMeasureAnnualReportMutation = gql`
  mutation upsertKapMeasureAnnualReport(
    $milestoneId: Int!
    $measureId: Int!
    $measureRatingToBeAdded: kap_measure_annual_report_insert_input!
    $kpisToBeAdded: [kap_measure_kpi_annual_report_insert_input!]!
  ) {
    insert_kap_measure_annual_report_one(
      object: $measureRatingToBeAdded
      on_conflict: { constraint: milestone_id_kap_measure_id_uq, update_columns: [rating, reason, valid] }
    ) {
      id
    }
    delete_kap_measure_kpi_annual_report(
      where: { _and: [{ milestone_id: { _eq: $milestoneId } }, { kap_measure_id: { _eq: $measureId } }] }
    ) {
      affected_rows
    }
    insert_kap_measure_kpi_annual_report(
      objects: $kpisToBeAdded
      on_conflict: { constraint: milestone_id_kap_measure_id_feature_config_id_uq, update_columns: [actual_value] }
    ) {
      affected_rows
    }
  }
`

export const fetchKapMeasureAnnualReportQuery = gql`
  query fetchKapMeasureAnnualReport($milestoneId: Int!, $measureId: Int!) {
    kap_measure_annual_report(where: { milestone_id: { _eq: $milestoneId }, kap_measure_id: { _eq: $measureId } }) {
      rating
      reason
    }
    kap_measure_by_pk(id: $measureId) {
      id
      short_title
      level
      sort_number
    }
  }
`

export const fetchKapMilestoneAnnualReportValidityQuery = gql`
  query fetchKapMilestoneAnnualReportValidity($milestoneId: Int!) {
    kap_measure_annual_report(where: { milestone_id: { _eq: $milestoneId } }) {
      kap_measure_id
      valid
    }
  }
`

export const fetchAllKapMeasureAnnualReportsByMeasureIdQuery = gql`
  query fetchAllKapMeasureAnnualReportsByMeasureId($id: Int!) {
    kap_measure_kpi_annual_all_reports: kap_measure_kpi_annual_report(where: { kap_measure_id: { _eq: $id } }) {
      feature_config_id
      actual_value
    }
  }
`

export const fetchKapMeasureAnnualPlanningsValidityByMilestoneIdQuery = gql`
  query fetchKapMeasuresAnnualPlanningValidityByMilestoneId($milestoneId: Int!) {
    kap_measure(where: { kap_program: { dossier: { milestones: { id: { _eq: $milestoneId } } } } }) {
      kap_measure_annual_plannings(where: { milestone_id: { _eq: $milestoneId } }) {
        milestone_id
        valid
      }
    }
  }
`

export const fetchDossierIdByProgramIdQuery = gql`
  query fetchDossierIdByProgramId($programId: Int!) {
    dossier(where: { kap_program: { id: { _eq: $programId } } }) {
      id
    }
  }
`
