import {
  Analysis_Area_Of_Activity,
  Analysis_Focus_Topic,
  Analysis_Success_Factor_Rating,
  Area_Of_Activity,
  Focus_Topic,
  Success_Factor,
} from 'src/@types/graphql'
import {
  AnalysisAreaOfActivity,
  AnalysisFocusTopic,
  AnalysisSuccessFactor,
} from 'src/screens/success-factor-tool/analysis/details/analysis/AnalysisDetailsPage'

export const SUCCESS_FACTOR_STATUS = {
  IMPLEMENTATION: 'IMPLEMENTATION',
  DEVELOPMENT: 'DEVELOPMENT',
  NONE: 'NONE',
} as const

const adaptDataForRendering = (
  areaOfActivities: Area_Of_Activity[],
  analysisAreaOfActivity: Analysis_Area_Of_Activity[],
): AnalysisAreaOfActivity[] => {
  return areaOfActivities.map((areaOfActivity) => {
    const comment = analysisAreaOfActivity.find(
      (analysisAreaOfActivity) => analysisAreaOfActivity.area_of_activity_id === areaOfActivity.id,
    )?.comment
    return {
      id: areaOfActivity.id,
      code: areaOfActivity.code,
      sort_number: areaOfActivity.sort_number,
      name: areaOfActivity.names,
      comment,
    } as unknown as AnalysisAreaOfActivity
  })
}

const mapFocusTopicToAnalysisFocusTopicModel = (
  focusTopics: Focus_Topic[],
  analysisFocusTopics: Analysis_Focus_Topic[],
) => {
  return focusTopics.map((focusTopic) => {
    const comment = analysisFocusTopics.find(
      (analysisFocusTopic) => analysisFocusTopic.focus_topic_id === focusTopic.id,
    )?.comment

    return {
      id: focusTopic.id,
      code: focusTopic.code,
      areaOfActivityId: focusTopic.area_of_activity_id,
      sortNumber: focusTopic.sort_number,
      name: focusTopic.names,
      comment: comment ?? '',
    } as AnalysisFocusTopic
  })
}

const mapSuccessFactorToAnalysisSuccessFactorModel = (
  successFactors: Success_Factor[],
  analysisSuccessFactors: Analysis_Success_Factor_Rating[],
) => {
  return successFactors.map((successFactor) => {
    const analysisSuccessFactor = analysisSuccessFactors.find(
      (analysisSuccessFactor) => analysisSuccessFactor.success_factor_id === successFactor.id,
    )

    return {
      id: successFactor.id,
      code: successFactor.code,
      focusTopicId: successFactor.focus_topic_id,
      sortNumber: successFactor.sort_number,
      name: successFactor.names,
      status: analysisSuccessFactor?.status,
      ratingStatus: analysisSuccessFactor?.rating_current_status,
      ratingPriority: analysisSuccessFactor?.rating_priority,
      comment: analysisSuccessFactor?.comment ?? '',
    } as AnalysisSuccessFactor
  })
}

const resolveAverageRating = (
  analysisSuccessFactors: AnalysisSuccessFactor[],
  field: 'ratingStatus' | 'ratingPriority',
): string | undefined => {
  const filteredArray = analysisSuccessFactors
    .map((item) => item[field])
    .filter((item) => typeof item === 'number') as number[]

  if (!filteredArray?.length) {
    return undefined
  }

  const result = filteredArray.reduce((a, b) => a + b, 0) / filteredArray.length
  const stringResult = result.toFixed(2).toString()

  if (stringResult.endsWith('0')) {
    // Remove the last 0 digit
    return stringResult.slice(0, -1)
  }

  return stringResult
}

export const AnalysisUtils = {
  adaptDataForRendering,
  mapFocusTopicToAnalysisFocusTopicModel,
  mapSuccessFactorToAnalysisSuccessFactorModel,
  resolveAverageRating,
}
