import { ComposePaths, ExtractRouteParams, withParams } from 'src/routing/routing-utils'
import { KapApplicationInformationEditPage } from 'src/screens/kap/application/details/application-information/KapApplicationInformationEditPage'
import { KapApplicationInformationPage } from 'src/screens/kap/application/details/application-information/KapApplicationInformationPage'
import { KapApplicationDetailsIndexPage } from 'src/screens/kap/application/details/KapApplicationDetailsIndexPage'
import { KapApplicationSummaryEditPage } from 'src/screens/kap/application/details/summary/KapApplicationSummaryEditPage'
import { KapApplicationSummaryPage } from 'src/screens/kap/application/details/summary/KapApplicationSummaryPage'
import { KapImplementationDetailsIndexPage } from 'src/screens/kap/implementation/KapImplementationDetailsIndexPage'
import { KapMilestoneAnnualPlanningEditPage } from 'src/screens/kap/implementation/milestone/details/annual-planning/edit/KapMilestoneAnnualPlanningEditPage'
import { KapMilestoneAnnualPlanningPage } from 'src/screens/kap/implementation/milestone/details/annual-planning/KapMilestoneAnnualPlanningPage'
import { KapMilestoneGoalReportEditPage } from 'src/screens/kap/implementation/milestone/details/annual-report/goals/edit/KapMilestoneGoalReportEditPage'
import { KapMilestoneGoalReportPage } from 'src/screens/kap/implementation/milestone/details/annual-report/goals/KapMilestoneGoalReportPage'
import { KapMilestoneMeasureRatingEditPage } from 'src/screens/kap/implementation/milestone/details/annual-report/measures/edit/KapMilestoneMeasureRatingEditPage'
import { KapMilestoneMeasureRatingPage } from 'src/screens/kap/implementation/milestone/details/annual-report/measures/KapMilestoneMeasureRatingPage'
import { KapMilestoneDetailsEditPage } from 'src/screens/kap/implementation/milestone/details/default/edit/KapMilestoneDetailsEditPage'
import { KapMilestoneDetailsPage } from 'src/screens/kap/implementation/milestone/details/default/KapMilestoneDetailsPage'
import { KapMilestoneSubprincipleEditPage } from 'src/screens/kap/implementation/milestone/details/final-report/principles/edit/KapMilestoneSubprincipleEditPage'
import { KapMilestonePrinciplesPage } from 'src/screens/kap/implementation/milestone/details/final-report/principles/KapMilestonePrinciplesPage'
import { KapMilestoneFinancialsEditPage } from 'src/screens/kap/implementation/milestone/details/financial/edit/KapMilestoneFinancialsEditPage'
import { KapMilestoneFinancialsPage } from 'src/screens/kap/implementation/milestone/details/financial/KapMilestoneFinancialsPage'
import { KapMilestoneDetailsIndex } from 'src/screens/kap/implementation/milestone/details/KapMilestoneDetailsIndex'
import { KapMilestoneSummaryEditPage } from 'src/screens/kap/implementation/milestone/details/summary/edit/KapMilestoneSummaryEditPage'
import { KapMilestoneSummaryPage } from 'src/screens/kap/implementation/milestone/details/summary/KapMilestoneSummaryPage'
import { KapImplementationMilestonePage } from 'src/screens/kap/implementation/milestone/KapImplementationMilestonePage'
import { KapImplementationSummaryEditPage } from 'src/screens/kap/implementation/summary/edit/KapImplementationSummaryEditPage'
import { KapImplementationSummaryPage } from 'src/screens/kap/implementation/summary/KapImplementationSummaryPage'
import { KapIndexPage } from 'src/screens/kap/index/KapIndexPage'
import { KapBasicInformationPage } from 'src/screens/kap/program/details/basic-information/KapBasicInformationPage'
import { KapBasicInformationEditPage } from 'src/screens/kap/program/details/basic-information/program-information/KapBasicInformationEditPage'
import { KapFinancialsEditPage } from 'src/screens/kap/program/details/financials/edit/KapFinancialsEditPage'
import { KapFinancialsPage } from 'src/screens/kap/program/details/financials/KapFinancialsPage'
import { KapGoalCreatePage } from 'src/screens/kap/program/details/goals/edit/KapGoalCreatePage'
import { KapGoalEditPage } from 'src/screens/kap/program/details/goals/edit/KapGoalEditPage'
import { KapGoalsPage } from 'src/screens/kap/program/details/goals/KapGoalsPage'
import { KapGoalViewPage } from 'src/screens/kap/program/details/goals/view/KapGoalViewPage'
import { KapProgramDetailsIndexPage } from 'src/screens/kap/program/details/KapProgramDetailsIndexPage'
import { KapMeasuresPage } from 'src/screens/kap/program/details/measures/KapMeasuresPage'
import { KapMeasureCreatePage } from 'src/screens/kap/program/details/measures/measure/add/KapMeasureCreatePage'
import { KapMeasureDescriptionEditPage } from 'src/screens/kap/program/details/measures/measure/description/edit/KapMeasureDescriptionEditPage'
import { KapMeasureDescriptionPage } from 'src/screens/kap/program/details/measures/measure/description/KapMeasureDescriptionPage'
import { KapMeasureFeaturesEditPage } from 'src/screens/kap/program/details/measures/measure/features/edit/KapMeasureFeaturesEditPage'
import { KapMeasureFeaturesPage } from 'src/screens/kap/program/details/measures/measure/features/KapMeasureFeaturesPage'
import { KapMeasureDetailsIndexPage } from 'src/screens/kap/program/details/measures/measure/KapMeasureDetailsIndexPage'
import { KapOrganizationCreatePage } from 'src/screens/kap/program/details/organization/edit/KapOrganizationCreatePage'
import { KapOrganizationEditPage } from 'src/screens/kap/program/details/organization/edit/KapOrganizationEditPage'
import { KapOrganizationPage } from 'src/screens/kap/program/details/organization/KapOrganizationPage'
import { KapOrganizationViewPage } from 'src/screens/kap/program/details/organization/view/KapOrganizationViewPage'
import { KapProgramDetailsSubprincipleEditPage } from 'src/screens/kap/program/details/principles/edit/KapProgramDetailsSubprincipleEditPage'
import { KapProgramDetailsPrinciplesPage } from 'src/screens/kap/program/details/principles/KapProgramDetailsPrinciplesPage'
import { KapStartingPointAndVisionEditPage } from 'src/screens/kap/program/details/starting-point-and-vision/edit/KapStartingPointAndVisionEditPage'
import { KapStartingPointAndVisionPage } from 'src/screens/kap/program/details/starting-point-and-vision/KapStartingPointAndVisionPage'
import { KapSummaryPage } from 'src/screens/kap/program/details/summary/KapSummaryPage'
import { ProgramSummaryCommentEditPage } from 'src/screens/kap/program/details/summary/ProgramSummaryCommentEditPage'

const KapIndex = {
  path: 'kap',
  Component: KapIndexPage,
  roles: [
    'PD-CANTON_KAP_CONTRIBUTOR',
    'PD-CANTON_KAP_READER',
    'PD-GFCH_KAP_COORDINATOR',
    'PD-GFCH_KAP_CONTRIBUTOR',
    'PD-GFCH_KAP_READER',
  ],
} as const

const KapDetailsRoot = {
  path: 'kap/program/:programId/details',
  Component: KapProgramDetailsIndexPage,
  roles: [
    'PD-CANTON_KAP_CONTRIBUTOR',
    'PD-CANTON_KAP_READER',
    'PD-GFCH_KAP_COORDINATOR',
    'PD-GFCH_KAP_CONTRIBUTOR',
    'PD-GFCH_KAP_READER',
  ],
} as const

const KapBasicInformation = {
  path: 'basic-information',
  Component: KapBasicInformationPage,
  navigationKey: 'label.navigation.project.basic.information',
  primary: true,
} as const

const KapBasicInformationEdit = {
  path: 'basic-information/edit',
  Component: KapBasicInformationEditPage,
  hideNavigation: true,
} as const

const KapOrganization = {
  path: 'organization',
  Component: KapOrganizationPage,
  navigationKey: 'label.navigation.program.organization',
} as const

const KapContactCanton = {
  path: 'organization/contact/:organizationId',
  Component: () => <KapOrganizationViewPage kapOrganizationType="CONTACT_ORGANIZATION" />,
  hideNavigation: true,
} as const

const KapContactCantonCreate = {
  path: 'organization/contact/new',
  Component: () => <KapOrganizationCreatePage kapOrganizationType="CONTACT_ORGANIZATION" />,
  hideNavigation: true,
} as const

const KapContactCantonEdit = {
  path: 'organization/contact/:organizationId/edit',
  Component: () => <KapOrganizationEditPage kapOrganizationType="CONTACT_ORGANIZATION" />,
  hideNavigation: true,
} as const

const KapResponsibleOrganization = {
  path: 'organization/responsible/:organizationId',
  Component: () => <KapOrganizationViewPage kapOrganizationType="RESPONSIBLE_ORGANIZATION" />,
  hideNavigation: true,
} as const

const KapResponsibleOrganizationCreate = {
  path: 'organization/responsible/new',
  Component: () => <KapOrganizationCreatePage kapOrganizationType="RESPONSIBLE_ORGANIZATION" />,
  hideNavigation: true,
} as const

const KapResponsibleOrganizationEdit = {
  path: 'organization/responsible/:organizationId/edit',
  Component: () => <KapOrganizationEditPage kapOrganizationType="RESPONSIBLE_ORGANIZATION" />,
  hideNavigation: true,
} as const

const KapPartnerOrganization = {
  path: 'organization/partner/:organizationId',
  Component: () => <KapOrganizationViewPage kapOrganizationType="PARTNER_ORGANIZATION" />,
  hideNavigation: true,
} as const

const KapPartnerOrganizationCreate = {
  path: 'organization/partner/new',
  Component: () => <KapOrganizationCreatePage kapOrganizationType="PARTNER_ORGANIZATION" />,
  hideNavigation: true,
} as const

const KapPartnerOrganizationEdit = {
  path: 'organization/partner/:organizationId/edit',
  Component: () => <KapOrganizationEditPage kapOrganizationType="PARTNER_ORGANIZATION" />,
  hideNavigation: true,
} as const

const KapStartingPointAndVision = {
  path: 'starting-point-and-vision',
  Component: KapStartingPointAndVisionPage,
  navigationKey: 'label.navigation.project.starting.point.and.vision',
} as const

const KapStartingPointAndVisionInitialCantonalSituation = {
  path: 'starting-point-and-vision/initial-cantonal-situation/edit',
  Component: () => <KapStartingPointAndVisionEditPage field="initial_cantonal_situation" />,
  hideNavigation: true,
} as const

const KapStartingPointAndVisionProgramJustificationEdit = {
  path: 'starting-point-and-vision/program-justification/edit',
  Component: () => <KapStartingPointAndVisionEditPage field="program_justification" />,
  hideNavigation: true,
} as const

const KapStartingPointAndVisionSourcesEdit = {
  path: 'starting-point-and-vision/sources/edit',
  Component: () => <KapStartingPointAndVisionEditPage field="sources" />,
  hideNavigation: true,
} as const

const KapStartingPointAndVisionVisionEdit = {
  path: 'starting-point-and-vision/vision/edit',
  Component: () => <KapStartingPointAndVisionEditPage field="vision" />,
  hideNavigation: true,
} as const

const KapPrinciples = {
  path: 'principles',
  Component: KapProgramDetailsPrinciplesPage,
  navigationKey: 'label.navigation.program.principles',
} as const

const KapSubrincipleEdit = {
  path: 'principles/:subprincipleId/edit',
  Component: KapProgramDetailsSubprincipleEditPage,
  hideNavigation: true,
} as const

const KapGoals = {
  path: 'goals',
  Component: KapGoalsPage,
  navigationKey: 'label.project.goals',
} as const

const KapGoalDetails = {
  path: 'goals/:goalId',
  Component: KapGoalViewPage,
  hideNavigation: true,
} as const

const KapGoalNew = {
  path: 'goals/new',
  Component: KapGoalCreatePage,
  hideNavigation: true,
} as const

const KapGoalEdit = {
  path: 'goals/:goalId/edit',
  Component: KapGoalEditPage,
  hideNavigation: true,
} as const

const KapMeasures = {
  path: 'measures',
  Component: KapMeasuresPage,
  navigationKey: 'label.project.measures',
}

const KapMeasureNew = {
  path: 'measures/new',
  Component: KapMeasureCreatePage,
  hideNavigation: true,
} as const

const KapFinancials = {
  path: 'financials',
  Component: KapFinancialsPage,
  navigationKey: 'label.navigation.project.financials',
} as const

const KapFinancialsEdit = {
  path: 'financials/edit',
  Component: KapFinancialsEditPage,
  hideNavigation: true,
} as const

const KapSummary = {
  path: 'summary',
  Component: KapSummaryPage,
  navigationKey: 'label.navigation.project.summary',
} as const

const KapSummaryCommentEdit = {
  path: 'summary/comment/edit',
  Component: ProgramSummaryCommentEditPage,
  hideNavigation: true,
} as const

const KapMeasureDetailsRoot = {
  path: 'kap/program/:programId/details/measures/measure/:measureId/details',
  Component: KapMeasureDetailsIndexPage,
  roles: [
    'PD-CANTON_KAP_CONTRIBUTOR',
    'PD-CANTON_KAP_READER',
    'PD-GFCH_KAP_COORDINATOR',
    'PD-GFCH_KAP_CONTRIBUTOR',
    'PD-GFCH_KAP_READER',
  ],
} as const

const KapMeasureDescription = {
  path: 'description',
  Component: KapMeasureDescriptionPage,
  navigationKey: 'label.navigation.program.measure.description',
  primary: true,
} as const

const KapMeasureDescriptionEdit = {
  path: 'description/edit',
  Component: KapMeasureDescriptionEditPage,
  hideNavigation: true,
} as const

const KapMeasureFeatures = {
  path: 'features',
  Component: KapMeasureFeaturesPage,
  navigationKey: 'label.navigation.program.measure.features',
} as const

const KapMeasureFeaturesEdit = {
  path: 'features/:featureTypeId/edit',
  Component: KapMeasureFeaturesEditPage,
  hideNavigation: true,
} as const

const KapApplicationDetailsRoot = {
  path: 'kap/program/:programId/application/details',
  Component: KapApplicationDetailsIndexPage,
  roles: [
    'PD-CANTON_KAP_CONTRIBUTOR',
    'PD-CANTON_KAP_READER',
    'PD-GFCH_KAP_COORDINATOR',
    'PD-GFCH_KAP_CONTRIBUTOR',
    'PD-GFCH_KAP_READER',
  ],
} as const

const KapApplicationInformation = {
  path: 'information',
  Component: KapApplicationInformationPage,
  navigationKey: 'label.navigation.application.information',
  primary: true,
} as const

const KapApplicationInformationEdit = {
  path: 'information/edit',
  Component: KapApplicationInformationEditPage,
  hideNavigation: true,
} as const

const KapApplicationSummary = {
  path: 'summary',
  Component: KapApplicationSummaryPage,
  navigationKey: 'label.navigation.project.summary',
} as const

const KapApplicationSummaryEdit = {
  path: 'summary/edit',
  Component: KapApplicationSummaryEditPage,
  hideNavigation: true,
} as const

const KapImplementationDetailsRoot = {
  path: 'kap/program/:programId/implementation',
  Component: KapImplementationDetailsIndexPage,
  roles: [
    'PD-CANTON_KAP_CONTRIBUTOR',
    'PD-CANTON_KAP_READER',
    'PD-GFCH_KAP_COORDINATOR',
    'PD-GFCH_KAP_CONTRIBUTOR',
    'PD-GFCH_KAP_READER',
  ],
} as const

const KapImplementationMilestone = {
  path: 'milestones',
  Component: KapImplementationMilestonePage,
  navigationKey: 'label.navigation.implementation.milestones',
  primary: true,
} as const

const KapImplementationSummary = {
  path: 'summary',
  Component: KapImplementationSummaryPage,
  navigationKey: 'label.navigation.implementation.summary',
} as const

const KapImplementationSummaryEdit = {
  path: 'summary/edit',
  Component: KapImplementationSummaryEditPage,
  hideNavigation: true,
} as const

const KapMilestoneDetailsRoot = {
  path: 'kap/program/:programId/implementation/milestone/:milestoneId/:milestonePath',
  Component: KapMilestoneDetailsIndex,
  roles: [
    'PD-CANTON_KAP_CONTRIBUTOR',
    'PD-CANTON_KAP_READER',
    'PD-GFCH_KAP_COORDINATOR',
    'PD-GFCH_KAP_CONTRIBUTOR',
    'PD-GFCH_KAP_READER',
  ],
} as const

const KapDefaultMilestoneDetails = {
  path: 'details',
  Component: KapMilestoneDetailsPage,
  navigationKey: 'label.navigation.implementation.details',
  primary: true,
} as const

const KapDefaultMilestoneDetailsEdit = {
  path: 'details/edit',
  Component: KapMilestoneDetailsEditPage,
  hideNavigation: true,
} as const

const KapMilestoneSummary = {
  path: 'summary',
  Component: KapMilestoneSummaryPage,
  navigationKey: 'label.navigation.implementation.summary',
} as const

const KapMilestoneFinancials = {
  path: 'financials',
  Component: KapMilestoneFinancialsPage,
  navigationKey: 'label.navigation.project.financials',
  primary: true,
} as const

const KapMilestoneFinancialsEdit = {
  path: 'financials/edit',
  Component: KapMilestoneFinancialsEditPage,
  hideNavigation: true,
} as const

const KapMilestoneSummaryEdit = {
  path: 'summary/comment/edit',
  Component: KapMilestoneSummaryEditPage,
  hideNavigation: true,
} as const

const KapMilestoneAnnualPlanning = {
  path: 'planning',
  Component: KapMilestoneAnnualPlanningPage,
  navigationKey: 'label.navigation.program.annual.planning',
  primary: true,
} as const

const KapMilestoneAnnualPlanningEdit = {
  path: 'planning/measure/:measureId/edit',
  Component: KapMilestoneAnnualPlanningEditPage,
  hideNavigation: true,
} as const

const KapMilestoneMeasureRating = {
  path: 'measure-rating',
  Component: KapMilestoneMeasureRatingPage,
  navigationKey: 'label.measure.rating',
} as const

const KapMilestoneMeasureRatingEdit = {
  path: 'measure-rating/:measureId/edit',
  Component: KapMilestoneMeasureRatingEditPage,
  hideNavigation: true,
} as const

const KapMilestoneGoalRating = {
  path: 'goal-rating',
  Component: KapMilestoneGoalReportPage,
  navigationKey: 'label.goal.rating',
  primary: true,
} as const

const KapMilestoneGoalRatingEdit = {
  path: 'goal-rating/:goalId/edit',
  Component: KapMilestoneGoalReportEditPage,
  hideNavigation: true,
} as const

const KapMilestonePrinciples = {
  path: 'principles',
  Component: KapMilestonePrinciplesPage,
  navigationKey: 'label.navigation.program.principles',
} as const

const KapMilestoneSubprincipleEdit = {
  path: 'principles/:subprincipleId/edit',
  Component: KapMilestoneSubprincipleEditPage,
  hideNavigation: true,
} as const

export const KapRoutes = {
  KapIndex: KapIndex,
  KapDetailsRoot: {
    ...KapDetailsRoot,
    params: (props: ExtractRouteParams<typeof KapDetailsRoot.path>) => withParams(`/${KapDetailsRoot.path}`)(props),
    nested: {
      BasicInformation: {
        ...KapBasicInformation,
        params: (
          props: ExtractRouteParams<ComposePaths<typeof KapDetailsRoot.path, typeof KapBasicInformation.path>>,
        ) => withParams(`/${KapDetailsRoot.path}/${KapBasicInformation.path}`)(props),
      },
      BasicInformationEdit: {
        ...KapBasicInformationEdit,
        params: (
          props: ExtractRouteParams<ComposePaths<typeof KapDetailsRoot.path, typeof KapBasicInformationEdit.path>>,
        ) => withParams(`/${KapDetailsRoot.path}/${KapBasicInformationEdit.path}`)(props),
      },
      Organization: {
        ...KapOrganization,
        params: (props: ExtractRouteParams<ComposePaths<typeof KapDetailsRoot.path, typeof KapOrganization.path>>) =>
          withParams(`/${KapDetailsRoot.path}/${KapOrganization.path}`)(props),
      },
      ContactCanton: {
        ...KapContactCanton,
        params: (props: ExtractRouteParams<ComposePaths<typeof KapDetailsRoot.path, typeof KapContactCanton.path>>) =>
          withParams(`/${KapDetailsRoot.path}/${KapContactCanton.path}`)(props),
      },
      ContactCantonCreate: {
        ...KapContactCantonCreate,
        params: (
          props: ExtractRouteParams<ComposePaths<typeof KapDetailsRoot.path, typeof KapContactCantonCreate.path>>,
        ) => withParams(`/${KapDetailsRoot.path}/${KapContactCantonCreate.path}`)(props),
      },
      ContactCantonEdit: {
        ...KapContactCantonEdit,
        params: (
          props: ExtractRouteParams<ComposePaths<typeof KapDetailsRoot.path, typeof KapContactCantonEdit.path>>,
        ) => withParams(`/${KapDetailsRoot.path}/${KapContactCantonEdit.path}`)(props),
      },
      ResponsibleOrganization: {
        ...KapResponsibleOrganization,
        params: (
          props: ExtractRouteParams<ComposePaths<typeof KapDetailsRoot.path, typeof KapResponsibleOrganization.path>>,
        ) => withParams(`/${KapDetailsRoot.path}/${KapResponsibleOrganization.path}`)(props),
      },
      ResponsibleOrganizationCreate: {
        ...KapResponsibleOrganizationCreate,
        params: (
          props: ExtractRouteParams<
            ComposePaths<typeof KapDetailsRoot.path, typeof KapResponsibleOrganizationCreate.path>
          >,
        ) => withParams(`/${KapDetailsRoot.path}/${KapResponsibleOrganizationCreate.path}`)(props),
      },
      ResponsibleOrganizationEdit: {
        ...KapResponsibleOrganizationEdit,
        params: (
          props: ExtractRouteParams<
            ComposePaths<typeof KapDetailsRoot.path, typeof KapResponsibleOrganizationEdit.path>
          >,
        ) => withParams(`/${KapDetailsRoot.path}/${KapResponsibleOrganizationEdit.path}`)(props),
      },
      PartnerOrganization: {
        ...KapPartnerOrganization,
        params: (
          props: ExtractRouteParams<ComposePaths<typeof KapDetailsRoot.path, typeof KapPartnerOrganization.path>>,
        ) => withParams(`/${KapDetailsRoot.path}/${KapPartnerOrganization.path}`)(props),
      },
      PartnerOrganizationCreate: {
        ...KapPartnerOrganizationCreate,
        params: (
          props: ExtractRouteParams<ComposePaths<typeof KapDetailsRoot.path, typeof KapPartnerOrganizationCreate.path>>,
        ) => withParams(`/${KapDetailsRoot.path}/${KapPartnerOrganizationCreate.path}`)(props),
      },
      PartnerOrganizationEdit: {
        ...KapPartnerOrganizationEdit,
        params: (
          props: ExtractRouteParams<ComposePaths<typeof KapDetailsRoot.path, typeof KapPartnerOrganizationEdit.path>>,
        ) => withParams(`/${KapDetailsRoot.path}/${KapPartnerOrganizationEdit.path}`)(props),
      },
      StartingPointAndVision: {
        ...KapStartingPointAndVision,
        params: (
          props: ExtractRouteParams<ComposePaths<typeof KapDetailsRoot.path, typeof KapStartingPointAndVision.path>>,
        ) => withParams(`/${KapDetailsRoot.path}/${KapStartingPointAndVision.path}`)(props),
      },
      StartingPointAndVisionInitialCantonalSituationEdit: {
        ...KapStartingPointAndVisionInitialCantonalSituation,
        params: (
          props: ExtractRouteParams<
            ComposePaths<typeof KapDetailsRoot.path, typeof KapStartingPointAndVisionInitialCantonalSituation.path>
          >,
        ) => withParams(`/${KapDetailsRoot.path}/${KapStartingPointAndVisionInitialCantonalSituation.path}`)(props),
      },
      StartingPointAndVisionProgramJustificationEdit: {
        ...KapStartingPointAndVisionProgramJustificationEdit,
        params: (
          props: ExtractRouteParams<
            ComposePaths<typeof KapDetailsRoot.path, typeof KapStartingPointAndVisionProgramJustificationEdit.path>
          >,
        ) => withParams(`/${KapDetailsRoot.path}/${KapStartingPointAndVisionProgramJustificationEdit.path}`)(props),
      },
      StartingPointAndVisionSourcesEdit: {
        ...KapStartingPointAndVisionSourcesEdit,
        params: (
          props: ExtractRouteParams<
            ComposePaths<typeof KapDetailsRoot.path, typeof KapStartingPointAndVisionSourcesEdit.path>
          >,
        ) => withParams(`/${KapDetailsRoot.path}/${KapStartingPointAndVisionSourcesEdit.path}`)(props),
      },
      StartingPointAndVisionVisionEdit: {
        ...KapStartingPointAndVisionVisionEdit,
        params: (
          props: ExtractRouteParams<
            ComposePaths<typeof KapDetailsRoot.path, typeof KapStartingPointAndVisionVisionEdit.path>
          >,
        ) => withParams(`/${KapDetailsRoot.path}/${KapStartingPointAndVisionVisionEdit.path}`)(props),
      },
      Principles: {
        ...KapPrinciples,
        params: (props: ExtractRouteParams<ComposePaths<typeof KapDetailsRoot.path, typeof KapPrinciples.path>>) =>
          withParams(`/${KapDetailsRoot.path}/${KapPrinciples.path}`)(props),
      },
      SubprincipleEdit: {
        ...KapSubrincipleEdit,
        params: (props: ExtractRouteParams<ComposePaths<typeof KapDetailsRoot.path, typeof KapSubrincipleEdit.path>>) =>
          withParams(`/${KapDetailsRoot.path}/${KapSubrincipleEdit.path}`)(props),
      },
      Goals: {
        ...KapGoals,
        params: (props: ExtractRouteParams<ComposePaths<typeof KapDetailsRoot.path, typeof KapGoals.path>>) =>
          withParams(`/${KapDetailsRoot.path}/${KapGoals.path}`)(props),
      },
      GoalDetails: {
        ...KapGoalDetails,
        params: (props: ExtractRouteParams<ComposePaths<typeof KapDetailsRoot.path, typeof KapGoalDetails.path>>) =>
          withParams(`/${KapDetailsRoot.path}/${KapGoalDetails.path}`)(props),
      },
      GoalNew: {
        ...KapGoalNew,
        params: (props: ExtractRouteParams<ComposePaths<typeof KapDetailsRoot.path, typeof KapGoalNew.path>>) =>
          withParams(`/${KapDetailsRoot.path}/${KapGoalNew.path}`)(props),
      },
      GoalEdit: {
        ...KapGoalEdit,
        params: (props: ExtractRouteParams<ComposePaths<typeof KapDetailsRoot.path, typeof KapGoalEdit.path>>) =>
          withParams(`/${KapDetailsRoot.path}/${KapGoalEdit.path}`)(props),
      },
      Measures: {
        ...KapMeasures,
        params: (props: ExtractRouteParams<ComposePaths<typeof KapDetailsRoot.path, typeof KapMeasures.path>>) =>
          withParams(`/${KapDetailsRoot.path}/${KapMeasures.path}`)(props),
      },
      MeasureNew: {
        ...KapMeasureNew,
        params: (props: ExtractRouteParams<ComposePaths<typeof KapDetailsRoot.path, typeof KapMeasureNew.path>>) =>
          withParams(`/${KapDetailsRoot.path}/${KapMeasureNew.path}`)(props),
      },
      Financials: {
        ...KapFinancials,
        params: (props: ExtractRouteParams<ComposePaths<typeof KapDetailsRoot.path, typeof KapFinancials.path>>) =>
          withParams(`/${KapDetailsRoot.path}/${KapFinancials.path}`)(props),
      },
      FinancialsEdit: {
        ...KapFinancialsEdit,
        params: (props: ExtractRouteParams<ComposePaths<typeof KapDetailsRoot.path, typeof KapFinancialsEdit.path>>) =>
          withParams(`/${KapDetailsRoot.path}/${KapFinancialsEdit.path}`)(props),
      },
      Summary: {
        ...KapSummary,
        params: (props: ExtractRouteParams<ComposePaths<typeof KapDetailsRoot.path, typeof KapSummary.path>>) =>
          withParams(`/${KapDetailsRoot.path}/${KapSummary.path}`)(props),
      },
      ProgramSummaryCommentEdit: {
        ...KapSummaryCommentEdit,
        params: (
          props: ExtractRouteParams<ComposePaths<typeof KapDetailsRoot.path, typeof KapSummaryCommentEdit.path>>,
        ) => withParams(`/${KapDetailsRoot.path}/${KapSummaryCommentEdit.path}`)(props),
      },
    },
  },
  KapMeasureDetailsRoot: {
    ...KapMeasureDetailsRoot,
    params: (props: ExtractRouteParams<typeof KapMeasureDetailsRoot.path>) =>
      withParams(`/${KapMeasureDetailsRoot.path}`)(props),
    nested: {
      Description: {
        ...KapMeasureDescription,
        params: (
          props: ExtractRouteParams<ComposePaths<typeof KapMeasureDetailsRoot.path, typeof KapMeasureDescription.path>>,
        ) => withParams(`/${KapMeasureDetailsRoot.path}/${KapMeasureDescription.path}`)(props),
      },
      DescriptionEdit: {
        ...KapMeasureDescriptionEdit,
        params: (
          props: ExtractRouteParams<
            ComposePaths<typeof KapMeasureDetailsRoot.path, typeof KapMeasureDescriptionEdit.path>
          >,
        ) => withParams(`/${KapMeasureDetailsRoot.path}/${KapMeasureDescriptionEdit.path}`)(props),
      },
      Features: {
        ...KapMeasureFeatures,
        params: (
          props: ExtractRouteParams<ComposePaths<typeof KapMeasureDetailsRoot.path, typeof KapMeasureFeatures.path>>,
        ) => withParams(`/${KapMeasureDetailsRoot.path}/${KapMeasureFeatures.path}`)(props),
      },
      FeaturesEdit: {
        ...KapMeasureFeaturesEdit,
        params: (
          props: ExtractRouteParams<
            ComposePaths<typeof KapMeasureDetailsRoot.path, typeof KapMeasureFeaturesEdit.path>
          >,
        ) => withParams(`/${KapMeasureDetailsRoot.path}/${KapMeasureFeaturesEdit.path}`)(props),
      },
    },
  },
  KapApplicationDetailsRoot: {
    ...KapApplicationDetailsRoot,
    params: (props: ExtractRouteParams<typeof KapApplicationDetailsRoot.path>) =>
      withParams(`/${KapApplicationDetailsRoot.path}`)(props),
    nested: {
      Information: {
        ...KapApplicationInformation,
        params: (
          props: ExtractRouteParams<
            ComposePaths<typeof KapApplicationDetailsRoot.path, typeof KapApplicationInformation.path>
          >,
        ) => withParams(`/${KapApplicationDetailsRoot.path}/${KapApplicationInformation.path}`)(props),
      },
      InformationEdit: {
        ...KapApplicationInformationEdit,
        params: (
          props: ExtractRouteParams<
            ComposePaths<typeof KapApplicationDetailsRoot.path, typeof KapApplicationInformationEdit.path>
          >,
        ) => withParams(`/${KapApplicationDetailsRoot.path}/${KapApplicationInformationEdit.path}`)(props),
      },
      Summary: {
        ...KapApplicationSummary,
        params: (
          props: ExtractRouteParams<
            ComposePaths<typeof KapApplicationDetailsRoot.path, typeof KapApplicationSummary.path>
          >,
        ) => withParams(`/${KapApplicationDetailsRoot.path}/${KapApplicationSummary.path}`)(props),
      },
      SummaryEdit: {
        ...KapApplicationSummaryEdit,
        params: (
          props: ExtractRouteParams<
            ComposePaths<typeof KapApplicationDetailsRoot.path, typeof KapApplicationSummaryEdit.path>
          >,
        ) => withParams(`/${KapApplicationDetailsRoot.path}/${KapApplicationSummaryEdit.path}`)(props),
      },
    },
  },
  KapImplementationDetailsRoot: {
    ...KapImplementationDetailsRoot,
    params: (props: ExtractRouteParams<typeof KapImplementationDetailsRoot.path>) =>
      withParams(`/${KapImplementationDetailsRoot.path}`)(props),
    nested: {
      Milestone: {
        ...KapImplementationMilestone,
        params: (
          props: ExtractRouteParams<
            ComposePaths<typeof KapImplementationDetailsRoot.path, typeof KapImplementationMilestone.path>
          >,
        ) => withParams(`/${KapImplementationDetailsRoot.path}/${KapImplementationMilestone.path}`)(props),
      },
      Summary: {
        ...KapImplementationSummary,
        params: (
          props: ExtractRouteParams<
            ComposePaths<typeof KapImplementationDetailsRoot.path, typeof KapImplementationSummary.path>
          >,
        ) => withParams(`/${KapImplementationDetailsRoot.path}/${KapImplementationSummary.path}`)(props),
      },
      SummaryEdit: {
        ...KapImplementationSummaryEdit,
        params: (
          props: ExtractRouteParams<
            ComposePaths<typeof KapImplementationDetailsRoot.path, typeof KapImplementationSummaryEdit.path>
          >,
        ) => withParams(`/${KapImplementationDetailsRoot.path}/${KapImplementationSummaryEdit.path}`)(props),
      },
    },
  },
  KapDefaultMilestoneRoot: {
    ...KapMilestoneDetailsRoot,
    params: (props: ExtractRouteParams<typeof KapMilestoneDetailsRoot.path>) =>
      withParams(`/${KapMilestoneDetailsRoot.path}`)(props),
    nested: {
      Details: {
        ...KapDefaultMilestoneDetails,
        params: (
          props: ExtractRouteParams<
            ComposePaths<typeof KapMilestoneDetailsRoot.path, typeof KapDefaultMilestoneDetails.path>
          >,
        ) => withParams(`/${KapMilestoneDetailsRoot.path}/${KapDefaultMilestoneDetails.path}`)(props),
      },
      DetailsEdit: {
        ...KapDefaultMilestoneDetailsEdit,
        params: (
          props: ExtractRouteParams<
            ComposePaths<typeof KapMilestoneDetailsRoot.path, typeof KapDefaultMilestoneDetailsEdit.path>
          >,
        ) => withParams(`/${KapMilestoneDetailsRoot.path}/${KapDefaultMilestoneDetailsEdit.path}`)(props),
      },
      Summary: {
        ...KapMilestoneSummary,
        params: (
          props: ExtractRouteParams<ComposePaths<typeof KapMilestoneDetailsRoot.path, typeof KapMilestoneSummary.path>>,
        ) => withParams(`/${KapMilestoneDetailsRoot.path}/${KapMilestoneSummary.path}`)(props),
      },
      SummaryCommentEdit: {
        ...KapMilestoneSummaryEdit,
        params: (
          props: ExtractRouteParams<
            ComposePaths<typeof KapMilestoneDetailsRoot.path, typeof KapMilestoneSummaryEdit.path>
          >,
        ) => withParams(`/${KapMilestoneDetailsRoot.path}/${KapMilestoneSummaryEdit.path}`)(props),
      },
    },
  },
  KapFinancialReportMilestoneRoot: {
    ...KapMilestoneDetailsRoot,
    params: (props: ExtractRouteParams<typeof KapMilestoneDetailsRoot.path>) =>
      withParams(`/${KapMilestoneDetailsRoot.path}`)(props),
    nested: {
      Financials: {
        ...KapMilestoneFinancials,
        params: (
          props: ExtractRouteParams<
            ComposePaths<typeof KapMilestoneDetailsRoot.path, typeof KapMilestoneFinancials.path>
          >,
        ) => withParams(`/${KapMilestoneDetailsRoot.path}/${KapMilestoneFinancials.path}`)(props),
      },
      FinancialsEdit: {
        ...KapMilestoneFinancialsEdit,
        params: (
          props: ExtractRouteParams<
            ComposePaths<typeof KapMilestoneDetailsRoot.path, typeof KapMilestoneFinancialsEdit.path>
          >,
        ) => withParams(`/${KapMilestoneDetailsRoot.path}/${KapMilestoneFinancialsEdit.path}`)(props),
      },
      Summary: {
        ...KapMilestoneSummary,
        params: (
          props: ExtractRouteParams<ComposePaths<typeof KapMilestoneDetailsRoot.path, typeof KapMilestoneSummary.path>>,
        ) => withParams(`/${KapMilestoneDetailsRoot.path}/${KapMilestoneSummary.path}`)(props),
      },
      SummaryCommentEdit: {
        ...KapMilestoneSummaryEdit,
        params: (
          props: ExtractRouteParams<
            ComposePaths<typeof KapMilestoneDetailsRoot.path, typeof KapMilestoneSummaryEdit.path>
          >,
        ) => withParams(`/${KapMilestoneDetailsRoot.path}/${KapMilestoneSummaryEdit.path}`)(props),
      },
    },
  },
  KapAnnualPlanningMilestoneRoot: {
    ...KapMilestoneDetailsRoot,
    params: (props: ExtractRouteParams<typeof KapMilestoneDetailsRoot.path>) =>
      withParams(`/${KapMilestoneDetailsRoot.path}`)(props),
    nested: {
      Planning: {
        ...KapMilestoneAnnualPlanning,
        params: (
          props: ExtractRouteParams<
            ComposePaths<typeof KapMilestoneDetailsRoot.path, typeof KapMilestoneAnnualPlanning.path>
          >,
        ) => withParams(`/${KapMilestoneDetailsRoot.path}/${KapMilestoneAnnualPlanning.path}`)(props),
      },
      PlanningEdit: {
        ...KapMilestoneAnnualPlanningEdit,
        params: (
          props: ExtractRouteParams<
            ComposePaths<typeof KapMilestoneDetailsRoot.path, typeof KapMilestoneAnnualPlanningEdit.path>
          >,
        ) => withParams(`/${KapMilestoneDetailsRoot.path}/${KapMilestoneAnnualPlanningEdit.path}`)(props),
      },
      Summary: {
        ...KapMilestoneSummary,
        params: (
          props: ExtractRouteParams<ComposePaths<typeof KapMilestoneDetailsRoot.path, typeof KapMilestoneSummary.path>>,
        ) => withParams(`/${KapMilestoneDetailsRoot.path}/${KapMilestoneSummary.path}`)(props),
      },
      SummaryCommentEdit: {
        ...KapMilestoneSummaryEdit,
        params: (
          props: ExtractRouteParams<
            ComposePaths<typeof KapMilestoneDetailsRoot.path, typeof KapMilestoneSummaryEdit.path>
          >,
        ) => withParams(`/${KapMilestoneDetailsRoot.path}/${KapMilestoneSummaryEdit.path}`)(props),
      },
    },
  },
  KapAnnualReportMilestoneRoot: {
    ...KapMilestoneDetailsRoot,
    params: (props: ExtractRouteParams<typeof KapMilestoneDetailsRoot.path>) =>
      withParams(`/${KapMilestoneDetailsRoot.path}`)(props),
    nested: {
      GoalRating: {
        ...KapMilestoneGoalRating,
        params: (
          props: ExtractRouteParams<
            ComposePaths<typeof KapMilestoneDetailsRoot.path, typeof KapMilestoneGoalRating.path>
          >,
        ) => withParams(`/${KapMilestoneDetailsRoot.path}/${KapMilestoneGoalRating.path}`)(props),
      },
      GoalRatingEdit: {
        ...KapMilestoneGoalRatingEdit,
        params: (
          props: ExtractRouteParams<
            ComposePaths<typeof KapMilestoneDetailsRoot.path, typeof KapMilestoneGoalRatingEdit.path>
          >,
        ) => withParams(`/${KapMilestoneDetailsRoot.path}/${KapMilestoneGoalRatingEdit.path}`)(props),
      },
      MeasureRating: {
        ...KapMilestoneMeasureRating,
        params: (
          props: ExtractRouteParams<
            ComposePaths<typeof KapMilestoneDetailsRoot.path, typeof KapMilestoneMeasureRating.path>
          >,
        ) => withParams(`/${KapMilestoneDetailsRoot.path}/${KapMilestoneMeasureRating.path}`)(props),
      },
      MeasureRatingEdit: {
        ...KapMilestoneMeasureRatingEdit,
        params: (
          props: ExtractRouteParams<
            ComposePaths<typeof KapMilestoneDetailsRoot.path, typeof KapMilestoneMeasureRatingEdit.path>
          >,
        ) => withParams(`/${KapMilestoneDetailsRoot.path}/${KapMilestoneMeasureRatingEdit.path}`)(props),
      },
      Summary: {
        ...KapMilestoneSummary,
        params: (
          props: ExtractRouteParams<ComposePaths<typeof KapMilestoneDetailsRoot.path, typeof KapMilestoneSummary.path>>,
        ) => withParams(`/${KapMilestoneDetailsRoot.path}/${KapMilestoneSummary.path}`)(props),
      },
      SummaryCommentEdit: {
        ...KapMilestoneSummaryEdit,
        params: (
          props: ExtractRouteParams<
            ComposePaths<typeof KapMilestoneDetailsRoot.path, typeof KapMilestoneSummaryEdit.path>
          >,
        ) => withParams(`/${KapMilestoneDetailsRoot.path}/${KapMilestoneSummaryEdit.path}`)(props),
      },
    },
  },
  KapFinalReportMilestoneRoot: {
    ...KapMilestoneDetailsRoot,
    params: (props: ExtractRouteParams<typeof KapMilestoneDetailsRoot.path>) =>
      withParams(`/${KapMilestoneDetailsRoot.path}`)(props),
    nested: {
      Details: {
        ...KapDefaultMilestoneDetails,
        params: (
          props: ExtractRouteParams<
            ComposePaths<typeof KapMilestoneDetailsRoot.path, typeof KapDefaultMilestoneDetails.path>
          >,
        ) => withParams(`/${KapMilestoneDetailsRoot.path}/${KapDefaultMilestoneDetails.path}`)(props),
      },
      DetailsEdit: {
        ...KapDefaultMilestoneDetailsEdit,
        params: (
          props: ExtractRouteParams<
            ComposePaths<typeof KapMilestoneDetailsRoot.path, typeof KapDefaultMilestoneDetailsEdit.path>
          >,
        ) => withParams(`/${KapMilestoneDetailsRoot.path}/${KapDefaultMilestoneDetailsEdit.path}`)(props),
      },
      Principles: {
        ...KapMilestonePrinciples,
        params: (
          props: ExtractRouteParams<
            ComposePaths<typeof KapMilestoneDetailsRoot.path, typeof KapMilestonePrinciples.path>
          >,
        ) => withParams(`/${KapMilestoneDetailsRoot.path}/${KapMilestonePrinciples.path}`)(props),
      },
      PrinciplesEdit: {
        ...KapMilestoneSubprincipleEdit,
        params: (
          props: ExtractRouteParams<
            ComposePaths<typeof KapMilestoneDetailsRoot.path, typeof KapMilestoneSubprincipleEdit.path>
          >,
        ) => withParams(`/${KapMilestoneDetailsRoot.path}/${KapMilestoneSubprincipleEdit.path}`)(props),
      },
      Summary: {
        ...KapMilestoneSummary,
        params: (
          props: ExtractRouteParams<ComposePaths<typeof KapMilestoneDetailsRoot.path, typeof KapMilestoneSummary.path>>,
        ) => withParams(`/${KapMilestoneDetailsRoot.path}/${KapMilestoneSummary.path}`)(props),
      },
      SummaryCommentEdit: {
        ...KapMilestoneSummaryEdit,
        params: (
          props: ExtractRouteParams<
            ComposePaths<typeof KapMilestoneDetailsRoot.path, typeof KapMilestoneSummaryEdit.path>
          >,
        ) => withParams(`/${KapMilestoneDetailsRoot.path}/${KapMilestoneSummaryEdit.path}`)(props),
      },
    },
  },
}
