import { Chip } from '@mui/material'
import Typography from '@mui/material/Typography'
import { TEXT_OVERFLOW_MIXIN, TYPOGRAPHY_MIXIN } from 'src/shared/constants/styling-constants'
import styled from 'styled-components/macro'

export const IdChipStyled = styled(Chip)`
  cursor: pointer;
  min-width: 3rem;
  &.MuiChip-filledDefault {
    background-color: ${({ theme }) => theme.colors.action.hover};
    color: ${({ theme }) => theme.colors.text.disabled};
  }
  & .MuiChip-label {
    ${TYPOGRAPHY_MIXIN.subtitle2};
    line-height: initial;
  }
`

export const MoveButtonsStyled = styled('div')`
  display: flex;
  & .MuiIconButton-root {
    width: 1.5rem;
    height: 1.5rem;
    padding: 0;
    &:first-child {
      margin-right: ${({ theme }) => theme.spacing(0.5)};
    }
    &:hover {
      color: ${({ theme }) => theme.colors.primary.dark};
    }
  }
  & .MuiSvgIcon-root {
    font-size: 1.25rem;
  }
`

export const ItemContainerStyled = styled.div`
  flex-grow: 1;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: ${({ theme }) => theme.spacing(2)};
  align-items: center;
`

export const ItemTitleStyled = styled(Typography)`
  ${TEXT_OVERFLOW_MIXIN};
`

export const RelatedItemStyled = styled(Typography)`
  color: ${({ theme }) => theme.colors.text.secondary};
  ${TEXT_OVERFLOW_MIXIN};
`
