import { useParams } from 'react-router'
import { Query_Root } from 'src/@types/graphql'
import { useMessageSource } from 'src/i18n/useMessageSource'
import { ROUTES } from 'src/routing/routes'
import { BASE_URL } from 'src/shared/constants/constants'
import { Sidebar, SidebarGroupObject } from 'src/shared/sidebar/Sidebar'
import { useSidebarContext } from 'src/shared/sidebar/SidebarAwareContext'
import { useNotificationService } from 'src/shared/utils/NotificationService'
import { gql, useQuery } from 'urql'

const getSuccessFactorAnalysisByIdQuery = gql`
  query fetchSuccessFactorAnalysisById($id: Int!) {
    success_factor_analysis_by_pk(id: $id) {
      title
      canton_code
    }
  }
`

export const SuccessFactorsSidebar = () => {
  const { getMessage } = useMessageSource()
  const { analysisId } = useParams()
  const notificationService = useNotificationService()

  const id = parseInt(analysisId as string)

  const [{ data, error, fetching }, refetchQuery] = useQuery<
    { success_factor_analysis_by_pk: Query_Root['success_factor_analysis_by_pk'] },
    { id: number }
  >({ query: getSuccessFactorAnalysisByIdQuery, variables: { id } })

  if (error) {
    notificationService.operationFailed()
  }

  const analysisTitle = data?.success_factor_analysis_by_pk?.title
  const analysisCanton = getMessage(`label.canton.${data?.success_factor_analysis_by_pk?.canton_code}`)

  useSidebarContext(refetchQuery)

  const routeGroups: SidebarGroupObject[] = [
    {
      groupMessageKey: 'label.success.factors.overview',
      list: [
        {
          listMessageKey: 'label.navigation.success.factors.analysis',
          href: ROUTES.SuccessFactorAnalysisRoot.params({ analysisId }),
          routePath: ROUTES.SuccessFactorAnalysisRoot.path,
        },
      ],
    },
  ]

  return (
    <Sidebar
      routes={routeGroups}
      home={{ url: BASE_URL.SUCCESS_FACTORS, label: getMessage('label.success.factors.cockpit') }}
      process="SUCCESS_FACTORS"
      title={analysisTitle}
      canton={analysisCanton}
      loading={fetching}
    />
  )
}
