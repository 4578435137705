import { valueof } from 'src/@types/global'

const REPORTING_DOWNLOAD = {
  PROJECT_INFORMATION: 'project-information',
  PROGRAM_INFORMATION: 'program-information',
  FINANCIALS: 'financials',
  FEATURES: 'features',
  KPI: 'kpi',
  GOALS: 'goals-download',
  MEASURES: 'measures-download',
  EVALUATION: 'evaluation-download',
  EVALUATION_NEED: 'evaluation-need-download',
} as const

export type REPORTING_DOWNLOAD_TYPE = valueof<typeof REPORTING_DOWNLOAD>

const FEATURE_OPERATOR = {
  AND: 'AND',
  OR: 'OR',
} as const

export type FEATURE_OPERATOR_TYPE = keyof typeof FEATURE_OPERATOR

export { FEATURE_OPERATOR, REPORTING_DOWNLOAD }
