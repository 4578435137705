import { Box, CircularProgress, Divider, Typography } from '@mui/material'
import { SupportedLocale } from 'src/@types'
import { Query_Root } from 'src/@types/graphql'
import { useMessageSource } from 'src/i18n/useMessageSource'
import { getSuccessFactorIndicators } from 'src/screens/success-factor-tool/analysis/details/analysis/analysisQueries'
import { LIFE_PHASES, LIFE_PHASE_TYPE, TOPICS, TOPIC_TYPE } from 'src/shared/constants/success-factor-constants'
import { HtmlRenderer } from 'src/shared/presentation/HtmlRenderer'
import { S } from 'src/shared/styled/S'
import { HtmlSanitizer } from 'src/shared/utils/HtmlSanitizer'
import { useUserLocale } from 'src/user/UserContext'
import styled, { css } from 'styled-components/macro'
import { useQuery } from 'urql'

interface Props {
  description: string
  successFactorId: number
  lifePhases: LIFE_PHASE_TYPE[]
  topics: TOPIC_TYPE[]
}

const LoadingContainer = styled(Box)`
  display: flex;
  justify-content: center;
  padding-bottom: ${({ theme }) => theme.spacing(2)};
  border-radius: 4px;
`

const IndicatorsContainer = styled(Box)<{ $threeColumnLayout: boolean }>`
  display: flex;
  gap: ${({ theme }) => theme.spacing(3)};
  flex-wrap: wrap;

  ${({ $threeColumnLayout }) =>
    $threeColumnLayout &&
    css`
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
    `}
`

export const SuccessFactorsOverviewCardContent = ({ description, successFactorId, lifePhases, topics }: Props) => {
  const locale = useUserLocale()
  const { getMessage } = useMessageSource()

  const [{ data, fetching }] = useQuery<
    { success_factor_indicator: Query_Root['success_factor_indicator'] },
    { successFactorId: number; language: SupportedLocale }
  >({ query: getSuccessFactorIndicators, variables: { successFactorId, language: locale } })

  const successFactorIndicators = data?.success_factor_indicator ?? []

  const indicators =
    successFactorIndicators
      ?.filter((i) => i.indicator_code === LIFE_PHASES.GENERAL)
      .concat(successFactorIndicators?.filter((i) => lifePhases.includes(i.indicator_code as LIFE_PHASE_TYPE)))
      .concat(successFactorIndicators.filter((i) => topics.includes(i.indicator_code as TOPIC_TYPE)))
      .sort((a, b) => a.sort_number - b.sort_number) ?? []

  const resolveTitle = (indicator_code: LIFE_PHASE_TYPE | TOPIC_TYPE | string) => {
    if (Object.values(LIFE_PHASES).includes(indicator_code as LIFE_PHASE_TYPE)) {
      return getMessage(`label.success.factor.life.phase.${indicator_code}`)
    } else if (Object.values(TOPICS).includes(indicator_code as TOPIC_TYPE)) {
      return getMessage(`label.success.factor.topic.${indicator_code}`)
    }
  }

  return (
    <>
      <Box>
        {!fetching ? (
          <>
            <Typography variant="body2" color="textSecondary">
              {description}
            </Typography>
            <IndicatorsContainer mt={2} $threeColumnLayout={indicators.length > 4}>
              {indicators &&
                indicators.map((indicator) => (
                  <S.List.SuccessFactorIndicators key={indicator.sort_number}>
                    <Typography variant="h6" className="indicator-title">
                      {resolveTitle(indicator.indicator_code)}
                    </Typography>
                    <Divider />
                    <HtmlRenderer
                      html={HtmlSanitizer.resolveConfigTextWithBullets(indicator.description)}
                      variant="body2"
                      className="html-renderer"
                    />
                  </S.List.SuccessFactorIndicators>
                ))}
            </IndicatorsContainer>
          </>
        ) : (
          <LoadingContainer>
            <CircularProgress />
          </LoadingContainer>
        )}
      </Box>
    </>
  )
}
