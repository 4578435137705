import { gql } from '@urql/core'

export const queryKapGoalByProgramId = gql`
  query ($programId: Int!) {
    kap_goal(where: { kap_program_id: { _eq: $programId } }, order_by: { sort_number: asc }) {
      id
      name
      sort_number
      kap_program_id
    }
  }
`
export const queryKapAnnualReportAngGoalReportAndMeasures = gql`
  query ($goalId: Int!, $milestoneId: Int!) {
    kap_goal_by_pk(id: $goalId) {
      id
      description
      kap_goal_measures {
        kap_measure {
          short_title
          id
          sort_number
          level
        }
      }
    }
    kap_goal_annual_report(
      where: { _and: [{ milestone_id: { _eq: $milestoneId } }, { kap_goal: { id: { _eq: $goalId } } }] }
    ) {
      rating
      reason
    }
    kap_measure_annual_report(where: { milestone_id: { _eq: $milestoneId } }) {
      kap_measure_id
      rating
    }
  }
`

export const upsertKapGoalAnnualReportMutation = gql`
  mutation upsertKapGoalAnnualReport($object: kap_goal_annual_report_insert_input!) {
    insert_kap_goal_annual_report_one(
      object: $object
      on_conflict: { constraint: kap_goal_annual_report_milestone_goal_uq, update_columns: [rating, reason] }
    ) {
      id
    }
  }
`
export const queryKapGoalAnnualReportByGoalAndMilestoneId = gql`
  query fetchKapGoalAnnualReport($goalId: Int!, $milestoneId: Int!) {
    kap_goal_annual_report(where: { _and: { milestone_id: { _eq: $milestoneId }, kap_goal_id: { _eq: $goalId } } }) {
      rating
      reason
    }
    kap_goal_by_pk(id: $goalId) {
      name
      sort_number
    }
  }
`
