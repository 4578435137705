import { Box, Card, CardContent, Collapse, IconButton } from '@mui/material'
import { ReactElement, ReactNode, useEffect, useState } from 'react'
import { ExpandMoreIcon } from 'src/shared/icons/Icons'
import styled from 'styled-components/macro'
import { S } from '../styled/S'

interface Props {
  id?: string
  header: ReactNode
  content: ReactNode
  actions?: ReactNode
  localExpansion?: boolean
  className?: string
}

const CardStyled = styled(Card)`
  &:hover {
    box-shadow: ${({ theme }) => theme.shadows[4]};
  }

  .header-wrapper {
    display: flex;
    justify-content: space-between;
    cursor: pointer;

    .header-content {
      flex-grow: 1;

      &:hover ~ .header-actions .expand-button {
        background-color: ${({ theme }) => theme.colors.action.hover};
        transition: ${({ theme }) =>
          theme.transitions.create('all', { duration: theme.transitions.duration.standard })};
      }
    }
  }
`

const IconButtonStyled = styled(IconButton)<{ $expand: boolean }>`
  transform: ${({ $expand }) => (!$expand ? 'rotate(0deg)' : 'rotate(180deg)')};
  transition: ${({ theme }) =>
    theme.transitions.create('transform', { duration: theme.transitions.duration.shortest })};
`

export const ExpandableCard = ({ id, header, content, actions, localExpansion, className }: Props): ReactElement => {
  const [expanded, setExpanded] = useState(false)
  const [expandedLocal, setExpandedLocal] = useState(false)

  const handleExpandClick = () => {
    setExpanded(!expanded)
  }

  const handleLocalExpandClick = () => {
    setExpandedLocal(!expandedLocal)
  }

  useEffect(() => {
    if (localExpansion) {
      setExpandedLocal(localExpansion)
    }
  }, [localExpansion])

  return (
    <CardStyled className={className}>
      <Box id={id} className="header-wrapper">
        <Box
          className="header-content"
          onClick={localExpansion !== undefined ? handleLocalExpandClick : handleExpandClick}
        >
          {header}
        </Box>
        <S.Card.Actions className="header-actions">
          {actions}
          <IconButtonStyled
            $expand={localExpansion !== undefined ? expandedLocal : expanded}
            onClick={localExpansion !== undefined ? handleLocalExpandClick : handleExpandClick}
            className="expand-button"
          >
            <ExpandMoreIcon />
          </IconButtonStyled>
        </S.Card.Actions>
      </Box>
      <Collapse in={localExpansion !== undefined ? expandedLocal : expanded} timeout="auto" unmountOnExit>
        <CardContent>{content}</CardContent>
      </Collapse>
    </CardStyled>
  )
}
