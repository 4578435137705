import { valueof } from 'src/@types/global'
import { PROJECT_USER_ROLE_TYPE, USER_ROLES_TYPE } from 'src/shared/constants/constants'
import { NextState } from 'src/shared/constants/workflow-constants'

const nextStatesForUser =
  (nextStates: Array<NextState>) =>
  (roles: Array<USER_ROLES_TYPE>, projectUserRoles: Array<valueof<PROJECT_USER_ROLE_TYPE>>): Array<NextState> => {
    const forProjectRoles = nextStates.filter((s) => s.projectRoles.some((pr) => projectUserRoles.includes(pr)))
    const forGlobalRoles = nextStates.filter((s) => s.userRoles.some((r) => roles.includes(r)))

    return [...forProjectRoles, ...forGlobalRoles]
  }

export const WorkflowPermissionService = {
  nextStatesForUser,
}
