import { Stack, Typography } from '@mui/material'
import { ReactElement, ReactNode } from 'react'
import { APPLICATION_TOOLBAR_HEIGHT } from 'src/shared/layout/ApplicationToolbar'
import { PAGE_HEADER_HEIGHT } from 'src/shared/layout/ScreenLayout'
import { SECOND_LEVEL_NAVIGATION_OFFSET_TOP } from 'src/shared/layout/SecondLevelNavigationContainer'
import { muiTheme } from 'src/theme/theme'
import styled from 'styled-components/macro'

interface Props {
  illustration: ReactNode
  label: string
  inSecondLevelNavigationTab?: boolean
  inEditPage?: boolean
}

const StackStyled = styled(Stack)`
  svg {
    height: 7rem;
  }
`

export const NoAccessDefault = ({
  illustration,
  label,
  inSecondLevelNavigationTab,
  inEditPage,
}: Props): ReactElement => {
  // Used when we show no access info inside tabs from Second Level Navigation
  // Since we are forcing height in vh units, we have to subtract paddings, margins and borders from the parent container
  const secondLevelNavigationHeight = `calc(100vh - ${APPLICATION_TOOLBAR_HEIGHT} - ${PAGE_HEADER_HEIGHT} - ${SECOND_LEVEL_NAVIGATION_OFFSET_TOP} - ${muiTheme.spacing(
    11,
  )} - 1px)`

  // Used when we show NotAuthorized inside edit pages
  const editHeight = `calc(100vh - ${APPLICATION_TOOLBAR_HEIGHT})`

  return (
    <StackStyled
      spacing={3}
      alignItems="center"
      justifyContent="center"
      height={inSecondLevelNavigationTab ? secondLevelNavigationHeight : inEditPage ? editHeight : '100%'}
    >
      {illustration}
      <Typography variant="h3">{label}</Typography>
    </StackStyled>
  )
}
