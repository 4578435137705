import { SupportedLocale } from 'src/@types'
import { USER_ROLES_TYPE } from 'src/shared/constants/constants'
import { proxy, useSnapshot } from 'valtio'

export interface User {
  id: number
  username: string
  email: string
  firstName: string
  lastName: string
  roles: Array<USER_ROLES_TYPE>
  language: SupportedLocale
  anonymous: boolean
  cantonalUserCanton: string | null
}

const getDefaultLanguage = () => {
  const languageSearch = (lang: SupportedLocale) => window.location.search.includes(`?lang=${lang}`)
  if (languageSearch('en')) {
    return 'en'
  } else if (languageSearch('de')) {
    return 'de'
  } else if (languageSearch('fr')) {
    return 'fr'
  } else if (languageSearch('it')) {
    return 'it'
  } else return 'de'
}

const defaultUser: User = {
  id: -1,
  language: getDefaultLanguage(),
  username: '',
  firstName: 'J',
  lastName: 'D',
  email: '',
  roles: [],
  anonymous: true,
  cantonalUserCanton: null,
}

interface Store {
  user: User
}

export const UserStore = proxy<Store>({ user: defaultUser })

export const useUserFromStore = (): User => {
  const userStore = useSnapshot(UserStore)
  return userStore.user as User
}

export const UserStoreActions = {
  setUser(user: User) {
    UserStore.user = user
  },
}
