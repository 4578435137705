import { Box } from '@mui/material'
import { ReactElement, useMemo } from 'react'
import { Form } from 'react-final-form'
import { useMessageSource } from 'src/i18n/useMessageSource'
import { TEXT_LENGTH } from 'src/shared/constants/constants'
import { HtmlEditorField } from 'src/shared/form/control/HtmlEditorField'
import { DirtyFormSpy } from 'src/shared/form/dirty/DirtyFormSpy'
import { maxChar } from 'src/shared/form/validation/validators'

interface Props {
  comment: string | null
  onSave: (values: SummaryCommentFormValues) => Promise<void>
}

export interface SummaryCommentFormValues {
  comment: string | null
}

export const SummaryCommentEditForm = ({ onSave, comment }: Props): ReactElement => {
  const initialValues = useMemo(() => ({ comment }), [comment])
  const { getMessage } = useMessageSource()

  return (
    <Box>
      {initialValues && (
        <Form<SummaryCommentFormValues>
          initialValues={initialValues}
          onSubmit={onSave}
          render={({ handleSubmit }) => {
            return (
              <form id="edit-form" onSubmit={handleSubmit}>
                <HtmlEditorField
                  name="comment"
                  validate={maxChar(TEXT_LENGTH.XL)}
                  label={getMessage('label.summary.comments')}
                  required
                />
                <DirtyFormSpy />
              </form>
            )
          }}
        />
      )}
    </Box>
  )
}
