import { Chip, IconButton } from '@mui/material'
import { Box } from '@mui/system'
import {
  DataGridPro,
  getGridStringOperators,
  GridColDef,
  GridFilterInputValueProps,
  GridFilterItem,
  GridPaginationModel,
  GridRowModel,
  useGridApiRef,
} from '@mui/x-data-grid-pro'
import { ReactElement, useCallback, useEffect, useMemo, useState } from 'react'
import { Mutation_Root, Query_Root, User_Bool_Exp, User_Order_By } from 'src/@types/graphql'
import { useMessageSource } from 'src/i18n/useMessageSource'
import { EditUserModal } from 'src/screens/administration/user-administration/edit-user/EditUserModal'
import { deleteUserByGfch } from 'src/screens/administration/user-administration/edit-user/editUsersQueries'
import { UsersGridToolbar } from 'src/screens/administration/user-administration/UsersGridToolbar'
import {
  GLOBAL_USER_ROLES,
  GLOBAL_USER_ROLES_TRANSLATION_MAP,
  USER_STATUS,
  USER_STATUS_TYPE,
  USER_TYPE,
} from 'src/shared/constants/constants'
import { Option } from 'src/shared/form/control'
import { AutoCompleteFilterInput } from 'src/shared/form/grid/filter/AutoCompleteFilterInput'
import { DeleteIcon, EditIcon } from 'src/shared/icons/Icons'
import { ModalDialog } from 'src/shared/modal-dialog/ModalDialog'
import { S } from 'src/shared/styled/S'
import { TooltipWrapper } from 'src/shared/tooltip/TooltipWrapper'
import { useGridTranslateHook } from 'src/shared/utils/hooks/grid-translate-hook'
import { useNotificationService } from 'src/shared/utils/NotificationService'
import StorageUtils, { STORAGE_KEY } from 'src/shared/utils/StorageUtils'
import { gql, useClient } from 'urql'
import { SecondaryConfirmationModalDialog } from 'src/shared/modal-dialog/SecondaryConfirmationModalDialog'

interface Props {
  canEdit: boolean
  onAddUser: () => void
  isUserAdded: boolean
  setIsUserAdded: (value: boolean) => void
}

const usersForGrid = gql`
  query ($userCondition: user_bool_exp, $offset: Int!, $limit: Int!, $orderBy: [user_order_by!]) {
    user(where: $userCondition, offset: $offset, limit: $limit, order_by: $orderBy) {
      id
      first_name
      last_name
      email
      status
      user_roles {
        role
      }
    }
    user_aggregate(where: $userCondition, order_by: $orderBy) {
      aggregate {
        count
      }
    }
  }
`
const autoCompleteFilterOperator = (options: Option[], label: string, placeholder: string) =>
  getGridStringOperators()
    .filter((operator) => operator.value === 'contains')
    .map((operator) => ({
      ...operator,
      InputComponent: (gridFilterProps: GridFilterInputValueProps) => (
        <AutoCompleteFilterInput {...gridFilterProps} options={options} label={label} placeholder={placeholder} />
      ),
    }))

export const UsersGrid = ({ canEdit, onAddUser, isUserAdded, setIsUserAdded }: Props): ReactElement => {
  const { getMessage } = useMessageSource()
  const gridApiRef = useGridApiRef()
  const gridTranslations = useGridTranslateHook()
  const [openEditUserModal, setOpenEditUserModal] = useState(false)
  const [editingUserId, setEditingUserId] = useState<number | null>(null)
  const [deleteUserConfirmationOpen, setDeleteUserConfirmationOpen] = useState(false)
  const [userEmailToBeDeleted, setUserEmailToBeDeleted] = useState<string>('')
  const [userDeleteLoading, setUserDeleteLoading] = useState<boolean>(false)
  const urqlClient = useClient()
  const [loading, setLoading] = useState(true)
  const notificationService = useNotificationService()
  const [rows, setRows] = useState<GridRowModel[]>([])
  const [rowsCount, setRowsCount] = useState(0)
  const PAGE_SIZE = StorageUtils.get(STORAGE_KEY._USER_STORAGE)?.rowsPerPage ?? 10
  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({ page: 0, pageSize: PAGE_SIZE })

  const columns = useMemo(() => {
    return [
      {
        field: 'firstName',
        headerName: getMessage('label.first.name'),
        flex: 0.8,
        sortable: false,
        filterOperators: getGridStringOperators().filter((x) => x.value === 'contains'),
      },
      {
        field: 'lastName',
        headerName: getMessage('label.last.name'),
        flex: 0.8,
        sortable: false,
        filterOperators: getGridStringOperators().filter((x) => x.value === 'contains'),
      },
      {
        field: 'email',
        headerName: getMessage('label.email'),
        flex: 1,
        sortable: false,
        filterOperators: getGridStringOperators().filter((x) => x.value === 'contains'),
      },
      {
        field: 'permissions',
        headerName: getMessage('label.permissions'),
        sortable: false,
        filterOperators: autoCompleteFilterOperator(
          Object.keys(GLOBAL_USER_ROLES).map((x) => ({
            label: getMessage(
              GLOBAL_USER_ROLES_TRANSLATION_MAP.filter((translation) => translation.role === x).map(
                (translation) => translation.messageKey,
              )[0],
            ),
            value: x,
          })),
          getMessage('label.grid.value'),
          getMessage('label.grid.filter.value'),
        ),
        renderCell: (params: any) => {
          const roles = params.value
            .map((role: any) => {
              const roleMessageKey = GLOBAL_USER_ROLES_TRANSLATION_MAP.filter(
                (translation) => translation.role === role,
              ).map((translation) => translation.messageKey)
              return getMessage(roleMessageKey[0])
            })
            .join(', ')
          return <S.Span.TextOverflow>{roles}</S.Span.TextOverflow>
        },
        flex: 1,
      },
      {
        field: 'status',
        headerName: getMessage('label.status'),
        sortable: false,
        flex: 0.5,
        filterOperators: autoCompleteFilterOperator(
          Object.keys(USER_STATUS).map((x) => ({
            label: getMessage(`label.${x.toLowerCase()}`),
            value: x,
          })),
          getMessage('label.grid.value'),
          getMessage('label.grid.filter.value'),
        ),
        renderCell: (params: any) => {
          const resolvedColor = (status: USER_STATUS_TYPE) => {
            switch (status) {
              case USER_STATUS.ACTIVE:
                return 'success'
              case USER_STATUS.PENDING:
                return 'primary'
              case USER_STATUS.INACTIVE:
                return 'default'
            }
          }

          return <Chip label={getMessage(`label.${params.value.toLowerCase()}`)} color={resolvedColor(params.value)} />
        },
      },
      {
        field: 'action',
        headerName: getMessage('label.actions'),
        headerAlign: 'right',
        align: 'right',
        sortable: false,
        filterable: false,
        flex: 0.4,
        renderCell: (params: any) => {
          const onClick = (e: any) => {
            e.stopPropagation() // don't select this row after clicking

            setEditingUserId(params.value.userId)
            setOpenEditUserModal(true)
          }

          const onClickDelete = async (e: any) => {
            e.stopPropagation() // don't select this row after clicking
            setUserEmailToBeDeleted(params.row.email)
            setDeleteUserConfirmationOpen(true)
          }

          return (
            canEdit && (
              <>
                <TooltipWrapper
                  title={getMessage('tooltip.actions.edit.pending-user')}
                  condition={params.row.status === USER_STATUS.PENDING}
                >
                  <span>
                    <IconButton onClick={onClick} color="primary" disabled={params.row.status === USER_STATUS.PENDING}>
                      <EditIcon />
                    </IconButton>
                  </span>
                </TooltipWrapper>
                <span>
                  <IconButton onClick={onClickDelete} color="primary">
                    <DeleteIcon />
                  </IconButton>
                </span>
              </>
            )
          )
        },
      },
    ] as GridColDef[]
  }, [getMessage, canEdit])

  const fetchRows = useCallback(
    async (rowsPerPage = PAGE_SIZE, items?: GridFilterItem[], pageParams?: number) => {
      setLoading(true)
      try {
        const userCondition = {
          _and: [],
          type: { _in: [USER_TYPE.EXTERNAL, USER_TYPE.GFCH, USER_TYPE.EXTERNAL_EXPERT, USER_TYPE.CANTON] },
        } as User_Bool_Exp
        if (items) {
          const groupedMap = items?.reduce(
            (entryMap, e) => entryMap.set(e.field, [...(entryMap.get(e.field) || []), e]),
            new Map(),
          )
          groupedMap.forEach((columnFilters: GridFilterItem[], column: string) => {
            if (column === 'firstName') {
              const firstName = columnFilters
                .filter((f) => f.value)
                .map((f) => {
                  return {
                    first_name: { _ilike: `%${f.value}%` },
                  }
                })

              if (firstName?.length) {
                userCondition._and?.push({ _or: firstName })
              }
            } else if (column === 'lastName') {
              const lastName = columnFilters
                .filter((f) => f.value)
                .map((f) => {
                  return {
                    last_name: { _ilike: `%${f.value}%` },
                  }
                })
              if (lastName?.length) {
                userCondition._and?.push({ _or: lastName })
              }
            } else if (column === 'email') {
              const email = columnFilters
                .filter((f) => f.value)
                .map((f) => {
                  return {
                    email: { _ilike: `%${f.value}%` },
                  }
                })
              if (email?.length) {
                userCondition._and?.push({ _or: email })
              }
            } else if (column === 'permissions') {
              const permissions = columnFilters.filter((f) => f.value).map((f) => f.value)
              if (permissions?.length) {
                userCondition._and?.push({ user_roles: { role: { _in: permissions } } })
              }
            } else if (column === 'status') {
              const status = columnFilters.filter((f) => f.value).map((f) => f.value)
              if (status?.length) {
                userCondition._and?.push({ status: { _in: status } })
              }
            }
          })
        }
        if (pageParams === undefined) {
          pageParams = 0
        }
        const offset = pageParams * rowsPerPage
        const limit = rowsPerPage

        const orderBy = [
          {
            first_name: 'asc',
          },
        ] as Array<User_Order_By>

        const { data } = await urqlClient
          .query<
            {
              user: Query_Root['user']
              user_aggregate: Query_Root['user_aggregate']
            },
            {
              userCondition: User_Bool_Exp | unknown
              offset: number
              limit: number
              orderBy: User_Order_By | User_Order_By[]
            }
          >(usersForGrid, {
            userCondition: userCondition,
            offset,
            limit,
            orderBy: orderBy,
          })
          .toPromise()

        const fetchedRows = data!.user.map((u) => ({
          id: u.id,
          firstName: u.first_name ?? getMessage('label.not.available'),
          lastName: u.last_name ?? getMessage('label.not.available'),
          email: u.email,
          permissions: u.user_roles.map((role) => role.role),
          status: u.status,
          action: {
            userId: u.id,
          },
        }))
        const rowsCount = data!.user_aggregate.aggregate?.count ?? 0
        setRows(fetchedRows)
        setRowsCount(rowsCount)
      } catch (e) {
        notificationService.operationFailed()
      }
      setLoading(false)
    },
    [PAGE_SIZE, getMessage, notificationService, urqlClient],
  )

  const onGridChange = useCallback(
    async (reason?: string) => {
      if (gridApiRef?.current) {
        const filtering = gridApiRef.current.state.filter.filterModel

        const page = gridApiRef.current.state.pagination.paginationModel.page
        const rowsPerPage = gridApiRef.current.state.pagination.paginationModel.pageSize
        setPaginationModel({ page: page, pageSize: rowsPerPage })

        if (reason !== 'restoreState') {
          StorageUtils.store(STORAGE_KEY._USER_STORAGE, { filtering, rowsPerPage })
        }

        await fetchRows(rowsPerPage, filtering.items, page)
      }
    },
    [fetchRows, gridApiRef],
  )

  const applyFilters = useCallback(
    async (savedParams: any) => {
      const filtering = savedParams?.filtering
      const rowsPerPage = savedParams?.rowsPerPage ?? PAGE_SIZE
      await fetchRows(rowsPerPage, filtering.items, 0)
      gridApiRef.current.setFilterModel(filtering, 'restoreState')
      gridApiRef.current.setPageSize(rowsPerPage)
    },
    [fetchRows, gridApiRef, PAGE_SIZE],
  )

  useEffect(() => {
    const savedParams = StorageUtils.get(STORAGE_KEY._USER_STORAGE)
    if (savedParams) {
      applyFilters(savedParams)
      return
    }
    fetchRows()
  }, [fetchRows, applyFilters])

  useEffect(() => {
    if (isUserAdded) {
      const savedParams = StorageUtils.get(STORAGE_KEY._USER_STORAGE)
      applyFilters(savedParams)
      setIsUserAdded(false)
    }
  }, [applyFilters, setIsUserAdded, isUserAdded])

  const closeEditUserModal = () => {
    setEditingUserId(null)
    setOpenEditUserModal(false)
  }

  const onEditUserSuccess = () => {
    applyFilters(StorageUtils.get(STORAGE_KEY._USER_STORAGE))
    closeEditUserModal()
  }

  const handleCancelDeleteUser = () => {
    setDeleteUserConfirmationOpen(false)
  }

  const handleDeleteUserConfirmed = async () => {
    setUserDeleteLoading(true)
    const { data } = await urqlClient
      .mutation<
        { deleteUserByGfch: Mutation_Root['deleteUserByGfch'] },
        {
          email: string
        }
      >(deleteUserByGfch, {
        email: userEmailToBeDeleted,
      })
      .toPromise()

    if (data) {
      const status = data.deleteUserByGfch.status
      const failureKey = data.deleteUserByGfch.failureKey

      if (status === 'SUCCESS') {
        setDeleteUserConfirmationOpen(false)
        setUserDeleteLoading(false)
        notificationService.deleteSuccessful()
        await fetchRows()
      } else {
        if (failureKey) {
          notificationService.error(getMessage(failureKey))
        } else {
          notificationService.error(getMessage('notification.user.update.failure'))
        }
      }
      setUserDeleteLoading(false)
    } else {
      notificationService.error(getMessage('notification.user.update.failure'))
      setUserDeleteLoading(false)
    }
  }

  return (
    <>
      <Box>
        <DataGridPro
          initialState={{
            pagination: {
              paginationModel: paginationModel,
            },
          }}
          rows={rows}
          rowCount={rowsCount}
          columns={columns}
          autoHeight
          loading={loading}
          apiRef={gridApiRef}
          localeText={gridTranslations}
          disableColumnPinning
          disableRowSelectionOnClick
          pagination
          pageSizeOptions={[10, 25, 50]}
          filterMode="server"
          paginationMode="server"
          sortingMode="server"
          onPaginationModelChange={() => onGridChange()}
          onSortModelChange={(_, { reason }) => onGridChange(reason)}
          onFilterModelChange={(_, { reason }) => onGridChange(reason)}
          slots={{
            toolbar: () => <UsersGridToolbar addNewUser={onAddUser} />,
          }}
        />
      </Box>
      <ModalDialog
        open={openEditUserModal}
        title={getMessage('label.edit.user')}
        maxWidth="sm"
        withCloseIcon={false}
        onClose={closeEditUserModal}
      >
        <EditUserModal onCancel={closeEditUserModal} onSuccess={onEditUserSuccess} userId={editingUserId} />
      </ModalDialog>
      <SecondaryConfirmationModalDialog
        open={deleteUserConfirmationOpen}
        onCancel={handleCancelDeleteUser}
        onConfirm={handleDeleteUserConfirmed}
        loading={userDeleteLoading}
        buttonColor="error"
      >
        {getMessage('label.delete.confirm')}
      </SecondaryConfirmationModalDialog>
    </>
  )
}
