import { Stack } from '@mui/material'
import { ReactElement } from 'react'
import { Form } from 'react-final-form'
import { Mutation_Root } from 'src/@types/graphql'
import { useMessageSource } from 'src/i18n/useMessageSource'
import { updateMilestoneNotesMutation } from 'src/screens/shared/implementation/details/milestoneQueries'
import { SaveAndBackButton, SaveButton } from 'src/shared/button/Buttons'
import { PROJECT_TYPE, TEXT_LENGTH } from 'src/shared/constants/constants'
import { MILESTONE_TYPE_TYPE } from 'src/shared/constants/milestone-constants'
import { HtmlEditorField } from 'src/shared/form/control/HtmlEditorField'
import { DirtyFormSpy } from 'src/shared/form/dirty/DirtyFormSpy'
import { composeValidators, maxChar, required } from 'src/shared/form/validation/validators'
import { PageLayout } from 'src/shared/layout/PageLayout'
import { ScreenLayout } from 'src/shared/layout/ScreenLayout'
import { HelpAndInstructions } from 'src/shared/presentation/HelpAndInstructions'
import { Section } from 'src/shared/presentation/Section'
import { useNotificationService } from 'src/shared/utils/NotificationService'
import { useClient } from 'urql'

export type originType = 'SAVE' | 'SAVE_AND_BACK'

export interface MilestoneDetailsFormValues {
  notes: string
  type: string
  status: string
  responsibleType: string
}

interface Props {
  loading: boolean
  process: PROJECT_TYPE
  milestoneId: number
  milestoneType: MILESTONE_TYPE_TYPE
  requiredNotes: boolean
  initialValues: MilestoneDetailsFormValues | undefined
  setInitialValues: (v: MilestoneDetailsFormValues) => void
  onBack: () => void
  originRef: React.MutableRefObject<originType>
  screenTitle?: string
  fieldLabel?: string
  helpLabel?: string
}

export const MilestoneDetailsEditForm = ({
  loading,
  process,
  milestoneId,
  milestoneType,
  requiredNotes,
  initialValues,
  setInitialValues,
  onBack,
  originRef,
  screenTitle = 'label.milestone.notes.edit',
  fieldLabel = 'label.notes',
  helpLabel = `label.help.milestone.notes.${milestoneType}.${process}`,
}: Props): ReactElement => {
  const { getMessage } = useMessageSource()
  const notificationService = useNotificationService()
  const urqlClient = useClient()

  const onSubmit = (origin: originType) => (_: any) => {
    // trigger submit event
    if (originRef) {
      originRef.current = origin
    }
    document
      .getElementById('milestone-note-edit-form')
      ?.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }))
  }

  const onSubmitLocal = async (values: MilestoneDetailsFormValues) => {
    const { data } = await urqlClient
      .mutation<{ update_milestone: Mutation_Root['update_milestone'] }, { id: number; notes: string }>(
        updateMilestoneNotesMutation,
        {
          id: milestoneId,
          notes: values.notes ?? '',
        },
      )
      .toPromise()

    if (data && data?.update_milestone?.affected_rows === 1) {
      setInitialValues(values)
      notificationService.changesSaved()
      originRef.current === 'SAVE_AND_BACK' && onBack()
    } else {
      notificationService.operationFailed()
    }
  }

  return (
    <ScreenLayout
      title={getMessage(screenTitle)}
      onBack={onBack}
      hasSecondLevelNavigation={false}
      actions={
        <>
          <SaveAndBackButton origin="header" onClick={onSubmit('SAVE_AND_BACK')} />
          <SaveButton origin="header" onClick={onSubmit('SAVE')} />
        </>
      }
    >
      <PageLayout>
        <>
          {!loading && initialValues && (
            <Section helpAndInstructions={<HelpAndInstructions labelKey={helpLabel} defaultExpansion />}>
              <Form<MilestoneDetailsFormValues>
                initialValues={initialValues}
                onSubmit={onSubmitLocal}
                render={({ handleSubmit }) => {
                  return (
                    <form onSubmit={handleSubmit} noValidate id="milestone-note-edit-form">
                      <Stack spacing={2}>
                        <HtmlEditorField
                          label={getMessage(fieldLabel)}
                          required={requiredNotes}
                          name="notes"
                          validate={
                            requiredNotes
                              ? composeValidators(required(), maxChar(TEXT_LENGTH.XL))
                              : maxChar(TEXT_LENGTH.XL)
                          }
                        />
                        <DirtyFormSpy />
                      </Stack>
                    </form>
                  )
                }}
              />
            </Section>
          )}
        </>
      </PageLayout>
    </ScreenLayout>
  )
}
