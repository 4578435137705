import { useParams } from 'react-router'
import { ROUTES } from 'src/routing/routes'
import { ProjectJournalCreate } from 'src/screens/shared/project/journal/edit/ProjectJournalCreate'
import { PROJECT } from 'src/shared/constants/constants'
import { useDelayedNavigate } from 'src/shared/utils/hooks/navigation-hooks'

export const PfKapProjectJournalCreatePage = () => {
  const { projectId } = useParams()

  const navigate = useDelayedNavigate()

  const onBack = (journalId?: number) => {
    const route = ROUTES.PfKapProjectJournal.params({ projectId })

    if (journalId) {
      navigate(`${route}#${journalId}`, { state: { expandedJournalId: journalId } })
    } else {
      navigate(route)
    }
  }

  return <ProjectJournalCreate projectType={PROJECT.PF_KAP} onBack={onBack} />
}
