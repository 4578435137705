import { useCallback, useEffect, useState } from 'react'
import { useNotificationService } from 'src/shared/utils/NotificationService'

export const useError = () => {
  const [error, setErrorLocal] = useState(false)
  const notificationService = useNotificationService()

  useEffect(() => {
    if (error) {
      notificationService.operationFailed()
      setErrorLocal(false)
    }
  }, [error, notificationService])

  const setError = useCallback(() => {
    setErrorLocal(true)
  }, [setErrorLocal])

  return setError
}
