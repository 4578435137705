import { Stack } from '@mui/material'
import Box from '@mui/material/Box'
import combinedQuery, { CombinedQueryBuilder } from 'graphql-combine-query'
import { useEffect, useState } from 'react'
import { Form } from 'react-final-form'
import { useParams } from 'react-router'
import { Maybe, Milestone_Qualitative_Evaluation, Mutation_Root, Query_Root } from 'src/@types/graphql'
import { useMessageSource } from 'src/i18n/useMessageSource'
import { resolveFinalReportMilestoneRoot } from 'src/routing/routing-utils'
import {
  fetchQualitativeEvaluationByMilestoneQuery,
  queryMilestoneById,
  upsertMilestoneQualitativeEvaluationQuery,
} from 'src/screens/shared/implementation/details/milestoneQueries'
import { usePermissionsForMilestones } from 'src/service/security/PermissionHook'
import { SaveAndBackButton, SaveButton } from 'src/shared/button/Buttons'
import { TEXT_LENGTH } from 'src/shared/constants/constants'
import { MILESTONE_RESPONSIBLE_TYPE_TYPE, MILESTONE_STATUS_TYPE } from 'src/shared/constants/milestone-constants'
import { HtmlEditorField } from 'src/shared/form/control/HtmlEditorField'
import { DirtyFormSpy } from 'src/shared/form/dirty/DirtyFormSpy'
import { createDecorators } from 'src/shared/form/utils/decorators'
import { maxChar } from 'src/shared/form/validation/validators'
import { PageLayout } from 'src/shared/layout/PageLayout'
import { ScreenLayout } from 'src/shared/layout/ScreenLayout'
import { NotAuthorized } from 'src/shared/not-authorized/NotAuthorized'
import { HelpAndInstructions } from 'src/shared/presentation/HelpAndInstructions'
import { useDelayedNavigate } from 'src/shared/utils/hooks/navigation-hooks'
import { useNotificationService } from 'src/shared/utils/NotificationService'
import { Utils } from 'src/shared/utils/Utils'
import { useClient } from 'urql'

interface Props {
  baseUrl: '/pf-kap' | '/pf-pgv'
  singleField?: keyof Pick<
    Milestone_Qualitative_Evaluation,
    'equal_opportunity' | 'sustainability' | 'multiplication_potential'
  >
  screenLayoutTextLabel: string
  helpAndInstructionsTextLabel: string
}

interface EditForm {
  strength?: Maybe<string>
  weakness?: Maybe<string>
  opportunities?: Maybe<string>
  threats?: Maybe<string>
  equal_opportunity?: Maybe<string>
  sustainability?: Maybe<string>
  multiplication_potential?: Maybe<string>
}

const formValuesFactory = (obj?: Milestone_Qualitative_Evaluation) => {
  return {
    strength: obj?.strength,
    weakness: obj?.weakness,
    opportunities: obj?.opportunities,
    threats: obj?.threats,
    equal_opportunity: obj?.equal_opportunity,
    sustainability: obj?.sustainability,
    multiplication_potential: obj?.multiplication_potential,
  }
}

export const QualitativeEvaluationBaseEdit = ({
  baseUrl,
  singleField,
  screenLayoutTextLabel,
  helpAndInstructionsTextLabel,
}: Props) => {
  const onUpsertConflictUpdateColumnsFactory = (): Array<keyof Milestone_Qualitative_Evaluation> => {
    if (singleField) {
      return [singleField]
    } else {
      return ['strength', 'weakness', 'opportunities', 'threats']
    }
  }
  const factoryMutationObject = (values: EditForm) => {
    if (singleField) {
      return {
        [singleField]: values[singleField],
      }
    } else {
      const { strength, weakness, opportunities, threats } = values

      return {
        strength,
        weakness,
        opportunities,
        threats,
      }
    }
  }

  const { projectId, milestoneId, milestonePath } = useParams()
  const { getMessage } = useMessageSource()

  const decorators = createDecorators()
  const notificationService = useNotificationService()
  const navigate = useDelayedNavigate()
  const urqlClient = useClient()
  const process = Utils.resolveProcess(baseUrl)

  const project_id = parseInt(projectId as string)
  const milestone_id = parseInt(milestoneId as string)

  const [formValues, setFormValues] = useState<EditForm>()
  const [milestoneData, setMilestoneData] = useState<{
    status: MILESTONE_STATUS_TYPE
    responsible_type: MILESTONE_RESPONSIBLE_TYPE_TYPE
  }>()

  useEffect(() => {
    const fetchData = async () => {
      let queryCombined = combinedQuery('FetchMilestoneAndQualitativeEvaluation') as unknown as CombinedQueryBuilder
      queryCombined = queryCombined
        .add<
          { milestone_by_pk: Query_Root['milestone_by_pk'] },
          {
            id: number
          }
        >(queryMilestoneById, {
          id: milestone_id,
        })
        .add<
          { milestone_qualitative_evaluation: Query_Root['milestone_qualitative_evaluation'] },
          {
            milestoneId: number
          }
        >(fetchQualitativeEvaluationByMilestoneQuery, {
          milestoneId: milestone_id,
        })

      const { document, variables } = queryCombined

      const { data, error } = await urqlClient
        .query<{
          milestone_by_pk: Query_Root['milestone_by_pk']
          milestone_qualitative_evaluation: Query_Root['milestone_qualitative_evaluation']
        }>(document, variables)
        .toPromise()

      if (error) {
        notificationService.operationFailed()
      }

      const values = formValuesFactory(data?.milestone_qualitative_evaluation[0])

      setFormValues(values)
      setMilestoneData({
        status: data?.milestone_by_pk?.status as MILESTONE_STATUS_TYPE,
        responsible_type: data?.milestone_by_pk?.responsible_type as MILESTONE_RESPONSIBLE_TYPE_TYPE,
      })
    }

    fetchData()
  }, [urqlClient, notificationService, milestone_id])

  const { loading, canEdit } = usePermissionsForMilestones(
    process,
    project_id,
    milestoneData?.status as MILESTONE_STATUS_TYPE,
    milestoneData?.responsible_type as MILESTONE_RESPONSIBLE_TYPE_TYPE,
  )

  let submit: any = () => {}
  const onSave = (event: any) => submit(event)
  const onSaveAndBack = async (event: any) => {
    try {
      await submit(event)
      onBack()
    } catch {
      // do nothing
    }
  }

  const onSubmitLocal = async (values: EditForm) => {
    const mutationObject = factoryMutationObject(values)

    const onUpsertConflictUpdateColumns = onUpsertConflictUpdateColumnsFactory()

    const { data } = await urqlClient
      .mutation<{
        insert_milestone_qualitative_evaluation_one: Mutation_Root['insert_milestone_qualitative_evaluation_one']
      }>(upsertMilestoneQualitativeEvaluationQuery, {
        object: {
          milestone_id: milestone_id,
          ...mutationObject,
        },
        onConflictUpdateColumns: onUpsertConflictUpdateColumns,
      })
      .toPromise()

    if (data && data.insert_milestone_qualitative_evaluation_one) {
      setFormValues(values)
      notificationService.changesSaved()
    } else {
      notificationService.operationFailed()
    }
  }

  const onBack = () => {
    const route = resolveFinalReportMilestoneRoot(baseUrl).nested.QualitativeEvaluation.params({
      projectId,
      milestoneId,
      milestonePath,
    })

    const sectionId = singleField ? singleField.replaceAll('_', '-') : 'swot-analysis'

    navigate(`${route}#${sectionId}`)
  }

  if (!loading && !canEdit && formValues) {
    return <NotAuthorized inEditPage />
  }

  return (
    <ScreenLayout
      title={getMessage(screenLayoutTextLabel)}
      onBack={onBack}
      hasSecondLevelNavigation={false}
      actions={
        <>
          <SaveAndBackButton origin="header" onClick={onSaveAndBack} />
          <SaveButton origin="header" onClick={onSave} />
        </>
      }
    >
      <PageLayout>
        <>
          {!loading && formValues && (
            <>
              <HelpAndInstructions labelKey={helpAndInstructionsTextLabel} defaultExpansion />
              <Box>
                {formValues && (
                  <Form<EditForm>
                    initialValues={formValues}
                    onSubmit={onSubmitLocal}
                    decorators={decorators}
                    render={({ handleSubmit }) => {
                      submit = handleSubmit
                      return (
                        <form id="edit-form" onSubmit={handleSubmit}>
                          {singleField && (
                            <Stack spacing={2}>
                              <HtmlEditorField
                                label={getMessage(`label.background.information.${singleField.replaceAll('_', '.')}`)}
                                name={`${singleField}`}
                                validate={maxChar(TEXT_LENGTH.XL)}
                              />
                            </Stack>
                          )}
                          {!singleField && (
                            <Stack spacing={2}>
                              <HtmlEditorField
                                label={getMessage('label.milestone.qualitative.evaluation.strength')}
                                name="strength"
                                validate={maxChar(TEXT_LENGTH.L)}
                              />
                              <HtmlEditorField
                                label={getMessage('label.milestone.qualitative.evaluation.weakness')}
                                name="weakness"
                                validate={maxChar(TEXT_LENGTH.L)}
                              />
                              <HtmlEditorField
                                label={getMessage('label.milestone.qualitative.evaluation.opportunities')}
                                name="opportunities"
                                validate={maxChar(TEXT_LENGTH.L)}
                              />
                              <HtmlEditorField
                                label={getMessage('label.milestone.qualitative.evaluation.threats')}
                                name="threats"
                                validate={maxChar(TEXT_LENGTH.L)}
                              />
                            </Stack>
                          )}
                          <DirtyFormSpy />
                        </form>
                      )
                    }}
                  />
                )}
              </Box>
            </>
          )}
        </>
      </PageLayout>
    </ScreenLayout>
  )
}
