import { ReactElement, useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router'
import { Mutation_Root, Query_Root } from 'src/@types/graphql'
import { useMessageSource } from 'src/i18n/useMessageSource'
import { ROUTES } from 'src/routing/routes'
import {
  initialValuesFactory,
  KapGoalForm,
  KapGoalFormValues,
} from 'src/screens/kap/program/details/goals/edit/KapGoalForm'
import { fetchKapGoalByIdQuery, updateKapGoalMutation } from 'src/screens/kap/program/details/goals/kapGoalQueries'
import { usePermissionsForKapProgram } from 'src/service/security/PermissionHook'
import { SaveAndBackButton, SaveButton } from 'src/shared/button/Buttons'
import { PageLayout } from 'src/shared/layout/PageLayout'
import { ScreenLayout } from 'src/shared/layout/ScreenLayout'
import { NotAuthorized } from 'src/shared/not-authorized/NotAuthorized'
import { HelpAndInstructions } from 'src/shared/presentation/HelpAndInstructions'
import { useDelayedNavigate } from 'src/shared/utils/hooks/navigation-hooks'
import { useError } from 'src/shared/utils/hooks/page-loading-error-hook'
import { HtmlSanitizer } from 'src/shared/utils/HtmlSanitizer'
import { useNotificationService } from 'src/shared/utils/NotificationService'
import { useClient } from 'urql'

type originType = 'SAVE' | 'SAVE_AND_BACK'

export const KapGoalEditPage = (): ReactElement => {
  const { programId, goalId } = useParams()
  const program_id = parseInt(programId as string)
  const goal_id = parseInt(goalId as string)
  const { getMessage } = useMessageSource()
  const navigate = useDelayedNavigate()
  const { canEdit, loading } = usePermissionsForKapProgram(program_id)
  const originRef = useRef<originType>('SAVE')
  const urqlClient = useClient()
  const [initialValues, setInitialValues] = useState<KapGoalFormValues | null>(null)
  const notificationService = useNotificationService()
  const setError = useError()

  useEffect(() => {
    const initializeFormValues = async () => {
      if (goalId) {
        const { data } = await urqlClient
          .query<{ kap_goal: Query_Root['kap_goal'] }, { id: number }>(fetchKapGoalByIdQuery, { id: goal_id })
          .toPromise()

        if (data && data.kap_goal) {
          const goal = data.kap_goal?.[0]
          const initialValues = initialValuesFactory(program_id, goal)
          setInitialValues(initialValues)
        } else {
          setError()
        }
      }
    }
    initializeFormValues()
  }, [goal_id, goalId, program_id, urqlClient, setError])

  const onSubmit = (origin: originType) => (_: any) => {
    // trigger submit event
    if (originRef) {
      originRef.current = origin
    }
    document.getElementById('goal-form')?.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }))
  }

  const onUpdateHandler = async (values: any) => {
    const measures = values.measureIds?.map((measureId: number) => ({
      kap_goal_id: values.goalId,
      kap_measure_id: measureId,
    }))

    const updateObject = {
      id: values.goalId,
      name: values.name,
      description: HtmlSanitizer.sanitize(values.description),
      measuresToBeAdded: measures,
    }

    const { error, data } = await urqlClient
      .mutation<{
        update_kap_goal: Mutation_Root['update_kap_goal']
        delete_kap_goal_measure: Mutation_Root['delete_kap_goal_measure']
        insert_kap_goal_measure: Mutation_Root['insert_kap_goal_measure']
      }>(updateKapGoalMutation, updateObject)
      .toPromise()

    if (error || data?.update_kap_goal?.affected_rows !== 1) {
      notificationService.operationFailed()
    } else {
      setInitialValues(values)
      notificationService.changesSaved()
      if (originRef.current === 'SAVE_AND_BACK') {
        onBack()
      }
    }
  }

  const onBack = () => {
    const route = ROUTES.KapDetailsRoot.nested.Goals.params({ programId })
    navigate(`${route}#${goalId}`)
  }

  if (!loading && !canEdit) {
    return <NotAuthorized inEditPage />
  }

  return (
    <ScreenLayout
      hasSecondLevelNavigation={false}
      title={getMessage('label.goal.title.edit')}
      onBack={onBack}
      actions={
        <>
          <SaveAndBackButton origin="header" onClick={onSubmit('SAVE_AND_BACK')} />
          <SaveButton origin="header" onClick={onSubmit('SAVE')} />
        </>
      }
    >
      <PageLayout>
        <>
          {!loading && initialValues && (
            <>
              <HelpAndInstructions labelKey="label.help.kap.program.goals" defaultExpansion />
              <KapGoalForm initialValues={initialValues} onSave={onUpdateHandler} />
            </>
          )}
        </>
      </PageLayout>
    </ScreenLayout>
  )
}
