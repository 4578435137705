import { Card, CardContent, Chip, IconButton, ListItem, ListItemIcon, ListItemText, Typography } from '@mui/material'
import { sortBy } from 'lodash'
import { ReactElement, useMemo, useState } from 'react'
import { useParams } from 'react-router'
import { Kap_Goal_Measure, Mutation_Root, Query_Root } from 'src/@types/graphql'
import { useMessageSource } from 'src/i18n/useMessageSource'
import { ROUTES } from 'src/routing/routes'
import {
  fetchKapMeasureByIdWithFactsheetQuery,
  fetchKapMeasureShortTitleAndLevelByIdQuery,
  updateKapMeasureFactsheetIdMutation,
} from 'src/screens/kap/program/details/measures/kapMeasuresQueries'
import { KapLinkRelatedFactsheetModalDialog } from 'src/screens/kap/program/details/measures/link-related-factsheet/KapLinkRelatedFactsheetModalDialog'
import { usePermissionsForKapProgram } from 'src/service/security/PermissionHook'
import { BaseButton, EditButton } from 'src/shared/button/Buttons'
import { LEVEL_TYPE } from 'src/shared/constants/constants'
import { TYPOGRAPHY_MIXIN } from 'src/shared/constants/styling-constants'
import { DeleteIcon, LinkIcon } from 'src/shared/icons/Icons'
import { PageLayout } from 'src/shared/layout/PageLayout'
import { ScreenLayout } from 'src/shared/layout/ScreenLayout'
import { ConfirmationModalDialog } from 'src/shared/modal-dialog/ConfirmationModalDialog'
import { ModalDialog } from 'src/shared/modal-dialog/ModalDialog'
import { DefaultSectionTypography } from 'src/shared/presentation/DefaultSectionTypography'
import { HelpAndInstructions } from 'src/shared/presentation/HelpAndInstructions'
import { HtmlRenderer } from 'src/shared/presentation/HtmlRenderer'
import { ReadOnlySelection, ReadOnlyTextField } from 'src/shared/presentation/ReadOnly'
import { Section } from 'src/shared/presentation/Section'
import { S } from 'src/shared/styled/S'
import { useModalCancel } from 'src/shared/utils/hooks/modal-hooks'
import { useDelayedNavigate } from 'src/shared/utils/hooks/navigation-hooks'
import { useNotificationService } from 'src/shared/utils/NotificationService'
import { useUserLocale } from 'src/user/UserContext'
import styled from 'styled-components/macro'
import { useClient, useQuery } from 'urql'

const IdChip = styled(Chip)`
  cursor: pointer;
  min-width: 3rem;
  &.MuiChip-filledDefault {
    background-color: ${({ theme }) => theme.colors.action.hover};
    color: ${({ theme }) => theme.colors.text.disabled};
  }
  & .MuiChip-label {
    ${TYPOGRAPHY_MIXIN.subtitle2};
  }
`

export const KapMeasureDescriptionPage = (): ReactElement => {
  const { measureId, programId } = useParams()
  const measure_id = parseInt(measureId as string)
  const program_id = parseInt(programId as string)

  const locale = useUserLocale()
  const notificationService = useNotificationService()
  const navigate = useDelayedNavigate()
  const urqlClient = useClient()
  const { getMessage } = useMessageSource()

  const [linkFactsheetModalOpen, setLinkFactsheetModalOpen] = useState(false)
  const [unlinkFactsheetModalOpen, setUnlinkFactsheetsModalOpen] = useState(false)

  const { canEditMeasures, loading } = usePermissionsForKapProgram(program_id)

  const [{ data, error }, refetch] = useQuery<{ kap_measure_by_pk: Query_Root['kap_measure_by_pk'] }>({
    query: fetchKapMeasureByIdWithFactsheetQuery,
    variables: {
      id: measure_id,
      locale: locale,
    },
  })

  const [{ data: measureShortTitleData, error: measureShortTitleError }] = useQuery({
    query: fetchKapMeasureShortTitleAndLevelByIdQuery,
    variables: { measureId: measureId },
  })

  if (error || measureShortTitleError) {
    notificationService.operationFailed()
  }

  const kapMeasure = data?.kap_measure_by_pk
  const pageTitle = measureShortTitleData
    ? `${getMessage('label.measure')}: ${measureShortTitleData.kap_measure_by_pk?.short_title}`
    : ''

  const sortedGoalsLinked: Kap_Goal_Measure[] = useMemo(
    () => sortBy(kapMeasure?.kap_goal_measures, ['kap_measure.level', 'kap_measure.sort_number']),
    [kapMeasure?.kap_goal_measures],
  )

  const handleEdit = () => {
    navigate(ROUTES.KapMeasureDetailsRoot.nested.DescriptionEdit.params({ programId, measureId }))
  }

  const onBack = () => {
    const route = ROUTES.KapDetailsRoot.nested.Measures.params({ programId })
    navigate(`${route}#${measure_id}`)
  }

  const resolveIdCharacter = (level: LEVEL_TYPE) => {
    return getMessage(`label.kap.measure.id.character.${level}`)
  }

  const relatedFactsheetClickHandler = (factsheetId: number) => {
    window.open(ROUTES.FactsheetDescriptionRoot.nested.FactsheetDescription.params({ factsheetId }))
  }

  const handleLinkFactsheet = () => setLinkFactsheetModalOpen(true)
  const onLinkFactsheetCancel = useModalCancel(() => setLinkFactsheetModalOpen(false))
  const onLinkFactsheetSuccess = () => {
    setLinkFactsheetModalOpen(false)
    refetch()
  }

  const handleUnlinkFactsheet = () => setUnlinkFactsheetsModalOpen(true)
  const onUnlinkFactsheetCancel = () => setUnlinkFactsheetsModalOpen(false)
  const onUnlinkFactsheetSuccess = async () => {
    setUnlinkFactsheetsModalOpen(false)
    const { data, error } = await urqlClient
      .mutation<{
        update_kap_measure: Mutation_Root['update_kap_measure']
      }>(updateKapMeasureFactsheetIdMutation, {
        measureId: measure_id,
      })
      .toPromise()

    if (error || data?.update_kap_measure?.affected_rows !== 1) {
      notificationService.operationFailed()
    } else {
      notificationService.changesSaved()
      refetch()
    }
  }

  return (
    <>
      <ScreenLayout title={pageTitle} onBack={onBack}>
        <PageLayout>
          <>
            {!loading && kapMeasure && (
              <>
                <Section
                  id="measure-information"
                  title={getMessage('label.kap.measure.information')}
                  actionButton={<EditButton onClick={handleEdit} hidden={!canEditMeasures} />}
                  helpAndInstructions={<HelpAndInstructions labelKey="label.help.kap.measure.information" />}
                >
                  <Card>
                    <CardContent>
                      <ReadOnlyTextField text={getMessage('label.measure.id')}>
                        {resolveIdCharacter(kapMeasure.level as LEVEL_TYPE)}
                        {kapMeasure.sort_number}
                      </ReadOnlyTextField>
                      <ReadOnlyTextField text={getMessage('label.title')}>{kapMeasure.name}</ReadOnlyTextField>
                      <ReadOnlyTextField text={getMessage('label.short.title')}>
                        {kapMeasure.short_title}
                      </ReadOnlyTextField>
                      <ReadOnlyTextField text={getMessage('label.description')}>
                        <HtmlRenderer html={kapMeasure.description} />
                      </ReadOnlyTextField>
                      <ReadOnlyTextField text={getMessage('label.modules')}>
                        {kapMeasure.modules.map((module: any) => (
                          <Typography key={module}>{getMessage(`label.module.description.${module}`)}</Typography>
                        ))}
                      </ReadOnlyTextField>
                      <ReadOnlyTextField text={getMessage('label.level')}>
                        {getMessage(`label.level.${kapMeasure.level}`)}
                      </ReadOnlyTextField>
                      <ReadOnlySelection text={getMessage('label.related.goals')} isLast={true}>
                        <S.List.ReadOnly>
                          {sortedGoalsLinked.length ? (
                            sortedGoalsLinked.map((x: Kap_Goal_Measure) => (
                              <ListItem key={x.kap_goal.sort_number}>
                                <ListItemIcon>
                                  <LinkIcon />
                                </ListItemIcon>
                                <ListItemText
                                  primary={`${getMessage('label.goal.id.character')}${x.kap_goal.sort_number}: ${
                                    x.kap_goal.name
                                  }`}
                                />
                              </ListItem>
                            ))
                          ) : (
                            <DefaultSectionTypography
                              noEntriesMessageKey={getMessage('label.not.available.measures')}
                              $standAlone={false}
                            />
                          )}
                        </S.List.ReadOnly>
                      </ReadOnlySelection>
                    </CardContent>
                  </Card>
                </Section>
                <Section
                  title={getMessage('label.related.factsheet')}
                  helpAndInstructions={<HelpAndInstructions labelKey="label.help.kap.measure.related.factsheet" />}
                  actionButton={
                    <BaseButton
                      messageKey="button.link.factsheet"
                      variant="text"
                      startIcon={<LinkIcon />}
                      onClick={handleLinkFactsheet}
                      hidden={!canEditMeasures}
                      disabled={!!kapMeasure.factsheet}
                    />
                  }
                  optional
                >
                  {kapMeasure.factsheet ? (
                    <>
                      <S.Card.Container>
                        <S.Card.Content
                          onClick={() => relatedFactsheetClickHandler(kapMeasure!.factsheet!.id)}
                          tabIndex={0}
                          onKeyDown={(event: { key: string }) => {
                            if (['Enter', ' '].includes(event.key)) {
                              relatedFactsheetClickHandler(kapMeasure!.factsheet!.id)
                            }
                          }}
                        >
                          <IdChip
                            size="small"
                            label={`${getMessage('label.factsheet.id.character')}${kapMeasure.factsheet.id}`}
                          />
                          <Typography variant="subtitle2">{kapMeasure.factsheet.title}</Typography>
                        </S.Card.Content>
                        {canEditMeasures && (
                          <S.Card.Actions>
                            <IconButton color="primary" size="large" onClick={handleUnlinkFactsheet}>
                              <DeleteIcon />
                            </IconButton>
                          </S.Card.Actions>
                        )}
                      </S.Card.Container>
                      <ConfirmationModalDialog
                        open={unlinkFactsheetModalOpen}
                        onCancel={onUnlinkFactsheetCancel}
                        onConfirm={onUnlinkFactsheetSuccess}
                      >
                        {getMessage('label.unlink.kap.measure.factsheet.confirm')}
                      </ConfirmationModalDialog>
                    </>
                  ) : (
                    <>
                      <S.Card.Container $nonClickable={true}>
                        <S.Card.Content>
                          <DefaultSectionTypography
                            noEntriesMessageKey={getMessage('label.no.linked.factsheets')}
                            $standAlone={true}
                          />
                        </S.Card.Content>
                      </S.Card.Container>
                      <ModalDialog
                        open={linkFactsheetModalOpen}
                        onClose={onLinkFactsheetCancel}
                        title={getMessage('label.link.related.factsheet')}
                        maxWidth="md"
                      >
                        <KapLinkRelatedFactsheetModalDialog
                          onCancel={onLinkFactsheetCancel}
                          onSuccess={onLinkFactsheetSuccess}
                          measureId={kapMeasure.id}
                          level={kapMeasure.level}
                          modules={kapMeasure.modules}
                        />
                      </ModalDialog>
                    </>
                  )}
                </Section>
              </>
            )}
          </>
        </PageLayout>
      </ScreenLayout>
    </>
  )
}
