import {
  DataGridPro,
  getGridStringOperators,
  GridColDef,
  GridFilterInputValueProps,
  GridPaginationModel,
  GridRowModel,
  GridSortModel,
  useGridApiRef,
} from '@mui/x-data-grid-pro'
import { useEffect, useMemo, useState } from 'react'
import { useMessageSource } from 'src/i18n/useMessageSource'
import { ROUTES } from 'src/routing/routes'
import { SuccessFactorGridToolbar } from 'src/screens/success-factor-tool/index/SuccessFactorGridToolbar'
import { SuccessFactorAnalysisModel } from 'src/screens/success-factor-tool/index/SuccessFactorsIndexPage'
import { LIFE_PHASES, TOPICS } from 'src/shared/constants/success-factor-constants'
import { Option } from 'src/shared/form/control'
import { AutoCompleteFilterInput } from 'src/shared/form/grid/filter/AutoCompleteFilterInput'
import { DateUtils } from 'src/shared/utils/DateUtils'
import { useGridTranslateHook } from 'src/shared/utils/hooks/grid-translate-hook'
import { useDelayedNavigate } from 'src/shared/utils/hooks/navigation-hooks'
import styled from 'styled-components/macro'

const DataGridProStyled = styled(DataGridPro)`
  & .MuiDataGrid-row {
    &:hover {
      cursor: pointer;
    }
  }
`

interface Props {
  analyses: SuccessFactorAnalysisModel[]
  onCreate: () => void
}

export const SuccessFactorAnalysisGrid = ({ analyses, onCreate }: Props) => {
  const { getMessage } = useMessageSource()
  const navigate = useDelayedNavigate()
  const gridTranslations = useGridTranslateHook()
  const gridRef = useGridApiRef()
  const [analysisDates, setAnalysisDates] = useState<string[]>([])
  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({ page: 0, pageSize: 10 })

  useEffect(() => {
    if (analyses) {
      const dates = [...analyses]
        .sort((a, b) => {
          const aDate = new Date(DateUtils.parseAndSerializeDate(b.creationDate))
          const bDate = new Date(DateUtils.parseAndSerializeDate(a.creationDate))
          return aDate.getTime() - bDate.getTime()
        })
        .map((it) => it.creationDate)

      setAnalysisDates([...new Set(dates)])
    }
  }, [analyses])

  const [sortModel, setSortModel] = useState<GridSortModel>([
    {
      field: 'creationDate',
      sort: 'desc',
    },
  ])

  const autoCompleteFilterOperator = (
    options: Option[],
    label: string,
    placeholder: string,
    defaultOperator = 'contains',
  ) =>
    getGridStringOperators()
      .filter((operator) => operator.value === defaultOperator)
      .map((operator) => ({
        ...operator,
        InputComponent: (gridFilterProps: GridFilterInputValueProps) => (
          <AutoCompleteFilterInput {...gridFilterProps} options={options} label={label} placeholder={placeholder} />
        ),
      }))

  const rows = useMemo(() => analyses, [analyses])

  const columns = useMemo<GridColDef[]>(
    () => [
      {
        field: 'title',
        flex: 3,
        headerName: getMessage('label.success.factor.analysis.title'),
        filterOperators: getGridStringOperators().filter((x) => x.value === 'contains'),
      },
      {
        field: 'creationDate',
        flex: 1,
        headerName: getMessage('label.success.factor.creation.date'),
        filterOperators: autoCompleteFilterOperator(
          analysisDates.map((it) => ({
            label: it,
            value: it,
          })),
          getMessage('label.grid.value'),
          getMessage('label.grid.filter.value'),
          'equals',
        ),
        sortComparator: (d1: string, d2: string) => {
          return DateUtils.parseAndSerializeDate(d1).localeCompare(DateUtils.parseAndSerializeDate(d2))
        },
      },
      {
        field: 'canton',
        flex: 1,
        headerName: getMessage('label.canton'),
        filterOperators: getGridStringOperators().filter((x) => x.value === 'equals'),
      },
      {
        field: 'lifePhase',
        flex: 2,
        headerName: getMessage('label.success.factor.life.phase'),
        filterOperators: autoCompleteFilterOperator(
          Object.keys(LIFE_PHASES).map((x) => ({
            label: getMessage(`label.success.factor.life.phase.${x}`),
            value: getMessage(`label.success.factor.life.phase.${x}`),
          })),
          getMessage('label.grid.value'),
          getMessage('label.grid.filter.value'),
          'equals',
        ),
      },
      {
        field: 'topic',
        flex: 2,
        headerName: getMessage('label.success.factor.topic'),
        filterOperators: autoCompleteFilterOperator(
          Object.keys(TOPICS).map((x) => ({
            label: getMessage(`label.success.factor.topic.${x}`),
            value: getMessage(`label.success.factor.topic.${x}`),
          })),
          getMessage('label.grid.value'),
          getMessage('label.grid.filter.value'),
        ),
      },
    ],
    [analysisDates, getMessage],
  )

  const handleRowClick = (params: GridRowModel) => {
    const analysisId = params?.id
    navigate(ROUTES.SuccessFactorAnalysisRoot.params({ analysisId }))
  }

  return (
    <>
      <DataGridProStyled
        rows={rows}
        columns={columns}
        autoHeight
        apiRef={gridRef}
        localeText={gridTranslations}
        onRowClick={(props) => handleRowClick(props)}
        disableRowSelectionOnClick
        disableColumnReorder
        disableColumnPinning
        paginationModel={paginationModel}
        onPaginationModelChange={(model) => setPaginationModel(model)}
        pageSizeOptions={[10, 25, 50]}
        pagination
        sortModel={sortModel}
        onSortModelChange={(model) => setSortModel(model)}
        components={{
          Toolbar: () => (
            <SuccessFactorGridToolbar
              createAnalysis={onCreate}
              title={getMessage('label.success.factors.analyses')}
              canCreateAnalysis
            />
          ),
        }}
      />
    </>
  )
}
