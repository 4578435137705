import { Box } from '@mui/material'
import { get } from 'lodash'
import { ReactElement, useEffect, useState } from 'react'
import { Form } from 'react-final-form'
import { Query_Root } from 'src/@types/graphql'
import { useMessageSource } from 'src/i18n/useMessageSource'
import { resolveApplicationAssessmentDetailsRoot, resolveApplicationAssessmentRoot } from 'src/routing/routing-utils'
import { ApplicationAssessmentCommentSection } from 'src/screens/shared/application/assessment/details/comment/ApplicationAssessmentCommentSection'
import { CriteriaConfigTable } from 'src/screens/shared/application/assessment/details/CriteriaConfigTable'
import {
  getCriteriaConfigByProcessQuery,
  getProjectAssessmentAndApplicationCodeId,
} from 'src/screens/shared/assessment-criteria/assessmentQueries'
import {
  createGroupsAndInitialData,
  CriteriaFormValues,
  CriteriaGroups,
  EXCLUSION_PREFIX,
} from 'src/screens/shared/assessment-criteria/utils/AssessmentUtils'
import { usePermissionsForProjectAssessment } from 'src/service/security/PermissionHook'
import { EditButton } from 'src/shared/button/Buttons'
import { CRITERIA_TYPE } from 'src/shared/constants/assessment-constants'
import { PROJECT } from 'src/shared/constants/constants'
import { CheckIcon } from 'src/shared/icons/Icons'
import { PageLayout } from 'src/shared/layout/PageLayout'
import { ScreenLayout } from 'src/shared/layout/ScreenLayout'
import { ProjectExportMenu } from 'src/shared/menu/ProjectExportMenu'
import { HelpAndInstructions } from 'src/shared/presentation/HelpAndInstructions'
import { Section } from 'src/shared/presentation/Section'
import { useDelayedNavigate } from 'src/shared/utils/hooks/navigation-hooks'
import { Utils } from 'src/shared/utils/Utils'
import { useUserLocale } from 'src/user/UserContext'
import { useClient, useQuery } from 'urql'

interface Props {
  baseUrl: '/pf-kap' | '/pf-pgv'
  projectId: number
  assessmentId: number
}

export const ApplicationAssessmentExclusion = ({ baseUrl, projectId, assessmentId }: Props): ReactElement => {
  const { getMessage } = useMessageSource()
  const language = useUserLocale()

  const navigate = useDelayedNavigate()
  const urqlClient = useClient()

  const [criteriaGroups, setCriteriaGroups] = useState<CriteriaGroups | undefined>()
  const [initialValues, setInitialValues] = useState<CriteriaFormValues | undefined>()

  const process = Utils.resolveProcessToLowerCase(baseUrl)
  const projectType = Utils.resolveProcess(baseUrl)
  const {
    loading,
    canEdit,
    metadata: { projectBaseId },
  } = usePermissionsForProjectAssessment(projectType, projectId, assessmentId)
  const onBackHandler = () => {
    navigate(resolveApplicationAssessmentRoot(baseUrl).params({ projectId }))
  }

  const onEditHandler = () => {
    navigate(resolveApplicationAssessmentDetailsRoot(baseUrl).nested.ExclusionEdit.params({ projectId, assessmentId }))
  }

  const isPfKap = projectType === PROJECT.PF_KAP
  const isPfPgv = projectType === PROJECT.PF_PGV
  const [{ data }] = useQuery<{
    project_assessment: Query_Root['project_assessment']
    pf_kap_project: Query_Root['pf_kap_project']
    pf_pgv_project: Query_Root['pf_pgv_project']
  }>({
    query: getProjectAssessmentAndApplicationCodeId,
    variables: { assessmentId, projectBaseId, isPfKap, isPfPgv, projectId },
  })

  const projectAssessment = data?.project_assessment?.[0]
  const firstName = projectAssessment?.user?.first_name
  const lastName = projectAssessment?.user?.last_name
  const email = projectAssessment?.user?.email
  const userFullName = !firstName ? email : `${firstName} ${lastName}`
  const applicationType = isPfKap
    ? data?.pf_kap_project?.[0]?.application_type_id
    : data?.pf_pgv_project?.[0]?.application_type_id

  useEffect(() => {
    const initData = async () => {
      const { data } = await urqlClient
        .query<{
          project_assessment_criteria_selection: Query_Root['project_assessment_criteria_selection']
          criteria_group_config: Query_Root['criteria_group_config']
        }>(getCriteriaConfigByProcessQuery, {
          process: projectType,
          criteriaType: CRITERIA_TYPE.EXCLUSION,
          assessment: assessmentId,
          applicationTypeId: applicationType,
        })
        .toPromise()

      const selectedExclusions = data?.project_assessment_criteria_selection
      const criteriaGroupedConfigs = data?.criteria_group_config

      const { criteriaGroups, initialValues } = createGroupsAndInitialData(
        selectedExclusions,
        criteriaGroupedConfigs,
        EXCLUSION_PREFIX,
        applicationType,
      )
      setCriteriaGroups(criteriaGroups)
      setInitialValues(initialValues)
    }

    initData()
  }, [urqlClient, projectType, assessmentId, applicationType])

  return (
    <ScreenLayout
      title={`${getMessage('label.navigation.application.assessment')}: ${userFullName || ''}`}
      onBack={onBackHandler}
      actions={<ProjectExportMenu process={projectType} entityId={projectBaseId as number} />}
    >
      <PageLayout>
        {!loading && (
          <>
            <Section
              id="exclusion-criteria"
              title={getMessage('label.exclusion.criteria')}
              actionButton={<EditButton origin="content" color="primary" hidden={!canEdit} onClick={onEditHandler} />}
              helpAndInstructions={
                <HelpAndInstructions labelKey={`label.help.assessment.exclusion.criteria.${process}`} />
              }
            >
              <Box mt={2}>
                <Form
                  initialValues={initialValues}
                  onSubmit={() => {}}
                  render={({ handleSubmit, values }) => {
                    return (
                      <form onSubmit={handleSubmit} noValidate>
                        {Object.values(criteriaGroups || {})
                          .sort((a, b) => a?.[0].sort_number - b?.[0].sort_number)
                          .map((criteriaSelections) => (
                            <CriteriaConfigTable
                              key={criteriaSelections[0].id}
                              rows={criteriaSelections}
                              groupTitle={get(criteriaSelections[0].names, language, '')}
                              mode="view"
                              EditControl={CheckIcon}
                              resolveControlProps={(row) => ({
                                name: `${EXCLUSION_PREFIX}${row.id}`,
                                hide: !values[`${EXCLUSION_PREFIX}${row.id}`],
                              })}
                            />
                          ))}
                      </form>
                    )
                  }}
                />
              </Box>
            </Section>
            <ApplicationAssessmentCommentSection baseUrl={baseUrl} canEdit={canEdit} type={CRITERIA_TYPE.EXCLUSION} />
          </>
        )}
      </PageLayout>
    </ScreenLayout>
  )
}
