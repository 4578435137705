import { Box } from '@mui/material'
import { TEXT_OVERFLOW_MIXIN } from 'src/shared/constants/styling-constants'
import styled from 'styled-components/macro'

export const EditableColumnHeaderStyled = styled(Box)`
  display: flex;
  align-items: center;
  font-weight: ${({ theme }) => theme.typography.subtitle2.fontWeight};
  gap: ${({ theme }) => theme.spacing(1)};
  min-width: 0;

  span {
    ${TEXT_OVERFLOW_MIXIN};
  }

  & .MuiSvgIcon-root {
    color: ${({ theme }) => theme.colors.text.disabled};
  }
`
