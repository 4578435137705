import { Fragment, ReactNode } from 'react'
import { S } from 'src/shared/styled/S'
import { useHashScroll } from 'src/shared/utils/hooks/navigation-hooks'

interface Props {
  id: string
  children: ReactNode
}

export const GoalMeasureCard = ({ id, children }: Props) => {
  useHashScroll(id)

  return (
    <Fragment key={id}>
      <S.Card.Container id={id}>{children}</S.Card.Container>
    </Fragment>
  )
}
