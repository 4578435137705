import { useMemo } from 'react'
import { toast } from 'react-toastify'
import { useMessageSource } from 'src/i18n/useMessageSource'

type MessageApi = (message: string) => void

interface PlainAPI {
  info: MessageApi
  success: MessageApi
  error: MessageApi
}

interface UtilsAPI {
  changesSaved: () => void
  operationFailed: (e?: Error & { errorCode?: string }) => void
  deleteSuccessful: () => void
}

type API = PlainAPI & UtilsAPI

export const useNotificationService = (): API => {
  const { getMessage } = useMessageSource()
  const notificationService = useMemo<API>(() => {
    const PlainAPI: PlainAPI = {
      info: (message) => toast.info(message),
      success: (message) => toast.success(message),
      error: (message) => toast.error(message),
    }
    const UtilsAPI: UtilsAPI = {
      changesSaved: () => PlainAPI.success(getMessage('notification.changes.saved')),
      operationFailed: () => PlainAPI.error(getMessage('notification.operation.failed')),
      deleteSuccessful: () => PlainAPI.info(getMessage('notification.deletion.successful')),
    }

    return { ...PlainAPI, ...UtilsAPI } as API
  }, [getMessage])

  return notificationService
}
