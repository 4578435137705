import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Milestone, Query_Root } from 'src/@types/graphql'
import { resolveDefaultMilestoneRoot } from 'src/routing/routing-utils'
import { MilestoneSummaryCommentEditForm } from 'src/screens/shared/implementation/details/milestone/details/summary/edit/MilestoneSummaryCommentEditForm'
import { milestoneSummaryDataQuery } from 'src/screens/shared/implementation/details/milestoneQueries'
import { usePermissionsForMilestones } from 'src/service/security/PermissionHook'
import { MILESTONE_RESPONSIBLE_TYPE_TYPE, MILESTONE_STATUS_TYPE } from 'src/shared/constants/milestone-constants'
import { NotAuthorized } from 'src/shared/not-authorized/NotAuthorized'
import { useDelayedNavigate } from 'src/shared/utils/hooks/navigation-hooks'
import { resolveProcessToLowerCase, Utils } from 'src/shared/utils/Utils'
import { useClient } from 'urql'

interface Props {
  baseUrl: '/pf-kap' | '/pf-pgv'
}

export const MilestoneSummaryCommentEditPage = ({ baseUrl }: Props) => {
  const { projectId, milestoneId, milestonePath } = useParams()
  const process = resolveProcessToLowerCase(baseUrl)
  const processType = Utils.resolveProcess(baseUrl)
  const milestoneType = Utils.resolveMilestoneType(milestonePath)
  const [milestoneSummaryData, setMilestoneSummaryData] = useState<Milestone>()
  const navigate = useDelayedNavigate()
  const urqlClient = useClient()

  const { loading, canEdit } = usePermissionsForMilestones(
    processType,
    parseInt(projectId as string),
    milestoneSummaryData?.status as MILESTONE_STATUS_TYPE,
    milestoneSummaryData?.responsible_type as MILESTONE_RESPONSIBLE_TYPE_TYPE,
  )

  useEffect(() => {
    const initData = async () => {
      const { data } = await urqlClient
        .query<{ milestone_by_pk: Query_Root['milestone_by_pk'] }>(milestoneSummaryDataQuery, { milestoneId })
        .toPromise()

      if (data?.milestone_by_pk) {
        setMilestoneSummaryData(data.milestone_by_pk)
      }
    }
    initData()
  }, [milestoneId, urqlClient])

  const backHandler = () => {
    navigate(resolveDefaultMilestoneRoot(baseUrl).nested.Summary.params({ projectId, milestoneId, milestonePath }))
  }

  if (milestoneSummaryData && !loading && !canEdit) {
    return <NotAuthorized />
  }

  return (
    <>
      <MilestoneSummaryCommentEditForm
        loading={loading}
        milestoneSummaryData={milestoneSummaryData}
        setMilestoneSummaryData={setMilestoneSummaryData}
        milestoneId={parseInt(milestoneId as string)}
        milestoneType={milestoneType}
        process={process}
        onBack={backHandler}
      />
    </>
  )
}
