import { DialogActions, DialogContent } from '@mui/material'
import { ReactElement, ReactNode } from 'react'
import { useMessageSource } from 'src/i18n/useMessageSource'
import { PrimaryButton, SecondaryButton } from 'src/shared/button/Buttons'
import { ModalDialog } from 'src/shared/modal-dialog/ModalDialog'
import styled from 'styled-components/macro'

interface ConfirmationModalDialogProps {
  open: boolean
  onCancel: () => void
  onConfirm: () => void
  titleKey?: string
  contentKey?: string
  children: ReactNode
}

const DialogContentStyled = styled(DialogContent)`
  &.MuiDialogContent-root {
    padding-top: 0;
  }
`

export const ConfirmationModalDialog = ({
  open,
  onCancel,
  onConfirm,
  titleKey = 'label.confirmation.title',
  children,
}: ConfirmationModalDialogProps): ReactElement => {
  const { getMessage } = useMessageSource()

  return (
    <ModalDialog open={open} onClose={onCancel} title={getMessage(titleKey)} maxWidth="xs" withCloseIcon={false}>
      <DialogContentStyled>{children}</DialogContentStyled>
      <DialogActions>
        <SecondaryButton messageKey="button.cancel" onClick={onCancel} />
        <PrimaryButton messageKey="button.confirm" onClick={onConfirm} />
      </DialogActions>
    </ModalDialog>
  )
}
