import { Stack } from '@mui/material'
import Box from '@mui/material/Box'
import { ReactElement, useEffect } from 'react'
import { Form } from 'react-final-form'
import { useMessageSource } from 'src/i18n/useMessageSource'
import { OrganizationFormValues, OrganizationType } from 'src/screens/shared/organization/utils/OrganizationFormUtils'
import { PROJECT, PROJECT_TYPE, TEXT_LENGTH } from 'src/shared/constants/constants'
import { AutoCompleteField } from 'src/shared/form/control/AutoCompleteField'
import { TextField } from 'src/shared/form/control/TextField'
import { DirtyFormSpy } from 'src/shared/form/dirty/DirtyFormSpy'
import { createDecorators } from 'src/shared/form/utils/decorators'
import { composeValidators, maxChar, required } from 'src/shared/form/validation/validators'

const decorators = createDecorators()

interface Props {
  projectType: PROJECT_TYPE
  initialValues: OrganizationFormValues
  organizationTypes: OrganizationType[]
  onSave: (values: OrganizationFormValues) => Promise<void>
}

export const OrganizationForm = ({ projectType, organizationTypes, initialValues, onSave }: Props): ReactElement => {
  const { getMessage } = useMessageSource()

  const isProject = projectType !== PROJECT.KAP

  const fieldValidation = {
    name: {
      required: true,
      validation: composeValidators(required(), maxChar(TEXT_LENGTH.M)),
    },
    organizationType: {
      required: isProject,
      validation: isProject && required(),
    },
    department: {
      required: false,
      validation: maxChar(TEXT_LENGTH.M),
    },
    address: {
      required: isProject,
      validation: isProject ? composeValidators(required(), maxChar(TEXT_LENGTH.M)) : maxChar(TEXT_LENGTH.M),
    },
    additionalAddress: {
      required: false,
      validation: maxChar(TEXT_LENGTH.M),
    },
    postalCode: {
      required: isProject,
      validation: isProject ? composeValidators(required(), maxChar(TEXT_LENGTH.S)) : maxChar(TEXT_LENGTH.S),
    },
    city: {
      required: isProject,
      validation: isProject ? composeValidators(required(), maxChar(TEXT_LENGTH.M)) : maxChar(TEXT_LENGTH.M),
    },
    phone: {
      required: isProject,
      validation: isProject ? composeValidators(required(), maxChar(TEXT_LENGTH.S)) : maxChar(TEXT_LENGTH.S),
    },
    email: {
      required: isProject,
      validation: isProject ? composeValidators(required(), maxChar(TEXT_LENGTH.M)) : maxChar(TEXT_LENGTH.M),
    },
    website: {
      required: false,
      validation: maxChar(TEXT_LENGTH.M),
    },
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <Box>
      {initialValues && (
        <Form<OrganizationFormValues>
          initialValues={initialValues}
          onSubmit={onSave}
          decorators={decorators}
          render={({ handleSubmit }) => {
            return (
              <form id="organization-form" onSubmit={handleSubmit}>
                <Stack spacing={2}>
                  <TextField
                    required={fieldValidation['name'].required}
                    label={getMessage('label.organization.name')}
                    type="text"
                    name="name"
                    validate={fieldValidation['name'].validation}
                  />
                  <AutoCompleteField
                    required={fieldValidation['organizationType'].required}
                    label={getMessage('label.organization.type')}
                    name="typeId"
                    options={organizationTypes}
                    validate={fieldValidation['organizationType'].validation}
                  />
                  <TextField
                    required={fieldValidation['department'].required}
                    label={getMessage('label.organization.department')}
                    type="text"
                    name="department"
                    validate={fieldValidation['department'].validation}
                  />
                  <TextField
                    required={fieldValidation['address'].required}
                    label={getMessage('label.organization.address')}
                    type="text"
                    name="address"
                    validate={fieldValidation['address'].validation}
                  />
                  <TextField
                    required={fieldValidation['additionalAddress'].required}
                    label={getMessage('label.organization.additional.address')}
                    type="text"
                    name="additionalAddress"
                    validate={fieldValidation['additionalAddress'].validation}
                  />
                  <TextField
                    required={fieldValidation['postalCode'].required}
                    label={getMessage('label.organization.postal.code')}
                    type="text"
                    name="postalCode"
                    validate={fieldValidation['postalCode'].validation}
                  />
                  <TextField
                    required={fieldValidation['city'].required}
                    label={getMessage('label.organization.city')}
                    type="text"
                    name="city"
                    validate={fieldValidation['city'].validation}
                  />
                  <TextField
                    required={fieldValidation['phone'].required}
                    label={getMessage('label.organization.phone')}
                    type="text"
                    name="phone"
                    validate={fieldValidation['phone'].validation}
                  />
                  <TextField
                    required={fieldValidation['email'].required}
                    label={getMessage('label.email')}
                    type="text"
                    name="email"
                    validate={fieldValidation['email'].validation}
                  />
                  <TextField
                    required={fieldValidation['website'].required}
                    label={getMessage('label.organization.website')}
                    type="text"
                    name="website"
                    validate={fieldValidation['website'].validation}
                  />
                  <DirtyFormSpy />
                </Stack>
              </form>
            )
          }}
        />
      )}
    </Box>
  )
}
