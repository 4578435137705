import { IconButton } from '@mui/material'
import { ReactElement, useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { Kap_Goal, Kap_Goal_Measure, Mutation_Root, Query_Root } from 'src/@types/graphql'
import { useMessageSource } from 'src/i18n/useMessageSource'
import { ROUTES } from 'src/routing/routes'
import {
  deleteKapGoalByPkMutation,
  fetchKapGoalsByProgramIdQuery,
  updateKapGoalSortNumbersQuery,
} from 'src/screens/kap/program/details/goals/kapGoalQueries'
import { GoalMeasureCard } from 'src/screens/shared/common/GoalMeasureCard'
import { areGoalsMeasuresSortedLatest } from 'src/screens/shared/common/utils/GoalMeasureUtils'
import { sortKapMeasures } from 'src/screens/shared/measure/utils/KapMeasureUtils'
import { usePermissionsForKapProgram } from 'src/service/security/PermissionHook'
import { AddButton } from 'src/shared/button/Buttons'
import { DeleteIcon, EditIcon, MoveDownIcon, MoveUpIcon } from 'src/shared/icons/Icons'
import { PageLayout } from 'src/shared/layout/PageLayout'
import { ScreenLayout } from 'src/shared/layout/ScreenLayout'
import { ProgramExportMenu } from 'src/shared/menu/ProgramExportMenu'
import { ConfirmationModalDialog } from 'src/shared/modal-dialog/ConfirmationModalDialog'
import { DefaultSectionTypography } from 'src/shared/presentation/DefaultSectionTypography'
import { HelpAndInstructions } from 'src/shared/presentation/HelpAndInstructions'
import { Section } from 'src/shared/presentation/Section'
import { S } from 'src/shared/styled/S'
import { useDelayedNavigate } from 'src/shared/utils/hooks/navigation-hooks'
import { useNotificationService } from 'src/shared/utils/NotificationService'
import { useClient } from 'urql'

enum MOVE_SORT_DIRECTION {
  UP,
  DOWN,
}

export const KapGoalsPage = (): ReactElement => {
  const { programId } = useParams()
  const { getMessage } = useMessageSource()
  const urqlClient = useClient()
  const notificationService = useNotificationService()
  const navigate = useDelayedNavigate()
  const program_id = parseInt(programId as string)
  const { loading, canEdit, canViewCantonRestricted } = usePermissionsForKapProgram(program_id)

  const [kapGoals, setKapGoals] = useState<Kap_Goal[]>()
  const [kapGoalDeleteConfirmationOpen, setKapGoalDeleteConfirmationOpen] = useState(false)
  const [kapGoalIdToBeDeleted, setKapGoalIdToBeDeleted] = useState<any>(null)
  const [measuresLinked, setMeasuresLinked] = useState<boolean>(false)

  const [beingSwappedIds, setBeingSwappedIds] = useState<{ id1: number; id2: number }>()

  useEffect(() => {
    if (beingSwappedIds && kapGoals) {
      const areSortedLatest = areGoalsMeasuresSortedLatest(kapGoals, beingSwappedIds.id1, beingSwappedIds.id2)

      if (areSortedLatest) {
        setBeingSwappedIds(undefined)
      }
    }
  }, [beingSwappedIds, kapGoals])

  const fetchData = useCallback(async () => {
    const { data } = await urqlClient
      .query<{ kap_goal: Query_Root['kap_goal'] }>(fetchKapGoalsByProgramIdQuery, {
        id: program_id,
      })
      .toPromise()

    if (data?.kap_goal) {
      setKapGoals(data?.kap_goal)
    } else {
      notificationService.operationFailed()
    }
  }, [urqlClient, program_id, notificationService])

  useEffect(() => {
    fetchData()
  }, [urqlClient, program_id, notificationService, fetchData])

  const handleAddGoal = () => {
    navigate(ROUTES.KapDetailsRoot.nested.GoalNew.params({ programId }))
  }

  const handleGoalCardClick = (goalId: number) => {
    navigate(ROUTES.KapDetailsRoot.nested.GoalDetails.params({ programId, goalId }))
  }

  const handleDeleteGoal = (goalId: number, linkedMeasures: Kap_Goal_Measure[]) => {
    setKapGoalIdToBeDeleted(goalId)
    setMeasuresLinked(linkedMeasures.length > 0)
    setKapGoalDeleteConfirmationOpen(true)
  }

  const handleEditGoal = (goalId: number) => {
    navigate(ROUTES.KapDetailsRoot.nested.GoalEdit.params({ programId, goalId }))
  }

  const moveGoal = async (event: any, goalId: number, direction: MOVE_SORT_DIRECTION) => {
    event.stopPropagation()
    if (!kapGoals) {
      return
    }

    const goal1Index = kapGoals.findIndex((goal: Kap_Goal) => goal.id === goalId)

    const isMoveDirectionUp = direction === MOVE_SORT_DIRECTION.UP
    const conditionIndexRange = isMoveDirectionUp ? goal1Index > 0 : goal1Index < kapGoals.length - 1

    if (conditionIndexRange) {
      const goal1 = kapGoals[goal1Index]
      const goal2 = isMoveDirectionUp ? kapGoals[goal1Index - 1] : kapGoals[goal1Index + 1]

      isMoveDirectionUp
        ? setBeingSwappedIds({ id1: goal1.id, id2: goal2.id })
        : setBeingSwappedIds({ id1: goal2.id, id2: goal1.id })

      const mutationObject = {
        goal1Id: goal1.id,
        goal2Id: goal2.id,
        goal1SortNumber: goal2.sort_number,
        goal2SortNumber: goal1.sort_number,
      }

      if (goal1 && goal2) {
        const { error } = await urqlClient
          .mutation<{ update_kap_goal: Mutation_Root['update_kap_goal'] }>(
            updateKapGoalSortNumbersQuery,
            mutationObject,
          )
          .toPromise()

        if (error) {
          notificationService.operationFailed()
        } else {
          notificationService.changesSaved()
          fetchData()
        }
      }
    }
  }

  const handleCancelDeleteKapGoal = () => {
    setKapGoalDeleteConfirmationOpen(false)
  }

  const handleDeleteKapGoalConfirmed = async () => {
    setKapGoalDeleteConfirmationOpen(false)

    const { error } = await urqlClient
      .mutation<{
        delete_kap_goal_by_pk: Mutation_Root['delete_kap_goal_by_pk']
      }>(deleteKapGoalByPkMutation, {
        id: kapGoalIdToBeDeleted,
      })
      .toPromise()

    if (error) {
      notificationService.operationFailed()
    } else {
      notificationService.deleteSuccessful()
      setMeasuresLinked(false)
      fetchData()
    }
  }

  return (
    <ScreenLayout
      title={getMessage('label.program.description')}
      actions={<>{canViewCantonRestricted && <ProgramExportMenu />}</>}
    >
      <PageLayout>
        <>
          {!loading && (
            <>
              <Section
                id="goals"
                title={getMessage('label.project.goals')}
                actionButton={<AddButton messageKey="button.goal.add" onClick={handleAddGoal} hidden={!canEdit} />}
                helpAndInstructions={<HelpAndInstructions labelKey="label.help.kap.program.goals" />}
              >
                {kapGoals &&
                  kapGoals.length &&
                  kapGoals.map((goal: Kap_Goal, index: number) => {
                    const goalHasReports = goal.kap_goal_annual_reports.length

                    sortKapMeasures(goal.kap_goal_measures, 'kap_measure')

                    return (
                      <GoalMeasureCard id={`${goal.id}`} key={goal.id}>
                        <S.Card.Content
                          onClick={() => handleGoalCardClick(goal.id)}
                          tabIndex={0}
                          onKeyDown={(event: { key: string }) => {
                            if (['Enter', ' '].includes(event.key)) {
                              handleGoalCardClick(goal.id)
                            }
                          }}
                        >
                          <S.GoalMeasureCard.IdChip
                            size="small"
                            label={`${getMessage('label.goal.id.character')}${goal.sort_number}`}
                          />

                          <S.GoalMeasureCard.MoveButtons>
                            <IconButton
                              color="inherit"
                              disabled={index === 0 || !canEdit || !!beingSwappedIds}
                              size="large"
                              onClick={(event) => moveGoal(event, goal.id, MOVE_SORT_DIRECTION.UP)}
                            >
                              <MoveUpIcon />
                            </IconButton>
                            <IconButton
                              color="inherit"
                              disabled={index === kapGoals.length - 1 || !canEdit || !!beingSwappedIds}
                              size="large"
                              onClick={(event) => moveGoal(event, goal.id, MOVE_SORT_DIRECTION.DOWN)}
                            >
                              <MoveDownIcon />
                            </IconButton>
                          </S.GoalMeasureCard.MoveButtons>
                          <S.GoalMeasureCard.ItemContainer>
                            <S.GoalMeasureCard.ItemTitle variant="subtitle2">{goal.name}</S.GoalMeasureCard.ItemTitle>
                            {goal.kap_goal_measures.length > 0 ? (
                              <S.GoalMeasureCard.RelatedItem variant="body2">
                                {goal.kap_goal_measures
                                  .map((x: Kap_Goal_Measure) => {
                                    const measureIdCharacter = `label.kap.measure.id.character.${x.kap_measure.level}`
                                    return `${getMessage(measureIdCharacter)}${x.kap_measure.sort_number}`
                                  })
                                  .join(', ')}
                              </S.GoalMeasureCard.RelatedItem>
                            ) : (
                              <DefaultSectionTypography
                                noEntriesMessageKey={getMessage('label.not.available.measures')}
                                $standAlone={true}
                              />
                            )}
                          </S.GoalMeasureCard.ItemContainer>
                        </S.Card.Content>
                        <S.Card.Actions $numberOfButtonsToAlign={2}>
                          {canEdit && (
                            <>
                              <IconButton color="primary" size="large" onClick={() => handleEditGoal(goal.id)}>
                                <EditIcon />
                              </IconButton>
                              {!goalHasReports && (
                                <IconButton
                                  color="primary"
                                  size="large"
                                  onClick={() => handleDeleteGoal(goal.id, goal.kap_goal_measures)}
                                >
                                  <DeleteIcon />
                                </IconButton>
                              )}
                            </>
                          )}
                        </S.Card.Actions>
                      </GoalMeasureCard>
                    )
                  })}
              </Section>
            </>
          )}
        </>
      </PageLayout>
      <ConfirmationModalDialog
        open={kapGoalDeleteConfirmationOpen}
        onCancel={handleCancelDeleteKapGoal}
        onConfirm={handleDeleteKapGoalConfirmed}
      >
        {measuresLinked ? getMessage('label.delete.confirm.goals.found') : getMessage('label.delete.confirm')}
      </ConfirmationModalDialog>
    </ScreenLayout>
  )
}
