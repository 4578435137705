import { Stack } from '@mui/material'
import { DataGridPro, GridColDef, GridRowParams, useGridApiRef } from '@mui/x-data-grid-pro'
import { ReactElement, useMemo } from 'react'
import { useMessageSource } from 'src/i18n/useMessageSource'
import { resolveMilestoneRoot, resolvePrimaryRoute } from 'src/routing/routing-utils'
import { MilestoneViewModel } from 'src/screens/shared/implementation/details/milestone/ProjectImplementationMilestone'
import { DateUtils } from 'src/shared/utils/DateUtils'
import { useGridTranslateHook } from 'src/shared/utils/hooks/grid-translate-hook'
import { useDelayedNavigate } from 'src/shared/utils/hooks/navigation-hooks'
import { Utils } from 'src/shared/utils/Utils'
import styled from 'styled-components/macro'

const DataGridProStyled = styled(DataGridPro)`
  & .MuiDataGrid-row {
    &:hover {
      cursor: pointer;
    }
  }
`
interface Props {
  milestones: MilestoneViewModel[]
  baseUrl: '/pf-kap' | '/pf-pgv'
  projectId: number
}

export const MilestoneGrid = ({ milestones, baseUrl, projectId }: Props): ReactElement => {
  const { getMessage } = useMessageSource()
  const navigate = useDelayedNavigate()
  const apiRef = useGridApiRef()
  const gridTranslations = useGridTranslateHook()

  const rows = useMemo(() => milestones, [milestones])

  const columns = useMemo(
    () =>
      [
        {
          field: 'title',
          headerName: getMessage('label.milestone'),
          flex: 3,
        },
        {
          field: 'end_date',
          headerName: getMessage('label.end.date'),
          flex: 1,
          renderCell: ({ value }) => DateUtils.parseAndFormatDate(value),
        },
        {
          field: 'status',
          headerName: getMessage('label.status'),
          flex: 1,
          renderCell: ({ value }) => getMessage(`label.milestone.status.${value}`),
        },
        {
          field: 'organization',
          headerName: getMessage('label.organization'),
          flex: 1,
        },
        {
          field: 'assignee',
          headerName: getMessage('label.assignee'),
          flex: 2,
        },
      ] as GridColDef[],
    [getMessage],
  )

  const onRowClick = ({ row }: GridRowParams) => {
    const milestoneId = row?.id
    const milestonePath = Utils.resolveMilestonePath(row?.type)
    const primaryRoute = resolvePrimaryRoute(resolveMilestoneRoot(baseUrl, row?.type))
    navigate(primaryRoute.params({ projectId, milestoneId, milestonePath }))
  }

  return (
    <>
      <div style={{ height: rows.length > 0 ? 'auto' : 200, width: '100%' }}>
        <DataGridProStyled
          apiRef={apiRef}
          rows={rows}
          columns={columns}
          localeText={gridTranslations}
          onRowClick={(props) => onRowClick(props)}
          components={{
            NoRowsOverlay: () => (
              <Stack height="100%" justifyContent="center" alignItems="center">
                {getMessage('label.project.not.yet.in.implementation')}
              </Stack>
            ),
          }}
          autoHeight={rows.length > 0}
          disableRowSelectionOnClick
          disableColumnReorder
          disableColumnSelector
          disableColumnFilter
          disableColumnPinning
          hideFooter
        />
      </div>
    </>
  )
}
