import { useCallback, useEffect, useState } from 'react'
import { useMessageSource } from 'src/i18n/useMessageSource'
import { FilterFieldWrapper } from 'src/screens/shared/common/filter-card/FilterFieldWrapper'
import {
  APPLICATION_TYPE,
  APPLICATION_TYPE_PF_PGV,
  APPLICATION_TYPE_PF_PGV_TYPE,
  APPLICATION_TYPE_TYPE,
  PROJECT,
  PROJECT_TYPE,
} from 'src/shared/constants/constants'
import { FilterCheckboxGroupField, FilterCheckboxModel } from 'src/shared/form/control/FilterCheckboxGroupField'
import { requiredSelection } from 'src/shared/form/validation/validators'

interface Props {
  name: string
  process: PROJECT_TYPE
  error?: boolean
  formValues: APPLICATION_TYPE_TYPE[] | APPLICATION_TYPE_PF_PGV_TYPE[] | undefined
}

export const ApplicationTypeFilter = ({ name, process, error, formValues }: Props) => {
  const { getMessage } = useMessageSource()

  const [applicationTypeData, setApplicationTypeData] = useState<FilterCheckboxModel[]>([])

  const resolveApplicationTypeLabel = useCallback(
    (type: string) => {
      if (process === PROJECT.PF_KAP) {
        return getMessage(`label.application_type.pfkap.${type.toLowerCase().replace('_', '.')}`)
      }
      if (process === PROJECT.PF_PGV) {
        return getMessage(`label.application_type.pfpgv.${type.toLowerCase().replace(/_/g, '.')}`)
      }
    },
    [process, getMessage],
  )

  useEffect(() => {
    const data = process === PROJECT.PF_KAP ? Object.values(APPLICATION_TYPE) : Object.values(APPLICATION_TYPE_PF_PGV)

    const applicationTypes = data.map((item) => ({
      label: resolveApplicationTypeLabel(item),
      value: item,
    })) as FilterCheckboxModel[]

    setApplicationTypeData(applicationTypes)
  }, [process, resolveApplicationTypeLabel])

  return (
    <FilterFieldWrapper label={getMessage('label.application_type')} required error={error}>
      <FilterCheckboxGroupField
        data={applicationTypeData}
        name={name}
        validate={requiredSelection()}
        formValues={formValues}
      />
    </FilterFieldWrapper>
  )
}
