import { ListItemLink } from 'src/routing/ListItemLink'
import { PAGE_HEADER_HEIGHT } from 'src/shared/layout/ScreenLayout'
import styled from 'styled-components/macro'

export const SidebarHomeListItemStyled = styled(ListItemLink)`
  height: ${PAGE_HEADER_HEIGHT};
  & .MuiListItemText-root {
    line-height: 100%;
  }
  & .MuiListItemIcon-root {
    color: inherit;
  }
`
