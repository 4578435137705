import { PROJECT, PROJECT_TYPE } from 'src/shared/constants/constants'
import { MILESTONE_TYPE } from 'src/shared/constants/milestone-constants'

export const getMilestoneTitleLabel = (process: PROJECT_TYPE, milestoneType: string) => {
  return process === PROJECT.KAP && milestoneType === MILESTONE_TYPE.FINAL_REPORT
    ? `label.program.milestone.type.${milestoneType}`
    : `label.milestone.type.${milestoneType}`
}

export const getMilestoneSummaryTitleLabel = (process: PROJECT_TYPE, milestoneType: string) => {
  return process === PROJECT.KAP && milestoneType === MILESTONE_TYPE.FINAL_REPORT
    ? `label.program.milestone.summary.title.${milestoneType}`
    : `label.milestone.summary.title.${milestoneType}`
}
