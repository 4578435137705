import {
  AutocompleteRenderInputParams as MuiAutoCompleteRenderInputParams,
  default as MuiAutoComplete,
} from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import { GridFilterInputValueProps } from '@mui/x-data-grid-pro'
import { ReactElement, ReactNode } from 'react'
import { Option } from 'src/shared/form/control/index'
import styled from 'styled-components/macro'

const MuiAutoCompleteStyled = styled(MuiAutoComplete)`
  & .MuiInputBase-root {
    height: 2rem;
  }
`

export const AutoCompleteFilterInput = (
  props: GridFilterInputValueProps & { options: Array<Option>; placeholder: string; label: string },
): ReactElement => {
  const { item, applyValue, options, placeholder, label } = props

  const handleFilterChange = (event: any, values: any) => {
    applyValue({ ...item, value: values?.value ?? null })
  }

  const autoCompleteValue = options.find((option) => option.value === item.value) ?? null

  return (
    <MuiAutoCompleteStyled
      options={options}
      getOptionLabel={(option: any): string => option?.label ?? ''}
      value={autoCompleteValue}
      onChange={handleFilterChange}
      multiple={false}
      renderInput={(params: MuiAutoCompleteRenderInputParams): ReactNode => (
        <TextField
          {...params}
          fullWidth
          placeholder={placeholder}
          variant="standard"
          label={label}
          InputLabelProps={{
            shrink: true,
          }}
        />
      )}
    />
  )
}
