import { Box, Container, Typography } from '@mui/material'
import IconButton from '@mui/material/IconButton'
import { FC, ReactElement, ReactNode } from 'react'
import { TEXT_OVERFLOW_MIXIN } from 'src/shared/constants/styling-constants'
import { ArrowBackIcon } from 'src/shared/icons/Icons'
import { COLORS } from 'src/theme/colors'
import styled from 'styled-components/macro'

export const PAGE_HEADER_HEIGHT = '65px'
const FOOTER_HEIGHT = '102px' // 80px padding-block + 21px font-size + 1px border-top

const ScreenHeader = styled(Box)`
  position: fixed;
  top: ${PAGE_HEADER_HEIGHT};
  background-color: ${({ theme }) => theme.colors.grey.neutral};
  height: ${PAGE_HEADER_HEIGHT};
  z-index: 500;
  width: 100%;
  padding-left: ${({ theme }) => theme.spacing(3)};
  padding-right: ${({ theme }) => theme.spacing(3)};
  display: flex;
  align-items: center;
  flex-grow: 1;
  border-bottom: 1px solid ${({ theme }) => theme.colors.grey.divider};
`

const TypographyStyled = styled(Typography)`
  flex-grow: 1;
  line-height: initial;
  ${TEXT_OVERFLOW_MIXIN};
`

const BackButtonStyled = styled(IconButton)`
  margin-right: ${({ theme }) => theme.spacing(1)};
`

const ActionsGroupStyled = styled(Box)`
  display: flex;
  grid-gap: ${({ theme }) => theme.spacing(2)};
  flex-direction: row-reverse;
`

const ScreenContent = styled(Box)<{ $secondLevel: boolean; $fullWidthHeader?: boolean; $footer?: boolean }>`
  padding-top: ${({ $secondLevel, $fullWidthHeader, theme }) =>
    $secondLevel
      ? theme.spacing(5)
      : $fullWidthHeader
      ? `${PAGE_HEADER_HEIGHT}`
      : `calc(${theme.spacing(5)} + ${PAGE_HEADER_HEIGHT})`};
  /* we set this so that the page will always have a scrollbar if the content itself is 100vh
  making the tabbing after the last tabbable element in the page, scroll to the top and focus the first ScreenHeader action.  */
  padding-bottom: ${({ $footer }) => ($footer ? 0 : `${PAGE_HEADER_HEIGHT}`)};
  height: ${({ $fullWidthHeader, $footer }) =>
    $fullWidthHeader ? 'auto' : $footer ? `calc(100% - ${FOOTER_HEIGHT})` : `calc(100% - ${PAGE_HEADER_HEIGHT})`};
`

const HeaderSection = styled(Box)`
  background: ${COLORS.gradient.primaryLightToNeutral};
  padding-top: ${({ theme }) => theme.spacing(6)};
  padding-bottom: ${({ theme }) => theme.spacing(8)};
  border-bottom: 1px solid ${({ theme }) => theme.colors.grey.divider};
  margin-bottom: ${({ theme }) => theme.spacing(5)};
`

interface ScreenLayoutProps {
  onBack?: () => void
  title: string
  actions?: ReactElement
  hasSecondLevelNavigation?: boolean
  children: ReactElement | ReactNode
  fullWidthHeaderSection?: ReactElement | ReactNode
  hasFooter?: boolean
}

export const ScreenLayout: FC<ScreenLayoutProps> = ({
  children,
  onBack,
  title,
  actions,
  hasSecondLevelNavigation = true,
  fullWidthHeaderSection,
  hasFooter,
}) => {
  return (
    <>
      {/* ScreenContent is intentionally rendered first so that the `ScreenHeader` will come AFTER the main content in the `tab`-ing order. */}
      <ScreenContent
        $secondLevel={hasSecondLevelNavigation}
        $fullWidthHeader={!!fullWidthHeaderSection}
        $footer={hasFooter}
      >
        {fullWidthHeaderSection && (
          <HeaderSection>
            <Container maxWidth="lg">{fullWidthHeaderSection}</Container>
          </HeaderSection>
        )}
        {children}
      </ScreenContent>

      <ScreenHeader>
        {onBack && (
          <BackButtonStyled color="inherit" onClick={onBack} hidden={!!onBack}>
            <ArrowBackIcon />
          </BackButtonStyled>
        )}
        <TypographyStyled variant="h1">{title}</TypographyStyled>
        <ActionsGroupStyled>{actions}</ActionsGroupStyled>
      </ScreenHeader>
    </>
  )
}
