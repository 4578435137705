import { Box, Stack } from '@mui/material'
import { ReactElement, useEffect, useState } from 'react'
import { Form } from 'react-final-form'
import { useParams } from 'react-router'
import { Kap_Measure, Query_Root } from 'src/@types/graphql'
import { useMessageSource } from 'src/i18n/useMessageSource'
import {
  fetchKapGoalsByProgramIdQuery,
  fetchKapProgramModulesByProgramIdQuery,
} from 'src/screens/kap/program/details/measures/kapMeasuresQueries'
import { LEVEL, LEVEL_TYPE, MODULE_TYPES, TEXT_LENGTH } from 'src/shared/constants/constants'
import { Option } from 'src/shared/form/control'
import { AutoCompleteField } from 'src/shared/form/control/AutoCompleteField'
import { HtmlEditorField } from 'src/shared/form/control/HtmlEditorField'
import { MultiSelectField } from 'src/shared/form/control/MultiSelectField'
import { TextField } from 'src/shared/form/control/TextField'
import { DirtyFormSpy } from 'src/shared/form/dirty/DirtyFormSpy'
import { createDecorators } from 'src/shared/form/utils/decorators'
import { composeValidators, maxChar, required } from 'src/shared/form/validation/validators'
import { HtmlSanitizer } from 'src/shared/utils/HtmlSanitizer'
import { useNotificationService } from 'src/shared/utils/NotificationService'
import { useClient, useQuery } from 'urql'

export interface KapMeasureFormValues {
  measureId?: number
  name: string
  shortTitle: string
  description: string
  modules: MODULE_TYPES[]
  level: LEVEL_TYPE
  goalIds?: number[]
  programId?: number
}

interface Props {
  initialValues: KapMeasureFormValues
  onSave: (values: KapMeasureFormValues) => Promise<void>
  mode: 'create' | 'edit'
}

export const initialValuesFactory = (programId: number, measure?: Kap_Measure): KapMeasureFormValues => {
  const selectedGoalIds = measure?.kap_goal_measures.map((kapGoalMeasure) => kapGoalMeasure.kap_goal.id) ?? []
  return {
    measureId: measure?.id || undefined,
    name: measure?.name || '',
    shortTitle: measure?.short_title || '',
    description: HtmlSanitizer.sanitize(measure?.description),
    modules: measure?.modules,
    level: measure?.level as LEVEL_TYPE,
    goalIds: selectedGoalIds,
    programId: programId,
  }
}

const decorators = createDecorators()

export const KapMeasureForm = ({ initialValues, onSave, mode }: Props): ReactElement => {
  const { programId } = useParams()
  const program_id = parseInt(programId as string)

  const { getMessage } = useMessageSource()
  const notificationService = useNotificationService()
  const urqlClient = useClient()

  const [goalOptions, setGoalOptions] = useState<Option[] | undefined>()

  useEffect(() => {
    const initializeKapGoalOptions = async () => {
      const { data } = await urqlClient
        .query<{ kap_goal: Query_Root['kap_goal'] }, { programId: number }>(fetchKapGoalsByProgramIdQuery, {
          programId: program_id,
        })
        .toPromise()

      if (data && data.kap_goal) {
        const options =
          data?.kap_goal.map((kapGoal) => ({
            label: `${getMessage('label.goal.id.character')}${kapGoal.sort_number}: ${kapGoal.name}`,
            value: kapGoal.id,
            sortNumber: kapGoal.sort_number || 0,
          })) ?? []
        setGoalOptions(options)
      }
    }

    initializeKapGoalOptions()
  }, [urqlClient, program_id, setGoalOptions, getMessage])

  const [{ data, error }] = useQuery<{ kap_program: Query_Root['kap_program'] }>({
    query: fetchKapProgramModulesByProgramIdQuery,
    variables: { id: programId },
  })

  const programModules = data?.kap_program?.[0]?.modules

  if (error) {
    notificationService.operationFailed()
  }

  const moduleOptions = programModules
    ? programModules.map((module: MODULE_TYPES) => ({
        label: `${getMessage(`label.module.description.${module}`)}`,
        value: module,
      }))
    : []

  return (
    <Box>
      {initialValues && goalOptions && (
        <Form<KapMeasureFormValues>
          initialValues={initialValues}
          onSubmit={onSave}
          decorators={decorators}
          render={({ handleSubmit }) => {
            return (
              <form id="kap-measure-form" onSubmit={handleSubmit}>
                <Stack spacing={2}>
                  <TextField
                    required
                    label={getMessage('label.title')}
                    type="text"
                    name="name"
                    validate={composeValidators(required(), maxChar(TEXT_LENGTH.M))}
                  />
                  <TextField
                    required
                    label={getMessage('label.short.title')}
                    type="text"
                    name="shortTitle"
                    validate={composeValidators(required(), maxChar(TEXT_LENGTH.S))}
                  />
                  <HtmlEditorField
                    required
                    label={getMessage('label.description')}
                    name="description"
                    validate={composeValidators(required(), maxChar(TEXT_LENGTH.XL))}
                  />
                  <MultiSelectField
                    options={moduleOptions}
                    name="modules"
                    label={getMessage('label.modules')}
                    validate={required()}
                  />
                  <AutoCompleteField
                    name="level"
                    label={getMessage('label.level')}
                    disabled={mode === 'edit'}
                    options={Object.keys(LEVEL).map((level) => ({
                      label: getMessage(`label.level.${level}`),
                      value: level,
                    }))}
                    required
                    validate={required()}
                  />
                  <MultiSelectField
                    options={goalOptions}
                    name="goalIds"
                    label={getMessage('label.related.goals')}
                    unavailableOptionsMessage={getMessage('label.related.goals.add.goals')}
                  />
                  <DirtyFormSpy />
                </Stack>
              </form>
            )
          }}
        />
      )}
    </Box>
  )
}
