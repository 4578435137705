import { ReactElement } from 'react'
import { useParams } from 'react-router'
import { ApplicationCommitteePage } from 'src/screens/shared/application/committee/ApplicationCommitteePage'

export const PfPgvApplicationCommitteePage = (): ReactElement => {
  const { projectId } = useParams()
  const pf_pgv_id = parseInt(projectId as string)

  return <ApplicationCommitteePage projectId={pf_pgv_id} baseUrl="/pf-pgv" />
}
