import { Box, Chip } from '@mui/material'
import { useState } from 'react'
import { ReportInput } from 'src/@types/graphql'
import { useMessageSource } from 'src/i18n/useMessageSource'
import { ReportingFilterCard } from 'src/screens/reporting/shared/filter-card/ReportingFilterCard'
import { ReportEvaluationNeedGrid } from 'src/screens/reporting/shared/report-card-content/report-evaluation-need/ReportEvaluationNeedGrid'
import { ReportEvaluationGrid } from 'src/screens/reporting/shared/report-card-content/report-evaluation/ReportEvaluationGrid'
import { ReportGoalGrid } from 'src/screens/reporting/shared/report-card-content/report-goal/ReportGoalGrid'
import { ReportMeasureGrid } from 'src/screens/reporting/shared/report-card-content/report-measure/ReportMeasureGrid'
import { ReportCard } from 'src/screens/reporting/shared/report-card/ReportCard'
import { ReportingSearchResultsSection } from 'src/screens/reporting/shared/search-results-section/ReportingSearchResultsSection'
import { ReportService } from 'src/service/axios/ReportService'
import { PROJECT } from 'src/shared/constants/constants'
import { REPORTING_DOWNLOAD, REPORTING_DOWNLOAD_TYPE } from 'src/shared/constants/reporting-constants'
import { PageLayout } from 'src/shared/layout/PageLayout'
import { ScreenLayout } from 'src/shared/layout/ScreenLayout'
import { S } from 'src/shared/styled/S'
import { saveFileAs } from 'src/shared/utils/BlobUtils'
import { useNotificationService } from 'src/shared/utils/NotificationService'
import { useUserLocale } from 'src/user/UserContext'

export const PfPgvReportingPage = () => {
  const { getMessage } = useMessageSource()
  const language = useUserLocale()
  const notificationService = useNotificationService()

  const [reportDataCount, setReportDataCount] = useState<number>()
  const [reportInput, setReportInput] = useState<ReportInput>()

  const onDownloadHandle = (reportType: REPORTING_DOWNLOAD_TYPE) => async () => {
    if (reportInput) {
      await ReportService.generateReport(reportInput, '/pf-pgv', reportType, language)
        .then((response) => {
          saveFileAs(response)
        })
        .catch(() => {
          notificationService.operationFailed()
        })
    }
  }

  return (
    <ScreenLayout title={getMessage(`label.reporting.${PROJECT.PF_PGV}`)} hasSecondLevelNavigation={false}>
      <PageLayout>
        <ReportingFilterCard
          process={PROJECT.PF_PGV}
          setProcessDataCount={setReportDataCount}
          setReportInput={setReportInput}
        />
        <Box marginTop={5}>
          <ReportingSearchResultsSection>
            {reportDataCount !== undefined && reportInput && (
              <>
                <ReportCard
                  reportName={getMessage('label.project.information')}
                  handleDownload={onDownloadHandle(REPORTING_DOWNLOAD.PROJECT_INFORMATION)}
                >
                  <Chip
                    label={`${reportDataCount} ${getMessage('label.projects')}`}
                    color="secondary"
                    variant="filled"
                    sx={{ marginLeft: 2 }}
                  />
                </ReportCard>
                <ReportCard
                  reportName={getMessage('label.financials')}
                  handleDownload={onDownloadHandle(REPORTING_DOWNLOAD.FINANCIALS)}
                />
                <S.DataGrid.ReportGridContainer>
                  <ReportCard
                    reportGrid={
                      <ReportGoalGrid
                        reportInput={reportInput}
                        process={PROJECT.PF_PGV}
                        handleDownload={onDownloadHandle(REPORTING_DOWNLOAD.GOALS)}
                      />
                    }
                  />
                  <ReportCard
                    reportGrid={
                      <ReportMeasureGrid
                        reportInput={reportInput}
                        process={PROJECT.PF_PGV}
                        handleDownload={onDownloadHandle(REPORTING_DOWNLOAD.MEASURES)}
                      />
                    }
                  />
                </S.DataGrid.ReportGridContainer>
                <ReportCard
                  reportName={getMessage('label.features.report')}
                  handleDownload={onDownloadHandle(REPORTING_DOWNLOAD.FEATURES)}
                />
                <ReportCard
                  reportName={getMessage('label.kpi.report')}
                  handleDownload={onDownloadHandle(REPORTING_DOWNLOAD.KPI)}
                />
                <S.DataGrid.ReportGridContainer>
                  <ReportCard
                    reportGrid={<ReportEvaluationNeedGrid reportInput={reportInput} process={PROJECT.PF_PGV} />}
                  />
                  <ReportCard
                    reportGrid={<ReportEvaluationGrid reportInput={reportInput} process={PROJECT.PF_PGV} />}
                  />
                </S.DataGrid.ReportGridContainer>
              </>
            )}
          </ReportingSearchResultsSection>
        </Box>
      </PageLayout>
    </ScreenLayout>
  )
}
