import { ReactElement, useMemo } from 'react'
import { useParams } from 'react-router'
import { Feature_Base_Bool_Exp, Feature_Config_Bool_Exp } from 'src/@types/graphql'
import { resolveDetailsRoot } from 'src/routing/routing-utils'
import { FeatureBaseEdit } from 'src/screens/shared/feature/edit/FeatureBaseEdit'
import { usePermissionsForProject } from 'src/service/security/PermissionHook'
import { PROJECT } from 'src/shared/constants/constants'
import { NotAuthorized } from 'src/shared/not-authorized/NotAuthorized'
import { useDelayedNavigate } from 'src/shared/utils/hooks/navigation-hooks'
import { Utils } from 'src/shared/utils/Utils'

interface ProjectFeaturesTopicEditPageProps {
  baseUrl: '/pf-kap' | '/pf-pgv'
}

export const ProjectFeaturesEditPage = ({ baseUrl }: ProjectFeaturesTopicEditPageProps): ReactElement => {
  const navigate = useDelayedNavigate()
  const { projectId, featureTypeId } = useParams()
  const id = parseInt(projectId as string)

  const process = Utils.resolveProcess(baseUrl)
  const { canEdit, loading } = usePermissionsForProject(process, id)

  const onBack = (featureGroupConfigCode: string) => {
    const route = resolveDetailsRoot(baseUrl).nested.ProjectFeatures.params({ projectId })
    navigate(`${route}#${featureGroupConfigCode}`)
  }

  const projectFeatureBaseWhereClause: Feature_Base_Bool_Exp = useMemo(() => {
    if (process === PROJECT.PF_KAP) {
      return {
        project_bases: { pf_kap_projects: { id: { _eq: id } } },
      }
    } else {
      return {
        project_bases: { pf_pgv_projects: { id: { _eq: id } } },
      }
    }
  }, [process, id])

  const projectFeatureConfigWhereClause: Feature_Config_Bool_Exp = useMemo(() => {
    return {
      processes: {
        _contains: [process],
      },
    }
  }, [process])

  if (!loading && !canEdit) {
    return <NotAuthorized />
  }

  return (
    <FeatureBaseEdit
      featureBaseWhere={projectFeatureBaseWhereClause}
      featureConfigWhere={projectFeatureConfigWhereClause}
      onBack={onBack}
      featureTypeId={parseInt(featureTypeId as string)}
    />
  )
}
