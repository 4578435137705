import { ReactElement } from 'react'
import { useParams } from 'react-router'
import { ProjectImplementationSummary } from 'src/screens/shared/implementation/details/summary/ProjectImplementationSummary'

export const PfPgvImplementationDetailsSummaryPage = (): ReactElement => {
  const { projectId } = useParams()
  const pfPgvId = parseInt(projectId as string)

  return <ProjectImplementationSummary baseUrl="/pf-pgv" projectId={pfPgvId} />
}
