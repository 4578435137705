import { gql } from '@urql/core'

export const queryDossierAndProjectApplicationByProjectBaseId = gql`
  query ($projectBaseQuery: project_base_bool_exp!) {
    dossier(where: { project_bases: $projectBaseQuery }) {
      status
      project_workflow_id
      project_bases {
        project_application_decisions {
          application_decision
          application_comment
          version
        }
        gfch_responsible_id
      }
    }
  }
`

export const queryApplicationCommentByProjectBaseId = gql`
  query ($projectBaseQuery: project_base_bool_exp!) {
    dossier(where: { project_bases: $projectBaseQuery }) {
      project_bases {
        project_application_decisions {
          application_comment
          version
        }
      }
    }
  }
`

export const saveProjectApplicationSummaryComment = gql`
  mutation (
    $projectBaseId: Int!
    $version: Int!
    $projectApplicationDecision: [project_application_decision_insert_input!]!
  ) {
    insert_project_application_decision(
      objects: $projectApplicationDecision
      on_conflict: {
        constraint: project_application_decision_project_base_id_uq
        update_columns: [application_comment]
        where: { _and: { project_base: { id: { _eq: $projectBaseId } }, version: { _eq: $version } } }
      }
    ) {
      returning {
        id
        version
      }
      affected_rows
    }
  }
`
