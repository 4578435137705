import { Box, Tooltip } from '@mui/material'
import { DataGridPro, GridColDef, useGridApiRef } from '@mui/x-data-grid-pro'
import combinedQuery from 'graphql-combine-query'
import { groupBy, sortBy, sumBy } from 'lodash'
import { useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router'
import { InternationalizedObject } from 'src/@types'
import { Feature_Config_Bool_Exp, Feature_Value, Query_Root } from 'src/@types/graphql'
import { useMessageSource } from 'src/i18n/useMessageSource'
import { fetchAllKapMeasureAnnualReportsByMeasureIdQuery } from 'src/screens/kap/implementation/milestone/kapMilestoneQueries'
import { queryKapMeasureFeatureValuesWithKPIs } from 'src/screens/kap/program/details/measures/kapMeasuresQueries'
import { queryFeatureConfigs } from 'src/screens/shared/feature/featureBaseQueries'
import { LEVEL_TYPE, PROJECT } from 'src/shared/constants/constants'
import { HelpAndInstructions } from 'src/shared/presentation/HelpAndInstructions'
import { S } from 'src/shared/styled/S'
import { useGridTranslateHook } from 'src/shared/utils/hooks/grid-translate-hook'
import { useError } from 'src/shared/utils/hooks/page-loading-error-hook'
import { useNotificationService } from 'src/shared/utils/NotificationService'
import { Utils } from 'src/shared/utils/Utils'
import { useUserLocale } from 'src/user/UserContext'
import { useClient } from 'urql'

interface RowData {
  id: number
  featureType: InternationalizedObject
  feature: InternationalizedObject
  actualValue: string | number
  tooltip: InternationalizedObject
  // not displayed as columns
  sortNumber: number
  featureTypeSortNumber: number
}

interface Props {
  measureLevel: LEVEL_TYPE
}

export const KapMeasureFeaturesKpiGrid = ({ measureLevel }: Props) => {
  const { measureId } = useParams()
  const { getMessage } = useMessageSource()
  const gridTranslations = useGridTranslateHook()
  const urqlClient = useClient()
  const language = useUserLocale()
  const apiRef = useGridApiRef()
  const notificationService = useNotificationService()
  const [featureKpis, setFeatureKpis] = useState<(Feature_Value & { actual_value: number })[]>([])
  const setError = useError()

  const kpiRowsData = useMemo(() => {
    const kpisUnsorted = featureKpis.reduce(
      (a, c) => [
        ...a,
        {
          id: c!.id,
          featureType: c.feature_config!.feature_type_config.names as InternationalizedObject,
          feature: c.feature_config!.names as InternationalizedObject,
          otherDescription: c.other_description,
          tooltip: c.feature_config!.tooltips as InternationalizedObject,
          actualValue: c?.actual_value ?? '-',
          featureTypeSortNumber: c.feature_config.feature_type_config.sort_number,
          sortNumber: c.feature_config.sort_number,
        } as RowData,
      ],
      [] as RowData[],
    )

    return sortBy(kpisUnsorted, ['featureTypeSortNumber', 'sortNumber'])
  }, [featureKpis])

  const columns = useMemo(() => {
    return [
      {
        field: 'featureType',
        headerName: getMessage('label.feature.type'),
        sortable: false,
        disableColumnMenu: true,
        flex: 0.6,
        disableColumnResize: true,
        valueFormatter: (params) => {
          const value = params.value as InternationalizedObject
          return value?.[language]
        },
      },
      {
        field: 'feature',
        headerName: getMessage('label.feature'),
        sortable: false,
        disableColumnMenu: true,
        flex: 1,
        renderCell: (params) => {
          let featureName = ''
          const value = params.value as InternationalizedObject
          if (value) {
            // for "Other" features display also the corresponding description
            featureName = params.row.otherDescription
              ? `${value[language]}: ${params.row.otherDescription}`
              : value[language]
          }
          return (
            <>
              <S.Span.TextOverflow $withTooltip>{value && featureName}</S.Span.TextOverflow>
              {params.row.tooltip[language] && (
                <Tooltip placement="right" title={params.row.tooltip[language]}>
                  <S.Icons.GridTooltipInfo />
                </Tooltip>
              )}
            </>
          )
        },
      },
      {
        field: 'actualValue',
        headerName: getMessage('label.actual.value'),
        headerAlign: 'right',
        sortable: false,
        align: 'right',
        disableColumnMenu: true,
        cellClassName: 'kpi-actual-value--cell',
        flex: 0.3,
        renderCell: ({ value }) => {
          return <S.Span.TextOverflow>{value?.toLocaleString('de-CH')}</S.Span.TextOverflow>
        },
      },
    ] as GridColDef[]
  }, [getMessage, language])

  useEffect(() => {
    const fetchData = async () => {
      const featureConfigWhere: Feature_Config_Bool_Exp = {
        processes: {
          _contains: [PROJECT.KAP],
        },
      }

      const featureConfigKPIWhere: Feature_Config_Bool_Exp = {
        has_kpi_for_processes: {
          _contains: [PROJECT.KAP],
        },
        has_kpi_for_levels: {
          _contains: [measureLevel],
        },
      }

      const { document, variables } = combinedQuery('KapMeasureFeatureValuesWithAnnualReportActualValues')
        .add<{
          feature_base: Query_Root['feature_base']
        }>(queryKapMeasureFeatureValuesWithKPIs, {
          measureId: measureId,
          featureConfigKPIWhere: featureConfigKPIWhere,
        })
        .add<{
          feature_config: Query_Root['feature_config']
        }>(queryFeatureConfigs, {
          featureConfigWhere: featureConfigWhere,
        })
        .add<{ kap_measure_kpi_annual_all_reports: Query_Root['kap_measure_kpi_annual_report'] }>(
          fetchAllKapMeasureAnnualReportsByMeasureIdQuery,
          {
            id: measureId,
          },
        )

      const { data, error } = await urqlClient
        .query<{
          feature_base: Query_Root['feature_base']
          feature_config: Query_Root['feature_config']
          kap_measure_kpi_annual_all_reports: Query_Root['kap_measure_kpi_annual_report']
        }>(document, variables)
        .toPromise()

      if (error) {
        notificationService.operationFailed()
      } else if (data) {
        let features = data.feature_base[0].feature_values
        const featureConfigByIds = groupBy(data?.kap_measure_kpi_annual_all_reports, 'feature_config_id')
        const reports = data.kap_measure_kpi_annual_all_reports
        const existingFeatures = data.feature_config
          .filter(
            (config) =>
              reports.some((report) => report.feature_config_id === config.id) &&
              !features.some((feature) => feature.feature_config_id === config.id),
          )
          .map((f, i) => ({
            id: i,
            feature_config_id: f.id,
            feature_config: f,
          })) as Feature_Value[]

        features = features.concat(existingFeatures)
        features = Utils.mergeBy(
          { arr: features, key: 'feature_config_id' },
          { arr: reports, key: 'feature_config_id' },
        )

        const featuresWithActualValue = features.map((feature) => ({
          ...feature,
          actual_value: sumBy(featureConfigByIds[feature.feature_config_id], 'actual_value'),
        }))

        setFeatureKpis(featuresWithActualValue)
      }
    }
    fetchData()
  }, [urqlClient, setError, measureId, measureLevel, notificationService])

  return (
    <Box>
      <HelpAndInstructions labelKey="label.help.kpis.kap" />
      <DataGridPro
        rows={kpiRowsData}
        columns={columns}
        autoHeight
        hideFooter
        apiRef={apiRef}
        localeText={gridTranslations}
        disableColumnReorder
        disableRowSelectionOnClick
        disableColumnSelector
      />
    </Box>
  )
}
