import { LoadingButton } from '@mui/lab'
import { Box, DialogContent, IconButton } from '@mui/material'
import { DataGridPro, GridColDef, GridPaginationModel, GridSortModel, useGridApiRef } from '@mui/x-data-grid-pro'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { EvaluationDetailsResponseData, Mutation_Root, ReportInput } from 'src/@types/graphql'
import { useMessageSource } from 'src/i18n/useMessageSource'
import { resolveDetailsRoot } from 'src/routing/routing-utils'
import { ReportService } from 'src/service/axios/ReportService'
import { PROJECT, PROJECT_TYPE } from 'src/shared/constants/constants'
import { REPORTING_DOWNLOAD, REPORTING_DOWNLOAD_TYPE } from 'src/shared/constants/reporting-constants'
import { DownloadIcon, ExternalIcon } from 'src/shared/icons/Icons'
import { ModalDialog } from 'src/shared/modal-dialog/ModalDialog'
import { HtmlRenderer } from 'src/shared/presentation/HtmlRenderer'
import { saveFileAs } from 'src/shared/utils/BlobUtils'
import { DateUtils } from 'src/shared/utils/DateUtils'
import { useGridTranslateHook } from 'src/shared/utils/hooks/grid-translate-hook'
import { useNotificationService } from 'src/shared/utils/NotificationService'
import { useUserLocale } from 'src/user/UserContext'
import { gql, useClient } from 'urql'

interface ReportEvaluationDetailsProps {
  reportInput: ReportInput
  evaluationType: string | undefined
  process: PROJECT_TYPE
  modalOpen: boolean
  setModalOpen: (v: boolean) => void
}

const fetchEvaluationDetailsReportDataMutation = gql`
  mutation fetchEvaluationDetailsReportData(
    $report: ReportInput!
    $evaluationType: String!
    $isPfKap: Boolean!
    $isPfPgv: Boolean!
  ) {
    fetchPfKapProjectEvaluationDetailsReportData(report: $report, evaluationType: $evaluationType)
      @include(if: $isPfKap) {
      data {
        projectId
        shortTitle
        status
        stepDate
        description
      }
    }
    fetchPfPgvProjectEvaluationDetailsReportData(report: $report, evaluationType: $evaluationType)
      @include(if: $isPfPgv) {
      data {
        projectId
        shortTitle
        status
        stepDate
        description
      }
    }
  }
`

export const ReportEvaluationDetails = ({
  reportInput,
  evaluationType,
  modalOpen,
  setModalOpen,
  process,
}: ReportEvaluationDetailsProps) => {
  const { getMessage } = useMessageSource()
  const apiRef = useGridApiRef()
  const urqlClient = useClient()
  const notificationService = useNotificationService()
  const gridTranslations = useGridTranslateHook()
  const language = useUserLocale()

  const isPfKap = process === PROJECT.PF_KAP
  const isPfPgv = process === PROJECT.PF_PGV
  const baseUrl = isPfKap ? '/pf-kap' : '/pf-pgv'

  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({ page: 0, pageSize: 10 })
  const [sortModel, setSortModel] = useState<GridSortModel>([
    {
      field: 'stepDate',
      sort: 'desc',
    },
  ])
  const [evaluations, setEvaluations] = useState<EvaluationDetailsResponseData[]>([])
  const [loadingDownload, setLoadingDownload] = useState<boolean>(false)

  const fetchData = useCallback(async () => {
    if (evaluationType) {
      const { data } = await urqlClient
        .mutation<
          {
            fetchPfKapProjectEvaluationDetailsReportData: Mutation_Root['fetchPfKapProjectEvaluationDetailsReportData']
            fetchPfPgvProjectEvaluationDetailsReportData: Mutation_Root['fetchPfPgvProjectEvaluationDetailsReportData']
          },
          { report: ReportInput; evaluationType: string; isPfKap: boolean; isPfPgv: boolean }
        >(fetchEvaluationDetailsReportDataMutation, {
          report: reportInput,
          evaluationType: evaluationType,
          isPfKap: isPfKap,
          isPfPgv: isPfPgv,
        })
        .toPromise()

      if (data) {
        const evaluationReportsData =
          process === PROJECT.PF_KAP
            ? data.fetchPfKapProjectEvaluationDetailsReportData.data
            : data.fetchPfPgvProjectEvaluationDetailsReportData.data

        setEvaluations(evaluationReportsData)
      } else {
        notificationService.operationFailed()
      }
    }
  }, [urqlClient, notificationService, process, evaluationType, reportInput, isPfKap, isPfPgv])

  useEffect(() => {
    if (modalOpen && evaluationType) {
      fetchData()
    }
  }, [evaluationType, modalOpen, fetchData])

  const rows = useMemo(
    () =>
      evaluations.map((evaluation, index) => ({
        ...evaluation,
        id: index,
      })),
    [evaluations],
  )

  const handleOpenLink = useCallback(
    (event: any, projectId: string) => {
      event.stopPropagation()
      const url = resolveDetailsRoot(isPfKap ? '/pf-kap' : '/pf-pgv').nested.EffectivityManagement.params({ projectId })
      window.open(url, '_blank')
    },
    [isPfKap],
  )

  const columns = useMemo<GridColDef[]>(
    () => [
      {
        field: 'projectId',
        headerName: getMessage('label.id'),
        flex: 0.5,
        renderCell: ({ value }) => `${getMessage(`label.project.id.character.${process.toLowerCase()}`)}${value}`,
      },
      {
        field: 'shortTitle',
        headerName: getMessage('label.short.title'),
        flex: 2,
      },
      {
        field: 'status',
        headerName: getMessage('label.status'),
        flex: 1,
        renderCell: ({ value }) => getMessage(`label.evaluation.milestone.status.${value}`),
      },
      {
        field: 'stepDate',
        headerName: getMessage('label.step.date'),
        flex: 1,
        renderCell: ({ value }) => DateUtils.formatDate(DateUtils.parseDateGraphQL(value)),
      },
      {
        field: 'description',
        headerName: getMessage('label.description'),
        flex: 2,
        renderCell: ({ value }) => {
          return <HtmlRenderer html={value} truncate variant="body2" />
        },
      },
      {
        field: 'link',
        headerName: getMessage('label.link'),
        flex: 1,
        sortable: false,
        renderCell: ({ row }) => {
          return (
            <IconButton color="primary" onClick={(event) => handleOpenLink(event, row.projectId)}>
              <ExternalIcon />
            </IconButton>
          )
        },
      },
    ],
    [getMessage, handleOpenLink, process],
  )

  const onDownloadHandle = (reportType: REPORTING_DOWNLOAD_TYPE) => async () => {
    if (reportInput && evaluationType) {
      setLoadingDownload(true)
      await ReportService.generateReport(reportInput, baseUrl, reportType, language, { evaluationType })
        .then((response) => {
          saveFileAs(response)
        })
        .catch(() => {
          notificationService.operationFailed()
        })
        .finally(() => {
          setLoadingDownload(false)
        })
    }
  }

  return (
    <>
      {evaluationType && (
        <ModalDialog
          open={modalOpen}
          onClose={() => {
            setModalOpen(false)
            setEvaluations([])
          }}
          title={`${getMessage('label.title.evaluation')}: ${getMessage(
            `label.evaluation.milestone.type.${evaluationType}`,
          )}`}
          maxWidth="lg"
          action={
            <LoadingButton
              variant="text"
              color="primary"
              startIcon={<DownloadIcon />}
              onClick={onDownloadHandle(REPORTING_DOWNLOAD.EVALUATION)}
              loading={loadingDownload}
            >
              {getMessage('button.download')}
            </LoadingButton>
          }
        >
          <DialogContent>
            <Box sx={{ height: 668 }}>
              <DataGridPro
                apiRef={apiRef}
                columns={columns}
                rows={rows}
                localeText={gridTranslations}
                disableColumnResize
                disableColumnSelector
                disableColumnReorder
                disableColumnPinning
                disableRowSelectionOnClick
                disableColumnMenu
                disableColumnFilter
                sortModel={sortModel}
                onSortModelChange={setSortModel}
                disableMultipleColumnsSorting
                paginationModel={paginationModel}
                onPaginationModelChange={setPaginationModel}
                pageSizeOptions={[10, 25, 50]}
                pagination
              />
            </Box>
          </DialogContent>
        </ModalDialog>
      )}
    </>
  )
}
