import { Alert, Box, Button, Card, CardContent, Stack, Typography } from '@mui/material'
import { Search } from 'history'
import { ReactElement, useMemo, useState } from 'react'
import { useLocation } from 'react-router'
import { useEnvironment } from 'src/env/EnvironmentStore'
import { useMessageSource } from 'src/i18n/useMessageSource'
import { ROUTES } from 'src/routing/routes'
import { RegisterNewUserModal } from 'src/screens/public/register/RegisterNewUserModal'
import { APP_TITLE } from 'src/shared/constants/constants'
import { ExternalIcon } from 'src/shared/icons/Icons'
import { ModalDialog } from 'src/shared/modal-dialog/ModalDialog'
import { useDelayedNavigate, useNavigateToLogin } from 'src/shared/utils/hooks/navigation-hooks'
import { useUserLocale } from 'src/user/UserContext'

const resolveSuccess = (search: Search): boolean => new URLSearchParams(search).has('success')

const resolveEmail = (search: Search): string | null => new URLSearchParams(search).get('email')

const resolveModalOpen = (search: Search): string | null => new URLSearchParams(search).get('open')

export const PublicLandingPage = (): ReactElement => {
  const { getMessage } = useMessageSource()
  const login = useNavigateToLogin()
  const { backendUrl } = useEnvironment()
  const language = useUserLocale()

  const { search } = useLocation()

  const showSuccess = useMemo(() => {
    return resolveSuccess(search)
  }, [search])

  const navigate = useDelayedNavigate()
  const [open, setOpen] = useState(() => !!resolveEmail(search) || !!resolveModalOpen(search))

  const initialValues = useMemo(
    () => ({
      email: resolveEmail(search) || '',
    }),
    [search],
  )

  const handleCancel = () => {
    setOpen(false)
  }

  const handleSuccess = () => {
    setOpen(false)
    navigate(`/${ROUTES.PublicLandingPage.path}?success`)
  }

  const handleOpen = () => {
    setOpen(true)
  }

  const handleOpenTermsAndConditions = () => {
    window.open(`${backendUrl}/api/public/terms-and-conditions?language=${language.toUpperCase()}`, '_blank')
  }

  return (
    <Box display="grid" flexDirection="column" justifyItems="center" justifyContent="center" height="100%">
      <Box alignSelf="center">
        <Card>
          <CardContent>
            <Stack spacing={3} p={2} width="400px">
              <Typography variant="h1">{APP_TITLE}</Typography>
              <Button variant="contained" color="primary" onClick={login}>
                {getMessage('button.login')}
              </Button>
              <Button variant="outlined" color="primary" onClick={handleOpen}>
                {getMessage('button.register')}
              </Button>
              <Box hidden={!showSuccess}>
                <Alert severity="success">{getMessage('label.registration.success.instructions')}</Alert>
              </Box>

              <Button color="primary" startIcon={<ExternalIcon />} onClick={handleOpenTermsAndConditions}>
                {getMessage('label.terms.and.conditions')}
              </Button>
            </Stack>
          </CardContent>
        </Card>
      </Box>
      <ModalDialog open={open} onClose={handleCancel} title={getMessage('label.register.new.user.title')} maxWidth="md">
        <RegisterNewUserModal initialValues={initialValues} onCancel={handleCancel} onSuccess={handleSuccess} />
      </ModalDialog>
    </Box>
  )
}
