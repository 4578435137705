import { Box, Typography } from '@mui/material'
import { DataGridPro, GridColDef, GridRowModel, GridSortModel } from '@mui/x-data-grid-pro'
import { ReactElement, useMemo, useState } from 'react'
import { Kap_Measure } from 'src/@types/graphql'
import { useMessageSource } from 'src/i18n/useMessageSource'
import { ROUTES } from 'src/routing/routes'
import { S } from 'src/shared/styled/S'
import { useGridTranslateHook } from 'src/shared/utils/hooks/grid-translate-hook'
import styled from 'styled-components/macro'

interface Props {
  measures: Kap_Measure[]
}

const DataGridProStyled = styled(DataGridPro)`
  & .MuiDataGrid-row {
    &:hover {
      cursor: pointer;
    }
  }
`

export const FactsheetLinkedKapMeasuresGrid = ({ measures }: Props): ReactElement => {
  const { getMessage } = useMessageSource()
  const gridTranslations = useGridTranslateHook()
  const [sortModel, setSortModel] = useState<GridSortModel>([
    {
      field: 'id',
      sort: 'desc',
    },
  ])

  const rows = useMemo(() => {
    return measures.map((measure) => {
      return {
        id: measure.id,
        measureShortTitle: measure.short_title,
        level: measure.level,
        modules: measure.modules,
        program: {
          programId: measure.kap_program.id,
          programShortTitle: measure.kap_program.dossier.short_title,
        },
        status: measure.kap_program.dossier.status,
      }
    })
  }, [measures])

  const columns = useMemo(() => {
    return [
      {
        field: 'id',
        headerName: getMessage('label.measure.id'),
        flex: 0.2,
        renderCell: ({ row }) => `${getMessage(`label.kap.measure.id.character.${row.level}`)}${row.id}`,
      },
      {
        field: 'measureShortTitle',
        headerName: getMessage('label.short.title'),
        flex: 0.7,
      },
      {
        field: 'level',
        headerName: getMessage('label.level'),
        flex: 0.3,
        renderCell: ({ value }) => `${getMessage(`label.level.${value}`)}`,
      },
      {
        field: 'modules',
        headerName: getMessage('label.modules'),
        flex: 0.2,
      },
      {
        field: 'program',
        headerName: getMessage('label.program'),
        flex: 0.7,
        sortable: false,
        renderCell: (params: any) => {
          return (
            <S.DataGrid.TwoLineCell>
              <Typography variant="subtitle2">{params.value.programShortTitle}</Typography>
              <Typography variant="caption" color="textSecondary">
                {getMessage('label.program.id.character')}
                {params.value.programId}
              </Typography>
            </S.DataGrid.TwoLineCell>
          )
        },
      },
      {
        field: 'status',
        headerName: getMessage('label.project.status'),
        flex: 0.3,
        renderCell: ({ value }) => `${getMessage(`label.project.status.${value}`)}`,
      },
    ] as GridColDef[]
  }, [getMessage])

  const handleRowClick = (params: GridRowModel) => {
    const measureId = params?.row?.id
    const programId = params?.row?.program.programId

    // We don't use useDelayedNavigate here because the kap measure needs to open in a new window
    window.open(ROUTES.KapMeasureDetailsRoot.params({ programId, measureId }))
  }

  return (
    <Box>
      <DataGridProStyled
        rows={rows}
        columns={columns}
        autoHeight
        localeText={gridTranslations}
        disableRowSelectionOnClick
        disableColumnReorder
        disableColumnFilter
        disableColumnPinning
        onRowClick={handleRowClick}
        sortModel={sortModel}
        onSortModelChange={(model) => setSortModel(model)}
      />
    </Box>
  )
}
