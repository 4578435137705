import { memo, ReactNode, useEffect, useState } from 'react'
import { EnvironmentService } from 'src/env/EnvironmentService'
import { EnvironmentStore } from 'src/env/EnvironmentStore'
import { Axios } from 'src/service/axios/axios'
import { GeneralSystemErrorAlert } from 'src/shared/alert/GeneralSystemErrorAlert'

export const EnvironmentLoader = memo(({ children }: { children: ReactNode }) => {
  const [{ isLoading, hasError }, setState] = useState({ isLoading: true, hasError: false })

  useEffect(() => {
    const fetchAndSetAppConfig = async (): Promise<void> => {
      try {
        const { backendUrl, graphqlUrl, reCaptchaSiteKey, environment } = await EnvironmentService.loadEnvironment()
        EnvironmentStore.backendUrl = backendUrl
        EnvironmentStore.graphqlUrl = graphqlUrl
        EnvironmentStore.reCaptchaSiteKey = reCaptchaSiteKey
        EnvironmentStore.environment = environment
        Axios.setAxios(backendUrl)
        setState((state) => ({ ...state, isLoading: false }))
      } catch {
        setState((state) => ({ ...state, hasError: true }))
      }
    }

    fetchAndSetAppConfig()
  }, [])

  return (
    <>
      {!isLoading && !hasError && children}
      {/* this can happen in PROD only if the connection to the static environment.json file hosted on nginx fails */}
      {hasError && <GeneralSystemErrorAlert />}
    </>
  )
})
