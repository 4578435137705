import { Chip, Typography } from '@mui/material'
import { TEXT_OVERFLOW_MIXIN } from 'src/shared/constants/styling-constants'
import { CardContentStyled } from 'src/shared/styled/card/CardStyled'
import styled from 'styled-components/macro'

export const JournalAndEvaluationMilestoneCardContentStyled = styled(CardContentStyled)`
  cursor: pointer;
`

export const JournalAndEvaluationMilestoneCardItemContainerStyled = styled.div`
  flex-grow: 1;
  display: grid;
  grid-template-columns: 5fr auto 0.9fr 0.1fr;
  grid-gap: ${({ theme }) => theme.spacing(2)};
  align-items: center;
`

export const JournalAndEvaluationMilestoneCardItemTitleStyled = styled(Typography)`
  ${TEXT_OVERFLOW_MIXIN};
`

export const JournalAndEvaluationMilestoneCardItemDateStyled = styled(Typography)`
  color: ${({ theme }) => theme.colors.text.secondary};
  ${TEXT_OVERFLOW_MIXIN};
`

export const JournalAndEvaluationMilestoneCardItemStatusChipStyled = styled(Chip)`
  cursor: pointer;
  ${TEXT_OVERFLOW_MIXIN};
`
