import { Typography } from '@mui/material'
import combinedQuery from 'graphql-combine-query'
import { sortBy } from 'lodash'
import { ReactElement, useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { Query_Root } from 'src/@types/graphql'
import { useMessageSource } from 'src/i18n/useMessageSource'
import { MilestoneRelatedGoalsMeasuresGrid } from 'src/screens/shared/implementation/details/milestone/details/annual/MilestoneRelatedGoalsMeasuresGrid'
import { queryGoalsAndMeasuresAnnualReport } from 'src/screens/shared/implementation/details/milestoneQueries'
import { queryProjectGoalById } from 'src/screens/shared/project/details/goals-and-measures/goal/goalQueries'
import { ProjectGoalAnnualReport } from 'src/shared/constants/milestone-constants'
import { DefaultSectionTypography } from 'src/shared/presentation/DefaultSectionTypography'
import { HtmlRenderer } from 'src/shared/presentation/HtmlRenderer'
import { ReadOnlySelection, ReadOnlyTextField } from 'src/shared/presentation/ReadOnly'
import { useNotificationService } from 'src/shared/utils/NotificationService'
import { resolveGoalRatingColor } from 'src/shared/utils/StylingUtils'
import { Utils } from 'src/shared/utils/Utils'
import { useClient } from 'urql'

interface Props {
  goalId: number
}

export const MilestoneGoalCardContent = ({ goalId }: Props): ReactElement => {
  const [goal, setGoal] = useState<ProjectGoalAnnualReport>()
  const { milestoneId } = useParams()
  const { getMessage } = useMessageSource()
  const urqlClient = useClient()
  const notificationService = useNotificationService()
  const milestone_id = parseInt(milestoneId as string)

  useEffect(() => {
    const initData = async () => {
      const { document, variables } = combinedQuery('projectGoalAndAnnualReportAndMilestone')
        .add<{ project_goal_by_pk: Query_Root['project_goal_by_pk'] }, { id: number }>(queryProjectGoalById, {
          id: goalId,
        })
        .add<
          {
            project_goal_annual_report: Query_Root['project_goal_annual_report']
            project_measure_annual_report: Query_Root['project_measure_annual_report']
          },
          { milestoneId: number }
        >(queryGoalsAndMeasuresAnnualReport, { milestoneId: milestone_id })

      const { data } = await urqlClient
        .query<{
          project_goal_by_pk: Query_Root['project_goal_by_pk']
          project_goal_annual_report: Query_Root['project_goal_annual_report']
          project_measure_annual_report: Query_Root['project_measure_annual_report']
        }>(document, variables)
        .toPromise()

      if (data && data?.project_goal_by_pk && data?.project_goal_annual_report) {
        const goal = data?.project_goal_by_pk
        const linkedMeasuresIds = goal.project_goal_measures.map((m) => m.project_measure.id)
        const goalReport = data?.project_goal_annual_report?.filter((p) => p.project_goal_id === goalId)[0]
        const measuresAnnualReport = data?.project_measure_annual_report.filter((pg) =>
          linkedMeasuresIds.some((id) => id === pg.project_measure_id),
        )
        const goalMeasures = goal.project_goal_measures.map((x) => x.project_measure)
        const mergedMeasuresAndReports = Utils.mergeBy(
          { arr: goalMeasures, key: 'id' },
          { arr: measuresAnnualReport, key: 'project_measure_id' },
        )

        setGoal({
          goal_id: goal.id,
          name: goal.name,
          description: goal.description,
          rating: goalReport?.rating ?? null,
          rating_comment: goalReport?.rating_comment ?? '',
          goal_measures: sortBy(mergedMeasuresAndReports, ['sort_number']),
          sort_number: goal.sort_number,
        })
      } else {
        notificationService.operationFailed()
      }
    }
    initData()
  }, [urqlClient, notificationService, milestone_id, goalId])

  return (
    <>
      {goal && (
        <>
          <ReadOnlyTextField text={getMessage('label.description')}>
            {goal.description && <HtmlRenderer html={goal.description} />}
          </ReadOnlyTextField>
          <ReadOnlyTextField text={getMessage('label.goal.rating')}>
            {goal.rating && (
              <Typography color={resolveGoalRatingColor(goal.rating)}>
                {goal.rating && getMessage(`label.goal.rating.${goal.rating}`)}
              </Typography>
            )}
          </ReadOnlyTextField>
          <ReadOnlyTextField text={getMessage('label.goal.rating.comment')}>
            {goal.rating_comment && <HtmlRenderer html={goal.rating_comment} />}
          </ReadOnlyTextField>
          <ReadOnlySelection text={getMessage('label.related.measures')} isLast>
            {goal.goal_measures && goal.goal_measures.length > 0 ? (
              <MilestoneRelatedGoalsMeasuresGrid values={goal.goal_measures ?? []} type="measure" />
            ) : (
              <DefaultSectionTypography
                noEntriesMessageKey={getMessage('label.not.available.measures')}
                $standAlone={true}
              />
            )}
          </ReadOnlySelection>
        </>
      )}
    </>
  )
}
