import { Location } from 'history'
import { useEffect, useState } from 'react'
import { Outlet, useLocation, useParams } from 'react-router'
import { ROUTES } from 'src/routing/routes'
import { NestedRoute, Route, SecondLevelRoute } from 'src/routing/routing'
import { SecondLevelNavigation } from 'src/routing/SecondLevelNavigation'
import { PageLayout } from 'src/shared/layout/PageLayout'
import { SecondLevelNavigationContainer } from 'src/shared/layout/SecondLevelNavigationContainer'

const resolveActiveRoute = (location: Location, roundId: string): SecondLevelRoute => {
  const nested = ROUTES.RoundManagementDetailsRoot!.nested! as unknown as NestedRoute
  const activeRoute = Object.keys(nested).find(
    (key) =>
      location.pathname.endsWith(nested[key].path) ||
      (ROUTES.RoundManagementDetailsRoot.params({ roundId }) === location.pathname && nested[key].primary),
  ) as string
  return nested[activeRoute] as SecondLevelRoute
}

export const RoundManagementDetailsIndexPage = () => {
  const location = useLocation()
  const { roundId } = useParams()

  const [activeRoute, setActiveRoute] = useState<SecondLevelRoute>(() => {
    return resolveActiveRoute(location, roundId as string)
  })

  useEffect(() => {
    const activeRoute = resolveActiveRoute(location, roundId as string)
    setActiveRoute(activeRoute)
  }, [location, roundId])

  const showNavigation = activeRoute && !activeRoute.hideNavigation

  return (
    <SecondLevelNavigationContainer $showNavigation={showNavigation}>
      {showNavigation && (
        <PageLayout>
          <SecondLevelNavigation
            activeRoute={activeRoute}
            route={ROUTES.RoundManagementDetailsRoot as unknown as Route}
            baseUrl={ROUTES.RoundManagementDetailsRoot.params({ roundId })}
          />
        </PageLayout>
      )}
      <Outlet />
    </SecondLevelNavigationContainer>
  )
}
