import { Box } from '@mui/material'
import arrayMutators from 'final-form-arrays'
import { Form } from 'react-final-form'
import { CommitteeValues } from 'src/screens/shared/application/committee/ApplicationCommiteeUtils'
import { CommitteeDecisions } from 'src/screens/shared/application/committee/common/CommitteeDecisions'
import { Option } from 'src/shared/form/control'
import { DirtyFormSpy } from 'src/shared/form/dirty/DirtyFormSpy'
import { createDecorators } from 'src/shared/form/utils/decorators'

interface Props {
  locale: string
  initialValues: CommitteeValues | undefined
  exclusionCriteriaOptions: Option[]
  qualityCriteriaOptions: Option[]
  gfchResponsibleOptions: Option[]
  onSave: (values: CommitteeValues) => Promise<void>
}

export const CommitteeEditForm = ({
  locale,
  initialValues,
  qualityCriteriaOptions,
  exclusionCriteriaOptions,
  gfchResponsibleOptions,
  onSave,
}: Props) => {
  const decorators = createDecorators()
  return (
    <Box>
      <Form<CommitteeValues>
        onSubmit={onSave}
        mutators={{ ...arrayMutators }}
        initialValues={initialValues}
        decorators={decorators}
        render={({ handleSubmit, values }) => {
          return (
            <form id="committees-form" onSubmit={handleSubmit}>
              <CommitteeDecisions
                locale={locale}
                values={values}
                qualityCriteriaOptions={qualityCriteriaOptions}
                exclusionCriteriaOptions={exclusionCriteriaOptions}
                gfchResponsibleOptions={gfchResponsibleOptions}
              />
              <DirtyFormSpy />
            </form>
          )
        }}
      />
    </Box>
  )
}
