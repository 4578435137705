import { Box } from '@mui/material'
import { GridRowSelectionModel } from '@mui/x-data-grid-pro'
import { groupBy } from 'lodash'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router'
import { Pf_Kap_Project, Pf_Pgv_Project, Project_Assessment, Query_Root } from 'src/@types/graphql'
import { useMessageSource } from 'src/i18n/useMessageSource'
import { ROUTES } from 'src/routing/routes'
import { AssessorManagementGrid } from 'src/screens/administration/round-management/details/assessor-management/AssessorManagementGrid'
import { AssessorManagementModal } from 'src/screens/administration/round-management/details/assessor-management/AssessorManagementModal'
import {
  getProjectsAndAssessorsQuery,
  getRoundInformationById,
} from 'src/screens/administration/round-management/round-management-queries'
import { AddButton } from 'src/shared/button/Buttons'
import { PROJECT, PROJECT_TYPE } from 'src/shared/constants/constants'
import { AddIcon } from 'src/shared/icons/Icons'
import { PageLayout } from 'src/shared/layout/PageLayout'
import { ScreenLayout } from 'src/shared/layout/ScreenLayout'
import { RoundManagementExportMenu } from 'src/shared/menu/RoundManagementExportMenu'
import { ModalDialog } from 'src/shared/modal-dialog/ModalDialog'
import { HelpAndInstructions } from 'src/shared/presentation/HelpAndInstructions'
import { Section } from 'src/shared/presentation/Section'
import { useDelayedNavigate } from 'src/shared/utils/hooks/navigation-hooks'
import { useNotificationService } from 'src/shared/utils/NotificationService'
import { useClient, useQuery } from 'urql'

export interface ProjectViewModel {
  id: number
  pid: number
  project: string
  process: PROJECT_TYPE
}

export interface AssessmentViewModel {
  projectBaseId: number
  instance: string
  instance_key: string
  process: string
  user: {
    firstName: string
    lastName: string
    email: string
  }
}

export const AssessorManagementPage = () => {
  const navigate = useDelayedNavigate()
  const { roundId } = useParams()
  const urqlClient = useClient()
  const notificationService = useNotificationService()
  const { getMessage } = useMessageSource()

  const [projects, setProjects] = useState<ProjectViewModel[]>([])
  const [openModal, setOpenModal] = useState<boolean>(false)

  const onBackHandler = () => {
    navigate(`/${ROUTES.RoundManagementIndex.path}`)
  }

  const [{ data, fetching }] = useQuery<{
    funding_round: Query_Root['funding_round']
  }>({
    query: getRoundInformationById,
    variables: { roundId },
  })

  const roundName = data?.funding_round?.[0].name ?? ''
  const process = useMemo(() => data?.funding_round?.[0].process as PROJECT_TYPE, [data])
  const [selectionModel, setSelectionModel] = useState<GridRowSelectionModel>([])

  const projectToProjectViewModel = (
    projects: Pf_Kap_Project[] | Pf_Pgv_Project[],
    process: PROJECT_TYPE,
  ): ProjectViewModel[] =>
    projects.map((p) => ({
      id: p.project_base.id,
      pid: p.id,
      project: p.project_base.dossier.short_title,
      process: process,
    }))

  const projectAssessmentToAssessmentViewModel = (assessments: Project_Assessment[]): AssessmentViewModel[] =>
    assessments.map((a) => ({
      projectBaseId: a.project_base_id,
      instance: a.assessment_instance_type.code,
      instance_key: a.assessment_instance_type.key,
      process: a.assessment_instance_type.process,
      user: {
        firstName: a.user.first_name ?? '',
        lastName: a.user.last_name ?? '',
        email: a.user.email ?? '',
      },
    }))

  const initData = useCallback(async () => {
    const { data, error } = await urqlClient
      .query<{
        project_assessment: Query_Root['project_assessment']
        pf_kap_project: Query_Root['pf_kap_project']
        pf_pgv_project: Query_Root['pf_pgv_project']
      }>(getProjectsAndAssessorsQuery, {
        process: process,
        isPfKap: process === PROJECT.PF_KAP,
        isPfPgv: process === PROJECT.PF_PGV,
        roundId,
      })
      .toPromise()

    if (data) {
      let projects: ProjectViewModel[] = []

      if (process === PROJECT.PF_KAP && data?.pf_kap_project) {
        projects = projectToProjectViewModel(data?.pf_kap_project, PROJECT.PF_KAP)
      } else if (process === PROJECT.PF_PGV && data?.pf_pgv_project) {
        projects = projectToProjectViewModel(data?.pf_pgv_project, PROJECT.PF_PGV)
      }

      const assessments = projectAssessmentToAssessmentViewModel(data?.project_assessment)

      const assessmentManagementList = projects.map((project) => ({
        ...project,
        ...groupBy(
          assessments.filter((assessment) => assessment.projectBaseId === project.id),
          'instance',
        ),
      }))
      setProjects(assessmentManagementList)
    } else if (!fetching && error) {
      notificationService.operationFailed()
    }
  }, [urqlClient, process, fetching, notificationService, roundId])

  useEffect(() => {
    initData()
  }, [initData])

  const onSuccess = () => {
    initData()
    setOpenModal(false)
  }

  const onRowSelection = (selection: GridRowSelectionModel) => {
    setSelectionModel(selection)
  }

  return (
    <ScreenLayout title={roundName} onBack={onBackHandler} actions={<RoundManagementExportMenu />}>
      <PageLayout>
        <Section
          id="assessor-management"
          title={getMessage('label.navigation.assessor.management')}
          actionButton={
            <AddButton
              messageKey={getMessage('label.add.assessors')}
              startIcon={<AddIcon />}
              onClick={() => setOpenModal(true)}
              disabled={selectionModel.length === 0}
            />
          }
          helpAndInstructions={<HelpAndInstructions labelKey="label.help.assessor.management" />}
        >
          <Box mt={2}>
            <AssessorManagementGrid projects={projects} onRowSelection={onRowSelection} />
          </Box>
        </Section>
        <ModalDialog
          open={openModal}
          title={getMessage('label.add.assessors')}
          maxWidth="sm"
          withCloseIcon={false}
          onClose={() => setOpenModal(false)}
        >
          <AssessorManagementModal
            onCancel={() => setOpenModal(false)}
            onSuccess={onSuccess}
            process={process}
            selections={selectionModel}
          />
        </ModalDialog>
      </PageLayout>
    </ScreenLayout>
  )
}
