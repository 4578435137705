import { ReactElement, useEffect, useMemo, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Mutation_Root, Project_Base_Bool_Exp, Query_Root } from 'src/@types/graphql'
import { useMessageSource } from 'src/i18n/useMessageSource'
import { resolveImplementationDetailsRoot } from 'src/routing/routing-utils'
import { SummaryCommentEditForm } from 'src/screens/shared/common/SummaryCommentEditForm'
import {
  queryImplementationCommentByProjectBaseId,
  updateImplementationComment,
} from 'src/screens/shared/implementation/details/summary/implementationSummaryQueries'
import { usePermissionsForProjectImplementation } from 'src/service/security/PermissionHook'
import { SaveAndBackButton, SaveButton } from 'src/shared/button/Buttons'
import { PROJECT } from 'src/shared/constants/constants'
import { PageLayout } from 'src/shared/layout/PageLayout'
import { ScreenLayout } from 'src/shared/layout/ScreenLayout'
import { NotAuthorized } from 'src/shared/not-authorized/NotAuthorized'
import { HelpAndInstructions } from 'src/shared/presentation/HelpAndInstructions'
import { useDelayedNavigate } from 'src/shared/utils/hooks/navigation-hooks'
import { useIsMounted } from 'src/shared/utils/hooks/react-hooks'
import { useNotificationService } from 'src/shared/utils/NotificationService'
import { resolveProcessToLowerCase, Utils } from 'src/shared/utils/Utils'
import { useClient } from 'urql'

interface Props {
  baseUrl: '/pf-kap' | '/pf-pgv'
}

type originType = 'SAVE' | 'SAVE_AND_BACK'

type ImplementationSummaryCommentData = {
  comment: string
}

export const ImplementationSummaryCommentEditPage = ({ baseUrl }: Props): ReactElement => {
  const { getMessage } = useMessageSource()
  const navigate = useDelayedNavigate()
  const { projectId } = useParams()
  const originRef = useRef<originType>('SAVE')
  const projectType = Utils.resolveProcess(baseUrl)
  const [implementationSummaryComment, setImplementationSummaryComment] =
    useState<ImplementationSummaryCommentData | null>(null)
  const process = resolveProcessToLowerCase(baseUrl)
  const id = parseInt(projectId as string)
  const urqlClient = useClient()
  const notificationService = useNotificationService()
  const isMounted = useIsMounted()

  const { loading, canEdit } = usePermissionsForProjectImplementation(projectType, parseInt(projectId as string))

  const projectPfKapBaseWhereClause: Project_Base_Bool_Exp = useMemo(() => {
    return {
      pf_kap_projects: {
        id: {
          _eq: id,
        },
      },
    }
  }, [id])

  const projectPfPgvBaseWhereClause: Project_Base_Bool_Exp = useMemo(() => {
    return {
      pf_pgv_projects: {
        id: {
          _eq: id,
        },
      },
    }
  }, [id])

  const projectBaseQuery: Project_Base_Bool_Exp = useMemo(() => {
    return {
      ...(projectType === PROJECT.PF_KAP && projectPfKapBaseWhereClause),
      ...(projectType === PROJECT.PF_PGV && projectPfPgvBaseWhereClause),
    }
  }, [projectType, projectPfKapBaseWhereClause, projectPfPgvBaseWhereClause])

  useEffect(() => {
    const initData = async () => {
      const { data, error } = await urqlClient
        .query<{ dossier: Query_Root['dossier'] }>(queryImplementationCommentByProjectBaseId, {
          projectBaseQuery,
        })
        .toPromise()

      if (data?.dossier) {
        const dossier = data.dossier[0]

        const implementationCommentDataFetched = {
          comment: dossier.project_bases[0].implementation_comment ?? '',
        }

        setImplementationSummaryComment(implementationCommentDataFetched)
      } else if (error) {
        notificationService.operationFailed()
      }
    }
    initData()
  }, [baseUrl, notificationService, id, projectType, urqlClient, projectBaseQuery])

  const onSubmit = (origin: originType) => (_: any) => {
    // trigger submit event
    if (originRef) {
      originRef.current = origin
    }
    document.getElementById('edit-form')?.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }))
  }

  const updateHandler = async (values: any) => {
    const updateObject = {
      comment: values.comment,
      projectBaseQuery: projectBaseQuery,
    }

    const { error, data } = await urqlClient
      .mutation<{
        update_project_base: Mutation_Root['update_project_base']
      }>(updateImplementationComment, updateObject)
      .toPromise()

    if (error || data?.update_project_base?.affected_rows !== 1) {
      notificationService.operationFailed()
    } else if (isMounted()) {
      const newInitialValues = { ...values }
      setImplementationSummaryComment(newInitialValues)
      notificationService.changesSaved()
      if (originRef.current === 'SAVE_AND_BACK') {
        navigate(resolveImplementationDetailsRoot(baseUrl).nested.Summary.params({ projectId }))
      }
    }
  }

  const onBack = () => {
    navigate(resolveImplementationDetailsRoot(baseUrl).nested.Summary.params({ projectId }))
  }

  if (!loading && !canEdit) {
    return <NotAuthorized inEditPage />
  }

  return (
    <ScreenLayout
      title={getMessage('label.title.edit.comments')}
      onBack={onBack}
      hasSecondLevelNavigation={false}
      actions={
        <>
          <SaveAndBackButton origin="header" onClick={onSubmit('SAVE_AND_BACK')} />
          <SaveButton origin="header" onClick={onSubmit('SAVE')} />
        </>
      }
    >
      <PageLayout>
        <>
          {!loading && implementationSummaryComment && (
            <>
              <HelpAndInstructions labelKey={`label.help.application.summary.comments.${process}`} defaultExpansion />
              <SummaryCommentEditForm comment={implementationSummaryComment.comment} onSave={updateHandler} />
            </>
          )}
        </>
      </PageLayout>
    </ScreenLayout>
  )
}
