import { Box, Stack, Typography } from '@mui/material'
import { ReactElement } from 'react'
import { useParams } from 'react-router'
import { ROUTES } from 'src/routing/routes'
import { MEASURE_CHAR } from 'src/shared/constants/constants'
import { KapMeasureAndValid } from 'src/shared/constants/milestone-constants'
import { DefaultSectionTypography } from 'src/shared/presentation/DefaultSectionTypography'
import { ExpandableGoalMeasureCard } from 'src/shared/presentation/ExpandableGoalMeasureCard'
import { S } from 'src/shared/styled/S'

interface ChildrenProps {
  measureId: number
}

interface Props {
  idCharacter: string
  title: string
  measures: KapMeasureAndValid[]
  canEditMeasure: boolean
  onEdit: (measureId: number) => void
  children: ({ measureId }: ChildrenProps) => ReactElement
}

export const KapMilestoneMeasureGroup = ({
  idCharacter,
  title,
  measures,
  canEditMeasure,
  onEdit,
  children,
}: Props): ReactElement => {
  const { programId } = useParams()

  const onNavigateHandle = (measureId: number) => {
    const route = ROUTES.KapMeasureDetailsRoot.nested.Description.params({ programId, measureId })
    window.open(route)
  }

  return (
    <>
      <Box>
        <Typography variant="h3" color="textSecondary" mb={1.5}>
          {title}
        </Typography>
        <Stack spacing={1}>
          {' '}
          {measures && measures.length > 0 ? (
            measures.map((measure: KapMeasureAndValid) => (
              <ExpandableGoalMeasureCard
                key={measure.id}
                type={MEASURE_CHAR}
                title={measure.short_title}
                id={`${idCharacter}${measure.sort_number}`}
                entityId={measure.id}
                valid={measure.milestoneMeasureValid}
                onNavigate={() => onNavigateHandle(measure.id)}
                onEdit={() => onEdit(measure.id)}
                canEdit={canEditMeasure}
                modules={measure.modules}
              >
                <>{children({ measureId: measure.id })}</>
              </ExpandableGoalMeasureCard>
            ))
          ) : (
            <S.Card.Container $nonClickable={true}>
              <S.Card.Content>
                <DefaultSectionTypography type="TEXT_FIELD" $standAlone={true} />
              </S.Card.Content>
            </S.Card.Container>
          )}
        </Stack>
      </Box>
    </>
  )
}
