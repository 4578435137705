import {
  Add,
  AddCircleOutline,
  ArrowBack,
  ArrowCircleUp,
  ArrowDownward,
  ArrowForward,
  ArrowUpward,
  Assessment,
  Assignment,
  AssignmentLate,
  AssignmentTurnedIn,
  Check,
  CheckCircle,
  ChevronRight,
  Circle,
  Close,
  CloudUpload,
  ContentCopy,
  Create,
  DashboardOutlined,
  DeleteForever,
  Description,
  Edit,
  ErrorOutline,
  EventNote,
  ExpandMore,
  Favorite,
  FavoriteBorder,
  FileDownload,
  FileUpload,
  Flag,
  Info,
  InfoOutlined,
  InsertDriveFile,
  InsertInvitation,
  Language,
  Launch,
  Link,
  LocalLibraryOutlined,
  Login,
  Logout,
  ManageAccounts,
  MonitorHeartOutlined,
  Person,
  PersonAdd,
  PlaylistAddCheck,
  Receipt,
  Replay,
  Save,
  Search,
  Settings,
  Star,
  StarBorder,
  Timelapse,
} from '@mui/icons-material'

export const CreateIcon = Create
export const ArrowBackIcon = ArrowBack
export const EditIcon = Edit
export const DeleteIcon = DeleteForever
export const SaveIcon = Save
export const AddIcon = Add
export const MoveUpIcon = ArrowUpward
export const MoveDownIcon = ArrowDownward
export const PersonAddIcon = PersonAdd
export const ExpandMoreIcon = ExpandMore
export const InfoIcon = Info
export const PersonIcon = Person
export const ExternalIcon = Launch
export const CloseIcon = Close
export const CheckIcon = Check
export const InfoOutlinedIcon = InfoOutlined
export const UploadIcon = FileUpload
export const DownloadIcon = FileDownload
export const CloudUploadIcon = CloudUpload
export const LinkIcon = Link
export const DocumentIcon = InsertDriveFile
export const GoToPageIcon = ChevronRight
export const ErrorOutlineIcon = ErrorOutline
export const DescriptionIcon = Description
export const SettingsIcon = Settings
export const LogoutIcon = Logout
export const EvaluationSuccessIcon = AssignmentTurnedIn
export const EvaluationWarningIcon = AssignmentLate
export const FactsheetIcon = Receipt
export const ReportIcon = Assessment
export const AssignmentIcon = Assignment
export const LanguageIcon = Language
export const ValidationIcon = CheckCircle
export const DashboardIcon = DashboardOutlined
export const SearchIcon = Search
export const ResetIcon = Replay
export const AddCircleIcon = AddCircleOutline
export const ListSelectionIcon = PlaylistAddCheck
export const BibliographyIcon = LocalLibraryOutlined
export const FullHeartIcon = Favorite
export const EmptyHeartIcon = FavoriteBorder
export const FullStarIcon = Star
export const EmptyStarIcon = StarBorder
export const JournalIcon = InsertInvitation
export const EvaluationMilestoneIcon = MonitorHeartOutlined
export const AccountIcon = ManageAccounts
export const LoginIcon = Login
export const GoalIcon = Flag
export const CalendarIcon = EventNote
export const ArrowForwardIcon = ArrowForward
export const InProgressIcon = Timelapse
export const BulletIcon = Circle
export const CopyIcon = ContentCopy
export const GoToTopIcon = ArrowCircleUp
