import { gql } from 'urql'

export const fetchKapMeasuresByProgramId = gql`
  query ($programId: Int!) {
    kap_program_by_pk(id: $programId) {
      modules
    }
    kap_measure(where: { kap_program_id: { _eq: $programId } }, order_by: { sort_number: asc }) {
      id
      name
      sort_number
      short_title
      level
      modules
      valid
      kap_goal_measures {
        kap_goal {
          sort_number
        }
      }
      kap_measure_annual_plannings {
        id
      }
      kap_measure_kpi_annual_plannings {
        id
      }
      kap_measure_annual_reports {
        id
      }
      kap_measure_kpi_annual_reports {
        id
      }
    }
  }
`

export const deleteKapMeasureByMeasureId = gql`
  mutation ($measureId: Int!) {
    delete_kap_measure_by_pk(id: $measureId) {
      id
    }
  }
`

export const updateKapMeasureSortNumbers = gql`
  mutation ($measure1Id: Int!, $measure1SortNumber: Int!, $measure2Id: Int!, $measure2SortNumber: Int!) {
    measure1: update_kap_measure(where: { id: { _eq: $measure1Id } }, _set: { sort_number: $measure1SortNumber }) {
      affected_rows
    }
    measure2: update_kap_measure(where: { id: { _eq: $measure2Id } }, _set: { sort_number: $measure2SortNumber }) {
      affected_rows
    }
  }
`

export const fetchKapMeasureByIdQuery = gql`
  query fetchKapMeasureById($id: Int!) {
    kap_measure_by_pk(id: $id) {
      id
      name
      short_title
      description
      modules
      level
      kap_goal_measures {
        kap_goal {
          id
          name
          sort_number
        }
      }
    }
  }
`

export const fetchKapMeasureByIdWithFactsheetQuery = gql`
  query fetchKapMeasureByIdWithFactsheet($id: Int!, $locale: String!) {
    kap_measure_by_pk(id: $id) {
      id
      name
      sort_number
      short_title
      description
      modules
      level
      factsheet {
        id
        title(path: $locale)
      }
      kap_goal_measures {
        kap_goal {
          id
          name
          sort_number
        }
      }
    }
  }
`

export const fetchKapGoalsByProgramIdQuery = gql`
  query fetchKapGoalsByProgramId($programId: Int!) {
    kap_goal(where: { kap_program_id: { _eq: $programId } }) {
      id
      name
      sort_number
    }
  }
`

export const fetchKapMeasureShortTitleAndLevelByIdQuery = gql`
  query fetchKapMeasureById($measureId: Int!) {
    kap_measure_by_pk(id: $measureId) {
      short_title
      level
    }
  }
`

export const fetchKapProgramModulesByProgramIdQuery = gql`
  query fetchKapProgramModulesByProgramId($id: Int!) {
    kap_program(where: { id: { _eq: $id } }) {
      modules
    }
  }
`

export const updateKapMeasureMutation = gql`
  mutation updateKapMeasure(
    $id: Int!
    $name: String
    $shortTitle: String
    $description: String
    $modules: jsonb
    $goalsToBeAdded: [kap_goal_measure_insert_input!]!
  ) {
    delete_kap_goal_measure(where: { kap_measure_id: { _eq: $id } }) {
      affected_rows
    }
    insert_kap_goal_measure(objects: $goalsToBeAdded) {
      affected_rows
    }
    update_kap_measure(
      where: { id: { _eq: $id } }
      _set: { name: $name, short_title: $shortTitle, description: $description, modules: $modules }
    ) {
      affected_rows
    }
  }
`

export const updateKapMeasureValidFieldMutation = gql`
  mutation updateKapMeasureValidField($measureId: Int!) {
    # Updating a measure just to call the trigger for validating a measure
    update_kap_measure(where: { id: { _eq: $measureId } }, _set: { valid: false }) {
      affected_rows
    }
  }
`

export const insertKapMeasureMutation = gql`
  mutation insertKapMeasure(
    $name: String
    $shortTitle: String
    $description: String
    $modules: jsonb
    $level: String
    $programId: Int!
    $goalsToBeAdded: [kap_goal_measure_insert_input!]!
  ) {
    insert_kap_measure(
      objects: {
        name: $name
        short_title: $shortTitle
        description: $description
        modules: $modules
        level: $level
        kap_program_id: $programId
        kap_goal_measures: { data: $goalsToBeAdded }
        feature_base: { data: {} }
      }
    ) {
      affected_rows
    }
  }
`

export const queryKapMeasureFeatureValuesWithKPIs = gql`
  query fetchFeatureBaseValuesWithKPIs($measureId: Int!, $featureConfigKPIWhere: feature_config_bool_exp!) {
    feature_base(where: { kap_measures: { id: { _eq: $measureId } } }) {
      id
      feature_values(
        where: { feature_config: $featureConfigKPIWhere }
        order_by: { feature_config: { sort_number: asc } }
      ) {
        id
        feature_config_id
        other_description
        feature_config {
          names
          tooltips
          sort_number
          feature_type_config {
            sort_number
            names
          }
        }
      }
    }
  }
`

export const updateKapMeasureFactsheetIdMutation = gql`
  mutation updateKapMeasureFactsheetId($measureId: Int!, $factsheetId: Int) {
    update_kap_measure(where: { id: { _eq: $measureId } }, _set: { factsheet_id: $factsheetId }) {
      affected_rows
    }
  }
`

export const fetchKapMeasureByIdWithFeaturesQuery = gql`
  query fetchKapMeasureById($measureId: Int!) {
    kap_measure_by_pk(id: $measureId) {
      level
      factsheet {
        id
        feature_base {
          feature_values {
            feature_config {
              id
              feature_type_id
            }
          }
        }
      }
      feature_base {
        feature_values {
          feature_config {
            id
            feature_type_id
          }
        }
      }
    }
  }
`
