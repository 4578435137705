import { Stack } from '@mui/material'
import { useEffect, useState } from 'react'
import { Form } from 'react-final-form'
import { useParams } from 'react-router'
import { FormValidationErrors } from 'src/@types/global'
import { Dossier_Set_Input, Mutation_Root, Query_Root } from 'src/@types/graphql'
import { useMessageSource } from 'src/i18n/useMessageSource'
import { ROUTES } from 'src/routing/routes'
import { fetchKapProgramByIdQuery, updateKapProgramQuery } from 'src/screens/kap/program/details/kapProgramQueries'
import { usePermissionsForKapProgram } from 'src/service/security/PermissionHook'
import { SaveAndBackButton, SaveButton } from 'src/shared/button/Buttons'
import { CANTON_TYPE, MODULE_TYPES, TEXT_LENGTH } from 'src/shared/constants/constants'
import { AutoCompleteField } from 'src/shared/form/control/AutoCompleteField'
import { DateTimePickerField } from 'src/shared/form/control/DateTimePickerField'
import { MultiSelectField } from 'src/shared/form/control/MultiSelectField'
import { TextField } from 'src/shared/form/control/TextField'
import { DirtyFormSpy } from 'src/shared/form/dirty/DirtyFormSpy'
import { createDecorators } from 'src/shared/form/utils/decorators'
import { composeValidators, maxChar, required, validDate } from 'src/shared/form/validation/validators'
import { PageLayout } from 'src/shared/layout/PageLayout'
import { ScreenLayout } from 'src/shared/layout/ScreenLayout'
import { NotAuthorized } from 'src/shared/not-authorized/NotAuthorized'
import { HelpAndInstructions } from 'src/shared/presentation/HelpAndInstructions'
import { useSidebarAPI } from 'src/shared/sidebar/SidebarAwareContext'
import { DateUtils } from 'src/shared/utils/DateUtils'
import { useDelayedNavigate } from 'src/shared/utils/hooks/navigation-hooks'
import { useError } from 'src/shared/utils/hooks/page-loading-error-hook'
import { useNotificationService } from 'src/shared/utils/NotificationService'
import { useClient } from 'urql'

interface EditForm {
  title: string
  shortTitle: string
  modules: MODULE_TYPES[]
  cantonCode: CANTON_TYPE
  programStartDate: string
  programEndDate: string
}

const decorators = createDecorators()

export const KapBasicInformationEditPage = () => {
  const { programId } = useParams()
  const { getMessage } = useMessageSource()
  const urqlClient = useClient()
  const notificationService = useNotificationService()
  const navigate = useDelayedNavigate()
  const setError = useError()
  const sidebarAPI = useSidebarAPI()

  const program_id = parseInt(programId as string)

  const [initialValues, setInitialValues] = useState<EditForm>()
  const { canEdit, loading } = usePermissionsForKapProgram(program_id)

  let submit: any = () => {}
  let formValid = false

  useEffect(() => {
    const fetchData = async () => {
      const { data } = await urqlClient
        .query<{ kap_program_by_pk: Query_Root['kap_program_by_pk'] }>(fetchKapProgramByIdQuery, { id: program_id })
        .toPromise()

      if (data) {
        const kap_program = data.kap_program_by_pk!

        setInitialValues({
          title: kap_program.dossier.title,
          shortTitle: kap_program.dossier.short_title,
          modules: kap_program.modules,
          cantonCode: kap_program.canton_code as CANTON_TYPE,
          programStartDate: kap_program.dossier.start_date
            ? DateUtils.parseAndFormatDate(kap_program.dossier.start_date)
            : kap_program.dossier.start_date,
          programEndDate: kap_program.dossier.end_date
            ? DateUtils.parseAndFormatDate(kap_program.dossier.end_date)
            : kap_program.dossier.end_date,
        })
      } else {
        setError()
      }
    }

    fetchData()
  }, [program_id, setError, urqlClient])

  const onBack = () => {
    const route = ROUTES.KapDetailsRoot.nested.BasicInformation.params({ programId })
    navigate(`${route}#program-information`)
  }

  const onSave = async (event: any) => {
    submit(event)
  }

  const onSaveAndBack = async (event: any) => {
    try {
      await submit(event)
      formValid && onBack()
    } catch {
      // do nothing
    }
  }

  const handleSubmitLocal = async (values: EditForm) => {
    const dossierFieldsSetClause: Dossier_Set_Input = {
      title: values.title,
      short_title: values.shortTitle,
      start_date: DateUtils.parseAndSerializeDate(values.programStartDate),
      end_date: DateUtils.parseAndSerializeDate(values.programEndDate),
    }

    const mutationObject = {
      programId: program_id,
      dossierFieldsSetClause,
    }

    const { data } = await urqlClient
      .mutation<{
        update_dossier: Mutation_Root['update_dossier']
      }>(updateKapProgramQuery, mutationObject)
      .toPromise()

    if (data?.update_dossier?.affected_rows === 1) {
      notificationService.changesSaved()
      setInitialValues(values)
      sidebarAPI.refetchSidebarData()
    } else {
      notificationService.operationFailed()
    }
  }

  const validateForm = (values: EditForm): FormValidationErrors<EditForm> => {
    const error: FormValidationErrors<EditForm> = {}

    const startDate = DateUtils.parseDate(values.programStartDate)
    const endDate = DateUtils.parseDate(values.programEndDate)

    if (DateUtils.valid(startDate) && DateUtils.valid(endDate) && DateUtils.after(startDate, endDate)) {
      error.programEndDate = { errorKey: 'validation.kap.program.date.end.before.start' }
    }

    return error
  }

  if (!loading && !canEdit) {
    return <NotAuthorized />
  }

  return (
    <ScreenLayout
      title={getMessage('label.title.edit.kap.program.information')}
      onBack={onBack}
      hasSecondLevelNavigation={false}
      actions={
        <>
          <SaveAndBackButton origin="header" onClick={onSaveAndBack} />
          <SaveButton origin="header" onClick={onSave} />
        </>
      }
    >
      <PageLayout>
        <>
          {!loading && initialValues && (
            <>
              <HelpAndInstructions labelKey="label.help.kap.program.basic.information" defaultExpansion />
              <Form<EditForm>
                initialValues={initialValues}
                onSubmit={handleSubmitLocal}
                validate={validateForm}
                decorators={decorators}
                render={({ handleSubmit, valid }) => {
                  submit = handleSubmit
                  formValid = valid
                  return (
                    <form onSubmit={handleSubmit} noValidate>
                      <Stack spacing={2}>
                        <TextField
                          required
                          name="title"
                          label={getMessage('label.title')}
                          validate={composeValidators(required(), maxChar(TEXT_LENGTH.M))}
                        />
                        <TextField
                          required
                          name="shortTitle"
                          label={getMessage('label.short.title')}
                          validate={composeValidators(required(), maxChar(TEXT_LENGTH.S))}
                        />
                        <MultiSelectField
                          disabled
                          name="modules"
                          label={getMessage('label.modules')}
                          options={initialValues?.modules.map((module) => ({
                            label: getMessage(`label.module.description.${module}`),
                            value: module,
                          }))}
                          validate={required()}
                        />
                        <AutoCompleteField
                          disabled
                          label={getMessage('label.canton')}
                          name="cantonCode"
                          options={[
                            {
                              label: `${getMessage(`label.canton.${initialValues.cantonCode}`)} (${
                                initialValues.cantonCode
                              })`,
                              value: initialValues.cantonCode,
                            },
                          ]}
                        />
                        <DateTimePickerField
                          required
                          name="programStartDate"
                          label={getMessage('label.kap.program.start.date')}
                          validate={composeValidators(required(), validDate())}
                        />
                        <DateTimePickerField
                          required
                          name="programEndDate"
                          label={getMessage('label.kap.program.end.date')}
                          validate={composeValidators(required(), validDate())}
                        />
                        <DirtyFormSpy />
                      </Stack>
                    </form>
                  )
                }}
              />
            </>
          )}
        </>
      </PageLayout>
    </ScreenLayout>
  )
}
