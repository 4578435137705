import { gql } from '@urql/core'
import { createContext, ReactElement, ReactNode, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { SupportedLocale } from 'src/@types'
import { useEnvironment } from 'src/env/EnvironmentStore'
import { GeneralSystemErrorAlert } from 'src/shared/alert/GeneralSystemErrorAlert'
import { useNavigateToLogin } from 'src/shared/utils/hooks/navigation-hooks'
import { useNotificationService } from 'src/shared/utils/NotificationService'
import { Utils } from 'src/shared/utils/Utils'
import { User, UserStoreActions, useUserFromStore } from 'src/user/user'
import { fetcherErrorHandlerWithRedirectToLogin, useFetchCurrentUser } from 'src/user/user-hooks'
import { useMutation } from 'urql'

const defaultContextValue: UserContextValue = {
  user: {} as User,
  changeLocale: (_: SupportedLocale) => {},
}

interface UserContextValue {
  user: User
  changeLocale: (locale: SupportedLocale) => void
}

const UserContext = createContext<UserContextValue>(defaultContextValue)

export const useUserLocale = (): SupportedLocale => {
  const user = useUserFromStore()
  return user.language
}

const userLanguageMutation = gql`
  mutation ($email: String!, $language: String!) {
    update_user(where: { email: { _eq: $email } }, _set: { language: $language }) {
      returning {
        language
      }
    }
  }
`

const UserContextProvider = ({ children }: { children: ReactNode }): ReactElement => {
  const [isLoading, setIsLoading] = useState(true)
  const [hasError, setError] = useState<boolean>(false)
  const user = useUserFromStore()

  const notificationService = useNotificationService()
  const [, executeMutation] = useMutation(userLanguageMutation)
  const { backendUrl } = useEnvironment()
  const currentUserFetcher = useFetchCurrentUser()
  const login = useNavigateToLogin()

  const PUBLIC_CONTEXT = Utils.isPublic()

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const fetchedUser = await currentUserFetcher()
        UserStoreActions.setUser(fetchedUser)
        setIsLoading(false)
      } catch (e: any) {
        fetcherErrorHandlerWithRedirectToLogin(login)(e).catch(() => setError(true))
      }
    }

    if (!PUBLIC_CONTEXT) {
      fetchUserDetails()
    } else {
      setIsLoading(false)
    }
  }, [notificationService, backendUrl, PUBLIC_CONTEXT, login, currentUserFetcher])

  const changeLocale = useCallback(
    (locale: SupportedLocale) => {
      if (!PUBLIC_CONTEXT) {
        executeMutation({ email: user.email, language: locale })
      }
      UserStoreActions.setUser({ ...user, language: locale })
    },
    [PUBLIC_CONTEXT, executeMutation, user],
  )

  const contextValue: UserContextValue = useMemo(() => ({ user, changeLocale }), [user, changeLocale])

  return (
    <UserContext.Provider value={contextValue}>
      <>{!isLoading && !hasError && children}</>
      <>{hasError && <GeneralSystemErrorAlert />}</>
    </UserContext.Provider>
  )
}

const useUser = (): UserContextValue => {
  return useContext(UserContext)
}

export { UserContext, UserContextProvider, useUser }
