import { Box } from '@mui/material'
import { useContext, useState } from 'react'
import { Query_Root } from 'src/@types/graphql'
import { useMessageSource } from 'src/i18n/useMessageSource'
import { CreateRoundModal } from 'src/screens/administration/round-management/CreateRoundModal'
import { getRoundOverviewDataQuery } from 'src/screens/administration/round-management/round-management-queries'
import { RoundManagementGrid } from 'src/screens/administration/round-management/RoundManagementGrid'
import { usePermissionsForRoundManagementAdministration } from 'src/service/security/PermissionHook'
import { AddButton } from 'src/shared/button/Buttons'
import { AddIcon } from 'src/shared/icons/Icons'
import { PageLayout } from 'src/shared/layout/PageLayout'
import { ScreenLayout } from 'src/shared/layout/ScreenLayout'
import { ModalDialog } from 'src/shared/modal-dialog/ModalDialog'
import { NotAuthorized } from 'src/shared/not-authorized/NotAuthorized'
import { HelpAndInstructions } from 'src/shared/presentation/HelpAndInstructions'
import { Section } from 'src/shared/presentation/Section'
import { Utils } from 'src/shared/utils/Utils'
import { UserContext } from 'src/user/UserContext'
import { useQuery } from 'urql'

export const RoundManagementIndexPage = () => {
  const [openModal, setOpenModal] = useState(false)
  const { user } = useContext(UserContext)
  const { getMessage } = useMessageSource()

  const processes = Utils.resolveProcessFromInternalGfchRole(user.roles)

  const [{ data }, refetch] = useQuery<
    { funding_round: Query_Root['funding_round']; application_type: Query_Root['application_type'] },
    { userProcesses: string[] }
  >({
    query: getRoundOverviewDataQuery,
    variables: { userProcesses: processes },
  })

  const { canView, canEdit } = usePermissionsForRoundManagementAdministration()

  if (!canView) {
    return <NotAuthorized />
  }

  const handleCreateRound = () => {
    setOpenModal(true)
  }

  const closeModal = () => {
    setOpenModal(false)
  }

  const onSuccess = () => {
    setOpenModal(false)
    refetch()
  }

  return (
    <ScreenLayout title={getMessage('label.navigation.round.management')} hasSecondLevelNavigation={false}>
      <PageLayout>
        <Section
          id="round-overview"
          title={getMessage('label.round.overview')}
          actionButton={
            canEdit && (
              <AddButton messageKey="button.create.round" startIcon={<AddIcon />} onClick={handleCreateRound} />
            )
          }
          helpAndInstructions={<HelpAndInstructions labelKey="label.help.round.overview" defaultExpansion />}
        >
          <Box>
            {data && (
              <RoundManagementGrid
                rounds={data.funding_round}
                applicationTypes={data.application_type}
                canEdit={canEdit}
                refetch={refetch}
              />
            )}
          </Box>
          <ModalDialog
            open={openModal}
            title={getMessage('label.create.round')}
            maxWidth="sm"
            withCloseIcon={false}
            onClose={closeModal}
          >
            <CreateRoundModal onCancel={closeModal} onSuccess={onSuccess} />
          </ModalDialog>
        </Section>
      </PageLayout>
    </ScreenLayout>
  )
}
