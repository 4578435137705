import { createContext, ReactNode } from 'react'
import { Help_And_Instruction_Link, Query_Root } from 'src/@types/graphql'
import { useUserLocale } from 'src/user/UserContext'
import { gql, useQuery } from 'urql'

interface HelpAndInstructionLinkModel {
  id: number
  key: string
  label: string
  link: string
}

export type HelpAndInstructionLinksContextShape = {
  getLinks: (key: string) => HelpAndInstructionLinkModel[]
}

/**
 * Initial Context value, an empty array.
 */
const empty: HelpAndInstructionLinksContextShape = {
  getLinks: () => [],
}

/**
 * A React Context which holds the help and instructions links map.
 */
export const HelpAndInstructionLinksContext = createContext<HelpAndInstructionLinksContextShape>(empty)
HelpAndInstructionLinksContext.displayName = 'HelpAndInstructionLinksContext'

const fetchHelpAndInstructionsLinksQuery = gql`
  query fetchHelpAndInstructionsLinks($language: String!) {
    help_and_instruction_link(order_by: { id: asc }) {
      id
      key
      labels(path: $language)
      links(path: $language)
    }
  }
`

export const HelpAndInstructionLinksContextProvider = ({ children }: { children: ReactNode }) => {
  const locale = useUserLocale()

  const [{ data, error }] = useQuery<
    { help_and_instruction_link: Query_Root['help_and_instruction_link'] },
    { language: string }
  >({
    query: fetchHelpAndInstructionsLinksQuery,
    variables: { language: locale },
  })

  if (!data || error) {
    return null
  }

  const getSafeLink = (link: string) => {
    if (link.startsWith('http')) {
      return link
    }

    return `https://${link}`
  }

  const mapLinksToHelpAndInstructionLinksModel = (
    links: Help_And_Instruction_Link[],
  ): HelpAndInstructionLinkModel[] => {
    return links.map(({ id, key, labels, links }) => ({
      id: id,
      key: key,
      label: labels,
      link: getSafeLink(links),
    }))
  }

  const links = data?.help_and_instruction_link

  const getLinks = (key: string) => {
    return mapLinksToHelpAndInstructionLinksModel(links.filter((link) => link.key === key))
  }

  return (
    <HelpAndInstructionLinksContext.Provider value={{ getLinks }}>{children}</HelpAndInstructionLinksContext.Provider>
  )
}
