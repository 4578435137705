import { Box, CardHeader, Typography } from '@mui/material'
import { ReactElement } from 'react'
import { useMessageSource } from 'src/i18n/useMessageSource'
import { MODULE_TYPES } from 'src/shared/constants/constants'
import { ExpandableCard } from 'src/shared/presentation/ExpandableCard'
import { HtmlRenderer } from 'src/shared/presentation/HtmlRenderer'
import { S } from 'src/shared/styled/S'
import styled from 'styled-components/macro'

const ExpandableCardStyled = styled(ExpandableCard)`
  .MuiCardActions-root {
    height: 3.5rem;
    margin-right: ${({ theme }) => theme.spacing(1)};
  }

  & .MuiCardHeader-content {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: ${({ theme }) => theme.spacing(2)};
    align-items: center;
  }
`

// Initial National Situation styling taken from eKap
const BoxStyled = styled(Box)`
  & .MuiTypography-root p {
    margin: 0;
    margin-bottom: 16px;
  }
`

interface CollapseCardProps {
  module: {
    character: MODULE_TYPES
    value: string
  }
}

export const InitialSituationCard = ({ module }: CollapseCardProps): ReactElement => {
  const { getMessage } = useMessageSource()

  const title = getMessage(`label.module.description.${module.character}`)
  const moduleType = getMessage(`label.module.${module.character}`)

  return (
    <ExpandableCardStyled
      header={
        <CardHeader
          title={<Typography variant="h6">{title}</Typography>}
          subheader={<S.ModuleChip size="small" label={moduleType} />}
        />
      }
      content={
        <BoxStyled>
          <HtmlRenderer html={module.value} />
        </BoxStyled>
      }
    />
  )
}
