import { useParams } from 'react-router-dom'
import { useMessageSource } from 'src/i18n/useMessageSource'
import { ROUTES } from 'src/routing/routes'
import { BASE_URL } from 'src/shared/constants/constants'
import { Sidebar, SidebarGroupObject } from 'src/shared/sidebar/Sidebar'

export const FactsheetsSidebar = () => {
  const { factsheetId } = useParams()
  const { getMessage } = useMessageSource()

  const routeGroups: SidebarGroupObject[] = [
    {
      groupMessageKey: 'label.factsheet.configuration',
      list: [
        {
          listMessageKey: 'label.navigation.factsheets.factsheet.description',
          href: ROUTES.FactsheetDescriptionRoot.params({ factsheetId }),
          routePath: ROUTES.FactsheetDescriptionRoot.path,
        },
        {
          listMessageKey: 'label.navigation.factsheets.related.projects.measures',
          href: ROUTES.FactsheetRelatedProjects.params({ factsheetId }),
          routePath: ROUTES.FactsheetRelatedProjects.path,
        },
      ],
    },
  ]

  return (
    <Sidebar
      routes={routeGroups}
      hideDetailedInformation
      home={{ url: BASE_URL.FACTSHEETS, label: getMessage('label.navigation.factsheets') }}
    />
  )
}
