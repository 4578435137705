// strict-mode-find-node is caused by material-ui. in the current version 4.x.x they are not react strict mode compliant
// but they aim to be in 5.x.x
const KNOWN_VIOLATIONS = [
  'https://reactjs.org/link/strict-mode-find-node',
  'Material-UI: The `fade` color utility was renamed to `alpha` to better describe its functionality.',
  // this useless warning has been removed in React 18 so we can silence it for the time being https://github.com/facebook/react/pull/22114
  "Warning: Can't perform a React state update on an unmounted component.",
  // unintended consequence of react fast refresh reloading the whole file (aka module) which makes
  // the decorators array get new reference, that react-final-form warns that it might be potentially changed
  'Form decorators should not change from one render to the next as new values will be ignored',
  'theme.components.MuiDataGrid.styleOverrides',
]

export const configureConsoleError = (silenceKnownErrors = true): void => {
  if (silenceKnownErrors) {
    const oldError = console.error
    const oldWarn = console.warn
    console.error = (...args) => {
      const firstArg = args[0]
      if (typeof firstArg === 'string' && KNOWN_VIOLATIONS.some((v) => firstArg.includes(v))) {
        return
      }
      oldError(...args)
    }

    console.warn = (...args) => {
      const firstArg = args[0]
      if (typeof firstArg === 'string' && KNOWN_VIOLATIONS.some((v) => firstArg.includes(v))) {
        return
      }
      oldWarn(...args)
    }
  }
}
