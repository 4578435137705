import { LoadingButton } from '@mui/lab'
import { Box, DialogActions, DialogContent } from '@mui/material'
import { useEffect, useState } from 'react'
import { EditUserByGfchInput, Mutation_Root, Query_Root } from 'src/@types/graphql'
import { useMessageSource } from 'src/i18n/useMessageSource'
import {
  AdministrationUserForm,
  AdministrationUserFormValues,
} from 'src/screens/administration/user-administration/common/AdministrationUserForm'
import {
  editUserByGfch,
  queryEditingUserById,
} from 'src/screens/administration/user-administration/edit-user/editUsersQueries'
import { SecondaryButton } from 'src/shared/button/Buttons'
import { useNotificationService } from 'src/shared/utils/NotificationService'
import { useClient } from 'urql'

interface Props {
  onCancel: () => void
  onSuccess: () => void
  userId: number | null
}

export const EditUserModal = ({ userId, onCancel, onSuccess }: Props) => {
  const { getMessage } = useMessageSource()
  const urqlClient = useClient()
  const notificationService = useNotificationService()
  const [initialValues, setInitialValues] = useState<AdministrationUserFormValues>()
  const [loading, setLoading] = useState<boolean>(false)

  useEffect(() => {
    const initData = async () => {
      const { data } = await urqlClient
        .query<{ user: Query_Root['user'] }>(queryEditingUserById, { userId })
        .toPromise()

      if (data?.user[0]) {
        const user = data?.user[0]
        const editingUser = {
          type: user.type,
          title: user.title,
          firstName: user.first_name,
          lastName: user.last_name,
          language: user.language,
          email: user.email,
          gfchCantonalResponsibility: user.gfch_cantonal_responsibility ?? [],
          cantonalUserCanton: user.cantonal_user_canton,
          operationalResponsibility: user.operational_responsibility ?? [],
          strategicResponsibility: user.strategic_responsibility ?? [],
          roles: user.user_roles.map((userRole) => userRole.role),
        } as unknown as AdministrationUserFormValues
        setInitialValues(editingUser)
      }
    }
    initData()
  }, [urqlClient, userId])

  const onSubmit = () => {
    document
      .getElementById('administration-edit-form')
      ?.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }))
  }

  const handleSubmitLocal = async (values: AdministrationUserFormValues) => {
    const refinedValue = {
      ...values,
      gfchCantonalResponsibility: values?.gfchCantonalResponsibility?.length
        ? values.gfchCantonalResponsibility.sort()
        : null,
      strategicResponsibility: values?.strategicResponsibility?.length ? values.strategicResponsibility.sort() : null,
      operationalResponsibility: values?.operationalResponsibility?.length
        ? values.operationalResponsibility.sort()
        : null,
    }

    try {
      setLoading(true)

      const { data } = await urqlClient
        .mutation<{ editUserByGfch: Mutation_Root['editUserByGfch'] }, { user: EditUserByGfchInput }>(editUserByGfch, {
          user: { id: userId, ...refinedValue } as EditUserByGfchInput,
        })
        .toPromise()

      if (data) {
        const status = data.editUserByGfch.status
        const failureKey = data.editUserByGfch.failureKey

        if (status === 'SUCCESS') {
          notificationService.success(getMessage('notification.user.update.success'))
          onSuccess()
        } else {
          if (failureKey) {
            notificationService.error(getMessage(failureKey))
          } else {
            notificationService.error(getMessage('notification.user.update.failure'))
          }
        }
      } else {
        notificationService.error(getMessage('notification.user.update.failure'))
      }
    } catch (e) {
      notificationService.error(getMessage('notification.user.update.failure'))
    } finally {
      setLoading(false)
    }
  }

  return (
    <Box>
      <DialogContent>
        <AdministrationUserForm onSave={handleSubmitLocal} initialValues={initialValues} mode={'EDIT'} />
      </DialogContent>
      <DialogActions>
        <SecondaryButton messageKey={'button.cancel'} onClick={onCancel} />
        <LoadingButton variant="contained" color="primary" onClick={onSubmit} loading={loading}>
          {getMessage('button.save')}
        </LoadingButton>
      </DialogActions>
    </Box>
  )
}
