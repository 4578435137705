import { useState } from 'react'
import { Query_Root } from 'src/@types/graphql'
import { useMessageSource } from 'src/i18n/useMessageSource'
import { AddAssessmentModal } from 'src/screens/shared/application/assessment/add-assessment/AddAssessmentModal'
import { AssessmentGrid } from 'src/screens/shared/application/assessment/AssessmentGrid'
import { usePermissionsForProjectAssessment } from 'src/service/security/PermissionHook'
import { AddButton } from 'src/shared/button/Buttons'
import { PageLayout } from 'src/shared/layout/PageLayout'
import { ScreenLayout } from 'src/shared/layout/ScreenLayout'
import { ProjectExportMenu } from 'src/shared/menu/ProjectExportMenu'
import { ModalDialog } from 'src/shared/modal-dialog/ModalDialog'
import { HelpAndInstructions } from 'src/shared/presentation/HelpAndInstructions'
import { Section } from 'src/shared/presentation/Section'
import { useModalCancel } from 'src/shared/utils/hooks/modal-hooks'
import { Utils } from 'src/shared/utils/Utils'
import { gql, useQuery } from 'urql'

const getAssessmentsQuery = gql`
  query fetchAssessmentsQuery($projectBaseId: Int!) {
    project_assessment(where: { project_base_id: { _eq: $projectBaseId } }) {
      id
      recommendation
      assessor_id
      instance_id
      user {
        first_name
        last_name
        email
      }
      assessment_instance_type {
        key
      }
    }
  }
`

interface Props {
  projectId: number
  baseUrl: '/pf-kap' | '/pf-pgv'
}

export const ApplicationAssessment = ({ projectId, baseUrl }: Props) => {
  const { getMessage } = useMessageSource()
  const [openModal, setOpenModal] = useState<boolean>(false)

  const process = Utils.resolveProcessToLowerCase(baseUrl)
  const projectType = Utils.resolveProcess(baseUrl)
  const {
    loading,
    canAdd,
    canDelete,
    metadata: { projectBaseId },
  } = usePermissionsForProjectAssessment(projectType, projectId)

  const onClose = useModalCancel(() => setOpenModal(false))

  const [{ data }, refetch] = useQuery<{
    project_assessment: Query_Root['project_assessment']
  }>({
    query: getAssessmentsQuery,
    variables: { projectBaseId },
  })

  const onSuccess = () => {
    refetch()
    setOpenModal(false)
  }

  return (
    <ScreenLayout
      title={getMessage('label.navigation.application.assessment')}
      actions={<ProjectExportMenu process={projectType} entityId={projectBaseId as number} />}
      hasSecondLevelNavigation={false}
    >
      <PageLayout>
        {!loading && (
          <Section
            id="information"
            title={getMessage('label.personal.assessment')}
            actionButton={
              <AddButton messageKey="button.assessor.add" onClick={() => setOpenModal(true)} hidden={!canAdd} />
            }
            helpAndInstructions={<HelpAndInstructions labelKey={`label.help.personal.assessment.${process}`} />}
          >
            <AssessmentGrid
              assessments={data?.project_assessment ?? []}
              baseUrl={baseUrl}
              projectId={projectId.toString()}
              refetch={refetch}
              canDeleteAssessment={canDelete}
            />
          </Section>
        )}
      </PageLayout>
      <ModalDialog title={getMessage('label.title.add.assessment')} open={openModal} onClose={onClose}>
        <AddAssessmentModal
          onCancel={onClose}
          projectType={projectType}
          projectBaseId={projectBaseId as number}
          onSuccess={onSuccess}
          assessments={data?.project_assessment}
          onDiscard={() => setOpenModal(false)}
        />
      </ModalDialog>
    </ScreenLayout>
  )
}
