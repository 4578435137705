import { Accordion } from '@mui/material'
import styled from 'styled-components/macro'

export const AccordionStyled = styled(Accordion)`
  background-color: inherit;
  box-shadow: ${({ theme }) => theme.shadows[0]};
  &:before {
    display: none;
  }
  &.Mui-expanded {
    margin: 0;
  }
  & .MuiAccordionSummary-root {
    padding: 0;
    display: flex;
    justify-content: flex-start;
    color: ${({ theme }) => theme.colors.text.disabled};
    & > .MuiAccordionSummary-content {
      flex-grow: 0;
      margin: 0;
    }
    &.Mui-expanded {
      min-height: 0;
    }
    & > .MuiAccordionSummary-content.Mui-expanded {
      margin: 0;
    }
  }
  & .MuiAccordionSummary-expandIconWrapper {
    padding: 0 ${({ theme }) => theme.spacing(0.5)};
    & .MuiSvgIcon-root {
      color: ${({ theme }) => theme.colors.text.disabled};
    }
  }
  & .MuiAccordionDetails-root {
    padding: 0;
  }
  & .MuiButtonBase-root {
    min-height: max-content;
    &:hover,
    &:focus-visible {
      color: ${({ theme }) => theme.colors.grey.dark};
    }
    &:hover .MuiSvgIcon-root,
    &:focus-visible .MuiSvgIcon-root {
      color: ${({ theme }) => theme.colors.grey.dark};
    }
  }
`
