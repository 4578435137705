import { useEffect, useState } from 'react'
import { Query_Root } from 'src/@types/graphql'
import { queryApplicationInformationByProjectBaseId } from 'src/screens/shared/application/details/application-information/applicationInformationQueries'
import { useClient } from 'urql'

export interface ApplicationInformationValues {
  dossierStatus: string
  fundingRoundId: number
  fundingRoundName: string
  fundingRoundDecisionDate: string
  applicationDecision: string
  version: number
}

export const useResolveApplicationInformationData = (projectBaseId: number | null) => {
  const [applicationInformation, setApplicationInformation] = useState<ApplicationInformationValues>()
  const urqlClient = useClient()

  useEffect(() => {
    const initData = async () => {
      const { data } = await urqlClient
        .query<{
          project_application_decision: Query_Root['project_application_decision']
          dossier: Query_Root['dossier']
        }>(queryApplicationInformationByProjectBaseId, { projectBaseId })
        .toPromise()
      if (data) {
        const applicationInformation = {
          dossierStatus: data.dossier[0]?.status,
          fundingRoundId: data.dossier[0]?.funding_round_id ?? null,
          fundingRoundName: data.dossier[0]?.funding_round?.name,
          fundingRoundDecisionDate: data.dossier[0]?.funding_round?.decision_date,
          applicationDecision: data.project_application_decision[0]?.application_decision ?? null,
          version: data.project_application_decision[0]?.version ?? null,
        } as ApplicationInformationValues
        setApplicationInformation(applicationInformation)
      }
    }
    initData()
  }, [projectBaseId, urqlClient])

  return {
    applicationInformation,
    setApplicationInformation,
  }
}
