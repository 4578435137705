import { ReactElement, useState } from 'react'
import { useMessageSource } from 'src/i18n/useMessageSource'
import { ROUTES } from 'src/routing/routes'
import { CreateKapProgramModalDialog } from 'src/screens/kap/index/add-program/CreateKapProgramModalDialog'
import { KapProgramCockpitGrid } from 'src/screens/kap/index/KapProgramCockpitGrid'
import { PageLayout } from 'src/shared/layout/PageLayout'
import { ScreenLayout } from 'src/shared/layout/ScreenLayout'
import { ModalDialog } from 'src/shared/modal-dialog/ModalDialog'
import { HelpAndInstructions } from 'src/shared/presentation/HelpAndInstructions'
import { useModalCancel } from 'src/shared/utils/hooks/modal-hooks'
import { useDelayedNavigate } from 'src/shared/utils/hooks/navigation-hooks'

export const KapIndexPage = (): ReactElement => {
  const { getMessage } = useMessageSource()
  const [open, setOpen] = useState(false)
  const delayedNavigate = useDelayedNavigate()

  const handleClickOpen = () => {
    setOpen(true)
  }

  const onSuccess = (programId: number) => {
    setOpen(false)
    delayedNavigate(ROUTES.KapDetailsRoot.nested.BasicInformation.params({ programId }))
  }

  const onCancel = useModalCancel(() => setOpen(false))

  return (
    <ScreenLayout title={getMessage('label.kap.programs.cockpit')} hasSecondLevelNavigation={false}>
      <PageLayout>
        <HelpAndInstructions labelKey="label.help.kap.cockpit" defaultExpansion />
        <ModalDialog open={open} onClose={onCancel} title={getMessage('label.kap.program.create')} maxWidth="md">
          <CreateKapProgramModalDialog onCancel={onCancel} onSuccess={onSuccess} />
        </ModalDialog>
        <KapProgramCockpitGrid addProgram={handleClickOpen} />
      </PageLayout>
    </ScreenLayout>
  )
}
