import { Box, CardHeader, IconButton, Typography } from '@mui/material'
import { ReactElement, ReactNode, useEffect, useState } from 'react'
import { useLocation } from 'react-router'
import { useMessageSource } from 'src/i18n/useMessageSource'
import { MODULE_TYPES } from 'src/shared/constants/constants'
import { EditIcon, ExternalIcon, MoveDownIcon, MoveUpIcon } from 'src/shared/icons/Icons'
import { ExpandableCard } from 'src/shared/presentation/ExpandableCard'
import { S } from 'src/shared/styled/S'
import styled from 'styled-components/macro'
import { useHashScroll } from '../utils/hooks/navigation-hooks'

interface SortGoalMeasure {
  moveUp: (event: any, id: number) => Promise<void>
  moveDown: (event: any, id: number) => void
  index: number
  listLength: number
  beingSwappedIds: { id1: number; id2: number } | undefined
}

interface Props {
  title: string
  id: string
  entityId: number
  modules?: MODULE_TYPES[]
  valid?: boolean
  children: ReactNode
  onNavigate?: () => void
  onEdit: () => void
  canEdit: boolean
  sorting?: SortGoalMeasure
  deleteAction?: ReactNode
  type?: string
}

const CardHeaderStyled = styled(CardHeader)`
  height: 3rem;
  display: flex;
  justify-content: center;
  flex-grow: 1;
  padding-right: ${({ theme }) => theme.spacing(3)};

  & .MuiCardHeader-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`

const BoxStyled = styled(Box)`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(1)};
`

const ModulesContainerStyled = styled(Box)`
  display: flex;
  gap: ${({ theme }) => theme.spacing(1)};
  & .MuiAvatar-root {
    margin-right: 0;
  }
`

export const ExpandableGoalMeasureCard = ({
  title,
  id,
  entityId,
  modules,
  valid,
  children,
  onNavigate,
  onEdit,
  canEdit,
  sorting,
  deleteAction,
  type,
}: Props): ReactElement => {
  const { state } = useLocation()
  const { getMessage } = useMessageSource()

  const expandedEntityId = state?.expandedEntityId
  const cardId = `${type}${entityId}`

  const [expanded, setExpanded] = useState(false)

  useEffect(() => {
    const shouldExpand = expandedEntityId === cardId
    setExpanded(shouldExpand)
  }, [cardId, expandedEntityId])

  useHashScroll(cardId, true)

  return (
    <ExpandableCard
      id={cardId}
      header={
        <CardHeaderStyled
          avatar={
            <BoxStyled>
              {valid !== undefined && <S.Icons.Validation $valid={valid} />}
              <S.GoalMeasureCard.IdChip size="small" label={id} />
              {sorting && (
                <S.GoalMeasureCard.MoveButtons>
                  <IconButton
                    color="inherit"
                    disabled={sorting.index === 0 || !canEdit || !!sorting.beingSwappedIds}
                    onClick={(event) => sorting.moveUp(event, entityId)}
                    size="large"
                  >
                    <MoveUpIcon />
                  </IconButton>
                  <IconButton
                    color="inherit"
                    disabled={sorting.index === sorting.listLength - 1 || !canEdit || !!sorting.beingSwappedIds}
                    onClick={(event) => sorting.moveDown(event, entityId)}
                    size="large"
                  >
                    <MoveDownIcon />
                  </IconButton>
                </S.GoalMeasureCard.MoveButtons>
              )}
            </BoxStyled>
          }
          title={<Typography variant="subtitle2">{title}</Typography>}
          subheader={
            modules && (
              <ModulesContainerStyled>
                {modules.map((module) => (
                  <S.ModuleChip key={module} size="small" label={getMessage(`label.module.${module}`)} />
                ))}
              </ModulesContainerStyled>
            )
          }
          disableTypography
        />
      }
      content={children}
      actions={
        <>
          {onNavigate && (
            <IconButton color="primary" onClick={onNavigate}>
              <ExternalIcon />
            </IconButton>
          )}
          {canEdit && (
            <IconButton color="primary" onClick={onEdit}>
              <EditIcon />
            </IconButton>
          )}
          {deleteAction}
        </>
      }
      localExpansion={expanded}
    />
  )
}
