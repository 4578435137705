import { Box, DialogActions, DialogContent, Stack } from '@mui/material'
import { ReactElement, useState } from 'react'
import { Form } from 'react-final-form'
import { Mutation_Root } from 'src/@types/graphql'
import { useMessageSource } from 'src/i18n/useMessageSource'
import { insertProjectUser } from 'src/screens/shared/project/permissions/projectPermissionsQueries'
import { PrimaryButton, SecondaryButton } from 'src/shared/button/Buttons'
import { PROJECT_USER_ROLES, PROJECT_USER_ROLE_TYPE, TEXT_LENGTH } from 'src/shared/constants/constants'
import { AutoCompleteField } from 'src/shared/form/control/AutoCompleteField'
import { TextField } from 'src/shared/form/control/TextField'
import { createDecorators } from 'src/shared/form/utils/decorators'
import { composeValidators, maxChar, required, validEmail } from 'src/shared/form/validation/validators'
import { useNotificationService } from 'src/shared/utils/NotificationService'
import { useClient } from 'urql'

interface AddNewUserFormValues {
  email: string
  permissionOptionCode: PROJECT_USER_ROLE_TYPE
}

interface NewProjectUser {
  projectBaseId: number
  type: PROJECT_USER_ROLE_TYPE
  email: string
}

interface AddNewUserModalDialogProps {
  onCancel: () => void
  onSuccess: () => void
  projectBaseId: number
}

const decorators = createDecorators()

export const AddNewUserModalDialog = ({
  onCancel,
  onSuccess,
  projectBaseId,
}: AddNewUserModalDialogProps): ReactElement => {
  const { getMessage } = useMessageSource()
  const notificationService = useNotificationService()
  const [permissionOptions] = useState<{ label: string; value: string | number }[]>([
    { label: getMessage('label.can.edit'), value: PROJECT_USER_ROLES.CONTRIBUTOR },
    { label: getMessage('label.can.view'), value: PROJECT_USER_ROLES.READER },
  ])
  const urqlClient = useClient()

  const handleSubmitLocal = async (values: AddNewUserFormValues): Promise<any> => {
    const { data, error } = await urqlClient
      .mutation<{ addUserToProject: Mutation_Root['addUserToProject'] }, NewProjectUser>(insertProjectUser, {
        projectBaseId,
        email: values.email,
        type: values.permissionOptionCode,
      })
      .toPromise()

    if (error || data?.addUserToProject?.status === 'FAILURE') {
      notificationService.operationFailed()
    } else {
      notificationService.changesSaved()
      onSuccess()
    }
  }

  return (
    <>
      <Box>
        <Form<AddNewUserFormValues>
          onSubmit={handleSubmitLocal}
          decorators={decorators}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit} noValidate>
              <DialogContent>
                <Stack spacing={2}>
                  <TextField
                    label={getMessage('label.email')}
                    required
                    name="email"
                    validate={composeValidators(required(), validEmail(), maxChar(TEXT_LENGTH.S))}
                  />
                  <AutoCompleteField
                    required
                    label={getMessage('label.select.permission')}
                    name="permissionOptionCode"
                    options={permissionOptions}
                    validate={required()}
                  />
                </Stack>
              </DialogContent>
              <DialogActions>
                <SecondaryButton messageKey={'button.cancel'} onClick={onCancel} />
                <PrimaryButton messageKey={'button.add'} type="submit" />
              </DialogActions>
            </form>
          )}
        />
      </Box>
    </>
  )
}
